import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, CheckCircle, Clock, ClockClockwise, ClockCounterClockwise, HourglassLow, Prohibit, Scales, Target, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';

export function CardsHeaderReportsSignedPoints({ alertActions, userDetails, resume }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
          <CheckCircle size={20} color="#22BEAD" />
          <Typography color={"#22BEAD"} component="span" fontSize={20} mt={1}>
            {resume?.total_signed}
          </Typography>
          <Typography color={"#22BEAD"} component="span" fontSize={12}>
            {t('checkin.report_signed_points.total_signed')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <Prohibit size={20} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.total_missing_signed}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('checkin.report_signed_points.total_no_signed')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <Target size={20} color="#4F7091" />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {resume?.expected_hours}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('checkin.report_signed_points.expected_hours')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <Clock size={20} color="#4F7091" />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {resume?.worked_hours}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('checkin.report_signed_points.total_hours_worked')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <ClockClockwise size={20} color="#22BEAD" />
          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {resume?.overtime}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('checkin.report_signed_points.total_overtime')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <ClockCounterClockwise size={20} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.missing_hours}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('checkin.report_signed_points.total_missing')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
