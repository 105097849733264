import React, { useEffect, useRef, useState } from 'react';
import style from './styles.module.scss'
import { Box, Button, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, history } from '../../../_helpers';
import { Delete, Edit, Link } from '@material-ui/icons';
import { Backdrop, CircularProgress, IconButton, makeStyles, Modal, Popover, Slide, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';


const
  SimpleSubspaceCreate = ({
    subspaces,
    setValue,
    roomToEdit,
    getDetailSpace
  }) => {

    const { t, i18n } = useTranslation('common');

    const [newSubspace, setNewSubspace] = useState({ key: null, name: '' });
    const [formError, setFormError] = useState({
      name: ''
    });
    const [submitted, setSubmitted] = useState(false)

    const handleClean = () => {
      setNewSubspace({ key: null, name: '' })
    }

    const handleDeleteSubspace = async (subspace) => {
      try {
        setValue('subspaces', subspaces.filter(s => s.key !== subspace.key))
        if (roomToEdit && subspace.id) {
          const res = await api.delete(`/rooms/sub-space/simple/${subspace.id}`, { headers: authHeader() })
          await getDetailSpace(false)
        }
      } catch (error) {
        console.log(error)
      }
    };

    const handleAddSubspace = async () => {
      try {
        setValue('subspaces', [...subspaces, { ...newSubspace, key: uuidv4() }])
        handleClean()
      } catch (error) {
        console.log(error)
      }
    };

    const validateForm = (form) => {
      const errors = {};

      if (!form.name) {
        errors.name = t('project_management.validations.required_field')
      }

      return errors
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      setFormError(validateForm(newSubspace))
      setSubmitted(true)
    }

    useEffect(() => {
      if (Object.keys(formError).length === 0 && submitted) {
        handleAddSubspace()
      } else {
        setSubmitted(false)
      }

    }, [formError, submitted])

    return (

      <Box sx={{ flexGrow: 1, width: '100%', backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }}>
        
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontSize: '14px', color: '#1E6B7F', fontWeight: 500, marginTop: 0, marginBottom: 0 }}>{t('spaces.map_editor.create_element')}</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              id="name"
              label={t('spaces.map_editor.field_title')}
              variant="outlined"
              value={newSubspace.name}
              onChange={(e) => setNewSubspace({ ...newSubspace, name: e.target.value })}
              error={formError.name}
              onFocus={() => setFormError({ ...formError, name: '' })}
              size='small'
            />
          </Grid>
          <Grid item xs={2}>
            <Button style={{ backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'uppercase' }}
              fullWidth type='button' variant="contained" disableElevation onClick={handleSubmit}>
              {t('spaces.map_editor.btn_add_shape')}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List>
              {
                (subspaces !== null && subspaces?.length > 0) &&
                subspaces?.map(subspace => (
                  <Box key={subspace.key}>
                    <ListItem
                      style={{ paddingLeft: '6px' }}
                    >
                      <ListItemText style={{ marginTop: '2px', marginBottom: '2px', color: '#1E6B7F' }} primary={subspace.name} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete-subspace" onClick={() => handleDeleteSubspace(subspace)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                  </Box>
                ))
              }
            </List>
          </Grid>
        </Grid>
      </Box>
    );
  };

export default SimpleSubspaceCreate;