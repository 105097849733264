import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, Clock, ClockClockwise, ClockCounterClockwise, HourglassLow, Scales, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';

export function CardsHeaderReportsOvertime({ alertActions, userDetails, resume }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
          <ClockClockwise size={18} color="#22BEAD" />
          <Typography color={"#22BEAD"} component="span" fontSize={20} mt={1}>
            {resume?.avg_total_overtime}
          </Typography>
          <Typography color={"#22BEAD"} component="span" fontSize={12}>
            {t("checkin.report_overtime.avg_total_overtime")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <ClockCounterClockwise size={18} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.avg_total_missing}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t("checkin.report_overtime.avg_total_missing")}

          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <ClockClockwise size={18} color="#22BEAD" />
          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {resume?.total_overtime}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t("checkin.report_overtime.total_overtime")}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <ClockCounterClockwise size={18} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.total_missing}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t("checkin.report_overtime.total_missing")}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187DFF` }}>
          <Scales size={18} color="#187DFF" />
          <Typography color="#187DFF" component="span" fontSize={20} mt={1}>
            {resume?.total_balance}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t("checkin.report_overtime.total_balance")}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
