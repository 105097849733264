import React from 'react';

const IconArrow2 = props => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="5.289" height="3" viewBox="0 0 5.289 3">
            <path id="_" data-name="&gt;" d="M1.15,13.5H5.727a.355.355,0,0,1,.251.607L3.69,16.4a.356.356,0,0,1-.5,0L.9,14.107A.355.355,0,0,1,1.15,13.5Z" transform="translate(-0.794 -13.5)" />
        </svg>


    )
}

export default IconArrow2;