import React, {useRef, useState, compose, useEffect} from 'react';

import {connect} from 'react-redux';
import {useTranslation, initReactI18next} from "react-i18next";

import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux'

import Skeleton from "@material-ui/lab/Skeleton";

function SettingsProfile(props) {
    const {t, i18n} = useTranslation('common');


    return (

        <div className={'tab full active'}>
            <div className="download">
                <section>
                    <h3><Skeleton variant="text" width="40%" height={42}/></h3>
                    <p><Skeleton variant="text" width="40%" height={22}/></p>
                    <div className="options">
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                    </div>
                </section>
                <section>
                    <h3><Skeleton variant="text" width="40%" height={42}/></h3>
                    <p><Skeleton variant="text" width="40%" height={22}/></p>
                    <div className="options">
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                    </div>
                </section>
                <section>
                    <h3><Skeleton variant="text" width="40%" height={42}/></h3>
                    <p><Skeleton variant="text" width="40%" height={22}/></p>
                    <div className="options">
                        <div className="option">
                            <Skeleton variant="text" width={156} height={62}/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
};


function mapStateToProps(state) {

    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsProfile));
