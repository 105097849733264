import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'

import { teamsActions } from '../../../_actions'

import { CircularProgress } from '@material-ui/core'
import Select from 'react-select'
import { useState } from 'react'
import { api } from '../../../_helpers/api'
import { authHeader, getOptionsPeriods, getPeriod, getValuePeriod } from '../../../_helpers'
import { useParams } from 'react-router-dom'

import './styles.scss'
import moment from 'moment'
import { BefectiveDatePicker } from '../../goals/BefectiveDatePicker'

function DrawerFiltersGoals({ setGoalsData, userDetails, getGoalsDashboardData, setIsLoadingGoalsData, getGoalsCardsInfo, setGoalsDataCards }) {
	const { t } = useTranslation('common')
	const token = authHeader()

	const params = useParams()


	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [goalOptions, setGoalOptions] = useState([])
	const [period, setPeriod] = useState('custom');
	const [valuePeriod, setValuePeriod] = useState('');
	const [goals, setGoals] = useState([]);
	const optionsPeriods = getOptionsPeriods();

	const [form, setForm] = useState({
		yearsSelect: '',
		goalsSelect: [],
		periodSelect: '',
		date_start: '',
		date_end: '',
		year_period: moment.utc().format('YYYY')
	})


	let years = goals?.map(function (obj) { return Number(new Date(obj.date_end).getFullYear()) });

	years = years.filter(function (v, i) { return years.indexOf(v) === i; });

	const yearsOptions = years?.reduce((acc, year) => {
		return [...acc, { value: year, label: year }]
	}, [])

	const handleChange = (event) => {

		setForm({ ...form, [event.target.name]: event.target.value });

	};

	useEffect(() => {
		setForm({
			...form,
			goalsSelect: goalOptions,
			periodSelect: '',
			date_start: '',
			date_end: '',
			year_period: moment.utc().format('YYYY')
		})
	}, [goalOptions])

	useEffect(() => {
		if (period === 'custom') {
			setForm({
				...form,
				period: period,
				value_period: 'custom',
				date_start: '',
				date_end: ''
			})
		}
		setValuePeriod(optionsPeriods?.filter((item) => item.type === period)[0]?.value)
	}, [period])

	
	useEffect(() => {
		setForm({
			...form,
			...getValuePeriod(form, period, valuePeriod)
		})
	}, [valuePeriod, form.year_period])

	const getGoals = async () => {
		try {
			const response = await api.get(`/goals/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			const filteredGoals = response.data?.goals?.reduce((acc, goal) => {
				return [...acc, { value: goal.id, label: goal.title }]
			}, [])
			setGoals(filteredGoals)
		} catch (error) {
			console.log(error.response)
			// //alertActions.error(t('goals.errors.get_goals'))
		}
	}

	useEffect(()=>{
		getGoals()
	}, [])

	// useEffect(() => {
	// 	handleChange({ target: { name: 'yearsSelect', value: yearOption } })
	// }, [yearOption])

	async function handleFilter(e) {
		e.preventDefault()

		try {
			setIsLoadingGoalsData(true)
			setIsSubmitting(true)
			let goals_ids = ''

			form.goalsSelect.forEach(goal => {
				goals_ids += `${goal.value},`
			})

			let year_period = moment.utc().format("YYYY")

			if(form?.date_start !== ''){
				year_period = moment.utc(form?.date_start).format("YYYY")
			}

			const resp1 = await api.get(`/goals/dashboard?organization_id=${userDetails?.organizationId}&goals_id=${goals_ids}&year_filter=${year_period}&period=${form?.periodSelect}&date_start=${form?.date_start}&date_end=${form?.date_end}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			const response = await api.get(`/goals/cardsinfo?organization_id=${userDetails?.organizationId}&goals_id=${goals_ids}&year_filter=${year_period}&period=${form?.periodSelect}&date_start=${form?.date_start}&date_end=${form?.date_end}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setGoalsData(resp1.data)
			setIsLoadingGoalsData(false)
			setGoalsDataCards(response.data)
			setIsSubmitting(false)
		} catch (error) {
			setIsSubmitting(false)
			console.log(error)
		}
	}

	async function handleClear(e) {
		setForm({
			yearsSelect: '',
			goalsSelect: [],
			periodSelect: '',
			date_start: '',
			date_end: ''
		})
		await getGoalsDashboardData()
		await getGoalsCardsInfo()
	}

	return (
		<div className="containerDrawerFilterGoals">
			<main>
				<header>
					<h3>{t('keyresults.sidebar_filter.title')}</h3>
				</header>

				<div className='content'>

					{/* <div>
						<div className="row">

							<div className="col">
								<label htmlFor='selectYear'>{t('goals.drawer_filter.year')}</label>
								<Select
									id='selectYear'
									defaultValue={yearsOptions[0]}
									name="yearsSelect"
									options={yearsOptions}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange={e => setYearOption(e.value)}
									placeholder={t('keyresults.sidebar_filter.year')}
								/>
							</div>
						</div>
					</div> */}

					<div>

						<div className="row">
							<div className="col">
								<label htmlFor='goalsSelect'>{t('goals.drawer_filter.goal')}</label>
								<Select
									id='goalsSelect'
									defaultValue={[]}
									isMulti
									name="goalsSelect"
									options={goals}
									className="basic-multi-select"
									classNamePrefix="select"
									placeholder={t('goals.drawer_filter.goal')}
									onChange={setGoalOptions}
								/>
							</div>
						</div>
					</div>

					<div>
						<div className="row">
							<div className="col">
								<label htmlFor="period">{t('goals.modal_goal.type_period')}</label>
								<select name="period" id="period" value={period || ''} onChange={(e) => { setPeriod(e.target.value) }} disabled={goalOptions?.length > 0 ? true : false}>
									<option value="custom" selected>{t('goals.modal_goal.custom')}</option>
									<option value="monthly">{t('goals.modal_goal.monthly')}</option>
									<option value="bimonthly">{t('goals.modal_goal.bimonthly')}</option>
									<option value="quarterly">{t('goals.modal_goal.quarterly')}</option>
									<option value="semester">{t('goals.modal_goal.semester')}</option>
									<option value="yearly">{t('goals.modal_goal.yearly')}</option>
								</select>
							</div>
						</div>
					</div>

					<div>
						<div className="row">
							<div className="col">
								<label htmlFor="value_period">{t('goals.modal_goal.value_period')}</label>
								<select name="value_period" id="value_period" value={valuePeriod} onChange={(e) => { setValuePeriod(e.target.value) }} disabled={period === 'custom'} style={{ backgroundColor: `${period === 'custom' ? '#EEEEEE' : '#FFFFFF'}` }}>
									{/* <option value="" selected>{t('goals.modal_goal.select')}</option> */}
									{
										period &&

										optionsPeriods
											.filter(option => { return option.type === period })
											.map((option, index) => {
												if (option.type === 'monthly') {
													return <option
														key={option.value}
														value={option.value}
														selected={index === 0}>
														{t(`months.${option.value}`)}
													</option>
												}
												return <option
													key={option.value}
													value={option.value}
													selected={index === 0}>
													{option.value}
												</option>
											}

											)
									}
								</select>
							</div>
						</div>
					</div>


					<div>
						<div className="row">
							<div className="col">
								<label htmlFor="date_start">{t('goals.drawer_filter.start')}</label>

								{/* <input className="w-auto" type="date" id="date_start" name="date_start" value={form.date_start || ''} onChange={handleChange} disabled={goalOptions?.length > 0 ? true : false} /> */}

								<BefectiveDatePicker
									userDetails={userDetails}
									date_value={form.date_start || ''}
									formEdit={form}
									setFormEdit={setForm}
									setSubmitted={setSubmitted}
									field="date_start"
									minDate={""}
									maxDate={""}
									className="goalInput"
									disabledValue={goalOptions?.length > 0 ? "disabled" : ""}
								/>
							</div>
						</div>
					</div>

					<div>
						<div className="row">
							<div className="col">
								<label htmlFor="date_end">{t('goals.drawer_filter.end')}</label>
								{/* <input className="w-auto" type="date" id="date_start" name="date_end" value={form.date_end || ''} onChange={handleChange} disabled={goalOptions?.length > 0 ? true : false} /> */}
								<BefectiveDatePicker
									userDetails={userDetails}
									date_value={form.date_end || ''}
									formEdit={form}
									setFormEdit={setForm}
									setSubmitted={setSubmitted}
									field="date_end"
									minDate={""}
									maxDate={""}
									className="goalInput"
									disabledValue={goalOptions?.length > 0 ? "disabled" : ""}
								/>
							</div>
						</div>
					</div>

					{/* <div>
						<div className="row">
							<div className="col">
								<ReactDatePicker

									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
								/>
							</div>
						</div>
					</div> */}

					<div>
						<div className="row" style={{ marginBottom: '8px' }}>

							<div className="col">
								<button
									className='search-button'
									onClick={handleFilter}
									disabled={isSubmitting}
								>
									{isSubmitting ? (
										<CircularProgress size={14} />
									) : t('goals.drawer_filter.filter')}
								</button>
							</div>
						</div>
					</div>

					<div>
						<div className="row">

							<div className="col">
								<button
									className='search-button-clean'
									onClick={handleClear}
									type="button"
								>
									{t('goals.drawer_filter.clean')}
								</button>
							</div>
						</div>
					</div>

				</div>

			</main>
		</div>

	)
}

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		created: state.teams.created
	}

}


function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch)
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerFiltersGoals)



