import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(param) {

    let message
    
    if(param.Message){
        message = param.Message;
    }
    else if(!param.Message && param){
        message = param;
    }
    else{
        message = "Erro ao contactar servidor!"
    }

    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}