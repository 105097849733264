import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './TeamsTable.module.scss'; // Importe seu arquivo de estilos
import { Pagination } from '@material-ui/lab';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp } from 'phosphor-react';
import { history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import IconConfig from '../_icons/IconConfig';
import IconArrow from '../_icons/IconArrow';
import IconArrowRight from '../_icons/IconArrowRight';

const TeamsTable = ({ columns, data, selectedRows, setSelectedRows, count, getTeamsData, pageCountRows, userDetails }) => {

  const { t } = useTranslation('common');

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );


  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, sortOrder, globalFilter }
  } = tableInstance;

  const toggleAllRowsSelection = (e) => {
    const { checked } = e.target
    const allRows = page.map((row) => row.original.id);
    if (checked) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([])
    }

  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevState) =>
      prevState.includes(id) ? prevState.filter((rowId) => rowId !== id) : [...prevState, id]
    );
  };

  const fetchData = async ({ pageIndex, pageSize, sortBy, sortOrder, globalFilter }) => {
    try {
      // console.log(pageIndex, pageSize, sortBy, sortOrder, globalFilter)
      getTeamsData({ pageIndex, pageSize, isLoading: false })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, sortOrder, globalFilter });
  }, [pageIndex, pageSize]);


  return (
    <div className={styles.tableContainer}>

      {/* <div className={styles.search}>
        <input
          type="text"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </div> */}

      <table {...getTableProps()}>
        <thead>

          <tr>

            {
              userDetails.allowed_update && (
                <th style={{ width: '10px' }} className={styles.headColTable}>
                  <label className={styles.customCheckbox}>
                    <input type="checkbox" onChange={toggleAllRowsSelection} />
                    <span className={`${styles.checkmark} ${styles.checkmarkGreen}`}></span>
                  </label>
                </th>
              )
            }

            {headerGroups.map((headerGroup) => (
              <React.Fragment key={`head-table-${headerGroup.id}`}>
                {headerGroup.headers.map((column, i2) => {
                  switch (column.id) {
                    case 'total_colabs':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`col-team-${column.id}-${i2}`} className={styles.headColTable} title={t('title_sort')} style={{ textAlign: 'center', width: '150px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    case 'status':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`col-team-${column.id}-${i2}`} className={styles.headColTable} title={t('title_sort')} style={{ textAlign: 'center', width: '100px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    default:
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`col-team-${column.id}-${i2}`} className={styles.headColTable} title={t('title_sort')}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                  }

                })}
              </React.Fragment>
            ))}
            {
              (userDetails.allowed_update || userDetails.allowed_read) && ( 
                <th style={{ width: '0px' }} className={styles.headColTable} />
              )
            }
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i1) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={`${userDetails.allowed_read ? styles.rowHover : ''}`} key={`row-team-${row.original.id}`}>
                {
                  userDetails.allowed_update && (
                    <td>
                      <label className={styles.customCheckbox}>
                        <input type="checkbox"
                          onChange={() => toggleRowSelection(row.original.id)}
                          checked={selectedRows.includes(row.original.id)}
                        />
                        <span className={styles.checkmark}></span>
                      </label>
                    </td>
                  )
                }
                {row.cells.map((cell, i2) => {
                  switch (cell.column.id) {
                    case 'status':
                      return (
                        <td {...cell.getCellProps()} className={row.original.status ? styles.statusActive : styles.statusInactive} key={`cell-${cell.column.id}-${i2}-${i1}`}>{cell.render("Cell")}</td>
                      );
                    default:
                      return (
                        <td {...cell.getCellProps()} key={`cell-${cell.column.id}-${i2}-${i1}`} onClick={() => userDetails.allowed_read && history.push(`/settings/teams/${row.original.id}`)} style={{ textAlign: `${['total_colabs'].includes(cell.column.id) ? 'center' : 'left'}` }}>{cell.render("Cell")}</td>
                      );
                  }
                })}
                {
                  (userDetails.allowed_update || userDetails.allowed_read) && ( 
                    <td>
                      <div className={styles.boxActions}>
                        {
                          userDetails.allowed_update && (
                            <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={(e) => history.push(`/settings/teams/${row.original.id}/configuration?to_back=teams`)}>
                              <IconConfig color={"#187DFF"} />
                            </button>
                          )
                        }
                         {
                          userDetails.allowed_read && (
                            <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={() => history.push(`/settings/teams/${row.original.id}`)}>
                              <IconArrowRight />
                            </button>
                          )
                        }
                      </div>
                    </td>
                  )
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.containerPagination}>
        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, page) => {
            gotoPage(page - 1)
          }}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem('pageSizeStorage', Number(e.target.value));
          }}
          className={styles.selectOptions}
        >
          {myPageOptions.map((pageSize) => (
            <option key={`page-${pageSize.value}`} value={pageSize.value}>
              {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TeamsTable;
