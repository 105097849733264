import { Box, Card, CardContent, Divider, Grid, Paper, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, Clock, ClockClockwise, ClockCounterClockwise, Cpu, Disc, HourglassLow, Scales, StackSimple, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ResumeColab({ alertActions, userDetails, resume }) {

  const { t, i18n } = useTranslation('common');


  return (

    <Box sx={{ flexGrow: 1, padding: '12px' }} component={Paper} elevation={0}>

      <Grid container spacing={2} marginBottom={'12px'}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#1E6B7F' }} marginLeft={'4px'} variant='h6'>{resume.username || '----'}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box component={'div'} display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.team')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.group_name || '----'}</Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.os')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>
                {
                  resume.os_system_mac !== null
                    ?
                    resume.os_system_mac
                    :
                    resume.operating_system
                }
              </Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.manufacturer')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.manufacturer || '----'}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box component={'div'} display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.ip_local')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.address_local || '----'}</Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.ip_public')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.address_public || '----'}</Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.mac_address')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.mac_address || '----'}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box component={'div'} display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.size_screen')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{resume.size_screen || '----'}</Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.ram_size')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{Math.round(Number(resume.total_ram_mb / 1024)) + "GB" || '----'}</Typography>
            </Box>
            <Box component={'div'} borderLeft={'2px solid #E4EAEF'} paddingLeft={'10px'}>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle2'>{t('inventory.colabs.details.disk_size')}</Typography>
              <Typography sx={{ color: '#1E6B7F' }} variant='subtitle1'>{Math.round(Number(resume.total_disk_mb / 1024)) + "GB" || '----'}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
}
