import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { useTranslation } from "react-i18next";
import Moment from 'moment';

import { activitiesActions, statsActions } from '../_actions';

import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import Filterbar from "../_components/_shared/Filterbar";

import ActivitiesFullSkeleton from "../_components/productivity/ActivitiesFullSkeleton";
import ActivitiesFull from "../_components/productivity/ActivitiesFull";
import ToolbarMu from '../_components/_shared/ToolbarMu';
import Toolbar from '../_components/_shared/Toolbar';

function MyProductivityPageActivities(props) {
	const { t } = useTranslation('common');
	registerLocale('es', es)
	registerLocale('pt', pt)
	const { activtiesActions, statsActions } = props;

	let userLang = navigator.language || navigator.userLanguage;

	if (userLang == 'pt-BR') {
		userLang = 'pt';
	}

	let loading = props.loading;
	let loadingStats = props.loadingStats;

	const isMountedDate = useRef(false);

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
	const [whoName, setWhoName] = useState('');

	let date = new Date();
	let startDate = new Date(Moment().add(-7, 'd')).toISOString().split('T')[0];
	let endDate = date.toISOString().split('T')[0];
	const maxDate = new Date(Moment());

	function getActivities(date) {
		statsActions.getProductivity(date);
		activtiesActions.getProductivityActivities(date);
	}

	useEffect(() => {
		let date = Moment(new Date()).format('YYYY-MM-DD');

		if (props.filter.singleDate) {
			date = props.filter.singleDate;
		}

		getActivities(date);
	}, [])

	useEffect(() => {
		if (props.productivity && props.productivity.user) {
			setWhoName(props.productivity.user.name)
		}
	}, [props.productivity])

	useEffect(() => {
		if (isMountedDate.current) {
			if (props.filter.singleDate) {
				getActivities(props.filter.singleDate)
			}
		} else {
			isMountedDate.current = true;
		}
	}, [props.filter.singleDate]);


	useEffect(() => {
		document.title = 'Befective | ' + t('home.my_productivity') + ' | ' + t('home.activities');
	});


	return (
		<React.Fragment>

			<section className="mainContent productivity">

				<Toolbar
					breadcrumb={t('breadcrub.home') + " " + whoName}
				/>

				<Filterbar defaultStartDate={startDate}
					defaultEndDate={endDate}
					filterWho={'disabled'}
					filterWhen={'single'}
					filterWhat={'disabled'}
					maxDate={maxDate}
					language={userLang}
				/>

				{/* PAGE STARTS */}
				<div className="mainPage">
					<div className="column single">
						{
							!minimumTimeElapsed || loading || loadingStats
								? <ActivitiesFullSkeleton />
								: <ActivitiesFull />
						}
					</div>

				</div>
				{/* PAGE ENDS */}
			</section>
		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		activtiesActions: bindActionCreators(activitiesActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		loading: state.activities.loading,
		loadingStats: state.stats.loading,
		filter: state.filter,
		activities: state.activities.rows,
		productivity: state.stats.payload,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(MyProductivityPageActivities)
