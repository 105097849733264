import React from 'react';

const IconRoomCalendar = props => {
    return (

        <svg id="reservas" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1584" data-name="Retângulo 1584" width="16" height="16" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 0.659)">
                <path id="Caminho_1451" data-name="Caminho 1451" d="M16,3.985H13v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985H7v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985h-3A.667.667,0,0,0,2,4.652V15.318a.667.667,0,0,0,.667.667H16a.667.667,0,0,0,.667-.667V4.652A.667.667,0,0,0,16,3.985Zm-.667,10.667h-12V7.985h12Zm0-8h-12V5.318h12Z" transform="translate(-2 -1.977)" fill="#187dff" />
                <rect id="Retângulo_1585" data-name="Retângulo 1585" width="2.667" height="2.667" rx="1.333" transform="translate(2 7.341)" fill="#187dff" />
                <rect id="Retângulo_1586" data-name="Retângulo 1586" width="2.667" height="2.667" rx="1.333" transform="translate(10 7.341)" fill="#187dff" />
                <rect id="Retângulo_1587" data-name="Retângulo 1587" width="2.667" height="2.667" rx="1.333" transform="translate(6 7.341)" fill="#187dff" />
            </g>
        </svg>

    )
}

export default IconRoomCalendar;