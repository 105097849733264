import React, {useRef, useState, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";
import {bindActionCreators} from 'redux'
import Moment from 'moment-timezone';
import {useTable, useSortBy} from 'react-table'
import {useHistory} from "react-router-dom";

import IconTeammate from '../_icons/IconTeammate';
import IconTeam from '../_icons/IconTeam';
import IconInfo from '../_icons/IconInfo';
import IconCalendar from '../_icons/IconCalendar2';
import IconOrganization from '../_icons/IconOrganization2';
import IconRemove2 from '../_icons/IconRemove2';
import IconEdit from '../_icons/IconEdit';

import {periodicActions} from '../../_actions';


function PeriodicReports(props) {
    const {t, i18n} = useTranslation('common');


    const {periodicActions} = props;

    const history = useHistory();
    let periodic = props.periodic || [];
    let periodicReports = props.periodicReports || [];


    Moment.locale(t('date.locale'));
    const data = useMemo(() => periodicReports, []);

    let TotalDaily = periodic.TotalDaily || 0;
    let TotalWeekly = periodic.TotalWeekly || 0;
    let TotalMonthly = periodic.TotalMonthly || 0;
    let TotalOrganization = periodic.TotalOrganization || 0;
    let TotalGroup = periodic.TotalGroup || 0;
    let TotalMachineUser = periodic.TotalMachineUser || 0;

    const columns = React.useMemo(
        () => [
            {
                Header: t('periodic.name'),
                accessor: 'name',
            },
            {
                Header: t('periodic.frequency'),
                accessor: (d) => {
                    let frequency = 0;
                    if (d.frequency == 'weekly') {
                        frequency = 1
                    }
                    if (d.frequency == 'monthly') {
                        frequency = 2
                    }

                    return frequency;
                },
                Cell: (d) => {

                    return t('periodic.' + d.row.original.frequency)

                }
            },
            {
                Header: t('periodic.type'),
                accessor: 'report_name',
                Cell: (d) => {

                    return t('periodic.' + d.value)

                }
            },
            {
                Header: t('periodic.target'),
                accessor: 'filter.for_type',
                Cell: (d) => {

                    return t('periodic.' + d.value)

                }
            },
            {
                Header: t('periodic.status'),
                accessor: (d) => {
                    let active = 0;
                    if (d.active) {
                        active = 1
                    }

                    return active;
                },
                Cell: (d) => {
                    let active = '';


                    if (d.row.original.active) {
                        active = 'active'
                    }


                    return <div className={'actions'}>
                        <div className={"toggle " + active} id={"toggle-" + d.row.original.id} onClick={() => {
                            togglePeriodic(d.row.original.id)
                        }}><span></span></div>
                        <button type={'button'} style={{display: 'none'}} className={'edit'} onClick={(e) => {
                            editPeriodic(e, d.row.original.id)
                        }}>
                            <IconEdit/>
                        </button>
                        <button type={'button'} className={'remove'} onClick={() => {
                            toggleRemoveDialog(d.row.original.id)
                        }}>
                            <IconRemove2/>
                        </button>
                        <div className="removeDialog" id={"remove-" + d.row.original.id}>
                            <div className="content">
                                <div className="message"><IconInfo/>{t('settings.administrator.you_are_sure')}</div>
                                <div className="options">
                                    <button onClick={() => {
                                        toggleRemoveDialog()
                                    }}>{t('no')}</button>
                                    <button onClick={() => {
                                        deletePeriodic(d.row.original.id)
                                    }}>{t('yes')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            }

        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        disableMultiSort: true,

    }, useSortBy);

    function toggleRemoveDialog(id) {

        let elems = document.querySelectorAll(".removeDialog.open")

        if (elems.length > 0) {
            [].forEach.call(elems, function (el) {
                el.classList.remove("open");
            });
        }

        let elem = document.getElementById("remove-" + id)

        if (elem) {
            elem.classList.toggle("open")
        }

    }

    function togglePeriodic(id) {
        let elem = document.getElementById("toggle-" + id);

        if (elem.classList.contains('active')) {
            //DESATIVAR

            periodicActions.deactivatePeriodic(id)

        } else {
            //ATIVAR
            periodicActions.activatePeriodic(id)

        }

        elem.classList.toggle("active");


    }

    function deletePeriodic(id) {
        periodicActions.deletePeriodic(id)
        toggleRemoveDialog(id)
        document.getElementById("periodic-" + id).remove()

    }

    function editPeriodic(e, id) {
        if (e.target.role == 'cell' || e.target.classList.contains('edit')) {
            history.push("/productive/reports/periodic/" + id + "/edit")
        }

    }

    return (
        <div className="box teammates noBg">
            <div className="pageHeader compact realtime">
                {/* <h3>
                    {t('periodic.title')} <span><IconInfo/>
                    <div className="tooltip">{t('realtime.tooltip')}</div></span>
                </h3> */}
                <div className="internalFilters">

                    <div className="filter active day">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCalendar/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalMonthly}</div>
                        <div className="name">{t('periodic.monthly')}</div>
                    </div>

                    <div className="filter active day">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCalendar/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalWeekly}</div>
                        <div className="name">{t('periodic.weekly')}</div>
                    </div>

                    <div className="filter active day">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCalendar/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalDaily}</div>
                        <div className="name">{t('periodic.daily')}</div>
                    </div>

                    <div className="filter active time">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOrganization/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalOrganization}</div>
                        <div className="name">{t('periodic.organization')}</div>
                    </div>

                    <div className="filter active time">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalGroup}</div>
                        <div className="name">{t('periodic.teams')}</div>
                    </div>

                    <div className="filter active time">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeammate/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalMachineUser}</div>
                        <div className="name">{t('periodic.teammates')}</div>
                    </div>

                </div>


            </div>
            <div className="dataTable periodic">
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <div className="arrowDown"></div>
                                                        : <div className="arrowUp"></div>
                                                    : ''}
                                            </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, i) => {

                                prepareRow(row);

                                return (
                                    <tr id={'periodic-' + row.original.id} {...row.getRowProps()} className={'editable'}
                                        onClick={(e) => {
                                            editPeriodic(e, row.original.id)
                                        }}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                }
                {data.length == 0 &&
                    <p className={'noData'}>{t('no_data')}</p>
                }
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        periodicActions: bindActionCreators(periodicActions, dispatch)
    }
}

function mapStateToProps(state) {

    return {
        periodicReports: state.periodic.reports,
        periodic: state.periodic,
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicReports)
