import React from 'react';

const IconWeek = props => {
  return (

    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
     
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.333 0.667)">
        <path id="Caminho_77" data-name="Caminho 77" d="M16.534,23.6,15.2,22.3a.7.7,0,0,1-.067-.9.633.633,0,0,1,1-.067L17,22.2l2.867-2.867a.667.667,0,0,1,.933.933L17.467,23.6a.633.633,0,0,1-.933,0Z" transform="translate(-11.334 -13.467)" fill="#98acc1" />
        <path id="Caminho_78" data-name="Caminho 78" d="M11.333,9.333a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-1.333a6.333,6.333,0,1,0,6.333,6.333A6.333,6.333,0,0,0,11.333,8Z" transform="translate(-4.667 -6)" fill="#98acc1" />
        <path id="Caminho_79" data-name="Caminho 79" d="M4.667,5.333a.7.7,0,0,1-.533-.267.667.667,0,0,1,.133-.933l2.667-2a.667.667,0,0,1,.8,1.067l-2.667,2A.633.633,0,0,1,4.667,5.333Z" transform="translate(-4 -2)" fill="#98acc1" />
        <path id="Caminho_80" data-name="Caminho 80" d="M35.333,5.333a.633.633,0,0,1-.4-.133l-2.667-2a.667.667,0,0,1,.8-1.067l2.667,2a.667.667,0,0,1,.133.933.7.7,0,0,1-.533.267Z" transform="translate(-22.667 -2)" fill="#98acc1" />
      </g>
    </svg>



  )
}

export default IconWeek;