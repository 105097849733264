import React from 'react';

const IconPassword = props => {
    return (
        
     
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

        <g id="Grupo_38" data-name="Grupo 38">
          <path id="Caminho_43" data-name="Caminho 43" d="M23,10H21V7.5A5.5,5.5,0,0,0,15.5,2h-1A5.5,5.5,0,0,0,9,7.5V10H7a1,1,0,0,0-1,1V23a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V11A1,1,0,0,0,23,10ZM11,7.5A3.5,3.5,0,0,1,14.5,4h1A3.5,3.5,0,0,1,19,7.5V10H11ZM22,22H8V12H22Z" transform="translate(-6 -2)" fill="#97b3bf"/>
          <path id="Caminho_44" data-name="Caminho 44" d="M23.9,27.918a2,2,0,1,0-3,1.75v1.25a1,1,0,0,0,2,0v-1.25A2,2,0,0,0,23.9,27.918Z" transform="translate(-12.95 -13.968)" fill="#97b3bf"/>
        </g>
      
    </svg>
    
      


 

    )
}

export default IconPassword;