import { CheckCircle, Info, MagnifyingGlass } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import './styles.scss'

export function HeaderCallsPage({ total_open = 0, total_solved = 0, handleFilterCalls }) {
  const { t, i18n } = useTranslation('common');
  const [titleSearch, setTitleSearch] = useState('')

  function handleSetTitleSearch(title){
    if(title === '') {
      handleFilterCalls(title)
    }
    setTitleSearch(title)
  }

  return (
    <header className="header-container-calls-page">
      <div className="container-texts-calls-page">
        <h2>{t('calls_page.calls')}</h2>

        <div>
          <div className="info-header-calls-page">
            <span>
              <Info size={12} weight="bold" />
              {t('calls_page.open')}:
            </span>
            <strong>{total_open}</strong>
          </div>
          <div className="info-header-calls-page">
            <span>
              <CheckCircle size={12} weight="bold" />
              {t('calls_page.solved')}:
            </span>
            <strong>{total_solved}</strong>
          </div>
        </div>
      </div>

      <div className="container-search-calls-page">
        <input 
          type="text" 
          placeholder={t('calls_page.placeholder_search')} 
          value={titleSearch}
          onChange={e => handleSetTitleSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleFilterCalls(titleSearch)
          }}
        />
        <MagnifyingGlass 
          size={16} 
          weight="light" 
          onClick={() => handleFilterCalls(titleSearch)}
        />
      </div>
    </header>
  )
}
