import React, { useEffect, useRef, useState } from 'react'
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography, Box, Divider, Tooltip, Popover } from '@material-ui/core';
import { ArrowDropDown, PersonAdd, Settings } from '@material-ui/icons';
import style from './WorkspacesFilter.module.scss';
import { history, limitedText } from '../../../_helpers';
import { useTranslation } from 'react-i18next';

export default function WorkspacesFilter({
  myWorkspaces = [],
  workspacesInvited = [],
  showCurrentWorkspace,
  getWorkspaceData
}) {

  const { t, i18n } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    
    if(myWorkspaces.length <= 0 ) return

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectWorkspace = (idWorkspace) => {
    history.push(`/project_management/home/workspace/${idWorkspace}`)
    handleClose()
  }

  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        className={style.btnFilterWorkspace}
      >
        <Box display={'flex'} width={'100%'} justifyContent={'space-evenly'}>
          <Box component={'span'} style={{ textTransform: 'initial' }} color={'#106276'} fontWeight={400}>
            {t("project_management.workspaces.workspaces")}
          </Box>
          <ArrowDropDown style={{ fill: '#106276' }} />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper style={{backgroundColor: '#24677e', minWidth:'200px', maxWidth:'280px'}}>
          <Box>
            {
              showCurrentWorkspace &&
              <Box>
                <Typography className={style.itemText}>
                  {t("project_management.workspaces.current_workspace")}
                </Typography>
                <MenuList>
                  <MenuItem onClick={handleClose}>Workspace 001</MenuItem>
                </MenuList>
              </Box>
            }
            {
              showCurrentWorkspace && <Divider />
            }

            <Box>
              <Typography className={style.itemTextTitle}>
                {t("project_management.workspaces.my_workspaces")}
              </Typography>
              <MenuList>
                {
                  myWorkspaces?.map(item => (
                    item.title.length > 40
                      ?
                      <Tooltip
                        disableInteractive
                        title={item.title}
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                      >
                        <MenuItem key={item.title} onClick={() => redirectWorkspace(item.id)}>
                          <Typography variant='subtitle2' className={style.itemText}>{limitedText(item.title, 40)}</Typography>
                        </MenuItem>
                      </Tooltip>
                      :
                      <MenuItem key={item.title} onClick={() => redirectWorkspace(item.id)}>
                        <Typography variant='subtitle2' className={style.itemText}>{item.title}</Typography>
                      </MenuItem>
                  ))
                }
              </MenuList>
            </Box>
            {workspacesInvited.length > 0 && <Divider />}
            {workspacesInvited.length > 0 &&
              <Box>
                <Typography className={style.itemTextTitle}>
                  {t("project_management.workspaces.shared_workspaces")}
                </Typography>
                <MenuList>
                  {
                    workspacesInvited?.map(item => (
                      item.title.length > 40
                        ?
                        <Tooltip
                          disableInteractive
                          title={item.title}
                          placement="bottom"
                          style={{ maxWidth: '300px' }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -10],
                                },
                              },
                            ],
                          }}
                        >
                          <MenuItem key={item.title} onClick={() => redirectWorkspace(item.id)}>
                            <Typography variant='subtitle2' className={style.itemText}>{limitedText(item.title, 40)}</Typography>
                          </MenuItem>
                        </Tooltip>
                        :
                        <MenuItem key={item.title} onClick={() => redirectWorkspace(item.id)}>
                          <Typography variant='subtitle2' className={style.itemText}>{item.title}</Typography>
                        </MenuItem>
                    ))
                  }
                </MenuList>
              </Box>
            }
          </Box>
        </Paper>
      </Popover>
    </div>
  )
}
