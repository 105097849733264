import React from 'react';

const IconTeammateDetail = props => {
    return (
        
      <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 8.25 9.625">
      <path id="Caminho_88" data-name="Caminho 88" d="M10.125,3a2.521,2.521,0,1,0,2.521,2.521A2.521,2.521,0,0,0,10.125,3Zm0,4.125a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,10.125,7.125ZM14.044,10.1a7.173,7.173,0,0,0-3.919-1.146,7.356,7.356,0,0,0-3.919,1.123L6,10.219v1.948a.458.458,0,0,0,.458.458h7.333a.458.458,0,0,0,.458-.458V10.219Zm-.71,1.6H6.917v-.985a6.508,6.508,0,0,1,3.208-.848,6.3,6.3,0,0,1,3.208.871Z" transform="translate(-6 -3)" fill="#22bead"/>
    </svg>
    

 

    )
}

export default IconTeammateDetail;