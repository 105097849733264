import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableCollapsible.scss';
import IconExpand from '../../_icons/IconExpand';
import { befectiveFormatDate } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

function Row(props) {
  const { row, index, openAll } = props;
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation('common');

  React.useEffect(() => {
    setOpen(openAll)
  }, [openAll])

  return (
    <React.Fragment>
      <TableRow
        className={`
        hover
        ${open ? 'table-row-goal-focus' : 'row-objective'}
      `}
      >
        <TableCell>
          {row.username}
        </TableCell>
        <TableCell align="center">{row.total_overtime}</TableCell>
        <TableCell align="center">{row.total_missing}</TableCell>
        <TableCell align="center" style={{color: String(row.total_balance) !== '0' ? String(row.total_balance).includes('-') ? '#FC6662' : '#22BEAD' : '#4f7091'}}>{row.total_balance}</TableCell>
        <TableCell>
          <IconButton
            className={`button-expand ${open ? 'rotate' : ''}`}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <IconExpand /> : <IconExpand />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow className={`${open ? 'table-body-focus ' : ''}`}>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size="small" aria-label="purchases" >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#98ACC1', fontSize: 11 }}>{t("checkin.report_overtime.col_date")}</TableCell>
                    <TableCell width={'22%'} style={{ color: '#98ACC1', fontSize: 11 }} align='center'>{t("checkin.report_overtime.col_overtime")}</TableCell>
                    <TableCell width={'25%'} style={{ color: '#98ACC1', fontSize: 11 }} align='center'>{t("checkin.report_overtime.col_missing")}</TableCell>
                    <TableCell width={'15%'} style={{ color: '#98ACC1', fontSize: 11 }} align='center'>{t("checkin.report_overtime.col_balance")}</TableCell>
                    <TableCell width={'3%'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.points_processed.length > 0 && row.points_processed.map((historyRow) => (
                    <TableRow key={historyRow.day_ref}>
                      <TableCell component="th" scope="row">
                        {befectiveFormatDate(historyRow.day_ref, i18n.language)}
                      </TableCell>
                      <TableCell align="center">{historyRow.overtime}</TableCell>
                      <TableCell align="center">{historyRow.missing}</TableCell>
                      <TableCell align="center">{historyRow.balance}</TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {
        open && <TableRow> <TableCell style={{ padding: 0, height: 3 }} colSpan={5}></TableCell></TableRow>
      }
    </React.Fragment>
  );
}

export default function TableCollapsible({
  data = [],
  orderColumn = () => { },
  orderedColumn,
  order
}) {
  const [openAll, setOpenAll] = React.useState(false)
  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableCollapsibleOvertime' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid #F5F6F9', textTransform: 'uppercase' }}>
            <TableCell onClick={() => orderColumn('username', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')}>
              {t("checkin.report_overtime.col_colab")}
              {orderedColumn === 'username' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('total_overtime', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_overtime.col_overtime_s")}
              {orderedColumn === 'total_overtime' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('total_missing', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_overtime.col_missing_s")}
              {orderedColumn === 'total_missing' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('total_balance', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_overtime.col_balance")}
              {orderedColumn === 'total_balance' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell style={{ width: '0%', minWidth: '0%', textAlign: 'end' }}>
              <div>
                <IconButton
                  className={`button-expand ${openAll ? 'rotate' : ''}`}
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenAll(!openAll)}
                >
                  {openAll ? <IconExpand /> : <IconExpand />}
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ?
            data.map((row, index) => (
              <Row index={index} key={row.name} row={row} openAll={openAll} />
            ))
            :
            <TableRow>
              <TableCell colSpan={5}>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
