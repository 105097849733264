import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import IconInfo from "../../_icons/IconInfo";
import IconClose from "../../_icons/IconClose";

import { api } from "../../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../../_actions";
import { authHeader } from "../../../_helpers";

import "./styles.scss"
import moment from "moment";
import { AttachFile, Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalNewMessage({
  openModalNewMessage,
  setOpenModalNewMessage,
  userDetails,
  teammates=[],
  alertActions,
  updateInfo,
  typeUser = "U"
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  // const token = authHeader()

  const [destinations, setDestinations] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    subject: '',
    message: '',
    destinations: [],
    attachments: []
  });
  const [formError, setFormError] = useState({
    subject: '',
    message: '',
    destinations: '',
    attachments: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function closeModalNewMessage() {
    setOpenModalNewMessage(false)
    setForm({
      subject: '',
      message: '',
      destinations: '',
      attachments: ''
    })
    setFormError({
      subject: '',
      message: '',
      destinations: '',
      attachments: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.subject) {
      errors.subject = t('messages.modal_new_message.required_field')
    } else if (form.subject.length < 8) {
      errors.subject = t('messages.modal_new_message.len_field_error_8')
    }

    if (!form.message) {
      errors.message = t('messages.modal_new_message.required_field')
    } else if (form.message.length < 8) {
      errors.message = t('messages.modal_new_message.len_field_error_8')
    }

    if (form.destinations.length === 0) {
      errors.destinations = t('messages.modal_new_message.required_field')
    }

    if (form.attachments.length > 0) {
      form.attachments.forEach(attachment => {
        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP']

        if ((attachment.size / 1000000) > 2) {
          errors.attachments = t('calls_page.error.file')
        }

        const fileNameSplitted = attachment.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          errors.attachments = t('calls_page.error.file_type')
        }
      })
    }

    return errors
  }


  const onChangeInputAttachments = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);
    setForm({ ...form, attachments: [...form.attachments, ...filesArr] });
    setFormError({ ...formError, attachments: '' })
  }

  const removeAttachment = (attachment) => {
    setForm({ ...form, attachments: form.attachments.filter(f => f !== attachment) });
  }

  const handleSubmitMessage = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createMessage = async () => {
    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('owner_id', userDetails.mu_id);
      formData.append('destination_id', JSON.stringify(form?.destinations));
      formData.append('subject', form.subject);
      formData.append('message_body', form.message);

      for(let item of form.attachments) {
        formData.append('files', item)
      }

      const res = await api.post(
        `/messages/create?organization_id=${userDetails?.organizationId}`,
        formData,
        {
          headers: {
            // 'Authorization': token.Authorization,
            'Content-Type': 'multipart/form-data',
          }
        }
      )
      if(res.data){
        updateInfo()
        alertActions.success(t('messages.alerts.success_create_message'))
        closeModalNewMessage()
        setIsSubmitting(false)
      }
     
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('messages.alerts.error_create_message'))
    }
  }


  useEffect(() => {
      handleChange({ target: { name: 'destinations', value: destinations.map(dest => dest.value) } })
  }, [destinations])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createMessage()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalForm ${classes.modal}`}
        open={openModalNewMessage}
        onClose={closeModalNewMessage}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalNewMessage} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormAddMessages"}>

            <button onClick={() => { closeModalNewMessage() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmitMessage}>
              <main>
                <header>
                  <h3>
                    {t('messages.modal_new_message.title')}
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="subject">{t('messages.modal_new_message.subject')}*</label>
                  <input
                    id="subject"
                    type="text"
                    name="subject"
                    minLength={8}
                    maxLength={100}
                    placeholder={t('messages.modal_new_message.subject_placeholder')}
                    value={form.subject || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.subject.length}/100</span>
                  {formError.subject &&
                    <div className="error">{formError.subject}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="message">{t('messages.modal_new_message.message')}*</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    minLength={8}
                    maxLength={600}
                    placeholder={t('messages.modal_new_message.message_placeholder')}
                    value={form.message || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.message.length}/600</span>
                  {formError.message &&
                    <div className="error">{formError.message}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="destinations">{t('messages.modal_new_message.destinations')}*</label>
                  <Select
                    id='destinations'
                    isMulti
                    defaultValue={form.destinations || ''}
                    name="destinations"
                    options={teammates}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('messages.modal_new_message.destinations_placeholder')}
                    onChange={setDestinations}
                  />

                  {formError.destinations &&
                    <div className="error">{formError.destinations}</div>
                  }
                </div>
                <div className="field">
                  <label htmlFor="destinations">{t('messages.modal_new_message.attachments')}</label>
                  <div className='boxInputFile' style={{
                    fontFamily: 'sans-serif',
                    textAlign: 'left',
                    display: 'flex',
                  }} >
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        multiple
                        onChange={onChangeInputAttachments}
                        accept="image/*"
                      />
                      <AttachFile fontSize='small' />
                      <div>
                        {t('messages.modal_new_message.attachments_placeholder')}
                      </div>
                    </label>

                    <p className='attachment-info'>
                      {t('messages.modal_new_message.attach_info')}*
                    </p>

                    {formError.attachments &&
                      <div className="error">{formError.attachments}</div>
                    }

                    {form.attachments.length > 0 && form.attachments.map((attachment, index) => (
                      <div key={index} className="file-preview">
                        <div>{attachment.name}</div>
                        <div>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => { removeAttachment(attachment) }}
                          >
                            <Close fontSize='small' />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>


                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalNewMessage() }}
                    >
                      {t('messages.modal_new_message.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('messages.modal_new_message.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalNewMessage));
