import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';

import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import Widget16Skeleton from './Widget16Skeleton';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function Widget16({ width, currentDate, alertActions, filters, userDetails }) {
  const { t, i18n } = useTranslation('common');

  const [data, setData] = useState({
    percent_productive: 0,
    percent_neutral: 0,
    percent_unproductive: 0,
    topSite: [],
    topSoftware: [],
    topOffline: []
  });

  const [fetching, setFetching] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/top-10-activities?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget16!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])

  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('home.activities')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('home.activities_tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
            </div>
            <div className={style.statsBlock}>
              <div className={style.secondaryChart}>
                <div className={style.block}>
                  <div className={style.stats}>
                    <div className={style.labels}>
                      {userDetails.allowed_productive.includes('related') && <div className={`${style.label} ${style.related}`}>
                        {t('home.related_to_work')}: <b> {data.percent_productive}%</b>
                      </div>}
                      {userDetails.allowed_productive.includes('neutral') &&
                        <div className={`${style.label} ${style.neutral}`}>
                          {t('home.neutral')}: <b> {data.percent_neutral}%</b>
                        </div>}
                      {userDetails.allowed_productive.includes('not_related') &&
                        <div className={style.label}>
                          {t('home.not_related_to_work')}: <b> {data.percent_unproductive}%</b>
                        </div>}
                    </div>

                    <div className={style.barchart}>

                      {userDetails.allowed_productive.includes('related') && <div className={style.bar} style={{ width: data.percent_productive + '%' }}></div>}
                      {userDetails.allowed_productive.includes('neutral') && <div className={`${style.bar} ${style.neutral}`} style={{ width: data.percent_neutral + '%' }}></div>}
                      {userDetails.allowed_productive.includes('not_related') && <div className={`${style.bar} ${style.unrelated}`} style={{ width: data.percent_unproductive + '%' }}></div>}

                    </div>
                  </div>
                </div>
              </div>
              <div className={style.displayList}>
                <div className={style.list}>
                  <h3>Top 10 Site</h3>
                  <ul>
                    {
                      data.topSite.length > 0
                        ?
                        data.topSite.map(activity => (
                          <li key={"topSite-" + activity.tracked_id} className={activity.efficience_name}>
                            <div className={style.itemName}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
                <div className={style.list}>
                  <h3>Top 10 Software</h3>
                  <ul>
                    {
                      data.topSoftware.length > 0
                        ?
                        data.topSoftware.map(activity => (
                          <li key={"topSoftware-" + activity.tracked_id} className={activity.efficience_name}>
                            <div className={style.itemName}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
                <div className={style.list}>
                  <h3>Top 10 Offline</h3>
                  <ul>
                    {
                      data.topOffline.length > 0
                        ?
                        data.topOffline.map(activity => (
                          <li key={"topOffline-" + activity.tracked_id} className={activity.efficience_name}>
                            <div className={style.itemName}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Grid>
      :

      <Grid item xs={width}>
        <Widget16Skeleton />
      </Grid>
  )
};