// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "table thead tr .OX00\\+HW0dFbfqpmCOlKe3g\\=\\= {\n  font-size: 10px;\n  color: #4F7091;\n  font-weight: 500;\n  padding: 10px 12px !important;\n  border-bottom: 2px solid #F5F6F9 !important;\n}", "",{"version":3,"sources":["webpack://./src/_components/users_settings/FormCreate.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,6BAAA;EACA,2CAAA;AACF","sourcesContent":["table thead tr .tableHeaderTd {\n  font-size: 10px;\n  color: #4F7091;\n  font-weight: 500;\n  padding: 10px 12px !important;\n  border-bottom: 2px solid #F5F6F9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderTd": "OX00+HW0dFbfqpmCOlKe3g=="
};
export default ___CSS_LOADER_EXPORT___;
