import {filterConstants} from '../_constants';

export const filterActions = {
    set,
    replace,
    clear
};

function set(payload, type) {


    if (type == "when") {

        let setFilters = {startDate: payload.startDate, endDate: payload.endDate};
        return {type: filterConstants.SET_WHEN, setFilters};

    }

    if (type == "what") {

        return {type: filterConstants.SET_WHAT, payload};

    }

    if (type == "teams") {

        let setFilters = [payload];
        return {type: filterConstants.SET_WHO_TEAMS, setFilters};

    }

    if (type == "teammates") {

        let setFilters = [payload];
        return {type: filterConstants.SET_WHO_TEAMMATES, setFilters};

    }

    if (type == "software") {
        return {type: filterConstants.SET_INTERNAL_SOFTWARE, payload};
    }
    if (type == "site") {
        return {type: filterConstants.SET_INTERNAL_SITE, payload};
    }
    if (type == "offline") {
        return {type: filterConstants.SET_INTERNAL_OFFLINE, payload};
    }
    if (type == "productive") {
        return {type: filterConstants.SET_INTERNAL_PRODUCTIVE, payload};
    }
    if (type == "neutral") {
        return {type: filterConstants.SET_INTERNAL_NEUTRAL, payload};
    }
    if (type == "unproductive") {
        return {type: filterConstants.SET_INTERNAL_UNPRODUCTIVE, payload};
    }

}

function replace(payload, type) {
    if (type == "teammates") {

        let setFilters = [payload];
        return {type: filterConstants.REPLACE_WHO_TEAMMATES, setFilters};

    }

    if (type == "singleWhen") {

        let setFilters = [payload];
        return {type: filterConstants.REPLACE_SINGLE_WHEN, setFilters};

    }

}


function clear(type) {
    if (type == "who") {
        return {type: filterConstants.CLEAR_WHO};
    }
    if (type == "when") {
        return {type: filterConstants.CLEAR_WHEN};
    }
    if (type == "what") {
        return {type: filterConstants.CLEAR_WHAT};
    }
    if (type == "teams") {
        return {type: filterConstants.CLEAR_WHO_TEAMS};
    }
    if (type == "teammates") {
        return {type: filterConstants.CLEAR_WHO_TEAMMATES};
    }
}

