import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReactAvatar from 'react-avatar';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import { Backdrop, makeStyles, Modal, Slide, Menu, MenuItem } from "@material-ui/core";
import { NumberFormatBase, NumericFormat } from 'react-number-format';

import MuiSelect from "@material-ui/core/Select";
import { shareActions } from "../../../_actions/share.actions";
import IconClose from "../../_icons/IconClose";
import IconInfo from "../../_icons/IconInfo";
// import ModalAddTeam from "../ModalAddTeam";
import { ModalAddTeamTask } from '../ModalAddTeamTask'
import { authHeader, getStatusClass } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import { alertActions } from "../../../_actions";

import "./styles.scss"
import { formatCurrencyByEnd } from "../../../_helpers/formattValue";

const useStyles = makeStyles((theme) => ({
	modal: {
		"&:focus": {
			outline: 'none'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
}));

function KeyResultModalForm({
	shareActions,
	alertActions,
	openModalNewKr,
	setOpenModalNewKr,
	...props
}) {
	const classes = useStyles();

	const { t } = useTranslation('common');

	const { userDetails } = props || {};
	const { teammates } = props || {};
	const { groups } = props || {};
	const { currentObjective } = props || {};

	const params = useParams()

	const [responsible, setResponsible] = useState('')
	const [openModalAddTeamTask, setOpenModalAddTeamTask] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [tags, setTags] = useState([]);
	const [assistants, setAssistants] = useState([])
	const [isIntChecked, setIsIntChecked] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [valueStart, setValueStart] = useState(null)
	const [valueEnd, setValueEnd] = useState(null)
	const [form, setForm] = useState({
		title: '',
		responsable: '',
		type: 'value',
		valueStart: 0,
		valueEnd: 0,
		startPeriod: currentObjective?.date_start,
		endPeriod: currentObjective?.date_end,
		tags: [],
		weight: 1
	});
	const [formError, setFormError] = useState({
		title: '',
		responsable: '',
		type: '',
		valueStart: '',
		valueEnd: '',
		startPeriod: '',
		endPeriod: '',
		tags: '',
		weight: ''
	});

	const token = authHeader()

	const validateForm = (form) => {
		const errors = {};

		if (!form.title) {
			errors.title = t('goals.errors.required')
		}
		// else if (form.title.length < 10) {
		// 	errors.title = t('goals.errors.title_goal')
		// }

		if (!form.responsable) {
			errors.responsable = t('goals.errors.required')
		}

		if (!form.type) {
			errors.type = t('goals.errors.required')
		}

		if (form.type === 'value' && !Number(form.valueEnd)) {
			errors.valueEnd = t('goals.errors.required')
		}

		if (form.type === 'value' && Number(form.valueEnd) <= 0) {
			errors.valueEnd = t('goals.errors.non_zero')
		}

		if (form.type === 'value' && Number(form.valueEnd) < Number(form.valueStart)) {
			errors.valueEnd = t('goals.errors.greater_than')
		}

		// if (!form.startPeriod) {
		// 	errors.startPeriod = 'Campo obrigatório'
		// }

		// if (!form.endPeriod) {
		// 	errors.endPeriod = 'Campo obrigatório'
		// }

		return errors
	}

	const handleChange = e => {
		if (e.target.name === 'valueStart' && (e.target.value === "" || e.target.value < 0)) {
			setForm({
				...form,
				[e.target.name]: 0,
			})

			return
		}


		if (e.target.name === 'valueEnd' && (e.target.value === "" || e.target.value < 0)) {
			setForm({
				...form,
				[e.target.name]: 0,
			})

			return
		}

		setForm({
			...form,
			[e.target.name]: e.target.value,
		})
	}

	function resetForm() {
		setForm({
			title: '',
			responsable: '',
			type: 'value',
			valueStart: 0,
			valueEnd: 0,
			startPeriod: '',
			endPeriod: '',
			tags: [],
			weight: 1
		})

		setFormError({
			title: '',
			responsable: '',
			type: '',
			valueStart: '',
			valueEnd: '',
			startPeriod: '',
			endPeriod: '',
			tags: '',
			weight: ''
		})

		setTags([])
	}

	function closeModalNewKr() {
		setOpenModalNewKr(false)
		resetForm()
		setResponsible('')
		setAssistants([])
	}

	// function openModalAddTeam() {
	// 	shareActions.openModalAddTeam()
	// }

	const handleOpenModalAddTeamTask = (e) => {
		e.preventDefault()
		setOpenModalAddTeamTask(true)
	}

	const createKeyResult = async () => {
		try {
			setIsLoading(true)
			setSubmitted(true)
			let teammatesIdsOnly = [];

			if (assistants.length > 0) {
				assistants.forEach((teammate) => {
					teammatesIdsOnly.push(teammate?.id)
				})
			}

			let payload = {
				action_id: 3,
				datetime: new Date(),
				title: form.title,
				accountable_id: form.responsable,
				type: form.type === 'value' ? false : true,
				start_value: form.type === 'value' ? form.valueStart : 0,
				end_value: form.type === 'value' ? form.valueEnd : 0,
				date_start: currentObjective?.date_start,
				date_end: currentObjective?.date_end,
				team_ids: teammatesIdsOnly,
				tags: form.tags,
				is_int_value: isIntChecked,
				weight: form?.weight
			};

			await api.post(`/goals/${params?.id}/key-results?organization_id=${userDetails.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			alertActions.success(t('keyresults.create'))
			props.updateGoalsDetails()
			closeModalNewKr()
		} catch (error) {
			setSubmitted(false)
			console.log(error.response)
			//alertActions.error(t('keyresults.error_create'))
		} finally {
			setIsLoading(false)
			setSubmitted(false)
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setFormError(validateForm(form))
		setSubmitted(true)
	}

	useEffect(() => {
		if (Object.keys(formError).length === 0 && submitted) {
			createKeyResult()
		} else {
			setSubmitted(false)
		}

	}, [formError, submitted])

	useEffect(() => {
		if (responsible) {
			handleChange({ target: { name: 'responsable', value: responsible } })
		}
	}, [responsible])

	useEffect(() => {
		if (valueStart) {
			handleChange({ target: { name: 'valueStart', value: valueStart } })
		}
	}, [valueStart])

	useEffect(() => {
		if (valueEnd) {
			handleChange({ target: { name: 'valueEnd', value: valueEnd } })
		}
	}, [valueEnd])

	useEffect(() => {
		setForm({
			...form,
			tags: tags
		})
	}, [tags])

	useEffect(() => {
		setForm({
			...form,
			valueStart: 0,
			valueEnd: 0
		})
	}, [form?.type])

	// useEffect(() => {
	// 	console.log(form)
	// }, [form])

	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={openModalNewKr}
				onClose={closeModalNewKr}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					style: { backgroundColor: '#205B6F', opacity: 0.8 }
				}}
				sx={{ border: 'none' }}
				style={{ outline: 0 }}
			>
				<Slide direction="down" in={openModalNewKr} style={{ outline: 0 }} mountOnEnter unmountOnExit>
					<div className="containerModalFormKeyResult">

						<button className="close" onClick={() => closeModalNewKr()}><IconClose /></button>

						<form onSubmit={handleSubmit}>
							<div className="leftCol">
								<header>
									<h3>
										{t('modalFormKeyResult.header_modal')}
										<span>
											<IconInfo />
											<div className="tooltip">
												<h6>{t('modalFormKeyResult.tooltip_header.title')}</h6>
												<p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_one')}</strong> {t('modalFormKeyResult.tooltip_header.info_one')}</p>
												<p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_two')}</strong> {t('modalFormKeyResult.tooltip_header.info_two')}</p>
												<p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_three')}</strong> {t('modalFormKeyResult.tooltip_header.info_three')}</p>
												<p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_four')}</strong> {t('modalFormKeyResult.tooltip_header.info_four')}</p>
											</div>
										</span>
									</h3>
								</header>
								<div className="field">
									<label htmlFor="title">{t('modalFormKeyResult.title')}</label>
									<input id="title" type="text" name="title" placeholder={t('modalFormKeyResult.placeholder_title')} value={form.title || ''} onChange={handleChange} autoFocus maxLength={150}/>
                  <span className="text-limit">{form.title.length}/150</span>
									{formError.title &&
										<div className="error">{formError.title}</div>
									}
								</div>

								<div className="field">
									<label htmlFor="responsable">{t('modalFormKeyResult.responsible')}*</label>
									<Select
										id='responsable'
										defaultValue={form.responsable || ''}
										name="responsable"
										options={teammates}
										className="basic-multi-select"
										classNamePrefix="select"
										placeholder={t('modalFormKeyResult.placeholder_select')}
										onChange={(e) => setResponsible(e.value)}
									/>

									{formError.responsable &&
										<div className="error">{formError.responsable}</div>
									}
								</div>

								<div className="fieldset" style={{ justifyContent: 'space-between' }}>
									<div className="col" style={{ width: '260px' }}>
										<label htmlFor="type">{t('modalFormKeyResult.type')}</label>

										<div className="groupInputRadio">
											<div>
												<input
													type="radio"
													name="type"
													id="value"
													value={'value'}
													onChange={handleChange}
													checked={form?.type === 'value' ? true : false}
												/>
												<label htmlFor="value">{t('modalFormKeyResult.type_value')}</label>
											</div>
											<div>
												<input type="radio" name="type" id="result" value={'result'} onChange={handleChange} checked={form?.type === 'result' ? true : false} />
												<label htmlFor="result">{t('modalFormKeyResult.type_result')}</label>
											</div>
										</div>

										{(formError.type) &&
											<div className="error">{formError.type}</div>
										}

									</div>

									{
										form?.type === 'result'
											?
											<>
												<div className="col label-result" style={{ width: '260px' }}>
													<span><IconInfo width={20} height={20} /></span>
													<span style={{ fontSize: '12px' }}>{t('modalFormKeyResult.label_result')}</span>
												</div>
											</>
											:
											<div className="value-type-container">
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<input
														id="valueTypeInt"
														type="checkbox"
														name="valueTypeInt"
														checked={isIntChecked}
														onChange={() => setIsIntChecked(!isIntChecked)}
													/>
													<label htmlFor="valueTypeInt">{t('modalFormKeyResult.int')}</label>
												</div>
												<div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
													<input
														id="valueTypeFloat"
														type="checkbox"
														name="valueTypeFloat"
														checked={!isIntChecked}
														onChange={() => setIsIntChecked(!isIntChecked)}
													/>
													<label htmlFor="valueTypeFloat">{t('modalFormKeyResult.float')}</label>
												</div>
											</div>
									}
								</div>

								{form?.type === 'value' && (
									isIntChecked ? (
										<div className="value-chosen-container">

											<div className="col">
												<label htmlFor="valueStart">{t('modalFormKeyResult.start_in')}</label>
												<input
													type="number"
													id="valueStart"
													name="valueStart"
													placeholder="0"
													value={form.valueStart || ''}
													onChange={handleChange}
													min={0}
												/>
												{formError.valueEnd &&
													<div className="error" style={{ opacity: 0 }}>#</div>
												}

												{formError.valueStart &&
													<div className="error">{formError.valueStart}</div>
												}
											</div>

											<div className="col">
												<div className="arrowBox">
													<span>{'>'}</span>
												</div>
												{formError.valueEnd &&
													<div className="error" style={{ opacity: 0 }}>#</div>
												}
											</div>

											<div className="col">
												<label htmlFor="valueEnd">{t('modalFormKeyResult.to')}</label>
												<input
													type="number"
													id="valueEnd"
													name="valueEnd"
													placeholder="0"
													value={form.valueEnd || ''}
													onChange={handleChange} 
													min={0}
												/>
												{formError.valueEnd &&
													<div className="error">{formError.valueEnd}</div>
												}
											</div>
										</div>
									) : (
										<div className="value-chosen-container">

											<div className="col">
												<label htmlFor="valueStart">{t('modalFormKeyResult.start_in')}</label>

												<NumberFormatBase
													format={formatCurrencyByEnd}
													allowNegative={false}
													fixedDecimalScale
													decimalScale={2}
													placeholder="0.00"
													inputMode="numeric"
													displayType="input"
													value={
														!valueStart || !Number(valueStart) ? "" : Number(valueStart) * 100
													}
													isAllowed={values => {
														const { formattedValue, floatValue } = values;
														return formattedValue === "" || floatValue / 100 <= 10000000000;
													}}
													onValueChange={values => {
														if (values.value) {
															setValueStart((parseFloat(values.value) / 100).toFixed(2));
														} else {
															setValueStart((0 / 100).toFixed(2))
														}
													}}
												/>

												{formError.valueEnd &&
													<div className="error" style={{ opacity: 0 }}>#</div>
												}
												{formError.valueStart &&
													<div className="error">{formError.valueStart}</div>
												}
											</div>

											<div className="col">
												<div className="arrowBox">
													<span>{'>'}</span>
												</div>
												{formError.valueEnd &&
													<div className="error" style={{ opacity: 0 }}>#</div>
												}
											</div>

											<div className="col">
												<label htmlFor="valueEnd">{t('modalFormKeyResult.to')}</label>

												<NumberFormatBase
													format={formatCurrencyByEnd}
													allowNegative={false}
													fixedDecimalScale
													decimalScale={2}
													placeholder="0.00"
													inputMode="numeric"
													displayType="input"
													value={
														!valueEnd || !Number(valueEnd) ? "" : Number(valueEnd) * 100
													}
													isAllowed={values => {
														const { formattedValue, floatValue } = values;
														return formattedValue === "" || floatValue / 100 <= 10000000000;
													}}
													onValueChange={values => {
														if (values.value) {
															setValueEnd((parseFloat(values.value) / 100).toFixed(2));
														} else {
															setValueEnd((0 / 100).toFixed(2))
														}
													}}
												/>

												{formError.valueEnd &&
													<div className="error">{formError.valueEnd}</div>
												}
											</div>
										</div>
									)
								)}

								<div className="boxListKRs">
									<h3>
										{t('modalFormKeyResult.krs_of_goal')}
									</h3>

									{currentObjective?.keyresults?.length > 0 &&
										<div className="keyResultsList">
											{currentObjective.keyresults.map((item, index) => (
												<div className="row" key={index}>
													<div>
														<span 
															className={`color-${getStatusClass(item?.krStatus)}`}
														>
															KR{index + 1}:
														</span>
														<span style={{ color: '#4F7091'}}>{item.title}</span>
													</div>
												</div>
											))}
										</div>
									}
								</div>

							</div>

							<div className="rightCol">

								<div className="boxContentInputsAndDetails">

									<div className="boxInputsRight" style={{ marginTop: 20 }}>
										<div className="teamBox">
											<header>
												<h4>{t('modalFormKeyResult.team')}</h4>
											</header>
											<div className="row">
												<div className='assistant-container'>
													{assistants?.length >= 3 ? (
														<>

															<ReactAvatar
																name={assistants[0].username}
																size={20}
																round
																color='#4F7091'
																style={{ border: '2px solid #fff', marginRight: '-5px' }}
															/>

															<ReactAvatar
																name={assistants[1].username}
																size={20}
																round
																color='#4F7091'
																style={{ border: '2px solid #fff', marginRight: '-5px' }}
															/>

															<ReactAvatar
																name={assistants[2].username}
																size={20}
																round
																color='#4F7091'
																style={{ border: '2px solid #fff' }}
															/>
															<span
																style={{
																	color: '#4F7091',
																	fontSize: '16px',
																}}
															>
																...
															</span>
														</>

													) : (
														assistants?.map(user => (
															<ReactAvatar
																key={user.id}
																name={user.username}
																size={20}
																round
																color='#4F7091'
																style={{ border: '2px solid #fff', marginRight: '-5px' }}
															/>
														))
													)}

													<button
														className='add-assistant-button'
														onClick={handleOpenModalAddTeamTask}
													>
														+
													</button>

												</div>
											</div>
										</div>

										<div className="tagsBox">
											<header>
												<h4>{t('modalFormKeyResult.tags')}</h4>
											</header>
											<div className="row">
												<TagsInput
													value={tags}
													onChange={setTags}
													name="tags"
													placeHolder="+ Tag"
												/>

												{(formError.tags) &&
													<div className="error">{formError.tags}</div>
												}
											</div>
										</div>

										<div className="weightBox">
											<header>
												<h4>{t('modalFormKeyResult.weight')}</h4>
											</header>
											<div className="row">
												<div className="weight-container">
													
													<MuiSelect
														style={{ color: '#98ACC1', textAlign: 'right' }}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														name="weight"
														id="weight"
														value={form.weight || 1}
														onChange={handleChange}
													>
														<MenuItem selected value={1}>1</MenuItem>
														<MenuItem value={2}>2</MenuItem>
														<MenuItem value={3}>3</MenuItem>
														<MenuItem value={4}>4</MenuItem>
													</MuiSelect>
													{formError.weight &&
														<div className="error">{formError.weight}</div>
													}
												</div>
											</div>
										</div>

									</div>
								</div>

								<div className="formButtonGroupAddKR">
									<button className="btnCancel" type="button" onClick={() => { closeModalNewKr() }}>{t('button.cancel')}</button>
									<button type="submit" disabled={isLoading}>{t('button.save')}</button>
								</div>
							</div>

						</form>

					</div>
				</Slide>
			</Modal>


			{/* Modal add teams */}
			<ModalAddTeamTask
				classes={classes}
				openModalAddTeamTask={openModalAddTeamTask}
				setOpenModalAddTeamTask={setOpenModalAddTeamTask}
				assistants={assistants}
				setAssistants={setAssistants}
				groups={groups}
			/>
			{/* <ModalAddTeam form={form} setForm={setForm} listTeams={groups} /> */}
		</>

	)

};

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		share: state.share
	}
}

function mapDispatchToProps(dispatch) {
	return {
		shareActions: bindActionCreators(shareActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)

	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeyResultModalForm));
