export const periodicConstants = {
    GET_PERIODIC_REPORT_REQUEST: '[PERIODIC] GET_PERIODIC_REPORT_REQUEST',
    GET_PERIODIC_REPORT_SUCCESS: '[PERIODIC] GET_PERIODIC_REPORT_SUCCESS',
    GET_PERIODIC_REPORT_FAILURE: '[PERIODIC] GET_PERIODIC_REPORT_FAILURE',
    DELETE_PERIODIC_REPORT_REQUEST: '[PERIODIC] DELETE_PERIODIC_REPORT_REQUEST',
    DELETE_PERIODIC_REPORT_SUCCESS: '[PERIODIC] DELETE_PERIODIC_REPORT_SUCCESS',
    DELETE_PERIODIC_REPORT_FAILURE: '[PERIODIC] DELETE_PERIODIC_REPORT_FAILURE',
    PATCH_PERIODIC_REPORT_REQUEST: '[PERIODIC] PATCH_PERIODIC_REPORT_REQUEST',
    PATCH_PERIODIC_REPORT_SUCCESS: '[PERIODIC] PATCH_PERIODIC_REPORT_SUCCESS',
    PATCH_PERIODIC_REPORT_FAILURE: '[PERIODIC] PATCH_PERIODIC_REPORT_FAILURE',
    PATCH_PERIODIC_REPORT_ACTIVATE_REQUEST: '[PERIODIC] PATCH_PERIODIC_REPORT_ACTIVATE_REQUEST',
    PATCH_PERIODIC_REPORT_ACTIVATE_SUCCESS: '[PERIODIC] PATCH_PERIODIC_REPORT_ACTIVATE_SUCCESS',
    PATCH_PERIODIC_REPORT_ACTIVATE_FAILURE: '[PERIODIC] PATCH_PERIODIC_REPORT_ACTIVATE_FAILURE',
    PATCH_PERIODIC_REPORT_DEACTIVATE_REQUEST: '[PERIODIC] PATCH_PERIODIC_REPORT_DEACTIVATE_REQUEST',
    PATCH_PERIODIC_REPORT_DEACTIVATE_SUCCESS: '[PERIODIC] PATCH_PERIODIC_REPORT_DEACTIVATE_SUCCESS',
    PATCH_PERIODIC_REPORT_DEACTIVATE_FAILURE: '[PERIODIC] PATCH_PERIODIC_REPORT_DEACTIVATE_FAILURE',
    POST_PERIODIC_REPORT_REQUEST: '[PERIODIC] POST_PERIODIC_REPORT_REQUEST',
    POST_PERIODIC_REPORT_SUCCESS: '[PERIODIC] POST_PERIODIC_REPORT_SUCCESS',
    POST_PERIODIC_REPORT_FAILURE: '[PERIODIC] POST_PERIODIC_REPORT_FAILURE',
};

