import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import PeriodicReportsEdit from '../_components/periodicreports/PeriodicReportsEdit'
import PeriodicReportsEditSkeleton from '../_components/periodicreports/PeriodicReportsEditSkeleton'
import Moment from 'moment';
import IconBack from '../_components/_icons/IconBack'

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { periodicActions, teammatesActions, teamsActions } from '../_actions';
import { periodicService } from '../_services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';


function PeriodicReportsPageEdit(props) {
    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    const { periodicActions, teammatesActions, teamsActions } = props;
    const [periodic, setPeriodic] = useState([])
    const [action, setAction] = useState("")
    let loading = props.loading;
    let loadingTeams = props.loadingTeams;
    let loadingTeammates = props.loadingTeammates
    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const [fetching, setFetching] = useState(true);
    const { id } = useParams()
    const path = window.location.pathname.split("/")


    const getPeriodicByID = async (pr_id) => {
        try {
            setFetching(true)
            const { data } = await api.get(`/periodic-reports/${pr_id}`, { headers: authHeader() })
            if (data.report && data.report.length > 0) {
                setPeriodic(data.report)
            }
            setFetching(false)
        } catch (error) {
            console.log('Error get periodic report = ' + pr_id)
            setFetching(false)
        }
    }
    useEffect(() => {

        const actionForm = !id ? 'add' : 'edit'

        setAction(actionForm)

        if (id) {
            getPeriodicByID(id)
        }else{
            setFetching(false)
        }

        teamsActions.getTeams();
        teammatesActions.getTeammates();

    }, [])


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar />
                <div className="mainFilterbar">
                    <button className={'back'} onClick={() => {
                        history.push("/productive/reports/periodic")
                    }}>
                        <IconBack />
                    </button>
                </div>
                {/* PAGE STARTS */}
                <div className="mainPage profile">
                    <div className="nocolumn">
                        <div className={'tab full active'}>
                            {
                                !minimumTimeElapsed || (loading === true || loadingTeammates === true || loadingTeams === true || fetching === true)
                                    ? <PeriodicReportsEditSkeleton />
                                    : <PeriodicReportsEdit pr={periodic} action={action} />
                            }
                        </div>
                    </div>

                </div>
                {/* PAGE ENDS */}
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        periodicActions: bindActionCreators(periodicActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        loading: state.periodic.loading,
        loadingTeams: state.teams.loading,
        loadingTeammates: state.teammates.loading
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicReportsPageEdit)
