import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Paper, Slide, Tooltip } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader, createHierarchy } from "../../_helpers";

import "./ModalGroupActivities.scss"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Delete } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '100%',
    marginTop: '0px'
  },
  list: {
    border: '1px solid #D6DEEB',
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: 200
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#187dff', // Cor da borda quando está em foco
      borderWidth: '1px'
    }
  },
  paper: {
    marginTop: '10px',
  }
}));

function ModalGroupActivities({
  openModalGroupActivities,
  setOpenModalGroupActivities,
  userDetails,
  alertActions,
  getActivitiesData,
  activitiesSelected,
  setSelectedRows,
  removeActivity,
  categories,
  filter
}) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const categoriesOptions = createHierarchy(categories || [], userDetails.language || i18n.language);

  const [form, setForm] = useState({
    name: '',
    category: {
      value: '',
      label: ''
    }
  });

  const [formError, setFormError] = useState({
    name: '',
    category: ''
  });

  function closeModalGroupActivities() {
    setOpenModalGroupActivities(false)
    setForm({
      name: '',
      category: {
        value: '',
        label: ''
      }
    })
    setFormError({
      name: '',
      category: ''
    })
  }

  const handleChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
    setFormError({
      ...formError,
      [name]: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};
    if (!form.name) {
      errors.name = t('settings.teams.required_field')
    }
    if (!form.category.value) {
      errors.category = t('settings.teams.required_field')
    }
    return errors
  }


  const handleGroupActivities = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createGroupActivities = async () => {
    try {
      setIsSubmitting(true)

      const payload = {
        tracked_name: form.name,
        category_id: form.category?.value,
        trackeds: activitiesSelected.map(t => ({trackedId:t.trackedId, origin: t.origin}))        
      }

      const res = await api.post(`/settings/group-activities`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao agrupar as atividades')
      }

      getActivitiesData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: filter.search || '' })
      closeModalGroupActivities()
      setSelectedRows([])
      alertActions.success(t('settings.activities.success.group_activities'))

    } catch (error) {
      console.error(error)
      alertActions.error(error.response.data)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createGroupActivities()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormGroupActivities ${classes.modal}`}
        open={openModalGroupActivities}
        onClose={closeModalGroupActivities}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalGroupActivities} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormGroupActivities"}>

            <button onClick={closeModalGroupActivities} className="close"><IconClose /></button>

            <form onSubmit={handleGroupActivities}>
              <main>
                <header>
                  <h3>
                    {t('settings.activities.modal_group_activities.title')}
                  </h3>
                </header>
                <div className="field">
                  <label>{`${t('settings.activities.modal_group_activities.field_activities_selected')} (${activitiesSelected.length})`}</label>
                  <div className={`${classes.listContainer}`}>
                    <List dense={true} className={classes.list}>
                      {
                        activitiesSelected.map((act) => (
                          <ListItem key={act.trackedId}>
                            <ListItemText
                              style={{ color: '#1E6B7F' }}
                              primary={
                                act.exhibitionName.length >= 50
                                ?
                                <Tooltip title={act.exhibitionName} placement="top" arrow>
                                  <span style={{fontSize:"12px"}}>{act.exhibitionName.slice(0, 50)+"[...]"}</span>
                                </Tooltip>
                                :
                                act.exhibitionName
                              }
                            />
                            {
                              activitiesSelected.length > 1 &&
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="remove" size={'small'} onClick={() => removeActivity(act.trackedId)}>
                                  <Delete size={'small'} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            }
                          </ListItem>
                        ))
                      }
                    </List>
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="label_tracked">{t('settings.activities.modal_group_activities.field_name')}*</label>
                  <TextField
                    style={{}}
                    id="label_tracked"
                    value={form.name}
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                  />
                  {formError.name &&
                    <div className="error">{formError.name}</div>
                  }
                </div>

                <div className="field">
                  <label>{t('settings.activities.modal_group_activities.field_category')}*</label>
                  <Autocomplete
                    value={form.category}
                    onChange={(event, newValue) => {
                      setForm({ ...form, category: newValue })
                      setFormError({ ...formError, category: '' })
                    }}
                    PopperComponent={(props) => (
                      <Paper elevation={0} {...props} className={classes.paper} />
                    )}
                    renderOption={(option) => {
                      if (option.is_children === true) {
                        return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{option.label}</span>
                      }

                      return <span>{option.label}</span>
                    }}
                    getOptionLabel={(option) => option.label}
                    id="category"
                    options={(categoriesOptions && categoriesOptions.length > 0) ? categoriesOptions : []}
                    fullWidth
                    renderInput={(params) => <TextField {...params} className={classes.focused} variant="outlined" fullWidth />}
                  />
                  {formError.category &&
                    <div className="error">{formError.category}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={closeModalGroupActivities}
                    >
                      {t('settings.activities.modal_group_activities.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('settings.activities.modal_group_activities.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalGroupActivities));
