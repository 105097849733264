// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MB70zksGlDCvWBfPrnlF7A\\=\\= svg {\n  width: 250px;\n  height: 115px;\n}", "",{"version":3,"sources":["webpack://./src/pages/SwitchPageOld/SwitchPageOld.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".logo {\n  svg {\n    width: 250px;\n    height: 115px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "MB70zksGlDCvWBfPrnlF7A=="
};
export default ___CSS_LOADER_EXPORT___;
