import React from 'react';

const IconMoon = props => {
  return (

    <svg id="lua" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1465" data-name="Retângulo 1465" width="16" height="16" fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons" transform="translate(2.331 1.667)">
        <path id="Caminho_383" data-name="Caminho 383" d="M10.728,7.067a7.533,7.533,0,0,0-.333,2.3,7.475,7.475,0,0,0,5,6.567,4.833,4.833,0,0,1-1.967.4h-.567A5,5,0,0,1,10.728,7.1m2-2.067h-.033a6.4,6.4,0,0,0-5.667,7.033,6.4,6.4,0,0,0,5.7,5.567h.7a6.4,6.4,0,0,0,4.8-2.133.3.3,0,0,0-.233-.5h-.633a6.176,6.176,0,0,1-5.633-5.7,5.8,5.8,0,0,1,1.267-3.767A.333.333,0,0,0,12.728,5Z" transform="translate(-6.992 -5)" fill="#22bead" />
      </g>
    </svg>

  )
}

export default IconMoon;