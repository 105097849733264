import React, { useState, useEffect } from 'react';

import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useLocation } from "react-router-dom";

import { usersActions } from '../../_actions';
import { menuActions } from '../../_actions';

import IconLogo from '../_icons/IconLogo'

import ReactGA from 'react-ga4';
import IconArrow from '../_icons/IconArrow';
import { useTranslation } from 'react-i18next';

ReactGA.initialize("G-9FF1246SC3")

function MenuCallsPage(props) {
  
  const { t, i18n } = useTranslation('common');
  
  const { menuActions } = props;

  const [isMenuOpen, setMenuOpen] = useState(true);

  const search = useLocation().search;
  const last_url = new URLSearchParams(search).get("last_url");

  function toggleClass() {

    if (!isMenuOpen) {
      toggleMenu('main');
    }else {
      menuActions.set('reports', false);
    }

  };

  function toggleMenu() {
        setMenuOpen(false);
  };


  useEffect(() => {


    setMenuOpen(props.menuStatus)


  }, [props.menuStatus]);

  const location = useLocation();

  useEffect(() => {
    //ReactGA.initialize("G-9FF1246SC3");
    const path = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: path });
  }, [location]);

  return (
    <nav className={isMenuOpen ? 'mainMenu backfective open' : 'mainMenu backfective'}>

      <div className="item nopointer">
        <Link to={`/${String(last_url).includes("calls-page") ? "" : String(last_url).replaceAll(",", "/")}`}>
          <div className="mainLogo">
            <IconLogo />
          </div>
        </Link>

      </div>

      <div className="item" onClick={() => { toggleClass(); toggleMenu() }}>
        <NavLink exact={true} to={`/${String(last_url).includes("calls-page") ? "" : String(last_url).replaceAll(",", "/")}`}>
          <IconArrow />
          <span>{t('menu.backfective.back_to_befective')}</span>
        </NavLink >
      </div>

    </nav>

  )
};

function mapDispatchToProps(dispatch) {

  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    menu: state.menu,
    permissions: state.authentication.permissions
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuCallsPage));