import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import styles from './TableProfiles.module.scss';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp } from 'phosphor-react';
import { authHeader, history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import IconEdit2 from '../_icons/IconEdit2';
import IconRemove from '../_icons/IconRemove';
import { Box, Button, Popover, Typography } from '@mui/material';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';

const TableProfiles = ({ columns, data, getData, alertActions, userDetails }) => {

  const { t } = useTranslation('common');

  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'simple-popover-confirm-delete-profile' : undefined;

  const handleClickConfirmDelete = (event, user) => {
    setProfileToDelete(user)
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElConfirmDelete(null)
    setProfileToDelete(null)
  };

  const handleConfirmDelete = async () => {
    try {
      if (!profileToDelete) {
        throw new Error('Days off to delete not found')
      }

      const res = await api.delete(`/settings/access-profile/${profileToDelete.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete profile')
      }
      getData(false)
      handleClose()
      alertActions.success(t('settings.profiles.success.deleted_profile'))
    } catch (error) {
      console.error(error)
    }
  }

  
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;


  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={`head-table-${headerGroup.id}`}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`col-${column.id}`} title={t('title_sort')} className={styles.headColTable} style={{ width: ['updated_at'].includes(column.id) ? '180px' : 'auto' }}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                ))}
              </React.Fragment>
            ))}

            <th style={{ width: '0px' }} className={styles.headColTable} />
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.length > 0
              ?
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={`${!row.original.is_default_profile ? styles.rowHover : ''}`} key={`row-${row.original.id}`}>
                    {row.cells.map((cell, i2) => (
                      <td {...cell.getCellProps()} key={`cell-${row.original.id}-${i2}`} onClick={(e) => !row.original.is_default_profile && history.push(`/settings/profiles/edit/${row.original.id}`)}>{cell.render("Cell")}</td>
                    ))}
                    <td>
                      <div className={styles.boxActions}>
                        {
                          !row.original.is_default_profile &&
                          <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={(e) => !row.original.is_default_profile && history.push(`/settings/profiles/edit/${row.original.id}`)}>
                            <IconEdit2 />
                          </button>
                        }
                        {
                          !row.original.is_default_profile &&
                          <button className={`${styles.buttonAction} ${styles.remove}`} onClick={(e) => handleClickConfirmDelete(e, row.original)}>
                            <IconRemove />
                          </button>
                        }
                      </div>
                    </td>
                  </tr>
                );
              })
              :
              <tr>
                <td colSpan={3}>
                  {t('settings.profiles.no_data_table')}
                </td>
              </tr>

          }
        </tbody>
      </table>
      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
        title={t('settings.profiles.delete_message')}
      />
    </div>
  );
};

export default TableProfiles;
