import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import "react-datepicker/dist/react-datepicker.css";

import {filterActions} from '../../../_actions';
import {statsActions} from '../../../_actions';
import {teamsActions} from '../../../_actions';
import {teammatesActions} from '../../../_actions';

import {useTranslation, initReactI18next} from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";


import IconClear from '../../_icons/IconClear'
import IconCheck from '../../_icons/IconCheck'

import IconTeam from '../../_icons/IconTeam'
import IconTeammate from '../../_icons/IconTeammate'
import Skeleton from "@material-ui/lab/Skeleton";

function WhenMultiSkeleton(props) {


    return (

        <div className="filter">
            <div className="holder">
                <Skeleton variant="text" width="100%" height={50}/>
            </div>
        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {}
}


function mapStateToProps(state) {

    return {}

}

export default connect(mapStateToProps, mapDispatchToProps)(WhenMultiSkeleton)
