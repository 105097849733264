import React from 'react';

const IconLate = props => {
  return (

    <svg id="atrasado" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1469" data-name="Retângulo 1469" width="16" height="16" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.333 0.667)">
        <g id="Grupo_491" data-name="Grupo 491">
          <path id="Caminho_384" data-name="Caminho 384" d="M11.333,8a6.333,6.333,0,1,0,6.333,6.333A6.333,6.333,0,0,0,11.333,8Zm0,11.333a5,5,0,1,1,5-5,5,5,0,0,1-5,5Z" transform="translate(-4.667 -6)" fill="#fc6662" />
          <path id="Caminho_385" data-name="Caminho 385" d="M5.067,5.2l2.667-2a.667.667,0,1,0-.8-1.067l-2.667,2a.667.667,0,0,0-.133.933.7.7,0,0,0,.533.267A.633.633,0,0,0,5.067,5.2Z" transform="translate(-4 -2)" fill="#fc6662" />
          <path id="Caminho_386" data-name="Caminho 386" d="M35.733,4.133l-2.667-2a.667.667,0,1,0-.8,1.067l2.667,2a.633.633,0,0,0,.4.133.7.7,0,0,0,.533-.267.667.667,0,0,0-.133-.933Z" transform="translate(-22.667 -2)" fill="#fc6662" />
          <path id="Caminho_387" data-name="Caminho 387" d="M20.659,23h-2.4l2.867-2.867a.6.6,0,0,0,.133-.733.667.667,0,0,0-.6-.4h-4a.667.667,0,1,0,0,1.333h2.4L16.192,23.2a.6.6,0,0,0-.133.733.667.667,0,0,0,.6.4h4a.667.667,0,1,0,0-1.333Z" transform="translate(-11.992 -13.333)" fill="#fc6662" />
        </g>
      </g>
    </svg>

  )
}

export default IconLate;