import React, { useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux'
import Moment from 'moment-timezone';
import { useTable, useSortBy } from 'react-table'


import IconTeammate from '../../_icons/IconTeammate';
import IconInfo from '../../_icons/IconInfo';
import IconCompleted from '../../_icons/IconCompleted';
import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';

import { secondsToHm } from '../../../_helpers';
import { filterActions, statsActions } from "../../../_actions";
import IconSoftware from "../../_icons/IconSoftware";
import IconSite from "../../_icons/IconSite";
import IconOutdoor from "../../_icons/IconOutdoor";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';


function RealTimeFull(props) {
	const { t, i18n } = useTranslation('common');
	const [showActiveUsers, setShowActiveUsers] = useState(true)
	const [showInactiveUsers, setShowInactiveUsers] = useState(true)
	const [showAbsentUsers, setShowAbsentUsers] = useState(true)
	const [showHappy, setShowHappy] = useState(true)
	const [showOk, setShowOk] = useState(true)
	const [showSad, setShowSad] = useState(true)

	const { filterActions, statsActions, setUserSelected } = props;

	let today = props.stats.payload || [];
	let todayUsers = props.stats.realtime || [];
	let userDetails = props.userDetails || [];

	const [usersData, setUsersData] = useState(todayUsers)

	const isMountedFilter = useRef(false);

	const data = useMemo(() => usersData, [usersData]);

	const history = useHistory();

	const [TotalOkUsers, setTotalOkUsers] = useState(0);
	const [TotalSadUsers, setTotalSadUsers] = useState(0);
	const [TotalHappyUsers, setTotalHappyUsers] = useState(0);

	const [tooltipValue, setTooltipValue] = useState(t('realtime.loading'));


	let TotalExpectedWorkedTime = today.TotalExpectedWorkedTime || 0;
	let TotalExpectedProductiveTime = today.TotalExpectedProductiveTime || 0;
	let TotalWorkedTime = 0;
	let PercentExpectedWorkedTime = 0;

	let TotalWorkedTimeRelated = 0
	let TotalWorkedTimeOnline = 0
	let PercentWorkedTimeRelated = 0;

	let TotalWorkedTimeOutdoor = today.TotalWorkedTimeOutdoor || 0;
	let PercentWorkedTimeOutdoor = 0;

	let TotalProductiveTime = 0;
	let PercentProductiveTime = 0;

	let TotalProductiveTimeRelated = 0
	let PercentProductiveTimeRelated = 0;

	let TotalProductiveTimeOutdoor = 0;
	let PercentProductiveTimeOutdoor = 0;


	if (!TotalExpectedWorkedTime || TotalExpectedWorkedTime <= 0) {
		TotalExpectedWorkedTime = 0
		PercentExpectedWorkedTime = 'Ω'
		PercentProductiveTime = 'Ω'
	} else {
		if (today.TotalWorkedTime && today.TotalWorkedTime > 0) {
			TotalWorkedTime = secondsToHm(today.TotalWorkedTime);
		}
		if (today.TotalProductiveTime && today.TotalProductiveTime > 0) {
			TotalProductiveTime = secondsToHm(today.TotalProductiveTime);
		}

		if (today.TotalWorkedTime && today.TotalWorkedTime > 0 && TotalExpectedWorkedTime) {
			TotalWorkedTimeRelated = secondsToHm(today.TotalWorkedTime);
			PercentWorkedTimeRelated = Math.round(((today.TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalExpectedWorkedTime) * 100);
			PercentExpectedWorkedTime = (((today.TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalExpectedWorkedTime) * 100)
			TotalWorkedTimeOnline = secondsToHm(today.TotalWorkedTime - TotalWorkedTimeOutdoor)
		}

		if (TotalWorkedTimeOutdoor && TotalWorkedTimeOutdoor > 0 && TotalExpectedWorkedTime > 0) {
			TotalWorkedTimeOutdoor = secondsToHm(today.TotalWorkedTimeOutdoor);
			PercentWorkedTimeOutdoor = Math.round((parseInt(today.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100);
			PercentExpectedWorkedTime += ((parseInt(today.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100)
		}

		if (today.TotalProductiveTime && today.TotalProductiveTime > 0) {

			let TempTotalProductiveTimeOutdoor = 0;

			if (today.TotalProductiveTimeOutdoor && today.TotalProductiveTimeOutdoor > 0) {
				TempTotalProductiveTimeOutdoor = today.TotalProductiveTimeOutdoor;
			}

			TotalProductiveTimeRelated = secondsToHm(today.TotalProductiveTime - TempTotalProductiveTimeOutdoor);
			PercentProductiveTimeRelated = Math.round(((today.TotalProductiveTime - TempTotalProductiveTimeOutdoor) / TotalExpectedProductiveTime) * 100);
			PercentProductiveTime = (((today.TotalProductiveTime - TempTotalProductiveTimeOutdoor) / TotalExpectedProductiveTime) * 100)
		}

		if (today.TotalProductiveTimeOutdoor && today.TotalProductiveTimeOutdoor > 0) {
			TotalProductiveTimeOutdoor = secondsToHm(today.TotalProductiveTimeOutdoor);
			PercentProductiveTimeOutdoor = Math.round((today.TotalProductiveTimeOutdoor / TotalExpectedProductiveTime) * 100);
			PercentProductiveTime += ((today.TotalProductiveTimeOutdoor / TotalExpectedProductiveTime) * 100)
		}
	}

	if (PercentExpectedWorkedTime != 'Ω') {
		PercentExpectedWorkedTime = Math.round(PercentExpectedWorkedTime)
	}

	if (PercentProductiveTime != 'Ω') {
		PercentProductiveTime = Math.round(PercentProductiveTime)
	}


	let ActiveUsersToday = today.ActiveUsersToday || 0;
	let ScheduledAbsensesToday = today.ScheduledAbsensesToday || 0;
	let InactiveUsersToday = ((today.TotalMachines - today.ActiveUsersToday) - today.ScheduledAbsensesToday) || 0;
	let AverageStart = "00:00";

	if (today.AverageStartToday && today.AverageStartToday != 0) {

		if (today.AverageStartToday == "Invalid date") {
			AverageStart = t('realtime.no_avarage_start');
		} else {
			let tempTime = today.AverageStartToday.split(":");
			AverageStart = tempTime[0] + ":" + tempTime[1];
		}


	}


	const columns = React.useMemo(
		() => [
			{
				Header: 'A',
				accessor: "mood_url_image",
				show: true,
				Cell: (d) => {

					if(!d.value){
						return <div className="icon ok">--</div>
					}
					return <img src={d.value} loading='lazy' width={18}/>
				}
			},
			{
				Header: t('realtime.teammate'),

				accessor: c => {


					if (c.alias) {
						return c.alias.toLowerCase()
					} else {
						return c.username.toLowerCase()
					}

				},
				show: true,
				Cell: (d) => {

					let name = ''

					if (d.row.original.alias) {
						name = d.row.original.alias
					} else {
						name = d.row.original.username
					}

					return <div className="name" onClick={() => history.push(`/productive/reports/realtime/user/${d.row.original.machine_user_id}`)} title={t('realtime.see_more_details')}>
						<span>{name}</span>
						<span style={{ marginLeft: '4px', marginTop: '2px' }}><OpenInNewIcon style={{ fontSize: ".8rem" }} /></span>
					</div>

				}
			},
			{
				Header: t('realtime.team'),
				accessor: 'team',
				show: true,
			},
			{
				Header: t('realtime.started'),
				accessor: c => {
					return c.datetime_start === 'NaN' ? 'NaN' : c.datetime_start
				},
				show: true,
				Cell: (d) => {

					return d.value === 'NaN' ? '---' : Moment.utc(d.value).format("HH:mm");

				}
			},
			{
				Header: t('realtime.ended'),
				accessor: c => {
					return c.last_activity === 'NaN' ? 'NaN' : c.last_activity
				},
				show: true,
				Cell: (d) => {
					return d.value === 'NaN' ? '---' : Moment.utc(d.value).format("HH:mm");
				}
			},
			{
				Header: t('realtime.time_goal'),
				show: userDetails.allowed_total_time,
				accessor: d => {
					let perCompleted = 0.00;
					if (d.totalworkedtime && d.totalworkedtime > 0 && d.totalexpectedworkedtime > 0) {
						perCompleted = ((d.totalworkedtime / d.totalexpectedworkedtime) * 100).toFixed(2)
					}
					return perCompleted
				},
				Cell: (d) => {
					const row = d.row.original;
					let perCompleted = "0.00";
					if (row.totalworkedtime && row.totalworkedtime > 0 && row.totalexpectedworkedtime > 0) {
						perCompleted = ((row.totalworkedtime / row.totalexpectedworkedtime) * 100).toFixed(2)
					}
					return <div className="time">{perCompleted} %</div>
				}
			},
			{
				id: 'timerelated',
				Header: t('realtime.work_goal'),
				show: userDetails.allowed_productive.includes('related'),
				accessor: row => {
					let perCompleted = "0.00"
					if (row.TotalProductiveTime > 0 && row.totalexpectedproductivetime > 0) {
						perCompleted = ((row.TotalProductiveTime / row.totalexpectedproductivetime) * 100).toFixed(2)
					}
					return perCompleted;
				},
				Cell: (d) => {
					const row = d.row.original;
					let perCompleted = "0.00"
					if (row.TotalProductiveTime > 0 && row.totalexpectedproductivetime > 0) {
						perCompleted = ((row.TotalProductiveTime / row.totalexpectedproductivetime) * 100).toFixed(2)
					}
					return <div className="work">{perCompleted + "%"}</div>
				}
			},
			{
				Header: t('realtime.last_seen'),
				accessor: c => {
					return c.last_seen_at === 'NaN' ? 'NaN' : c.last_seen_at.toLowerCase()
				},
				show: true,
				Cell: (d) => {

					return d.value === 'NaN' ? (
						'---'
					) : (
						<>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<div className={"icon"} onClick={() => {
									displayTooltip(d.row.original.last_tracked_id, d.row.original.machine_user_id)
								}}>
									<IconInfo />
									<div className="tooltip" id={'tooltip-' + d.row.original.machine_user_id}>
										{tooltipValue}
									</div>
								</div>
								<div className={d.row.original.efficiency}>
									{d.row.original.last_seen_at}
								</div>
							</div>
						</>
					)
				}
			}, {
				id: 'status',
				Header: t('realtime.status'),
				accessor: c => {
					return c.last_activity === 'NaN' ? 'NaN' : c.last_activity
				},
				show: true,
				Cell: (d) => {
					const today = Moment().format("YYYY-MM-DD")
					if (props.filter.singleDate !== undefined && props.filter.singleDate !== today) {
						return (<></>)
					}
					const requestTime = new Date('1990-01-01 ' + Moment().format("HH:mm"));
					const lastActivity = d.value === 'NaN' ? '---' : new Date('1990-01-01 ' + Moment.utc(d.value).format("HH:mm"));
					let resp = (<>
						<div style={{ backgroundColor: "red", borderRadius: 999, width: "10px", height: "10px", marginRight: "0.2em" }} ></div>
						<p>{t('realtime.inactive')}</p>
					</>)
					if (lastActivity !== '---') {
						const ms = Moment(requestTime, "YYYY-MM-DD HH:mm:ss").diff(Moment(lastActivity, "YYYY-MM-DD HH:mm:ss"));
						const diff_in_seconds = Math.ceil(ms / 1000)
						if (diff_in_seconds <= 600) {
							resp = (<>
								<div style={{ backgroundColor: "green", borderRadius: 999, width: "10px", height: "10px", marginRight: "0.2em" }} ></div>
								<p>{t('realtime.active')}</p>
							</>)
						}

					}


					return resp;
				}
			}
		],
		[]
	);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data,
		disableMultiSort: true,
		initialState: {
			hiddenColumns: [ (props.filter.singleDate && Moment(props.filter.singleDate).format('YYYY-MM-DD') !== Moment().format('YYYY-MM-DD'))&& 'status'],
			sortBy: [
				{
					id: 'timerelated',
					desc: true
				}
			]
		}

	}, useSortBy);

	function displayTooltip(tracked_id, machine_user_id) {

		let elems = document.querySelectorAll('.tooltip');

		if (elems.length > 0) {
			[].forEach.call(elems, function (el) {
				el.classList.remove('show');
			});
		}

		document.getElementById("tooltip-" + machine_user_id).classList.add('show');

		statsActions.getRealtimeTooltip(tracked_id, machine_user_id);


	}

	function openActivities(id) {
		filterActions.clear("who");
		filterActions.replace({ id }, "teammates");

	}

	function handleToggleFilterActiveUsers() {
		setShowActiveUsers(!showActiveUsers)

		let usersFiltered = []

		if (showActiveUsers) {
			usersFiltered = usersData.filter(user => user.efficiency === 'NaN')
			setUsersData(usersFiltered)
		} else {
			let usersActive = todayUsers.filter(user => user.efficiency !== 'NaN')

			if (!showHappy) {
				const usersWithoutHappy = usersActive.filter(user => user.mood !== 0)
				usersActive = [...usersWithoutHappy]
				// setUsersData(oldState => [...oldState, ...usersWithoutHappy])
			}

			if (!showOk) {
				const usersWithoutOk = usersActive.filter(user => user.mood !== 1)
				usersActive = [...usersWithoutOk]
				// setUsersData(oldState => [...oldState, ...usersWithoutOk])
			}

			if (!showSad) {
				const usersWithoutSad = usersActive.filter(user => user.mood !== 2)
				usersActive = [...usersWithoutSad]
				// setUsersData(oldState => [...oldState, ...usersWithoutSad])
			}

			setUsersData(oldState => [...oldState, ...usersActive])

		}
	}

	function handleToggleFilterInactiveUsers() {
		setShowInactiveUsers(!showInactiveUsers)

		let usersFiltered = []

		if (showInactiveUsers) {
			const usersActiveFiltered = usersData.filter(user => user.efficiency !== 'NaN' && user.isAbsent === false)

			const usersAbsentFiltered = usersData.filter(user => user.isAbsent === true)
			usersFiltered = [...usersActiveFiltered, ...usersAbsentFiltered]

			setUsersData(usersFiltered)
		} else {
			const usersInactive = todayUsers.filter(user => user.efficiency === 'NaN' && user.isAbsent === false)
			setUsersData(oldState => [...oldState, ...usersInactive])
		}
	}

	function handleToggleFilterAbsentUsers() {
		setShowAbsentUsers(!showAbsentUsers)

		let usersFiltered = []

		if (showAbsentUsers) {
			usersFiltered = usersData.filter(user => user.isAbsent === false)

			setUsersData(usersFiltered)
		} else {
			const usersAbsent = todayUsers.filter(user => user.isAbsent === true)
			setUsersData(oldState => [...oldState, ...usersAbsent])
		}
	}

	function handleToggleFilterHappy() {
		setShowHappy(!showHappy)

		let usersFiltered = []

		if (showHappy) {
			usersFiltered = usersData.filter(user => user.mood !== 0)
			setUsersData(usersFiltered)
		} else {
			const usersHappy = todayUsers.filter(user => user.mood === 0)

			if (showActiveUsers) {
				setUsersData(oldState => [...oldState, ...usersHappy])
			}
		}
	}

	function handleToggleFilterOk() {
		setShowOk(!showOk)

		let usersFiltered = []

		if (showOk) {
			usersFiltered = usersData.filter(user => user.mood !== 1)
			setUsersData(usersFiltered)
		} else {
			const usersOk = todayUsers.filter(user => user.mood === 1)

			if (showActiveUsers) {
				setUsersData(oldState => [...oldState, ...usersOk])
			}
		}
	}

	function handleToggleFilterSad() {
		setShowSad(!showSad)

		let usersFiltered = []

		if (showSad) {
			usersFiltered = usersData.filter(user => user.mood !== 2)
			setUsersData(usersFiltered)
		} else {
			const usersSad = todayUsers.filter(user => user.mood === 2)

			if (showActiveUsers) {
				setUsersData(oldState => [...oldState, ...usersSad])
			}
		}
	}

	useEffect(() => {
		if (props.stats.realtime) {

			let happy = 0;
			let ok = 0;
			let sad = 0;

			for (let m of props.stats.realtime) {
				if (m.mood == 2) {
					sad++;
				}

				if (m.mood == 1) {
					ok++;
				}

				if (m.mood == 0) {
					happy++;
				}
			}

			setTotalHappyUsers(happy);
			setTotalOkUsers(ok);
			setTotalSadUsers(sad);

		}
	}, [props.stats])


	useEffect(() => {

		if (isMountedFilter.current) {


			if (props.filter.teammates && props.filter.teammates.length == 1) {

				if (props.filter.singleDate) {
					filterActions.set({ startDate: props.filter.singleDate, endDate: props.filter.singleDate }, "when");
				}


				history.push("/productive/activities")

			}
		} else {
			isMountedFilter.current = true;
		}


	}, [props.filter.teammates])

	useEffect(() => {

		if (props.stats.realtimeTooltip && props.stats.realtimeTooltip.origin) {

			let elem = document.querySelectorAll('.tooltip.show');

			if (elem.length > 0) {
				[].forEach.call(elem, function (el) {
					el.innerHTML = props.stats.realtimeTooltip.origin;

				});
			}
		}


	}, [props.stats.realtimeTooltip])

	return (
		<div className="box teammates noBg">
			<div className="pageHeader compact realtime">
				<h3>{t('realtime.title')} <span><IconInfo /><div
					className="tooltip">{/*<IconInfo/>*/}{t('realtime.tooltip')}</div></span></h3>
				<div className="internalFilters">


					{showActiveUsers ? (
						<div className="filter canFilter active work" onClick={handleToggleFilterActiveUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{ActiveUsersToday}</div>
							<div className="name">{t('realtime.active_today')}</div>
						</div>
					) : (
						<div className="filter canFilter work" onClick={handleToggleFilterActiveUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{ActiveUsersToday}</div>
							<div className="name">{t('realtime.active_today')}</div>
						</div>
					)}

					{showInactiveUsers ? (
						<div className="filter canFilter active work" onClick={handleToggleFilterInactiveUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{InactiveUsersToday}</div>
							<div className="name">{t('realtime.inactive_today')}</div>
						</div>
					) : (
						<div className="filter canFilter work" onClick={handleToggleFilterInactiveUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{InactiveUsersToday}</div>
							<div className="name">{t('realtime.inactive_today')}</div>
						</div>
					)}

					{showAbsentUsers ? (
						<div className="filter canFilter active work" onClick={handleToggleFilterAbsentUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{ScheduledAbsensesToday}</div>
							<div className="name">{t('realtime.free_today')}</div>
						</div>
					) : (
						<div className="filter canFilter work" onClick={handleToggleFilterAbsentUsers}>
							<div className={"head"}>
								<div className="icon">
									<IconTeammate />
								</div>
							</div>
							<div className={"highlight"}>{ScheduledAbsensesToday}</div>
							<div className="name">{t('realtime.free_today')}</div>
						</div>
					)}

					{/* {showHappy ? (
						<div className="filter canFilter active size2 mood" onClick={handleToggleFilterHappy}>
							<div className={"head"}>
								<div className="icon happy">
									<IconHappy />
								</div>
							</div>
							<div className={"highlight"}>{TotalHappyUsers}</div>
							<div className="name">{t('mood.happy')}</div>
						</div>
					) : (
						<div className="filter canFilter size2 mood" onClick={handleToggleFilterHappy}>
							<div className={"head"}>
								<div className="icon happy">
									<IconHappy />
								</div>
							</div>
							<div className={"highlight"}>{TotalHappyUsers}</div>
							<div className="name">{t('mood.happy')}</div>
						</div>
					)}

					{showOk ? (
						<div className="filter canFilter active size2 ok" onClick={handleToggleFilterOk}>
							<div className={"head"}>
								<div className="icon ok">
									<IconOk />
								</div>
							</div>
							<div className="highlight">{TotalOkUsers}</div>
							<div className="name">{t('mood.ok')}</div>
						</div>
					) : (
						<div className="filter canFilter size2" onClick={handleToggleFilterOk}>
							<div className={"head"}>
								<div className="icon ok">
									<IconOk />
								</div>
							</div>
							<div className="highlight">{TotalOkUsers}</div>
							<div className="name">{t('mood.ok')}</div>
						</div>
					)}

					{showSad ? (
						<div className="filter canFilter active size2 sad" onClick={handleToggleFilterSad}>
							<div className={"head"}>
								<div className="icon sad">
									<IconSad />
								</div>
							</div>
							<div className="highlight">{TotalSadUsers}</div>
							<div className="name">{t('mood.sad')}</div>
						</div>
					) : (
						<div className="filter canFilter size2 sad" onClick={handleToggleFilterSad}>
							<div className={"head"}>
								<div className="icon sad">
									<IconSad />
								</div>
							</div>
							<div className="highlight">{TotalSadUsers}</div>
							<div className="name">{t('mood.sad')}</div>
						</div>
					)} */}

					<div className="filter active  ">
						<div className={"head"}>
							<div className="icon">
								<IconCompleted />
							</div>
						</div>
						<div className="highlight">{AverageStart}</div>
						<div className="name">{t('realtime.avarage_start')}</div>
					</div>
					{userDetails.allowed_total_time === true && (
						<div className="filter active time ">
							<div className={"head"}>
								<div className="icon">
									<IconCompleted />
								</div>
							</div>
							<div className="highlight">{PercentExpectedWorkedTime}%</div>
							<div className="name">{t('realtime.time_goal')}</div>
						</div>
					)}
					{userDetails.allowed_productive.includes('related') && (
					<div className="filter work active  ">
						<div className={"head"}>
							<div className="icon">
								<IconCompleted />
							</div>
						</div>
						<div className="highlight">{PercentProductiveTime}%</div>
						<div className="name">{t('realtime.work_goal')}</div>
					</div>
					)}


				</div>


			</div>
			<div className="dataTable realtime"  style={{overflow:'auto'}}>
				{data.length > 0 &&
					<table {...getTableProps()}>
						<thead>

							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => {
										if (column.show) {
										return <th {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											{/* Add a sort direction indicator */}
											<span>
												{column.isSorted
													? column.isSortedDesc
														? <div className="arrowDown"></div>
														: <div className="arrowUp"></div>
													: ''}
											</span>
										</th>
										}
									})}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>

							{rows.map(
								(row, i) => {

									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												if (cell.column.show) {
													return (
														<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
													)
												}
												return null
											})}
										</tr>
									)
								}
							)}
						</tbody>
					</table>
				}
				{data.length == 0 &&
					<p className={'noData'}>{t('no_data')}</p>
				}

			</div>


		</div>
	)
};

function mapDispatchToProps(dispatch) {

	return {
		filterActions: bindActionCreators(filterActions, dispatch),
		statsActions: bindActionCreators(statsActions, dispatch),
	}
}

function mapStateToProps(state) {

	return {
		stats: state.stats,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeFull)
