import React from 'react';

const IconChart = props => {
    return (


        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path id="azul" d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Zm0,5.245A10.755,10.755,0,1,1,5.245,16H16Zm0-2.884A13.639,13.639,0,1,1,2.361,16,13.639,13.639,0,0,1,16,2.361Z" fill="#187dff" fillRule="evenodd"/>
      </svg>
      
    )
}

export default IconChart;