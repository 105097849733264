import { Modal, Slide, makeStyles } from '@material-ui/core';
import { Backdrop, Box, Link, Stack, Typography } from '@mui/material';
import { Warning } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from '../../_icons/IconClose';
import style from './ModalRemindSign.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const linkSX = {
  cursor: 'pointer',
  textDecoration: 'none'
};

export function ModalRemindSign({
  openModal,
  handleCloseModal,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="30px"
          px="26px"
          borderRadius="8px"
          gap="4px"
          width={420}
          component="form"
          noValidate
          autoComplete='off'
          position="relative"
        >
          <button
            onClick={handleCloseModal}
            className={style.close}
          >
            <IconClose />
          </button>

          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="8px">
            <Warning size={44} weight="fill" color="#FDBE2B" />

            <Stack direction="column" alignItems="center" justifyContent="center" gap="16px">
              <Typography fontSize={14} color="#4F7091" fontWeight={500} textAlign="center">
                {t("checkin.modal_alert.modal_remind")}<br />
                {t("checkin.modal_alert.sub_title")}
                <Link href="/virtual-point/history" fontSize={14} fontWeight={600} color="#1E6B7F" sx={linkSX}>{" "}{t("menu.historical")}</Link>
                <br /> {t("checkin.modal_alert.sub_title2")}
              </Typography>

              <Link href="/virtual-point/history" color="#1E6B7F" fontSize={16} textDecoration="underline" style={{ cursor: 'pointer' }}>{t("checkin.modal_alert.info3")}</Link>
            </Stack>

          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}

