import React, { useState } from 'react'
import style from './BoardCard.module.scss';
import { AvatarGroup, Box, Button, CardActionArea, CardActions, Grid, Menu, MenuItem, Popover } from '@mui/material';
import { authHeader, history } from '../../_helpers';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ExpandMore, Favorite, MoreVert, Share } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom';
import ModalEditBoard from './ModalEditBoard';
import { useEffect } from 'react';
import { api } from '../../_helpers/api';
import { useTranslation } from 'react-i18next';
import { Clock, Heart, Star, UsersThree } from 'phosphor-react';


export default function BoardCard({
  boards,
  userDetails,
  alertActions,
  colabs,
  getBoards,
  workspaces=[]
}) {
  const { t } = useTranslation('common');

  const [currentBoard, setCurrentBoard] = useState(null)
  const [openModalEditBoard, setOpenModalEditBoard] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleting, setDeleting] = useState(false)
  const [anchorElDeleteBoard, setAnchorElDeleteBoard] = useState(null);
  const handleClickDeleteBoard = (event) => {
    setAnchorElDeleteBoard(event.currentTarget);
  };
  const handleCloseDeleteBoard = () => {
    setAnchorElDeleteBoard(null);
  };

  const open = Boolean(anchorEl);
  const openDeleteBoard = Boolean(anchorElDeleteBoard);
  const id = openDeleteBoard ? 'simple-popover-delete-goal' : undefined;

  const handleClick = (event, board) => {
    setCurrentBoard(board)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentBoard(null)
  };

  const handleDeleteBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.delete(`/project-management/boards/${currentBoard?.id}`,
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getBoards()
      alertActions.success(t('project_management.success.deleted_board'))
      setDeleting(false)
    } catch (error) {
      alertActions.success(t('project_management.errors.deleted_board'))
      setDeleting(false)
    }
  }

  useEffect(() => {
    if (openModalEditBoard === false) {
      handleClose()
    }
  }, [openModalEditBoard])

  return (
    <Box display={`flex`} flexDirection={'column'} gap={'34px'}>
      <Box>
        <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
          <Star size={24} color="#1E6B7F" />
          <Typography variant='h6' style={{ color: '#1E6B7F' }}>Quadros favoritos</Typography>
        </Box>
        <Grid container spacing={2}>
          {
            boards.map((board, index) =>
              <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={2} position={'relative'}>
                <Link
                  className={style.linkBoard}
                  to={`/project_management/boards/${board.id}`}
                >
                  <Card
                    style={{ borderRadius: '8px' }}
                    className={style.boardCard}
                  >
                    <CardHeader
                      style={{
                        padding: "12px 12px 0px"
                      }}
                      title={<Typography variant='subtitle2' fontSize={'14px'} fontWeight={500} sx={{ color: '#1E6B7F' }}>{board.title}</Typography>}
                    />

                    <CardContent style={{ padding: '8px 12px' }}>
                      <Typography className={style.cardContentDescription} variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                        {
                          board.description
                            ?

                            board.description.length >= 100 ? board.description.slice(0, 100) + '...' : board.description

                            :
                            'Nenhuma descrição...'
                        }
                      </Typography>
                    </CardContent>
                    <CardActions style={{ padding: '0px 12px 8px' }}>
                      <Box className={style.cardContentColabs}>
                        {
                          board.colabs.length > 0
                            ?
                            <AvatarGroup
                              max={4}
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              {
                                board.colabs.length <= 4
                                  ?
                                  board.colabs.map((colab) => (
                                    <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                  ))
                                  :
                                  <>
                                    {
                                      board.colabs.slice(0, 5).map((colab, index) => index < 4 ? (
                                        <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                      ) : <Avatar style={{ width: 28, height: 28, marginLeft: '-10px', display: 'flex', alignItems: 'flex-start', lineHeight: '20px' }} >...</Avatar>
                                      )
                                    }

                                  </>

                              }
                            </AvatarGroup>
                            :
                            <Typography variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                              Nenhum membro...
                            </Typography>
                        }
                      </Box>
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            )
          }
        </Grid>
      </Box>

      <Box>
        <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
          <Clock size={24} color="#1E6B7F" />
          <Typography variant='h6' style={{ color: '#1E6B7F' }}>Quadros acessados recentemente</Typography>
        </Box>
        <Grid container spacing={2}>
          {
            boards.map((board, index) =>
              <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={2} position={'relative'}>
                <Link
                  className={style.linkBoard}
                  to={`/project_management/boards/${board.id}`}
                >
                  <Card
                    style={{ borderRadius: '8px' }}
                    className={style.boardCard}
                  >
                    <CardHeader
                      style={{
                        padding: "12px 12px 0px"
                      }}
                      title={<Typography variant='subtitle2' fontSize={'14px'} fontWeight={500} sx={{ color: '#1E6B7F' }}>{board.title}</Typography>}
                    />

                    <CardContent style={{ padding: '8px 12px' }}>
                      <Typography className={style.cardContentDescription} variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                        {
                          board.description
                            ?

                            board.description.length >= 100 ? board.description.slice(0, 100) + '...' : board.description

                            :
                            'Nenhuma descrição...'
                        }
                      </Typography>
                    </CardContent>
                    <CardActions style={{ padding: '0px 12px 8px' }}>
                      <Box className={style.cardContentColabs}>
                        {
                          board.colabs.length > 0
                            ?
                            <AvatarGroup
                              max={4}
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              {
                                board.colabs.length <= 4
                                  ?
                                  board.colabs.map((colab) => (
                                    <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                  ))
                                  :
                                  <>
                                    {
                                      board.colabs.slice(0, 5).map((colab, index) => index < 4 ? (
                                        <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                      ) : <Avatar style={{ width: 28, height: 28, marginLeft: '-10px', display: 'flex', alignItems: 'flex-start', lineHeight: '20px' }} >...</Avatar>
                                      )
                                    }

                                  </>

                              }
                            </AvatarGroup>
                            :
                            <Typography variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                              Nenhum membro...
                            </Typography>
                        }
                      </Box>
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            )
          }
        </Grid>
      </Box>
      <Box>
        <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
          <UsersThree size={24} color="#1E6B7F" />
          <Typography variant='h6' style={{ color: '#1E6B7F' }}>Minhas áreas de trabalho</Typography>
        </Box>
        <Grid container spacing={2}>
          {
            boards.map((board, index) =>
              <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={2} position={'relative'}>
                <Link
                  className={style.linkBoard}
                  to={`/project_management/boards/${board.id}`}
                >
                  <Card
                    style={{ borderRadius: '8px', backgroundColor: board.color || '#ffffff' }}
                    className={style.boardCard}
                  >
                    <CardHeader
                      style={{
                        padding: "12px 12px 0px"
                      }}
                      title={<Typography variant='subtitle2' fontSize={'14px'} fontWeight={500} sx={{ color: '#1E6B7F' }}>{board.title}</Typography>}
                    />

                    <CardContent style={{ padding: '8px 12px' }}>
                      <Typography className={style.cardContentDescription} variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                        {
                          board.description
                            ?

                            board.description.length >= 100 ? board.description.slice(0, 100) + '...' : board.description

                            :
                            'Nenhuma descrição...'
                        }
                      </Typography>
                    </CardContent>
                    <CardActions style={{ padding: '0px 12px 8px' }}>
                      <Box className={style.cardContentColabs}>
                        {
                          board.colabs.length > 0
                            ?
                            <AvatarGroup
                              max={4}
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              {
                                board.colabs.length <= 4
                                  ?
                                  board.colabs.map((colab) => (
                                    <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                  ))
                                  :
                                  <>
                                    {
                                      board.colabs.slice(0, 5).map((colab, index) => index < 4 ? (
                                        <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                      ) : <Avatar style={{ width: 28, height: 28, marginLeft: '-10px', display: 'flex', alignItems: 'flex-start', lineHeight: '20px' }} >...</Avatar>
                                      )
                                    }

                                  </>

                              }
                            </AvatarGroup>
                            :
                            <Typography variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                              Nenhum membro...
                            </Typography>
                        }
                      </Box>
                    </CardActions>
                  </Card>
                </Link>
                <Box position={'absolute'} top={'20px'} right={'4px'}>
                  <IconButton aria-label="settings" size='small' onClick={(event) => handleClick(event, board)}>
                    <MoreVert fontSize='8px' />
                  </IconButton>
                </Box>
              </Grid>
            )
          }

          <Popover
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableEnforceFocus
          >
            <Box className={style.menuOptionsBoard}>
              <button className={style.menuOptionsBoardButton} onClick={() => setOpenModalEditBoard(true)}>Editar</button>
              <button className={style.menuOptionsBoardButton} aria-describedby={id} onClick={handleClickDeleteBoard}>Excluir</button>
            </Box>
          </Popover>

          <Popover
            id={id}
            open={openDeleteBoard}
            anchorEl={anchorElDeleteBoard}
            onClose={handleCloseDeleteBoard}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableEnforceFocus
          >
            <Box
              sx={{
                backgroundColor: '#FFE0DF'
              }}
            >
              <Typography variant="subtitle2" sx={{ p: 2, color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_delete')}</Typography>
              <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
                <Button
                  onClick={handleCloseDeleteBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#fff',
                    color: '#FC6662',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.no')}
                </Button>
                <Button
                  onClick={handleDeleteBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#FC6662',
                    color: '#fff',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.yes')}
                </Button>
              </Box>
            </Box>
          </Popover>


          <ModalEditBoard
            openModalEditBoard={openModalEditBoard}
            setOpenModalEditBoard={setOpenModalEditBoard}
            userDetails={userDetails}
            colabsOptions={colabs}
            alertActions={alertActions}
            updateInfo={getBoards}
            currentBoard={currentBoard}
            closeMenu={handleClose}
            workspaces={workspaces}
          />

        </Grid>
      </Box>
      <Box>
        <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
          <UsersThree size={24} color="#1E6B7F" />
          <Typography variant='h6' style={{ color: '#1E6B7F' }}>Áreas de trabalho compartilhadas comigo</Typography>
        </Box>
        <Grid container spacing={2}>
          {
            boards.map((board, index) =>
              <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={2} position={'relative'}>
                <Link
                  className={style.linkBoard}
                  to={`/project_management/boards/${board.id}`}
                >
                  <Card
                    style={{ borderRadius: '8px' }}
                    className={style.boardCard}
                  >
                    <CardHeader
                      style={{
                        padding: "12px 12px 0px"
                      }}
                      title={<Typography variant='subtitle2' fontSize={'14px'} fontWeight={500} sx={{ color: '#1E6B7F' }}>{board.title}</Typography>}
                    />

                    <CardContent style={{ padding: '8px 12px' }}>
                      <Typography className={style.cardContentDescription} variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                        {
                          board.description
                            ?

                            board.description.length >= 100 ? board.description.slice(0, 100) + '...' : board.description

                            :
                            'Nenhuma descrição...'
                        }
                      </Typography>
                    </CardContent>
                    <CardActions style={{ padding: '0px 12px 8px' }}>
                      <Box className={style.cardContentColabs}>
                        {
                          board.colabs.length > 0
                            ?
                            <AvatarGroup
                              max={4}
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              {
                                board.colabs.length <= 4
                                  ?
                                  board.colabs.map((colab) => (
                                    <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                  ))
                                  :
                                  <>
                                    {
                                      board.colabs.slice(0, 5).map((colab, index) => index < 4 ? (
                                        <Avatar key={colab.id} alt={colab.name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(colab.name)} style={{ width: 28, height: 28 }} />
                                      ) : <Avatar style={{ width: 28, height: 28, marginLeft: '-10px', display: 'flex', alignItems: 'flex-start', lineHeight: '20px' }} >...</Avatar>
                                      )
                                    }

                                  </>

                              }
                            </AvatarGroup>
                            :
                            <Typography variant="caption" color="text.secondary" component={'div'} display={'flex'} alignItems={'center'}>
                              Nenhum membro...
                            </Typography>
                        }
                      </Box>
                    </CardActions>
                  </Card>
                </Link>
                <Box position={'absolute'} top={'20px'} right={'4px'}>
                  <IconButton aria-label="settings" size='small' onClick={(event) => handleClick(event, board)}>
                    <MoreVert fontSize='8px' />
                  </IconButton>
                </Box>
              </Grid>
            )
          }

          <Popover
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableEnforceFocus
          >
            <Box className={style.menuOptionsBoard}>
              <button className={style.menuOptionsBoardButton} onClick={() => setOpenModalEditBoard(true)}>Editar</button>
              <button className={style.menuOptionsBoardButton} aria-describedby={id} onClick={handleClickDeleteBoard}>Excluir</button>
            </Box>
          </Popover>

          <Popover
            id={id}
            open={openDeleteBoard}
            anchorEl={anchorElDeleteBoard}
            onClose={handleCloseDeleteBoard}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableEnforceFocus
          >
            <Box
              sx={{
                backgroundColor: '#FFE0DF'
              }}
            >
              <Typography variant="subtitle2" sx={{ p: 2, color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_delete')}</Typography>
              <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
                <Button
                  onClick={handleCloseDeleteBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#fff',
                    color: '#FC6662',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.no')}
                </Button>
                <Button
                  onClick={handleDeleteBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#FC6662',
                    color: '#fff',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.yes')}
                </Button>
              </Box>
            </Box>
          </Popover>


          <ModalEditBoard
            openModalEditBoard={openModalEditBoard}
            setOpenModalEditBoard={setOpenModalEditBoard}
            userDetails={userDetails}
            colabsOptions={colabs}
            alertActions={alertActions}
            updateInfo={getBoards}
            currentBoard={currentBoard}
            closeMenu={handleClose}
            workspaces={workspaces}
          />

        </Grid>
      </Box>
    </Box>
  )
}
