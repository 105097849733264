import React, {useRef, useState, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";

import {useTable, useSortBy, useRowSelect} from 'react-table'

import {secondsToHm} from '../../_helpers';

import IconShowPassword from '../_icons/IconShowPassword';

function DrawerActivityTableTeammates(props) {
    const {t, i18n} = useTranslation('common');

    let detailsTeammates = props.detailsTeammates || [];
    let current = props.current || {};
    let stats = props.stats || {};
    let permissions = props.permissions || {};

    const dataTeammates = useMemo(() => detailsTeammates, []);

    const columnsTeammates = React.useMemo(
        () => [
            {
                Header: t('activities.teammate'),
                accessor: 'machineUserName', // accessor is the "key" in the data
                Cell: (d) => {
                    return d.value
                }
            },
            {
                Header: t('activities.team'),
                accessor: 'teamName', // accessor is the "key" in the data
            },
            {
                Header: t('activities.total_time'),
                accessor: 'duration',
                Cell: (d) => {

                    let totalTime = secondsToHm(d.value);
                    let percentTime = 0;
                    let duration = '';

                    if (current.productiveDuration > 0) {
                        percentTime = Math.round((d.value / current.productiveDuration) * 100)
                    }

                    if (d.row.original.efficiencyName == 'productive') {
                        duration = <div className="duration related">{totalTime}<span>{percentTime}%</span></div>
                    }

                    if (d.row.original.efficiencyName == 'neutral') {
                        duration = <div className="duration neutral">{totalTime}<span>{percentTime}%</span></div>
                    }

                    if (d.row.original.efficiencyName == 'unproductive') {
                        duration = <div className="duration unrelated">{totalTime}<span>{percentTime}%</span></div>
                    }

                    return duration
                }
            },
            {
                Header: t('activities.classification'),
                accessor: 'efficiencyName',
                Cell: (d) => {

                    let classification = '';

                    if (d.value == 'productive') {
                        classification = <div className="related">{t('activities.work_related')}</div>;
                    }
                    if (d.value == 'neutral') {
                        classification = <div className="neutral">{t('activities.neutral')}</div>;
                    }

                    if (d.value == 'unproductive') {
                        classification = <div className="unrelated">{t('activities.work_unrelated')}</div>;
                    }


                    return classification

                }
            },
            {
                Header: '',
                accessor: 'machineUserId',
                Cell: (d) => {
                    return <button className="details" onClick={() => {
                        openSpecific(d.row.original.machineUserId, d.row.original.machineUserName, d.row.original.teamName)
                    }}><IconShowPassword/></button>
                }
            }

        ],
        []
    );

    function openSpecific(machineUserId, machineUserName, teamName) {
        props.openSpecific({machineUserId: machineUserId, data: {machineUserName, teamName}});
    }


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: columnsTeammates,
            data: dataTeammates,
        },
        useSortBy
    )


    return (


        <div className="dataTable teammates">
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <div className="arrowDown"></div>
                                                : <div className="arrowUp"></div>
                                            : ''}
                                    </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                })}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>


        </div>


    )
};

function mapStateToProps(state) {

    return {
        detailsTeammates: state.activities.detailsTeammates,
        stats: state.stats.payload,
        permissions: state.authentication.permissions
    }

}

export default connect(mapStateToProps)(DrawerActivityTableTeammates)
