import React from 'react'
import style from './styles.module.scss';
import { Box, Skeleton } from '@mui/material';

export default function Widget15Skeleton() {

  return (
    <div className={style.container}>
     <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        <Skeleton variant='h6' width={'30%'} />
      </Box>

      <Box display={'flex'} gap={'6px'}>
        <Skeleton variant='circular' width={30} height={30} />
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'center'}>
            <Skeleton variant='h6' width={'70%'} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Skeleton variant='h6' width={40} />
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} gap={'6px'}>
        <Skeleton variant='circular' width={30} height={30} />
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'center'}>
            <Skeleton variant='h6' width={'70%'} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Skeleton variant='h6' width={40} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}
