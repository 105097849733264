import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { Box, Button, CircularProgress, Grid, IconButton, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';

import MaskedInput from 'react-text-mask';
import style from './ModalCreateLead.module.scss';
import IconClose from '../_icons/IconClose';


const PhoneMaskPT = React.forwardRef((props, ref) => {

  return (
    <MaskedInput
      {...props}
      ref={ref}
      mask={[
        '+',
        '5',
        '5', // Código de país Brasil (+55)
        ' ', // Espaço separador
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});

// Máscara para número de telefone do México (ES)
const PhoneMaskES = React.forwardRef((props, ref) => {

  return (
    <MaskedInput
      {...props}
      ref={ref}
      mask={[
        '+',
        '5',
        '2', // Código de país México (+52)
        ' ', // Espaço separador
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});

// Máscara para número de telefone dos Estados Unidos (EN)
const PhoneMaskEN = React.forwardRef((props, ref) => {

  return (
    <MaskedInput
      {...props}
      ref={ref}
      mask={[
        '+',
        '1', // Código de país EUA (+1)
        ' ', // Espaço separador
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalCreateLead({
  openModal,
  closeModal,
  userDetails,
  moduleKey,
  alertActions
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');
  const lang = userDetails.language || i18n.language

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);

  const [form, setForm] = useState({
    name: '',
    email: '',
    whatsapp: '',
    company: '',
    message: ''
  })
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    whatsapp: '',
    company: ''
  })

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    setFormErrors({
      ...formErrors,
      [e.target.name]: '',
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.name) {
      errors.name = t("validation.required")
    }

    if (!form.email) {
      errors.email = t("validation.required")
    }

    if (!form.whatsapp) {
      errors.whatsapp = t("validation.required")
    }

    if (!form.company) {
      errors.company = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      name: '',
      email: '',
      whatsapp: '',
      company: '',
      message: ''
    })
    setFormErrors({
      name: '',
      email: '',
      whatsapp: '',
      company: ''
    })
  }

  async function createLead() {
    try {
      setIsSubmitting(true)

      const payload = {
        ...form,
        module_key: moduleKey
      }

      const res = await api.post('/switch/lead', payload, {headers: authHeader()})
      
      if(res.status !== 200){
        throw new Error('Erro to send lead')
      }

      alertActions.success(t('form_lead.success_create_message'))
      
      handleCloseModal()
    } catch (error) {
      console.log(error)
      alertActions.error(t("generic_messages.errors.send"))
    } finally {
      setIsSubmitting(false)
    }
  }

  function handleCloseModal() {
    closeModal()
    resetForm()
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      createLead()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  const renderPhoneMask = (lang) => {
    switch (lang) {
      case 'pt':
        return PhoneMaskPT;
      case 'en':
        return PhoneMaskEN;
      case 'es':
        return PhoneMaskES;
      default:
        return PhoneMaskEN; // Máscara padrão, caso o idioma não seja reconhecido
    }
  };


  const PhoneMask = renderPhoneMask(lang);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${style.modalForm} ${classes.modal}`}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={style.modalFormContainer}>
          <button onClick={handleCloseModal} className={style.close}>
            <div><IconClose /></div>
          </button>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="32px"
            px="26px"
            borderRadius="8px"
            gap="28px"
            width={420}
            component="form"
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >

            <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
              {t('form_lead.title')}
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label={`${t('form_lead.field_name')}*`}
                    type='text'
                    placeholder={t('form_lead.placeholder_name')}
                    value={form.name}
                    onChange={handleChange}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: "#4F7091" } }}
                    error={formErrors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    label={`${t('form_lead.field_email')}*`}
                    type='email'
                    placeholder={t('form_lead.placeholder_email')}
                    value={form.email}
                    onChange={handleChange}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: "#4F7091" } }}
                    error={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="whatsapp"
                    name="whatsapp"
                    label={`${t('form_lead.field_whatsapp')}*`}
                    // InputLabelProps={{
                    //   shrink: true
                    // }}
                    placeholder='Ex.: +999 (99) 99999-9999'
                    type='text'
                    helperText={t('form_lead.helper_text_whatsapp')}
                    // InputProps={{
                    //   inputComponent: PhoneMask,
                    // }}
                    value={form.whatsapp}
                    onChange={handleChange}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: "#4F7091" } }}
                    error={formErrors.whatsapp}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="company"
                    label={`${t('form_lead.field_company')}*`}
                    placeholder={t('form_lead.placeholder_company')}
                    type='text'
                    value={form.company}
                    onChange={handleChange}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: "#4F7091" } }}
                    error={formErrors.company}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    label={`${t('form_lead.field_message')}`}
                    placeholder={t('form_lead.placeholder_message')}
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={form.message}
                    onChange={handleChange}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, textarea: { color: "#4F7091" } }}
                    inputProps={{maxLenght: 5}}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
                    <Button
                      className={style.cancelBtn}
                      onClick={handleCloseModal}
                    >
                      <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>
                      {t('form_lead.btn_cancel')}
                      </Typography>
                    </Button>
                    <Button
                      className={style.save}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={14} />
                      ) : (
                        <Typography
                          component="span"
                          fontSize={12}
                          color='#fff'
                          textTransform="initial"
                          fontWeight={500}
                        >
                          {t('form_lead.btn_send')}
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Slide>
    </Modal>
  )
}
