import {shareConstants} from '../_constants';
import {shareService} from "../_services";
import {alertActions} from "./alert.actions";

export const shareActions = {
    openModal,
    closeModal,
    getFile,
    openModalFormGoals,
    closeModalFormGoals,
    openModalAddTeam,
    closeModalAddTeam,
    openModalAddKeyResult,
    closeModalAddKeyResult,
    openModalEditKeyResult,
    closeModalEditKeyResult
};

function openModal() {
    return { type: shareConstants.OPEN_MODAL};
}

function closeModal() {
    return { type: shareConstants.CLOSE_MODAL};
}

function getFile({ format, report, filters, type, setLoadingDoDownload, loadingDoDownload, typeExportView, paginationFilters}) {
    return dispatch => {
        dispatch(request());

        shareService.getFile({ format, report, filters, type, typeExportView, paginationFilters})
            .then(
                payload => {
                    setLoadingDoDownload(false);
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: shareConstants.GET_FILE_REQUEST } }
    function success(payload) { return { type: shareConstants.GET_FILE_SUCCESS, payload } }
    function failure(error) { return { type: shareConstants.GET_FILE_FAILURE, error } }
}

function openModalFormGoals() {
    return { type: shareConstants.OPEN_MODAL_FORM_GOALS};
}

function closeModalFormGoals() {
    return { type: shareConstants.CLOSE_MODAL_FORM_GOALS};
}


function openModalAddTeam() {
    return { type: shareConstants.OPEN_MODAL_ADD_TEAM};
}

function closeModalAddTeam() {
    return { type: shareConstants.CLOSE_MODAL_ADD_TEAM};
}

function openModalAddKeyResult() {
    return { type: shareConstants.OPEN_MODAL_ADD_KEY_RESULT};
}

function closeModalAddKeyResult() {
    return { type: shareConstants.CLOSE_MODAL_ADD_KEY_RESULT};
}

function openModalEditKeyResult() {
    return { type: shareConstants.OPEN_MODAL_EDIT_KEY_RESULT};
}

function closeModalEditKeyResult() {
    return { type: shareConstants.CLOSE_MODAL_EDIT_KEY_RESULT};
}
