import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../../_icons/IconClose";
import { api } from "../../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../../_actions";
import { authHeader } from "../../../_helpers";

import "./styles.scss"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalNewMap({
  openModalNewMap,
  setOpenModalNewMap,
  alertActions,
  updateInfo
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    name: '',
    description: ''
  });
  const [formError, setFormError] = useState({
    name: '',
    description: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function closeModalNewMap() {
    setOpenModalNewMap(false)
    setForm({
      name: '',
      description: ''
    })
    setFormError({
      name: '',
      description: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.name) {
      errors.name = t('messages.modal_new_message.required_field')
    }

    return errors
  }


  const handleSubmitMap = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createMap = async () => {
    try {
      setIsSubmitting(true)

      const res = await api.post(`/rooms/maps`, form,
        {
          headers: authHeader()
        }
      )
      if (res.data) {
        updateInfo()
        alertActions.success(t('spaces.success.create'))
        closeModalNewMap()
        setIsSubmitting(false)
      }

    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('spaces.error.create'))
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createMap()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormMap ${classes.modal}`}
        open={openModalNewMap}
        onClose={closeModalNewMap}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalNewMap} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormMap"}>

            <button onClick={closeModalNewMap} className="close"><IconClose /></button>

            <form onSubmit={handleSubmitMap}>
              <main>
                <header>
                  <h3>
                    {t('spaces.maps.modal.title_new_map')}
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="name">{t('spaces.maps.modal.name')}*</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    minLength={2}
                    maxLength={60}
                    placeholder={t('spaces.maps.modal.name_placeholder')}
                    value={form.name || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.name.length}/60</span>
                  {formError.name &&
                    <div className="error">{formError.name}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="description">{t('spaces.maps.modal.description')}</label>
                  <textarea
                    id="description"
                    type="text"
                    name="description"
                    maxLength={150}
                    rows={3}
                    placeholder={t('spaces.maps.modal.description_placeholder')}
                    value={form.description || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.description.length}/150</span>
                  {formError.description &&
                    <div className="error">{formError.description}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={closeModalNewMap}
                    >
                      {t('spaces.maps.modal.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('spaces.maps.modal.create')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalNewMap));
