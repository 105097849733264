import React from 'react';

const IconJustify = props => {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1280" data-name="Retângulo 1280" width="19" height="19" fill="none" />
      </g>
      <g id="Layer_6" data-name="Layer 6" transform="translate(0.732 0.792)">
        <g id="Grupo_248" data-name="Grupo 248">
          <path id="Caminho_302" data-name="Caminho 302" d="M26.429,17.4,23.3,14.238a.831.831,0,0,0-1.069-.079.752.752,0,0,0-.079,1.188l1.821,1.821H14.792a.792.792,0,0,0,0,1.583h9.183l-1.821,1.821a.752.752,0,0,0,.079,1.188A.831.831,0,0,0,23.3,21.68l3.127-3.167a.752.752,0,0,0,0-1.108Z" transform="translate(-9.191 -9.251)" fill="#98acc1" />
          <path id="Caminho_303" data-name="Caminho 303" d="M12.2,17.833H10.618a7.125,7.125,0,0,1,0-14.25H12.2a.812.812,0,0,0,.792-.792.95.95,0,0,0-.079-.356A.792.792,0,0,0,12.2,2H10.539a8.708,8.708,0,0,0,.04,17.417H12.2a.792.792,0,0,0,.554-.238l.158-.2a.95.95,0,0,0,.079-.356A.792.792,0,0,0,12.2,17.833Z" transform="translate(-1.85 -2)" fill="#98acc1" />
        </g>
      </g>
    </svg>
  )
}

export default IconJustify;