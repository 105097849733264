import React from 'react';

const IconRemove = props => {
    return (

<svg xmlns="http://www.w3.org/2000/svg" width="8" height="9.143" viewBox="0 0 8 9.143">
  <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M.571,8.286a.857.857,0,0,0,.857.857H6.571a.857.857,0,0,0,.857-.857v-6H.571ZM5.429,3.714a.286.286,0,1,1,.571,0v4a.286.286,0,0,1-.571,0Zm-1.714,0a.286.286,0,1,1,.571,0v4a.286.286,0,0,1-.571,0ZM2,3.714a.286.286,0,1,1,.571,0v4a.286.286,0,0,1-.571,0ZM7.714.571H5.571L5.4.237A.429.429,0,0,0,5.02,0H2.979A.424.424,0,0,0,2.6.237L2.429.571H.286A.286.286,0,0,0,0,.857v.571a.286.286,0,0,0,.286.286H7.714A.286.286,0,0,0,8,1.429V.857A.286.286,0,0,0,7.714.571Z" fill="#fc6662"/>
</svg>


      
    )
}

export default IconRemove;