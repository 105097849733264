import React, { useState } from 'react';
import { connect } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation, } from "react-i18next";

import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';

import { Tooltip } from '@material-ui/core';
import { Box, Popover, Typography } from '@mui/material';
import { getWeekDays } from '../../../_helpers/get-week-calendar';

import './styles.scss'
import { getMonthName } from '../../../_helpers/get-month-calendar';

function MoodFull({ moodsData, startDate, endDate }) {
	const { t, i18n } = useTranslation('common');

	const [anchorEl, setAnchorEl] = useState(null);
	const [labelPopover, setLabelPopover] = useState('');
	const [idPopover, setIdPopover] = useState(null);

	const handleClick = (event, id_popover) => {
		setIdPopover(id_popover)
		setLabelPopover(id_popover === 'time_work' ? t('mood.time_work') : t('mood.time_goal'))
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const weekDays = getWeekDays(i18n.language)
	const monthName = getMonthName(endDate + 'T00:00:00', i18n.language)

	return (
		<>
			<header className='mood-header'>
				{i18n.language === 'pt' && (
					<h2>Dia {startDate} a {endDate.split('-')[2]} de {monthName}</h2>
				)}
				{i18n.language === 'en' && (
					<h2>{monthName} {startDate}th to {endDate.split('-')[2]}th</h2>
				)}
				{i18n.language === 'es' && (
					<h2>{startDate} al {endDate.split('-')[2]} de {monthName}</h2>
				)}
			</header>
			<table className='table-mood'>
				<thead>
					<tr>
						<th style={{
							paddingLeft: '16px',
							borderRadius: '2px 0 0 0',
							width: '20%'
						}}
						>
							{t('mood.teammate').toUpperCase()}
						</th>
						<th style={{ width: '25%' }}>{t('activities.team').toUpperCase()}</th>
						{moodsData.length > 0 && moodsData[0].calendarMoods.map(itemDay => (
							<th key={itemDay.weekDay} style={{ width: '5%' }}>{weekDays[itemDay.weekDay]}</th>
						))}
						{moodsData.length === 0 && weekDays.map(day => (
							<th key={day} style={{ width: '5%' }}>{day}</th>
						))}
						<th style={{ borderRadius: '0 2px 0 0', paddingLeft: '70px', paddingRight: '16px' }}>{t('productivity.productivity').toUpperCase()}</th>
					</tr>
				</thead>
				<tbody>
					{moodsData.map(item => (
						<tr key={item.machine_user_id}>
							<td>{item.username}</td>
							<td>{item.ancestry.length > 0 ? (
								<Tooltip
									title={item.ancestry.reduce((acc, ances) => {
										return acc + ances.group_name + ' / '
									}, '') + item.group_name}
									arrow
								>
									<span>
										{(item.ancestry.reduce((acc, ances) => {
											return acc + ances.group_name + ' / '
										}, '') + item.group_name).substring(0, 35)}...
									</span>
								</Tooltip>
							) : (
								<Tooltip title={item.group_name} arrow>
									<span>
										{item.group_name}
									</span>
								</Tooltip>
							)}
							</td>
							{item.calendarMoods.map(moodItem => (
								<td key={moodItem.weekDay}>
									<div className='mood'>
										{
											moodItem.mood > -1 
											? 
											<img src={moodItem.image_url} width={18} loading='lazy'/>
											:
											'--'
										}
									</div>
								</td>
							))}
							<td style={{ paddingLeft: '70px', paddingRight: '16px' }}>
								<div className='productivity-container'>
									<div className='time-work' onClick={(event) => handleClick(event, 'time_work')}>
										<div className='circle' style={{ border: `1px solid #187DFF` }}>
											<CircularProgressbar
												value={item.worked_time * 100}
												strokeWidth={50}
												styles={buildStyles({
													strokeLinecap: "butt",
													trailColor: "#fff",
													pathColor: '#187DFF',
												})}
											/>
										</div>
										<span>
											{(item.worked_time * 100).toFixed(2)}%
										</span>
									</div>
									<div className='time-goal' onClick={(event) => handleClick(event, 'time_goal')}>
										<div className='circle' style={{ border: `1px solid #22BEAD` }}>
											<CircularProgressbar
												value={item.productive_worked_time * 100}
												strokeWidth={50}
												styles={buildStyles({
													strokeLinecap: "butt",
													trailColor: "#fff",
													pathColor: '#22BEAD',
												})}
											/>
										</div>
										<span>
											{(item.productive_worked_time * 100).toFixed(2)}%
										</span>
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						borderRadius: 0
					}
				}}
			>
				<Box
					sx={{
						position: "relative",
						mt: "10px",
						"&::before": {
							backgroundColor: idPopover === "time_work" ? "#187DFF" : "#22BEAD",
							content: '""',
							display: "block",
							position: "absolute",
							width: 12,
							height: 12,
							top: -2,
							transform: "rotate(45deg)",
							left: "calc(70% - 0px)"
						}
					}}
				/>
				<Typography sx={{
					p: '10px 12px',
					backgroundColor: idPopover === "time_work" ? "#187DFF" : "#22BEAD",
					color: '#fff',
					borderRadius: '4px',
					fontSize: '12px'
				}}
				>
					{labelPopover}
				</Typography>
			</Popover>
		</>
	)
};

function mapDispatchToProps(dispatch) {

	return {

	}
}

function mapStateToProps(state) {

	return {

	}

}

export default connect(mapStateToProps, mapDispatchToProps)(MoodFull)
