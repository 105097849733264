import React from 'react';

const IconCheck = props => {
    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" width="10.189" height="7.598" viewBox="0 0 10.189 7.598">
        <path id="V" d="M3.461,12.026.149,8.715a.509.509,0,0,1,0-.72l.72-.72a.509.509,0,0,1,.72,0L3.821,9.5,8.6,4.726a.509.509,0,0,1,.72,0l.72.72a.509.509,0,0,1,0,.72L4.181,12.026A.509.509,0,0,1,3.461,12.026Z" transform="translate(0 -4.577)" fill="#fff"/>
      </svg>
        

    )
}

export default IconCheck;