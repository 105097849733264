import { Box, Container, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonModuleDetailsPage(props) {

  return (

    <Container component={'main'} maxWidth="lg" sx={{ flexGrow: 1, padding: '16px 0px' }}>
      <Grid container spacing={2} mt={'16px'} mb={'32px'}>

        <Grid item xs={3}>
          <Skeleton width={'100%'} height={300} variant='rounded' />
        </Grid>

        <Grid item xs={9}>

          <Skeleton width={'100%'} height={200} variant='rounded' />

          <Box mt={'32px'}>
            <Typography component="div" variant='h1' mb={'10px'}>
              <Skeleton width={150} />
            </Typography>
            <Skeleton width={'100%'} height={240} variant='rounded' />
          </Box>

          <Box mt={'32px'}>
            <Typography component="div" variant='h1' mb={'10px'}>
              <Skeleton width={150} />
            </Typography>
            <Skeleton width={'100%'} height={340} variant='rounded' />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}