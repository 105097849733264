import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import InventoryColabDetailsSkeleton from '../../_components/_shared/inventory/colab_details/InventoryColabDetailsSkeleton';
import { CardsSoftware } from '../../_components/_shared/inventory/software/CardsSoftware';
import TableSoftware from '../../_components/_shared/inventory/software/TableSoftware';
import ModalCreate from '../../_components/_shared/inventory/software/modal/ModalCreate';
import ModalEdit from '../../_components/_shared/inventory/software/modal/ModalEdit';
import { useLocation, useHistory  } from 'react-router-dom';
// import { history } from '../../_helpers';
const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function InventorySoftwarePage(props) {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  const users_qp = queryParams.get('users');
  const open_modal = queryParams.get('open_modal');
  const tracked_id_qp = queryParams.get('tracked_id');
  const type_qp = queryParams.get('type');

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, shareActions, usersActions, filter, responsibles } = props

  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [openModal, setOpenModal] = useState(open_modal || false); 


  
  const [openModalToEdit, setOpenModalToEdit] = useState(false);
  const [data, setData] = useState({
    stats: null,
    data: []
  });
  const [licenseToEdit, setLicenseToEdit] = useState(null);
  let startDate = new Date(moment()).toISOString().split('T')[0];
  let endDate = new Date(moment()).toISOString().split('T')[0];

  const [trackedOptions, setTrackedOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const handleGetTrackedOptions = async (users) => {
   
    try {
      const { data } = await api.get(`/settings/trackeds?users=${users}`, {
        headers: authHeader()
      });
      setTrackedOptions(data)
    } catch (error) {
      console.error('Erro ao buscar trackeds:', error);
    }
  }

  const getCurrencies = async () => {
   
    try {
      const { data } = await api.get(`/settings/currencies`, {
        headers: authHeader()
      });
      setCurrencies(data)
    } catch (error) {
      console.error('Erro get currencies. Erro: ', error);
    }
  }

  const handleDeleteQueryParams = () => {
    queryParams.delete('users');
    queryParams.delete('open_modal');
    queryParams.delete('tracked_id');
    queryParams.delete('type');   
    // Atualiza a URL sem o parâmetro 'exemplo'
    history.push({ search: queryParams.toString() });
    // history.push(`/inventory/software`)
    // navigate({ search: queryParams.toString() });
  }

  async function getDataInventorySoftware(date_start = new Date(moment()).toISOString().split('T')[0], date_end = new Date(moment()).toISOString().split('T')[0], teams = null, teammates = null, isFetching = true) {
    try {

      if (isFetching) {
        setFetching(true)
      }

      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teams=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&users=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      // console.log("/DATA QUERY PARAMS",queryTeammates,queryTeams)
      const response = await api.get(`/inventory/software?current_day=${moment().format("YYYY-MM-DD")}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })
      if (response.status === 200) {
        setData(response.data)
        setFetching(false)
      }


    } catch (error) {
      console.log(error)
      // alertActions.error('Erro ao carregar os dados')
    }
  }

  useEffect(() => {
    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataInventorySoftware(start_date, end_date, teams, teammates, true)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

  useEffect(() => {
    usersActions.getResponsibles(userDetails?.organizationId)
    getCurrencies()
    if(users_qp !== undefined){
      handleGetTrackedOptions(users_qp)
    }
    
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'} 
          startDate={startDate}
          endDate={endDate}
          showButtonCreateLicense={true}
          setOpenModalCreateSoftwarelicense={setOpenModal}
          showShareBtn={false}
          statsLicense={data.stats}
        />

        <div className={style.containerCheckinPage}>
          {
            !fetching ?
              <>
                <CardsSoftware alertActions={alertActions} userDetails={userDetails} resume={data?.stats} />
                <TableSoftware data={data?.data} setOpenModalToEdit={setOpenModalToEdit} setLicenseToEdit={setLicenseToEdit}/>
              </>
              :
              <InventoryColabDetailsSkeleton />
          }

          {
            responsibles && trackedOptions   && <ModalCreate
              openModal={openModal}
              setOpenModal={setOpenModal}
              user={userDetails}
              teammates={responsibles}
              updateInfo={getDataInventorySoftware}
              alertActions={alertActions}
              presetted_users={(users_qp !== null && String(users_qp).split(",").length > 0) ? users_qp.split(",") : null }
              presetted_type={type_qp !== null ? String(type_qp).toLocaleLowerCase() :'software'}
              presetted_tracked_options={trackedOptions}
              presetted_tracked={tracked_id_qp}
              handleDeleteQueryParams={handleDeleteQueryParams}
              handleTrackedOptions={handleGetTrackedOptions}
              currencies={currencies}

            /> 
          }

          {
            (responsibles && licenseToEdit) && <ModalEdit
              openModal={openModalToEdit}
              setOpenModal={setOpenModalToEdit}
              user={userDetails}
              teammates={responsibles}
              updateInfo={getDataInventorySoftware}
              licenseToEdit={licenseToEdit}
              setLicenseToEdit={setLicenseToEdit}
              alertActions={alertActions}
              handleTrackedOptions={handleGetTrackedOptions}
              currencies={currencies}

            />
          }
        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'inventoryColabsReports'} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySoftwarePage)