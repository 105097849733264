import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { authHeader, getMonths } from '../../../_helpers'
import { api } from '../../../_helpers/api'
import { Card } from './Card'
import ModalViewSubspaces from '../ModalViewSubspaces'


export function CardsContainer({ userDetails, locations, alertActions, isLoading, handleOpenModalViewSubspaces, handleOpenDrawer }) {

  const { t, i18n } = useTranslation('common');


  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent={isLoading ? 'center' : 'initial'} gap="8px">

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container rowSpacing={2} spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          {
            locations.length > 0 ?
              locations.map(room => (
                <Card key={room.id} room={room} userDetails={userDetails} handleOpenDrawer={handleOpenDrawer} handleOpenModalViewSubspaces={handleOpenModalViewSubspaces} />
              ))
              :
              <Box width={'100%'} sx={{ bgcolor: '#fff' }} paddingY={3} paddingX={2}>
                <Typography variant='subtitle2' fontWeight={400} color={'#1E6B7F'}>{t('spaces.no_rooms_available')}</Typography>
              </Box>
          }
        </Grid>
      )}
    </Box>
  )
}
