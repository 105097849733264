import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, CircularProgress, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authHeader, befectiveFormatDate } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { useMessagesCount } from '../../../contexts/MessagesCountContext';

import style from './ModalJustify.module.scss';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalJustify({
  openModalJustify,
  lang,
  reasons,
  dayToJustify,
  periodToJustify,
  alertActions,
  setOpenModalJustify,
  pointToJustify,
  summariesDateToJustify,
  typePointToJustify,
  setPointToJustify,
  setSummariesDateToJustify,
  setDayToJustify,
  setTypePointToJustify,
  getColabPoints
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const { getOpenSolicitations } = useMessagesCount()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    time: '00:00',
    reason: "0",
    comment: "",
    files: []
  })
  const [formErrors, setFormErrors] = useState({
    time: null,
    reason: null,
    files: null
  })

  const handleChange = (e, time = false) => {
    if (time) {
      setForm({
        ...form,
        time: e
      })

      return
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);

    setForm({ ...form, files: [...form.files, ...filesArr] });
    setFormErrors({ ...formErrors, files: null })
  }

  const removeFile = (file) => {
    setForm({ ...form, files: form.files.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};

    if (form.time === null || form.time === 'NaN:NaN') {
      errors.time = t("validation.required")
    }

    if (form.reason === "0") {
      errors.reason = t("validation.required")
    }

    if (form.files.length > 0) {
      form.files.forEach(file => {
        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP']

        if ((file.size / 1000000) > 2) {
          errors.files = t('calls_page.error.file')
        }

        const fileNameSplitted = file.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          errors.files = t('calls_page.error.file_type')
        }
      })
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      time: '00:00',
      reason: "0",
      comment: "",
      files: []
    })

    setFormErrors({
      time: null,
      reason: null,
      files: null
    })
  }

  const convertToTime = (valueTime) => {
    const hour = dayjs(valueTime).hour()
    const minute = dayjs(valueTime).minute()
    let hourString = ''
    let minuteString = ''
    if (hour < 10) {
      hourString = '0' + String(hour)
    } else {
      hourString = String(hour)
    }
    if (minute < 10) {
      minuteString = '0' + String(minute)
    } else {
      minuteString = String(minute)
    }
    return hourString + ':' + minuteString
  }


  async function createSolicitation() {
    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('points_id', pointToJustify?.id);
      formData.append('adjustment_reasons_id', form.reason);
      formData.append('comment', form.comment);
      formData.append('summaries_day_ref_id', summariesDateToJustify);
      formData.append('time', form.time);
      formData.append('point_type', typePointToJustify);

      for (let item of form.files) {
        formData.append('files', item)
      }

      await api.post(
        `/checkin/solicitations`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        }
      )

      setOpenModalJustify(false);
      resetForm()
      setPointToJustify(null)
      setSummariesDateToJustify(null)
      setDayToJustify(null)
      alertActions.success(t("checkin.dashboard.alerts.justify_work_success"))
      await getColabPoints()
      setIsSubmitting(false)
      getOpenSolicitations()
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t("checkin.dashboard.alerts.justify_work_error"))
    }
  }

  function handleCloseModalJustify() {
    resetForm()
    setTypePointToJustify(null)
    setPointToJustify(null)
    setSummariesDateToJustify(null)
    setDayToJustify(null)
    setOpenModalJustify(false)
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      createSolicitation()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalJustify}
      onClose={() => handleCloseModalJustify()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalJustify} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="42px"
          px="26px"
          borderRadius="8px"
          gap="28px"
          width={380}
          component="form"
          noValidate
          autoComplete='off'
        >
          <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
            {t("checkin.dashboard.modal_solicitation_adjustment.title") + ": " + befectiveFormatDate(dayToJustify, lang, "S")}
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>

            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              
              <TextField
                disabled
                id="outlined-disabled"
                label={t("checkin.dashboard.modal_solicitation_adjustment.label_period")}
                defaultValue={`${periodToJustify?.value || "--"}`}
                sx={{ flex: '1', bgcolor: '#F5F6F9' }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  // label={"Novo horaraio"}
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  // label={t("settings.teams.fri")}
                  value={dayjs('2022-01-01 ' + form.time)}
                  onChange={(newValue) => {
                    setForm({
                      ...form,
                      time: convertToTime(newValue)
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                    
                      variant='outlined'
                      {...params}
                      error={formErrors.time && true}
                      helperText={formErrors.time}
                      style={{ width:"40%",marginRight: 0, color: "#98ACC1" }}
                      sx={{ fieldset: { border: '2px solid #DEE3E9CC' } }}
                    />
                  )}
                />

              </LocalizationProvider>
            </Box>

            <TextField
              select
              name="reason"
              label={t("checkin.dashboard.modal_solicitation_adjustment.label_reason")}
              error={formErrors.reason && true}
              helperText={formErrors.reason}
              value={form.reason}
              onChange={e => handleChange(e, null)}
              sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
            >
              <MenuItem value="0" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                {t("checkin.dashboard.modal_solicitation_adjustment.select")}
              </MenuItem>
              {reasons.map(reason => (
                <MenuItem key={reason.id} value={reason.id} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                  {reason.label_pt || reason.label_custom}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="comment"
              id="outlined-multiline-flexible"
              label={t("checkin.dashboard.modal_justify_work.placeholder_comment")}
              multiline
              rows={4}
              maxRows={4}
              value={form.comment}
              onChange={(e) => handleChange(e, null)}
              sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, textarea: { color: "#4F7091" } }}
            />

            <Box display="flex" flexDirection="column" mt={1}>
              <Typography color="#205B6F" mb={1}>{t("checkin.dashboard.modal_justify_work.label_attach")}</Typography>

              <label className={style.inputFileBox}>
                <input
                  type="file"
                  multiple
                  onChange={onChangeInputFile}
                  disabled={form.files.length > 0}
                />
                <AttachFile fontSize='small' />
                <div style={{ color: '#205B6F' }}>
                  {t("checkin.dashboard.modal_justify_work.placeholder_select_file")}
                </div>
              </label>

              <span style={{ color: '#1E6B7F', fontSize: '10px', marginTop: '4px' }}>
                {t('tasks.file_message')}
              </span>

              {formErrors.files &&
                <div
                  className={style.error}
                  style={{ marginTop: "2px", marginBottom: 0 }}
                >
                  {formErrors.files}
                </div>
              }

              <div className={style.filesContainer}>
                {form.files.length > 0 && form.files.map((file, index) => (
                  <div key={index} className={style.filePreview}>
                    <div>{file.name}</div>
                    <div>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => removeFile(file)}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
            <Button
              style={{
                backgroundColor: '#E3EFFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.cancelBtn}
              onClick={() => handleCloseModalJustify()}
            >
              <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>{t("checkin.dashboard.modal_justify_work.label_btn_cancel")}</Typography>
            </Button>
            <Button
              style={{
                backgroundColor: '#187DFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.save}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  component="span"
                  fontSize={12}
                  color='#fff'
                  textTransform="initial"
                  fontWeight={500}
                >
                  {t("checkin.dashboard.modal_justify_work.label_btn_request")}
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
