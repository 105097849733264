import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";

import { useTable, useSortBy } from 'react-table'

import { TruncateNumberWithoutRounding, secondsToHm } from '../../../_helpers';


import IconTeam from '../../_icons/IconTeam';
import IconInfo from '../../_icons/IconInfo';
import IconCompleted from '../../_icons/IconCompleted';
import IconOnline from '../../_icons/IconOnline';
import IconOffline from '../../_icons/IconOffline';
import IconHappy from '../../_icons/IconHappy';
import { isProductive } from "../../../_helpers/calculate";


function TeammatesFull(props) {
    const { t, i18n } = useTranslation('common');

    let goals = props.goals || [];
    let stats = props.stats || [];
    const { userDetails } = props
    const [filterProductive, setFilterProductive] = useState(true);
    const [filterUnproductive, setFilterUnproductive] = useState(true);
    const [TotalProductiveTeammates, setProductiveTeammates] = useState(0);
    const [TotalUnproductiveTeammates, setUnproductiveTeammates] = useState(0);

    const [PercentWorkedTime, setPercentWorkedTime] = useState(0);
    const [PercentProductiveTime, setPercentProductiveTime] = useState(0);
    const [TotalOnlineTime, setTotalOnlineTime] = useState(0);
    const [TotalOfflineTime, setTotalOfflineTime] = useState(0);
    const [PercentOnlineTime, setPercentOnlineTime] = useState(0);
    const [PercentOfflineTime, setPercentOfflineTime] = useState(0);

    const [TotalWorkGoalTime, setTotalWorkGoalTime] = useState(0);
    const [TotalTimeGoalTime, setTotalTimeGoalTime] = useState(0);

    const [TotalExpectedProductive, setTotalExpectedProductive] = useState(0);
    const [TotalExpectedTime, setTotalExpectedTime] = useState(0);

    const data = useMemo(() => goals);

    let PercentHappy = 0;
    let HappyMoodUsers = stats.HappyMoodUsers || 0;
    let SadMoodUsers = stats.SadMoodUsers || 0;
    let OkMoodUsers = stats.OkMoodUsers || 0;

    let TotalMoodUsers = 0;
    TotalMoodUsers = HappyMoodUsers + OkMoodUsers + SadMoodUsers;

    if (TotalMoodUsers > 0) {

        PercentHappy = ((HappyMoodUsers / TotalMoodUsers) * 100).toFixed(2);

    }


    const columns = React.useMemo(
        () => [
            {
                Header: t('teammates.teammates'),
                accessor: c => c.machineUserName.toLowerCase(),
                show: true,
                Cell: (d) => d.row.original.machineUserName
            },
            {
                Header: `${t('teammates.time_goal')} (h)`,
                accessor: (cellRow) => {
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_total_time,
                Cell: (d) => {
                    const cellRow = d.row.original;
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    return <div
                        className={"time "}>{secondsToHm(cellRow.totalWorkedTime)}
                    </div>
                }
            },
            {
                Header: `${t('teammates.time_goal')} (%)`,
                accessor: (cellRow) => {
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_total_time,
                Cell: (d) => {
                    const cellRow = d.row.original;
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    return <div
                        className={"time "}><span>{TruncateNumberWithoutRounding(percentWorked)}%</span>
                    </div>
                }
            },
            {
                id: 'workgoal',
                Header: `${t('teammates.work_goal')} (h)`,
                accessor: (d) => {
                    let percentWorked = 0.00;
                    if ((d.totalProductiveTime - d.totalProductiveTimeOutdoor > 0) && d.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.totalProductiveTime) / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_productive.includes("related"),
                Cell: (d) => {
                    let percentWorked = 0.00;
                    if (d.row.original.totalProductiveTime && d.row.original.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.row.original.totalProductiveTime) / d.row.original.totalExpectedProductiveTime) * 100;
                    }
                    let productive = 'unproductive';
                    if (percentWorked >= 100) {
                        productive = 'productive';
                    }
                    return <div
                        className={"work " + productive}>{secondsToHm(d.row.original.totalProductiveTime)}
                    </div>
                }
            },
            {
                Header: `${t('teammates.work_goal')} (%)`,
                accessor: (d) => {
                    let percentWorked = 0.00;
                    if ((d.totalProductiveTime - d.totalProductiveTimeOutdoor > 0) && d.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.totalProductiveTime) / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_productive.includes("related"),
                Cell: (d) => {
                    let percentWorked = 0.00;
                    if (d.row.original.totalProductiveTime && d.row.original.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.row.original.totalProductiveTime) / d.row.original.totalExpectedProductiveTime) * 100;
                    }
                    let productive = 'unproductive';
                    if (percentWorked >= 100) {
                        productive = 'productive';
                    }
                    return <div
                        className={"work " + productive}><span>{TruncateNumberWithoutRounding(percentWorked)}%</span>
                    </div>
                }
            },
            {
                Header: t('teammates.on'),
                show: true,
                accessor: (d) => {
                    let percentOnline = 0.00;
                    if (d.totalExpectedProductiveTime > 0) {
                        percentOnline = ((d.totalProductiveTime-d.totalProductiveTimeOutdoor) / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentOnline;
                },
                Cell: (d) => {
                    let percentOnline = 0.00;

                    if (d.row.original.totalExpectedProductiveTime > 0) {
                        percentOnline = ((d.row.original.totalProductiveTime-d.row.original.totalProductiveTimeOutdoor) / d.row.original.totalExpectedProductiveTime) * 100;
                    }

                    return <div className="work">{TruncateNumberWithoutRounding(percentOnline)}%</div>;
                }
            },
            {
                Header: t('teammates.off'),
                show: true,
                accessor: (d) => {
                    let percentWorkedOutdoor = 0.00;
                    if (d.totalProductiveTimeOutdoor > 0 && d.totalExpectedProductiveTime > 0) {
                        percentWorkedOutdoor = (d.totalProductiveTimeOutdoor / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentWorkedOutdoor;
                },
                Cell: (d) => {
                    let percentWorkedOutdoor = '0.00';
                    if (d.row.original.totalProductiveTimeOutdoor > 0 && d.row.original.totalExpectedProductiveTime > 0) {
                        percentWorkedOutdoor = TruncateNumberWithoutRounding((d.row.original.totalProductiveTimeOutdoor / d.row.original.totalExpectedProductiveTime) * 100);
                    }
                    return <div className="work">{percentWorkedOutdoor}%</div>;
                }
            },
            {
                Header: t('teammates.last_mood'),
                show: true,
                accessor: "mood",
                Cell: (d) => {
                    if (!d.value) {
                        return <div style={{minWidth:'80px'}}>--</div>
                    }
                    return <div style={{minWidth:'80px'}}>{d.value[`title_${userDetails.language || i18n.language}`]}</div>
                }
            },
            {
                Header: t('teammates.agent_version'),
                show: true,
                accessor: (d) => {
                    return d.agentVersion === null ? '---' : d.agentVersion;

                },
                Cell: (d) => {

                    return <div className="">{d.value}</div>;
                }
            }
        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        disableMultiSort: true,
        initialState: {
            sortBy: [
                {
                    id: 'workgoal',
                    desc: true
                }
            ]
        }
    },
        useSortBy);

    useEffect(() => {
        if (stats) {

            let tExpected = stats.TotalExpectedWorkedTime
            let tProductive = stats.TotalProductiveTime
            let pTime = 0
            let pProductive = 0

            if (stats.TotalExpectedWorkedTime) {
                pTime = ((stats.TotalWorkedTime / stats.TotalExpectedWorkedTime) * 100)
            }

            if (stats.TotalExpectedWorkedTime) {
                pProductive = ((stats.TotalProductiveTime / stats.TotalExpectedProductiveTime) * 100)
            }

            let tOnline = stats.TotalProductiveTime - stats.TotalProductiveTimeOutdoor
            let tOffline = stats.TotalProductiveTimeOutdoor

            let pOffline = 0;
            let pOnline = 0;

            if (stats.TotalProductiveTimeOutdoor > 0 && stats.TotalExpectedProductiveTime > 0) {
                pOffline = ((stats.TotalProductiveTimeOutdoor / stats.TotalExpectedProductiveTime) * 100);
            }

            if (stats.TotalProductiveTime - stats.TotalProductiveTimeOutdoor > 0 && stats.TotalExpectedProductiveTime > 0) {
                pOnline = (((stats.TotalProductiveTime) / stats.TotalExpectedProductiveTime) * 100);
            }


            setTotalWorkGoalTime(stats.TotalWorkedTime);
            setTotalTimeGoalTime(stats.TotalProductiveTime);

            setTotalExpectedTime(tExpected)
            setTotalExpectedProductive(tProductive)
            setPercentWorkedTime(pTime)
            setPercentProductiveTime(pProductive)
            setTotalOnlineTime(tOnline)
            setTotalOfflineTime(tOffline)
            setPercentOnlineTime(pOnline - pOffline)
            setPercentOfflineTime(pOffline)

        }
    }, [stats])

    useEffect(() => {
        const elementsProductive = document.querySelectorAll(".productive");
        const elementsUnproductive = document.querySelectorAll(".unproductive");
        if (filterProductive) {
            [].forEach.call(elementsProductive, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            });
        } else {
            [].forEach.call(elementsProductive, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            });
        }

        if (filterUnproductive) {
            [].forEach.call(elementsUnproductive, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            });
        } else {
            [].forEach.call(elementsUnproductive, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            });
        }

    }, [filterProductive, filterUnproductive])

    useEffect(() => {
        let totalProductiveTeammates = 0
        let totalTeammates = 0
        if (goals) {
            for (let t0 of goals) {
                totalTeammates++
                if (isProductive(t0)) {
                    totalProductiveTeammates++
                }
            }
        }

        setProductiveTeammates(totalProductiveTeammates)
        setUnproductiveTeammates(totalTeammates - totalProductiveTeammates)
    }, [goals])

    return (
        <div className="box teammates noBg">
            <div className="pageHeader compact">
                <h3>{t('teammates.teammates')} <span><IconInfo /><div
                    className="tooltip">{/*<IconInfo/>*/}{t('teammates.tooltip')}</div></span></h3>
                <div className="internalFilters">
                    <div className={filterProductive ? 'filter canFilter work active' : 'filter canFilter work'}
                        onClick={() => {
                            setFilterProductive(!filterProductive)
                        }}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam />
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalProductiveTeammates}</div>
                        <div className="name">{t('teammates.work_goal_reached')}</div>
                    </div>
                    <div className={filterUnproductive ? 'filter active canFilter work' : 'filter canFilter work'}
                        onClick={() => {
                            setFilterUnproductive(!filterUnproductive)
                        }}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam />
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalUnproductiveTeammates}</div>
                        <div className="name">{t('teammates.work_goal_not_reached')}</div>
                    </div>

                    {userDetails.allowed_total_time == true && (
                    <div className="filter active time disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCompleted />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalWorkedTimeAVG)}</div>
                        </div>
                        <div className="highlight">{(PercentWorkedTime).toFixed(2)}%</div>
                        <div className="name">{t('teammates.time_goal')}</div>
                    </div>
                    )}
                    {userDetails.allowed_productive.includes("related") && (
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCompleted />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalProductiveTimeAVG)}</div>
                        </div>
                        <div className="highlight">{(PercentProductiveTime).toFixed(2)}%</div>
                        <div className="name">{t('teammates.work_goal')}</div>
                    </div>
                    )}
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOnline />
                            </div>
                            <div className="number">{secondsToHm(stats.totalOnlineTimeAVG)}</div>
                        </div>
                        <div className="highlight">{PercentOnlineTime.toFixed(2)}%</div>
                        <div className="name">{t('teammates.online_activities')}</div>
                    </div>
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOffline />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalWorkedTimeOutdoorAVG)}</div>
                        </div>
                        <div className="highlight">{PercentOfflineTime.toFixed(2)}%</div>
                        <div className="name">{t('teammates.offline_activities')}</div>
                    </div>
                    <div className="filter active mood">
                        <div className={"head"}>
                            <div className="icon">
                                <IconHappy />
                            </div>
                        </div>
                        <div className="highlight">{stats.happiness_percent || 0}%</div>
                        <div className="name">{t('teammates.happiness')}</div>
                    </div>
                </div>


            </div>
            <div className="dataTable teammates" style={{ overflow: 'auto' }}>
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                    if (column.show) {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <div className="arrowDown"></div>
                              ) : (
                                <div className="arrowUp"></div>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    }
                  })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {

                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.show) {
                                                    return (
                                                      <td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                      </td>
                                                    );
                                                  }
                                                  return null;
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                }


            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {}
}

function mapStateToProps(state) {

    return {

        goals: state.goals.payloadTeammates,
        stats: state.stats.payload,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeammatesFull)
