import React from 'react';

const IconTeam = props => {
    return (
        
     
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">

        <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 2)">
            <path id="Caminho_8" data-name="Caminho 8" d="M9,12A3,3,0,1,0,6,9,3,3,0,0,0,9,12ZM9,7.333A1.667,1.667,0,1,1,7.333,9,1.667,1.667,0,0,1,9,7.333Z" transform="translate(-4.667 -6)" fill="#187dff"/>
            <path id="Caminho_9" data-name="Caminho 9" d="M30.333,14.667A2.333,2.333,0,1,0,28,12.333a2.333,2.333,0,0,0,2.333,2.333Zm0-3.333a1,1,0,1,1-1,1,.967.967,0,0,1,1-1Z" transform="translate(-19.333 -8.667)" fill="#187dff"/>
            <path id="Caminho_10" data-name="Caminho 10" d="M16.667,27.132l-.3-.2a6.533,6.533,0,0,0-6.733,0l-.267.2a8.2,8.2,0,0,0-2.7-.467,7.8,7.8,0,0,0-4.367,1.3l-.3.2v2.5a.667.667,0,0,0,.667.667h8a.667.667,0,0,0,.667-.667V30H16a.667.667,0,0,0,.667-.667ZM10,30H3.333V28.9A6.623,6.623,0,0,1,10,28.9Zm5.333-1.333h-4v-.5l-.3-.2-.2-.133a5.1,5.1,0,0,1,4.5.067Z" transform="translate(-2 -19.332)" fill="#187dff"/>
        </g>
        </svg>



 

    )
}

export default IconTeam;