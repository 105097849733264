import { CheckSquareOffset, X } from 'phosphor-react';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import ChildrenBlur from '../../ChildrenBlur';
import { Item } from './Item';
import { ListDivider } from './ListDivider';
import "./styles.scss";

export function KanbanCardChecklist({ title = "Checlist :D", items = [], id, saveChecklist, saveItem, updateItem, deleteChecklist, provided, innerRef, updateItemIndex, deleteItem, openConfirmDeleteChecklist }) {
  const [cardEdit, setcardEdit] = useState(false)
  const [newItem, setNewItem] = useState(false)
  const [titleCard, setTitleCard] = useState(title)
  const [newItemTitle, setNewItemTitle] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { t } = useTranslation('common');

  function handleNewItem() {
    setNewItemTitle("")
    setNewItem((value) => {
      return !value
    })
  }
  function saveChanges(body) {
    saveChecklist(body)
    handleEditOpen()
  }
  function handleEditOpen() {
    setcardEdit((value) => {
      return !value
    })
  }

  const handleChangeTextarea = (event) => {
    setTitleCard(event.target.value);
  };

  const handleChangeTextareaNewTitle = (event) => {
    setNewItemTitle(event.target.value);
  };

  function handleSaveItem(body) {
    setIsSubmitting(true)
    saveItem(body)
    handleNewItem()
    setNewItemTitle("")
    setIsSubmitting(false)
  }
  function handleDeleteChecklist(id) {
    deleteChecklist(id)
  }

  async function handleDragItems(result) {
    // console.log("/result dragEnd", result)
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const item_id = result.draggableId
    const origin = result.source.index
    const destination = result.destination.index
    // console.log("/ITEMS: origin ", origin, "destino", destination, "item_id", item_id)
    if (destination === origin) return; // Se moveu para a mesma posição


    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(origin, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    // console.log("/items ordered?", newItems)

    items = newItems;
    updateItemIndex(id, item_id, origin, destination);
  }

  const save = async (value) => {
    if (!value) return
    handleSaveItem({ checklist_id: id, title: value })
    setNewItemTitle('')
  }

  const cancel = (e) => {
    handleNewItem()
  }

  return (
    <div
      className='containerKanbanCardChecklist'
      ref={innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >

      {!cardEdit
        ?
        <div className='card-header-checklist'>
          <div className='card-icon-title'>
            <CheckSquareOffset size={24} color='#4F7091' />
            <p className='card-title' onClick={handleEditOpen} style={{ color: '#4F7091', fontWeight: 500 }}>{title}</p>
          </div>
          <div className='card-header-buttons'>
            {/* { items.length > 0 &&  items.filter(item => item.checked === true).length > 0 && 
                    ( <div className='button' onClick={()=>{console.log("clicou em mim")}}>
                        ocultar itens marcados
                      </div>)

                    } */}

            <div className='button' onClick={(e) => openConfirmDeleteChecklist(e, id)}>
              {t('modalDetailTask.delete')}

            </div>
          </div>
        </div>
        :
        <div className='card-header-edit'>
          <CheckSquareOffset size={24} />
          <div className='edit-title'>
            <textarea className='item-title-input' onBlur={() => console.log('testando')} onChange={handleChangeTextarea} value={titleCard} rows={1}></textarea>
            <div className='botton-action-2'>
              <div className='button' onClick={() => { saveChanges({ id: id, title: titleCard }) }}>
                {t('modalDetailTask.save')}
              </div>
              <X style={{ cursor: "pointer" }} size={24} color='#4F7091' onClick={() => {
                // console.log("/clicou no icon para fechar! 2")
                handleEditOpen()
              }} />
            </div>
          </div>

        </div>
      }

      {
        items.length > 0 &&
        (<div className="progress-checklist-container">
          <div className="percent-label">
            {parseInt(items.filter(item => item.is_checked === true).length * 100 / items.length) + '%'}
          </div>
          <div className="progress-main">
            <div
              className={`bar-progress completed`}
              style={{ width: items.filter(item => item.is_checked === true).length * 100 / items.length + '%' }}
            />
          </div>
        </div>)
      }

      <DragDropContext onDragEnd={handleDragItems}>
        <Droppable droppableId='items-1'>
          {(provided) => (
            <div className='card-items-checklist-kanban' ref={provided.innerRef}>
              {items.length > 0 && items.map((item, idx) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={idx}
                >
                  {provided =>
                  (
                    <Item
                      key={idx}
                      id={item.id}
                      title={item.title}
                      checked={item.is_checked}
                      saveItem={saveItem}
                      updateItem={updateItem}
                      innerRef={provided.innerRef}
                      provided={provided}
                      deleteItem={deleteItem}
                    />
                  )}
                </Draggable>
              ))
              }

            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* <EasyEdit
        type={'text'}
        onSave={(value) => save(value, id)}
        onCancel={cancel}
        attributes={{ name: "title", id: id }}
        value={newItemTitle}
        displayComponent={
          <div className='add-item'>
            <div className='button'>
              {t('modalDetailTask.add_item')}
            </div>
          </div>
        }
        hideSaveButton
        hideCancelButton
        cancelOnBlur
      /> */}

      {newItem && (
        <ChildrenBlur
          onBlur={() => {
            handleNewItem()
          }}
        >

          <div className='card-header-edit'>
            <div className='edit-title'>
              <input className='item-title-input' onChange={handleChangeTextareaNewTitle} value={newItemTitle} placeholder={t('modalDetailTask.add_item')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    handleSaveItem({ checklist_id: id, title: newItemTitle })
                }}
              />
              <div className='botton-action-2'>

                <button
                  className='button'
                  onClick={() => { handleSaveItem({ checklist_id: id, title: newItemTitle }) }}
                  disabled={isSubmitting}
                >
                  {t('modalDetailTask.save')}
                </button>
                <X style={{ cursor: "pointer" }} size={24} color='#4F7091' onClick={() => {
                  handleNewItem()
                }} />
              </div>
            </div>
          </div>
        </ChildrenBlur>
      )

      }
      {
        !newItem && (
          <div className='add-item'>
            <div className='button' onClick={() => { handleNewItem() }}>
              {t('modalDetailTask.add_item')}
            </div>
          </div>
        )

      }

      <ListDivider
        width={100}
        heigth={1}
        color="#E4EAEF"
        pt={10}
        position='flex-end'
      />

    </div >

  );
}