import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'

import { teamsActions } from '../../../_actions'

import { CircularProgress } from '@material-ui/core'
import IconSearch from '../../_icons/IconSearch'
import Select from 'react-select'
import { useState } from 'react'
import { api } from '../../../_helpers/api'
import { authHeader } from '../../../_helpers'
import { useHistory, useParams } from 'react-router-dom'

import './styles.scss'

function DrawerSearchFilters({ keyResults, setCurrentObjective, userDetails }) {
	const { t } = useTranslation('common')
	const token = authHeader()

	const params = useParams()
	const history = useHistory();

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [krOptions, setKrOptions] = useState([])
	const [statusOption, setStatusOption] = useState('')
	const [form, setForm] = useState({
		searchField: '',
		keyResultSelect: [],
		statusSelect: '',
		priority: ''
	})

	const keyResultsOptions = keyResults?.reduce((acc, kr) => {
		return [...acc, { value: kr.id, label: kr.title }]
	}, [])
	

	const statusOptions = [
		{
			value: '',
			label: t('keyresults.sidebar_filter.all')
		},
		{
			value: 1,
			label: t('tasks.planned')
		},
		{
			value: 2,
			label: t('tasks.progress')
		},
		{
			value: 3,
			label: t('tasks.completed')
		},
		{
			value: 4,
			label: t('tasks.late')
		}
	]

	const handleChange = (event) => {
		// if (event.target.name === 'onlyMyActivities') {
		// 	setForm({ ...form, [event.target.name]: !form?.onlyMyActivities })
		// } else {
			setForm({ ...form, [event.target.name]: event.target.value });
		// }
	};

	async function handleFilter(e, clear=false) {
		e.preventDefault()

		try {
			setIsSubmitting(true)
			let kr_ids = '' 
			form.keyResultSelect.forEach(kr => {
				kr_ids += `${kr},`
			})

			let url = ''
			
			if(clear) {
				url = `goals/${params?.id}/?organization_id=${userDetails?.organizationId}&busca=&status_id=&priority_id=&kr_ids=`
			} else {
				url = `goals/${params?.id}/?organization_id=${userDetails?.organizationId}&busca=${form.searchField}&status_id=${form.statusSelect}&priority_id=${form.priority}&kr_ids=${kr_ids}`
			}

			const response = await api.get(
				url, 
				{
					headers: {
						'Authorization': token.Authorization
					},
				}
			)

			setCurrentObjective(response.data)
			history.replace(`/goals/details/${params?.id}`)
			setIsSubmitting(false)
		} catch (error) {
			setIsSubmitting(false)
			console.log(error)
		}
	}

	async function clearFilter(e) {
		setForm({
			searchField: '',
			keyResultSelect: [],
			statusSelect: '',
			priority: ''
		})

		setKrOptions([])
		setStatusOption('')
		handleFilter(e, true)
	}

	useEffect(() => {
		handleChange({ target: { name: 'keyResultSelect', value: krOptions.map(kr => kr.value) } })
	}, [krOptions])

	useEffect(() => {
		handleChange({ target: { name: 'statusSelect', value: statusOption } })
	}, [statusOption])

	return (
		<div className="containerDrawerSearch">
			<main>
				<header>
					<h3>{t('keyresults.sidebar_filter.title')}</h3>
				</header>

				<div className='content'>

					<div>
						{/* <div className="row">

							<div className="col">


								<FormControlLabel
									control={
										<Checkbox
											name='onlyMyActivities'
											color="primary"
											checked={form.onlyMyActivities || false}
											value={form.onlyMyActivities || false}
											onChange={handleChange}
										/>
									}
									label={t('keyresults.sidebar_filter.only')}
									style={{ textTransform: 'none', fontSize: 14, fontWeight: 500 }}
								/>
							</div>
						</div> */}

						<div className="row">

							<div className="col">

								<div className='inputSearch'>
									<label htmlFor='inputSearch'>
										<IconSearch width="13" height="13" />
									</label>
									<input 
										id='inputSearch' 
										type="text" 
										name='searchField' 
										placeholder={t('keyresults.sidebar_filter.search')} 
										onChange={handleChange} 
										value={form.searchField} 
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label htmlFor='keyResultSelect'>{t('keyresults.sidebar_filter.keyresult')}</label>
								<Select
									id='keyResultSelect'
									value={krOptions}
									isMulti
									name="keyResultSelect"
									options={keyResultsOptions}
									className="basic-multi-select"
									classNamePrefix="select"
									placeholder={t('keyresults.sidebar_filter.select')}
									onChange={setKrOptions}
								/>
							</div>
						</div>
					</div>


					<div>
						<div className="row">

							<div className="col">
								<label htmlFor='selectStatus'>{t('keyresults.sidebar_filter.status')}</label>
								<Select
									id='selectStatus'
									value={statusOptions.find(item => item.value === statusOption)}
									name="statusSelect"
									options={statusOptions}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange={e => setStatusOption(e.value)}
									placeholder={t('keyresults.sidebar_filter.select')}
								/>
							</div>
						</div>
					</div>

					<div>
						<h3>{t('keyresults.sidebar_filter.priority.label')}</h3>
					</div>

					<div>
						<div className="row">

							<div className="col">
								<div className={form.priority === 1 ? `priorityOption active` : `priorityOption`}>
									<button 
										type='button' 
										onClick={() => handleChange({ target: { name: 'priority', value: 1 } })}
									>
										{t('keyresults.sidebar_filter.priority.low')}
									</button>
								</div>
							</div>

							<div className="col">
								<div className={form.priority === 2 ? `priorityOption active` : `priorityOption`}>
									<button 
										type='button' 
										onClick={() => handleChange({ target: { name: 'priority', value: 2 }})}
									>
										{t('keyresults.sidebar_filter.priority.medium')}
									</button>
								</div>
							</div>

							<div className="col">
								<div className={form.priority === 3 ? `priorityOption active` : `priorityOption`}>
									<button 
										type='button' 
										onClick={() => handleChange({ target: { name: 'priority', value: 3 } })}
									>
										{t('keyresults.sidebar_filter.priority.high')}
									</button>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div className="row">

							<div className="col">
								<button 
									className='search-button'
									onClick={handleFilter}
									disabled={isSubmitting}
								>
									{isSubmitting ? (
										<CircularProgress size={14} />
									) : t('keyresults.sidebar_filter.search')}
								</button>
								<button
									className='clear-button'
									onClick={clearFilter}
								>
									{t('keyresults.sidebar_filter.clear')}
								</button>
							</div>
						</div>
					</div>

				</div>

			</main>
		</div>

	)
}

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		created: state.teams.created
	}

}


function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch)
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerSearchFilters)



