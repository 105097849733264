import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Avatar, Grid, Box, Backdrop, IconButton, Menu, MenuItem, Slide } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import {Skeleton } from "@material-ui/lab";
import { XCircle } from "phosphor-react";
import { Mention, MentionsInput } from "react-mentions";
import 'react-web-tabs/dist/react-web-tabs.css';
import moment from 'moment'

import IconClose from "../../_icons/IconClose";
import { api } from "../../../_helpers/api";
import { alertActions, usersActions } from "../../../_actions";
import { authHeader, getAvatarUrl } from "../../../_helpers";
import { mentionsInputStyle } from "./inputMentionsStyles/mentionsInputStyles";
import { mentionStyle } from "./inputMentionsStyles/mentionStyles";

import "./styles.scss"

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	rootTab: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	textField: {
		width: '37ch',
	},
	modal: {
		"&:focus": {
			outline: 'none'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		width: '650px',
		height: '560px',
		outline: 'none',
		borderRadius: '8px',
		position: 'relative'
	},
	title: {
		fontSize: 16,
		color: '#1E6B7F',
		fontWeight: '500',
		marginBottom: 24
	}
}));

function ModalCommentsKR({
	openCommentsKr,
	handleCloseCommentsKr,
	userDetails,
	currentKr,
	teammates
}) {

	const classes = useStyles();
	const { t } = useTranslation('common');
	
	let token = authHeader()

	const teammatesOptions = teammates?.map(item => {
		return {
			display: item.label,
			id: `${item.value}`,
			idOnly: item.value
		}
	});

	const optionsColabs = teammatesOptions?.filter(colab => colab.idOnly !== userDetails?.id)

	const [isLoading, setIsLoading] = useState(false);
	const [commentsList, setCommentsList] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [currentComment, setCurrentComment] = useState({});
	const [editComment, setEditComment] = useState(false);
	const [commentId, setCommentId] = useState(null);
	const [commentOwnerId, setCommentOwnerId] = useState(null);
	const [commentMention, setCommentMention] = useState({
		message: '',
		mentions: []
	});
	
	const getComments = async () => {
		try {
			const result = await api.get(`/goals/key-results/${currentKr.id}/comment?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setCommentsList(result?.data?.comments);
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		} finally {
			setIsLoading(false)
		}
	}

	const createComment = async (messageFormattedFull) => {
		try {

			const payload = {
				owner_id: currentKr[0]?.responsible_id,
				comment: messageFormattedFull,
				mentions: commentMention.mentions,
				datetime: new Date()
			};
			const result = await api.post(`goals/key-results/${currentKr?.id}/comment?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});
			if (result.status === 200) {
				getComments();
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const updateComment = async (comment_id, owner_id, newCommentTitle) => {

		if (!comment_id && !owner_id && !newCommentTitle) {
			//alertActions.error(t('goals.errors.create_goal'))
			return;
		}

		try {
			const payload = {
				owner_id: owner_id,
				comment: newCommentTitle,
				mentions: commentMention.mentions,
				datetime: new Date()
			};
			console.log("//alert ",commentMention.mentions)
			const result = await api.put(`goals/key-results/comment/${comment_id}/?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			if (result.status === 200) {
				getComments();
				setEditComment(false);
			}

		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	const handleEditComment = (isEdit) => {
		setEditComment(!editComment);
		if (isEdit) {
			setCommentId(currentComment?.id)
			setCommentOwnerId(currentComment?.owner_id)
			setCommentMention({
				message: currentComment?.comment,
				mentions: commentMention.mentions
			});
		} else {
			setCommentMention({
				message: '',
				mentions: []
			});
			setCurrentComment({});
		}
	}

	const deleteComment = async (comment) => {
		try {
			const result = await api.delete(`goals/key-results/comment/${comment?.id}/?organization_id=${userDetails?.organizationId}`,
				{
					headers: {
						'Authorization': token.Authorization
					}
				}
			);
			if (result?.data?.statusCode === 200) {
				getComments();
				setCommentMention({
					message: '',
					mentions: []
				});
				handleCloseMenu();
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const handleClickMenu = (event, comment) => {
		setAnchorEl(event.currentTarget);
		setCurrentComment(comment);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setCurrentComment({});
	};

	const handleDeleteComment = () => {
		if (currentComment) {
			deleteComment(currentComment)
		}
	}

	const handleCreateComment = (e) => {
		if (e.key === 'Enter' && commentMention.message !== '' && !commentMention.message.includes('\n')) {
			const messageFormatted = commentMention.message?.replaceAll('[', '')?.replaceAll(']', '')

			let messageFormattedFull = messageFormatted
			for (let mention of commentMention.mentions) {
				messageFormattedFull = messageFormattedFull.replaceAll(`{${mention}}`, '')
			}

			const checkIfExists = commentsList?.filter(
				comment => comment?.title?.toLowerCase() === messageFormattedFull.toLowerCase()
			)
			if (checkIfExists.length === 0) {
				if (!editComment) {
					createComment(messageFormattedFull);
				} else {
					updateComment(commentId, commentOwnerId, messageFormattedFull);
				}
				setCommentMention({
					message: '',
					mentions: []
				});
			}
		}
	}

	function handleChangeComment(event) {
		let value = event.target.value;
		if (value.includes('\n')) {
			value = event.target.value.replace("\n", "")
		}
		const regex = /[^{}]+(?=})/g;

		const mentions = value.match(regex);

		// const messageFormatted = value?.replaceAll('[', '')?.replaceAll(']', '')?.replace(`{${mentions[0]}}`, '')

		setCommentMention({ message: value, mentions: mentions || [] });
	}

	useEffect(() => {
		setIsLoading(true)
		getComments();
		setIsLoading(false)
	}, [])

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={openCommentsKr}
				className={`${classes.modal} modal-comments-kr`}
				onClose={handleCloseCommentsKr}
				disableEnforceFocus
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					style: { backgroundColor: '#205B6F', opacity: 0.8 }
				}}
				sx={{ border: 'none' }}
				style={{ outline: 0 }}
			>
				<Slide direction="down" in={openCommentsKr} style={{ outline: 0 }} mountOnEnter unmountOnExit>
					<div className={classes.paper}>
						<div className="box-button-close">
							<button onClick={handleCloseCommentsKr} className="close" type="button"><IconClose /></button>
						</div>

						<Grid container className='container-detail-task'>
							<Grid item xs={12} className='box-left'>
								<Grid container>
									<Grid item xs={12}>
										{
											isLoading
												?
												<>
													<Skeleton style={{ marginTop: 8 }} height={20} variant="rounded" />
													<Skeleton style={{ marginTop: 8 }} variant="text" />
													<Skeleton variant="text" />
													<Skeleton variant="text" />
												</>
												:
												<>
													<h5 className='title-task'>{currentKr.title}</h5>
													<p className='description-task'>
														{moment(currentKr.date_start).format('DD/MM/YYYY')} - {moment(currentKr.date_end).format('DD/MM/YYYY')}
													</p>
												</>
										}
									</Grid>
									<Grid item xs={12}>
										<Box sx={{ width: '100%', typography: 'body1' }}>

											<Tabs
												defaultTab="0"
											>
												<TabList className="custom-tab-list">
													<Tab tabFor="0">
														{t('modalDetailTask.comments')}
													</Tab>
												</TabList>
										

												<TabPanel tabId="0" className="tab-comments-container">

													<div className='comments-container'>
														{commentsList.length > 0 && commentsList.map((comment, index) => (
															<div key={index} className='comment-box'>
																<ul>
																	{comment.owner_id === userDetails.mu_id && comment.read === false && (
																		<div className="box-three-points">
																			<IconButton
																				aria-label="delete"
																				color="primary"
																				onClick={(e) => handleClickMenu(e, comment)}
																				style={{ padding: 4 }}
																			>
																				<MoreHorizIcon fontSize='medium' />
																			</IconButton>
																		</div>
																	)}
																	<li>
																		<div className='comment'>
																			<div className="avatarMU">
																				<Avatar alt={comment?.username} src={getAvatarUrl({
																					name: comment?.username
																				})} style={{ width: 22, height: 22 }} />
																			</div>
																			<div className="infoComment">
																				<div>
																					<span>{comment?.username}</span>
																					<span>{moment(comment?.created_at).format('DD/MM/YYYY HH:mm')}</span>
																				</div>
																				<div>
																					<p>{comment?.comment}</p>
																				</div>
																			</div>
																		</div>
																	</li>
																</ul>
															</div>
														))}
													</div>

													<Menu
														id={`menu-comment`}
														anchorEl={anchorEl}
														keepMounted
														open={Boolean(anchorEl)}
														onClose={handleCloseMenu}
														getContentAnchorEl={null}
														anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
														transformOrigin={{ horizontal: "center" }}
													>
														<MenuItem onClick={() => handleEditComment(true)}>{t('modalDetailTask.edit')}</MenuItem>
														<MenuItem onClick={handleDeleteComment}>{t('modalDetailTask.delete')}</MenuItem>
													</Menu>

													<div className='new-comment-container'>

														{
															editComment && (
																<XCircle
																	style={{
																		position: 'absolute',
																		bottom: "16px",
																		right: "16px"
																	}}
																	size={20}
																	weight="fill"
																	color="#FF5151"
																	onClick={() => handleEditComment(false)}
																/>
															)
														}

														<div className="mention-input">
															<MentionsInput
																value={commentMention.message}
																onChange={handleChangeComment}
																onKeyDown={(e) => handleCreateComment(e)}
																placeholder={t('tasks.input_placeholder_comment')}
																style={mentionsInputStyle}
															>

																<Mention
																	appendSpaceOnAdd={true}
																	markup="[__display__]{__id__}"
																	data={optionsColabs}
																	style={mentionStyle}
																/>
															</MentionsInput>
														</div>

													</div>

												</TabPanel>
												
											</Tabs>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Slide>
			</Modal>

		</div>

	)

};

function mapStateToProps(state) {

	return {
		users: state.users.items,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		alertActions: bindActionCreators(alertActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalCommentsKR));
