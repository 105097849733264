import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { PaperPlaneTilt } from 'phosphor-react';
import ReactAvatar from 'react-avatar';
import { isMobile } from 'react-device-detect';

import { api } from '../../_helpers/api'
import { alertActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { authHeader, befectiveFormatDate } from '../../_helpers';

import './styles.scss'
import ModalHistoryMessage from '../../_components/goals/GoalsNotificationsPage/ModalHistoryMessage';

import imageSrc from '../../../public/images/no-notifications.png';
import MenuMU from '../../_components/_shared/MenuMU';

function GoalsNotificationsPage(props) {
	const { t, i18n } = useTranslation('common');

	let path = window.location.pathname.split("/") || [];

	const { alertActions, userDetails } = props;

	const [newComment, setNewComment] = useState('')
	const [openModalHistory, setOpenModalHistory] = useState(false)
	const [isNotificationsActive, setIsNotificationsActive] = useState(true)
	const [selectedMessageAnswer, setSelectedMessageAnswer] = useState('')
	const [notifications, setNotifications] = useState([])
	const [historyMov, setHistoryMov] = useState([])
	const [notificationsRead, setNotificationsRead] = useState([])
	const [taskMessages, setTaskMessages] = useState([])
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const token = authHeader()
	const containerRef = useRef(null);
	const containerRef2 = useRef(null);
	const containerRef3 = useRef(null);

	function toggleMenu(type) {
		if (type == 'main') {
			setIsMenuOpen(!isMenuOpen);
		} else {
			if (isMobile) {
				setIsMenuOpen(false)
			}
		}
	};

	function toggleFromMenu(value) {

		if (value == 'closed') {
			setIsMenuOpen(true);
		} else {
			setIsMenuOpen(false)
		}
	}

	async function getGoalsNotifications(read) {
		let url = `goals/notification?organization_id=${userDetails?.organizationId}`
		if (read) {
			url += '&read=true'
		}

		try {
			const response = await api.get(url, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			if (read) {
				setNotificationsRead(response.data)
				return
			}

			setNotifications(response.data)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals_notifications.error'))
		}

	}

	const myHistoryMov = async () => {
		try {
			const response = await api.get(`goals/movements/my_historic?organization_id=${userDetails?.organizationId}&retroactive_days=7`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			if (response.status === 200) {
				setHistoryMov(response.data)
			}
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals_notifications.error_read'))
		}
	}

	async function handlePressEnter(e, id_table, notification_id, table) {
		if (e.key === 'Enter' && newComment !== '') {
			handleCreateComment(id_table, notification_id, table)
		}
	}

	const handleCreateComment = async (id_table, notification_id, table) => {
		try {
			if (newComment !== '') {

				const payload = {
					comment: newComment,
					mentions: [],
					datetime: new Date()
				};

				const urls = {
					'T': `goals/key-results/tasks/${id_table}/comment?organization_id=${userDetails?.organizationId}`,
					'KR': `goals/key-results/${id_table}/comment?organization_id=${userDetails?.organizationId}`,
					'G': `goals/${id_table}/comment?organization_id=${userDetails?.organizationId}`
				}

				await api.post(urls[`${table}`], payload, {
					headers: {
						'Authorization': token.Authorization
					}
				});

				setNewComment('')
				handleMarkNotificationAsRead(notification_id)

				alertActions.success(t('goals_notifications.success_create_message'))
			}
		} catch (error) {
			console.log(error.response);
			//alertActions.error(t('goals_notifications.error_create_message'))
		}
	}

	async function handleMarkNotificationAsRead(notification_id) {
		try {
			await api.put(`goals/notification/${notification_id}?organization_id=${userDetails?.organizationId}`, {}, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			const notifications_filtered = notifications.filter(notification => notification.id !== notification_id)

			setNotifications(notifications_filtered)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals_notifications.error_read'))
		}
	}

	async function getTaskDetail(taskId) {
		try {
			const result = await api.get(`goals/key-results/tasks/${taskId}/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setTaskMessages(result?.data[0].task_comments)
		} catch (error) {
			//alertActions.error(t('goals_notifications.error'))
		}
	}

	function handleOpenModalHistory(taskId) {
		getTaskDetail(taskId)
		setOpenModalHistory(true)
	}

	function handleCloseModalHistory() {
		setOpenModalHistory(false)
	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.goals');
	}, []);

	useEffect(() => {
		myHistoryMov()
		if (isNotificationsActive === true) {
			getGoalsNotifications()
		} else {
			const read = true
			getGoalsNotifications(read)
		}
	}, [isNotificationsActive])


	useEffect(() => {
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setSelectedMessageAnswer('');
			}
			if (containerRef2.current && !containerRef2.current.contains(event.target)) {
				setSelectedMessageAnswer('');
			}
			if (containerRef3.current && !containerRef3.current.contains(event.target)) {
				setSelectedMessageAnswer('');
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [containerRef, containerRef2, containerRef3]);

	return (
		<React.Fragment>



			{path.includes('productivity') && (
				<>
					<div className={isMenuOpen ? "menuToggle open" : "menuToggle"}
						onClick={() => {
							toggleMenu('main')
						}}>
						<i className="arrow"></i>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<MenuMU menuStatus={isMenuOpen} toggle={toggleFromMenu} />
				</>
			)}

			<section className="mainContent" ref={containerRef3}>

				<Toolbar objectiveName={t('breadcrub.notification')} />

				<Filterbar
					filterWho={'disabled'}
					filterWhen={'disabled'}
					filterWhat={'disabled'}
					hasTogglePage={true}
					first_label={t('goals_notifications.notification')}
					second_label={t('goals_notifications.read_small')}
					setFirstPageActive={setIsNotificationsActive}
				/>

				{isNotificationsActive ? (
					<main className='container-objectives-notifications'>
						{notifications.length > 0 && (
							<div className='updates-container'>
								<h2>{t('goals_notifications.what_happens')} {' '}
									<span>
										{notifications.length} {t('goals_notifications.updates')}
									</span>
								</h2>

								{notifications?.map(notification => {

									const title_options = {
										pt: notification?.title_pt || '',
										es: notification?.title_es || '',
										en: notification?.title_en || ''
									}

									let breadcrumb = notification.breadcrumb
									if (notification.token_table === 'T') {
										const breadcrumbSplited = notification?.breadcrumb.split('/')
										breadcrumb = breadcrumbSplited[0] + ' / ' + breadcrumbSplited[1]
									}

									return (
										<div key={notification.id} className='updates-notification-container' ref={containerRef2}>
											<ReactAvatar
												name={notification.username}
												size={24}
												round
												color='#4F7091'
												style={{ border: '2px solid #fff' }}
											/>
											<div className='updates-notification-content'>
												<span className='date'>{befectiveFormatDate(notification.datetime, i18n.language, "C")}</span>
												<p className='message'>
													{notification.username}{' '}{title_options[`${userDetails?.language}`]}:
													<strong>{' '}{notification.answer_ref}</strong>
												</p>
												<span className='info'>
													{notification.token_table === 'T' ? breadcrumb + ' / ' : breadcrumb}

													{notification.token_table === 'T' && (
														<button onClick={() => handleOpenModalHistory(notification.id_table)} className='history-btn'>{notification.ref_title}.</button>
													)}
												</span>

												<div className='buttons-container' ref={containerRef}>
													{selectedMessageAnswer === notification.id ? (
														<div className='input-container' >
															<input
																type="text"
																value={newComment}
																onChange={e => setNewComment(e.target.value)}
																autoFocus
																placeholder={t('goals_notifications.placeholder')}
																onKeyDown={(e) => handlePressEnter(e, notification.id_table, notification.id, notification.token_table)}
															/>
															<PaperPlaneTilt
																size={18}
																color="#187DFF"
																weight="fill"
																onClick={() => handleCreateComment(notification.id_table, notification.id, notification.token_table)}
															/>
														</div>
													) : (
														<>
															{notification.can_be_answered && (
																<button
																	type='button'
																	className='button-response'
																	onClick={() => setSelectedMessageAnswer(notification.id)}
																>
																	{t('goals_notifications.reply')}
																</button>
															)}

															<button className='button-response' onClick={() => handleMarkNotificationAsRead(notification.id)}>
																Ok
															</button>
														</>

													)}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						)}

						{notifications.length === 0 && (
							<div className='updates-container'>
								<h2>{t('goals_notifications.no_notifications')}</h2>
								<div className='updates-notification-container' style={{ border: 'none', justifyContent: 'center' }}>
									<img src={imageSrc} alt="Imagem de um desenho de um homem tomando café" width={380} />
								</div>
							</div>
						)}

						<div className='history-container'>
							<h2>
								{t('goals_notifications.historical')}
							</h2>

							{
								historyMov?.map(mov => {
									const title_options = {
										pt: mov?.title_pt || '',
										es: mov?.title_es || '',
										en: mov?.title_en || ''
									}
									return (<div key={mov.id} className='history-notification-container'>
										<ReactAvatar
											name={mov.username}
											size={24}
											round
											color='#4F7091'
											style={{ border: '2px solid #fff' }}
										/>

										<div className='history-notification-content'>
											<span className='date'>{befectiveFormatDate(mov.datetime, i18n.language, "C")}</span>
											<p className='message'
												dangerouslySetInnerHTML={{ __html: title_options[`${userDetails?.language}`] }}
											>

											</p>
											<span className='info'>
												{mov.breadcrumb}
											</span>
										</div>
									</div>)
								})}


							{/* <div className='history-notification-container'>
								<ReactAvatar
									name='Guilherme Lourenco'
									size={24}
									round
									color='#4F7091'
									style={{ border: '2px solid #fff' }}
								/>

								<div className='history-notification-content'>
									<span className='date'>02/12/2021 17:39</span>
									<p className='message'>
										Definiu
										<strong>{' '}Alta prioridade{' '}</strong>
										em ter 3 entrevistas publicadas na midia.
									</p>
									<span className='info'>
										Desenvolver o Befective 2.0 / KR1 - Aumentar exposicao na web em 25% / 3 entrevistas publicadas na midia.
									</span>
								</div>
							</div> */}
						</div>
					</main>
				) : (
					<main className='container-objectives-notifications'>
						<div className='updates-container'>
							<h2>{t('goals_notifications.read')}</h2>

							{notificationsRead.map(notification => {
								const title_options = {
									pt: notification?.title_pt || '',
									es: notification?.title_es || '',
									en: notification?.title_en || ''
								}
								return (
									<div className='updates-notification-container' style={{ border: 'none' }}>
										<ReactAvatar
											name={notification.username}
											size={24}
											round
											color='#4F7091'
											style={{ border: '2px solid #fff' }}
										/>
										<div className='updates-notification-content'>
											<span className='date'>{befectiveFormatDate(notification.datetime, i18n.language, "C")}</span>
											<p className='message'>
												{notification.username}{' '}{title_options[`${userDetails?.language}`]}:
												<strong>{' '}{notification.answer_ref}</strong>
											</p>
											<span className='info'>
												{notification.breadcrumb}
											</span>
										</div>
									</div>
								)
							})}

						</div>
					</main>
				)}

			</section>
			{taskMessages.length > 0 && (
				<ModalHistoryMessage
					messages={taskMessages}
					openModalHistory={openModalHistory}
					handleCloseModalHistory={handleCloseModalHistory}
				/>
			)}

		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsNotificationsPage)
