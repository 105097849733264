import React, { useEffect, useRef, useState } from 'react';
import style from './styles.module.scss'
import { Box, Button, Grid, MenuItem, Paper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, history } from '../../../_helpers';
import { Delete, Link } from '@material-ui/icons';
import { Backdrop, CircularProgress, makeStyles, Modal, Popover, Slide, Typography, Tooltip } from "@material-ui/core";

const MapsListContainer = ({
  userDetails,
  alertActions,
  maps
}) => {

  const { t, i18n } = useTranslation('common');
  const [selectedShape, setSelectedShape] = useState(null)

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1); // Aumentar o zoom em 10%
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1); // Diminuir o zoom em 10%
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      {
        maps.length > 0 &&
        maps.map((map, index) => (
          <Box key={map.id} component={Paper} elevation={0} padding={'6px 10px'}>
            <Box display={'flex'}>
              <Typography variant='h6' style={{ color: '#4F7091', paddingTop: '10px', paddingBottom: '10px' }}>{map.name}</Typography>
            </Box>
            <svg width="100%" height="600" style={{ border: "1px solid #01010155" }} >
              {Array.from({ length: 40 }, (_, i) => (
                <line
                  key={`row-${i}`}
                  x1="0%"
                  y1={`${(i / 40) * 100}%`}
                  x2="100%"
                  y2={`${(i / 40) * 100}%`}
                  stroke="#ccc"
                />
              ))}
              {Array.from({ length: 40 }, (_, i) => (
                <line
                  key={`col-${i}`}
                  x1={`${(i / 40) * 100}%`}
                  y1="0%"
                  x2={`${(i / 40) * 100}%`}
                  y2="100%"
                  stroke="#ccc"
                />
              ))}
              {map.shapes.map((shape, index) => (
                shape.type === 'rectangle' ?
                  (
                    <Tooltip
                      title={
                        <Box>
                          <Typography variant='body2'>{`${t('spaces.maps.title_field')}: ${shape.name}`}</Typography>
                          <Typography variant='body2'>{`${t('spaces.maps.position_linked')}: ${shape.room_name}`}</Typography>
                        </Box>
                      }
                      interactive
                      arrow>
                      <g key={shape.id} className={style.rectangleGroup}>
                        <rect
                          x={shape.x}
                          y={shape.y}
                          cursor={'pointer'}
                          width={shape.width}
                          height={shape.height}
                          fill={shape.color}
                          style={{ cursor: 'pointer' }}
                          className={`${style.rectangle} ${!shape.room_id && style.disableShape}`}
                          onClick={() => shape.room_id && history.push(`/spaces/reservation/${shape.room_id}?view=map`)}
                        />
                        <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                          {shape.name}
                        </text>
                      </g>
                    </Tooltip>
                  ) :
                  (
                    <Tooltip
                      title={
                        <Box>
                          <Typography variant='body2'>{`${t('spaces.maps.title_field')}: ${shape.name}`}</Typography>
                          <Typography variant='body2'>{`${t('spaces.maps.position_linked')}: ${shape.room_name}`}</Typography>
                        </Box>
                      }
                      interactive
                      arrow>
                      <g key={shape.id} className={style.rectangleGroup}>
                        <circle
                          cx={shape.x + shape.width / 2}
                          cy={shape.y + shape.height / 2}
                          r={Math.min(shape.width, shape.height) / 2}
                          cursor={'pointer'}
                          fill={shape.color}
                          style={{ cursor: 'pointer' }}
                          className={`${style.rectangle} ${!shape.room_id && style.disableShape}`}
                          onClick={() => shape.room_id && history.push(`/spaces/reservation/${shape.room_id}?view=map`)}
                        />
                        <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                          {shape.name}
                        </text>
                      </g>
                    </Tooltip>
                  )
              ))}
            </svg>
          </Box>
        ))
      }
    </Box>
  );
};

export default MapsListContainer;