import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import styles from './TableDaysOff.module.scss'; // Importe seu arquivo de estilos
import { Pagination } from '@material-ui/lab';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp } from 'phosphor-react';
import { authHeader, befectiveFormatDate, getMonths, history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, makeStyles } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import IconEdit2 from '../_icons/IconEdit2';
import IconRemove from '../_icons/IconRemove';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  select: {
    "&.MuiOutlinedInput-root": {
      color: "#4f7091",
      "&:hover fieldset": {
        borderColor: "#3788d8"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3788d8"
      }
    }
  }
}));


const TableDaysOff = ({
  data,
  getData,
  alertActions,
  dateFilter,
  setDateFilter,
  year,
  setYear,
  month,
  setMonth,
  userDetails
}) => {

  const classes = useStyles()

  const [disableButton, setDisableButton] = useState(false)

  const { t, i18n } = useTranslation('common');

  const types = {
    1: t("settings.daysoff_settings.types.national_holiday"),
    2: t("settings.daysoff_settings.types.state_holiday"),
    3: t("settings.daysoff_settings.types.municipal_holiday"),
    4: t("settings.daysoff_settings.types.vacations"),
    5: t("settings.daysoff_settings.types.dayoff"),
    6: t("settings.daysoff_settings.types.absence")
  }

  const handleChange = (event) => {
    setMonth(event.target.value)
  };
  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const [daysOffToDelete, setDaysOffToDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'simple-popover-confirm-deactive-colab' : undefined;

  const handleClickConfirmDelete = (event, dayOff) => {
    setDaysOffToDelete(dayOff)
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElConfirmDelete(null)
    setDaysOffToDelete(null)
  };

  const handleConfirmDelete = async () => {
    try {
      if (!daysOffToDelete) {
        throw new Error('Days off to delete not found')
      }

      const res = [4, 5, 6].includes(Number(daysOffToDelete.type))
        ?
        await api.delete(`/settings/free-days/${daysOffToDelete.id}/?type=${daysOffToDelete.type}`, { headers: authHeader() })
        :
        await api.delete(`/settings/free-days/${daysOffToDelete.id}/?date_start=${daysOffToDelete.date_start}&date_end=${daysOffToDelete.date_end}&type=${daysOffToDelete.type}&observation=${daysOffToDelete.observation}`, { headers: authHeader() })


      if (res.status !== 200) {
        throw new Error('Erro to delete days off')
      }

      getData()
      handleClose()
      alertActions.success(t('settings.daysoff_settings.success.delete_days_off'))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    setDisableButton(true)
    if (month === 'all') {
      getData({ year: year, month: '', date_start: '', teams: '', '': '' })
    } else {
      getData({ year: year, month: month, date_start: '', teams: '', '': '' })
    }
    setDisableButton(false)
  }, [month, year])

  return (
    <div className={styles.tableContainer}>
      <Box padding={'28px 20px'} display={'flex'} justifyContent={'space-between'} borderBottom={'4px solid #F5F6F9'}>
        <Box display={'flex'} alignItems={'center'} gap={'14px'}>
          <Typography variant='h4' color={'#1E6B7F'} fontSize={'24px'} fontWeight={500}>{year}</Typography>
          <IconButton aria-label="before" onClick={() => setYear(year - 1)} size='small'>
            <NavigateBefore />
          </IconButton>
          <IconButton aria-label="next" onClick={() => setYear(year + 1)} size='small'>
            <NavigateNext />
          </IconButton>
        </Box>
        <Box>
          <FormControl variant="outlined" size='small'>
            <Select
              labelId="months-filter-select"
              id="demo-simple-select-outlined"
              onChange={handleChange}
              value={month}
              size={'small'}
              className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value={"all"}>{t('months.all')}</MenuItem>
              <MenuItem value={"1"}>{t('months.jan')}</MenuItem>
              <MenuItem value={"2"}>{t('months.feb')}</MenuItem>
              <MenuItem value={"3"}>{t('months.mar')}</MenuItem>
              <MenuItem value={"4"}>{t('months.apr')}</MenuItem>
              <MenuItem value={"5"}>{t('months.may')}</MenuItem>
              <MenuItem value={"6"}>{t('months.jun')}</MenuItem>
              <MenuItem value={"7"}>{t('months.jul')}</MenuItem>
              <MenuItem value={"8"}>{t('months.aug')}</MenuItem>
              <MenuItem value={"9"}>{t('months.sep')}</MenuItem>
              <MenuItem value={"10"}>{t('months.oct')}</MenuItem>
              <MenuItem value={"11"}>{t('months.nov')}</MenuItem>
              <MenuItem value={"12"}>{t('months.dec')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer className='container-table-control' component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <Table className='table-control' sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow sx={{ textTransform: 'uppercase' }}>
              <TableCell className={styles.tableHeaderTd} width={'20%'}>
                {t("settings.daysoff_settings.table.col_date")}
              </TableCell>
              <TableCell className={styles.tableHeaderTd}>
                {t("settings.daysoff_settings.table.col_colabs")}
              </TableCell>
              <TableCell className={styles.tableHeaderTd} width={'25%'}>
                {t("settings.daysoff_settings.table.col_description")}
              </TableCell>
              <TableCell className={styles.tableHeaderTd} width={'15%'} align='left'>
                {t("settings.daysoff_settings.table.col_type")}
              </TableCell>
              {userDetails.allowed_delete && <TableCell className={styles.tableHeaderTd} width={'10px'} align='center' />}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.length > 0
                ?
                data.map(monthData => (
                  <React.Fragment key={monthData.month}>
                    <TableRow key={monthData.month} className='table-row'>
                      <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '14px', backgroundColor: '#DBF5F2', color: '#22BEAD', fontWeight: 500 }} colSpan={5}>
                        {getMonths(monthData.month - 1, t)}
                      </TableCell>
                    </TableRow>
                    {monthData.data.map((item, index) => (
                      <TableRow key={`${monthData.month}+${index}`} className='table-row'>
                        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                          {
                            item.date_start === item.date_end
                              ?
                              befectiveFormatDate(item.date_start, i18n.language)
                              :
                              befectiveFormatDate(item.date_start, i18n.language) + ' - ' + befectiveFormatDate(item.date_end, i18n.language)
                          }
                        </TableCell>
                        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                          {
                            item.total_users
                              ?
                              (
                                item.total_users === 'total_empresa'
                                  ?
                                  t("settings.daysoff_settings.total_organization")
                                  :
                                  item.users.length > 3
                                    ?

                                    <Box>
                                      <span style={{ fontSize: '12px' }}>{`${item.users.slice(0, 3).map(u => u.alias).join(', ')}`}</span>
                                      <Tooltip
                                        arrow
                                        interactive
                                        title={
                                          <Box>
                                            {item.users.map(u => <Box fontSize={'12px'} fontWeight={'400'} padding={'4px'}>{`${u.alias} (${u.email})`}</Box>)}
                                          </Box>
                                        }
                                        placement="right"
                                        classes={{ tooltip: classes.customWidth }}
                                      >
                                        <span style={{ fontSize: '12px' }}>{", [...]"}</span>
                                      </Tooltip>
                                    </Box>
                                    :
                                    <Box fontSize={'12px'}>
                                      {item.users.map(u => u.alias).join(', ')}
                                    </Box>
                              )
                              :
                              item.username

                          }
                        </TableCell>
                        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                          {
                            item.observation.length > 30
                              ?
                              <Box>
                                <span style={{ fontSize: '12px' }}>{`${item.observation.slice(0, 30)}`}</span>
                                <Tooltip
                                  arrow
                                  interactive
                                  title={<Box fontSize={'12px'} fontWeight={'400'} padding={'4px'}>{item.observation}</Box>}
                                  placement="right"
                                  classes={{ tooltip: classes.customWidth }}
                                >
                                  <span style={{ fontSize: '12px' }}>{"[...]"}</span>
                                </Tooltip>
                              </Box>
                              :
                              item.observation
                          }
                        </TableCell>
                        <TableCell className={[1, 2, 3, 6].includes(Number(item.type)) ? styles.color_red : ([5].includes(Number(item.type)) ? styles.color_purple : styles.color_blue)} style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px', textAlign: 'left' }}>
                          {types[Number(item.type)]}
                        </TableCell>
                        {userDetails.allowed_delete && (
                          <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                            <div className={styles.boxActions}>
                              <button className={`${styles.buttonAction} ${styles.remove}`} onClick={(e) => handleClickConfirmDelete(e, item)}>
                                <IconRemove />
                              </button>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                    <TableRow key={`${monthData.month}-clear`} className='table-row' sx={{ backgroundColor: '#F5F6F9' }}>
                      <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px', height: '4px', padding: 0 }} colSpan={5} />
                    </TableRow>
                  </React.Fragment>
                ))
                :
                <TableRow className='table-row'>
                  <TableCell colSpan={5} style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                    <Typography>{t("settings.daysoff_settings.table.no_data")}</Typography>
                  </TableCell>
                </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>
      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
        title={t('settings.daysoff_settings.delete_message')}
      />
    </div>
  );
};

export default TableDaysOff;
