import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";

import { useTable, useSortBy } from 'react-table'

import Skeleton from '@material-ui/lab/Skeleton';


function DrawerActivity(props) {
    const { t, i18n } = useTranslation('common');

    let today = props.today || {};

  

    return (
        <div className="internal loading">
        <div className="header">
            <button className="arrow back"><Skeleton variant="circle" width={28} height={28} /></button>
            <div className="title"><Skeleton variant="text" width={180} height={17} />  </div>
            <button className="arrow foward"><Skeleton variant="circle" width={28} height={28} /></button>
        </div>
        <div className="category"><Skeleton variant="text" width={100} height={17} />  </div>
        <div className="total"><Skeleton variant="text" width={200} height={17} />  </div>
        <div className="filters">
            <div className="filter">
                <div className="icon"><Skeleton variant="circle" width={32} height={32} /></div>
                <div className="number"><Skeleton variant="text" width={120} height={17} />  </div>
       
            </div>
            <div className="filter teammate">
                <div className="icon"><Skeleton variant="circle" width={32} height={32} /></div>
                <div className="number"><Skeleton variant="text" width={120} height={17} />  </div>
            </div>
        </div>

        <div className="dataTable">
            <Skeleton variant="rect" width="100%" height={247} />            
        </div>

    </div>
    )
};

function mapStateToProps(state) {

    return {
       
    }

}

export default connect(mapStateToProps)(DrawerActivity)