import { Box, Chip, Divider, FormControlLabel, Grid, List, ListItem, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { X } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { alertActions, teammatesActions, usersActions } from '../../_actions'
import IconAdd from '../../_components/_icons/IconAdd'
import Filterbar from '../../_components/_shared/Filterbar'
import Toolbar from '../../_components/_shared/Toolbar'
import { authHeader } from '../../_helpers'
import { api } from '../../_helpers/api'
import SelectResponsibles from './SelectResponsibles'
import './styles.scss'
import { Button, Drawer, IconButton } from '@material-ui/core'
import { WeekDays } from '../../_components/checkin_page/WeekDays'
import { Close } from '@material-ui/icons'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22BEAD' : '#22BEAD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function CheckinSettings(props) {
  const [form, setForm] = useState(null)
  const [formError, setFormError] = useState({
    type_check_in: '',
    type_correction_point: '',
    type_report_submission: '',
    send_email_to_responsible: '',
    tolerated_time: '',
    signature_date: '',
    responsible_solicitation_ids: ''
  })
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDrower, setShowDrower] = useState(false)
  const [optionsResponsibles, setOptionsResponsibles] = useState([])
  const [reason, setReason] = useState('');
  const { userDetails, alertActions } = props

  const { t, i18n } = useTranslation('common');

  const [adjustmentReasons, setAdjustmentReasons] = useState([]);

  const handleDeleteReason = (reasonToDelete) => () => {
    if (reasonToDelete)
      setAdjustmentReasons((reasons) => reasons.filter((reason) => reason.label !== reasonToDelete.label));
  };


  const getCheckinSettings = async () => {
    try {
      setIsLoading(true)
      const resSettings = await api.get('/checkin/settings', { headers: authHeader() });
      const resResponsibles = await api.get(`/goals/get-responsibles?organization_id=${userDetails.organizationId}`, {
				headers: authHeader()
			})

      setOptionsResponsibles(resResponsibles?.data)

      setForm({
        ...resSettings.data,
        responsible_solicitation_ids: resResponsibles?.data?.filter((res) => resSettings.data?.responsible_solicitation_ids.includes(res.value))
      })

      if (resSettings.data.adjustment_reasons.length > 0) {
        setAdjustmentReasons(resSettings.data.adjustment_reasons.map((ar) => ({ id: ar.id, label: ar.label_custom })))
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }

  }

  const saveCheckinSettings = async () => {
    try {
      setIsSubmitting(true)
      const payload = {
        ...form,
        responsible_solicitation_ids: form.responsible_solicitation_ids.map(res => res.value),
        adjustment_reasons: adjustmentReasons
      }
      await api.post(`/checkin/settings`, payload, {
        headers: authHeader()
      })
      alertActions.success(t('checkin.settings_form.alerts.success_save'))
      getCheckinSettings()
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error)
      //alertActions.error(t('checkin.settings_form.alerts.error_save'))
    }
  }


  const validateForm = (form) => {
    const errors = {};
    // if (!form.type_check_in) {
    //   errors.type_check_in = t('checkin.settings_form.errors.field_required')
    // }
    if (!form.type_correction_point) {
      errors.type_correction_point = t('checkin.settings_form.errors.field_required')
    }
    if (form.responsible_solicitation_ids.length === 0) {
      errors.responsible_solicitation_ids = t('checkin.settings_form.errors.field_required')
    }
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setForm({
        ...form,
        [event.target.name]: event.target.checked
      })
      return;
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const addReason = () => {
    if (reason !== '') {
      const checkIfExists = adjustmentReasons?.filter(ar => ar?.label?.toLowerCase() === reason.toLowerCase())
      if (checkIfExists.length === 0) {
        setAdjustmentReasons([...adjustmentReasons, { id: null, label: reason }])
        setReason('')
      }
    }
  }

  const handleAddReason = (e) => {
    if (e.key === 'Enter') {
      addReason()
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      saveCheckinSettings()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    getCheckinSettings()
    document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.check_in_remote');
  }, [])

  useEffect(() => {

  }, [adjustmentReasons])


  return (
    <section className="mainContent">
      <Toolbar />
      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhenMonths={'disabled'}
        filterWhat={'disabled'}
      />
      <Paper elevation={0} sx={{ marginX: 4, marginY: 3, padding: 3 }}>
        {
          !isLoading &&
          <Grid
            container
            spacing={2}
            component={'form'}
            id='formSettingsCheckin'
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Typography component={'h6'} variant='h6'>{t('checkin.settings_form.title')}</Typography>
              <Box className='btnContainer'>
                <button className={`btnCancel`} type='button'>{t('button.cancel')}</button>
                <button className={`btnSubmit ${isSubmitting && 'disabled'}`} type='button' disabled={isSubmitting} onClick={handleSubmit}>
                  {!isSubmitting ? t('button.save') : t('button.saving')}
                </button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.type_check_in')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.type_check_in')}</Typography>
              </Box>
              <Box className='field'>
                <Select
                  id="type_check_in"
                  name="type_check_in"
                  fullWidth
                  value={form?.type_check_in}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="free">{t('checkin.settings_form.options.free')}</MenuItem>
                  <MenuItem value="period">{t('checkin.settings_form.options.period')}</MenuItem>
                </Select>
                {formError.type_check_in && <div className="error">{formError.type_check_in}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid> */}
            {/**  
             * Calendario de horario, period por semana.
             * **/}
            {/* <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.work_time')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.work_time')}</Typography>
              </Box>
              <Box display={"flex"} gap={"10px"} alignItems={"center"}>

                 <WeekDays
                  language={i18n.language}
                />
                <Box className="btn-work-time" width={334} height={20} padding={"16px 32px 16px 14px"} border={"solid rgb(208, 208, 208) 1px"} borderRadius={"4px"} onClick={() => setShowDrower(true)}>
                  <Typography variant='' fontWeight={500}>Personalizado</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid> */}
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.type_correction_point')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.type_correction_point')}</Typography>
              </Box>
              <Box className='field'>
                <Select
                  id="type_correction_point"
                  name="type_correction_point"
                  fullWidth
                  value={form?.type_correction_point}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="with_permission">{t('checkin.settings_form.options.without_permission')}</MenuItem>
                  <MenuItem value="without_permission">{t('checkin.settings_form.options.with_permission')}</MenuItem>
                </Select>
                {formError.type_correction_point && <div className="error">{formError.type_correction_point}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.type_report_submission')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.type_report_submission')}</Typography>
              </Box>
              <Box className='field'>
                <Select
                  id="type_report_submission"
                  name="type_report_submission"
                  fullWidth
                  value={form?.type_report_submission}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="manual">{t('checkin.settings_form.options.manual')}</MenuItem>
                  <MenuItem value="automatic">{t('checkin.settings_form.options.automatic')}</MenuItem>
                </Select>
                {formError.type_report_submission && <div className="error">{formError.type_report_submission}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.tolerated_time')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.tolerated_time')}</Typography>
              </Box>
              <Box className='field'>
                <TextField
                  id="tolerated_time"
                  name="tolerated_time"
                  fullWidth
                  type="number"
                  value={form?.tolerated_time}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0, max: 120 } }}
                  required
                />
                {formError.tolerated_time && <div className="error">{formError.tolerated_time}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.signature_date')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.signature_date')}</Typography>
              </Box>
              <Box className='field'>
                <TextField
                  id="signature_date"
                  name="signature_date"
                  fullWidth
                  type="number"
                  value={form?.signature_date}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 1, max: 20 } }}
                  required
                />
                {formError.signature_date && <div className="error">{formError.signature_date}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.days_in_advance')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.days_in_advance')}</Typography>
              </Box>
              <Box className='field'>
                <TextField
                  id="days_in_advance"
                  name="days_in_advance"
                  fullWidth
                  type="number"
                  value={form?.days_in_advance}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 1, max: 20 } }}
                  required
                />
                {formError.days_in_advance && <div className="error">{formError.days_in_advance}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.allow_geolocation')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.allow_geolocation')}</Typography>
              </Box>
              <Box className='field'>
                <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={<IOSSwitch sx={{ m: 0 }} checked={form?.allow_geolocation} onChange={handleChange} name="allow_geolocation" />}
                  />
                </Box>
                {formError.allow_geolocation && <div className="error">{formError.allow_geolocation}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.send_email_to_responsible')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.send_email_to_responsible')}</Typography>
              </Box>
              <Box className='field'>
                <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={<IOSSwitch sx={{ m: 0 }} checked={form?.send_email_to_responsible} onChange={handleChange} name="send_email_to_responsible" />}
                  />
                </Box>
                {formError.send_email_to_responsible && <div className="error">{formError.send_email_to_responsible}</div>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} zIndex={1}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.responsible_solicitation_ids')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.responsible_solicitation_ids')}</Typography>
              </Box>
              <Box className='field'>
                <SelectResponsibles defaultValue={form?.responsible_solicitation_ids} options={optionsResponsibles} onChange={(values) => setForm({ ...form, responsible_solicitation_ids: values })} />
                {formError.responsible_solicitation_ids && <div className="error">{formError.responsible_solicitation_ids}</div>}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
              <Box>
                <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.adjustment_reasons')}</Typography>
                <Typography variant='caption'>{t('checkin.settings_form.help.adjustment_reasons')}</Typography>
              </Box>
              <Box className='reasonsContainer'>
                <Box className='inputBox'>
                  <TextField
                    id="outlined-size-small"
                    value={reason}
                    size="small"
                    fullWidth
                    onChange={(e) => setReason(e.target.value)}
                    onKeyDown={(e) => handleAddReason(e)}
                  />
                  <button type="button" className="add" onClick={addReason}><IconAdd /></button>
                </Box>
                <List className='reasonsList'>
                  {adjustmentReasons.map((data) => {
                    return (
                      <ListItem key={data.label}>
                        <Chip
                          label={data.label}
                          onDelete={handleDeleteReason(data)}
                          deleteIcon={<X size={12} />}
                          style={{
                            borderRadius: 0,
                            backgroundColor: '#E8F1FE'
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Grid>
          </Grid>
        }
        {/* {
        
        showDrower && (
          <Drawer
            anchor="right"
            open={showDrower}
            onClose={() => setShowDrower(false)}
            PaperProps={{
              style: {
                padding: '0px 12px',
                width: '450px'
              }
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%", alignSelf: "center", position: 'absolute', right: 4, top: 4 }}>
              <Typography variant='subtitle1' fontWeight={500}>Horario de trabalho Personalizado!</Typography>
              <IconButton aria-label="close" onClick={() => setShowDrower(false)}>
                <Close fontSize="medium" />
              </IconButton>
            </div>

            <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" gap={"15px"}>
               <WeekDays
                  language={i18n.language}
                  showWorkTime={true}
                  isClickAble={true}
                  group_id={userDetails.group_id}
                /> 


            </Box>

          </Drawer>
        )
        
        } */}

      </Paper>
    </section>

  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinSettings)