import React from 'react';

const IconToday = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_11" data-name="Retângulo 11" width="24" height="24" fill="none"/>
        </g>
        <g className="icon"  id="Q3_icons" data-name="Q3 icons" transform="translate(1 2)">
            <path id="Caminho_2" data-name="Caminho 2" d="M23,8H17.5V5a1,1,0,0,0-1-1h-7a1,1,0,0,0-1,1V8H3A1,1,0,0,0,2,9V23a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V9A1,1,0,0,0,23,8ZM10.5,6h5V8h-5ZM22,10v3.2a18.9,18.9,0,0,1-9,2.35A18.9,18.9,0,0,1,4,13.2V10ZM4,22V15.45a19.95,19.95,0,0,0,8,2.05v1.75h2V17.5a19.95,19.95,0,0,0,8-2.05V22Z" transform="translate(-2 -4)" fill="#fda02b"/>
        </g>
        </svg>
 

    )
}

export default IconToday;