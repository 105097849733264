import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import "react-datepicker/dist/react-datepicker.css";
import { alertActions, filterActions, teamsActions } from '../../_actions';
import { statsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../_helpers/useOutsideClick';
import IconSearch from '../_icons/IconSearch';
import IconClear from '../_icons/IconClear';
import styles from './FilterbarUsersSettings.module.scss'
import { history } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: '100px',
		minHeight: '42px',
		backgroundColor: '#FDBE2B',
		borderRadius: '2px',
		cursor: 'pointer',
		fontWeight: '600',
		border: 'none',
		color: '#106276',
		textTransform: 'none',
		boxShadow: 'none',
		transition: '0.2s',

		'&:hover': {
			backgroundColor: '#FDBE2B',
			boxShadow: '0px 0px 4px 0px #2B2B2B55',
		},
		'& > span': {
			fontSize: '13px',
			display: 'flex',
			flexWrap: 'nowrap'
		}
	}

}));

function FilterbarTeams(props) {

	const classes = useStyles()
	const refFilter = useRef();
	const searchInput = useRef(null);

	const { t, i18n } = useTranslation('common');
	const { teammatesActions, alertActions, getTeamsData, filter, filterActions, getData, filterLabel, hasCreateUser, userDetails } = props;
	const [filterWhatValue, setFilterWhatValue] = useState('');
	const [filterWhat, setWhat] = useState(true);


	// useOutsideClick(refFilter, target => {

	// 	if (filterWhatValue.length > 2) {
	// 		changeFilterWhat();
	// 	}

	// 	setWhat(false)
	// });

	const handleChange = e => {

		setFilterWhatValue(e.target.value)

	}

	const changeFilterWhat = () => {


		let value = filterWhatValue.toLowerCase();

		if (value.length > 2) {
			filterActions.set(value, "what");
		} else {
			filterActions.set('', 'what');
		}

	}

	function submitWhat(e) {
		if (e.keyCode == 13) {
			changeFilterWhat()
			let value = filterWhatValue.toLowerCase();
			getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: value })
		}
	}

	function clearWhat() {
		setFilterWhatValue('');
		filterActions.clear("what");
		filterActions.set('', 'what');
		getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, search_text: '' })
	}


	// useEffect(() => {

	// 	searchInput.current.focus();

	// }, [filterWhat])

	// useEffect(() => {

	// }, [])

	return (
		<div className={styles.mainFilterbar}>
			<div className={styles.filters}>
				<div className={filterWhat ? `${styles.filter} ${styles.what} ${styles.open}` : `${styles.what} ${styles.filter}`} ref={refFilter}>
					<div className={styles.holder}>
						<span className={styles.label}>{t('filter.what')}:</span>
						<span className={styles.value} onClick={() => {
							setWhat(!filterWhat);
						}}>{filterWhatValue}

							{!filterWhatValue && (filterLabel || 'Search')}
						</span>

						<input ref={searchInput} className={styles.input} placeholder={t('filter.search_2')} value={filterWhatValue}
							onChange={handleChange} onKeyUp={(e) => {
								submitWhat(e);
							}} />
						<div className={styles.clear} onClick={() => {
							clearWhat()
						}}><IconClear /></div>
						<div className={filterWhat ? `${styles.clear} ${styles.submit} ${styles.show}` : ` ${styles.clear} ${styles.submit}`} onClick={() => {
							changeFilterWhat()
						}}><IconSearch /></div>
					</div>
				</div>
			</div>
			{
				(hasCreateUser && userDetails.allowed_create) &&
				<div className="ButtonActionsGroup">
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => history.push('/settings/users/create')}>
						{`+ ${t('settings.users.user')}`}
					</Button>
				</div>
			}
		</div>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch),
		teamsActions: bindActionCreators(teamsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teammates: state.teammates.payload,
		loadingTeammates: state.teammates.loading,
		teams: state.teams.payload,
		loadingTeams: state.teams.loading,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterbarTeams)
