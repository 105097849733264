import React from 'react';

const IconExpand = props => {
    return (
        
      <svg xmlns="http://www.w3.org/2000/svg" width="6.414" height="7.207" viewBox="0 0 6.414 7.207">
      <g id="Icon_feather-chevrons-right" data-name="Icon feather-chevrons-right" transform="translate(5.707 0.707) rotate(90)">
        <path id="Caminho_98" data-name="Caminho 98" d="M0,0,2.5,2.5,0,5" transform="translate(3.5)" fill="none" stroke="#187dff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Caminho_99" data-name="Caminho 99" d="M0,0,2.5,2.5,0,5" fill="none" stroke="#187dff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </svg>
    
       

    )
}

export default IconExpand;