export function authHeader(refresh = false) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem(process.env.KEY_USER_LOCAL_STORAGE));   

    if (user && user.accessToken && !refresh) {
        return { 'Authorization': 'Bearer ' + user.accessToken };
    }
    else if (user && user.accessToken && refresh) {
      
        return { 'Authorization': 'Bearer ' + user.refreshToken};
    }
    else {
        return {};
    }
}