import React from 'react';

const IconFolders = props => {
    return (


        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
            <g id="icone" transform="translate(0.127)">
                <g id="invisible_box" data-name="invisible box" transform="translate(0)">
                    <rect id="Retângulo_1042" data-name="Retângulo 1042" width="13" height="13" transform="translate(-0.127)" fill="none" />
                </g>
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(0.402 1.354)">
                    <path id="Caminho_81" data-name="Caminho 81" d="M13.375,6.354H9.313L7.958,5H4.708a.542.542,0,0,0-.542.542V7.167H2.542A.542.542,0,0,0,2,7.708V14.75a.542.542,0,0,0,.542.542h8.667a.542.542,0,0,0,.542-.542V13.125h1.625a.542.542,0,0,0,.542-.542V6.9A.542.542,0,0,0,13.375,6.354Zm-2.708,7.854H3.083V8.25H5.331L6.685,9.6h3.981Zm2.167-2.167H11.75V9.063a.542.542,0,0,0-.542-.542H7.146L5.792,7.167H5.25V6.083H7.5L8.852,7.438h3.981Z" transform="translate(-2 -5)" fill="#c2cdda" />
                </g>
            </g>
        </svg>




    )
}

export default IconFolders;