import React from 'react';

import { Skeleton } from '@material-ui/lab';

import './styles.scss'

export function ObjectivesTempRulerSkeleton() {

  return (
    <div className="container-objectives-ruler-skeleton">

      <header>
        <Skeleton variant="text" width="45%" height={10} />
        <Skeleton variant="text" width="20%" height={10} />
      </header>

      <main>
        <div>
          <Skeleton variant="text" width="100%" height={35} />
        </div>


        <div className='progressBarTemp-skeleton'>
          <div style={{ width: '100%', left: '0%' }}>
            <Skeleton variant="text" width="100%" height={25} />
          </div>
        </div>
      </main>
    </div>
  )
}

