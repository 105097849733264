import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { Card } from "../../_components/rooms_page/rooms/Card";
import { CardsContainer } from '../../_components/rooms_page/rooms/CardsContainer';
import { authHeader, getMonths } from '../../_helpers';
import { api } from '../../_helpers/api';


import { Toaster } from "react-hot-toast";
import { useRoomsFilter } from "../../contexts/RoomsFilterContext";
import './fullcalendar.scss';
import style from './styles.module.scss';
import MapsListContainer from "../../_components/rooms_page/MapsListContainer";
import ModalViewSubspaces from "../../_components/rooms_page/ModalViewSubspaces";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: '10 !important',
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function Spaces(props) {
  const { rooms } = useRoomsFilter()

  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props

  const [locations, setLocations] = useState([])

  const [showCalendar, setShowCalendar] = useState(false);
  const [showMaps, setShowMaps] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [roomsFilter, setRoomsFilter] = useState()
  const [maps, setMaps] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [currentSpace, setCurrentSpace] = useState(null)
  const [openModalViewSubspaces, setOpenModalViewSubspaces] = useState(false)

  const [dateRef, setDateRef] = useState(null)

  const handleOpenModalViewSubspaces = (space) => {
    setCurrentSpace(space)
    setOpenModalViewSubspaces(true)
  }

  const handleCloseModalViewSubspaces = () => {
    setOpenModalViewSubspaces(false)
    setCurrentSpace(null)
  }

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }


  async function getRoomsLocations(date) {
    try {
      setIsLoading(true)
      const queryDate = date ? `date=${date}` : ''
      let roomsFilter = ""
      let url = `/rooms/locations`

      if (rooms.length > 0) {
        roomsFilter = rooms.reduce((acc, room) => {
          return acc += `${room},`
        }, "")

        url += `?rooms_ids=${roomsFilter}`.slice(0, -1)
      } else {
        url += `?${queryDate}`
      }

      const response = await api.get(url, {
        headers: authHeader()
      })

      setLocations(response.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
      setIsLoading(false)
    }
  }


  function renderCellContent(info) {
    const day = info.event.startStr

    // const filtererEventByDay = colabPoints.find(item => item.day === day)

    return (
      <Box display="flex" gap="4px" alignItems="center" justifyContent="center">
        <Box key={3} width={6} height={6} bgcolor="#22BEAD" border="1px solid #22BEAD" borderRadius="100%" />
      </Box>
    );
  }

  const getLocationsCalendarData = async (fetchInfo, successCallback, failureCallback) => {
    try {
      const start_date = moment(fetchInfo.start).format('YYYY-MM-DD')
      const end_date = moment(fetchInfo.end).format('YYYY-MM-DD')

      const response = await api.get(`/rooms/locations/calendar?date_start=${start_date}&date_end=${end_date}`, {
        headers: authHeader()
      });

      successCallback(
        response.data.map(event => {
          return {
            title: "",
            date: moment(event.ref_day).format('YYYY-MM-DD')
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleEventClick = async (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    console.log(clickInfo.date)
    const now = moment().format('YYYY-MM-DD');

    setDateRef(moment(clickInfo.date).format('YYYY-MM-DD'))
    if (moment(clickInfo.dateStr).isBefore(now)) return

    await getRoomsLocations(moment(clickInfo.date).format('YYYY-MM-DD'))
    setOpenModal(true)
  }

  // const showToast = () => {
  //   toast(
  //     (tt) => (
  //       <Grid container position={'relative'} p={'4px'}>
  //         <Box position={'absolute'} top={'-3px'} right={'-8px'}>
  //           <IconButton size='small' onClick={() => toast.dismiss(tt.id)}>
  //             <XCircle size={16} color="#106276" />
  //           </IconButton>
  //         </Box>
  //         <Grid item xs={12} display={'flex'} gap="10px">
  //           <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
  //             <Box className={style.toastCustomIcon}>
  //               <CalendarCheck color="#22BEAD" />
  //             </Box>
  //           </Grid>
  //           <Grid item xs={9} alignItems="center" justifyContent="center">
  //             <Typography component={'h6'} sx={{ color: '#22BEAD', fontSize: 14, fontWeight: 500, marginBottom: '2px' }}>
  //               Sala reservada!
  //             </Typography>
  //             <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>Segunda feira, 19</Typography>
  //             <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>08:00 às 09:00</Typography>
  //           </Grid>
  //         </Grid>
  //       </Grid>),
  //     {
  //       duration: 4000,
  //       style: {
  //         borderRadius: '4px',
  //         background: '#DBF5F2',
  //         color: '#fff',
  //         minWidth: '250px',
  //         minHeight: '90px',
  //         padding: '0px'
  //       }
  //     }
  //   );
  // }

  const [openDrawer, setOpenDrawer] = useState(false)
  const [roomToDrawer, setRoomToDrawer] = useState(false)

  async function handleOpenDrawer(room) {
    try {
      const response = await api.get(`/rooms/reservations-history/${room.id}`, {
        headers: authHeader()
      })

      const data = {
        ...response.data,
        title: room.title
      }

      setRoomToDrawer(data)
      setOpenDrawer(true)
    } catch (error) {
      console.log(error.response)
      alertActions.error('Erro ao buscar histórico da sala!')
    }

  }

  function handleCloseDrawer() {
    setOpenDrawer(false)
    setRoomToDrawer(null)
  }


  async function getRooms() {
    try {
      const response = await api.get('/rooms', {
        headers: authHeader()
      })


      const roomsData = response.data?.reduce((acc, room) => {
        return [...acc, { name: room.name, value: room.id }]
      }, [])

      setRoomsFilter(roomsData)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  async function getMapsFull() {
    try {
      const response = await api.get('/rooms/maps_full', {
        headers: authHeader()
      })
      setMaps(response.data)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
    }
  }


  useEffect(() => {
    getRoomsLocations()
    // showToast()
  }, [rooms])

  useEffect(() => {
    getRooms()
    getMapsFull()
    const searchParams = new URLSearchParams(location.search);
    const paramView = searchParams.get('view');
    if (paramView && paramView === 'map') {
      setShowMaps(true)
    }

  }, [])

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          setShowCalendar={setShowCalendar}
          showCalendar={showCalendar}
          filterRoom={true}
          roomsData={roomsFilter}
        // showMaps={showMaps}
        // setShowMaps={setShowMaps}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          {
            showCalendar ? (
              <Box id='roomsReservation' padding={4} sx={{ background: '#fff' }}>
                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView='dayGridMonth'
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  weekends={true}
                  firstDay="0"
                  contentHeight={750}
                  dayHeaders={true}
                  headerToolbar={{
                    left: 'title',
                    right: 'today prev,next'
                  }}
                  events={
                    // [{ title: '', date: new Date().toISOString() }]
                    (fetchInfo, successCallback, failureCallback) => getLocationsCalendarData(fetchInfo, successCallback, failureCallback)
                  }
                  eventContent={renderCellContent}
                  dateClick={handleEventClick}
                />
              </Box>
            )
              :
              showMaps ?
                (
                  <MapsListContainer userDetails={userDetails} maps={maps} alertActions={alertActions} />
                )
                : (<CardsContainer userDetails={userDetails} locations={locations} alertActions={alertActions} isLoading={isLoading} handleOpenModalViewSubspaces={handleOpenModalViewSubspaces} handleOpenDrawer={handleOpenDrawer} />)
          }

        </div>
        {/* PAGE ENDS */}

      </section>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          setOpenModal()
          setDateRef(null)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            py="42px"
            px="26px"
            borderRadius="8px"
            gap="28px"
            maxWidth="fit-content"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
            maxHeight="500px"
            sx={{ overflowY: 'scroll' }}
          >
            {
              locations.length > 0 ?
                locations.map(room => (
                  <Card key={room.id} room={room} userDetails={userDetails} showIcon={true} showBorder={true} width="100%" handleOpenModalViewSubspaces={handleOpenModalViewSubspaces} handleOpenDrawer={handleOpenDrawer} dateRef={dateRef}/>
                ))
                :
                <Typography variant='subtitle2' fontWeight={400} color={'#1E6B7F'}>{t('spaces.no_rooms_available')}</Typography>
            }
          </Box>
        </Slide>
      </Modal>
      {
        openModalViewSubspaces &&
        <ModalViewSubspaces openModal={openModalViewSubspaces} currentSpace={currentSpace} closeModal={handleCloseModalViewSubspaces} dateRef={dateRef}/>
      }

      {roomToDrawer && (
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={handleCloseDrawer}
          PaperProps={{
            style: {
              padding: '0px 12px',
              width: '350px',
              zIndex: 9999
            }
          }}
        >
          <div style={{ position: 'absolute', right: 4, top: 4 }}>
            <IconButton aria-label="close" onClick={handleCloseDrawer}>
              <Close fontSize="medium" />
            </IconButton>
          </div>

          <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

            <Box display="flex" flexDirection="column" gap={1}>

              <Box display="flex" flexDirection="column" mb={1} gap="6px" paddingX="16px">
                <Typography fontSize={18} color="#1E6B7F" fontWeight={600}>{roomToDrawer.title}</Typography>
                <Typography fontSize={12} color="#98ACC1" fontWeight={400}>{t('spaces.total_reservations') + ": " + roomToDrawer.totalReservations}</Typography>
              </Box>

              {roomToDrawer.monthsHistory.map((item, index) => (
                <Box key={index} display="flex" flexDirection="column" mt={index !== 0}>
                  <Box display="flex" bgcolor="#F5F6F9" paddingX={2} paddingY={1} borderRadius="4px">
                    <Typography color="#98ACC1" fontSize={12} fontWeight={500}>{getMonths(parseInt(item.month) - 1, t)} {item.year}</Typography>
                  </Box>

                  {item.reservations.map((reservation, index) => (
                    <Box key={index} display="flex" flexDirection="column" mt={2} paddingX="16px">
                      <Box display="flex" flexDirection="column" gap="4px">
                        <Typography color="#4F7091" fontSize={14}>
                          <span style={{ color: '#4F7091', fontWeight: 600, fontSize: "14px" }}>
                            {{
                              0: t('settings.teams.sun'),
                              1: t('settings.teams.mon'),
                              2: t('settings.teams.tue'),
                              3: t('settings.teams.wed'),
                              4: t('settings.teams.thu'),
                              5: t('settings.teams.fri'),
                              6: t('settings.teams.sat')
                            }[moment(reservation.date).weekday()] + ", " + moment(reservation.date).format('DD/MM')}
                          </span>
                          {' '}
                          {
                            reservation.times
                              ?
                              reservation.times.map(t => (`${t.start_time} - ${t.end_time}`)).join(', ')
                              :
                              reservation.timeStart + " - " + reservation.timeEnd
                          }
                        </Typography>
                        <Typography color="#4F7091" fontSize={12}>{reservation.reservedBy}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Button
              style={{ backgroundColor: '#E3EFFF', color: '#187DFF', height: '40px', boxShadow: 'none', marginTop: "16px" }}
              onClick={handleCloseDrawer}
            >
              {t('spaces.back')}
            </Button>
          </Box>

        </Drawer>
      )}

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Spaces)