import {periodicConstants} from '../_constants';
import {periodicService} from '../_services';
import {usersService} from '../_services';
import {alertActions} from '.';
import {history} from '../_helpers';

export const periodicActions = {
    postPeriodic,
    getPeriodics,
    getPeriodic,
    activatePeriodic,
    deactivatePeriodic,
    deletePeriodic

};

function postPeriodic(form) {
    return dispatch => {
        dispatch(request());

        periodicService.postPeriodic(form)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.POST_PERIODIC_REPORT_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.POST_PERIODIC_REPORT_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.POST_PERIODIC_REPORT_FAILURE, error}
    }
}

function deletePeriodic(id) {
    return dispatch => {
        dispatch(request());

        periodicService.deletePeriodic(id)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.DELETE_PERIODIC_REPORT_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.DELETE_PERIODIC_REPORT_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.DELETE_PERIODIC_REPORT_FAILURE, error}
    }
}

function activatePeriodic(id) {
    return dispatch => {
        dispatch(request());

        periodicService.activatePeriodic(id)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_ACTIVATE_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_ACTIVATE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_ACTIVATE_FAILURE, error}
    }
}

function deactivatePeriodic(id) {
    return dispatch => {
        dispatch(request());

        periodicService.deactivatePeriodic(id)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_DEACTIVATE_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_DEACTIVATE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.PATCH_PERIODIC_REPORT_DEACTIVATE_FAILURE, error}
    }
}
// getPeriodic
function getPeriodic(id) {
    return dispatch => {
        dispatch(request());

        periodicService.getPeriodic(id)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.GET_PERIODIC_REPORT_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.GET_PERIODIC_REPORT_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.GET_PERIODIC_REPORT_FAILURE, error}
    }
}
function getPeriodics(filters) {
    return dispatch => {
        dispatch(request());

        periodicService.getPeriodics(filters)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: periodicConstants.GET_PERIODIC_REPORT_REQUEST}
    }

    function success(payload) {
        return {type: periodicConstants.GET_PERIODIC_REPORT_SUCCESS, payload}
    }

    function failure(error) {
        return {type: periodicConstants.GET_PERIODIC_REPORT_FAILURE, error}
    }
}


