import React from 'react';

import { Skeleton } from '@material-ui/lab';

import './styles.scss'

export function ObjectivesFooterSkeleton() {

  return (
    <div className="container-objectives-footer-skeleton">
      <header>
        <Skeleton variant="text" width="10%" height={15} />
        <Skeleton variant="text" width="10%" height={15} />
      </header>

      <main className="content-objectives-footer-skeleton">
        <Skeleton variant="text" width="10%" height={15} />
        <Skeleton variant="text" width="7%" height={15} />
        <Skeleton variant="text" width="15%" height={15} />
        <Skeleton variant="text" width="12%" height={15} />
        <Skeleton variant="text" width="7%" height={15} />
      </main>
    </div>
  )
}
