import React, { useRef, useState, compose, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../../_actions';

import { useTable, useSortBy } from 'react-table'

import { settingsActions } from '../../../_actions';

import IconAllAgents from '../../_icons/IconAllAgents'
import IconMac from '../../_icons/IconMac'
import IconWindows from '../../_icons/IconWindows'
import IconRemove from '../../_icons/IconRemove'
import IconInfo from '../../_icons/IconInfo'


function CompanyInventory(props) {
  const { t, i18n } = useTranslation('common');

  let token = props.token || {};
  const { settingsActions } = props;

  const companyInventory = props.companyInventory || []

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const user = JSON.parse(window.atob(base64));

  let totalAgents = 0
  let totalMac = 0
  let totalLinux = 0
  let totalWindows = 0


  const data = useMemo(() => companyInventory);


  const columns = React.useMemo(
    () => [
      {
        Header: t('settings.inventoryf.version'),
        accessor: 'agent_version',
      },
      {
        Header: t('settings.inventoryf.os'),
        accessor: 'operating_system',
      },
      {
        Header: t('settings.inventoryf.manufacturer'),
        accessor: 'manufacturer',
      },
      {
        Header: t('settings.inventoryf.device_name'),
        accessor: 'device_name',
      },
      {
        Header: t('settings.inventoryf.last_user'),
        accessor: 'last_user',
      },
      {
        Header: t('settings.inventoryf.last_team'),
        accessor: 'last_group',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (d) => {      
          return (
            <div className="actions">             
              <button className="remove" title={t('settings.administrator.remove')} onClick={() => { toggleRemoveDialog(d.row.values.id) }}><IconRemove /></button>             
              <div className="removeDialog" id={"remove-" + d.row.values.id}>
                <div className="content">
                  <div className="message"><IconInfo />{t('settings.administrator.you_are_sure')}</div>
                  <div className="options">
                    <button onClick={() => { toggleRemoveDialog() }}>{t('no')}</button>
                    <button onClick={() => { disableUser(d.row.values) }}>{t('yes')}</button>
                  </div>
                </div>
              </div>
            </div>
          )


        }
      }
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);


  for(let machine of companyInventory){
    if(machine.agent_version.includes('M')){
      totalMac++
    }else if(machine.agent_version.includes('L')){
      totalLinux++
    }else{
      totalWindows++
    }

    totalAgents++
  }

  function toggleRemoveDialog(id) {
    
    let elems = document.querySelectorAll(".removeDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById("remove-" + id)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  async function disableUser(user) {

    await settingsActions.deleteCompanyInventory(user)
    await settingsActions.getCompanyInventory()
    toggleRemoveDialog()  
    // document.getElementById("remove-" + user?.id).parentNode.parentNode.parentNode.remove()   

  }


  return (
    <React.Fragment>
      <header className="pageHeader daysoff">
        <h3>{t('settings.inventoryf.inventory')}</h3>
        <ul>
          <li>
            <div className="icon">
              <IconAllAgents />
            </div>
            <div className="">{t('settings.inventoryf.all')}:</div>
            <div className="number">{totalAgents}</div>
          </li>
          <li>
            <div className="icon">
              <IconMac />
            </div>
            <div className="">{t('settings.inventoryf.mac')}:</div>
            <div className="number">{totalMac}</div>
          </li>
          <li>
            <div className="icon">
              <IconWindows />
            </div>
            <div className="">{t('settings.inventoryf.windows')}:</div>
            <div className="number">{totalWindows}</div>
          </li>
          {/* <li>
            <div className="icon">
              <IconWindows />
            </div>
            <div className="">{t('settings.inventoryf.linux')}:</div>
            <div className="number">{totalLinux}</div>
          </li> */}
        </ul>
 
      </header>

      <div className="dataTable administrators">
        {data.length > 0 &&
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <div className="arrowDown"></div>
                            : <div className="arrowUp"></div>
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(
                (row, i) => {

                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        )
                      })}
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        }



      </div>
    </React.Fragment>


  )
};



function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    companyInventory: state.settings.companyInventory
  }
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyInventory));