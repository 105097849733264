import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, CircularProgress, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './ModalEditRemoteCheckin.module.scss';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalEditRemoteCheckin({
  lang,
  alertActions,
  userDetails,
  openModalEdit,
  setOpenModalEdit,
  remoteTrack,
  handleCloseModalEdit,
  getData
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);

  const [form, setForm] = useState({
    id: remoteTrack.id,
    status: remoteTrack.status,
    date_ref: remoteTrack.date_ref,
    start: moment(remoteTrack.records[0].datetime).format('HH:mm'),
    time: remoteTrack.status === 'COMPLETED' ? moment(remoteTrack.records[1].datetime).format('HH:mm') : '00:00',
    observation: remoteTrack.observation || ""
  })

  const [formErrors, setFormErrors] = useState({
    time: null
  })

  const handleChange = (e, time = false) => {
    if (time) {
      setForm({
        ...form,
        time: e
      })

      return
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    console.log(form)
  }, [form])

  const validateForm = (form) => {
    const errors = {};

    if (form.time === null || form.time === 'NaN:NaN') {
      errors.time = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      time: '00:00',
      observation: ""
    })
    setFormErrors({
      time: null
    })
  }

  const convertToTime = (valueTime) => {
    const hour = dayjs(valueTime).hour()
    const minute = dayjs(valueTime).minute()
    let hourString = ''
    let minuteString = ''
    if (hour < 10) {
      hourString = '0' + String(hour)
    } else {
      hourString = String(hour)
    }
    if (minute < 10) {
      minuteString = '0' + String(minute)
    } else {
      minuteString = String(minute)
    }
    return hourString + ':' + minuteString
  }


  async function updateRemoteTrack() {
    try {
      setIsSubmitting(true)

      if(form.status === 'COMPLETED'){
        const res = await api.put(
          `/remote_track?org_id=${userDetails.organizationId}`,
          {
            ...form,
            rt_id: form.id,
            mu_id: userDetails.mu_id
          }
        )
      }else{
        const res = await api.patch(
          `/remote_track/finish?org_id=${userDetails.organizationId}`,
          form
        )
      }

      handleCloseModal()
      await getData()
      alertActions.success(t("remote_checkin.success.update_checkin"))
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t("remote_checkin.erros.update_checkin"))
    }
  }

  function handleCloseModal() {
    resetForm()
    handleCloseModalEdit()
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      updateRemoteTrack()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalEdit}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalEdit} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="42px"
          px="26px"
          borderRadius="8px"
          gap="28px"
          width={380}
          component="form"
          noValidate
          autoComplete='off'
        >
          <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
            {t("remote_checkin.dashboard.modal_edit.title")}
          </Typography>

          <TextField
            disabled
            id="outlined-disabled"
            label={t("remote_checkin.dashboard.modal_edit.date")}
            defaultValue={`${befectiveFormatDate(remoteTrack.date_ref, lang, "S") || "----/--/--"}`}
            sx={{ flex: '1', bgcolor: '#F5F6F9' }}
          />

          <Box display="flex" flexDirection="column" gap={2}>

            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  disabled
                  label={t("remote_checkin.dashboard.modal_edit.in")}
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  value={dayjs('2022-01-01 ' + form.start)}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      variant='outlined'
                      {...params}
                      style={{ width: '50%', marginRight: 0, color: "#98ACC1" }}
                      sx={{ fieldset: { border: '2px solid #DEE3E9CC' } }}
                    />
                  )}
                />
                <TimePicker
                  label={t("remote_checkin.dashboard.modal_edit.out")}
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  minTime={dayjs('2022-01-01 ' + form.start)}
                  value={dayjs('2022-01-01 ' + form.time)}
                  onChange={(newValue) => {
                    setForm({
                      ...form,
                      time: convertToTime(newValue)
                    })
                  }}
                  
                  renderInput={(params) => (
                    <TextField

                      variant='outlined'
                      {...params}
                      error={formErrors.time && true}
                      helperText={formErrors.time}
                      style={{ width: '50%', marginRight: 0, color: "#98ACC1" }}
                      sx={{ fieldset: { border: '2px solid #DEE3E9CC' } }}
                    />
                  )}
                />

              </LocalizationProvider>
            </Box>

            <TextField
              name="observation"
              id="outlined-multiline-flexible"
              label={t("remote_checkin.dashboard.modal_edit.observation")}
              multiline
              rows={4}
              maxRows={4}
              value={form.observation}
              onChange={(e) => handleChange(e, null)}
              sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, textarea: { color: "#4F7091" } }}
            />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
            <Button
              style={{
                backgroundColor: '#E3EFFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.cancelBtn}
              onClick={handleCloseModal}
            >
              <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>{t("remote_checkin.dashboard.modal_edit.cancel")}</Typography>
            </Button>
            <Button
              style={{
                backgroundColor: '#187DFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.save}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  component="span"
                  fontSize={12}
                  color='#fff'
                  textTransform="initial"
                  fontWeight={500}
                >
                  {t("remote_checkin.dashboard.modal_edit.save")}
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
