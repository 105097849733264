import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { CardsHeaderVacation } from '../../_components/reports/vacation/CardsHeader';
import TableReportsVacationPagination from '../../_components/reports/vacation/TableReportsVacationPagination';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsVacationPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataVacation, setDataVacation] = useState(null);

  const [orderedColumn, setOrderedColumn] = useState('alias')
  const [order, setOrder] = useState('asc');
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const orderColumn = (column, type) => {

    const orderedData = orderData(dataVacation.data, column, type, orderedColumn, setOrderedColumn, order, setOrder)

    setDataVacation({
      ...dataVacation,
      data: orderedData
    });
  };


  async function getDataVacation(pageIndex, pageSize, isLoading, teams = null, teammates = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/freeDays?page=${pageIndex}&per_page=${pageSize}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataVacation(response.data)
      setPageCount(Math.ceil(parseInt(response.data.countRows) / pageSize))
      setPageCountRows(parseInt(response.data.countRows))
      setPageSize(pageSize)
      setPageIndex(pageIndex)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_vacation.table.col_colab'),
        accessor: 'username'
      },
      {
        Header: t('reports_vacation.table.col_date_admission'),
        accessor: 'admission_date',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value, i18n.language) : '------'
        }
      },
      {
        Header: t('reports_vacation.table.col_total_vacation'),
        accessor: 'total_vacation',
      },
      {
        Header: t('reports_vacation.table.col_total_vacation_completed'),
        accessor: 'total_vacation_completed',
      },
      {
        Header: t('reports_vacation.table.col_total_vacation_expired'),
        accessor: 'total_vacation_expired'
      },
      {
        Header: t('reports_vacation.table.col_days_delay'),
        accessor: 'days_in_delay',
        Cell: ({ value }) => {
          if(Number(value) == 1){
            return `${value} ${ t('reports_vacation.table.day')}`
          }
          return `${value} ${ t('reports_vacation.table.days')}`
        }
      },
      {
        Header: t('reports_vacation.table.col_balance_vacation'),
        accessor: 'total_balance_vocation_days',
        Cell: ({ value }) => {
          if(Number(value) == 1){
            return `${value} ${ t('reports_vacation.table.day')}`
          }
          return `${value} ${ t('reports_vacation.table.days')}`
        }
      },
    ];
  }, []);

  useEffect(() => {

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataVacation(0, localStorage.getItem('pageSizeStorage') || 20, false, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }


  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderVacation alertActions={alertActions} userDetails={userDetails} stats={dataVacation.stats} />
                <TableReportsVacationPagination
                  columns={columns}
                  data={dataVacation.data}
                  count={pageCount}
                  getData={getDataVacation}
                  pageCountRows={pageCountRows}
                  alertActions={alertActions}
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
          }

        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'vacationReports'} paginationFilters={{pageSize: pageSize, pageIndex: pageIndex}}/>
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsVacationPage)