import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'
import { alertActions } from '../../../_actions'
import './styles.scss'
import {
    authHeader
} from '../../../_helpers'
import { api } from '../../../_helpers/api'
import { useState } from 'react'
import { IconButton, Menu, TextField } from '@material-ui/core';
import ReactInputMask from 'react-input-mask'
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

function InputTime({ anchorEl, handleCloseMenu, currentTask, updateGoalsDetails = null, updateTaskDetails = null, userDetails, alertActions }) {
    const { t } = useTranslation('common')

    const token = authHeader()

    const [valueTime, setValueTime] = useState(currentTask?.time)
    const [isUpdatingProgress, setIsUpdatingProgress] = useState(null)

    const mask = '12:34';


    const formatChars = {
        '1': '[0-2]',
        '2': '[0-9]',
        '3': '[0-5]',
        '4': '[0-9]'
    };

    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        let { value } = newState;

        if (value.startsWith('2'))
            formatChars['2'] = '[0-3]'; // To block 24, 25, etc.
        else
            formatChars['2'] = '[0-9]'; // To allow 05, 12, etc.
        return { value, selection: newState.selection };
    }

    const handleChangeTime = (e) => {
        setValueTime(e.target.value)
    }

    const updateTimeTask = async () => {
        try {
            const payload = {
                time: valueTime
            };

            setIsUpdatingProgress(true)

            const result = await api.patch(`goals/key-results/tasks/${currentTask?.id}/?organization_id=${userDetails?.organizationId}`, payload, {
                headers: {
                    'Authorization': token.Authorization
                }
            });

            setIsUpdatingProgress(false)
            if (updateGoalsDetails)
                updateGoalsDetails(true);
            if (updateTaskDetails)
                updateTaskDetails(currentTask?.id)
            handleCloseMenu()
            //   setCurrentTaskOnTable(result.data?.task)

        } catch (error) {
            setIsUpdatingProgress(false)
            //alertActions.error(error)
        }
    }

    const handleUpdateKeyEnter = (e) => {
        if (e.key === 'Enter') {
            updateTimeTask()
        }
    }

    return (
        <Menu
            className="menu-task-on-table-to-time"
            id={`menu-options-time`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ horizontal: "center" }}
            style={{ padding: 10 }}
        >
            <div className={`box-input-time ${isUpdatingProgress && 'disabled-div'}`}>
                <ReactInputMask
                    mask={mask}
                    value={valueTime}
                    disabled={false}
                    formatChars={formatChars}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    onChange={(value) => handleChangeTime(value)}
                    placeholder={'hh:mm'}
                    onKeyDown={(e) => { handleUpdateKeyEnter(e) }}
                >
                    {(inputProps) => <TextField {...inputProps}
                        disabled={false}
                        name="valueTime"
                        type="text"
                        variant='outlined'
                    />}
                </ReactInputMask>
                <IconButton aria-label="save" size="small" onClick={updateTimeTask} >
                    <DoneIcon />
                </IconButton>
                <IconButton aria-label="close" size="small" onClick={handleCloseMenu}>
                    <CloseIcon />
                </IconButton>
            </div>
        </Menu>
    )
}

function mapStateToProps(state) {

    return {
        userDetails: state.authentication.userDetails
    }

}


function mapDispatchToProps(dispatch) {

    return {
        alertActions: bindActionCreators(alertActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(InputTime)



