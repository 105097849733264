import React from 'react';

const IconSoftware = props => {
    return (
        
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
       
            <g id="icons_Q2" data-name="icons Q2" transform="translate(1.333 1)">
            <path id="Caminho_47" data-name="Caminho 47" d="M16.966,6.067l-6-3L10.666,3l-.3.067-6,3a.633.633,0,0,0-.367.6v6.6a.633.633,0,0,0,.367.6l6,3.067.3.067.3-.067,6-3.067a.633.633,0,0,0,.367-.6v-6.6A.633.633,0,0,0,16.966,6.067ZM10.666,4.4l4.867,2.433L10.666,9.3,5.8,6.833ZM5.332,8.1,10,10.467v4.767L5.332,12.867Zm6,7.133V10.467L16,8.1v4.767Z" transform="translate(-3.998 -3)" fill="#187dff"/>
            </g>
        </svg>
        

    )
}

export default IconSoftware;