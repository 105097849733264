import { Box, Chip, Divider, FormControlLabel, Grid, List, ListItem, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import Filterbar from '../../../_components/_shared/Filterbar'
import Toolbar from '../../../_components/_shared/Toolbar'
import { authHeader } from '../../../_helpers'
import { api } from '../../../_helpers/api'

import './styles.scss'

import { WeekDays } from '../../../_components/checkin_page/WeekDays'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { alertActions, teammatesActions, usersActions } from '../../../_actions'
import { WeekDayFull } from '../../../_components/checkin_page/WeekDayFull'


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22BEAD' : '#22BEAD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function TeamSettings(props) {
  const { t, i18n } = useTranslation('common');


  const [showDrower, setShowDrower] = useState(false)
  const [workingHours,setWorkingHours] = useState([])
  const [team,setTeam] = useState({})

  const { team_id } = useParams();
  
  const { userDetails, alertActions,  } = props
  
  const getWorkingHours = async () => {
    try {
      
      const response = await api.get(`/checkin/working-hours?group_id=${team_id}`, 
      { headers: authHeader() });
      
      if(response.status === 200){
        setWorkingHours(response.data)
      }
    } catch (e) {
      console.log(e)
      
    }
  }

  const getTeamSettingByID = async () => {
    try {
      
      const response = await api.get(`/checkin/settings/groups/${team_id}`, 
      { headers: authHeader() });

      if(response.status === 200){        
        setTeam(response.data)
      }
    } catch (e) {
      console.log("/Error",e)
      
    }
  }
  const handleDayWeek = async (day_week) => {
    try {
      
      const response = await api.post(`/checkin/working-hours`,
      {
        group_id:team_id, 
        day_week:day_week

      }, 
      { headers: authHeader() });
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  }
  const createWorkingHourPeriod = async (working_hour_id) => {
    try {
      
      const response = await api.post(`/checkin/working-hours/periods`,
      {
        working_hour_id:working_hour_id

      }, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 

  const updateWorkingHourPeriod = async (day_week_id,period_id,start,end,ref) => {
    try {
      // day_week_id,period_id,start,new_value
      const response = await api.put(`/checkin/working-hours/periods`,
      {
        day_week_id:day_week_id,
        period_id:period_id,
        start:start,
        end:end,
        ref:ref
      }, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 
  const handleCopyToAll = async (group_id) => {
    
    try {      
      const response = await api.patch(`/checkin/working-hours/copy-to-all`, 
      {
        group_id:group_id
      },
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  }
  const handleDeleteWorkingPeriod = async (id) => {
    try {
      
      const response = await api.delete(`/checkin/working-hours/periods/${id}`, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 

  useEffect(() => {    
    
    getTeamSettingByID()
    document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.check_in_remote');
  }, [])
  useEffect(() => { 
     
    console.log("/ repit")
  }, [showDrower])
  // showDrower
  return (
    <section className="mainContent">
      <Toolbar />
      
      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhenMonths={'disabled'}
        filterWhat={'disabled'}
        routeBackToPage={'/settings/virtual-point/teams'}
        showShareBtn={false}
      />
      <Paper elevation={0} sx={{ marginX: 4, marginY: 3, padding: 3 }}>
        {
          <Grid
            container
            spacing={2}
            component={'form'}
            id='TeamSettings'
            // onSubmit={handleSubmit}
          >
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography component={'h6'} variant='h6'>{team?.name || "no name"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/**  
             * Calendario de horario, period por semana.
             * **/}            
            <WeekDayFull 
              language={i18n.language}
              team_id={team_id}
            />

          </Grid>
        }
      </Paper>
    </section>
    
  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings)