import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import Moment from 'moment';
import IconAdd from '../_components/_icons/IconAdd'

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { alertActions } from '../_actions';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import ScheduledReportsSkeleton from '../_components/scheduled_reports/ScheduledReportsSkeleton';
import ScheduledReports from '../_components/scheduled_reports/ScheduledReports';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';


function ScheduledReportsPage(props) {

    const { t, i18n } = useTranslation('common');
    const { alertActions } = props;
    const history = useHistory();

    const [scheduledData, setScheduledData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const getScheduledData = async () => {
        try {
            setIsLoading(true)
            const { data } = await api.get('/scheduled_reports', {headers: authHeader()})
            setScheduledData(data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            //alertActions.error(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getScheduledData()
    }, [])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.periodic-reports');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar />
                <div className="mainFilterbar" >
                    <div className="filters">
                        <NavLink to="periodic">
                            <button className={'settingsNav'}>{t('periodic.tab_periodic')}</button>
                        </NavLink>
                        <NavLink to="scheduled-reports" activeClassName="active">
                            <button className={'settingsNav'}>{t('periodic.tab_schedules')}</button>
                        </NavLink>
                    </div>
                    <button type={'button'} className={'add'} style={{ width: '140px', justifyContent: 'center' }} onClick={() => {
                        history.push("/productive/reports/scheduled-reports/create")
                    }}>
                        <IconAdd />
                        {t('periodic.add')}
                    </button>
                </div>
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            isLoading
                                ? <ScheduledReportsSkeleton />
                                : <ScheduledReports scheduledData={scheduledData} updateInfo={getScheduledData}/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        alertActions: bindActionCreators(alertActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsPage)
