import config from 'config'
import {authHeader} from '../_helpers'

export const settingsService = {
    getCompanyInformation,
    putCompanyInformation,
    putStatusCompanyPeriodicReports,
    getCompanyAdministrators,
    getCompanyAdministrator,
    postCompanyInviteAdministrator,
    putCompanyInviteAdministrator,
    deleteCompanyAdministrator,
    getCompanyDaysoff,
    getCompanyDaysoffSpecific,
    getCompanyInventory,
    deleteCompanyInventory,
    deleteCompanyPeriodicReport,
    getCompanyReportPeriodic,
    postCompanyDaysoff,
    deleteCompanyDaysoff
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE)
}

function getCompanyInformation() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/settings/company/information`, requestOptions).then(handleResponse)
}

function putCompanyInformation(form) {

    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({ 
            Name: form.name,
            Website: form.website,
            Phone: form.phone,
            TargetPercent: form.target,
            Purpose: form.purpose,
            ShowGoals: form.show_goals,
            OriginTrackedsFiles: form.originTrackedsFiles,
            CurrencyCode: form.currency
        })
    }

    return fetch(`${config.apiNodeUrl}/settings/company/information`, requestOptions).then(handleResponse)
}

function putStatusCompanyPeriodicReports(id, form) {

    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({ active: form.active })
    }

    return fetch(`${config.apiNodeUrl}/v2/periodic-reports/${id}/update-status`, requestOptions).then(handleResponse)
}

function getCompanyAdministrators() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/settings/company/administrators`, requestOptions).then(handleResponse)
}

function getCompanyAdministrator(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/settings/company/administrators/${user_id}`, requestOptions).then(handleResponse)
}

function postCompanyDaysoff(form, id, type) {

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            DateStart: form.date_start,
            DateEnd: form.date_end,
            Reason: form.reason,
            Target: id,
            Type: type
        })
    }

    return fetch(`${config.apiNodeUrl}/settings/company/daysoff`, requestOptions).then(handleResponse)
}

function postCompanyInviteAdministrator(form, teams, type) {

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            Name: form.name,
            Email: form.email,
            Role: form.role,
            EnableActivities: form.enable_activities,
            EnableFreedays: form.enable_freedays,
            Teams: teams
        })
    }

    return fetch(`${config.apiNodeUrl}/settings/company/administrators/invite`, requestOptions).then(handleResponse)
}

function putCompanyInviteAdministrator(form, teams, user_id) {

    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            Name: form.name,
            Email: form.email,
            Role: form.role,
            EnableActivities: form.enable_activities,
            EnableFreedays: form.enable_freedays,
            Teams: teams
        })
    }

    return fetch(`${config.apiNodeUrl}/settings/company/administrators/${user_id}`, requestOptions).then(handleResponse)
}

function deleteCompanyAdministrator(user_id) {

    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}}
    }

    return fetch(`${config.apiNodeUrl}/settings/company/administrators/${user_id}`, requestOptions).then(handleResponse)
}

function getCompanyDaysoff() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/settings/company/daysoff`, requestOptions).then(handleResponse)
}

function getCompanyDaysoffSpecific(id, type) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    if (id) {
        return fetch(`${config.apiNodeUrl}/settings/company/daysoff/${type.toLowerCase()}/${id}`, requestOptions).then(handleResponse)
    } else {
        return fetch(`${config.apiNodeUrl}/settings/company/daysoff/${type.toLowerCase()}/`, requestOptions).then(handleResponse)
    }

}

function deleteCompanyDaysoff(id) {

    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}}
    }

    return fetch(`${config.apiNodeUrl}/settings/company/daysoff/${id}`, requestOptions).then(handleResponse)
}

function deleteCompanyPeriodicReport(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}}
    }
    return fetch(`${config.apiNodeUrl}/v2/periodic-reports/${id}`, requestOptions).then(handleResponse)
}

function getCompanyInventory() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/settings/company/inventory`, requestOptions).then(handleResponse)
}

function getCompanyReportPeriodic() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/v2/periodic-reports`, requestOptions).then(handleResponse)
}

function deleteCompanyInventory(user) {
    
    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify(user)
    }

    return fetch(`${config.apiNodeUrl}/settings/company/inventory`, requestOptions).then(handleResponse)
}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text)

        if (!response.ok) {
            if (response.status === 401) {
                logout()
                window.location.reload(true)
            }

            const error = (data && data.message) || response.statusText
            return Promise.reject([error, response.status])
        }

        return data
    })
}

