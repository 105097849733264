import React from 'react';

const IconSad = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Icon_awesome-frown" data-name="Icon awesome-frown" d="M12,.563a12,12,0,1,0,12,12A12,12,0,0,0,12,.563Zm3.871,8.129a1.548,1.548,0,1,1-1.548,1.548A1.547,1.547,0,0,1,15.871,8.692Zm-7.742,0A1.548,1.548,0,1,1,6.581,10.24,1.547,1.547,0,0,1,8.129,8.692ZM16.365,19.25a5.685,5.685,0,0,0-8.729,0,.775.775,0,0,1-1.19-.992,7.227,7.227,0,0,1,11.1,0A.771.771,0,0,1,16.365,19.25Z" transform="translate(0 -0.563)" fill="#ff8b8b"/>
        </svg>
    )
}

export default IconSad;