import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

export function ProgressRuler({ data }) {
  const [points, setPoints] = useState([])

  const CurrentTime = moment().format('HH:mm')
  const CurrentTimeInitialTranslateX = 67
  const CurrentTimeTranslateX = 52

  function calcGapByMinutes(hour) {
    var time = hour.split(':');
    var minutes = parseInt(time[1]);

    var gapByMin = 52 / 70;

    var gap = gapByMin * minutes;

    return gap;
  }


  function convertMinutesToHours(minutes) {
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();
    const formattedHours = moment({ hour: hours, minute: remainingMinutes }).format('HH:mm');
    return formattedHours;
  }

  function calcDurationInMinutes(start, end) {
    const startHour = moment(start, "HH:mm");
    const endHour = moment(end, "HH:mm");
    const diffInMiliSeconds = endHour.diff(startHour);
    const durationInMinutes = moment.duration(diffInMiliSeconds).asMinutes();
    return durationInMinutes;
  }


  useEffect(() => {
    const points = []

    if (data) {
      let index = 0

      while (index < data.points.length) {
        const p1 = data.points[index]
        const p2 = data.points[index + 1] || {}
        if((index+1) >= data.points.length){          
          points.push({ in: p1.time, out: null })
          index += 2
          continue;
        }        
        points.push({ in: p1.time, out: p2.time })
        index += 2
        
      }

    }

    setPoints(points)
  }, [data])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="85" viewBox="0 0 1248 30">
      <defs>
        <clipPath id="clip-path">
          <rect width="1248" height="30" fill="none" />
        </clipPath>
        <linearGradient
          id="linearGradient-1"
          x1="0%"
          x2="100%"
        >
          {/* Set gradient color */}
          <stop offset="0%" stopColor="#D6F4F1"></stop>
          <stop offset="100%" stopColor="#9CECE4"></stop>
        </linearGradient>
      </defs>
      <rect
        width="100%" height="16"
        rx={8}
        ry={8}
        fill="#f5f6f9"
      />
      
      {points.length > 0 && points.map((item, index) =>
        <Fragment key={index}>
          {item.in.split(':')[0] === "00" ? (
            <g transform={`translate(16 0)`}>
              {/* <text
                x="5px"
                y="6"
                fill="#187DFF"
                style={{ fontSize: 14, fontWeight: 400 }}
              >
                {item.in}
              </text> */}
            </g>
          ) : (
            item.out !== null ? (
              <g
                transform={
                  `translate(${(CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in)) + 16} 0)`
                }
              >
                <rect
                  width={6 + (CurrentTimeInitialTranslateX + (Number(item.out.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.out)) - (CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in))} height="16"
                  rx={8}
                  ry={8}
                  fill="url(#linearGradient-1)"
                />
                <text
                  y="30"
                  fill="#22BEAD"
                  style={{ fontSize: 10, fontWeight: 600 }}
                  transform={
                    `translate(${-35 + (CurrentTimeInitialTranslateX + (Number(item.out.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.out)) - (CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in))} -18)`
                  }
                >
                  {convertMinutesToHours(Number(calcDurationInMinutes(item.in, item.out)))}
                </text>
              </g>
            ) : (
              <g
                transform={
                  `translate(${(CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in)) + 16} 0)`
                }
              >
                <rect
                  width={6 + (CurrentTimeInitialTranslateX + (Number(CurrentTime.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(CurrentTime)) - (CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in))} height="16"
                  rx={8}
                  ry={8}
                  fill="url(#linearGradient-1)"
                />
                <text
                  y="30"
                  fill="#22BEAD"
                  style={{ fontSize: 10, fontWeight: 600 }}
                  transform={
                    `translate(${-35 + (CurrentTimeInitialTranslateX + (Number(CurrentTime.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(CurrentTime)) - (CurrentTimeInitialTranslateX + (Number(item.in.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(item.in))} -18)`
                  }
                >
                  {convertMinutesToHours(Number(calcDurationInMinutes(item.in, CurrentTime)))}
                </text>
              </g>
            )
          )}
        </Fragment>
      )}

      {data && data.points.map((point, index) => (
          <g
            key={index}
            transform={
              `translate(${(CurrentTimeInitialTranslateX + (Number(point.time.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(point.time))} -4)`
            }
          >
            {point.type === 'IN' ? (
              <>
                <circle cx="22" cy="12" r="3" fill="#22BEAD"></circle>
                <text
                  x="4"
                  y="0"
                  fill="#22BEAD"
                  style={{ fontSize: 10, fontWeight: 600 }}
                >
                  {"check-in"}
                </text>

              </>

            ) : (
              <>
                <circle cx="16" cy="12" r="3" fill="#22BEAD"></circle>
                <text
                  x="-2"
                  y="30"
                  fill="#22BEAD"
                  style={{ fontSize: 10, fontWeight: 600 }}
                >
                  {"check-out"}
                </text>
              </>
            )}

          </g>
        ))
      }
    </svg>
  )
}
