export const backfectiveConstants = {   
    GET_BACKFECTIVE_INVITATIONS_REQUEST: '[BACKFECTIVE] GET_BACKFECTIVE_INVITATIONS_REQUEST',
    GET_BACKFECTIVE_INVITATIONS_SUCCESS: '[BACKFECTIVE] GET_BACKFECTIVE_INVITATIONS_SUCCESS',
    GET_BACKFECTIVE_INVITATIONS_FAILURE: '[BACKFECTIVE] GET_BACKFECTIVE_INVITATIONS_FAILURE',
    GET_BACKFECTIVE_ORGANIZATIONS_REQUEST: '[BACKFECTIVE] GET_BACKFECTIVE_ORGANIZATIONS_REQUEST',
    GET_BACKFECTIVE_ORGANIZATIONS_SUCCESS: '[BACKFECTIVE] GET_BACKFECTIVE_ORGANIZATIONS_SUCCESS',
    GET_BACKFECTIVE_ORGANIZATIONS_FAILURE: '[BACKFECTIVE] GET_BACKFECTIVE_ORGANIZATIONS_FAILURE',
    POST_BACKFECTIVE_MAGIC_AGENT_REQUEST: '[BACKFECTIVE] POST_BACKFECTIVE_MAGIC_AGENT_REQUEST',
    POST_BACKFECTIVE_MAGIC_AGENT_SUCCESS: '[BACKFECTIVE] POST_BACKFECTIVE_MAGIC_AGENT_SUCCESS',
    POST_BACKFECTIVE_MAGIC_AGENT_FAILURE: '[BACKFECTIVE] POST_BACKFECTIVE_MAGIC_AGENT_FAILURE'
};