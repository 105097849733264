import React from 'react';

const IconList = props => {
  return (
    <svg id="lista" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1197" data-name="Retângulo 1197" width="20" height="20" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(2.5 4.167)">
        <path id="Caminho_124" data-name="Caminho 124" d="M6,15.833H6A.833.833,0,0,1,6.833,15h.833a.833.833,0,0,1,.833.833h0a.833.833,0,0,1-.833.833H6.833A.833.833,0,0,1,6,15.833Zm.833-4.167h.833a.833.833,0,0,0,.833-.833h0A.833.833,0,0,0,7.667,10H6.833A.833.833,0,0,0,6,10.833H6A.833.833,0,0,0,6.833,11.667Zm0,10h.833a.833.833,0,0,0,.833-.833h0A.833.833,0,0,0,7.667,20H6.833A.833.833,0,0,0,6,20.833H6A.833.833,0,0,0,6.833,21.667Zm4.583-5H19.75a.833.833,0,0,0,.833-.833h0A.833.833,0,0,0,19.75,15H11.417a.833.833,0,0,0-.833.833h0A.833.833,0,0,0,11.417,16.667Zm0-5H19.75a.833.833,0,0,0,.833-.833h0A.833.833,0,0,0,19.75,10H11.417a.833.833,0,0,0-.833.833h0A.833.833,0,0,0,11.417,11.667Zm0,10H19.75a.833.833,0,0,0,.833-.833h0A.833.833,0,0,0,19.75,20H11.417a.833.833,0,0,0-.833.833h0A.833.833,0,0,0,11.417,21.667Z" transform="translate(-6 -10)" fill="#98acc1" />
      </g>
    </svg>

  )
}

export default IconList;