import React, { useRef, useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import Moment from 'moment';
import { useTable, useSortBy, useRowSelect } from 'react-table'


import { filteredDataEfficiencies, secondsToHm } from '../../_helpers';

import IconSoftware from "../_icons/IconSoftware";
import IconSite from "../_icons/IconSite";
import IconOutdoor from "../_icons/IconOutdoor";
import IconArrow from "../_icons/IconArrow";

function DrawerHistorical(props) {
    const { t, i18n } = useTranslation('common');
    const { userDetails } = props
    let historical = props.historical || [];
    let current = props.current || {};
    let filter = props.filter;

    Moment.locale(t('date.locale'));

    const [DisplayTotalWorkedTime, setDisplayTotalWorkedTime] = useState(0);
    const [DisplayTotalWorkedTimeOnline, setDisplayTotalWorkedTimeOnline] = useState(0);
    const [DisplayTotalWorkedTimeOutdoor, setDisplayTotalWorkedTimeOutdoor] = useState(0);
    const [DisplayTotalProductiveTime, setDisplayTotalProductiveTime] = useState(0);
    const [DisplayTotalNeutralTime, setDisplayTotalNeutralTime] = useState(0);
    const [DisplayTotalUnproductiveTime, setDisplayTotalUnproductiveTime] = useState(0);
    const [DisplayHourListHTML, setDisplayHourListHTML] = useState([]);


    const dataTeammates = useMemo(() => filteredDataEfficiencies(historical.hourActivities.hours, userDetails), []);

    const columnsTeammates = React.useMemo(
        () => [
            {
                Header: t('historical.drawer.hours'),
                accessor: 'datetime_start',
                Cell: (d) => {

                    return Moment.utc(d.row.original.datetime_start).format('HH:mm:ss') + " - " + Moment.utc(d.row.original.datetime_end).format('HH:mm:ss');

                }
            },
            {
                Header: t('historical.drawer.duration'),
                accessor: 'duration',
                Cell: (d) => {

                    return secondsToHm(d.value, true)
                }
            },
            {
                Header: t('historical.drawer.activity'),
                accessor: 'exhibition_name',
                Cell: (d) => {

                    let iconType = '';

                    if (d.row.original.trackedType == "Software") {
                        iconType = <IconSoftware />
                    }
                    if (d.row.original.trackedType == "Site") {
                        iconType = <IconSite />
                    }
                    if (d.row.original.trackedType == "Outdoor") {
                        iconType = <IconOutdoor />
                    }

                    let current = d.row.original;

                    return <div className="activity noClick">
                        <div className="icon">{iconType}</div>
                        <div className={"dot " + d.row.original.efficiencyName}></div>
                        {d.row.original.exhibitionName}</div>
                }
            }

        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: columnsTeammates,
            data: dataTeammates
        }
    )

    function changeHour(hour) {
        props.changeDrawer({
            "hour": hour,
            "MachineUserName": current.MachineUserName
        });
    }

    function buildHourList() {

        let hourListHTML = [];
        let currentHour = parseInt(Moment(current.hour).format('HH'));

        if (currentHour > 0) {
            hourListHTML.push(<li className={"button back"} onClick={() => {
                changeHour(Moment(current.hour).add(-1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                <IconArrow />
            </li>)
        }

        if (currentHour >= 2) {
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(-2, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(-2, 'hours').format('HH:mm')}
            </li>)
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(-1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(-1, 'hours').format('HH:mm')}
            </li>)
        } else if (currentHour == 1 && currentHour < 2) {
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(-1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(-1, 'hours').format('HH:mm')}
            </li>)
        }

        hourListHTML.push(<li className={"active"}>
            {Moment(current.hour).format('HH:mm')}
        </li>)

        if (currentHour <= 21) {
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(+1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(+1, 'hours').format('HH:mm')}
            </li>)
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(+2, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(+2, 'hours').format('HH:mm')}
            </li>)
        } else if (currentHour == 22) {
            hourListHTML.push(<li onClick={() => {
                changeHour(Moment(current.hour).add(+1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                {Moment(current.hour).add(+1, 'hours').format('HH:mm')}
            </li>)
        }

        if (currentHour < 23) {
            hourListHTML.push(<li className={"button next"} onClick={() => {
                changeHour(Moment(current.hour).add(+1, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }}>
                <IconArrow />
            </li>)
        }

        setDisplayHourListHTML(hourListHTML);

    }

    useEffect(() => {


        if (props.historical?.hourActivities) {
            buildHourList();
            setDisplayTotalWorkedTime(props.historical?.hourActivities.TotalWorkedTime);
            setDisplayTotalWorkedTimeOnline(props.historical?.hourActivities.TotalWorkedTime - props.historical?.hourActivities.TotalWorkedTimeOutdoor);
            setDisplayTotalWorkedTimeOutdoor(props.historical?.hourActivities.TotalWorkedTimeOutdoor);
            setDisplayTotalProductiveTime(props.historical?.hourActivities.TotalProductiveTime);
            setDisplayTotalNeutralTime(props.historical?.hourActivities.TotalNeutralTime);
            setDisplayTotalUnproductiveTime(props.historical?.hourActivities.TotalUnproductiveTime);
        }


    }, [props.historical.loading])

    return (
        <React.Fragment>
            <div className="internal">
                <div className="header">
                    <div className={"title historical"}>{current.MachineUserName}</div>
                </div>
                <div className="day">{Moment(current.hour).format(t('date.mm/dd/yyyy'))}</div>
                <div className="hourChange">{DisplayHourListHTML}</div>


                <div className="dataTable specific historical">
                    <ul className={"stats"}>
                        <li>
                            {t('historical.drawer.total')}: <span>{secondsToHm(DisplayTotalWorkedTime, true)}</span>
                        </li>
                        <li>
                            {t('historical.drawer.on')}: <span>{secondsToHm(DisplayTotalWorkedTimeOnline, true)}</span>
                        </li>
                        <li>
                            {t('historical.drawer.off')}: <span>{secondsToHm(DisplayTotalWorkedTimeOutdoor, true)}</span>
                        </li>
                        {
                            userDetails.allowed_productive.includes('related') && (
                                <li>
                                    {t('historical.drawer.related')}: <span
                                        className={"related"}>{secondsToHm(DisplayTotalProductiveTime, true)}</span>
                                </li>
                            )
                        }

                        {
                            userDetails.allowed_productive.includes('neutral') && (
                                <li>
                                    {t('historical.drawer.neutral')}: <span
                                        className={"neutral"}>{secondsToHm(DisplayTotalNeutralTime, true)}</span>
                                </li>
                            )
                        }

                        {
                            userDetails.allowed_productive.includes('not_related') && (
                                <li>
                                    {t('historical.drawer.unrelated')}: <span
                                        className={"unrelated"}>{secondsToHm(DisplayTotalUnproductiveTime, true)}</span>
                                </li>
                            )
                        }
                    </ul>

                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {
                                    prepareRow(row);

                                    return (
                                        <React.Fragment>

                                            <tr className="mainRow">

                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}

                                            </tr>


                                        </React.Fragment>

                                    )
                                }
                            )}
                        </tbody>
                    </table>


                </div>


            </div>


        </React.Fragment>

    )
};

function mapStateToProps(state) {

    return {
        historical: state.historical,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps)(DrawerHistorical)
