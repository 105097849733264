import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, Clock, ClockClockwise, ClockCounterClockwise, Cpu, Disc, HourglassLow, Scales, StackSimple, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './CardsHeader.module.scss';

export function CardsHeaderColabs({ alertActions, userDetails, resume }) {

  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187DFF` }}>
          <Cpu color='#187DFF' size={18} weight="bold" />
          <Typography color={"#187DFF"} component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_cpu_usage).toFixed(2)) + "%"}
          </Typography>
          <Typography color={"#187DFF"} component="span" fontSize={12}>
            {t('inventory.colabs.header.cpu_usage')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <StackSimple color='#4F7091' size={18} weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_ram_current_usage / 1024).toFixed(2)) + " GB"}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('inventory.colabs.header.ram_usage')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <StackSimple color='#4F7091' size={18} weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_ram_total / 1024).toFixed(2)) + " GB"}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('inventory.colabs.header.ram_total')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <Disc color='#187DFF' size={18} weight="bold" />

          <Typography color="#187DFF" component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_disk_total / 1024).toFixed(2)) + " GB"}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('inventory.colabs.header.disk_total')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>

          <Disc color='#FC6662' size={18} weight="bold" />

          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_disk_used_in_mb / 1024).toFixed(2)) + " GB"}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('inventory.colabs.header.disk_usage')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <Disc color='#22BEAD' size={18} weight="bold" />

          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {String(Number(resume?.avg_disk_free / 1024).toFixed(2)) + " GB"}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('inventory.colabs.header.disk_free')}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
