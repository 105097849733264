import React, { useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux'
import Moment from 'moment-timezone';
import { useTable, useSortBy } from 'react-table'
import { useHistory } from "react-router-dom";

import IconTeammate from '../_icons/IconTeammate';
import IconTeam from '../_icons/IconTeam';
import IconInfo from '../_icons/IconInfo';
import IconCalendar from '../_icons/IconCalendar2';
import IconOrganization from '../_icons/IconOrganization2';
import IconRemove2 from '../_icons/IconRemove2';
import IconEdit from '../_icons/IconEdit';

import { periodicActions } from '../../_actions';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import { CalendarBlank, CalendarCheck, CalendarX } from 'phosphor-react';


function ScheduledReports({
    scheduledData,
    updateInfo
}) {
    console.log(scheduledData)
    const { t, i18n } = useTranslation('common');

    const history = useHistory();

    Moment.locale(t('date.locale'));

    const data = useMemo(() => scheduledData.schedules, []);

    const columns = React.useMemo(
        () => [
            {
                Header: t('scheduled.col_report_name'),
                Cell: (d) => {
                    return t(`periodic.${d.row.original.report_name}`)
                }
            },
            {
                Header: t('scheduled.col_date_scheduled'),
                Cell: (d) => {
                    return befectiveFormatDate(d.row.original.scheduled_date, i18n.language)
                }
            },
            {
                Header: t('scheduled.col_filter_date'),
                Cell: (d) => {
                    return befectiveFormatDate(d.row.original.start_date, i18n.language) + ' - ' + befectiveFormatDate(d.row.original.end_date, i18n.language)
                }
            },
            {
                Header: t('scheduled.col_status'),
                Cell: (d) => {
                    let processed = 'Não processado';

                    if (d.row.original.processed) {
                        processed = 'Processado'
                    }

                    return processed
                }
            },
            {
                Header: '  ',
                Cell: (d) => {
                    return <div className={'actions'}>
                        <button type={'button'} className={'edit'} onClick={(e) => {
                            editScheduled(e, d.row.original.id)
                        }}>
                            <IconEdit />
                        </button>
                        <button type={'button'} className={'remove'} onClick={() => {
                            toggleRemoveDialog(d.row.original.id)
                        }}>
                            <IconRemove2 />
                        </button>
                        <div className="removeDialog" id={"remove-" + d.row.original.id}>
                            <div className="content">
                                <div className="message"><IconInfo />{t('settings.administrator.you_are_sure')}</div>
                                <div className="options">
                                    <button onClick={() => {
                                        toggleRemoveDialog()
                                    }}>{t('no')}</button>
                                    <button onClick={() => {
                                        deleteSchedule(d.row.original.id)
                                    }}>{t('yes')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            }

        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        disableMultiSort: true,

    }, useSortBy);

    function toggleRemoveDialog(id) {

        let elems = document.querySelectorAll(".removeDialog.open")

        if (elems.length > 0) {
            [].forEach.call(elems, function (el) {
                el.classList.remove("open");
            });
        }

        let elem = document.getElementById("remove-" + id)

        if (elem) {
            elem.classList.toggle("open")
        }

    }

    async function deleteSchedule(id) {
        try {
            const res = await api.delete(`/scheduled_reports/${id}`, { headers: authHeader() })
            toggleRemoveDialog(id)
            document.getElementById("schedule-" + id).remove()
            await updateInfo()
        } catch (error) {
            console.log(error)
        }
    }

    function editScheduled(e, id) {
        if (e.target.role == 'cell' || e.target.classList.contains('edit')) {
            history.push(`/productive/reports/scheduled-reports/edit/${id}`)
        }

    }

    return (
        <div className="box teammates noBg">
            <div className="pageHeader compact realtime">
                <div className="internalFilters">
                    <div className="filter active">
                        <CalendarBlank size={18} color="#187DFF" weight="bold" />
                        <div className={"highlight"} style={{ color: '#187DFF' }}>{scheduledData.stats.total_schedules}</div>
                        <div className="name" style={{ color: '#187DFF' }}>{t('scheduled.stats_total')}</div>
                    </div>

                    <div className="filter active">
                        <CalendarCheck size={18} color="#22BEAD" weight="bold" />
                        <div className={"highlight"} style={{ color: '#22BEAD' }}>{scheduledData.stats.total_schedules_processed}</div>
                        <div className="name" style={{ color: '#22BEAD' }}>{t('scheduled.stats_total_processed')}</div>
                    </div>

                    <div className="filter active">
                        <CalendarX size={18} color="#FC6662" weight="bold" />
                        <div className={"highlight"} style={{ color: '#FC6662' }}>{scheduledData.stats.total_schedules_unprocessed}</div>
                        <div className="name" style={{ color: '#FC6662' }}>{t('scheduled.stats_total_not_processed')}</div>
                    </div>

                </div>


            </div>
            <div className="dataTable schedules">
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <div className="arrowDown"></div>
                                                        : <div className="arrowUp"></div>
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {

                                    prepareRow(row);

                                    return (
                                        <tr id={'schedule-' + row.original.id} {...row.getRowProps()} className={'editable'}
                                            onClick={(e) => {
                                                editScheduled(e, row.original.id)
                                            }}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                }
                {data.length == 0 &&
                    <p className={'noData'}>{t('no_data')}</p>
                }
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        periodicActions: bindActionCreators(periodicActions, dispatch)
    }
}

function mapStateToProps(state) {

    return {
        periodicReports: state.periodic.reports,
        periodic: state.periodic,
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReports)
