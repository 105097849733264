import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import React from 'react'
import styles from './CardInfoUser.module.scss'
import { Laptop } from 'phosphor-react'
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from '../../../_helpers';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function CardInfoUser({
  cardInfo
}) {
  
  const { t, i18n } = useTranslation('common');

  return (
    <Grid item xs={4} sm={4} lg={4} xl={3}>
      <Card className={styles.card} variant="outlined">
        <CardContent className={styles.cardContent}>
          <Laptop size={32} color={cardInfo.status === true ? "#22BEAD" : "#FF5151"} weight="fill" />         
            {
              cardInfo.username.length > 25
              ?
              <BefectiveTooltip
                title={<Typography component={'span'} variant='caption'>{cardInfo.username}</Typography>}
                placement="right-start"
                arrow
                interactive>
                 <Typography variant="h6" className={styles.cardTitle}>{String(cardInfo.username).slice(0, 25)+"..."}</Typography>
              </BefectiveTooltip>
              :
              <Typography variant="h6" className={styles.cardTitle}>{cardInfo.username}</Typography>
            }
          <p className={styles.cardSubtitle}>
            {
              cardInfo.status === true
              ?
              `${t("productive_reports.collection_monitoring.last_collection")}: ${befectiveFormatDate(cardInfo.last_tracked,i18n.language, 'T')}`
              :
              `${t("productive_reports.collection_monitoring.not_collection")}`
            }
          </p>
        </CardContent>
      </Card>
    </Grid>
  )
}
