import React from 'react';
import { CheckCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import IconBalon from '../../../_icons/IconBalon';

const statusColors = [
  {
    backgroundColor: '#E4EAEF',
    color: '#98ACC1',
  },
  {
    backgroundColor: '#FFF0DD',
    color: '#FDA02B',
  },
  {
    backgroundColor: '#DBF5F2',
    color: '#22BEAD',
  },
  {
    backgroundColor: '#FFEAED',
    color: '#FF5151',
  }
]

const priority = ['high', 'medium', 'low']

export function KanbanCard({ task, kr, showModalTask }) {

  const { t } = useTranslation('common');

  return (
    <div
      className='kanban-card'
      style={{
        backgroundColor: statusColors[task?.status_id - 1].backgroundColor
      }}
      onClick={() => showModalTask(task, kr)}
    >
      <strong style={{ color: statusColors[task?.status_id - 1].color }}>
        {task?.title}
      </strong>

      <div className='kr-info'>
        {task.total_items > 0 && (
          <>
            <CheckCircle size={16} color={statusColors[task?.status_id - 1].color} weight="bold" />
            <span style={{ color: statusColors[task?.status_id - 1].color }}>
              {task.total_items_done}/{task.total_items}
            </span>
          </>
        )}

        {task?.priority_id !== null && (
          <strong style={{ color: statusColors[task?.status_id - 1].color }}>
            {t(`tasks.${priority[task.priority_id - 1]}`)}
          </strong>
        )}
        <button>
          <span>{task?.total_comments}</span>
          <IconBalon />
        </button>
      </div>

      <div className='progress-container'>
        <div style={{
          width: task?.progress + '%',
          maxWidth: '100%',
          height: '3px',
          backgroundColor: statusColors[task.status_id - 1].color
        }} />
      </div>
    </div>
  );
}
