import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableReportsTeammates.scss';
import { befectiveFormatDate, history, secondsToHm } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { ArrowRight, House } from 'phosphor-react';
import { Apartment, Business, Home } from '@material-ui/icons';
import { Typography } from '@mui/material';


export default function TableReportsTeammates({
  data = []
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsPoints' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{borderBottom: '2px solid #F5F6F9'}}>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>{t("remote_checkin.reports_colab.table.col_colab")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>{t("remote_checkin.reports_colab.table.col_team")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>{t("remote_checkin.reports_colab.table.col_total_time")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("remote_checkin.reports_colab.table.col_average_external_time")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("remote_checkin.reports_colab.table.col_total_remote_checkin")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("remote_checkin.reports_colab.table.col_total_remote_checkin_finished")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("remote_checkin.reports_colab.table.col_total_remote_checkin_unfinished")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("remote_checkin.reports_colab.table.col_total_remote_checkin_integrated")}</TableCell>
            <TableCell width={'30px'} />
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.mu_id} className={`row-table`} onClick={() => history.push(`/checkin-remote/reports/teammates/${row.mu_id}`)}>
                <TableCell style={{fontSize:'12px'}}>{row.username}</TableCell>
                <TableCell style={{fontSize:'12px'}}>{row.group_name}</TableCell>
                <TableCell style={{fontSize:'12px'}}>{secondsToHm(row.total_time_in_seconds)}</TableCell>
                <TableCell style={{fontSize:'12px'}} align="center">{secondsToHm(row.average_external_time)}</TableCell>
                <TableCell style={{fontSize:'12px'}} align="center">{row.total_remote_checkin}</TableCell>
                <TableCell style={{fontSize:'12px'}} align="center">{row.total_remote_checkin_finished}</TableCell>
                <TableCell style={{fontSize:'12px'}} align="center">{row.total_remote_checkin_unfinished}</TableCell>
                <TableCell style={{fontSize:'12px'}} align="center">{row.total_remote_checkin_integrated}</TableCell>
                <TableCell style={{fontSize:'12px'}}>
                  <IconButton
                    className={`button-options`}
                    aria-label="expand row"
                    size="small"
                  >
                    <ArrowRight size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
