import React from 'react';

const IconAvatar = props => {
    return (

<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7">
  <path id="Icon_awesome-camera" data-name="Icon awesome-camera" d="M8,4V8.5a.75.75,0,0,1-.75.75H.75A.75.75,0,0,1,0,8.5V4a.75.75,0,0,1,.75-.75H2.125l.192-.514a.749.749,0,0,1,.7-.486H4.98a.749.749,0,0,1,.7.486l.194.514H7.25A.75.75,0,0,1,8,4ZM5.875,6.25A1.875,1.875,0,1,0,4,8.125,1.876,1.876,0,0,0,5.875,6.25Zm-.5,0A1.375,1.375,0,1,1,4,4.875,1.377,1.377,0,0,1,5.375,6.25Z" transform="translate(0 -2.25)" fill="#fff"/>
</svg>

      
    )
}

export default IconAvatar;