import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import Inactivity from '../_components/reports/inactivity/Inactivity'
import InactivitySkeleton from '../_components/reports/inactivity/InactivitySkeleton'
import Moment from 'moment';

import {useTranslation} from "react-i18next";


import {statsActions, shareActions} from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';


function ReportsInactivityPage(props) {
    const {t, i18n} = useTranslation('common');

    const {statsActions, shareActions, filter} = props;

    let loading = props.loading;


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);


    function closeShareModal() {
		shareActions.closeModal()
	}

    useEffect(() => {

        let teams = null;
        let teammates = null;

        statsActions.getInactivity(teams, teammates);


    }, []);


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.inactivity');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Filterbar 
                    filterWhen='disabled' 
                    filterWho='disabled' />

                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading === true
                                ? <InactivitySkeleton/>
                                : <Inactivity statsActions={statsActions}/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

            <div 
				className={'overlay' + (props.share.modal ? ' open' : '')} 
				onClick={() => {
					closeShareModal()
				}}
			></div>

			<section 
				className={'modal' + (props.share.modal ? ' open' : '')}
			>
				<ShareModal reports='inactivity' filters={filter} />
			</section>
        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
		shareActions: bindActionCreators(shareActions, dispatch)

    }
}


function mapStateToProps(state) {

    return {
        loading: state.stats.loading,
		share: state.share,
		filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsInactivityPage)
