export const filterConstants = {
    SET_WHEN: '[FILTERS] SET_WHEN',
    SET_WHAT: '[FILTERS] SET_WHAT',
    SET_WHO_TEAMS: '[FILTERS] SET_WHO_TEAMS',
    SET_WHO_TEAMMATES: '[FILTERS] SET_WHO_TEAMMATES',
    SET_INTERNAL_SOFTWARE: '[FILTERS] SET_INTERNAL_SOFTWARE',
    SET_INTERNAL_SITE: '[FILTERS] SET_INTERNAL_SITE',
    SET_INTERNAL_OFFLINE: '[FILTERS] SET_INTERNAL_OFFLINE',
    SET_INTERNAL_PRODUCTIVE: '[FILTERS] SET_INTERNAL_PRODUCTIVE',
    SET_INTERNAL_NEUTRAL: '[FILTERS] SET_INTERNAL_NEUTRAL',
    SET_INTERNAL_UNPRODUCTIVE: '[FILTERS] SET_INTERNAL_UNPRODUCTIVE',
    CLEAR_WHO: "[FILTERS] CLEAR_WHO",
    CLEAR_WHEN: "[FILTERS] CLEAR_WHEN",
    CLEAR_WHAT: "[FILTERS] CLEAR_WHAT",
    CLEAR_WHO_TEAMS: "[FILTERS] CLEAR_WHO_TEAMS",
    CLEAR_WHO_TEAMMATES: "[FILTERS] CLEAR_WHO_TEAMMATES",
    REPLACE_WHO_TEAMMATES: '[FILTERS] REPLACE_WHO_TEAMMATES',
    REPLACE_SINGLE_WHEN: '[FILTERS] REPLACE_SINGLE_WHEN',
};
