import { Container, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonChecklistPage(props) {

  return (

    <Container component={'main'} maxWidth="xl" sx={{ flexGrow: 1, padding: '16px 0px', marginTop:'-80px' }} >
      <Grid container spacing={2} component={'section'} marginTop={'0px'} marginBottom={'16px'}>
        <Grid item xs={4}>
            <Skeleton width={'100%'} height={470} variant='rounded'/>
        </Grid>
        <Grid item xs={4}>
          <Skeleton width={'100%'} height={470} variant='rounded' />
        </Grid>
        <Grid item xs={4}>
          <Skeleton width={'100%'} height={470} variant='rounded' />
        </Grid>
      </Grid>
    </Container>
  )
}