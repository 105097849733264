import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./styles.scss"
import { Tooltip } from '@mui/material';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@material-ui/core';
import { CopySimple, Minus, Plus } from 'phosphor-react';
import { authHeader } from '../../../_helpers';
import { api } from '../../../_helpers/api';

const week = [
  {
    "label_pt":"Domingo",
    "label_es":"Domingo",
    "label_en":"Sunday",
    "AKA_pt": "D",
    "AKA_es": "D",
    "AKA_en": "S",
    "day_week": 0    
  },
  {
    "label_pt":"Segunda-feira",
    "label_es":"Lunes",
    "label_en":"Monday",
    "AKA_pt": "S",
    "AKA_es": "L",
    "AKA_en": "M",
    "day_week": 1    
  },
  {
    "label_pt":"Terça-feira",
    "label_es":"Martes",
    "label_en":"Tuesday",
    "AKA_pt": "T",
    "AKA_es": "M",
    "AKA_en": "T",
    "day_week": 2    
  },
  {
    "label_pt":"Quarta-feira",
    "label_es":"Miércoles",
    "label_en":"Wednesday",
    "AKA_pt": "Q",
    "AKA_es": "M",
    "AKA_en": "W",
    "day_week": 3   
  },
  {
    "label_pt":"Quinta-feira",
    "label_es":"Jueves",
    "label_en":"Thursday",
    "AKA_pt": "Q",
    "AKA_es": "J",
    "AKA_en": "T",
    "day_week": 4    
  },
  {
    "label_pt":"Sexta-feira",
    "label_es":"Viernes",
    "label_en":"Friday",
    "AKA_pt": "S",
    "AKA_es": "V",
    "AKA_en": "F",
    "day_week": 5    
  },
  {
    "label_pt":"Sábado",
    "label_es":"Sábado",
    "label_en":"Saturday",
    "AKA_pt": "S",
    "AKA_es": "S",
    "AKA_en": "S",
    "day_week": 6    
  }
]
const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 90,
    },
  },
};

const clock = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export function WeekDays({language="en",workingHours,showWorkTime=false,isClickAble=false,handleDayWeek,createWorkingHourPeriod,updateWorkingHourPeriod,handleDeleteWorkingPeriod,handleCopyToAll}) {
  
  const theme = useTheme();
  const { t, i18n } = useTranslation('common');

  async function handleCopyToAllPeriod(group_id) {
    await handleCopyToAll(group_id)
  }
  const handleChange = async (event,ref,period_id,start,end,day_week_id) => {
    const new_value = event.target.value
    // day_week_id,period_id,ref,new_value,start,end
    if(ref === "end"){
      await updateWorkingHourPeriod(day_week_id,period_id,start,new_value,ref)
      return 
    }    
    await updateWorkingHourPeriod(day_week_id,period_id,new_value,end,ref)
    return
  };
  async function handleWeekDay(day_week) {
    await handleDayWeek(day_week)
  }

  async function handleCreateWorkingHourPeriod(working_hour_id) {
    await createWorkingHourPeriod(working_hour_id)
  }
  async function handleDeletePeriod(id) {
    await handleDeleteWorkingPeriod(id)
  }
  
  // function switchDayWeek(dw) {
  //   const resp = activeDays.filter(item => item.dayWeek === dw)
  //   console.log("/day_week: ",dw)
  //   if(resp.length > 0){
  //     console.log("/Esse dia sera desativado!")
      
  //     setActiveDays([
  //       ...activeDays.filter(item => item.dayWeek !== dw)
  //     ])
  //     return{}
  //   }


  //   setActiveDays((value) => {
  //     return [...value,{
  //       id:dw,
  //       dayWeek:dw,
  //       active:true,
  //       periods:[
  //         {
  //           start:"08:00",
  //           end:"18:00"
  //         }
  //       ]
    
  //     }].sort(function compare( a, b ) {
  //       if ( a.dayWeek < b.dayWeek ){
  //         return -1;
  //       }
  //       if ( a.dayWeek > b.dayWeek ){
  //         return 1;
  //       }
  //       return 0;
  //     })
  //   } )
  //   console.log("/Esse dia sera ativado: ")
    
  // }
  return (
    <Fragment>
      <div id='week-days'>
        {week && week.map((day) => 
         <Tooltip key={day.id} title={day[`label_${language}`]}>
            <div className={`circle ${(workingHours && workingHours?.filter(item => item.day_week === day.day_week).length > 0 )? "active": "not-active"}`} onClick={() => {
              if(isClickAble){
                //Do Active day week
                
                handleWeekDay(day.day_week)
              }
            }} >      
              <span>{day[`AKA_${language}`]}</span> 
            </div>
          </Tooltip>
        )}             

      </div>
      {
        showWorkTime && (<div className='dayWeek-work-time'>
                          {workingHours && workingHours?.map( (item,item_idx) => (
                            <div>
                              <Typography  fontWeight={500} style={{color: '#4F7091'}}>{week[item.day_week][`label_${language}`]}</Typography>
                              <div className='square'>
                                
                                
                                  {item.periods.length > 0 && item.periods.map((period,idx)=> (
                                  <div className='period-row'>
                                    <div className='time-square'>
                                      <FormControl sx={{ m: 1, width: 200 }}>
                                        {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
                                        <Select
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={period.start}
                                          // event,ref,period_id,start,end,day_week_id
                                          onChange={(e) => {handleChange(e,"start",period.id,period.start,period.end,item.id)}}
                                          // input={<OutlinedInput label="Name" />}
                                          MenuProps={MenuProps}
                                          style={{color:"#1E6B7F"}}
                                        >
                                          {clock.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(name, period.start, theme)}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <Typography variant='subtitle1' fontWeight={500} style={{color:"#1E6B7F"}}>até</Typography> 
                                      <FormControl sx={{ m: 1, width: 200 }}>
                                        {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
                                        <Select
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={period.end}
                                          onChange={(e) => {handleChange(e,"end",period.id,period.start,period.end,item.id)}}
                                          // input={<OutlinedInput label="Name" />}
                                          MenuProps={MenuProps}
                                          style={{color:"#1E6B7F"}}
                                        >
                                          {clock.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(name, period.start, theme)}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>       
                                    </div>
                                    {item.periods.length - 1 === idx && 
                                    ( <div className='simple-square' onClick={() => {
                                      handleCreateWorkingHourPeriod(item.id)
                                    }}>
                                        <Plus size={16} color='#187DFF' weight="bold" />
                                      </div>)

                                    }
                                    {idx !== 0 && 
                                    (<> 
                                        { item.periods.length - 1 !== idx && 
                                            (<div className='invi-square' >
                                              
                                            </div>)                                            
                                        }
                                        <div className='simple-square' onClick={() => {                                          
                                          handleDeletePeriod(period.id)
                                        }} >
                                          <Minus size={16} color='#187DFF' weight="bold" />
                                        </div> 
                                    </> 
                                    )

                                    }
                                    {idx === 0 && item_idx === 0 &&
                                      ( 
                                        <>
                                          { item.periods.length > 1 && 
                                            (<div className='invi-square' >
                                              
                                            </div>)                                            
                                          }
                                          <Tooltip key={idx} title={t("checkin.settings_form.field.copy_to_all")}>
                                            <div className='copy-to-all' onClick={() => {                                              
                                               handleCopyToAllPeriod(item.group_id)
                                              }}>
                                              <div className='simple-square' style={{backgroundColor:"#E3EFFF"}}>
                                                <CopySimple size={16} color='#187DFF' weight="bold" />
                                              </div>
                                              <Typography fontWeight={700} style={{color:"#187DFF",textAlign:"center",lineHeight:"1"}}>{t("checkin.settings_form.field.copy_to_all")}</Typography>
                                            </div>
                                          </Tooltip>
                                          
                                          
                                          
                                        </>
                                     
                                      )
                                    }
                                    
                                  </div>
                                  ))
                                    
                                }
                                  
                              </div>
                          </div>) 

                          )

                          }
                          
                        </div>)
      }
      
      
    </Fragment>
    
  );
}