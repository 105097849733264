
import { SignOut, UserPlus } from 'phosphor-react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useOutsideClick from '../../../_helpers/useOutsideClick';

import './styles.scss';
import { withRouter } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import { Menu, MenuItem } from '@mui/material';

const MenuAccount = ({
  accountMenu,
  setAccountMenu,
  accountsLogged,
  handleLogin,
  handleGetAccountEvents,
  handleDeactivateAllAccounts,
  anchorEl
}) => {
  const { t, i18n } = useTranslation('common');

  const ref = useRef();

  const [showModalConfirmation, setShowModalConfirmation] = useState(false)

  // useOutsideClick(ref, () => {
  //   if (accountMenu) {
  //     setAccountMenu(false)
  //     setShowModalConfirmation(false)
  //   }
  // });

  function closeMenuAccounts() {
    setAccountMenu(false)
    setShowModalConfirmation(false)
  }

  function handleSelectUser(account_id, account_is_active) {
    setAccountMenu(false)
    if (!account_is_active) {
      handleGetAccountEvents(account_id)
    }
  }

  function openDialogConfirmation() {
    setShowModalConfirmation(true)
  }

  function closeDialogConfirmation() {
    setShowModalConfirmation(false)
  }

  function deactivateAccounts() {
    handleDeactivateAllAccounts()
    setShowModalConfirmation(false)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="menu-accounts-out"
        open={accountMenu}
        onClose={closeMenuAccounts}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: '#24677E',
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              backgroundColor: '#24677E'
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#24677E",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className='accountMenuMu'>

          {
            accountsLogged && accountsLogged?.map((acc, index) => (
              <MenuItem
                className={`${acc.is_active && 'active'} button-users`}
                onClick={() => handleSelectUser(acc.id, acc.is_active)}
                key={index}
              >

                <div className='avatarAccount'>
                  <img src={acc.picture ? acc.picture : 'https://ui-avatars.com/api/?background=random&color=ffffff&name=' + encodeURI(acc.name)} referrerPolicy="no-referrer" />
                </div>
                <div className='infoAccount'>
                  <h6>
                    {acc?.username}
                  </h6>
                  <span>
                    {acc?.email}
                  </span>
                </div>

              </MenuItem>

            ))
          }

          <MenuItem
            className='button-action'
            onClick={handleLogin}
          >
            <UserPlus size={28} color="#FFF" />
            <span>{t('integrations.add_other_account')}</span>
          </MenuItem>

          <MenuItem
            className='button-action'
            onClick={() => openDialogConfirmation()}
          >
            <SignOut size={28} color="#FFF" />
            <span>{t('integrations.sign_out_accounts')}</span>
          </MenuItem>
        </div>

      </Menu>


      {showModalConfirmation && (
        <div className="confirmDialog open" style={{height: '112px'}}>
          <div className="content">
            <div className="message">
              <IconInfo />
              {t(t('integrations.confirm_delete'))}
            </div>
            <div className='subtitle'>
              {t('integrations.subtitle_delete')}
            </div>
            <div className="options">
              <button onClick={() => closeDialogConfirmation()}>{t('no')}</button>
              <button onClick={() => deactivateAccounts()}>{t('yes')}</button>
            </div>
          </div>
        </div>
      )}
    </>
  )

}


function mapStateToProps(state) {

  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuAccount));