import React, { useRef, useState, useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation, initReactI18next } from "react-i18next"
import { bindActionCreators } from 'redux'

import { teammatesActions, teamsActions } from '../../../_actions'

import { calcLicensesAvailable, validateEmail } from '../../../_helpers';

import IconAdd from '../../_icons/IconAdd'
import IconRemove from '../../_icons/IconRemove'

function DrawerInvite(props) {
  const { t, i18n } = useTranslation('common')

  const { teammatesActions, teamsActions, companyInformation, getCompanyInformation } = props
  const { drawerData } = props


  let current = props.current
  //let inviteList = []

  const [invited, setInvited] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [inviteList, setInviteList] = useState([])
  const [form, setForm] = useState({
    emails: [],
    email: '',
    sendInvite: true
  })

  const [formError, setFormError] = useState({
    invalid: '',
    already: ''
  })

  const handleChange = e => {
    setSubmitted(false)

    if (e.target.name == 'sendInvite') {
      setForm({
        ...form,
        [e.target.name]: !form.sendInvite,
      })
    } else {

      setForm({
        ...form,
        [e.target.name]: e.target.value.trim(),
      })
    }


  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    let formIsValid = true

    if (!form["emails"] || form["emails"].length == 0) {
      formIsValid = false
      setFormError({
        ...formError,
        emails: t('settings.company.error.required'),
      })
    }

    if (formIsValid) {

      setFormError({
        invalid: '',
        already: ''
      })

      teammatesActions.inviteTeammate(form, current.id)
    } else {
      setSubmitted(false)
    }

  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      add()
    }
  }

  function closeDrawer() {


    setForm({
      emails: [],
      email: '',
      sendInvite: true
    })

    setFormError({
      invalid: '',
      already: ''
    })

    props.closeDrawer()
  }

  function add() {

    let invite = form.email

    setFormError({
      invalid: '',
      already: ''
    })

    if (invite && validateEmail(invite)) {

      if (form.emails.includes(invite)) {

        setFormError({
          already: true
        })

        return
      }

      let newList = form.emails
      newList.push(invite)

      setForm({
        ...form,
        emails: newList
      })

      let elems = document.querySelectorAll(".invitation")

      let nextId = elems.length + 1

      setInviteList([
        ...inviteList,
        <li key={"inv-" + nextId} id={"inv-" + nextId} className={"invitation"}>
          {invite}
          <button type="button" onClick={() => {
            remove("inv-" + nextId, invite)
          }}><IconRemove /></button>
        </li>
      ])

    } else {
      setFormError({
        invalid: true
      })
    }


  }

  function remove(id, email) {

    document.getElementById(id).remove()
    let newList = form.emails

    let index = newList.indexOf(email);
    if (index !== -1) {
      newList.splice(index, 1);
    }

    setForm({
      ...form,
      emails: newList
    })


  }

  useEffect(() => {

    setInvited(props.invited)


    if (props.invited == 'error') {
      setSubmitted(false)
    }

    if (props.invited == 'success' && submitted) {

      setSubmitted(false)
      setInvited(false)
      closeDrawer()
      teammatesActions.getTeammates()
      teamsActions.getTeams()
      getCompanyInformation()
    }

  }, [props.invited])


  return (
    <React.Fragment>
      <div className="internal invite team create">

        <header>
          <h3>{t('settings.teams.invite_title') + current.name}</h3>
        </header>

        <form onSubmit={handleSubmit} className="form">


          <div className="fieldset text emails">
            <div className="field">
              <label htmlFor="email">{t('settings.teams.email')}*</label>
              <input
                type="text"
                id="email"
                name="email"
                value={form.email.trim()}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />

            </div>
            <button type="button" className="add" onClick={() => {
              add()
            }} disabled={calcLicensesAvailable(companyInformation, form.emails.length) === 0}><IconAdd /></button>
          </div>
          <div className="fieldset radio extra">
            <div className="field custom-width">
              <input type="checkbox" id="sendInvite" name="sendInvite" checked={form.sendInvite} value={form.sendInvite} onChange={handleChange} /> <label htmlFor="sendInvite">{t('settings.teams.send_invite')}</label>
            </div>
          </div>

          <div className={`infoLicense ${calcLicensesAvailable(companyInformation, form.emails.length) > 0 ? 'success' : 'error'}`}>
            {t('settings.licenses_available')}: {calcLicensesAvailable(companyInformation, form.emails.length)}
          </div>


          <div className="formReturn">
            {(invited == 'error') &&
              <div className="error">{t('settings.teams.create_error')}</div>
            }

            {(formError.invalid) &&
              <div className="error">{t('settings.teams.invalid_mail')}</div>
            }

            {(formError.already) &&
              <div className="error">{t('settings.teams.already')}</div>
            }
          </div>

          <ul className="emails">
            {inviteList}
          </ul>

          <div className="controls">
            {(submitted && !invited) &&
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
            <button type="button" disabled={submitted ? true : false} onClick={() => {
              closeDrawer()
            }}>{t('settings.teams.cancel')}</button>
            <button type="submit" disabled={submitted ? true : false}>{t('settings.teams.invite')}</button>
          </div>
        </form>

      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {

  return {
    teams: state.teams.payload,
    invited: state.teammates.invited
  }

}


function mapDispatchToProps(dispatch) {

  return {
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    teamsActions: bindActionCreators(teamsActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DrawerInvite)



