import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {NavLink, Link} from 'react-router-dom';

import {useTranslation, initReactI18next} from "react-i18next";
import {VictoryPie, VictoryTheme} from 'victory';
import {secondsToHm} from '../../_helpers';
import IconInfo from '../_icons/IconInfo';
import IconOnline from '../_icons/IconOnline';
import IconOffline from '../_icons/IconOffline';
import IconChart from '../_icons/IconChart';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

function TodayStats(props) {
    const {t, i18n} = useTranslation('common');

    let today = props.today || {};

    let TotalExpectedWorkedTime = today.TotalExpectedWorkedTime || 0;
    let TotalExpectedProductiveTime = today.TotalExpectedProductiveTime || 0;
    let TotalWorkedTime = 0;
    let PercentExpectedWorkedTime = 0;

    let TotalWorkedTimeRelated = 0
    let TotalWorkedTimeOnline = 0
    let PercentWorkedTimeRelated = 0;

    let TotalWorkedTimeOutdoor = today.TotalWorkedTimeOutdoor || 0;
    let PercentWorkedTimeOutdoor = 0;

    let TotalProductiveTime = 0;
    let PercentProductiveTime = 0;

    let TotalProductiveTimeRelated = 0
    let PercentProductiveTimeRelated = 0;

    let TotalProductiveTimeOutdoor = 0;
    let PercentProductiveTimeOutdoor = 0;

    if (!TotalExpectedWorkedTime || TotalExpectedWorkedTime <= 0) {
        TotalExpectedWorkedTime = 0
        PercentExpectedWorkedTime = 'Ω'
        PercentProductiveTime = 'Ω'
    } else {
        if (today.TotalWorkedTime && today.TotalWorkedTime > 0) {
            TotalWorkedTime = secondsToHm(today.TotalWorkedTime);
        }
        if (today.TotalProductiveTime && today.TotalProductiveTime > 0) {
            TotalProductiveTime = secondsToHm(today.TotalProductiveTime);
        }

        if (today.TotalWorkedTime && today.TotalWorkedTime > 0 && TotalExpectedWorkedTime) {
            TotalWorkedTimeRelated = secondsToHm(today.TotalWorkedTime);
            PercentWorkedTimeRelated = Math.round(((today.TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalExpectedWorkedTime) * 100);
            PercentExpectedWorkedTime = (((today.TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalExpectedWorkedTime) * 100)
            TotalWorkedTimeOnline = secondsToHm(today.TotalWorkedTime - TotalWorkedTimeOutdoor)
        }

        if (TotalWorkedTimeOutdoor && TotalWorkedTimeOutdoor > 0 && TotalExpectedWorkedTime > 0) {
            TotalWorkedTimeOutdoor = secondsToHm(today.TotalWorkedTimeOutdoor);
            PercentWorkedTimeOutdoor = Math.round((parseInt(today.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100);
            PercentExpectedWorkedTime += ((parseInt(today.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100)
        }

        if (today.TotalProductiveTime && today.TotalProductiveTime > 0) {

            let TempTotalProductiveTimeOutdoor = 0;

            if (today.TotalProductiveTimeOutdoor && today.TotalProductiveTimeOutdoor > 0) {
                TempTotalProductiveTimeOutdoor = today.TotalProductiveTimeOutdoor;
            }

            TotalProductiveTimeRelated = secondsToHm(today.TotalProductiveTime - TempTotalProductiveTimeOutdoor);
            PercentProductiveTimeRelated = Math.round(((today.TotalProductiveTime - TempTotalProductiveTimeOutdoor) / TotalExpectedProductiveTime) * 100);
            PercentProductiveTime = (((today.TotalProductiveTime - TempTotalProductiveTimeOutdoor) / TotalExpectedProductiveTime) * 100)
        }

        if (today.TotalProductiveTimeOutdoor && today.TotalProductiveTimeOutdoor > 0) {
            TotalProductiveTimeOutdoor = secondsToHm(today.TotalProductiveTimeOutdoor);
            PercentProductiveTimeOutdoor = Math.round((today.TotalProductiveTimeOutdoor / TotalExpectedProductiveTime) * 100);
            PercentProductiveTime += ((today.TotalProductiveTimeOutdoor / TotalExpectedProductiveTime) * 100)
        }
    }

    if (PercentExpectedWorkedTime != 'Ω') {
        PercentExpectedWorkedTime = Math.round(PercentExpectedWorkedTime)
    }

    if (PercentProductiveTime != 'Ω') {
        PercentProductiveTime = Math.round(PercentProductiveTime)
    }

    return (
        <div className={props.highlights ? 'box stats' : 'box stats'}>
            <h3>{t('home.your_company_stats')}<span><IconInfo/>
                <div
                className="tooltip">
                    {/* <IconInfo/> */}
                    <p className="desc-label"><strong>{t('home.time_goal')+': '}</strong>{t('home.performance_label_1')}</p>
                    <p className="desc-label"><strong>{t('home.work_goal')+': '}</strong>{t('home.performance_label_2')}</p>
                </div>
            </span>
            </h3>

            {props.productivity &&
                <a className="statsBlock time" style={{cursor: 'default'}}>
                    <div className="mainChart" style={{cursor: 'default'}}>
                        <div className="stats">
                            <MobileView>
                                <div className="icon"><IconChart></IconChart></div>
                            </MobileView>
                            <span className="primary">
                                {PercentExpectedWorkedTime == 'Ω' ?
                                    PercentExpectedWorkedTime
                                    :
                                    PercentExpectedWorkedTime + '%'
                                }
                            </span>
                            <span className="secondary">{t('home.time_goal')}</span>
                            <span>{TotalWorkedTime}</span>
                        </div>
                        <BrowserView>
                            <svg width={188} height={188}>
                                <VictoryPie
                                    padAngle={0}
                                    standalone={false}
                                    // used to hide labels
                                    labelComponent={<span/>}
                                    innerRadius={86}
                                    width={188} height={188}
                                    data={[{'key': "", 'y': PercentExpectedWorkedTime}, {
                                        'key': "",
                                        'y': (100 - PercentExpectedWorkedTime)
                                    }]}
                                    colorScale={["", "#D2D7E1"]}
                                />
                                <circle cx={94} cy={94} r={79}/>
                            </svg>
                        </BrowserView>

                    </div>

                    <div className="secondaryChart">

                        <div className="block">
                            <div className="icon">
                                <IconOnline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.online_activities')}</span>
                                <span>{TotalWorkedTimeOnline}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentWorkedTimeRelated + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentWorkedTimeRelated}%
                            </div>
                        </div>
                        <div className="block">
                            <div className="icon">
                                <IconOffline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.offline_activities')}</span>
                                <span>{TotalWorkedTimeOutdoor}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentWorkedTimeOutdoor + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentWorkedTimeOutdoor}%
                            </div>
                        </div>
                    </div>

                </a>
            }
            {!props.productivity &&
                <Link to="/productive/reports/timegoal" className="statsBlock time">
                    <div className="mainChart">
                        <Link to="/productive/reports/timegoal">
                            <div className="stats">
                                <MobileView>
                                    <div className="icon"><IconChart></IconChart></div>
                                </MobileView>
                                <span className="primary">
                                {PercentExpectedWorkedTime == 'Ω' ?
                                    PercentExpectedWorkedTime
                                    :
                                    PercentExpectedWorkedTime + '%'
                                }
                            </span>
                                <span className="secondary">{t('home.time_goal')}</span>
                                <span>{TotalWorkedTime}</span>
                            </div>
                            <BrowserView>
                                <svg width={188} height={188}>
                                    <VictoryPie
                                        padAngle={0}
                                        standalone={false}
                                        // used to hide labels
                                        labelComponent={<span/>}
                                        innerRadius={86}
                                        width={188} height={188}
                                        data={[{'key': "", 'y': PercentExpectedWorkedTime}, {
                                            'key': "",
                                            'y': (100 - PercentExpectedWorkedTime)
                                        }]}
                                        colorScale={["", "#D2D7E1"]}
                                    />
                                    <circle cx={94} cy={94} r={79}/>
                                </svg>
                            </BrowserView>
                        </Link>
                    </div>

                    <div className="secondaryChart">

                        <div className="block">
                            <div className="icon">
                                <IconOnline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.online_activities')}</span>
                                <span className="totalTimeText">{TotalWorkedTimeOnline}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentWorkedTimeRelated + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentWorkedTimeRelated}%
                            </div>
                        </div>
                        <div className="block">
                            <div className="icon">
                                <IconOffline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.offline_activities')}</span>
                                <span className="totalTimeText">{TotalWorkedTimeOutdoor}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentWorkedTimeOutdoor + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentWorkedTimeOutdoor}%
                            </div>
                        </div>
                    </div>

                </Link>
            }
            {props.productivity &&
                <a className="statsBlock work" style={{cursor: 'default'}}>
                    <div className="mainChart" style={{cursor: 'default'}}>

                        <div className="stats">
                            <MobileView>
                                <div className="icon"><IconChart></IconChart></div>
                            </MobileView>
                            <span className="primary">
                                {PercentProductiveTime == 'Ω' ?
                                    PercentProductiveTime
                                    :
                                    PercentProductiveTime + '%'
                                }
                            </span>
                            <span className="secondary">{t('home.work_goal')}</span>
                            <span>{TotalProductiveTime}</span>
                        </div>
                        <BrowserView>
                            <svg width={188} height={188}>
                                <VictoryPie
                                    padAngle={0}
                                    standalone={false}
                                    // used to hide labels
                                    labelComponent={<span/>}
                                    innerRadius={86}
                                    width={188} height={188}
                                    data={[{'key': "", 'y': PercentProductiveTime}, {
                                        'key': "",
                                        'y': (100 - PercentProductiveTime)
                                    }]}
                                    colorScale={["", "#D2D7E1"]}
                                />
                                <circle cx={94} cy={94} r={79}/>
                            </svg>
                        </BrowserView>

                    </div>
                    <div className="secondaryChart">
                        <div className="block">
                            <div className="icon">
                                <IconOnline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.online_activities')}</span>
                                <span>{TotalProductiveTimeRelated}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentProductiveTimeRelated + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentProductiveTimeRelated}%
                            </div>
                        </div>
                        <div className="block">
                            <div className="icon">
                                <IconOffline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.offline_activities')}</span>
                                <span>{TotalProductiveTimeOutdoor}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentProductiveTimeOutdoor + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentProductiveTimeOutdoor}%
                            </div>
                        </div>
                    </div>
                </a>
            }
            {!props.productivity &&
                <Link to="/productive/reports/workgoal" className="statsBlock work">

                    <div className="mainChart">
                        <Link to="/productive/reports/workgoal">
                            <div className="stats">
                                <MobileView>
                                    <div className="icon"><IconChart></IconChart></div>
                                </MobileView>
                                <span className="primary">
                                {PercentProductiveTime == 'Ω' ?
                                    PercentProductiveTime
                                    :
                                    PercentProductiveTime + '%'
                                }
                            </span>
                                <span className="secondary">{t('home.work_goal')}</span>
                                <span>{TotalProductiveTime}</span>
                            </div>
                            <BrowserView>
                                <svg width={188} height={188}>
                                    <VictoryPie
                                        padAngle={0}
                                        standalone={false}
                                        // used to hide labels
                                        labelComponent={<span/>}
                                        innerRadius={86}
                                        width={188} height={188}
                                        data={[{'key': "", 'y': PercentProductiveTime}, {
                                            'key': "",
                                            'y': (100 - PercentProductiveTime)
                                        }]}
                                        colorScale={["", "#D2D7E1"]}
                                    />
                                    <circle cx={94} cy={94} r={79}/>
                                </svg>
                            </BrowserView>
                        </Link>
                    </div>
                    <div className="secondaryChart">
                        <div className="block">
                            <div className="icon">
                                <IconOnline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.online_activities')}</span>
                                <span className="totalTimeText">{TotalProductiveTimeRelated}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentProductiveTimeRelated + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentProductiveTimeRelated}%
                            </div>
                        </div>
                        <div className="block">
                            <div className="icon">
                                <IconOffline/>
                            </div>
                            <div className="stats">
                                <span>{t('home.offline_activities')}</span>
                                <span className="totalTimeText">{TotalProductiveTimeOutdoor}</span>
                                <div className="barchart">
                                    <div className="bar" style={{width: PercentProductiveTimeOutdoor + '%'}}></div>
                                </div>
                            </div>
                            <div className="number">
                                {PercentProductiveTimeOutdoor}%
                            </div>
                        </div>
                    </div>
                </Link>
            }

            {props.highlights &&
                <div className="extraInfo">
                    <p>{t('home.extra_info')} <b> Ω</b></p>
                </div>
            }
        </div>
    )
};


function mapStateToProps(state) {

    return {
        today: state.stats.payload
    }

}

export default connect(mapStateToProps)(TodayStats)
