import React, { useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux'
import Moment from 'moment-timezone';
import { useTable, useSortBy } from 'react-table'


import IconTeammate from '../../_icons/IconTeammate';
import IconInfo from '../../_icons/IconInfo';
import IconCompleted from '../../_icons/IconCompleted';
import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';

import { authHeader, befectiveFormatDate, secondsToHm } from '../../../_helpers';
import { ClockClockwise, HourglassMedium, Warning } from 'phosphor-react';
import { api } from '../../../_helpers/api';
import { Divider, Tooltip, withStyles } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import { alertActions } from '../../../_actions';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#1E6B7F',
        maxWidth: '300px'
    },
    arrow: {
        color: '#1E6B7F'
    },
}))(Tooltip);

function Inactivity(props) {
    const { t, i18n } = useTranslation('common');
    const [disableButtonRestart, setDisableButtonRestart] = useState(false)

    let inactivity = props.inactivity || [];
    let inactivityMu = props.inactivityMu || [];


    Moment.locale(t('date.locale'));
    const data = useMemo(() => inactivityMu, []);

    let TotalUsers = inactivity.TotalUsers || 0;
    let ActiveUsersToday = inactivity.ActiveUsersToday || 0;
    let ScheduledAbsensesToday = inactivity.ScheduledAbsensesToday || 0;
    let InactiveUsersToday = (inactivity.TotalUsers - inactivity.ActiveUsersToday - inactivity.ScheduledAbsensesToday) || 0;

    const columns = React.useMemo(
        () => [
            {
                Header: t('inactivity.teammate'),
                accessor: 'machineUserName',
            },
            {
                Header: t('inactivity.team'),
                accessor: 'teamName',
            },
            {
                Header: t('inactivity.work_days'),
                accessor: 'workTime',
                Cell: (d) => {

                    let days = ''


                    let nd = 0;

                    if (d.value[0] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.sun')
                        nd++;
                    }
                    if (d.value[1] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.mon')
                        nd++;
                    }
                    if (d.value[2] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.tue')
                        nd++;
                    }
                    if (d.value[3] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.wed')
                        nd++;
                    }
                    if (d.value[4] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.thu')
                        nd++;
                    }
                    if (d.value[5] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.fri')
                        nd++;
                    }
                    if (d.value[6] != '00:00') {
                        if (nd > 0) {
                            days += ", "
                        }
                        days += t('settings.teams.sat')
                        nd++;
                    }

                    return days


                }
            },
            {
                Header: t('inactivity.last_activity'),
                accessor: 'lastActivity',
                Cell: (d) => {

                    if (d.value) {
                        return Moment.utc(d.value).local().startOf('seconds').fromNow()
                    } else {
                        return t('inactivity.never')
                    }


                }
            }

        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        disableMultiSort: true,

    }, useSortBy);

    const handleActionRestart = async (group_id, machine_user_id) => {

        try {
            setDisableButtonRestart(true)
            const response = await api.post(
                `/teammates/agent-actions`,
                {
                    "group_id": group_id,
                    "machine_user_id": machine_user_id,
                    "action": "RESTART"
                },
                {
                    headers: authHeader()
                }
            )
            if(response.status !== 200){
                throw new Error("Erro ao criar a ação de restart")
            }
            await props.statsActions.getInactivity(null, null);
            setDisableButtonRestart(false)
        } catch (error) {
            console.log(error)
            setDisableButtonRestart(false)
        }
    }


    return (
        <div className="box teammates noBg">
            <div className="pageHeader compact realtime">
                <h3>{t('inactivity.title')} <span><IconInfo /><div
                    className="tooltip">{/*<IconInfo/>*/}{t('realtime.tooltip_inactivity')}</div></span></h3>
                <div className="internalFilters">

                    <div className="filter active alert">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeammate />
                            </div>
                        </div>
                        <div className={"highlight"}>{InactiveUsersToday}</div>
                        <div className="name">{t('inactivity.inactivity')}</div>
                    </div>
                    <div className="filter active  work">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeammate />
                            </div>
                        </div>
                        <div className={"highlight"}>{ActiveUsersToday}</div>
                        <div className="name">{t('inactivity.active')}</div>
                    </div>

                    <div className="filter active  time">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeammate />
                            </div>
                        </div>
                        <div className={"highlight"}>{ScheduledAbsensesToday}</div>
                        <div className="name">{t('realtime.free_today')}</div>
                    </div>

                    <div className="filter active  time">
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeammate />
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalUsers}</div>
                        <div className="name">{t('inactivity.total')}</div>
                    </div>


                </div>


            </div>
            <div className="dataTable inactivity" style={{ overflow: 'auto' }}>
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <div className="arrowDown"></div>
                                                        : <div className="arrowUp"></div>
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                    <th/>
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {

                                    prepareRow(row);

                                    return (
                                        <tr {...row.getRowProps()} className={row.original.lastActivity ? "" : "alert"}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                            <td>
                                                {
                                                    !row.original.lastActivity
                                                        ?
                                                        <CustomTooltip
                                                            title={<Typography py={'2px'} fontSize={'12px'} fontWeight={400}>{t('inactivity.action_unavailable')}</Typography>}
                                                            placement="bottom"
                                                            PopperProps={{
                                                                style: { marginTop: '-8px' }
                                                            }}
                                                            interactive
                                                        >
                                                            <button disabled={true} className={`buttonError`}>
                                                                <Warning size={18} color="#FC6662" weight="bold" />
                                                            </button>
                                                        </CustomTooltip>
                                                        :
                                                        (
                                                            (row.original.actions.length > 0 && row.original.actions.filter(item => item.executed === false).length > 0)
                                                                ?
                                                                <CustomTooltip
                                                                    title={
                                                                        <Box padding={'2px'}>
                                                                            <Typography py={'2px'} fontSize={'12px'}>{t('inactivity.action_title_restarts')}</Typography>
                                                                            <ul className='listActionsRestart'>
                                                                                {
                                                                                    row.original.actions.map(a => (
                                                                                        <li style={{color: a.executed ? '#22BEAD': '#FDBE2B'}} className='listItemActionRestart' key={a.id}>&#8226; {`${a.executed ? t('inactivity.action_status_completed') : t('inactivity.action_status_pedding')} - ${befectiveFormatDate(a.created_at, i18n.language, 't')}`}</li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                            <Divider style={{margin:'4px 0px'}}/>
                                                                            <Typography py={'2px'} fontSize={'11px'} style={{color: '#FC6662'}}>{t('inactivity.action_obs_pending')}</Typography>
                                                                        </Box>
                                                                    }
                                                                    placement="bottom"
                                                                    PopperProps={{
                                                                        style: { marginTop: '-8px' }
                                                                    }}
                                                                    interactive
                                                                >
                                                                    <button
                                                                        className={`buttonPendingAction`}
                                                                    >
                                                                        <HourglassMedium size={18} color="#FDBE2B" weight="bold" />
                                                                    </button>
                                                                </CustomTooltip>
                                                                
                                                                :

                                                                <CustomTooltip
                                                                    title={<Typography py={'2px'} fontSize={'12px'} fontWeight={400}>{t('inactivity.action_restart_agent')}</Typography>}
                                                                    placement="bottom"
                                                                    PopperProps={{
                                                                        style: { marginTop: '-8px' }
                                                                    }}
                                                                    interactive
                                                                >
                                                                    <button
                                                                        disabled={disableButtonRestart}
                                                                        className={`buttonRestartAction ${disableButtonRestart && 'disableHover'}`}
                                                                        onClick={() => handleActionRestart(row.original.group_id, row.original.id)}
                                                                    >
                                                                        <ClockClockwise size={18} color="#187DFF" weight="bold" />
                                                                    </button>
                                                                </CustomTooltip>
                                                        )
                                                }

                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                }
                {data.length == 0 &&
                    <p className={'noData'}>{t('no_data')}</p>
                }
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        alertActions: bindActionCreators(alertActions, dispatch)
    }
}

function mapStateToProps(state) {

    return {
        inactivity: state.stats.inactivity,
        inactivityMu: state.stats.inactivityMu,
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Inactivity)
