import React from 'react'

export default function IconModuleVirtualPoint({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="ponto" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1733" data-name="Retângulo 1733" width="18" height="18" fill="none" />
          </g>
          <g id="Q3_icons" data-name="Q3 icons" transform="translate(1.869 0.75)">
            <g id="Grupo_868" data-name="Grupo 868">
              <path id="Caminho_7218" data-name="Caminho 7218" d="M17.665,6.913l.9-.938a.712.712,0,0,0,0-1.05A.675.675,0,0,0,18.04,4.7a.75.75,0,0,0-.525.225l-.937.9a7.162,7.162,0,0,0-3.712-1.537V3.5h.75a.75.75,0,1,0,0-1.5h-3a.75.75,0,1,0,0,1.5h.75v.788a7.125,7.125,0,1,0,6.3,2.625ZM12.115,17a5.625,5.625,0,1,1,5.625-5.625A5.625,5.625,0,0,1,12.115,17Z" transform="translate(-4.984 -2)" fill="#24677e" />
              <path id="Caminho_7219" data-name="Caminho 7219" d="M26.125,19.375H23.5V16.75a.75.75,0,0,0-1.5,0v4.125h4.125a.75.75,0,1,0,0-1.5Z" transform="translate(-15.619 -10.75)" fill="#24677e" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
