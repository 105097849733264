import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Paper, Slide, Tooltip } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader, createHierarchy } from "../../_helpers";
import "./EditActivityModal.scss"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '100%',
    marginTop: '4px'
  },
  list: {
    border: '1px solid #D6DEEB',
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: 200
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#187dff', // Cor da borda quando está em foco
      borderWidth: '1px'
    }
  },
  paper: {
    marginTop: '10px',
  }
}));

function EditActivityModal({
  currentTracked,
  openModalEditActivity,
  userDetails,
  handleClose,
  alertActions,
  getActivitiesData,
  categories
}) {

  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const categoriesOptions = createHierarchy(categories, userDetails.language || i18n.language);
  
  const [form, setForm] = useState({
    name: currentTracked?.exhibitionName || '',
    category: currentTracked.categoryId ? categoriesOptions.find(c => c.value == currentTracked.categoryId) : ''
  });

  const [formError, setFormError] = useState({
    name: '',
    category: ''
  });

  function closeModalEditActivity() {
    handleClose()
    setForm({
      name: '',
      category: ''
    })
    setFormError({
      name: '',
      category: ''
    })
  }

  const validateForm = (form) => {

    const errors = {};

    if (!form.name) {
      errors.name = t('settings.teams.required_field')
    }

    if (!form.category || form.category.value === '') {
      errors.category = t('settings.teams.required_field')
    }

    return errors
  }


  const handleEditActivity = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const updateActivity = async () => {
    try {
      setIsSubmitting(true)

      const payload = {
        tracked_id: currentTracked.trackedId,
        category_id: form.category.value,
        label: form.name
      }

      const res = await api.put(`/settings/update-activities`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao editar a atividade')
      }

      getActivitiesData()
      closeModalEditActivity()
      alertActions.success(t('activities.edit_activity_success'))
      setIsSubmitting(false)
    } catch (error) {
      console.error(error)
      // alertActions.error(error)
      setIsSubmitting(false)
    }
  }


  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      updateActivity()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  const handleChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormEditActivity ${classes.modal}`}
        open={openModalEditActivity}
        onClose={closeModalEditActivity}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalEditActivity} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormEditActivity"}>

            <button onClick={closeModalEditActivity} className="close"><IconClose /></button>

            <form onSubmit={handleEditActivity}>
              <main>

                <header>
                  <h3>
                    {t('activities.edit_activity')}
                  </h3>
                </header>
                
                <div className="field">
                  <label>{t('activities.field_label')}</label>
                  <TextField
                    id="label_tracked"
                    value={form.name}
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                  />
                  {formError.name &&
                    <div className="error">{formError.name}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="title">{t('activities.field_category')}</label>
                  <Autocomplete
                    value={form.category}
                    onChange={(event, newValue) => {
                      setForm({ ...form, category: newValue })
                      setFormError({ ...formError, category: '' })
                    }}
                    PopperComponent={(props) => (
                      <Paper elevation={0} {...props} className={classes.paper} />
                    )}
                    renderOption={(option) => {
                      if (option.is_children === true) {
                        return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{option.label}</span>
                      }

                      return <span>{option.label}</span>
                    }}
                    getOptionLabel={(option) => option.label}
                    id="category"
                    options={(categoriesOptions && categoriesOptions.length > 0) ? categoriesOptions : []}
                    fullWidth
                    renderInput={(params) => <TextField {...params} className={classes.focused} variant="outlined" fullWidth />}
                  />
                  {formError.category &&
                    <div className="error">{formError.category}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={closeModalEditActivity}
                    >
                      {t('activities.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('activities.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditActivityModal));
