export const teamsConstants = {
    GET_TEAMS_REQUEST: '[TEAMS] GET_TEAMS_REQUEST',
    GET_TEAMS_SUCCESS: '[TEAMS] GET_TEAMS_SUCCESS',
    GET_TEAMS_FAILURE: '[TEAMS] GET_TEAMS_FAILURE',
    POST_TEAMS_REQUEST: '[TEAMS] POST_TEAMS_REQUEST',
    POST_TEAMS_SUCCESS: '[TEAMS] POST_TEAMS_SUCCESS',
    POST_TEAMS_FAILURE: '[TEAMS] POST_TEAMS_FAILURE',
    PATCH_TEAMS_REQUEST: '[TEAMS] PATCH_TEAMS_REQUEST',
    PATCH_TEAMS_SUCCESS: '[TEAMS] PATCH_TEAMS_SUCCESS',
    PATCH_TEAMS_FAILURE: '[TEAMS] PATCH_TEAMS_FAILURE',
    GET_TEAM_DETAIL_REQUEST: '[TEAMS] GET_TEAM_DETAIL_REQUEST',
    GET_TEAM_DETAIL_SUCCESS: '[TEAMS] GET_TEAM_DETAIL_SUCCESS',
    GET_TEAM_DETAIL_FAILURE: '[TEAMS] GET_TEAM_DETAIL_FAILURE',
    GET_TEAM_ACTIVITIES_REQUEST: '[TEAMS] GET_TEAM_ACTIVITIES_REQUEST',
    GET_TEAM_ACTIVITIES_SUCCESS: '[TEAMS] GET_TEAM_ACTIVITIES_SUCCESS',
    GET_TEAM_ACTIVITIES_FAILURE: '[TEAMS] GET_TEAM_ACTIVITIES_FAILURE',
    GET_TEAM_FOCUS_REQUEST: '[TEAMS] GET_TEAM_FOCUS_REQUEST',
    GET_TEAM_FOCUS_SUCCESS: '[TEAMS] GET_TEAM_FOCUS_SUCCESS',
    GET_TEAM_FOCUS_FAILURE: '[TEAMS] GET_TEAM_FOCUS_FAILURE',
    DELETE_TEAM_REQUEST: '[TEAMS] DELETE_TEAM_REQUEST',
    DELETE_TEAM_SUCCESS: '[TEAMS] DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: '[TEAMS] DELETE_TEAM_FAILURE',
    POST_TEAMS_ACTIVITIE_REQUEST: '[TEAMS] POST_TEAMS_ACTIVITIE_REQUEST',
    POST_TEAMS_ACTIVITIE_SUCCESS: '[TEAMS] POST_TEAMS_ACTIVITIE_SUCCESS',
    POST_TEAMS_ACTIVITIE_FAILURE: '[TEAMS] POST_TEAMS_ACTIVITIE_FAILURE',
    DELETE_TEAM_ACTIVITIE_REQUEST: '[TEAMS] DELETE_TEAMS_ACTIVITIE_REQUEST',
    DELETE_TEAM_ACTIVITIE_SUCCESS: '[TEAMS] DELETE_TEAMS_ACTIVITIE_SUCCESS',
    DELETE_TEAM_ACTIVITIE_FAILURE: '[TEAMS] DELETE_TEAMS_ACTIVITIE_FAILURE',
};

