import moment from 'moment';
import React from 'react';

export function TimeRuler({ data }) {

  const CurrentTime = moment().format('HH:mm')
  const initialTranslateX = 333
  const gapTranslateX = 52
  const CurrentTimeInitialTranslateX = 67
  const CurrentTimeTranslateX = 52

  function calcGapByMinutes(hour) {
    var time = hour.split(':');
    var minutes = parseInt(time[1]);

    var gapByMin = 52 / 70;

    var gap = gapByMin * minutes;

    return gap;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="85" viewBox="0 0 1248 35">
      <defs>
        <clipPath id="clip-path">
          <rect width="1194" height="12" fill="none" />
        </clipPath>
      </defs>

      <g id="Horário" transform="translate(-304 -304)">
        {Array.from(Array(24)).map((_, index) => (
          <text
            key={index}
            id={`_${index.toString().padStart(2, '0')}`}
            data-name={`${index.toString().padStart(2, '0')}`}
            transform={`translate(${initialTranslateX + (index * gapTranslateX)} 326)`}
            fill="#98acc1"
            fontSize="8"
            fontFamily="Roboto-Regular, Roboto"
          >
            <tspan style={{ fontSize: '10px' }} x="-12.441" y="0">
              {index.toString().padStart(2, '0') + ":00"}
            </tspan>
          </text>
        ))}
      </g>

      <g id="Meses" transform="translate(-304 -308)">
        <g id="Repetição_de_grade_6" data-name="Repetição de grade 6" transform="translate(332 331)" clipPath="url(#clip-path)">
          <g id="Grupo_322" data-name="Grupo 322" transform="translate(-332 -331)">
            <rect id="Retângulo_1331" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-2" data-name="Grupo 322" transform="translate(-280 -331)">
            <rect id="Retângulo_1331-2" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-2" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-3" data-name="Grupo 322" transform="translate(-228 -331)">
            <rect id="Retângulo_1331-3" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-3" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-4" data-name="Grupo 322" transform="translate(-176 -331)">
            <rect id="Retângulo_1331-4" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-4" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-5" data-name="Grupo 322" transform="translate(-124 -331)">
            <rect id="Retângulo_1331-5" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-5" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-6" data-name="Grupo 322" transform="translate(-72 -331)">
            <rect id="Retângulo_1331-6" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-6" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-7" data-name="Grupo 322" transform="translate(-20 -331)">
            <rect id="Retângulo_1331-7" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-7" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-8" data-name="Grupo 322" transform="translate(32 -331)">
            <rect id="Retângulo_1331-8" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-8" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-9" data-name="Grupo 322" transform="translate(84 -331)">
            <rect id="Retângulo_1331-9" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-9" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-10" data-name="Grupo 322" transform="translate(136 -331)">
            <rect id="Retângulo_1331-10" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-10" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-11" data-name="Grupo 322" transform="translate(188 -331)">
            <rect id="Retângulo_1331-11" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-11" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-12" data-name="Grupo 322" transform="translate(240 -331)">
            <rect id="Retângulo_1331-12" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-12" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-13" data-name="Grupo 322" transform="translate(292 -331)">
            <rect id="Retângulo_1331-13" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-13" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-13" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-14" data-name="Grupo 322" transform="translate(344 -331)">
            <rect id="Retângulo_1331-14" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-14" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-14" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-15" data-name="Grupo 322" transform="translate(396 -331)">
            <rect id="Retângulo_1331-15" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-15" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-15" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-16" data-name="Grupo 322" transform="translate(448 -331)">
            <rect id="Retângulo_1331-16" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-16" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-16" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-17" data-name="Grupo 322" transform="translate(500 -331)">
            <rect id="Retângulo_1331-17" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-17" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-17" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-18" data-name="Grupo 322" transform="translate(552 -331)">
            <rect id="Retângulo_1331-18" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-18" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-18" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-19" data-name="Grupo 322" transform="translate(604 -331)">
            <rect id="Retângulo_1331-19" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-19" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-19" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-18" data-name="Grupo 322" transform="translate(656 -331)">
            <rect id="Retângulo_1331-18" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-18" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-18" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-19" data-name="Grupo 322" transform="translate(708 -331)">
            <rect id="Retângulo_1331-19" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-19" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-19" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-20" data-name="Grupo 322" transform="translate(760 -331)">
            <rect id="Retângulo_1331-20" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-20" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-20" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-21" data-name="Grupo 322" transform="translate(812 -331)">
            <rect id="Retângulo_1331-21" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-21" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-21" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-22" data-name="Grupo 322" transform="translate(864 -331)">
            <rect id="Retângulo_1331-22" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-22" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-22" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
          <g id="Grupo_322-23" data-name="Grupo 322" transform="translate(916 -331)">
            <rect id="Retângulo_1331-23" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
            <rect id="Retângulo_1332-23" data-name="Retângulo 1332" width="1" height="4" transform="translate(349 339)" fill="#e4eaef" />
            <rect id="Retângulo_1334-23" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 339)" fill="#e4eaef" />
          </g>
        </g>
        <rect id="Retângulo_1338" data-name="Retângulo 1338" width="1248" height="1" transform="translate(304 342)" fill="#e4eaef" />
        <rect id="Retângulo_1339" data-name="Retângulo 1339" width="1248" height="1" transform="translate(304 308)" fill="#e4eaef" />
      </g>

      {CurrentTime && (
        CurrentTime.split(':')[0] === "00" ? (
          <g transform={`translate(16 30)`}>
            <text
              x="5px"
              y="6"
              fill="#187DFF"
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              {CurrentTime}
            </text>
          </g>
        ) : (
          <g
            transform={
              `translate(${(CurrentTimeInitialTranslateX + (Number(CurrentTime.split(':')[0] - 1) * CurrentTimeTranslateX) + calcGapByMinutes(CurrentTime))} 40)`
            }
          >
            <text
              x="5px"
              y="7"
              fill="#187DFF"
              style={{ fontSize: 10, fontWeight: 500 }}
            >
              {CurrentTime}
            </text>
          </g>
        )
      )}
    </svg>
  )
}
