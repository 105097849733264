import React, { useRef, useState, useEffect, useMemo, Fragment  } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux'

import { useTable, useSortBy, useExpanded } from 'react-table'

import { goalsActions } from '../../_actions';

import Moment from 'moment';

import IconInfo from '../_icons/IconInfo';
import IconHappy from '../_icons/IconHappy';
import IconOk from '../_icons/IconOk';
import IconSad from '../_icons/IconSad';
import IconExpand from '../_icons/IconExpand';
import IconReduce from '../_icons/IconReduce';


function Invitations(props) {
    const { t, i18n } = useTranslation('common');

    let invitations = props.invitations || [];
    let filter = props.filter || {};

    Moment.locale(t('date.locale'));

    const data = useMemo(() => invitations, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Code',
                accessor: 'code'
            },
            {
                Header: 'Organization',
                accessor: 'organization_name'
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
                Cell: (d) => {                      
        
                  return Moment.utc(d.value).local().startOf('seconds').fromNow()
                }
            },
        ],
        []
    );

    


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);



    return (
        <div className="box teammates">
            <div className="pageHeader">
                <h3>{t('backfective.invitations')}</h3>
                
            </div>
            <div className="dataTable invitations">
                {data.length > 0 &&
                    <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <div className="arrowDown"></div>
                                                    : <div className="arrowUp"></div>
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, i) => {
                              
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
                }

            </div>



        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
    

    }
}

function mapStateToProps(state) {

    return {
        invitations: state.backfective.invitations,
        filter: state.filter
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Invitations)
