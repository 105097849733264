
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const BefectiveTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F', // Cor de fundo personalizada
    marginRight: 0, // Espaçamento à direita
    maxWidth: '300px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

export default BefectiveTooltip;