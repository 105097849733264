import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink, Link } from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'

import CompanyInventory from '../_components/settings/company/CompanyInventory'
import CompanyInventorySkeleton from '../_components/settings/company/CompanyInventorySkeleton'


import { useTranslation, initReactI18next } from "react-i18next";

import { shareActions, teamsActions } from '../_actions';
import { settingsActions } from '../_actions';
import ShareButton from '../_components/_shared/ShareButton';
import ShareModal from '../_components/_shared/ShareModal';

function CompanyPageInventory(props) {
    const { t, i18n } = useTranslation('common');

    const { settingsActions, shareActions } = props;

    let permissions = props.permissions || [];
    let token = props.token || {};
    let loading = props.loading;
    let filter = props.filter || {};


    let path = window.location.pathname.split("/");

    const [minimumTime, setMinimumTime] = useState(500)
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)

    function closeShareModal() {
        shareActions.closeModal()
    }


    useEffect(() => {

        settingsActions.getCompanyInventory()

    }, []);

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.company') + ' | ' + t('settings.inventory');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar />

                <div className="mainFilterbar">
                    <div className="filters">
                        {(permissions.ViewCompanyInformation) &&
                            <NavLink exact to="/settings/company" activeClassName="active">
                                <button className={'settingsNav'}>{t('settings.information')}</button>
                            </NavLink>
                        }

                        {(permissions.ViewCompanyAdministrators) &&
                            <NavLink to="/settings/company/administrators" activeClassName="active">
                                <button className={'settingsNav'}>{t('settings.administrators')}</button>
                            </NavLink>
                        }

                        {(permissions.ViewCompanyFreeDays) &&
                            <NavLink to="/settings/company/daysoff" activeClassName="active">
                                <button className={'settingsNav'}>{t('settings.daysoff')}</button>
                            </NavLink>
                        }

                        {/* {(permissions.ViewCompanyInventory) &&
                            <NavLink to="/settings/company/inventory-teammates" activeClassName="active">
                                <button className={'settingsNav'}>{t('settings.inventory')}</button>
                            </NavLink>
                        } */}
                    </div>
                    <ShareButton path={path} />
                </div>

                {/* PAGE STARTS */}

                <div className="mainPage profile">
                    <div className="nocolumn">
                        <div className='tab full active'>
                            {
                                !minimumTimeElapsed || loading
                                    ? <CompanyInventorySkeleton />
                                    : <CompanyInventory />
                            }
                        </div>

                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>
            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal reports='inventory' filters={filter}/>
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        companyInformation: state.settings.companyInformation,
        loading: state.settings.loading,
        filter: state.filter,
        permissions: state.authentication.permissions,
        share: state.share
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageInventory)
