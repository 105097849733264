import { CheckSquare, CheckSquareOffset, X } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./styles.scss"
import { Checkbox, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));

export function Item({title= "",checked=false,id,saveItem,innerRef,provided,deleteItem}) {
  // id, title, due_date, checked
  const [itemEdit,setItemEdit] = useState(false)
  const { t } = useTranslation('common');
  const [checkedItem, setCheckedItem] = useState(checked);
  const [titleItem,setTitleItem] = useState(title); 

  const classes = useStyles()

  const handleChangeCheck = (event) => {
    setCheckedItem(event.target.checked);
    saveItem({id:id,title:titleItem,checked:event.target.checked})
  };
  const handleChangeTextarea= (event) => {
    setTitleItem(event.target.value);
  };
  function handleEditItem(){
    setItemEdit((value)=>{
      return !value
    })
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  function handleSaveItem(body) {
    saveItem(body)
    handleEditItem()
  }
  function handleDeleteItem(id) {
    deleteItem(id)
  }

  return (      
        <div 
          className='item'
          ref={innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className='checkBox'>
            <Checkbox {...label}
              checked={checkedItem}
              onChange={handleChangeCheck} 
              color="default" 
              disableRipple
              classes={{root:classes.root}}
              />
          </div>
          <div className='item-title'>
            {!itemEdit ? 
            <p onClick={handleEditItem}>
              {title}
            </p> 
            :
            <div className='boxTask'>
              <textarea className='item-title-input' onChange={handleChangeTextarea} value={titleItem} rows={1}></textarea>
              <div className='botton-action'>
                <div className='button-row-items'>
                  <div className='button' onClick={()=>{handleSaveItem({id:id,title:titleItem,checked:checkedItem})}}>
                  {t('modalDetailTask.save')}
                  </div>
                  <X style={{cursor:"pointer"}} size={24} color='#4F7091' onClick={()=>{
                    console.log("/clicou no icon!")
                    handleEditItem()
                  }} />
                </div>
                

              <div className='button-delete' onClick={()=>{handleDeleteItem(id)}}>
                {t('modalDetailTask.delete')}
              </div>
              </div>
            </div>               
            }
          
          </div>                     
        </div>  
      
  );
}