import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { numberToPercentString } from '../../../../_helpers';
import { useTranslation } from 'react-i18next';
import IconSearchLight from '../../../_icons/IconSearchLight';
import IconInfoFlat from '../../../_icons/IconInfoFlat';

import './styles.scss'

export function Header({
  isLoading,
  currentObjective,
  openDrawerDetailsObjective,
  openDrawerSearchFilters
}) {
  const { t } = useTranslation('common');

  const today = new Date(String(new Date().toISOString()).split('T')[0])

  const [colorStatusGoal, setColorStatusGoal] = useState('')


  useEffect(() => {

    const listColorsStatus = {
      completed: '#22BEAD',
      progress: '#FDA02B',
      planning: '#98ACC1',
      overdue: '#FF5151'
    }

    let colorStatus = listColorsStatus.planning

    if (currentObjective) {
      let goalProgress = currentObjective?.percentProgressGeneral || 0
      let goalDateEnd = new Date(currentObjective?.date_end)
      if (goalProgress > 0 && goalProgress < 100 && !(today > goalDateEnd)) {
        colorStatus = listColorsStatus.progress
      } else if (goalProgress >= 100.00) {
        colorStatus = listColorsStatus.completed
      } else if (goalProgress > 0 && goalProgress < 100 && today > goalDateEnd) {
        colorStatus = listColorsStatus.overdue
      }
      setColorStatusGoal(colorStatus)

    }

  }, [currentObjective])

  return (
    <>
      <header className='objectives-details-header'>
        {
          isLoading ? (
            <div className='boxResumeObjective'>

              <div className='circularProgressGeneral'>
                <Skeleton variant="circle" width={70} height={70} />
              </div>

              <div className='boxInfoResumeObjective'>
                <h4 className='titleObjective'>
                  <Skeleton variant="text" />
                </h4>
                <div className='gridStatesObjective'>
                  <div className='colState'>
                    <Skeleton variant="text" width={120} />
                  </div>

                  <div className='colState'>
                    <Skeleton variant="text" width={120} />
                  </div>

                  <div className='colState'>
                    <Skeleton variant="text" width={120} />
                  </div>

                  <div className='colState'>
                    <Skeleton variant="text" width={120} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='boxResumeObjective'>

              <div className='circularProgressGeneral'>
                <CircularProgressbarWithChildren
                  value={currentObjective?.percentProgressGeneral}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: colorStatusGoal,
                    trailColor: "#eee",
                  })}>
                  <span style={{ fontSize: 14, fontWeight: 500, color: '#4F7091' }}>
                    {`${numberToPercentString(currentObjective?.percentProgressGeneral)}`}
                  </span>
                </CircularProgressbarWithChildren>
              </div>

              <div className='boxInfoResumeObjective'>
                <h4 className='titleObjective'>{currentObjective?.name}</h4>
                <div className='gridStatesObjective'>
                  <div className='colState'>
                    <div style={{ backgroundColor: '#22BEAD' }}></div>
                    <div>{t('goals.activities_info_completed')}:</div>
                    <div>{currentObjective?.completed}</div>
                  </div>

                  <div className='colState'>
                    <div style={{ backgroundColor: '#FDA02B' }}></div>
                    <div>{t('goals.activities_info_progress')}:</div>
                    <div>{currentObjective?.progress}</div>
                  </div>

                  <div className='colState'>
                    <div style={{ backgroundColor: '#98ACC1' }}></div>
                    <div>{t('goals.activities_info_planned')}:</div>
                    <div>{currentObjective?.planned}</div>
                  </div>

                  <div className='colState'>
                    <div style={{ backgroundColor: '#FF5151' }}></div>
                    <div>{t('goals.activities_info_late')}:</div>
                    <div>{currentObjective?.late}</div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className='boxButtonHeader'>
          <button type='button' onClick={openDrawerSearchFilters}>
            <IconSearchLight />
          </button>
          <button type='button' onClick={openDrawerDetailsObjective}>
            <IconInfoFlat />
          </button>
        </div>
      </header>
    </>

  );
}
