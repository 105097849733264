import React from 'react';

const IconSite = props => {
    return (
        
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">

            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 0.667)">
                <path id="Caminho_48" data-name="Caminho 48" d="M9.333,2a7.3,7.3,0,1,0,5.2,2.138A7.333,7.333,0,0,0,9.333,2ZM14.7,6.667H13.067a9.267,9.267,0,0,0-1-2.667A6.167,6.167,0,0,1,14.7,6.667Zm.633,2.667a5.833,5.833,0,0,1-.167,1.333h-1.9c.033-.433.067-.867.067-1.333S13.3,8.433,13.267,8h1.9a5.833,5.833,0,0,1,.167,1.333Zm-12,0A5.833,5.833,0,0,1,3.5,8H5.4c-.033.433-.067.867-.067,1.333s.033.9.067,1.333H3.5a5.833,5.833,0,0,1-.167-1.333Zm3.333,0c0-.467.033-.9.067-1.333H8.667v2.667H6.733C6.7,10.233,6.667,9.8,6.667,9.333ZM10,3.567A3.9,3.9,0,0,1,11,4.8a7.233,7.233,0,0,1,.7,1.867H10Zm-1.333,0v3.1h-1.7a7.233,7.233,0,0,1,.7-1.867,3.9,3.9,0,0,1,1-1.233Zm0,8.433v3.1a3.9,3.9,0,0,1-1-1.233A7.233,7.233,0,0,1,6.967,12ZM10,15.1V12h1.7a7.233,7.233,0,0,1-.7,1.867A3.9,3.9,0,0,1,10,15.1Zm0-4.433V8h1.933c.033.433.067.867.067,1.333s-.033.9-.067,1.333ZM6.6,4a9.267,9.267,0,0,0-1,2.667H3.967A6.167,6.167,0,0,1,6.6,4ZM3.967,12H5.6a9.267,9.267,0,0,0,1,2.667A6.167,6.167,0,0,1,3.967,12Zm8.1,2.667a9.267,9.267,0,0,0,1-2.667H14.7A6.167,6.167,0,0,1,12.067,14.667Z" transform="translate(-2 -2)" fill="#187dff"/>
            </g>
        </svg>

    )
}

export default IconSite;