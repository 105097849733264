import React from 'react'
import styles from './HeaderProfiles.module.scss'
import { useTranslation } from 'react-i18next';

export default function HeaderProfiles() {
  const { t } = useTranslation('common');

  return (
    <header className={styles.header}>
      <div className={styles.containerInfo}>
        <h6 className={styles.title}>{t('settings.profiles.title')}</h6>
      </div>
    </header>
  )
}
