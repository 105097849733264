import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { Form } from '../../_components/rooms_page/create/Form';
import style from './styles.module.scss';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';


function RoomCreate(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, teammates, teammatesActions, alertActions } = props
  const [roomToEdit, setRoomToEdit] = useState(null)
  const [loading, setLoading] = useState(false);
  const [optionsGroups, setOptionsGroups] = useState([])
  const { id } = useParams();

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getRoomById = async (withLoading=true) => {
    try {
      if(withLoading){
        setLoading(true)
      }
      const { data } = await api.get(`/rooms/detail/${id}`, { headers: authHeader() })
      setRoomToEdit(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const getGroups = async () => {
    try {
      const resResponsibles = await api.get(`/goals/get-groups?organization_id=${userDetails.organizationId}`, {
				headers: authHeader()
			})
      setOptionsGroups(resResponsibles?.data)
      return resResponsibles?.data
    } catch (error) {
      console.log(error)  
    }

  }

  useEffect(() => {

    if (id) {
      getRoomById()
    } else {
      setLoading(false)
    }
    teammatesActions.getTeammates()
    getGroups()
  }, [])

  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          {
            !loading && <Form teammates={teammates} alertActions={alertActions} userDetails={userDetails} roomToEdit={roomToEdit} optionsGroups={optionsGroups} getDetailSpace={getRoomById}/>
          }
        </div>
        {/* PAGE ENDS */}

      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreate)