import React, { useState, useEffect } from 'react';
import { api } from '../../_helpers/api'
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { isMobile } from 'react-device-detect';

import { statsActions, teammatesActions, teamsActions, alertActions, usersActions, settingsActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import GoalsModalForm from '../../_components/goals/ModalForm';
import IconClose from '../../_components/_icons/IconClose';
import DrawerMovimentations from '../../_components/_shared/DrawerMovimentations';

import { CardDividerProgressBarSkeleton } from '../../_components/goals/CardDividerProgressBarSkeleton';
import { ObjectivesFooterSkeleton } from '../../_components/goals/ObjectivesFooterSkeleton';
import { ObjectivesTempRulerSkeleton } from '../../_components/goals/ObjectivesTempRulerSkeleton';
import { CardDividerProgressBar } from '../../_components/goals/CardDividerProgressBar';
import { ObjectivesTempRuler } from '../../_components/goals/ObjectivesTempRuler'

import { ObjectivesFooter } from '../../_components/goals/ObjectivesFooter';

import './styles.scss'
import { authHeader } from '../../_helpers';
import GoalsCardsView from '../../_components/goals/GoalsPage/GoalsCardsView';
import DrawerFiltersGoals from '../../_components/_shared/DrawerFiltersGoals';
import moment from 'moment';
import MenuMU from '../../_components/_shared/MenuMU';


function GoalsPage(props) {
	const { t } = useTranslation('common');

	let path = window.location.pathname.split("/") || [];

	const { teammatesActions, teamsActions, companyInformation } = props;
	const { alertActions, userDetails, settingsActions, usersActions, users } = props;

	const [drawer, setDrawer] = useState(false)
	const [isShowingDrawerMovimentations, setDrawerMovimentations] = useState(false)
	const [openModalNewGoal, setOpenModalNewGoal] = useState(false);
	const [changeViewer, setChangeViewer] = useState(false)
	const [isLoadingGoalsData, setIsLoadingGoalsData] = useState(true)
	const [isLoadingRuler, setIsLoadingRuler] = useState(false)
	const [goalsData, setGoalsData] = useState({})
	const [rulerData, setRulerData] = useState({})
	const [drawerFiltersGoals, setDrawerFiltersGoals] = useState(false)
	const [goals, setGoals] = useState([])
	const [goalsDataCards, setGoalsDataCards] = useState({})
	const [teammates, setTeammates] = useState([])
	const token = authHeader()
	const [selectedMenu, setSelectedMenu] = useState(moment().year())
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	function toggleMenu(type) {
		if (type == 'main') {
			setIsMenuOpen(!isMenuOpen);
		} else {
			if (isMobile) {
				setIsMenuOpen(false)
			}
		}
	};

	function toggleFromMenu(value) {

		if (value == 'closed') {
			setIsMenuOpen(true);
		} else {
			setIsMenuOpen(false)
		}
	}

	function closeDrawer() {
		setDrawer(false)
		setDrawerMovimentations(false)
	}

	function openDrawerMovimentations(data) {
		setDrawer(true)
		setDrawerMovimentations(true)
	}

	const goalsInfo = {
		data: [
			{
				total: goalsData.overallGoal?.goalDone,
				type: 'completed',
				percent: goalsData.overallGoal?.percentGoalDone,
				totalGeneral: goalsData.overallGoal?.totalGoal
			},
			{
				total: goalsData.overallGoal?.goalRunning,
				type: 'progress',
				percent: goalsData.overallGoal?.percentGoalRunning,
				totalGeneral: goalsData.overallGoal?.totalGoal
			},
			{
				total: goalsData.overallGoal?.goalPlanning,
				type: 'planned',
				percent: goalsData.overallGoal?.percentGoalPlanning,
				totalGeneral: goalsData.overallGoal?.totalGoal
			},
			{
				total: goalsData.overallGoal?.goalLate,
				type: 'late',
				percent: goalsData.overallGoal?.percentGoalLate,
				totalGeneral: goalsData.overallGoal?.totalGoal
			}
		],
	}

	const keyResultsInfo = {
		data: [
			{
				total: goalsData.overallKR?.krDone,
				type: 'completed',
				percent: goalsData.overallKR?.percentKrDone,
				totalGeneral: goalsData.overallKR?.totalKr
			},
			{
				total: goalsData.overallKR?.krRunning,
				type: 'progress',
				percent: goalsData.overallKR?.percentKrRunning,
				totalGeneral: goalsData.overallKR?.totalKr
			},
			{
				total: goalsData.overallKR?.krPlanning,
				type: 'planned',
				percent: goalsData.overallKR?.percentKrPlanning,
				totalGeneral: goalsData.overallKR?.totalKr
			},
			{
				total: goalsData.overallKR?.krLate,
				type: 'late',
				percent: goalsData.overallKR?.percentKrLate,
				totalGeneral: goalsData.overallKR?.totalKr
			}
		],
	}

	const getGoalsDashboardData = async (loadingRuler = false, filterYear = moment().year()) => {
		try {
			if (loadingRuler) {
				setIsLoadingRuler(true)
			} else {
				setIsLoadingGoalsData(true)
			}

			const response = await api.get(`/goals/dashboard?organization_id=${userDetails?.organizationId}&year_filter=${filterYear}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setGoalsData(response.data)
			
      usersActions.refresh(userDetails);
			if (loadingRuler) {
				setIsLoadingRuler(false)
			} else {
				setIsLoadingGoalsData(false)
			}

		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals.errors.get_goals'))
		}
	}


	// const getRulerData = async (filterYear = '') => {
	// 	try {

	// 		setIsLoadingRuler(true)
	// 		const response = await api.get(`/goals/ruler?organization_id=${userDetails?.organizationId}&year_filter=${filterYear}`, {
	// 			headers: {
	// 				'Authorization': token.Authorization
	// 			}
	// 		})
	// 		setRulerData(response.data)
	// 		setIsLoadingRuler(false)
	// 	} catch (error) {
	// 		console.log(error.response)
	// 		//alertActions.error(t('goals.errors.get_goals'))
	// 	}
	// }

	const getGoalsResponsibleData = async () => {
		try {
			const response = await api.get(`/goals/get-responsibles?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			if (response.status === 200) {
				setTeammates(response.data)
			}
		} catch (error) {
			console.log(error.response)
		}
	}

	const getGoalsCardsInfo = async (filterYear = '') => {
		try {
			const response = await api.get(`/goals/cardsinfo?organization_id=${userDetails?.organizationId}&year_filter=${filterYear}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setGoalsDataCards(response.data)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals.errors.get_goals'))
		}
	}

	const getGoals = async () => {
		try {
			const response = await api.get(`/goals/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setGoals(response.data?.goals)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals.errors.get_goals'))
		}
	}


	const updateDashboardInformations = async () => {
		try {
			const resp1 = await api.get(`/goals/dashboard?organization_id=${userDetails?.organizationId}&year_filter=${selectedMenu}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setGoalsData(resp1.data)

			const resp2 = await api.get(`/goals/cardsinfo?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setGoalsDataCards(resp2.data)

      usersActions.refresh(userDetails);
			// const resp3 = await api.get(`/goals/ruler?organization_id=${userDetails?.organizationId}&year_filter=${selectedMenu}`, {
			// 	headers: {
			// 		'Authorization': token.Authorization
			// 	}
			// })
			// setRulerData(resp3.data)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals.errors.get_goals'))
		}

	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.goals');

		teammatesActions.getTeammates()
		teamsActions.getTeams()
		settingsActions.getCompanyInformation()

		// getRulerData()
		getGoalsDashboardData()
		getGoalsCardsInfo()
		getGoals()
		getGoalsResponsibleData()
		usersActions.getAll()
	}, []);

	// const [yearFilter, setYearFilter] = useState(moment().format('yyyy'))

	// useEffect(() => {
	// 	console.log(yearFilter)
	// 	getGoalsDashboardData(yearFilter)
	// 	getGoalsCardsInfo(yearFilter)
	// }, [yearFilter])

	async function handleChangeMenu(menu) {
		setSelectedMenu(menu)
		await getGoalsDashboardData(true, menu)
	}

	return (
		<React.Fragment>


			{path.includes('productivity') && (
				<>
					<div className={isMenuOpen ? "menuToggle open" : "menuToggle"}
						onClick={() => {
							toggleMenu('main')
						}}>
						<i className="arrow"></i>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<MenuMU menuStatus={isMenuOpen} toggle={toggleFromMenu} />
				</>
			)}

			<section className="mainContent">


				<Toolbar
					companyPurpose={companyInformation?.purpose}
				/>


				<Filterbar
					filterWho={'disabled'}
					filterWhen={'disabled'}
					filterWhat={'disabled'}
					// filterPeriod={'single'}
					goals={{ total: goalsData.goalProcess?.length }}
					cardView={changeViewer ? true : false}
					actionsGoals={true}
					openDrawerMovimentations={(data) => {
						openDrawerMovimentations(data)
					}}
					changeViewerObjectivies={setChangeViewer}
					setOpenModalNewGoal={setOpenModalNewGoal}
					setDrawerFiltersGoals={setDrawerFiltersGoals}
					companyPurpose={changeViewer ? companyInformation?.purpose : ''}
				/>

				{!changeViewer ? (
					<main className='containerObjectiveMain'>
						{/* <div className='boxFilterYears'>
							{
								goalsData?.goalsYears?.length > 1
									&&
									<select name="value_period" id="value_period" value={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
										{goalsData?.goalsYears?.map((item, index) => (
											<option key={index} value={item?.year}>{item?.year}</option>
										))}
									</select>
								}
						</div> */}
						<div className='boxRulerTop'>
							{(isLoadingGoalsData || isLoadingRuler) ? (
								<ObjectivesTempRulerSkeleton />
							) : (
								<ObjectivesTempRuler
									purpose={companyInformation?.purpose}
									goalsData={goalsData}
									timePast={goalsData?.timePast}
									timeMissing={goalsData?.timeMissing}
									generalProgress={goalsData?.generalProgress}
									selectedMenu={selectedMenu}
									handleChangeMenu={handleChangeMenu}
								/>
							)}
						</div>

						<div className='boxContainerCardsCenter'>
							<div className='col-left'>
								<div className='containerObjectivesAndKeyResults table-overlay'>
									{isLoadingGoalsData ? (
										<>
											<CardDividerProgressBarSkeleton />

											<CardDividerProgressBarSkeleton />
										</>
									) : (
										<>
											<CardDividerProgressBar data={goalsInfo} title='goals.title' />

											<CardDividerProgressBar data={keyResultsInfo} title='keyresults.title' />
										</>
									)}

								</div>

								<div>
									{isLoadingGoalsData ? (
										<ObjectivesFooterSkeleton />
									) : (
										<ObjectivesFooter
											goalsData={goalsData?.goalProcess}
											krsData={goalsData?.kRProcess}
											colabsData={goalsData?.colab_stats}
											teamsData={goalsData?.team_stats}
											userDetails={userDetails}
											updateDashboardInfo={updateDashboardInformations}
											teammates={teammates}
										/>
									)}

								</div>
							</div>

							{/* <div className='col-right'>
								{isLoadingGoalsData ? (
									<RightContentSkeleton />
								) : (
									<RightContent colabsData={goalsData?.colab_stats} teamsData={goalsData?.team_stats} />
								)}
							</div> */}

						</div>

					</main>
				) : (
					<GoalsCardsView alertActions={alertActions} userDetails={userDetails} goalsDataCards={goalsDataCards} />
				)}

			</section>

			<GoalsModalForm
				openModalNewGoal={openModalNewGoal}
				setOpenModalNewGoal={setOpenModalNewGoal}
				userDetails={userDetails}
				teammates={teammates}
				alertActions={alertActions}
				updateDashboardInfo={updateDashboardInformations}
			/>

			<div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
				setDrawer(false)
			}}></div>

			<section className={'drawer drawer400' + (drawer ? ' open' : '')}>
				<button onClick={() => {
					setDrawer(false)
				}} className="close"><IconClose /></button>

				{isShowingDrawerMovimentations &&
					<DrawerMovimentations keyResults={goalsData?.kRProcess} users={users} closeDrawer={() => {
						closeDrawer()
					}} teammates={teammates} />
				}
			</section>

			{/* Start Drawer Search Filters */}
			<div
				className={'overlay' + (drawerFiltersGoals ? ' open' : '')}
				onClick={() => { setDrawerFiltersGoals(false) }}>

			</div>
			<section
				className={'drawer drawer400' + (drawerFiltersGoals ? ' open' : '')}
			>
				<button
					className="close"
					onClick={() => { setDrawerFiltersGoals(false) }}
				>
					<IconClose />
				</button>

				{drawerFiltersGoals &&
					<DrawerFiltersGoals
						setGoalsData={setGoalsData}
						userDetails={userDetails}
						getGoalsDashboardData={getGoalsDashboardData}
						setIsLoadingGoalsData={setIsLoadingGoalsData}
						getGoalsCardsInfo={getGoalsCardsInfo}
						setGoalsDataCards={setGoalsDataCards}
					/>
				}
			</section>
			{/* End Drawer Search Filters */}



		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		teammatesActions: bindActionCreators(teammatesActions, dispatch),
		teamsActions: bindActionCreators(teamsActions, dispatch),
		statsActions: bindActionCreators(statsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch),
		settingsActions: bindActionCreators(settingsActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teammates: state.teammates.payload,
		loadingStat: state.stats.loading,
		share: state.share,
		userDetails: state.authentication.userDetails,
		users: state.users.items,
		companyInformation: state.settings.companyInformation
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsPage)
