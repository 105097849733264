import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./styles.scss"
import { IconButton, Tooltip } from '@mui/material';
import { FormControl, InputLabel, MenuItem,Grid, OutlinedInput, Select, Typography, useTheme, Drawer, Box } from '@material-ui/core';
import { CopySimple, Minus, Plus } from 'phosphor-react';
import { authHeader } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { WeekDays } from '../WeekDays';
import { Close } from '@material-ui/icons';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 90,
    },
  },
};

export function WeekDayFull({language="en",team_id}) {
  
  const { t, i18n } = useTranslation('common');
  const [workingHours,setWorkingHours] = useState([])
  const [showDrower, setShowDrower] = useState(false)

  const getWorkingHours = async () => {
    try {      
      const response = await api.get(`/checkin/working-hours?group_id=${team_id}`, 
      { headers: authHeader() });
      
      if(response.status === 200){
        setWorkingHours(response.data)
      }
    } catch (e) {
      console.log(e)
      
    }
  }
  const handleDayWeek = async (day_week) => {
    try {
      
      const response = await api.post(`/checkin/working-hours`,
      {
        group_id:team_id, 
        day_week:day_week

      }, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  }
  const createWorkingHourPeriod = async (working_hour_id) => {
    try {
      
      const response = await api.post(`/checkin/working-hours/periods`,
      {
        working_hour_id:working_hour_id

      }, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 

  const updateWorkingHourPeriod = async (day_week_id,period_id,start,end,ref) => {
    try {
      // day_week_id,period_id,start,new_value
      const response = await api.put(`/checkin/working-hours/periods`,
      {
        day_week_id:day_week_id,
        period_id:period_id,
        start:start,
        end:end,
        ref:ref
      }, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 
  const handleDeleteWorkingPeriod = async (id) => {
    try {
      
      const response = await api.delete(`/checkin/working-hours/periods/${id}`, 
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  } 

  const handleCopyToAll = async (group_id) => {
    
    try {      
      const response = await api.patch(`/checkin/working-hours/copy-to-all`, 
      {
        group_id:group_id
      },
      { headers: authHeader() });
      
      if(response.status === 200){        
        getWorkingHours()
      }
    } catch (e) {
      console.log(e)
      
    }
  }
  useEffect(() => {
    getWorkingHours()
  }, [])

  return (
    <Fragment>
      <Grid item xs={12} style={{display:"flex", justifyContent:"space-between"}} alignItems={'center'}>
        <Box>
          <Typography variant='subtitle1' fontWeight={500}>{t('checkin.settings_form.field.work_time')}</Typography>
          <Typography variant='caption'>{t('checkin.settings_form.help.work_time')}</Typography>
        </Box>
        <Box display={"flex"} style={{gap:"10px"}}  alignItems={"center"}>          
          <WeekDays
            language={language}
            workingHours={workingHours}
            team_id={team_id}
          />
          <Box className="btn-work-time" width={334} height={20} padding={"16px 32px 16px 14px"} border={"solid rgb(208, 208, 208) 1px"} borderRadius={"4px"} onClick={() => setShowDrower(true)}>
            <Typography variant='' fontWeight={500}>Personalizado</Typography>
          </Box>   
        </Box>
      </Grid>
          
        
      {showDrower && (
            <Drawer
              anchor="right"
              open={showDrower}
              onClose={() => setShowDrower(false)}
              PaperProps={{
                style: {
                  padding: '0px 12px',
                  width: '450px'
                }
              }}
            >
              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"90%",alignSelf:"center", position: 'absolute', right: 4, top: 4 }}>
                <Typography variant='subtitle1' fontWeight={500}>Horario de trabalho Personalizado!</Typography>
                <IconButton aria-label="close" onClick={() => setShowDrower(false)}>
                  <Close fontSize="medium" />
                </IconButton>
              </div>

              <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" gap={"15px"}>
                <WeekDays
                  language={language}
                  workingHours={workingHours}
                  showWorkTime={true}
                  isClickAble={true}
                  team_id={team_id}
                  handleDayWeek={handleDayWeek}
                  createWorkingHourPeriod={createWorkingHourPeriod}
                  updateWorkingHourPeriod={updateWorkingHourPeriod}
                  handleDeleteWorkingPeriod={handleDeleteWorkingPeriod}
                  handleCopyToAll={handleCopyToAll}
                />
                
              </Box>

            </Drawer>
          )}
    </Fragment>
    
  );
}