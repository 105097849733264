import React from 'react';

const IconClock = props => {
  return (

    <svg id="clock" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1378" data-name="Retângulo 1378" width="16" height="16" fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons" transform="translate(2.077 0.833)">
        <g id="Grupo_378" data-name="Grupo 378">
          <path id="Caminho_276" data-name="Caminho 276" d="M19.074,7.458l1-1.042a.792.792,0,0,0,0-1.167A.75.75,0,0,0,19.491,5a.833.833,0,0,0-.583.25l-1.042,1a7.958,7.958,0,0,0-4.125-1.708V3.667h.833a.833.833,0,0,0,0-1.667H11.241a.833.833,0,0,0,0,1.667h.833v.875a7.917,7.917,0,1,0,7,2.917ZM12.907,18.667a6.25,6.25,0,1,1,6.25-6.25,6.25,6.25,0,0,1-6.25,6.25Z" transform="translate(-4.984 -2)" fill="#22bead" />
          <path id="Caminho_277" data-name="Caminho 277" d="M26.583,19.75H23.667V16.833a.833.833,0,1,0-1.667,0v4.583h4.583a.833.833,0,0,0,0-1.667Z" transform="translate(-14.91 -10.167)" fill="#22bead" />
        </g>
      </g>
    </svg>
  )
}

export default IconClock;