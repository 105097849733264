import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CardEvent from './CardEvent';

import './styles.scss';

const ListEventsCalendar = ({ eventsList, date, infoUser, accountIsActive, getActiveAccountEvents, platform="google" }) =>{
  
  return (
    <div className='container-card-events'>
      {eventsList.map((event) => (
        <CardEvent 
          event={event} 
          key={event.event_id} 
          date={date} 
          infoUser={infoUser} 
          accountIsActive={accountIsActive}
          getActiveAccountEvents={getActiveAccountEvents}
          platform={platform}
        />
      ))}
    </div>
  );
}

function mapStateToProps(state) {

  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListEventsCalendar));