import {historicalConstants} from '../_constants';
import {historicalService} from '../_services';
import {alertActions} from '.';

export const historicalActions = {
    getHistoricalSummary,
    getHistoricalTeammate,
    getHistoricalHourActivities
};


function getHistoricalSummary(startDate, endDate, teams = null, teammates = null) {
    return dispatch => {
        dispatch(request());

        historicalService.getHistoricalSummary(startDate, endDate, teams, teammates)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: historicalConstants.GET_HISTORICAL_SUMMARY_REQUEST}
    }

    function success(payload) {
        return {type: historicalConstants.GET_HISTORICAL_SUMMARY_SUCCESS, payload}
    }

    function failure(error) {
        return {type: historicalConstants.GET_HISTORICAL_SUMMARY_FAILURE, error}
    }
}

function getHistoricalTeammate(day, teammateId) {
    return dispatch => {
        dispatch(request());

        historicalService.getHistoricalTeammate(day, teammateId)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: historicalConstants.GET_HISTORICAL_TEAMMATEDAY_REQUEST}
    }

    function success(payload) {
        return {type: historicalConstants.GET_HISTORICAL_TEAMMATEDAY_SUCCESS, payload}
    }

    function failure(error) {
        return {type: historicalConstants.GET_HISTORICAL_TEAMMATEDAY_FAILURE, error}
    }
}

function getHistoricalHourActivities(hour, teammateId) {
    return dispatch => {
        dispatch(request());

        historicalService.getHistoricalHourActivities(hour, teammateId)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_REQUEST}
    }

    function success(payload) {
        return {type: historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_SUCCESS, payload}
    }

    function failure(error) {
        return {type: historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_FAILURE, error}
    }

}
