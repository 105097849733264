import React from 'react';

const IconGoals = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
          <rect id="Retângulo_1433" data-name="Retângulo 1433" width="24" height="24" fill="none"/>
          <rect id="Retângulo_1434" data-name="Retângulo 1434" width="24" height="24" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(3.333 0.821)">
          <path id="Caminho_353" data-name="Caminho 353" d="M17.917,16.867l-1.667-1.625a.875.875,0,0,1-.083-1.125.792.792,0,0,1,1.25-.083L18.5,15.117l2.75-2.75a.833.833,0,0,1,1.167,1.167l-3.333,3.333a.792.792,0,0,1-1.167,0Z" transform="translate(-12.667 -7.937)" fill="#cedee3"/>
          <path id="Caminho_354" data-name="Caminho 354" d="M21.332,8.649a6.667,6.667,0,1,0-10.417,5.5v5.292a.875.875,0,0,0,.833.875.625.625,0,0,0,.458-.167l2.458-1.917,2.458,1.917a.625.625,0,0,0,.458.167.875.875,0,0,0,.833-.875V14.149a6.667,6.667,0,0,0,2.917-5.5Zm-11.667,0a5,5,0,1,1,5,5A5,5,0,0,1,9.666,8.649Zm7.083,9.083-1.833-1.417a.375.375,0,0,0-.5,0l-1.833,1.417v-2.75a6.677,6.677,0,0,0,4.167,0Z" transform="translate(-7.998 -1.97)" fill="#cedee3"/>
        </g>
      </svg>    

    )
}

export default IconGoals;