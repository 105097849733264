import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { settingsActions } from '../../../_actions';

import IconOrganization from '../../_icons/IconOrganization'
import IconTeam from '../../_icons/IconTeam'
import IconTeammate from '../../_icons/IconTeammate'
import IconFolders from '../../_icons/IconFolders'
import IconAdd from '../../_icons/IconAdd'
import IconExpand from '../../_icons/IconExpand'

import Moment from 'moment'

function CompanyDaysoff(props) {

	const { t } = useTranslation('common');

	const { settingsActions, filter } = props;

	const [teamsListHTML, setTeamsList] = useState([])
	const [organizationDays, setOrganizationDays] = useState(0)
	const [organizationTeams, setOrganizationTeams] = useState(0)
	const [organizationTeammates, setOrganizationTeammates] = useState(0)
	const [organizationReason, setOrganizationReason] = useState('')
	const [organizationNext, setOrganizationNext] = useState('')

	Moment.locale(t('date.locale'))

	function setTeams(daysoff) {

		if (!daysoff) return false

		let teamsList = []

		setOrganizationDays(daysoff.organization?.days)
		setOrganizationTeams(daysoff.organization.days_teams)
		setOrganizationTeammates(daysoff.organization.days_teammates)

		if (daysoff.organization.absences[0]) {

			setOrganizationReason(daysoff.organization.absences[0].reason)
			setOrganizationNext(Moment.utc(daysoff.organization.absences[0].next).format(t('date.mm/dd/yyyy')))
		}


		for (let t0 of daysoff.teams) {

			if (!t0?.ancestry) {

				let t0Total = parseInt(t0?.days) + parseInt(t0.days_teammates) + parseInt(organizationDays)

				let next = t0.next
				if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
				else next = ''

				teamsList.push(<li key={t0.id} id={"team" + t0.id} className={"t0 team" + t0.id}>
					<div className="name"><IconFolders /> {t0.name}</div>
					<div className="daysoff">{t0Total}</div>
					<div className="organizationDays">{organizationDays}</div>
					<div className="teamsDays">{t0?.days || 0}</div>
					<div className="teammatesDays">{t0.days_teammates || 0}</div>
					<div className="next">{next}</div>
					<div className="reason">{t0.reason}</div>
					<div className="actions">
						{daysoff.teams.find(x => x?.ancestry == t0.id) &&
							<button className="expand" onClick={() => {
								expand(t0.id, "t1")
							}}><IconExpand /></button>
						}
						<button className="add" onClick={() => {
							add(t0.id, 'Group', t0.name, t0.teammates)
						}}><IconAdd /></button>
					</div>
				</li>)
				let p0 = t0.id

				for (let teammate of daysoff.teammates) {
					if (teammate.team_id == p0) {

						let tmTotal = parseInt(t0?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
						let tmNext = ''
						if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

						teamsList.push(<li key={"tm" + teammate.id} id={"teammate" + teammate.id}
							className={"t0 tm team" + t0.id}>
							<div className="name"> {teammate.name || teammate.username}</div>
							<div className="daysoff">{tmTotal}</div>
							<div className="organizationDays">0</div>
							<div className="teamsDays">{t0?.days || 0}</div>
							<div className="teammatesDays">{teammate?.days || 0}</div>
							<div className="next">{tmNext}</div>
							<div className="reason">{teammate.reason}</div>
							<div className="actions">
								<button className="add" onClick={() => {
									add(teammate.id, 'MachineUser', teammate.name)
								}}><IconAdd /></button>
							</div>
						</li>)
					}

				}

				for (let t1 of daysoff.teams) {
					if (t1?.ancestry == p0) {

						let t1Total = parseInt(t1?.days) + parseInt(t1.days_teammates) + parseInt(organizationDays)

						let next = t1.next
						if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
						else next = ''

						teamsList.push(<li key={t1.id} id={"team" + t1.id} className={"t1 team" + p0 + " team" + t1.id}>
							<div className="name"><IconFolders /> {t1.name}</div>
							<div className="daysoff">{t1Total}</div>
							<div className="organizationDays">{organizationDays}</div>
							<div className="teamsDays">{t1?.days || 0}</div>
							<div className="teammatesDays">{t1.days_teammates || 0}</div>
							<div className="next">{next}</div>
							<div className="reason">{t1.reason}</div>
							<div className="actions">
								{daysoff.teams.find(x => x?.ancestry == t1.id) &&
									<button className="expand" onClick={() => {
										expand(t1.id, "t2")
									}}><IconExpand /></button>
								}
								<button className="add" onClick={() => {
									add(t1.id, 'Group', t1.name, t1.teammates)
								}}><IconAdd /></button>
							</div>
						</li>)
						let p1 = t1.id

						for (let teammate of daysoff.teammates) {

							if (teammate.team_id == p1) {

								let tmTotal = parseInt(t1?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
								let tmNext = ''
								if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

								teamsList.push(<li key={"tm" + teammate.id} id={"teammate" + teammate.id}
									className={"t1 tm team" + p0 + " team" + t1.id}>
									<div className="name"> {teammate.name || teammate.username}</div>
									<div className="daysoff">{tmTotal}</div>
									<div className="organizationDays">0</div>
									<div className="teamsDays">{t1?.days || 0}</div>
									<div className="teammatesDays">{teammate?.days || 0}</div>
									<div className="next">{tmNext}</div>
									<div className="reason">{teammate.reason}</div>
									<div className="actions">
										<button className="add" onClick={() => {
											add(teammate.id, 'MachineUser', teammate.name)
										}}><IconAdd /></button>
									</div>
								</li>)
							}

						}

						for (let t2 of daysoff.teams) {
							if (t2?.ancestry == p1) {

								let t2Total = parseInt(t2?.days) + parseInt(t2.days_teammates) + parseInt(organizationDays)

								let next = t2.next
								if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
								else next = ''

								teamsList.push(<li key={t2.id} id={"team" + t2.id}
									className={"t2 team" + p1 + " team" + t2.id}>
									<div className="name"><IconFolders /> {t2.name}</div>
									<div className="daysoff">{t2Total}</div>
									<div className="organizationDays">{organizationDays}</div>
									<div className="teamsDays">{t2?.days || 0}</div>
									<div className="teammatesDays">{t2.days_teammates || 0}</div>
									<div className="next">{next}</div>
									<div className="reason">{t2.reason}</div>
									<div className="actions">
										{daysoff.teams.find(x => x?.ancestry == t2.id) &&
											<button className="expand" onClick={() => {
												expand(t2.id, "t3")
											}}><IconExpand /></button>
										}
										<button className="add" onClick={() => {
											add(t2.id, 'Group', t2.name, t2.teammates)
										}}><IconAdd /></button>
									</div>
								</li>)
								let p2 = t2.id

								for (let teammate of daysoff.teammates) {

									if (teammate.team_id == p2) {

										let tmTotal = parseInt(t2?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
										let tmNext = ''
										if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

										teamsList.push(<li key={"tm" + teammate.id} id={"teammate" + teammate.id}
											className={"t2 tm team" + p1 + " team" + t2.id}>
											<div className="name"> {teammate.name || teammate.username}</div>
											<div className="daysoff">{tmTotal}</div>
											<div className="organizationDays">0</div>
											<div className="teamsDays">{t2?.days || 0}</div>
											<div className="teammatesDays">{teammate?.days || 0}</div>
											<div className="next">{tmNext}</div>
											<div className="reason">{teammate.reason}</div>
											<div className="actions">
												<button className="add" onClick={() => {
													add(teammate.id, 'MachineUser', teammate.name)
												}}><IconAdd /></button>
											</div>
										</li>)
									}

								}


								for (let t3 of daysoff.teams) {
									if (t3?.ancestry == p2) {

										let t3Total = parseInt(t3?.days) + parseInt(t3.days_teammates) + parseInt(organizationDays)

										let next = t3.next
										if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
										else next = ''

										teamsList.push(<li key={t3.id} id={"team" + t3.id}
											className={"t3 team" + p2 + " team" + t3.id}>
											<div className="name"><IconFolders /> {t3.name}</div>
											<div className="daysoff">{t3Total}</div>
											<div className="organizationDays">{organizationDays}</div>
											<div className="teamsDays">{t3?.days || 0}</div>
											<div className="teammatesDays">{t3.days_teammates || 0}</div>
											<div className="next">{next}</div>
											<div className="reason">{t3.reason}</div>
											<div className="actions">
												{daysoff.teams.find(x => x?.ancestry == t3.id) &&
													<button className="expand" onClick={() => {
														expand(t3.id, "t4")
													}}><IconExpand /></button>
												}
												<button className="add" onClick={() => {
													add(t3.id, 'Group', t3.name, t3.teammates)
												}}><IconAdd /></button>
											</div>
										</li>)
										let p3 = t3.id

										for (let teammate of daysoff.teammates) {

											if (teammate.team_id == p3) {

												let tmTotal = parseInt(t3?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
												let tmNext = ''
												if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

												teamsList.push(<li key={"tm" + teammate.id}
													id={"teammate" + teammate.id}
													className={"t3 tm team" + p2 + " team" + t3.id}>
													<div className="name">{teammate.name || teammate.username}</div>
													<div className="daysoff">{tmTotal}</div>
													<div className="organizationDays">0</div>
													<div className="teamsDays">{t3?.days || 0}</div>
													<div className="teammatesDays">{teammate?.days || 0}</div>
													<div className="next">{tmNext}</div>
													<div className="reason">{teammate.reason}</div>
													<div className="actions">
														<button className="add" onClick={() => {
															add(teammate.id, 'MachineUser', teammate.name)
														}}><IconAdd /></button>
													</div>
												</li>)
											}

										}

										for (let t4 of daysoff.teams) {
											if (t4?.ancestry == p3) {

												let t4Total = parseInt(t4?.days) + parseInt(t4.days_teammates) + parseInt(organizationDays)

												let next = t4.next
												if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
												else next = ''

												teamsList.push(<li key={t4.id} id={"team" + t4.id}
													className={"t4 team" + p3 + " team" + t4.id}>
													<div className="name"><IconFolders /> {t4.name}</div>
													<div className="daysoff">{t4Total}</div>
													<div className="organizationDays">{organizationDays}</div>
													<div className="teamsDays">{t4?.days || 0}</div>
													<div className="teammatesDays">{t4.days_teammates || 0}</div>
													<div className="next">{next}</div>
													<div className="reason">{t4.reason}</div>
													<div className="actions">
														{daysoff.teams.find(x => x?.ancestry == t4.id) &&
															<button className="expand" onClick={() => {
																expand(t4.id, "t5")
															}}><IconExpand /></button>
														}
														<button className="add" onClick={() => {
															add(t4.id, 'Group', t4.name, t4.teammates)
														}}><IconAdd /></button>
													</div>
												</li>)
												let p4 = t4.id

												for (let teammate of daysoff.teammates) {

													if (teammate.team_id == p4) {

														let tmTotal = parseInt(t4?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
														let tmNext = ''
														if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

														teamsList.push(<li key={"tm" + teammate.id}
															id={"teammate" + teammate.id}
															className={"t4 tm team" + p3 + " team" + t4.id}>
															<div
																className="name"> {teammate.name || teammate.username}</div>
															<div className="daysoff">{tmTotal}</div>
															<div className="organizationDays">0</div>
															<div className="teamsDays">{t4?.days || 0}</div>
															<div className="teammatesDays">{teammate?.days || 0}</div>
															<div className="next">{tmNext}</div>
															<div className="reason">{teammate.reason}</div>
															<div className="actions">
																<button className="add" onClick={() => {
																	add(teammate.id, 'MachineUser', teammate.name)
																}}><IconAdd /></button>
															</div>
														</li>)
													}

												}

												for (let t5 of daysoff.teams) {
													if (t5?.ancestry == p4) {

														let t5Total = parseInt(t5?.days) + parseInt(t5.days_teammates) + parseInt(organizationDays)

														let next = t5.next
														if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
														else next = ''

														teamsList.push(<li key={t5.id} id={"team" + t5.id}
															className={"t5 team" + p4 + " team" + t5.id}>
															<div className="name"><IconFolders /> {t5.name}</div>
															<div className="daysoff">{t5Total}</div>
															<div className="organizationDays">{organizationDays}</div>
															<div className="teamsDays">{t5?.days || 0}</div>
															<div
																className="teammatesDays">{t5.days_teammates || 0}</div>
															<div className="next">{next}</div>
															<div className="reason">{t5.reason}</div>
															<div className="actions">
																{daysoff.teams.find(x => x?.ancestry == t5.id) &&
																	<button className="expand" onClick={() => {
																		expand(t5.id, "t6")
																	}}><IconExpand /></button>
																}
																<button className="add" onClick={() => {
																	add(t5.id, 'Group', t5.name, t5.teammates)
																}}><IconAdd /></button>
															</div>
														</li>)
														let p5 = t5.id

														for (let teammate of daysoff.teammates) {

															if (teammate.team_id == p5) {

																let tmTotal = parseInt(t5?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
																let tmNext = ''
																if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

																teamsList.push(<li key={"tm" + teammate.id}
																	id={"teammate" + teammate.id}
																	className={"t5 tm team" + p4 + " team" + t5.id}>
																	<div
																		className="name"> {teammate.name || teammate.username}</div>
																	<div className="daysoff">{tmTotal}</div>
																	<div
																		className="organizationDays">0</div>
																	<div className="teamsDays">{t5?.days || 0}</div>
																	<div
																		className="teammatesDays">{teammate?.days || 0}</div>
																	<div className="next">{tmNext}</div>
																	<div className="reason">{teammate.reason}</div>
																	<div className="actions">
																		<button className="add" onClick={() => {
																			add(teammate.id, 'MachineUser', teammate.name)
																		}}><IconAdd /></button>
																	</div>
																</li>)
															}

														}

														for (let t6 of daysoff.teams) {
															if (t6?.ancestry == p5) {

																let t6Total = parseInt(t6?.days) + parseInt(t6.days_teammates) + parseInt(organizationDays)

																let next = t6.next
																if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
																else next = ''

																teamsList.push(<li key={t6.id} id={"team" + t6.id}
																	className={"t6 team" + p5 + " team" + t6.id}>
																	<div className="name"><IconFolders /> {t6.name}</div>
																	<div className="daysoff">{t6Total}</div>
																	<div
																		className="organizationDays">{organizationDays}</div>
																	<div className="teamsDays">{t6?.days || 0}</div>
																	<div
																		className="teammatesDays">{t6.days_teammates || 0}</div>
																	<div className="next">{next}</div>
																	<div className="reason">{t6.reason}</div>
																	<div className="actions">
																		{daysoff.teams.find(x => x?.ancestry == t6.id) &&
																			<button className="expand" onClick={() => {
																				expand(t6.id, "t7")
																			}}><IconExpand /></button>
																		}
																		<button className="add" onClick={() => {
																			add(t6.id, 'Group', t6.name, t6.teammates)
																		}}><IconAdd /></button>
																	</div>
																</li>)
																let p6 = t6.id

																for (let teammate of daysoff.teammates) {

																	if (teammate.team_id == p6) {

																		let tmTotal = parseInt(t6?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
																		let tmNext = ''
																		if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

																		teamsList.push(<li key={"tm" + teammate.id}
																			id={"teammate" + teammate.id}
																			className={"t6 tm team" + p5 + " team" + t6.id}>
																			<div
																				className="name"> {teammate.name || teammate.username}</div>
																			<div className="daysoff">{tmTotal}</div>
																			<div
																				className="organizationDays">0</div>
																			<div
																				className="teamsDays">{t6?.days || 0}</div>
																			<div
																				className="teammatesDays">{teammate?.days || 0}</div>
																			<div className="next">{tmNext}</div>
																			<div
																				className="reason">{teammate.reason}</div>
																			<div className="actions">
																				<button className="add" onClick={() => {
																					add(teammate.id, 'MachineUser', teammate.name)
																				}}><IconAdd /></button>
																			</div>
																		</li>)
																	}

																}
																for (let t7 of daysoff.teams) {
																	if (t7?.ancestry == p6) {

																		let t7Total = parseInt(t7?.days) + parseInt(t7.days_teammates) + parseInt(organizationDays)

																		let next = t0.next
																		if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
																		else next = ''

																		teamsList.push(<li key={t7.id}
																			id={"team" + t7.id}
																			className={"t7 team" + p6 + " team" + t7.id}>
																			<div className="name">
																				<IconFolders /> {t7.name}</div>
																			<div className="daysoff">{t7Total}</div>
																			<div
																				className="organizationDays">{organizationDays}</div>
																			<div
																				className="teamsDays">{t7?.days || 0}</div>
																			<div
																				className="teammatesDays">{t7.days_teammates || 0}</div>
																			<div className="next">{next}</div>
																			<div className="reason">{t7.reason}</div>
																			<div className="actions">
																				<button className="add" onClick={() => {
																					add(t7.id, 'Group', t7.name, t7.teammates)
																				}}><IconAdd /></button>
																			</div>
																		</li>)
																		let p7 = t7.id

																		for (let teammate of daysoff.teammates) {

																			if (teammate.team_id == p7) {

																				let tmTotal = parseInt(t7?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
																				let tmNext = ''
																				if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

																				teamsList.push(<li
																					key={"tm" + teammate.id}
																					id={"teammate" + teammate.id}
																					className={"t7 tm team" + p6 + " team" + t7.id}>
																					<div
																						className="name"> {teammate.name || teammate.username}</div>
																					<div
																						className="daysoff">{tmTotal}</div>
																					<div
																						className="organizationDays">0</div>
																					<div
																						className="teamsDays">{t7?.days || 0}</div>
																					<div
																						className="teammatesDays">{teammate?.days || 0}</div>
																					<div className="next">{tmNext}</div>
																					<div
																						className="reason">{teammate.reason}</div>
																					<div className="actions">
																						<button className="add"
																							onClick={() => {
																								add(teammate.id, 'MachineUser', teammate.name)
																							}}><IconAdd /></button>
																					</div>
																				</li>)
																			}

																		}
																	}
																}

															}
														}

													}
												}

											}
										}
									}
								}
							}
						}
					}
				}
			} 
			
			else {


				if (daysoff.teams.filter(item => item?.id == t0.ancestry).length === 0) {

					let t0Total = parseInt(t0?.days) + parseInt(t0.days_teammates) + parseInt(organizationDays)

					let next = t0.next
					if (next) next = Moment.utc(next).format(t('date.mm/dd/yyyy'))
					else next = ''

					teamsList.push(<li key={t0.id} id={"team" + t0.id} className={"t0 team" + t0.id}>
						<div className="name"><IconFolders /> {t0.name}</div>
						<div className="daysoff">{t0Total}</div>
						<div className="organizationDays">{organizationDays}</div>
						<div className="teamsDays">{t0?.days || 0}</div>
						<div className="teammatesDays">{t0.days_teammates || 0}</div>
						<div className="next">{next}</div>
						<div className="reason">{t0.reason}</div>
						<div className="actions">
							{daysoff.teams.find(x => x?.ancestry == t0.id) &&
								<button className="expand" onClick={() => {
									expand(t0.id, "t1")
								}}><IconExpand /></button>
							}
							<button className="add" onClick={() => {
								add(t0.id, 'Group', t0.name, t0.teammates)
							}}><IconAdd /></button>
						</div>
					</li>)

					let p0 = t0.id

					for (let teammate of daysoff.teammates) {
						if (teammate.team_id == p0) {

							let tmTotal = parseInt(t0?.days) + parseInt(teammate?.days) + parseInt(organizationDays)
							let tmNext = ''
							if (teammate.next) tmNext = Moment.utc(teammate.next).format(t('date.mm/dd/yyyy'))

							teamsList.push(<li key={"tm" + teammate.id} id={"teammate" + teammate.id}
								className={"t0 tm team" + t0.id}>
								<div className="name"> {teammate.name || teammate.username}</div>
								<div className="daysoff">{tmTotal}</div>
								<div className="organizationDays">0</div>
								<div className="teamsDays">{t0?.days || 0}</div>
								<div className="teammatesDays">{teammate?.days || 0}</div>
								<div className="next">{tmNext}</div>
								<div className="reason">{teammate.reason}</div>
								<div className="actions">
									<button className="add" onClick={() => {
										add(teammate.id, 'MachineUser', teammate.name)
									}}><IconAdd /></button>
								</div>
							</li>)
						}

					}
				}
			}
		}

		setTeamsList([...teamsList])

	}

	function add(id, type, current, teammates) {

		if (type == "Group") {
			settingsActions.getCompanyDaysoffSpecific(id, "team")
		} else {
			settingsActions.getCompanyDaysoffSpecific(id, type)
		}

		props.openDrawer({ type: 'dayoff', id: id, mode: type, current: current, teammates: teammates })

	}

	function expand(id, level) {

		let mainEl = document.getElementById("team" + id)
		let cEl = mainEl.querySelector('.expand')

		if (cEl.classList.contains("active")) {

			cEl.classList.remove("active")
			cEl.parentNode.parentNode.classList.remove("active")

			let elems = document.querySelectorAll(".team" + id + ".expanded." + level)

			if (elems.length > 0) {
				[].forEach.call(elems, function (el) {
					el.classList.remove("expanded");
					el.classList.remove("last");
				});
			}
		} else {

			cEl.classList.add("active")
			cEl.parentNode.parentNode.classList.add("active")

			let elems = document.querySelectorAll(".team" + id + "." + level)

			if (elems.length > 0) {
				let n = 0;
				[].forEach.call(elems, function (el) {
					n++;
					el.classList.add("expanded");
					if (elems.length == n) {
						el.classList.add("last");
					}
				});
			}
		}

	}

	useEffect(() => {
		if (props.settings.companyDaysoff) {
			if (filter.teammates?.length > 0) {
				const teammate = filter.teammates[0]

				const { teammates, teams } = props.settings?.companyDaysoff

				const teammateFiltered = teammates?.find(item => parseInt(item.id) === parseInt(teammate.id))

				const teamsFiltered = teams?.find(item => item.id === teammateFiltered?.team_id)

				const teamsAncestry = []
				let tempAncestry = teamsFiltered.ancestry

				while (tempAncestry) {
					// eslint-disable-next-line no-loop-func
					const teamAncestryFiltered = teams?.find(item => String(item.id) === tempAncestry)
					if (teamAncestryFiltered) {
						teamsAncestry.push(teamAncestryFiltered)
					}
					tempAncestry = teamAncestryFiltered?.ancestry
				}

				const daysOffFiltered = {
					organization: props.settings.companyDaysoff.organization,
					teammates: [teammateFiltered],
					teams: [teamsFiltered, ...teamsAncestry]
				}

				setTeams(daysOffFiltered)
			} else {
				setTeams(props.settings.companyDaysoff)
			}
		}

	}, [props.settings.loading, filter.teammates, props.settings.companyDaysoff])

	return (
		<React.Fragment>
			<header className="pageHeader daysoff">
				<h3>{t('settings.daysofff.daysoff')}</h3>
				<ul>
					<li>
						<div className="icon">
							<IconOrganization />
						</div>
						<div className="text">{t('settings.daysofff.organization_free_days')}:</div>
						<div className="number">{organizationDays}</div>
					</li>
					<li>
						<div className="icon">
							<IconTeam />
						</div>
						<div className="text">{t('settings.daysofff.teams_free_days')}:</div>
						<div className="number">{organizationTeams}</div>
					</li>
					<li>
						<div className="icon">
							<IconTeammate />
						</div>
						<div className="text">{t('settings.daysofff.teammates_free_days')}:</div>
						<div className="number">{organizationTeammates}</div>
					</li>
				</ul>
				<div className="calendar">

				</div>
			</header>
			<div className="teamsListTable">
				<ul>
					<li className="header">
						<div className="name">{t('settings.daysofff.teams_and_teammates')}</div>
						<div className="daysoff">{t('settings.daysofff.daysoff')}</div>
						<div className="organizationDays">{t('settings.daysofff.organization')}</div>
						<div className="teamsDays">{t('settings.daysofff.teams')}</div>
						<div className="teammatesDays">{t('settings.daysofff.teammates')}</div>
						<div className="next">{t('settings.daysofff.next_free_days')}</div>
						<div className="reason">{t('settings.daysofff.reason')}</div>
						<div className="actions"></div>
					</li>
					<li>
						<div className="name"><IconFolders /> {t('settings.daysofff.all')}</div>
						<div
							className="daysoff">{parseInt(organizationDays) + parseInt(organizationTeammates) + parseInt(organizationTeams)}</div>
						<div className="organizationDays">{organizationDays}</div>
						<div className="teamsDays">{organizationTeams}</div>
						<div className="teammatesDays">{organizationTeammates}</div>
						<div className="next">{organizationNext}</div>
						<div className="reason">{organizationReason}</div>
						<div className="actions">
							<button className="add" onClick={() => {
								// eslint-disable-next-line no-unused-expressions
								add(null, 'Organization', t('settings.daysofff.all')), props.settings.companyDaysoff.organization.teammates
							}}><IconAdd /></button>
						</div>
					</li>
					{teamsListHTML && teamsListHTML}
				</ul>
			</div>
		</React.Fragment>

	)
};

function mapStateToProps(state) {

	return {
		token: state.authentication.user.accessToken,
		settings: state.settings,
		filter: state.filter
	}
}

function mapDispatchToProps(dispatch) {
	return {
		settingsActions: bindActionCreators(settingsActions, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyDaysoff));
