import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";

import IconInfo from '../_icons/IconInfo';
import IconStart from '../_icons/IconStart';
import IconTeammate from '../_icons/IconTeammate';

function WorkingToday(props) {
    const {t, i18n} = useTranslation('common');

    let today = props.today || {};

    let ActivesPercent = 0;
    let AbsensesPercent = 0;
    let AverageStart = "00:00";

    if (today.ActiveUsersToday != 0) {
        ActivesPercent = Math.round((today.ActiveUsersToday / today.TotalMachines) * 100);
    }

    if (today.ScheduledAbsensesToday != 0) {
        AbsensesPercent = Math.round((today.ScheduledAbsensesToday / today.TotalMachines) * 100);
    }

    if (today.AverageStartToday && today.AverageStartToday != 0) {
        let tempTime = today.AverageStartToday.split(":");
        AverageStart = tempTime[0] + ":" + tempTime[1];
    }

    if (today.AverageStartToday == "Invalid date") {
        AverageStart = t('realtime.no_avarage_start');
    }


    return (
        <div className="box medium">
            <h3><Link to="/productive/reports/realtime">{t('home.working_today')}<span><IconInfo/><div
                className="tooltip">{/*<IconInfo/>*/}{t('home.working_today_tooltip')}</div></span></Link></h3>
            <div className="statsBlock">
                <div className="secondaryChart workingToday">
                    <div className="block work">
                        <div className="icon">
                            <IconTeammate/>
                        </div>
                        <div className="stats">
                            <span>{t('home.active_teammates')}</span>

                            <div className="barchart">
                                <div className="bar" style={{width: ActivesPercent + '%'}}></div>
                            </div>
                        </div>
                        <div className="number">{today.ActiveUsersToday}/{today.TotalMachines || 0}</div>
                    </div>
                    <div className="block off">
                        <div className="icon">
                            <IconTeammate/>
                        </div>
                        <div className="stats">
                            <span>{t('home.day_off')}</span>

                            <div className="barchart">
                                <div className="bar" style={{width: AbsensesPercent + '%'}}></div>
                            </div>
                        </div>
                        <div className="number">{today.ScheduledAbsensesToday}/{today.TotalMachines || 0}</div>
                    </div>
                    <div className="block start">
                        <div className="icon">
                            <IconStart/>
                        </div>
                        <div className="stats">
                            <span>{t('home.average_start')}</span>
                        </div>
                        <div className="number">{AverageStart}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};


function mapStateToProps(state) {

    return {
        today: state.stats.payload
    }

}

export default connect(mapStateToProps)(WorkingToday)
