import {usersConstants} from '../_constants';
import {myProductivityService} from '../_services';
import {alertActions} from '.';
import {history} from '../_helpers';


export const myProductivityActions = {
    login,
    logout,
    refresh,
    getAll,
    activate,
    getByToken,
    patch,
    updatePassword,
    passwordRecover,
    getByResetToken,
    resetPassword,
    generatePasswordFirst
};

function login(organizationId, tokenMachineUser, email, password) {
    return dispatch => {
        dispatch(request({email}));

        myProductivityService.login(organizationId,  tokenMachineUser, email, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(`/productivity/${organizationId}/${tokenMachineUser}`)
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    myProductivityService.logout();
    return {type: usersConstants.LOGOUT};
}

function passwordRecover(organizationId, tokenMachineUser, email) {
    return dispatch => {
        dispatch(request());

        myProductivityService.passwordRecover(organizationId, tokenMachineUser, email)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.message))
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.POST_USER_RECOVER_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.POST_USER_RECOVER_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.POST_USER_RECOVER_FAILURE, error}
    }
}

function refresh() {
    return dispatch => {
        dispatch(request());

        myProductivityService.refresh()
            .then(
                user => dispatch(success(user)),
                error => {
                    //dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.LOGIN_REFRESH_REQUEST}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_REFRESH_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_REFRESH_FAILURE, error}
    }
}

function getByToken(token) {
    return dispatch => {
        dispatch(request());

        myProductivityService.getByToken(token)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.GET_BY_TOKEN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.GET_BY_TOKEN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.GET_BY_TOKEN_FAILURE, error}
    }
}

function getByResetToken(organizationId, token) {
    return dispatch => {
        dispatch(request());

        myProductivityService.getByResetToken(organizationId, token)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.GET_BY_TOKEN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.GET_BY_TOKEN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.GET_BY_TOKEN_FAILURE, error}
    }
}

function activate(password, passwordConfirm, user_id, token) {
    return dispatch => {
        dispatch(request());

        myProductivityService.activate(password, passwordConfirm, user_id, token)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}

function resetPassword(password, passwordConfirm, organization_id, tokenMachineUser, token) {
    return dispatch => {
        dispatch(request());

        myProductivityService.resetPassword(password, passwordConfirm, organization_id, token)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(`/productivity/${organization_id}/${tokenMachineUser}`)
                    
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}

function generatePasswordFirst(organizationId, tokenMachineUser, email, password, passwordConfirm) {
    return dispatch => {
        dispatch(request());

        myProductivityService.generatePasswordFirst(organizationId, tokenMachineUser, email, password, passwordConfirm)
            .then(
                user => {
                    dispatch(success(user));

                    history.push(`/productivity/${organizationId}/${tokenMachineUser}/login`);
                    window.location.reload(true);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };


    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.GENERATE_PASSWORD_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}


function getAll() {
    return dispatch => {
        dispatch(request());

        myProductivityService.getAll()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.GETALL_REQUEST}
    }

    function success(users) {
        return {type: usersConstants.GETALL_SUCCESS, users}
    }

    function failure(error) {
        return {type: usersConstants.GETALL_FAILURE, error}
    }
}

function patch(form, id) {
    return dispatch => {
        dispatch(request());

        myProductivityService.patch(form, id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() {
        return {type: usersConstants.PATCH_USER_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.PATCH_USER_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.PATCH_USER_FAILURE, error}
    }
}

function updatePassword(form, id) {
    return dispatch => {
        dispatch(request());

        myProductivityService.updatePassword(form, id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.PATCH_USER_PASSWORD_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.PATCH_USER_PASSWORD_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.PATCH_USER_PASSWORD_FAILURE, error}
    }
}
