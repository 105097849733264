import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { CaretCircleDoubleUp, ChartPie, ChartPieSlice, Cpu, CurrencyCircleDollar, Disc, StackSimple } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './CardsSoftware.module.scss';
import { befetiveFormatCurrency } from '../../../../_helpers';

export function CardsSoftware({ alertActions, userDetails, resume }) {

  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187DFF` }}>
          <CaretCircleDoubleUp color='#187DFF' size={18} weight="bold" />
          <Typography color={"#187DFF"} component="span" fontSize={18} mt={1}>
            {String(Number(resume?.total_licenses_balance))}
          </Typography>
          <Typography color={"#187DFF"} component="span" fontSize={14}>
            {t("software_inventory.cards_header.license_balance")}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <CurrencyCircleDollar color='#4F7091' size={18} weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={18} mt={1} display={'flex'} alignItems={'center'} gap={'4px'}>
          {resume?.organization_currency_code+" "+resume?.organization_currency_symbol+" "+befetiveFormatCurrency(resume?.total_cost,userDetails.language,false)}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={14}>
            {t("software_inventory.cards_header.total_cost")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <ChartPieSlice color='#4F7091' size={18} weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={18} mt={1}>
            {String(Number(resume?.utilization_percent)) + "%"}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={14}>
            {t("software_inventory.cards_header.percent_utilization")}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <ChartPie color='#187DFF' size={18} weight="bold" />

          <Typography color="#187DFF" component="span" fontSize={18} mt={1}>
            {String(Number(resume?.real_usage_percent)) + "%"}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={14}>
            {t("software_inventory.cards_header.percent_real_use")}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid ${Number(resume?.economic_potential) > 0 ? "#22BEAD" : "#a6a6a6"}` }}>

          <CurrencyCircleDollar color={Number(resume?.economic_potential) > 0 ? "#22BEAD" : "#a6a6a6"} size={18} weight="bold" />

          <Typography color={Number(resume?.economic_potential) > 0 ? "#22BEAD" : "#a6a6a6"} component="span" fontSize={18} mt={1} display={'flex'} alignItems={'center'} gap={'4px'}>
          {resume?.organization_currency_code+" "+resume?.organization_currency_symbol+" "+befetiveFormatCurrency(Number(resume?.economic_potential),userDetails.language,false)}
          </Typography>
          <Typography color={Number(resume?.economic_potential) > 0 ? "#22BEAD" : "#a6a6a6"} component="span" fontSize={14}>
            {t("software_inventory.cards_header.economy_potential")}
          </Typography>

        </CardContent>
      </Card>

    </Stack>
  );
}
