import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";
import {Link} from 'react-router-dom';
import IconInfo from '../_icons/IconInfo';
import IconHappy from '../_icons/IconHappy';
import IconOk from '../_icons/IconOk';
import IconSad from '../_icons/IconSad';

function TeammatesMood(props) {
    const {t, i18n} = useTranslation('common');

    let today = props.today || {};

    let TotalHappyusers = today.HappyMoodUsers || 0;
    let TotalSadMoodUsers = today.SadMoodUsers || 0;
    let TotalOkMoodUsers = today.OkMoodUsers || 0;

    let TotalMoodUsers = TotalHappyusers + TotalOkMoodUsers + TotalSadMoodUsers;

    let TotalMachines = today.TotalMachines;

    let HappyMoodPer = 0;
    let OkMoodPer = 0;
    let SadMoodPer = 0;

    if (today.HappyMoodUsers && today.HappyMoodUsers != 0 && TotalMoodUsers != 0) {
        HappyMoodPer = ((today.HappyMoodUsers / TotalMoodUsers) * 100).toFixed(1);
    }

    if (today.OkMoodUsers && today.OkMoodUsers != 0 && TotalMoodUsers != 0) {
        OkMoodPer = ((TotalOkMoodUsers / TotalMoodUsers) * 100).toFixed(1);
    }
    if (today.SadMoodUsers && today.SadMoodUsers != 0 && TotalMoodUsers != 0) {
        SadMoodPer = ((today.SadMoodUsers / TotalMoodUsers) * 100).toFixed(1);
    }


    return (
        <div className="box small">
            <h3><Link to="/productive/reports/mood">{t('home.teammates_mood')}<span><IconInfo/><div
                className="tooltip">{/*<IconInfo/>*/}{t('home.teammates_mood_tooltip')}</div></span></Link></h3>
            <div className="statsBlock mood">
                <div className="secondaryChart">
                    <div className="block">
                        <div className="icon2">
                            <IconHappy/>
                        </div>
                        <div className="number">{HappyMoodPer}%</div>
                    </div>
                    <div className="block">
                        <div className="icon2">
                            <IconOk/>
                        </div>
                        <div className="number">{OkMoodPer}%</div>
                    </div>
                    <div className="block">
                        <div className="icon2">
                            <IconSad/>
                        </div>
                        <div className="number">{SadMoodPer}%</div>
                    </div>

                </div>
                <div className={"totals"}>{TotalMoodUsers} / {TotalMachines} {t('home.awsered')}</div>
            </div>
        </div>
    )
};


function mapStateToProps(state) {

    return {
        today: state.stats.payload
    }

}

export default connect(mapStateToProps)(TeammatesMood)
