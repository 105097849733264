import { usersConstants } from '../_constants';

export function users(state = {}, action) {
    switch (action.type) {
        case usersConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case usersConstants.GETALL_SUCCESS:
            return {
                items: action.users
            };
        case usersConstants.GETALL_FAILURE:
            return {
                error: action.error,
                loading: true
            };
        case usersConstants.GET_BY_TOKEN_REQUEST:
            return {
                error: false,
                loading: true
            };
        case usersConstants.GET_BY_TOKEN_SUCCESS:
            return {
                loading: false,
                error: false,
                activation: action.user,

            };
        case usersConstants.GET_BY_TOKEN_FAILURE:
            return {

                loading: false,
                error: true

            };
        case usersConstants.PATCH_USER_REQUEST:
            return {
                ...state,
                updated: '',
                loading: false
            };
        case usersConstants.PATCH_USER_SUCCESS:
            return {
                ...state,
                updated: 'success',
                loading: false
            };
        case usersConstants.PATCH_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                updated: 'error',
                loading: false
            };
        case usersConstants.PATCH_USER_PASSWORD_REQUEST:
            return {
                ...state,
                updated: '',
                loading: false
            };
        case usersConstants.PATCH_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                updated: 'success',
                loading: false
            };
        case usersConstants.PATCH_USER_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.error,
                updated: 'error',
                loading: false
            };
        case usersConstants.POST_USER_RECOVER_REQUEST:
            return {

                sended: '',
                loading: false
            };
        case usersConstants.POST_USER_RECOVER_SUCCESS:
            return {

                sended: 'success',
                loading: false
            };
        case usersConstants.POST_USER_RECOVER_FAILURE:
            return {

                error: action.error,
                sended: 'error',
                loading: false
            };
        case usersConstants.GET_RESPONSIBLES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case usersConstants.GET_RESPONSIBLES_SUCCESS:
            return {
                ...state,
                responsibles: action.payload,
                loading: false
            };
        case usersConstants.GET_RESPONSIBLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        default:
            return state
    }
}