import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'


import "react-datepicker/dist/react-datepicker.css";

import { alertActions, filterActions, teamsActions } from '../../_actions';
import { statsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';

import WhoSingleTeammate from "./filters/WhoSingleTeammate";
import WhoSingleTeammateSkeleton from "./filters/WhoSingleTeammateSkeleton";
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import WhoMultiSkeleton from './filters/WhoMultiSkeleton';
import WhoMulti from './filters/WhoMulti';
import IconImportExport from '../_icons/IconImportExport';
import styles from './FilterbarTeams.module.scss'
import { ModalImportTeamStructure } from '../teams_settings/ModalImportTeamStructure';
import ModalExportTeamStructure from '../teams_settings/ModalExportTeamStructure';


const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: '100px',
		minHeight: '42px',
		backgroundColor: '#FDBE2B',
		borderRadius: '2px',
		cursor: 'pointer',
		fontWeight: '600',
		border: 'none',
		color: '#106276',
		textTransform: 'none',
		boxShadow: 'none',
		transition: '0.2s',

		'&:hover': {
			backgroundColor: '#FDBE2B',
			boxShadow: '0px 0px 4px 0px #2B2B2B55',
		},
		'& > span': {
			fontSize: '13px',
			display: 'flex',
			flexWrap: 'nowrap'
		}
	}

}));

function FilterbarTeams(props) {

	const { teammatesActions, alertActions, getTeamsData, filter, userDetails } = props;
	const classes = useStyles()
	const { t, i18n } = useTranslation('common');
	const [openModalImportTeamStruture, setOpenModalImportTeamStruture] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [disableActions, setDisableActions] = useState(false);
	const [openModalExportTeamStructure, setOpenModalExportTeamStructure] = useState(false);

	const handleOpenModalExportTeamStructure = () => {
		setOpenModalExportTeamStructure(true);
		handleClose()
	};

	const handleCloseModalExportTeamStructure = () => {
		setOpenModalExportTeamStructure(false);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenModalTeamStructure = () => {
		setOpenModalImportTeamStruture(true);
		handleClose()
	};

	const open = Boolean(anchorEl);
	const id = open ? 'export-popover' : undefined;

	useEffect(() => {
		teammatesActions.getTeammates();
	}, [teammatesActions])

	return (
		<div className="mainFilterbar">
			<div className="filters">
				{/* {props.loadingTeammates
					? <WhoSingleTeammateSkeleton />
					: <WhoSingleTeammate openWho={props.openWho} />
				} */}

				{props.loadingTeams || props.loadingTeammates
					? <WhoMultiSkeleton />
					: <WhoMulti openWho={props.openWho} />
				}
			</div>

			{
				props.showButtonNewTeam == true &&
				<div className="ButtonActionsGroup">
					{
						['magic', 'admin'].includes(userDetails.profile_key) && (
							<button className="btnMovimentations" style={{ marginRight: '4px' }} onClick={handleClick} aria-describedby={id}>
								<IconImportExport />
							</button>
						)
					}

					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						PaperProps={{
							style: {
								backgroundColor: '#1E6B7F',
								marginTop: '10px'
							}
						}}
					>
						<Box className={styles.menuOptionsActions}>
							<button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} onClick={handleOpenModalTeamStructure}>{t('settings.teams.import_teams')}</button>
							<button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} onClick={handleOpenModalExportTeamStructure}>{t('settings.teams.export_teams')}</button>
						</Box>
					</Popover>
					{
						userDetails.allowed_create && (
							<Button variant="contained" color="secondary" className={classes.button} onClick={() => props.openDrawer()}>
								{`+ ${t('settings.teams.team')}`}
							</Button>
						)
					}
				</div>
			}
			<ModalImportTeamStructure
				openModalImportTeamStructure={openModalImportTeamStruture}
				setOpenModalImportTeamStruture={setOpenModalImportTeamStruture}
				setAnchorEl={setAnchorEl}
				alertActions={alertActions}
				getTeamsData={getTeamsData}
			/>

			<ModalExportTeamStructure
				openModalExport={openModalExportTeamStructure}
				handleCloseModalExport={handleCloseModalExportTeamStructure}
				viewExport='exportTeamStructure'
				filter={{ ...filter }}
			/>

		</div>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch),
		teamsActions: bindActionCreators(teamsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teammates: state.teammates.payload,
		loadingTeammates: state.teammates.loading,
		teams: state.teams.payload,
		loadingTeams: state.teams.loading,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterbarTeams)
