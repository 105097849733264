export const activitiesConstants = {
    GET_ACTIVITIES_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_REQUEST',
    GET_ACTIVITIES_OFFSET_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_OFFSET_REQUEST',
    GET_ACTIVITIES_DETAILS_TEAMS_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_TEAMS_REQUEST',
    GET_ACTIVITIES_DETAILS_TEAMMATES_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_TEAMMATES_REQUEST',
    GET_ACTIVITIES_DETAILS_SPECIFIC_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_SPECIFIC_REQUEST',
    GET_ACTIVITIES_CATEGORIES_REQUEST: '[ACTIVITIES] GET_ACTIVITIES_CATEGORIES_REQUEST',
    GET_ACTIVITIES_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_SUCCESS',
    GET_ACTIVITIES_OFFSET_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_OFFSET_SUCCESS',
    GET_ACTIVITIES_DETAILS_TEAMS_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_TEAMS_SUCCESS',
    GET_ACTIVITIES_DETAILS_TEAMMATES_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_TEAMMATES_SUCCESS',
    GET_ACTIVITIES_DETAILS_SPECIFIC_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_SPECIFIC_SUCCESS',
    GET_ACTIVITIES_CATEGORIES_SUCCESS: '[ACTIVITIES] GET_ACTIVITIES_CATEGORIES_SUCCESS',
    GET_ACTIVITIES_FAILURE: '[ACTIVITIES] GET_ACTIVITIES_FAILURE',
    GET_ACTIVITIES_DETAILS_TEAMS_FAILURE: '[ACTIVITIES] GET_ACTIVITIES_TEAMS_DETAILS_FAILURE',
    GET_ACTIVITIES_DETAILS_TEAMMATES_FAILURE: '[ACTIVITIES] GET_ACTIVITIES_TEAMMATES_DETAILS_FAILURE',
    GET_ACTIVITIES_DETAILS_SPECIFIC_FAILURE: '[ACTIVITIES] GET_ACTIVITIES_DETAILS_SPECIFIC_FAILURE',
    GET_ACTIVITIES_CATEGORIES_FAILURE: '[ACTIVITIES] GET_ACTIVITIES_CATEGORIES_FAILURE',
    PATCH_ACTIVITIES_RELATIONS_REQUEST: '[ACTIVITIES] PATCH_ACTIVITIES_RELATIONS_REQUEST',
    PATCH_ACTIVITIES_RELATIONS_SUCCESS: '[ACTIVITIES] PATCH_ACTIVITIES_RELATIONS_SUCCESS',
    PATCH_ACTIVITIES_RELATIONS_FAILURE: '[ACTIVITIES] PATCH_ACTIVITIES_RELATIONS_FAILURE',
    PATCH_ACTIVITIES_CATEGORY_RELATIONS_REQUEST: '[ACTIVITIES] PATCH_ACTIVITIES_CATEGORY_RELATIONS_REQUEST',
    PATCH_ACTIVITIES_CATEGORY_RELATIONS_SUCCESS: '[ACTIVITIES] PATCH_ACTIVITIES_CATEGORY_RELATIONS_SUCCESS',
    PATCH_ACTIVITIES_CATEGORY_RELATIONS_FAILURE: '[ACTIVITIES] PATCH_ACTIVITIES_CATEGORY_RELATIONS_FAILURE',
    PUT_ACTIVITIES_TRANSACTIONAL_REQUEST: '[ACTIVITIES] PATCH_ACTIVITIES_TRANSACTIONAL_REQUEST',
    PUT_ACTIVITIES_TRANSACTIONAL_SUCCESS: '[ACTIVITIES] PATCH_ACTIVITIES_TRANSACTIONAL_SUCCESS',
    PUT_ACTIVITIES_TRANSACTIONAL_FAILURE: '[ACTIVITIES] PATCH_ACTIVITIES_TRANSACTIONAL_FAILURE'
};

