import React, { useState } from "react";
import { useEffect } from "react";
import { api } from "../../../_helpers/api";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { authHeader, befectiveMaskFormatDate } from "../../../_helpers";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import {useTranslation, initReactI18next} from "react-i18next";
import './styles.scss'

export function BefectiveDatePicker({...props}) {
  const {t, i18n} = useTranslation('common');
  const {userDetails,date_value,maxDate,minDate,formEdit,setFormEdit,setSubmitted,field} = props  
  const className = props.className || ""
  const disabled = props.disabledValue === "disabled" ? true : false
  const readOnly = props.readOnly ? true : false
  const customInput = props.customInput || ""
  const [startDate, setStartDate] = useState(null);
  registerLocale('es', es)
  registerLocale('pt', pt)
  registerLocale('en', en)
  
  let calendarLanguage = 'pt'
  if (userDetails && userDetails.language) {
      calendarLanguage = userDetails.language;
  } else {
      calendarLanguage = window.navigator.userLanguage;
  }
  useEffect(() => {
    if(date_value){
      let curr_value = new Date(String(date_value)+"T00:00:00.00")
      if(date_value === '' || date_value === undefined){
        curr_value = null
      }
      setStartDate(curr_value)
    }else{
      setStartDate("")
    }
    
  },[date_value])

  function handleUpdateValue(data) {
    if(!data){
      return
    }
    setStartDate(data)
    setSubmitted(true)
    setFormEdit({
      ...formEdit,
      [field]: moment(data).format().split("T")[0]
    })
  }

  return (
    <div className="befectiveDate">
        <DatePicker  
                  
          selected={startDate}
          startDate={startDate}
          onChange={(date) => handleUpdateValue(date)}
          maxDate={maxDate !== "" ? new Date(String(maxDate)+"T00:00:00.00") : ""}
          minDate={minDate !== "" ? new Date(String(minDate)+"T00:00:00.00") : ""}
          dateFormat={befectiveMaskFormatDate(calendarLanguage)}
          disabledKeyboardNavigation // <-- active-day bug is still open
          locale={calendarLanguage}
          className={className}
          disabled={disabled}
          readOnly={readOnly}
          customInput={customInput}
          // isClearable={true}
        />
    </div>
  )
}

