import React, { useEffect, useState } from 'react'
import styles from './StatsCardHeader.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  customLabel: {
    fontSize: '10px'
  }

}));

export default function StatsCardHeader({
  stats
}) {
  const { t } = useTranslation('common');

  return (
    <header className={styles.header}>
      <div className={styles.containerInfo}>
        <h6 className={styles.title}>{t('settings.extra_collections.title')}</h6>
        <div className={styles.boxStats}>
          <div className={styles.stats}>
            <div className={`${styles.circle} ${styles.circleBlue}`}></div>
            <div className={styles.labelStats}>{`${t('settings.extra_collections.stats.total')}:`}</div>
            <div className={styles.numberValue}>{stats.total_extra_collections || 0}</div>
          </div>
          <div className={styles.stats}>
            <div className={`${styles.circle} ${styles.circleGreen}`}></div>
            <div className={styles.labelStats}>{`${t('settings.extra_collections.stats.total_finish')}:`}</div>
            <div className={styles.numberValue}>{stats.total_extra_collections_finish || 0}</div>
          </div>
          <div className={styles.stats}>
            <div className={`${styles.circle} ${styles.circleRed}`}></div>
            <div className={styles.labelStats}>{`${t('settings.extra_collections.stats.total_next')}:`}</div>
            <div className={styles.numberValue}>{stats.total_extra_collections_next || 0}</div>
          </div>
        </div>
      </div>
    </header>
  )
}
