import { Avatar, Backdrop, Box, Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, Modal, Slide, Tooltip, Typography, withStyles } from '@material-ui/core';
import MuiSelect from "@material-ui/core/Select";
import { makeStyles } from '@material-ui/core/styles';
import { AttachFile } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AvatarGroup, Skeleton } from "@material-ui/lab";
import { PencilSimple, TrashSimple, X, XCircle } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { bindActionCreators } from "redux";

import Slider from '@material-ui/core/Slider';
import moment from 'moment';
import { alertActions, usersActions } from "../../../_actions";
import { authHeader, befectiveFormatDate, getAvatarUrl, getColorStatusKr, getStatusClass } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import { InputDate } from "../../_shared/InputDate/InputDate";
import InputTime from "../../_shared/InputTime";
import { BefectiveDatePicker } from "../BefectiveDatePicker";
import { ModalAddTeamTask } from "../ModalAddTeamTask";
import TagsList from "../TagsList";
import { mentionStyle } from "./inputMentionsStyles/mentionStyles";
import { mentionsInputStyle } from "./inputMentionsStyles/mentionsInputStyles";
import "./styles.scss";
import { CardChecklist } from './CardChecklist';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListDivider } from './CardChecklist/ListDivider';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	rootTab: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	textField: {
		width: '37ch',
	},
	modal: {
		"&:focus": {
			outline: 'none'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		borderRadius: '8px',
		position: 'relative',
		// overflowY: "auto",
	},
	title: {
		fontSize: 16,
		color: '#1E6B7F',
		fontWeight: '500',
		marginBottom: 24
	}
}));


const PrettoSlider = withStyles({
	root: {
		height: 10,
		width: 100
	},
	thumb: {
		height: 12,
		width: 12,
		backgroundColor: '#fff',
		border: '3px solid currentColor',
		marginTop: -1,
		marginLeft: -6,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 10,
		borderRadius: 4,
	},
	rail: {
		height: 10,
		borderRadius: 4,
		backgroundColor: '#E4EAEF'
	},
})(Slider);


const useFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

	return [htmlElRef, setFocus]
}


const GreenCheckbox = withStyles({
	root: {
		color: '#E4EAEF',
		"&$checked": {
			color: '#22BEAD'
		},
		'&:hover': {
			backgroundColor: 'transparent !important'
		}
	},
	checked: {}
})((props) => <Checkbox color="default" {...props} />);

const statusTask = ["planned", "progress", "completed", "late"]

function ModalDetailTask({
	setOpenModalNewTaskEdit,
	isEditable = true,
	userDetails,
	userDetails1 = {
		tasksPermissions: [1, 2, 163]
	},
	...props
}) {

	const classes = useStyles();
	const { t, i18n } = useTranslation('common');

	const {
		alertActions,
		currentTask,
		setCurrentTaskToModal,
		krType,
		teammates,
		open,
		handleClose,
		isLoading,
		handleCloseTaskDetails,
		typeInput,
		date_start,
		date_end,
		end_value,
		groups,
		teammatesResponsibleOp
	} = props;


	const [inputRef, setInputFocus] = useFocus();

	const assistantsBackup = [...currentTask[0]?.co_responsibles]

	const [isUpdatingProgress, setIsUpdatingProgress] = useState(false)
	const [canSubmit, setCanSubmit] = useState(false)
	const [assistants, setAssistants] = useState(currentTask[0]?.co_responsibles)
	const [openModalAddTeamTask, setOpenModalAddTeamTask] = useState(false);
	const [itemId, setItemId] = useState(-1);
	const [edit, setEdit] = useState(false);
	const [editComment, setEditComment] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [form, setForm] = useState({
		checkListItems: [],
		files: []
	});
	const [tabId, setTabId] = useState('0');
	
	const [newCard, setNewCard] = useState(false);
	const [newChecklistTitle, setNewChecklistTitle] = useState('');
	

	const [checkListTitle, setCheckListTitle] = useState('');
	// const [commentTitle, setCommentTitle] = useState('');
	const [commentMention, setCommentMention] = useState({
		message: '',
		mentions: []
	});
	const [commentOwnerId, setCommentOwnerId] = useState(null);
	const [commentId, setCommentId] = useState(null);
	const [taskList, setTaskList] = useState([]);
	const [checklist, setChecklist] = useState([]);
	const [commentsList, setCommentsList] = useState([]);
	const [attachmentsList, setAttachmentsList] = useState([]);
	const [value, setValue] = useState(currentTask[0]?.progress);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElAttachment, setAnchorElAttachment] = useState(null);
	const [anchorElOptionsTask, setAnchorElOptionsTask] = useState(null);
	const [currentComment, setCurrentComment] = useState({});
	const [historicList, setHistoricList] = useState([]);
	const [currentAttachment, setCurrentAttachment] = useState({});
	const [isUpdating, setIsUpdating] = useState(false)
	const [currentTaskOnTable, setCurrentTaskOnTable] = useState(null);
	const [currentTaskOnModalToTime, setCurrentTaskOnModalToTime] = useState(null);
	const [anchorElTaskOnModalToTime, setAnchorElTaskOnModalToTime] = useState(null);
	const [anchorElTaskOnTable, setAnchorElTaskOnTable] = useState(null);
	// const [anchorElTaskOnEditValue, setAnchorElTaskOnEditValue] = useState(null);
	const [anchorElTaskOnEditDateStart, setAnchorElTaskOnEditDateStart] = useState(null);
	const [anchorElTaskOnEditDateEnd, setAnchorElTaskOnEditDateEnd] = useState(null);
	// const [taskValue, setTaskValue] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formEdit, setFormEdit] = useState({
		responsible: currentTask[0]?.responsible_id,
		date_start: currentTask[0]?.date_start ? currentTask[0]?.date_start.split('T')[0] : '',
		date_end: currentTask[0]?.date_end ? currentTask[0]?.date_end.split('T')[0] : '',
		priority: currentTask[0]?.priority?.length > 0 ? currentTask[0]?.priority[0].id : '',
		complexity: currentTask[0]?.complexity?.length > 0 ? currentTask[0]?.complexity[0].id : '',
		weight: currentTask[0]?.weight
	});

	const handleCloseMenuTaskOnModalToTime = () => {
		setCurrentTaskOnModalToTime(null);
		setAnchorElTaskOnModalToTime(null);
	};

	// const handleCloseMenuEditValue = () => {
	// 	setAnchorElTaskOnEditValue(null);
	// };

	const handleCloseMenuEditDateStart = () => {
		setAnchorElTaskOnEditDateStart(null);
	};

	const handleCloseMenuEditDateEnd = () => {
		setAnchorElTaskOnEditDateEnd(null);
	};

	// const handleClickTaskOnTableEditValue = (event, task) => {
	// 	setCurrentTaskOnTable(task)
	// 	setTaskValue(Number(task[0].value))
	// 	setAnchorElTaskOnEditValue(event.currentTarget);
	// };

	const handleClickTaskOnTableEditDateStart = (event, task) => {
		setCurrentTaskOnTable(task)
		setAnchorElTaskOnEditDateStart(event.currentTarget);
	};

	const handleClickTaskOnTableEditDateEnd = (event, task) => {
		setCurrentTaskOnTable(task)
		setAnchorElTaskOnEditDateEnd(event.currentTarget);
	};

	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
		setCanSubmit(true)
	};

	const handleClickTaskOnTable = (event, task) => {
		setCurrentTaskOnTable(task[0])
		setValue(parseInt(task[0]?.progress))
		setAnchorElTaskOnTable(event.currentTarget);
	};

	const handleCloseMenuTaskOnTable = () => {
		setAnchorElTaskOnTable(null);
	};

	const handleClickTaskOnModalToTime = (event, task) => {
		setCurrentTaskOnModalToTime(task)
		setAnchorElTaskOnModalToTime(event.currentTarget);
	};

	let token = authHeader()

	const teammatesOptions = teammates?.map(item => {
		return {
			display: item.label,
			id: `${item.value}`,
			idOnly: item.value
		}
	});

	const optionsColabs = teammatesOptions?.filter(colab => colab.idOnly !== userDetails?.id)

	function handleChangeComment(event) {
		let value = event.target.value;
		if (value.includes('\n')) {
			value = event.target.value.replace("\n", "")
		}
		const regex = /[^{}]+(?=})/g;

		const mentions = value.match(regex);

		// const messageFormatted = value?.replaceAll('[', '')?.replaceAll(']', '')?.replace(`{${mentions[0]}}`, '')

		setCommentMention({ message: value, mentions: mentions || [] });
	}

	const handleChangeTabs = (tabId) => {
		setTabId(tabId);
	}

	const handleClickMenu = (event, comment) => {
		setAnchorEl(event.currentTarget);
		setCurrentComment(comment);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setCurrentComment({});
	};

	const handleClickMenuAttachment = (event, comment) => {
		setAnchorElAttachment(event.currentTarget);
		setCurrentAttachment(comment);
	};

	const handleCloseMenuAttachment = () => {
		setAnchorElAttachment(null);
		setCurrentAttachment({});
	};

	const handleClickMenuOptionsTask = (event, comment) => {
		setAnchorElOptionsTask(event.currentTarget);
	};

	const handleCloseMenuOptionsTask = () => {
		setAnchorElOptionsTask(null);
	};

	const handleOpenModalAddTeamTask = (e) => {
		e.preventDefault()
		setOpenModalAddTeamTask(true)
	}

	// const handleInputChange = (event) => {
	// 	if (event.target.value > 100) {
	// 		setValue(100);
	// 		return
	// 	}

	// 	if (event.target.value < 0) {
	// 		setValue(0);
	// 		return
	// 	}

	// 	setValue(event.target.value === '' ? '' : Number(event.target.value));
	// };

	const getTaskDetail = async (taskId) => {
		try {
			const result = await api.get(`goals/key-results/tasks/${taskId}/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setCurrentTaskToModal(result?.data)

		} catch (error) {
			// alertActions.error(error)
		}

	}


	const updateProgressTask = async () => {
		try {
			let payload

			payload = {
				progress: value,
				itens: taskList?.length > 0 ? taskList : undefined,
				datetime: moment().format()
			}

			setIsUpdating(true)
			await api.put(`goals/key-results/tasks/${currentTask[0]?.id}/small/?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			await getTaskDetail(currentTask[0]?.id);
			await getHistoric()
			setIsUpdating(false)
			setCanSubmit(false)
			// await props.updateGoalsDetails();
		} catch (error) {
			setIsUpdating(false)
			console.log('Response Error -> ', error);
			setCanSubmit(false)
			// alertActions.error(error)
		} finally {
			setIsUpdatingProgress(false)
		}

	}

	const onChangeInputFile = (e) => {
		const filesInput = e.target.files;
		const filesArr = Array.prototype.slice.call(filesInput);

		if (filesArr?.length)
			sendAttachments(filesArr);

		// setForm({ ...form, files: [...form.files, ...filesArr] });

		// if(form?.files.length)
		//     sendAttachments();

	}

	const sendAttachments = async (filesArr) => {
		try {
			// setIsSubmitting(true)

			const formData = new FormData();

			for (let item of filesArr) {
				formData.append('files', item)
			}

			await api.post(`goals/key-results/tasks/${currentTask[0]?.id}/attachments/?organization_id=${userDetails?.organizationId}&owner_id=${userDetails?.mu_id}&datetime=${moment().format()}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': token.Authorization
					},
				}
			)

			alertActions.success(t('calls_page.success.create_call'))

			getAtachments();
			getHistoric();
			setForm({ ...form, files: [] });
			// setTimeout(() => {
			//   window.location.reload(true)
			// }, 1000)
		} catch (error) {
			// setIsSubmitting(false)
			console.log(error.response)
			// //alertActions.error(t('calls_page.error.create_call'))
		}
	}

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 100) {
			setValue(100);
		}
	};

	const getChecklist = async () => {
		console.log("/doing request.")
		try {
			const result = await api.get(`goals/key-results/tasks/${currentTask[0]?.id}/checklist?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			console.log("/result ",result)
			setChecklist(result?.data)
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	
	}
	const saveChecklist = async (body) => {
		
		try {
			const result = await api.post(`goals/key-results/tasks/checklist?organization_id=${userDetails?.organizationId}`,
			{

        id: body.id,
				task_id:body.task_id,
        title: body.title,
      }, 
			{
				headers: {
					'Authorization': token.Authorization
				}
			})
			if(result.status === 200){
				getChecklist()
			}			
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	
	}
	const deleteChecklist = async (id) => {
		if(id === undefined){
			console.log("/bad request!")
			return {error:"bad request!"}
		}
		try {
			const result = await api.delete(`goals/key-results/tasks/checklist/${id}?organization_id=${userDetails?.organizationId}&task_id=${currentTask[0]?.id}`, 
			{
				headers: {
					'Authorization': token.Authorization
				}
			})
			if(result.status === 200){
				getChecklist()
				await getTaskDetail(currentTask[0]?.id);
			}			
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	
	}
	const saveItem = async (body) => {
		
		try {
			const result = await api.post(`goals/key-results/tasks/checklist/items?organization_id=${userDetails?.organizationId}`,
			{
				task_id:currentTask[0]?.id,
				checklist_id:body.checklist_id,
				id:body.id,
        title: body.title,
				checked:body.checked,
				due_date:body.due_date
      }, 
			{
				headers: {
					'Authorization': token.Authorization
				}
			})
			if(result.status === 200){
				getChecklist()
				await getTaskDetail(currentTask[0]?.id);
			}			
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	
	}
	const deleteItem = async (id) => {
		if(id === undefined){
			return {error:"bad request!"}
		}
		try {
			const result = await api.delete(`goals/key-results/tasks/checklist/items/${id}?organization_id=${userDetails?.organizationId}&task_id=${currentTask[0]?.id}`,
			{
				headers: {
					'Authorization': token.Authorization
				}
			})
			if(result.status === 200){
				getChecklist()
				await getTaskDetail(currentTask[0]?.id);
			}			
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	
	}

	const updateItemIndex = async (checklist_id,item_id,origin,destination) => {
		try {
      const response = await api.patch(`/goals/key-results/tasks/checklist/items/update-index?organization_id=${userDetails?.organizationId}`, {
				checklist_id:checklist_id,
				item_id:item_id,
        source: origin,
        destination: destination,        
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })
			if(response.status === 200){
				getChecklist()
			}      
    } catch (error) {
      console.log(error.response)
    }
	}
	function handleNewCard() {
		setNewChecklistTitle("")
		setNewCard((value)=>{
			return !value
		})
		

	}

	function handleChangeTextareaChecklistTitle(event) {
		setNewChecklistTitle(event.target.value)
		
	}

	function handleChangeSaveChecklist(body) {
		saveChecklist(body)
		handleNewCard()
	}
	const handleDragChecklist = async (result) => {
		console.log("/result dragEnd",result)
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const checklist_id = result.draggableId
    const source = result.source.index
    const destination = result.destination.index
		console.log("/origin/ ",source,"destino", destination, "checklist_id",checklist_id)
    if (destination === source) return; // Se moveu para a mesma posição

    // const keyResultFiltered = checklist.find(kr => Number(kr.id) === Number(checklist_id))

    const newItems = Array.from(checklist);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
		console.log("/checklist ordered?",newItems)

    setChecklist(newItems);

    try {
      const response = await api.patch(`/goals/key-results/tasks/checklist/update-index?organization_id=${userDetails?.organizationId}`, {
				task_id:currentTask[0]?.id,
				checklist_id:checklist_id,
        source: source,
        destination: destination,        
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })
			if(response.status === 200){
				getChecklist()
			}      
    } catch (error) {
      console.log(error.response)
    }

  };
	

	const getTaskItens = async () => {
		try {
			const result = await api.get(`goals/key-results/tasks/${currentTask[0]?.id}/item?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setTaskList(result?.data?.task_itens)
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	const createTaskItens = async () => {
		try {
			const payload = {
				title: checkListTitle,
				is_checked: false
			};
			const result = await api.post(`goals/key-results/tasks/${currentTask[0]?.id}/item?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});
			setTaskList([...taskList, result?.data[0]]);
			setCanSubmit(true)
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const removeTaskItem = async (itemId) => {
		try {
			const result = await api.delete(`goals/key-results/tasks/item/${itemId}/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			if (result?.data?.statusCode === 200) {
				setTaskList(taskList.filter(item => item.id !== itemId));
				setEdit(false);
				setItemId(-1)
				setIsChecked(false);
				if (krType === true && taskList.length === 1) {
					setValue(0)
				}
				setCheckListTitle('');
				setCanSubmit(true)
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	const updateTaskItem = async (itemId, isChecked, newTitle) => {
		try {
			const payload = {
				title: newTitle,
				is_checked: isChecked
			};
			const result = await api.put(`goals/key-results/tasks/item/${itemId}/?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			if (result.status === 200) {
				setCanSubmit(true)
				getTaskItens();
				setEdit(false);
			}

		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	const handleChangeCheckTask = (e, task) => {
		if (task) {
			updateTaskItem(task?.id, e.target.checked, task?.title);
		}
	}

	const handleCheckReadComment = async () => {
		try {

			const commentsNotRead = currentTask[0]?.task_comments.filter(comment => comment.read === false)

			if (commentsNotRead.length === 0) {
				return
			}

			const commentsUser = commentsNotRead.filter(comment => comment.owner_id === userDetails?.id)
			if (commentsNotRead.length === commentsUser.length) {
				return
			}

			const result = await api.put(`goals/key-results/tasks/${currentTask[0]?.id}/comment/?organization_id=${userDetails?.organizationId}`, {},
				{
					headers: {
						'Authorization': token.Authorization
					}
				});

			if (result.status === 200) {
				await getTaskDetail(currentTask[0]?.id);
			}

		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const handleAddCheckList = (e) => {
		if (e.key === 'Enter' && checkListTitle !== '') {
			const checkIfExists = taskList?.filter(
				task => task?.title?.toLowerCase() === checkListTitle.toLowerCase()
			)

			if (checkIfExists.length === 0) {
				// setForm({ ...form, checkListItems: [...form.checkListItems, checkListTitle] });
				if (!edit) {
					createTaskItens();
				} else {
					updateTaskItem(itemId, isChecked, checkListTitle);
				}
				setCheckListTitle('');
			}
		}
	}

	const handleRemoveCheckList = (item) => {
		if (item) {
			removeTaskItem(item?.id)
		}
	}

	const handleChange = e => {
		setFormEdit({
			...formEdit,
			[e.target.name]: e.target.value,
		})
		setSubmitted(true)
	}

	const handleEdit = (item) => {

		setEdit(!edit);

		if (item) {
			setItemId(item?.id);
			setCheckListTitle(item?.title);
			setIsChecked(item?.is_checked);
			setInputFocus();
		} else {
			setItemId(-1)
			setIsChecked(false);
			setCheckListTitle('');
		}

	};

	// Comments 
	const getComments = async () => {
		try {
			const result = await api.get(`goals/key-results/tasks/${currentTask[0]?.id}/comment?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setCommentsList(result?.data?.comments);
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	// Comments 
	const getHistoric = async () => {
		try {
			const response = await api.get(`goals/key-results/tasks/${currentTask[0]?.id}/historic?organization_id=${userDetails?.organizationId}`,
				{
					headers: {
						'Authorization': token.Authorization
					}
				})

			if (response.status === 200) {
				setHistoricList(response?.data?.historic);
			} else {
				setHistoricList([])
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			alertActions.error(error)
		}

	}

	const createComment = async (messageFormattedFull) => {
		try {

			const payload = {
				owner_id: currentTask[0]?.responsible_id,
				comment: messageFormattedFull,
				mentions: commentMention.mentions,
				datetime: new Date()
			};
			const result = await api.post(`goals/key-results/tasks/${currentTask[0]?.id}/comment?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});
			if (result.status === 200) {
				getComments();
				getHistoric();
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const updateComment = async (comment_id, owner_id, newCommentTitle) => {

		if (!comment_id && !owner_id && !newCommentTitle) {
			//alertActions.error(t('goals.errors.create_goal'))
			return;
		}

		try {
			const payload = {
				owner_id: owner_id,
				comment: newCommentTitle,
				mentions: commentMention.mentions,
				datetime: new Date()
			};
			const result = await api.put(`goals/key-results/tasks/comment/${comment_id}/?organization_id=${userDetails?.organizationId}`, payload, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			if (result.status === 200) {
				getComments();
				setEditComment(false);
			}

		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}

	}

	const handleCreateComment = (e) => {
		if (e.key === 'Enter' && commentMention.message !== '' && !commentMention.message.includes('\n')) {
			const messageFormatted = commentMention.message?.replaceAll('[', '')?.replaceAll(']', '')

			let messageFormattedFull = messageFormatted
			for (let mention of commentMention.mentions) {
				messageFormattedFull = messageFormattedFull.replaceAll(`{${mention}}`, '')
			}

			const checkIfExists = commentsList?.filter(
				comment => comment?.title?.toLowerCase() === messageFormattedFull.toLowerCase()
			)
			if (checkIfExists.length === 0) {
				if (!editComment) {
					createComment(messageFormattedFull);
				} else {
					updateComment(commentId, commentOwnerId, messageFormattedFull);
				}
				setCommentMention({
					message: '',
					mentions: []
				});
			}
		}
	}

	const handleEditComment = (isEdit) => {
		setEditComment(!editComment);
		if (isEdit) {
			setCommentId(currentComment?.id)
			setCommentOwnerId(currentComment?.owner_id)
			setCommentMention({
				message: currentComment?.comment,
				mentions: commentMention.mentions
			});
			setInputFocus();
		} else {
			setCommentMention({
				message: '',
				mentions: []
			});
			setCurrentComment({});
		}
	}

	const handleDeleteCurrentTask = async (e) => {
		// eslint-disable-next-line no-restricted-globals
		const confimation = confirm(t("alert.warning_delete_task"))
		if (confimation === true) {
			try {
				await api.delete(`goals/key-results/tasks/${currentTask[0].id}/?organization_id=${userDetails?.organizationId}`, {
					headers: {
						'Authorization': token.Authorization
					}
				});
				props.updateGoalsDetails()
				//chama a funao que fecha o modal de detalhe  
				handleCloseTaskDetails()
			} catch (error) {
				console.log('Response Error -> ', error);
				// alertActions.error(error)
			}
		}

	}

	const deleteComment = async (comment) => {
		try {
			const result = await api.delete(`goals/key-results/tasks/comment/${comment?.id}/?organization_id=${userDetails?.organizationId}`,
				{
					headers: {
						'Authorization': token.Authorization
					}
				}
			);
			if (result?.data?.statusCode === 200) {
				getComments();
				setCommentMention({
					message: '',
					mentions: []
				});
				handleCloseMenu();
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const handleDeleteComment = () => {
		if (currentComment) {
			deleteComment(currentComment)
		}
	}

	// Attachments
	const getAtachments = async () => {
		try {
			const result = await api.get(`goals/key-results/tasks/${currentTask[0]?.id}/attachments?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setAttachmentsList(result?.data)
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const deleteAttachment = async (attach) => {
		try {
			const result = await api.delete(`goals/key-results/tasks/attachments/${attach?.id}/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			});

			if (result?.data?.statusCode === 200) {
				getAtachments();
				handleCloseMenuAttachment();
			}
		} catch (error) {
			console.log('Response Error -> ', error);
			// alertActions.error(error)
		}
	}

	const handleDeleteAttachment = () => {
		if (currentAttachment) {
			deleteAttachment(currentAttachment)
		}
	}

	const handleEditCurrentTask = () => {
		setOpenModalNewTaskEdit(true)
		setAnchorElOptionsTask(null)
	}

	async function updateTask() {
		try {
			setIsUpdating(true)
			const co_responsibles = assistants.reduce((acc, item) => {
				return [...acc, item.id]
			}, [])

			await api.put(
				`/goals/key-results/tasks/${currentTask[0]?.id}?organization_id=${userDetails?.organizationId}`,
				{
					action_id: 6,
					datetime: new Date(),
					from: JSON.stringify({
						title: currentTask[0]?.title,
						description: currentTask[0]?.description || '',
						responsible: currentTask[0]?.responsible_id,
						date_start: currentTask[0]?.date_start ? currentTask[0]?.date_start.split('T')[0] : '',
						date_end: currentTask[0]?.date_end ? currentTask[0]?.date_end.split('T')[0] : '',
						priority: currentTask[0]?.priority?.length > 0 ? currentTask[0]?.priority[0].id : '',
						complexity: currentTask[0]?.complexity?.length > 0 ? currentTask[0]?.complexity[0].id : '',
						category: '39473',
						tags: currentTask[0]?.tags || [],
						co_responsibles: currentTask[0]?.co_responsibles || [],
						weight: currentTask[0]?.weight
					}),
					to: JSON.stringify({
						title: currentTask[0]?.title,
						description: currentTask[0]?.description || '',
						date_start: formEdit.date_start,
						date_end: formEdit.date_end,
						priority_id: formEdit.priority,
						complexity_id: formEdit.complexity,
						mu_id: formEdit.responsible,
						category_id: '39473',
						tags: currentTask[0]?.tags || [],
						weight: formEdit?.weight,
						co_responsibles: currentTask[0]?.co_responsibles || [],
					}),
					title: currentTask[0]?.title,
					description: currentTask[0]?.description || '',
					date_start: formEdit.date_start,
					date_end: formEdit.date_end,
					priority_id: formEdit.priority,
					complexity_id: formEdit.complexity,
					mu_id: formEdit.responsible,
					category_id: '39473',
					tags: currentTask[0]?.tags || [],
					weight: formEdit?.weight,
					co_responsibles
				}, {
				headers: {
					'Authorization': token.Authorization
				}
			}
			)
			await getTaskDetail(currentTask[0]?.id);
			await getHistoric()
		} catch (error) {
			console.log(error.response)
		} finally {
			setSubmitted(false)
			setIsUpdating(false)
		}
	}

	useEffect(() => {
		// if (!currentTask){
		setValue(Number(currentTask[0]?.progress))
		getTaskItens();
		getChecklist();
		getAtachments();
		getHistoric()
		getComments();
		usersActions.getAll()
		// }

	}, [])


	// useEffect(() => {
	// 	if (!canSubmit) {
	// 		updateProgressTask();
	// 	}
	// 	setCanSubmit(false)
	// }, [taskList])


	useEffect(() => {
		if (canSubmit) {
			setIsUpdatingProgress(true)

			const delayDebounceFn = setTimeout(() => {
				updateProgressTask();
				props.updateGoalsDetails(true);
			}, 1000)
			return () => clearTimeout(delayDebounceFn)
		}
	}, [value, taskList, canSubmit])

	useEffect(() => {
		if (formEdit && submitted) {
			updateTask()
		}
	}, [formEdit, submitted])

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				className={`${classes.modal} modal-detail-task`}
				onClose={handleClose}
				disableEnforceFocus
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					style: { backgroundColor: '#205B6F', opacity: 0.8 }
				}}
				sx={{ border: 'none' }}
				style={{ outline: 0 }}
			>

				<Slide direction="down" in={open} style={{ outline: 0 }} mountOnEnter unmountOnExit>
					<div className={classes.paper}>
						<div className="box-button-close">
							{(isEditable && userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id))) && (
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={(e) => handleClickMenuOptionsTask(e)}
									style={{ padding: 4, color: '#98ACC1' }}
								>
									<MoreHorizIcon fontSize='medium' />
								</IconButton>
							)}

							<button onClick={handleClose} className="close" type="button"><IconClose /></button>
						</div>

						<Menu
							id={`menu-options-task`}
							anchorEl={anchorElOptionsTask}
							keepMounted
							open={Boolean(anchorElOptionsTask)}
							onClose={handleCloseMenuOptionsTask}
							getContentAnchorEl={null}
							anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
							transformOrigin={{ horizontal: "center" }}
						>
							<MenuItem onClick={() => handleEditCurrentTask()}>{t('modalDetailTask.edit')}</MenuItem>
							<MenuItem onClick={(e) => handleDeleteCurrentTask()}>{t('modalDetailTask.delete')}</MenuItem>
						</Menu>

						<Grid container className='container-detail-task'>
							<Grid item xs={8} className='box-left' >
								<Grid container>
									<Grid item xs={12}>
										{
											isLoading
												?
												<>
													<Skeleton style={{ marginTop: 8 }} height={20} variant="rounded" />
													<Skeleton style={{ marginTop: 8 }} variant="text" />
													<Skeleton variant="text" />
													<Skeleton variant="text" />
												</>
												:
												<>
													<h5 className='title-task'>{currentTask[0]?.title}</h5>
													<p className='description-task'>{currentTask[0]?.description}</p>
												</>
										}
									</Grid>
									<Grid item xs={12}>
										<Box sx={{ width: '100%', typography: 'body1' }}>

											<Tabs
												defaultTab={userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) ? "0" : "1"}
												onChange={(tabId) => {
													handleChangeTabs(tabId)
												}}
											>
												<TabList className="custom-tab-list">
													{
														userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (
															<Tab tabFor="0">{t('modalDetailTask.checklist')}</Tab>
														)
													}
													<Tab tabFor="1" onClick={handleCheckReadComment}>
														{t('modalDetailTask.comments')}
													</Tab>
													{
														userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (
															<Tab tabFor="2">{t('modalDetailTask.historical')}</Tab>
														)
													}
													{
														userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (
															<Tab tabFor="3">{t('modalDetailTask.attachments')}</Tab>
														)
													}
												</TabList>

												{
													userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (
														<TabPanel tabId="0" className="tab-checklist-container">
															{newCard && (
																<div className='card-header-edit'>
																	<div className='edit-title'>
																		<textarea className='item-title-input' onChange={handleChangeTextareaChecklistTitle} value={newChecklistTitle} placeholder={t('modalDetailTask.placeholder_add_checklist')} rows={1}> </textarea>
																		<div className='botton-action-2'>
																			<div className='button' onClick={()=>{handleChangeSaveChecklist({task_id:currentTask[0]?.id,title:newChecklistTitle})}}>
																			{/* handleSaveItem({task_id:id,title:newCardTitle}) */}
																			{t('modalDetailTask.add')}
																			</div>
																			<X style={{cursor:"pointer"}} size={24} color='#4F7091' onClick={()=>{																			
																				handleNewCard()
																			}} />
																		</div>
																	</div>
																
																	</div>  
															)
															
															}
															{!newCard && (
																<div style={{maxWidth:"250px", marginBottom:'10px'}}>
																	<div className='buttonAdd' onClick={()=>{handleNewCard()}}>																	
																		{t('modalDetailTask.add_checklist')}
																	</div>
																</div>
															)

															}

														{/* <ListDivider /> */}
														<DragDropContext onDragEnd={handleDragChecklist}>
															<Droppable droppableId='checklist-1'>
																{(provided) => (
																	//{...provided.droppableProps} >
																	<div className='list-cards' ref={provided.innerRef}> 

																		{checklist.length > 0 && checklist.map((item,idx) => (
																				<Draggable 
																					key={item.id} 
																					draggableId={String(item.id)} 
																					index={idx}
																				>

																					{(provided, snapshot) => (
																						<CardChecklist																											
																							id={item.id}
																							title={item.title}
																							items={item?.items || []}
																							saveChecklist={saveChecklist}
																							deleteChecklist={deleteChecklist}
																							saveItem={saveItem}
																							getChecklist={getChecklist}
																							innerRef={provided.innerRef}
																							provided={provided}
																							snapshot={snapshot}
																							user={userDetails}
																							updateItemIndex={updateItemIndex}
																							deleteItem={deleteItem}
																							
																					/>
																					)}
																										
																				</Draggable>
																									
																		))																
																		}
																		{provided.placeholder}
																	</div>
																)

																}
																
															</Droppable>
														</DragDropContext>
															

															{/* <div className='new-check-container'>

																{
																	edit && (
																		<XCircle
																			style={{
																				position: 'absolute',
																				bottom: "16px",
																				right: "16px"
																			}}
																			size={20}
																			weight="fill"
																			color="#FF5151"
																			onClick={() => handleEdit()}
																		/>
																	)
																}

																<label htmlFor="input-check-title">{t('tasks.check_title')}</label>
																<input
																	value={checkListTitle}
																	type="text"
																	id="input-check-title"
																	className='input-check-title'
																	placeholder={t('tasks.input_placeholder')}
																	onKeyDown={(e) => handleAddCheckList(e)}
																	autoComplete="off"
																	onChange={e => setCheckListTitle(e.target.value)}
																	ref={inputRef}
																/>

															</div>
															{
																taskList.length > 0 &&
																(<div className="progress-checklist-container">
																	<div className="percent-label">
																		{parseInt(taskList.filter(task => task.is_checked === true).length * 100 / taskList.length) + '%'}
																	</div>
																	<div className="progress-main">
																		<div
																			className={`bar-progress completed`}
																			style={{ width: taskList.filter(task => task.is_checked === true).length * 100 / taskList.length + '%' }}
																		/>
																	</div>
																</div>)
															}


															<div className='checklist-task-container'>
																{taskList.length > 0 && taskList.map((task, index) => (
																	<div key={index} className='checklist-task'>
																		<ul>
																			<li>
																				<div className='check'>
																					<FormControlLabel
																						control={
																							<GreenCheckbox
																								checked={
																									task?.is_checked
																								}
																								disabled={isUpdatingProgress}
																								onChange={(e) => handleChangeCheckTask(e, task)}
																								name={`checkboxTask${task?.id}`}
																								color="primary"
																							/>
																						}
																						label={<Typography style={{ color: '#4F7091', fontSize: '14px' }}>{task?.title}</Typography>}
																					/>
																				</div>
																				<div>
																					<PencilSimple
																						size={18}
																						weight="fill"
																						onClick={() => handleEdit(task)}
																						onMouseOver={({ target }) => target.style.color = "#187DFF"}
																						onMouseOut={({ target }) => target.style.color = "#98ACC1"}
																					/>
																					<TrashSimple size={18}
																						weight="fill"
																						onClick={() => handleRemoveCheckList(task)}
																					/>
																				</div>
																			</li>
																		</ul>
																	</div>
																))}
															</div> */}
														</TabPanel>
													)
												}

												<TabPanel tabId="1" className="tab-comments-container">

													<div className='comments-container'>
														{commentsList.length > 0 && commentsList.map((comment, index) => (
															<div key={index} className='comment-box'>
																<ul>
																	{comment.owner_id === userDetails.mu_id && comment.read === false && (
																		<div className="box-three-points">
																			<IconButton
																				aria-label="delete"
																				color="primary"
																				onClick={(e) => handleClickMenu(e, comment)}
																				style={{ padding: 4 }}
																			>
																				<MoreHorizIcon fontSize='medium' />
																			</IconButton>
																		</div>
																	)}
																	<li>
																		<div className='comment'>
																			<div className="avatarMU">
																				<Avatar alt={comment?.username} src={getAvatarUrl({
																					name: comment?.username
																				})} style={{ width: 22, height: 22 }} />
																			</div>
																			<div className="infoComment">
																				<div>
																					<span>{comment?.username}</span>
																					<span>{befectiveFormatDate(comment?.created_at, i18n.language, "C")}</span>
																				</div>
																				<div>
																					<p>{comment?.comment}</p>
																				</div>
																			</div>
																		</div>
																	</li>
																</ul>
															</div>
														))}
													</div>

													<Menu
														id={`menu-comment`}
														anchorEl={anchorEl}
														keepMounted
														open={Boolean(anchorEl)}
														onClose={handleCloseMenu}
														getContentAnchorEl={null}
														anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
														transformOrigin={{ horizontal: "center" }}
													>
														<MenuItem onClick={() => handleEditComment(true)}>{t('modalDetailTask.edit')}</MenuItem>
														<MenuItem onClick={handleDeleteComment}>{t('modalDetailTask.delete')}</MenuItem>
													</Menu>

													<div className='new-comment-container'>

														{
															editComment && (
																<XCircle
																	style={{
																		position: 'absolute',
																		bottom: "16px",
																		right: "16px"
																	}}
																	size={20}
																	weight="fill"
																	color="#FF5151"
																	onClick={() => handleEditComment(false)}
																/>
															)
														}

														<div className="mention-input">
															<MentionsInput
																value={commentMention.message}
																onChange={handleChangeComment}
																onKeyDown={(e) => handleCreateComment(e)}
																placeholder={t('tasks.input_placeholder_comment')}
																style={mentionsInputStyle}
															>

																<Mention
																	appendSpaceOnAdd={true}
																	markup="[__display__]{__id__}"
																	data={optionsColabs}
																	style={mentionStyle}
																/>
															</MentionsInput>
														</div>

													</div>

												</TabPanel>

												{
													userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (

														<TabPanel tabId="2" className="tab-historical-container">
															<Grid container className="grid-container-historical">
																{
																	historicList?.length > 0 && historicList?.map((historical, index) => (
																		<Grid key={index} item xs={12} className="row-historical">
																			<Box className="avatarMU">
																				<Avatar alt={historical?.username} src={getAvatarUrl({
																					name: historical?.username
																				})} style={{ width: 22, height: 22 }} />
																			</Box>
																			<Box className="content-historical">
																				<div>
																					<span>{historical?.username}</span>
																					<span>{befectiveFormatDate(historical?.datetime, i18n.language, "C")}</span>
																				</div>
																				<div>
																					<p
																						dangerouslySetInnerHTML={{ __html: historical[`title_${i18n.language}`] }}
																					/>
																				</div>
																			</Box>
																		</Grid>
																	))
																}

															</Grid>
														</TabPanel>
													)
												}

												{
													userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && (
														<TabPanel tabId="3" className="tab-comments-container">
															<div className='comments-container'>
																{attachmentsList?.length > 0 && attachmentsList.map((file, index) => (
																	<div key={index} className='comment-box'>
																		<ul>
																			{file.owner_id === userDetails.id && (
																				<div className="box-three-points">
																					<IconButton
																						aria-label="delete"
																						color="primary"
																						onClick={(e) => handleClickMenuAttachment(e, file)}
																						style={{ padding: 4 }}
																					>
																						<MoreHorizIcon fontSize='medium' />
																					</IconButton>
																				</div>
																			)}

																			<li>
																				<div className='comment'>
																					<div className="avatarMU">
																						<Avatar alt={file?.username} src={getAvatarUrl({
																							name: file?.username
																						})} style={{ width: 22, height: 22 }} />
																					</div>
																					<div className="infoComment">
																						<div className="username-date-info">
																							<span>{file?.username}</span>
																							<span>{befectiveFormatDate(file?.created_at, i18n.language, "C")}</span>
																						</div>

																						<Tooltip title={file?.original_name}>
																							<a href={file?.url_attachment} download>
																								<div>
																									<AttachFile fontSize='small' />
																								</div>

																								<div>
																									{
																										file?.original_name?.length > 40
																											?
																											file?.original_name?.substring(0, 40) + '[...].' + file?.original_name?.split('.').slice(-1)[0]


																											:
																											file?.original_name
																									}
																								</div>

																							</a>
																						</Tooltip>

																					</div>
																				</div>
																			</li>
																		</ul>
																	</div>
																))}
															</div>

															<Menu
																id={`menu-attachment`}
																anchorEl={anchorElAttachment}
																keepMounted
																open={Boolean(anchorElAttachment)}
																onClose={handleCloseMenuAttachment}
																getContentAnchorEl={null}
																anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
																transformOrigin={{ horizontal: "center" }}
															>
																{/* <MenuItem onClick={() => handleEditComment(true)}>{t('modalDetailTask.edit')}</MenuItem> */}
																<MenuItem onClick={handleDeleteAttachment}>{t('modalDetailTask.delete')}</MenuItem>
															</Menu>

															<div className='new-comment-container'>
																<label className='input-file-box'>

																	<input
																		type="file"
																		multiple
																		onChange={onChangeInputFile}
																		accept="image/png, image/jpeg,application/gzip,application/pdf,application/xlsx,application/vnd.ms-excel,application/x-zip-compressed,application/vnd.rar,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
																	/>
																	<AttachFile fontSize='small' />
																	<div>
																		{t('tasks.attach_file')}
																	</div>

																</label>

																<span style={{ color: '#1E6B7F', fontSize: '10px' }}>
																	{t('tasks.file_message')}
																</span>

															</div>
														</TabPanel>
													)
												}

											</Tabs>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={4} className='box-right'>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.start')}</Typography>
											{
												isLoading
													? (
														<Skeleton variant="text" width={80} />
													) : (
														currentTask[0]?.date_start !== null ? (
															<Box className='value'>

																<BefectiveDatePicker
																	userDetails={userDetails}
																	date_value={currentTask[0]?.date_start.split("T")[0]}
																	formEdit={formEdit}
																	setFormEdit={setFormEdit}
																	setSubmitted={setSubmitted}
																	field="date_start"
																	minDate={date_start}
																	maxDate={date_end}
																	disabledValue={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) ? "disabled" : ""}
																/>

															</Box>
															// <Box className='value'>{moment(currentTask[0]?.date_start).format('DD/MM/YYYY')}</Box>
														) : (
															<Box className='value'>
																<div
																	className="box-time-on-modal"
																	title={t('modalDetailTask.edit')}
																	onClick={(e) => handleClickTaskOnTableEditDateStart(e, currentTask)}
																>
																	<span style={{ padding: '0 10px' }}>---</span>
																</div>
															</Box>
														)
													)
											}
										</Grid>
										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.end')}</Typography>
											{
												isLoading
													? (
														<Skeleton variant="text" width={80} />
													)
													: (
														currentTask[0]?.date_end !== null ? (
															<Box className='value'>

																<BefectiveDatePicker
																	userDetails={userDetails}
																	date_value={currentTask[0]?.date_end.split("T")[0]}
																	formEdit={formEdit}
																	setFormEdit={setFormEdit}
																	setSubmitted={setSubmitted}
																	field="date_end"
																	minDate={date_start}
																	maxDate={date_end}
																	disabledValue={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) ? "disabled" : ""}

																/>
															</Box>

														) : (
															<Box className='value'>
																<div
																	className="box-time-on-modal"
																	title={t('modalDetailTask.edit')}
																	onClick={(e) => handleClickTaskOnTableEditDateEnd(e, currentTask)}
																>
																	<span style={{ padding: '0 10px' }}>---</span>
																</div>
															</Box>
														)
													)
											}
										</Grid>

									</Grid>
								</Box>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.priority')}</Typography>
											{
												isLoading
													? (
														<Skeleton variant="text" width={80} />
													) : (
														<MuiSelect
															style={{ color: '#98ACC1' }}
															displayEmpty
															disableUnderline
															inputProps={{ "aria-label": "Without label" }}
															name="priority"
															id="priority"
															value={currentTask[0]?.priority.length > 0 ? currentTask[0]?.priority[0].id : ''}
															onChange={handleChange}
															disabled={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id))}

														>
															<MenuItem value="">{t('goals.modal_goal.select')}</MenuItem>
															<MenuItem value={1}>{t('tasks.low')}</MenuItem>
															<MenuItem value={2}>{t('tasks.medium')}</MenuItem>
															<MenuItem value={3}>{t('tasks.high')}</MenuItem>
														</MuiSelect>
													)
											}
										</Grid>
										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.complexity')}</Typography>
											{
												isLoading
													? (
														<Skeleton variant="text" width={80} />
													) : (
														<MuiSelect
															style={{ color: '#98ACC1' }}
															displayEmpty
															disableUnderline
															inputProps={{ "aria-label": "Without label" }}
															name="complexity"
															id="complexity"
															value={currentTask[0]?.complexity.length > 0 ? currentTask[0]?.complexity[0].id : ''}
															onChange={handleChange}
															disabled={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id))}

														>
															<MenuItem value="">{t('goals.modal_goal.select')}</MenuItem>
															<MenuItem value={1}>{t('tasks.low')}</MenuItem>
															<MenuItem value={2}>{t('tasks.medium')}</MenuItem>
															<MenuItem value={3}>{t('tasks.high')}</MenuItem>
														</MuiSelect>
													)
											}
										</Grid>
									</Grid>
								</Box>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.responsible')}</Typography>
											<Box className='value'>
												<MuiSelect
													style={{ color: '#98ACC1', maxWidth: '120px' }}
													displayEmpty
													disableUnderline
													inputProps={{ "aria-label": "Without label" }}
													name="responsible"
													id="responsible"
													value={currentTask[0]?.responsible_id}
													onChange={handleChange}
													disabled={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id))}
												>
													{teammatesResponsibleOp.map(item => (
														// item.value === currentTask[0]?.responsible_id ? (
														// 	<MenuItem value={item.value}>
														// 		<Tooltip title={item.label}>
														// 			<Avatar alt={item.label} src={getAvatarUrl({
														// 				name: item.label
														// 			})} style={{ width: 22, height: 22 }} />
														// 		</Tooltip>
														// 	</MenuItem>
														// ) : (
														<MenuItem value={item.value}>
															{item.label}
														</MenuItem>
														// )
													))}

												</MuiSelect>

											</Box>
										</Grid>
										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.co_responsibles')}</Typography>
											{currentTask[0]?.co_responsibles.length
												? (
													<Box className='value'>
														<AvatarGroup max={4} onClick={(e) => userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && handleOpenModalAddTeamTask(e)}>
															{currentTask[0]?.co_responsibles?.map((co_responsible, index) => (
																<Tooltip key={index} title={co_responsible?.username}>
																	<Avatar alt={co_responsible?.username} src={getAvatarUrl({
																		name: co_responsible?.username
																	})} style={{ width: 22, height: 22 }} />
																</Tooltip>
															))
															}
														</AvatarGroup>
													</Box>
												) : (
													<Box className='value'>
														<div
															className="box-time-on-modal"
															title={t('modalDetailTask.edit')}
															onClick={(e) => userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && handleOpenModalAddTeamTask(e)}
														>
															<span style={{ padding: '0 10px' }}>---</span>
														</div>
													</Box>
												)
											}

										</Grid>
									</Grid>
								</Box>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.tags')}</Typography>
											<Box className='value'>
												{currentTask[0]?.tags?.length > 0 ? (
													<div>
														<TagsList tags={currentTask[0]?.tags} all={true} />
													</div>
												) : (
													'---'
												)}
											</Box>
										</Grid>

										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.weight')}</Typography>
											{
												isLoading
													? (
														<Skeleton variant="text" width={80} />
													) : (
														<MuiSelect
															style={{ color: '#98ACC1', textAlign: 'right' }}
															displayEmpty
															disableUnderline
															inputProps={{ "aria-label": "Without label" }}
															name="weight"
															id="weight"
															value={currentTask[0]?.weight || 1}
															onChange={handleChange}
															disabled={!userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id))}
														>
															<MenuItem value={1}>1</MenuItem>
															<MenuItem value={2}>2</MenuItem>
															<MenuItem value={3}>3</MenuItem>
															<MenuItem value={4}>4</MenuItem>
														</MuiSelect>
													)
											}
										</Grid>
									</Grid>
								</Box>

								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.progress')}</Typography>
											{console.log("/currentTask",currentTask[0])}
											{
												
												checklist?.length > 0
													? (
														<Box className='value'>
															<span>{Number(currentTask[0].progress || 0) + '%'}</span>
														</Box>
													) : (
														<Box className='value'>
															<div
																className="box-time-on-modal"
																title={t('modalDetailTask.edit')}
																onClick={(e) => { userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && handleClickTaskOnTable(e, currentTask) }}
															>
																<span>{value + '%'}</span>
																<PencilSimple size={16} color="#4F7091" />
															</div>
														</Box>
													)
											}

										</Grid>

										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.status')}</Typography>
											<Box className={`value color-${getStatusClass(currentTask[0]?.status[0]?.id)}`}>{t(`tasks.${statusTask[currentTask[0]?.status[0]?.id - 1]}`)}</Box>
										</Grid>

									</Grid>
								</Box>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={7}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.created_at')}</Typography>
											<Box className='value' style={{ paddingTop: '4px', paddingBottom: '4px', color: '#4F7091' }}>{befectiveFormatDate(currentTask[0]?.created_at, i18n.language)}</Box>
										</Grid>
										<Grid item xs={4}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.time')}</Typography>
											<Box className='value'>
												<div
													className="box-time-on-modal"
													title={t('modalDetailTask.edit')}
													onClick={(e) => { userDetails?.tasksPermissions?.includes(parseInt(currentTask[0]?.id)) && handleClickTaskOnModalToTime(e, currentTask) }}
												>
													<span>{currentTask[0]?.time}</span>
													<PencilSimple size={16} color="#4F7091" />
												</div>
											</Box>
										</Grid>
									</Grid>
								</Box>
								<Box marginBottom={'16px'}>
									<Grid container>
										<Grid item xs={12} style={{ maxHeight: '90px', overflowY: 'auto' }}>
											<Typography className='label' variant='subtitle2' >{t('modalDetailTask.linked')}</Typography>
											<Box className='value' style={{ color: '#4F7091', lineHeight:'18px' }}>{currentTask[0]?.vinculo[0]?.goal_name}/{currentTask[0]?.vinculo[0]?.kr_name}</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</div>
				</Slide>
			</Modal>

			{currentTaskOnModalToTime &&
				<InputTime
					anchorEl={anchorElTaskOnModalToTime}
					handleCloseMenu={handleCloseMenuTaskOnModalToTime}
					currentTask={currentTaskOnModalToTime[0]}
					updateTaskDetails={getTaskDetail}
				/>
			}

			{/* {currentTaskOnTable && Boolean(anchorElTaskOnEditValue) && (
				<InputValue
					anchorEl={anchorElTaskOnEditValue}
					handleCloseMenu={handleCloseMenuEditValue}
					value={taskValue}
					setValue={setTaskValue}
					end_value={end_value}
					isUpdatingProgress={isUpdating}
					typeInput={typeInput}
					close={true}
				/>
			)} */}

			{currentTaskOnTable && Boolean(anchorElTaskOnEditDateStart) && (
				<InputDate
					anchorEl={anchorElTaskOnEditDateStart}
					handleCloseMenu={handleCloseMenuEditDateStart}
					formEdit={formEdit}
					value={formEdit.date_start}
					setFormEdit={setFormEdit}
					isUpdating={isUpdating}
					setSubmitted={setSubmitted}
					field="date_start"
					date_end={date_end}
					date_start={date_start}
				/>
			)}

			{currentTaskOnTable && Boolean(anchorElTaskOnEditDateEnd) && (
				<InputDate
					anchorEl={anchorElTaskOnEditDateEnd}
					handleCloseMenu={handleCloseMenuEditDateEnd}
					formEdit={formEdit}
					value={formEdit.date_end}
					setFormEdit={setFormEdit}
					isUpdating={isUpdating}
					setSubmitted={setSubmitted}
					field="date_end"
					date_end={date_end}
					date_start={date_start}
				/>
			)}

			{currentTaskOnTable &&
				(

					<Menu
						className="menu-task-on-table"
						id={`menu-options-goals`}
						anchorEl={anchorElTaskOnTable}
						keepMounted
						open={Boolean(anchorElTaskOnTable)}
						onClose={handleCloseMenuTaskOnTable}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
						transformOrigin={{ horizontal: "center" }}
						style={{ width: 100, padding: 10, }}
					>
						<div className={`box-progress ${isUpdating && 'disabled-div'}`}>
							<label style={{ color: getColorStatusKr(currentTask[0]?.status[0]?.id) }}>{String(value) + '%'}</label>
							<PrettoSlider style={{ color: getColorStatusKr(currentTask[0]?.status[0]?.id) }} width={16} height={16} aria-label="pretto slider" value={typeof value === 'number' ? value : 0} onChange={handleSliderChange} />
						</div>
					</Menu>)
			}

			<ModalAddTeamTask
				classes={classes}
				openModalAddTeamTask={openModalAddTeamTask}
				setOpenModalAddTeamTask={setOpenModalAddTeamTask}
				assistants={assistants}
				setAssistants={setAssistants}
				groups={groups}
				setSubmitted={setSubmitted}
				assistantsBackup={assistantsBackup}
			/>

		</div>

	)

};

function mapStateToProps(state) {

	return {
		users: state.users.items,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		alertActions: bindActionCreators(alertActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalDetailTask));
