import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeaderControlSolicitations } from "../../_components/checkin_page/control_solicitations/CardsHeader";
import { CheckinTableControlSolicitations } from '../../_components/checkin_page/control_solicitations/CheckinTable';
import { authHeader, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import './fullcalendar.scss';
import style from './styles.module.scss';
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinControlSolicitations(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, teammates, filter } = props
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reasons, setReasons] = useState([])
  const [fetching, setFetching] = useState(true);
  const [openModalJustifyWork, setOpenModalJustifyWork] = useState(false)
  const [openModalHelp, setOpenModalHelp] = useState(false)
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [solicitations, setSolicitations] = useState([]);
  const [statsSolicitations, setStatsSolicitations] = useState([]);

  const [orderedColumn, setOrderedColumn] = useState('username')
  const [order, setOrder] = useState('asc');


  const orderColumn = (column, type) => {

    const orderedData = orderData(solicitations, column, type, orderedColumn, setOrderedColumn, order, setOrder)

    setSolicitations(orderedData);
  };

  async function getReasons() {
    try {
      const response = await api.get('/checkin/adjustments_reasons', {
        headers: authHeader()
      })

      setReasons(response.data)
    } catch (error) {
      console.log(error.response)
      alertActions.error("Erro ao buscar a lista de motivos de justificativa!")
    }
  }

  async function getSolicitations(dateFilter = '', status = 'WAITING') {
    try {
      setFetching(true)
      const response = await api.get(`/checkin/solicitations?date=${dateFilter}&status=${status}`, {
        headers: authHeader()
      })
      setStatsSolicitations(response.data.stats);
      setSolicitations(response.data.solicitations);
      setFetching(false)
    } catch (error) {
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    document.title = `Befective | ${t('menu.check_in_remote')}`;
    getSolicitations()
    getReasons()
  }, []);

  const getCalendarData = async (fetchInfo, successCallback, failureCallback) => {
    try {
      const start_date = moment(fetchInfo.start).format('YYYY-MM-DD')
      const end_date = moment(fetchInfo.end).format('YYYY-MM-DD')

      const response = await api.get(`/checkin/solicitations?start_date=${start_date}&end_date=${end_date}&statusCalendar=WAITING`, {
        headers: authHeader()
      });

      successCallback(
        response.data.map(event => {
          return {
            title: event.qtd_solicitations + ' ajustes',
            date: moment(event.date_ref).format('YYYY-MM-DD')
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  function renderEventContent(eventInfo) {
    return (
      <div>
        <span style={{ color: '#FDBE2B', fontWeight: 500 }}>{eventInfo.event.title}</span>
      </div>
    )
  }

  const handleClickDate = async (e) => {

    let date = e.dateStr

    if (filter.singleDate) {
      date = filter.singleDate
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    await getSolicitations(date, 'WAITING')
    setShowCalendar(false);

  }

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar setOpenModalHelp={setOpenModalHelp} />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          setShowCalendar={setShowCalendar}
          showCalendar={showCalendar}
        />
        {
          !showCalendar
            ?
            <div className={style.containerCheckinPage}>
              {
                !fetching
                  ?
                  <>
                    <CardsHeaderControlSolicitations stats={statsSolicitations} getSolicitations={getSolicitations} />
                    <CheckinTableControlSolicitations
                      alertActions={alertActions}
                      userDetails={userDetails}
                      solicitations={solicitations}
                      updateListSolicitations={getSolicitations}
                      filter={filter}
                      reasons={reasons}
                      orderColumn={orderColumn} 
                      orderedColumn={orderedColumn} 
                      order={order}
                    />
                  </>
                  :
                  <GlobalSkeleton totalRowsStats={1} totalTables={1} />
              }

            </div>
            :
            <div id='calendarCheckinControlSolicitations' className={style.containerCheckinPage}>
              <Box padding={4} sx={{ background: '#fff' }}>

                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'title',
                    right: 'today prev,next'
                  }}
                  events={
                    (fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)
                  }
                  firstDay="0"
                  initialView='dayGridMonth'
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  dayMaxEvents={true}
                  dateClick={handleClickDate}
                  eventContent={renderEventContent}
                />
              </Box>
            </div>
        }



      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinControlSolicitations)