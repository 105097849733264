import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';

import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import IconTeammate from '../../_icons/IconTeammate';
import IconStart from '../../_icons/IconStart';
import IconTeam from '../../_icons/IconTeam';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import Widget15Skeleton from './Widget15Skeleton';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

function Widget15({
  currentDate,
  width,
  alertActions,
  filters
}) {
  const { t, i18n } = useTranslation('common');

  const [data, setData] = useState({
    total_productive_team: 0,
    total_teams: 0,
    percent_productive_teams: 0,
    total_productive_users: 0,
    total_users: 0,
    percent_productive_users: 0
  });

  const [fetching, setFetching] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/raking?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget15!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])



  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('home.working_awesomely')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('home.working_awesomely_tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
            </div>
            <div className={style.containerList}>
              <div className={style.itemList}>
                <div className={style.contentLeft}>
                  <div className={`${style.boxIcon} ${style.iconBlue}`}>
                    <IconTeam />
                  </div>
                  <div className={style.boxStats}>
                    <span>{t('home.teams_working_awesomely')}</span>

                    <div className={style.barContainer}>
                      <div className={style.bar} style={{ width: data.percent_productive_teams + '%' }}></div>
                    </div>
                  </div>
                </div>
                <div className={style.number}>{data.total_productive_team}/{data.total_teams || 0}</div>
              </div>
              <div className={style.itemList}>
                <div className={style.contentLeft}>
                  <div className={`${style.boxIcon} ${style.iconGreen}`}>
                    <IconTeammate />
                  </div>
                  <div className={style.boxStats}>
                    <span>{t('home.teammates_working_awesomely')}</span>

                    <div className={style.barContainer}>
                      <div className={style.bar} style={{ width: data.percent_productive_users + '%' }}></div>
                    </div>
                  </div>

                </div>
                <div className={style.number}>{data.total_productive_users}/{data.total_users || 0}</div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget15Skeleton />
      </Grid>
  )
};


function mapStateToProps(state) {

  return {
    today: state.stats.payload
  }

}

export default connect(mapStateToProps)(Widget15)
