import React from 'react';

const IconDate = props => {
    return (
             
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8">
        <path id="Icon_awesome-calendar-day" data-name="Icon awesome-calendar-day" d="M0,7.25A.75.75,0,0,0,.75,8h5.5A.75.75,0,0,0,7,7.25V3H0Zm1-3A.251.251,0,0,1,1.25,4h1.5A.251.251,0,0,1,3,4.25v1.5A.251.251,0,0,1,2.75,6H1.25A.251.251,0,0,1,1,5.75ZM6.25,1H5.5V.25A.251.251,0,0,0,5.25,0h-.5A.251.251,0,0,0,4.5.25V1h-2V.25A.251.251,0,0,0,2.25,0h-.5A.251.251,0,0,0,1.5.25V1H.75A.75.75,0,0,0,0,1.75V2.5H7V1.75A.75.75,0,0,0,6.25,1Z" fill="#7ca4b2"/>
        </svg>

    )
}

export default IconDate;