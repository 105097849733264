import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { ReportsPointsDetails } from '../../_components/checkin_page/reports_points/ReportsPointsDetails';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinReportsPointsDetailsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');


  const { mu_id } = useParams();

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataHistorical, setDataHistorical] = useState([])

  async function getInfoHistoricalByMU(dateFilter = moment(new Date()).format('YYYY-MM')) {
    try {
      setFetching(true)
      const response = await api.get(`/checkin/report/colab_points/details?mu_id=${mu_id}&date_ref=${dateFilter}`, {
        headers: authHeader()
      })
      setDataHistorical(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    let date = moment(new Date()).format('YYYY-MM');

    if (filter.singleDate) {
      date = moment(filter.singleDate).format('YYYY-MM')
    }

    getInfoHistoricalByMU(date)

  }, [filter])

  useEffect(() => {
    
    document.title = `Befective | ${t('menu.check_in_remote')}`;
  }, []);

  const splitPath = window.location.pathname.split("/")
  const pathToBack = `/${splitPath[1]}/${splitPath[2]}/${splitPath[3]}`

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'single'}
          filterWhat={'disabled'}
          routeBackToPage={pathToBack}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <ReportsPointsDetails
                alertActions={alertActions}
                userDetails={userDetails}
                dataHistorical={dataHistorical}
                isCurrentMonth={true}
                filter={filter}
              />
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={2}/>


          }
        </div>

      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinReportsPointsDetailsPage)