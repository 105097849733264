// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThNgs8ZT2780G3m3IZ30eQ\\=\\= {\n  padding: 0px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ProjectManagementListsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".containerBoardsPage{\n  padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBoardsPage": "ThNgs8ZT2780G3m3IZ30eQ=="
};
export default ___CSS_LOADER_EXPORT___;
