import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../_actions';
import utf8 from 'utf8'


import IconWindows from "../_icons/IconWindows";
import IconMac from "../_icons/IconMac";
import Moment from "moment-timezone";
import { CopySimple } from 'phosphor-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@material-ui/core';

function SettingsProfile(props) {
    const { t, i18n } = useTranslation('common');

    const [version, setVersion] = useState([]);
    const [copied, setCopied] = useState({
        text: t('download.info_copy'),
        value: false
    });

    function download(type, version) {
        if (type === "windows") {

            const link = document.createElement('a');
            link.href = `https://befective-agent.s3.amazonaws.com/current/windows/befective.exe`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        if (type === "mac") {

            const link = document.createElement('a');
            link.href = `https://befective-agent.s3.amazonaws.com/current/mac/befective.dmg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const onCopyLink = () => {
        setCopied({ text: t('download.copied'), value: true })
        setTimeout(function () {
            setCopied({ text: t('download.info_copy'), value: false })
        }, 3000);
    }

    useEffect(() => {


        if (props.downloads && props.downloads.list) {

            let win = 0;
            let mac = 0
            let lin = 0;
            let ad = 0;

            let winDate = null;
            let macDate = null;
            let linDate = null;
            let adDate = null;

            for (let v of props.downloads.list) {
                if (v.so_type == 'win') {
                    win = v.version;
                    winDate = v.created_at;
                }
                if (v.so_type == 'mac') {
                    mac = v.version;
                    macDate = v.created_at;
                }
                if (v.so_type == 'lin') {
                    lin = v.version;
                    linDate = v.created_at;
                }
                if (v.so_type == 'ad') {
                    ad = v.version;
                    adDate = v.created_at;
                }
            }

            setVersion({
                'win': win,
                'url_win': "https://befective-agent.s3.amazonaws.com/current/windows/befective.exe",
                'mac': mac,
                'url_mac': `https://befective-agent.s3.amazonaws.com/current/mac/befective.dmg`,
                'lin': lin,
                'ad': ad,
                'winDate': winDate,
                'macDate': macDate,
                'linDate': linDate,
                'adDate': adDate
            })
        }


    }, [props.downloads.loading])

    return (

        <div className={'tab full active'}>
            <div className="download">
                <section>
                    <h3>{t("download.basic")}</h3>
                    <p>{t("download.basic_info")}</p>
                    <div className="options">
                        <div className="option">
                            <div>
                                <button onClick={() => {
                                    download('windows', version['win'])
                                }}>
                                    <IconWindows />
                                    {t("download.for_windows")}
                                </button>
                                <Tooltip className={`tooltip-copy ${copied?.value && 'copied'}`} title={copied?.text} arrow>
                                    <CopyToClipboard text={version['url_win']} onCopy={onCopyLink}>
                                        <button className='button-clipboard'>
                                            <CopySimple size={22} />
                                        </button>
                                    </CopyToClipboard>
                                </Tooltip>
                            </div>
                            <span>{t("download.version")} {version['win'] + 'W'} - {Moment(version['winDate']).format(t('date.mm/dd/yyyy'))}</span>
                        </div>
                        
                        <div className="option">
                            <div>
                                <button onClick={() => {
                                    download('mac', version['mac'])
                                }}>
                                    <IconMac />
                                    {t("download.for_mac")}
                                </button>
                                <Tooltip className={`tooltip-copy ${copied?.value && 'copied'}`} title={copied?.text} arrow>
                                    <CopyToClipboard text={version['url_mac']} onCopy={onCopyLink}>
                                        <button className='button-clipboard'>
                                            <CopySimple size={22} />
                                        </button>
                                    </CopyToClipboard>
                                </Tooltip>
                            </div>

                            <span>{t("download.version")} {version['mac'] + 'M'}  - {Moment(version['macDate']).format(t('date.mm/dd/yyyy'))}</span>
                        </div>

                        <div className="option" style={{ display: 'none' }}>
                            <button onClick={() => {
                                download('linux')
                            }}>
                                <IconMac />
                                {t("download.for_linux")}
                            </button>
                            <span>{t("download.version")} {version['lin'] + 'L'}</span>
                        </div>
                    </div>
                </section>
                <section style={{ display: 'none' }}>
                    <h3>{t("download.ad")}</h3>
                    <p>{t("download.ad_info")}</p>
                    <div className="options">
                        <div className="option">
                            <button onClick={() => {
                                download('ad')
                            }}>
                                <IconWindows />
                                {t("download.befective")}
                            </button>
                        </div>
                        <div className="option">
                            <button onClick={() => {
                                download('group')
                            }}>
                                {t("download.ad_create")}
                            </button>
                            <span>{t("download.ad_replicate")}</span>
                        </div>
                        <div className="option">
                            <button onClick={() => {
                                download('manual')
                            }}>
                                {t("download.ad_manual")}
                            </button>
                        </div>
                    </div>
                </section>
                <section style={{ display: 'none' }}>
                    <h3>{t("download.old")}</h3>
                    <p>{t("download.old_info")}</p>
                    <div className="options">
                        <div className="option">
                            <button onClick={() => {
                                download('old')
                            }}>
                                <IconWindows />
                                {t("download.for_windows")}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
};


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        userDetails: state.authentication.userDetails,
        updated: state.users.updated,
        downloads: state.downloads,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsProfile));
