import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, orderData, secondsToHm } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { CardsHeaderAbsenteeism } from '../../_components/reports/absenteeism/CardsHeader';
import moment from 'moment';
import TableReportsAbsenteeismPagination from '../../_components/reports/absenteeism/TableReportsAbsenteeismPagination';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsAbsenteeismPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [dataAbsenteeism, setDataAbsenteeism] = useState(null);

  const [orderedColumn, setOrderedColumn] = useState('alias')
  const [order, setOrder] = useState('asc');

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(moment(new Date()).startOf('month').format('YYYY-MM-DD'));
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const orderColumn = (column, type) => {

    const orderedData = orderData(dataAbsenteeism.data, column, type, orderedColumn, setOrderedColumn, order, setOrder)

    setDataAbsenteeism({
      ...dataAbsenteeism,
      data: orderedData
    });
  };


  async function getDataAbsenteeism(dateFilter, pageIndex, pageSize, isLoading, teams = null, teammates = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t => t.id).join(',')}` : ''

      const response = await api.get(`/report/absenteeism?page=${pageIndex}&per_page=${pageSize}&date_ref=${dateFilter}&current_day=${moment().format('YYYY-MM-DD')}&${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataAbsenteeism(response.data)
      setPageCount(Math.ceil(parseInt(response.data.countRows) / pageSize))
      setPageCountRows(parseInt(response.data.countRows))
      setPageSize(pageSize)
      setPageIndex(pageIndex)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_absenteeism.table.col_colab'),
        accessor: 'username'
      },
      {
        Header: t('reports_absenteeism.table.col_working_days'),
        accessor: 'total_working_days'
      },
      {
        Header: t('reports_absenteeism.table.col_total_days_worked'),
        accessor: 'total_days_worked',
      },
      {
        Header: t('reports_absenteeism.table.col_total_not_worked_days'),
        accessor: 'total_not_worked_days',
      },
      {
        Header: t('reports_absenteeism.table.col_absenteeism_rate'),
        accessor: 'absenteeism_day_rate',
        Cell: ({ value }) => {
          return Number(value) <= 0 ? <span style={{ color: '#22BEAD', fontSize: '12px' }}>{`${value}%`}</span> : <span style={{ color: '#FC6662', fontSize: '12px' }}>{`${value}%`}</span>
        }
      },
    ];
  }, []);


  useEffect(() => {

    let date = moment(new Date()).format('YYYY-MM-DD');

    if (filter.singleDate) {
      date = moment(filter.singleDate).format('YYYY-MM-DD')
    }

    setDateRef(date)

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataAbsenteeism(date, 0, localStorage.getItem('pageSizeStorage') || 20, false, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhenMonths={'single'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderAbsenteeism alertActions={alertActions} userDetails={userDetails} stats={dataAbsenteeism.stats} />
                <TableReportsAbsenteeismPagination
                  columns={columns}
                  data={dataAbsenteeism.data}
                  count={pageCount}
                  getData={getDataAbsenteeism}
                  pageCountRows={pageCountRows}
                  alertActions={alertActions}
                  dateRef={dateRef}
                  firstLoading={firstLoading}
                  setFirstLoading={setFirstLoading}
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
          }

        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'absenteeismReports'} paginationFilters={{ pageSize: pageSize, pageIndex: pageIndex }} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsAbsenteeismPage)