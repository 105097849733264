// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A1SvgZP4vJOuybHAQ8LyAA\\=\\= {\n  background-color: #ffffff;\n  border: 1px solid #E4EAEF !important;\n  transition: ease 0.2s;\n}\n\n.A1SvgZP4vJOuybHAQ8LyAA\\=\\=:hover {\n  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);\n  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);\n  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);\n}\n\n.FywgG9tIQ\\+fSx8sXaohqWA\\=\\= {\n  padding: 18px !important;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: center;\n  align-items: center;\n}\n\n.nyY\\+y5PaQ4O\\+qO5JLcdJBQ\\=\\= {\n  color: #1E6B7F;\n  font-size: 14px !important;\n  font-weight: 500;\n}\n\n.v\\+L7I5GYkKAXgh2w1FeppA\\=\\= {\n  color: #1E6B7F;\n  font-size: 13px !important;\n  font-weight: 500;\n  margin: 0px;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/collection_monitoring/CardInfoUser.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,oCAAA;EACA,qBAAA;AACF;;AAEA;EACE,sDAAA;EACA,mDAAA;EACA,+CAAA;AACF;;AAEA;EACE,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAGA;EACE,cAAA;EACA,0BAAA;EACA,gBAAA;AAAF;;AAGA;EACE,cAAA;EACA,0BAAA;EACA,gBAAA;EACA,WAAA;AAAF","sourcesContent":[".card {\n  background-color: #ffffff;\n  border: 1px solid #E4EAEF !important;\n  transition: ease .2s;\n}\n\n.card:hover {\n  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);\n  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);\n  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);\n}\n\n.cardContent {\n  padding: 18px !important;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.cardTitle {\n  color: #1E6B7F;\n  font-size: 14px !important;\n  font-weight: 500;\n}\n\n.cardSubtitle {\n  color: #1E6B7F;\n  font-size: 13px !important;\n  font-weight: 500;\n  margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "A1SvgZP4vJOuybHAQ8LyAA==",
	"cardContent": "FywgG9tIQ+fSx8sXaohqWA==",
	"cardTitle": "nyY+y5PaQ4O+qO5JLcdJBQ==",
	"cardSubtitle": "v+L7I5GYkKAXgh2w1FeppA=="
};
export default ___CSS_LOADER_EXPORT___;
