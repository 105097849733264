import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { useTranslation } from "react-i18next";
import { getColorByTypeGoals, numberToPercentString } from '../../../_helpers';


import './styles.scss'


export function CardDividerProgressBar(props) {
  const { t } = useTranslation('common');
  const { data } = props.data || []
  const { title } = props || {}




  return (
    <div className="container-activities-progress">
      <header>
        <h2>
          {t(title)}
        </h2>
      </header>

      <main className="content-activities-progress">
        {/* <ul>
          {data.map(item => (
            <li key={item.type} className={`info-${item.type}`}>
              <div className={`ball ball-${item.type}`}></div>
              <span>
                {t(`goals.activities_info_${item.type}`)}:
              </span>
              <strong>
                {item.total}
              </strong>
            </li>
          ))}
        </ul> */}

        <div className="bar-activities-progress">
          {data.map(item => (
            // <div
            //   key={item.type}
            //   className={`bar-${item.type}`}
            //   style={{ width: item.percent + '%' }}
            // />
            <div style={{ width: '100px' }} key={item?.type}>
              <CircularProgressbarWithChildren
                value={item?.percent}
                strokeWidth={10}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: getColorByTypeGoals(item?.type),
                  trailColor: "#eee",
                })}>
                <div style={{ fontSize: 12, fontWeight: 500, color: '#4F7091' }}>
                  {item?.total + "/" + item?.totalGeneral}
                </div>
                <div style={{ fontSize: 11, fontWeight: 500, color: '#4F7091' }}>
                  {t(`goals.activities_info_${item.type}`)}
                </div>
              </CircularProgressbarWithChildren>
            </div>
          ))}
        </div>
      </main>
    </div>
  )
};

