import config from 'config';
import { authHeader } from '../_helpers';

export const teamsService = {
    getTeams,
    postTeams,
    patchTeams,
    getTeamDetail,
    getTeamActivities,
    postActivitie,
    getTeamFocus,
    deleteActivitie,
    disableTeam
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getTeams(teams) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = ''

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "?teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    return fetch(`${config.apiNodeUrl}/teams` + queryTeams, requestOptions).then(handleResponse);
}

function postTeams(form) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({
            Name: form.name,
            IsSubteam: form.is_subteam,
            IsClone: form.is_a_clone,
            ParentTeam: form.parent,
            CloneFrom: form.clone_from
        })
    }

    return fetch(`${config.apiNodeUrl}/teams`, requestOptions).then(handleResponse)
}

function patchTeams(form, id) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({
            Name: form.name,
            ParentTeam: form.parent,
            ShowIcon: form.showIcon,
            AskMood: form.askMood,
            Timeout: form.timeout,
            selective_collection: form.selective_collection,
            Mon: form.mon,
            Tue: form.tue,
            Wed: form.wed,
            Thu: form.thu,
            Fri: form.fri,
            Sat: form.sat,
            Sun: form.sun,
            periods: form.periods,
            responsible_id: form.responsible_id,
            respect_checkin_actions: form.respect_checkin_actions,
            continuous_working_time: form.continuous_working_time,
            alert_time_must_rest: form.alert_time_must_rest,
            rest_time: form.rest_time,
            shortage_time: form.shortage_time,
            mandatory: form.mandatory,
            max_repeat_alert: form.max_repeat_alert
        })
    }


    return fetch(`${config.apiNodeUrl}/teams/${id}`, requestOptions).then(handleResponse)
}

function getTeamDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teams/${id}`, requestOptions).then(handleResponse);
}

function getTeamActivities(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teams/${id}/activities`, requestOptions).then(handleResponse);
}

function getTeamFocus(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teams/${id}/focus`, requestOptions).then(handleResponse);
}

function disableTeam(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teams/${id}`, requestOptions).then(handleResponse);
}

function postActivitie(form, teamId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ name: form.activitieName, efficiency: form.activitieType, teamId: teamId })
    }

    return fetch(`${config.apiNodeUrl}/teams/activities`, requestOptions).then(handleResponse)
}

function deleteActivitie(teamId, trackedId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teams/${teamId}/activities/${trackedId}`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

