import { ArchiveBox, CheckCircle, EnvelopeOpen, Info, MagnifyingGlass, PaperPlaneTilt } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import './styles.scss'

export function HeaderMessages({ total_received = 0, total_sent = 0, total_archived = 0, handleFilterCalls, tab = 'sent' }) {
  const { t, i18n } = useTranslation('common');
  const [titleSearch, setTitleSearch] = useState('')

  function handleSetTitleSearch(title) {
    if (title === '') {
      handleFilterCalls(title)
    }
    setTitleSearch(title)
  }

  return (
    <header className="header-container-calls-page">
      <div className="container-texts-calls-page">
        <h2>{t(`messages.messages_${tab}`)}</h2>

        {/* <div>
          <div className="info-header-calls-page">
            <span>
              <EnvelopeOpen size={14} weight="bold" />
              {t('messages.received')}:
            </span>
            <strong>{total_received}</strong>
          </div>
          <div className="info-header-calls-page">
            <span>
              <PaperPlaneTilt size={14} weight="bold" />
              {t('messages.sent')}:
            </span>
            <strong>{total_sent}</strong>
          </div>
          <div className="info-header-calls-page">
            <span>              
              <ArchiveBox size={14} weight="bold" />
              {t('messages.archived')}:
            </span>
            <strong>{total_archived}</strong>
          </div>
        </div> */}
      </div>

      <div className="container-search-calls-page">
        <input
          type="text"
          placeholder={t('calls_page.placeholder_search')}
          value={titleSearch}
          onChange={e => handleSetTitleSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleFilterCalls(titleSearch)
          }}
        />
        <MagnifyingGlass
          size={16}
          weight="light"
          onClick={() => handleFilterCalls(titleSearch)}
        />
      </div>
    </header>
  )
}
