import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";

import IconStart from "../_icons/IconStart";
import {secondsToHm} from '../../_helpers';

function TimeGoalProductivity(props) {
    const {t, i18n} = useTranslation('common');

    let productivity = props.productivity || {};

    let PercentTimeGoal = 0;
    let TimeGoalHelper = 'Ω';

    if (productivity.TotalExpectedWorkedTime > 0 && productivity.TotalWorkedTime > 0) {

        PercentTimeGoal = ((productivity.TotalWorkedTime / productivity.TotalExpectedWorkedTime) * 100).toFixed(2);
    } else if (productivity.TotalExpectedWorkedTime == 0) {

        PercentTimeGoal = 0;
    }

    
    if (productivity.TotalExpectedWorkedTime > 0) {
        TimeGoalHelper = '( '+secondsToHm(productivity.TotalWorkedTime) + ' / ' + secondsToHm(productivity.TotalExpectedWorkedTime) + ` ) `;
    }

    return (
        <div className={'box goal'}>
            <h3>{t('productivity.time_goal')}</h3>
            <div className={'helper'}>{t('productivity.helper_time_goal')}</div>

            <div className={'barchart'}>
                <div className={'bar'} style={{width: PercentTimeGoal + '%'}}></div>
            </div>
            <div className={'helperChart'}> 
                {TimeGoalHelper}
                {
                    productivity?.TotalExpectedWorkedTime > 0 && <span style={{marginLeft:4, color: '#187dff', fontWeight: '500'}}> {PercentTimeGoal + '%'} </span>
                }
                
            </div>

        </div>
    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(TimeGoalProductivity)
