import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";
import IconOk from "../_icons/IconOk";
import IconSad from "../_icons/IconSad";
import IconHappy from "../_icons/IconHappy";


function PeriodProductivity(props) {
    const {t, i18n} = useTranslation('common');

    let productivity = props.productivity || {};

    let Period = t('productivity.not_set');
    let FirstAcivity = t('productivity.not_set');
    let LastAcivity = t('productivity.not_set');
    let MoodName = t('productivity.not_set');
    let MoodIcon = <IconOk/>

    if (productivity.collect_period && productivity.collect_period.type == 'Period') {
        Period = productivity.collect_period.start_time_collect + 'h/' + productivity.collect_period.end_time_collect + 'h'
    } else {
        Period = t('productivity.always');
    }


    if (productivity.start_hour) {

        let hour = productivity.start_hour.split(':');

        FirstAcivity = hour[0] + 'h ' + hour[1] + 'min';
    }

    if (productivity.end_hour) {

        let hour = productivity.end_hour.split(':');

        LastAcivity = hour[0] + 'h ' + hour[1] + 'min';
    }

    if (productivity.mood != null) {

        if (productivity.mood === 0) {
            MoodName = t('home.happy');
            MoodIcon = <IconHappy/>
        }
        if (productivity.mood === 1) {
            MoodName = t('home.ok');
            MoodIcon = <IconOk/>
        }
        if (productivity.mood === 2) {
            MoodName = t('home.sad');
            MoodIcon = <IconSad/>
        }
    }


    return (
        <React.Fragment>
            <div className="box productivity">

                <div className={'number'}>{Period}</div>
                <div className={'label'}>{t('productivity.period')}</div>
            </div>
            <div className="box productivity">
                <div className={'number'}>{FirstAcivity}</div>
                <div className={'label'}>{t('productivity.first_activity')}</div>
            </div>
            <div className="box ">
                <div className={'number'}>{LastAcivity}</div>
                <div className={'label'}>{t('productivity.last_activity')}</div>
            </div>
            <div className="box ">
                <div className={'number'}>{MoodIcon} {MoodName}  </div>
                <div className={'label'}>{t('productivity.mood')}</div>

            </div>
        </React.Fragment>
    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(PeriodProductivity)
