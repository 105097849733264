import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';
import { Percent, Sun, Timer } from 'phosphor-react';
import { secondsToHm } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';

export function CardsHeaderFatigue({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >

      <Box width={'100%'}>
        <h3 className='reportTitleGeneric'>
          {t('productive_reports.card_reports.fatigue')}<span><IconInfo/><div className="tooltip">{t('productive_reports.card_reports.subtitle_fatigue')}</div></span>
        </h3>
      </Box>
      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <Timer size={18} color="#4F7091" weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} my={1}>
            {secondsToHm(stats.total_expected_work_time_avg)}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('reports_fatigue.header_cards.total_hours_expected_avg')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Timer size={18} color="#22BEAD" weight="bold" />
          <Typography color="#22BEAD" component="span" fontSize={20} my={1}>
            {secondsToHm(stats.total_worked_time_avg)}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('reports_fatigue.header_cards.total_hours_worked_avg')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: Number(stats.total_worked_balance_percent) >= 0 ? '2px solid #FC6662' : '2px solid #22BEAD' }}>
          <Percent size={18} color={Number(stats.total_worked_balance_percent) >= 0 ? '#FC6662' : '#22BEAD'} weight="bold" />
          <Typography color={Number(stats.total_worked_balance_percent) >= 0 ? '#FC6662' : '#22BEAD'} component="span" fontSize={20} my={1}>
            {`${stats.total_worked_balance_percent}%`}
          </Typography>
          <Typography color={Number(stats.total_worked_balance_percent) >= 0 ? '#FC6662' : '#22BEAD'} component="span" fontSize={12}>
            {t('reports_fatigue.header_cards.avg_percent_worked')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
