import { TextField } from '@material-ui/core';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';

export default function InputNewChecklistItem({
  noteId,
  getData
}) {
  const [newItemChecklistTitle, setNewItemChecklistTitle] = useState("")
  const { t, i18n } = useTranslation('common');

  const handleAddItem = async (e) => {
    if (e.key === 'Enter' && newItemChecklistTitle !== '') {
      const resp = await api.post(`/users/todo/${noteId}/items`, { title: newItemChecklistTitle }, { headers: authHeader() })
      await getData(false)
      setNewItemChecklistTitle('')
    }
  }


  return (
    <TextField
      fullWidth
      label={t('colab_page.checklist.modal_note.field_checklist_title')}
      size="small"
      placeholder={t('colab_page.checklist.modal_note.placeholder_checklist_title')}
      value={newItemChecklistTitle}
      onChange={(e) => setNewItemChecklistTitle(e.target.value)}
      onKeyDown={(e) => handleAddItem(e)}
    />
  )
}
