// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.ka-MjaBzYxBad68esd3vJQ\\=\\= {\n  margin-top: -10px;\n  font-size: 11px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.-FgkC7CyjhnPsjdyd1Xq9A\\=\\= {\n  height: 100%;\n  width: 100%;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n/* Estilize o retângulo */\n.Jc6ohQjZKMKrrtXJpoXSig\\=\\= {\n  cursor: pointer;\n  transition: fill 0.2s;\n}\n\n/* Estilize o ícone de exclusão */\n._107CP4U2k0KWasKAk6cDCg\\=\\= {\n  font-size: 20px;\n  fill: red;\n  visibility: hidden;\n  cursor: pointer;\n  transition: visibility 0.2s;\n}\n\n/* Quando o mouse passar sobre o grupo (retângulo) */\n.W3wRiCqTCYuvT4WQ2Kqf3w\\=\\=:hover ._107CP4U2k0KWasKAk6cDCg\\=\\= {\n  visibility: visible;\n}\n\n._3EFNaIa9Z3dw2PMGFXUQ8A\\=\\= {\n  fill: #ddd !important;\n  cursor: auto !important;\n}", "",{"version":3,"sources":["webpack://./src/_components/rooms_page/MapsListContainer/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,iBAAA;EACA,eAAA;EACA,yBAAA;AAEF;;AACA;EACE,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AAEF;;AACA,yBAAA;AACA;EACE,eAAA;EACA,qBAAA;AAEF;;AACA,iCAAA;AACA;EACE,eAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;AAEF;;AACA,oDAAA;AACA;EACE,mBAAA;AAEF;;AACA;EACE,qBAAA;EACA,uBAAA;AAEF","sourcesContent":[".labelColor{\n  margin-top: -10px;\n  font-size: 11px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.inputColor{\n  height: 100%;\n  width: 100%;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n/* Estilize o retângulo */\n.rectangle {\n  cursor: pointer;\n  transition: fill 0.2s;\n}\n\n/* Estilize o ícone de exclusão */\n.deleteIcon {\n  font-size: 20px;\n  fill: red;\n  visibility: hidden;\n  cursor: pointer;\n  transition: visibility 0.2s;\n}\n\n/* Quando o mouse passar sobre o grupo (retângulo) */\n.rectangleGroup:hover .deleteIcon {\n  visibility: visible;\n}\n\n.disableShape{\n  fill: #ddd !important;\n  cursor: auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelColor": "ka-MjaBzYxBad68esd3vJQ==",
	"inputColor": "-FgkC7CyjhnPsjdyd1Xq9A==",
	"rectangle": "Jc6ohQjZKMKrrtXJpoXSig==",
	"deleteIcon": "_107CP4U2k0KWasKAk6cDCg==",
	"rectangleGroup": "W3wRiCqTCYuvT4WQ2Kqf3w==",
	"disableShape": "_3EFNaIa9Z3dw2PMGFXUQ8A=="
};
export default ___CSS_LOADER_EXPORT___;
