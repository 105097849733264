import { settingsConstants } from '../_constants';



export function settings(state = {}, action) {
    switch (action.type) {
        case settingsConstants.GET_COMPANY_INFORMATION_REQUEST:
            return {
                loading: true,
                error: false
            };
        case settingsConstants.GET_COMPANY_INFORMATION_SUCCESS:
            return {
                companyInformation: action.payload,
                loading: false,
                error: false
            };
        case settingsConstants.GET_COMPANY_INFORMATION_FAILURE:
            return {
                loading: true,
                error: true
            };
        case settingsConstants.PUT_COMPANY_INFORMATION_REQUEST:
            return {
                loading: true,
                error: false
            };
        case settingsConstants.PUT_COMPANY_INFORMATION_SUCCESS:
            return {
                companyInformation: action.payload,
                loading: false,
                error: false
            };
        case settingsConstants.PUT_COMPANY_INFORMATION_FAILURE:
            return {
                loading: true,
                error: true
            };

        case settingsConstants.PUT_COMPANY_PERIODIC_REPORT_REQUEST:
        case settingsConstants.PUT_COMPANY_PERIODIC_REPORT_SUCCESS:
        case settingsConstants.PUT_COMPANY_PERIODIC_REPORT_FAILURE:
            return {
                ...state
            };

        case settingsConstants.GET_COMPANY_ADMINISTRATORS_REQUEST:
            return {
                loading: true,
                error: false

            };
        case settingsConstants.GET_COMPANY_ADMINISTRATORS_SUCCESS:
            return {
                companyAdministrators: action.payload,
                loading: false,
                error: false
            };
        case settingsConstants.GET_COMPANY_ADMINISTRATORS_FAILURE:
            return {
                loading: true,
                error: true
            };
        case settingsConstants.GET_COMPANY_ADMINISTRATOR_REQUEST:
            return {
                ...state,

            };
        case settingsConstants.GET_COMPANY_ADMINISTRATOR_SUCCESS:
            return {
                ...state,
                specificAdministrator: action.payload,

            };
        case settingsConstants.GET_COMPANY_ADMINISTRATOR_FAILURE:
            return {
                ...state,
                error: action.error,

            };
        case settingsConstants.DELETE_COMPANY_ADMINISTRATORS_REQUEST:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_ADMINISTRATORS_SUCCESS:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_ADMINISTRATORS_FAILURE:
            return {
                ...state,
            };
        case settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_REQUEST:

            return {
                ...state,
                invited: '',
                loading: false
            };
        case settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_SUCCESS:
            return {
                ...state,
                invited: 'success',
                loading: false
            };
        case settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_FAILURE:
            return {
                ...state,
                error: action.error,
                invited: 'error',
                loading: false
            };

        case settingsConstants.PUT_COMPANY_ADMINISTRATORS_REQUEST:

            return {
                ...state,
                invited: '',
                loading: false
            };
        case settingsConstants.PUT_COMPANY_ADMINISTRATORS_SUCCESS:
            return {
                ...state,
                invited: 'success',
                loading: false
            };
        case settingsConstants.PUT_COMPANY_ADMINISTRATORS_FAILURE:
            return {
                ...state,
                error: action.error,
                invited: 'error',
                loading: false
            };
        case settingsConstants.POST_COMPANY_DAYSOFF_REQUEST:
            return {
                ...state,
                created: '',
            };
        case settingsConstants.POST_COMPANY_DAYSOFF_SUCCESS:
            return {
                ...state,
                created: 'success',
            };
        case settingsConstants.POST_COMPANY_DAYSOFF_FAILURE:
            return {
                ...state,
                created: 'error',
            };
        case settingsConstants.GET_COMPANY_DAYSOFF_REQUEST:
            return {
                loading: true,
                error: false

            };
        case settingsConstants.GET_COMPANY_DAYSOFF_SUCCESS:
            return {
                companyDaysoff: action.payload,
                loading: false,
                error: false
            };
        case settingsConstants.GET_COMPANY_DAYSOFF_FAILURE:
            return {
                loading: true,
                error: true
            };
        case settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_REQUEST:
            return {
                ...state,
                error: false,
                loadingSpecific: true

            };
        case settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_SUCCESS:

            return {
                ...state,
                companyDaysoffSpecific: action.payload,
                error: false,
                loadingSpecific: false
            };
        case settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_FAILURE:
            return {
                ...state,
                error: true,
                loadingSpecific: true
            };

        case settingsConstants.GET_COMPANY_INVENTORY_REQUEST:
            return {
                loading: true,
                error: false

            };

        case settingsConstants.GET_COMPANY_INVENTORY_SUCCESS:
            return {
                companyInventory: action.payload,
                loading: false,
                error: false
            };

        case settingsConstants.GET_COMPANY_INVENTORY_FAILURE:
            return {
                loading: true,
                error: true
            };



        case settingsConstants.DELETE_COMPANY_INVENTORY_REQUEST:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_INVENTORY_SUCCESS:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_INVENTORY_FAILURE:
            return {
                ...state
            };

        case settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_REQUEST:
        case settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_SUCCESS:
        case settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_FAILURE:
            return {
                ...state,
            };

        case settingsConstants.GET_COMPANY_PERIODIC_REPORT_REQUEST:
            return {
                loading: true,
                error: false

            };
        case settingsConstants.GET_COMPANY_PERIODIC_REPORT_SUCCESS:
            return {
                companyReportPeriodic: action.payload,
                loading: false,
                error: false
            };
        case settingsConstants.GET_COMPANY_PERIODIC_REPORT_FAILURE:
            return {
                loading: true,
                error: true
            };

        case settingsConstants.DELETE_COMPANY_DAYSOFF_REQUEST:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_DAYSOFF_SUCCESS:
            return {
                ...state,
            };
        case settingsConstants.DELETE_COMPANY_DAYSOFF_FAILURE:
            return {
                ...state,
            };
        default:
            return state
    }
}