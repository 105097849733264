import { Box, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material'
import { CheckCircle, DotsNine } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IconMainLogoV2 from '../../_components/_icons/IconMainLogoV2'
import { CardModule } from '../../_components/switch_page/CardModule'
import Header from '../../_components/switch_page/Header'

import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { usersActions } from '../../_actions'
import { useCurrentModule } from '../../contexts/CurrentModuleContext'
import style from './NewsDetailsPage.module.scss'
import { authHeader, befectiveFormatDate, doCountAccessModule, history } from '../../_helpers'
// import { Carousel } from 'react-carousel-minimal';
import IconModuleProductivityManagement from '../../_components/_icons/IconModuleProductivityManagement'
import IconModuleGoalManagement from '../../_components/_icons/IconModuleGoalManagement'
import IconModuleVirtualPoint from '../../_components/_icons/IconModuleVirtualPoint'
import IconModuleCheckInRemote from '../../_components/_icons/IconModuleCheckInRemote'
import IconModuleSpaceManagement from '../../_components/_icons/IconModuleSpaceManagement'
import IconModuleProjectManagement from '../../_components/_icons/IconModuleProjectManagement'
import IconModuleInventory from '../../_components/_icons/IconModuleInventory'
import IconThreePoints from '../../_components/_icons/IconThreePoints'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { api } from '../../_helpers/api'
import bannerNewsPT from '../../../public/images/banner_news_pt.png';
import bannerNewsES from '../../../public/images/banner_news_es.png';
import bannerNewsEN from '../../../public/images/banner_news_en.png';
import SkeletonNewsDetailsPage from '../../_components/switch_page/SkeletonNewsDetailsPage'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'


function NewsDetailsPage({ userDetails, usersActions }) {
  const { t, i18n } = useTranslation('common');
  const lang = userDetails.language || i18n.language
  const [fetching, setFetching] = useState(true)
  const [news, setNews] = useState(null)
  // const { id } = useParams()
  const [id, setId] = useState(useParams().id)
  const getDetailsNews = async (newsId=null) => {
    try {
      setFetching(true)
      const paramId = newsId || id
      const response = await api.get(`/switch/news/${paramId}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Error to load data details news')
      }
      setNews(response.data)
      setFetching(false)

    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }

  useEffect(() => {

    getDetailsNews()

    document.title = 'Befective | Home ';

  }, [])


  const handleMouseOver = (newsId) => {
    document.getElementById(`news-date-${newsId}`).style.display = 'none'
    document.getElementById(`news-see-more-${newsId}`).style.display = 'inline'
  }

  const handleMouseOut = (newsId) => {
    document.getElementById(`news-date-${newsId}`).style.display = 'inline'
    document.getElementById(`news-see-more-${newsId}`).style.display = 'none'
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Define a posição do scroll para o topo quando a rota mudar
  }, [history.location.pathname]); // Dispara o efeito quando a rota mudar


  return (
    <Box
      bgcolor="#F5F6F9"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Header toBackUrl={'/'} />
      {
        (!fetching && news !== null)
          ?

          <Container component={'main'} maxWidth="md" className={style.containerMain} sx={{ flexGrow: 1, marginBottom: '16px'}}>

            <Grid container spacing={2} component={'section'} mt={'32px'}>
              <Grid item xs={12}>

                <div className={style.boxBanner}>
                  {lang === 'pt' && <img className={style.imgBanner} src={bannerNewsPT} alt="Banner" />}
                  {lang === 'es' && <img className={style.imgBanner} src={bannerNewsES} alt="Banner" />}
                  {lang === 'en' && <img className={style.imgBanner} src={bannerNewsEN} alt="Banner" />}
                </div>


                <div className={style.cardDescription}>
                  <h6 className={style.titleCard}>{news[`title_${lang}`]}</h6>
                  <div
                    className={style.bodyDescription}
                    dangerouslySetInnerHTML={{ __html: news[`description_${lang}`] }}
                  />
                  {/* <p className={style.bodyDescription}>{news[`description_${lang}`]}</p> */}
                </div>

              </Grid>
            </Grid>

            {
              news.extra_news.length > 0 &&
              <Grid container spacing={2} component={'section'} marginTop={'16px'}>
                <Grid item xs={12}>
                  <h6 className={style.titleSection}>{t('news_details_page.title_read_also')}</h6>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="stretch">
                    {
                      news.extra_news.map(news => (
                        <Grid key={`lastest-news-${news.id}`} item xs={12} sm={4} display={'flex'} alignItems="stretch">
                          <div
                            className={style.cardLastestNews}
                            onMouseOverCapture={() => handleMouseOver(news.id)}
                            onMouseOutCapture={() => handleMouseOut(news.id)}
                            onClick={() => {
                              history.push(`/news-details/${news.id}`)
                              getDetailsNews(news.id)
                            }}
                          >
                            <div className={style.cardHeader} style={{ marginTop: '16px' }}>
                              <h6 className={style.titleModule}>{news[`title_${lang}`]}</h6>
                            </div>
                            <p className={style.abstractNews}>
                              {news[`summary_${lang}`]}
                            </p>
                            <hr className={style.divider} />
                            <div className={style.knowMore}>
                              <span id={`news-date-${news.id}`} style={{ display: 'inline' }}>
                                {befectiveFormatDate(news.date_publish, lang)}
                              </span>
                              <span id={`news-see-more-${news.id}`} style={{ display: 'none' }}>{t('news_details_page.read_more')}</span>
                            </div>
                          </div>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
            
          </Container>

          :

          <SkeletonNewsDetailsPage />
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetailsPage));