import { useCallback, useState } from "react";

export default function useOpenController(initialState) {
  const [isOpen, setOpenState] = useState(initialState);

  const toggle = useCallback((goalId) => {
    setOpenState((state) => {
      return {
        isOpen: !state?.isOpen,
        goalId: goalId
      }
    });
  }, [setOpenState]);

  return { isOpen, toggle };
}