import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableReportsSignedPoints.scss';
import IconExpand from '../../_icons/IconExpand';
import { befectiveFormatDate, history } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import { ArrowRight, House } from 'phosphor-react';
import { Apartment, ArrowDownward, ArrowUpward, Business, Home } from '@material-ui/icons';
import { Typography } from '@mui/material';


export default function TableReportsSignedPoints({
  data = [],
  orderColumn = () => { },
  orderedColumn,
  order
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsSignedPoints' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid #F5F6F9' }}>
            <TableCell onClick={() => orderColumn('username', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')}>
              {t("checkin.report_signed_points.col_colab")}
              {orderedColumn === 'username' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('moment', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_signed_points.col_signed_at")}
              {orderedColumn === 'moment' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('total_hours', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_signed_points.col_estimated")}
              {orderedColumn === 'total_hours' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('worked_hours', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_signed_points.col_worked")}
              {orderedColumn === 'worked_hours' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('overtime', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_signed_points.col_overtime_s")}
              {orderedColumn === 'overtime' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell onClick={() => orderColumn('missing_hours', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')} align='center'>
              {t("checkin.report_signed_points.col_missing_s")}
              {orderedColumn === 'missing_hours' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            {/* <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("checkin.report_signed_points.col_balance")}</TableCell> */}
            <TableCell width={'30px'} />
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.mu_id} className={`row-table`} onClick={() => history.push(`/virtual-point/reports/signed-points/${row.mu_id}`)}>
                <TableCell>
                  <Box display={'flex'} alignItems={'center'} gap={'2px'}>
                    <Box>{row.username}</Box>
                  </Box>
                </TableCell>
                <TableCell align="center">{befectiveFormatDate(row.moment, i18n.language)}</TableCell>
                <TableCell align="center">{row.total_hours}</TableCell>
                <TableCell align="center">{row.worked_hours}</TableCell>
                <TableCell align="center" style={{color: String(row.overtime) !== '00:00' ? '#22BEAD' : '#4f7091'}}>{row.overtime}</TableCell>
                <TableCell align="center" style={{color: String(row.missing_hours) !== '00:00' ? '#FC6662' : '#4f7091'}}>{row.missing_hours}</TableCell>
                <TableCell>
                  <IconButton
                    className={`button-options`}
                    aria-label="expand row"
                    size="small"
                  >
                    <ArrowRight size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
