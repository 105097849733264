import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeaderReportsPoints } from '../../_components/checkin_page/reports_points/CardsHeader';
import TableReportsPoints from '../../_components/checkin_page/reports_points/TableReportsPoints';
import { authHeader, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinReportsPointsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataReportsPoints, setDataReportsPoints] = useState({});

  let startDate = new Date(moment().startOf('month')).toISOString().split('T')[0];
  let endDate = new Date(moment()).toISOString().split('T')[0];

  const [orderedColumn, setOrderedColumn] = useState('alias')
  const [order, setOrder] = useState('asc');


  const orderColumn = (column, type) => {

    const orderedData = orderData(dataReportsPoints.listColabs, column, type, orderedColumn, setOrderedColumn, order, setOrder)

    setDataReportsPoints({
      ...dataReportsPoints,
      listColabs: orderedData
    });
  };


  async function getDataReportsPoints(date_start = new Date(moment()).toISOString().split('T')[0], date_end = new Date(moment()).toISOString().split('T')[0], teams = null, teammates = null) {
    try {

      setFetching(true)

      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/checkin/report/colab_points?date_start=${date_start}&date_end=${date_end}&moment=${moment().format()}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      setDataReportsPoints({
        ...response.data,
        listColabs: [...response.data.listColabs].sort((a, b) => a.alias.localeCompare(b.alias))
      })

      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {

    if(fetching){
      getDataReportsPoints(startDate, endDate, null, null)
      return
    }

    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataReportsPoints(start_date, end_date, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

  useEffect(() => {

  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'multi'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderReportsPoints alertActions={alertActions} userDetails={userDetails} resume={dataReportsPoints?.statsInfo} />
                <TableReportsPoints data={dataReportsPoints?.listColabs} orderColumn={orderColumn} orderedColumn={orderedColumn} order={order} />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
          }

        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'checkinReportPoints'} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinReportsPointsPage)