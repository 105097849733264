import React from 'react';
import { Backdrop, makeStyles, Modal, Slide } from '@material-ui/core';
import ReactAvatar from 'react-avatar';

import IconClose from "../../../_icons/IconClose";

import './styles.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function ModalHistoryMessage({ openModalHistory, handleCloseModalHistory, messages }) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalHistory}
      className={`${classes.modal} modal-detail-task`}
      onClose={handleCloseModalHistory}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0, width: '600px'}}
    >
      <Slide direction="down" in={openModalHistory} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className='message-history-container'>
          <div className="box-button-close">
            <button 
              onClick={handleCloseModalHistory} 
              className="close" 
              type="button"
            >
              <IconClose />
            </button>
          </div>
          <header>
            {/* <h2>TAsk ativar teste 123 e fazer analise do fluxo da cas de mora aluguel</h2> */}
            <span>
              Comentários: {' '}
              <strong>{messages.length}</strong>
            </span>
          </header>
          <div className='messages-container'>
            {messages?.map(message => (
              <div key={message.id} className='message-box'>
                <ReactAvatar
                  name={message.username}
                  size={24}
                  round
                  color='#4F7091'
                  style={{ border: '2px solid #fff' }}
                />

                <div className='message-content'>
                  <span className='date'>{message.username}</span>
                  <p className='message'>
                    {message.comment}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Slide>

    </Modal>
  );
}

export default ModalHistoryMessage;