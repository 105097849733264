import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget11Skeleton from './Widget11Skeleton';
import ReactApexChart from 'react-apexcharts';
import { CaretDoubleDown, CaretDoubleUp } from 'phosphor-react';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function Widget11({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [chartData, setChartData] = useState({
    stats: {
      current_month_productive_time_avg: 0,
      previous_month_productive_time_avg: 0,
      percent_status: 0
    },
    series: [],
    options: {
      chart: {
        height: 200,
        type: 'line',
        zoom: {
          enabled: false
        },
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 4,
        curve: 'smooth'
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '8px',
            colors: '#4F7091'
          },
          // show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#4F7091'
          },
          formatter: (value) => {
            return secondsToHm(value) ;
          }
          // show: false
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        shared: true,
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //   const tooltipText = [];
        //   const sharedValues = {}; // Armazenar valores compartilhados

        //   w.globals.seriesX[0].forEach((x, index) => {
        //     // Verificar se os valores são iguais nas séries
        //     if (series[0][index] === series[1][index]) {
        //       if (!sharedValues[x]) {
        //         sharedValues[x] = [];
        //       }
        //       sharedValues[x].push(series[0][index]);
        //     }
        //   });

        //   for (const x in sharedValues) {
        //     // Criar uma linha para cada conjunto de valores compartilhados
        //     tooltipText.push(`Valores para ${x}: ${sharedValues[x].join(', ')}`);
        //   }

        //   return tooltipText;
        // },
      },
    }
  })

  const getData = async ({filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/productive-graph?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget11!!!')
      }
      const { data } = response
      if (data && data.series.length > 0) {
        setChartData({
          stats: {
            current_month_productive_time_avg: secondsToHm(data.current_month_productive_time_avg),
            previous_month_productive_time_avg: secondsToHm(data.previous_month_productive_time_avg),
            percent_status: data.percent_status
          },
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: data.categories
            },
            colors: [data.percent_status > 0 ? '#22BEAD' : '#FF7A90', '#D2D7E1']
          },
          series: data.series,
        })
      }
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget11.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget11.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo color={'#FF7A90'}/></div>
                </BefectiveTooltip>
              </div>
            </div>

            {
              chartData.stats.percent_status > 0
                ?
                <div className={style.boxInfoStats}>
                  <span style={{ color: '#D2D7E1' }}>{chartData.stats.previous_month_productive_time_avg}&nbsp;/&nbsp;</span><span style={{ color: '#22BEAD' }}>{chartData.stats.current_month_productive_time_avg}</span>&nbsp;<span style={{ color: '#22BEAD' }}><CaretDoubleUp size={16} color='#22BEAD' weight="bold" />{chartData.stats.percent_status + '%'}</span>
                </div>
                :
                <div className={style.boxInfoStats}>
                  <span style={{ color: '#D2D7E1' }}>{chartData.stats.previous_month_productive_time_avg}&nbsp;/&nbsp;</span><span style={{ color: '#FF7A90' }}>{chartData.stats.current_month_productive_time_avg}</span>&nbsp;<span style={{ color: '#FF7A90' }}><CaretDoubleDown size={16} color='#FF7A90' weight="bold" />{Math.abs(chartData.stats.percent_status) + '%'}</span>
                </div>
            }
            <div className={style.chartContainer}>
              <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={200} className={'productiveChart'} />
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget11Skeleton />
      </Grid>

  )
}
