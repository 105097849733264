import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function Activities(props) {
    const {t, i18n} = useTranslation('common');

    const [metricTime, setMetric1] = useState(80);
    const [metricTimeOnline, setMetric2] = useState(50);
    const [metricTimeOffline, setMetric3] = useState(30);
    const [metricWork, setMetric4] = useState(70);
    const [metricWorkOnline, setMetric5] = useState(40);
    const [metricWorkOffline, setMetric6] = useState(30);


    return (
        <div className="box large activities">
            <h3><Skeleton variant="text" width="100%" height={22}/></h3>

            <div className="statsBlock">
                <div className="secondaryChart">
                    <div className="block">
                        <div className="stats">
                            <Skeleton variant="text" width="100%" height={17}/>
                            <Skeleton variant="text" width="100%" height={8}/>
                        </div>
                    </div>
                </div>
                <div className="displayList">
                    <Skeleton variant="rect" width="100%" height="300px"/>
                </div>
            </div>
        </div>
    )
};

export default Activities;
