import { Menu, MenuItem } from '@material-ui/core';
import { DotsThreeVertical } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { numberToPercentString } from '../../../_helpers';
import IconInfoFlat from '../../_icons/IconInfoFlat';
import { KanbanAddCard } from './KanbanAddCard';
import { KanbanCard } from './KanbanCard';

import './styles.scss';

const statusColors = ['#98ACC1', '#FDA02B', '#22BEAD', '#FF5151']

export function KanbanGoalsDetails({
  showModalNewTask,
  goalsData,
  showModalTask,
  openDrawerDetailsObjective,
  userDetails
}) {
  const { t } = useTranslation('common');

  const [anchorElOptionsKeyResult, setAnchorElOptionsKeyResult] = useState(null);
  const [currentKeyResult, setCurrentKeyResult] = useState(null);

  const handleCloseMenuOptionsKeyResult = () => {
    setAnchorElOptionsKeyResult(null);
    setCurrentKeyResult(null)
  };

  const handleClickMenuKeyResult = (e, kr) => {
    setAnchorElOptionsKeyResult(e.currentTarget);
    setCurrentKeyResult(kr);
  }

  function handleOpenModalNewTask() {
    handleCloseMenuOptionsKeyResult()
    showModalNewTask(currentKeyResult)
  }

  const today = new Date(String(new Date().toISOString()).split('T')[0])

  const [colorStatusGoal, setColorStatusGoal] = useState('')


  useEffect(() => {

    const listColorsStatus = {
      completed: '#22BEAD',
      progress: '#FDA02B',
      planning: '#98ACC1',
      overdue: '#FF5151'
    }

    let colorStatus = listColorsStatus.planning

    if (goalsData) {
      let goalProgress = goalsData?.percentProgressGeneral || 0
      let goalDateEnd = new Date(goalsData?.date_end)
      if (goalProgress > 0 && goalProgress < 100 && !(today > goalDateEnd)) {
        colorStatus = listColorsStatus.progress
      } else if (goalProgress >= 100.00) {
        colorStatus = listColorsStatus.completed
      } else if (goalProgress > 0 && goalProgress < 100 && today > goalDateEnd) {
        colorStatus = listColorsStatus.overdue
      }
      setColorStatusGoal(colorStatus)

    }

  }, [goalsData])

  return (
    <main className='containerDetailsKanban'>

      <header className='details-kanban-view-header '>
        <div className='infos-container'>
          <div
            style={{ minWidth: 72, minHeight: 72, width: 72, height: 72, display: 'flex', alignItems: 'center' }}
          >
            <CircularProgressbarWithChildren
              value={goalsData?.percentProgressGeneral || 0}
              strokeWidth={10}
              styles={buildStyles({
                strokeLinecap: "butt",
                trailColor: "#eee",
                pathColor: colorStatusGoal,
              })}
            >
              <span style={{ color: '#4F7091', fontSize: 14, fontWeight: 500 }}>
                {numberToPercentString(goalsData.percentProgressGeneral)}
              </span>
            </CircularProgressbarWithChildren>
          </div>

          <div className='header-content'>
            <div>
              <strong>
                {goalsData?.name}
              </strong>
            </div>
            <ul>
              <li>
                <div className='completed' />
                {t('goals.activities_info_completed')}:
                <span>{goalsData?.completed || 0}</span>
              </li>
              <li>
                <div className='progress' />
                {t('goals.activities_info_progress')}:
                <span>{goalsData?.progress || 0}</span>
              </li>
              <li>
                <div className='planning' />
                {t('goals.activities_info_planned')}:
                <span>{goalsData?.planned || 0}</span>
              </li>
              <li>
                <div className='late' />
                {t('goals.activities_info_late')}:
                <span>{goalsData?.late || 0}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className='boxButtonHeader'>
          <button type='button' onClick={openDrawerDetailsObjective}>
            <IconInfoFlat />
          </button>
        </div>
      </header>
      <div className='kanban-container'>
        {goalsData.keyresults?.map((kr, index) => {
          return (
            <div key={kr.id} className='kanban-kr-container'>
              <header style={{ marginBottom: kr.tasks.length === 0 ? '0px' : '16px'}}>
                <h2 style={{ color: statusColors[kr.krStatus - 1] }}>
                  KR{index + 1} - {kr.title}
                </h2>
                
                <DotsThreeVertical 
                  size={30} weight="bold" 
                  color='#98ACC1' 
                  onClick={e => userDetails?.krsPermissions.includes(Number(kr.id)) && handleClickMenuKeyResult(e, kr)} 
                  style={{ opacity: userDetails?.krsPermissions.includes(Number(kr.id)) ? '' : '0'}}
                />
              </header>

              <div className='krs-cards'>
                {kr.tasks?.map(task => (
                  <KanbanCard
                    task={task}
                    kr={kr}
                    key={task.id}
                    showModalTask={showModalTask}
                  />
                ))}
              </div>
              {userDetails?.krsPermissions.includes(Number(kr.id)) && (
                <KanbanAddCard kr={kr} showModalNewTask={showModalNewTask} />
              )}

            </div>
          )
        })}
      </div>

      {currentKeyResult && (
        <Menu
          className="menu-options"
          id={`menu-options-key-result-kanban`}
          anchorEl={anchorElOptionsKeyResult}
          keepMounted
          open={Boolean(anchorElOptionsKeyResult)}
          onClose={handleCloseMenuOptionsKeyResult}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ horizontal: "center" }}
        >
          <MenuItem onClick={() => handleOpenModalNewTask()}>
            {t('tasks.new_task')}
          </MenuItem>
        </Menu>
      )}

    </main>
  );
}
