// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".muJ3gdWSZS6\\+SXKl6z2DRQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 4px 0px;\n  min-width: 120px;\n}\n\n._7Na1RmWSCDACh0djfTQb7A\\=\\=,\n.mL6rbP-nvDp-GnlHhxVkIQ\\=\\= {\n  color: #BED3DA;\n  font-weight: 400;\n  border: none;\n  background-color: transparent;\n  padding: 10px 10px;\n  cursor: pointer;\n  font-size: 1rem;\n  text-align: left;\n}\n\n._7Na1RmWSCDACh0djfTQb7A\\=\\=:hover {\n  transition: ease 0.3s;\n  background-color: #106276;\n  color: #FDBE2B;\n}", "",{"version":3,"sources":["webpack://./src/_components/_shared/FilterbarTeams.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AACA;;EAEE,cAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AAAA;EACE,qBAAA;EACA,yBAAA;EACA,cAAA;AAGF","sourcesContent":[".menuOptionsActions{\n  display: flex;\n  flex-direction: column;\n  padding: 4px 0px;\n  min-width: 120px;\n}\n.menuOptionsActionButton,\n.menuOptionsActionButtonDisabled{\n  color: #BED3DA;\n  font-weight: 400;\n  border: none;\n  background-color: transparent;\n  padding: 10px 10px;\n  cursor: pointer;\n  font-size: 1rem;\n  text-align: left;\n}\n.menuOptionsActionButton:hover{\n  transition: ease 0.3s;\n  background-color: #106276;\n  color: #FDBE2B;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuOptionsActions": "muJ3gdWSZS6+SXKl6z2DRQ==",
	"menuOptionsActionButton": "_7Na1RmWSCDACh0djfTQb7A==",
	"menuOptionsActionButtonDisabled": "mL6rbP-nvDp-GnlHhxVkIQ=="
};
export default ___CSS_LOADER_EXPORT___;
