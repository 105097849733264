import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './TableReportsTransactionalPagination.module.scss';
import { Pagination } from '@material-ui/lab';
import { ArrowDown, ArrowRight, ArrowUp, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const TableReportsTransactionalPagination = ({ columns, data, count, getData, pageCountRows, alertActions, dateRef, startDate, endDate, filter }) => {

  const { t } = useTranslation('common');

  const [firstLoading, setFirstLoading] = useState(true);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, sortOrder, globalFilter }
  } = tableInstance;


  const fetchData = async ({ pageIndex, pageSize, sortBy, sortOrder, globalFilter, filter }) => {
    try {
      getData(pageIndex, pageSize, filter)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false)
      return
    }
    fetchData({ pageIndex, pageSize, sortBy, sortOrder, globalFilter, filter });
  }, [pageIndex, pageSize, filter]);


  return (
    <div className={styles.tableContainer}>

      <table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {

                  if (!['username', 'group_name'].includes(column.id)) {
                    return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'center', width: '150px' }}>
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                    </th>
                  }
                  return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'left' }}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                })}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            data.length > 0
            ?
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ textAlign: `${!['username', 'group_name'].includes(cell.column.id) ? 'center' : 'left'}`, fontSize: '12px' }} >{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
            :
            <tr>
              <td colSpan={5}>
                {t('generic_messages.none_data')}
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div className={styles.containerPagination}>
        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, page) => {
            gotoPage(page - 1)
          }}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem('pageSizeStorage', Number(e.target.value));
          }}
          className={styles.selectOptions}
        >
          {myPageOptions.map((pageSize) => (
            <option key={pageSize.value} value={pageSize.value}>
              {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TableReportsTransactionalPagination;
