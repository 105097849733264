import moment from "moment";
import React, { createContext, useContext, useState } from "react";

const DatePickerFilterContext = createContext({})

export function DatePickerFilterProvider({ children }) {
  const dateNow = moment()

  const [startDate, setStartDate] = useState(dateNow.subtract(2, 'months').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  return (
    <DatePickerFilterContext.Provider value={{ startDate, endDate, setStartDate, setEndDate }}>
      {children}
    </DatePickerFilterContext.Provider>
  )
}

export const useDatePickerFilter = () => useContext(DatePickerFilterContext)