import config from 'config';
import {authHeader} from '../_helpers';

export const downloadsService = {
    getDownloads

};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getDownloads() {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), ...{'Content-Type': 'application/octet-stream'}}
    };


    return fetch(`${config.apiNodeUrl}/downloads`, requestOptions).then(handleResponse);


}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

