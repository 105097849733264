import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { TrashSimple } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { authHeader, history } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import style from './Form.module.scss';
import { ModalConfirm } from './ModalConfirm';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Switch from "react-switch";

export function Form({ userDetails, teammates, alertActions, showToast, current_user_id }) {
  const { t, i18n } = useTranslation('common');
  const { id } = useParams();

  const search = useLocation().search;
  const dateRef = new URLSearchParams(search).get("date");

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [reservationTimes, setReservationTimes] = useState(null)
  const [currentRoom, setCurrentRoom] = useState(null)
  const [startTime, setStartTime] = useState("00:00")
  const [endTime, setEndTime] = useState("01:00")
  const [reservationId, setReservationId] = useState(null)
  const [participant, setParticipant] = useState(0)

  let formSchema = yup.object({
    subject: yup.string().required(t('spaces.required_field')),
    observations: yup.string(),
    responsible: yup.number().positive(t('spaces.select_responsible')).required(t('spaces.required_field')),
    participants: yup.array(),
    date: yup.string().required(),
    reservations: yup.array(),
    solicitations: yup.array()
  });

  const { register, handleSubmit, watch, setValue, formState: { errors }, reset, setError, getValues } = useForm({
    defaultValues: {
      subject: '',
      observations: '',
      responsible: current_user_id || 0,
      participants: [],
      date: dateRef ? moment(dateRef).format('YYYY-MM-DD') : moment().toISOString(),
      reservations: [],
      hasSolicitations: false,
      solicitations: []
    },
    resolver: yupResolver(formSchema)
  });

  const { responsible, participants, subject, observations, date, reservations, solicitations, hasSolicitations } = watch()

  const [solicitationForm, setSolicitationForm] = useState({
    name: '',
    email: '',
    message: ''
  })

  const [solicitationFormError, setSolicitationFormError] = useState({
    name: '',
    email: '',
    message: ''
  })


  const isRoomFree = currentRoom?.status === "free"

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const resetSolicitationForm = () => {
    setSolicitationForm({
      name: '',
      email: '',
      message: ''
    })
    setSolicitationFormError({
      name: '',
      email: '',
      message: ''
    })
  }

  const handleChangeSolicitations = (event) => {
    const { name, value } = event.target
    setSolicitationForm({ ...solicitationForm, [name]: value })
  }

  const validateSolicitationForm = (form) => {
    const errors = {};

    if (!form.name) {
      errors.name = t("validation.required")
    }

    if (!form.email) {
      errors.email = t("validation.required")
    }

    const checkIfExists = solicitations.find(item => item.email === form.email)

    if (checkIfExists) {
      errors.email = t("validation.email_exists")
    }

    if (!form.message) {
      errors.message = t("validation.required")
    }

    return errors
  }

  function handleAddSolicitation() {

    const errors = validateSolicitationForm(solicitationForm)

    if (Object.keys(errors).length === 0) {
      setValue('solicitations', [...solicitations, solicitationForm])
      resetSolicitationForm()
    } else {
      setSolicitationFormError(errors)
    }
  }

  function handleRemoveSolicitation(email) {
    const solicitationsFiltered = participants.filter(item => item.email !== email)

    setValue('solicitations', solicitationsFiltered)
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  const convertToTime = (valueTime) => {
    const hour = dayjs(valueTime).hour()
    const minute = dayjs(valueTime).minute()
    let hourString = ''
    let minuteString = ''
    if (hour < 10) {
      hourString = '0' + String(hour)
    } else {
      hourString = String(hour)
    }
    if (minute < 10) {
      minuteString = '0' + String(minute)
    } else {
      minuteString = String(minute)
    }
    return hourString + ':' + minuteString
  }

  function handleOpenConfirmModal(reservation_id) {
    setReservationId(reservation_id)
    setOpenConfirmModal(true)
  }

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false)
  }

  async function getAvailableTimesToDay(roomId, dateRef) {
    try {
      if (!roomId) {
        throw Error('Room id is empty')
      }
      const { data } = await api.get(`/rooms/available-time/?room_id=${roomId}&date=${dateRef}`, {
        headers: authHeader()
      })
      setReservationTimes(data)
      setValue('reservations', [])
    } catch (error) {
      console.error(t('spaces.errors.load'))
    }
  }

  async function getRoomData(date = '') {
    try {
      const filterDate = date ? `&date=${date}` : ''
      
      const { data: [room] } = await api.get(`/rooms/locations?rooms_ids=${id}${filterDate}`, {
        headers: authHeader()
      })
      if (room) {

        // setValue('date', dateRef ? moment(dateRef).format('YYYY-MM-DD') : moment().toISOString())
        getAvailableTimesToDay(room.id, date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        setCurrentRoom(room)
      }
    } catch (error) {
      console.error(error.response)
    }
  }

  function handleChangeDate(e) {
    const newDate = moment(e.$d).format().split("T")[0];
    setValue('date', newDate)
    if (currentRoom) {
      getRoomData(newDate)      
      getAvailableTimesToDay(currentRoom?.id, newDate)
    }
  }

  function handleAddCollaborator() {
    const checkIfExists = participants.find(item => item === participant)

    if (!checkIfExists && participant !== 0) {
      setValue('participants', [...participants, participant])
      setParticipant(0)
    }
  }

  function handleRemoveCollaborator(collab_id) {
    const collabFiltered = participants.filter(item => item !== collab_id)

    setValue('participants', collabFiltered)
  }

  function handleAddOrRemoveReservation(reservation) {

    const checkIfExists = reservations.find(item => item.start_time === reservation.startTime)

    if (!checkIfExists) {
      setValue('reservations', [...reservations, { start_time: reservation.startTime, end_time: reservation.endTime }])
    } else {
      const reservationsFiltered = reservations.filter(item => item.start_time !== reservation.startTime)
      setValue('reservations', [...reservationsFiltered])
    }
  }

  function checkIfIsSelected(time) {
    const checkIfExists = reservations.length > 0 && reservations.find(item => item.start_time === time.startTime)

    return checkIfExists
  }


  const onSubmit = async (data) => {
    try {
      if (currentRoom.type !== "F" && reservations.length === 0) {
        setError('reservations', { type: 'custom', message: t('validation.select_period') })
        return
      }

      let payload = null

      if (currentRoom.type !== "F") {
        payload = {
          rooms_id: id,
          responsible_id: data.responsible,
          subject: data.subject,
          observation: data.observations,
          date: moment(data.date).format('YYYY-MM-DD'),
          participants_ids: data.participants,
          times: data.reservations,
          solicitations: hasSolicitations ? data.solicitations : []
        }
      } else {
        payload = {
          rooms_id: id,
          responsible_id: data.responsible,
          subject: data.subject,
          observation: data.observations,
          date: moment(data.date).format('YYYY-MM-DD'),
          participants_ids: data.participants,
          times: [],
          start_time: startTime,
          end_time: endTime,
          solicitations: hasSolicitations ? data.solicitations : []
        }
      }

      const response = await api.post(`/rooms/reservations`,
        payload,
        {
          headers: authHeader()
        }
      )
      showToast(date, data.reservations, startTime, endTime)

      history.push('/spaces')
      reset()
      setStartTime('00:00')
      setEndTime('01:00')
    } catch (error) {
      console.log("/error", error.response)
      alertActions.error(error.response.data)
    }
  };

  useEffect(() => {
    if (dateRef) {
      getRoomData(moment(dateRef).format('YYYY-MM-DD'))
    } else {
      getRoomData()
    }
  }, [id])

  useEffect(() => {
    if (currentRoom && currentRoom.availableHour) {
      setStartTime(String(currentRoom?.availableHour?.split('-')[0]).trim())
      setEndTime(String(currentRoom?.availableHour?.split('-')[1]).trim())
    }
  }, [currentRoom])

  useEffect(() => {
    if (moment('2022-01-01 ' + startTime).isAfter('2022-01-01 ' + endTime)) {
      setEndTime(startTime)
    }
  }, [startTime])

  return (
    <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={16} color="#1E6B7F" fontWeight={500}>{currentRoom?.title}</Typography>
            {/* <Box
              display="flex"
              alignItems="center"
              gap="6px"
              padding="4px 8px"
              borderRadius="15px"
              bgcolor={isRoomFree ? "#D6F4F1" : "#22BEAD"}
            >
              <Box bgcolor={isRoomFree ? "#22BEAD" : "#fff"} width="6px" height="6px" borderRadius="100%"></Box>
              <Typography style={{ color: isRoomFree ? '#22BEAD' : '#fff', fontSize: "10px", fontWeight: 500 }}>
                {isRoomFree ? t('spaces.status.available') : t('spaces.reserved_to_me')}
              </Typography>
            </Box> */}
          </Box>


          <Box display="flex" alignItems="center" gap="8px">
            <Button
              type="button"
              style={{ minWidth: '100px', backgroundColor: "#E3EFFF", color: "#187DFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              onClick={() => {
                const searchParams = new URLSearchParams(location.search);
                const paramView = searchParams.get('view');
                if (paramView && paramView === 'map') {
                  history.push(`/spaces?view=map`)
                  return
                }
                history.push(`/spaces`)
              }}
            >
              {t('spaces.back')}
            </Button>

            {/* <Button
              type={isRoomFree ? "submit" : "button"}
              style={{ backgroundColor: isRoomFree ? "#187DFF" : "#FFEAED", color: isRoomFree ? "#FFFFFF" : "#FF5151", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              onClick={() => !isRoomFree && handleOpenConfirmModal(currentRoom.reservationsToday[0].id)}
            >
              {isRoomFree ? t('spaces.reserve') : t('spaces.cancel_reservation')}
            </Button> */}
            <Button
              type={"submit"}
              style={{ backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
            >
              {t('spaces.reserve')}
            </Button>
          </Box>

        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('spaces.subject')}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <TextField
              name="subject"
              label={t('spaces.subject')}
              error={false}
              helperText={""}
              value={subject}
              {...register('subject')}
              sx={{ width: '300px', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
            >
            </TextField>

            {errors.subject && (
              <Typography fontSize={12} color="#FF7A90" component="span">
                {errors.subject.message}
              </Typography>
            )}
          </Box>

        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('spaces.obs')}</Typography>
          </Box>

          <TextField
            name="observation"
            label={t('spaces.obs')}
            error={false}
            helperText={""}
            multiline
            rows={3}
            maxRows={3}
            value={observations}
            {...register('observations')}
            sx={{ width: '300px', fieldset: { border: '2px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
          >
          </TextField>

        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('spaces.responsible')}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <TextField
              select
              name="responsible"
              label={t('spaces.responsible')}
              error={false}
              helperText={""}
              value={responsible}
              {...register('responsible')}
              sx={{ width: '300px', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
            >
              <MenuItem value={0} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                {t('spaces.select')}
              </MenuItem>

              {teammates?.payload?.map(teammate => (
                <MenuItem key={teammate.id} value={teammate.id} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                  {teammate.name}
                </MenuItem>
              ))}
            </TextField>

            {errors.subject && (
              <Typography fontSize={12} color="#FF7A90" component="span">
                {errors.subject.message}
              </Typography>
            )}
          </Box>

        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>Data</Typography>
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('spaces.date')}
              value={date}
              inputFormat={getDataFormat(lang)}
              onChange={e => handleChangeDate(e)}
              minDate={moment().format("YYYY-MM-DD")}
              renderInput={(params) => (
                <TextField
                  variant='outlined'
                  {...params}
                  sx={{ width: '300px', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, input: { color: '#4F7091', paddingY: '11.5px' }, label: { top: '-4px' } }}
                  error={false}
                  helperText={""}
                />
              )}
            />
          </LocalizationProvider>

        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}> {t('spaces.available_times')}</Typography>
          </Box>


          <Box display="flex" flexDirection="column" gap="4px">
            <Box display="flex" alignItems="center" gap="4px" maxWidth="600px" justifyContent="inherit" flexWrap="wrap">
              {date ? (

                currentRoom?.type === 'T' ? (
                  reservationTimes !== null && reservationTimes.length > 0 ? (
                    reservationTimes.map((time, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        padding="10px 4px"
                        border="1px solid #98ACC1"
                        borderRadius="2px"
                        onClick={() => handleAddOrRemoveReservation(time)}
                        className={[style.time, checkIfIsSelected(time) && style.selected].join(" ")}
                      >
                        <Typography component="span" fontSize={10} color="#4F7091" lineHeight={1}>
                          {time.timeFormat}
                        </Typography>
                      </Box>
                    ))
                  )
                    :
                    <Typography component="span" fontSize={14} color="#4F7091" >
                      {t('spaces.no_available_times')}
                    </Typography>
                )
                  :
                  (

                    reservationTimes !== null && reservationTimes.length > 0 ? (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography component="span" color="#4F7091" fontSize={10}>{t('spaces.start')}</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              ampm={false}
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              mask="__:__"
                              // label={t("settings.teams.fri")}
                              value={dayjs('2022-01-01 ' + startTime)}
                              minTime={dayjs(`2022-01-01 ${currentRoom?.availableHour?.split('-')[0]}`)}
                              maxTime={dayjs(`2022-01-01 ${currentRoom?.availableHour?.split('-')[1]}`)}
                              onChange={(newValue) => setStartTime(convertToTime(newValue))}
                              renderInput={(params) => (
                                <TextField
                                  variant='outlined'
                                  {...params}
                                  style={{ width: 100, marginRight: 0, color: "#98ACC1" }}
                                  sx={{ fieldset: { border: '2px solid #DEE3E9CC' } }}
                                />
                              )}
                            />

                          </LocalizationProvider>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography component="span" color="#4F7091" fontSize={10}>{t('spaces.end')}</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              ampm={false}
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              mask="__:__"
                              // label={t("settings.teams.fri")}
                              value={dayjs('2022-01-01 ' + endTime)}
                              minTime={dayjs(`2022-01-01 ${startTime}`)}
                              maxTime={dayjs(`2022-01-01 ${currentRoom?.availableHour?.split('-')[1]}`)}
                              onChange={(newValue) => setEndTime(convertToTime(newValue))}
                              renderInput={(params) => (
                                <TextField
                                  variant='outlined'
                                  {...params}
                                  style={{ width: 100, marginRight: 0, color: "#98ACC1" }}
                                  sx={{ fieldset: { border: '2px solid #DEE3E9CC' } }}
                                />
                              )}
                            />

                          </LocalizationProvider>
                        </Box>
                      </>
                    ) :
                      (<Typography component="span" fontSize={14} color="#4F7091" >
                        {t('spaces.no_available_times')}
                      </Typography>)

                  )
              ) : (
                <Typography fontSize={14} color="#4F7091">
                  Selecione uma data!
                </Typography>
              )}
            </Box>

            {reservationTimes !== null && reservationTimes.length > 0 ? (
              errors.reservations && (
                <Typography fontSize={12} color="#FF7A90" component="span">
                  {errors.reservations.message}
                </Typography>
              )
            ) : (
              errors.start && (
                <Typography fontSize={12} color="#FF7A90" component="span">
                  {errors.start.message}
                </Typography>
              )
            )}

          </Box>


        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('spaces.participants')}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Box display="flex" alignItems="center" gap="8px">
              <TextField
                select
                name="teammate"
                label="Colaborador"
                error={false}
                helperText={""}
                value={participant}
                onChange={e => setParticipant(e.target.value)}
                sx={{ width: '300px', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
              >
                <MenuItem value="0" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                  {t('spaces.select')}
                </MenuItem>

                {teammates?.payload?.map(teammate => (
                  <MenuItem key={teammate.id} value={teammate.id} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%', color: participants.find((p) => p === teammate.id) && '#22BEAD' }}>
                    {teammate.name}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                onClick={handleAddCollaborator}
                style={{
                  backgroundColor: participant === 0 ? '#F5F6F9' : '#FDBE2B',
                  color: '#106276',
                  height: '42px',
                  marginBottom: '1px',
                  borderRadius: '2px',
                  fontSize: '12px',
                  textTransform: 'initial'
                }}
                disabled={participant === 0}
              >
                + {t('spaces.collaborator')}
              </Button>

            </Box>
            {errors.participants && (
              <Typography fontSize={12} color="#FF7A90" component="span">
                {errors.participants.message}
              </Typography>
            )}
          </Box>

        </Box>
        {
          participants.length > 0 &&
          <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '27px', textTransform: "uppercase" }} width="15%">
                    {t('spaces.collaborator')}
                  </TableCell>
                  <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px', textTransform: "uppercase" }}>E-mail</TableCell>
                  <TableCell className={style.tableHeaderTd} ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participants.map(id => (
                  <TableRow key={id} >
                    <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {teammates.payload.find(teammate => teammate.id === id)?.name}
                    </TableCell>
                    <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {teammates.payload.find(teammate => teammate.id === id)?.email}
                    </TableCell>
                    <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px', textAlign: 'right' }}>
                      <TrashSimple size={18}
                        weight="fill"
                        cursor="pointer"
                        className={style.trash}
                        onClick={() => handleRemoveCollaborator(id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer >
        }

        <Box display="flex" paddingY="20px" width={'100%'} borderTop={participants.length > 0 ? "none" : "2px solid #F5F6F9"} flexDirection={'column'}>
          <Box display="flex" justifyContent={'space-between'} marginBottom={'20px'} paddingX="12px">
            <Box display="flex" alignItems="center">
              <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('spaces.add_solicitations')}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={hasSolicitations}
                  onChange={(checked) => setValue('hasSolicitations', checked)}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="material-switch"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Box>
          </Box>
          {
            hasSolicitations &&
            <Box sx={{ flexGrow: 1 }} paddingX="12px">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="name"
                    label={t('spaces.name')}
                    error={solicitationFormError.name}
                    FormHelperTextProps={{
                      style: {
                        margin: 0
                      }
                    }}
                    helperText={solicitationFormError.name}
                    value={solicitationForm.name}
                    onChange={handleChangeSolicitations}
                    onFocus={() => setSolicitationFormError({ ...solicitationFormError, name: '' })}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="email"
                    label={t('spaces.email')}
                    error={solicitationFormError.email}
                    FormHelperTextProps={{
                      style: {
                        margin: 0
                      }
                    }}
                    helperText={solicitationFormError.email}
                    value={solicitationForm.email}
                    onChange={handleChangeSolicitations}
                    onFocus={() => setSolicitationFormError({ ...solicitationFormError, email: '' })}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    label={t('spaces.message')}
                    error={solicitationFormError.message}
                    FormHelperTextProps={{
                      style: {
                        margin: 0
                      }
                    }}
                    helperText={solicitationFormError.message}
                    multiline
                    rows={3}
                    maxRows={3}
                    value={solicitationForm.message}
                    onChange={handleChangeSolicitations}
                    onFocus={() => setSolicitationFormError({ ...solicitationFormError, message: '' })}
                    sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
                  />
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    onClick={handleAddSolicitation}
                    style={{
                      backgroundColor: '#FDBE2B',
                      color: '#106276',
                      height: '42px',
                      marginBottom: '1px',
                      borderRadius: '2px',
                      fontSize: '12px',
                      textTransform: 'initial'
                    }}
                  >
                    + {t('spaces.add')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                {
                  solicitations.length > 0 &&
                  <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow sx={{ textTransform: "uppercase" }}>
                          <TableCell className={style.tableHeaderTd} style={{ textTransform: "uppercase" }} width="20%">
                            {t('spaces.name')}
                          </TableCell>
                          <TableCell className={style.tableHeaderTd} style={{ textTransform: "uppercase" }} width="20%">{t('spaces.email')}</TableCell>
                          <TableCell className={style.tableHeaderTd} style={{ textTransform: "uppercase" }}>{t('spaces.message')}</TableCell>
                          <TableCell className={style.tableHeaderTd} width="4%" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {solicitations.map((solicitation, key) => (
                          <TableRow key={solicitation.email} >
                            <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                              {solicitation.name}
                            </TableCell>
                            <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                              {solicitation.email}
                            </TableCell>
                            <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                              {solicitation.message}
                            </TableCell>
                            <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px', textAlign: 'right' }}>
                              <TrashSimple size={18}
                                weight="fill"
                                cursor="pointer"
                                className={style.trash}
                                onClick={() => handleRemoveSolicitation(solicitation.email)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer >
                }
              </Grid>
            </Box>
          }
        </Box>

      </Box>

      <ModalConfirm openModal={openConfirmModal} handleCloseModal={handleCloseConfirmModal} reservation_id={reservationId} alertActions={alertActions} />
    </Box>
  )
}
