import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { api } from '../../../../../_helpers/api';
import { authHeader } from '../../../../../_helpers';
import { CircularProgress } from '@material-ui/core';
import AsyncSelect from 'react-select/async';

const InputFilterSite = ({
  selectedSite=null,
  setSelectedSite= () => {}
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  
  const handleChangeInput = async (event) => {
    try {
      const {value} = event.target

      console.log(value)

      if(!value || String(value).length <= 3) return

      setLoading(true);
      const { data } = await api.get(`/settings/trackeds-sites/?search_text=${value}`, {
        headers: authHeader()
      });
      setOptions(data);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar trackeds sites:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  
  const loadOptions = async (inputValue, callback) => {

    const { data } = await api.get(`/settings/trackeds-sites/?search_text=${inputValue}`, {
      headers: authHeader()
    });

    // Chame o callback com as opções encontradas
    callback(data);
  };

  const handleChange = selectedOption => {
    setSelectedValues(selectedOption);
  };

  useEffect(() => {
    console.log("selectedValues => ", selectedValues)
  }, [selectedValues])

  return (
    <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={t("software_inventory.form_license.placeholder_apps_used")}
        loadOptions={loadOptions}
        onChange={handleChange}
        value={selectedValues}
      />
    // <Autocomplete
    //   multiple
    //   freeSolo
    //   disableClearable
    //   id="free-solo-2-demo"
    //   open={open}
    //   onOpen={() => {
    //     setOpen(true);
    //   }}
    //   onClose={() => {
    //     setOpen(false);
    //   }}
    //   value={selectedSite}
    //   options={options}
    //   loading={loading}
    //   onChange={(e, value) => setSelectedSite(value)}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       variant="outlined"
    //       placeholder='Digite aqui para buscar o dominio do site...'
    //       helperText="Ex: befective.com"
    //       onChange={handleChangeInput}
    //       InputProps={{
    //         ...params.InputProps,
    //         endAdornment: (
    //           <>
    //             {loading ? <CircularProgress color="inherit" size={20} /> : null}
    //             {params.InputProps.endAdornment}
    //           </>
    //         ),
    //       }}
    //     />
    //   )}
    // />
  );
};

export default InputFilterSite;
