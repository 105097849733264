import React, { useState } from 'react'

import './styles.scss'

export function ChangeMyActivitiesButtons({ 
  setFirstPageActive, 
  first_label,
  second_label 
}) {
  const [isMyActivitiesSelected, setIsMyActivitiesSelected] = useState(true)

  function handleToggleMyActivitiesSelected(flag) {
    setIsMyActivitiesSelected(flag)
    setFirstPageActive(flag)
  }
  
  return (
    <div className="my-activities-button-container">
      <button 
        className={isMyActivitiesSelected ? 'selected' : ''} 
        type='button' onClick={() => handleToggleMyActivitiesSelected(true)}
      >
        {first_label}
      </button>
      <button
        className={!isMyActivitiesSelected ? 'selected' : ''}
        type='button' onClick={() => handleToggleMyActivitiesSelected(false)}
      >
        {second_label}
      </button>
    </div>
  )
}
