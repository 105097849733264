import { Backdrop, CircularProgress, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { alertActions, usersActions, teammatesActions, shareActions } from '../../_actions';
import IconClose from '../../_components/_icons/IconClose';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { CardsHeaderReportsOvertime } from '../../_components/checkin_page/reports_overtime/CardsHeader';
import TableCollapsible from '../../_components/checkin_page/reports_overtime/TableCollapsible';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinReportsOvertimePage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, shareActions, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataReportsOvertime, setDataReportsOvertime] = useState({});

  const [orderedColumn, setOrderedColumn] = useState('username')
  const [order, setOrder] = useState('asc');


  const orderColumn = (column, type) => {

    const orderedData = orderData(dataReportsOvertime.dataStats, column, type, orderedColumn, setOrderedColumn, order, setOrder)


    setDataReportsOvertime({
      ...dataReportsOvertime,
      dataStats: orderedData
    })

  };


  async function getDataReportsOvertime(dateFilter = moment(new Date()).format('YYYY-MM'), teams = null, teammates = null) {
    try {

      setFetching(true)

      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/checkin/report/stats_summaries?year_month=${dateFilter}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      setDataReportsOvertime(response.data)
      setDataReportsOvertime({
        ...response.data,
        dataStats: [...response.data.dataStats].sort((a, b) => a.username.localeCompare(b.username))
      })

      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    let date = moment(new Date()).format('YYYY-MM');

    if (filter.singleDate) {
      date = moment(filter.singleDate).format('YYYY-MM')
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataReportsOvertime(date, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }
  useEffect(() => {

    document.title = `Befective | ${t('menu.check_in_remote')}`;
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'single'}
          filterWhat={'disabled'}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderReportsOvertime alertActions={alertActions} userDetails={userDetails} resume={dataReportsOvertime?.stats} />
                <TableCollapsible data={dataReportsOvertime?.dataStats || []} orderColumn={orderColumn} orderedColumn={orderedColumn} order={order} />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} />
          }
        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'checkinOvertime'} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinReportsOvertimePage)