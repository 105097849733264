import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Tooltip } from '@material-ui/core';
import { ArrowElbowDownRight, ChartLine, CheckCircle, DotsThreeVertical, Warning } from 'phosphor-react';
import React, { useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

import { befectiveFormatDate } from '../../../../_helpers';

import './styles.scss'


const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    padding: '0',
    height: '64px',
  }
}));


const cardsKrColors = [
  {
    backgroundColor: '#E4EAEF',
    color: '#98ACC1',
  }, {
    backgroundColor: '#FFF0DD',
    color: '#FDA02B',
  }, {
    backgroundColor: '#DBF5F2',
    color: '#22BEAD',
  }, {
    backgroundColor: '#FFEAED',
    color: '#FF5151',
  }
]


export function GoalsCardsView({ userDetails, alertActions, goalsDataCards, getGoalsCardsInfo }) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const [cardsExpanded, setCardsExpanded] = useState(goalsDataCards.goals?.filter(item => item.all_kr.length > 0).reduce((acc, item) => {
    return [...acc, item.id]
  }, []))

  const handleChange = (panel, canExpand) => (event, isNotExpanded) => {
    if (isNotExpanded && canExpand) {
      const cardsToExpand = [
        ...cardsExpanded, panel
      ]

      setCardsExpanded(cardsToExpand)
    } else {
      const cardsFiltered = cardsExpanded?.filter(item => item !== panel)

      setCardsExpanded(cardsFiltered)
    }
  };

  return (
    <main className='containerObjectiveMainCards'>

      <header className='goals-cards-view-header'>
        <div className='card-header' style={{ borderLeft: "2px solid #FDA02B"}}>
          <ChartLine size={22} color="#FDA02B" style={{marginBottom: "12px"}}/>
          <strong className='progress-info' style={{ color: "#FDA02B" }}>{goalsDataCards?.generalProgress || 0}%</strong>
          <span className='card-label' style={{ color: "#FDA02B" }}>{t('goals.progress-general')}</span>
        </div>

        <div className='card-header' style={{ borderLeft: "2px solid #98ACC1" }}>
          <CheckCircle size={22} color="#98ACC1" style={{ marginBottom: "12px" }} />
          <strong className='progress-info'>{goalsDataCards?.goalsStats || '0/0'}</strong>
          <span className='card-label'>{t('goals.goals-done')}</span>
        </div>

        <div className='card-header' style={{ borderLeft: "2px solid #98ACC1" }}>
          <CheckCircle size={22} color="#98ACC1" style={{ marginBottom: "12px" }} />
          <strong className='progress-info'>{goalsDataCards?.krStats || '0/0'}</strong>
          <span className='card-label'>{t('goals.krs-done')}</span>
        </div>

        <div className='card-header' style={{ borderLeft: "2px solid #FDA02B" }}>
          <ArrowElbowDownRight size={22} color="#FDA02B" style={{ marginBottom: "12px" }} />
          <strong className='progress-info' style={{ color: "#FDA02B" }}>{goalsDataCards?.goalRunning || 0}</strong>
          <span className='card-label' style={{ color: "#FDA02B" }}>{t('tasks.progress')}</span>
        </div>

        <div className='card-header' style={{ borderLeft: "2px solid #98ACC1" }}>
          <ChartLine size={22} color="#98ACC1" style={{ marginBottom: "12px" }} />
          <strong className='progress-info' style={{ color: "#98ACC1" }}>{goalsDataCards?.goalPlanning || 0}</strong>
          <span className='card-label' style={{ color: "#98ACC1" }}> {t('goals.activities_info_planned')}</span>
        </div>

        <div className='card-header' style={{ borderLeft: "2px solid #FC6662" }}>
          <Warning size={22} color="#FC6662" style={{ marginBottom: "12px" }} />
          <strong className='progress-info' style={{ color: "#FC6662" }}>{goalsDataCards?.goalLate || 0}</strong>
          <span className='card-label' style={{ color:"#FC6662"}}> {t('goals.activities_info_late')}</span>
        </div>
      </header>

      <div className='drag-drop-container'>
        {goalsDataCards.goals?.map(item => {
          const today = new Date(String(new Date().toISOString()).split('T')[0])

          const listColorsStatus = {
            completed: '#22BEAD',
            progress: '#FDA02B',
            planning: '#98ACC1',
            overdue: '#FF5151'
          }

          let colorStatus = listColorsStatus.planning
        
          let goalProgress = Number(item.progress) || 0

          let goalDateEnd = new Date(item?.date_end)
          if (goalProgress > 0 && goalProgress < 100 && !(today > goalDateEnd)) {
            colorStatus = listColorsStatus.progress

          } else if (goalProgress >= 100) {
            colorStatus = listColorsStatus.completed
          } else if (goalProgress > 0 && goalProgress < 100 && today > goalDateEnd) {
            colorStatus = listColorsStatus.overdue
          }        

          const expanded = cardsExpanded.includes(item.id)

          return (
            <Accordion
              key={item.id}
              className={classes.accordion}
              expanded={expanded}
              onChange={handleChange(item.id, item.all_kr.length > 0)}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.summary}
              >

                <div
                  className={
                    expanded === item.id
                      ? 'card-goal-container open'
                      : 'card-goal-container'
                  }
                  style={
                    expanded === item.id ? {
                      borderBottom: '1px solid #E4EAEF',
                      boxShadow: 'none',
                      borderBottomLeftRadius: '0',
                      borderBottomRightRadius: '0',
                    } : {

                    }
                  }
                >
                  <header>
                    <div style={{ position: 'absolute', top: '5px', right: 0 }}>
                      <DotsThreeVertical size={18} />
                    </div>
                    <div
                      style={{ minWidth: 56, minHeight: 56, width: 56, height: 56, display: 'flex', alignItems: 'center' }}
                    >
                      <CircularProgressbarWithChildren
                        value={Number(item.progress)}
                        strokeWidth={14}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          trailColor: "#eee",
                          pathColor: colorStatus,
                        })}
                      >
                        <span
                          style={{
                            color: '#4F7091',
                            fontSize: '10px',
                            fontWeight: 500
                          }}
                        >
                          {Number(item.progress).toFixed(2)}%
                        </span>
                      </CircularProgressbarWithChildren>
                    </div>

                    <div className='goals-info'>
                      <strong>
                        {item.title.length >= 20 ? (
                          <Tooltip title={item.title} arrow>
                            <strong>
                              {item.title.substring(0, 20)}...
                            </strong>
                          </Tooltip>
                        ) : (
                          <strong>
                            {item.title}
                          </strong>
                        )}

                      </strong>
                      <span>{t('goals.updatedAt') + " " + befectiveFormatDate(item.updated_at, i18n.language)}</span>
                    </div>
                  </header>
                </div>

              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className='card-goal-container open'>
                  <ul className='krs-container'>
                    {item.all_kr.map(item => (
                      <li key={item.id}>
                        <span
                          style={{
                            color: cardsKrColors[item.krStatus - 1].color
                          }}
                        >
                          {item.label} - {item.title}
                        </span>
                        <div
                          style={{
                            color: cardsKrColors[item.krStatus - 1].color,
                            backgroundColor: cardsKrColors[item.krStatus - 1].backgroundColor
                          }}
                        >
                          {item.progress}%
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </main>
  );
}

export default GoalsCardsView;