import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'


import CompanyDaysoff from '../_components/settings/company/CompanyDaysoff'
import CompanyDaysoffSkeleton from '../_components/settings/company/CompanyDaysoffSkeleton'

import DrawerDaysoff from '../_components/settings/company/DrawerDaysoff'


import { useTranslation } from "react-i18next";

import { settingsActions } from '../_actions';

import IconClose from '../_components/_icons/IconClose';
import FilterbarTeams from '../_components/_shared/FilterbarTeams';

function CompanyPageDaysoff(props) {
	const { t } = useTranslation('common');

	const { settingsActions, filter } = props;

	let permissions = props.permissions || [];

	let loading = props.loading;
	let loadingSpecific = props.loadingSpecific;


	const [drawer, setDrawer] = useState(false)
	const [isShowingDrawerDayoff, setDrawerDayoff] = useState(false)
	const [drawerCurrent, setDrawerCurrent] = useState()

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)


	function openDrawer(data) {
		setDrawer(true)
		setDrawerCurrent(data)

		if (data.type == "dayoff") {

			setDrawerDayoff(true)

		}

	}

	function closeDrawer() {
		setDrawer(false)
		setDrawerDayoff(false)
	}

	useEffect(() => {

		settingsActions.getCompanyDaysoff()
	}, [filter.teammates])

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.company') + ' | ' + t('settings.daysoff');
	});

	return (
		<React.Fragment>
			<section className="mainContent">
				<Toolbar />

				<div className="mainFilterbar">
					{(permissions.ViewCompanyInformation) &&
						<NavLink exact to="/settings/company" activeClassName="active">
							<button className={'settingsNav'}>{t('settings.information')}</button>
						</NavLink>
					}

					{(permissions.ViewCompanyAdministrators) &&
						<NavLink to="/settings/company/administrators" activeClassName="active">
							<button className={'settingsNav'}>{t('settings.administrators')}</button>
						</NavLink>
					}

					{(permissions.ViewCompanyFreeDays) &&
						<NavLink to="/settings/company/daysoff" activeClassName="active">
							<button className={'settingsNav'}>{t('settings.daysoff')}</button>
						</NavLink>
					}

					{/* {(permissions.ViewCompanyInventory) &&
						<NavLink to="/settings/company/inventory-teammates" activeClassName="active">
							<button className={'settingsNav'}>{t('settings.inventory')}</button>
						</NavLink>
					} */}

					<FilterbarTeams />
				</div>
				{/* PAGE STARTS */}

				<div className="mainPage profile">
					<div className="nocolumn">


						<div className={'tab full active'}>
							{
								!minimumTimeElapsed || loading
									? <CompanyDaysoffSkeleton />
									: <CompanyDaysoff openDrawer={(data) => {
										openDrawer(data)
									}} />
							}
						</div>

					</div>
				</div>
				{/* PAGE ENDS */}
			</section>

			<div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
				setDrawer(false)
			}}></div>

			<section className={'drawer drawer600' + (drawer ? ' open' : '')}>
				<button onClick={() => {
					setDrawer(false)
				}} className="close"><IconClose /></button>
				{(isShowingDrawerDayoff && !loadingSpecific) &&
					<DrawerDaysoff current={drawerCurrent} closeDrawer={() => {
						closeDrawer()
					}} />
				}

			</section>


		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		settingsActions: bindActionCreators(settingsActions, dispatch),
	}
}


function mapStateToProps(state) {

	return {
		loading: state.settings.loading,
		loadingSpecific: state.settings.loadingSpecific,
		filter: state.filter,
		permissions: state.authentication.permissions,
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageDaysoff)
