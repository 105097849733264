import React, {useState, useCallback, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'


import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import HistoricalSummary from '../_components/historical/HistoricalSummary.jsx'
import HistoricalSummarySkeleton from '../_components/historical/HistoricalSummarySkeleton'

import {useTranslation, initReactI18next} from "react-i18next";
import Moment from 'moment';

import {filterActions, historicalActions} from '../_actions';
import {useHistory} from "react-router-dom";

function HistoricalPage(props) {
    const {t, i18n} = useTranslation('common');

    const {historicalActions} = props;
    const {filterActions} = props;

    let loading = props.loading;
    let filter = props.filter || {};

    const history = useHistory();

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const maxDate = new Date(Moment().add(-1, 'd'));


    const [view, setView] = useState('summary');
    const [openWho, setOpenWho] = useState('');
    const [clearWho, setClearWho] = useState('');
    const [dayToShow, setDayToShow] = useState(null);
    const [whenFilter, setWhenFilter] = useState(true);


    let date = new Date();
    let startDate = new Date(Moment().add(-8, 'd')).toISOString().split('T')[0];
    let endDate = new Date(Moment()).toISOString().split('T')[0];


    function tiggerWho(day, now) {

        setOpenWho(now);

    }

    useEffect(() => {

        let startDate = new Date(Moment().add(-8, 'd')).toISOString().split('T')[0];
        let endDate = new Date(Moment()).toISOString().split('T')[0];
        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teammates && filter.teammates[0] && dayToShow) {
            teammates = filter.teammates;

            history.push("/productive/historical/hour?mu=" + teammates[0].id + "&day=" + dayToShow)
        } else if (filter.teammates && filter.teammates[0] && !dayToShow) {

            historicalActions.getHistoricalSummary(startDate, endDate, null, filter.teammates);
        } else {
            historicalActions.getHistoricalSummary(startDate, endDate);
        }


    }, [props.filter]);


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.historical');

    });

    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar/>
                <Filterbar defaultStartDate={startDate}
                           defaultEndDate={endDate}
                           openWho={openWho}
                           filterWho={'singleTeammates'}
                           filterWhen={'multi'}
                           filterWhat={'disabled'}
                           startDate={startDate}
                           endDate={endDate}
                           showShareBtn={false}
                    //maxDate={maxDate}
                />
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {!minimumTimeElapsed || loading === true
                            ? <HistoricalSummarySkeleton/>
                            : <HistoricalSummary startDate={startDate} endDate={endDate} openWho={(data) => {
                                tiggerWho(data.day, data.now);
                                setDayToShow(data.day);
                            }}/>
                        }
                    </div>
                </div>
                {/* PAGE ENDS */}

            </section>

        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        historicalActions: bindActionCreators(historicalActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.historical.loading,
        filter: state.filter,
        historical: state.historical
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalPage)
