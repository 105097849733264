import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, Checkbox, CircularProgress, Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Popover, Slide, withStyles } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";

import "./ModalCreateNote.scss"
import moment from "moment";
import { BefectiveDatePicker } from "../goals/BefectiveDatePicker";
import { CirclePicker, SliderPicker } from "react-color";
import { TagsInput } from "react-tag-input-component";
import { v4 as uuidv4 } from 'uuid';
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#E4EAEF',
    "&$checked": {
      color: '#22BEAD'
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function ModalCreateNote({
  openModalCreateNote,
  setOpenModalCreateNote,
  userDetails,
  alertActions,
  updateInfo
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [form, setForm] = useState({
    title: '',
    card_color: '',
    sharing: 'private',
    tags: [],
    checklist: []
  });

  const [checklistTitle, setChecklistTitle] = useState("")

  const [formError, setFormError] = useState({
    title: '',
    card_color: '',
    sharing: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function handleChangeColor(color) {
    setForm({
      ...form,
      card_color: color.hex
    })
    handleClose()
  }

  function closeModalCreateNote() {
    setOpenModalCreateNote(false)
    setForm({
      title: '',
      card_color: '',
      sharing: 'private',
      tags: [],
      checklist: []
    })
    setFormError({
      title: '',
      card_color: '',
      sharing: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('project_management.validations.required_field')
    }

    if (!form.card_color) {
      errors.card_color = t('project_management.validations.required_field')
    }

    if(!form.sharing){
      errors.sharing = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmitNote = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createNote = async () => {
    try {
      setIsSubmitting(true)

      const res = await api.post('/users/todo', {
        ...form,
        card_color: form.card_color === '' ? '#ffffff' : form.card_color
      }, {
        headers: authHeader()
      })
      if(res.status !== 200){
        throw new Error("Erro ao criar a nota")
      }
      updateInfo(false)
      alertActions.success(t('colab_page.checklist.success_created_note'))
      closeModalCreateNote()
      setIsSubmitting(false)
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
    }
  }

  const handleAddCheckList = (e) => {
    if (e.key === 'Enter' && checklistTitle !== '') {
      const checkIfExists = form.checklist.filter(
        item => item.title.toLowerCase() === checklistTitle.toLowerCase()
      )

      if (checkIfExists.length === 0) {
        setForm({
          ...form,
          checklist: [...form.checklist, {
            id: uuidv4(),
            title: checklistTitle,
            checked: false
          }]
        })

        setChecklistTitle('')
      }
    }

  }

  const handleRemoveCheckList = (id) => {
    setForm({ ...form, checklist: form.checklist.filter(item => item.id !== id) });
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createNote()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


  const handleToggle = (e, id) => {
    const {checked} = e.target

    const newCheckList = form.checklist.map(item => {
      if(item.id === id){
        return {
          ...item,
          checked: checked
        }
      }
      return item
    })

    setForm({
      ...form,
      checklist: newCheckList
    })
    
  }

  return (
    <>
      <Modal
        key={'modal-create-note'}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalCreateNote ${classes.modal}`}
        open={openModalCreateNote}
        onClose={closeModalCreateNote}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalCreateNote} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalCreateNote"}>

            <button onClick={() => { closeModalCreateNote() }} className="close"><IconClose /></button>

            <form>
              <main>
                <header>
                  <h3>
                    {t('colab_page.checklist.modal_note.title')}
                  </h3>
                </header>

                <div className="field">
                  <label htmlFor="card_color">{t('colab_page.checklist.modal_note.field_color')}*</label>
                  <div className="inputBgColor" aria-describedby={id} onClick={handleClick}>
                    {
                      form.card_color !== '' ? <div className="bgColorSelect" style={{ backgroundColor: form.card_color || '#ffffff' }}></div> : <div className="placeholderBgColor">{t('project_management.boards.modal.placeholder_bgcolor')}</div>
                    }
                  </div>
                  {formError.card_color &&
                    <div className="error">{formError.card_color}</div>
                  }
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        overflowY: 'hidden'
                      }
                    }}
                  >

                    <Box width={'595px'} padding={'10px'}>
                      <CirclePicker color={form.card_color} onChangeComplete={handleChangeColor} className="colorPickerCircle"
                        colors={[
                          "#D2EBBF",
                          "#FFF494",
                          "#FEDA8E",
                          "#D1BBFF",
                          "#CEE5FF",
                          "#EFE3FA",
                          "#E6ECF3",
                          "#D0D9E3",
                          "#CFFAE2",
                          "#FEC4D0",
                          "#FFEAED",
                          "#91E9F3"
                        ]}
                      />
                    </Box>
                  </Popover>
                </div>

                <div className="field">
                  <label htmlFor="title">{t('colab_page.checklist.modal_note.field_title')}*</label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    placeholder={t('colab_page.checklist.modal_note.placeholder_title')}
                    value={form.title || ''}
                    onChange={handleChange}
                  />
                  {formError.title &&
                    <div className="error">{formError.title}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="select_sharing">{t('colab_page.checklist.modal_note.field_sharing')}*</label>
                   <select
                      id="select_sharing"
                      name="sharing"
                      value={form.sharing}
                      onChange={handleChange}
                  >
                      <option value="private">
                        {t('colab_page.checklist.modal_note.option_private')}
                      </option>
                      <option value="manager">
                         {t('colab_page.checklist.modal_note.option_manager')}
                      </option>
                      <option value="peers">
                        {t('colab_page.checklist.modal_note.option_peers')}
                      </option>
                      <option value="all">
                        {t('colab_page.checklist.modal_note.option_all')}
                      </option>
                  </select>
                  {formError.sharing &&
                    <div className="error">{formError.sharing}</div>
                  }
                </div>

                <div className="tagField">
                  <label htmlFor="tags">{t('colab_page.checklist.modal_note.field_tags')}</label>
                  <div className='tagBox'>
                    <TagsInput
                      value={form.tags}
                      onChange={e => setForm({ ...form, tags: e })}
                      name="tags"
                      id="tags"
                      placeHolder={t('colab_page.checklist.modal_note.placeholder_tags')}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="checklistInput">{t('colab_page.checklist.modal_note.field_checklist_title')}</label>
                  <input
                    id="checklistInput"
                    type="text"
                    name="checklist_title"
                    placeholder={t('colab_page.checklist.modal_note.placeholder_checklist_title')}
                    value={checklistTitle || ''}
                    onChange={(e) => setChecklistTitle(e.target.value)}
                    onKeyDown={(e) => handleAddCheckList(e)}
                  />
                </div>
                <div className="listContainerChecklists">
                  {
                    form.checklist.length > 0
                      ?
                      <List style={{ padding: 0 }}>
                        {form.checklist.map((checklist) => {
                          const labelId = `checkbox-list-label-${checklist.id}`;

                          return (
                            <div key={labelId}>
                              <ListItem key={`checkbox-list-label-${checklist.id}`} role={undefined} dense>
                                <ListItemIcon style={{ minWidth: 'auto' }}>
                                  <GreenCheckbox
                                    edge="start"
                                    checked={checklist.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    onChange={(e) => handleToggle(e, checklist.id)}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={checklist.title} style={{ color: '#1E6B7F', fontSize: '14px' }} />
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="delete" size="small" onClick={() => handleRemoveCheckList(checklist.id)}>
                                    <Delete fontSize="inherit" color="error" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          );
                        })}
                      </List>
                      :
                      t('colab_page.checklist.none_checklist_item')
                  }
                </div>
                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalCreateNote() }}
                    >
                      {t('colab_page.checklist.modal_note.cancel')}
                    </button>
                    <button
                      type="button"
                      disabled={isSubmitting}
                      onClick={handleSubmitNote}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('colab_page.checklist.modal_note.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalCreateNote));
