import React from 'react';

const IconOrganization = props => {
    return (
        
     
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="invisible_box" data-name="invisible box">
          <rect id="Retângulo_1079" data-name="Retângulo 1079" width="16" height="16" fill="none"/>
        </g>
        <g id="Medical" transform="translate(0.667 1.333)">
          <g id="Grupo_113" data-name="Grupo 113">
            <path id="Caminho_100" data-name="Caminho 100" d="M31.067,31.1a5.8,5.8,0,0,0-6.8,0,.567.567,0,0,0-.267.467V33.5a.533.533,0,0,0,.533.5H30.8a.533.533,0,0,0,.533-.5V31.565A.567.567,0,0,0,31.067,31.1ZM30,32.665H25.333V32A4.5,4.5,0,0,1,30,32Z" transform="translate(-16.667 -21.331)" fill="#187dff"/>
            <path id="Caminho_101" data-name="Caminho 101" d="M31,20a2,2,0,1,0-2-2A2,2,0,0,0,31,20Zm0-2.667a.667.667,0,1,1-.667.667A.667.667,0,0,1,31,17.333Z" transform="translate(-20 -12)" fill="#187dff"/>
            <path id="Caminho_102" data-name="Caminho 102" d="M10,12V5.667H8.667V4H3.333V5.667H2V16a.667.667,0,0,0,1.333,0V7H4.667V5.333H7.333V7H8.667v5A.667.667,0,0,0,10,12Z" transform="translate(-2 -4)" fill="#187dff"/>
            <rect id="Retângulo_1080" data-name="Retângulo 1080" width="1.333" height="1.333" transform="translate(2.333 4.333)" fill="#187dff"/>
            <rect id="Retângulo_1081" data-name="Retângulo 1081" width="1.333" height="1.333" transform="translate(4.333 4.333)" fill="#187dff"/>
            <rect id="Retângulo_1082" data-name="Retângulo 1082" width="1.333" height="1.333" transform="translate(2.333 6.333)" fill="#187dff"/>
            <rect id="Retângulo_1083" data-name="Retângulo 1083" width="1.333" height="1.333" transform="translate(4.333 6.333)" fill="#187dff"/>
            <rect id="Retângulo_1084" data-name="Retângulo 1084" width="1.333" height="1.333" transform="translate(2.333 8.333)" fill="#187dff"/>
            <rect id="Retângulo_1085" data-name="Retângulo 1085" width="1.333" height="1.333" transform="translate(4.333 8.333)" fill="#187dff"/>
          </g>
        </g>
      </svg>
      
      


    )
}

export default IconOrganization;