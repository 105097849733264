import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './TableExtraCollections.module.scss'; // Importe seu arquivo de estilos
import { Pagination } from '@material-ui/lab';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp } from 'phosphor-react';
import { authHeader, history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import IconEdit2 from '../_icons/IconEdit2';
import IconRemove from '../_icons/IconRemove';
import { Box, Button, Popover, Typography } from '@mui/material';

const TableExtraCollections = ({ columns, data, handleOpenModalEditExtraCollection, getData, alertActions, userDetails }) => {

  const { t } = useTranslation('common');
  const [extraCollectionIdToDelete, setExtraCollectionIdToDelete] = useState(null)
  const [anchorElConfirmDeleteExtraCollection, setAnchorElConfirmDeleteExtraCollection] = useState(null);

  const handleClickConfirmDeleteExtraCollection = (event, extraCollectionId) => {
    setExtraCollectionIdToDelete(extraCollectionId)
    setAnchorElConfirmDeleteExtraCollection(event.currentTarget);
  };

  const handleCloseConfirmDeleteExtraCollection = () => {
    setExtraCollectionIdToDelete(null)
    setAnchorElConfirmDeleteExtraCollection(null);
  };

  const openConfirmDeleteExtraCollection = Boolean(anchorElConfirmDeleteExtraCollection);
  const idConfirmDeleteExtraCollection = openConfirmDeleteExtraCollection ? 'simple-popover-2' : undefined;


  const onDeleteExtraCollection = async () => {
    try {

      const res = await api.delete(`/settings/extra-collection/${extraCollectionIdToDelete}`, { headers: authHeader() })

      if (res.data && res.data.statusCode !== 200) {
        alertActions.error(res.data)
        handleCloseConfirmDeleteExtraCollection()
        return
      }
      handleCloseConfirmDeleteExtraCollection()
      getData()
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      alertActions.error(t('spaces.errors.delete'))
    }
  }

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  useEffect(() => {
  }, []);


  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  switch (column.id) {
                    case 'users':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ width: 'auto' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    default:
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ width: '120px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                  }

                })}
              </React.Fragment>
            ))}
            {
              (userDetails.allowed_update || userDetails.allowed_delete) && <th style={{ width: '0px' }} className={styles.headColTable} />
            }
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={`${userDetails.allowed_update ? styles.rowHover : ''}`}>
                {row.cells.map((cell) => {
                  switch (cell.column.id) {
                    case 'status':
                      return (
                        <td {...cell.getCellProps()} className={row.original.status ? styles.statusActive : styles.statusInactive} >{cell.render("Cell")}</td>
                      );
                    default:
                      return (
                        <td {...cell.getCellProps()} onClick={(e) => userDetails.allowed_update && handleOpenModalEditExtraCollection(e, row.original)} style={{ textAlign: `${['total_colabs'].includes(cell.column.id) ? 'center' : 'left'}` }}>{cell.render("Cell")}</td>
                      );
                  }
                })}
                {
                  (userDetails.allowed_update || userDetails.allowed_delete) && (
                    <td>
                      <div className={styles.boxActions}>
                        {
                          userDetails.allowed_update && (
                            <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={(e) => handleOpenModalEditExtraCollection(e, row.original)}>
                              <IconEdit2 />
                            </button>
                          )
                        }
                        {
                          userDetails.allowed_delete && (
                            <button className={`${styles.buttonAction} ${styles.remove}`} onClick={(e) => handleClickConfirmDeleteExtraCollection(e, row.original.id)}>
                              <IconRemove />
                            </button>
                          )
                        }
                      </div>
                    </td>
                  )
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      {
        data.length === 0 &&
        <div className={styles.boxNoData}>
          {t('settings.extra_collections.table.no_data')}
        </div>
      }

      <Popover
        id={idConfirmDeleteExtraCollection}
        open={openConfirmDeleteExtraCollection}
        anchorEl={anchorElConfirmDeleteExtraCollection}
        onClose={handleCloseConfirmDeleteExtraCollection}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FFE3E5",
            boxShadow: "none",
            padding: '18px 14px',
            borderRadius: '6px',
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

          <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmDeleteExtraCollection}>
              <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
            </Button>
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteExtraCollection}>
              <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default TableExtraCollections;
