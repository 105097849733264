import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'


import Skeleton from '@material-ui/lab/Skeleton';

function CompanyInformationSkeleton(props) {
  const { t, i18n } = useTranslation('common');

  return (
    <React.Fragment>

        <div className="form extra">
          <div className="field">
            <Skeleton variant="text" width="100%" height={72} />
          </div>
          <div className="field">
            <Skeleton variant="text" width="100%" height={72} />
          </div>
          <div className="field">
            <Skeleton variant="text" width="100%" height={72} />
          </div>
          <div className="field">
            <Skeleton variant="text" width="100%" height={72} />
          </div>
          <div className="field">
            <Skeleton variant="text" width="100px" height={64} />
            <Skeleton variant="text" width="100px" height={64} />
          </div>
        </div>
    
    </React.Fragment>
  )
};



function mapStateToProps(state) {

  return { auth: state.authentication.user }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyInformationSkeleton));