import React from 'react';

const IconStart = props => {
    return (
        
     
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
       
        <g id="Q3_icons" data-name="Q3 icons" transform="translate(0.667 0.667)">
          <g id="Grupo_37" data-name="Grupo 37">
            <path id="Caminho_32" data-name="Caminho 32" d="M22.667,4.667A.667.667,0,0,0,23.333,4V2.667a.667.667,0,0,0-1.333,0V4A.667.667,0,0,0,22.667,4.667Z" transform="translate(-15.333 -2)" fill="#fda02b"/>
            <path id="Caminho_33" data-name="Caminho 33" d="M22.667,38a.667.667,0,0,0-.667.667V40a.667.667,0,1,0,1.333,0V38.667A.667.667,0,0,0,22.667,38Z" transform="translate(-15.333 -26)" fill="#fda02b"/>
            <path id="Caminho_34" data-name="Caminho 34" d="M34.3,9.957l.967-.933a.767.767,0,0,0,0-.967.767.767,0,0,0-.967,0l-.933.967a.667.667,0,1,0,.933.933Z" transform="translate(-22.733 -5.924)" fill="#fda02b"/>
            <path id="Caminho_35" data-name="Caminho 35" d="M9.024,33.367l-.967.933a.767.767,0,0,0,0,.967.767.767,0,0,0,.967,0l.933-.967a.667.667,0,1,0-.933-.933Z" transform="translate(-5.924 -22.733)" fill="#fda02b"/>
            <path id="Caminho_36" data-name="Caminho 36" d="M40,22H38.667a.667.667,0,1,0,0,1.333H40A.667.667,0,1,0,40,22Z" transform="translate(-26 -15.333)" fill="#fda02b"/>
            <path id="Caminho_37" data-name="Caminho 37" d="M4.667,22.667A.667.667,0,0,0,4,22H2.667a.667.667,0,0,0,0,1.333H4A.667.667,0,0,0,4.667,22.667Z" transform="translate(-2 -15.333)" fill="#fda02b"/>
            <path id="Caminho_38" data-name="Caminho 38" d="M34.3,33.367a.667.667,0,1,0-.933.933l.933.967a.7.7,0,1,0,.967-.967Z" transform="translate(-22.733 -22.733)" fill="#fda02b"/>
            <path id="Caminho_39" data-name="Caminho 39" d="M9.024,9.957a.667.667,0,0,0,.933-.933l-.933-.967a.767.767,0,0,0-.967,0,.767.767,0,0,0,0,.967Z" transform="translate(-5.924 -5.924)" fill="#fda02b"/>
            <path id="Caminho_40" data-name="Caminho 40" d="M17.333,14a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,17.333,14Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,17.333,19.333Z" transform="translate(-10 -10)" fill="#fda02b"/>
          </g>
        </g>
      </svg>
      
      


    )
}

export default IconStart;