import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";

import IconStart from "../_icons/IconStart";
import {RadialChart} from "react-vis";


function ActivitiesProductivity(props) {
    const {t, i18n} = useTranslation('common');

    let productivity = props.productivity || {};

    const myData = [{
        angle: productivity.TotalWorkedTimeSite,
        color: '#1565C0'
    },
        {
            angle: productivity.TotalWorkedTimeSoftware,
            color: '#42A5F5'
        },
        {
            angle: productivity.TotalWorkedTimeOutdoor,
            color: '#BBDEFB'
        }]

    let PerSite = 0;
    let PerSoftware = 0;
    let PerOutdoor = 0;

    if (productivity.TotalWorkedTime > 0) {
        if (productivity.TotalWorkedTimeSite > 0) {
            PerSite = Math.round((parseInt(productivity.TotalWorkedTimeSite) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
        if (productivity.TotalWorkedTimeSoftware > 0) {
            PerSoftware = Math.round((parseInt(productivity.TotalWorkedTimeSoftware) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
        if (productivity.TotalWorkedTimeOutdoor > 0) {
            PerOutdoor = Math.round((parseInt(productivity.TotalWorkedTimeOutdoor) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
    }


    return (
        <div className={'box graph'}>
            <h3>{t('productivity.activities')}</h3>
            <div className={'helper'}>
                <ul>
                    <li className={'site'}>{t('productivity.site')} ({PerSite}%)</li>
                    <li className={'software'}>{t('productivity.software')} ({PerSoftware}%)</li>
                    <li className={'outdoor'}>{t('productivity.outdoor')} ({PerOutdoor}%)</li>
                </ul>
            </div>
            <div className={'piechart'}>
                {productivity.TotalWorkedTime > 0 &&
                    <RadialChart
                        data={myData}
                        colorType="literal"
                        stroke={'#fff'}
                        width={400}
                        height={400}/>
                }
                {productivity.TotalWorkedTime == 0 &&
                    t('productivity.not_set')
                }
            </div>
        </div>
    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(ActivitiesProductivity)
