import React from 'react';

import { Skeleton } from '@material-ui/lab';

import './styles.scss'

export function CardDividerProgressBarSkeleton() {

  return (
    <div className="container-activities-progress-skeleton">
      <header>
        <h2>
          <Skeleton variant="text" width="40%" height={22} />
        </h2>
      </header>

      <main className="content-activities-progress-skeleton">
        <ul>
            <li >
                <Skeleton variant="text" width="18%" height={15} />
                <Skeleton variant="text" width="18%" height={15} />
                <Skeleton variant="text" width="18%" height={15} />
                <Skeleton variant="text" width="18%" height={15} />
            </li>
        </ul>

        <div className="bar-activities-progress-skelleton ">
          <Skeleton variant="text" width="100%" height={8} />
        </div>
      </main>
    </div>
  )
};

