import React from 'react';

const IconCalendar2 = props => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
             viewBox="0 0 15 15">
      
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.625 0.618)">
                <path id="Caminho_103" data-name="Caminho 103"
                      d="M31.875,28h-1.25a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h1.25a.625.625,0,0,0,.625-.625v-1.25A.625.625,0,0,0,31.875,28Zm0,0h-1.25a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h1.25a.625.625,0,0,0,.625-.625v-1.25A.625.625,0,0,0,31.875,28Z"
                      transform="translate(-21.25 -19.868)" fill="#187dff"/>
                <path id="Caminho_104" data-name="Caminho 104"
                      d="M15.125,3.859H12.313V2.641a.656.656,0,0,0-.531-.656.625.625,0,0,0-.719.625v1.25H6.688V2.641a.656.656,0,0,0-.531-.656.625.625,0,0,0-.719.625v1.25H2.625A.625.625,0,0,0,2,4.484v10a.625.625,0,0,0,.625.625h12.5a.625.625,0,0,0,.625-.625v-10A.625.625,0,0,0,15.125,3.859Zm-.625,10H3.25V7.609H14.5Zm0-7.5H3.25V5.109H14.5Z"
                      transform="translate(-2 -1.977)" fill="#187dff"/>
                <rect id="Retângulo_1087" data-name="Retângulo 1087" width="2.5" height="2.5" rx="1.25"
                      transform="translate(8.75 8.132)" fill="#187dff"/>
            </g>
        </svg>


    )
}

export default IconCalendar2;
