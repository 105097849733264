import React, { createContext, useContext, useState } from "react";

const RoomsFilterContext = createContext({})

export function RoomsFilterProvider({ children }) {
  const [rooms, setRooms] = useState([])

  return (
    <RoomsFilterContext.Provider value={{ rooms, setRooms }}>
      {children}
    </RoomsFilterContext.Provider>
  )
}

export const useRoomsFilter = () => useContext(RoomsFilterContext)