import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { backfectiveActions } from '../../_actions';
import { MenuMagic } from './MenuMagic';
import { MenuSwitch } from './MenuSwitch';

// import { Container } from './styles';

import './ToolbarMu.scss'

function ToolbarMu({
  userDetails,
  organizations,
  breadcrumb
}) {
  const isLogged = localStorage.getItem(process.env.KEY_USER_LOCAL_STORAGE)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  
  return (
    <div className={'mainToolbar'}>
      <ul className={'breadCrub'}>
        <li>{breadcrumb}</li>
      </ul>

      <div className='switchBtn'>
        <MenuSwitch userDetails={userDetails} />
      </div>

      {userDetails && (
        <div className="user">

          <div className="avatar" onClick={handleClick}>
            <img
              src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(userDetails?.name)} />
          </div>
        </div>
      )}
      

      {userDetails && open && (
        <MenuMagic
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          userDetails={userDetails}
          handleClick2={handleClick2}
          open2={open2}
          anchorEl2={anchorEl2}
          handleClose2={handleClose2}
          organizations={organizations}
          backfectiveActions={backfectiveActions}
          isMuUser={true}
          showLogin={!isLogged}
        />
      )}
      
    </div>
  );
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    organizations: state.backfective.organizations,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    backfectiveActions: bindActionCreators(backfectiveActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolbarMu));
