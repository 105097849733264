import React, { useRef, useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux';
import { useTable, useSortBy, useRowSelect } from 'react-table'
import Moment from 'moment';
import { activitiesActions } from '../../_actions';

import DrawerActivityTableTeams from './DrawerActivityTableTeams'
import DrawerActivityTableTeammates from './DrawerActivityTableTeammates'
import DrawerActivityTeammateDetail from './DrawerActivityTeammateDetail'
import DrawerActivityTeammateDetailSkeleton from './DrawerActivityTeammateDetailSkeleton'

import IconTeam from '../_icons/IconTeam';
import IconTeammate from '../_icons/IconTeammate';
import IconArrow from '../_icons/IconArrow';

import { secondsToHm } from '../../_helpers';

function DrawerActivity(props) {
    const { t, i18n } = useTranslation('common');

    const { activitiesActions, userDetails } = props;

    let filter = props.filter;
    let current = props.current || {};
    let currentIndex = props.currentIndex;
    let active = props.active || 'teams';
    let stats = props.stats || {};
    let detailsTeams = props.detailsTeams || [];
    let detailsTeammates = props.detailsTeammates || [];
    let loadingDetailsSpecific = props.loadingDetailsSpecific;

    const [drawerMaxIndex, setDrawerMaxIndex] = useState(9);

    const [specificData, setSpecificData] = useState({});

    let currentPercent = 0;


    let TotalProductiveTeams = 0;
    let TotalNeutralTeams = 0;
    let TotalUnproductiveTeams = 0;

    let TotalProductiveTeammates = 0;
    let TotalNeutralTeammates = 0;
    let TotalUnproductiveTeammates = 0;

    for (let item of detailsTeams) {
        if (item.efficiencyName == "productive") {
            TotalProductiveTeams++;
        }
        if (item.efficiencyName == "neutral") {
            TotalNeutralTeams++;
        }
        if (item.efficiencyName == "unproductive") {
            TotalUnproductiveTeams++;
        }
    }

    for (let item of detailsTeammates) {
        if (item.efficiencyName == "productive") {
            TotalProductiveTeammates++;
        }
        if (item.efficiencyName == "neutral") {
            TotalNeutralTeammates++;
        }
        if (item.efficiencyName == "unproductive") {
            TotalUnproductiveTeammates++;
        }
    }

    useEffect(() => {

        if (props.activities) {
            setDrawerMaxIndex(parseInt(props.activities.length) - 1);
        }


    }, [props.activities]);


    const [isShowingSpecific, setSpecific] = useState(false);
    const [isShowingTeams, setShowingTeams] = useState(true);
    const [isShowingTeammates, setShowingTeammates] = useState(false);

    function toggleShowing(type = null) {

        if (type === "teams") {
            setShowingTeams(true);
            setShowingTeammates(false);
        } else {
            setShowingTeammates(true);
            setShowingTeams(false);
        }

    };


    useEffect(() => {
        if (active == "teammates") {
            setShowingTeammates(true);
            setShowingTeams(false);
        } else {
            setShowingTeams(true);
            setShowingTeammates(false);
        }

        if (stats.TotalWorkedTime > 0 && current.productiveDuration > 0) {
            currentPercent = Math.round((current.productiveDuration / stats.TotalWorkedTime) * 100);
        }

    }, []);


    function changeDrawer(index) {

        if (index >= 0 && index <= drawerMaxIndex) {
            props.changeDrawer({ next: index });
        }

    }

    function openSpecific(data) {

        setSpecific(true);
        setSpecificData(data.data);

        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        activitiesActions.getActivityDetailsSpecific(startDate, endDate, data.machineUserId, current.trackedId);

    }

    function closeSpecific() {

        setSpecific(false);
        setSpecificData();

    }


    return (
        <React.Fragment>
            <div className="internal">
                <div className="header">
                    <button className={currentIndex == 0 ? 'arrow back disable' : 'arrow back'} onClick={() => {
                        changeDrawer(parseInt(currentIndex) - 1)
                    }}><IconArrow /></button>
                    <div className={"title " + current.efficiencyName}>{current.exhibitionName}</div>
                    <button className={currentIndex == drawerMaxIndex ? 'arrow foward disable' : 'arrow foward'}
                        onClick={() => {
                            changeDrawer(parseInt(currentIndex) + 1)
                        }}><IconArrow /></button>
                </div>
                <div className="category">{current.categoryName}</div>
                <div className="total">{t('activities.total_time')}: {secondsToHm(current.duration)}
                </div>
                <div className="filters">
                    <div className="filter">
                        <div className={isShowingTeams ? 'icon active' : 'icon'} onClick={() => {
                            toggleShowing('teams')
                        }}><IconTeam /></div>
                        {
                            userDetails.allowed_productive.includes('related') && (
                                <div className="number">
                                    <div className="dot related"></div>
                                    {TotalProductiveTeams}
                                </div>
                            )
                        }
                        {
                            userDetails.allowed_productive.includes('neutral') && (
                                <div className="number">
                                    <div className="dot neutral"></div>
                                    {TotalNeutralTeams}
                                </div>
                            )
                        }
                        {
                            userDetails.allowed_productive.includes('not_related') && (

                                <div className="number">
                                    <div className="dot unrelated"></div>
                                    {TotalUnproductiveTeams}
                                </div>

                            )
                        }
                    </div>
                    <div className="filter teammate">
                        <div className={isShowingTeammates ? 'icon active' : 'icon'} onClick={() => {
                            toggleShowing('teammates')
                        }}><IconTeammate /></div>
                        {
                            userDetails.allowed_productive.includes('related') && (
                                <div className="number">
                                    <div className="dot related"></div>
                                    {TotalProductiveTeammates}
                                </div>
                            )
                        }
                        {
                            userDetails.allowed_productive.includes('neutral') && (
                                <div className="number">
                                    <div className="dot neutral"></div>
                                    {TotalNeutralTeammates}
                                </div>
                            )
                        }
                        {
                            userDetails.allowed_productive.includes('not_related') && (
                                <div className="number">
                                    <div className="dot unrelated"></div>
                                    {TotalUnproductiveTeammates}
                                </div>
                            )
                        }
                       
                    </div>
                </div>

                {
                    (isShowingTeams && !isShowingSpecific) &&
                    <DrawerActivityTableTeams current={current} />

                }

                {
                    (isShowingTeammates && !isShowingSpecific) &&
                    <DrawerActivityTableTeammates current={current} openSpecific={(data) => {
                        openSpecific(data)
                    }} />

                }


            </div>

            {
                !isShowingSpecific || loadingDetailsSpecific
                    ? <DrawerActivityTeammateDetailSkeleton />
                    : <DrawerActivityTeammateDetail current={current} specificData={specificData} closeSpecific={() => {
                        closeSpecific()
                    }} />
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {

    return {
        stats: state.stats.payload,
        detailsTeams: state.activities.detailsTeams,
        detailsTeammates: state.activities.detailsTeammates,
        activities: state.activities.rows,
        loadingDetailsSpecific: state.activities.loadingDetailsSpecific,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}


function mapDispatchToProps(dispatch) {

    return {
        activitiesActions: bindActionCreators(activitiesActions, dispatch)

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DrawerActivity)



