import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../../_icons/IconClose";
import { api } from "../../../_helpers/api";
import { Backdrop, Button, CircularProgress, makeStyles, Modal, Slide, TextField } from "@material-ui/core";
import { alertActions } from "../../../_actions";
import { authHeader, history, replaceTemplate } from "../../../_helpers";
import ReactTooltip from 'react-tooltip'
import "./styles.scss"
import { Box, Divider, FormControlLabel, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import { Close, Visibility } from "@material-ui/icons";
import SubspaceIconTV from "../../_icons/SubspaceIconTV";
import SubspaceIconLaptop from "../../_icons/SubspaceIconLaptop";
import SubspaceIconDesk from "../../_icons/SubspaceIconDesk";
import SubspaceIconChair from "../../_icons/SubspaceIconChair";
import { Autocomplete } from "@material-ui/lab";
import SVGRenderGroup from "../SpaceMapEditor/SVGRenderGroup";
import SVGRender from "../SpaceMapEditor/SVGRender";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

function ModalViewSubspaces({
  openModal,
  currentSpace,
  closeModal,
  alertActions,
  updateInfo,
  dateRef
}) {

  const { t } = useTranslation('common');
  const classes = useStyles();

  const [subspaceSelected, setSubspaceSelected] = useState(null)
  const [subspaceSelectedError, setSubspaceSelectedError] = useState(false)

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalViewSubspaces ${classes.modal}`}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box className={"containerModalViewSubspaces"}>

            <button onClick={closeModal} className="close"><Close /></button>


            <Box component={'div'} sx={{ width: currentSpace?.sub_space_type === "shape" ? "1080px" : "600px" }}>
              {
                currentSpace?.sub_space_type === "shape"
                  ?
                  <Box display={'flex'} flexDirection={'column'} gap={2}>
                    {
                      (currentSpace.map_representation) &&

                      <Box component={Paper} elevation={0} padding={'6px 10px'}>
                        <Box display={'flex'}>
                          <Typography variant='h6' style={{ color: '#4F7091', paddingTop: '10px', paddingBottom: '0px' }}>{currentSpace.map_representation.name}</Typography>

                        </Box>
                        <Box className="help_section" style={{ padding: "0px" }}>
                          <ul style={{ paddingInlineStart: "20px" }} >
                            <li>
                              <Typography style={{ color: "#1E6B7F", fontSize: "1rem" }}>{t('spaces.instructions.tip_one')}</Typography>
                            </li>

                          </ul>

                        </Box>

                        {
                          currentSpace.map_representation.shapes.length > 0
                            ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="600" style={{ border: "1px solid #01010155" }} >
                              {Array.from({ length: 40 }, (_, i) => (
                                <line
                                  key={`row-${i}`}
                                  x1="0%"
                                  y1={`${(i / 40) * 100}%`}
                                  x2="100%"
                                  y2={`${(i / 40) * 100}%`}
                                  stroke="#ccc"
                                />
                              ))}
                              {Array.from({ length: 40 }, (_, i) => (
                                <line
                                  key={`col-${i}`}
                                  x1={`${(i / 40) * 100}%`}
                                  y1="0%"
                                  x2={`${(i / 40) * 100}%`}
                                  y2="100%"
                                  stroke="#ccc"
                                />
                              ))}
                              {currentSpace.map_representation.shapes.map((shape, index) => {
                                 return ["rectangle","circle"].includes(shape.type)  ?  
                                 SVGRenderGroup({svgCode:replaceTemplate(shape.content,{...shape,textPos_x:Number(shape.x + (shape.width /2)),textPos_y:Number(shape.y + (shape.height/2)),cx:Number(shape.x + (shape.width /2)),cy:Number(shape.y + (shape.height/2)),radio:(Math.min(shape.width, shape.height) / 2)} ),x:shape.x,y:shape.y,width:shape.width,height:shape.height,setShapeToEdit:null,shape:shape,handleMouseDown:null,urlRedirect:`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}` })
                                 :
                                 SVGRender({svgCode:replaceTemplate(shape.content,{...shape,textPos_x:Number(shape.x + (shape.width /2)),textPos_y:Number(shape.y + (shape.height/2)),cx:Number(shape.x + (shape.width /2)),cy:Number(shape.y + (shape.height/2)),radio:(Math.min(shape.width, shape.height) / 2)} ),x:shape.x,y:shape.y,width:shape.width,height:shape.height,setShapeToEdit:null,shape:shape,handleMouseDown:null,urlRedirect:`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}` })
                                // switch (shape.type) {
                                //   case 'rectangle':
                                //     return (
                                //       <g key={shape.id} transform={`rotate(${shape.angle} ${shape.x + (shape.width/2) } ${shape.y + (shape.height/2)})`} style={{ cursor: 'pointer' }} onClick={() => history.push(`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`)}

                                //       >
                                //         <rect
                                //           x={shape.x}
                                //           y={shape.y}
                                //           width={shape.width}
                                //           height={shape.height}
                                //           fill={shape.color}
                                //           style={{ cursor: 'pointer' }}

                                //         />
                                //         <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                                //           {shape.name}
                                //         </text>
                                //       </g>
                                //     )
                                //   case 'circle':
                                //     return (
                                //       <g key={shape.id} transform={`rotate(${shape.angle} ${shape.x + (shape.width/2) } ${shape.y + (shape.height/2)})`} style={{ cursor: 'pointer' }} onClick={() => history.push(`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`)}>
                                //         <circle
                                //           cx={shape.x + shape.width / 2}
                                //           cy={shape.y + shape.height / 2}
                                //           r={Math.min(shape.width, shape.height) / 2}
                                //           fill={shape.color}
                                //           style={{ cursor: 'pointer' }}
                                //         />
                                //         <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                                //           {shape.name}
                                //         </text>
                                //       </g>
                                //     )

                                //   case 'chair':
                                //     return (
                                //       <SubspaceIconChair shape={shape} tooltipId={`tooltip-${shape.type}-${shape.room_id}`} x={shape.x} y={shape.y} rotationAngle={shape.angle} urlRedirect={`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`} />
                                //     )
                                //   case 'desk':
                                //     return (
                                //       <SubspaceIconDesk x={shape.x} y={shape.y} rotationAngle={shape.angle} urlRedirect={`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`} />
                                //     )
                                //   case 'laptop':
                                //     return (
                                //       <SubspaceIconLaptop x={shape.x} y={shape.y} rotationAngle={shape.angle} urlRedirect={`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`} />
                                //     )
                                //   case 'tv':
                                //     return (
                                //       <SubspaceIconTV x={shape.x} y={shape.y} rotationAngle={shape.angle} urlRedirect={`/spaces/reservation/${shape.room_id}${dateRef ? '?date=' + dateRef : ''}`} />
                                //     )
                                //   default:
                                //     break;
                                // }

                              })
                              }
                            </svg>
                            :
                            <Typography variant="subtitle1" fontSize={'12px'} sx={{ color: '#1E6B7F' }}>{t('spaces.no_subspaces')}</Typography>
                        }
                        {/* {
                          currentSpace.map_representation.shapes.length > 0 &&
                          currentSpace.map_representation.shapes.map(shape => (
                            <ReactTooltip key={shape.room_id} id={`tooltip-${shape.type}-${shape.room_id}`} place="right" type="light" effect="solid" border={true} delayHide={500} delayShow={500} delayUpdate={500}
                              getContent={(dataTip) =>
                                <Box component={'div'}>
                                  <ul style={{padding:0, listStyleType:'none', margin:'4px'}}>
                                    <li style={{marginBottom:'6px'}}>
                                      <span><strong>Posição:</strong> {shape.name}</span>
                                       <p>{JSON.stringify(shape)}</p>
                                    </li>
                                  </ul>
                                </Box>
                                // <div>
                                //   <h3>This little buddy is {shape.name}</h3>
                                //   <p>{JSON.stringify(shape)}</p>
                                // </div>
                              }
                              padding="4px"
                            />

                          ))
                        } */}

                      </Box>


                    }
                  </Box>
                  :
                  <Box display={'flex'} flexDirection={'column'} gap={2}>

                    <Box component={Paper} elevation={0} padding={'6px 10px'}>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='h6' style={{ color: '#4F7091', paddingTop: '10px', paddingBottom: '4px' }}>{currentSpace?.title}</Typography>
                        <Typography variant='subtitle1' style={{ color: '#4F7091', paddingTop: '0px', paddingBottom: '12px', fontSize: '12px' }}>{t('spaces.subtitle_subspace')}</Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          id="country-select-demo"
                          options={currentSpace.sub_spaces.length > 0 ? currentSpace.sub_spaces.map(ss => ({ value: ss.id, label: ss.name })) : []}
                          classes={{
                            option: classes.option,
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(option) => (
                            <Box>
                              <Box>{option.label}</Box>
                            </Box>
                          )}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={t('project_management.modal_members.select')}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                              error={subspaceSelectedError}
                            />
                          )}
                          value={subspaceSelected}
                          onChange={(event, newValue) => {
                            setSubspaceSelected(newValue);
                            setSubspaceSelectedError(false)
                          }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button
                          className="buttonToGoReserve"
                          type="button"
                          disabled={subspaceSelected === null}
                          onClick={() => history.push(`/spaces/reservation/${subspaceSelected.value}`)}
                        >
                          {t('spaces.start_reservation')}
                        </Button>
                      </Box>
                      {/* {
                        currentSpace.sub_spaces.length > 0
                          ?
                          <List>
                            {
                              currentSpace.sub_spaces.map((subspace, index) => (
                                <Box
                                  key={subspace.id}
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                      backgroundColor: '#D9E1E8',
                                      transition: 'ease .4s'
                                    }
                                  }}
                                  onClick={() => history.push(`/spaces/reservation/${subspace.id}`)}
                                >
                                  <ListItem
                                    style={{ paddingLeft: '6px' }}
                                  >
                                    <ListItemText style={{ marginTop: '2px', marginBottom: '2px', color: '#1E6B7F' }} primary={subspace.name} />
                                    <ListItemSecondaryAction>
                                      <IconButton edge="end" aria-label="delete-subspace" onClick={() => history.push(`/spaces/reservation/${subspace.id}`)}>
                                        <Visibility />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <Divider component="li" />
                                </Box>
                              ))
                            }
                          </List>
                          :
                          <Typography variant="subtitle1" fontSize={'12px'} sx={{ color: '#1E6B7F' }}>{t('spaces.no_subspaces')}</Typography>
                      } */}
                    </Box>


                  </Box>

              }
            </Box>
          </Box>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalViewSubspaces));
