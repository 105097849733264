import React from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';


import { Close } from '@material-ui/icons';
import { Box, Button, Card, CardContent, Checkbox, Drawer, FormControl, IconButton, ListItemText, MenuItem, OutlinedInput, Popover, Select, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { CaretLeft, CaretRight, MapTrifold, Timer, Users } from 'phosphor-react';
import { useEffect, useState } from 'react';
import IconEdit2 from '../../_components/_icons/IconEdit2';
import IconRemove from '../../_components/_icons/IconRemove';
import IconRoomAvailable from '../../_components/_icons/IconRoomAvailable';
import IconRoomCalendar from '../../_components/_icons/IconRoomCalendar';
import IconRoomDay from '../../_components/_icons/IconRoomDay';
import IconRoomLessReservation from '../../_components/_icons/IconRoomLessReservation';
import IconRoomMostReservation from '../../_components/_icons/IconRoomMostReservation';
import IconTeammate from '../../_components/_icons/IconTeammate';
import { authHeader, befectiveFormatDate, getDayWeek, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import { useRoomsFilter } from '../../contexts/RoomsFilterContext';
import style from './styles.module.scss';
import CustomSelect from '../../_components/_shared/CustomSelect';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: '#1E6B7F',
    },
  },
};


const defaultUrl = "https://iseb3.com.br/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png"

function RoomsManagement(props) {
  const { rooms } = useRoomsFilter()

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammates, teammatesActions } = props


  const [roomHistory, setRoomHistory] = useState([])
  const [dashboardInfo, setDashboardInfo] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [week, setWeek] = useState([])
  const [roomIdToDelete, setRoomIdToDelete] = useState(null)
  const [currentDay, setCurrentDay] = useState(moment().format('YYYY-MM-DD'))
  const [currentRoomId, setCurrentRoomId] = useState(null)
  const [currentReservationTime, setCurrentReservationTime] = useState(null)
  const [collabs, setCollabs] = useState([])
  const [roomsFilter, setRoomsFilter] = useState()

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElConfirmDeleteRoom, setAnchorElConfirmDeleteRoom] = useState(null);

  const handleClickConfirmDeleteRoom = (event, roomId) => {
    setRoomIdToDelete(roomId)
    setAnchorElConfirmDeleteRoom(event.currentTarget);
  };

  const handleCloseConfirmDeleteRoom = () => {
    setRoomIdToDelete(null)
    setAnchorElConfirmDeleteRoom(null);
  };

  const openConfirmDeleteRoom = Boolean(anchorElConfirmDeleteRoom);
  const idConfirmDeleteRoom = openConfirmDeleteRoom ? 'simple-popover-2' : undefined;


  const collabsData = teammates?.payload?.reduce((acc, item) => {
    return [...acc, { name: item.name, value: item.id }]
  }, [])


  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  if (lang === 'pt' || lang === 'es') {
    moment.locale('pt-br')
  } else {
    moment.locale('en-us')
  }


  function generateWeek(date) {
    let dates = [];
    let momentDate = moment(date);
    let startOfWeek = momentDate.clone().startOf('week');
    let endOfWeek = momentDate.clone().endOf('week');

    for (let day = startOfWeek; day.isSameOrBefore(endOfWeek); day.add(1, 'days')) {
      dates.push(day.format('YYYY-MM-DD'));
    }

    return dates;
  }

  const handleClick = (event, reservation) => {
    setAnchorEl(event.currentTarget);
    setCurrentReservationTime(reservation)
    console.log(reservation)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentReservationTime(null)
  };

  const onDeleteReservationTime = async () => {
    try {
      const res = await api.put(`/rooms/reservations/${currentReservationTime?.id}/times`, { ...currentReservationTime }, { headers: authHeader() })
      handleClose()
      if (currentRoomId) (
        getReservationHistoryByDate(currentDay, currentRoomId)
      )
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.delete'))
    }
  }

  function formatTime(number) {
    const formattedHour = number.toString().padStart(2, '0');

    return `${formattedHour}:00`;
  }

  function calculateMinutesGap(hour) {
    const hours = hour.split(':')[0];
    const minutes = hour.split(':')[1];

    return Number(hours) * 60 + Number(minutes) + 2
    const gap = (Number(minutes) * 64) / 60

    return gap - 1
  }

  function handleCloseDrawer() {
    setOpenDrawer(false)
    setCurrentDay(moment().format('YYYY-MM-DD'))
    setWeek(generateWeek(moment().format('YYYY-MM-DD')))
  }

  const onDeleteRoom = async () => {
    try {

      const res = await api.delete(`/rooms/${roomIdToDelete}`, { headers: authHeader() })
      handleCloseConfirmDeleteRoom()
      getDashboardInfo()
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.delete'))
    }
  }

  const getDashboardInfo = async () => {
    try {
      let roomsFilter = ""

      if (rooms.length > 0) {
        roomsFilter = "?rooms_ids="
        roomsFilter += rooms.reduce((acc, room) => {
          return acc += `${room},`
        }, "").slice(0, -1)

      }

      const response = await api.get(`/rooms/dashboard${roomsFilter}`, {
        headers: authHeader()
      })
      setDashboardInfo(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  async function getReservationHistoryByDate(date, roomId) {
    try {
      let filterCollabs = ""

      if (collabs.length > 0) {
        filterCollabs = "&colab_ids="

        filterCollabs += collabs.reduce((acc, item) => {
          return acc += `${item},`
        }, "").slice(0, -1)
      }

      const response = await api.get(`/rooms/reservations-date?date=${date}&room_id=${roomId}${filterCollabs}`, {
        headers: authHeader()
      })
      setCurrentRoomId(roomId)
      setWeek(generateWeek(date))
      setRoomHistory(response.data)
      setOpenDrawer(true)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
    }

  }


  function calcHourGap(reservation) {
    /**
     * 09:30 - 10:00
     * 10:00 - 10:30
     */
    const start = Number(reservation.start_time.split(":")[0])
    const end = Number(reservation.end_time.split(":")[0])

    const calc = (Number(moment(reservation.end_time, 'HH:mm').diff(moment(reservation.start_time, 'HH:mm'), 'm')) / 60) * 58

    return calc
    const diff = (roomHistory.time / 60) //end - start
    const gap = diff * 66

    if (diff === 1) return gap
    return gap - ((diff - 1) * 2)
  }

  function handleDecreaseWeek() {
    const weekDecreased = moment(currentDay).subtract(1, 'week').format('YYYY-MM-DD')
    setWeek(generateWeek(weekDecreased))
    setCurrentDay(weekDecreased)
  }

  function handleIncreaseWeek() {
    const weekIncreased = moment(currentDay).add(1, 'week').format('YYYY-MM-DD')
    setWeek(generateWeek(weekIncreased))
    setCurrentDay(weekIncreased)
  }

  function handleDecreaseMonth() {
    const monthDecreased = moment(currentDay).subtract(1, 'month').format('YYYY-MM-DD')
    setWeek(generateWeek(monthDecreased))
    setCurrentDay(monthDecreased)
  }

  function handleIncreaseMonth() {
    const monthIncreased = moment(currentDay).add(1, 'month').format('YYYY-MM-DD')
    setWeek(generateWeek(monthIncreased))
    setCurrentDay(monthIncreased)
  }

  function handleChangeDay(day, roomId) {
    getReservationHistoryByDate(day, roomId)
    setCurrentDay(day)
  }

  async function getRooms() {
    try {
      const response = await api.get('/rooms', {
        headers: authHeader()
      })


      const roomsData = response.data?.reduce((acc, room) => {
        return [...acc, { name: room.name, value: room.id }]
      }, [])

      setRoomsFilter(roomsData)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
    }
  }


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCollabs(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  useEffect(() => {
    teammatesActions.getTeammates()
    getDashboardInfo()
  }, [rooms])

  useEffect(() => {
    if (currentRoomId) (
      getReservationHistoryByDate(currentDay, currentRoomId)
    )
  }, [collabs])

  useEffect(() => {
    document.title = 'Befective | ' + t('spaces.management');
    getRooms()
  }, [])


  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          newRoom={true}
          filterRoom={true}
          roomsData={roomsFilter}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>

          <Stack
            direction="row"
            component="header"
            justifyContent="space-between"
            gap={1}
            flexWrap="wrap"
            marginBottom={'16px'}
          >
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
                <IconRoomAvailable />
                <Typography component="span" style={{ color: "#22BEAD", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.availableRoomsToday}
                </Typography>
                <Typography style={{ color: "#22BEAD", fontSize: 12 }} component="span">
                  {t('spaces.available_today')}
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187dff` }}>
                <IconRoomCalendar />
                <Typography component="span" style={{ color: "#187dff", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.totalRoomsReservationsWeek}
                </Typography>
                <Typography style={{ color: "#187dff", fontSize: 12 }} component="span">
                  {t('spaces.week_reservations')}
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
                <IconRoomMostReservation />
                <Typography component="span" style={{ color: "#22BEAD", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.mostUsedRoom}
                </Typography>
                <Typography style={{ color: "#22BEAD", fontSize: 12 }} component="span">
                  {t('spaces.more_reservations')}
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #fda02b` }}>
                <IconRoomLessReservation />
                <Typography component="span" style={{ color: "#fda02b", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.leastUsedRoom}
                </Typography>
                <Typography style={{ color: "#fda02b", fontSize: 12 }} component="span">
                  {t('spaces.less_reservations')}
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187dff` }}>
                <Timer size={20} color={"#187dff"} weight="bold" />
                <Typography component="span" style={{ color: "#187dff", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.avgTimeLocation}
                </Typography>
                <Typography style={{ color: "#187dff", fontSize: 12 }} component="span">
                  {t('spaces.average_time')}
                </Typography>

              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #98acc1` }}>
                <IconRoomDay />
                <Typography style={{ color: "#98acc1", fontSize: 20, marginTop: '8px' }} component="span">
                  {getDayWeek(dashboardInfo?.statsRooms?.mostUsedDay)}
                </Typography>
                <Typography style={{ color: "#98acc1", fontSize: 12 }} component="span">
                  {t('spaces.day_more_used')}
                </Typography>

              </CardContent>
            </Card>
            <Card variant="outlined" style={{ border: 'none' }} className={style.cardContainer}>
              <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #98acc1` }}>
                <IconTeammate fill={"#98acc1"} />
                <Typography style={{ color: "#98acc1", fontSize: 20, marginTop: '8px' }}>
                  {dashboardInfo?.statsRooms?.whoBooksMore}
                </Typography>
                <Typography style={{ color: "#98acc1", fontSize: 12 }} component="span">
                  {t('spaces.who_more')}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
          <div className={style.containerTable}>
            <table>
              <thead>
                <tr>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.room')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.description')}
                  </th>
                  <th style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                    {t('spaces.columns.capacity')}
                  </th>
                  <th style={{ textAlign: 'center', width: '8%' }}>
                    STATUS
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.last_reservation')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.next_period')}
                  </th>
                  <th style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                    {t('spaces.columns.reservations')}
                  </th>
                  <th
                    style={{ width: '4%', textAlign: 'center', textTransform: 'uppercase' }}
                  >
                    {t('spaces.columns.actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  (dashboardInfo && dashboardInfo?.roomsData?.length > 0) ?
                    dashboardInfo?.roomsData?.map((room, index) => {
                      return (
                        <tr key={index}>
                          <td onClick={() => getReservationHistoryByDate(moment().format("YYYY-MM-DD"), room.id)}>
                            <div className={style.boxRoom}>
                              <img src={room.url || defaultUrl} alt="Foto sala" />
                              {room?.name}
                              {
                                room.sub_space_type !== null &&
                                <MapTrifold color='#1E6B7F'/>
                              }
                            </div>
                          </td>
                          <td onClick={() => getReservationHistoryByDate(moment().format("YYYY-MM-DD"), room.id)}>{room?.description}</td>
                          <td style={{ textAlign: 'center' }}>{room?.capacity}</td>
                          <td onClick={() => getReservationHistoryByDate(moment().format("YYYY-MM-DD"), room.id)}>
                            <Box display={'flex'} justifyContent={'center'}>
                              <div className={`${style.boxStatus} ${room?.status === 'free' ? style.statusAvailable : style.statusOccupied}`}>
                                <div></div>
                                <div style={{ fontSize: '12px' }}>{room?.status === 'free' ? t('spaces.status.available') : room?.status === 'unavailable' ? t('spaces.status.unavailable') : t('spaces.status.busy')}</div>
                              </div>
                            </Box>
                          </td>
                          <td onClick={() => getReservationHistoryByDate(moment().format("YYYY-MM-DD"), room.id)}>
                            {
                              room?.lastReservations
                                ?
                                <>
                                  {
                                    room?.lastReservations?.times
                                      ?
                                      (
                                        room?.lastReservations?.times?.length > 1
                                          ?
                                          <Tooltip arrow title={
                                            <Box>
                                              {room?.lastReservations?.times.map(t => <Box fontSize={'12px'} fontWeight={'500'}>{t.start_time + ' - ' + t.end_time}</Box>)}
                                            </Box>
                                          } placement="bottom">
                                            <Box fontSize={'12px'}>{`${befectiveFormatDate(room?.lastReservations?.date, lang, "S")}, ${room?.lastReservations?.times?.map(t => t.start_time + ' - ' + t.end_time)[0]} [...] - ${room?.lastReservations?.responsible_name}`}</Box>
                                          </Tooltip>
                                          :
                                          <Box fontSize={'12px'}>{`${befectiveFormatDate(room?.lastReservations?.date, lang, "S")}, ${room?.lastReservations?.times[0]?.start_time} - ${room?.lastReservations?.times[0]?.end_time} - ${room?.lastReservations?.responsible_name}`}</Box>
                                      )
                                      :
                                      (
                                        <Box fontSize={'12px'}>{`${befectiveFormatDate(room?.lastReservations?.date, lang, "S")}, ${room?.lastReservations?.start_time} - ${room?.lastReservations?.end_time} - ${room?.lastReservations?.responsible_name}`}</Box>
                                      )

                                  }

                                </>
                                :
                                "------"
                            }
                          </td>
                          <td onClick={() => getReservationHistoryByDate(moment().format("YYYY-MM-DD"), room.id)}>
                            {
                              room?.nextTimeAvailable
                                ?
                                <>
                                  {moment(room?.nextTimeAvailable?.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && `${t('spaces.today')}, ${room?.nextTimeAvailable?.timeFormat}`}
                                  {moment(room?.nextTimeAvailable?.date).format('YYYY-MM-DD') === moment().add(1, 'day').format('YYYY-MM-DD') && `${t('spaces.tomorrow')}, ${room?.nextTimeAvailable?.timeFormat}`}
                                  {moment(room?.nextTimeAvailable?.date).isAfter(moment().add(1, 'day')) && `${befectiveFormatDate(room?.nextTimeAvailable?.date, i18n.language)}, ${room?.nextTimeAvailable?.timeFormat}`}
                                </>
                                :
                                "------"
                            }
                          </td>
                          <td style={{ textAlign: 'center' }}>{room?.totalReservations}</td>
                          <td>
                            <div className={style.boxActions}>
                              <button className={`${style.buttonAction} ${style.permissions}`} onClick={() => history.push(`/spaces/management/edit/${room.id}`)}>
                                <IconEdit2 />
                              </button>
                              <button className={`${style.buttonAction} ${style.remove}`} onClick={(e) => handleClickConfirmDeleteRoom(e, room.id)}>
                                <IconRemove />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                    :
                    <tr><td style={{ cursor: 'auto' }} colSpan={8}>{t('spaces.no_rooms')}</td></tr>
                }
              </tbody>
            </table>

          </div>

        </div>
        {/* PAGE ENDS */}

        <Popover
          id={idConfirmDeleteRoom}
          open={openConfirmDeleteRoom}
          anchorEl={anchorElConfirmDeleteRoom}
          onClose={handleCloseConfirmDeleteRoom}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              backgroundColor: "#FFE3E5",
              boxShadow: "none",
              padding: '18px 14px',
              borderRadius: '6px',
            }
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

            <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmDeleteRoom}>
                <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
              </Button>
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteRoom}>
                <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
              </Button>
            </Box>
          </Box>
        </Popover>

      </section>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          style: {
            width: 'fit-content',
          }
        }}
      >
        <div style={{ position: 'absolute', right: 4, top: 4 }}>
          <IconButton aria-label="close" onClick={handleCloseDrawer}>
            <Close fontSize="medium" />
          </IconButton>
        </div>

        <Box
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            bgcolor="#F5F6F9"
            p="40px 32px 24px 32px"
          >
            <Box display="flex" alignItems="center">
              <CaretLeft
                size={18}
                color="#1E6B7F"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDecreaseMonth()}
              />
              <Typography component="span" color="#1E6B7F" fontSize={16} lineHeight={1}>
                {moment(currentDay).format("YYYY MMMM")}
              </Typography>
              <CaretRight
                size={18}
                color="#1E6B7F"
                style={{ cursor: 'pointer' }}
                onClick={() => handleIncreaseMonth()}
              />
            </Box>

            <Box display="flex" justifyContent="space-between" gap="4px" alignItems="center">
              <CaretLeft
                size={32}
                color="#1E6B7F"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDecreaseWeek()}
              />
              {week.map(day => (
                <Box
                  key={day}
                  display="flex"
                  borderRadius="6px"
                  flexDirection="column"
                  gap="8px"
                  alignItems="center"
                  justifyContent="center"
                  p="12px 14px"
                  style={{ cursor: "pointer" }}
                  bgcolor={day === currentDay ? "#1E6B7F" : "transparent"}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#1E6B7F',
                    },
                    '&:hover p': {
                      color: '#fff'
                    },
                  }}
                  onClick={() => handleChangeDay(day, currentRoomId)}
                >
                  <Typography color={day === currentDay ? "#FFFFFF" : "#1E6B7F"} fontSize={16} lineHeight={1}>{moment(day).format("DD")}</Typography>
                  <Typography color={day === currentDay ? "#FFFFFF" : "#1E6B7F"} fontSize={12} lineHeight={1} textTransform="capitalize">{moment(day).format("ddd")}</Typography>
                </Box>
              ))}
              <CaretRight
                size={32}
                color="#1E6B7F"
                style={{ cursor: 'pointer' }}
                onClick={() => handleIncreaseWeek()}
              />
            </Box>

          </Box>

          <Box display="flex" flexDirection="column" px="24px" py="16px">
            <Box marginBottom={'24px'}>
              <CustomSelect
                isMulti={true}
                widthControl='450px'
                initialValues={collabs}
                onChange={handleChange}
                options={collabsData}
                setSelectedOptions={setCollabs}
              />
            </Box>
            {/* <FormControl>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="select-rooms"
                multiple
                displayEmpty
                value={collabs}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    sx={{ marginBottom: "22px", width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                  />
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{t('spaces.collab')}</em>;
                  }

                  return (
                    <Box
                      display="flex"
                      width="fit-content"
                      alignItems="center"
                      justifyContent="center"
                      px="8px"
                      gap="4px"
                      bgcolor="#E4EAEF"
                      borderRadius="2px"
                    >
                      <Users size={18} color="#4F7091" />
                      {selected.length}
                    </Box>
                  );
                }}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  <em>{t('spaces.collab')}</em>
                </MenuItem>
                {collabsData?.map((room) => (
                  <MenuItem key={room.value} value={room.value} style={{ backgroundColor: '#1E6B7F !important' }}>
                    <Checkbox checked={collabs.indexOf(room.value) > -1} />
                    <ListItemText primary={room.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <Box position="relative">

              {Array.from(Array(25 * 60)).map((_, index) => (
                (index % 60 === 0) && <Box key={index} display="flex" alignItems="flex-start" gap="8px" minHeight="60px">
                  <Typography as="span" fontSize={12} color="#4F7091" lineHeight={1} mt="-5px">{index === 1440 ? formatTime(0) : formatTime(index / 60)}</Typography>
                  <Box display="flex" flex="1" borderTop="2px solid #D6DEEB" pl="24px" position="relative">
                    {/* {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      borderRadius="4px"
                      position="absolute"
                      bgcolor="#E4F7F5"
                      minHeight={`${calcHourGap(roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index))}px`}
                      width="230px"
                      borderLeft="4px solid #22BEAD"
                      px="12px"
                      gap="4px"
                      zIndex={1}
                      top={`${calculateMinutesGap(roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index).start_time)}px`}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleClick(e, roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index))}
                    >
                      <Typography fontSize={14} color="#22BEAD" lineHeight={1} component="span">
                        {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index).responsible_name}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" lineHeight={1} component="span">
                        {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index).start_time}
                        {' '}às{' '}
                        {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'),'m')) === index).end_time}
                      </Typography>
                    </Box>
                  )} */}
                  </Box>
                </Box>
              ))}
              {Array.from(Array(25 * 60)).map((_, index) => (

                roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index) && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    borderRadius="4px"
                    position="absolute"
                    bgcolor="#E4F7F5"
                    minHeight={`${calcHourGap(roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index))}px`}
                    width="280px"
                    borderLeft="4px solid #22BEAD"
                    px="12px"
                    gap="0px"
                    zIndex={1}
                    top={`${calculateMinutesGap(roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index).start_time)}px`}
                    left={'40px'}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleClick(e, roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index))}
                  >
                    <Typography fontSize={14} color="#22BEAD" lineHeight={1} component="span">
                      {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index).responsible_name},
                    </Typography>
                    <Typography fontSize={12} color="#4F7091" lineHeight={1} component="span" marginLeft={'6px'}>
                      {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index).start_time}
                      {' '}-{' '}
                      {roomHistory.find(reservation => Number(moment(reservation.start_time, 'HH:mm').diff(moment().startOf('day'), 'm')) === index).end_time}
                    </Typography>
                  </Box>
                )
              ))}

            </Box>
          </Box>
        </Box>
      </Drawer >

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FFE3E5",
            boxShadow: "none",
            padding: '18px 14px',
            borderRadius: '6px',
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

          <Box display="flex" gap="4px" alignItems="center" justifyContent="center">
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleClose}>
              <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
            </Button>
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteReservationTime}>
              <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsManagement)