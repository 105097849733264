import { Modal, Slide, makeStyles } from '@material-ui/core';
import { Backdrop, Box, Button, Grid, Typography } from '@mui/material';
import { Paperclip } from 'phosphor-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authHeader, befectiveFormatDate } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { useMessagesCount } from '../../../contexts/MessagesCountContext';
import style from "./style.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function ModalApproveNotWorkSolicitation({
  openModalApprove,
  handleCloseModalApprove,
  currentSolicitation,
  updateListSolicitations,
  alertActions
}) {
  
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const { getOpenSolicitations } = useMessagesCount()

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeModalConfirm, setTypeModalConfirm] = useState(null);

  const [form, setForm] = useState({
    commentReasonReprove: ''
  })

  function getLabelReasonAdjust() {
    switch (i18n.language) {
      case 'pt':
        return currentSolicitation?.label_pt || currentSolicitation?.label_custom
      case 'es':
        return currentSolicitation?.label_es || currentSolicitation?.label_custom
      case 'en':
        return currentSolicitation?.label_en || currentSolicitation?.label_custom
      default:
        return currentSolicitation?.label_en || currentSolicitation?.label_custom
    }
  }

  const handleActionSolicitation = async (typeAction) => {
    try {
      setIsLoading(true)

      let comment = form.commentReasonReprove;

      if (comment === '') {
        comment = null
      }

      const res = await api.put(`/checkin/not_work_reason/${currentSolicitation?.id}?action=${typeAction}`, { extra_comment: comment },
        { headers: authHeader() })

      if (res.data.statusCode !== 200) {
        throw new Error("Internal server error")
      }
      await updateListSolicitations()
      handleCloseModalApprove()
      setOpenModalConfirm(false)
      if (typeAction === 'D') {
        alertActions.success(t("checkin.control_points.alerts.solicitation_success_reprove"))
      } else {
        alertActions.success(t("checkin.control_points.alerts.solicitation_success_approved"))
      }
      setIsLoading(false)

    } catch (error) {
      if (typeAction === 'D') {
        alertActions.success(t("checkin.control_points.alerts.solicitation_error_reprove"))
      } else {
        alertActions.success(t("checkin.control_points.alerts.solicitation_error_approved"))
      }
      setIsLoading(false)
    } finally {
      getOpenSolicitations()
    }
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleOpenModalConfirm = (type) => {
    setTypeModalConfirm(type)
    setOpenModalConfirm(true)
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalApprove}
        onClose={handleCloseModalApprove}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalApprove} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="30px"
            px="26px"
            borderRadius="8px"
            gap="4px"
            width={420}
            component="form"
            noValidate
            autoComplete='off'
          >
            <Box mb={'8px'}>
              <Typography component="h1" fontSize={16} color="#205B6F" fontWeight={500} mb={'4px'}>
                {t("checkin.control_points.modal_approve.adjust_fault")}
              </Typography>
              <Typography component="span" variant='body2' fontSize={12} color="#205B6F" fontWeight={400}>{t("checkin.control_points.modal_approve.date_solicitation") + ": " + befectiveFormatDate(currentSolicitation?.moment_solicitation, i18n.language, "T")}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">

              <Box className={style.field}>
                <label>{t("checkin.control_points.modal_approve.field_colab")}</label>
                <input
                  disabled
                  defaultValue={currentSolicitation?.username}
                  type='text'
                />
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box className={style.field}>
                      <label>{t("checkin.control_points.modal_approve.from")}</label>
                      <input
                        style={{ width: '107px' }}
                        disabled
                        defaultValue={befectiveFormatDate(currentSolicitation?.from_day, i18n.language)}
                        type='text'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box width={'auto'} className={style.field}>
                      <label>{t("checkin.control_points.modal_approve.to")}</label>
                      <input
                        style={{ width: '107px' }}
                        disabled
                        defaultValue={befectiveFormatDate(currentSolicitation?.to_day, i18n.language)}
                        type='text'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={style.field}>
                      <label>{t("checkin.control_points.modal_approve.field_period")}</label>
                      <input
                        style={{ width: '107px' }}
                        disabled
                        defaultValue={currentSolicitation?.period_day === 'F' ? t("checkin.control_points.modal_approve.period_f") : `${currentSolicitation?.period_day}`}
                        type='text'
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className={style.field}>
                <label>{t("checkin.control_points.modal_approve.field_reason")}</label>
                <input
                  disabled
                  defaultValue={getLabelReasonAdjust()}
                  type='text'
                />
              </Box>


              <Box className={style.field}>
                <label>{t("checkin.control_points.modal_approve.field_comment")}</label>
                <textarea cols="30" rows="3" disabled defaultValue={currentSolicitation?.comment}></textarea>
              </Box>

              {
                currentSolicitation?.attachments.length > 0 &&
                <Box display="flex" flexDirection="column" mt={1} className={style.field}>
                  <label>{t("checkin.control_points.modal_approve.field_attach")}</label>
                  {
                    currentSolicitation?.attachments.map((attach, index) => (
                      <a key={index} className={style.boxAttachments} download href={attach?.url_attachment}>
                        <Paperclip size={14} color="#187DFF" />
                        <span>{attach?.original_name}</span>
                      </a>
                    ))
                  }
                </Box>
              }
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="6px" mt={'8px'}>
              <Button
                type='button'
                sx={{ backgroundColor: '#FFEAEA', paddingY: '12px', paddingX: '28px', borderRadius: '2px' }}
                className={style.reprovedBtn}
                onClick={() => handleOpenModalConfirm("modal_reprove_confirm")}
                disableRipple
                disabled={isLoading}
              >
                <Typography component="span" fontSize={12} color='#FF5151' textTransform="initial" fontWeight={500}>{t("checkin.control_points.modal_approve.btn_reprove")}</Typography>
              </Button>
              <Button
                type='button'
                sx={{ backgroundColor: '#187DFF', paddingY: '12px', paddingX: '28px', borderRadius: '2px' }}
                className={style.save}
                disableRipple
                onClick={() => handleOpenModalConfirm('modal_approve_confirm')}
                disabled={isLoading}
              >
                <Typography component="span" fontSize={12} color='#fff' textTransform="initial" fontWeight={500}>{t("checkin.control_points.modal_approve.btn_approve")}</Typography>
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.4 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalConfirm} style={{ outline: 0 }} mountOnEnter unmountOnExit>

          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="42px"
            px="26px"
            borderRadius="8px"
            gap="28px"
            width={400}
            component="form"
            noValidate
            autoComplete='off'
          >
            <Box>
              <Typography component="h1" fontSize={16} color={typeModalConfirm === "modal_approve_confirm" ? "#187DFF" : "#FF5151"} fontWeight={500} mb={'4px'}>{t(`checkin.control_points.${typeModalConfirm}.title`)}</Typography>
              <Typography component="span" variant='body2' fontSize={12} color="#205B6F" fontWeight={400}>{t(`checkin.control_points.${typeModalConfirm}.date`) + ": " + befectiveFormatDate(new Date(), i18n.language)}</Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box className={style.field}>
                <label>{t(`checkin.control_points.${typeModalConfirm}.field_reason`)}</label>
                <textarea cols="30" rows="6" value={form.commentReasonReprove} onChange={handleChange} name='commentReasonReprove' autoFocus></textarea>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
              <Button
                type='button'
                sx={{ backgroundColor: '#E3EFFF', paddingY: '12px', paddingX: '28px', borderRadius: '2px' }}
                className={style.cancelBtn}
                disableRipple
                onClick={() => setOpenModalConfirm(false)}
                disabled={isLoading}
              >
                <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>{t("button.cancel")}</Typography>
              </Button>
              <Button
                type='button'
                sx={{ backgroundColor: '#187DFF', paddingY: '12px', paddingX: '28px', borderRadius: '2px' }}
                className={style.saveConfirm}
                disableRipple
                onClick={() => handleActionSolicitation(typeModalConfirm === "modal_approve_confirm" ? "A" : "D")}
                disabled={isLoading}
              >
                <Typography component="span" fontSize={12} color='#fff' textTransform="initial" fontWeight={500}>{t("button.confirm")}</Typography>
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>

  )
}
