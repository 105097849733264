import React, { useEffect, useState } from 'react'
import { heckbox, FormControlLabel, FormGroup, Grid, Checkbox } from '@material-ui/core'
import style from './styles.module.scss';
import Paper from '@material-ui/core/Paper';
import Toolbar from '../../_components/_shared/Toolbar';
import Filterbar from '../../_components/_shared/Filterbar';
import { useTranslation } from 'react-i18next';
import { authHeader, history, secondsToHm } from '../../_helpers';
import { alertActions, filterActions, teammatesActions, teamsActions, usersActions } from '../../_actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Box, TextField, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { FileXls } from 'phosphor-react';
import { api } from '../../_helpers/api';
import { DataGrid, ptBR, enUS, esES } from '@mui/x-data-grid';
import WhoMultiSkeleton from '../../_components/_shared/filters/WhoMultiSkeleton';
import WhoMulti from '../../_components/_shared/filters/WhoMulti';
import WhoMultiReport from '../../_components/_shared/filters/WhoMultiReport';
import CustomReactSelect from '../../_components/_shared/CustomReactSelect';

const GreenCheckbox = withStyles({
  root: {
    color: '#98ACC1',
    '&$checked': {
      color: '#22BEAD',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenLabel = withStyles({
  label: {
    color: '#98ACC1', // Cor inicial do rótulo
  },
})((props) => <span className={props.className}>{props.label}</span>);


function ProductiveReportsAdvancedPage(props) {
  const { t, i18n } = useTranslation('common');


  const { userDetails, alertActions, teammatesActions, teammates, teamsActions, filter } = props

  const [form, setForm] = useState({
    dateStart: moment().subtract(30, 'day').toISOString(),
    dateEnd: moment().subtract(1, 'day').toISOString(),
    colabs: [],
    metrics: [],
  })

  const [formErrors, setFormErrors] = useState({
    dateStart: null,
    dateEnd: null,
    colabs: null,
    metrics: null
  })

  const [data, setData] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [columns, setColumns] = useState([])

  const [rows, setRows] = useState([])

  const [checkedItems, setCheckedItems] = useState([]); // State para armazenar itens marcados

  const [items, setItems] = useState([
    { id: 1, label: t('productive_reports.advanced.filter_card.checkboxes.percentage'), name: 'percent', checked: false },
    { id: 2, label: t('productive_reports.advanced.filter_card.checkboxes.team_name'), name: 'group_name', checked: false }
    // { label: t('productive_reports.advanced.filter_card.checkboxes.online'), name  : 'online' },
    // { label: t('productive_reports.advanced.filter_card.checkboxes.offline'), name : 'offline' },
    // { label: t('productive_reports.advanced.filter_card.checkboxes.most_used_apps'), name  : 'most_used_apps' }
  ]);

  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target
    const itemCurrent = items.find(i => i.name == name)
    const newItem = {
      ...itemCurrent,
      checked: checked
    }
    const itemsFiltered = items.filter(i => i.id !== itemCurrent.id)
    itemsFiltered.push(newItem)
    const newItems = itemsFiltered.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0))
    setItems(newItems)
  };

  const teammatesOptions = teammates?.map(item => {
    return {
      label: item.name,
      value: item.id
    }
  });

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  const handleChange = (e, date = null) => {
    if (date) {
      if (date === "dateStart") {
        const start = moment(new Date(e), 'YYYY-MM-DD');
        const end = moment(new Date(form.dateEnd), 'YYYY-MM-DD');

        if (start.isAfter(end)) {
          setForm({
            ...form,
            [date]: moment(e.$d).format().split("T")[0],
            'dateEnd': moment(e.$d).format().split("T")[0],
          });
        } else {
          setForm({
            ...form,
            [date]: moment(e.$d).format().split("T")[0],
          });
        }
      } else {
        setForm({
          ...form,
          [date]: moment(e.$d).format().split("T")[0],
        });
      }
      return
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  const validateForm = (form) => {
    const errors = {};

    if (form.dateStart === null) {
      errors.dateStart = t('validation.required')
    }

    if (form.dateEnd === null) {
      errors.dateEnd = t('validation.required')
    }

    // if (form.colabs.length === 0) {
    //   errors.colabs = t('validation.required')
    // }
    if (form.metrics.length === 0) {
      errors.metrics = t('validation.required')
    }
    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  useEffect(() => {

    if (Object.keys(formErrors).length === 0 && submitted) {
      getData()
    } else {
      setSubmitted(false)
    }

  }, [formErrors, submitted])

  function resetForm() {
    setForm({
      dateStart: moment().subtract(30, 'day').toISOString(),
      dateEnd: moment().subtract(1, 'day').toISOString(),
      colabs: [],
      metrics: []
    })
    setFormErrors({
      dateStart: null,
      dateEnd: null,
      colabs: null,
      metrics: null
    })
    setCheckedItems([])
    setData([])
    setFetching(true)
    setDownloading(false)
  }

  const getData = async () => {
    try {

      // let filterColabs = ''
      // if (form.colabs.length > 0) {
      //   filterColabs = `&users=${form.colabs.map(c => c.value).join(',')}`
      // }

      setFetching(true)

      const queryTeams = (filter.teams && filter.teams.length > 0) ? `&teams=${filter.teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = (filter.teammates && filter.teammates.length > 0) ? `&users=${filter.teammates.map(t => t.id).join(',')}` : ''

      const { data } = await api.get(`/stats/report/advaced?date_start=${form.dateStart}&date_end=${form.dateEnd}${queryTeammates}${queryTeams}`, {
        headers: authHeader()
      })

      if (data && data.length > 0) {

        const hasPercent = items.find(ci => ci.name === 'percent' && ci.checked === true)
        const hasGroupName = items.find(ci => ci.name === 'group_name' && ci.checked === true)

        const columnsFiltered = [
          { field: 'id', headerName: 'ID', sortable: false, filterable: false, flex: 1 },
          { field: 'username', headerName: t('productive_reports.advanced.data_card.columns.teammate'), sortable: false, filterable: false, flex: 1 }
        ]

        if (hasGroupName) {
          columnsFiltered.push({ field: 'group_name', headerName: t('productive_reports.advanced.data_card.columns.team'), sortable: false, filterable: false, flex: 1 })
        }

        for (const metric of form.metrics) {
          columnsFiltered.push({ field: metric.key, headerName: metric.label, sortable: false, filterable: false, flex: 1 })
        }

        const rowsFiltered = []

        for (const d of data) {
          const obj = {}
          for (const column of columnsFiltered) {
            if (column.field === 'id') {
              obj[column.field] = d['machine_user_id']
            } else if (column.field === 'last_mood') {
              obj[column.field] = d.mood_json[`title_${userDetails.language || i18n.language}`]
            } else if (['productive_time', 'worked_time'].includes(column.field)) {
              obj[column.field] = hasPercent ? `${secondsToHm(Number(d[column.field]), false)} (${d[`percent_${column.field}`]}%)` : secondsToHm(Number(d[column.field]), false)
            } else if (['site_time', 'expected_productive_time', 'expected_work_time', 'neutral_time', 'outdoor_time', 'unproductive_time', 'software_time'].includes(column.field)) {
              obj[column.field] = secondsToHm(Number(d[column.field]), false)
            }else if(column.field === 'avg_start_time'){
              obj[column.field] = d.start_end_avg_time.avg_start
            }else if(column.field === 'avg_end_time'){
              obj[column.field] = d.start_end_avg_time.avg_end
            }else {
              obj[column.field] = d[column.field]
            }
          }
          rowsFiltered.push(obj)
        }
        setColumns(columnsFiltered)
        setRows(rowsFiltered)
        setData(data)
        setFetching(false)
      }
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const downloadExcel = async (e) => {

    try {

      if (data.length === 0) {
        return
      }

      setDownloading(true)
      const hasPercent = items.find(ci => ci.name === 'percent' && ci.checked === true)
      const hasGroupName = items.find(ci => ci.name === 'group_name' && ci.checked === true)

      const queryHasPercent = hasPercent ? `&has_percent=true` : ''
      const queryHasGroupName = hasGroupName ? `&has_group_name=true` : ''
      const queryTeams = (filter.teams && filter.teams.length > 0) ? `&teams=${filter.teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = (filter.teammates && filter.teammates.length > 0) ? `&users=${filter.teammates.map(t => t.id).join(',')}` : ''
      const queryMetrics = (form.metrics && form.metrics.length > 0) ? `&metrics=${form.metrics.map(m => m.key).join(',')}` : ''
      const response = await api.get(`/excel/reportAdvanced?date_start=${moment(form.dateStart).format('YYYY-MM-DD')}&date_end=${moment(form.dateEnd).format('YYYY-MM-DD')}&lang=${lang}${queryTeammates}${queryTeams}${queryHasPercent}${queryHasGroupName}${queryMetrics}`,
        {
          responseType: 'blob',
          headers: { ...authHeader(), 'Content-Type': 'application/octet-stream' }
        }
      )

      if (response.status !== 200) {
        throw new Error('Erro download file!')
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `reportAdvanced.xlsx`);
      document.body.appendChild(link);
      link.click();
      setDownloading(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.download'))
      setDownloading(false)
    }

  }

  const getMetrics = async () => {
    try {
      const { data } = await api.get(`/stats/report/metrics`, {
        headers: authHeader()
      })

      if (data && data.length > 0) {
        const metricsFiltered = data.map(met => ({ value: met.id, label: met[`title_${i18n.language}`], key: met.key }))
        setMetrics(metricsFiltered)
      }
    } catch (error) {
      console.log(error)  
      alertActions.error(t('generic_messages.errors.load'))
    }
  }

  useEffect(() => {
    getMetrics()
    teammatesActions.getTeammates();
    teamsActions.getTeams()
  }, [])

  return (
    <section className="mainContent productivity">
      <Toolbar />
      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhenYear={'disabled'}
        showShareBtn={false}
        routeBackToPage={'/productive/reports'}
      />
      <Box className={style.containerProductiveReportsAdvancedPage}>
        <Paper className={style.paperContainer} elevation={0}>
          <Typography variant="h6" className={style.title} style={{ marginBottom: '10px' }}>{t('productive_reports.advanced.title_card_filter')}</Typography>
          <Box
            component="form"
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                {props.loadingTeams || props.loadingTeammates
                  ? <WhoMultiSkeleton />
                  : <WhoMultiReport openWho={props.openWho} showLabel={false} />
                }
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("productive_reports.advanced.filter_card.date_start")}
                    value={form.dateStart}
                    inputFormat={getDataFormat(lang)}
                    onChange={dateStart => handleChange(dateStart, "dateStart")}
                    maxDate={moment().subtract(1, 'day').format("YYYY-MM-DD")}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        fullWidth
                        {...params}
                        sx={{ height: '48px', fieldset: { border: '1px solid #DEE3E9CC' }, input: { color: '#4F7091', padding: '14px' } }}
                        error={formErrors.dateStart && true}
                        helperText={formErrors.dateStart}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("productive_reports.advanced.filter_card.date_end")}
                    value={form.dateEnd}
                    inputFormat={getDataFormat(lang)}
                    onChange={dateEnd => handleChange(dateEnd, "dateEnd")}
                    maxDate={moment().subtract(1, 'day').format("YYYY-MM-DD")}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        fullWidth
                        {...params}
                        sx={{ height: '48px', fieldset: { border: '1px solid #DEE3E9CC' }, input: { color: '#4F7091', padding: '14px' } }}
                        error={formErrors.dateEnd && true}
                        helperText={formErrors.dateEnd}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <CustomReactSelect placeholderKey={'select_metrics'} value={form.metrics} options={metrics} onChange={(values) => setForm({ ...form, metrics: values })} />
                {formErrors.metrics &&
                  <div className={style.error}>{formErrors.metrics}</div>
                }
                <Typography variant='caption'></Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup row>
                  {items.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={<GreenCheckbox checked={item.checked} onChange={(event) => handleChangeCheckbox(event)} name={item.name} />}
                      label={<GreenLabel label={item.label} className={item.checked ? `${style.greenLabel}` : `${style.grayLabel}`} />}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ padding: '0px' }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button className={`${style.button} ${style.buttonCancel}`} type={'button'} onClick={() => resetForm()}>
                    {t('productive_reports.advanced.filter_card.clear')}
                  </button>
                  <button className={`${style.button} ${style.buttonSave}`} type={'submit'}>
                    {t('productive_reports.advanced.filter_card.filter')}
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper className={style.paperContainer} elevation={0}>
          <header className={style.headerTable}>
            <div className={style.boxInfo}>
              <Typography variant="h6" className={style.title} style={{ marginBottom: '6px' }}>{t('productive_reports.advanced.title_card_preview')}</Typography>
              {/* <Typography variant="p" className={style.subtitle}>Toda a empresa (10 colaboradores) 01/10/2023 a 01/11/2023</Typography> */}
            </div>
            {
              !downloading
              ?
            <button className={`${style.button} ${(!fetching) ? style.buttonExport : style.buttonDisabled}`} disabled={(!fetching) ? false : true} type={'button'} onClick={downloadExcel}>
              <span><FileXls size={18} color="#106276" weight="bold" /></span><span style={{ marginLeft: '6px' }}>{t('productive_reports.advanced.data_card.btn_export')}</span>
            </button>
            :
            <button className={`${style.button} ${style.buttonDisabled}`} disabled={true} type={'button'} onClick={downloadExcel}>
              <span style={{ marginLeft: '6px' }}>{t('productive_reports.advanced.data_card.downloading')}</span>
            </button>

            }
          </header>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {
                data.length > 0 ?
                  <Box sx={{ width: '100%' }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-row:hover": {
                          backgroundColor: "inherit"
                        }
                      }}
                      rows={rows}
                      columns={columns.map((column) => ({
                        ...column,
                        minWidth: 120, // Define autoWidth como true para todas as colunas
                        fontSize: 12
                      }))}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false
                          },
                        },
                        pagination: {
                          paginationModel: {
                            pageSize: 50,
                          },
                        },
                      }}
                      localeText={lang == 'pt' ? ptBR.components.MuiDataGrid.defaultProps.localeText : lang == 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                      pageSizeOptions={[50, 100, 200, 300, 400]}
                      checkboxSelection={false}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnResize
                      disableColumnMenu
                      columnHeaderHeight={40}
                    />
                  </Box>
                  :
                  <Box className={style.boxNoneData}>
                    <Typography variant='subtitle1' sx={{ color: '#4F7091' }}>{t('productive_reports.advanced.data_card.no_data')}</Typography>
                  </Box>
              }
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </section>
  )
}


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    teamsActions: bindActionCreators(teamsActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),

  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    teammates: state.teammates.payload,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsAdvancedPage)
