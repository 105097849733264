import { Avatar, Menu, MenuItem } from '@material-ui/core';
import { Check, CheckCircle, DotsThreeVertical } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { authHeader, numberToPercentString } from '../../../_helpers';
import IconInfoFlat from '../../_icons/IconInfoFlat';
import { KanbanAddCard } from './KanbanAddCard';
import { KanbanCard } from './KanbanCard';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './styles.scss';
import { Box, Button, IconButton, Popover, TextField, Typography, Tooltip } from '@mui/material';
import { CancelPresentation, Close, PeopleOutline, Save } from '@material-ui/icons';
import { api } from '../../../_helpers/api';
import { useRef } from 'react';
import EasyEdit from 'react-easy-edit';
import AvatarGroup from 'react-avatar-group';
import MembersWorkspaceModal from '../MembersWorkspaceModal';
import styleDashboard from '../Dashboard.module.scss';
import CardDetailsModal from '../CardDetailsModal';


export function KanbanBoard({
  boardData,
  setBoardData,
  getBoardKanban,
  alertActions,
  userDetails,
  boardID,
  colabs
}) {
  const { t } = useTranslation('common');
  // const [dataSorted, setDataSorted] = useState(boardData)
  const [anchorElOptionsList, setAnchorElOptionsList] = useState(null);
  const [currentList, setCurrentList] = useState(null);
  const [showFormAddList, setShowFormAddList] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formList, setFormList] = useState({
    title: ''
  });
  const [formListError, setFormListError] = useState({
    title: ''
  });
  const [deleting, setDeleting] = useState(false)
  const [marking, setMarking] = useState(false)
  const [anchorElDeleteList, setAnchorElDeleteList] = useState(null);
  const openDeleteList = Boolean(anchorElDeleteList);
  const id = openDeleteList ? 'simple-popover-delete-list' : undefined;
  const [openModalMembers, setOpenModalMembers] = useState(false)
  const [openModalDetailsCard, setOpenModalDetailsCard] = useState(false)
  const [currentCard, setCurrentCard] = useState(null)

  function handleCloseDeleteListPopUp() {
    setDeleting(false)
    setAnchorElDeleteList(null)
    handleCloseMenuOptionsList()
  }
  const handleDeleteList = async () => {
    try {
      setDeleting(true)
      const response = await api.delete(`/project-management/boards/lists/${currentList?.id}`,
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteListPopUp()
      if (response.status === 200) {
        alertActions.success(t('project_management.success.deleted_list'))
        getBoardKanban()
      }

      setDeleting(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.deleted_list'))
      setDeleting(false)
    }
  }

  const handleMarkLastStage = async () => {
    try {
      setMarking(true)
      const response = await api.put(`/project-management/boards/lists/${currentList?.id}/mark_as_last_stage`,
        {
          board_id: boardData.id
        },
        {
          headers: authHeader()
        }
      )

      if (response.status === 200) {
        // alertActions.success(t('project_management.success.deleted_list'))
        getBoardKanban()
      }
      handleCloseMenuOptionsList()
      setMarking(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.deleted_list'))
      setMarking(false)
    }
  }

  const handleClickDeleteList = (event) => {
    setAnchorElDeleteList(event.currentTarget);
  };

  const handleCloseMenuOptionsList = () => {
    setAnchorElOptionsList(null);
    setCurrentList(null)
  };

  const handleClickMenuList = (e, list) => {
    setAnchorElOptionsList(e.currentTarget);
    setCurrentList(list);
  }

  function handleOpenModalNewTask() {
    handleCloseMenuOptionsList()
    showModalNewCard(currentList)
  }
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  const validateFormList = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('project_management.validations.required_field')
    } else if (form.title.length < 4) {
      errors.title = t('project_management.validations.len_field_error_4')
    }

    return errors
  }

  const handleSubmitAddList = (e) => {
    e.preventDefault()
    setFormListError(validateFormList(formList))
    setSubmitted(true)
  }

  const handleChange = e => {
    setFormList({
      ...formList,
      [e.target.name]: e.target.value,
    })
  }

  const createList = async () => {
    try {
      setIsSubmitting(true)

      await api.post(`/project-management/boards/${boardData.id}/lists`, formList, {
        headers: authHeader()
      })
      getBoardKanban()
      // alertActions.success(t('project_management.success.created_board'))
      setIsSubmitting(false)
      setShowFormAddList(false)
      setFormList({
        title: ''
      })
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      // //alertActions.error(t('project_management.errors.created_board'))
    }
  }

  useEffect(() => {

    if (Object.keys(formListError).length === 0 && submitted) {
      createList()
    } else {
      setSubmitted(false)
    }

  }, [formListError, submitted])

  const handleDragEndCard = async ({ source, destination, draggableId }) => {

    if (!destination) return; // Se o item não foi solto em uma posição válida

    if (source.droppableId !== destination.droppableId) {

      const lists = boardData.lists

      const sourceList = lists.find(l1 => String(l1.id) === source.droppableId)
      const destinationList = lists.find(l2 => String(l2.id) === destination.droppableId)

      const sourceCards = [...sourceList.cards]
      const destinationsCards = [...destinationList.cards]

      const [removed] = sourceCards.splice(source.index, 1);
      destinationsCards.splice(destination.index, 0, removed);

      const listsAux = lists.filter(l3 => String(l3.id) !== source.droppableId && String(l3.id) !== destination.droppableId)

      console.log("/draggableId/card_id:", draggableId, " source:", source.index, "destination:", destination.index, "list_source", sourceList.id, "list_destination", destinationList.id)
      setBoardData({
        ...boardData,
        lists: [
          ...listsAux,
          {
            ...sourceList,
            cards: sourceCards
          },
          {
            ...destinationList,
            cards: destinationsCards
          }
        ].sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(b.position) > parseInt(a.position)) ? -1 : 0))
      })

      try {// source, destination, source_list, destination_list, card_id
        const response = await api.patch(`/project-management/boards/lists/cards/dif-lists/update-index?organization_id=${userDetails?.organizationId}`, {
          card_id: draggableId,
          source_list: sourceList.id,
          destination_list: destinationList.id,
          source: source.index,
          destination: destination.index,
        },
          {
            headers: authHeader()
          })
        if (response.status === 200) {
          await getBoardKanban()
        }
      } catch (error) {
        console.log(error.response)
      }
    } else {
      const lists = boardData.lists

      const list = lists.find(l1 => String(l1.id) === source.droppableId)
      const copiedCards = [...list.cards];
      const [removed] = copiedCards.splice(source.index, 1);
      copiedCards.splice(destination.index, 0, removed);

      const listsAux = lists.filter(l3 => String(l3.id) !== source.droppableId)

      setBoardData({
        ...boardData,
        lists: [
          ...listsAux,
          {
            ...list,
            cards: copiedCards
          }
        ]
          .sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(b.position) > parseInt(a.position)) ? -1 : 0))
      })
      // console.log("/draggableId:",draggableId," sourc:",source.index,"destination:",destination.index,"list_id",list.id)
      try {// source, destination, list_id, card_id
        const response = await api.patch(`/project-management/boards/lists/cards/update-index?organization_id=${userDetails?.organizationId}`, {
          card_id: draggableId,
          list_id: list.id,
          source: source.index,
          destination: destination.index,
        },
          {
            headers: authHeader()
          })
        if (response.status === 200) {
          await getBoardKanban()
        }
      } catch (error) {
        console.log(error.response)
      }
    }
  };

  const handleDragEndList = async (result) => {

    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const listId = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const listFiltered = boardData.lists.find(kr => Number(kr.id) === Number(listId))

    const newItems = Array.from(boardData.lists);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setBoardData({
      ...boardData,
      lists: newItems
    });

    console.log("/draggableId:", listId, " sourc:", source, "destination:", destination, "board", result)
    try {// source, destination, list_id, card_id
      const response = await api.patch(`/project-management/boards/lists/update-index?organization_id=${userDetails?.organizationId}`, {
        list_id: listId,
        board_id: boardID,
        source: source,
        destination: destination,
      },
        {
          headers: authHeader()
        })
      if (response.status === 200) {
        await getBoardKanban()
      }
    } catch (error) {
      console.log(error.response)
    }

  }

  const handleDragEnd = async (result) => {
    if (result.type === 'LIST') {
      handleDragEndList(result)
    } else {
      handleDragEndCard(result)
    }

  };

  const save = async (value, listId) => {
    // console.log(value, listId)
    try {
      await api.put(`/project-management/boards/lists/${listId}`, { title: value }, { headers: authHeader() })
      getBoardKanban()
      // setDataSorted(boardData)
    } catch (error) {
      console.log(error)
    }
  }
  const cancel = (e) => {
    console.log(e)
  }

  return (
    <Box className='mainKanbanBoard'>
      <Box display={'flex'} alignItems={'center'} height={'40px'} padding={'10px 20px'} justifyContent={'space-between'}>
        <Typography variant='h6' style={{ color: "#1E6B7F" }}>{boardData.title}</Typography>
        <Box display={'flex'} gap={'6px'}>
          <Box
            onClick={() => {
              setOpenModalMembers(true)
            }}
          >
            <AvatarGroup
              randomBackgroundColors={['#24677E']}
              avatars={boardData.board_members.map((m => m.username))}
              initialCharacters={2}
              max={5}
              size={30}
              fontSize={0.4}
              displayAllOnHover={false}
              // shadow={2}
              style={{
                cursor: 'pointer',
                marginLeft: '-2px'
              }}
              avatarStyle={{
                margin: '-2px'
              }}
            />
          </Box>
          {
            boardData.board_members.length === 0 &&
            <Button
              variant="outlined"
              size="small"
              className="buttonActions"
              startIcon={<PeopleOutline />}
              onClick={() => {
                setOpenModalMembers(true)
              }}
            >
              {t('project_management.workspaces.members')}
            </Button>
          }
        </Box>
      </Box>
      <Box className='kanbanBoardContainer'>
        {
          boardData?.lists?.length > 0
            ?
            <>
              <DragDropContext onDragEnd={handleDragEnd} >
                <Droppable
                  type="LIST"
                  droppableId="lists"
                  direction="horizontal"
                >
                  {
                    (provided) => (
                      <Box display={'flex'} gap={'10px'} ref={provided.innerRef} {...provided.droppableProps} sx={{ overflow: 'auto', maxHeight: `${windowSize[1] - 200}px` }} padding={'8px'} >
                        {
                          boardData.lists?.map((list, index) => (
                            <Draggable key={list.id} index={index} draggableId={String(list.id)}>

                              {
                                (provided, snapshot) => {

                                  return (
                                    <Box
                                      key={index}
                                      index={index}
                                      className='kanban-container-cards'
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    // isDragging={snapshot.isDragging}
                                    >
                                      <header>
                                        <EasyEdit
                                          type={'text'}
                                          onSave={(value) => save(value, list.id)}
                                          onCancel={cancel}
                                          attributes={{ name: "titleList", id: 1 }}
                                          value={list.title}
                                          placeholder={t('project_management.kanban.placeholder_title')}
                                          hideSaveButton
                                          hideCancelButton
                                          cancelOnBlur

                                        />
                                        {
                                          list.last_stage === true &&
                                          <Tooltip
                                            title={t('project_management.kanban.title_list_done')}
                                            style={{ cursor: 'pointer' }}
                                            PopperProps={{
                                              modifiers: [
                                                {
                                                  name: "offset",
                                                  options: {
                                                    offset: [0, -5],
                                                  },
                                                },
                                              ],
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: '8px',
                                                display: 'flex',
                                                cursor: 'auto'
                                              }}
                                            >
                                              <Check size={24} color="#22BEAD" weight="regular" />
                                            </div>
                                          </Tooltip>
                                        }

                                        <DotsThreeVertical
                                          size={30} weight="bold"
                                          color='#98ACC1'
                                          onClick={e => handleClickMenuList(e, list)}
                                        />
                                      </header>

                                      <Droppable
                                        type="CARD"
                                        droppableId={String(list.id)}
                                      >
                                        {(dropProvided, dropSnapshot) => (
                                          <Box
                                            className='krs-cards'
                                            ref={dropProvided.innerRef}
                                            {...dropProvided.droppableProps}
                                            {...dropProvided.dragHandleProps}
                                            isDraggingOver={dropSnapshot.isDraggingOver}
                                            sx={{height: '100%', overflowY: 'auto', maxHeight: `${windowSize[1] - 320}px` }}
                                          >
                                            {list.cards?.map((card, index) => (
                                              <Draggable key={card.id} draggableId={String(card.id)} index={index} >
                                                {
                                                  (dragProvided, dragSnapshot) => (
                                                    <KanbanCard
                                                      innerRef={dragProvided.innerRef}
                                                      provided={dragProvided}
                                                      card={card}
                                                      list={list}
                                                      key={card.id}
                                                      showModalCard={() => {
                                                        setCurrentCard(card)
                                                        setOpenModalDetailsCard(true)
                                                      }}
                                                    />

                                                  )}
                                              </Draggable>
                                            ))}
                                            {dropProvided.placeholder}
                                          </Box>
                                        )}
                                      </Droppable>
                                      <KanbanAddCard list={list} getBoardKanban={getBoardKanban} />
                                    </Box>
                                  )
                                }
                              }
                            </Draggable>

                          ))
                        }
                        {provided.placeholder}
                        {
                          !showFormAddList
                            ?
                            <Box className='boxNewList' onClick={() => setShowFormAddList(true)}>
                              <span>+ {t('project_management.kanban.add_list')}</span>
                            </Box>
                            :
                            <Box
                              className='formNewList'
                              component="form"
                              noValidate
                              autoComplete="off"
                              onSubmit={handleSubmitAddList}
                            >
                              <Box>
                                <TextField id="title" label="Titulo" autoFocus variant="outlined" size='small' name='title' onChange={handleChange} value={formList.title || ''} fullWidth />
                                {formListError.title &&
                                  <div className="error">{formListError.title}</div>
                                }
                              </Box>
                              <Box display={'flex'} justifyContent={'flex-end'} gap={1}>
                                <IconButton aria-label="close" size="small" onClick={() => setShowFormAddList(false)} type='button'>
                                  <Close fontSize='inherit' />
                                </IconButton>
                                <Button variant="contained" disableElevation size='small' type='submit' disabled={isSubmitting}>
                                  {t('project_management.kanban.add_btn')}
                                </Button>
                              </Box>
                            </Box>
                        }
                      </Box>
                    )
                  }

                </Droppable>

              </DragDropContext>
            </>

            :
            !showFormAddList
              ?
              <Box className='boxNewList' onClick={() => setShowFormAddList(true)}>
                <span>+ {t('project_management.kanban.add_list')}</span>
              </Box>
              :
              <Box
                className='formNewList'
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmitAddList}
              >
                <Box>
                  <TextField id="title" autoFocus label="Titulo" variant="outlined" size='small' name='title' onChange={handleChange} value={formList.title || ''} fullWidth />
                  {formListError.title &&
                    <div className="error">{formListError.title}</div>
                  }
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} gap={1}>
                  <IconButton aria-label="close" size="small" onClick={() => setShowFormAddList(false)} type='button'>
                    <Close fontSize='inherit' />
                  </IconButton>
                  <Button variant="contained" disableElevation size='small' type='submit' disabled={isSubmitting}>
                    {t('project_management.kanban.add_btn')}
                  </Button>
                </Box>
              </Box>
        }

      </Box>
      {
        currentList &&
        <Popover
          id="menu-options-key-result-kanban`"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorElOptionsList}
          open={Boolean(anchorElOptionsList)}
          onClose={handleCloseMenuOptionsList}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableEnforceFocus
        >
          <Box className={styleDashboard.menuOptionsBoard}>
            {
              !currentList?.last_stage &&
              <button disabled={marking} className={styleDashboard.menuOptionsBoardButton} onClick={handleMarkLastStage}>{t('project_management.btn_define_done')}</button>
            }
            <button disabled={deleting} className={styleDashboard.menuOptionsBoardButton} onClick={handleClickDeleteList}>{t('project_management.kanban.delete_menu')}</button>
          </Box>
        </Popover>
      }

      <Popover
        id={id}
        open={openDeleteList}
        anchorEl={anchorElDeleteList}
        onClose={handleCloseDeleteListPopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
      >
        <Box
          sx={{
            backgroundColor: '#FFE0DF'
          }}
        >
          <Typography variant="subtitle2" sx={{ p: 2, color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_delete_list')}</Typography>
          <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseDeleteListPopUp}
              disabled={deleting}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#fff',
                color: '#FC6662',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.no')}
            </Button>
            <Button
              onClick={handleDeleteList}
              disabled={deleting}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#FC6662',
                color: '#fff',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.yes')}
            </Button>
          </Box>
        </Box>
      </Popover>

      <MembersWorkspaceModal
        type='B'
        openModalMembers={openModalMembers}
        setOpenModalMembers={setOpenModalMembers}
        userDetails={userDetails}
        colabsOptions={colabs}
        alertActions={alertActions}
        updateInfo={getBoardKanban}
        currentBoard={boardData}
      />

      {
        openModalDetailsCard &&
        <CardDetailsModal
          openModalDetailsCard={openModalDetailsCard}
          setOpenModalDetailsCard={setOpenModalDetailsCard}
          userDetails={userDetails}
          colabsOptions={colabs}
          alertActions={alertActions}
          updateInfo={getBoardKanban}
          currentCard={currentCard}
          setCurrentCard={setCurrentCard}
        />
      }

    </Box>
  );
}
