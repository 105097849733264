import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";

function Map({ markers }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const [zoom, setZoom] = useState(3)
  const [center, setCenter] = useState({ lat: -5.112764, lng: -55.530247 });

  const handleActiveMarker = (marker,position) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setActiveMarker(marker);
    setZoom(6)
    setCenter(position);
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    if (markers.length > 0) {
      markers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
    } else {
      bounds.extend({
        lat: -5.112764, 
        lng: -55.530247
      });
      map.fitBounds(bounds);
    }

  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      // onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100%", height: "75vh" }}
      options={{
        zoom: zoom,
        center: center
      }}
    >
      {markers.map(({ point_id, owner_name, position }) => (
        <Marker
          // label={owner_name}
          key={point_id}
          position={position}
          onClick={() => handleActiveMarker(point_id, position)}
          // onMouseOver={() => handleActiveMarker(point_id)}
          icon={{
            url: `https://ui-avatars.com/api/?background=24677E&color=ffffff&name=${encodeURI(owner_name)}`,
            scaledSize: { width: 32, height: 32 }
          }}
        >
          {activeMarker === point_id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{owner_name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default Map;
