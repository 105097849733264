import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import Moment from 'moment';
import IconBack from '../_components/_icons/IconBack'

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { periodicActions, teammatesActions, teamsActions } from '../_actions';
import { periodicService } from '../_services';
import ScheduledReportsCreateSkeleton from '../_components/scheduled_reports/ScheduledReportsCreateSkeleton';
import ScheduledReportsCreate from '../_components/scheduled_reports/ScheduledReportsCreate';
import ScheduledReportsEdit from '../_components/scheduled_reports/ScheduledReportsEdit';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';


function ScheduledReportsEditPage(props) {
    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    const { teammatesActions, teamsActions } = props;
    let loading = props.loading;
    let loadingTeams = props.loadingTeams;
    let loadingTeammates = props.loadingTeammates

    const { id } = useParams()

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const [scheduled, setScheduled] = useState(null)

    const getScheduledById = async () => {
        try {
            const { data } = await api.get(`/scheduled_reports/${id}`, {headers: authHeader()})
            setScheduled(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        teamsActions.getTeams();
        teammatesActions.getTeammates();
        getScheduledById()
    }, [])

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar />
                <div className="mainFilterbar">
                    <button className={'back'} onClick={() => {
                        history.push("/productive/scheduled-reports")
                    }}>
                        <IconBack />
                    </button>
                </div>
                {/* PAGE STARTS */}
                <div className="mainPage profile">
                    <div className="nocolumn">
                        <div className={'tab full active'}>
                            {
                                !minimumTimeElapsed || (loading === true || loadingTeammates === true || loadingTeams === true || scheduled === null)
                                    ? <ScheduledReportsCreateSkeleton />
                                    : <ScheduledReportsEdit scheduled={scheduled} updateInfo={getScheduledById}/>
                            }
                        </div>
                    </div>

                </div>
                {/* PAGE ENDS */}
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        periodicActions: bindActionCreators(periodicActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        loading: state.periodic.loading,
        loadingTeams: state.teams.loading,
        loadingTeammates: state.teammates.loading
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsEditPage)
