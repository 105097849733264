import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../../_components/_shared/Toolbar'
import { useTranslation } from "react-i18next";
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import Filterbar from '../../_components/_shared/Filterbar';
import style from './styles.module.scss'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import MoodsSettings from '../../_components/moods_settings';
import { alertActions } from '../../_actions';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  }
}));

function MoodsSettingsPage(props) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles()

  const { userDetails, alertActions} = props;
  const [dataMoods, setDataMoods] = useState(null)
  const [fetching, setFetching] = useState(true)

  const getMoodsSettings = async (isFetching=true) => {
    try {
      if(isFetching) 
        setFetching(true)

      const res = await api.get(`/settings/mood`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get mood settings')
      }

      
      setDataMoods({
        moodsOptions: res.data.avaliable_moods.map(item1 => ({value: item1.id, label: item1[`title_${i18n.language}`], url_image: item1.url_image})),
        moodsConfig:  res.data.recorded_moods.map(item2 => ({value: item2.id, label: item2[`title_${i18n.language}`], url_image: item2.url_image}))
      })

    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    getMoodsSettings()
  }, [])

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.moods_settings');
  }, []);


  return (
      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />

        <div className={style.container}>
          {
            (!fetching && dataMoods !== null)
              ?
              <MoodsSettings moodsOptions={dataMoods.moodsOptions} moodsConfig={dataMoods.moodsConfig} getData={getMoodsSettings} alertActions={alertActions}/>
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
      </section>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MoodsSettingsPage)
