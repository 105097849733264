import React, { useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useTranslation, initReactI18next} from "react-i18next"
import {bindActionCreators} from 'redux'
import Moment from 'moment';
import {settingsActions} from '../../../_actions'
import IconRemove from '../../_icons/IconRemove'
import IconAdd from '../../_icons/IconAdd'
import IconInfo from '../../_icons/IconInfo'

function DrawerDaysoff(props) {
    const {t, i18n} = useTranslation('common')

    const {settingsActions} = props
    const current = props.current
    let daysOff = props.daysOff || []
    let created = props.created || false

    let daysList = []

    const [submitted, setSubmitted] = useState(false)
    const [form, setForm] = useState({
        date_start: '',
        date_end: '',
        reason: ''
    })

    const [formError, setFormError] = useState({
        date_start: '',
        date_end: '',
        reason: ''
    })

    for (let dayOff of daysOff) {

        let day

        if (dayOff.date_start === dayOff.date_end) {
            day = Moment.utc(dayOff.date_start).format(t("date.mm/dd/yyyy"))
        } else {
            day = Moment.utc(dayOff.date_start).format(t("date.mm/dd/yyyy")) + " " + t("date.to") + " " + Moment.utc(dayOff.date_end).format(t("date.mm/dd/yyyy"))
        }

        daysList.push(<li key={"det-" + dayOff.id}>
            <div className="day">{day}</div>
            <div className="reason">{dayOff.reason}</div>
            <div className="actions">
                <button onClick={() => {
                    toggleRemoveDialog(dayOff.id)
                }}><IconRemove/></button>
                <div className="removeDialog" id={"remove-" + dayOff.id}>
                    <div className="content">
                        <div className="message"><IconInfo/>{t('settings.administrator.you_are_sure')}</div>
                        <div className="options">
                            <button onClick={() => {
                                toggleRemoveDialog()
                            }}>{t('no')}</button>
                            <button onClick={() => {
                                disableDayOff(dayOff.id)
                            }}>{t('yes')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </li>)

    }

    function toggleRemoveDialog(id) {

        let elems = document.querySelectorAll(".removeDialog.open")

        if (elems.length > 0) {
            [].forEach.call(elems, function (el) {
                el.classList.remove("open");
            });
        }

        let elem = document.getElementById("remove-" + id)

        if (elem) {
            elem.classList.toggle("open")
        }

    }

    const handleChange = e => {

        setSubmitted(false)
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    function handleSubmit() {


        setSubmitted(true)

        let formIsValid = true

        if (!form["date_start"]) {
            formIsValid = false
            setFormError({
                ...formError,
                date_start: t('settings.company.error.required'),
            })
        }

        if (!form["date_end"]) {
            formIsValid = false
            setFormError({
                ...formError,
                date_end: t('settings.company.error.required'),
            })
        }

        if (!form["reason"] && form["reason"].length > 3) {
            formIsValid = false
            setFormError({
                ...formError,
                reason: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            setFormError({
                date_start: '',
                date_end: '',
                reason: ''
            })

            settingsActions.postCompanyDaysoff(form, current.id, current.mode)
        } else {
            setSubmitted(false)
        }

    }

    function disableDayOff(id) {
        settingsActions.deleteCompanyDaysoff(id)
        document.getElementById("remove-" + id).parentNode.parentNode.remove()
    }

    function closeDrawer() {

        setForm({
            date_start: '',
            date_end: '',
            reason: ''
        })

        setFormError({
            date_start: '',
            date_end: '',
            reason: ''
        })

        props.closeDrawer()
    }

    useEffect(() => {

        if (created == 'error') {
            setSubmitted(false)
        }

        if (created == 'success' && submitted) {
            setSubmitted(false)
            setForm({
                date_start: '',
                date_end: '',
                reason: ''
            })


            if (current.mode == "Group") {
                settingsActions.getCompanyDaysoffSpecific(current.id, "team")
            } else {
                settingsActions.getCompanyDaysoffSpecific(current.id, current.mode)
            }
        }

    }, [created])


    return (
        <React.Fragment>
            <div className="internal daysoff">

                <header>
                    <h3>{current.current}</h3>

                </header>

                <div className="days">
                    <ul>
                        <li className="newDayOff">
                            <div className="day" style={{backgroundColor: 'transparent'}}>
                                <input
                                    className={formError.date_start ? 'error' : ''}
                                    type="date" id="date_start"
                                    name="date_start"
                                    value={form.date_start}
                                    onChange={handleChange}
                                    max={form.date_end}
                                // min={Moment(new Date()).add(1, 'days').format('yyyy-MM-DD')}
                                />
                                <span>{t("date.to")}</span>
                                <input
                                    className={formError.date_end ? 'error' : ''}
                                    type="date" id="date_end"
                                    name="date_end"
                                    value={form.date_end}
                                    onChange={handleChange}
                                    min={form.date_start}
                                />
                            </div>
                            <div className="reason">
                                <input
                                    className={formError.reason ? 'error' : ''}
                                    type="text"
                                    id="reason"
                                    name="reason"
                                    placeholder={t("settings.daysofff.reason_for")}
                                    value={form.reason}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </div>

                            <div className="actions">

                                {(submitted) &&
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                }
                                {(!submitted) &&
                                    <button
                                        className="add"
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    >
                                        <IconAdd />
                                    </button>
                                }
                            </div>


                        </li>
                        <li>
                            <div className="day">{t("settings.daysofff.daysoff")}</div>
                            <div className="reason">{t("settings.daysofff.reason")}</div>
                            <div className="actions"></div>
                        </li>
                        {daysList}
                    </ul>


                </div>


            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {

    return {
        daysOff: state.settings.companyDaysoffSpecific,
        created: state.settings.created
    }

}


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DrawerDaysoff)



