import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide, Popover, Box } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";

import "./ModalBoard.scss"
import moment from "moment";
import { BefectiveDatePicker } from "../goals/BefectiveDatePicker";
import { CirclePicker } from "react-color";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalEditBoard({
  openModalEditBoard,
  setOpenModalEditBoard,
  closeMenu,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo,
  currentBoard,
  workspaces = []
}) {

  const { t } = useTranslation('common');
  const classes = useStyles();
  const [colabs, setColabs] = useState(currentBoard?.colabs_ids)
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [form, setForm] = useState({
    title: '',
    description: '',
    colabs: [],
    deadline: moment().format('YYYY-MM-DD'),
    bgColor: '',
    workspace_id: ''
  });

  const [formError, setFormError] = useState({
    title: '',
    description: '',
    colabs: '',
    deadline: '',
    bgColor: '',
    workspace_id: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function handleChangeColor(color) {
    setForm({
      ...form,
      bgColor: color.hex
    })
    handleClose()
  }

  function closeModalEditBoard() {
    setOpenModalEditBoard(false)
    setForm({
      title: '',
      description: '',
      colabs: [],
      deadline: moment().format('YYYY-MM-DD'),
      bgColor: '',
      workspace_id: ''
    })
    setFormError({
      title: '',
      description: '',
      colabs: '',
      deadline: '',
      bgColor: '',
      workspace_id: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('project_management.validations.required_field')
    } else if (form.title.length < 4) {
      errors.title = t('project_management.validations.len_field_error_4')
    }

    if (!form.deadline) {
      errors.deadline = t('project_management.validations.required_field')
    }

    if (!form.workspace_id) {
      errors.workspace_id = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmitBoard = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const updateBoard = async () => {
    try {
      setIsSubmitting(true)

      await api.put(`/project-management/boards/${form?.id}`, {
        ...form,
        color: form.bgColor,
        colabs_ids: form?.colabs.map(item => item.value)
      }, {
        headers: authHeader()
      })
      updateInfo()
      alertActions.success(t('project_management.success.updated_board'))
      closeModalEditBoard()
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('project_management.errors.updated_board'))
    }
  }


  useEffect(() => {
    handleChange({ target: { name: 'colabs', value: colabs } })
  }, [colabs])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      updateBoard()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


  const getBoard = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/project-management/boards/${currentBoard.id}`, {
        headers: authHeader()
      })
      setForm({ ...data, bgColor: data.color || '', colabs: colabsOptions?.filter(item => data?.colabs_ids.includes(item.value)) })
      setLoading(false)
    } catch (error) {
      console.error(error.response)
      //alertActions.error(t('project_management.errors.load'))
      setLoading(false)
    }
  }


  useEffect(() => {
    if (currentBoard) {
      getBoard()
    }
  }, [currentBoard])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalBoard ${classes.modal}`}
        open={openModalEditBoard}
        onClose={closeModalEditBoard}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalEditBoard} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalBoard"}>

            <button onClick={() => { closeModalEditBoard() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmitBoard}>
              <main>
                <header>
                  <h3>
                    {t('project_management.boards.modal.title_edit')}
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="bgColor">{t('project_management.boards.modal.label_bgcolor')}</label>
                  <div className="inputBgColor" aria-describedby={id} onClick={handleClick}>
                    {
                      form.bgColor !== '' ? <div className="bgColorSelect" style={{ backgroundColor: form.bgColor || '#ffffff' }}></div> : <div className="placeholderBgColor">{t('project_management.boards.modal.placeholder_bgcolor')}</div>
                    }
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        overflowY: 'hidden'
                      }
                    }}
                  >
                    <Box width={'595px'} padding={'10px'}>
                      <CirclePicker color={form.bgColor} onChangeComplete={handleChangeColor} className="colorPickerCircle" colors={[
                          "#D2EBBF",
                          "#FFF494",
                          "#FEDA8E",
                          "#D1BBFF",
                          "#CEE5FF",
                          "#EFE3FA",
                          "#E6ECF3",
                          "#D0D9E3",
                          "#CFFAE2",
                          "#FEC4D0",
                          "#FFEAED",
                          "#91E9F3"
                        ]} />
                    </Box>
                  </Popover>
                </div>
                <div className="field">
                  <label htmlFor="title">{t('project_management.boards.modal.label_title')}*</label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    minLength={4}
                    maxLength={100}
                    placeholder={t('project_management.boards.modal.placeholder_title')}
                    value={form.title || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.title.length}/100</span>
                  {formError.title &&
                    <div className="error">{formError.title}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="description">{t('project_management.boards.modal.label_description')}</label>
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="5"
                    minLength={8}
                    maxLength={600}
                    placeholder={t('project_management.boards.modal.placeholder_description')}
                    value={form.description || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.description.length}/600</span>
                  {formError.description &&
                    <div className="error">{formError.description}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="colabs">{t('project_management.boards.modal.label_colabs')}</label>
                  <Select
                    id='colabs'
                    isMulti
                    value={form.colabs}
                    name="colabs"
                    options={colabsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('project_management.boards.modal.placeholder_colabs')}
                    onChange={setColabs}
                  />

                  {formError.colabs &&
                    <div className="error">{formError.colabs}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="deadline">{t('project_management.boards.modal.deadline')}</label>
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.deadline}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="deadline"
                    minDate={moment().format('YYYY-MM-DD')}
                    className={'deadlineBoard'}
                  />

                  {formError.deadline &&
                    <div className="error">{formError.deadline}</div>
                  }
                </div>


                <div className="field">
                  <label htmlFor="type">{t('project_management.workspaces.workspace')}*</label>
                  <select name="workspace_id" id="workspace_id" value={form.workspace_id || ''} onChange={handleChange} style={{ fontSize: form.workspace_id === '' ? '12px' : '14px', color: form.workspace_id === '' && '#A4B3CD' }}>
                    <option value="" selected hidden disabled >{t('project_management.workspaces.select')}</option>
                    {
                      workspaces.map((workspace, index) => (
                        <option key={workspace.id} style={{ color: '#1E6B7F' }} value={workspace.id}>{workspace.title}</option>
                      ))
                    }
                  </select>
                  {formError.workspace_id &&
                    <div className="error">{formError.workspace_id}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalEditBoard() }}
                    >
                      {t('project_management.boards.modal.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('project_management.boards.modal.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalEditBoard));
