export const formatCurrencyByEnd = (value) => {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2
  }).format(parseFloat(value) / 100).replaceAll(',', '');

  return `${amount}`;
};
