import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {NavLink, Link} from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'

import CompanyInformation from '../_components/settings/company/CompanyInformation'
import CompanyInformationSkeleton from '../_components/settings/company/CompanyInformationSkeleton'
import CompanyAdministrators from '../_components/settings/company/CompanyAdministrators'
import CompanyAdministratorsSkeleton from '../_components/settings/company/CompanyAdministratorsSkeleton'
import CompanyDaysoff from '../_components/settings/company/CompanyDaysoff'
import CompanyDaysoffSkeleton from '../_components/settings/company/CompanyDaysoffSkeleton'
import CompanyInventory from '../_components/settings/company/CompanyInventory'
import CompanyInventorySkeleton from '../_components/settings/company/CompanyInventorySkeleton'
import DrawerInvite from '../_components/settings/company/DrawerInvite'
import DrawerManage from '../_components/settings/company/DrawerManage'
import DrawerDaysoff from '../_components/settings/company/DrawerDaysoff'

import Skeleton from '@material-ui/lab/Skeleton';
import {useTranslation, initReactI18next} from "react-i18next";

import {teamsActions} from '../_actions';
import {settingsActions} from '../_actions';

import {VictoryPie, VictoryTheme} from 'victory';

import IconAvatar from '../_components/_icons/IconAvatar';
import IconClose from '../_components/_icons/IconClose';

function CompanyPageAdministrators(props) {
    const {t, i18n} = useTranslation('common');

    const {settingsActions} = props;
    const {teamsActions} = props;

    let permissions = props.permissions || [];
    let companyInformation = props.companyInformation || {};
    let token = props.token || {};
    let loading = props.loading;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const user = JSON.parse(window.atob(base64));

    const [isActive, setIsActive] = useState({
        information: true,
        administrators: false,
        daysoff: false,
        inventory: false
    });

    const [drawer, setDrawer] = useState(false)
    const [isShowingDrawerInvite, setDrawerInvite] = useState(false)
    const [isShowingDrawerManage, setDrawerManage] = useState(false)
    const [isShowingDrawerDayoff, setDrawerDayoff] = useState(false)
    const [drawerManageId, setDrawerManageId] = useState()
    const [drawerDayoffData, setDrawerDayoffData] = useState()


    const [minimumTime, setMinimumTime] = useState(500)
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)

    let perLicenses = 0;

    if (companyInformation.usedLicenses > 0) {

        perLicenses = Math.round((companyInformation.usedLicenses / companyInformation.totalLicenses) * 100);

    }


    function openDrawer(data) {
        setDrawer(true)

        if (data.type == "invite") {
            setDrawerManage(false)
            setDrawerDayoff(false)
            setDrawerInvite(true)
        }

        if (data.type == "manage") {
            setDrawerInvite(false)
            setDrawerDayoff(false)
            setDrawerManage(true)
            setDrawerManageId(data.id)
        }

        if (data.type == "dayoff") {

            setDrawerManage(false)
            setDrawerInvite(false)
            setDrawerDayoff(true)
            setDrawerDayoffData(data)
        }

    }

    function closeDrawer() {
        setDrawer(false)
        setDrawerInvite(false)
        setDrawerManage(false)
        setDrawerDayoff(false)
    }

    useEffect(() => {

        settingsActions.getCompanyAdministrators()

    }, [])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.company') + ' | ' + t('settings.administrators');
    });

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>

                <div className="mainFilterbar">
                    {(permissions.ViewCompanyInformation) &&
                        <NavLink exact to="/settings/company" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.information')}</button>
                        </NavLink>
                    }

                    {(permissions.ViewCompanyAdministrators) &&
                        <NavLink to="/settings/company/administrators" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.administrators')}</button>
                        </NavLink>
                    }

                    {(permissions.ViewCompanyFreeDays) &&
                        <NavLink to="/settings/company/daysoff" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.daysoff')}</button>
                        </NavLink>
                    }

                    {/* {(permissions.ViewCompanyInventory) &&
                        <NavLink to="/settings/company/inventory-teammates" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.inventory')}</button>
                        </NavLink>
                    } */}

                </div>

                {/* PAGE STARTS */}

                <div className="mainPage profile">
                    <div className="nocolumn">


                        <div className={'tab full active'}>
                            {
                                !minimumTimeElapsed || loading
                                    ? <CompanyAdministratorsSkeleton/>
                                    : <CompanyAdministrators openDrawer={(data) => {
                                        openDrawer(data)
                                    }}/>

                            }
                        </div>


                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
                setDrawer(false)
            }}></div>

            <section className={'drawer drawer600' + (drawer ? ' open' : '')}>
                <button onClick={() => {
                    setDrawer(false)
                }} className="close"><IconClose/></button>
                {isShowingDrawerInvite &&
                    <DrawerInvite closeDrawer={() => {
                        closeDrawer()
                    }}/>
                }

                {isShowingDrawerManage &&
                    <DrawerManage userId={drawerManageId} closeDrawer={() => {
                        closeDrawer()
                    }}/>
                }


            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        companyInformation: state.settings.companyInformation,
        loading: state.settings.loading,
        filter: state.filter,
        permissions: state.authentication.permissions
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageAdministrators)
