import { Box, Breadcrumbs, CircularProgress, Container, Divider, Grid, IconButton, InputBase, Link, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './styles.module.scss'
import IconLogo from '../../_components/_icons/IconLogo'
import { useTranslation } from 'react-i18next';
import { Search } from '@material-ui/icons';
import { api } from '../../_helpers/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertActions } from '../../_actions';
import { history } from '../../_helpers';

const CardCategory = ({ url_image, title, description }) => {
  return (
    <Paper elevation={0} className={style.cardCategory}>
      {
        title.length > 40 ?
          <Tooltip
            title={title}
          >
            <Typography variant='h6' sx={{ color: '#106276', textAlign: "center", fontSize: '16px' }}>{String(title).slice(0, 40) + "..."}</Typography>
          </Tooltip>
          :
          <Typography variant='h6' sx={{ color: '#106276', textAlign: "center", fontSize: '16px' }}>{title}</Typography>

      }
      <img src={url_image} alt={title} width={100} height={100} />
      {
        description.length > 50 ?
          <Tooltip
            title={description}
          >
            <Typography variant='body2' sx={{ color: '#106276', textAlign: "center" }}>{String(description).slice(0, 50) + "..."}</Typography>
          </Tooltip>
          :
          <Typography variant='body2' sx={{ color: '#106276', textAlign: "center" }}>{description}</Typography>
      }
    </Paper>
  )
}

function HelpCenterPage(props) {
  const { t, i18n } = useTranslation('common');
  const { alertActions } = props
  const [focusFieldSearch, setFocusFieldSearch] = useState(false)
  const [categoriesTree, setCategoriesTree] = useState([])
  const [fetching, setFetching] = useState(true)
  const [searchField, setSearchField] = useState('')

  const getCategoriesTree = async (queryFilter = '') => {
    setFetching(true)
    await api.get(`/simple/help/categories/tree?lang=${i18n.language}${queryFilter}`)
      .then(({ data }) => {
        setCategoriesTree(data)
        setFetching(false)
      })
      .catch(error => {
        alertActions.error(error)
        setFetching(false)
      })
  }

  const handleSearch = async (e) => {
    e.preventDefault()

    if (!searchField) return

    const queryFilter = `&search=${searchField}`

    await getCategoriesTree(queryFilter)

  }

  const redirect = (cat) => {
    if (cat.children && cat.children.length > 0) {
      return history.push(`/help-center-subcategories/${cat.id}`)
    }
    return history.push(`/help-center-details/${cat.id}`)
  }

  const handleChange = async (e) => {
    const { value } = e.target
    if (value === '') {
      await getCategoriesTree()
      return
    }
    setSearchField(value)
  }

  useEffect(() => {
    getCategoriesTree()
  }, [])

  return (
    <Box className={style.containerPage}>
      <Box component={'header'} className={style.header}>
        <Container maxWidth="md" className={style.containerHeader}>
          <IconLogo />
          <a className={style.button} href='/productive'>
            {t('help_center.btn_to_account')}
          </a>
        </Container>
      </Box>

      <Box component={'section'} className={style.sectionFilter}>
        <Container maxWidth="md" className={style.containerFilter}>
          <h4 className={style.questionHelp}>{t('help_center.hint_helper')}</h4>
          <Paper
            className={focusFieldSearch && style.focusFieldSeach}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 600, height: 50 }}
            elevation={0}
            onSubmit={handleSearch}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={t('help_center.placeholder_search')}
              inputProps={{ 'aria-label': 'Pesquisar...', style: { height: 40, fontSize: '14px', color: '#106276' } }}
              onFocus={() => setFocusFieldSearch(true)}
              onBlur={() => setFocusFieldSearch(false)}
              onChange={handleChange}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <Search />
            </IconButton>
          </Paper>
        </Container>
      </Box>
      {
        !fetching
          ?

          <Container component={'main'} maxWidth="md" className={style.containerMain} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12}>
                {/* <Typography variant='h5' sx={{ color: '#106276', fontWeight: 500 }}>Categorias</Typography> */}
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/help-center">
                    {t('help_center.home')}
                  </Link>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {
                (categoriesTree && categoriesTree.length > 0)
                  ?
                  categoriesTree.map(cat => (
                    <Grid key={cat.id} item xs={12} sm={6} md={4} lg={3} onClick={() => redirect(cat)}>
                      <CardCategory url_image={cat.url_image} title={cat[`title_${i18n.language}`]} description={cat[`description_${i18n.language}`]} />
                    </Grid>
                  ))
                  :
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>{t('help_center.no_category')}</Typography>
                  </Grid>
              }
            </Grid>
          </Container>

          :

          <Container component={'main'} maxWidth="md" className={style.containerMain} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <CircularProgress size={20} />
              </Grid>
            </Grid>
          </Container>
      }
    </Box>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterPage)