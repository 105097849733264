export const shareConstants = {
    OPEN_MODAL: '[SHARE] OPEN_MODAL',
    CLOSE_MODAL: '[SHARE] CLOSE_MODAL',
    OPEN_MODAL_FORM_GOALS: '[SHARE] OPEN_MODAL_FORM_GOALS',
    CLOSE_MODAL_FORM_GOALS: '[SHARE] CLOSE_MODAL_FORM_GOALS',
    OPEN_MODAL_ADD_TEAM: '[SHARE] OPEN_MODAL_ADD_TEAM',
    CLOSE_MODAL_ADD_TEAM: '[SHARE] CLOSE_MODAL_ADD_TEAM',
    OPEN_MODAL_ADD_KEY_RESULT: '[SHARE] OPEN_MODAL_ADD_KEY_RESULT',
    CLOSE_MODAL_ADD_KEY_RESULT: '[SHARE] CLOSE_MODAL_ADD_KEY_RESULT',
    OPEN_MODAL_EDIT_KEY_RESULT: '[SHARE] OPEN_MODAL_EDIT_KEY_RESULT',
    CLOSE_MODAL_EDIT_KEY_RESULT: '[SHARE] CLOSE_MODAL_EDIT_KEY_RESULT',
    GET_FILE_REQUEST: '[SHARE] GET_FILE_REQUEST',
    GET_FILE_SUCCESS: '[SHARE] GET_FILE_SUCCESS',
    GET_FILE_FAILURE: '[SHARE] GET_FILE_FAILURE'
};
