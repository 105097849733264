import React, { useEffect, useState } from 'react';

import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import IconClear from '../../_icons/IconClear';

import moment from 'moment';
import { useDatePickerFilter } from '../../../contexts/DatePickerFilterContext';
import './WhenMonthMulti.scss';

export function WhenMonthMulti(props) {
    const { t, i18n } = useTranslation('common');

    const { startDate, endDate, setStartDate, setEndDate } = useDatePickerFilter()


    const [isCalendarStartOpen, setIsCalendarStartOpen] = useState(false)
    const [isCalendarEndOpen, setIsCalendarEndOpen] = useState(false)
    const [formattedStartDate, setFormattedStartDate] = useState(new Date())
    const [formattedEndDate, setFormattedEndDate] = useState(new Date())


    registerLocale('es', es)
    registerLocale('pt', pt)

    const { userDetails } = props

    let calendarLanguage = 'pt'

    if (userDetails && userDetails.language) {
        calendarLanguage = userDetails.language;
    } else {
        calendarLanguage = window.navigator.userLanguage;
    }

    function handleChangeStart(date) {
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }

    function handleChangeEnd(date) {
        setEndDate(moment(date).format("YYYY-MM-DD"))
    }

    function resetStartDate() {
        const dateNow = moment()
        setStartDate(dateNow.subtract(2, 'months').format('YYYY-MM-DD'))
    }

    function resetEndDate() {
        setEndDate(moment().format("YYYY-MM-DD"))
    }

    useEffect(() => {
        setFormattedStartDate(new Date(`${startDate}T00:00:00`))
        if (moment(startDate).isAfter(endDate)) {
            setEndDate(startDate)
        } else {
            setFormattedEndDate(new Date(`${endDate}T00:00:00`))
        }
    }, [startDate, endDate])

    return (
        <Box display="flex" alignItems="center" gap="60px">
            <Box display="flex" alignItems="center" width="100px">
                <Typography fontSize={14} color="#4F7091" fontWeight="500" style={{ marginRight: "8px" }} component="span">De:</Typography>
                <Box display="flex" position="relative" alignItems="center" className="calendarWrapper">
                    <DatePicker
                        selected={formattedStartDate}
                        onChange={(date) => handleChangeStart(date)}
                        selectsStart
                        startDate={formattedStartDate}
                        dateFormat="MM/yyyy"
                        endDate={formattedEndDate}
                        locale={calendarLanguage}
                        showMonthYearPicker
                        onCalendarOpen={() => setIsCalendarStartOpen(true)}
                        onCalendarClose={() => setIsCalendarStartOpen(false)}
                    />
                    <Box
                        display={isCalendarStartOpen ? "flex" : "none"}
                        position="absolute"
                        top="13px"
                        right="3px"
                        className="iconClose"
                        onClick={() => resetStartDate()}
                    >
                        <IconClear />
                    </Box>
                </Box>

            </Box>

            <Box display="flex" alignItems="center" width="100px">
                <Typography fontSize={14} color="#4F7091" fontWeight="500" style={{ marginRight: "8px" }} component="span">Até:</Typography>
                <Box display="flex" position="relative" alignItems="center" className="calendarWrapper">
                    <DatePicker
                        selected={formattedEndDate}
                        onChange={(date) => handleChangeEnd(date)}
                        selectsEnd
                        startDate={formattedStartDate}
                        endDate={formattedEndDate}
                        dateFormat="MM/yyyy"
                        minDate={formattedStartDate}
                        locale={calendarLanguage}
                        showMonthYearPicker
                        onCalendarOpen={() => setIsCalendarEndOpen(true)}
                        onCalendarClose={() => setIsCalendarEndOpen(false)}
                    />
                    <Box
                        display={isCalendarEndOpen ? "flex" : "none"}
                        position="absolute"
                        top="13px"
                        right="3px"
                        className="iconClose"
                        onClick={() => resetEndDate()}
                    >
                        <IconClear />
                    </Box>
                </Box>

            </Box>

        </Box >

    )
};