import React from 'react';

const IconRoomDay = props => {
    return (


       
<svg id="dia" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="invisible_box" data-name="invisible box">
    <rect id="Retângulo_1582" data-name="Retângulo 1582" width="16" height="16" fill="none"/>
  </g>
  <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 0.659)">
    <path id="Caminho_1449" data-name="Caminho 1449" d="M32,28H30.667a.667.667,0,0,0-.667.667V30a.667.667,0,0,0,.667.667H32A.667.667,0,0,0,32.667,30V28.667A.667.667,0,0,0,32,28Zm0,0H30.667a.667.667,0,0,0-.667.667V30a.667.667,0,0,0,.667.667H32A.667.667,0,0,0,32.667,30V28.667A.667.667,0,0,0,32,28Z" transform="translate(-20.667 -19.326)" fill="#98acc1"/>
    <path id="Caminho_1450" data-name="Caminho 1450" d="M16,3.985H13v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985H7v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985h-3A.667.667,0,0,0,2,4.652V15.318a.667.667,0,0,0,.667.667H16a.667.667,0,0,0,.667-.667V4.652A.667.667,0,0,0,16,3.985Zm-.667,10.667h-12V7.985h12Zm0-8h-12V5.318h12Z" transform="translate(-2 -1.977)" fill="#98acc1"/>
    <rect id="Retângulo_1583" data-name="Retângulo 1583" width="2.667" height="2.667" rx="1.333" transform="translate(9.333 8.674)" fill="#98acc1"/>
  </g>
</svg>




    )
}

export default IconRoomDay;