import { goalsConstants } from '../_constants';
import { goalsService } from '../_services';
import { alertActions } from '.';

export const goalsActions = {
    getGoals,
    getGoalsTeammates
};


function getGoals(startDate, endDate, teams = null, teammates = null, tree = true) {
    return dispatch => {
        dispatch(request());

        goalsService.getGoals(startDate, endDate, teams, teammates, tree)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: goalsConstants.GET_GOALS_REQUEST } }
    function success(payload) {  return { type: goalsConstants.GET_GOALS_SUCCESS, payload } }
    function failure(error) { return { type: goalsConstants.GET_GOALS_FAILURE, error } }
}

function getGoalsTeammates(startDate, endDate, teams = null, teammates = null) {
    return dispatch => {
        dispatch(request());

        goalsService.getGoalsTeammates(startDate, endDate, teams, teammates)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: goalsConstants.GET_GOALS_TEAMMATES_REQUEST } }
    function success(payload) { return { type: goalsConstants.GET_GOALS_TEAMMATES_SUCCESS, payload } }
    function failure(error) { return { type: goalsConstants.GET_GOALS_FAILURE, error } }
}