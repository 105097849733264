import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../../_components/_shared/Toolbar'
import { useTranslation } from "react-i18next";
import { alertActions, settingsActions, teamsActions, usersActions } from '../../_actions';
import { teammatesActions } from '../../_actions';
import { api } from '../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import Filterbar from '../../_components/_shared/Filterbar';
import style from './styles.module.scss'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import StatsCardHeader from '../../_components/extra_collections/StatsCardHeader';
import TableExtraCollections from '../../_components/extra_collections/TableExtraCollections';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import ModalCreateExtraCollection from '../../_components/extra_collections/ModalCreateExtraCollection';
import ModalEditExtraCollection from '../../_components/extra_collections/ModalEditExtraCollection';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  }
}));

function ExtraCollectionsSettingsPage(props) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles()

  const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions, filter, loadingTeams, teams, usersActions, responsibles } = props;
  const [data, setData] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [openModalCreateExtraCollection, setOpenModalCreateExtraCollection] = useState(false);
  const [openModalEditExtraCollection, setOpenModalEditExtraCollection] = useState(false);
  const [extraCollectionToEdit, setExtraCollectionToEdit] = useState(null);

  function handleOpenModalCreateExtraCollection() {
    setOpenModalCreateExtraCollection(true)
  }

  function handleOpenModalEditExtraCollection(e, extraCollection) {
    setOpenModalEditExtraCollection(true)
    setExtraCollectionToEdit(extraCollection)
  }

  function handleCloseModalEditExtraCollection() {
    setOpenModalEditExtraCollection(false)
    setExtraCollectionToEdit(null)
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.extra_collections.table.col_date'),
        accessor: 'date',
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language)
        }
      },
      {
        Header: t('settings.extra_collections.table.col_colabs'),
        accessor: 'users',
        Cell: ({ value }) => {
          const users = value;

          return (
            users.length > 3
              ?

              <Box fontSize={'14px'}>
                <span>{`${users.slice(0, 3).map(u => u.username).join(', ')}`}</span>
                <Tooltip
                  arrow
                  interactive
                  title={
                    <Box>
                      {users.map(u => <Box fontSize={'12px'} fontWeight={'400'} padding={'4px'}>{`${u.username} (${u.email})`}</Box>)}
                    </Box>
                  }
                  placement="right"
                  classes={{ tooltip: classes.customWidth }}
                >
                  <span>{", [...]"}</span>
                </Tooltip>
              </Box>
              :
              <Box fontSize={'14px'}>
                {users.map(u => u.username).join(', ')}
              </Box>
          )
        },
      },
      {
        Header: t('settings.extra_collections.table.col_period'),
        accessor: 'period'
      },
      {
        Header: t('settings.extra_collections.table.col_created_at'),
        accessor: 'created_at',
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language, 'T')
        }
      }
    ];
  }, []);


  const getDataExtraCollections = async () => {
    try {
      setFetching(true)

      const res = await api.get(`/settings/extra-collection`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get teams')
      }

      setData(res.data)

    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    getDataExtraCollections()
    usersActions.getResponsibles(userDetails?.organizationId)
  }, [])

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.extra_collections');
  }, []);


  return (
    <React.Fragment>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          showActionCreateExtraCollection={true}
          handleOpenModalCreateExtraCollection={handleOpenModalCreateExtraCollection}
        />

        <div className={style.container}>
          {
            !fetching
              ?
              <>
                <StatsCardHeader stats={data ? data.stats : {}} />
                <TableExtraCollections
                  columns={columns}
                  data={data ? data.data : []}
                  handleOpenModalEditExtraCollection={handleOpenModalEditExtraCollection}
                  getData={getDataExtraCollections}
                  alertActions={alertActions}
                  userDetails={userDetails}
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
      </section>

      <ModalCreateExtraCollection
        openModalCreateExtraCollection={openModalCreateExtraCollection}
        setOpenModalCreateExtraCollection={setOpenModalCreateExtraCollection}
        userDetails={userDetails}
        colabsOptions={responsibles}
        alertActions={alertActions}
        updateInfo={getDataExtraCollections}
      />

      {
        extraCollectionToEdit &&
        <ModalEditExtraCollection
          openModalEditExtraCollection={openModalEditExtraCollection}
          setOpenModalEditExtraCollection={setOpenModalEditExtraCollection}
          userDetails={userDetails}
          colabsOptions={responsibles}
          alertActions={alertActions}
          updateInfo={getDataExtraCollections}
          extraCollectionToEdit={extraCollectionToEdit}
          handleCloseModalEditExtraCollection={handleCloseModalEditExtraCollection}
        />
      }

    </React.Fragment>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)

  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,
    teams: state.teams.payload,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraCollectionsSettingsPage)
