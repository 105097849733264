import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Teams from '../_components/settings/teams/Teams'
import TeamsSkeleton from '../_components/settings/teams/TeamsSkeleton'
import DrawerCreate from '../_components/settings/teams/DrawerCreate'
import DrawerInvite from '../_components/settings/teams/DrawerInvite'
import DrawerEdit from '../_components/settings/teams/DrawerEdit'


import { useTranslation } from "react-i18next";

import { alertActions, settingsActions, teamsActions } from '../_actions';
import { teammatesActions } from '../_actions';

import IconClose from '../_components/_icons/IconClose';
import FilterbarTeams from '../_components/_shared/FilterbarTeams';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';
import AlertBox from '../_components/_shared/AlertBox';

function CompanyTeamsPage(props) {
	const { t } = useTranslation('common');

	const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions } = props;


	let loadingTeams = props.loadingTeams;
	let filter = props.filter || {};


	const [drawer, setDrawer] = useState(false)
	const [isShowingDrawerCreate, setDrawerCreate] = useState(false)
	const [isShowingDrawerInvite, setDrawerInvite] = useState(false)
	const [isShowingDrawerEdit, setDrawerEdit] = useState(false)
	const [inviteCurrent, setInviteCurrent] = useState()
	const [editCurrent, setEditCurrent] = useState()
	const [inactiveTeammates, setInactiveTeammates] = useState([])
	const [isTeamsSelected, setIsTeamsSelected] = useState(true)
	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)
	const [value, setValue] = useState('1');

	const handleChangeValue = (event, newValue) => {
			setValue(newValue);
	};

	function openDrawer(data) {

		setDrawer(true)

		if (data.type == "create") {
			setDrawerCreate(true)
			setDrawerInvite(false)
			setDrawerEdit(false)
			setInviteCurrent()
			setEditCurrent()
		}

		if (data.type == "invite") {
			setDrawerInvite(true)
			setDrawerCreate(false)
			setDrawerEdit(false)
			setInviteCurrent(data)
			setEditCurrent()
		}
		
		if (data.type == "edit") {
			teammatesActions.getTeammate(data.id)
			setDrawerInvite(false)
			setDrawerCreate(false)
			setDrawerEdit(true)
			setInviteCurrent()
			setEditCurrent(data)
			setValue("1")
		}

	}

	function closeDrawer() {
		setDrawer(false)
		setDrawerCreate(false)
		setDrawerInvite(false)
		setDrawerEdit(false)
		setInviteCurrent(null)
		setEditCurrent(null)
	}

	async function getInactiveTeammates() {
		try {
			const response = await api.get(
				`/teammates/inactive_teammates?organization_id=${userDetails.organizationId}`,
				{
					headers: authHeader()
				}
			)

			setInactiveTeammates(response.data)
		} catch (error) {
			console.log(error)
			//alertActions.error(t('teams.get_inactive_erro'))
		}
	}

	useEffect(() => {
		settingsActions.getCompanyInformation()
		teamsActions.getTeams()
		teammatesActions.getTeammates()
		getInactiveTeammates()		
	}, [])


	useEffect(() => {
		if (filter.teams) {
			teamsActions.getTeams(filter.teams)
		} else {
			teamsActions.getTeams()
		}

	}, [filter.teams]);


	useEffect(() => {
		document.title = 'Befective | ' + t('menu.teams');
	}, []);

	return (
		<React.Fragment>
			{/* <AlertBox /> */}

			<section className="mainContent">
				<Toolbar />
				<FilterbarTeams />
				{/* PAGE STARTS */}

				<div className="mainPage profile">
					<div className="nocolumn"style={{background: 'none'}}>
						<div className={'tab full active'}>
							{
								!minimumTimeElapsed || loadingTeams
									? (
										<TeamsSkeleton />
									) : (
										<Teams
											openDrawer={(data) => {
												openDrawer(data)
											}}
											isTeamsSelected={isTeamsSelected}
											setIsTeamsSelected={setIsTeamsSelected}
											inactiveTeammates={inactiveTeammates}
											setValue={setValue}
											teamsActions={teamsActions}
											teammatesActions={teammatesActions}
											companyInformation={props.companyInformation}
										/>
									)
							}
						</div>
					</div>
				</div>
				{/* PAGE ENDS */}
			</section>

			<div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
				setDrawer(false)
			}}></div>

			<section className={'drawer drawer600' + (drawer ? ' open' : '')}>
				<button onClick={() => {
					setDrawer(false)
					if(!isTeamsSelected)
						setValue("1")
				}} className="close"><IconClose /></button>
				{(isShowingDrawerInvite && props.companyInformation) &&
					<DrawerInvite 
						current={inviteCurrent} 
						closeDrawer={() => {
							closeDrawer()
						}} 
						companyInformation={props.companyInformation}
						getCompanyInformation={settingsActions.getCompanyInformation}
						/>
				}

				{isShowingDrawerCreate &&
					<DrawerCreate closeDrawer={() => {
						closeDrawer()
					}} />
				}

				{isShowingDrawerEdit &&
					<DrawerEdit 
						userDetails={userDetails}
						current={editCurrent} 
						setInactiveTeammates={setInactiveTeammates} 
						inactiveTeammates={inactiveTeammates} 
						isTeamsSelected={isTeamsSelected} 
						value={value} 
						handleChangeValue={handleChangeValue} 
						closeDrawer={() => {
							closeDrawer()
						}}
						getCompanyInformation={settingsActions.getCompanyInformation}
						/>
				}

			</section>

		</React.Fragment>
	)
};

function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch),
		settingsActions: bindActionCreators(settingsActions, dispatch)
	}
}

function mapStateToProps(state) {

	return {
		token: state.authentication.user.accessToken,
		loadingTeams: state.teams.loading,
		loadingTeammates: state.teammates.loading,
		filter: state.filter,
		permissions: state.authentication.permissions,
		userDetails: state.authentication.userDetails,
		companyInformation: state.settings.companyInformation,
		
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTeamsPage)
