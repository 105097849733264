import React from 'react'
import style from './styles.module.scss';
import { Box, Skeleton } from '@mui/material';

export default function Widget26Skeleton() {

  return (
    <div className={style.container}>
      <Box display={'flex'} flexDirection={'column'} gap={'18px'}>
        <Skeleton variant='h6' width={'30%'} />

        <Box display={'flex'} gap={'6px'}>
          <Skeleton variant="rectangular" width={'20%'} height={20} />
          <Skeleton variant="rectangular" width={'20%'} height={20} />
          <Skeleton variant="rectangular" width={'20%'} height={20} />
        </Box>
        <Skeleton variant="rectangular" width={'100%'} height={10} />
      </Box>

      <Box display={'flex'} gap={'6px'}>
        <Skeleton variant="rectangular" width={'100%'} height={400} />
      </Box>
    </div>
  )
}
