import { Close } from '@material-ui/icons'
import { Avatar, Box, Drawer, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { Paperclip } from 'phosphor-react'
import React, { Fragment } from 'react'

import { befectiveFormatDate, getAvatarUrl, getLabelReasonAdjust } from '../../../_helpers'
import IconOut from '../../_icons/IconOut'

import { useTranslation } from 'react-i18next'


export function DrawerSolicitationsControlPoints({
  userDetails,
  solicitations,
  openDrawerSolicitations,
  setOpenDrawerSolicitations,
}) {

  const {t, i18n} = useTranslation('common');

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  if (lang === 'pt' || lang === 'es') {
    moment.locale('pt-br')
  } else {
    moment.locale('en-us')
  }



  function groupByMonth(data) {
    let grouped = {};
    data.forEach(item => {
      let date = moment(item.moment_solicitation);
      let yearMonth = date.format('MMMM YYYY');  // Formato: Mês Ano

      if (!grouped[yearMonth]) {
        grouped[yearMonth] = [];
      }

      grouped[yearMonth].push(item);
    });
    return grouped;
  }

  let groupedData = groupByMonth(solicitations);

  const dates = Object.keys(groupedData)

  return (
    <Drawer
      anchor="right"
      open={openDrawerSolicitations}
      onClose={() => setOpenDrawerSolicitations(false)}
      PaperProps={{
        style: {
          padding: '0px 12px',
          width: '350px'
        }
      }}
    >
      <div style={{ position: 'absolute', right: 4, top: 4 }}>
        <IconButton aria-label="close" onClick={() => setOpenDrawerSolicitations(false)}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" mb={3}>
            <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>{t('checkin.drawer_solicitations.solicitations')}</Typography>
          </Box>

          {dates.map(date => (
            <Box key={`${date}`} display="flex" flexDirection="column">
              <Box display="flex" bgcolor="#F5F6F9" paddingX={2} paddingY={1} borderRadius="4px">
                <Typography color="#98ACC1" fontSize={12} textTransform="capitalize">{date}</Typography>
              </Box>

              {groupedData[date].map(solicitation => (
                <Fragment key={solicitation.id}>
                  {solicitation.status !== "WAITING" && (
                    <Box display="flex" flexDirection="column" mt={2} borderBottom="1px solid #E4EAEF" pb={2} >
                      <Box display="flex" alignItems="flex-start" gap="12px" paddingX={2}>
                        <Box mt={1}>
                          <IconOut
                            fill={solicitation.status === "APPROVED" ? "#187DFF" : "#FF5151"}
                          />
                        </Box>
                        <Box display="flex" flexDirection="column" gap="3px">
                          <Typography color="#4F7091" fontSize={10}>
                            {t('checkin.drawer_solicitations.date_solicitation')}{': '}{befectiveFormatDate(solicitation.moment_solicitation, i18n.language, 'T')}
                          </Typography>

                          {solicitation.points_id !== null ? (
                            <Typography
                              component="span"
                              lineHeight={1.3}
                              fontSize={14}
                              color={solicitation.status === "APPROVED" ? "#187DFF" : "#FF5151"}
                            >
                              {t('checkin.drawer_solicitations.adjust_of')}
                              <Typography
                                component="span"
                                color={solicitation.status === "APPROVED" ? "#187DFF" : "#FF5151"}
                                lineHeight={1}
                                fontSize={12}
                                fontWeight={700}
                              >
                                {' '}
                                {solicitation.point_type === 'IN' ? t('checkin.drawer_solicitations.input') : t('checkin.drawer_solicitations.output')} {t('checkin.drawer_solicitations.of_day')}
                                {' '}{befectiveFormatDate(solicitation.point_day_ref, lang, "S")}
                                {' '}
                              </Typography>
                              {t('checkin.drawer_solicitations.from')} {String(solicitation.point_time).slice(0, -3)} {t('checkin.drawer_solicitations.to')} {solicitation.time} {t('checkin.drawer_solicitations.was')} {solicitation.status === "APPROVED" ? t('checkin.drawer_solicitations.approved') : t('checkin.drawer_solicitations.refused')}.
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              lineHeight={1.3}
                              fontSize={14}
                              color={solicitation.status === "APPROVED" ? "#187DFF" : "#FF5151"}
                            >
                              {t('checkin.drawer_solicitations.adjust_point_of_day')}
                              <Typography
                                component="span"
                                color={solicitation.status === "APPROVED" ? "#187DFF" : "#FF5151"}
                                lineHeight={1}
                                fontSize={12}
                                fontWeight={700}
                              >
                                {' '}
                                {befectiveFormatDate(solicitation.point_day_ref, lang, "S")}
                                {' '}
                              </Typography>
                              {t('checkin.drawer_solicitations.to_the')}  {solicitation.time} {t('checkin.drawer_solicitations.was')}  {solicitation.status === "APPROVED" ? t('checkin.drawer_solicitations.approved') : t('checkin.drawer_solicitations.refused')}.
                            </Typography>
                          )}

                        </Box>
                      </Box>

                      {solicitation.extra_comment !== null && (
                        <Box display="flex" gap="6px" paddingX={2} mt={2} alignItems="center">
                          <Avatar
                            alt={solicitation.responsibles[0].username}
                            src={
                              getAvatarUrl({
                                name: solicitation.responsibles[0].username
                              },
                                "187DFF"
                              )
                            }
                            style={{ width: 16, height: 16 }}
                          />

                          <Typography fontSize={12} color="#4F7091" fontStyle="italic">
                            {`"${solicitation.extra_comment}"`}
                          </Typography>
                        </Box>
                      )}

                      {/* {solicitation.status === "DINIED" && (
                        <Box display="flex" gap="8px" paddingX={2} mt={2} alignItems="center" className={style.sendAgain}>
                          <ArrowsCounterClockwise size={14} weight='bold' color="#187DFF" />
                          <Typography
                            fontSize={12}
                            color="#187DFF"
                            fontStyle="italic"
                            sx={{ cursor: 'pointer' }}
                          >
                            {t('checkin.drawer_solicitations.again')} 
                          </Typography>
                        </Box>
                      )} */}
                    </Box>
                  )}

                  <Box display="flex" flexDirection="column" mt={2} borderBottom="1px solid #E4EAEF" pb={2} >
                    <Box display="flex" alignItems="flex-start" gap="12px" paddingX={2}>
                      <Box mt={1}>
                        <IconOut fill="#187DFF" />
                      </Box>
                      <Box display="flex" flexDirection="column" gap="3px">
                        <Typography color="#4F7091" fontSize={10}>
                        {t('checkin.drawer_solicitations.date_solicitation')}{': '}{befectiveFormatDate(solicitation.moment_solicitation, i18n.language, 'T')}
                        </Typography>

                        {solicitation.points_id !== null ? (
                          <Typography component="span" lineHeight={1.3} fontSize={14} color="#4F7091">
                            {t('checkin.drawer_solicitations.soli_adjust_of')}
                            <Typography component="span" color="#4F7091" lineHeight={1} fontSize={12} fontWeight={700}>
                              {' '}
                              {solicitation.point_type === 'IN' ? t('checkin.drawer_solicitations.input') : t('checkin.drawer_solicitations.output')} {t('checkin.drawer_solicitations.of_day')}
                              {' '}{befectiveFormatDate(solicitation.point_day_ref, lang, "S")}
                              {' '}
                            </Typography>
                            {t('checkin.drawer_solicitations.from')} {String(solicitation.point_time).slice(0, -3)} {t('checkin.drawer_solicitations.to')} {solicitation.time} {t('checkin.drawer_solicitations.was_send')}.
                          </Typography>
                        ) : (
                          <Typography component="span" lineHeight={1.3} fontSize={14} color="#4F7091">
                            {t('checkin.drawer_solicitations.soli_adjust_of_point')}
                            <Typography component="span" color="#4F7091" lineHeight={1} fontSize={12} fontWeight={700}>
                              {' '}
                              {befectiveFormatDate(solicitation.point_day_ref, lang, "S")}
                              {' '}
                            </Typography>
                            {t('checkin.drawer_solicitations.to_the')} {solicitation.time} {t('checkin.drawer_solicitations.was_send')}.
                          </Typography>
                        )}

                        <Box display="flex" gap={4} maxWidth="200px" mt={3} flexWrap="wrap">
                          <Box display="flex" flexDirection="column">
                            <Typography component="span" fontSize={12} color="#4F7091">{t('checkin.drawer_solicitations.period')}</Typography>
                            <Typography component="span" fontWeight={400} fontSize={14} color="#4F7091">
                              {solicitation.periods[0]?.start_time.slice(0, -3)} - {solicitation.periods[0]?.end_time.slice(0, -3)}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography component="span" fontSize={12} color="#4F7091">{t('checkin.drawer_solicitations.horary')}</Typography>
                            <Typography component="span" fontWeight={400} fontSize={14} color="#4F7091">{solicitation.time}</Typography>
                          </Box>
                        </Box>

                        <Box display="flex" flexDirection="column" mt={1}>
                          <Typography component="span" fontSize={12} color="#4F7091">{t('checkin.drawer_solicitations.reason')}</Typography>
                          <Typography component="span" fontWeight={400} fontSize={14} color="#4F7091">{getLabelReasonAdjust(solicitation)}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    {solicitation.comment !== "" && (
                      <Box display="flex" gap="6px" paddingX={2} mt={2} alignItems="center">
                        <Avatar
                          alt={userDetails.name}
                          src={
                            getAvatarUrl({
                              name: userDetails.name
                            },
                              "FDA02B"
                            )
                          }
                          style={{ width: 16, height: 16 }}
                        />
                        <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                      </Box>
                    )}

                    {solicitation.attachments.length > 0 && (
                      <Box
                        display="flex"
                        gap="8px"
                        paddingX={2}
                        mt={2}
                        alignItems="center"
                        component="a"
                        href={solicitation.attachments[0].url_attachment}
                        sx={{ textDecoration: 'none' }}
                        download
                      >
                        <Paperclip size={14} weight='bold' color="#187DFF" />
                        <Typography fontSize={12} color="#187DFF" fontStyle="italic" sx={{ cursor: 'pointer' }}>
                          {solicitation.attachments[0].original_name}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Fragment>
              ))}
            </Box>
          ))}

          {dates.length === 0 && (
            <Box display="flex" py="16px" borderTop="1px solid #E4EAEF" bgcolor="#fff" flexDirection="column" alignItems="center" justifyItems="center">
              <Typography fontSize={16} fontWeight={500} color="#4F7091">
              {t('checkin.drawer_solicitations.none_solicitation')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

    </Drawer>
  )
}
