import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'

import { teamsActions, usersActions } from '../../_actions'
import moment from 'moment'
import { useState } from 'react'
import { api } from '../../_helpers/api'
import Select from 'react-select'
import { Avatar, Divider, Tooltip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { authHeader } from '../../_helpers'


function DrawerMovimentations({ keyResults, teammates, users, ...props }) {

	const { t } = useTranslation('common')

	const { userDetails } = props || {};
	const [movements, setMovements] = useState([]);
	const [responsible, setResponsible] = useState({})
	const [keyResultsFilter, setKeyResultsFilter] = useState([])
	const [isLoading, setIsLoading] = useState(true);

	const movementsMoc = [1, 2, 3]

	const keyResultsOptions = keyResults?.map(item => {
		return {
			label: item.title,
			value: item.id
		}
	})

	const token = authHeader()

	const handleChangeTemmates = (e) => {
		setResponsible({
			id: e.value,
			type: e.type
		});
	}

	const handleChangeKeyResult = (item) => {
		let keyResultsSelected = item.reduce((acc, kr) => {
			return [...acc, kr.value]
		}, [])
		setKeyResultsFilter(keyResultsSelected);
	}

	const getMovements = async () => {

		try {
			setIsLoading(true);
			let filterTeammateParam = 5
			let filterTypeUser = 'U'
			let filterKeyResultParam = ''

			if (keyResultsFilter?.length > 0) {
				keyResultsFilter.forEach((krId) => {
					filterKeyResultParam += `${krId},`
				})
				filterKeyResultParam = filterKeyResultParam.slice(0, -1)
			}
			if (responsible) {
				filterTeammateParam = responsible?.id || userDetails?.id
				filterTypeUser = responsible?.type || 'U'
			}

			const response = await api.get(`/goals/movements/?organization_id=${userDetails?.organizationId}&owner_id=${filterTeammateParam}&owner_type=${filterTypeUser}&kr_ids=${filterKeyResultParam}`, {
				headers: {
					'Authorization': token.Authorization
				}
			});
			if(response.status === 200){
				setMovements(response?.data)
			}else{
				setMovements([]);
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error.response)
		}

	}

	useEffect(() => {
		getMovements()
	}, [keyResultsFilter, responsible]);

	return (
		<div className="containerMovimentations">

			<header>
				<h3>{t('drawerMovements.label')}</h3>
				<div className='groupSelectsFilter'>

					<div className="field">
						<Select
							id='teammates'
							defaultValue={''}
							name="responsable"
							options={teammates}
							className="basic-multi-select"
							classNamePrefix="select"
							placeholder={t('drawerMovements.select_teammates')}
							onChange={(e) => handleChangeTemmates(e)}
						/>
					</div>

					<div className="field">
						<Select
							id='keyresult-filter'
							defaultValue={keyResultsFilter}
							isMulti
							name="keyresult"
							options={keyResultsOptions}
							className="basic-multi-select"
							classNamePrefix="selectKr"
							placeholder={t('drawerMovements.select_keyresults')}
							onChange={(item) => handleChangeKeyResult(item)}
						/>
					</div>
				</div>
			</header>
			{
				isLoading

					?

					<section className='contentMovimentations'>
						{
							movementsMoc?.length > 0
								?
								(
									movementsMoc?.map((movement, index) => (
										<div className='movimentation' key={index}>
											<div className='movimentationHeader'>
												<Skeleton width="30%" style={{ marginLeft: 16 }} />
											</div>
											<div className='movimentationItem'>
												<div>
													<div className='avatarUserBox'>
														<Skeleton variant="circle">
															<Avatar />
														</Skeleton>
													</div>

													<div className='boxTextMovimentation'>
														<p><Skeleton width="280px" height={30} /></p>
														<div className='boxFooter'>
															<div><Skeleton width="80px" /></div>
															<div><Skeleton width="100px" /></div>
														</div>
													</div>
												</div>
											</div>
										</div>

									))
								)
								:
								(
									<div className='movimentation'>

										<div className='movimentationItem'>
											<div className='boxTextMovimentation' style={{ width: '100%' }}>
												<div className='boxFooter' style={{ justifyContent: "center" }}>
													<div>{t('drawerMovements.no_activities')}</div>
												</div>
											</div>
										</div>

									</div>
								)
						}


					</section>
					:

					<section className='contentMovimentations'>
					
						{							
							movements?.length > 0
								?
								(
									movements?.map((movement, index) => (
										<div className='movimentation' key={index}>
											<div className='movimentationHeader'>
												<h3>{new Date().toLocaleDateString() === new Date(movement.day).toLocaleDateString() ? t('drawerMovements.today'): new Date(movement.day).toLocaleDateString() }</h3>
											</div>
											{
												movement?.movements?.length > 0 && (
													movement?.movements?.map((movementItem, index) => (
														<div className='movimentationItem' key={index}>
															<div>
																<div className='avatarUserBox'>
																	<div className="avatarUser">
																		<img src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(movementItem?.username)} />
																	</div>
																</div>

																<div className='boxTextMovimentation'>
																	<p dangerouslySetInnerHTML={{ __html: movementItem?.title_pt }}></p>
																	<div className='boxFooter'>
																		<div>{new Date(movementItem?.datetime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
																		<Tooltip 
																		title={movementItem?.breadcrumb} >
																			<div>{String(movementItem?.breadcrumb).length >= 51 ? String(movementItem?.breadcrumb).slice(0,50)+"..." : String(movementItem?.breadcrumb)}</div>
																		</Tooltip>
																		
																	</div>
																</div>
															</div>
															<Divider light />
														</div>
													))
												)
											}
										</div>

									))
								)
								:
								(
									<div className='movimentation'>

										<div className='movimentationItem'>
											<div className='boxTextMovimentation' style={{ width: '100%' }}>
												<div className='boxFooter' style={{ justifyContent: "center" }}>
													<div>{t('drawerMovements.no_activities')}</div>
												</div>
											</div>
										</div>

									</div>
								)
						}


					</section>
			}
		</div>
	)
}

function mapStateToProps(state) {
	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		created: state.teams.created,
	}

}


function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch)
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerMovimentations)



