import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { authHeader, getDayWeek, history } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { ModalConfirm } from './ModalConfirm';

import { AttachFile, Close } from '@material-ui/icons';
import style from './Form.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import SelectResponsibles from '../../../pages/CheckinSettings/SelectResponsibles';
import SpaceMapEditor from '../SpaceMapEditor';
import SimpleSubspaceCreate from '../SimpleSubspaceCreate';
import { Box, Grid, Button, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import Switch from "react-switch";


export function Form({ userDetails, teammates, optionsGroups, alertActions, roomToEdit, getDetailSpace }) {

  // const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [attributes, setAttributes] = useState([])

  const [groups, setGroups] = useState([])

  const [shapesOp, setShapesOp] = useState([]);

  let formSchema = yup.object({
    name: yup.string().required(t('spaces.required_field')),
    description: yup.string(),
    maxTimeReservation: yup.number().required(t('spaces.required_field')),
    responsible: yup.number().positive(t('spaces.select_responsible')).required(t('spaces.required_field'))
  });

  const { register, handleSubmit, watch, setValue, formState: { errors }, setError, getValues } = useForm({
    defaultValues: {
      name: roomToEdit?.name || '',
      file: roomToEdit?.images?.length > 0 ? roomToEdit?.images : [],
      description: roomToEdit?.description || '',
      items: roomToEdit?.attributes?.length > 0 ? roomToEdit?.attributes?.map(a => a.id) : [],
      maxTimeReservation: roomToEdit?.time || 0,
      maxPerson: roomToEdit?.capacity || 0,
      responsible: roomToEdit?.responsible_id || null,
      interval: roomToEdit?.interval || 0,
      daysAvailable: roomToEdit?.days_available || [1, 2, 3, 4, 5],
      openTime: roomToEdit?.open_time || '08:00',
      closedTime: roomToEdit?.closed_time || '18:00',
      accessLevel: optionsGroups.filter((item) => roomToEdit?.access_level?.includes(item.value)) || [],
      addSubspaces: !roomToEdit?.sub_space_type ? false : true,
      sub_space_type: roomToEdit?.sub_space_type !== null ? roomToEdit?.sub_space_type : 'shape',
      shapes: roomToEdit?.map_representation?.shapes || [],
      subspaces: roomToEdit?.sub_spaces || []
    },
    resolver: yupResolver(formSchema)
  });

  console.log("/VALUEs",getValues())
  const { description, file, items, maxPerson, maxTimeReservation, name, responsible, interval, daysAvailable, openTime, closedTime, accessLevel, addSubspaces, sub_space_type, shapes, subspaces } = watch()

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false)
  }

  function handleChangeDate(e) {
    const newDate = moment(e.$d).format().split("T")[0];

    setValue('date', newDate)
  }

  function handleAddOrRemoveItem(newItem) {
    const checkIfExists = items.find(item => item === newItem)

    if (checkIfExists) {
      const itemsFiltered = items.filter(item => item !== newItem)
      setValue('items', [...itemsFiltered])
    } else {
      setValue('items', [...items, newItem])
    }
  }

  function handleAddOrRemoveDaysAvailable(day) {
    const checkIfExists = daysAvailable.filter(item => item === day)

    if (checkIfExists.length > 0) {
      const daysFiltered = daysAvailable.filter(item => item !== day)
      setValue('daysAvailable', [...daysFiltered])
    } else {
      setValue('daysAvailable', [...daysAvailable, day])
    }
  }


  async function getRoomAttributes() {
    try {
      const response = await api.get('/rooms/attributes', {
        headers: authHeader()
      })
      if (items?.length === 0) {
        setValue('items', response.data.filter(t => t.pre_selected === true).map(t2 => t2.id))
      }
      setAttributes(response.data)
    } catch (error) {
      console.log(error.response)
    }
  }

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);
    setValue('file', filesArr[0])
  }

  const removeFile = (file) => {
    setValue('file', '')
  }

  const getShapes = async () => {
    try {
      const res = await api.get(`/rooms/maps/shapes`, { headers: authHeader() })

      if (res.status === 200) {
        setShapesOp(res.data)
      }

    } catch (error) {
      console.log(error)
    }
  };

  const onSubmit = async (data) => {
    if (data.daysAvailable.length === 0) {
      setError('daysAvailable', { type: 'custom', message: 'Selecione um dia' });
      return
    }

    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('name', data?.name);
      formData.append('description', data?.description);
      formData.append('capacity', data?.maxPerson);
      formData.append('time', data?.maxTimeReservation);
      formData.append('interval', data?.interval);
      formData.append('responsible_id', data?.responsible);
      formData.append('open_time', data?.openTime);
      formData.append('closed_time', data?.closedTime);
      formData.append('attribute_ids', JSON.stringify(data?.items));
      formData.append('days_available', JSON.stringify(data?.daysAvailable));
      formData.append('sub_spaces_shapes', JSON.stringify(data?.shapes));
      formData.append('sub_spaces', JSON.stringify(data?.subspaces));
      formData.append('has_sub_spaces', data?.addSubspaces);
      formData.append('sub_space_type', data?.sub_space_type);
      formData.append('access_level', groups.reduce((acum, curr) => {
        return [...acum, curr.value]
      }, []));
      formData.append('files', file.length > 0 ? file[0] : null)


      if (!roomToEdit) {
        const resCreate = await api.post('/rooms', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': authHeader().Authorization
            }
          })

        if (resCreate.status !== 200) {
          throw new Error(t('spaces.errors.create'))
        }
        alertActions.success(t('spaces.success.create'))
        history.push('/spaces/management')
        setIsSubmitting(false)
        return
      }

      const resEdit = await api.put(`/rooms/${roomToEdit?.id}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          }
        })

      if (resEdit.status !== 200) {
        throw new Error(t('spaces.errors.update'))
      }

      alertActions.success(t('spaces.success.update'))
      history.push('/spaces/management')
      setIsSubmitting(false)

    } catch (error) {
      //alertActions.error(error)
      console.error(error)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    getShapes()
    getRoomAttributes()
  }, [])

  return (

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              {
                !roomToEdit && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                  {t('spaces.new_room')}
                </Typography>
              }
              {
                roomToEdit && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                  {t('spaces.update_room')}
                </Typography>
              }

            </Box>

            <Box display="flex" alignItems="center" gap="8px">

              <Button
                type="button"
                style={{ minWidth: '100px', backgroundColor: "#E3EFFF", color: "#187DFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
                onClick={() => history.push('/spaces/management')}
              >
                {t('spaces.back')}
              </Button>
              {
                roomToEdit
                  ?
                  <Button
                    type="submit"
                    style={{ minWidth: '100px', backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
                    disabled={isSubmitting}
                  >
                    {t('spaces.update')}
                  </Button>
                  :
                  <Button
                    type="submit"
                    style={{ minWidth: '100px', backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
                    disabled={isSubmitting}
                  >
                    {t('spaces.save')}
                  </Button>
              }

            </Box>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.name_room')}</Typography>
            </Box>

            <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
              <Box display={'flex'} width={'100%'} gap={'8px'}>
                <Box display="flex" flexDirection="column" gap="4px" width="100%">
                  <TextField
                    name="name"
                    label={t('spaces.name_room')}
                    placeholder=''
                    error={false}
                    helperText={""}
                    {...register('name')}
                    sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                  >
                  </TextField>

                  {errors.name && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.name.message}
                    </Typography>
                  )}
                </Box>

                <Box display="flex" alignItems="center" flexDirection="column" width="100%">

                  <label className={style.inputFileBox}>
                    <input
                      type="file"
                      onChange={onChangeInputFile}
                      {...register('file')}
                    // disabled={form.files.length > 0}
                    />
                    <AttachFile fontSize='small' />
                    <div style={{ color: '#205B6F' }}>
                      {file.length === 0 ? t('spaces.file') : file[0].name}
                    </div>
                  </label>

                  {errors.file && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.file.message}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display="flex" justifyContent={'flex-end'} gap="10px" width="100%">
                {file.length > 0 && (

                  <div className={style.filePreview}>
                    <div>{file[0].name}</div>
                    <div>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => removeFile(file[0])}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </div>
                  </div>
                )}
              </Box>

            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.columns.description')}</Typography>
            </Box>

            <TextField
              name="description"
              label={t('spaces.columns.description')}
              placeholder=''
              error={false}
              helperText={""}
              {...register('description')}
              sx={{ width: '50%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
            >
            </TextField>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.item')}</Typography>
            </Box>

            <Box display="flex" gap="4px" p="3px" minHeight="38px" width="50%" borderRadius="2px" flexWrap={'wrap'}>
              {attributes.map(item => (
                <Box
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor={items.includes(item.id) ? "#187DFF" : "#F5F6F9"}
                  border={`1px solid ${items.includes(item.id) ? "#187DFF" : "#D6DEEB"}`}
                  px="20px"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAddOrRemoveItem(item.id)}
                  minHeight={'36px'}
                >
                  <Typography color={items.includes(item.id) ? "#FFFFFF" : "#1E6B7F"} fontSize={14} component="span">
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.available_times_form')}</Typography>
            </Box>

            <Box display="flex" gap="8px" p="3px" width="50%" borderRadius="2px" flexWrap={'wrap'} flexDirection={'column'}>
              <Box display="flex" flexDirection="column" gap="4px" width="100%">

                <Box className="daysAvailableBox" display="flex" gap="4px" flexWrap={'wrap'}>
                  {[0, 1, 2, 3, 4, 5, 6].map(day => (
                    <Box
                      key={day}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={'38px'}
                      bgcolor={daysAvailable.includes(day) ? "#187DFF" : "#F5F6F9"}
                      border={`1px solid ${daysAvailable.includes(day) ? "#187DFF" : "#D6DEEB"}`}
                      px="20px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleAddOrRemoveDaysAvailable(day)}
                    >
                      <Typography color={daysAvailable.includes(day) ? "#FFFFFF" : "#1E6B7F"} fontSize={14} component="span">
                        {getDayWeek(day, true)}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                {errors.daysAvailable && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.daysAvailable.message}
                  </Typography>
                )}
              </Box>

              <Box className={'periodTime'}>
                <TimePicker
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label={"Abre às"}
                  value={dayjs('2022-01-01 ' + openTime)}
                  onChange={(newValue) => setValue('openTime', dayjs(newValue).format('HH:mm'))}
                  renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 4 }} {...params} />}
                />
                <TimePicker
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label={"Fecha às"}
                  value={dayjs('2022-01-01 ' + closedTime)}
                  onChange={(newValue) => setValue('closedTime', dayjs(newValue).format('HH:mm'))}
                  renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 4 }} {...params} />}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.max_time')}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="8px" width="50%">
              <TextField
                select
                name="maxTimeReservation"
                label={t('spaces.max_time')}
                error={false}
                helperText={""}
                {...register("maxTimeReservation")}
                sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                value={maxTimeReservation}
              >
                <MenuItem value="" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                  {t('spaces.select')}
                </MenuItem>

                <MenuItem value={0} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                  0 min
                </MenuItem>

                {Array.from(Array(60 / 15)).map((_, index) => (
                  <MenuItem key={index} value={(index + 1) * 15} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                    {(index + 1) * 15} min
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                type='number'
                name="interval"
                label={t('spaces.interval') + " " + t('spaces.interval_placeholder')}
                InputProps={{ inputProps: { min: 0 } }}
                error={false}
                helperText={""}
                placeholder={t('spaces.interval_placeholder')}
                sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                disabled={maxTimeReservation === "" || maxTimeReservation === 0}
                value={interval}
                {...register('interval')}
              >
              </TextField>
            </Box>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.max_persons')}</Typography>
            </Box>

            <TextField
              name="maxPerson"
              label={t('spaces.max_persons')}
              placeholder='0'
              error={false}
              type="number"
              helperText={""}
              min="0"
              {...register('maxPerson')}
              sx={{ width: 'calc(25% - 5px)', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
              value={maxPerson}
            >
            </TextField>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.responsible')}</Typography>
            </Box>

            <Box display="flex" gap="8px" width="50%">
              <Box display="flex" flexDirection="column" gap="4px" width="100%">
                <TextField
                  select
                  name="responsible"
                  label={t('spaces.responsible')}
                  error={false}
                  helperText={""}
                  {...register("responsible")}
                  sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                  value={responsible}
                >
                  <MenuItem value="-1" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                    {t('spaces.select')}
                  </MenuItem>

                  {teammates?.payload?.map(teammate => (
                    <MenuItem key={teammate.id} value={teammate.id} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                      {teammate.name}
                    </MenuItem>
                  ))}
                </TextField>

                {errors.responsible && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.responsible.message}
                  </Typography>
                )}
              </Box>
              <Box width="calc(100% - 45px)" px="8px" display="flex" alignItems="center" bgcolor="#F5F6F9" borderRadius="2px" height="39px" border='2px solid #DEE3E9CC'>
                <Typography component="span" color="#4F7091" fontSize={12}>
                  {teammates?.payload?.find(user => user.id === responsible)?.email}
                </Typography>
              </Box>
            </Box>

          </Box>

          {optionsGroups.length > 0 &&
            (<Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('spaces.access_level')}</Typography>
                {/* <Typography variant='caption'>{t('checkin.settings_form.help.responsible_solicitation_ids')}</Typography> */}
              </Box>
              <Box className='field'>
                <SelectResponsibles defaultValue={optionsGroups.filter((item) => roomToEdit?.access_level?.includes(item.value)) || []} options={optionsGroups} onChange={(values) => setGroups(values)} />
                {errors.access_level && <div className="error">{errors.access_level}</div>}
              </Box>
            </Box>)
          }

          <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between' }}>

            <Box display={'flex'} alignItems={'center'}>
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                {t('spaces.add_subspaces')}
              </Typography>
            </Box>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
              <Switch
                checked={addSubspaces}
                onChange={(checked) => setValue('addSubspaces', checked)}
                onColor="#22BEAD"
                onHandleColor="#FFFFFF"
                offColor='#E3E7EC'
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={30}
                width={55}
                className="react-switch"
                id="material-switch"
              />
              <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
            </Stack>
          </Grid>

          {
            addSubspaces === true &&
            <Grid container style={{ padding: "14px", minHeight: "45px", borderTop: "2px solid #F5F6F9" }}>

              {/* <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel style={{ color: '#1E6B7F' }} name='sub_space_type' checked={sub_space_type === 'shape'} onChange={(e) => setValue('sub_space_type', e.target.value)} value="shape" control={<Radio />} label={t('spaces.draw_map')} />
                <FormControlLabel style={{ color: '#1E6B7F' }} name='sub_space_type' checked={sub_space_type === 'simple'} onChange={(e) => setValue('sub_space_type', e.target.value)} value="simple" control={<Radio />} label={t('spaces.no_draw_map')} />
              </RadioGroup> */}

              <Box display={'flex'} gap={'14px'} height={'36px'}>
                <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                  <input style={{ cursor: 'pointer', margin: 0 }} id="shapeType" type='radio' checked={sub_space_type === 'shape'} name='sub_space_type' onChange={(e) => setValue('sub_space_type', e.target.value)} value="shape" />
                  <label style={{ cursor: 'pointer', color: '#1E6B7F' }} htmlFor='shapeType'>
                    {t('spaces.draw_map')}
                  </label>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                  <input style={{ cursor: 'pointer', margin: 0 }} id='simpleType' type='radio' checked={sub_space_type === 'simple'} name='sub_space_type' onChange={(e) => setValue('sub_space_type', e.target.value)} value="simple" />
                  <label style={{ cursor: 'pointer', color: '#1E6B7F' }} htmlFor='simpleType'>
                    {t('spaces.no_draw_map')}
                  </label>
                </Box>
              </Box>


              {

                sub_space_type === 'shape'
                  ?
                  <SpaceMapEditor shapes={shapes} setValue={setValue} shapesOp={shapesOp} roomToEdit={roomToEdit} getDetailSpace={getDetailSpace} />
                  :
                  <SimpleSubspaceCreate subspaces={subspaces} setValue={setValue} roomToEdit={roomToEdit} getDetailSpace={getDetailSpace}/>

              }
            </Grid>

          }



        </Box>

        <ModalConfirm openModal={openConfirmModal} handleCloseModal={handleCloseConfirmModal} />
      </Box>
    </LocalizationProvider>
  )
}
