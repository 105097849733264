import React from 'react';

const IconPermissions = props => {
    return (
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 11 11">
  <g id="invisible_box" data-name="invisible box">
    <rect id="Retângulo_1076" data-name="Retângulo 1076" width="11" height="11" fill="none"/>
  </g>
  <g id="icons_Q2" data-name="icons Q2" transform="translate(0.458 0.458)">
    <g id="Grupo_112" data-name="Grupo 112">
      <circle id="Elipse_52" data-name="Elipse 52" cx="1.146" cy="1.146" r="1.146" transform="translate(3.896)" fill="#187dff"/>
      <path id="Caminho_96" data-name="Caminho 96" d="M11.625,18.125h-.458V16.75a.458.458,0,0,0-.458-.458H7.5V15.6H9.1v-.917A4.689,4.689,0,0,0,7.042,14a4.689,4.689,0,0,0-2.062.688V15.6h1.6v.687H3.375a.458.458,0,0,0-.458.458v1.375H2.458A.458.458,0,0,0,2,18.583v1.833a.458.458,0,0,0,.458.458H4.292a.458.458,0,0,0,.458-.458V18.583a.458.458,0,0,0-.458-.458H3.833v-.917h2.75v.917H6.125a.458.458,0,0,0-.458.458v1.833a.458.458,0,0,0,.458.458H7.958a.458.458,0,0,0,.458-.458V18.583a.458.458,0,0,0-.458-.458H7.5v-.917h2.75v.917H9.792a.458.458,0,0,0-.458.458v1.833a.458.458,0,0,0,.458.458h1.833a.458.458,0,0,0,.458-.458V18.583A.458.458,0,0,0,11.625,18.125ZM3.833,19.958H2.917v-.917h.917Zm3.667,0H6.583v-.917H7.5Zm3.667,0H10.25v-.917h.917Z" transform="translate(-2 -11.25)" fill="#187dff"/>
    </g>
  </g>
</svg>


      
    )
}

export default IconPermissions;