import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, filterActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeaderReportsPoints } from '../../_components/checkin_page/reports_points/CardsHeader';
import TableReportsPoints from '../../_components/checkin_page/reports_points/TableReportsPoints';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { CardsHeaderRoomReportsAdmin } from '../../_components/rooms_page/reports_admin/CardsHeader';
import TableRoomReportsAdmin from '../../_components/rooms_page/reports_admin/TableRoomReportsAdmin';
import ShareModal from '../../_components/_shared/ShareModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function RoomsReports(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [submitted, setSubmitted] = useState(false);
  const [dataReportsPoints, setDataReportsPoints] = useState({});

  let startDate = moment().startOf("month").format('YYYY-MM-DD');
  let endDate = moment().format('YYYY-MM-DD');

  async function getDataRoomReportsColab(date_start = moment().startOf("month").format('YYYY-MM-DD'), date_end = moment().format('YYYY-MM-DD'), teams = null, teammates = null) {
    try {
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/rooms/reports?startDate=${date_start}&endDate=${date_end}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      setDataReportsPoints(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error('Erro ao carregar os dados')
    }
  }

  useEffect(() => {
    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataRoomReportsColab(start_date, end_date, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
}

  useEffect(() => {
    document.title = 'Befective | Gestão de Salas | Relatórios';
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'multi'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
        />
        <div className={style.containerCheckinPage}>
          <CardsHeaderRoomReportsAdmin alertActions={alertActions} userDetails={userDetails} resume={dataReportsPoints?.statsInfo} />
          <TableRoomReportsAdmin data={dataReportsPoints?.listColabs}/>
        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>

            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal filters={filter}  reports={'reportsRoomsColabs'}/>
            </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsReports)