// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P1W66rozVmJ13MFG5t-eEA\\=\\= input {\n  height: 40px;\n}\n\n._9DK8crcRJxw8mcM59d3oBA\\=\\= {\n  background-color: #fefefe !important;\n  border: 1px solid rgba(1, 1, 1, 0.2666666667) !important;\n  border-radius: 4px !important;\n}\n\n.GBRLOm0wBqtjVONQmAmelg\\=\\= {\n  height: 45px;\n  background-color: transparent;\n  border: solid 1px #D6DEEB;\n  min-width: 130px;\n  cursor: pointer;\n  color: #4F7091;\n  text-align: left;\n  padding-left: 10px;\n  border-radius: 2px;\n}", "",{"version":3,"sources":["webpack://./src/_components/scheduled_reports/ScheduledReportsCreate.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;;AAIA;EACE,oCAAA;EACA,wDAAA;EACA,6BAAA;AADF;;AAIA;EACE,YAAA;EACA,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AADF","sourcesContent":[".customDatePicker{\n  input{\n    height: 40px;\n  }\n}\n\n.datepickerCalendar{\n  background-color: #fefefe !important;\n  border: 1px solid #01010144 !important;\n  border-radius: 4px !important;\n}\n\n.customInput{\n  height: 45px;\n  background-color: transparent;\n  border: solid 1px #D6DEEB;\n  min-width: 130px;\n  cursor: pointer;\n  color: #4F7091;\n  text-align: left;\n  padding-left: 10px;\n  border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customDatePicker": "P1W66rozVmJ13MFG5t-eEA==",
	"datepickerCalendar": "_9DK8crcRJxw8mcM59d3oBA==",
	"customInput": "GBRLOm0wBqtjVONQmAmelg=="
};
export default ___CSS_LOADER_EXPORT___;
