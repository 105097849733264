import React from 'react';

const IconArrow = props => {
    return (
        
    <svg className="icon back"  xmlns="http://www.w3.org/2000/svg" width="6.69" height="11.666" viewBox="0 0 6.69 11.666">
        <path id="_" data-name="&gt;" d="M20.643,15.834l-4.417,4.417a.792.792,0,0,0,.083,1.25.875.875,0,0,0,1.125-.083l4.958-5a.792.792,0,0,0,0-1.167l-4.958-5a.875.875,0,0,0-1.125-.083.792.792,0,0,0-.083,1.25Z" transform="translate(-15.959 -10.001)" fill="#98acc1"/>
    </svg>      

    )
}

export default IconArrow;