import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { activitiesActions, teamsActions } from '../../../_actions';
import IconExpand from "../../_icons/IconExpand";
import IconArrow2 from "../../_icons/IconArrow2";
import useOutsideClick from "../../../_helpers/useOutsideClick";

function Focus(props) {

    const { t, i18n } = useTranslation('common');
    const { activitiesActions, teamsActions } = props;

    let permissions = props.permissions || {};
    let current = props.current || []

    const refFilter = useRef();

    const [submitted2, setSubmitted2] = useState(false)
    const [categoriesListHTML, setCategoriesList] = useState([])


    useOutsideClick(refFilter, target => {

        if (target != 'productive' && target != 'neutral' && target != 'unproductive') {
            let elems = document.querySelectorAll(".relation.active")

            elems.forEach.call(elems, function (el) {

                if (el.classList.contains("active")) {
                    el.classList.remove("active");
                }
            });
        }

    });

    function setTeams(categories, focus) {

        if (!categories) return false

        let categoriesList = [];

        categories.find(x => x.parent == parent.id)

        for (let parent of categories) {
            if (!parent.parent_id && parent.is_parent) {

                let efficiencyParent = parent.efficiency

                let customFocus = focus.find(x => x.category_id == parent.id);

                if (customFocus) {
                    efficiencyParent = customFocus.name
                }

                categoriesList.push(<li key={parent.id} id={"team" + parent.id}
                    className={"t0 team" + parent.id + " " + efficiencyParent}>
                    <div className="activity">{getlabelByLanguage(parent)}</div>
                    <div className="classification">
                        <div className={"relation " + efficiencyParent}>
                            <button ref={refFilter} onClick={() => {
                                toggleSetRelation(parent.id)
                            }} className={efficiencyParent}>{t('settings.teams.' + efficiencyParent)}<IconArrow2 />
                            </button>
                            <div ref={refFilter} className={"setRelation"} id={"team-" + parent.id}>
                                <ul>
                                    <li onClick={() => {
                                        setRelation(current.id, parent.id, 'productive', true)
                                    }} className="related">{t('activities.work_related')}</li>
                                    <li onClick={() => {
                                        setRelation(current.id, parent.id, 'neutral', true)
                                    }} className="neutral">{t('activities.work_neutral')}</li>
                                    <li onClick={() => {
                                        setRelation(current.id, parent.id, 'unproductive', true)
                                    }} className="unrelated">{t('activities.work_unrelated')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        {categories.find(x => x.parent_id == parent.id) &&
                            <button className="expand" onClick={() => {
                                expand(parent.id, "t1")
                            }}><IconExpand /></button>
                        }
                    </div>
                </li>)
                let p0 = parent.id

                for (let child of categories) {
                    if (child.parent_id == p0) {

                        let efficiencyChild = child.efficiency

                        let customFocus = focus.find(x => x.category_id == child.id);

                        if (customFocus) {

                            efficiencyChild = customFocus.name
                        }

                        categoriesList.push(<li key={child.id} id={"team" + child.id}
                            className={"t1 team" + p0 + " team" + child.id + " " + efficiencyChild}
                        >

                            <div className="activity">{getlabelByLanguage(child)}</div>
                            <div className="classification">
                                <div className={"relation " + efficiencyChild}>
                                    <button ref={refFilter} onClick={() => {
                                        toggleSetRelation(child.id)
                                    }}
                                        className={efficiencyChild}>{t('settings.teams.' + efficiencyChild)}<IconArrow2 />
                                    </button>
                                    <div className={"setRelation"} id={"team-" + child.id}>
                                        <ul>
                                            <li onClick={() => {
                                                setRelation(current.id, child.id, 'productive', false)
                                            }} className="related">{t('activities.work_related')}</li>
                                            <li onClick={() => {
                                                setRelation(current.id, child.id, 'neutral', false)
                                            }} className="neutral">{t('activities.work_neutral')}</li>
                                            <li onClick={() => {
                                                setRelation(current.id, child.id, 'unproductive', false)
                                            }} className="unrelated">{t('activities.work_unrelated')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="actions">

                            </div>
                        </li>)
                    }
                }
            }
        }

        setCategoriesList(categoriesList)

    }
    function getlabelByLanguage(child) {
        switch (String(i18n.language).toLowerCase()) {
            case 'pt':
                return child.name_pt;

            case 'es':
                return child.name_es;

            case 'en':
                return child.name_en;
                ;
        }
    }
    function toggleSetRelation(teamId) {

        let thisRelation = document.getElementById("team-" + teamId)

        var elems = document.querySelectorAll(".relation.active")

        if (thisRelation.parentNode.classList.contains('active')) {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });

        } else {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });


            thisRelation.parentNode.classList.add('active')
        }


    }

    function setRelation(teamId, categoryId, efficiencyName, subCategory) {


        toggleSetRelation(categoryId)

        let thisRelation = document.getElementById("team" + categoryId)


        thisRelation.classList.remove("productive", "unproductive", "neutral")
        thisRelation.classList.add(efficiencyName)
        thisRelation.childNodes[1].childNodes[0].classList.remove("productive", "unproductive", "neutral")
        thisRelation.childNodes[1].childNodes[0].classList.add(efficiencyName)
        thisRelation.childNodes[1].childNodes[0].childNodes[0].childNodes[0].textContent = t('settings.teams.' + efficiencyName)

        activitiesActions.setCategoryRelation(teamId, categoryId, efficiencyName, subCategory)
    }

    function expand(id, level) {

        let mainEl = document.getElementById("team" + id)
        let cEl = mainEl.querySelector('.expand')

        if (cEl.classList.contains("active")) {

            cEl.classList.remove("active")
            cEl.parentNode.parentNode.classList.remove("active")

            let elems = document.querySelectorAll(".team" + id + ".expanded." + level)

            if (elems.length > 0) {
                [].forEach.call(elems, function (el) {
                    el.classList.remove("expanded");
                    el.classList.remove("last");
                    el.classList.remove("first");
                });
            }
        } else {

            cEl.classList.add("active")
            cEl.parentNode.parentNode.classList.add("active")

            let elems = document.querySelectorAll(".team" + id + "." + level)

            if (elems.length > 0) {
                let n = 0;
                [].forEach.call(elems, function (el) {
                    n++;
                    el.classList.add("expanded");
                    if (elems.length == n) {
                        el.classList.add("last");
                    }
                    if (n == 1) {
                        el.classList.add("first");
                    }
                });
            }
        }

    }


    useEffect(() => {


        if (props.activities.categories && props.teams.focus) {
            setTeams(props.activities.categories, props.teams.focus)
        }


    }, [props.teams.loadingFocus || props.activities.loadingCategories])


    return (
        <React.Fragment>
            <header className="pageHeader teams" style={{flexDirection:'row'}}>
                
                    <h3>{current.name}</h3>

                    <div className={'controls'}>


                    <NavLink to={"/settings/teams/" + current.id + "/configuration"}>
                        <button type="button">{t('settings.teams.cancel')}</button>
                    </NavLink>
                    {(permissions.UpdateTeams) &&
                        <NavLink to={"/settings/teams/" + current.id + "/configuration"}>
                            <button type="button" className="update">{t('settings.teams.update')}</button>
                        </NavLink>
                    }
                </div>

            </header>
            <div className="editPage">
                <div className="teamsListTable focus">
                    <ul>
                        <li className="header">
                            <div className="activity">{t('settings.teams.activity')}</div>
                            <div className="classification">{t('settings.teams.classification')}</div>
                            <div className="actions"></div>
                        </li>
                        {categoriesListHTML}
                    </ul>
                </div>

            </div>
        </React.Fragment>

    )
};

function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        permissions: state.authentication.permissions,
        current: state.teams.detail,
        teams: state.teams,
        activities: state.activities,
        categories: state.activities.categories,
        updated: state.activities.updated,
        deleted: state.teams.deleted
    }
}

function mapDispatchToProps(dispatch) {
    return {
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Focus));
