import React from 'react';

const IconToday = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_11" data-name="Retângulo 11" width="24" height="24" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(3 1)">
            <path id="Caminho_3" data-name="Caminho 3" d="M23,22H22V18.25a3.95,3.95,0,0,0-1.15-2.85L18.4,13l2.45-2.4A3.95,3.95,0,0,0,22,7.75V4h1a1,1,0,0,0,0-2H7A1,1,0,0,0,7,4H8V7.75A3.95,3.95,0,0,0,9.15,10.6L11.6,13,9.15,15.4A3.95,3.95,0,0,0,8,18.25V22H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2ZM10.6,16.85l3.1-3.15a.95.95,0,0,0,0-1.4L10.6,9.15a1.8,1.8,0,0,1-.6-1.4V4H20V7.75a1.8,1.8,0,0,1-.6,1.4L16.3,12.3a.95.95,0,0,0,0,1.4l3.1,3.15a1.8,1.8,0,0,1,.6,1.4V21.4l-3.6-3.65a1.95,1.95,0,0,0-2.8,0L10,21.4V18.25A1.8,1.8,0,0,1,10.6,16.85ZM17.8,22H12.2L15,19.15Z" transform="translate(-6 -2)" fill="#97b3bf"/>
        </g>
        </svg>
 

    )
}

export default IconToday;