import React from 'react';

const IconInfoFlat = props => {
    return (
        <svg id="info" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1356" data-name="Retângulo 1356" width="20" height="20" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.833 0.833)">
                <path id="Caminho_251" data-name="Caminho 251" d="M11.167,2a9.125,9.125,0,1,0,6.494,2.673A9.167,9.167,0,0,0,11.167,2Zm0,16.667a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,11.167,18.667Z" transform="translate(-2 -2)" fill="#98acc1" />
                <path id="Caminho_252" data-name="Caminho 252" d="M22.833,20a.833.833,0,0,0-.833.833v5a.833.833,0,0,0,1.667,0v-5A.833.833,0,0,0,22.833,20Z" transform="translate(-13.667 -12.5)" fill="#98acc1" />
                <circle id="Elipse_102" data-name="Elipse 102" cx="0.833" cy="0.833" r="0.833" transform="translate(8.333 4.167)" fill="#98acc1" />
            </g>
        </svg>
    )
}

export default IconInfoFlat;