import React from 'react';

const IconOk = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Icon_awesome-meh" data-name="Icon awesome-meh" d="M12,.563a12,12,0,1,0,12,12A12,12,0,0,0,12,.563ZM8.129,8.692A1.548,1.548,0,1,1,6.581,10.24,1.547,1.547,0,0,1,8.129,8.692Zm8.516,9.29H7.355a.774.774,0,0,1,0-1.548h9.29A.774.774,0,0,1,16.645,17.982Zm-.774-6.194a1.548,1.548,0,1,1,1.548-1.548A1.547,1.547,0,0,1,15.871,11.788Z" transform="translate(0 -0.563)" fill="#c1cdda"/>
        </svg>



    )
}

export default IconOk;