import React from 'react';

const IconClear = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="X" transform="translate(-762 -79)">
            <circle id="Elipse_11" data-name="Elipse 11" cx="8" cy="8" r="8" transform="translate(762 79)" fill="#e4eaef"/>
            <path id="X-2" data-name="X" d="M15,14.288l2.143-2.143a.5.5,0,0,0-.71-.71l-2.143,2.143-2.143-2.143a.5.5,0,1,0-.71.71l2.143,2.143-2.143,2.143a.5.5,0,0,0,.71.71L14.286,15l2.143,2.143a.5.5,0,1,0,.71-.71Z" transform="translate(755.715 72.711)" fill="#98acc1"/>
        </g>
        </svg>

    )
}

export default IconClear;