import { Close } from '@material-ui/icons'
import { Box, Drawer, IconButton, Typography } from '@mui/material'
import React from 'react'


import { befectiveFormatDate, getCorrectPeriod } from '../../../_helpers'
import IconIn from '../../_icons/IconIn'
import IconOut from '../../_icons/IconOut'

import { useTranslation } from 'react-i18next'

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

export function DrawerDetailsToday({ pointDetails, openDrawerDetailsToday, setOpenDrawerDetailsToday, userDetails }) {

  const { t, i18n } = useTranslation('common');

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  return (
    <Drawer
      anchor="right"
      open={openDrawerDetailsToday}
      onClose={() => setOpenDrawerDetailsToday(false)}
      PaperProps={{
        style: {
          padding: '0px 12px',
          width: '350px'
        }
      }}
    >
      <div style={{ position: 'absolute', right: 4, top: 4 }}>
        <IconButton aria-label="close" onClick={() => setOpenDrawerDetailsToday(false)}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

        <Box display="flex" flexDirection="column" gap={1}>

          <Box display="flex" mb={3}>
            <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>{t('checkin.drawer_details_day.today') + ", " + befectiveFormatDate(pointDetails?.day, lang, "S")}</Typography>
          </Box>

          {pointDetails && (
            <Box key={pointDetails.id} display="flex" flexDirection="column" mt={1 !== 0 && 2}>
              <Box display="flex" bgcolor="#F5F6F9" paddingX={2} paddingY={1} borderRadius="4px">
                <Typography color="#98ACC1" fontSize={12}>{getCorrectPeriod(null,pointDetails.periods)}</Typography>
              </Box>

              {pointDetails.points.map((point, index) => (
                <Box key={index} display="flex" gap="8px" paddingX={2} mt={2}>
                  <Box>
                    {point.type === 'IN' ? (
                      <IconIn fill={statusColors[point.status_id - 1]} />
                    ) : (
                      <IconOut fill={statusColors[point.status_id - 1]} />
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      component="span"
                      lineHeight={1}
                      fontSize={14}
                      color="#1E6B7F"
                    >
                      <Typography
                        component="span"
                        color="#1E6B7F"
                        lineHeight={1}
                        fontSize={14}
                        fontWeight={600}
                      >
                        {point.time.split(':')[0] + ':' + point.time.split(':')[1]}{' '}
                      </Typography>
                      {point.type === 'IN' ? t('checkin.drawer_details_day.entry') : t('checkin.drawer_details_day.exit')}
                    </Typography>
                    <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                    <Typography fontSize={12} color="#4F7091">{t('checkin.drawer_details_day.receipt') + ": " + point?.receipt_token}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* <Button
          variant="contained"
          className={style.checkBtn}
          style={{ backgroundColor: '#FDBE2B', color: '#106276', height: '40px', boxShadow: 'none' }}
          startIcon={<SignIn size={16} weight='bold' />}
        >
          Check-in
        </Button> */}
      </Box>

    </Drawer>
  )
}
