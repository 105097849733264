import React, {useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import TodayStats from '../_components/home/TodayStats'
import TodayStatsSkeleton from '../_components/home/TodayStatsSkeleton'
import WorkingAwesomely from '../_components/home/WorkingAwesomely'
import WorkingAwesomelySkeleton from '../_components/home/WorkingAwesomelySkeleton'
import TeammatesMood from '../_components/home/TeammatesMood'
import TeammatesMoodSkeleton from '../_components/home/TeammatesMoodSkeleton'
import Activities from '../_components/home/Activities'
import ActivitiesSkeleton from '../_components/home/ActivitiesSkeleton'

import { useTranslation, initReactI18next } from "react-i18next";

import { teamsActions } from '../_actions';
import { statsActions } from '../_actions';
import { teammatesActions } from '../_actions';


function HighlightsPage(props){
    const {t, i18n} = useTranslation('common');

    const { statsActions } = props;
    const { teamsActions } = props;
    const { teammatesActions } = props;

    let loading = props.loadingStat; 
    let filter = props.filter || {};

    const [minimumTime, setMinimumTime] = useState(500); 
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  
    useEffect(() => {
       
        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');
        let teams = null;
        let teammates = null;
        
        if(filter.date){
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }          

        if(filter.teams){teams = filter.teams;}
        if(filter.teammates){teammates = filter.teammates;}

        

        statsActions.getStats(startDate, endDate, teams, teammates);             

    }, [])

    useEffect(() => {
        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');
        let teams = null;
        let teammates = null;
        
        if(filter.date){
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }          

        if(filter.teams){teams = filter.teams;}
        if(filter.teammates){teammates = filter.teammates;}

        

        statsActions.getStats(startDate, endDate, teams, teammates);       
        
    }, [filter])


    return (        
        <React.Fragment>
            <section className="mainContent">
            <Toolbar />
            <Filterbar />
            {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column left">
                        { 
                        !minimumTimeElapsed || loading 
                            ? <TodayStatsSkeleton /> 
                            : <TodayStats highlights='true' /> 
                        }
                                         
                    </div>
                    <div className="column right">
                        <div className="wrapper large">                            
                            { 
                            !minimumTimeElapsed || loading 
                                ? <WorkingAwesomelySkeleton /> 
                                : <WorkingAwesomely /> 
                            }   
                            { 
                            !minimumTimeElapsed || loading
                                ? <TeammatesMoodSkeleton /> 
                                : <TeammatesMood /> 
                            } 
                        </div>
                        { 
                            !minimumTimeElapsed || loading 
                                ? <ActivitiesSkeleton /> 
                                : <Activities /> 
                            }                          
                    </div>
                   
                    
                </div>
            {/* PAGE ENDS */}
            </section>
        </React.Fragment>
    )
    };


function mapDispatchToProps(dispatch) {
 
    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
      }
}
      

function mapStateToProps(state) {
    
    return {
        loadingStat: state.stats.loading,
        filter: state.filter
    }
    
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(HighlightsPage)