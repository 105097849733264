import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Warning } from 'phosphor-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from "../../../_helpers";
import ModalApproveNotWorkSolicitation from '../ModalApproveNotWorkSolicitation';
import ModalApproveSolicitation from '../ModalApproveSolicitation';
import style from './CheckinTable.module.scss';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

export function CheckinTableControlSolicitations({
  alertActions,
  userDetails,
  solicitations,
  updateListSolicitations,
  filter,
  orderColumn = () => { },
  orderedColumn,
  order
}) {
  const { t, i18n } = useTranslation('common');

  const [openModalApprove, setOpenModalApprove] = useState(false)
  const [currentSolicitation, setCurrentSolicitation] = useState(null)
  const [openModalApproveNotWork, setOpenModalApproveNotWork] = useState(false)
  const [currentSolicitationNotWork, setCurrentSolicitationNotWork] = useState(null)

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const handleOpenModalApprove = (solicitation) => {
    setCurrentSolicitation(solicitation)
    setOpenModalApprove(true)
  }

  const handleCloseModalApprove = () => {
    setOpenModalApprove(false)
    setCurrentSolicitation(null)
  }

  const handleOpenModalApproveNotWork = (solicitation) => {
    setCurrentSolicitationNotWork(solicitation)
    setOpenModalApproveNotWork(true)
  }

  const handleCloseModalApproveNotWork = () => {
    setOpenModalApproveNotWork(false)
    setCurrentSolicitationNotWork(null)
  }

  const getReasonSolicitation = (solicitation) => {
    switch (lang) {
      case 'pt':
        return solicitation.label_pt || solicitation.label_custom;
      case 'es':
        return solicitation.label_es || solicitation.label_custom;
      default:
        return solicitation.label_en || solicitation.label_custom;
    }
  }

  return (
    <>
      <TableContainer className='container-table-control' component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <Table className='table-control' sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow sx={{ textTransform: 'uppercase' }}>
              <TableCell onClick={() => orderColumn('username', 'text')} sx={{ cursor: 'pointer' }} title={t('order_by')} className={style.tableHeaderTd}>
                {t("checkin.control_points.table_solicitations.col_colab")}
                {orderedColumn === 'username' ? (
                  order === 'asc' ? (
                    <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  ) : (
                    <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  )
                ) : null}
              </TableCell>
              <TableCell onClick={() => orderColumn('moment_solicitation', 'text')} sx={{ cursor: 'pointer' }} title={t('order_by')} className={style.tableHeaderTd}>
                {t("checkin.control_points.table_solicitations.col_date")}
                {orderedColumn === 'moment_solicitation' ? (
                  order === 'asc' ? (
                    <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  ) : (
                    <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  )
                ) : null}
              </TableCell>
              <TableCell className={style.tableHeaderTd}>{t("checkin.control_points.table_solicitations.col_reason")}</TableCell>
              <TableCell onClick={() => orderColumn('solicitation_type', 'text')} sx={{ cursor: 'pointer' }} title={t('order_by')} className={style.tableHeaderTd}>
                {t("checkin.control_points.table_solicitations.col_type")}
                {orderedColumn === 'solicitation_type' ? (
                  order === 'asc' ? (
                    <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  ) : (
                    <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                  )
                ) : null}
              </TableCell>
              <TableCell className={style.tableHeaderTd} width={'5%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              solicitations?.length > 0
                ?
                solicitations?.map(solicitation => (
                  <TableRow key={solicitation.id} className='table-row' onClick={() =>
                    solicitation?.status === 'WAITING' && (solicitation.solicitation_type === 'AD' ? handleOpenModalApprove(solicitation) : handleOpenModalApproveNotWork(solicitation))} sx={{ cursor: 'pointer' }}>
                    <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {solicitation.username}
                    </TableCell>
                    <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {befectiveFormatDate(solicitation.moment_solicitation, i18n.language, 'T')}
                    </TableCell>
                    <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {getReasonSolicitation(solicitation)}
                    </TableCell>
                    <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      {solicitation.solicitation_type === 'AD' ? <div><span className={`${style.boxType} ${style.boxTypePoint}`}>{t("checkin.control_points.table_solicitations.type_point")}</span></div> : <div><span className={`${style.boxType} ${style.boxTypeFault}`}>{t("checkin.control_points.table_solicitations.type_absence")}</span></div>}
                    </TableCell>
                    {
                      solicitation?.status === 'WAITING' ?
                        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="right">
                          <Box display={'flex'} gap={'4px'}>

                            <Box className={style.infoSolicitation} onClick={() => solicitation.solicitation_type === 'AD' ? handleOpenModalApprove(solicitation) : handleOpenModalApproveNotWork(solicitation)}>
                              <Warning size={16} />
                            </Box>
                          </Box>
                        </TableCell>
                        :
                        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="right">
                        </TableCell>
                    }
                  </TableRow>
                ))
                :
                <TableRow className='table-row'>
                  <TableCell colSpan={4} style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                    <Typography>{t("checkin.control_points.table_solicitations.no_solicitations_waiting")}</Typography>
                  </TableCell>
                </TableRow>
            }

          </TableBody>
        </Table>


        {
          <ModalApproveSolicitation
            openModalApprove={openModalApprove}
            handleCloseModalApprove={handleCloseModalApprove}
            currentSolicitation={currentSolicitation}
            updateListSolicitations={updateListSolicitations}
            alertActions={alertActions}
          />
        }

        {
          <ModalApproveNotWorkSolicitation
            openModalApprove={openModalApproveNotWork}
            handleCloseModalApprove={handleCloseModalApproveNotWork}
            currentSolicitation={currentSolicitationNotWork}
            updateListSolicitations={updateListSolicitations}
            alertActions={alertActions}
          />
        }
      </TableContainer >
    </>

  );
}
