import { Avatar, Backdrop, Box, Grid, IconButton, InputAdornment, Menu, MenuItem, Modal, Slide, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AttachFile, Send } from "@material-ui/icons";
import { AvatarGroup, Skeleton } from "@material-ui/lab";
import { XCircle } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { bindActionCreators } from "redux";

import ScrollableFeed from "react-scrollable-feed";
import { alertActions, } from "../../../_actions";
import { befectiveFormatDate, formatDatetime, getAvatarUrl } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootTab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '37ch',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '860px',
    height: '560px',
    outline: 'none',
    borderRadius: '8px',
    position: 'relative'
  },
  title: {
    fontSize: 16,
    color: '#1E6B7F',
    fontWeight: '500',
    marginBottom: 24
  }
}));


function ModalReplyMessage({
  currentMessageId,
  userDetails,
  ...props
}) {

  const classes = useStyles();
  const { t } = useTranslation('common');

  const { alertActions } = props || {};

  const { open, isLoading, handleCloseModalReplyMessage, updateInfo } = props || {};

  const [editComment, setEditComment] = useState(false);

  const [commentMention, setCommentMention] = useState({
    message: '',
    mentions: []
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentComment, setCurrentComment] = useState({});
  const inputRef = useRef()
  const [messageInput, setMessageInput] = useState('');
  const [currentMessage, setCurrentMessage] = useState({})

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentComment({});
  };

  const handleCheckReadReplies = async (userUpdateInfo = true) => {
    try {

      const result = await api.get(`messages/${currentMessage?.id}/replie?organization_id=${userDetails?.organizationId}&owner_id=${userDetails?.mu_id}`);

      if (userUpdateInfo) {
        if (result.status === 200) {
          updateInfo()
        }
      }

    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const createMessage = async (messageFormattedFull) => {
    try {

      const payload = {
        owner_id: userDetails.mu_id,
        message_body: messageFormattedFull
      };
      const result = await api.post(`messages/${currentMessage?.id}/replie?organization_id=${userDetails?.organizationId}`, payload);
      if (result.status === 200) {
        await handleCheckReadReplies(false)
        await getDetailsMassage();
        setMessageInput('');
        inputRef.current.focus()
        inputRef.current.value = ''
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  
  const handleSendMessage = (e) => {
    if (e.key === 'Enter' && messageInput.length > 0) {
      createMessage(messageInput);
    }
  }

  const handleEditComment = (isEdit) => {
    setEditComment(!editComment);
    if (isEdit) {
      setCommentMention({
        message: currentComment?.comment,
        mentions: commentMention.mentions
      });
    } else {
      setCommentMention({
        message: '',
        mentions: []
      });
      setCurrentComment({});
    }
  }

  
  const getDetailsMassage = async () => {
    try {
      const result = await api.get(`messages/${currentMessageId}/details?organization_id=${userDetails?.organizationId}`)

      setCurrentMessage({
        ...result?.data,
        replies: result?.data?.replies?.length > 0 ? result?.data?.replies.sort((current, next) => new Date(current?.created_at) - new Date(next?.created_at)) : []
      })

    } catch (error) {
      //alertActions.error(error)
    }
  }

  useEffect(() => {
    getDetailsMassage()
  }, []);


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={`${classes.modal} modal-messages`}
        onClose={handleCloseModalReplyMessage}
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={open} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={classes.paper}>
            <div className="box-button-close">
              <button onClick={handleCloseModalReplyMessage} className="close" type="button"><IconClose /></button>
            </div>

            <Grid container className='container-detail-messages'>
              <Grid item xs={9} className='box-left'>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>

                      <Tabs
                        defaultTab="0"
                      >
                        <TabList className="custom-tab-list">
                          <Tab tabFor="0">
                            {t('messages.modal_details_message.message')}
                          </Tab>
                          <Tab tabFor="1" onClick={handleCheckReadReplies}>
                            {t('messages.modal_details_message.comments')+` (${currentMessage?.replies?.length})`}
                          </Tab>
                          <Tab tabFor="2">{t('messages.modal_details_message.attachments')+` (${currentMessage?.attachments?.length})`}</Tab>
                        </TabList>


                        <TabPanel tabId="0" className="tab-comments-container">

                          <div className='comments-container'>
                            <div  className="box-message">
                              <h5 className='title-task'>{currentMessage?.subject}</h5>
                              <p className='description-task'>{currentMessage?.message_body}</p>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel tabId="1" className="tab-comments-container">

                          <div className='comments-container'>
                            <ScrollableFeed>
                              <ul>
                                {currentMessage?.replies?.length > 0 && currentMessage?.replies.map((comment, index) => (
                                  <>
                                    <li key={comment.id} className='comment-box' >
                                      <div className='comment'>
                                        <div className="avatarMU">
                                          <Avatar alt={comment?.username} src={getAvatarUrl({
                                            name: comment?.username
                                          })} style={{ width: 22, height: 22 }} />
                                        </div>
                                        <div className="infoComment">
                                          <div>
                                            <span>{comment?.username}</span>
                                            <span>{befectiveFormatDate(comment?.created_at,userDetails.language,"C")}</span>
                                          </div>
                                          <div>
                                            <p>{comment?.message_body}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>

                            </ScrollableFeed>
                          </div>

                          <Menu
                            id={`menu-comment`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          >
                            <MenuItem onClick={() => handleEditComment(true)}>{t('modalDetailTask.edit')}</MenuItem>
                            <MenuItem>{t('modalDetailTask.delete')}</MenuItem>
                          </Menu>

                          <div className='new-comment-container'>

                            {
                              editComment && (
                                <XCircle
                                  style={{
                                    position: 'absolute',
                                    bottom: "16px",
                                    right: "16px"
                                  }}
                                  size={20}
                                  weight="fill"
                                  color="#FF5151"
                                  onClick={() => handleEditComment(false)}
                                />
                              )
                            }
                            <TextField
                              inputRef={inputRef}
                              autoComplete="off"
                              onKeyDown={(e) => handleSendMessage(e)}
                              onChange={e => setMessageInput(e.target.value)}
                              type='text'
                              style={{ backgroundColor: '#ffffff' }}
                              placeholder={t('messages.modal_details_message.placeholder_input_send')}
                              variant="outlined"
                              hiddenLabel={true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      edge="end"
                                    >
                                      <Send onClick={(e) => handleSendMessage({...e,key:"Enter"})} />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            >

                            </TextField>
                          </div>

                        </TabPanel>

                        <TabPanel tabId="2" className="tab-comments-container">
                          <div className='comments-container'>
                            {currentMessage?.attachments?.length > 0 && currentMessage?.attachments.map((file, index) => (
                              <div key={file.id} className='comment-box'>
                                <ul>
                                  <li>
                                    <div className='comment'>
                                      <div className="avatarMU">
                                        <Avatar alt={currentMessage?.owner_name} src={getAvatarUrl({
                                          name: currentMessage?.owner_name
                                        })} style={{ width: 22, height: 22 }} />
                                      </div>
                                      <div className="infoComment">
                                        <div className="username-date-info">
                                          <span>{currentMessage?.owner_name}</span>
                                          <span>{formatDatetime(currentMessage?.created_at)}</span>
                                        </div>

                                        <Tooltip title={file?.original_name}>
                                          <a href={file?.url_attachment} download>
                                            <div>
                                              <AttachFile fontSize='small' />
                                            </div>

                                            <div>
                                              {
                                                file?.original_name?.length > 40
                                                  ?
                                                  file?.original_name?.substring(0, 40) + '[...].' + file?.original_name?.split('.').slice(-1)[0]


                                                  :
                                                  file?.original_name
                                              }
                                            </div>

                                          </a>
                                        </Tooltip>

                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            ))}
                          </div>
                        </TabPanel>
                      </Tabs>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} className='box-right'>
                <Box>
                  <Grid container >
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                      <Typography className='label' variant='subtitle2' >{t('messages.list.sent_at')}</Typography>
                      {
                        isLoading
                          ?
                          <Skeleton variant="text" width={80} />
                          :
                          <Box className='value'>{new Date(currentMessage?.datetime).toLocaleString().slice(0, -3)}</Box>
                      }
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                      <Typography className='label' variant='subtitle2' >{t('messages.list.sent_by')}</Typography>
                      {
                        isLoading
                          ?
                          <Skeleton variant="text" width={80} />
                          :
                          <Box className='value'>{currentMessage?.owner_name}</Box>
                      }
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                      <Typography className='label' variant='subtitle2' >{t('messages.list.destinations')}</Typography>
                      <Box className='value'>
                        <AvatarGroup max={4}>
                          {
                            currentMessage?.destinations?.length > 0
                              ?
                              currentMessage?.destinations?.map((destination, index) => (
                                <Tooltip key={destination?.id} title={destination?.username}>
                                  <Avatar alt={destination?.username} src={getAvatarUrl({
                                    name: destination?.username
                                  })} style={{ width: 22, height: 22 }} />
                                </Tooltip>
                              ))
                              :
                              <div style={{ border: 'none' }}>----</div>
                          }

                        </AvatarGroup>
                      </Box>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>

            </Grid>
          </div>
        </Slide>
      </Modal>
    </div >
  )

};

function mapStateToProps(state) {

  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalReplyMessage));
