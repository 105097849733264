import {historicalConstants} from '../_constants';

export function historical(state = {}, action) {

    switch (action.type) {
        case historicalConstants.GET_HISTORICAL_SUMMARY_REQUEST:
            return {
                loading: true,
                loadingType: 'loadingSummary'
            };

        case historicalConstants.GET_HISTORICAL_SUMMARY_SUCCESS:
            return {
                summary: action.payload,
                loading: false,
                loadingType: 'loadedSummary'
            };

        case historicalConstants.GET_HISTORICAL_SUMMARY_FAILURE:
            return {
                error: action.error,
                loading: false,
                loadingType: 'failedSummary'
            };
        case historicalConstants.GET_HISTORICAL_TEAMMATEDAY_REQUEST:
            return {
                loading: true,
                loadingType: 'loadingDay'
            };
        case historicalConstants.GET_HISTORICAL_TEAMMATEDAY_SUCCESS:
            return {

                teammateDay: action.payload,
                loading: false,
                loadingType: 'loadedDay'
            };
        case historicalConstants.GET_HISTORICAL_TEAMMATEDAY_FAILURE:
            return {
                error: action.error,
                loading: true,
                loadingType: 'failedDay'
            };
        case historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: false,
                loadingType: 'loadingHour'
            };
        case historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_SUCCESS:
            return {
                ...state,
                hourActivities: action.payload,
                loading: false,
                loadingType: 'loadedHour'
            };
        case historicalConstants.GET_HISTORICAL_HOUR_ACTIVITIES_FAILURE:
            return {
                error: action.error,
                loading: 'failedHour'
            };
        default:
            return state
    }
}
