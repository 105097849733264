import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeaderControlSolicitations } from "../../_components/checkin_page/control_solicitations/CardsHeader";
import { CheckinTableControlSolicitations } from '../../_components/checkin_page/control_solicitations/CheckinTable';
import { authHeader, befectiveFormatDate, history, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import './fullcalendar.scss';
import style from './styles.module.scss';
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import TableDaysOff from "../../_components/daysoff_settings/TableDaysOff";
import { ModalImportDaysOff } from "../../_components/daysoff_settings/ModalImportDaysOff";
import ModalCreateDaysOff from "../../_components/daysoff_settings/ModalCreateDaysOff";
const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function DaysOffSettingsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, usersActions, teammates, filter, responsibles } = props
  const [viewCalendar, setViewCalendar] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [daysOffData, setDaysOffData] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const [events, setEvents] = useState([])
  const [openModalImportDaysOff, setOpenModalImportDaysOff] = useState(false)
  const [openModalCreateDaysOff, setOpenModalCreateDaysOff] = useState(false);
  const [dateFilter, setDateFilter] = useState(moment().startOf('year'))
  const [year, setYear] = useState(moment().year())
  const [month, setMonth] = useState(moment().month()+1)
  const [datesDefault, setDatesDefault] = useState(null)
  const [datesCalendar, setDatesCalendar] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().startOf('month').format('YYYY-MM-DD')
  })

  async function getDaysOffData({ year = '', month = '', date_start = '', teams = '', teammates = '' }) {
    try {
      const queryTeams = (teams && teams.length > 0) ? `&teams=${teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''
      const queryDateStart = date_start ? `&date_start=${date_start}` : ''
      const queryYear = year ? `&year=${year}` : ''
      const queryMonth = month ? `&month=${month}` : ''

      const response = await api.get(`/settings/free-days?current_date=${dateFilter.format('YYYY-MM-DD')}${queryYear}${queryMonth}${queryTeams}${queryTeammates}${queryDateStart}`, {
        headers: authHeader()
      })
      setDaysOffData(response.data);
      setFetching(false)
    } catch (error) {
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }
    getDaysOffData({ year: year, month: month, date_start: '', teams: teams, teammates: teammates })
  }, [filter])

  useEffect(() => {
    document.title = `Befective | ${t('menu.daysoff')}`;
    usersActions.getResponsibles(userDetails?.organizationId)
  }, []);

  function renderEventContent(eventInfo) {
    return (
      <div>
        <span style={{ color: '#FDBE2B', fontWeight: 500 }}>{eventInfo.event.title}</span>
      </div>
    )
  }

  const handleClickDate = async (e) => {

    const date = e.dateStr

    const infoFiltered = calendarData.find(info => String(info.day_ref).split('T')[0] === date)

    if (infoFiltered.data.length > 0) {
      setYear(moment(date).year())
      setMonth(moment(date).month()+1)
      setViewCalendar(false)
      getDaysOffData({ year: moment(date).year(), month: moment(date).month()+1, date_start: moment(date).format('YYYY-MM-DD'), teams: '', teammates: '' })
      return
    }
    setDatesDefault({
      start: moment(date).format('YYYY-MM-DD'),
      end: moment(date).format('YYYY-MM-DD')
    })
    setOpenModalCreateDaysOff(true)
  }


  const updateInfo = () => {
    handleDatesSet(datesCalendar)
    getDaysOffData()
  }

  const handleDatesSet = async (arg) => {

    const date_start = moment(arg.start).format('YYYY-MM-DD')
    const date_end = moment(arg.end).format('YYYY-MM-DD')
    setDatesCalendar({
      start: date_start,
      end: date_end
    })
    try {
      const { data } = await api.get(`/settings/free-days/calendar/?date_start=${date_start}&date_end=${date_end}`, {
        headers: authHeader()
      });
      setCalendarData(data); // Define os eventos retornados pela API no estado 'events'
      const dataRender = data.map(event => {
        if (event.data.length > 0) {
          const label_day_off = event.data.length === 1 ? t('settings.daysoff_settings.day_off') : t('settings.daysoff_settings.days_off')
          return {
            title: `${event.data.length} ${label_day_off}`,
            date: moment(event.day_ref).format('YYYY-MM-DD')
          }
        }
        return {
          title: '',
          date: moment(event.day_ref).format('YYYY-MM-DD')
        };
      })
      setEvents(dataRender)
    } catch (error) {
      console.error('Erro ao buscar os eventos:', error);
    }
  };

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={viewCalendar ? 'disabled' : 'multi'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          groupButtonsDaysOff={true}
          setOpenModalImportDaysOff={setOpenModalImportDaysOff}
          setOpenModalCreateDaysOff={setOpenModalCreateDaysOff}
          viewCalendar={viewCalendar}
          setViewCalendar={setViewCalendar}
        />

        {
          !viewCalendar
            ?
            <div className={style.containerDaysOffPage}>
              {
                !fetching
                  ?
                  <TableDaysOff
                    data={daysOffData}
                    getData={getDaysOffData}
                    alertActions={alertActions}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    year={year}
                    setYear={setYear}
                    month={month}
                    setMonth={setMonth}
                    userDetails={userDetails}
                  />
                  :
                  <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
              }

            </div>
            :
            <div id='calendarDaysOffSettingsPage' className={style.containerDaysOffPage}>
              <Box padding={4} sx={{ background: '#fff' }}>

                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'title',
                    right: 'today prev,next'
                  }}
                  // eventSources={handleEventSources}
                  datesSet={handleDatesSet}
                  events={events}
                  firstDay="0"
                  initialView='dayGridMonth'
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  dayMaxEvents={true}
                  dateClick={handleClickDate}
                  eventContent={renderEventContent}
                />
              </Box>
            </div>
        }

        <ModalImportDaysOff
          openModalImportDaysOff={openModalImportDaysOff}
          setOpenModalImportDaysOff={setOpenModalImportDaysOff}
          alertActions={alertActions}
          getDataDaysOff={getDaysOffData}
          userDetails={userDetails}
        />

        {
          responsibles &&
          <ModalCreateDaysOff
            openModalCreateDaysOff={openModalCreateDaysOff}
            setOpenModalCreateDaysOff={setOpenModalCreateDaysOff}
            userDetails={userDetails}
            teammates={responsibles}
            alertActions={alertActions}
            updateInfo={updateInfo}
            datesDefault={datesDefault}
            setDatesDefault={setDatesDefault}
            viewCalendar={viewCalendar}
          />
        }



      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates.payload,
    filter: state.filter,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(DaysOffSettingsPage)