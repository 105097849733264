import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import TodayStats from '../_components/home/TodayStats'
import TodayStatsSkeleton from '../_components/home/TodayStatsSkeleton'
import WorkingAwesomely from '../_components/home/WorkingAwesomely'
import WorkingAwesomelySkeleton from '../_components/home/WorkingAwesomelySkeleton'
import TeammatesMood from '../_components/home/TeammatesMood'
import TeammatesMoodSkeleton from '../_components/home/TeammatesMoodSkeleton'
import WorkingToday from '../_components/home/WorkingToday'
import WorkingTodaySkeleton from '../_components/home/WorkingTodaySkeleton'
import Activities from '../_components/home/Activities'
import ActivitiesSkeleton from '../_components/home/ActivitiesSkeleton'
import ShareModal from '../_components/_shared/ShareModal';

import { useTranslation, initReactI18next } from "react-i18next";
import Moment from 'moment';

import { statsActions, shareActions, usersActions } from '../_actions';

function HomePage(props) {
    const { t, i18n } = useTranslation('common');
    
    const { statsActions, shareActions, userDetails, usersActions } = props;


    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);

    let loading = props.loadingStat;
    let filter = props.filter || {};

    const isMountedFilter = useRef(false);

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);

    function closeShareModal() {
        shareActions.closeModal()
    }

    // const handleChangeViewSettings = async (event) => {
    //     setViewSettingStart(event.target.value);
    //     setAnchorEl(false);
    //     try {
    //         setIsUpdatingPreview(true);
    //         const res = await api.put(`/users/update-preview/${userDetails?.id}`,{
    //             select_preview : event.target.value
    //         },{
    //             headers: authHeader()
    //         })
    //         setIsUpdatingPreview(false);
    //     } catch (error) {
    //         console.log(error)
    //         setIsUpdatingPreview(false);
    //     }
    // };

    const getRangeFilterWhen = (preview) => {
        switch (preview) {
            case 'yesterday':
                return {
                    startDate: Moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
                }
            case 'current_week':
                return {
                    startDate: Moment(new Date()).clone().startOf('isoWeek').format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).format('YYYY-MM-DD')
                }
            case 'last_week':
                return {
                    startDate: Moment(new Date()).add(-1, 'w').weekday(1).format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).add(-1, 'w').weekday(7).format('YYYY-MM-DD')
                }
            case 'this_month':
                return {
                    startDate: Moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).endOf('month').format('YYYY-MM-DD')
                }
            case 'last_month':
                return {
                    startDate: Moment(new Date()).add(-1, 'M').startOf('month').format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).add(-1, 'M').endOf('month').format('YYYY-MM-DD')
                }
            default:
                return {
                    startDate: Moment(new Date()).format('YYYY-MM-DD'),
                    endDate: Moment(new Date()).format('YYYY-MM-DD')
                }

        }
    }

    const updateInfo = () => {
        const range = getRangeFilterWhen(userDetails?.initialPreview || '')
        let startDate = range.startDate;
        let endDate = range.endDate;
        
        setFilterDateStart(startDate)
        setFilterDateEnd(endDate)
        
        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }
        
        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }
    
        statsActions.getStats(startDate, endDate, teams, teammates);
        usersActions.refresh(userDetails);
        
    }

    useEffect(() => {
        updateInfo()        
    }, [])


    useEffect(() => {

        if (isMountedFilter.current) {

           updateInfo()
           
        } else {
            isMountedFilter.current = true;
        }

    }, [filter])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.today');
    });

    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar />
                <Filterbar 
                    startDate={filterDateStart} 
                    endDate={filterDateEnd}
                    widgetsSettings={true}
                    />
                {/* PAGE STARTS */}
                <div className="mainPage productivity">
                    <div className="column full">
                        {
                            !minimumTimeElapsed || loading
                                ? <TodayStatsSkeleton />
                                : <TodayStats />
                        }

                    </div>
                </div>
                <div className="mainPage productivity" style={{ paddingTop: '8px' }}>
                    <div className="column full">
                        {
                            !minimumTimeElapsed || loading
                                ? <TeammatesMoodSkeleton />
                                : <TeammatesMood />
                        }
                        {
                            !minimumTimeElapsed || loading
                                ? <WorkingTodaySkeleton />
                                : <WorkingToday />
                        }
                        {
                            !minimumTimeElapsed || loading
                                ? <WorkingAwesomelySkeleton />
                                : <WorkingAwesomely />
                        }

                    </div>
                </div>


                <div className="mainPage productivity" style={{ paddingTop: '8px' }}>
                    <div className="column full">
                        {
                            !minimumTimeElapsed || loading
                                ? <ActivitiesSkeleton />
                                : <Activities />
                        }
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>

            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal filters={filter} />
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        loadingStat: state.stats.loading,
        filter: state.filter,
        share: state.share,
        userDetails: state.authentication.userDetails,
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
