import { Box, CircularProgress, Container, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { DotsNine } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IconMainLogoV2 from '../../_components/_icons/IconMainLogoV2'
import { CardModule } from '../../_components/switch_page/CardModule'
import Header from '../../_components/switch_page/Header'

import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import { redirectToModule } from '../../_helpers/redirectToModule'
import { useCurrentModule } from '../../contexts/CurrentModuleContext'
import style from './SwitchPage.module.scss'
import { authHeader, befectiveFormatDate, delay, doCountAccessModule, history } from '../../_helpers'
import IconModuleProductivityManagement from '../../_components/_icons/IconModuleProductivityManagement'
import IconModuleGoalManagement from '../../_components/_icons/IconModuleGoalManagement'
import IconModuleVirtualPoint from '../../_components/_icons/IconModuleVirtualPoint'
import IconModuleCheckInRemote from '../../_components/_icons/IconModuleCheckInRemote'
import IconModuleSpaceManagement from '../../_components/_icons/IconModuleSpaceManagement'
import IconModuleProjectManagement from '../../_components/_icons/IconModuleProjectManagement'
import IconModuleInventory from '../../_components/_icons/IconModuleInventory'
import IconThreePoints from '../../_components/_icons/IconThreePoints'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { api } from '../../_helpers/api'
import SkeletonSwitchPage from '../../_components/switch_page/SkeletonSwitchPage'
import { IconButton } from '@material-ui/core'
import { Bookmark, BookmarkBorder, DragIndicator } from '@material-ui/icons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import AlertBox from '../../_components/_shared/AlertBox'

function SwitchPage({ userDetails, usersActions, alertActions }) {
  const { t, i18n } = useTranslation('common');

  const { removeCurrentModule, saveFirstAccess, getFirstAccess, saveCurrentModule } = useCurrentModule()


  // const modules = userDetails.modules || []
  const initialScreen = userDetails.initialScreen
  const defaultModule = userDetails.defaultModule
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState(null)
  const [sending, setSending] = useState(false);
  const lang = userDetails.language || i18n.language

  const getDataSwitchPage = async (isFetching = true) => {
    
    if (isFetching) {
      setFetching(true)
    }

    await api.get('/switch', { headers: authHeader() })
    .then((response) => {

      if (response.status !== 200) {
        throw new Error('Error to load data switch page')
      }

      setData(response.data)
      setFetching(false)
    })
    .catch(async (error) => {
      if(error.response && error.response.status == 401){
        alertActions.error(t("alerts.error.expired_token"))
        await delay(300)
        localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
        location.reload()
        return
      }
      console.log(error)
      setFetching(false)
    })
  }

  useEffect(() => {
    const firstAccess = localStorage.getItem('befective:firstAccess');

    if (defaultModule && firstAccess == 'true') {
      saveFirstAccess(false)
      if (userDetails.roleName !== 'collaborator') {
        
        redirectToModule(defaultModule, initialScreen,userDetails?.allowed_menu)
      } else {
        if (defaultModule === 'productivity_management') {
          history.push('/productive/productivity/')
        } else {
          redirectToModule(defaultModule, initialScreen)
        }
      }
    }

    getDataSwitchPage()

    document.title = 'Befective | Home ';

  }, [])

  const updateLastModule = async (module) => {
    try {
      await api.put(`/teammates/${userDetails?.mu_id}/update-last-access`, {
        last_module_name: module.name
      }, {
        headers: authHeader()
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleRedirectToModule(module) {

    saveCurrentModule(module.name)
    doCountAccessModule(module.name, api, authHeader)
    await updateLastModule(module)
    if (userDetails.roleName !== 'collaborator') {
      
      redirectToModule(module.name, initialScreen,userDetails?.allowed_menu)
    } else if (module.name === 'productivity_management') {
      history.push('/productive/productivity/')
    } else {
      redirectToModule(module.name, initialScreen)
    }
  }

  const getIconModule = (module_key, active) => {
    return {
      productivity_management: <IconModuleProductivityManagement active={active} />,
      goals_management: <IconModuleGoalManagement active={active} />,
      check_in_remote: <IconModuleVirtualPoint active={active} />,
      check_in_remote_mobile: <IconModuleCheckInRemote active={active} />,
      rooms_management: <IconModuleSpaceManagement active={active} />,
      project_management: <IconModuleProjectManagement active={active} />,
      inventory: <IconModuleInventory active={active} />
    }[module_key]
  }


  const handleMouseOver = (newsId) => {
    document.getElementById(`news-date-${newsId}`).style.display = 'none'
    document.getElementById(`news-see-more-${newsId}`).style.display = 'inline'
  }

  const handleMouseOut = (newsId) => {
    document.getElementById(`news-date-${newsId}`).style.display = 'inline'
    document.getElementById(`news-see-more-${newsId}`).style.display = 'none'
  }

  const handleFavoriteModule = async (module) => {
    try {
      setSending(true)
      const res = await api.put(`/teammates/${userDetails?.mu_id}/favorite-module`, {
        module_favorite: module ? module.name : null
      }, {
        headers: {
          'Authorization': authHeader().Authorization
        }
      })
      getDataSwitchPage(false)
      setSending(false)
      usersActions.refresh(userDetails);
    } catch (error) {
      console.log(error)
      setSending(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Define a posição do scroll para o topo quando a rota mudar
  }, [history.location.pathname]); // Dispara o efeito quando a rota mudar


  const handleDragEnd = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const moduleID = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const items = Array.from(data.modules_active);
    const [reorderedItem] = items.splice(source, 1);
    items.splice(destination, 0, reorderedItem);

    setData({
      ...data,
      modules_active: items
    });

    try {
      await api.patch(`/switch`, {
        module_id: moduleID,
        source: source,
        destination: destination,
      },
        {
          headers: authHeader()
        }
      )
    } catch (error) {
      console.log(error.response)
    }
  };

  return (
    <Box
      bgcolor="#F5F6F9"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />
      <Header />
      {
        (!fetching && data !== null)
          ?
          <Container component={'main'} maxWidth="lg" className={style.containerMain} sx={{ flexGrow: 1 }}>

            {
              (lang === 'pt' && data.banners_pt.length > 0) &&
              <Grid container spacing={2} mt={'16px'} mb={'28px'}>
                <Grid item xs={12} >
                  <Slide
                    autoplay={data.banners_pt.length > 1}
                    infinite={data.banners_pt.length > 1}
                    arrows={false}
                    duration={2000}
                  >
                    {
                      data.banners_pt.map(banner => (
                        <a href="https://www.befective.com/" target='_blank'>
                          <div className={style.eachSlideEffect}>
                            <div style={{ 'backgroundImage': `url(${banner.url})` }}></div>
                          </div>
                        </a>
                      ))
                    }
                  </Slide>
                </Grid>
              </Grid>
            }
            {
              (lang === 'es' && data.banners_es.length > 0) &&
              <Grid container spacing={2} mt={'16px'} mb={'28px'}>
                <Grid item xs={12} >
                  <Slide
                    autoplay={data.banners_es.length > 1}
                    infinite={data.banners_es.length > 1}
                    arrows={false}
                    duration={2000}
                  >
                    {
                      data.banners_es.map(banner => (
                        <div className={style.eachSlideEffect}>
                          <div style={{ 'backgroundImage': `url(${banner.url})` }}></div>
                        </div>
                      ))
                    }
                  </Slide>
                </Grid>
              </Grid>
            }
            {
              (lang === 'en' && data.banners_en.length > 0) &&
              <Grid container spacing={2} mt={'16px'} mb={'28px'}>
                <Grid item xs={12} >
                  <Slide
                    autoplay={data.banners_en.length > 1}
                    infinite={data.banners_en.length > 1}
                    arrows={false}
                    duration={2000}
                  >
                    {
                      data.banners_en.map(banner => (
                        <div className={style.eachSlideEffect}>
                          <div style={{ 'backgroundImage': `url(${banner.url})` }}></div>
                        </div>
                      ))
                    }
                  </Slide>
                </Grid>
              </Grid>
            }

            <Grid container spacing={2} component={'section'} marginTop={'4px'}>
              <Grid item xs={12} >
                <h6 className={style.titleSection}>{t('home_page.title_modules_active')}</h6>
              </Grid>
              <Grid item xs={12} >
                <table className={style.moduleActiveList}>
                  {
                    data.modules_active.length > 0
                      ?
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                              {
                                data.modules_active.map((module_active, index) => (

                                  <Draggable
                                    key={module_active.id}
                                    draggableId={String(module_active.id)}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <tr
                                        key={`module-active-${module_active.id}`}
                                        className={style.moduleActiveListItem}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <td style={{ border: 'none' }}  {...provided.dragHandleProps}>
                                          <DragIndicator style={{ cursor: 'grab' }} />
                                        </td>
                                        <td style={{ border: 'none' }} onClick={() => handleRedirectToModule(module_active)}>
                                          {getIconModule(module_active.name, true)}
                                        </td>
                                        <td className={style.boxModuleActiveName} style={{ border: 'none' }} onClick={() => handleRedirectToModule(module_active)}>
                                          {t(`switch.${module_active.name}`)}
                                        </td>

                                        <td style={{ border: 'none', width:'140px'}} className={style.boxModuleActiveVersion} onClick={() => handleRedirectToModule(module_active)}>
                                          <div className={style.circleActive}></div>
                                          <div className={style.numberVersion}>{module_active.version || '1.0.0'}</div>
                                        </td>

                                        <td style={{ border: 'none', width:'60px' }}>
                                          {
                                            userDetails?.origin_organization === userDetails?.organizationId && (

                                              module_active.is_favorite
                                                ?
                                                <Tooltip title={t('switch.unfavorite')} arrow>
                                                  <IconButton className={style.buttonFavorite} aria-label="favorite" size="small" onClick={() => handleFavoriteModule(null)} disabled={sending}>
                                                    <Bookmark style={{ fill: '#22BEAD' }} />
                                                  </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip title={t('switch.favorite')} arrow>
                                                  <IconButton className={style.buttonFavorite} aria-label="favorite" size="small" onClick={() => handleFavoriteModule(module_active)} disabled={sending}>
                                                    <BookmarkBorder style={{ fill: '#22BEAD' }} />
                                                  </IconButton>
                                                </Tooltip>
                                            )}
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                      :
                      <tbody>
                        <tr>{t('home_page.none_modules_active')}</tr>
                      </tbody>
                  }
                </table>
              </Grid>
            </Grid>

            {/* {
             (userDetails.roleName !== 'collaborator' && data.modules_not_active.length > 0) &&
              <Grid container spacing={2} component={'section'} marginBottom={'32px'}>
                <Grid item xs={12} >
                  <h6 className={style.titleSection}>{t('home_page.title_discover_also')}</h6>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="stretch">
                    {
                      data.modules_not_active.map(module_inactive => (
                        <Grid key={`module-inactive-${module_inactive.id}`} item xs={12} sm={3} display={'flex'} alignItems="stretch">
                          <div className={style.cardModuleInactive} onClick={() => history.push(`/module-details/${module_inactive.id}`)}>
                            <div className={style.cardHeader}>
                              {getIconModule(module_inactive.name, false)}
                              <h6 className={style.titleModule}>{t(`switch.${module_inactive.name}`)}</h6>
                            </div>
                            <p className={style.cardDescription}>
                              {module_inactive[`abstract_${userDetails.language || i18n.language}`]}
                            </p>
                            <hr className={style.divider} />
                            <div className={style.knowMore}>
                              {t('home_page.know_more')}
                            </div>
                          </div>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            } */}

            {
              data.news.length > 0 &&
              <Grid container spacing={2} component={'section'}>
                <Grid item xs={12} >
                  <h6 className={style.titleSection}>{t('home_page.title_lastest_news')}</h6>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="stretch">
                    {
                      data.news.map(news => (
                        <Grid key={`lastest-news-${news.id}`} item xs={12} sm={3} display={'flex'} alignItems="stretch">
                          <div
                            className={style.cardLastestNews}
                            onMouseOverCapture={() => handleMouseOver(news.id)}
                            onMouseOutCapture={() => handleMouseOut(news.id)}
                            onClick={() => history.push(`/news-details/${news.id}`)}
                          >
                            <div className={style.cardHeader} style={{ marginTop: '16px' }}>
                              <h6 className={style.titleModule}>{news[`title_${userDetails.language || i18n.language}`]}</h6>
                            </div>
                            <p className={style.cardDescription}>
                              {news[`summary_${userDetails.language || i18n.language}`]}
                            </p>
                            <hr className={style.divider} />
                            <div className={style.knowMore}>
                              <span id={`news-date-${news.id}`} style={{ display: 'inline' }}>
                                {befectiveFormatDate(news.release_date, i18n.language)}
                              </span>
                              <span id={`news-see-more-${news.id}`} style={{ display: 'none' }}>{t('home_page.read_more')}</span>
                            </div>
                          </div>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            }

          </Container>

          :

          <SkeletonSwitchPage />
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SwitchPage));