import React, { useEffect, useState } from 'react'
import style from './styles.module.scss'
import { Autocomplete } from '@material-ui/lab'
import { Chip, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Delete } from '@material-ui/icons';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  option: {
    fontSize: 15,
    '& > img': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function MoodsSettings({
  moodsOptions = [],
  moodsConfig = [],
  getData,
  alertActions
}) {
  const { t, i18n } = useTranslation('common');
  const [optionsSelected, setOptionsSelected] = useState(moodsConfig)
  const [optionsSelectedError, setOptionsSelectedError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const classes = useStyles();


  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setIsSubmitting(true)
      const res = await api.post('/settings/mood/list', {mood_list_id: optionsSelected.map(item => item.value)}, {headers: authHeader()})
      await getData(false)
      alertActions.success(t("moods_settings.success_save"))
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      alertActions.error(t("moods_settings.error_save"))
    }
  }


  return (
    <div className={style.card}>

      <h4 className={style.cardTitle}>{t("moods_settings.title")}</h4>

      <form className={style.formStatesMoods} onSubmit={handleSubmit}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="select-options-moods"
              fullWidth
              multiple
              autoHighlight
              disableCloseOnSelect
              options={moodsOptions}
              classes={{
                option: classes.option
              }}
              onChange={(e, values) => {
                if(values.length <= 6){
                  setOptionsSelected(values)
                }
              }}
              value={optionsSelected}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              renderOption={(option) => (
                <>
                  <img src={`${option.url_image}`} alt={option.label} width={34} height={34}/>
                  {option.label}
                </>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    avatar={<img src={`${option.url_image}`} alt={option.label} width={34} height={34}/>}
                    label={option.label}
                    onDelete={() => { }}
                    {...getTagProps({ index })}
                    variant='outlined'
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("moods_settings.select")}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  error={optionsSelectedError}
                  helperText={t("moods_settings.limit")}
                />
              )}
            />

          </Grid>
          <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
            <button className={style.buttonAdd} disabled={(optionsSelected.length === 0 || isSubmitting)}>
              { !isSubmitting ? t("moods_settings.save") : <CircularProgress size={16} />}
            </button>
          </Grid>
        </Grid>
      </form>

      {/* <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

      <div>
        <h4 className={style.cardTitle}>Estados de ânimos adicionados</h4>
        {
          moodsConfig.length > 0
            ?
            <List>
              {
                moodsConfig.map(item => (
                  <ListItem key={item.key}>
                    <ListItemAvatar>
                      <img src="https://picsum.photos/32/32" alt="Moods" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item[`title_${i18n.language || 'en'}`]}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <Delete color='error' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
            :
            <Typography variant='caption' fontSize={"14px"} color={"#4F7091"} component={'span'}>Nenhum estado de ânimo adicionado</Typography>
        }
      </div> */}

    </div>
  )
}
