import React, { useEffect, useState } from 'react'
import styles from './ActivitiesHeader.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import ModalGroupActivities from './ModalGroupActivities';
import { alertActions } from '../../_actions';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  customLabel: {
    fontSize: '10px'
  }

}));

export default function ActivitiesHeader({
  selectedRows,
  setSelectedRows,
  activities,
  alertActions,
  getActivitiesData,
  categories,
  userDetails
}) {
  const { t } = useTranslation('common');
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalGroupActivities, setOpenModalGroupActivities] = useState(null);
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeActivity = (trackedId) => {
    setActivitiesSelected(activitiesSelected.filter((t) => t.trackedId !== trackedId))
    setSelectedRows(selectedRows.filter(r => r !== trackedId))
  }

  const handleOpenModalGroupActivities = () => {
    setOpenModalGroupActivities(true)
    handleClose()
  }

  useEffect(() => {
    setActivitiesSelected(activities.filter(t => selectedRows.includes(t.trackedId)))
  }, [selectedRows])

  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{t('settings.activities.title')}</h6>
        </div>
      </div>
      <div className={styles.boxActions}>
        {
          userDetails.allowed_update && (
            <Button disableElevation aria-describedby={id} variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMore />} className={classes.button} disabled={!(selectedRows.length > 0)}>
              {`${t('settings.teams.actions')}`}
            </Button>
          )
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#1E6B7F',
              marginTop: '10px'
            }
          }}
        >
          <Box className={styles.menuOptionsActions}>
            <button className={styles.menuOptionsActionButton} onClick={handleOpenModalGroupActivities}>{t('settings.activities.action_group_activities')}</button>
          </Box>
        </Popover>
      </div>

      <ModalGroupActivities
        openModalGroupActivities={openModalGroupActivities}
        setOpenModalGroupActivities={setOpenModalGroupActivities}
        alertActions={alertActions}
        getActivitiesData={getActivitiesData}
        activitiesSelected={activitiesSelected}
        setSelectedRows={setSelectedRows}
        removeActivity={removeActivity}
        categories={categories}
        userDetails={userDetails}
      />
    </header>
  )
}
