import { machineUsersConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('machine_user'));

let base64Url = null;
let base64 = null;
let machineUserDetails = null;


if (user && user.accessToken) {

    base64Url = user.accessToken.split('.')[1];
    base64 = base64Url.replace('-', '+').replace('_', '/');
    machineUserDetails = JSON.parse(window.atob(base64));

}

const initialState = user ? { loggedIn: true, user, machineUserDetails: machineUserDetails} : {};


export function authentication_mu(state = initialState, action) {

    switch (action.type) {
        case machineUsersConstants.LOGIN_REQUEST:


            return {
                loggingIn: true,
                user: action.user,
                machineUserDetails: machineUserDetails
            };
        case machineUsersConstants.LOGIN_SUCCESS:

            base64Url = action.user.accessToken.split('.')[1];
            base64 = base64Url.replace('-', '+').replace('_', '/');
            machineUserDetails = JSON.parse(window.atob(base64));
            return {
                loggedIn: true,
                user: action.user,
                machineUserDetails: machineUserDetails
            };
        case machineUsersConstants.LOGIN_FAILURE:
            return { loggedIn: false, };
        case machineUsersConstants.LOGOUT:
            return {};
        case machineUsersConstants.LOGIN_REFRESH_SUCCESS:

            base64Url = action.user.accessToken.split('.')[1];
            base64 = base64Url.replace('-', '+').replace('_', '/');
            machineUserDetails = JSON.parse(window.atob(base64));

            return {
                loggedIn: true,
                user: action.user,
                machineUserDetails: machineUserDetails
            };

        case machineUsersConstants.GENERATE_PASSWORD_SUCCESS:
            return state
            
        default:
            return state
    }
}