import React, { useState } from 'react'
import Moment from 'moment';
import { makeStyles, Popover, Typography } from '@material-ui/core'
import { DotsThreeVertical, X } from 'phosphor-react';
import { api } from '../../../_helpers/api'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import { bindActionCreators } from 'redux';
import { alertActions } from '../../../_actions';
import { connect } from 'react-redux';
import AlertBox from '../../_shared/AlertBox';
import Avatar from 'react-avatar';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '20px',
    height: '20px',
  },
  typography_popover: {
    backgroundColor: '#fdbe2b',
  }
}));

function Comment({ user_id, full_name, org_id, commentData, showOptions, alertActions }) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false)
  const [newCommentText, setNewCommentText] = useState(commentData.value)
  const [currentText, setCurrentText] = useState(commentData.value)

  const open_popover = Boolean(anchorEl);
  const id_popover = open_popover ? 'simple-popover' : undefined;

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  function handleSetIsEditing() {
    setIsEditing(!isEditing)
    handleClosePopover()
  }

  async function handleUpdateComment(event) {
    try {
      event.preventDefault()

      await api.put(`/support_calls/comments/${commentData.id}?organization_id=${org_id}&owner_id=${user_id}`, {
        value: newCommentText
      })

      setIsEditing(false)
      setCurrentText(newCommentText)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('calls_page.error.update_comment'))
    }
  }

  return (
    <>
      <AlertBox />
      <div className="drawer-comments-container">
        <div className="drawer-header-comments">
          <div className="drawer-user-info">
            <Avatar 
              name={user_id === commentData.owner_id ? full_name : 'Be Fective'} 
              round
              color={user_id === commentData.owner_id ? '#bdbdbd' : '#24677E'}
              size={20} 
            />
            <span>{Moment(commentData.made_in).format('DD/MM/YYYY')}</span>
          </div>
          
          {isEditing ? (
            <X
              size={20}
              color="#98ACC1"
              weight="bold"
              onClick={handleSetIsEditing}
            />
          ) : (
            user_id === commentData.owner_id 
            && showOptions &&
            <DotsThreeVertical
              size={20}
              color="#98ACC1"
              weight="bold"
              onClick={handleClickPopover}
            />
          )}

        </div>

        {isEditing ? (
          <form onSubmit={handleUpdateComment}>
            <input
              autoFocus
              type="text"
              value={newCommentText}
              onChange={(event) => setNewCommentText(event.target.value)}
            />
          </form>
        ) : (
            <p>{currentText}</p>
        )}
      </div>

     <Popover
          id={id_popover}
          open={open_popover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography className={classes.typography_popover}>
            <button 
              type="button"
              className='edit-comment-button' 
              onClick={handleSetIsEditing}
            >
            {t('calls_page.edit')} 
            </button>
          </Typography>
        </Popover>
    </>
  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default connect(mapDispatchToProps)(Comment)