import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CardsHeaderColabDetails } from '../../_components/_shared/inventory/colab_details/CardsHeader';
import { ResumeColab } from '../../_components/_shared/inventory/colab_details/ResumeColab';
import TableHistoryColab from '../../_components/_shared/inventory/colab_details/TableHistoryColab';
import InventoryColabDetailsSkeleton from '../../_components/_shared/inventory/colab_details/InventoryColabDetailsSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function InventoryColabsDetailsPage(props) {
  const classes = useStyles();

  const { id } = useParams()

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState(null);

  async function getInventoryColab() {
    try {
      setFetching(true)
      const response = await api.get(`/inventory/colab/${id}`, {
        headers: authHeader()
      })

      if (response.status === 200) {
        setData(response.data)
        setFetching(false)
      }

    } catch (error) {
      console.log(error)
      // alertActions.error('Erro ao carregar os dados')
    }
  }

  useEffect(() => {
    getInventoryColab()
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          showShareBtn={false}
          routeBackToPage={'/inventory/colabs'}
        />

        <div className={style.containerCheckinPage}>
          {
            !fetching ?
              <>
                <CardsHeaderColabDetails alertActions={alertActions} userDetails={userDetails} resume={data?.stats} />
                <ResumeColab alertActions={alertActions} userDetails={userDetails} resume={data} />
                <TableHistoryColab data={data?.data} />
              </>
              :
              <InventoryColabDetailsSkeleton />
          }
        </div>


      </section>

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryColabsDetailsPage)