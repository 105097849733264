import { Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CaretCircleDoubleUp, CheckCircle, Prohibit, Warning } from 'phosphor-react';
import style from './CardsHeader.module.scss';

export function CardsHeaderControlSolicitations({ stats, getSolicitations }) {
  const { t, i18n } = useTranslation('common');

  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none', cursor: 'pointer' }} className={style.cardContainer} onClick={() => getSolicitations('', 'WAITING')}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #FDBE2B` }}>
          <Warning size={18} color='#FDBE2B' />
          <Typography color={"#FDBE2B"} component="span" fontSize={20} mt={1}>
            {stats?.total_waiting}
          </Typography>
          <Typography color={"#FDBE2B"} component="span" fontSize={12}>
            {t("checkin.control_points.header_cards_solicitations.waiting")}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', cursor: 'pointer', position: 'relative' }} className={style.cardContainer} onClick={() => getSolicitations('', 'APPROVED')}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <CheckCircle size={18} color="#22BEAD" />
          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {stats?.total_approved}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
          {t("checkin.control_points.header_cards_solicitations.approved")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none', cursor: 'pointer' }}
        className={style.cardContainer}
        onClick={() => getSolicitations('', 'DINIED')}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <Prohibit size={18} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {stats?.total_denied}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
          {t("checkin.control_points.header_cards_solicitations.dinied")}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none'}}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <CaretCircleDoubleUp size={18} color="#4F7091" />

          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {stats?.total_general}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
          {t("checkin.control_points.header_cards_solicitations.total")}
          </Typography>

        </CardContent>
      </Card>

    </Stack>
  );
}
