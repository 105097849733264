import { Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, withRouter } from "react-router-dom";
import { befectiveFormatDate } from '../../../../_helpers';
import './styles.scss'

const CustomTooltip = withStyles({
	tooltip: {
		color: "#106276",
		backgroundColor: "#FDBE2B",
		cursor: 'pointer'
	},
	arrow: {
		"&:before": {
			border: "1px solid #E6E8ED"
		},
		color: "#FDBE2B"
	}
})(Tooltip);

const Ruler = ({ data }) => {
	
	console.log('Ruler -> ', data)
	const { t, i18n } = useTranslation('common');

	const history = useHistory();
	const currentMonth = (new Date()).getMonth()
	const currentDay = (new Date()).getDate()
	const arrayMonths = []
	const goals = data?.goals || []

	const today = new Date(String(new Date().toISOString()).split('T')[0])

	const dateMin = moment.utc(data?.minDateGoal?.split('T')[0]) > moment.utc(today) && moment.utc(data?.minDateGoal?.split('T')[0]).year() === moment.utc(today).year() ? moment.utc(today) : moment.utc(data?.minDateGoal?.split('T')[0])
	const dateMax = moment.utc(data?.maxDateGoal?.split('T')[0]) < moment.utc(today) && moment.utc(data?.maxDateGoal?.split('T')[0]).year() === moment.utc(today).year() ? moment.utc(today) : moment.utc(data?.maxDateGoal?.split('T')[0])
	const duration = moment.duration(dateMax.diff(dateMin));
	const diffMonths = Math.ceil(duration.asMonths())


	const listColorsStatus = {
		completed: '#22BEAD',
		progress: '#FDA02B',
		planning: '#98ACC1',
		overdue: '#FF5151'
	}

	function monthDiff(d1, d2) {
		let months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	function generateMonths() {

		// let startDate = new Date(data?.minDateKR + 'T00:00:00')

		// let endDate = new Date(data?.maxDateKR + 'T00:00:00');
		// endDate.setFullYear(endDate.getFullYear() + 1);
		// let endDate = new Date();
		// endDate.setMonth(endDate.getMonth() + 7);
		// let startDate = new Date();
		// startDate.setMonth(startDate.getMonth() - 4);
		// console.log(endDate, startDate)

		let monthStart = dateMin.month()
		let startTranslatePos = 332

		for (let index = 0; index <= diffMonths + 1; index++) {
			if (monthStart === 0) {
				arrayMonths.push({ label: t('months_small.jan'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 1) {
				arrayMonths.push({ label: t('months_small.feb'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 2) {
				arrayMonths.push({ label: t('months_small.mar'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 3) {
				arrayMonths.push({ label: t('months_small.apr'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 4) {
				arrayMonths.push({ label: t('months_small.may'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 5) {
				arrayMonths.push({ label: t('months_small.jun'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 6) {
				arrayMonths.push({ label: t('months_small.jul'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 7) {
				arrayMonths.push({ label: t('months_small.aug'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 8) {
				arrayMonths.push({ label: t('months_small.sep'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 9) {
				arrayMonths.push({ label: t('months_small.oct'), translateX: startTranslatePos, index: monthStart })
			} else if (monthStart === 10) {
				arrayMonths.push({ label: t('months_small.nov'), translateX: startTranslatePos, index: monthStart })
			} else {
				arrayMonths.push({ label: t('months_small.dec'), translateX: startTranslatePos, index: monthStart })
			}

			monthStart++
			startTranslatePos += (100 * (12 / diffMonths))

			if (monthStart > 11) {
				monthStart = 0
			}

		}
	}
	generateMonths()
	let flagPositionX = 0

	if (arrayMonths.length > 0) {
		const currentDateMonth = moment().startOf('m').month()
		const nextMonthFirstDay = moment().add(1, 'month').startOf('m').month()
		const res = arrayMonths.filter(item => (item.index === currentDateMonth || item.index === nextMonthFirstDay))
		const percentMonthPast = (currentDay) / 30
		const startX = res[0]?.translateX
		const endX = res[1]?.translateX
		const difStarEnd = endX - startX
		const finalValue = startX + (difStarEnd * percentMonthPast)
		flagPositionX = finalValue - 320
	}

	function groupByKey(array, key) {
		return array
			.reduce((hash, obj) => {
				if (obj[key] === undefined) return hash;
				return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
			}, {})
	}



	let goalsPointsPositions = []

	if (goals?.length > 0) {

		const distinctDateEnd = goals.map(item => item.date_end).filter((value, index, self) => self.indexOf(value) === index)

		if (distinctDateEnd?.length) {

			let goalsAgroup = groupByKey(goals, 'date_end')

			distinctDateEnd?.forEach((date_end) => {

				const goal = goalsAgroup[date_end][0]

				const goalMonth = arrayMonths.filter(item => (item.index === moment.utc(goal.date_end).month()) || (item.index === moment.utc(goal.date_end).month() + 1))

				const startX = goalMonth?.length === 2 ? goalMonth[0]?.translateX : 0
				const endX = goalMonth?.length === 2 ? goalMonth[1]?.translateX : goalMonth[0]?.translateX
				const difStarEnd = endX - startX
				const finalValue = goalMonth?.length === 2 ? ((startX + difStarEnd) - 315) : (difStarEnd - 215)

				const dateEndGoal = new Date(goal?.date_end)

				let colorStatus = listColorsStatus.planning
				let goalProgress = Number(goal?.progress)

				if (goalProgress > 0 && goalProgress < 100 && !(today > dateEndGoal)) {
					colorStatus = listColorsStatus.progress
				} else if (goalProgress >= 100.00) {
					colorStatus = listColorsStatus.completed
				} else if (goalProgress > 0 && goalProgress < 100 && today > dateEndGoal) {
					colorStatus = listColorsStatus.overdue
				}

				let tooltipInfo = []
				for (const item of goalsAgroup[date_end]) {
					let nameGoal = String(item?.title)
					if (nameGoal.length > 20) {
						nameGoal = nameGoal.slice(0, 20) + '...'
					}
					tooltipInfo.push(<Typography variant='subtitle2'><span style={{ fontSize: 12 }}><strong style={{ fontSize: 12 }}>{`${t('goals.objective')}: `}</strong>{nameGoal}</span></Typography>)
				}
				goalsPointsPositions.push(
					<CustomTooltip title={<><Typography variant='subtitle2'><span style={{ fontSize: 12 }}><strong style={{ fontSize: 12 }}>{`${t('goals.ends_on')}: `}</strong>{befectiveFormatDate(dateEndGoal, i18n.language)}</span></Typography>{tooltipInfo}</>} arrow>
						<g transform={"translate(" + finalValue + ", 10)"} style={{ cursor: 'pointer' }}>
							<circle cx="10" cy="10" r="6" strokeWidth="0" fill={'#FDBE2B'} />
							{
								goalsAgroup[date_end].length > 1 && <text className="chk" x="6.5" y="14">+</text>
							}

						</g>
					</CustomTooltip>
				);

			})

		}

		// // let flagPositionX = 0
		// goals.forEach(goal => {
		// 	// console.log(moment.utc(goal.date_end).dayOfYear())
		// 	// const goalDayOfYear = moment.utc(goal.date_end).dayOfYear()
		// 	// const currentMonthObj = arrayMonths.find(item => (item.index === currentMonth))
		// 	// console.log(currentMonthObj)
		// 	const goalMonth = arrayMonths.filter(item => (item.index === moment.utc(goal.date_end).month()) || (item.index === moment.utc(goal.date_end).month() + 1))
		// 	// const percentMonthPast = moment.utc(new Date()).dayOfYear()
		// 	// console.log(percentMonthPast)
		// 	const startX = goalMonth?.length === 2 ? goalMonth[0]?.translateX : 0
		// 	const endX = goalMonth?.length === 2 ? goalMonth[1]?.translateX : goalMonth[0]?.translateX
		// 	const difStarEnd = endX - startX
		// 	// const percentMonthPast = (currentDay) / 30
		// 	const finalValue = goalMonth?.length === 2 ? ((startX + difStarEnd) - 320) : (difStarEnd - 220)
		// 	// // const difStarEnd = endX - startX + (1248 / goalDayOfYear)
		// 	// const finalValue = goalMonth?.translateX - 320 //(((1248 * goalDayOfYear) / (diffMonths * 30)) - percentMonthPast)
		// 	// // flagPositionX = finalValue - 320
		// 	const dateEndGoal = new Date(goal?.date_end).toLocaleDateString()

		// 	let colorStatus = listColorsStatus.planning
		// 	let goalProgress = Number(goal?.progress)

		// 	if (goalProgress > 0 && goalProgress < 100 && !(today > dateEndGoal)) {
		// 		colorStatus = listColorsStatus.progress
		// 	} else if (goalProgress >= 100.00) {
		// 		colorStatus = listColorsStatus.completed
		// 	} else if (goalProgress > 0 && goalProgress < 100 && today > dateEndGoal) {
		// 		colorStatus = listColorsStatus.overdue
		// 	}

		// 	goalsPointsPositions.push(
		// 		<Tooltip title={String(dateEndGoal) + ' : ' + goal?.title} arrow onClick={() => history.push(`/goals/details/${goal?.id}`)} style={{ cursor: 'pointer' }}>
		// 			<g transform={"translate(" + finalValue + ", 10)"} style={{ cursor: 'pointer' }}>
		// 				<rect x="-50px" y="-22px" height="18px" width="120px" rx="3" ry="3" fill={colorStatus} stroke={colorStatus} />
		// 				<text x="-46px" y="-10px" fill="#0D1F40" style={{ fontSize: 10, fontWeight: 500 }} >{goal?.title.substring(0, 20) + '...'}</text>
		// 				<circle cx="10" cy="10" r="6" strokeWidth="0" fill={colorStatus} />
		// 				{/* <line x1="15" y1="13" x2="15" y2="25" stroke="#FDBE2B" strokeWidth={3} /> */}
		// 			</g>
		// 		</Tooltip>
		// 	);

		// });
		// console.log(goalsPointsPositions)

	}
	return (

		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0 0 1248 35">
			<defs>
				<clipPath id="clip-path">
					<rect width="1194" height="12" fill="none" />
				</clipPath>
			</defs>

			<g id="Meses" transform="translate(-304 -308)">
				<g id="meses-2" data-name="meses" transform="translate(0 2)">
					{

						arrayMonths && (
							arrayMonths.map((item, index) => {
								return <text key={index} id={item.label} transform={"translate(" + item.translateX + " 326)"} fill="#98acc1" fontSize="10" fontFamily="Roboto-Regular, Roboto"><tspan x="-8.5" y="0">{item.label}</tspan></text>
							})
						)
					}
				</g>

				<g id="Repetição_de_grade_6" data-name="Repetição de grade 6" transform="translate(332 331)" clipPath="url(#clip-path)">
					<g id="Grupo_322" data-name="Grupo 322" transform="translate(-332 -331)">
						<rect id="Retângulo_1331" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334" data-name="Retângulo 1334" width="1" height="9" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-2" data-name="Grupo 322" transform="translate(-230 -331)">
						<rect id="Retângulo_1331-2" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-2" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-2" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-2" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-2" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-2" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-3" data-name="Grupo 322" transform="translate(-128 -331)">
						<rect id="Retângulo_1331-3" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-3" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-3" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-3" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-3" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-3" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-4" data-name="Grupo 322" transform="translate(-26 -331)">
						<rect id="Retângulo_1331-4" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-4" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-4" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-4" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-4" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-4" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-5" data-name="Grupo 322" transform="translate(76 -331)">
						<rect id="Retângulo_1331-5" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-5" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-5" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-5" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-5" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-5" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-6" data-name="Grupo 322" transform="translate(178 -331)">
						<rect id="Retângulo_1331-6" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-6" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-6" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-6" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-6" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-6" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-7" data-name="Grupo 322" transform="translate(280 -331)">
						<rect id="Retângulo_1331-7" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-7" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-7" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-7" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-7" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-7" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-8" data-name="Grupo 322" transform="translate(382 -331)">
						<rect id="Retângulo_1331-8" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-8" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-8" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-8" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-8" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-8" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-9" data-name="Grupo 322" transform="translate(484 -331)">
						<rect id="Retângulo_1331-9" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-9" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-9" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-9" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-9" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-9" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-10" data-name="Grupo 322" transform="translate(586 -331)">
						<rect id="Retângulo_1331-10" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-10" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-10" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-10" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-10" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-10" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-11" data-name="Grupo 322" transform="translate(688 -331)">
						<rect id="Retângulo_1331-11" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-11" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-11" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-11" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-11" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-11" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
					<g id="Grupo_322-12" data-name="Grupo 322" transform="translate(790 -331)">
						<rect id="Retângulo_1331-12" data-name="Retângulo 1331" width="1" height="7" transform="translate(332 336)" fill="#e4eaef" />
						<rect id="Retângulo_1332-12" data-name="Retângulo 1332" width="1" height="3" transform="translate(349 339)" fill="#e4eaef" />
						<rect id="Retângulo_1334-12" data-name="Retângulo 1334" width="1" height="6" transform="translate(366 337)" fill="#e4eaef" />
						<rect id="Retângulo_1335-12" data-name="Retângulo 1335" width="1" height="6" transform="translate(383 337)" fill="#e4eaef" />
						<rect id="Retângulo_1336-12" data-name="Retângulo 1336" width="1" height="6" transform="translate(400 337)" fill="#e4eaef" />
						<rect id="Retângulo_1337-12" data-name="Retângulo 1337" width="1" height="6" transform="translate(417 337)" fill="#e4eaef" />
					</g>
				</g>
				<rect id="Retângulo_1338" data-name="Retângulo 1338" width="1248" height="1" transform="translate(304 342)" fill="#e4eaef" />
				<rect id="Retângulo_1339" data-name="Retângulo 1339" width="1248" height="1" transform="translate(304 308)" fill="#e4eaef" />

			</g>
			{
				moment.utc(data?.minDateGoal?.split('T')[0]).year() === moment.utc(today).year() &&
				<g transform={"translate(" + flagPositionX + ",10)"} >
					<rect x="0px" y="-10px" height="18px" width="34px" rx="5" ry="5" fill="#FDBE2B" stroke="#FDBE2B" />
					<text x="5px" y="2" fill="#0D1F40" style={{ fontSize: 10 }} >{t('goals.today')}</text>
					<line x1="15" y1="13" x2="15" y2="25" stroke="#FDBE2B" strokeWidth={3} />
				</g>
			}
			{goalsPointsPositions}
		</svg>
	)
}


export default Ruler