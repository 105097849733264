import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { TableCell } from '@material-ui/core';
import { AddCircleOutline, Close, Edit, Sync } from '@material-ui/icons';
import { Avatar, Box, Button, Drawer, IconButton, Paper, Popover, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import moment from "moment";
import { ArrowRight, Info, ListBullets } from 'phosphor-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCalender3 from '../_icons/IconCalender3';
import IconIn from '../_icons/IconIn';
import IconOut from '../_icons/IconOut';


import { authHeader, befectiveFormatDate, calcDurationBTWArrPeriodsInMinutes, genPeriodSimpleArray, getAvatarUrl, getCorrectPeriod, getRemainingPoints, hoursToMinutes, secondsToHm } from "../../_helpers";
import { api } from "../../_helpers/api";
import style from './DashboardTable.module.scss';
import { ModalEditRemoteCheckin } from "./ModalEditRemoteCheckin";


export function DashboardTable({ alertActions, userDetails, data, getData }) {

  const { t, i18n } = useTranslation('common');
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [remoteTrackToEdit, setRemoteTrackToEdit] = useState(null)
  const [remoteTrackToIntegration, setRemoteTrackToIntegration] = useState(null)
  const [submittedIntegration, setSubmmitedIntegration] = useState(false)

  const [anchorElConfirmIntegration, setAnchorElConfirmIntegration] = useState(null);
  const openConfirmIntegration = Boolean(anchorElConfirmIntegration);
  const idConfirmIntegration = openConfirmIntegration ? 'popover-confirm-integration' : undefined;

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function handleOpenModalEdit(remote_track) {
    setRemoteTrackToEdit(remote_track)
    setOpenModalEdit(true)
  }

  function handleCloseModalEdit() {
    setOpenModalEdit(false)
    setRemoteTrackToEdit(null)
  }


  const handleCloseConfirmIntegration = () => {
    setRemoteTrackToIntegration(null)
    setAnchorElConfirmIntegration(null);
  };

  const handleClickConfirmIntegration = (event, remoteTrack) => {
    setRemoteTrackToIntegration(remoteTrack)
    setAnchorElConfirmIntegration(event.currentTarget);
  };

  const handleIntegrationRemoteTrack = async () => {
    try {
      setSubmmitedIntegration(true)
      const body = {
        mu_token: userDetails.mu_token,
        event_id: remoteTrackToIntegration.token,
        email: userDetails.email,
        start: remoteTrackToIntegration.records[0].datetime,
        end: remoteTrackToIntegration.records[1].datetime,
        platform: "remote_track",
        extra: remoteTrackToIntegration.observation
      }
      const res = await api.post(`/remote_track/integration?org_id=${userDetails.organizationId}`, body)
      handleCloseConfirmIntegration()
      await getData()
      alertActions.success(t("remote_checkin.success.integration"))
      setSubmmitedIntegration(false)
    } catch (error) {
      console.log(error)
      handleCloseConfirmIntegration()
      //alertActions.error(t("remote_checkin.errors.integration"))
      setSubmmitedIntegration(false)
    }
  }


  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none', marginTop: '10px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow sx={{ textTransform: "uppercase" }}>
              <TableCell className={style.tableHeaderTd} >{t("remote_checkin.dashboard.table.col_date")}</TableCell>
              <TableCell className={style.tableHeaderTd} style={{ width: '10%' }} align={'center'}>{t("remote_checkin.dashboard.table.col_in")}</TableCell>
              <TableCell className={style.tableHeaderTd} style={{ width: '10%' }} align={'center'}>{t("remote_checkin.dashboard.table.col_out")}</TableCell>
              <TableCell className={style.tableHeaderTd} align={'center'}>{t("remote_checkin.dashboard.table.col_status")}</TableCell>
              <TableCell className={style.tableHeaderTd} align={'center'}>{t("remote_checkin.dashboard.table.col_total_remote_checkin_integrated")}</TableCell>
              <TableCell className={style.tableHeaderTd} >{t("remote_checkin.dashboard.table.col_time")}</TableCell>
              <TableCell className={style.tableHeaderTd} >{t("remote_checkin.dashboard.table.col_obs")}</TableCell>
              <TableCell className={style.tableHeaderTd} style={{ width: '4%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {

              data?.length > 0
                ?
                data?.map(row => (
                  <TableRow className={style.tableRow} key={row.id}>
                    <TableCell>
                      {befectiveFormatDate(row.date_ref, i18n.language)}
                    </TableCell>

                    <TableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        padding={'6px 2px'}
                        margin="0 auto"
                        // minWidth={'0px'}
                        className={[
                          style.icon
                        ].join(' ')}
                      >
                        {moment(row.records[0].datetime).format('HH:mm')}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        padding={'6px 2px'}
                        margin="0 auto"
                        // minWidth={'40px'}
                        className={[
                          style.icon,
                          (row.status == 'COMPLETED' || moment().format('YYYY-MM-DD') !== row.date_ref) && style.iconHover
                        ].join(' ')}
                        onClick={() => (row.status == 'COMPLETED' || moment().format('YYYY-MM-DD') !== row.date_ref) && handleOpenModalEdit(row)}
                      >
                        {
                          row.status === 'COMPLETED'
                            ?

                            moment(row.records[1].datetime).format('HH:mm')

                            :
                            '--:--'
                        }
                      </Box>
                    </TableCell>
                    <TableCell align='center'>
                      {
                        row.status === 'COMPLETED'
                          ?
                          <Box className={style.boxStatus}>
                            <Box component='span' className={style.statusFinished}>
                              {t('remote_checkin.dashboard.table.finished')}
                            </Box>
                          </Box>
                          :
                          <Box className={style.boxStatus}>
                            <Box component='span' className={style.statusUnfinished}>
                              {t('remote_checkin.dashboard.table.unfinished')}
                            </Box>
                          </Box>
                      }
                    </TableCell>
                    <TableCell align='center'>
                      {
                        row.integrated
                          ?
                          t('remote_checkin.dashboard.table.value_yes')
                          :
                          t('remote_checkin.dashboard.table.value_no')
                      }
                    </TableCell>
                    <TableCell>
                      {secondsToHm(row.total_time_in_seconds)}
                    </TableCell>

                    <TableCell>
                      {
                        row.observation && row.observation.length > 20
                          ?
                          <Tooltip title={row.observation} arrow>
                            <span>{String(row.observation).slice(0, 20) + '...'}</span>
                          </Tooltip>
                          :
                          <span>{row.observation || ''}</span>
                      }
                    </TableCell>

                    <TableCell>
                      <IconButton onClick={(e) => (row.status === 'COMPLETED' && row.integrated === false) && handleClickConfirmIntegration(e, row)} size="small" aria-label="btnIntegration" disabled={row.status !== 'COMPLETED' || row.integrated === true} title={t('remote_checkin.dashboard.table.title_integration')}>
                        <Sync fontSize="inherit" style={{ fill: (row.status === 'COMPLETED' && row.integrated === false) ? '#187DFF' : '#4f709178' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                      {t('remote_checkin.dashboard.table.no_data')}
                    </Typography>
                  </TableCell>
                </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>
      {
        openModalEdit &&
        <ModalEditRemoteCheckin
          alertActions={alertActions}
          userDetails={userDetails}
          lang={lang}
          openModalEdit={openModalEdit}
          setOpenModalEdit={setOpenModalEdit}
          remoteTrack={remoteTrackToEdit}
          handleCloseModalEdit={handleCloseModalEdit}
          getData={getData}
        />
      }

      <Popover
        id={idConfirmIntegration}
        open={openConfirmIntegration}
        anchorEl={anchorElConfirmIntegration}
        onClose={handleCloseConfirmIntegration}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FFE3E5",
            boxShadow: "none",
            padding: '18px 14px',
            borderRadius: '6px',
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap="4px"  maxWidth={'320px'}>

          <Typography fontSize="12px" fontWeight={500} color="#FF737D" componente="span">{t('remote_checkin.dashboard.confirm_integration.title')}</Typography>
          <Typography fontSize="11px" variant="caption" color="#FF737D" componente="span">{t('remote_checkin.dashboard.confirm_integration.subtitle')}</Typography>

          <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
            <Button disabled={submittedIntegration} style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmIntegration}>
              <Typography component="span" fontSize={10} color="#FF737D">{t('remote_checkin.dashboard.confirm_integration.no')}</Typography>
            </Button>
            <Button disabled={submittedIntegration} style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={handleIntegrationRemoteTrack}>
              <Typography component="span" fontSize={10} color="#fff">{t('remote_checkin.dashboard.confirm_integration.yes')}</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
