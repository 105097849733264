import React, { useEffect, useState } from 'react'
import { IconButton, Menu } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { NumericFormat } from 'react-number-format'
import { NumberFormatBase } from 'react-number-format';

import './styles.scss'
import { formatCurrencyByEnd } from '../../../_helpers/formattValue';
export function InputValue({ anchorEl, handleCloseMenu, value, setValue, isUpdatingProgress, end_value, start_value, typeInput, setIsSubmitted, close = false }) {

  const [newValue, setNewValue] = useState(Number(value))

  const handleUpdateKeyEnter = (e) => {
    if (e.key === 'Enter') {
      setIsSubmitted(true)
      setValue(newValue)
      if (close) {
        handleCloseMenu()
      }
    }
  }

  function onChangeValue(value) {
    if (value === '') {
      setNewValue(0)
    }else {
      setNewValue(value)
    }

  }

  function handleCloseMenuEditValue() {
    setNewValue(0)
    setValue(null)
    handleCloseMenu()
  }

  function handleUpdateValue() {
    setIsSubmitted(true)
    setValue(newValue)
    if(close) {
      handleCloseMenu()
    }
  }

  useEffect(() => {
    setNewValue(Number(value))
  }, [value])

  return (
    <Menu
      className="menu-task-on-table-to-value"
      id={`menu-options-value`}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenuEditValue}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ horizontal: "center" }}
      style={{ padding: 10 }}
    >
      <span className='value-kr-info' tabIndex=''>{start_value > 0 && `${start_value} + `} {value} | {end_value}</span>
      <div className={`box-input-time ${isUpdatingProgress && 'disabled-div'}`}>
        {typeInput === 'float' ? (
          <NumberFormatBase
            format={formatCurrencyByEnd}
            allowNegative={false}
            fixedDecimalScale
            decimalScale={2}
            placeholder="0.00"
            inputMode="numeric"
            displayType="input"
            style={{ width: "70px", padding: '10px', fontSize: '16px' }}
            value={
              !newValue || !Number(newValue) ? "" : Number(newValue) * 100
            }
            isAllowed={values => {
              const { formattedValue, floatValue } = values;
              return formattedValue === "" || floatValue / 100 <= 10000000000;
            }}
            onValueChange={values => {
              if(values.value){
                onChangeValue((parseFloat(values.value) / 100).toFixed(2));
              }else {
                onChangeValue((0 / 100).toFixed(2))
              }
            }}
            onKeyDown={(e) => handleUpdateKeyEnter(e)}
          />
        ):(
          <NumericFormat
            value={newValue}
            name="progress"
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
            placeholder="0"
            style={{ width: "70px", padding: '10px', fontSize: '16px' }}
            onChange={(e) => onChangeValue(e.target.value)}
            onKeyDown={(e) => handleUpdateKeyEnter(e)}
          />
        )}
       
        <IconButton aria-label="save-value" size="small" onClick={() => handleUpdateValue()} >
          <DoneIcon />
        </IconButton>
        <IconButton aria-label="close" size="small" onClick={handleCloseMenuEditValue}>
          <CloseIcon />
        </IconButton>
      </div>
    </Menu>
  )
}
