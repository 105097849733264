import React, { useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({data}) => {

  const options = {
    chart: {
      type: 'donut',
      height: 100,
      width: 100,
    },
    series: [40, 30, 30],
    labels: ['Seção 1', 'Seção 2', 'Seção 3'],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div id="donut">
      <Chart options={data} series={data.series} type="donut" height={100} width={100}  />
    </div>
  );
};

export default DonutChart;