import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {NavLink, Link} from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Toolbar from '../_components/_shared/Toolbar'

import {useTranslation, initReactI18next} from "react-i18next";

import {downloadsActions} from '../_actions';
import Downloads from '../_components/downloads/Downloads'
import DownloadsSkeleton from '../_components/downloads/DownloadsSkeleton'

function DownloadPage(props) {
    const {t, i18n} = useTranslation('common');

    const {downloadsActions} = props;

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    let userDetails = props.userDetails || [];
    let loading = props.loading || false;

    let token = props.token || {};


    useEffect(() => {

        downloadsActions.getDownloads();

    }, [])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.user.download');
    });

    return (

        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <div className="mainFilterbar">

                </div>
                {/* PAGE STARTS */}
                <div className="mainPage profile">
                    <div className="nocolumn">
                        {
                            !minimumTimeElapsed || loading === true
                                ? <DownloadsSkeleton/>
                                : <Downloads/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>


        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        downloadsActions: bindActionCreators(downloadsActions, dispatch),

    }
}

function mapStateToProps(state) {

    return {
        filter: state.filter,
        loading: state.downloads.loading,
        downloads: state.downloads,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage)
