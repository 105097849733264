import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';


import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function ActivitiesProductivitySkeleton(props) {
    const {t, i18n} = useTranslation('common');


    return (
        <div className={'box graph'}>
            <h3><Skeleton variant="text" width={160} height={32}/></h3>
            <div className={'helper'}>
                <ul>
                    <Skeleton style={{marginRight: "8px"}} variant="text" width={100} height={22}/>
                    <Skeleton style={{marginRight: "8px"}} variant="text" width={100} height={22}/>
                    <Skeleton variant="text" width={100} height={22}/>
                </ul>
            </div>
            <div className={'piechart'}>
                <Skeleton variant="circle" width={380} height={380}/>
            </div>

        </div>
    )
};

export default ActivitiesProductivitySkeleton;
