import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import styles from './TableReportsChecklists.module.scss';
import { ArrowDown, ArrowRight, ArrowUp, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../_helpers';
import IconArrowRight from '../../_icons/IconArrowRight';

const TableReportsChecklists = ({ columns, data }) => {

  const { t } = useTranslation('common');

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  return (
    <div className={styles.tableContainer}>

      <table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: !['username', 'name'].includes(column.id) ? 'center' : 'left' }}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                })}
              </React.Fragment>
            ))}
            <th style={{ width: '0px' }} className={styles.headColTable} />
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          rows.length > 0
          ?
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowHover} onClick={(e) => {
                e.stopPropagation()
                history.push(`/productive/reports/checklists/sharing/${row.original.id}`)
              }}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()} style={{ textAlign: !['username', 'name'].includes(cell.column.id) ? 'center' : 'left', fontSize: '12px' }} >{cell.render("Cell")}</td>
                })}
                <td>
                  <div className={styles.boxActions}>
                    <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={(e) => history.push(`/productive/reports/checklists/sharing/${row.original.id}`)}>
                      <IconArrowRight />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })
          :
          <tr>
            <td colSpan={7}>{t('reports_checklists.table.no_data')}</td>
          </tr>
        }
        </tbody>
      </table>
    </div>
  );
};

export default TableReportsChecklists;
