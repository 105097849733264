import React from 'react';

const IconOutdoor = ({color = "#187dff", width = 16, height = 16}) => {
    
    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <g id="Grupo_47" data-name="Grupo 47" transform="translate(-4 -4)">

            <g id="icons_Q2" data-name="icons Q2" transform="translate(5.333 5)">
            <path id="Caminho_25" data-name="Caminho 25" d="M5.967,17h-1.3A.667.667,0,0,1,4,16.333V3.667A.667.667,0,0,1,4.667,3H15.333A.667.667,0,0,1,16,3.667V6.3a.7.7,0,0,1-.567.7.667.667,0,0,1-.767-.667v-2H5.333V15.667H6a.667.667,0,0,1,.667.767.7.7,0,0,1-.7.567Z" transform="translate(-4 -3)" fill={color}/>
            <rect id="Retângulo_29" data-name="Retângulo 29" width="6" height="1.333" rx="0.667" transform="translate(3 3.667)" fill={color}/>
            <path id="Caminho_26" data-name="Caminho 26" d="M23.8,20.456,20.533,17.19a.633.633,0,0,0-.933,0l-1.467,1.467H13.667a.667.667,0,1,0,0,1.333H16.8l-1.333,1.333h-1.8a.667.667,0,1,0,0,1.333h1v3a.667.667,0,0,0,.667.667H18.6a.667.667,0,0,0,.467-.2L23.8,21.39a.633.633,0,0,0,0-.933ZM18.3,24.99H16v-2.3l2.133-2.133,2.3,2.3Zm3.067-3.067-2.3-2.3,1-1.033L22.4,20.923Z" transform="translate(-10 -12.323)" fill={color}/>
            </g>
        </g>
        </svg>


    )
}

export default IconOutdoor;