import { TableCell, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Avatar, Box, Button, Drawer, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from "moment";
import { ArrowRight, Warning } from 'phosphor-react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import IconIn from '../../_icons/IconIn';
// import IconOut from '../../_icons/IconOut';


import { authHeader, befectiveFormatDate, getAvatarUrl, getDayWeekV2, history, hoursToMinutes } from "../../../_helpers";
import { api } from "../../../_helpers/api";

// import ModalApproveSolicitation from '../ModalApproveSolicitation';
import style from './styles.module.scss';
// import { DrawerSolicitationsControlPoints } from './DrawerSolicitations';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]


export function TableGroups({ alertActions, userDetails, groups = [], getDashboardInfo, filter }) {

  const { t, i18n } = useTranslation('common');

  function MakeStringWeekDays(arr) {
    if(arr.length === 0){
      return "Not setting"
    }

    const str_ = arr.reduce((acc,curr)=>{
      return acc+getDayWeekV2(curr.day_week,true,t)+", "
    },"").slice(0,-1)

    return str_
  }

  return (
    <>
      <TableContainer id='table-groups' className='container-table-control' component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <Table className='table-control' sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow sx={{ textTransform: 'uppercase' }}>
              <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px' }} width='15%'>{t("settings.teams.team")}</TableCell>
              {/* <TableCell className={style.tableHeaderTd} align="left">{t("checkin.control_points.table.col_points")}</TableCell> */}
              <TableCell className={style.tableHeaderTd} align="center" width="20%">{t("settings.teams.admin")}</TableCell>
              <TableCell className={style.tableHeaderTd} align="center" width="10%">{t("settings.teams.colabs")}</TableCell>
              <TableCell className={style.tableHeaderTd} style={{ paddingRight: '20px' }} width={'10%'} align="right">{t("settings.teams.work_week")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {groups?.map(team => (
              <TableRow  tag="group_name" key={team.id} className='table-row' onClick={() => {                
                history.push(`/settings/virtual-point/teams/${team.id}`)
              }}>
                <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                  {team.name}
                </TableCell>

                <TableCell tag="admin_name" style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="center">
                  {team.admin_name || "------"}
                </TableCell>
                <TableCell
                  tag="total_colabs"
                  style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}
                  align="center"
                >
                  {team.total_colabs}
                </TableCell>
                <TableCell tag="working_hours" style={{ fontSize: '12px', paddingRight: '20px', borderBottom: '2px solid #F5F6F9' }} align="right"> 
                  
                  {MakeStringWeekDays(team.working_hours || [])}
                 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer >
    </>

  );
}
