import React from 'react';

const IconTeams = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_11" data-name="Retângulo 11" width="24" height="24" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(1 3)">
            <path id="Caminho_8" data-name="Caminho 8" d="M10.5,15A4.5,4.5,0,1,0,6,10.5,4.5,4.5,0,0,0,10.5,15Zm0-7A2.5,2.5,0,1,1,8,10.5,2.5,2.5,0,0,1,10.5,8Z" transform="translate(-4 -6)" fill="#97b3bf"/>
            <path id="Caminho_9" data-name="Caminho 9" d="M31.5,17A3.5,3.5,0,1,0,28,13.5,3.5,3.5,0,0,0,31.5,17Zm0-5A1.5,1.5,0,1,1,30,13.5,1.45,1.45,0,0,1,31.5,12Z" transform="translate(-15 -8)" fill="#97b3bf"/>
            <path id="Caminho_10" data-name="Caminho 10" d="M24,27.7l-.45-.3a9.8,9.8,0,0,0-10.1,0l-.4.3A12.3,12.3,0,0,0,9,27a11.7,11.7,0,0,0-6.55,1.95l-.45.3V33a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V32h7a1,1,0,0,0,1-1ZM14,32H4v-1.65A9.75,9.75,0,0,1,9,29a9.75,9.75,0,0,1,5,1.35Zm8-2H16v-.75l-.45-.3-.3-.2a7.65,7.65,0,0,1,6.75.1Z" transform="translate(-2 -15.999)" fill="#97b3bf"/>
        </g>
        </svg>
 

    )
}

export default IconTeams;