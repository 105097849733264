import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function MapLocations({ markers }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const { t, i18n } = useTranslation('common');
  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(3)
  const [center, setCenter] = useState({ lat: -5.112764, lng: -55.530247 });

  const handleActiveMarker = (marker, location) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setZoom(6)
    setCenter(location);
  };

  const handleOnLoad = (map) => {
    setMap(map)
    const bounds = new google.maps.LatLngBounds();
    if (markers.length > 0) {
      markers.forEach(({ location }) => bounds.extend(location));
      map.fitBounds(bounds);
    } else {
      bounds.extend({
        lat: -5.112764,
        lng: -55.530247
      });
      map.fitBounds(bounds);
    }

  };

  const resetAll = () => {
    setActiveMarker(null)
    setMap(null)
    setCenter({ lat: -5.112764, lng: -55.530247 })
    setZoom(3)
  }

  useEffect(() => {
    if (markers) {
      setZoom(3)
      setCenter({ lat: -5.112764, lng: -55.530247 })
      setMap(null)
      setActiveMarker(null)
    }
  }, [markers])

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      // onClick={resetAll}
      mapContainerStyle={{ width: "100%", height: "75vh" }}
      // zoom={3}
      options={{
        zoom: zoom,
        center: center
      }}
    >
      {markers.map(({ mu_id, alias, location, status, observation }) => (
        <Marker
          // label={alias}
          key={mu_id}
          position={location}
          // onClick={() => handleActiveMarker(mu_id)}
          onClick={() => {
            handleActiveMarker(mu_id, location)
          }}
          icon={{
            url: `https://ui-avatars.com/api/?background=24677E&color=ffffff&name=${encodeURI(alias)}`,
            scaledSize: { width: 32, height: 32 }
          }}
        >
          {activeMarker === mu_id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <div>{`${t("remote_checkin.label_name")}: ${alias}`}</div>
                <div>{`${t("remote_checkin.label_status")}: ${status === 'COMPLETED' ? t("remote_checkin.status_finished") : t("remote_checkin.status_unfinished")}`}</div>
                <div>{`${t("remote_checkin.label_obs")}: ${observation || '----'}`}</div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default MapLocations;
