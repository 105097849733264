import { Skeleton } from '@material-ui/lab'
import React from 'react'


import './styles.scss'

export function CommentSkeleton() {
  return (
    <div className="drawer-comments-container-skeleton">
      <div className="drawer-header-comments-skeleton">
        <div className="drawer-user-info-skeleton">
          <Skeleton variant="circle" width="17px" height={17} />
          <Skeleton variant="text" width="30%" height={17} />
        </div>
      </div>
     
      <Skeleton variant="text" width="100%" height={30} />
    </div>
    
  )
}
