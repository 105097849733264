import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';

import style from './ModalImportTeamStructure.module.scss';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalImportTeamStructure({
  openModalImportTeamStructure,
  setOpenModalImportTeamStruture,
  alertActions,
  setAnchorEl,
  getTeamsData
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);

  const [form, setForm] = useState({
    file: null
  })
  const [formErrors, setFormErrors] = useState({
    file: null
  })

  const onChangeInputFile = (e) => {
    const file = e.target.files[0];
    setForm({ ...form, file: file });
    setFormErrors({ ...formErrors, file: null })
  }

  const removeFile = (file) => {
    setForm({ ...form, file: form.file.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};
    if (!form.file) {
      errors.file = t('settings.teams.modal_import_structure_teams.file_required')
    }

    if (form.file) {

      const acceptFiles = ['XLSX']

      if ((form.file.size / 1000000) > 2) {
        errors.file = t('settings.teams.modal_import_structure_teams.size_invalid_file')
      }

      const fileNameSplitted = form.file.name.split('.')
      const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

      if (!acceptFiles.includes(fileExtension.toUpperCase())) {
        errors.file = t('settings.teams.modal_import_structure_teams.type_invalid_file')
      }

    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      file: null
    })

    setFormErrors({
      file: null
    })
  }

  async function importTeamStructure() {
    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('files', form.file)

      const res = await api.post(
        `/settings/import-structure`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        }
      )

      handleCloseModalImportTeamStructure()
      getTeamsData({ pageIndex: 0, pageSize: 20, isLoading: false })
      alertActions.success(t("settings.teams.success.import_structure_teams"))
    } catch (error) {
      console.log(error)
      alertActions.error(error.response.data.message || t("settings.teams.error.import_structure_teams"))
    }finally{
      setIsSubmitting(false)
    }
  }

  function handleCloseModalImportTeamStructure() {
    setOpenModalImportTeamStruture(false)
    resetForm()
    setAnchorEl(null)
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      importTeamStructure()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalImportTeamStructure}
      onClose={handleCloseModalImportTeamStructure}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalImportTeamStructure} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="32px"
          px="26px"
          borderRadius="8px"
          width={380}
          component="form"
          noValidate
          autoComplete='off'
        >
          <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
            {t("settings.teams.modal_import_structure_teams.title")}
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>

            <Box display="flex" flexDirection="column" mt={1}>


              <ul className={style.listInfo}>
                <li className={style.listItemInfo}>
                  {`${t("settings.teams.modal_import_structure_teams.info_1")}`}
                  <a target='_blank' href="https://befective-staging.s3.amazonaws.com/plan_model_structure_teams.xlsx" download>link.</a>
                </li>
                <li className={style.listItemInfo}>
                  {t("settings.teams.modal_import_structure_teams.info_2")}
                </li>
                <li className={style.listItemInfo}>
                  {t("settings.teams.modal_import_structure_teams.info_4")}
                </li>
                <li className={style.listItemInfo} style={{color:'#FF737D'}}>
                  {t("settings.teams.modal_import_structure_teams.info_3")}
                </li>
              </ul>
              <label className={style.inputFileBox}>
                <input
                  type="file"
                  onChange={onChangeInputFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <AttachFile fontSize='small' />
                <div style={{ color: '#205B6F' }}>
                  {
                    !form.file
                      ?
                      t("settings.teams.modal_import_structure_teams.placeholder_file")
                      :
                      form.file.name.length > 40
                        ?
                        <span title={form.file.name}>
                          {`${form.file.name.slice(0, 20)} [...] ${form.file.name.slice(form.file.name.length - 20, form.file.name.length)}`}
                        </span>
                        :
                        form.file.name

                  }
                </div>
              </label>
              {formErrors.file &&
                <div
                  className={style.error}
                  style={{ marginTop: "2px", marginBottom: 0 }}
                >
                  {formErrors.file}
                </div>
              }
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px" marginTop={'20px'}>
            <Button
              style={{
                backgroundColor: '#E3EFFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.cancelBtn}
              onClick={handleCloseModalImportTeamStructure}
            >
              <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>
                {t("settings.teams.modal_import_structure_teams.cancel")}
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor: '#187DFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.save}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  component="span"
                  fontSize={12}
                  color='#fff'
                  textTransform="initial"
                  fontWeight={500}
                >
                  {t("settings.teams.modal_import_structure_teams.import")}
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
