// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MEDJlIa70nRPhp2EACrRIg\\=\\= {\n  background-color: #ffffff;\n  border-radius: 2px;\n  padding: 28px;\n}\n\n._0SxnXs29d7ac9fvwbUAiZA\\=\\= {\n  margin: 0px 0px 20px;\n  color: #1E6B7F;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.rDcUCrnDcx1J\\+7mNG4Fvfw\\=\\= {\n  padding: 16px 32px;\n  background-color: #187DFF;\n  color: #ffffff;\n  font-weight: 500;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s;\n}\n\n.rDcUCrnDcx1J\\+7mNG4Fvfw\\=\\=:hover {\n  background-color: #5ea0f7;\n}\n\n.rDcUCrnDcx1J\\+7mNG4Fvfw\\=\\=:disabled {\n  background-color: #E0E0E0;\n  color: rgba(0, 0, 0, 0.2588235294);\n  cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://./src/_components/moods_settings/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,oBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EAEE,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,kCAAA;EACA,mBAAA;AAAF","sourcesContent":[".card {\n  background-color: #ffffff;\n  border-radius: 2px;\n  padding: 28px;\n}\n\n.cardTitle{\n  margin: 0px 0px 20px;\n  color: #1E6B7F;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.buttonAdd{\n  // width: 100%;\n  padding: 16px 32px;\n  background-color: #187DFF;\n  color: #ffffff;\n  font-weight: 500;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s;\n}\n\n.buttonAdd:hover{\n  background-color: #5ea0f7;\n}\n\n.buttonAdd:disabled{\n  background-color: #E0E0E0;\n  color: #00000042;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "MEDJlIa70nRPhp2EACrRIg==",
	"cardTitle": "_0SxnXs29d7ac9fvwbUAiZA==",
	"buttonAdd": "rDcUCrnDcx1J+7mNG4Fvfw=="
};
export default ___CSS_LOADER_EXPORT___;
