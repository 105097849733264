import { TableCell, makeStyles } from '@material-ui/core';
import { Apartment, Close, Home } from '@material-ui/icons';
import { Avatar, Box, Button, Drawer, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from "moment";
import { ArrowRight, Warning } from 'phosphor-react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconIn from '../../_icons/IconIn';
import IconOut from '../../_icons/IconOut';


import { authHeader, befectiveFormatDate, calcDurationBTWArrPeriodsInMinutes, getAvatarUrl, getCorrectPeriod, getRemainingPoints, hoursToMinutes } from "../../../_helpers";
import { api } from "../../../_helpers/api";

import ModalApproveSolicitation from '../ModalApproveSolicitation';
import style from './CheckinTable.module.scss';
import { DrawerSolicitationsControlPoints } from './DrawerSolicitations';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]


export function CheckinTableControl({ alertActions, userDetails, colabsList, getDashboardInfo, filter }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const [form, setForm] = useState({
    extra_comment: ''
  })

  const [pointDetails, setPointDetails] = useState(null)
  const [openDrawerDetailsDay, setOpenDrawerDetailsDay] = useState(false)

  const [openModalApprove, setOpenModalApprove] = useState(false)
  const [currentColab, setCurrentColab] = useState(null)
  const [isReprove, setIsReprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [solicitations, setSolicitations] = useState([]);
  const [openDrawerSolicitations, setOpenDrawerSolicitations] = useState(false)

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }


  function calcDurationInMinutes(start, end) {
    const startHour = moment(start, "HH:mm:ss");
    const endHour = moment(end, "HH:mm:ss");
    const diffInMiliSeconds = endHour.diff(startHour);
    const durationInMinutes = moment.duration(diffInMiliSeconds).asMinutes();
    return durationInMinutes;
  }

  function checkIfIsAfterTime(period, checkStart = true) {
    const now = moment();

    if (checkStart) {
      const start = moment(period.start_time, 'HH:mm:ss');

      if (now.isAfter(start)) {
        return true
      } else {
        return false
      }
    } else {
      const end = moment(period.end_time, 'HH:mm:ss');

      if (now.isAfter(end)) {
        return true
      } else {
        return false
      }
    }
  }

  const handleOpenModalApprove = (colab) => {
    setCurrentColab(colab)
    setOpenModalApprove(true)
  }

  const handleCloseModalApprove = () => {
    setOpenModalApprove(false)
    setCurrentColab(null)
    setIsReprove(false)
  }

  async function getPointDetails(day, owner_id, group_id) {
    try {
      const response = await api.get(`/checkin/point/details?owner_id=${owner_id}&group_id=${group_id}&day=${day}`, {
        headers: authHeader()
      })

      setPointDetails(response.data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_details_point_error"))
    }
  }


  async function handleOpenDrawerSolicitations() {
    try {
      let date = moment(new Date()).format('YYYY-MM-DD');

      if (filter.singleDate) {
        date = filter.singleDate
      }

      const response = await api.get(`/checkin/colab/solicitations/${currentColab.owner_id}?day_ref=${date}`, {
        headers: authHeader()
      })

      setSolicitations(response.data)
      setOpenDrawerSolicitations(true)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_solicitations_points_error"))
    }
  }

  function handleOpenDrawerDetailsDay(day, owner_id, group_id, colab) {
    getPointDetails(day, owner_id, group_id)
    setCurrentColab(colab)
    setOpenDrawerDetailsDay(true)
  }

  return (
    <>
      <TableContainer className='container-table-control' component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <Table className='table-control' sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow sx={{ textTransform: 'uppercase' }}>
              <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px' }} width='15%'>{t("checkin.control_points.table.col_colabs")}</TableCell>
              <TableCell className={style.tableHeaderTd} align="left">{t("checkin.control_points.table.col_points")}</TableCell>
              <TableCell className={style.tableHeaderTd} align="center" width="20%">{t("checkin.control_points.table.col_total_hours")}</TableCell>
              <TableCell className={style.tableHeaderTd} align="left" width="10%">{t("checkin.control_points.table.col_balance")}</TableCell>
              <TableCell className={style.tableHeaderTd} style={{ paddingRight: '20px' }} width={'5%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {

              colabsList?.length > 0
                ?
                colabsList?.map(colab => (
                  <TableRow key={colab.owner_id} className='table-row'>
                    <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                      <Box display={'flex'} alignItems={'center'} gap={'2px'}>
                        <Box>
                          {
                            colab.location.length > 0
                              ?
                              (
                                colab.location[0].type === 'H' ? <Home fontSize='small' style={{ marginTop: '2px' }} /> : <Apartment fontSize='small' />
                              )
                              :
                              '--'
                          }

                        </Box>
                        <Box fontSize={'12px'}>{colab.owner_name}</Box>
                      </Box>
                    </TableCell>

                    <TableCell style={{ borderBottom: '2px solid #F5F6F9' }}>
                      <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
                        {
                          colab.points.length > 0
                          &&
                          <>
                            {colab.points.map((point, index) => (

                              <Box
                                key={`${point.moment} ${index}`}
                                style={{ padding: 0 }}
                                align="center"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap="8px"
                                  height={35}
                                  maxWidth="76px"
                                  margin="0 auto"
                                  className={[
                                    style.icon
                                  ].join(' ')}
                                >
                                  {point.type === 'IN' ? (
                                    <IconIn fill={statusColors[point.status_id - 1]} />
                                  ) : (
                                    <IconOut fill={statusColors[point.status_id - 1]} />
                                  )}
                                  <Typography
                                    color={
                                      [6, 7].includes(point.status_id)
                                        ? "#187DFF"
                                        : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                                    }
                                    component="span"
                                    fontSize={12}
                                    lineHeight="initial"
                                  >
                                    {[5, 6, 7].includes(point.status_id) ? (
                                      point.status_id === 7 ? (
                                        t("checkin.status.send")
                                      ) : point.status_id === 6 ? (
                                        point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                      ) : (
                                        t("checkin.status.refused")
                                      )
                                    ) : (
                                      point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                    )}
                                  </Typography>
                                </Box>
                              </Box>

                            ))}

                            {
                              getRemainingPoints(colab).map((item, index) => (

                                <Box
                                  key={index}
                                  style={{ padding: 0 }}
                                  align="center"
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="8px"
                                    maxWidth="76px"
                                    margin="0 auto"
                                    className={[
                                      style.icon
                                    ].join(' ')}
                                    height={35}
                                  >
                                    {item?.type === 'IN' ? (
                                      <IconIn fill={"#C1CDDA"} />
                                    ) : (
                                      <IconOut fill={"#C1CDDA"} />
                                    )}
                                    <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                                      --:--
                                    </Typography>
                                  </Box>
                                </Box>


                              ))
                            }
                          </>
                        }
                      </Box>
                    </TableCell>
                    <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="center">
                      {colab.hours_amount}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px', color: hoursToMinutes(colab.balance) < 0 ? '#FF5151' : '#22BEAD' }}
                      align="left"
                    >
                      {colab.balance}
                    </TableCell>
                    <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9' }} align="right">
                      <Box display={'flex'} gap={'4px'}>
                        {
                          colab?.solicitations?.length > 0 &&
                          <Box className={style.infoSolicitation} onClick={() => handleOpenModalApprove(colab)}>
                            <Warning size={16} />
                          </Box>
                        }
                        <Box className={style.arrow} onClick={() => handleOpenDrawerDetailsDay(String(colab.day).split('T')[0], colab.owner_id, colab.group_id, colab)}>
                          <ArrowRight size={16} />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                      {t('checkin.no_data.points')}
                    </Typography>
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>

        {
          <ModalApproveSolicitation
            openModalApprove={openModalApprove}
            handleCloseModalApprove={handleCloseModalApprove}
            currentSolicitation={currentColab?.solicitations[0]}
            updateListSolicitations={getDashboardInfo}
            alertActions={alertActions}
          />
        }

        {pointDetails && openDrawerDetailsDay && (
          <Drawer
            anchor="right"
            open={openDrawerDetailsDay}
            onClose={() => setOpenDrawerDetailsDay(false)}
            PaperProps={{
              style: {
                padding: '0px 12px',
                width: '350px'
              }
            }}
          >
            <div style={{ position: 'absolute', right: 4, top: 4 }}>
              <IconButton aria-label="close" onClick={() => setOpenDrawerDetailsDay(false)}>
                <Close fontSize="medium" />
              </IconButton>
            </div>

            <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" gap="4px" flexDirection="column">
                    <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>
                      {t("checkin.drawer_details_day.register_day") + " " + befectiveFormatDate(pointDetails.day, lang, "S")}
                    </Typography>
                    <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                      {t("checkin.drawer_details_day.regular_business_hours") + " " + pointDetails.hours_should_work}
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="row" gap={4} mb={2} px="16px">
                    <Box display="flex" gap="4px" flexDirection="column">
                      <Typography
                        fontSize={24}
                        color={pointDetails.hours_amount < pointDetails.hours_should_work ? "#FF7A90" : "#22BEAD"}
                        fontWeight={500}
                      >
                        {pointDetails.hours_amount}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" fontWeight={400}>{t("checkin.drawer_details_day.worked_hours")}</Typography>
                    </Box>
                    <Box display="flex" gap="4px" flexDirection="column">
                      <Typography
                        fontSize={24}
                        color={hoursToMinutes(pointDetails.balance) < 0 ? "#FF7A90" : "#22BEAD"}
                        fontWeight={500}
                      >
                        {pointDetails.balance}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                        {hoursToMinutes(pointDetails.balance) < 0 ? (
                          t("checkin.drawer_details_day.missing_hours")
                        ) : (
                          t("checkin.drawer_details_day.overtime_hours")
                        )}

                      </Typography>
                    </Box>

                  </Box>

                </Box>

                {pointDetails && (
                  <Box key={pointDetails.id} display="flex" flexDirection="column" className={style.periodBox}>
                    <Box
                      display="flex"
                      bgcolor={
                        (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                          ? "#FFE6E6"
                          : "#DBF5F2"
                      }
                      paddingX={2}
                      paddingY={1}
                      borderRadius="4px"
                    >
                      <Typography
                        color={
                          (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                            ? "#FF7A90"
                            : "#22BEAD"
                        }
                        fontSize={12}
                      >
                        {t("checkin.drawer_details_day.period_of")}{' '}
                        {getCorrectPeriod(null, pointDetails.periods)}

                      </Typography>
                    </Box>

                    {pointDetails.points.map(point => (
                      <Box key={point.id} display="flex" flexDirection="column" paddingX={2} mt={2}>
                        <Box display="flex" gap="8px">
                          <Box>
                            {point.type === 'IN' ? (
                              <IconIn fill={statusColors[point.status_id - 1]} />
                            ) : (
                              <IconOut fill={statusColors[point.status_id - 1]} />
                            )}
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography component="span" lineHeight={1} fontSize={14} color="#1E6B7F">
                              <Typography component="span" color="#1E6B7F" lineHeight={1} fontSize={14} fontWeight={600}>
                                {point.time.split(':')[0] + ":" + point.time.split(':')[1]}{' '}
                              </Typography>
                              {point.type === 'IN' ? (
                                t("checkin.drawer_details_day.entry")
                              ) : (
                                t("checkin.drawer_details_day.exit")
                              )}
                            </Typography>
                            <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                            <Typography fontSize={12} color="#4F7091">{t("checkin.drawer_details_day.receipt") + ": " + point?.receipt_token}</Typography>
                          </Box>
                        </ Box>

                        {point.solicitations.map(solicitation => (
                          solicitation.comment !== "" && (
                            <Box key={solicitation.id} display="flex" gap="6px" mt={2} mb={2} alignItems="center">
                              <Avatar
                                alt={userDetails.name}
                                src={
                                  getAvatarUrl({
                                    name: userDetails.name
                                  },
                                    "FDA02B"
                                  )
                                }
                                style={{ width: 18, height: 18 }}
                              />
                              <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                            </Box>
                          )
                        ))}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>


              <Button
                variant="contained"
                className={style.solicitationsBtn}
                style={{ padding: '0', backgroundColor: '#E3EFFF', color: '#187DFF', height: '40px', boxShadow: 'none' }}
                onClick={() => handleOpenDrawerSolicitations()}
              >
                {t('checkin.see_solicitations')}
              </Button>
            </Box>

          </Drawer>
        )}

        {openDrawerSolicitations && solicitations && (
          <DrawerSolicitationsControlPoints
            userDetails={userDetails}
            solicitations={solicitations}
            openDrawerSolicitations={openDrawerSolicitations}
            setOpenDrawerSolicitations={setOpenDrawerSolicitations}
          />
        )}
        {/* {pointDetails && openDrawerDetailsDay && (
            <Drawer
              anchor="right"
              open={openDrawerDetailsDay}
              onClose={() => setOpenDrawerDetailsDay(false)}
              PaperProps={{
                style: {
                  padding: '0px 12px',
                  width: '350px'
                }
              }}
            >
              <div style={{ position: 'absolute', right: 4, top: 4 }}>
                <IconButton aria-label="close" onClick={() => setOpenDrawerDetailsDay(false)}>
                  <Close fontSize="medium" />
                </IconButton>
              </div>

              <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap="4px" flexDirection="column">
                      <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>
                        {t("checkin.drawer_details_day.register_day")+" "+befectiveFormatDate(pointDetails.day, lang, "S")}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                        {t("checkin.drawer_details_day.regular_business_hours")+" "+pointDetails.hours_should_work}
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" gap={4} mb={2} px="16px">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={pointDetails.hours_amount < pointDetails.hours_should_work ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.hours_amount}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>{t("checkin.drawer_details_day.worked_hours")}</Typography>
                      </Box>
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={hoursToMinutes(pointDetails.balance) < 0 ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.balance}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                          {hoursToMinutes(pointDetails.balance) < 0 ? (
                            t("checkin.drawer_details_day.missing_hours")
                          ) : (
                            t("checkin.drawer_details_day.overtime_hours")
                          )}

                        </Typography>
                      </Box>

                    </Box>

                  </Box>

                  {pointDetails.periods_by_day.map(period => (
                    <Box key={period.start_time} display="flex" flexDirection="column" className={style.periodBox}>
                      <Box
                        display="flex"
                        bgcolor={
                          (calcDurationInMinutes(period.start_time, period.end_time) / 60) < period.hours_should_work
                            ? "#FFE6E6"
                            : "#DBF5F2"
                        }
                        paddingX={2}
                        paddingY={1}
                        borderRadius="4px"
                      >
                        <Typography
                          color={
                            (calcDurationInMinutes(period.start_time, period.end_time) / 60) < period.hours_should_work
                              ? "#FF7A90"
                              : "#22BEAD"
                          }
                          fontSize={12}
                        >
                          {t("checkin.drawer_details_day.period_of")} {period.start_time} - {period.end_time}
                        </Typography>
                      </Box>

                      {period.points_by_periods.map(point => (
                        <Box key={point.id} display="flex" flexDirection="column" paddingX={2} mt={2}>
                          <Box display="flex" gap="8px">
                            <Box>
                              {point.type === 'IN' ? (
                                <IconIn fill={statusColors[point.status_id - 1]} />
                              ) : (
                                <IconOut fill={statusColors[point.status_id - 1]} />
                              )}
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Typography component="span" lineHeight={1} fontSize={14} color="#1E6B7F">
                                <Typography component="span" color="#1E6B7F" lineHeight={1} fontSize={14} fontWeight={600}>
                                  {point.time.split(':')[0] + ":" + point.time.split(':')[1]}{' '}
                                </Typography>
                                {point.type === 'IN' ? (
                                  t("checkin.drawer_details_day.entry")
                                ) : (
                                  t("checkin.drawer_details_day.exit")
                                )}
                              </Typography>
                              <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                              <Typography fontSize={12} color="#4F7091">{t("checkin.drawer_details_day.receipt")+": "+point?.receipt_token}</Typography>
                            </Box>
                          </ Box>

                          {point.solicitations.map(solicitation => (
                            solicitation.comment !== null && (
                              <Box key={solicitation.id} display="flex" gap="6px" mt={2} mb={2} alignItems="center">
                                <Avatar
                                  alt={userDetails.name}
                                  src={
                                    getAvatarUrl({
                                      name: userDetails.name
                                    },
                                      "FDA02B"
                                    )
                                  }
                                  style={{ width: 18, height: 18 }}
                                />
                                <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                              </Box>
                            )
                          ))}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>

              </Box>

            </Drawer>
          )} */}


      </TableContainer >
    </>

  );
}
