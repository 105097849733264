import React from 'react';

const IconEmail2 = props => {
    return (


        <svg xmlns="http://www.w3.org/2000/svg" width="14.667" height="11.333" viewBox="0 0 14.667 11.333">
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0 0)">
                <path id="Caminho_334" data-name="Caminho 334"
                      d="M16,7H2.667A.667.667,0,0,0,2,7.667v10a.667.667,0,0,0,.667.667H16a.667.667,0,0,0,.667-.667v-10A.667.667,0,0,0,16,7Zm-.667,10h-12V10.233l5.833,3.433a.3.3,0,0,0,.333,0l5.833-3.533ZM9.5,12.133a.3.3,0,0,1-.333,0l-5.833-3.8h12Z"
                      transform="translate(-2 -7)" fill="#24677e"/>
            </g>
        </svg>


    )
}

export default IconEmail2;
