import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { alertActions, usersActions, teammatesActions, filterActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { CardsHeaderControl } from '../../_components/checkin_page/control/CardsHeader';
import { CheckinTableControl } from '../../_components/checkin_page/control/CheckinTable';
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import './fullcalendar.scss'
import { Box } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinControl(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, filterActions, filter } = props
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reasons, setReasons] = useState([])
  const [dashboardInfo, setDashboardInfo] = useState({});
  

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  async function getReasons() {
    try {
      const response = await api.get('/checkin/adjustments_reasons', {
        headers: authHeader()
      })

      setReasons(response.data)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t("checkin.dashboard.alerts.get_reasons_error"))
    }
  }


  async function getDashboardInfo(dateFilter = moment(new Date()).format('YYYY-MM-DD'), teams = null, teammates = null) {
    try {
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/checkin/control-points/dashboard/?date=${dateFilter}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })
      setDashboardInfo(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.control_points.alerts.get_dashboard_data_error"))
    }
  }

  useEffect(() => {
    let date = moment(new Date()).format('YYYY-MM-DD');

    if (filter.singleDate) {
      date = filter.singleDate
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDashboardInfo(date, teams, teammates)
  }, [filter])

  useEffect(() => {
    document.title = `Befective | ${t('checkin.control_points.title_page')}`;
    filterActions.replace(moment(new Date()).format('YYYY-MM-DD'), 'singleWhen')
    filterActions.clear('who')
    getReasons()
  }, []);

  const getCalendarData = async (fetchInfo, successCallback, failureCallback) => {
    try {
      const start_date = moment(fetchInfo.start).format('YYYY-MM-DD')
      const end_date = moment(fetchInfo.end).format('YYYY-MM-DD')

      const response = await api.get(`/checkin/solicitations?start_date=${start_date}&end_date=${end_date}&status=WAITING`, {
        headers: authHeader()
      });

      successCallback(
        response.data.map(event => {
          return {
            title: event.qtd_solicitations+' '+t('checkin.control_points.adjustments'),
            date: moment(event.date_ref).format('YYYY-MM-DD')
          };
        })
      );
    } catch (error) {
      console.log(error);
      //alertActions.error(t("checkin.control_points.alerts.get_calendar_data_error"))
    }
  }

  function renderEventContent(eventInfo) {
    return (
      <div>
        <span style={{color:'#FDBE2B', fontWeight: 500}}>{eventInfo.event.title}</span>
      </div>
    )
  }

  const handleClickDate = async (e) => {
    
    let date = e.dateStr

    if (filter.singleDate) {
      date = filter.singleDate
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    await getDashboardInfo(date, teams, teammates)
    setShowCalendar(false);

  }

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'single'}
          filterWhat={'disabled'}
          setShowCalendar={setShowCalendar}
          showCalendar={showCalendar}
        />
        {
          !showCalendar
            ?
            <div className={style.containerCheckinPage}>
              <CardsHeaderControl alertActions={alertActions} userDetails={userDetails} resume={dashboardInfo?.resume} />
              <CheckinTableControl alertActions={alertActions} userDetails={userDetails} colabsList={dashboardInfo?.colabs_list} getDashboardInfo={getDashboardInfo} filter={filter} />
            </div>
            :
            <div id='calendarCheckinControl' className={style.containerCheckinPage}>
              <Box padding={4} sx={{ background: '#fff' }}>

                <FullCalendar
                  locales={allLocales}
                  locale={i18n.language}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'title',
                    right: 'today prev,next'
                  }}
                  events={
                    (fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)
                  }
                  firstDay="0"
                  initialView='dayGridMonth'
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  dayMaxEvents={true}
                  dateClick={handleClickDate}
                  eventContent={renderEventContent}
                />
              </Box>
            </div>
        }



      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinControl)