import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {NavLink, Link} from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'
import Teams from '../_components/settings/teams/Teams'
import Nav from '../_components/settings/teams/Nav'
import TeamsSkeleton from '../_components/settings/teams/TeamsSkeleton'
import DrawerCreate from '../_components/settings/teams/DrawerCreate'


import {useTranslation, initReactI18next} from "react-i18next";

import {activitiesActions, teamsActions} from '../_actions';


import IconClose from '../_components/_icons/IconClose';
import FocusSkeleton from "../_components/settings/teams/FocusSkeleton";
import Focus from "../_components/settings/teams/Focus";

function CompanyTeamsPageFocus(props) {
    const {t, i18n} = useTranslation('common');

    const {teamsActions} = props;
    const {activitiesActions} = props;

    let loadingCategories = props.loadingCategories;
    let loadingFocus = props.loadingFocus;
    let permissions = props.permissions || [];

    let token = props.token || {};
    let loadingTeams = props.loadingTeams;
    let loadingTeammates = props.loadingTeammates;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    const [teamId, setTeamId] = useState(null)
    const [drawer, setDrawer] = useState(false)
    const [isShowingDrawerCreate, setDrawerCreate] = useState(false)

    const [drawerManageId, setDrawerManageId] = useState()

    const [minimumTime, setMinimumTime] = useState(500)
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)

    function openDrawer(data) {
        setDrawer(true)

    }

    function closeDrawer() {
        setDrawer(false)

    }

    useEffect(() => {

        let path = window.location.pathname.split("/")
        let currentId = path[3]

        setTeamId(currentId)

        if (currentId) {
            teamsActions.getTeamFocus(currentId)
            teamsActions.getTeamDetail(currentId)
            activitiesActions.getActivitiesCategories()

        }


    }, []);

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.teams') + ' | ' + t('settings.teams.nav.focus');
    });

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Nav/>
                {/* PAGE STARTS */}

                <div className="mainPage profile">
                    <div className="nocolumn visible">
                        <div className={'tab full active'}>
                            {
                                !minimumTimeElapsed || loadingCategories || loadingFocus
                                    ? <FocusSkeleton/>
                                    : <Focus/>
                            }
                        </div>
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
                setDrawer(false)
            }}></div>

            <section className={'drawer drawer600' + (drawer ? ' open' : '')}>
                <button onClick={() => {
                    setDrawer(false)
                }} className="close"><IconClose/></button>
                <DrawerCreate closeDrawer={() => {
                    closeDrawer()
                }}/>
            </section>

        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        teamsActions: bindActionCreators(teamsActions, dispatch),
        activitiesActions: bindActionCreators(activitiesActions, dispatch)
    }
}

function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        loadingFocus: state.teams.loadingFocus,
        loadingCategories: state.activities.loadingCategories,
        filter: state.filter,
        permissions: state.authentication.permissions
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTeamsPageFocus)
