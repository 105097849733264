import React, { useRef, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";

import { useTable, useSortBy, useRowSelect } from 'react-table'

import IconShowPassword from '../_icons/IconShowPassword';

function DrawerActivityTeammateDetailSkeleton(props) {
    const { t, i18n } = useTranslation('common');

    let detailsTeammates = props.detailsTeammates || [];
    let current = props.current || {};
    let stats = props.stats || {};



    return (
        <div className="internal">
            <div className="header">

                <div className={"title " + current.efficiencyName}>{current.exhibitionName}</div>

            </div>
            <div className="category"></div>
            <div className="total"></div>
        

            <div className="dataTable teammates">



            </div>

        </div>
    )
};

function mapStateToProps(state) {

    return {
        detailsTeammates: state.activities.detailsTeammates,
        stats: state.stats.payload
    }

}

export default connect(mapStateToProps)(DrawerActivityTeammateDetailSkeleton)