import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";
import IconOk from "../_icons/IconOk";
import IconSad from "../_icons/IconSad";
import IconHappy from "../_icons/IconHappy";


function AlertProductivity(props) {
    const {t, i18n} = useTranslation('common');

    const [showAlert, setShowAlert] = useState(false);
    const [popunder, setPopunder] = useState(false);
    const user_name = props.productivity?.user?.name ?? ""
    
    useEffect(() => {

        
        if (props.productivity?.alert) {
            setShowAlert(props.productivity.alert.showAlert)
        }


    }, [props.productivity])


    return (
        <React.Fragment>
            {showAlert &&
                <React.Fragment>
                    <div className={'alert'} onClick={() => {
                        setPopunder(true)
                    }}>
                        
                        {t('productivity.alert_message').replace(":user_name", user_name)}
                        
                    </div>
                    <div className={'overlay' + (popunder ? ' open' : '')} onClick={() => {
                        setPopunder(false)
                    }}></div>
                    <div className={'popunder' + (popunder ? ' open' : '')} onClick={() => {
                        setPopunder(false)
                    }}>
                        <div className={'content'} onClick={() => {
                            setPopunder(false)
                        }}>
                            <h3>{t('productivity.alert_content_title_restart')}</h3>
                            <ol type="1">
                                <li>{t('productivity.alert_content_restart_step_one')}</li>
                                <li>{t('productivity.alert_content_restart_step_two')}</li>                                
                            </ol>
                            <h3>{t('productivity.alert_content_title_start')}</h3>
                            <ol type="1">
                                <li>{t('productivity.alert_content_start_step_one')}</li>
                                <li>{t('productivity.alert_content_start_step_two')}</li>                                
                            </ol>
                            <p>                           
                            {t('productivity.alert_content_message_important')}
                            </p>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(AlertProductivity)
