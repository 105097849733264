import React from 'react';

const IconRemove2 = props => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="9.625" height="11" viewBox="0 0 9.625 11">
            <path id="Icon_awesome-trash" data-name="Icon awesome-trash"
                  d="M9.281.687H6.7l-.2-.4A.516.516,0,0,0,6.039,0H3.584a.51.51,0,0,0-.46.286l-.2.4H.344A.344.344,0,0,0,0,1.031v.687a.344.344,0,0,0,.344.344H9.281a.344.344,0,0,0,.344-.344V1.031A.344.344,0,0,0,9.281.687ZM1.143,10.033A1.031,1.031,0,0,0,2.172,11H7.453a1.031,1.031,0,0,0,1.029-.967L8.937,2.75H.687Z"
                  fill="#98acc1"/>
        </svg>


    )
}

export default IconRemove2;
