import {periodicConstants, teamsConstants} from '../_constants';


export function periodic(state = {}, action) {
    switch (action.type) {
        case periodicConstants.PATCH_PERIODIC_REPORT_REQUEST:
        case periodicConstants.PATCH_PERIODIC_REPORT_SUCCESS:
        case periodicConstants.PATCH_PERIODIC_REPORT_FAILURE:
            return {
                ...state
            };

        case periodicConstants.DELETE_PERIODIC_REPORT_REQUEST:
        case periodicConstants.DELETE_PERIODIC_REPORT_SUCCESS:
        case periodicConstants.DELETE_PERIODIC_REPORT_FAILURE:
            return {
                ...state,
            };

        case periodicConstants.GET_PERIODIC_REPORT_REQUEST:
            return {
                loading: true,
                error: false

            };
        case periodicConstants.GET_PERIODIC_REPORT_SUCCESS:
            return {
                TotalDaily: parseInt(action.payload.TotalDaily),
                TotalWeekly: parseInt(action.payload.TotalWeekly),
                TotalMonthly: parseInt(action.payload.TotalMonthly),
                TotalOrganization: parseInt(action.payload.TotalOrganization),
                TotalGroup: parseInt(action.payload.TotalGroup),
                TotalMachineUser: parseInt(action.payload.TotalMachineUser),
                reports: action.payload.reports,
                loading: false,
                error: false
            };
        case periodicConstants.GET_PERIODIC_REPORT_FAILURE:
            return {
                loading: true,
                error: true
            };

        case periodicConstants.POST_PERIODIC_REPORT_REQUEST:
            return {
                ...state,
                created: '',
                loading: false
            };
        case periodicConstants.POST_PERIODIC_REPORT_SUCCESS:
            return {
                ...state,
                created: 'success',
                loading: false
            };
        case periodicConstants.POST_PERIODIC_REPORT_FAILURE:
            return {
                ...state,
                error: action.error,
                created: 'error',
                loading: false
            };

        default:
            return state
    }
}
