import React, { useEffect, useRef, useState } from 'react';
import style from './styles.module.scss'
import { Box, Button, Divider, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, history, replaceTemplate } from '../../../_helpers';
import { Delete, Edit, Link } from '@material-ui/icons';
import { Backdrop, CircularProgress, makeStyles, Modal, Popover, Slide, Typography } from "@material-ui/core";
import { ArrowUDownLeft, ArrowUDownRight, ArrowUUpRight } from 'phosphor-react';
import { v4 as uuidv4 } from 'uuid';
import SubspaceIconChair from '../../_icons/SubspaceIconChair';
import SubspaceIconLaptop from '../../_icons/SubspaceIconLaptop';
import SubspaceIconTV from '../../_icons/SubspaceIconTV';
import SubspaceIconDesk from '../../_icons/SubspaceIconDesk';

import SVGRenderGroup from './SVGRenderGroup';
import SVGRender from './SVGRender';

const SpaceMapEditor = ({
  shapes,
  setValue,
  shapesOp,
  roomToEdit,
  getDetailSpace
}) => {

  const { t, i18n } = useTranslation('common');

  const minhaRef = useRef(null);
  const [newShape, setNewShape] = useState({ key: null, name: '', x: 0, y: 0, width: 50, height: 50, type: '', color: '#444cbb' });
  const [submittedShape, setSubmittedShape] = useState(false);
  const [formError, setFormError] = useState({
    name: '',
    type: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [dragging, setDragging] = useState(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [rotationAngle, setRotationAngle] = useState(0);
  const [selectedShape, setSelectedShape] = useState(null);
  const [shapeToEdit, setShapeToEdit] = useState(null);
  console.log("/shapesOp -> ",newShape)
  const leftRotateSVG = () => {
    setRotationAngle((rot) => {
      const value = ((rot + 90) >= 360) ? 0 : rot + 90;
      setNewShape({ ...newShape, angle: parseInt(value) })
      return value// Altere o ângulo conforme necessário
    })
  };

  const rightRotateSVG = () => {
    setRotationAngle((rot) => {
      const value = ((rot - 90) <= -360) ? 0 : rot - 90
      setNewShape({ ...newShape, angle: parseInt(value) })
      return value
    }); // Altere o ângulo conforme necessário
  };

  const handleMouseUp = async () => {
    try {
      if (!dragging) return
      const shape = shapes.find((shape) => shape.key === dragging);
      if(roomToEdit && shape.id){
        const res = await api.put(`/rooms/maps/shapes/${dragging}`, shape, { headers: authHeader() })
        await getDetailSpace(false)
      }
      setDragging(null);
      setSelectedShape(null)
    } catch (error) {
      console.log(error)
    }

  };

  const handleClean = () => {
    // setNewShape({ key: null, name: '', x: 0, y: 0, width: 50, height: 50, type: '', color: '#444cbb' })
    setNewShape({ ...newShape,key: null, name: '', x: 0, y: 0, type: '', color: '#444cbb',angle:0 })
    setRotationAngle(0)
  }

  const handleDeleteShape = async (shape) => {
    try {
      setValue('shapes', shapes.filter(s => s.key !== shape.key))
      if(roomToEdit && shape.id){
        const res = await api.delete(`/rooms/maps/shapes/${shape.id}`, { headers: authHeader() })
        await getDetailSpace(false)
      }
      setShapeToEdit(null)
    } catch (error) {
      console.log(error)
    }
  };

  const handleAddShape = async () => {
    try {

      setValue('shapes', [...shapes, { ...newShape, key: uuidv4() }])
      // handleClean()
    } catch (error) {
      console.log(error)
    }
  };
  
  const handleMouseDown = (event, shapeId) => {
    event.preventDefault()
    setDragging(shapeId);
    const shape = shapes.find((shape) => shape.key === shapeId);
    const offsetX = event.clientX - shape.x;
    const offsetY = event.clientY - shape.y;
    setOffset({ x: offsetX, y: offsetY });
  };

  const handleMouseMove = (event) => {
    if (dragging !== null) {
      const updatedShapes = [...shapes];
      const shape = updatedShapes.find((shape) => shape.key === dragging);
      const newX = event.clientX - offset.x;
      const newY = event.clientY - offset.y;
      shape.x = newX;
      shape.y = newY;
      setValue('shapes', updatedShapes);
    }
  };


  const validateForm = (form) => {
    const errors = {};
    if (!form.name) {
      errors.name = t('project_management.validations.required_field')
    }
    if (!form.type) {
      errors.type = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(newShape))
    setSubmitted(true)
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && submitted) {
      handleAddShape()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  return (

    <Box sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1, backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }}>
        <Box className="help_section" style={{padding:"0px"}}>
          <ul style={{paddingInlineStart:"20px"}} >
            <li>
              <Typography  style={{ color: "#1E6B7F",fontSize:"1rem" }}>{t('spaces.instructions.tip_three')}</Typography>
            </li>
            <li>
              <Typography  style={{ color: "#1E6B7F",fontSize:"1rem" }}>{t('spaces.instructions.tip_two')}</Typography>
            </li>
          </ul>
          
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontSize: '14px', color: '#1E6B7F', fontWeight: 500, marginTop: 0, marginBottom: 0 }}>{t('spaces.map_editor.create_element')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              id="type"
              select
              label={t('spaces.map_editor.geometricForm')}
              value={newShape.type}
              onChange={(e) => { 
               
                const template = shapesOp.find(item => item.key === e.target.value)
                
                setNewShape({ ...newShape, type: template.key, svg_shape_id:template.id , content:template.content,viewbox:template.viewbox}) } }
              placeholder={t('spaces.map_editor.select')}
              error={formError.type}
              onFocus={() => setFormError({ ...formError, type: '' })}
            >
              <MenuItem value={''}>
                {t('spaces.map_editor.select')}
              </MenuItem>
              {shapesOp.length > 0 && shapesOp.filter(item => item.type === 'geometric').map(item =>
              (
                <MenuItem key={item.key} value={item.key}>
                  {item[`locale_${i18n.language}`]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* t('spaces.map_editor.field_shape') */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              id="type"
              select
              label={t('spaces.map_editor.predefined')}
              value={newShape.type}
              onChange={(e) => {
                const template = shapesOp.find(item => item.key === e.target.value)
                setNewShape({ ...newShape, type: template.key, svg_shape_id:template.id , content:template.content, viewbox:template.viewbox })
              } }
              placeholder={t('spaces.map_editor.select')}
              error={formError.type}
              onFocus={() => setFormError({ ...formError, type: '' })}
            >
              <MenuItem value={''}>
                {t('spaces.map_editor.select')}
              </MenuItem>
              {shapesOp.length > 0 && shapesOp.filter(item => item.type === 'object').map(item =>
              (
                <MenuItem key={item.key} value={item.key}  >
                  {item[`locale_${i18n.language}`]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="name"
              label={t('spaces.map_editor.field_title')}
              variant="outlined"
              value={newShape.name}
              onChange={(e) => setNewShape({ ...newShape, name: e.target.value })}
              error={formError.name}
              onFocus={() => setFormError({ ...formError, name: '' })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="width"
              label={t('spaces.map_editor.field_width')}
              variant="outlined"
              type='number'
              InputLabelProps={{
                shrink: true
              }}
              value={newShape.width}
              // defaultValue={newShape.width}
              onChange={(e) => setNewShape({ ...newShape, width: parseInt(e.target.value) })}
              disabled={['', 'desk', 'laptop', 'tv', 'chair'].includes(newShape.type)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="height"
              label={t('spaces.map_editor.field_height')}
              variant="outlined"
              type='number'
              InputLabelProps={{
                shrink: true
              }}
              value={newShape.height}
              // defaultValue={newShape.height}
              onChange={(e) => setNewShape({ ...newShape, height: parseInt(e.target.value) })}
              disabled={['', 'desk', 'laptop', 'tv', 'chair'].includes(newShape.type)}
            />
          </Grid>
          <Grid item xs={4} display={'flex'} flexDirection={'column'}>
            <label className={style.labelColor} htmlFor="color">{t('spaces.map_editor.field_color')}</label>
            <input className={style.inputColor} id='color' type="color" value={newShape.color} onChange={(e) => setNewShape({ ...newShape, color: e.target.value })} disabled={['', 'desk', 'laptop', 'tv', 'chair'].includes(newShape.type)} />
          </Grid>
          <Grid item xs={12} justifyContent={'space-between'} display={'flex'} gap={'6px'}>
            <Grid item xs={6} display={'flex'} gap={'10px'}>
              <ArrowUDownRight size={30} onClick={() => rightRotateSVG()} />

              <ArrowUDownLeft size={30} onClick={() => leftRotateSVG()} />
            </Grid>
            <Grid item xs={6} justifyContent={'flex-end'} display={'flex'} gap={'6px'}>
              {
                newShape.type &&
                <Button disabled={submittedShape} type='button' variant="contained" color='inherit' disableElevation onClick={handleClean}>
                  {t('spaces.map_editor.clean')}
                </Button>
              }

              {
                shapeToEdit &&
                <>
                  <Button disabled={submittedShape} type='button' variant="contained" color='inherit' disableElevation onClick={() => setShapeToEdit(null)}>
                    {t('spaces.map_editor.cancel')}
                  </Button>
                  <Button disabled={submittedShape} type='button' variant="contained" color='error' disableElevation onClick={() => handleDeleteShape(shapeToEdit)}>
                    {t('spaces.map_editor.btn_delete_shape')}
                  </Button>
                </>
              }

              <Button disabled={submittedShape} type='button' variant="contained" disableElevation onClick={handleSubmit}>
                {t('spaces.map_editor.btn_add_shape')}
              </Button>
            </Grid>
            
          </Grid>
          
        </Grid>
        
        {
          newShape.type &&
          <Grid container borderTop={'1px solid #B2B8BF75'} marginTop={'12px'}>
            <Grid item xs={12} marginTop={'6px'} marginBottom={'8px'}>
              <Typography variant='h6' style={{ fontSize: '14px', color: '#1E6B7F', fontWeight: 500 }}>{t('spaces.map_editor.preview')}</Typography>
            </Grid>
            <Grid item xs={12} justifyContent={'center'} display={'flex'} style={{ backgroundColor: '#F5F6F9', padding: '10px' }}>
              {
                newShape.type === 'rectangle' &&
                <svg width={newShape.width} height={newShape.height} style={{ transform: `rotate(${rotationAngle}deg)` }}>
                  <g className={style.rectangleGroup}>
                    <rect
                      x={newShape.x}
                      y={newShape.y}
                      width={newShape.width}
                      height={newShape.height}
                      fill={newShape.color}
                      className={style.rectangle}
                    />
                    <text x={newShape.x + newShape.width / 2} y={newShape.y + newShape.height / 2} width={newShape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                      {newShape.name}
                    </text>
                  </g>
                </svg>
              }
              {
                newShape.type === 'circle' &&
                <svg width={newShape.width} height={newShape.height}  style={{ transform: `rotate(${rotationAngle}deg)` }}>
                  <g className={style.rectangleGroup}>
                    <circle
                      cx={newShape.x + newShape.width / 2}
                      cy={newShape.y + newShape.height / 2}
                      r={Math.min(newShape.width, newShape.height) / 2}
                      fill={newShape.color}
                      className={style.rectangle}
                    />
                    <text x={newShape.x + newShape.width / 2} y={newShape.y + newShape.height / 2} width={newShape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                      {newShape.name}
                    </text>
                  </g>
                </svg>
              }

              {
                newShape.type === 'chair' &&
                <SubspaceIconChair x={newShape.x} y={newShape.y} rotationAngle={rotationAngle} />
              }

              {
                newShape.type === 'desk' &&
                <SubspaceIconDesk x={newShape.x} y={newShape.y} rotationAngle={rotationAngle} />
              }
              {
                newShape.type === 'laptop' &&
                <SubspaceIconLaptop x={newShape.x} y={newShape.y} rotationAngle={rotationAngle} />
              }

              {
                newShape.type === 'tv' &&
                <SubspaceIconTV x={newShape.x} y={newShape.y} rotationAngle={rotationAngle} />
              }
            </Grid>
          </Grid>
        }

      </Box>

      <svg ref={minhaRef} width="100%" height="600" style={{ border: "1px solid #01010155" }} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
        {Array.from({ length: 40 }, (_, i) => (
          <line
            key={`row-${i}`}
            x1="0%"
            y1={`${(i / 40) * 100}%`}
            x2="100%"
            y2={`${(i / 40) * 100}%`}
            stroke="#ccc"
          />
        ))}
        {Array.from({ length: 40 }, (_, i) => (
          <line
            key={`col-${i}`}
            x1={`${(i / 40) * 100}%`}
            y1="0%"
            x2={`${(i / 40) * 100}%`}
            y2="100%"
            stroke="#ccc"
          />
        ))}

        {shapes.map((shape, index) => {
          
          return ["rectangle","circle"].includes(shape.type)  ?  
            SVGRenderGroup({svgCode:replaceTemplate(shape.content,{...shape,textPos_x:Number(shape.x + (shape.width /2)),textPos_y:Number(shape.y + (shape.height/2)),cx:Number(shape.x + (shape.width /2)),cy:Number(shape.y + (shape.height/2)),radio:(Math.min(shape.width, shape.height) / 2)} ),x:shape.x,y:shape.y,width:shape.width,height:shape.height,setShapeToEdit:setShapeToEdit,shape:shape,handleMouseDown:handleMouseDown })
            :
            SVGRender({svgCode:replaceTemplate(shape.content,{...shape,textPos_x:Number(shape.x + (shape.width /2)),textPos_y:Number(shape.y + (shape.height/2)),cx:Number(shape.x + (shape.width /2)),cy:Number(shape.y + (shape.height/2)),radio:(Math.min(shape.width, shape.height) / 2)} ),x:shape.x,y:shape.y,width:shape.width,height:shape.height,setShapeToEdit:setShapeToEdit,shape:shape,handleMouseDown:handleMouseDown })


          // switch (shape.type) {
          //   case 'rectangle':
          //     return (                
          //       <g transform={`rotate(${shape.angle} ${shape.x + (shape.width/2) } ${shape.y + (shape.height/2)})`} className={style.rectangleGroup}  key={shape.key} onMouseDown={(event) => handleMouseDown(event, shape.key)} style={{ cursor: 'pointer' }} width={shape.width} height={shape.height} >
          //         <rect
          //           x={shape.x}
          //           y={shape.y}
          //           stroke={selectedShape?.key === index ? 'red' : shape.color}
          //           strokeWidth="2"
          //           cursor={selectedShape?.key === index ? 'move' : 'pointer'}
          //           width={shape.width}
          //           height={shape.height}
          //           fill={shape.color}
          //           style={{ cursor: 'pointer' }}
          //           onDoubleClick={() => setShapeToEdit(shape)}
          //           className={style.rectangle}
                    
          //         />
          //         <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
          //           {shape.name}
          //         </text>
          //       </g>
                
          //     )
          //   case 'circle':
          //     return (
          //       <g className={style.rectangleGroup} key={shape.key} rotationAngle={shape.angle} onMouseDown={(event) => handleMouseDown(event, shape.key)} >
          //         <circle
          //           cx={shape.x + shape.width / 2}
          //           cy={shape.y + shape.height / 2}
          //           r={Math.min(shape.width, shape.height) / 2}
          //           stroke={selectedShape?.key === index ? 'red' : shape.color}
          //           strokeWidth="2"
          //           cursor={selectedShape?.key === index ? 'move' : 'pointer'}
          //           fill={shape.color}
          //           style={{ cursor: 'pointer' }}
          //           onDoubleClick={() => setShapeToEdit(shape)}
          //           className={style.rectangle}
          //         />
          //         <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
          //           {shape.name}
          //         </text>
          //       </g>
          //     )

          //   case 'chair':
          //     return (
          //       <SubspaceIconChair x={shape.x} y={shape.y} rotationAngle={shape.angle} onMouseDown={(event) => handleMouseDown(event, shape.key)} onDoubleClick={() => setShapeToEdit(shape)} />
          //     )
          //   case 'desk':
          //     return (
          //       <SubspaceIconDesk x={shape.x} y={shape.y} rotationAngle={shape.angle} onMouseDown={(event) => handleMouseDown(event, shape.key)} onDoubleClick={() => setShapeToEdit(shape)} />
          //     )
          //   case 'laptop':
          //     return (
          //       <SubspaceIconLaptop x={shape.x} y={shape.y} rotationAngle={shape.angle} onMouseDown={(event) => handleMouseDown(event, shape.key)} onDoubleClick={() => setShapeToEdit(shape)} />
          //     )
          //   case 'tv':
          //     return (
          //       <SubspaceIconTV x={shape.x} y={shape.y} rotationAngle={shape.angle} onMouseDown={(event) => handleMouseDown(event, shape.key)} onDoubleClick={() => setShapeToEdit(shape)} />
          //     )
          //   default:
          //     break;
          // }
        
        } 

        )}

      </svg>
    </Box>
  );
};

export default SpaceMapEditor;