import React, {useRef, useState, useMemo, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";
import Moment from 'moment';
import {useTable, useSortBy, useRowSelect} from 'react-table'

import IconArrow from '../_icons/IconArrow';
import IconExpand from '../_icons/IconExpand';
import {Rowing} from '@material-ui/icons';

import {secondsToHm} from '../../_helpers';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from '@mui/material';

function DrawerActivityTeammateDetail(props) {
    const {t, i18n} = useTranslation('common');

    let detailsSpecific = props.detailsSpecific || [];
    let current = props.current || {};
    let specificData = props.specificData || {};
    let stats = props.stats || {}
    let filter = props.filter;

    const [specificDuration, setSpecificDuration] = useState(0);
    const [showDay, setShowDay] = useState(null);

    let date = new Date();
    let startDateP = Moment(new Date()).format('YYYY-MM-DD');
    let endDateP = Moment(new Date()).format('YYYY-MM-DD');


    if (filter.date) {
        startDateP = filter.date.startDate;
        endDateP = filter.date.endDate;
    }

    const dataTeammates = useMemo(() => detailsSpecific, []);

    const columnsTeammates = React.useMemo(
        () => [
            {
                Header: t('activities.date_hour'),
                accessor: 'date',
                Cell: (d) => {
                    Moment.locale(t('date.locale'));

                    return Moment.utc(d.value).format(t('date.MMMM DD YYYY'));

                }
            },
            {
                Header: t('activities.time_perfomed'),
                accessor: 'timePerfomed',
                Cell: (d) => {

                    let perPerfomed = 0;
                    let timePerfomed = 0;

                    for (let activitie of d.row.original.activities) {
                        timePerfomed += activitie.duration;
                    }

                    if (current.duration > 0 && timePerfomed > 0) {
                        perPerfomed = Math.round((timePerfomed / current.duration) * 100)

                    }


                    return <div className="timePerfomed hidePer"><span>{secondsToHm(timePerfomed)}</span> {perPerfomed}%
                    </div>;
                }
            },
            {
                Header: Moment.utc(startDateP).format(t('date.mm/dd/yyyy')) + " - " + Moment.utc(endDateP).format(t('date.mm/dd/yyyy')),
                accessor: 'duration',
                Cell: (d) => {

                    let perPerfomed = 0;
                    let timePerfomed = 0;

                    for (let activitie of d.row.original.activities) {
                        timePerfomed += activitie.duration;
                    }

                    if (current.duration > 0 && timePerfomed > 0) {
                        perPerfomed = Math.round((timePerfomed / current.duration) * 100)

                    }

                    return <div className="barchart">
                        <div style={{width: perPerfomed + '%'}}></div>
                    </div>
                }
            },
            {
                Header: '',
                accessor: 'machineUserId',
                Cell: (d) => {
                    return <button className="details expandBug" onClick={() => {
                        dayDetails(d.row.index)
                    }}><IconExpand/></button>
                }
            }
        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: columnsTeammates,
            data: dataTeammates
        }
    )

    function closeSpecific() {
        props.closeSpecific();
    }

    function dayDetails(index) {

        document.getElementById("day" + index).classList.toggle("expanded");

    }

    useEffect(() => {

        let duration = 0;

        for (let day of detailsSpecific) {
            for (let activitie of day.activities) {
                duration += activitie.duration;
            }

        }


        setSpecificDuration(duration);


    }, [specificData]);

    return (
        <div className="internal details">

            <button onClick={() => {
                closeSpecific()
            }} className="goBack"><IconArrow/></button>

            <div className="header">

                <div className={"title"}>{specificData.machineUserName}: <b>{specificData.teamName}</b></div>

            </div>
            <div className="category">{current.exhibitionName}</div>
            <div
                className="total">{t('activities.total_time')}: {secondsToHm(specificDuration)} {Math.round((specificDuration / current.duration) * 100)}%
            </div>


            <div className="dataTable specific expansionTable">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            let rowClass = '';
                            let rowExtra = [];
                            
                            if (row.original.activities.length > 0) {
                                for (const item of row.original.activities) {


                                    let perPerfomed = 0;

                                    if (specificDuration > 0 && item.duration > 0) {
                                        perPerfomed = Math.round((item.duration / specificDuration) * 100);
                                    }


                                    rowExtra.push(<tr className="extraContent">
                                        
                                        <td style={{paddingLeft:12}} > 
                                            <div style={{display:"flex",alignItems:"center",paddingLeft:0}}>
                                            {item.tracked_id === 18899140 &&    <Tooltip title={item.extra}>
                                                                            <HelpOutlineIcon />
                                                                        </Tooltip>

                                                }
                                              {Moment.utc(item.datetime_start).format('HH:mm:ss')} - {Moment.utc(item.datetime_end).format('HH:mm:ss')}

                                            </div>
                                        </td>
                                        <td>
                                            <div className="timePerfomed">
                                                <span>{secondsToHm(item.duration)}</span> {perPerfomed}%
                                            </div>
                                        </td>
                                        <td>
                                            <div className="barchart">
                                                <div style={{width: perPerfomed + '%'}}></div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>);
                                }

                            }


                            return (
                                <React.Fragment>
                                    <tr id={'day' + row.index}>
                                        <td className="rows" colspan="4">
                                            <tr className="mainRow">

                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}

                                            </tr>

                                            {rowExtra}
                                        </td>
                                    </tr>

                                </React.Fragment>

                            )
                        }
                    )}
                    </tbody>
                </table>


            </div>

        </div>
    )
};

function mapStateToProps(state) {

    return {
        detailsSpecific: state.activities.detailsSpecific,
        stats: state.stats.payload,
        filter: state.filter,
    }

}

export default connect(mapStateToProps)(DrawerActivityTeammateDetail)
