import React, {useRef, useState, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";

import Skeleton from "@material-ui/lab/Skeleton";

function DrawerHistoricalSkeleton(props) {
    const {t, i18n} = useTranslation('common');

    let detailsTeammates = props.detailsTeammates || [];
    let current = props.current || {};
    let stats = props.stats || {};


    return (
        <div className="internal loading">
            <div className="header">
                <div className="title"><Skeleton variant="text" width={180} height={30}/></div>

            </div>

            <div className="day"><Skeleton variant="text" width={100} height={25}/></div>
            <div className="hourChange"><Skeleton variant="text" width={100} height={18}/></div>

            <div className="dataTable">
                <Skeleton variant="rect" width="100%" height={247}/>
            </div>

        </div>
    )
};

function mapStateToProps(state) {

    return {
        detailsTeammates: state.activities.detailsTeammates,
        stats: state.stats.payload
    }

}

export default connect(mapStateToProps)(DrawerHistoricalSkeleton)
