import config from 'config';
import { authHeader } from '../_helpers';

export const goalsService = {
    getGoals,
    getGoalsTeammates
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getGoals(startDate, endDate, teams, teammates, tree) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryGoals = ''
    let queryTeams = ''
    let queryTeammates = ''


    if(tree == false){
        queryGoals = '&tree=false';
    }


    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            }
            else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }


    return fetch(`${config.apiNodeUrl}/stats/goals/teams?startDate=${startDate}&endDate=${endDate}`+queryTeams+queryTeammates+queryGoals, requestOptions).then(handleResponse);
}
function getGoalsTeammates(startDate, endDate, teams, teammates) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = ''
    let queryTeammates = ''

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            }
            else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }
    }

    return fetch(`${config.apiNodeUrl}/stats/goals/teammates?startDate=${startDate}&endDate=${endDate}`+queryTeams+queryTeammates, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
