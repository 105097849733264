// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HTsnymGFBEPe3a9sHoUnJA\\=\\= p {\n  transition: all 0.2s ease-in-out;\n}\n\n.HTsnymGFBEPe3a9sHoUnJA\\=\\=:hover p {\n  color: #62a0f1;\n}", "",{"version":3,"sources":["webpack://./src/_components/checkin_page/dashboard/DrawerSolicitations.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".sendAgain p {\n  transition: all 0.2s ease-in-out;\n}\n\n.sendAgain:hover p {\n  color: #62a0f1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sendAgain": "HTsnymGFBEPe3a9sHoUnJA=="
};
export default ___CSS_LOADER_EXPORT___;
