import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function TotalLabel(props) {
	const { t } = useTranslation('common');

	const goals = props.goals || null
	const cardView = props.cardView

	return goals.total > 0 ? (

		<span className="totalLabel">
			{cardView ? (
				''
			) : (
				`${goals.total} ${t('goals.plural')}`
			)}

		</span>

	) : t('goals.no_goal')

};

function mapStateToProps(state) {

	return {

	}
}

function mapDispatchToProps(dispatch) {
	return {

	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TotalLabel));
