// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.Ahj\\+pxcgLjCBfxueqMPQaw\\=\\= {\n  margin-top: -10px;\n  font-size: 11px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.vbUhQu9R72oJav1b6I-fdg\\=\\= {\n  height: 100%;\n  width: 100%;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n/* Estilize o retângulo */\n.eZmi0yEFMo8XUoV4Vxh29g\\=\\= {\n  cursor: pointer;\n  transition: fill 0.2s;\n}\n\n/* Estilize o ícone de exclusão */\n.ZKTJVKAQD49umy7xUOcU4g\\=\\= {\n  font-size: 20px;\n  fill: red;\n  visibility: hidden;\n  cursor: pointer;\n  transition: visibility 0.2s;\n}\n\n/* Quando o mouse passar sobre o grupo (retângulo) */\n.xEfbRSsPk0OxnHoUKSC-vw\\=\\=:hover .ZKTJVKAQD49umy7xUOcU4g\\=\\= {\n  visibility: visible;\n}", "",{"version":3,"sources":["webpack://./src/_components/rooms_page/SpaceMapEditor/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,iBAAA;EACA,eAAA;EACA,yBAAA;AAEF;;AACA;EACE,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AAEF;;AACA,yBAAA;AACA;EACE,eAAA;EACA,qBAAA;AAEF;;AACA,iCAAA;AACA;EACE,eAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;AAEF;;AACA,oDAAA;AACA;EACE,mBAAA;AAEF","sourcesContent":[".labelColor{\n  margin-top: -10px;\n  font-size: 11px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.inputColor{\n  height: 100%;\n  width: 100%;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n/* Estilize o retângulo */\n.rectangle {\n  cursor: pointer;\n  transition: fill 0.2s;\n}\n\n/* Estilize o ícone de exclusão */\n.deleteIcon {\n  font-size: 20px;\n  fill: red;\n  visibility: hidden;\n  cursor: pointer;\n  transition: visibility 0.2s;\n}\n\n/* Quando o mouse passar sobre o grupo (retângulo) */\n.rectangleGroup:hover .deleteIcon {\n  visibility: visible;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelColor": "Ahj+pxcgLjCBfxueqMPQaw==",
	"inputColor": "vbUhQu9R72oJav1b6I-fdg==",
	"rectangle": "eZmi0yEFMo8XUoV4Vxh29g==",
	"deleteIcon": "ZKTJVKAQD49umy7xUOcU4g==",
	"rectangleGroup": "xEfbRSsPk0OxnHoUKSC-vw=="
};
export default ___CSS_LOADER_EXPORT___;
