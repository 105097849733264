// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bP-5hH1FJb6\\+NNvAtR5GsQ\\=\\= {\n  padding: 24px 32px;\n}", "",{"version":3,"sources":["webpack://./src/pages/RemoteCheckinReportsTeammatesPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".containerCheckinPage {\n  padding: 24px 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerCheckinPage": "bP-5hH1FJb6+NNvAtR5GsQ=="
};
export default ___CSS_LOADER_EXPORT___;
