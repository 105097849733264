export const teammatesConstants = {
    GET_TEAMMATES_REQUEST: '[TEAMMATES] GET_TEAMMATES_REQUEST',
    GET_TEAMMATES_SUCCESS: '[TEAMMATES] GET_TEAMMATES_SUCCESS',
    GET_TEAMMATES_FAILURE: '[TEAMMATES] GET_TEAMMATES_FAILURE',
    GET_TEAMMATE_REQUEST: '[TEAMMATES] GET_TEAMMATE_REQUEST',
    GET_TEAMMATE_SUCCESS: '[TEAMMATES] GET_TEAMMATE_SUCCESS',
    GET_TEAMMATE_FAILURE: '[TEAMMATES] GET_TEAMMATE_FAILURE',
    PATCH_TEAMMATE_REQUEST: '[TEAMMATES] PATCH_TEAMMATE_REQUEST',
    PATCH_TEAMMATE_SUCCESS: '[TEAMMATES] PATCH_TEAMMATE_SUCCESS',
    PATCH_TEAMMATE_FAILURE: '[TEAMMATES] PATCH_TEAMMATE_FAILURE',
    DELETE_TEAMMATE_REQUEST: '[TEAMMATES] DELETE_TEAMMATE_REQUEST',
    DELETE_TEAMMATE_SUCCESS: '[TEAMMATES] DELETE_TEAMMATE_SUCCESS',
    DELETE_TEAMMATE_FAILURE: '[TEAMMATES] DELETE_TEAMMATE_FAILURE',
    POST_TEAMMATE_REQUEST: '[TEAMMATES] POST_TEAMMATE_REQUEST',
    POST_TEAMMATE_SUCCESS: '[TEAMMATES] POST_TEAMMATE_SUCCESS',
    POST_TEAMMATE_FAILURE: '[TEAMMATES] POST_TEAMMATE_FAILURE'
};