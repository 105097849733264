import React from 'react';

const IconInvitation = props => {
    return (
        <svg className="icon" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">

        <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 0.667)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_27" data-name="Caminho 27" d="M18.667,10a2.667,2.667,0,1,0,2.667,2.667A2.667,2.667,0,0,0,18.667,10Zm0,4A1.333,1.333,0,1,1,20,12.667,1.333,1.333,0,0,1,18.667,14Z" transform="translate(-11.333 -7.333)" fill="#22bead"/>
            <path id="Caminho_28" data-name="Caminho 28" d="M9.333,2a7.3,7.3,0,1,0,5.2,2.138A7.333,7.333,0,0,0,9.333,2ZM5.267,13.733a8.8,8.8,0,0,1,4.067-1.067A8.8,8.8,0,0,1,13.4,13.733a5.967,5.967,0,0,1-8.133,0ZM14.3,12.7h0a10.233,10.233,0,0,0-4.967-1.367A10.233,10.233,0,0,0,4.367,12.7h0A5.9,5.9,0,0,1,3.333,9.333a6,6,0,1,1,12,0A5.9,5.9,0,0,1,14.3,12.7Z" transform="translate(-2 -2)" fill="#22bead"/>
          </g>
        </g>
      </svg>
      
    )
}

export default IconInvitation;