import React from 'react';
import { useTranslation } from "react-i18next";

import Ruler from './Ruler';
import moment from 'moment'
import "react-circular-progressbar/dist/styles.css";

import './styles.scss'
import { Tooltip } from '@material-ui/core';

export function ObjectivesTempRuler({ purpose, goalsData, timePast, timeMissing, generalProgress, handleChangeMenu, selectedMenu }) {
  const { t } = useTranslation('common');

  let dt_start = goalsData.date_start
  let dt_end = goalsData.date_end
  if (goalsData.date_start === null || goalsData.date_end === null) {
    const year = moment().year()
    dt_start = `${year}-01-01T10:00:00.000`
    dt_end = `${year}-12-31T10:00:00.000`
  }
  
  const data = {
    typePeriod: 'trimestre',
    minDateGoal: dt_start,//goalsData?.date_start,
    maxDateGoal: dt_end,//goalsData?.date_end,
    percentualProgressMedio: generalProgress,
    daysPast: 93,
    dayMiss: 120,
    goals: goalsData?.rulerGoalsFiltered
  }

  return (
    <div className="container-objectives-ruler">
      {/* <header>
        <h2>
          {purpose || t('goals.objectives-ruler-title')}
        </h2>
      </header> */}
      {
        goalsData?.goalsYears?.length > 1 &&
        <header>
          {
            goalsData?.goalsYears?.map((item, index) => (
              <button
                onClick={() => handleChangeMenu(item?.year)}
                className={selectedMenu === item?.year ? "selected" : ''}
                type="button"
                key={index}
              >
                {item?.year}
              </button>
            ))
          }
        </header>

      }
      <span className='labelIndicatonDays'>
        {t('goals.time_past') + ` ${timePast} ` + t('goals.days') + ` - ${t('goals.time_missing')} ${timeMissing} ` + t('goals.days')}
      </span>

      <main className="content-objectives-ruler" style={{ position: 'relative' }}>
        <div>
          <Ruler data={data} />
        </div>

        <div className='progressBarTemp'>
          <div style={{ width: '100%', left: '0%' }}>
            <Tooltip title={t('goals.label_progress_general')} arrow>
              <div className='progress' style={{ width: data?.percentualProgressMedio + '%', cursor: 'pointer', maxWidth: '100%' }}>
                {
                  data?.percentualProgressMedio ? <div className='label' style={{ fontWeight: 600 }}>{data?.percentualProgressMedio + '%'}</div>
                    : <div className='label' style={{ fontWeight: 600 }}>{'0%'}</div>
                }
              </div>
            </Tooltip>
          </div>
        </div>

      </main>
    </div>
  )
}

