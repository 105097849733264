import {activitiesConstants} from '../_constants';

export function activities(state = {}, action) {
    switch (action.type) {
        case activitiesConstants.GET_ACTIVITIES_REQUEST:

            return {
                ...state,
                updated: '',
                loading: true
            };

        case activitiesConstants.GET_ACTIVITIES_CATEGORIES_REQUEST:

            return {
                ...state,
                loadingCategories: true
            };

        case activitiesConstants.GET_ACTIVITIES_OFFSET_REQUEST:

            return {
                ...state,
                updated: '',
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_REQUEST:

            return {
                ...state,
                detailsTeams: [],
                updated: '',
                loadingDetailsTeams: true
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_REQUEST:

            return {
                ...state,
                detailsTeammates: [],
                updated: '',
                loadingDetailsTeammates: true
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_REQUEST:

            return {
                ...state,
                detailsSpecific: [],
                updated: '',
                loadingDetailsSpecific: true
            };

        case activitiesConstants.GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                totalActivies: action.payload.totalActivies,
                total: action.payload.total,
                rows: action.payload.rows,
                loading: false,
                labelsOutdoorGroup: action.payload.labels,
                user: action.payload.user
            };

        case activitiesConstants.GET_ACTIVITIES_CATEGORIES_SUCCESS:

            return {
                ...state,
                categories: action.payload,
                loadingCategories: false
            };

        case activitiesConstants.GET_ACTIVITIES_OFFSET_SUCCESS:
            return {
                ...state,
                totalActivies: action.payload.totalActivies,
                total: action.payload.total,
                rows: [...state.rows, ...action.payload.rows]
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_SUCCESS:

            return {
                ...state,
                detailsTeams: action.payload,
                loadingDetailsTeams: false
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_SUCCESS:

            return {
                ...state,
                detailsTeammates: action.payload,
                loadingDetailsTeammates: false
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_SUCCESS:

            return {
                ...state,
                detailsSpecific: action.payload,
                loadingDetailsSpecific: false
            };

        case activitiesConstants.GET_ACTIVITIES_FAILURE:

            return {
                ...state,
                error: action.error,
                loading: true
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_FAILURE:

            return {
                ...state,
                error: action.error,
                loading: true
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_FAILURE:

            return {
                ...state,
                error: action.error,
                loading: true
            };

        case activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_FAILURE:

            return {
                ...state,
                error: action.error,
                loading: true
            };

        case activitiesConstants.GET_ACTIVITIES_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingCategories: true
            };

        case activitiesConstants.PATCH_ACTIVITIES_RELATIONS_REQUEST:

            return {
                ...state,
                updated: '',
                loadingDetailsTeams: true
            };

        case activitiesConstants.PATCH_ACTIVITIES_RELATIONS_SUCCESS:

            return {
                ...state,
                updated: 'success',
                loadingDetailsTeams: false
            };

        case activitiesConstants.PATCH_ACTIVITIES_RELATIONS_FAILURE:

            return {
                ...state,
                error: action.error,
                updated: 'error',
                loadingDetailsTeams: false
            };
        case activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_REQUEST:

            return {
                ...state,
                updated: '',
                loadingDetailsTeams: true
            };

        case activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_SUCCESS:

            return {
                ...state,
                updated: 'success',
                loadingDetailsTeams: false
            };

        case activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_FAILURE:

            return {
                ...state,
                error: action.error,
                updated: 'error',
                loadingDetailsTeams: false
            };

        case activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_REQUEST:

            return {
                ...state,
                updated: ''

            };

        case activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_SUCCESS:

            return {
                ...state,
                updated: 'success'

            };

        case activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_FAILURE:

            return {
                ...state,
                error: action.error,
                updated: 'error'

            };

        default:
            return {
                ...state
            }
    }
}

