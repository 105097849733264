import React from "react";
import "./styles.scss";
import { ViewMode } from "gantt-task-react";
import { useTranslation } from "react-i18next";

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  view
}) => {
  const { t, i18n } = useTranslation('common');
  return (
    <div className="ViewContainer">
      {/* <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Hour
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </button> */}
      <button className={`Button ${view === ViewMode.Day && 'Active'}`} onClick={() => onViewModeChange(ViewMode.Day)}>
        {t('project_management.reports.time_line.day')}
      </button>
      <button
        className={`Button ${view === ViewMode.Week && 'Active'}`}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        {t('project_management.reports.time_line.week')}
      </button>
      <button
        className={`Button ${view === ViewMode.Month && 'Active'}`}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
       {t('project_management.reports.time_line.month')}
      </button>
      <button
        className={`Button ${view === ViewMode.Year && 'Active'}`}
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        {t('project_management.reports.time_line.year')}
      </button>
      {/* <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterYear)}
      >
        Year
      </button> */}
      <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        {t('project_management.reports.time_line.show_task_list')}
      </div>
    </div>
  );
};