import {activitiesConstants} from '../_constants';
import {activitiesService} from '../_services';
import {alertActions} from '.';

export const activitiesActions = {
    getActivities,
    getActivitiesCategories,
    getActivityDetailsTeams,
    getActivityDetailsTeammates,
    getActivityDetailsSpecific,
    setRelation,
    setCategoryRelation,
    getProductivityActivities,
    getProductivityInactivity,
    setTransactional
};


function getActivities(startDate, endDate, teams = null, teammates = null, offset = null, software = true, site = true, offline = true, productive = true, neutral = true, unproductive = true, search = null) {
    return dispatch => {
        if (offset) {
            dispatch(requestOffset());
        } else {
            dispatch(request());
        }

        activitiesService.getActivities(startDate, endDate, teams, teammates, offset, software, site, offline, productive, neutral, unproductive, search)
            .then(
                payload => {
                    if (offset) {
                        dispatch(successOffset(payload))
                    } else {
                        dispatch(success(payload))
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.GET_ACTIVITIES_REQUEST}
    }

    function requestOffset() {
        return {type: activitiesConstants.GET_ACTIVITIES_OFFSET_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_SUCCESS, payload}
    }

    function successOffset(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_OFFSET_SUCCESS, payload}
    }

    function failure(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_FAILURE, error}
    }
}

function getActivityDetailsTeams(id, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive) {
    return dispatch => {

        dispatch(requestDetails());

        activitiesService.getActivityDetailsTeam(id, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive)
            .then(
                payload => {

                    dispatch(successDetails(payload.teams))

                },
                error => {
                    dispatch(failureDetails(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function requestDetails() {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_REQUEST}
    }

    function successDetails(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_SUCCESS, payload}
    }

    function failureDetails(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMS_FAILURE, error}
    }
}

function getActivityDetailsTeammates(id, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive) {
    return dispatch => {

        dispatch(requestDetails());

        activitiesService.getActivityDetailsTeammates(id, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive)
            .then(
                payload => {
                    dispatch(successDetails(payload.teammates))
                },
                error => {
                    dispatch(failureDetails(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function requestDetails() {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_REQUEST}
    }

    function successDetails(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_SUCCESS, payload}
    }

    function failureDetails(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_TEAMMATES_FAILURE, error}
    }
}

function getActivityDetailsSpecific(startDate, endDate, machineUserId, trackedId) {
    return dispatch => {

        dispatch(requestDetails());

        activitiesService.getActivityDetailsSpecific(startDate, endDate, machineUserId, trackedId)
            .then(
                payload => {
                    dispatch(successDetails(payload))
                },
                error => {
                    dispatch(failureDetails(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function requestDetails() {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_REQUEST}
    }

    function successDetails(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_SUCCESS, payload}
    }

    function failureDetails(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_DETAILS_SPECIFIC_FAILURE, error}
    }
}


function getActivitiesCategories() {
    return dispatch => {

        dispatch(requestDetails());

        activitiesService.getActivitiesCategories()
            .then(
                payload => {
                    dispatch(successDetails(payload))
                },
                error => {
                    dispatch(failureDetails(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function requestDetails() {
        return {type: activitiesConstants.GET_ACTIVITIES_CATEGORIES_REQUEST}
    }

    function successDetails(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_CATEGORIES_SUCCESS, payload}
    }

    function failureDetails(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_CATEGORIES_FAILURE, error}
    }
}


function setRelation(teamId, efficiencyId, efficiencyName, subteams, company) {
    return dispatch => {

        dispatch(request());

        activitiesService.setRelation(teamId, efficiencyId, efficiencyName, subteams, company)
            .then(
                payload => {
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.PATCH_ACTIVITIES_RELATIONS_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.PATCH_ACTIVITIES_RELATIONS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: activitiesConstants.PATCH_ACTIVITIES_RELATIONS_FAILURE, error}
    }
}

function setTransactional(update_type, group_id, tracked_id, transactional) {
    return dispatch => {

        dispatch(request());

        activitiesService.setTransactional(update_type, group_id, tracked_id, transactional)
            .then(
                response => {
                    dispatch(success(response))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_SUCCESS, payload}
    }

    function failure(error) {
        return {type: activitiesConstants.PUT_ACTIVITIES_TRANSACTIONAL_FAILURE, error}
    }
}

function setCategoryRelation(teamId, categoryId, efficiencyName, subCategory) {
    return dispatch => {

        dispatch(request());

        activitiesService.setCategoryRelation(teamId, categoryId, efficiencyName, subCategory)
            .then(
                payload => {
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: activitiesConstants.PATCH_ACTIVITIES_CATEGORY_RELATIONS_FAILURE, error}
    }
}


function getProductivityActivities(date) {
    return dispatch => {

        dispatch(request());

        activitiesService.getProductivityActivities(date)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.GET_ACTIVITIES_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_SUCCESS, payload}
    }


    function failure(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_FAILURE, error}
    }
}

function getProductivityInactivity(start_date, end_date) {
    return dispatch => {

        dispatch(request());

        activitiesService.getProductivityInactivity(start_date, end_date)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: activitiesConstants.GET_ACTIVITIES_REQUEST}
    }

    function success(payload) {
        return {type: activitiesConstants.GET_ACTIVITIES_SUCCESS, payload}
    }


    function failure(error) {
        return {type: activitiesConstants.GET_ACTIVITIES_FAILURE, error}
    }
}


