import React from 'react';

const IconHistorical = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_11" data-name="Retângulo 11" width="24" height="24" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(2 2)">
            <path id="Caminho_1" data-name="Caminho 1" d="M12.55,12.35l4.05,4L24.25,8.2a1.026,1.026,0,1,0-1.5-1.4L16.4,13.6,12.45,9.65,6,16.1V5A1,1,0,0,0,4,5V24H23a1,1,0,0,0,0-2H6V18.9Z" transform="translate(-4 -4)" fill="#97b3bf"/>
        </g>
        </svg>
 

    )
}

export default IconHistorical;