import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../_components/_shared/Toolbar'

import { useTranslation, initReactI18next } from "react-i18next";
import DownloadApps from '../../_components/remote_checkin/DownloadApps';

function RemoteCheckinDownloadPage(props) {

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.user.download');
  });

  return (

    <React.Fragment>
      <section className="mainContent">
        <Toolbar />
        <div className="mainFilterbar">

        </div>
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn">
            <DownloadApps />
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>


    </React.Fragment>
  )
};

function mapDispatchToProps(dispatch) {

  return {
  }
}

function mapStateToProps(state) {

  return {

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteCheckinDownloadPage)
