// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".beCItBAachmxu\\+sLHDo98A\\=\\= {\n  padding: 12px 16px;\n}", "",{"version":3,"sources":["webpack://./src/pages/DaysOffSettingsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".containerDaysOffPage {\n  padding: 12px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDaysOffPage": "beCItBAachmxu+sLHDo98A=="
};
export default ___CSS_LOADER_EXPORT___;
