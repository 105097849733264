// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Jrd21e6eelvnrFbJt9vEA\\=\\= svg {\n  color: #4F7091;\n}\n\n._9Jrd21e6eelvnrFbJt9vEA\\=\\=:hover svg {\n  color: #187DFF;\n}", "",{"version":3,"sources":["webpack://./src/_components/rooms_page/rooms/Card.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAKE;EACE,cAAA;AAFJ","sourcesContent":[".iconDrawer {\n  svg {\n    color: #4F7091;\n  }\n}\n\n.iconDrawer:hover {\n  svg {\n    color: #187DFF;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconDrawer": "_9Jrd21e6eelvnrFbJt9vEA=="
};
export default ___CSS_LOADER_EXPORT___;
