// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6R\\+HOaVcl6jiSixXpbO9jw\\=\\= {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n  background-color: #FFFFFF;\n  border-radius: 4px;\n  padding: 18px;\n  min-height: 286px;\n}\n\n._4yEMKmy65oHQEjikCV8PBw\\=\\= {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.oPt4hsNai2s0cP2UE8gTJQ\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 35px;\n}\n\n.vwrmuAe0PuT9QpH9L8T-cA\\=\\= {\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 0;\n}\n\n._5HCMCLod51YUWR2cnR5adA\\=\\= {\n  font-size: 24px;\n  margin: 4px;\n}\n\n._5HCMCLod51YUWR2cnR5adA\\=\\= span {\n  font-size: 24px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/_components/executive/Widget11/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;AAEF","sourcesContent":[".container {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n  background-color: #FFFFFF;\n  border-radius: 4px;\n  padding: 18px;\n  min-height: 286px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.boxTitle {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 35px;\n}\n\n.title {\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 0;\n}\n\n.boxInfoStats{\n  font-size: 24px;\n  margin: 4px;\n}\n.boxInfoStats span{\n  font-size: 24px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_6R+HOaVcl6jiSixXpbO9jw==",
	"header": "_4yEMKmy65oHQEjikCV8PBw==",
	"boxTitle": "oPt4hsNai2s0cP2UE8gTJQ==",
	"title": "vwrmuAe0PuT9QpH9L8T-cA==",
	"boxInfoStats": "_5HCMCLod51YUWR2cnR5adA=="
};
export default ___CSS_LOADER_EXPORT___;
