// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "table thead tr .k2fH\\+y-zj0YMhT-qaQYb3g\\=\\= {\n  font-size: 10px;\n  color: #4F7091;\n  font-weight: 500;\n  border-bottom: 2px solid #F5F6F9 !important;\n}\n\n.jpRqdxlpIFvwkOqf6BBdAw\\=\\= {\n  color: #98ACC1;\n}\n.jpRqdxlpIFvwkOqf6BBdAw\\=\\=:hover {\n  color: #FF5151;\n}\n\n.eMZCx1z2mGSTzJ\\+uugvlWA\\=\\=:hover {\n  cursor: pointer;\n  border-color: #E4F7F5;\n  background-color: #E4F7F5;\n}\n\n.IAs6W1MiauGckUC1qf-cLg\\=\\= {\n  background-color: #22BEAD;\n  border-color: #22BEAD !important;\n}\n.IAs6W1MiauGckUC1qf-cLg\\=\\= span {\n  color: #fff;\n}\n.IAs6W1MiauGckUC1qf-cLg\\=\\=:hover {\n  background-color: #22BEAD;\n}\n.IAs6W1MiauGckUC1qf-cLg\\=\\=:hover span {\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/_components/rooms_page/reservationForm/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EAEA,2CAAA;AAAF;;AAGA;EACE,cAAA;AAAF;AAEE;EACE,cAAA;AAAJ;;AAIA;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;AADF;;AAIA;EACE,yBAAA;EACA,gCAAA;AADF;AAGE;EACE,WAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAII;EACE,WAAA;AAFN","sourcesContent":["table thead tr .tableHeaderTd {\n  font-size: 10px;\n  color: #4F7091;\n  font-weight: 500;\n\n  border-bottom: 2px solid #F5F6F9 !important;\n}\n\n.trash {\n  color: #98ACC1;\n\n  &:hover {\n    color: #FF5151;\n  }\n}\n\n.time:hover {\n  cursor: pointer;\n  border-color: #E4F7F5;\n  background-color: #E4F7F5;\n}\n\n.selected {\n  background-color: #22BEAD;\n  border-color: #22BEAD !important;\n\n  span {\n    color: #fff;\n  }\n\n  &:hover {\n    background-color: #22BEAD;\n\n    span {\n      color: #fff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderTd": "k2fH+y-zj0YMhT-qaQYb3g==",
	"trash": "jpRqdxlpIFvwkOqf6BBdAw==",
	"time": "eMZCx1z2mGSTzJ+uugvlWA==",
	"selected": "IAs6W1MiauGckUC1qf-cLg=="
};
export default ___CSS_LOADER_EXPORT___;
