import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from 'react-router';
import { DotsThreeCircleVertical } from "phosphor-react";
import moment from "moment";
import { Avatar, CircularProgress, Menu, MenuItem, Slide } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Select from 'react-select'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Divider, Popover, Switch, Typography } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import { AvatarGroup } from "@material-ui/lab";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BefectiveDatePicker } from "../../BefectiveDatePicker";


import { authHeader, enumerateYearsBetweenDates, getAvatarUrl, getOptionsPeriods, getDynamicRedirectUrl, getValuePeriod } from "../../../../_helpers";
import IconClose from "../../../_icons/IconClose";
import IconInfo from "../../../_icons/IconInfo";
import { api } from "../../../../_helpers/api";
import ConfirmDialog from "../../../_shared/ConfirmDialog";
import IconExpand from "../../../_icons/IconExpand";
import { alertActions } from "../../../../_actions";
import ModalCommentsGoal from "../../ModalCommentsGoal";
import MuiSelect from "@material-ui/core/Select";

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootTable: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  rootTab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '37ch',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    position: 'absolute',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '940px',
    height: '560px',
    outline: 'none',
    borderRadius: '8px'
  },
  rowTable: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#E4EAEF !important"
    }
  }
}));


const CollapsedRow = ({
  childrenRow,
  row,
  openModule,
  index,
  innerRef,
  provided,
  snapshot,
  userDetails
}) => {

  const classes = useStyles();
  const history = useHistory();
  const today = new Date(String(new Date().toISOString()).split('T')[0])

  const listColorsStatus = {
    completed: '#22BEAD',
    progress: '#FDA02B',
    planning: '#98ACC1',
    overdue: '#FF5151'
  }

  let colorStatus = listColorsStatus.planning
  let goalProgress = Number(childrenRow?.progress)
  let newDateEnd = new Date(childrenRow?.date_end)

  if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEnd)) {
    colorStatus = listColorsStatus.progress
  } else if (goalProgress >= 100.00) {
    colorStatus = listColorsStatus.completed
  } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEnd) {
    colorStatus = listColorsStatus.overdue
  }
  return (
    <React.Fragment>
      <TableRow
        key={childrenRow.title}
        style={{ margin: 2, cursor: 'pointer' }}
        className={`${classes.tableRow} ${snapshot.isDragging ? 'dragging' : ''}`}
        onClick={
          () => history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${childrenRow?.goal_id}?kr_ids=${childrenRow?.id},`))
        }
        ref={innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TableCell style={{ width: '37%', flexWrap: 'nowrap' }}>
          {childrenRow.title}
        </TableCell>
        <TableCell width={'6%'}>
          <span style={{ backgroundColor: '#F3F7FA', color: '#9EAFC3', padding: '2px 6px', borderRadius: '4px' }}>
            {'KR' + (index + 1)}
          </span>
        </TableCell>
        <TableCell width={'10%'}>
          <Tooltip arrow title={childrenRow?.progress + '%'} >
            <div className="progress-column-container-kr">
              <div
                className="bar-progress"
                style={{ width: childrenRow?.progress + '%', backgroundColor: colorStatus }}
              />
            </div>
          </Tooltip>
        </TableCell>
        <TableCell style={{ minWidth: '13%', width: '16%' }} />
        <TableCell style={{ minWidth: '13%', width: '16%' }} />
        <TableCell style={{ minWidth: '10%', width: '10%' }} />
        <TableCell style={{ minWidth: '6%', width: '6%' }} />
        <TableCell style={{ minWidth: '4%', width: '4%' }} />
      </TableRow>
      {
        parseInt(index) < row?.key_results?.length - 1 &&
        <TableRow >
          <TableCell colSpan={9} style={{ padding: '0px 4px' }}>
            <Divider />
          </TableCell>
        </TableRow>
      }
    </React.Fragment>
  );
};

function Row(props) {
  const {
    row,
    index,
    hover,
    tab,
    columns,
    handleClickMenuGoals,
    toggleRemoveDialog,
    handleArchiveGoal,
    openAll,
    responsable,
    showCommentsGoalModal,
    innerRef,
    provided,
    snapshot,
    updateDashboardInfo,
    userDetails
  } = props;

  const [dataSorted, setDataSorted] = useState(row)
  const [openModule, setOpenModule] = useState(false);
  const [weightValue, setWeightValue] = useState(row?.weight || 1);
  const today = new Date(String(new Date().toISOString()).split('T')[0])
  const history = useHistory();

  const token = authHeader()

  const { t } = useTranslation('common');
  const objColorsStatus = {
    completed: { color: '#22BEAD', bgColor: '#D6F4F1' },
    progress: { color: '#FDA02B', bgColor: '#FFF0DD' },
  }

  const listColorsStatus = {
    completed: '#22BEAD',
    progress: '#FDA02B',
    planning: '#98ACC1',
    overdue: '#FF5151'
  }

  let statusGoal = 'planning'
  let goalProgress = Number(row?.progress)
  let dateEndSplit = row.end.split('/')
  let newDateEndGoal = new Date(dateEndSplit[2] + '-' + dateEndSplit[1] + '-' + dateEndSplit[0])

  if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEndGoal)) {
    statusGoal = 'progress'
  } else if (goalProgress >= 100.00) {
    statusGoal = 'completed'
  } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEndGoal) {
    statusGoal = 'overdue'
  }

  const handleChange = (e) => {
    setWeightValue(e.target.value)
  }

  const handleDragEndKr = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const kr_id = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const keyResultFiltered = row.key_results.find(kr => Number(kr.id) === Number(kr_id))

    const newItems = Array.from(dataSorted.key_results);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setDataSorted({
      ...row,
      key_results: newItems
    });

    try {
      await api.patch(`/goals/key-result/${kr_id}/update-index?organization_id=${userDetails?.organizationId}`, {
        source: source,
        destination: destination,
        goal_id: keyResultFiltered.goal_id,
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      updateDashboardInfo()
    } catch (error) {
      console.log(error.response)
    }

  };

  useEffect(() => {
    setOpenModule(openAll)
  }, [openAll])

  useEffect(() => {
    if (snapshot.isDragging) {
      setOpenModule(false)
    }
  }, [snapshot.isDragging])

  useEffect(() => {
    if (row) {
      setDataSorted(row)
    }
  }, [row])

  return (
    <React.Fragment>
      <TableRow
        className={`
          ${hover && "hover"} 
          ${openModule && row?.key_results?.length > 0 ? 'table-row-goal-focus ' + statusGoal : ''}
          row-objective
          ${snapshot.isDragging && 'dragging'}
          ${parseInt(index) % 2 === 0 ? 'bgColorGray' : '#FFFFFF'}
        `}
        style={{
          cursor: (tab === 'goals') ? 'grab' : 'normal'
        }}
        ref={innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {columns.map((col, index) => {
          let status = {}
          if (row.status === 'completed') {
            status.color = objColorsStatus.completed.color
            status.bgColor = objColorsStatus.completed.bgColor
          } else {
            status.color = objColorsStatus.progress.color
            status.bgColor = objColorsStatus.progress.bgColor
          }


          switch (col.field) {
            case 'progress':

              let colorStatus = listColorsStatus.planning
              let goalProgress = Number(row[col.field])
              let dateEndSplit = row.end.split('/')
              let newDateEnd = new Date(dateEndSplit[2] + '-' + dateEndSplit[1] + '-' + dateEndSplit[0])

              if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEnd)) {
                colorStatus = listColorsStatus.progress
              } else if (goalProgress >= 100.00) {
                colorStatus = listColorsStatus.completed
              } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEnd) {
                colorStatus = listColorsStatus.overdue
              }

              return (
                <TableCell key={index} onClick={() => {
                  if (tab && tab === 'goals') {
                    return history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${row?.id}`))
                  } else {
                    return
                  }
                }}
                  width={'10%'}
                >
                  <Tooltip arrow title={row[col.field] + '%'} >

                    <div className="progress-column-container">
                      <div
                        className="bar-progress"
                        style={{ width: row[col.field] + '%', backgroundColor: colorStatus }}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
              )
            case 'activity':
              return (
                <TableCell key={index} className="progress" style={{ color: status.color }}>
                  {row[col.field]}
                </TableCell>
              )
            case 'percent':
              return (
                <TableCell key={index} className="progress">
                  <div className="percent-background" style={{ color: status.color, backgroundColor: status.bgColor }}>
                    {row[col.field]}
                  </div>
                </TableCell>
              )
            case 'objective':
              return (
                <TableCell
                  key={index}
                  className={`${col.field === 'krs' ? 'krs-column' : ''}`}
                  style={{
                    width: '30%',
                    flexWrap: 'nowrap',
                  }}
                  onClick={() => {
                    if (tab && tab === 'goals') {
                      return history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${row?.id}`))

                    } else {
                      return
                    }
                  }}
                >
                  {col.field === 'id' ? Number(index) + 1 : row[col.field]}
                </TableCell>
              )
            case 'krs':
              return (
                <TableCell key={index} className={`${col.field === 'krs' ? 'krs-column' : ''}`} onClick={() => {
                  if (tab && tab === 'goals') {
                    return history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${row?.id}`))

                  } else {
                    return
                  }
                }}
                  style={{ width: '4.5%', minWidth: '4.5%' }}
                >
                  {col.field === 'id' ? Number(index) + 1 : row[col.field]}
                </TableCell>
              )
            case 'published':
              return (
                <TableCell
                  style={{ minWidth: '8%', width: '8%' }}
                  key={index}
                  className={`${col.field === 'krs' ? 'krs-column' : ''}`}
                >
                  {
                    <div className='textCenter' style={{ display: 'flex', justifyContent: 'center', textTransform: 'capitalize' }}>
                      {row[col.field] ? <span style={{ color: '#22BEAD' }}>{t('settings.company.yes')}</span> : <span style={{ color: '#FF5151' }}>{t('settings.company.no')}</span>}
                    </div>
                  }

                </TableCell>
              )

            case 'responsible':
              return (
                <TableCell
                  style={{ minWidth: '8%', width: '8%' }}
                  key={index}
                  className={`${col.field === 'krs' ? 'krs-column' : ''}`}
                  onClick={() => showCommentsGoalModal(row)}
                >
                  {
                    <div className='textCenter' style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* {task?.username} */}
                      <AvatarGroup max={4}>
                        <Tooltip title={responsable?.name}>
                          <Avatar
                            alt={responsable?.name}
                            src={getAvatarUrl({ name: responsable?.name })}
                            style={{ width: 24, height: 24 }}
                          />
                        </Tooltip>
                      </AvatarGroup>
                    </div>
                  }

                </TableCell>
              )

            case 'weight':
              return (
                <TableCell
                  style={{ minWidth: '6%', width: '6%' }}
                  key={index}
                  className={`${col.field === 'krs' ? 'krs-column' : ''}`}
                >
                  {/* <TextFieldEdit initialWeightValue={row?.weight} itemEditId={row?.id} label="Label" id="label-input" type="number" min={1} max={4} /> */}
                  <MuiSelect
                    style={{ color: '#98ACC1', textAlign: 'right', backgroundColor: '#E8E8E8', borderRadius: '6px' }}
                    displayEmpty
                    disableUnderline
                    inputProps={{ "aria-label": "Without label" }}
                    name="weight"
                    id="weight"
                    value={weightValue}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </MuiSelect>
                </TableCell>
              )
            default:
              return (
                <TableCell key={index} className={`${col.field === 'krs' ? 'krs-column' : ''}`} onClick={() => {
                  if (tab && tab === 'goals') {
                    return history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${row?.id}`))

                  } else {
                    return
                  }
                }}>
                  {col.field === 'id' ? Number(index) + 1 : row[col.field]}
                </TableCell>
              )
          }
        })}
        {
          tab && tab === 'goals' && (
            <TableCell
              key={'options'}
              style={{ paddingRight: 4, paddingLeft: 4 }}

            >
              <div
                style={{ display: 'flex', alignItems: 'center', marginRight: 1, gap: 3 }}
                className={`actions`}
              >

                <div
                  className={`button-options ${openModule ? statusGoal : ''}`}
                  style={userDetails?.goalsPermissions?.includes(parseInt(row?.id)) ? { cursor: 'pointer' } : { opacity: '0' }}
                  onClick={(e) => userDetails?.goalsPermissions?.includes(parseInt(row?.id)) && handleClickMenuGoals(e, row)}
                >
                  <DotsThreeCircleVertical size={20} />

                </div>

                <div style={{ marginTop: '-5px' }}>
                  <IconButton
                    className={`button-expand ${openModule ? 'rotate ' + statusGoal : ''}`}
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenModule(!openModule)}
                  >
                    {openModule ? <IconExpand /> : <IconExpand />}
                  </IconButton>
                </div>
                <div className="removeDialog" id={"remove-" + row?.id}>
                  <div className="content">
                    <div className="message">
                      {t('confirmDialog.title')}
                    </div>
                    <div className="options">
                      <button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
                      <button onClick={() => { handleArchiveGoal() }}>{t('confirmDialog.yes')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </TableCell>
          )
        }
      </TableRow>
      <TableRow className="kr-dashboard-list">
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={openModule} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <Table size="small" aria-label="purchases">
                <DragDropContext onDragEnd={handleDragEndKr}>
                  <Droppable droppableId="krs">
                    {(provided) => (
                      <TableBody
                        className={`${openModule ? 'table-body-focus ' + statusGoal : ''}`}
                        ref={provided.innerRef}
                      >
                        {dataSorted.key_results.map((childrenRow, index) => (
                          <Draggable
                            key={childrenRow.id}
                            draggableId={String(childrenRow.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <CollapsedRow
                                row={row}
                                index={index}
                                childrenRow={childrenRow}
                                openModule={openModule}
                                key={childrenRow?.id}
                                innerRef={provided.innerRef}
                                provided={provided}
                                snapshot={snapshot}
                                userDetails={userDetails}
                              />
                            )}
                          </Draggable>

                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>

                </DragDropContext>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {
        openModule && <TableRow> <TableCell style={{ padding: 0, height: 3 }} colSpan={9}></TableCell></TableRow>
      }
    </React.Fragment>
  );
}


function CollapsibleTableNew({
  data = null,
  columns = null,
  hover = true,
  striped = true,
  tab = '',
  userDetails = {},
  alertActions = null,
  teammates,
  teammatesActions = null,
  expand = false,
  updateDashboardInfo
}) {
  const classes = useStyles();

  const { t } = useTranslation('common');

  const [dataSorted, setDataSorted] = useState(data)
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [openModalEditGoal, setOpenModalEditGoal] = useState(false);
  const [isCheckType, setIsCheckType] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teammatesOptions, setTeammatesOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [openAll, setOpenAll] = useState(false)
  const [anchorElOptionsGoals, setAnchorElOptionsGoals] = useState(null);
  const [currentGoal, setCurrentGoal] = useState({});
  const [period, setPeriod] = useState('');
  const [valuePeriod, setValuePeriod] = useState('');
  const [openCommentsGoal, setOpenCommentsGoal] = useState(false);
  const [deleting, setDeleting] = useState(false)
  const [anchorElDeleteGoal, setAnchorElDeleteGoal] = useState(null);

  const handleClickDeleteGoal = (event) => {
    setAnchorElDeleteGoal(event.currentTarget);
  };

  const handleCloseDeleteGoal = () => {
    setAnchorElDeleteGoal(null);
  };

  const openDeleteGoal = Boolean(anchorElDeleteGoal);

  const id = openDeleteGoal ? 'simple-popover-delete-goal' : undefined;

  const [form, setForm] = useState({
    id: null,
    title: '',
    description: '',
    type: '2',
    responsable: '',
    period: '',
    value_period: '',
    year_period: '',
    date_start: '',
    date_end: '',
    weight: 1,
    published: true,
    created_by_id: null
  });
  const [formError, setFormError] = useState({
    id: null,
    title: '',
    description: '',
    type: '',
    responsable: '',
    date_start: '',
    date_end: '',
    weight: '',
    published: ''
  });

  const getCaps = (head, field) => {
    if (head) return head.toUpperCase();
    return field.toUpperCase();
  };

  const token = authHeader()

  function toggleRemoveDialog(id) {

    let elems = document.querySelectorAll(".removeDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById("remove-" + id)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  const handleChange = e => {

    if (e.target.name === 'year_period') {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
        date_start: moment.utc(e.target.value + '-01-01').format('YYYY-MM-DD'),
        date_end: moment.utc(String(parseInt(e.target.value) + 1) + '-01-01').subtract(1, 'days').format('YYYY-MM-DD')
      })
      return
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    if (e.target.name === 'type' && e.target.checked) {
      setIsCheckType(true)
    }
  }


  const closeModalEditGoal = () => {
    setOpenModalEditGoal(false);
  };

  const getDetailsObjective = async (goal_id) => {
    try {
      const result = await api.get(`goals/${goal_id}/details/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      let goal = result?.data
      let value_period = goal?.value_period ? goal?.value_period : ''
      let year_period = goal?.year_period ? goal?.year_period : moment.utc(goal?.date_start).format('YYYY')

      setForm({
        id: goal?.id,
        title: goal?.name,
        description: goal?.description,
        type: String(goal?.type_id),
        responsable: goal?.responsable,
        period: goal?.measurement,
        date_start: moment.utc(goal?.date_start?.split('T')[0]).format('YYYY-MM-DD'),
        date_end: moment.utc(goal?.date_end?.split('T')[0]).format('YYYY-MM-DD'),
        value_period: value_period,
        year_period: year_period,
        weight: goal?.weight,
        published: goal?.published,
        created_by_id: goal?.created_by_id
      })
      setPeriod(goal?.measurement)
      setValuePeriod(value_period)

      const resp1 = await api.get(`goals/get-responsibles?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      if (resp1.status === 200) {
        setTeammatesOptions(resp1.data)
      }


    } catch (error) {
      //alertActions.error(error)
    }

  }

  const handleClickMenuGoals = (event, goal) => {
    getDetailsObjective(goal?.id)
    setAnchorElOptionsGoals(event.currentTarget);
    setCurrentGoal(goal);
  };
  const handleCloseMenuOptionsGoals = () => {
    setAnchorElOptionsGoals(null);
  };

  const handleEditGoal = () => {
    setOpenModalEditGoal(true);
    setAnchorElOptionsGoals(null)
  }

  const handleArchiveGoal = async () => {

    try {

      const result = await api.put(`/goals/${currentGoal?.id}/deactive/?organization_id=${userDetails?.organizationId}`,
        {
          action_id: 9,
          from: {},
          to: {},
          datetime: new Date()
        },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      setAnchorElOptionsGoals(null);

      // setConfirmDialog({
      //   ...confirmDialog,
      //   isOpen: false
      // })

      toggleRemoveDialog()

      updateDashboardInfo()

      alertActions.success(t('goals.success.deactive'))
    } catch (error) {
      //alertActions.error(error)
    }
  }

  const handleDeleteGoal = async () => {

    try {

      setDeleting(true)
      const result = await api.delete(`/goals/${currentGoal?.id}?organization_id=${userDetails?.organizationId}`,
        {
          headers: {
            'Authorization': token?.Authorization
          }
        }
      )
      handleCloseDeleteGoal()
      handleCloseMenuOptionsGoals()
      updateDashboardInfo()
      alertActions.success(t('goals.success.deleted'))
      setDeleting(false)
    } catch (error) {
      alertActions.success(t('goals.error.deleted'))
      setDeleting(false)
    }
  }



  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('goals.errors.required')
    }
    // else if (form.title.length < 10) {
    //   errors.title = t('goals.errors.title_goal')
    // }

    if (!form.description) {
      errors.description = t('goals.errors.required')
    }
    // else if (form.description.length < 10) {
    //   errors.description = t('goals.errors.description_goal')
    // }

    if (!form.type) {
      errors.type = t('goals.errors.required')
    }

    if (!form.responsable) {
      errors.responsable = t('goals.errors.required')
    }

    if (!form.period) {
      errors.period = t('goals.errors.required')
    }

    if (!form.date_start) {
      errors.date_start = t('goals.errors.required')
    } else if (form.date_start >= form.date_end) {
      errors.date_start = t('goals.errors.invalid_field')
    }

    if (!form.date_end) {
      errors.date_end = t('goals.errors.required')
    } else if (form.date_end <= form.date_start) {
      errors.date_end = t('goals.errors.invalid_field')
    }

    return errors
  }

  const handleSubmitGoal = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const editGoal = async () => {
    try {
      setIsSubmitting(true)
      await api.put(`/goals/${form?.id}/?organization_id=${userDetails.organizationId}`, {
        action_id: 2,
        datetime: new Date(),
        title: form?.title,
        description: form?.description,
        type_id: Number(form?.type),
        accountable_id: form?.responsable,
        created_by: userDetails?.id,
        updated_by: userDetails?.id,
        measurement: form?.period,
        date_start: form?.date_start,
        date_end: form?.date_end,
        value_period: form?.value_period,
        weight: form?.weight,
        published: form?.published
      }, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      updateDashboardInfo()
      alertActions.success(t('goals.success.edit_goal'))
      setIsSubmitting(false)
      closeModalEditGoal()
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t('goals.errors.edit_goal'))
    }
  }

  const showCommentsGoalModal = (goal) => {
    setCurrentGoal(goal)
    setOpenCommentsGoal(true)
  }

  const handleCloseCommentsGoalModal = (e) => {
    e.preventDefault()
    setOpenCommentsGoal(false);
  };

  const yearsRange = enumerateYearsBetweenDates(moment.utc(), moment.utc().add(10, 'y'))?.map((year, index) => {
    return {
      type: 'yearly',
      id: year,
      value: year
    }
  })

  const optionsPeriods = getOptionsPeriods();

  const handleChangePeriod = (e) => {
    setPeriod(e.target.value)
    if (period === 'custom') {
      setForm({
        ...form,
        period: e.target.value,
        value_period: 'custom',
        date_start: '',
        date_end: ''
      })
    }
    setValuePeriod(optionsPeriods?.filter((item) => item.type === e.target.value)[0]?.value)
  }

  const handleDragEnd = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const goalId = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const newItems = Array.from(dataSorted);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setDataSorted(newItems);

    try {
      await api.patch(`/goals/${goalId}/update-index?organization_id=${userDetails?.organizationId}`, {
        source: source,
        destination: destination,
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })
    } catch (error) {
      console.log(error.response)
    }
  };

  useEffect(() => {

    setForm({
      ...form,
      ...getValuePeriod(form, period, valuePeriod)
    })
  }, [valuePeriod])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      editGoal()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    setDataSorted(data)
  }, [data])

  return (
    <>
      <TableContainer component={Box}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <TableCell style={{ width: '10px' }} /> */}
              {columns &&

                columns.map((head) => {
                  switch (head.field) {
                    case 'start':
                    case 'end':
                      return <TableCell key={head.header} style={{ width: '13%', minWidth: '13%', color: '#98ACC1', fontSize: 10 }} align='left'>{getCaps(head.header, head.field)}</TableCell>
                    case 'objective':
                      return <TableCell key={head.header} style={{ color: '#98ACC1', fontSize: 10 }} align='left'>{getCaps(head.header, head.field)}</TableCell>
                    case 'krs':
                      return <TableCell key={head.header} style={{ color: '#98ACC1', fontSize: 10, width: '4.5%', minWidth: '4.5%' }}>{getCaps(head.header, head.field)}</TableCell>
                    case 'progress':
                      return <TableCell key={head.header} style={{ width: '10%', minWidth: '10%', color: '#98ACC1', fontSize: 10 }}>{getCaps(head.header, head.field)}</TableCell>
                    case 'published':
                      return <TableCell key={head.header} style={{ width: '8%', minWidth: '8%', color: '#98ACC1', fontSize: 10, textAlign: 'center' }}>{getCaps(head.header, head.field)}</TableCell>
                    case 'responsible':
                      return <TableCell key={head.header} style={{ width: '8%', minWidth: '8%', color: '#98ACC1', fontSize: 10, textAlign: 'center' }}>{getCaps(head.header, head.field)}</TableCell>
                    case 'weight':
                      return <TableCell key={head.header} style={{ width: '6%', minWidth: '6%', color: '#98ACC1', fontSize: 10, textAlign: 'center' }}>{getCaps(head.header, head.field)}</TableCell>
                    default:
                      return <TableCell key={head.header} style={{ color: '#98ACC1', fontSize: 10 }}>{getCaps(head.header, head.field)}</TableCell>
                  }
                })}
              {
                tab && tab === 'goals' && (
                  <>
                    <TableCell style={{ width: '0%', minWidth: '0%', textAlign: 'end' }}>
                      <div>
                        <IconButton
                          className={`button-expand ${openAll ? 'rotate planning' : ''}`}
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenAll(!openAll)}
                        >
                          {openAll ? <IconExpand /> : <IconExpand />}
                        </IconButton>
                      </div>
                    </TableCell>
                  </>
                )
              }
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="items">
              {(provided) => (
                <TableBody ref={provided.innerRef}>
                  {dataSorted?.map((row, index) => (
                    <Draggable
                      key={row.id}
                      draggableId={String(row.id)}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          setOpenAll(false)
                        }

                        return (
                          <Row
                            index={index}
                            row={row}
                            striped={striped}
                            hover={hover}
                            tab={tab}
                            columns={columns}
                            responsable={{ id: row?.responsable_id, name: row?.responsable_name }}
                            handleClickMenuGoals={handleClickMenuGoals}
                            toggleRemoveDialog={toggleRemoveDialog}
                            handleArchiveGoal={handleArchiveGoal}
                            openAll={openAll}
                            showCommentsGoalModal={showCommentsGoalModal}
                            innerRef={provided.innerRef}
                            provided={provided}
                            snapshot={snapshot}
                            updateDashboardInfo={updateDashboardInfo}
                            userDetails={userDetails}
                          />
                        )
                      }}

                    </Draggable>

                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>

      {data && <Menu
        className="menu-options"
        id={`menu-options-goals`}
        anchorEl={anchorElOptionsGoals}
        keepMounted
        open={Boolean(anchorElOptionsGoals)}
        onClose={handleCloseMenuOptionsGoals}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <MenuItem onClick={() => handleEditGoal()}>{t('modalDetailTask.edit')}</MenuItem>
        <MenuItem style={{ backgroundColor: '#fff' }} onClick={() => {
          toggleRemoveDialog(currentGoal?.id)
          setAnchorElOptionsGoals(null)
        }}>{t('modalDetailTask.archive_goal')}</MenuItem>
        {
          (currentGoal?.created_by_id === userDetails?.mu_id && currentGoal?.published === false) &&
          <MenuItem aria-describedby={id} onClick={handleClickDeleteGoal}>{t('modalDetailTask.delete')}</MenuItem>
        }
      </Menu>
      }


      {data &&
        <div className="">

          <Modal
            disableEnforceFocus={true}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`modalFormEdit ${classes.modal}`}
            open={openModalEditGoal}
            onClose={closeModalEditGoal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: { backgroundColor: '#205B6F', opacity: 0.8 }
            }}
            sx={{ border: 'none' }}
            style={{ outline: 0 }}
          >
            {/* <Fade in={openModalEditGoal} style={{outline: 0}}> */}
            <Slide direction="down" in={openModalEditGoal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
              <div className={"containerModalFormEditObjective"}>

                <button onClick={closeModalEditGoal} className="close"><IconClose /></button>


                <form onSubmit={handleSubmitGoal}>
                  <main>
                    <header>
                      <h3>
                        {t('goals.modal_goal.edit_obj')}
                        <span>
                          <IconInfo />
                          <div className="tooltip">
                            <h6>{t('goals.modal_goal.h6')}</h6>
                            <p><strong>{t('goals.modal_goal.item1')}</strong> {t('goals.modal_goal.content1')}</p>
                            <p><strong>{t('goals.modal_goal.item2')}</strong> {t('goals.modal_goal.content2')}</p>
                            <p><strong>{t('goals.modal_goal.item3')}</strong> {t('goals.modal_goal.content3')}</p>
                            <p><strong>{t('goals.modal_goal.item4')}</strong> {t('goals.modal_goal.content4')}</p>
                          </div>
                        </span>
                      </h3>
                    </header>
                    <div className="field">
                      <label htmlFor="title">{t('goals.modal_goal.title')}*</label>
                      <input
                        id="title"
                        type="text"
                        name="title"
                        placeholder={t('goals.modal_goal.title_placeholder')}
                        value={form?.title || ''}
                        onChange={handleChange}
                        maxLength={150}
                      />
                      <span className="text-limit">{form.title.length}/150</span>
                      {formError.title &&
                        <div className="error">{formError.title}</div>
                      }
                    </div>

                    <div className="field">
                      <label htmlFor="description">{t('goals.modal_goal.description')}*</label>
                      <textarea
                        name="description"
                        id="description"
                        cols="30"
                        rows="3"
                        placeholder={t('goals.modal_goal.description_placeholder')}
                        value={form.description || ''}
                        onChange={handleChange}
                      />
                      {formError.description &&
                        <div className="error">{formError.description}</div>
                      }
                    </div>

                    <div className="field">
                      <label htmlFor="responsable">{t('goals.modal_goal.responsible')}*</label>
                      <Select
                        id='responsable'
                        defaultValue={() => teammates?.find(item => item.value == form?.responsable)}
                        name="responsable"
                        options={teammates}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={t('goals.modal_goal.select')}
                        onChange={(e) => setForm({ ...form, responsable: e.value })}
                      />
                      {formError.responsable &&
                        <div className="error">{formError.responsable}</div>
                      }
                    </div>

                    <div className="fieldset">

                      <div className="col">
                        <label htmlFor="period">{t('goals.modal_goal.period')}</label>
                        <select name="period" id="period" value={form?.period || ''} onChange={(e) => { handleChangePeriod(e) }}>
                          <option value="custom">{t('goals.modal_goal.custom')}</option>
                          <option value="monthly">{t('goals.modal_goal.monthly')}</option>
                          <option value="bimonthly">{t('goals.modal_goal.bimonthly')}</option>
                          <option value="quarterly">{t('goals.modal_goal.quarterly')}</option>
                          <option value="semester">{t('goals.modal_goal.semester')}</option>
                          <option value="yearly">{t('goals.modal_goal.yearly')}</option>
                        </select>

                        {formError.period &&
                          <div className="error">{formError.period}</div>
                        }
                      </div>

                      <div className="col">
                        <label htmlFor="value_period">{t('goals.modal_goal.value_period')}</label>
                        <select name="value_period" id="value_period" value={form?.value_period} onChange={(e) => { setValuePeriod(e.target.value) }} disabled={period === 'custom'} style={{ backgroundColor: `${period === 'custom' ? '#EEEEEE' : '#FFFFFF'}` }}>
                          {/* <option value="" selected>{t('goals.modal_goal.select')}</option> */}
                          {
                            form?.period &&

                            optionsPeriods
                              .filter(option => { return option.type === form?.period })
                              .map((option, index) => {
                                if (option.type === 'monthly') {
                                  return <option
                                    key={option.value}
                                    value={option.value}
                                    selected={index === 0}>
                                    {t(`months.${option.value}`)}
                                  </option>
                                }
                                return <option
                                  key={option.value}
                                  value={option.value}
                                  selected={index === 0}>
                                  {option.value}
                                </option>
                              }

                              )

                          }
                        </select>

                        {formError.value_period &&
                          <div className="error">{formError.value_period}</div>
                        }
                      </div>
                    </div>


                    <div className="fieldset">

                      <div className="col w-50">
                        <label htmlFor="date_start">{t('goals.modal_goal.start')}</label>
                        <BefectiveDatePicker
                          userDetails={userDetails}
                          date_value={form.date_start}
                          formEdit={form}
                          setFormEdit={setForm}
                          setSubmitted={setSubmitted}
                          field="date_start"
                          minDate={""}
                          maxDate={""}
                          className="goalInput"
                        />
                        {/* <input 
                          className="w-auto" 
                          type="date" 
                          id="date_start" 
                          name="date_start" 
                          value={form.date_start || ''} 
                          onChange={handleChange} 
                        /> */}
                        {(formError.date_start) &&
                          <div className="error">{formError.date_start}</div>
                        }
                      </div>

                      <div className="col w-50">
                        <label htmlFor="date_end">{t('goals.modal_goal.end')}</label>
                        <BefectiveDatePicker
                          userDetails={userDetails}
                          date_value={form.date_end}
                          formEdit={form}
                          setFormEdit={setForm}
                          setSubmitted={setSubmitted}
                          field="date_end"
                          minDate={""}
                          maxDate={""}
                          className="goalInput"
                        />
                        {/* <input className="w-auto" 
                          type="date" 
                          id="date_end" 
                          name="date_end" 
                          value={form.date_end || ''} 
                          onChange={handleChange} 
                        /> */}
                        {(formError.date_end) &&
                          <div className="error">{formError.date_end}</div>
                        }
                      </div>
                    </div>

                    <div className="fieldset">
                      <div className="col w-50">
                        <label htmlFor="weight">{t('goals.modal_goal.weight')}</label>
                        <select name="weight" id="weight" value={form.weight || '1'} onChange={handleChange}>
                          <option value="1" selected>1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {(formError.weight) &&
                          <div className="error">{formError.weight}</div>
                        }
                      </div>
                    </div>

                    <div
                      className="fieldset"
                      style={{ display: form?.created_by_id === userDetails?.mu_id ? 'flex' : 'none', alignItems: 'center', gap: '10px', marginBottom: '0px', }}
                    >
                      <label
                        htmlFor="published"
                        style={{ fontSize: '13px', color: '#24677E', paddingLeft: '2px' }}
                      >
                        {t('goals.modal_goal.published_label')}
                      </label>

                      <div>

                        <span style={{ color: form.published === false ? '#1976d2' : '#1E6B7F', fontWeight: form.published === false ? '600' : '400', textTransform: 'capitalize', fontSize: '12px' }}>{t('settings.company.no')}</span>
                        <Switch
                          id="published"
                          checked={form.published}
                          onChange={() => setForm({
                            ...form,
                            published: !form.published
                          })}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <span style={{ color: form.published === true ? '#1976d2' : '#1E6B7F', fontWeight: form.published === true ? '600' : '400', textTransform: 'capitalize', fontSize: '12px' }}>{t('settings.company.yes')}</span>
                      </div>

                    </div>

                    <footer>
                      <div className="buttonActionsGroup">
                        <button
                          className="btnCancel"
                          type="button"
                          onClick={closeModalEditGoal}
                        >
                          {t('goals.modal_goal.cancel')}
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? <CircularProgress size={15} /> : t('goals.modal_goal.save')}
                        </button>
                      </div>
                    </footer>
                  </main>
                </form>


              </div>
            </Slide>
            {/* </Fade> */}
          </Modal>
        </div>
      }


      {openCommentsGoal && (
        <ModalCommentsGoal
          openCommentsGoal={openCommentsGoal}
          handleCloseCommentsGoal={handleCloseCommentsGoalModal}
          userDetails={userDetails}
          currentGoal={currentGoal}
          teammates={teammates}
        />
      )}

      <Popover
        id={id}
        open={openDeleteGoal}
        anchorEl={anchorElDeleteGoal}
        onClose={handleCloseDeleteGoal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
      >
        <Box
          sx={{
            backgroundColor: '#FFE0DF'
          }}
        >
          <Typography variant="subtitle2" sx={{ p: 2, color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('confirmDialog.title_delete')}</Typography>
          <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseDeleteGoal}
              disabled={deleting}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#fff',
                color: '#FC6662',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.no')}
            </Button>
            <Button
              onClick={handleDeleteGoal}
              disabled={deleting}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#FC6662',
                color: '#fff',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.yes')}
            </Button>
          </Box>
        </Box>
      </Popover>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        handleCloseMenuOptionsGoals={handleCloseMenuOptionsGoals}
      />
    </>
  );
}


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollapsibleTableNew));