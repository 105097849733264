import config from 'config';
import {authHeader} from '../_helpers';

export const historicalService = {
    getHistoricalSummary,
    getHistoricalTeammate,
    getHistoricalHourActivities
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getHistoricalSummary(startDate, endDate, teams, teammates) {

    let queryTeammates = '';

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/historical?startDate=${startDate}&endDate=${endDate}` + queryTeammates, requestOptions).then(handleResponse);
}

function getHistoricalTeammate(day, teammateId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(`${config.apiNodeUrl}/historical/${teammateId}/?day=${day}`, requestOptions).then(handleResponse);
}

function getHistoricalHourActivities(hour, teammateId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(`${config.apiNodeUrl}/historical/${teammateId}/activities/?hour=${hour}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
