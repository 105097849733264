import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import PeriodProductivity from '../_components/productivity/PeriodProductivity'
import PeriodProductivitySkeleton from '../_components/productivity/PeriodProductivitySkeleton'
import { statsActions } from '../_actions';
import Filterbar from "../_components/_shared/Filterbar";
import WorkGoalProductivitySkeleton from "../_components/productivity/WorkGoalProductivitySkeleton";
import WorkGoalProductivity from "../_components/productivity/WorkGoalProductivity";
import ProductivitySkeleton from "../_components/productivity/ProductivitySkeleton";
import Productivity from "../_components/productivity/Productivity";
import ActivitiesProductivity from "../_components/productivity/ActivitiesProductivity";
import ActivitiesProductivitySkeleton from "../_components/productivity/ActivitiesProductivitySkeleton";
import TimeGoalProductivitySkeleton from "../_components/productivity/TimeGoalProductivitySkeleton";
import TimeGoalProductivity from "../_components/productivity/TimeGoalProductivity";
import AlertProductivity from "../_components/productivity/AlertProductivity";
import ToolbarMu from '../_components/_shared/ToolbarMu';
import Toolbar from '../_components/_shared/Toolbar';

function HomePage(props) {
	const { t } = useTranslation('common');
	registerLocale('es', es)
	registerLocale('pt', pt)

	const { statsActions } = props;

	let userLang = navigator.language || navigator.userLanguage;

	if (userLang == 'pt-BR') {
		userLang = 'pt';
	}

	let loading = props.loadingStat;

	const isMountedDate = useRef(false);

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
	const [whoName, setWhoName] = useState('');

	let date = new Date();
	let startDate = new Date(Moment().add(-7, 'd')).toISOString().split('T')[0];
	let endDate = date.toISOString().split('T')[0];
	const maxDate = new Date(Moment());

	function getProductivity(date) {

		let path = window.location.pathname.split("/");

		let organizationId = path[3]
		let hash = path[4]

		statsActions.getProductivity(date);
	}

	useEffect(() => {
		let date = Moment(new Date()).format('YYYY-MM-DD');
		getProductivity(date);

	}, [])

	useEffect(() => {
		if (props.productivity && props.productivity.user) {
			setWhoName(props.productivity.user.name)
		}

	}, [props.productivity])

	useEffect(() => {
		if (isMountedDate.current) {
			if (props.filter.singleDate) {

				getProductivity(props.filter.singleDate)

			}

		} else {
			isMountedDate.current = true;
		}

	}, [props.filter.singleDate]);

	useEffect(() => {
		document.title = 'Befective | ' + t('home.my_productivity');
	});

	return (
		<React.Fragment>
			<section className="mainContent productivity">

				<Toolbar
					breadcrumb={t('breadcrub.home') + " " + whoName}
				/>

				<Filterbar
					defaultStartDate={startDate}
					defaultEndDate={endDate}
					filterWho={'disabled'}
					filterWhen={'single'}
					filterWhat={'disabled'}
					maxDate={maxDate}
					language={userLang}
				/>
				<div className="mainPage productivity">

					<AlertProductivity />

					<div className="column full">
						{
							!minimumTimeElapsed || loading
								? <PeriodProductivitySkeleton />
								: <PeriodProductivity />
						}
					</div>
				</div>
				{/* PAGE STARTS */}
				<div className="mainPage">

					<div className="column half">
						{
							!minimumTimeElapsed || loading
								? <TimeGoalProductivitySkeleton />
								: <TimeGoalProductivity />
						}
						{
							!minimumTimeElapsed || loading
								? <ProductivitySkeleton />
								: <Productivity />
						}
					</div>
					<div className="column half">
						{
							!minimumTimeElapsed || loading
								? <WorkGoalProductivitySkeleton />
								: <WorkGoalProductivity />
						}
						{
							!minimumTimeElapsed || loading
								? <ActivitiesProductivitySkeleton />
								: <ActivitiesProductivity />
						}
					</div>

				</div>
				{/* PAGE ENDS */}
			</section>
		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		loadingStat: state.stats.loading,
		filter: state.filter,
		productivity: state.stats.payload,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
