import { CheckCircle, Close, Subject, Warning } from '@material-ui/icons';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from '../../../_helpers';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';

export default function DrawerReservationDetails({
  currentReservation,
  handleCloseDrawer,
  openDrawer
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={handleCloseDrawer}
      PaperProps={{
        style: {
          width: 'fit-content',
        }
      }}
    >
      <div style={{ position: 'absolute', right: 4, top: 4 }}>
        <IconButton aria-label="close" onClick={handleCloseDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <Box
        display="flex"
        flexDirection="column"
        padding={"42px 20px"}
        minWidth={'380px'}
        gap={2}
      >
        <Box>
          <Typography variant='h6' style={{ color: '#4F7091' }}>{t('spaces.reservation_details')}</Typography>
        </Box>
        {
          currentReservation?.images.length > 0 &&
          <Box>
            <img src={currentReservation?.images[0]?.image_url} alt="Foto sala" width={'380px'} />
          </Box>
        }
        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.rooms').slice(0, -1)}</Typography>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>{currentReservation?.name}</Typography>
        </Box>
        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.columns.description')}</Typography>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>{currentReservation.description || '----'}</Typography>
        </Box>
        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.reservation_date')}</Typography>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>{befectiveFormatDate(currentReservation?.reservation_date, i18n.language)}</Typography>
        </Box>
        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.reservation_periods')}</Typography>
          {
            currentReservation.type === "T"
              ?

              <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>
                {`${currentReservation?.reservation_times.map(t => t.start_time + ' - ' + t.end_time).join(', ')}`}
              </Typography>
              :
              <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>
                {`${currentReservation?.reservation_start_time} - ${currentReservation?.reservation_end_time}`}
              </Typography>
          }
        </Box>
        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.participants')}</Typography>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>
            {currentReservation.participants.length > 0 ? currentReservation?.participants?.map(p => p.name).join(', ') : '----'}
          </Typography>
        </Box>

        <Box>
          <Typography style={{ color: '#4F7091' }} component={'div'} variant='caption' fontWeight={500} fontSize={'10px'} textTransform={'uppercase'}>{t('spaces.requests')}</Typography>
          {
            currentReservation.requests.length > 0
              ?
              <List dense={true} style={{ padding: '0px' }}>
                {
                  currentReservation.requests.map(request => (
                    <>
                      <ListItem key={request.id} style={{ padding: '0px', marginTop:'4px', marginBottom:'4px' }}>
                        <ListItemText
                          primary={
                            <Typography style={{ color: '#4F7091', fontSize: '12px', fontWeight: 500 }} variant='body1'>{request.name}</Typography>
                          }
                          secondary={
                            <Box display={'flex'} flexDirection={'column'}>
                              <Typography style={{ color: '#4F7091' }} variant='caption'>{request.email}</Typography>
                              {
                                request.message.length > 40
                                  ?
                                  <Tooltip title={request.message} classes={{ tooltip: { maxWidth: 500 } }} interactive>
                                    <Typography style={{ color: '#4F7091' }} variant='caption'>{`${t('spaces.message')}: ${request.message.slice(0, 40)} [...]`}</Typography>
                                  </Tooltip>
                                  :
                                  <Typography style={{ color: '#4F7091' }} variant='caption'>{`${t('spaces.message')}: ${request.message}`}</Typography>

                              }
                            </Box>
                          }
                          style={{ marginTop: '0px', marginBottom: '0px' }}
                        />
                        <ListItemSecondaryAction>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            {
                              request.confirmed_at !== null
                                ?
                                <Tooltip title={t('spaces.confirmed')} classes={{ tooltip: { maxWidth: 500 } }} interactive>
                                  <CheckCircle fontSize='small' style={{ color: '#22BEAD', cursor: 'pointer' }} />
                                </Tooltip>
                                :
                                <Tooltip title={t('spaces.pending')} classes={{ tooltip: { maxWidth: 500 } }} interactive>
                                  <Warning fontSize='small' style={{ color: '#FDBE2B', cursor: 'pointer' }} />
                                </Tooltip>
                            }
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </>
                  ))
                }
              </List>
              :
              <Typography style={{ color: '#4F7091' }} component={'div'} variant='body2' fontSize={'14px'}>
                ----
              </Typography>
          }
        </Box>
      </Box>
    </Drawer >
  )
}
