import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableReportsPoints.scss';
import IconExpand from '../../_icons/IconExpand';
import { befectiveFormatDate, history } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import { ArrowRight, House } from 'phosphor-react';
import { Apartment, ArrowDownward, ArrowUpward, Business, Home } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { useState } from 'react';


export default function TableReportsPoints({
  data = [],
  orderColumn = () => { },
  orderedColumn,
  order
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsPoints' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid #F5F6F9' }}>
            <TableCell onClick={() => orderColumn('alias', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} title={t('order_by')}>

              {t("checkin.control_points.table.col_colab")}
              {orderedColumn === 'alias' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}

            </TableCell>
            <TableCell onClick={() => orderColumn('hours_should_work', 'text')} sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} align="center" title={t('order_by')}>
              {t("checkin.control_points.table.col_estimated")}
              {orderedColumn === 'hours_should_work' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }} />
                )
              ) : null}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} align='center' onClick={() => orderColumn('worked_hours', 'text')} title={t('order_by')}>
              {t("checkin.control_points.table.col_worked")}
              {orderedColumn === 'worked_hours' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }}/>
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }}/>
                )
              ) : null}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase', cursor: 'pointer' }} align='center' onClick={() => orderColumn('hoursBalance', 'text')} title={t('order_by')}>
              {t("checkin.control_points.table.col_balance")}
              {orderedColumn === 'hoursBalance' ? (
                order === 'asc' ? (
                  <ArrowDownward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }}/>
                ) : (
                  <ArrowUpward fontSize='inherit' style={{ marginBottom: '-2px', marginLeft: '2px' }}/>
                )
              ) : null}
            </TableCell>
            <TableCell width={'30px'} />
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.mu_id} className={`row-table`} onClick={() => history.push(`/virtual-point/reports/points/${row.mu_id}`)}>
                <TableCell>
                  <Box display={'flex'} alignItems={'center'} gap={'2px'}>
                    <Box>
                      {
                        row.location.length > 0
                          ?
                          (
                            row.location[0].type === 'H' ? <Home fontSize='small' style={{ marginTop: '2px' }} /> : <Apartment fontSize='small' />
                          )
                          :
                          '--'
                      }

                    </Box>
                    <Box>{row.alias}</Box>
                  </Box>
                </TableCell>
                <TableCell align="center">{row.hours_should_work}</TableCell>
                <TableCell align="center">{row.worked_hours}</TableCell>
                <TableCell align="center" style={{color: String(row.hoursBalance) !== '0' ? String(row.hoursBalance).includes('-') ? '#FC6662' : '#22BEAD' : '#4f7091'}}>{row.hoursBalance}</TableCell>
                <TableCell>
                  <IconButton
                    className={`button-options`}
                    aria-label="expand row"
                    size="small"
                  >
                    <ArrowRight size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
