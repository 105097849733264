import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation, initReactI18next} from "react-i18next";
import {bindActionCreators} from 'redux'
import {goalsActions} from '../../../_actions';
import {secondsToHm} from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import IconTeam from '../../_icons/IconTeam';
import IconCompleted from '../../_icons/IconCompleted';
import IconOnline from '../../_icons/IconOnline';
import IconOffline from '../../_icons/IconOffline';
import IconExpand from '../../_icons/IconExpand';
import {isProductive} from "../../../_helpers/calculate";


function TimeGoalFull(props) {
    const {t} = useTranslation('common');
    const { userDetails } = props
    let goals = props.goals.payload || [];
    let stats = props.stats || [];

    const [teamsListHTML, setTeamsList] = useState([])

    const [filterProductive, setFilterProductive] = useState(true);
    const [filterUnproductive, setFilterUnproductive] = useState(true);
    const [TotalProductiveTeams, setProductiveTeams] = useState(0);
    const [TotalUnproductiveTeams, setUnproductiveTeams] = useState(0);
    const [allExpanded, setAllExpanded] = useState(false);

    let TotalExpectedWorkedTime = stats.TotalExpectedWorkedTime || 0;
    let TotalWorkedTime = stats.TotalWorkedTime || 0;
    let PercentExpectedWorkedTime = 0;
    let PercentWorkedTime = 0;

    let TotalWorkedTimeOnline = 0
    let PercentWorkedTimeOnline = 0;

    let TotalWorkedTimeOutdoor = stats.TotalWorkedTimeOutdoor || 0;
    let PercentWorkedTimeOutdoor = 0;

    if (!TotalExpectedWorkedTime || TotalExpectedWorkedTime <= 0) {
        PercentExpectedWorkedTime = 'Ω'
    } else {
        if (stats.TotalWorkedTime && stats.TotalWorkedTime > 0 && TotalExpectedWorkedTime) {
            TotalWorkedTimeOnline = secondsToHm(stats.TotalWorkedTime - TotalWorkedTimeOutdoor)
            PercentWorkedTimeOnline = ((parseInt(stats.TotalWorkedTime - stats.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100).toFixed(2);
        }

        if (TotalWorkedTimeOutdoor && TotalWorkedTimeOutdoor > 0 && TotalExpectedWorkedTime > 0) {
            TotalWorkedTimeOutdoor = secondsToHm(stats.TotalWorkedTimeOutdoor);
            PercentWorkedTimeOutdoor = ((parseInt(stats.TotalWorkedTimeOutdoor) / parseInt(TotalExpectedWorkedTime)) * 100).toFixed(2);
        }

        PercentWorkedTime = (parseInt(TotalWorkedTime) / parseInt(TotalExpectedWorkedTime)) * 100
    }

    const createTeamTeammates = (t0, teamsList, classNameTeam, classNameTeammate) => {
        let percentTotalT0 = "0%";
        let percentWorkedT0 = 0;
        let percentWorkedOutdoorT0 = 0;
        if (!classNameTeam) {
            classNameTeam = "t0 team" + t0.id
        }

        if (!classNameTeammate) {
            classNameTeammate = "t1 tm team" + t0.id
        }

        if (t0.totalWorkedTimeOutdoor > 0 && t0.totalExpectedWorkedTime > 0) {
            percentWorkedOutdoorT0 = ((t0.totalWorkedTimeOutdoor / t0.totalExpectedWorkedTime) * 100).toFixed();
        }

        if (t0.totalWorkedTime > 0 && t0.totalExpectedWorkedTime > 0) {
            percentWorkedT0 = ((t0.totalWorkedTime / t0.totalExpectedWorkedTime) * 100).toFixed();
        }

        let productiveT0 = 'unproductive'
        if (percentTotalT0 >= 100) {
            productiveT0 = "productive"
        }

        teamsList.push(<li key={t0.id} id={"team" + t0.id} className={classNameTeam}>
            <div className="team">{t0.name}</div>
            <div className="completed1">{<div className={"barchart " + productiveT0}>
                <div className="bar" style={{width: percentTotalT0 + '%'}}></div>
            </div>}</div>
            <div className="completed2">{percentTotalT0 || 0} %</div>
            <div className="online">{percentWorkedT0 - percentWorkedOutdoorT0 || 0} %</div>
            <div className="offline">{percentWorkedOutdoorT0 || 0} %</div>
            <div className="actions">
                {(t0.children.length > 0 || t0.machineUsers.length > 0) &&
                    <button className="expand" onClick={() => {
                        expand(t0.id, "t1")
                    }}><IconExpand/></button>
                }
            </div>
        </li>)

        for (let teammate of t0.machineUsers) {

            let percentTotalTm = "0%";
            let percentWorkedTm = 0;
            let percentWorkedOutdoorTm = 0;

            if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                percentWorkedTm = Math.round((teammate.totalExpectedWorkedTime / teammate.totalExpectedWorkedTime) * 100);
            }

            if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                percentWorkedOutdoorTm = Math.round((teammate.totalWorkedTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
            }

            let productiveTm = 'unproductive'
            if (percentTotalTm >= 100) {
                productiveTm = "productive"
            }

            teamsList.push(<li key={"tm" + teammate.machineUserId} id={"teammate" + teammate.machineUserId}
                               className={classNameTeammate}>
                <div className="team"> {teammate.machineUserName}</div>
                <div className="completed1">{<div className={"barchart " + productiveTm}>
                    <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                </div>}</div>
                <div className="completed2">{percentTotalTm || 0} %</div>
                <div className="online">{percentWorkedTm - percentWorkedOutdoorTm || 0} %</div>
                <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                <div className="actions">
                </div>
            </li>)


        }
    }

    function setTeams(teams, teammates) {

        if (!teams) return false

        let teamsList = []

        for (let t0 of teams) {


            let percentTotalT0 = "0%";
            let percentWorkedT0 = 0;
            let percentWorkedOutdoorT0 = 0;

            if (t0.totalWorkedTime > 0 && t0.totalExpectedWorkedTime > 0) {
                percentWorkedT0 = Math.round((t0.totalWorkedTime / t0.totalExpectedWorkedTime) * 100);
            }

            if (t0.totalWorkedTimeOutdoor > 0 && t0.totalExpectedWorkedTime > 0) {
                percentWorkedOutdoorT0 = Math.round((t0.totalWorkedTimeOutdoor / t0.totalExpectedWorkedTime) * 100);
            }

            percentTotalT0 = percentWorkedT0;

            let productiveT0 = 'unproductive'
            if (percentTotalT0 >= 100) {
                productiveT0 = "productive"
            }

            teamsList.push(<li key={t0.id} id={"team" + t0.id} className={"t0 team" + t0.id}>
                <div className="team">{t0.name}</div>
                <div className="completed1">{<div className={"barchart " + productiveT0}>
                    <div className="bar" style={{width: percentTotalT0 + '%'}}></div>
                </div>}</div>
                <div className="completed2">{percentTotalT0 || 0} %</div>
                <div className="online">{percentWorkedT0 - percentWorkedOutdoorT0 || 0} %</div>
                <div className="offline">{percentWorkedOutdoorT0 || 0} %</div>
                <div className="actions">
                    {(t0.children.length > 0 || t0.machineUsers.length > 0) &&
                        <button className="expand" onClick={() => {
                            expand(t0.id, "t1")
                        }}><IconExpand/></button>
                    }
                </div>
            </li>)
            let p0 = t0.id

            for (let teammate of t0.machineUsers) {

                let percentTotalTm = "0%";
                let percentWorkedTm = 0;
                let percentWorkedOutdoorTm = 0;

                if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                    percentWorkedTm = Math.round((teammate.totalWorkedTime / teammate.totalExpectedWorkedTime) * 100);
                }

                if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                    percentWorkedOutdoorTm = Math.round((teammate.totalWorkedTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                }

                percentTotalTm = percentWorkedTm;

                let productiveTm = 'unproductive'
                if (percentTotalTm >= 100) {
                    productiveTm = "productive"
                }

                teamsList.push(<li key={"tm" + teammate.machineUserId} id={"teammate" + teammate.machineUserId}
                                   className={"t1 tm team" + t0.id}>
                    <div className="team"> {teammate.machineUserName}</div>
                    <div className="completed1">{<div className={"barchart " + productiveTm}>
                        <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                    </div>}</div>
                    <div className="completed2">{percentTotalTm || 0} %</div>
                    <div className="online">{percentWorkedTm - percentWorkedOutdoorTm || 0} %</div>
                    <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                    <div className="actions">
                    </div>
                </li>)


            }

            for (let t1 of t0.children) {
                let percentTotalT1 = "0%";
                let percentWorkedT1 = 0;
                let percentWorkedOutdoorT1 = 0;

                if (t1.totalWorkedTime > 0 && t1.totalExpectedWorkedTime > 0) {
                    percentWorkedT1 = Math.round((t1.totalWorkedTime / t1.totalExpectedWorkedTime) * 100);
                }

                if (t1.totalWorkedTimeOutdoor > 0 && t1.totalExpectedWorkedTime > 0) {
                    percentWorkedOutdoorT1 = Math.round((t1.totalWorkedTimeOutdoor / t1.totalExpectedWorkedTime) * 100);
                }

                percentTotalT1 = percentWorkedT1;

                let productiveT1 = 'unproductive'
                if (percentTotalT1 >= 100) {
                    productiveT1 = "productive"
                }

                teamsList.push(<li key={t1.id} id={"team" + t1.id} className={"t1 team" + p0 + " team" + t1.id}>
                    <div className="team">{t1.name}</div>
                    <div className="completed1">{<div className={"barchart " + productiveT1}>
                        <div className="bar" style={{width: percentTotalT1 + '%'}}></div>
                    </div>}</div>
                    <div className="completed2">{percentTotalT1 || 0} %</div>
                    <div className="online">{percentWorkedT1 - percentWorkedOutdoorT1 || 0} %</div>
                    <div className="offline">{percentWorkedOutdoorT1 || 0} %</div>
                    <div className="actions">
                        {(t1.children.length > 0 || t1.machineUsers.length > 0) &&
                            <button className="expand" onClick={() => {
                                expand(t1.id, "t2")
                            }}><IconExpand/></button>
                        }
                    </div>
                </li>)
                let p1 = t1.id

                for (let teammate of t1.machineUsers) {

                    let percentTotalTm = "0%";
                    let percentWorkedTm = 0;
                    let percentWorkedOutdoorTm = 0;

                    if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                        percentWorkedTm = Math.round((teammate.totalWorkedTime / teammate.totalExpectedWorkedTime) * 100);
                    }

                    if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                        percentWorkedOutdoorTm = Math.round((teammate.totalWorkedTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                    }

                    percentTotalTm = percentWorkedTm;

                    let productiveTm = 'unproductive'
                    if (percentTotalTm >= 100) {
                        productiveTm = "productive"
                    }

                    teamsList.push(<li key={"tm" + teammate.machineUserId} id={"teammate" + teammate.machineUserId}
                                       className={"t2 tm team" + p0 + " team" + t1.id}>
                        <div className="team"> {teammate.machineUserName}</div>
                        <div className="completed1">{<div className={"barchart " + productiveTm}>
                            <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                        </div>}</div>
                        <div className="completed2">{percentTotalTm || 0} %</div>
                        <div className="online">{percentWorkedTm - percentWorkedOutdoorTm || 0} %</div>
                        <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                        <div className="actions">
                        </div>
                    </li>)


                }

                for (let t2 of t1.children) {
                    let percentTotalT2 = "0%";
                    let percentWorkedT2 = 0;
                    let percentWorkedOutdoorT2 = 0;

                    if (t2.totalWorkedTime > 0 && t2.totalExpectedWorkedTime > 0) {
                        percentWorkedT2 = Math.round((t2.totalWorkedTime / t2.totalExpectedWorkedTime) * 100);
                    }

                    if (t2.totalWorkedTimeOutdoor > 0 && t2.totalExpectedWorkedTime > 0) {
                        percentWorkedOutdoorT2 = Math.round((t2.totalWorkedTimeOutdoor / t2.totalExpectedWorkedTime) * 100);
                    }

                    percentTotalT2 = percentWorkedT2;

                    let productiveT2 = 'unproductive'
                    if (percentTotalT2 >= 100) {
                        productiveT2 = "productive"
                    }

                    teamsList.push(<li key={t2.id} id={"team" + t2.id}
                                       className={"t2 team" + p0 + " team" + p1 + " team" + t2.id}>
                        <div className="team">{t2.name}</div>
                        <div className="completed1">{<div className={"barchart " + productiveT2}>
                            <div className="bar" style={{width: percentTotalT2 + '%'}}></div>
                        </div>}</div>
                        <div className="completed2">{percentTotalT2 || 0} %</div>
                        <div className="online">{percentWorkedT2 - percentWorkedOutdoorT2 || 0} %</div>
                        <div className="offline">{percentWorkedOutdoorT2 || 0} %</div>
                        <div className="actions">
                            {(t2.children.length > 0 || t2.machineUsers.length > 0) &&
                                <button className="expand" onClick={() => {
                                    expand(t2.id, "t3")
                                }}><IconExpand/></button>
                            }
                        </div>
                    </li>)
                    let p2 = t2.id

                    for (let teammate of t2.machineUsers) {

                        let percentTotalTm = "0%";
                        let percentWorkedTm = 0;
                        let percentWorkedOutdoorTm = 0;

                        if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                            percentWorkedTm = Math.round((teammate.totalWorkedTime / teammate.totalExpectedWorkedTime) * 100);
                        }

                        if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                            percentWorkedOutdoorTm = Math.round((teammate.totalWorkedTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                        }

                        percentTotalTm = percentWorkedTm;

                        let productiveTm = 'unproductive'
                        if (percentTotalTm >= 100) {
                            productiveTm = "productive"
                        }

                        teamsList.push(<li key={"tm" + teammate.machineUserId} id={"teammate" + teammate.machineUserId}
                                           className={"t3 tm team" + p0 + " team" + p1 + " team" + t2.id}>
                            <div className="team"> {teammate.machineUserName}</div>
                            <div className="completed1">{<div className={"barchart " + productiveTm}>
                                <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                            </div>}</div>
                            <div className="completed2">{percentTotalTm || 0} %</div>
                            <div className="online">{percentWorkedTm - percentWorkedOutdoorTm || 0} %</div>
                            <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                            <div className="actions">
                            </div>
                        </li>)

                    }

                    for (let t3 of t2.children) {
                        let percentTotalT3 = "0%";
                        let percentWorkedT3 = 0;
                        let percentWorkedOutdoorT3 = 0;

                        if (t3.totalWorkedTime > 0 && t3.totalExpectedWorkedTime > 0) {
                            percentWorkedT3 = Math.round((t3.totalWorkedTime / t3.totalExpectedWorkedTime) * 100);
                        }

                        if (t3.totalWorkedTimeOutdoor > 0 && t3.totalExpectedWorkedTime > 0) {
                            percentWorkedOutdoorT3 = Math.round((t3.totalWorkedTimeOutdoor / t3.totalExpectedWorkedTime) * 100);
                        }

                        percentTotalT3 = percentWorkedT3;

                        let productiveT3 = 'unproductive'
                        if (percentTotalT3 >= 100) {
                            productiveT3 = "productive"
                        }

                        teamsList.push(<li key={t3.id} id={"team" + t3.id}
                                           className={"t3 team" + p0 + " team" + p1 + " team" + p2 + " team" + t3.id}>
                            <div className="team">{t3.name}</div>
                            <div className="completed1">{<div className={"barchart " + productiveT3}>
                                <div className="bar" style={{width: percentTotalT3 + '%'}}></div>
                            </div>}</div>
                            <div className="completed2">{percentTotalT3 || 0} %</div>
                            <div className="online">{percentWorkedT3 - percentWorkedOutdoorT3 || 0} %</div>
                            <div className="offline">{percentWorkedOutdoorT3 || 0} %</div>
                            <div className="actions">
                                {(t3.children.length > 0 || t3.machineUsers.length > 0) &&
                                    <button className="expand" onClick={() => {
                                        expand(t3.id, "t4")
                                    }}><IconExpand/></button>
                                }
                            </div>
                        </li>)
                        let p3 = t3.id

                        for (let teammate of t3.machineUsers) {

                            let percentTotalTm = "0%";
                            let percentWorkedTm = 0;
                            let percentWorkedOutdoorTm = 0;

                            if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                                percentWorkedTm = Math.round((teammate.totalWorkedTime / teammate.totalExpectedWorkedTime) * 100);
                            }

                            if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                                percentWorkedOutdoorTm = Math.round((teammate.totalWorkedTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                            }

                            percentTotalTm = percentWorkedTm;

                            let productiveTm = 'unproductive'
                            if (percentTotalTm >= 100) {
                                productiveTm = "productive"
                            }

                            teamsList.push(<li key={"tm" + teammate.machineUserId}
                                               id={"teammate" + teammate.machineUserId}
                                               className={"t4 tm team" + p0 + " team" + p1 + " team" + p2 + " team" + t3.id}>
                                <div className="team"> {teammate.machineUserName}</div>
                                <div className="completed1">{<div className={"barchart " + productiveTm}>
                                    <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                                </div>}</div>
                                <div className="completed2">{percentTotalTm || 0} %</div>
                                <div className="online">{percentWorkedTm - percentWorkedOutdoorTm || 0} %</div>
                                <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                                <div className="actions">
                                </div>
                            </li>)

                        }

                        for (let t4 of t3.children) {
                            let percentTotalT4 = "0%";
                            let percentWorkedT4 = 0;
                            let percentWorkedOutdoorT4 = 0;

                            if (t4.totalWorkedTime > 0 && t4.totalExpectedWorkedTime > 0) {
                                percentWorkedT4 = Math.round((t4.totalProductiveTime / t4.totalExpectedWorkedTime) * 100);
                            }

                            if (t4.totalWorkedTimeOutdoor > 0 && t4.totalExpectedWorkedTime > 0) {
                                percentWorkedOutdoorT4 = Math.round((t4.totalProductiveTimeOutdoor / t4.totalExpectedWorkedTime) * 100);
                            }

                            percentTotalT4 = percentWorkedT4 + percentWorkedOutdoorT4;

                            let productiveT4 = 'unproductive'
                            if (percentTotalT4 >= 100) {
                                productiveT4 = "productive"
                            }

                            teamsList.push(<li key={t4.id} id={"team" + t4.id}
                                               className={"t4 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + t4.id}>
                                <div className="team">{t4.name}</div>
                                <div className="completed1">{<div className={"barchart " + productiveT4}>
                                    <div className="bar" style={{width: percentTotalT4 + '%'}}></div>
                                </div>}</div>
                                <div className="completed2">{percentTotalT4 || 0} %</div>
                                <div className="online">{percentWorkedT4 || 0} %</div>
                                <div className="offline">{percentWorkedOutdoorT4 || 0} %</div>
                                <div className="actions">
                                    {(t4.children.length > 0 || t4.machineUsers.length > 0) &&
                                        <button className="expand" onClick={() => {
                                            expand(t4.id, "t5")
                                        }}><IconExpand/></button>
                                    }
                                </div>
                            </li>)
                            let p4 = t4.id

                            for (let teammate of t4.machineUsers) {

                                let percentTotalTm = "0%";
                                let percentWorkedTm = 0;
                                let percentWorkedOutdoorTm = 0;

                                if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                                    percentWorkedTm = Math.round((teammate.totalProductiveTime / teammate.totalExpectedWorkedTime) * 100);
                                }

                                if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                                    percentWorkedOutdoorTm = Math.round((teammate.totalProductiveTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                                }

                                percentTotalTm = percentWorkedTm + percentWorkedOutdoorTm;

                                let productiveTm = 'unproductive'
                                if (percentTotalTm >= 100) {
                                    productiveTm = "productive"
                                }

                                teamsList.push(<li key={"tm" + teammate.machineUserId}
                                                   id={"teammate" + teammate.machineUserId}
                                                   className={"t5 tm team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + t4.id}>
                                    <div className="team"> {teammate.machineUserName}</div>
                                    <div className="completed1">{<div className={"barchart " + productiveTm}>
                                        <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                                    </div>}</div>
                                    <div className="completed2">{percentTotalTm || 0} %</div>
                                    <div className="online">{percentWorkedTm || 0} %</div>
                                    <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                                    <div className="actions">
                                    </div>
                                </li>)

                            }

                            for (let t5 of t4.children) {
                                let percentTotalT5 = "0%";
                                let percentWorkedT5 = 0;
                                let percentWorkedOutdoorT5 = 0;

                                if (t5.totalWorkedTime > 0 && t5.totalExpectedWorkedTime > 0) {
                                    percentWorkedT5 = Math.round((t5.totalProductiveTime / t5.totalExpectedWorkedTime) * 100);
                                }

                                if (t5.totalWorkedTimeOutdoor > 0 && t5.totalExpectedWorkedTime > 0) {
                                    percentWorkedOutdoorT5 = Math.round((t5.totalProductiveTimeOutdoor / t5.totalExpectedWorkedTime) * 100);
                                }

                                percentTotalT5 = percentWorkedT5 + percentWorkedOutdoorT5;

                                let productiveT5 = 'unproductive'
                                if (percentTotalT5 >= 100) {
                                    productiveT5 = "productive"
                                }

                                teamsList.push(<li key={t5.id} id={"team" + t5.id}
                                                   className={"t5 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + t5.id}>
                                    <div className="team">{t5.name}</div>
                                    <div className="completed1">{<div className={"barchart " + productiveT5}>
                                        <div className="bar" style={{width: percentTotalT5 + '%'}}></div>
                                    </div>}</div>
                                    <div className="completed2">{percentTotalT5 || 0} %</div>
                                    <div className="online">{percentWorkedT5 || 0} %</div>
                                    <div className="offline">{percentWorkedOutdoorT5 || 0} %</div>
                                    <div className="actions">
                                        {(t5.children.length > 0 || t5.machineUsers.length > 0) &&
                                            <button className="expand" onClick={() => {
                                                expand(t5.id, "t6")
                                            }}><IconExpand/></button>
                                        }
                                    </div>
                                </li>)
                                let p5 = t5.id

                                for (let teammate of t5.machineUsers) {

                                    let percentTotalTm = "0%";
                                    let percentWorkedTm = 0;
                                    let percentWorkedOutdoorTm = 0;

                                    if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                                        percentWorkedTm = Math.round((teammate.totalProductiveTime / teammate.totalExpectedWorkedTime) * 100);
                                    }

                                    if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                                        percentWorkedOutdoorTm = Math.round((teammate.totalProductiveTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                                    }

                                    percentTotalTm = percentWorkedTm + percentWorkedOutdoorTm;

                                    let productiveTm = 'unproductive'
                                    if (percentTotalTm >= 100) {
                                        productiveTm = "productive"
                                    }

                                    teamsList.push(<li key={"tm" + teammate.machineUserId}
                                                       id={"teammate" + teammate.machineUserId}
                                                       className={"t6 tm team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + t5.id}>
                                        <div className="team"> {teammate.machineUserName}</div>
                                        <div className="completed1">{<div className={"barchart " + productiveTm}>
                                            <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                                        </div>}</div>
                                        <div className="completed2">{percentTotalTm || 0} %</div>
                                        <div className="online">{percentWorkedTm || 0} %</div>
                                        <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                                        <div className="actions">
                                        </div>
                                    </li>)

                                }

                                for (let t6 of t5.children) {
                                    let percentTotalT6 = "0%";
                                    let percentWorkedT6 = 0;
                                    let percentWorkedOutdoorT6 = 0;

                                    if (t6.totalWorkedTime > 0 && t6.totalExpectedWorkedTime > 0) {
                                        percentWorkedT6 = Math.round((t6.totalProductiveTime / t6.totalExpectedWorkedTime) * 100);
                                    }

                                    if (t6.totalWorkedTimeOutdoor > 0 && t6.totalExpectedWorkedTime > 0) {
                                        percentWorkedOutdoorT6 = Math.round((t6.totalProductiveTimeOutdoor / t6.totalExpectedWorkedTime) * 100);
                                    }

                                    percentTotalT6 = percentWorkedT6 + percentWorkedOutdoorT6;

                                    let productiveT6 = 'unproductive'
                                    if (percentTotalT6 >= 100) {
                                        productiveT6 = "productive"
                                    }

                                    teamsList.push(<li key={t6.id} id={"team" + t6.id}
                                                       className={"t6 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + t6.id}>
                                        <div className="team">{t6.name}</div>
                                        <div className="completed1">{<div className={"barchart " + productiveT6}>
                                            <div className="bar" style={{width: percentTotalT6 + '%'}}></div>
                                        </div>}</div>
                                        <div className="completed2">{percentTotalT6 || 0} %</div>
                                        <div className="online">{percentWorkedT6 || 0} %</div>
                                        <div className="offline">{percentWorkedOutdoorT6 || 0} %</div>
                                        <div className="actions">
                                            {(t6.children.length > 0 || t6.machineUsers.length > 0) &&
                                                <button className="expand" onClick={() => {
                                                    expand(t6.id, "t7")
                                                }}><IconExpand/></button>
                                            }
                                        </div>
                                    </li>)
                                    let p6 = t6.id

                                    for (let teammate of t6.machineUsers) {

                                        let percentTotalTm = "0%";
                                        let percentWorkedTm = 0;
                                        let percentWorkedOutdoorTm = 0;

                                        if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                                            percentWorkedTm = Math.round((teammate.totalProductiveTime / teammate.totalExpectedWorkedTime) * 100);
                                        }

                                        if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                                            percentWorkedOutdoorTm = Math.round((teammate.totalProductiveTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                                        }

                                        percentTotalTm = percentWorkedTm + percentWorkedOutdoorTm;

                                        let productiveTm = 'unproductive'
                                        if (percentTotalTm >= 100) {
                                            productiveTm = "productive"
                                        }

                                        teamsList.push(<li key={"tm" + teammate.machineUserId}
                                                           id={"teammate" + teammate.machineUserId}
                                                           className={"t7 tm team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + t6.id}>
                                            <div className="team"> {teammate.machineUserName}</div>
                                            <div className="completed1">{<div className={"barchart " + productiveTm}>
                                                <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                                            </div>}</div>
                                            <div className="completed2">{percentTotalTm || 0} %</div>
                                            <div className="online">{percentWorkedTm || 0} %</div>
                                            <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                                            <div className="actions">
                                            </div>
                                        </li>)

                                    }

                                    for (let t7 of t6.children) {
                                        let percentTotalT7 = "0%";
                                        let percentWorkedT7 = 0;
                                        let percentWorkedOutdoorT7 = 0;

                                        if (t7.totalWorkedTime > 0 && t7.totalExpectedWorkedTime > 0) {
                                            percentWorkedT7 = Math.round((t7.totalProductiveTime / t7.totalExpectedWorkedTime) * 100);
                                        }

                                        if (t7.totalWorkedTimeOutdoor > 0 && t7.totalExpectedWorkedTime > 0) {
                                            percentWorkedOutdoorT7 = Math.round((t7.totalProductiveTimeOutdoor / t7.totalExpectedWorkedTime) * 100);
                                        }

                                        percentTotalT7 = percentWorkedT7 + percentWorkedOutdoorT7;

                                        let productiveT7 = 'unproductive'
                                        if (percentTotalT7 >= 100) {
                                            productiveT7 = "productive"
                                        }

                                        teamsList.push(<li key={t7.id} id={"team" + t7.id}
                                                           className={"t7 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + p6 + " team" + t7.id}>
                                            <div className="team">{t7.name}</div>
                                            <div className="completed1">{<div className={"barchart " + productiveT7}>
                                                <div className="bar" style={{width: percentTotalT7 + '%'}}></div>
                                            </div>}</div>
                                            <div className="completed2">{percentTotalT7 || 0} %</div>
                                            <div className="online">{percentWorkedT7 || 0} %</div>
                                            <div className="offline">{percentWorkedOutdoorT7 || 0} %</div>
                                            <div className="actions">
                                                {(t7.children.length > 0 || t7.machineUsers.length > 0) &&
                                                    <button className="expand" onClick={() => {
                                                        expand(t7.id, "t8")
                                                    }}><IconExpand/></button>
                                                }
                                            </div>
                                        </li>)
                                        let p7 = t7.id

                                        for (let teammate of t7.machineUsers) {

                                            let percentTotalTm = "0%";
                                            let percentWorkedTm = 0;
                                            let percentWorkedOutdoorTm = 0;

                                            if (teammate.totalWorkedTime > 0 && teammate.totalExpectedWorkedTime > 0) {
                                                percentWorkedTm = Math.round((teammate.totalProductiveTime / teammate.totalExpectedWorkedTime) * 100);
                                            }

                                            if (teammate.totalWorkedTimeOutdoor > 0 && teammate.totalExpectedWorkedTime > 0) {
                                                percentWorkedOutdoorTm = Math.round((teammate.totalProductiveTimeOutdoor / teammate.totalExpectedWorkedTime) * 100);
                                            }

                                            percentTotalTm = percentWorkedTm + percentWorkedOutdoorTm;

                                            let productiveTm = 'unproductive'
                                            if (percentTotalTm >= 100) {
                                                productiveTm = "productive"
                                            }

                                            teamsList.push(<li key={"tm" + teammate.machineUserId}
                                                               id={"teammate" + teammate.machineUserId}
                                                               className={"t8 tm team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + p6 + " team" + t7.id}>
                                                <div className="team"> {teammate.machineUserName}</div>
                                                <div className="completed1">{<div
                                                    className={"barchart " + productiveTm}>
                                                    <div className="bar" style={{width: percentTotalTm + '%'}}></div>
                                                </div>}</div>
                                                <div className="completed2">{percentTotalTm || 0} %</div>
                                                <div className="online">{percentWorkedTm || 0} %</div>
                                                <div className="offline">{percentWorkedOutdoorTm || 0} %</div>
                                                <div className="actions">
                                                </div>
                                            </li>)

                                        }
                                    }

                                }

                            }

                        }

                    }


                }

            }

        }

        setTeamsList(teamsList)

    }


    function expand(id, level) {

        let mainEl = document.getElementById("team" + id)
        let cEl = mainEl.querySelector('.expand')

        if (cEl.classList.contains("active")) {

            cEl.classList.remove("active")
            cEl.parentNode.parentNode.classList.remove("active")

            let elems = document.querySelectorAll(".team" + id)

            if (elems.length > 0) {
                [].forEach.call(elems, function (el) {
                    el.classList.remove("expanded");
                    el.classList.remove("active");

                    let ac = el.querySelectorAll('.active');

                    if (ac[0]) {

                        ac[0].classList.remove("active");
                    }

                    el.classList.remove("last");
                });
            }
        } else {

            cEl.classList.add("active")
            cEl.parentNode.parentNode.classList.add("active")

            let elems = document.querySelectorAll(".team" + id + "." + level)

            if (elems.length > 0) {
                let n = 0;
                [].forEach.call(elems, function (el) {
                    n++;
                    el.classList.add("expanded");
                    if (elems.length == n) {
                        el.classList.add("last");
                    }
                });
            }
        }

    }

    function expandAll() {
        let elems = document.querySelectorAll(".t0, .t1, .t2, .t3, .t4, .t5, .t6, .t7")


        if (elems.length > 0) {
            [].forEach.call(elems, function (el) {


                if (allExpanded) {
                    el.classList.remove("expanded");
                    setAllExpanded(false)
                } else {
                    el.classList.add("expanded");
                    setAllExpanded(true)
                }

            });
        }
    }


    useEffect(() => {

        let totalProductiveTeams = 0
        let totalTeams = 0
        if (goals) {
            for (let t0 of goals) {
                totalTeams++
                if (isProductive(t0)) {
                    totalProductiveTeams++
                }
                if (t0.children.length > 0) {
                    for (let t1 of t0.children) {
                        totalTeams++
                        if (isProductive(t1)) {
                            totalProductiveTeams++
                        }
                        if (t1.children.length > 0) {
                            for (let t2 of t1.children) {
                                totalTeams++
                                if (isProductive(t2)) {
                                    totalProductiveTeams++
                                }
                                if (t2.children.length > 0) {
                                    totalTeams++
                                    for (let t3 of t2.children) {
                                        if (isProductive(t3)) {
                                            totalProductiveTeams++
                                        }
                                        if (t3.children.length > 0) {
                                            totalTeams++
                                            for (let t4 of t3.children) {
                                                if (isProductive(t4)) {
                                                    totalProductiveTeams++
                                                }
                                                if (t4.children.length > 0) {
                                                    for (let t5 of t4.children) {
                                                        totalTeams++
                                                        if (isProductive(t5)) {
                                                            totalProductiveTeams++
                                                        }
                                                        if (t5.children.length > 0) {
                                                            for (let t6 of t5.children) {
                                                                totalTeams++
                                                                if (isProductive(t6)) {
                                                                    totalProductiveTeams++
                                                                }
                                                                if (t6.children.length > 0) {
                                                                    for (let t7 of t6.children) {
                                                                        totalTeams++
                                                                        if (isProductive(t7)) {
                                                                            totalProductiveTeams++
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        setProductiveTeams(totalProductiveTeams)
        setUnproductiveTeams(totalTeams - totalProductiveTeams)


    }, [goals])

    useEffect(() => {

        if (filterProductive) {
            var elems = document.querySelectorAll(".productive");

            [].forEach.call(elems, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            })
        } else {

            var elems = document.querySelectorAll(".productive");

            [].forEach.call(elems, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            })
        }

        if (filterUnproductive) {
            var elems = document.querySelectorAll(".unproductive");

            [].forEach.call(elems, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            })

        } else {

            var elems = document.querySelectorAll(".unproductive");

            [].forEach.call(elems, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            })
        }

    }, [filterProductive, filterUnproductive])


    useEffect(() => {


        if (props.goals.payload) {
            setTeams(props.goals.payload)
        }


    }, [props.goals.loading])

    return (
        <div className="box goal noBg">
            <div className="pageHeader">
                {userDetails.allowed_total_time === true && (
                <h3>{t('time_goal.time_goal')}: <b> {secondsToHm(TotalWorkedTime)}</b><span><IconInfo/><div
                    className="tooltip">{/*<IconInfo/>*/}{t('time_goal.tooltip')}</div></span></h3>
                )}
                <div className="internalFilters">
                    <div className={filterProductive ? 'filter canFilter time active' : 'filter canFilter time'}
                         onClick={() => {
                             setFilterProductive(!filterProductive)
                         }}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalProductiveTeams}</div>
                        <div className="name">{t('time_goal.goal_reached')}</div>
                    </div>
                    <div className={filterUnproductive ? 'filter canFilter time active' : 'filter canFilter time'}
                         onClick={() => {
                             setFilterUnproductive(!filterUnproductive)
                         }}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam/>
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalUnproductiveTeams}</div>
                        <div className="name">{t('time_goal.goal_not_reached')}</div>
                    </div>

                    <div className="filter active time disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCompleted/>
                            </div>
                            {userDetails.allowed_total_time === true && <div className="number">{secondsToHm(TotalWorkedTime)}</div> }
                        </div>
                        <div className="highlight">{PercentWorkedTime.toFixed(2)}%</div>
                        <div className="name">{t('time_goal.completed')}</div>
                    </div>
                    <div className="filter active time  disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOnline/>
                            </div>
                            {userDetails.allowed_total_time === true && <div className="number">{TotalWorkedTimeOnline}</div>}
                        </div>
                        <div className="highlight">{PercentWorkedTimeOnline}%</div>
                        <div className="name">{t('time_goal.online_activities')}</div>
                    </div>
                    <div className="filter active time disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOffline/>
                            </div>
                            {userDetails.allowed_total_time === true && <div className="number">{TotalWorkedTimeOutdoor}</div> }
                        </div>
                        <div className="highlight">{PercentWorkedTimeOutdoor}%</div>
                        <div className="name">{t('time_goal.offline_activities')}</div>
                    </div>
                </div>
            </div>
            <div className="teamsListTable">
                <ul>
                    <li className="header">
                        <div className="team">{t('time_goal.team')}</div>
                        <div className="completed1">{t('time_goal.time_completed')}</div>
                        <div className="completed2">{t('time_goal.completed')}</div>
                        <div className="online">{t('time_goal.online')}</div>
                        <div className="offline">{t('time_goal.offline')}</div>
                        <div className="actions">
                            <button className={allExpanded ? "expand active" : "expand"} onClick={() => {
                                expandAll()
                            }}><IconExpand/></button>
                        </div>
                    </li>
                    {teamsListHTML}
                </ul>
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        goalsActions: bindActionCreators(goalsActions, dispatch)

    }
}

function mapStateToProps(state) {

    return {
        goals: state.goals,
        stats: state.stats.payload,
        filter: state.filter,
		userDetails : state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TimeGoalFull)
