import React, { useEffect, useState } from 'react'
import { IconButton, Menu } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment'

import './styles.scss'

export function InputDate({ 
  anchorEl,
  handleCloseMenu,
  value,
  formEdit,
  setFormEdit,
  isUpdating,
  setSubmitted,
  field,
  date_end,
  date_start,
}) {
  const [newValue, setNewValue] = useState(value)

  const handleUpdateKeyEnter = (e) => {
    if (e.key === 'Enter') {
      setSubmitted(true)
      setFormEdit({
        ...formEdit,
        field: newValue
      })
      handleCloseMenu()
    }
  }

  function onChangeValue(e) {
    if(e.target.value === '') {
      setNewValue('')
    }else {
      setNewValue(e.target.value)
    }

  }

  function handleCloseMenuEditValue() {
    setNewValue('')
    handleCloseMenu()
  }

  function handleUpdateValue() {
    setSubmitted(true)
    setFormEdit({
      ...formEdit,
      [field]: newValue
    })
    handleCloseMenu()
  }

  useEffect(() => {
    if(value !== null) {
      setNewValue(moment(value).format('YYYY-MM-DD'))
    }
  }, [value])
  
  return (
    <Menu
      className="menu-task-on-table-to-date"
      id={`menu-options-value`}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenuEditValue}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ horizontal: "center" }}
      style={{ padding: 10 }}
    >
      <div className={`box-input-time ${isUpdating && 'disabled-div'}`}>
        <input 
          type="date" 
          className='date-input-menu' 
          value={newValue} 
          onChange={(e) => onChangeValue(e)} 
          onKeyDown={(e) => handleUpdateKeyEnter(e)}
          min={date_start}
          max={date_end}
        />
       
        <IconButton aria-label="save-value" size="small" onClick={() => handleUpdateValue()} >
          <DoneIcon />
        </IconButton>
        <IconButton aria-label="close" size="small" onClick={handleCloseMenuEditValue}>
          <CloseIcon />
        </IconButton>
      </div>
    </Menu>
  )
}


