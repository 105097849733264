import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import IconInfo from "../../_icons/IconInfo";
import IconClose from "../../_icons/IconClose";

import { api } from "../../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../../_actions";
import { authHeader, enumerateYearsBetweenDates, getOptionsPeriods, getValuePeriod } from "../../../_helpers";
import { Switch } from '@mui/material';
import "./styles.scss"
import moment from "moment";
import { BefectiveDatePicker } from "../BefectiveDatePicker";

const useStyles = makeStyles((theme) => ({
	modal: {
		"&:focus": {
			outline: 'none'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
}));

function GoalsModalForm({
	openModalNewGoal,
	setOpenModalNewGoal,
	userDetails,
	teammates,
	alertActions,
	updateDashboardInfo
}) {
	const { t } = useTranslation('common');
	const classes = useStyles();
	const [period, setPeriod] = useState('custom');
	const [valuePeriod, setValuePeriod] = useState('');
	const [responsible, setResponsible] = useState('')
	const [submitted, setSubmitted] = useState(false);
	const [isCheckType, setIsCheckType] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [categories, setCategories] = useState([])

	const [form, setForm] = useState({
		title: '',
		description: '',
		type: '2',
		responsable: '',
		period: '',
		value_period: '',
		year_period: moment.utc().format('YYYY'),
		date_start: '',
		date_end: '',
		weight: 1,
		published: true
	});
	const [formError, setFormError] = useState({
		title: '',
		description: '',
		type: '',
		responsable: '',
		period: '',
		value_period: '',
		year_period: '',
		date_start: '',
		date_end: '',
		weight: '',
		published: ''
	});

	const optionsPeriods = getOptionsPeriods()

	const token = authHeader()

	const teammatesOptions = teammates

	const handleChange = e => {

		if (e.target.name === 'year_period') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				date_start: moment.utc(e.target.value + '-01-01').format('YYYY-MM-DD'),
				date_end: moment.utc(String(parseInt(e.target.value) + 1) + '-01-01').subtract(1, 'days').format('YYYY-MM-DD')
			})
		}
		setForm({
			...form,
			[e.target.name]: e.target.value,
		})

		if (e.target.name === 'type' && e.target.checked) {
			setIsCheckType(true)
		}
	}

	const handleFocus = e => {

		if (e.target.name === 'category' || e.target.name === 'responsable') {
			setFormError({
				...formError,
				[e.target.name]: ''
			})
		}
	}

	function closeModalFormGoals() {
		setOpenModalNewGoal(false)
		setForm({
			title: '',
			description: '',
			type: '2',
			responsable: '',
			period: '',
			value_period: '',
			year_period: moment.utc().format('YYYY'),
			date_start: '',
			date_end: '',
			weight: 1,
			published: true
		})
		setFormError({
			title: '',
			description: '',
			type: '',
			responsable: '',
			period: '',
			value_period: '',
			year_period: '',
			date_start: '',
			date_end: '',
			weight: '',
			published: ''
		})
		setPeriod('')
		setResponsible('')
	}

	const validateForm = (form) => {
		const errors = {};

		if (!form.title) {
			errors.title = t('goals.errors.required')
		}
		// else if (form.title.length < 10) {
		// 	errors.title = t('goals.errors.title_goal')
		// }

		if (!form.description) {
			errors.description = t('goals.errors.required')
		}
		// else if (form.description.length < 10) {
		// 	errors.description = t('goals.errors.description_goal')
		// }

		// if (!form.type) {
		// 	errors.type = t('goals.errors.required')
		// }

		if (!form.responsable) {
			errors.responsable = t('goals.errors.required')
		}

		// if (!period) {
		// 	errors.period = t('goals.errors.required')
		// }

		// if (!valuePeriod) {
		// 	errors.value_period = t('goals.errors.required')
		// }

		if (!form.year_period) {
			errors.year_period = t('goals.errors.required')
		}

		if (!form.date_start) {
			errors.date_start = t('goals.errors.required')
		} else if (form.date_start >= form.date_end) {
			errors.date_start = t('goals.errors.invalid_field')
		}

		if (!form.date_end) {
			errors.date_end = t('goals.errors.required')
		} else if (form.date_end <= form.date_start) {
			errors.date_end = t('goals.errors.invalid_field')
		}
		return errors
	}

	const handleSubmitGoal = (e) => {
		e.preventDefault()
		setFormError(validateForm(form))
		setSubmitted(true)
	}

	const createGoal = async () => {
		try {
			setIsSubmitting(true)
			await api.post(`/goals?organization_id=${userDetails.organizationId}`, {
				action_id: 1,
				datetime: new Date(),
				title: form.title,
				description: form.description,
				type_id: Number(form.type),
				accountable_id: form.responsable,
				created_by: userDetails.id,
				updated_by: userDetails.id,
				measurement: form?.period,
				date_start: form?.date_start,
				date_end: form?.date_end,
				value_period: form?.value_period,
				weight: form?.weight,
				published: form.published
			}, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			updateDashboardInfo()
			alertActions.success(t('goals.success.create_goal'))
			closeModalFormGoals()
			setIsSubmitting(false)
		} catch (error) {
			setIsSubmitting(false)
			console.log(error.response)
			//alertActions.error(t('goals.errors.create_goal'))
		}
	}

	const getCategories = async () => {
		const token = authHeader()
		const response = await api.get('/activities/categories', {
			headers: {
				'Authorization': token.Authorization
			}
		})

		const categoriesFiltered = response.data?.filter(item => item.is_parent === true)

		setCategories(categoriesFiltered)
	}

	useEffect(() => {
		if (responsible) {
			handleChange({ target: { name: 'responsable', value: responsible } })
		}
	}, [responsible])

	useEffect(() => {
		if (period === 'custom') {
			setForm({
				...form,
				period: period,
				value_period: 'custom',
				date_start: '',
				date_end: ''
			})
		}
		setValuePeriod(optionsPeriods?.filter((item) => item.type === period)[0]?.value)
	}, [period])

	useEffect(() => {
		setForm({
			...form,
			...getValuePeriod(form, period, valuePeriod)
		})
	}, [valuePeriod, form.year_period])

	useEffect(() => {

		if (Object.keys(formError).length === 0 && submitted) {
			createGoal()
		} else {
			setSubmitted(false)
		}

	}, [formError, submitted])

	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={`modalForm ${classes.modal}`}
				open={openModalNewGoal}
				onClose={closeModalFormGoals}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					style: { backgroundColor: '#205B6F', opacity: 0.8 }
				}}
				sx={{ border: 'none' }}
				style={{ outline: 0 }}
			>
				<Slide direction="down" in={openModalNewGoal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
					<div className={"containerModalFormAddObjective"}>

						<button onClick={() => { closeModalFormGoals() }} className="close"><IconClose /></button>

						<form onSubmit={handleSubmitGoal}>
							<main>
								<header>
									<h3>
										{t('goals.modal_goal.new_obj')}
										<span>
											<IconInfo />
											<div className="tooltip">
												<h6>{t('goals.modal_goal.h6')}</h6>
												<p><strong>{t('goals.modal_goal.item1')}</strong> {t('goals.modal_goal.content1')}</p>
												<p><strong>{t('goals.modal_goal.item2')}</strong> {t('goals.modal_goal.content2')}</p>
												<p><strong>{t('goals.modal_goal.item3')}</strong> {t('goals.modal_goal.content3')}</p>
												<p><strong>{t('goals.modal_goal.item4')}</strong> {t('goals.modal_goal.content4')}</p>
											</div>
										</span>
									</h3>
								</header>
								<div className="field">
									<label htmlFor="title">{t('goals.modal_goal.title')}*</label>
									<input
										id="title"
										type="text"
										name="title"
										placeholder={t('goals.modal_goal.title_placeholder')}
										value={form.title || ''}
										onChange={handleChange}
										autoFocus
										maxLength={150}
									/>
									<span className="text-limit">{form.title.length}/150</span>
									{formError.title &&
										<div className="error">{formError.title}</div>
									}
								</div>

								<div className="field">
									<label htmlFor="description">{t('goals.modal_goal.description')}*</label>
									<textarea
										name="description"
										id="description"
										cols="30"
										rows="3"
										placeholder={t('goals.modal_goal.description_placeholder')}
										value={form.description || ''}
										onChange={handleChange}
									/>
									{formError.description &&
										<div className="error">{formError.description}</div>
									}
								</div>

								{/* <div className="fieldset selectsNewObjective"> */}
								{/* <div className="col">
										<label htmlFor="category">{t('goals.modal_goal.category')}*</label>
										<select
											name="category"
											id="category"
											value={form.category || ''}
											onChange={handleChange}
											onFocus={handleFocus}
										>
											<option value="" disabled selected hidden>{t('goals.modal_goal.select')}</option>

											{categories?.map(item => {
												if (userDetails?.language === 'en') {
													return (
														<option key={item.id} value={item.id}>
															{item.name_en}
														</option>
													)
												}

												if (userDetails?.language === 'es') {
													return (
														<option key={item.id} value={item.id}>
															{item.name_es}
														</option>
													)
												}

												return (
													<option key={item.id} value={item.id}>
														{item.name_pt}
													</option>
												)
											})}
										</select>
										{formError.category &&
											<div className="error">{formError.category}</div>
										}
									</div> */}

								{/* <div className="col">
										<label htmlFor="type">{t('goals.modal_goal.type')}*</label>

										<div className="groupInputRadio">
											<div>
												<input type="radio" name="type" id="strategic" value={1} onChange={handleChange} />
												<label htmlFor="strategic">{t('goals.modal_goal.strategic')}</label>
											</div><div>
												<input type="radio" name="type" id="tactical" value={2} onChange={handleChange} />
												<label htmlFor="tactical">{t('goals.modal_goal.tactical')}</label>
											</div>
										</div>

										{(formError.type && !isCheckType) &&
											<div className="error">{formError.type}</div>
										}

									</div> */}
								{/* </div> */}

								<div className="field">
									<label htmlFor="responsable">{t('goals.modal_goal.responsible')}*</label>
									<Select
										id='responsable'
										defaultValue={form.responsable || ''}
										name="responsable"
										options={teammatesOptions}
										className="basic-multi-select"
										classNamePrefix="select"
										placeholder={t('goals.modal_goal.select')}
										onChange={(e) => setResponsible(e.value)}
									/>

									{formError.responsable &&
										<div className="error">{formError.responsable}</div>
									}
								</div>

								<div className="fieldset">



									{/* <div className="col">
										<label htmlFor="year_period">{t('goals.modal_goal.year')}</label>

										<select name="year_period" id="year_period" value={form.year_period} onChange={handleChange}>
											{
												optionYears.length && (
													optionYears?.map((item, index) => <option key={item?.id} value={item?.value}>{item?.value}</option>)
												)
											}
										</select>


										{formError.year_period &&
											<div className="error">{formError.year_period}</div>
										}
									</div> */}

									<div className="col">
										<label htmlFor="period">{t('goals.modal_goal.type_period')}</label>
										<select name="period" id="period" value={period || ''} onChange={(e) => { setPeriod(e.target.value) }}>
											<option value="custom" selected>{t('goals.modal_goal.custom')}</option>
											<option value="monthly">{t('goals.modal_goal.monthly')}</option>
											<option value="bimonthly">{t('goals.modal_goal.bimonthly')}</option>
											<option value="quarterly">{t('goals.modal_goal.quarterly')}</option>
											<option value="semester">{t('goals.modal_goal.semester')}</option>
											<option value="yearly">{t('goals.modal_goal.yearly')}</option>
										</select>

										{formError.period &&
											<div className="error">{formError.period}</div>
										}
									</div>

									<div className="col">
										<label htmlFor="value_period">{t('goals.modal_goal.value_period')}</label>
										<select name="value_period" id="value_period" value={valuePeriod} onChange={(e) => { setValuePeriod(e.target.value) }} disabled={period === 'custom'} style={{ backgroundColor: `${period === 'custom' ? '#EEEEEE' : '#FFFFFF'}` }}>
											{/* <option value="" selected>{t('goals.modal_goal.select')}</option> */}
											{
												period &&

												optionsPeriods
													.filter(option => { return option.type === period })
													.map((option, index) => {
														if (option.type === 'monthly') {
															return <option
																key={option.value}
																value={option.value}
																selected={index === 0}>
																{t(`months.${option.value}`)}
															</option>
														}
														return <option
															key={option.value}
															value={option.value}
															selected={index === 0}>
															{option.value}
														</option>
													}

													)

											}
										</select>

										{formError.value_period &&
											<div className="error">{formError.value_period}</div>
										}
									</div>

								</div>

								<div className="fieldset">

									<div className="col w-50">
										<label htmlFor="date_start">{t('goals.modal_goal.start')}</label>

										<BefectiveDatePicker
											userDetails={userDetails}
											date_value={form.date_start}
											formEdit={form}
											setFormEdit={setForm}
											setSubmitted={setSubmitted}
											field="date_start"
											minDate={""}
											maxDate={""}
											className="goalInput"
										/>
										{/* <input 
											className="w-auto" 
											type="date" 
											id="date_start" 
											name="date_start" 
											value={form.date_start || ''} 
											onChange={handleChange}
										/> */}
										{(formError.date_start) &&
											<div className="error">{formError.date_start}</div>
										}
									</div>

									<div className="col w-50">
										<label htmlFor="date_end">{t('goals.modal_goal.end')}</label>
										<BefectiveDatePicker
											userDetails={userDetails}
											date_value={form.date_end}
											formEdit={form}
											setFormEdit={setForm}
											setSubmitted={setSubmitted}
											field="date_end"
											minDate={""}
											maxDate={""}
											className="goalInput"
										/>
										{/* <input 
											className="w-auto" 
											type="date" 
											id="date_end" 
											name="date_end" 
											value={form.date_end || ''} 
											onChange={handleChange} 											
										/> */}
										{(formError.date_end) &&
											<div className="error">{formError.date_end}</div>
										}
									</div>
								</div>

								<div className="fieldset">
									<div className="col w-50">
										<label htmlFor="weight">{t('goals.modal_goal.weight')}</label>
										<select name="weight" id="weight" value={form.weight || '1'} onChange={handleChange}>
											<option value="1" selected>1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
										</select>
										{(formError.weight) &&
											<div className="error">{formError.weight}</div>
										}
									</div>
								</div>

								<div
									className="fieldset"
									style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px', }}
								>
									<label
										htmlFor="published"
										style={{ fontSize: '13px', color: '#24677E', paddingLeft: '2px'}}
									>
										{t('goals.modal_goal.published_label')}
									</label>

									<div>

										<span style={{ color: form.published === false ? '#1976d2':'#1E6B7F', fontWeight: form.published === false ? '600' : '400', textTransform: 'capitalize', fontSize: '12px' }}>{t('settings.company.no')}</span>
										<Switch
											id="published"
											checked={form.published}
											onChange={() => setForm({
												...form,
												published: !form.published
											})}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
										<span style={{ color: form.published === true ? '#1976d2':'#1E6B7F', fontWeight: form.published === true ? '600' : '400', textTransform: 'capitalize', fontSize: '12px' }}>{t('settings.company.yes')}</span>
									</div>

								</div>

								<footer>
									<div className="buttonActionsGroup">
										<button
											className="btnCancel"
											type="button"
											onClick={() => { closeModalFormGoals() }}
										>
											{t('goals.modal_goal.cancel')}
										</button>
										<button
											type="submit"
											disabled={isSubmitting}
										>
											{isSubmitting ? <CircularProgress size={15} /> : t('goals.modal_goal.save')}
										</button>
									</div>
								</footer>
							</main>
						</form>

					</div>
				</Slide>

			</Modal>

		</>

	)

};

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		share: state.share
	}
}

function mapDispatchToProps(dispatch) {
	return {
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalsModalForm));
