import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { Box, CircularProgress, Grid, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { KanbanBoard } from '../../_components/project_management/KanbanBoard';

function ProjectManagementListsPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, responsibles, usersActions } = props

  
	let path = window.location.pathname.split("/") || [];

  const { id } = useParams()

  const [boardData, setBoardData] = useState(null)


  const [loading, setLoading] = useState(false);

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getBoardKanban = async (isSetLoading=true) => {
    try {
      if(isSetLoading) setLoading(true);
      const { data } = await api.get(`/project-management/boards/${id}/kanban`, {
        headers: authHeader()
      })
      setBoardData(data)
      if(isSetLoading) setLoading(false)
    } catch (error) {
      console.error(error.response)
      //alertActions.error(t('project_management.errors.load'))
      if(isSetLoading) setLoading(false)
    }
  }

  useEffect(() => {
    usersActions.getResponsibles(userDetails?.organizationId)
    getBoardKanban()
  }, [])


  return (
    <section className="mainContent productivity">

      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        routeBackToPage={`/project_management/${path[2]}`}
      />

      <div className={style.containerBoardsPage}>
        {
          !loading
            ?
              boardData &&
                <KanbanBoard
                  boardID={id}
                  boardData={boardData}
                  setBoardData={setBoardData}
                  userDetails={userDetails}
                  getBoardKanban={() => {
                    getBoardKanban(false)
                  }}
                  alertActions={alertActions}
                  colabs={responsibles}
                />
            :
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Box display={'flex'} alignItems={'center'} padding={'40px'} minHeight={'100px'} justifyContent={'center'}>
                  <CircularProgress size={40} color='primary'/>
                </Box>
              </Grid>
            </Grid>
        }
      </div>

    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementListsPage)