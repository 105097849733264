export function orderData(data, column, type, orderedColumn, setOrderedColumn, order, setOrder) {

    let newOrder = 'asc';

    if (column === orderedColumn && order === 'asc') {
      newOrder = 'desc';
    }

    setOrderedColumn(column);
    setOrder(newOrder);

    const orderedData = [...data].sort((a, b) => {
      if (newOrder === 'asc') {
        if(type==='text'){
          return a[column].localeCompare(b[column]);
        }else if (type === 'number') {
          return Number(a[column]) - Number(b[column]);
        } else if (type === 'date') {
          return new Date(a[column]) - new Date(b[column]);
        }
      } else {
        if (type === 'text') {
          return b[column].localeCompare(a[column]);
        } else if (type === 'number') {
          return Number(b[column]) - Number(a[column]);
        } else if (type === 'date') {
          return new Date(b[column]) - new Date(a[column]);
        }
      }
    });

    return orderedData

}
