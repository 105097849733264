import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { TableCell } from '@material-ui/core';
import { AddCircleOutline, Close, Edit } from '@material-ui/icons';
import { Avatar, Box, Button, Drawer, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import moment from "moment";
import { ArrowRight, Info, ListBullets } from 'phosphor-react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCalender3 from '../../_icons/IconCalender3';
import IconIn from '../../_icons/IconIn';
import IconOut from '../../_icons/IconOut';


import { authHeader, befectiveFormatDate, calcDurationBTWArrPeriodsInMinutes, genPeriodSimpleArray, getAvatarUrl, getCorrectPeriod, getRemainingPoints, hoursToMinutes } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import { DrawerDetailsToday } from "./DrawerDetailsToday";

import style from './CheckinTable.module.scss';
import { DrawerSolicitations } from "./DrawerSolicitations";
import { ModalJustify } from "./ModalJustify";
import './fullCalendar.scss';

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

const pointsCircle = [
  <Box key={1} width={6} height={6} border="1px solid #C1CDDA" borderRadius="100%" />,
  <Box key={2} width={6} height={6} bgcolor="#C1CDDA" border="1px solid #C1CDDA" borderRadius="100%" />,
  <Box key={3} width={6} height={6} bgcolor="#22BEAD" border="1px solid #22BEAD" borderRadius="100%" />,
  <Box key={4} width={6} height={6} bgcolor="#FF7A90" border="1px solid #FF7A90" borderRadius="100%" />,
  <Box key={5} width={6} height={6} border="1px solid #FF7A90" borderRadius="100%" />,
  <Box key={6} width={6} height={6} bgcolor="#187DFF" border="1px solid #187DFF" borderRadius="100%" />,
  <Box key={7} width={6} height={6} border="1px solid #187DFF" borderRadius="100%" />,
]

export function CheckinTable({ alertActions, userDetails, reasons, colabPoints, periods, getColabPoints, pExemple }) {

  const { t, i18n } = useTranslation('common');

  const [solicitations, setSolicitations] = useState([]);

  const [pointDetails, setPointDetails] = useState(null)
  const [openDrawerDetailsToday, setOpenDrawerDetailsToday] = useState(false)
  const [openModalJustify, setOpenModalJustify] = useState(false)
  const [openDrawerDetailsDay, setOpenDrawerDetailsDay] = useState(false)
  const [openDrawerSolicitations, setOpenDrawerSolicitations] = useState(false)
  const [seeCalendar, setSeeCalendar] = useState(false)
  const [pointToJustify, setPointToJustify] = useState(null)
  const [periodToJustify, setPeriodToJustify] = useState(null)
  const [dayToJustify, setDayToJustify] = useState(null)
  const [summariesDateToJustify, setSummariesDateToJustify] = useState(null)
  const [typePointToJustify, setTypePointToJustify] = useState(null)


  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }



  function renderCellContent(info) {
    const day = info.event.startStr

    const filtererEventByDay = colabPoints.find(item => item.day === day)

    return (
      <Box display="flex" gap="4px" alignItems="center" justifyContent="center">
        {filtererEventByDay.periods_by_day.map(period => (
          period.points_by_periods.map((point, index) => (
            <Fragment key={index}>
              {pointsCircle[point.status_id]}
            </Fragment>
          ))
        ))}
      </Box>
    );
  }

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    if (clickInfo.dateStr !== new Date().toISOString().split('T')[0]) return

    getPointDetails(clickInfo.dateStr)
    setOpenDrawerDetailsToday(true)
  }



  function checkIfIsAfterTime(period, day, checkStart = true) {
    const now = moment();
    const dayFormatted = day.split('T')[0]
    if (period.start_time === undefined || period.end_time === undefined) {
      return false
    }
    if (checkStart) {
      const start = moment(`${dayFormatted}T${period.start_time}`);

      if (now.isAfter(start)) {
        return true
      } else {
        return false
      }
    } else {
      const end = moment(`${dayFormatted}T${period.end_time}`);

      if (now.isAfter(end)) {
        return true
      } else {
        return false
      }
    }
  }

  function calcDurationInMinutes(start, end) {
    const startHour = moment(start, "HH:mm:ss");
    const endHour = moment(end, "HH:mm:ss");
    const diffInMiliSeconds = endHour.diff(startHour);
    const durationInMinutes = moment.duration(diffInMiliSeconds).asMinutes();
    return durationInMinutes;
  }

  function handleOpenDrawerDetailsDay(day) {
    getPointDetails(day)
    setOpenDrawerDetailsDay(true)
  }


  function handleOpenModalJustify(periods_by_day, point, day, summaries_date_id, isCheckin) {

    if (isCheckin) {
      setTypePointToJustify("IN")
    } else {
      setTypePointToJustify("OUT")
    }
    if (point !== null) {
      setPointToJustify(point)
    }
    const p1 = getCorrectPeriod(point, periods_by_day)

    setPeriodToJustify({
      value: p1
    })
    setDayToJustify(day)
    setSummariesDateToJustify(summaries_date_id)

    setOpenModalJustify(true)
  }


  async function handleOpenDrawerSolicitations() {
    try {
      const response = await api.get(`/checkin/colab/solicitations/${userDetails.mu_id}`, {
        headers: authHeader()
      })

      setSolicitations(response.data)
      setOpenDrawerSolicitations(true)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_solicitations_points_error"))
    }
  }

  async function getPointDetails(day) {
    try {
      const response = await api.get(`/checkin/point/details?owner_id=${userDetails.mu_id}&group_id=${userDetails.group_id}&day=${day}`, {
        headers: authHeader()
      })

      setPointDetails(response.data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_details_point_error"))
    }
  }

  return (
    <>
      {seeCalendar ? (
        <Box className={style.calendarContainer} id="calendarDashCheckin">

          <Box display="flex" bgcolor="#fff" sx={{ flex: '1', padding: '10px 0 5px 0' }} marginBottom="2px">
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.sun')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.mon')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.tue')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.wed')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.thu')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.fri')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box className={style.bullets} marginRight={2} sx={{ opacity: '0', cursor: 'initial' }}>
                <ListBullets fill="#98acc1" />
              </Box>
              <Typography component="span" fontSize={12} color="#4F7091" fontWeight={500} textTransform="uppercase">{t('settings.teams.sat')}</Typography>
              <Box className={style.bullets} marginRight={2} onClick={() => setSeeCalendar(false)}>
                <ListBullets width={15} height={15} color="#98acc1" />
              </Box>
            </Box>
          </Box>

          <FullCalendar
            locales={allLocales}
            locale={i18n.language}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            initialView='dayGridMonth'
            editable={false}
            selectable={false}
            selectMirror={false}
            weekends={true}
            firstDay="0"
            contentHeight={650}
            dayHeaders={false}
            events={
              colabPoints.reduce((points, point) => {
                return [...points, { title: '', date: point.day, periods_by_day: point.periods_by_day }]
              }, [])
            }
            eventContent={renderCellContent}
            dateClick={handleEventClick}
          />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px', textTransform: "uppercase" }} width='8%'>{t("checkin.dashboard.checkin_table.col_date")}</TableCell>
                {/* {periods?.length > 0 && periods.map((_, index) => (
                  <Fragment key={index}>
                    <TableCell className={style.tableHeaderTd} align="center" width="5%">{t("checkin.dashboard.checkin_table.col_in")}</TableCell>
                    <TableCell className={style.tableHeaderTd} align="center" width="7%">{t("checkin.dashboard.checkin_table.col_out")}</TableCell>
                    <TableCell className={style.tableHeaderTd} align="center" width="3%"></TableCell>
                  </Fragment>
                ))} */}
                <TableCell className={style.tableHeaderTd} align="left">{t("checkin.control_points.table.col_points")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="7%">{t("checkin.dashboard.checkin_table.col_time")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="5%">{t("checkin.dashboard.checkin_table.col_balance")}</TableCell>
                <TableCell className={style.tableHeaderTd} style={{ paddingRight: '20px', width: '5%' }} align="right">
                  <Box className={style.calendar} onClick={() => setSeeCalendar(true)}>
                    <IconCalender3 fill="#98acc1" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {

                colabPoints?.length > 0
                  ?
                  colabPoints?.map(dayInfos => (
                    <TableRow key={dayInfos.day}>
                      <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                        <Box display={'flex'} flexDirection={'row'}>
                          <Box display={'flex'} alignItems={'center'}>
                            {befectiveFormatDate(dayInfos.day, lang, "S")}
                          </Box>
                          <Box>
                            <Tooltip
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    backgroundColor: "#FDBE2B",
                                    color: "black",
                                    fontWeight: 500
                                  }
                                }
                              }}
                              title={dayInfos.periods.map((p) => <Box fontSize={'12px'}>{p.start + ' - ' + p.end}</Box>)}
                              placement="right"
                            >
                              <IconButton size="small" style={{ width: '26px', height: '26px' }}>
                                {/* <Info fontSize="inherit" color="red"/> */}
                                <Info size={16} color="#4F7091" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell style={{ borderBottom: '2px solid #F5F6F9' }}>
                        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                          {
                            dayInfos.points.length > 0
                            &&
                            <>
                              {dayInfos.points.map((point, index) => (

                                <Box
                                  key={`${point.moment} ${index}`}
                                  style={{ padding: 0 }}
                                  align="center"
                                >
                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          backgroundColor: "#FDBE2B",
                                          color: "black",
                                          fontWeight: 500,
                                          width: '120px',
                                          fontSize: '11px'
                                        }
                                      }
                                    }}
                                    title={
                                      [6, 7].includes(point.status_id)
                                        ? point.status_id === 6
                                          ? t("checkin.tooltip.adjust_manual_success")
                                          : t("checkin.tooltip.waiting_return")
                                        : ""
                                    }
                                    placement="right"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      gap="4px"
                                      padding={'6px 2px'}
                                      margin="0 auto"
                                      minWidth={'54px'}
                                      className={[
                                        style.icon,
                                        [3, 4, 5, 6, 7].includes(point.status_id) && style.iconHover
                                      ].join(' ')}
                                      onClick={() => {
                                        [5, 6, 7].includes(point.status_id)
                                          ? handleOpenDrawerSolicitations()
                                          : [3, 4].includes(point.status_id)
                                          && handleOpenModalJustify(dayInfos.periods, point, dayInfos.day, dayInfos.summaries_date_id, index === 0)
                                      }}
                                    >
                                      {point.type === 'IN' ? (
                                        <IconIn fill={statusColors[point.status_id - 1]} />
                                      ) : (
                                        <IconOut fill={statusColors[point.status_id - 1]} />
                                      )}
                                      <Typography
                                        color={
                                          [6, 7].includes(point.status_id)
                                            ? "#187DFF"
                                            : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                                        }
                                        component="span"
                                        fontSize={12}
                                        lineHeight="initial"
                                      >
                                        {[5, 6, 7].includes(point.status_id) ? (
                                          point.status_id === 7 ? (
                                            t("checkin.status.send")
                                          ) : point.status_id === 6 ? (
                                            point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                          ) : (
                                            t("checkin.status.refused")
                                          )
                                        ) : (
                                          point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                        )}
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                </Box>

                              ))}

                              {
                                getRemainingPoints(dayInfos)?.map((item, index) => (

                                  <Box
                                    key={index}
                                    style={{ padding: 0 }}
                                    align="center"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      minWidth={'54px'}
                                      gap="4px"
                                      margin="0 auto"
                                      className={[
                                        style.icon,
                                        style.iconHover
                                      ].join(' ')}
                                      onClick={() => {
                                        handleOpenModalJustify(dayInfos.periods, null, dayInfos.day, dayInfos.summaries_date_id, index === 0)
                                      }}
                                      padding={'6px 2px'}
                                    >
                                      {item?.type === 'IN' ? (
                                        <IconIn fill={"#C1CDDA"} />
                                      ) : (
                                        <IconOut fill={"#C1CDDA"} />
                                      )}
                                      <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                                        --:--
                                      </Typography>
                                    </Box>
                                  </Box>


                                ))
                              }
                            </>
                          }
                        </Box>
                      </TableCell>

                      <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="right">
                        {dayInfos.hours_amount}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: '2px solid #F5F6F9',
                          color: hoursToMinutes(dayInfos.balance) < 0 ? '#FF5151' : '#22BEAD',
                          fontSize: '12px',
                        }}
                        align="right"
                      >
                        {dayInfos.balance}
                      </TableCell>
                      <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9' }} align="right">
                        <Box className={style.arrow} onClick={() => handleOpenDrawerDetailsDay(dayInfos.day)}>
                          <ArrowRight size={18} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                  :
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                        {t('checkin.no_data.points')}
                      </Typography>
                    </TableCell>
                  </TableRow>
              }

            </TableBody>
          </Table>

          {openDrawerSolicitations && solicitations && (
            <DrawerSolicitations
              userDetails={userDetails}
              solicitations={solicitations}
              openDrawerSolicitations={openDrawerSolicitations}
              setOpenDrawerSolicitations={setOpenDrawerSolicitations}
            />
          )}

          {openModalJustify && (
            <ModalJustify
              alertActions={alertActions}
              lang={lang}
              dayToJustify={dayToJustify}
              openModalJustify={openModalJustify}
              periodToJustify={periodToJustify}
              pointToJustify={pointToJustify}
              reasons={reasons}
              setDayToJustify={setDayToJustify}
              setOpenModalJustify={setOpenModalJustify}
              setPointToJustify={setPointToJustify}
              setSummariesDateToJustify={setSummariesDateToJustify}
              setTypePointToJustify={setTypePointToJustify}
              summariesDateToJustify={summariesDateToJustify}
              typePointToJustify={typePointToJustify}
              getColabPoints={getColabPoints}
            />
          )}

          {pointDetails && openDrawerDetailsDay && (
            <Drawer
              anchor="right"
              open={openDrawerDetailsDay}
              onClose={() => setOpenDrawerDetailsDay(false)}
              PaperProps={{
                style: {
                  padding: '0px 12px',
                  width: '350px'
                }
              }}
            >
              <div style={{ position: 'absolute', right: 4, top: 4 }}>
                <IconButton aria-label="close" onClick={() => setOpenDrawerDetailsDay(false)}>
                  <Close fontSize="medium" />
                </IconButton>
              </div>

              <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap="4px" flexDirection="column">
                      <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>
                        {t("checkin.drawer_details_day.register_day") + " " + befectiveFormatDate(pointDetails.day, lang, "S")}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                        {t("checkin.drawer_details_day.regular_business_hours") + " " + pointDetails.hours_should_work}
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" gap={4} mb={2} px="16px">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={pointDetails.hours_amount < pointDetails.hours_should_work ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.hours_amount}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>{t("checkin.drawer_details_day.worked_hours")}</Typography>
                      </Box>
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={hoursToMinutes(pointDetails.balance) < 0 ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.balance}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                          {hoursToMinutes(pointDetails.balance) < 0 ? (
                            t("checkin.drawer_details_day.missing_hours")
                          ) : (
                            t("checkin.drawer_details_day.overtime_hours")
                          )}

                        </Typography>
                      </Box>

                    </Box>

                  </Box>

                  {pointDetails && (
                    <Box key={pointDetails.start} display="flex" flexDirection="column" className={style.periodBox}>
                      <Box
                        display="flex"
                        bgcolor={
                          (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                            ? "#FFE6E6"
                            : "#DBF5F2"
                        }
                        paddingX={2}
                        paddingY={1}
                        borderRadius="4px"
                      >
                        <Typography
                          color={
                            (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                              ? "#FF7A90"
                              : "#22BEAD"
                          }
                          fontSize={12}
                        >
                          {t("checkin.drawer_details_day.period_of")}{' '}
                          {getCorrectPeriod(null, pointDetails.periods)}

                        </Typography>
                      </Box>

                      {pointDetails.points.map(point => (
                        <Box key={point.id} display="flex" flexDirection="column" paddingX={2} mt={2}>
                          <Box display="flex" gap="8px">
                            <Box>
                              {point.type === 'IN' ? (
                                <IconIn fill={statusColors[point.status_id - 1]} />
                              ) : (
                                <IconOut fill={statusColors[point.status_id - 1]} />
                              )}
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Typography component="span" lineHeight={1} fontSize={14} color="#1E6B7F">
                                <Typography component="span" color="#1E6B7F" lineHeight={1} fontSize={14} fontWeight={600}>
                                  {point.time.split(':')[0] + ":" + point.time.split(':')[1]}{' '}
                                </Typography>
                                {point.type === 'IN' ? (
                                  t("checkin.drawer_details_day.entry")
                                ) : (
                                  t("checkin.drawer_details_day.exit")
                                )}
                              </Typography>
                              <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                              <Typography fontSize={12} color="#4F7091">{t("checkin.drawer_details_day.receipt") + ": " + point?.receipt_token}</Typography>
                            </Box>
                          </ Box>

                          {point.solicitations.map(solicitation => (
                            solicitation.comment !== "" && (
                              <Box key={solicitation.id} display="flex" gap="6px" mt={2} mb={2} alignItems="center">
                                <Avatar
                                  alt={userDetails.name}
                                  src={
                                    getAvatarUrl({
                                      name: userDetails.name
                                    },
                                      "FDA02B"
                                    )
                                  }
                                  style={{ width: 18, height: 18 }}
                                />
                                <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                              </Box>
                            )
                          ))}
                        </Box>
                      ))}
                    </Box>

                  )
                  }
                </Box>


                <Button
                  variant="contained"
                  className={style.solicitationsBtn}
                  style={{ padding: '0', backgroundColor: '#E3EFFF', color: '#187DFF', height: '40px', boxShadow: 'none' }}
                  onClick={() => handleOpenDrawerSolicitations()}
                >
                  {t('checkin.see_solicitations')}
                </Button>
              </Box>

            </Drawer>
          )}

        </TableContainer >
      )}

      {openDrawerDetailsToday && pointDetails && (

        <DrawerDetailsToday
          userDetails={userDetails}
          pointDetails={pointDetails}
          openDrawerDetailsToday={openDrawerDetailsToday}
          setOpenDrawerDetailsToday={setOpenDrawerDetailsToday}
        />
      )}

    </>
  );
}
