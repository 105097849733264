import React, { createContext, useContext, useState } from "react";
import { authHeader } from "../_helpers";
import { api } from "../_helpers/api";

const MessagesCountContext = createContext({})

export function MessagesCountProvider({ children }) {
  const [messagesNotRead, setMessagesNotRead] = useState(0)
  const [solicitations, setSolicitations] = useState(0)

  const getMessagesUnRead = async (organization_id, user_id) => {
    try {
      const result = await api.get(
        `messages/unread/?organization_id=${organization_id}&owner_id=${user_id}`
      )
      setMessagesNotRead(result.data?.amount)
    } catch (error) {
      // alertActions.error(error)
      console.log(error.response)
    }
  }

  const getOpenSolicitations = async () => {
    try {
      const result = await api.get(`checkin/solicitations/open`, {
        headers: authHeader()
      })
      setSolicitations(result.data?.total_open)
    } catch (error) {
      // alertActions.error(error)
      console.log(error.response)
    }
  }
  return (
    <MessagesCountContext.Provider value={{ messagesNotRead, getMessagesUnRead, solicitations, getOpenSolicitations }}>
      {children}
    </MessagesCountContext.Provider>
  )
}

export const useMessagesCount = () => useContext(MessagesCountContext)