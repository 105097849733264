import { Backdrop, CircularProgress, Modal, Slide, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, filterActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import moment from 'moment';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { DashboardCardsHeader } from '../../_components/remote_checkin/DashboardCardsHeader';
import { DashboardTable } from '../../_components/remote_checkin/DashboardTable';
import { DashboardProgress } from '../../_components/remote_checkin/DashboardProgress';
import GenericSkeleton from '../../_components/remote_checkin/GenericSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function RemoteCheckinDashboardPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, teammates, filter, filterActions } = props

  let startDate = new Date(moment().startOf('month')).toISOString().split('T')[0];
  let endDate = new Date(moment()).toISOString().split('T')[0];

  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)

  function groupedRecordsByDate(data) {
    return data.reduce((agrupado, item) => {
      const { date_ref, records, ...outrasProps } = item;

      if (!agrupado[date_ref]) {
        agrupado[date_ref] = { date_ref, records: [], ...outrasProps };
      }

      agrupado[date_ref].records.push(...records);

      return agrupado;
    }, {});
  }

  async function getDashboardData() {
    try {
      setFetching(true)

      let start_date = startDate;
      let end_date = endDate;

      if (filter.date) {
        start_date = filter.date.startDate;
        end_date = filter.date.endDate;
      }

      const currentDay = new Date(moment()).toISOString().split('T')[0];

      const response = await api.get(`/remote_track/dashboard?mu_id=${userDetails.mu_id}&org_id=${userDetails.organizationId}&start=${start_date}&end=${end_date}&current_day=${currentDay}`, {
        headers: authHeader()
      })

      setData(response.data)

      setFetching(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("remote_checkin.errors.load"))
      setFetching(false)
    }
  }


  useEffect(() => {
    // let start_date = startDate;
    // let end_date = endDate;

    // if (filter.date) {
    //   start_date = filter.date.startDate;
    //   end_date = filter.date.endDate;
    // }

    getDashboardData()
  }, [filter.date])

  useEffect(() => {
    document.title = `Befective | ${t('remote_checkin.title_page')}`;
    // filterActions.clear('when')
  }, []);

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'multi'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
        />

        {/* PAGE STARTS */}
        <div className={style.containerCheckinPage}>

          {
            !fetching ?
              <>
                <DashboardCardsHeader
                  stats={data.stats}
                  alertActions={alertActions}
                  userDetails={userDetails}
                />
                <DashboardProgress
                  todayInfo={groupedRecordsByDate(data.data)[moment().format('YYYY-MM-DD')]}
                  alertActions={alertActions}
                  userDetails={userDetails}
                />
                <DashboardTable
                  data={data.data}
                  userDetails={userDetails}
                  alertActions={alertActions}
                  getData={getDashboardData}
                />
              </>
              :
              <GenericSkeleton />
          }
        </div>
        {/* PAGE ENDS */}

      </section>

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteCheckinDashboardPage)