import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import "react-datepicker/dist/react-datepicker.css";
import {addDays, subYears} from 'date-fns';
import Moment from 'moment';

import {activitiesActions, filterActions} from '../../../_actions';


import {useTranslation, initReactI18next} from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";


import IconClear from '../../_icons/IconClear'
import IconSearch from '../../_icons/IconSearch'


function Filterbar(props) {
    const {t, i18n} = useTranslation('common');

    const { filterActions, activitiesActions, filter} = props;


    const [filterWhatValue, setFilterWhatValue] = useState('');

    const refFilter = useRef();

    const [filterWhat, setWhat] = useState(true);
    const searchInput = useRef(null);
    // useOutsideClick(refFilter, target => {

    //     if (filterWhatValue.length > 2) {
    //         changeFilterWhat();
    //     }

    //     setWhat(false)
    // });

    const handleChange = e => {

        setFilterWhatValue(e.target.value)

    }

    const changeFilterWhat = () => {


        let value = filterWhatValue.toLowerCase();

        if (value.length > 2) {
            filterActions.set(value, "what");
        } else {
            filterActions.set('', 'what');
        }

    }

    function submitWhat(e) {
        if (e.keyCode == 13) {
            changeFilterWhat()

            let startDate = Moment(new Date()).format('YYYY-MM-DD');
            let endDate = Moment(new Date()).format('YYYY-MM-DD');

            if (filter.date) {
                startDate = filter.date.startDate;
                endDate = filter.date.endDate;
            }
            let value = filterWhatValue.toLowerCase();

            activitiesActions.getActivities(startDate, endDate, null, null, 0, true, true, true, true, true, true, value);
        }
    }

    function clearWhat() {
        setFilterWhatValue('');
        filterActions.clear("what");
        filterActions.set('', 'what');

        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        activitiesActions.getActivities(startDate, endDate, null, null, 0, true, true, true, true, true, true, null);
    }


    // useEffect(() => {

    //     searchInput.current.focus();

    // }, [filterWhat])

    return (

        <div className={filterWhat ? 'filter what open' : 'what filter'} ref={refFilter}>
            <div className="holder">
                <span className="label" style={{minWidth:'45px'}}>{t('filter.what')}:</span>
                <span className="value" onClick={() => {
                    setWhat(!filterWhat);
                }}>{filterWhatValue}</span>
                <input ref={searchInput} className="input" placeholder={t('filter.search_2')} value={filterWhatValue}
                       onChange={handleChange} onKeyUp={(e) => {
                    submitWhat(e);
                }}/>
                <div className="clear" onClick={() => {
                    clearWhat()
                }}><IconClear/></div>
                <div className={filterWhat ? 'clear submit show' : ' clear submit'} onClick={() => {
                    changeFilterWhat()
                }}><IconSearch/></div>
            </div>

        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {
        filterActions: bindActionCreators(filterActions, dispatch),
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Filterbar)
