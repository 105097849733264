import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableSoftware.scss';
import { useTranslation } from 'react-i18next';
import { ArrowRight, PencilSimple } from 'phosphor-react';
import { Apartment, Edit, Home } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { befetiveFormatCurrency, history } from '../../../../_helpers';
import { IconButton, Tooltip } from '@material-ui/core';

export default function TableSoftware({
  data = [],
  setLicenseToEdit,
  setOpenModalToEdit
}) {

  const { t, i18n } = useTranslation('common');
  
  const handleActionClick = (e, row) => {
    e.stopPropagation();
    setOpenModalToEdit(true)
    setLicenseToEdit(row)
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableInventorySoftware' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid #F5F6F9' }}>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='left'>
              {t("software_inventory.table.col_software_name")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>
              {t("software_inventory.table.col_manufacture")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_version")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_type")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_licenses")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_licenses_install")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_license_balance")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_percent_utilization")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>
              {t("software_inventory.table.col_percent_real_use")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='right'>
              {t("software_inventory.table.col_unit_cost")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='right'>
              {t("software_inventory.table.col_total_cost")}
            </TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='right'>
              {t("software_inventory.table.col_economy_potential")}
            </TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.id} className={`row-table`} onClick={() => history.push(`/inventory/software/${row.id}`)}>
                <TableCell align="left" className='textWrap'>
                  {String(row.name || "----")}
                </TableCell>
                <TableCell>
                  {row.manufacturer_name || "----"}
                </TableCell>
                <TableCell align="center">
                  {String(row.version || "----")}
                </TableCell>
                <TableCell align="center">
                  {String(row.type)}
                </TableCell>
                <TableCell align="center">
                  {String(row.number_of_license)}
                </TableCell>
                <TableCell align="center">
                  {String(row.licenses_used)}
                </TableCell>
                <TableCell align="center">
                  {Number(row.license_balance)}
                </TableCell>
                <TableCell align="center">
                  {Number(row.utilization_percent) + "%"}
                </TableCell>
                <TableCell align="center">
                  {Number(row.avg_real_usage) + "%"}
                </TableCell>
                <TableCell align="right">
                  {row.currency_symbol ? row.currency_type+" "+row.currency_symbol+" "+befetiveFormatCurrency((Number(row.total_cost) / Number(row.number_of_license)),i18n.language, false) : befetiveFormatCurrency((Number(row.total_cost) / Number(row.number_of_license)),i18n.language)}
                </TableCell>
                <TableCell align="right">
                  {row.currency_symbol ? row.currency_type+" "+row.currency_symbol+" "+ befetiveFormatCurrency(Number(row.total_cost),i18n.language, false) : befetiveFormatCurrency(Number(row.total_cost),i18n.language)}
                </TableCell>
                <TableCell align="right">
                  {row.currency_symbol ? row.currency_type+" "+row.currency_symbol+" "+befetiveFormatCurrency(Number(row.economic_potential),i18n.language, false) : befetiveFormatCurrency(Number(row.economic_potential),i18n.language)}
                </TableCell>
                <TableCell style={{ fontSize: '12px'}}>
                  <div style={{display:"flex", justifyContent:"flex-end", gap:"2px"}}>
                    <IconButton
                      className={`button-options`}
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => handleActionClick(e, row)}
                    >
                      <PencilSimple size={16} weight='bold' fill='#187DFF'/>
                    </IconButton>
                    <IconButton
                      className={`button-options`}
                      aria-label="expand row"
                      size="small"
                      onClick={() => history.push(`/inventory/software/${row.id}`)}
                    >
                      <ArrowRight size={16} weight='bold'/>
                    </IconButton>
                  </div>
                </TableCell>

              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
