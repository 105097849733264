import React from 'react';

const IconCalender3 = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10.286" viewBox="0 0 9 10.286">
      <path id="Icon_awesome-calendar" data-name="Icon awesome-calendar" d="M.241,3.857H8.759A.242.242,0,0,1,9,4.1V9.321a.965.965,0,0,1-.964.964H.964A.965.965,0,0,1,0,9.321V4.1A.242.242,0,0,1,.241,3.857ZM9,2.973V2.25a.965.965,0,0,0-.964-.964H7.071V.241A.242.242,0,0,0,6.83,0h-.8a.242.242,0,0,0-.241.241V1.286H3.214V.241A.242.242,0,0,0,2.973,0h-.8a.242.242,0,0,0-.241.241V1.286H.964A.965.965,0,0,0,0,2.25v.723a.242.242,0,0,0,.241.241H8.759A.242.242,0,0,0,9,2.973Z" fill={props.fill} />
    </svg>
  )
}

export default IconCalender3;