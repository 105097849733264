import { backfectiveConstants } from '../_constants';
import { backfectiveService } from '../_services';
import { alertActions } from '.';

export const backfectiveActions = {  
    getInvitations,
    getOrganizations,
    magicAgent
};

function getOrganizations() {
    return dispatch => {
        dispatch(request());

        backfectiveService.getOrganizations()
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_REQUEST } }
    function success(payload) { return { type: backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_SUCCESS, payload } }
    function failure(error) { return { type: backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_FAILURE, error } }
}


function getInvitations() {
    return dispatch => {
        dispatch(request());

        backfectiveService.getInvitations()
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_REQUEST } }
    function success(payload) { return { type: backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_SUCCESS, payload } }
    function failure(error) { return { type: backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_FAILURE, error } }
}

function magicAgent(id) {
    return dispatch => {
        dispatch(request());

        backfectiveService.magicAgent(id)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_REQUEST } }
    function success(payload) { return { type: backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_SUCCESS, payload } }
    function failure(error) { return { type: backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_FAILURE, error } }
}