// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X\\+HTx4vvfGAsfH1dBoraeA\\=\\= {\n  background-color: #D9E1E8 !important;\n  height: 46px;\n  min-width: 180px !important;\n}\n\n.vvGegIToLT-MWDeDVvCy3A\\=\\= {\n  width: 280px;\n  top: 4px !important;\n  left: 28px !important;\n  z-index: 999 !important;\n}\n\n.q-ZI91bzdT-RC2UW4ZIQ1g\\=\\= {\n  width: 280px;\n  top: 4px !important;\n  left: 50px !important;\n  z-index: 999 !important;\n}\n\n.RzxQfPP\\+\\+c45aRbNCLcP-g\\=\\= {\n  padding: 10px 14px 0px;\n  color: #D9E1E8;\n  font-size: 12px !important;\n}\n\n.lYVmv6Zv06-Ho\\+P\\+6S-bwg\\=\\= {\n  color: #D9E1E8;\n  font-size: 12px !important;\n}", "",{"version":3,"sources":["webpack://./src/_components/_shared/filters/WorkspacesFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,YAAA;EACA,2BAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF","sourcesContent":[".btnFilterWorkspace{\n  background-color: #D9E1E8 !important;\n  height: 46px;\n  min-width: 180px !important;\n}\n\n.dropdownWorkspaces{\n  width: 280px;\n  top: 4px !important;\n  left: 28px !important;\n  z-index: 999 !important;\n}\n\n.dropdownRecentBoards{\n  width: 280px;\n  top: 4px !important;\n  left: 50px !important;\n  z-index: 999 !important;\n}\n\n.itemTextTitle{\n  padding: 10px 14px 0px;\n  color: #D9E1E8;\n  font-size: 12px !important;\n}\n\n.itemText{\n  color: #D9E1E8;\n  font-size: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnFilterWorkspace": "X+HTx4vvfGAsfH1dBoraeA==",
	"dropdownWorkspaces": "vvGegIToLT-MWDeDVvCy3A==",
	"dropdownRecentBoards": "q-ZI91bzdT-RC2UW4ZIQ1g==",
	"itemTextTitle": "RzxQfPP++c45aRbNCLcP-g==",
	"itemText": "lYVmv6Zv06-Ho+P+6S-bwg=="
};
export default ___CSS_LOADER_EXPORT___;
