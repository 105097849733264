import React from 'react';

const IconAnticipated = props => {
  return (

    <svg id="antecip" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1470" data-name="Retângulo 1470" width="16" height="16" fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons" transform="translate(0.69 0.674)">
        <g id="Grupo_492" data-name="Grupo 492">
          <path id="Caminho_388" data-name="Caminho 388" d="M6.113,11.981h0a.667.667,0,0,0-.3-.967,3.933,3.933,0,0,1-2.4-4.067,3.972,3.972,0,0,1,7.333-1.733.767.767,0,0,0,.767.3h0a.667.667,0,0,0,.367-1,5.267,5.267,0,0,0-5-2.467,5.333,5.333,0,0,0-1.6,10.2.667.667,0,0,0,.833-.267Z" transform="translate(-2.069 -2.022)" fill={ props.fill || "#fc6662"} />
          <path id="Caminho_389" data-name="Caminho 389" d="M11.833,10.167v1.667H10.167a.667.667,0,1,0,0,1.333h3v-3a.667.667,0,1,0-1.333,0Z" transform="translate(-7.023 -7.007)" fill={ props.fill || "#fc6662"} />
          <path id="Caminho_390" data-name="Caminho 390" d="M23.255,25.036l-5-8.667a.667.667,0,0,0-1.133,0l-5,8.667a.667.667,0,0,0,.567,1h10a.667.667,0,0,0,.567-1ZM17.688,24.7a.667.667,0,1,1,.667-.667A.667.667,0,0,1,17.688,24.7Zm.667-2.667a.667.667,0,1,1-1.333,0V19.369a.667.667,0,1,1,1.333,0Z" transform="translate(-8.711 -11.376)" fill={ props.fill || "#fc6662"} />
        </g>
      </g>
    </svg>

  )
}

export default IconAnticipated;