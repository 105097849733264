import React from 'react';

const IconEmail = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
     
          <g id="icons_Q2" data-name="icons Q2" transform="translate(0.971 3.658)">
            <path id="Caminho_41" data-name="Caminho 41" d="M22.4,7H2.971A.971.971,0,0,0,2,7.971v14.57a.971.971,0,0,0,.971.971H22.4a.971.971,0,0,0,.971-.971V7.971A.971.971,0,0,0,22.4,7Zm-.971,14.57H3.943V11.711l8.5,5a.437.437,0,0,0,.486,0l8.5-5.148Zm-8.5-7.091a.437.437,0,0,1-.486,0l-8.5-5.537H21.426Z" transform="translate(-2 -7)" fill="#97b3bf"/>
          </g>
      
      </svg>
      


 

    )
}

export default IconEmail;