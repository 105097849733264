import React from 'react';

const IconClose = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path id="X" d="M18.707,17.287,22.993,13a1,1,0,0,0-1.42-1.42l-4.286,4.286L13,11.581A1,1,0,1,0,11.581,13l4.286,4.286-4.286,4.286A1,1,0,0,0,13,22.993l4.286-4.286,4.286,4.286a1,1,0,0,0,1.42-1.42Z" transform="translate(-11.285 -11.289)" fill="#98acc1"/>
         </svg>
      


    )
}

export default IconClose;