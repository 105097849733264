import { Box, Breadcrumbs, CircularProgress, Container, Divider, Grid, IconButton, InputBase, Link, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './styles.module.scss'
import IconLogo from '../../_components/_icons/IconLogo'
import { useTranslation } from 'react-i18next';
import { Search } from '@material-ui/icons';
import { api } from '../../_helpers/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertActions } from '../../_actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


function HelpCenterDetailsPage(props) {
  const { t, i18n } = useTranslation('common');
  const { alertActions } = props
  const [category, setCategory] = useState([])
  const [fetching, setFetching] = useState(true)

  const { id } = useParams()

  const getCategoryDetail = async () => {
    setFetching(true)
    await api.get(`/simple/help/categories/details/${id}?lang=${i18n.language}`)
      .then(({ data }) => {
        setCategory(data)
        setFetching(false)
      })
      .catch(error => {
        alertActions.error(error)
        setFetching(false)
      })
  }

  useEffect(() => {
    getCategoryDetail()
  }, [])

  return (
    <Box className={style.containerPage}>
      <Box component={'header'} className={style.header}>
        <Container maxWidth="md" className={style.containerHeader}>
          <IconLogo />
          <a className={style.button} href='/productive'>
            {t('help_center.btn_to_account')}
          </a>
        </Container>
      </Box>

      {
        !fetching
          ?
          <Container component={'main'} maxWidth="md" className={style.containerMain} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                  <Link underline="hover" color="inherit" href="/help-center">
                    {t('help_center.home')}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href={`/help-center-subcategories/${category.parent[0].id}`}
                  >
                    {category.parent[0][`title_${i18n.language}`]}
                  </Link>
                  <Typography sx={{color: '#106276'}}>{category[`title_${i18n.language}`]}</Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid container spacing={2} my={1}>
              <Grid item xs={12}>
                <Typography variant='h5' sx={{ color: '#106276', fontWeight: 500 }}>{category[`article_title_${i18n.language}`]}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: category[`article_text_${i18n.language}`] }}></div>
              </Grid>
            </Grid>
            

          </Container>

          :
          <Container component={'main'} maxWidth="md" className={style.containerMain} sx={{ flexGrow: 1}}>
            <Grid container spacing={2}>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} mt={2}>
                <CircularProgress size={20} />
              </Grid>
            </Grid>
          </Container>
      }
    </Box>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterDetailsPage)