import { Box, Tooltip, Typography } from '@mui/material';
import { CalendarCheck, ChartBar, ClipboardText, DeviceMobile, Kanban, ListChecks, Timer } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { redirectToModule } from '../../_helpers/redirectToModule';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';

import { IconButton } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { useState } from 'react';
import { authHeader, doCountAccessModule, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './CardModule.module.scss';

const icons = {
  productivity_management: <ChartBar color="#FDA02B" weight='bold' />,
  goals_management: <ListChecks color="#FDA02B" weight='bold' />,
  check_in_remote: <Timer color="#FDA02B" weight='bold' />,
  check_in_remote_mobile: <DeviceMobile color="#FDA02B" weight='bold' />,
  rooms_management: <CalendarCheck color="#FDA02B" weight='bold' />,
  project_management: <Kanban color="#FDA02B" weight='bold' />,
  inventory: <ClipboardText color="#FDA02B" weight='bold' />
}

export function CardModule({ module, initialScreen, userDetails, usersActions }) {
  const { t } = useTranslation('common');
  const token = authHeader();
  const [sending, setSending] = useState(false);
  const { saveCurrentModule } = useCurrentModule()

  const updateLastModule = async () => {
    try {
      await api.put(`/teammates/${userDetails?.mu_id}/update-last-access`, {
        last_module_name: module.name
      }, {
        headers: {
          'Authorization': token.Authorization
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleRedirectToModule() {

    saveCurrentModule(module.name)
    doCountAccessModule(module.name,api,authHeader)
    await updateLastModule()
    if (userDetails.roleName !== 'collaborator') {
      redirectToModule(module.name, initialScreen)
    } else if (module.name === 'productivity_management') {
      history.push('/productive/productivity/')
    } else {
      redirectToModule(module.name, initialScreen)
    }
  }

  const handleFavoriteModule = async (module) => {
    try {
      setSending(true)
      const res = await api.put(`/teammates/${userDetails?.mu_id}/favorite-module`, {
        module_favorite: module ? module.name : null
      }, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      setSending(false)
      usersActions.refresh(userDetails);
    } catch (error) {
      console.log(error)
      setSending(false)
    }
  }

  return (
    <Box position={'relative'}>
      <Box
        display="flex"
        flexDirection="column"
        paddingX={3}
        paddingY={2}
        bgcolor="#fff"
        borderRadius="8px"
        alignItems="center"
        justifyContent="center"
        className={style.modulesCard}
        boxShadow="0px 2px 3px -2px rgba(0,0,0,0.5)"
        cursor="pointer"
        sx={{
          height: { sm: 112, xl: 144 },
          width: { sm: 200, xl: 170 }
        }}
        onClick={handleRedirectToModule}
      >
        <Box
          sx={{
            height: { sm: 40, xl: 60 },
            width: { sm: 40, xl: 60 }
          }}
        >
          {icons[`${module.name}`]}
        </Box>
        <Typography
          component="span"
          textAlign="center"
          fontSize={18}
          fontWeight={500}
          color="#1E6B7F"
          mt={2}
          paddingX="5px"
        >
          {t(`switch.${module.name}`)}
        </Typography>
      </Box>
      {
        userDetails?.origin_organization === userDetails?.organizationId && (

          module?.name === userDetails?.defaultModule
            ?
            <Tooltip title={t('switch.unfavorite')} arrow>
              <IconButton className={style.buttonFavorite} aria-label="favorite" size="small" onClick={() => handleFavoriteModule(null)} disabled={sending}>
                <BookmarkIcon style={{ fill: '#22BEAD' }} />
              </IconButton>
            </Tooltip>
            :
            <Tooltip title={t('switch.favorite')} arrow>
              <IconButton className={style.buttonFavorite} aria-label="favorite" size="small" onClick={() => handleFavoriteModule(module)} disabled={sending}>
                <BookmarkBorderIcon style={{ fill: '#22BEAD' }} />
              </IconButton>
            </Tooltip>
        )}

    </Box>

  )
}
