import React from 'react';

const IconArrow3 = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4.855" viewBox="0 0 8 4.855">
            <path id="_" data-name="&gt;"
                  d="M4.087,13.409.526,9.849a.44.44,0,0,1,0-.622l.415-.415a.44.44,0,0,1,.621,0L4.4,11.633,7.233,8.811a.44.44,0,0,1,.621,0l.415.415a.44.44,0,0,1,0,.622l-3.56,3.56A.44.44,0,0,1,4.087,13.409Z"
                  transform="translate(-0.398 -8.683)" fill="#187dff"/>
        </svg>


    )
}

export default IconArrow3;
