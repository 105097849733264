import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, getRangeFilterWhen, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget28Skeleton from './Widget28Skeleton';
import { ChartBarHorizontal, ChartPieSlice } from 'phosphor-react';
import DonutChart from './DonutChart';
import ReactApexChart from 'react-apexcharts';
import IconOnline from '../../_icons/IconOnline';
import IconOutdoor from '../../_icons/IconOutdoor';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function Widget28({
  alertActions,
  currentDate,
  width,
  filters,
  userDetails
}) {
  const { t, i18n } = useTranslation('common');
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [toggleView, setToggleView] = useState(true);

  const [chartDonutProductive, setChartDonutProductive] = useState({
    chart: {
      type: 'donut',
      height: 300,
      width: 300,
    },
    options: {
      labels: [t('widgets.widget7.online_activity'), t('widgets.widget7.offline_activity')],
      colors: ['#22BEAD', '#1E6B7F'],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div class="custom-tooltip" style="background-color: ${w.config.colors[seriesIndex]}; padding: 4px;">${w.config.labels[seriesIndex]}: ${series[seriesIndex]}%</div>`;
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 320
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      legend: {
        show: true,
        formatter: function (series, opts) {
          return `<span class="legend-text" style="font-size:12px;">${opts.w.globals.labels[opts.seriesIndex]}</span>`;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: []
  })

  const [chartDonutApps, setChartDonutApps] = useState({
    chart: {
      type: 'donut',
      height: 100,
      width: 100,
    },
    colors: ['#22BEAD', '#FDBE2B', '#FF7A90'],
    series: [],
    labels: [t('widgets.widget7.related'), t('widgets.widget7.neutral'), t('widgets.widget7.unrelated')],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="custom-tooltip" style="background-color: ${w.config.colors[seriesIndex]}; padding: 4px;">${w.config.labels[seriesIndex]}: ${series[seriesIndex]}%</div>`;
      },
    },
  })

  const [chartDonutSite, setChartDonutSite] = useState({
    chart: {
      type: 'donut',
      height: 100,
      width: 100,
    },
    colors: ['#22BEAD', '#FDBE2B', '#FF7A90'],
    series: [0],
    labels: [t('widgets.widget7.related'), t('widgets.widget7.neutral'), t('widgets.widget7.unrelated')],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="custom-tooltip" style="background-color: ${w.config.colors[seriesIndex]}; padding: 4px;">${w.config.labels[seriesIndex]}: ${series[seriesIndex]}%</div>`;
      },
    },
  })

  const [chartDonutOffline, setChartDonutOffline] = useState({
    chart: {
      type: 'donut',
      height: 100,
      width: 100,
    },
    colors: ['#22BEAD', '#FDBE2B', '#FF7A90'],
    series: [0],
    labels: [t('widgets.widget7.related'), t('widgets.widget7.neutral'), t('widgets.widget7.unrelated')],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="custom-tooltip" style="background-color: ${w.config.colors[seriesIndex]}; padding: 4px;">${w.config.labels[seriesIndex]}: ${series[seriesIndex]}%</div>`;
      },
    },
  })

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates, toggleView }) => {
    try {
      setFetching(true)

      const response = await api.get(`/stats/executive/time_goal_work_goal2?year_month=${currentDate.format('YYYY-MM')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget28!!!')
      }
      const { data } = response;
      
      const objSeries = {
        seriesSoftware: [],
        seriesSite: [],
        seriesOutdoor: []
      }
      const colors = []
      const labels = []

      if(userDetails.allowed_productive.includes('related')){
        objSeries.seriesSoftware.push(data.percent_productive_software)
        objSeries.seriesSite.push(data.percent_productive_site)
        objSeries.seriesOutdoor.push(data.percent_productive_outdoor)
        colors.push('#22BEAD')
        labels.push(t('widgets.widget7.related'))
      }
      if(userDetails.allowed_productive.includes('neutral')){
        objSeries.seriesSoftware.push(data.percent_neutral_software)
        objSeries.seriesSite.push(data.percent_neutral_site)
        objSeries.seriesOutdoor.push(data.percent_neutral_outdoor)
        colors.push('#FDBE2B')
        labels.push(t('widgets.widget7.neutral'))
      }
      if(userDetails.allowed_productive.includes('not_related')){
        objSeries.seriesSoftware.push(data.percent_unproductive_software)
        objSeries.seriesSite.push(data.percent_unproductive_site)
        objSeries.seriesOutdoor.push(data.percent_unproductive_outdoor)
        colors.push('#FF7A90')
        labels.push(t('widgets.widget7.unrelated'))
      }

      setChartDonutApps({
        ...chartDonutApps,
        colors: colors,
        series: objSeries.seriesSoftware
      })
      setChartDonutSite({
        ...chartDonutSite,
        colors: colors,
        series: objSeries.seriesSite
      })
      setChartDonutOffline({
        ...chartDonutOffline,
        colors: colors,
        series: objSeries.seriesOutdoor
      })

      setChartDonutProductive({
        ...chartDonutProductive,
        series: [data.percent_productive_online, data.percent_productive_offline]
      })

      setData(data)

      setFetching(false)

    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({ ...filters })
  }, [])

  // useEffect(() => {
  //   getData({ ...filters, toggleView: toggleView })
  // }, [toggleView])

  return (

    <Grid item xs={width} position={'relative'}>
      {/* <button className={style.btnToggle} onClick={() => setToggleView(!toggleView)}>
        {
          toggleView
            ?
            <ChartBarHorizontal
              size={18}
              weight="bold"
            />
            :
            <ChartPieSlice
              size={18}
              weight="bold"
            />
        }
      </button> */}
      {
        !fetching
          ?
          <div className={style.containerView2}>
            <div className={style.boxLeft}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{`${t('widgets.widget7.time_at_work')}: ${secondsToHm(data.total_work)} ${data.percent_work}%`}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget7.tooltip2')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              <div className={style.containerCharts}>
                <div className={style.col}>
                  <div className={style.rowChart}>
                    <div className={style.boxChart}>
                      <DonutChart data={chartDonutApps} />
                      <div className={style.iconCenter}>
                        <IconOnline color='#1E6B7F' width={20} height={20} />
                      </div>
                    </div>
                    <div className={style.infoStatsChart}>
                      <h6>{`${t('widgets.widget7.software')}:${data.percent_work_software}%`}</h6>
                      <span>{secondsToHm(data.total_work_software)}</span>
                    </div>
                  </div>

                  {
                    userDetails.allowed_productive.includes('related') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.related}`}>{`${t('widgets.widget7.related')}: ${secondsToHm(data.total_productive_software)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.related}`}>{`${data.percent_productive_software}%`}</div>
                      </div>
                    )
                  }
                  {
                    userDetails.allowed_productive.includes('neutral') && (

                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.neutral}`}>{`${t('widgets.widget7.neutral')}: ${secondsToHm(data.total_neutral_software)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.neutral}`}>{`${data.percent_neutral_software}%`}</div>
                      </div>
                    )
                  }
                  {
                    userDetails.allowed_productive.includes('not_related') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.unrelated}`}>{`${t('widgets.widget7.unrelated')}: ${secondsToHm(data.total_unproductive_software)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.unrelated}`}>{`${data.percent_unproductive_software}%`}</div>
                      </div>

                    )
                  }
                </div>
                <div className={style.col}>
                  <div className={style.rowChart}>
                    <div className={style.boxChart}>
                      <DonutChart data={chartDonutSite} />
                      <div className={style.iconCenter}>
                        <IconOnline color='#1E6B7F' width={20} height={20} />
                      </div>
                    </div>
                    <div className={style.infoStatsChart}>
                      <h6>{`${t('widgets.widget7.site')}:${data.percent_work_site}%`}</h6>
                      <span>{secondsToHm(data.total_work_site)}</span>
                    </div>
                  </div>
                  {
                    userDetails.allowed_productive.includes('related') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.related}`}>{`${t('widgets.widget7.related')}: ${secondsToHm(data.total_productive_site)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.related}`}>{`${data.percent_productive_site}%`}</div>
                      </div>
                    )
                  }
                  {
                    userDetails.allowed_productive.includes('neutral') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.neutral}`}>{`${t('widgets.widget7.neutral')}: ${secondsToHm(data.total_neutral_site)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.neutral}`}>{`${data.percent_neutral_site}%`}</div>
                      </div>

                    )
                  }
                  {
                    userDetails.allowed_productive.includes('not_related') && (

                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.unrelated}`}>{`${t('widgets.widget7.unrelated')}: ${secondsToHm(data.total_unproductive_site)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.unrelated}`}>{`${data.percent_unproductive_site}%`}</div>
                      </div>
                    )
                  }
                </div>
                <div className={style.col}>
                  <div className={style.rowChart}>
                    <div className={style.boxChart}>
                      <DonutChart data={chartDonutOffline} />
                      <div className={style.iconCenter}>
                        <IconOutdoor color='#1E6B7F' width={20} height={20} />
                      </div>
                    </div>
                    <div className={style.infoStatsChart}>
                      <h6>{`${t('widgets.widget7.offline')}:${data.percent_work_offline}%`}</h6>
                      <span>{secondsToHm(data.total_work_offline)}</span>
                    </div>
                  </div>
                  {
                    userDetails.allowed_productive.includes('related') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.related}`}>{`${t('widgets.widget7.related')}: ${secondsToHm(data.total_productive_outdoor)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.related}`}>{`${data.percent_productive_outdoor}%`}</div>
                      </div>
                    )
                  }
                  {
                    userDetails.allowed_productive.includes('neutral') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.neutral}`}>{`${t('widgets.widget7.neutral')}: ${secondsToHm(data.total_neutral_outdoor)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.neutral}`}>{`${data.percent_neutral_outdoor}%`}</div>
                      </div>
                    )
                  }
                  {
                    userDetails.allowed_productive.includes('not_related') && (
                      <div className={style.rowInfoStats}>
                        <div className={`${style.textInfoStats} ${style.unrelated}`}>{`${t('widgets.widget7.unrelated')}: ${secondsToHm(data.total_unproductive_outdoor)}`}</div>
                        <div className={`${style.boxPercentStats} ${style.unrelated}`}>{`${data.percent_unproductive_outdoor}%`}</div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className={style.boxMiddle}>

            </div>
            <div className={style.boxRight}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{`${t('widgets.widget7.productive_time')}: ${secondsToHm(data.total_productive)} ${data.percent_productive}%`}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget7.tooltip2')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              <div className={style.containerCharts}>
                <Box component={'div'} sx={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center' }}>
                  <ReactApexChart options={chartDonutProductive.options} series={chartDonutProductive.series} width={380} height={220} type="donut" />
                </Box>
              </div>
            </div>
          </div>
          :
          <Widget28Skeleton />
      }
    </Grid>
  )
}
