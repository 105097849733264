import React, { useRef, useState, compose, useMemo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { useTable, useSortBy } from 'react-table'
import Moment from 'moment';

import { settingsActions } from '../../../_actions';
import { teamsActions } from '../../../_actions';

import { secondsToHm } from '../../../_helpers';

import IconTeammate from '../../_icons/IconTeammate'
import IconPermissions from '../../_icons/IconPermissions'
import IconRemove from '../../_icons/IconRemove'
import IconInfo from '../../_icons/IconInfo'


function CompanyAdministrators(props) {
  const { t, i18n } = useTranslation('common');

  const { teamsActions } = props;
  const { settingsActions } = props;

  let token = props.token || {};
  let companyAdministrators = props.companyAdministrators || [];
  let permissions = props.permissions || {};

  Moment.locale(t('date.locale'));

  const data = useMemo(() => companyAdministrators)

  const columns = React.useMemo(
    () => [
      {
        Header: t('settings.administrator.name'),
        accessor: 'name',
        Cell: (d) => {

          if (d.row.original.confirmed_at) {
            if(d.value){
              return <div className="administrator"><div className="avatar"><img src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&rounded=true&size=200&name='+encodeURI(d.value)} /></div>{d.value}</div>
            }
            else{
              return <div className="administrator"><div className="avatar"></div>{t("settings.administrator.not_informed")}</div>
            }
            
          }
          else {
            if(d.value){
              return <div className="administrator notConfirmed"><div className="avatar"><img src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&rounded=true&size=200&name='+encodeURI(d.value)} /></div>{d.value} - {t("settings.administrator.not_confirmed")}</div>
            }
            else{
              return <div className="administrator notConfirmed"><div className="avatar"></div>{t("settings.administrator.not_confirmed")}</div>
            }
            
          }

        }
      },
      {
        Header: t('settings.administrator.email'),
        accessor: 'email',
      },
      {
        Header: t('settings.administrator.role'),
        accessor: 'role',
        Cell: (d) => {

          let role = d.value;
          if (d.value == 'admin' || d.value == 'magic') {
            role = <div className="admin1">{t('admin1')}</div>
          }
          if (d.value == 'manager') {
            role = <div className="admin2">{t('admin2')}</div>
          }
          if (d.value == 'analyst') {
            role = <div className="admin3">{t('admin3')}</div>
          }


          return role
        }
      },
      {
        Header: t('settings.administrator.last_access'),
        accessor: 'last_sign_in_at',
        Cell: (d) => {

          let last_access;

          if (d.row.original.confirmed_at) {
            last_access = Moment.utc(d.row.original.last_sign_in_at).local().startOf('seconds').fromNow()
          }
          else {
            last_access = "n/a";
          }

          return last_access
        }
      },
      {
        Header: '',
        accessor: 'user_id',
        Cell: (d) => {

          return (
            <div className="actions">
              {
                (permissions.UpdateCompanyAdministrators) &&
                <button 
                  className="permissions" title={t('settings.administrator.update')} 
                  onClick={() => { openDrawer("manage", d.value) }}>
                    <IconPermissions />
                </button>
              }
              {
                (permissions.DisableCompanyAdministrators) &&
                <button 
                  className="remove" 
                  title={t('settings.administrator.remove')} 
                  onClick={() => { toggleRemoveDialog(d.value) }}>
                    <IconRemove />
                </button>
              }
              <div className="removeDialog" id={"remove-" + d.value}>
                <div className="content">
                  <div className="message"><IconInfo />{t('settings.administrator.you_are_sure')}</div>
                  <div className="options">
                    <button onClick={() => { toggleRemoveDialog() }}>{t('no')}</button>
                    <button onClick={() => { disableUser(d.value) }}>{t('yes')}</button>
                  </div>
                </div>
              </div>
            </div>
          )


        }
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  function toggleRemoveDialog(id) {

    let elems = document.querySelectorAll(".removeDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList?.remove("open");
      });
    }

    let elem = document.getElementById("remove-" + id)

    if (elem) {
      elem.classList?.toggle("open")
    }

  }

  function openDrawer(type, id) {

    if (type == "manage") {
      settingsActions.getCompanyAdministrator(id)
    }
    teamsActions.getTeams();
    props.openDrawer({ type: type, id: id });


  }

  function disableUser(id) {
    toggleRemoveDialog()

    document.getElementById("remove-" + id).parentNode.parentNode.parentNode.remove()

    settingsActions.deleteCompanyAdministrator(id)

  }

  useEffect(() => {

  }, [])


  return (
    <React.Fragment>
      <header className="pageHeader">
        <h3>{t('settings.administrator.title')}</h3>
        {(permissions.InviteCompanyAdministrators) &&
          <button onClick={() => { openDrawer("invite", null) }}>
            <IconTeammate />
            <span>{t('settings.administrator.invite')}</span>
          </button>
        }
      </header>

      <div className="dataTable administrators">
        {data.length > 0 &&
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <div className="arrowDown"></div>
                            : <div className="arrowUp"></div>
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(
                (row, i) => {

                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        )
                      })}
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        }

      </div>

    </React.Fragment>

  )
};



function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    companyAdministrators: state.settings.companyAdministrators,
    permissions: state.authentication.permissions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
    teamsActions: bindActionCreators(teamsActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyAdministrators));