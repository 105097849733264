import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Backdrop, CircularProgress, IconButton, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../../../../_actions";
import AsyncSelect from 'react-select/async';
import "./ModalCreate.scss"
import moment from "moment";
import { Close } from "@material-ui/icons";
import { BefectiveDatePicker } from "../../../../goals/BefectiveDatePicker";
import { authHeader } from "../../../../../_helpers";
import { api } from "../../../../../_helpers/api";
import { NumberFormatBase } from "react-number-format";
import { formatCurrencyByEnd } from "../../../../../_helpers/formattValue";
import InputFilterSite from "./InputFilterSite";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalEdit({
  openModal,
  setOpenModal,
  user,
  teammates,
  updateInfo,
  licenseToEdit,
  setLicenseToEdit,
  alertActions,
  currencies
}) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trackedOptions, setTrackedOptions] = useState([]);

  const filteredUsers = (teammatesArray, usersArray) => {

    if(usersArray.length === 0) return []

    for (const t of teammatesArray) {

      if(!usersArray.find(u => u.id === t.value)){
        return usersArray.map(u => ({value: u.id, label: u.username}))
      }

    }

    return [
      {
        value: 'all_colabs',
        label: t('settings.daysoff_settings.modal_create.all')
      }
    ]
  }

  const [form, setForm] = useState({
    type: licenseToEdit?.type,
    software_name: licenseToEdit?.type === "software" ? licenseToEdit?.name : "",
    software_version: licenseToEdit?.version || "",
    manufacturer_name: licenseToEdit?.manufacturer_name || "",
    site_name: licenseToEdit?.type === "site" ? licenseToEdit?.name : "",
    total_licenses: licenseToEdit?.number_of_license || 1,
    purchase_date: moment(licenseToEdit?.purchase_date).format("YYYY-MM-DD") || "",
    due_date: moment(licenseToEdit?.due_date).format("YYYY-MM-DD") || "",
    currency: licenseToEdit?.currency_type || "",
    total_cost: licenseToEdit?.total_cost || "",
    users: filteredUsers(teammates, licenseToEdit?.users),
    trackeds: licenseToEdit?.type === "software" ? licenseToEdit?.trackeds.map(t => ({value: t.id, label: t.tracked__name})) : [],
    trackeds_sites: licenseToEdit?.type === "site" ? licenseToEdit?.trackeds.map(t => ({value: t.id, label: t.tracked__name})) : [],
  });

  const [formError, setFormError] = useState({
    software_name: '',
    software_version: "",
    manufacturer_name: "",
    site_name: "",
    total_licenses: "",
    purchase_date: "",
    due_date: "",
    currency: "",
    total_cost: "",
    users: "",
    trackeds: "",
    trackeds_sites: ""

  });

  const token = authHeader()

  const  [teammatesOptions, setTeammatesOptions] = useState([
    {
      value: 'all_colabs',
      label: t('settings.daysoff_settings.modal_create.all'),
      disabled: false
    },
    ...teammates
  ])

  const handleChange = e => {

    if (e.target.name === 'total_cost') {
      if (e.target.value) {
        setForm({
          ...form,
          [e.target.name]: (parseFloat(e.target.value) / 100).toFixed(2),
        })

        setFormError({
          ...formError,
          [e.target.name]: (0 / 100).toFixed(2),
        })

      }
    }

    if(e.target.name === 'total_licenses'){

      if(form.users.find(u => u.value === 'all_colabs') && Number(e.target.value) < teammates.length){
        return
      }

      if(Number(e.target.value) < 1){
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    
        setFormError({
          ...formError,
          [e.target.name]: t("validation.min_number"),
        })
        return
      }
      
      if(e.target.value < form.users.length){
        return
      }

      setTeammatesOptions([
        {
          value: 'all_colabs',
          label: t('settings.daysoff_settings.modal_create.all'),
          disabled: e.target.value < teammates.length,
        },
        ...teammates        
      ])
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    setFormError({
      ...formError,
      [e.target.name]: '',
    })

  }

  function closeModal() {

    setForm({
      type: "software",
      site_name: "",
      software_name: '',
      software_version: "",
      manufacturer_name: "",
      total_licenses: 1,
      purchase_date: "",
      due_date: "",
      currency: "",
      total_cost: "",
      users: [],
      trackeds: [],
      trackeds_sites: []
    })
    setFormError({
      site_name: '',
      software_name: '',
      software_version: "",
      manufacturer_name: "",
      total_licenses: "",
      purchase_date: "",
      due_date: "",
      currency: "",
      total_cost: "",
      users: [],
      trackeds: [],
      trackeds_sites: ""
    })
    setLicenseToEdit(null)
    setOpenModal(false)

  }

  const validateForm = (form) => {
    const errors = {};

    if (form.type === "software" && !form.software_name) {
      errors.software_name = t("validation.required")
    }

    if (form.type === "site" && !form.site_name) {
      errors.site_name = t("validation.required")
    }

    if (!form.purchase_date) {
      errors.purchase_date = t("validation.required")
    }
    if (!form.due_date) {
      errors.due_date = t("validation.required")
    }
    if (!form.currency) {
      errors.currency = t("validation.required")
    }
    if (!form.total_cost) {
      errors.total_cost = t("validation.required")
    }
    if (!form.total_licenses) {
      errors.total_licenses = t("validation.required")
    }else if(form.total_licenses < 1){
      errors.total_licenses = t("validation.min_number")
    }

    if (form.users.length === 0) {
      errors.users = t("validation.required")
    }
    

    // if (form.type === "software" && form.trackeds.length === 0) {
    //   errors.trackeds = t("validation.required")
    // }

    // if (form.type === "site" && form.trackeds_sites.length === 0) {
    //   errors.trackeds_sites = t("validation.required")
    // }

    return errors
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }


  const handleChangeUsers = (values) => {
    
    if(values.length > form.total_licenses){
      return
    }

    const containsAllColabs = values.find(v => v.value == 'all_colabs')

    if (containsAllColabs) {
      const all_teammates = teammatesOptions.filter(t => t.value !== "all_colabs")

      if (form.type === "software" && all_teammates.length > 0) {
        handleTrackedOptions(all_teammates)
      }   

      setForm({
        ...form,
        users: [containsAllColabs]
      })

      setFormError({ ...formError, users: '' })

      return
    }

    setForm({
      ...form,
      users: values
    })

    setFormError({ ...formError, users: '' })

    if (form.type === "software" && values.length > 0) {
      handleTrackedOptions(values)
    }

  }

  useEffect(() => {
    if(licenseToEdit && licenseToEdit.type === "software"){
      handleTrackedOptions(licenseToEdit?.users.map(u => ({value: u.id, label: u.username})))
    }
  }, [licenseToEdit])

  const handleTrackedOptions = async (users) => {
    const re_users = users.reduce((acum, curr) => {
      return `${acum}${curr.value},`
    }, '').slice(0, -1)
    try {
      const { data } = await api.get(`/settings/trackeds?users=${re_users}`, {
        headers: authHeader()
      });
      setTrackedOptions(data)
    } catch (error) {
      console.error('Erro ao buscar trackeds:', error);
    }
  }

  const createLicense = async () => {
    try {
      setIsSubmitting(true)
      let users = [];

      if (form.users.find(u => u.value === 'all_colabs')) {
        users = teammatesOptions.map(t => {
          if (t.value !== "all_colabs") {
            return t.value
          }
        })//.join(',')
      } else {
        users = form.users.map(u => u.value)//.join(',')
      }

      let trackeds = []
      let name = ""
      if (form.type === "software") {
        trackeds = form.trackeds.reduce((acum, curr) => {
          return [...acum, curr.value]
        }, [])//.join(',')
        name = form.software_name
      } else {
        trackeds = form.trackeds_sites.reduce((acum, curr) => {
          return [...acum, curr.value]
        }, [])
        name = form.site_name
      }

      const payload = {
        ref_users: users,
        ref_trackeds: trackeds,
        name: name,
        version: form.software_version,
        manufacturer_name: form.manufacturer_name,
        number_of_license: form.total_licenses,
        purchase_date: form.purchase_date,
        due_date: form.due_date,
        currency_type: form.currency,
        total_cost: form.total_cost,
        type: form.type
      }


      await api.put(`/inventory/software/${licenseToEdit.id}`, payload, {
        headers: {
          'Authorization': token.Authorization
        }
      })

      alertActions.success(t('software_inventory.form_license.updated_success'))
      closeModal()
      setIsSubmitting(false)

      await updateInfo(null, null, null, null, true)

    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      // alertActions.error(t('goals.errors.create_goal'))
    }
  }


  const handleChangeTrackeds = (values) => {

    setForm({
      ...form,
      trackeds: values
    })
    setFormError({ ...formError, trackeds: '' })
  }

  const handleChangeTrackedsSite = (values) => {

    setForm({
      ...form,
      trackeds_sites: values
    })
    setFormError({ ...formError, trackeds_sites: '' })
  }

  const loadOptionsTrackedsSites = async (inputValue, callback) => {

    const { data } = await api.get(`/settings/trackeds-sites/?search_text=${inputValue}`, {
      headers: authHeader()
    });
    callback(data);
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && submitted) {
      createLicense()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormCreateLicense ${classes.modal}`}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormCreateLicense"}>

            <div style={{ position: 'absolute', right: 12, top: 12, zIndex: 1 }}>
              <IconButton aria-label="close" onClick={closeModal} size="small">
                <Close fontSize="medium" />
              </IconButton>
            </div>

            <form onSubmit={handleSubmit}>
              <main>
                <header>
                  <h3>
                    {t("software_inventory.form_license.title_edit")}
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="type">{t('software_inventory.form_license.field_type')}*</label>
                  <div className="groupInputRadio">
                    <div>
                      <input
                        type="radio"
                        name="type"
                        id="software"
                        value={'software'}
                        onChange={handleChange}
                        checked={form.type === 'software'}
                      />
                      <label htmlFor="software">{t('software_inventory.form_license.field_type_software')}</label>
                    </div>
                    <div>
                      <input type="radio" name="type" id="site" value={'site'} onChange={handleChange} checked={form.type === 'site'} />
                      <label htmlFor="site">{t('software_inventory.form_license.field_type_site')}</label>
                    </div>
                  </div>

                  {(formError.type) &&
                    <div className="error">{formError.type}</div>
                  }
                </div>
                {
                  form.type === "software"
                    ?
                      <div className="field">
                        <label htmlFor="software_name">{t("software_inventory.form_license.field_software_name")}*</label>
                        <input
                          id="software_name"
                          type="text"
                          name="software_name"
                          placeholder={t("software_inventory.form_license.placeholder_software_name")}
                          value={form.software_name || ''}
                          onChange={handleChange}
                          maxLength={150}
                        />
                        <span className="text-limit">{form.software_name.length}/150</span>
                        {formError.software_name &&
                          <div className="error">{formError.software_name}</div>
                        }
                      </div>
                    :
                      <div className="field">
                        <label htmlFor="site_name">{t("software_inventory.form_license.field_site_name")}*</label>
                        <input
                          id="site_name"
                          type="text"
                          name="site_name"
                          placeholder={t("software_inventory.form_license.placeholder_site_name")}
                          value={form.site_name || ''}
                          onChange={handleChange}
                          maxLength={150}
                        />
                        <span className="text-limit">{form.site_name.length}/150</span>
                        {formError.site_name &&
                          <div className="error">{formError.site_name}</div>
                        }
                      </div>
                }
                
                <div className="field">
                  <label htmlFor="software_version">{t("software_inventory.form_license.field_software_version")}</label>
                  <input
                    id="software_version"
                    type="text"
                    name="software_version"
                    placeholder={"Ex: 1.0.0"}
                    value={form.software_version || ''}
                    onChange={handleChange}
                    maxLength={10}
                  />
                  {/* <span className="text-limit">{form.software_version.length}/10</span> */}
                  {formError.software_version &&
                    <div className="error">{formError.software_version}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="manufacturer_name">{t("software_inventory.form_license.field_manufacture_name")}</label>
                  <input
                    id="manufacturer_name"
                    type="text"
                    name="manufacturer_name"
                    placeholder={"Ex: Adobe"}
                    value={form.manufacturer_name || ''}
                    onChange={handleChange}
                    maxLength={50}
                  />
                  {/* <span className="text-limit">{form.manufacturer_name.length}/50</span> */}
                  {formError.manufacturer_name &&
                    <div className="error">{formError.manufacturer_name}</div>
                  }
                </div>


                <div className="fieldset">
                  <div className="field">
                    <label htmlFor="purchase_date">{t("software_inventory.form_license.field_salle_date")}*</label>

                    <BefectiveDatePicker
                      userDetails={user}
                      date_value={form.purchase_date}
                      formEdit={form}
                      setFormEdit={setForm}
                      setSubmitted={setSubmitted}
                      field="purchase_date"
                      minDate={""}
                      maxDate={""}
                      className="goalInput"
                    />

                    {/* <span className="text-limit">{form.purchase_date.length}/50</span> */}
                    {formError.purchase_date &&
                      <div className="error">{formError.purchase_date}</div>
                    }
                  </div>

                  <div className="field">
                    <label htmlFor="due_date">{t("software_inventory.form_license.field_expired_date")}*</label>

                    <BefectiveDatePicker
                      userDetails={user}
                      date_value={form.due_date}
                      formEdit={form}
                      setFormEdit={setForm}
                      setSubmitted={setSubmitted}
                      field="due_date"
                      minDate={""}
                      maxDate={""}
                      className="goalInput"
                    />

                    {/* <span className="text-limit">{form.due_date.length}/50</span> */}
                    {formError.due_date &&
                      <div className="error">{formError.due_date}</div>
                    }
                  </div>
                </div>
                <div className="fieldset">
                  <div className="col field">
                    <label htmlFor="total_licenses">{t("software_inventory.form_license.field_quantity_license")}*</label>
                    <input
                      id="total_licenses"
                      type="number"
                      name="total_licenses"
                      // placeholder={"ex: 10"}
                      value={form.total_licenses || ''}
                      onChange={handleChange}

                    />
                    {/* <span className="text-limit">{form.total_licenses.length}/50</span> */}
                    {formError.total_licenses &&
                      <div className="error">{formError.total_licenses}</div>
                    }
                  </div>

                  <div className="col">
                      <label htmlFor="currency">{t("software_inventory.form_license.field_currency")}*</label>
                      <select id="currency" name="currency" value={form.currency || ''} onChange={handleChange}>
                        <option value="" disabled hidden>{t("software_inventory.form_license.select_currency")}</option>
                        {
                          currencies && currencies.map((currency, index) => {
                            return <option key={index} value={currency.value}>{currency[`label_${ user.language || i18n.language}`] + ` ( ${currency.symbol} )`}</option>
                          })
                        }
                      </select>
                      {formError.currency &&
                        <div className="error">{formError.currency}</div>
                      }
                  </div>

                  <div className="col">
                    <label htmlFor="total_cost">{t("software_inventory.form_license.field_total_cost")}*</label>

                    <NumberFormatBase
                      id="total_cost"
                      format={formatCurrencyByEnd}
                      placeholder="0.00"
                      inputMode="numeric"
                      displayType="input"
                      value={
                        !form.total_cost || !Number(form.total_cost) ? "" : Number(form.total_cost) * 100
                      }
                      isAllowed={values => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue / 100 <= 10000000000;
                      }}
                      onValueChange={values => {
                        if (values.value) {
                          setForm({
                            ...form,
                            "total_cost": (parseFloat(values.value) / 100).toFixed(2),
                          })

                        } else {

                          setForm({
                            ...form,
                            "total_cost": (0 / 100).toFixed(2),
                          })
                        }
                      }}
                    />
                    {formError.total_cost &&
                      <div className="error">{formError.total_cost}</div>
                    }
                  </div>
                </div>



                <div className="field">
                  <label htmlFor="users">{t('settings.daysoff_settings.modal_create.field_colabs')}*</label>
                  <Select
                    id='users'
                    isMulti
                    defaultValue={form.users || null}
                    value={form.users || null}
                    name="users"
                    options={teammatesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('settings.daysoff_settings.modal_create.placeholder_colabs')}
                    onChange={handleChangeUsers}
                    isOptionDisabled={(option) => option.disabled || form.users.find(u => u.value == 'all_colabs')}
                  />

                  {formError.users &&
                    <div className="error">{formError.users}</div>
                  }
                </div>
                {
                  (form.type === "software" && trackedOptions.length > 0) &&
                  <div className="field">
                    <label htmlFor="trackeds">{t("software_inventory.form_license.field_apps_used")}</label>
                    <Select
                      id='trackeds'
                      isMulti
                      defaultValue={form.trackeds || null}
                      value={form.trackeds || null}
                      name="trackeds"
                      options={trackedOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={t("software_inventory.form_license.placeholder_apps_used")}
                      onChange={handleChangeTrackeds}
                    // isOptionDisabled={() => form.trackeds.find(u => u.value == 'all_colabs')}
                    />

                    {formError.trackeds &&
                      <div className="error">{formError.trackeds}</div>
                    }
                  </div>
                }

                {
                  (form.type === "site") &&
                  <div className="field">
                    <label htmlFor="trackeds_sites">{t("software_inventory.form_license.field_domain_sites")}</label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      id="trackeds_sites"
                      name="trackeds_sites"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={t("software_inventory.form_license.placeholder_domain_sites")}
                      loadOptions={loadOptionsTrackedsSites}
                      onChange={handleChangeTrackedsSite}
                      defaultValue={form.trackeds_sites || null}
                      value={form.trackeds_sites || null}
                      noOptionsMessage={() => t("software_inventory.form_license.no_options")}
                    />
                    {formError.trackeds_sites &&
                      <div className="error">{formError.trackeds_sites}</div>
                    }
                  </div>
                }

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => closeModal()}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t("software_inventory.form_license.cancel")}

                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t("software_inventory.form_license.save")}
                    </button>

                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalEdit));
