import React from 'react';

const IconRoomMostReservation = props => {
    return (

        <svg id="mais_locada" data-name="mais locada" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1279" data-name="Retângulo 1279" width="16" height="16" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0.666 0.667)">
                <path id="Caminho_300" data-name="Caminho 300" d="M4.566,14.667a.667.667,0,0,1-.467-.2A7.2,7.2,0,0,1,2,9.333a7.333,7.333,0,1,1,14.667,0,7.2,7.2,0,0,1-2.1,5.133.667.667,0,1,1-.933-.933,6,6,0,1,0-8.6,0,.633.633,0,0,1,0,.933A.667.667,0,0,1,4.566,14.667Z" transform="translate(-1.999 -2)" fill="#22bead" />
                <path id="Caminho_301" data-name="Caminho 301" d="M16.867,14.19,14.2,16.823a.7.7,0,0,0-.067.9.633.633,0,0,0,1,.067l1.533-1.533V23.99a.667.667,0,0,0,.667.667h0A.667.667,0,0,0,18,23.99V16.256l1.533,1.533a.633.633,0,0,0,1-.067.7.7,0,0,0-.067-.9L17.8,14.19a.633.633,0,0,0-.933,0Z" transform="translate(-10.001 -9.99)" fill="#22bead" />
            </g>
        </svg>


    )
}

export default IconRoomMostReservation;