import { downloadsConstants, shareConstants } from '../_constants';

export function share(state = {}, action) {

    switch (action.type) {

        case shareConstants.OPEN_MODAL:
            return {
                modal: true
            };

        case shareConstants.CLOSE_MODAL:
            return {
                modal: false
            };

        case shareConstants.OPEN_MODAL_FORM_GOALS:
            return {
                modal: true
            };

        case shareConstants.CLOSE_MODAL_FORM_GOALS:
            return {
                modal: false
            };

        case shareConstants.OPEN_MODAL_ADD_TEAM:
            return {
                ...state,
                modalAddTeam: true
            };

        case shareConstants.CLOSE_MODAL_ADD_TEAM:
            return {
                ...state,
                modalAddTeam: false
            };

        case shareConstants.OPEN_MODAL_ADD_KEY_RESULT:
            return {
                ...state,
                modalAddKeyResult: true
            };

        case shareConstants.CLOSE_MODAL_ADD_KEY_RESULT:
            return {
                ...state,
                modalAddKeyResult: false
            };
        
        case shareConstants.OPEN_MODAL_EDIT_KEY_RESULT:
            return {
                ...state,
                modalEditKeyResult: true
            };

        case shareConstants.CLOSE_MODAL_EDIT_KEY_RESULT:
            return {
                ...state,
                modalEditKeyResult: false
            };

        case shareConstants.GET_FILE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case shareConstants.GET_FILE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                loading: false
            };
        case shareConstants.GET_FILE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };

        default:
            return state
    }
}
