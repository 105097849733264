import React from 'react';

const IconOffline = props => {
    return (
        
     
        <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="21.008" height="21" viewBox="0 0 21.008 21">
        <path id="Caminho_25" data-name="Caminho 25" d="M6.95,24H5a1,1,0,0,1-1-1V4A1,1,0,0,1,5,3H21a1,1,0,0,1,1,1V7.95A1.05,1.05,0,0,1,21.15,9,1,1,0,0,1,20,8V5H6V22H7a1,1,0,0,1,1,1.15A1.05,1.05,0,0,1,6.95,24Z" transform="translate(-4 -3)" />
        <rect id="Retângulo_29" data-name="Retângulo 29" width="9" height="2" rx="1" transform="translate(4.5 5.5)" />
        <path id="Caminho_26" data-name="Caminho 26" d="M29.2,22.192l-4.9-4.9a.95.95,0,0,0-1.4,0l-2.2,2.2H14a1,1,0,1,0,0,2h4.7l-2,2H14a1,1,0,1,0,0,2h1.5v4.5a1,1,0,0,0,1,1h4.9a1,1,0,0,0,.7-.3l7.1-7.1a.95.95,0,0,0,0-1.4Zm-8.25,6.8H17.5v-3.45l3.2-3.2,3.45,3.45Zm4.6-4.6-3.45-3.45,1.5-1.55,3.5,3.5Z" transform="translate(-8.5 -9.992)" />
        </svg>
      


    )
}

export default IconOffline;