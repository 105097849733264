import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import IconInfo from "../_icons/IconInfo";
import IconClose from "../_icons/IconClose";

import { api } from "../../_helpers/api";
import { Backdrop, CircularProgress, IconButton, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader, enumerateYearsBetweenDates, getOptionsPeriods, getValuePeriod } from "../../_helpers";
import { Switch } from '@mui/material';
import "./ModalCreateDaysOff.scss"
import moment from "moment";
import { BefectiveDatePicker } from "../../_components/goals/BefectiveDatePicker";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalCreateDaysOff({
  openModalCreateDaysOff,
  setOpenModalCreateDaysOff,
  userDetails,
  teammates,
  alertActions,
  updateInfo,
  datesDefault,
  setDatesDefault,
  viewCalendar
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [colabs, setColabs] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    type: '',
    date_start: datesDefault !== null ? moment(datesDefault.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    date_end: datesDefault !== null ? moment(datesDefault.end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    users: [],
    description: ''
  });
  const [formError, setFormError] = useState({
    type: '',
    date_start: '',
    date_end: '',
    users: '',
    description: ''
  });

  const token = authHeader()

  const teammatesOptions = [
    {
      value: 'all_colabs',
      label: t('settings.daysoff_settings.modal_create.all')
    },
    ...teammates
  ]


  const handleChange = e => {

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    setFormError({
      ...formError,
      [e.target.name]: '',
    })

  }

  function closeModalFormDaysOff() {
    setOpenModalCreateDaysOff(false)
    setForm({
      type: '',
      date_start: moment().format('YYYY-MM-DD'),
      date_end: moment().format('YYYY-MM-DD'),
      users: [],
      description: ''
    })
    setFormError({
      type: '',
      date_start: '',
      date_end: '',
      users: '',
      description: ''
    })
    setColabs([])
    setDatesDefault(null)
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.type) {
      errors.type = t("validation.required")
    }

    if (form.type != '1' && form.users.length === 0) {
      errors.users = t("validation.required")
    }

    if (!form.date_start) {
      errors.date_start = t("validation.required")
    } else if (form.date_start > form.date_end) {
      errors.date_start = t("validation.time_invalid")
    }

    if (!form.date_end) {
      errors.date_end = t("validation.required")
    } else if (form.date_end < form.date_start) {
      errors.date_end = t("validation.time_invalid")
    }

    // if (!form.description) {
    //   errors.description = t("validation.required")
    // }

    return errors
  }

  const handleSubmitDaysOff = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createDaysOff = async () => {
    try {
      setIsSubmitting(true)
      let users = [];

      if(form.users.find(u => u.value === 'all_colabs')){
        users = form.type == "1" ? undefined : teammatesOptions.map(t => {
          if(t.value !== "all_colabs"){
            return t.value
          }
        }).join(',')
      }else{
        users = form.users.map(u => u.value).join(',')
      }

      const payload = {
        users: users,
        type: form.type,
        start: form.date_start,
        end: form.date_end,
        description: form.description
      }

      await api.post(`/settings/free-days`, payload, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      updateInfo()
      alertActions.success(t('settings.daysoff_settings.success.create_days_off'))
      closeModalFormDaysOff()
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      // alertActions.error(t('goals.errors.create_goal'))
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createDaysOff()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    if (viewCalendar === true) {
      setForm({
        ...form,
        date_start: datesDefault !== null ? moment(datesDefault.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        date_end: datesDefault !== null ? moment(datesDefault.end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      })
    }
  }, [datesDefault])

  const handleChangeUsers = (values) => {
    const containsAllColabs = values.find(v => v.value == 'all_colabs')
    if(containsAllColabs){
      setForm({
        ...form,
        users: [containsAllColabs]
      })
      setFormError({ ...formError, users: '' })
      return
    }
    setForm({
      ...form,
      users: values
    })
    setFormError({ ...formError, users: '' })
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormCreateDaysOff ${classes.modal}`}
        open={openModalCreateDaysOff}
        onClose={closeModalFormDaysOff}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalCreateDaysOff} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormCreateDaysOff"}>

            <div style={{ position: 'absolute', right: 12, top: 12, zIndex: 5 }}>
              <IconButton aria-label="close" onClick={closeModalFormDaysOff} size="small">
                <Close fontSize="medium" />
              </IconButton>
            </div>

            <form onSubmit={handleSubmitDaysOff}>
              <main>
                <header>
                  <h3>
                    {t('settings.daysoff_settings.modal_create.title')}
                  </h3>
                </header>
                <div className="fieldset">
                  <div className="col">
                    <label htmlFor="type">{t('settings.daysoff_settings.modal_create.field_type')}</label>
                    <select name="type" id="type" value={form.type} onChange={handleChange}>
                      <option value="" selected hidden>{t('settings.daysoff_settings.modal_create.select')}</option>
                      <option value="1">{t("settings.daysoff_settings.types.national_holiday")}</option>
                      <option value="2">{t("settings.daysoff_settings.types.state_holiday")}</option>
                      <option value="3">{t("settings.daysoff_settings.types.municipal_holiday")}</option>
                      <option value="4">{t("settings.daysoff_settings.types.vacations")}</option>
                      <option value="5">{t("settings.daysoff_settings.types.dayoff")}</option>
                      <option value="6">{t("settings.daysoff_settings.types.absence")}</option>
                    </select>

                    {formError.type &&
                      <div className="error">{formError.type}</div>
                    }
                  </div>

                  <div className="col w-50">
                    <label htmlFor="date_start">{t('settings.daysoff_settings.modal_create.field_from')}</label>

                    <BefectiveDatePicker
                      userDetails={userDetails}
                      date_value={form.date_start}
                      formEdit={form}
                      setFormEdit={({ date_start }) => {
                        setForm({
                          ...form,
                          date_start: date_start
                        })
                        setFormError({
                          ...formError,
                          date_start: ''
                        })
                      }}
                      setSubmitted={setSubmitted}
                      field="date_start"
                      // minDate={moment().format('YYYY-MM-DD')}
                      className={'deadlineBoard'}
                    />
                    {(formError.date_start) &&
                      <div className="error">{formError.date_start}</div>
                    }
                  </div>

                  <div className="col w-50">
                    <label htmlFor="date_end">{t('settings.daysoff_settings.modal_create.field_to')}</label>
                    <BefectiveDatePicker
                      userDetails={userDetails}
                      date_value={form.date_end}
                      formEdit={form}
                      setFormEdit={({ date_end }) => {
                        setForm({
                          ...form,
                          date_end: date_end
                        })
                        setFormError({
                          ...formError,
                          date_end: ''
                        })
                      }}
                      setSubmitted={setSubmitted}
                      field="date_end"
                      // minDate={moment().format('YYYY-MM-DD')}
                      className={'deadlineBoard'}
                    />
                    {(formError.date_end) &&
                      <div className="error">{formError.date_end}</div>
                    }
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="users">{t('settings.daysoff_settings.modal_create.field_colabs')}</label>
                  <Select
                    id='users'
                    isMulti
                    defaultValue={form.users || null}
                    value={form.users || null}
                    name="users"
                    options={teammatesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('settings.daysoff_settings.modal_create.placeholder_colabs')}
                    onChange={handleChangeUsers}
                    isOptionDisabled={() => form.users.find(u => u.value == 'all_colabs')}
                  />

                  {formError.users &&
                    <div className="error">{formError.users}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="description">{t('settings.daysoff_settings.modal_create.field_description')}</label>
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="4"
                    value={form.description || ''}
                    onChange={handleChange}
                    placeholder={t('settings.daysoff_settings.modal_create.placeholder_description')}
                  />
                  {formError.description &&
                    <div className="error">{formError.description}</div>
                  }
                </div>

                <div className="infoText">{t('settings.daysoff_settings.modal_create.info')}</div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={closeModalFormDaysOff}
                    >
                      {t('settings.daysoff_settings.modal_create.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('settings.daysoff_settings.modal_create.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalCreateDaysOff));
