import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './styles.scss';


function TagsList(props) {

	const tags = props.tags || []
	const all = props.all || false

	return (
		<div className="tagsBoxComponent">
			{tags?.length > 0
				? (
					tags.length > 2 && !all ? (
						<div style={{ display: 'flex', gap: '4px', flexWrap:'wrap' }}>
							<span>{tags[0]}</span>
							<span>{tags[1]}</span>
							<span>+{tags.length - 2}</span>
						</div>
					) : (
						tags.map((tag, index) => (<span key={index}>{tag}</span>))
					)
				)
				: 'No tags'
				// : t('goals.no_goal')
			}

		</div>
	)


};

function mapStateToProps(state) {

	return {

	}
}

function mapDispatchToProps(dispatch) {
	return {

	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsList));
