import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink, Link } from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'

import { useTranslation, initReactI18next } from "react-i18next";

import { downloadsActions } from '../_actions';

import IconMac from '../_components/_icons/IconMac';
import IconWindows from '../_components/_icons/IconWindows';

function PublicDownloadPage(props) {
    const { t, i18n } = useTranslation('common');

    const { downloadsActions } = props;

    let userDetails = props.userDetails || [];

    let token = props.token || {};

    function download(type) {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const queryParams = Object.fromEntries(urlSearchParams.entries());

        const base64Url = queryParams.t.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const organization = JSON.parse(window.atob(base64));

        let filename

        if (type == "windows" || type == "old" || type == "mac") { filename = "Befective_" + organization.organization_name }
        if (type == "ad") { filename = "Befective_" + organization.organization_name + "_AD" }
        if (type == "group") { filename = "Befective_CreateGroup_AD" }

        if (organization.team) { filename += '_' + organization.team }

        downloadsActions.getDownloads(type, filename, queryParams.t)

    }

    useEffect(() => {     

        const path = window.location.pathname.split("/") || [];
        if (path) {
            switch (path[3]) {
                case 'windows':
                    download("windows")
                    break;
                case 'mac':
                    download("mac")
                    break;
                default:
                    download("windows")
                    break;
            }           
        }


    }, [])

    return (
        <React.Fragment>


        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        downloadsActions: bindActionCreators(downloadsActions, dispatch),

    }
}

function mapStateToProps(state) {

    return {
        filter: state.filter,
        loading: state.downloads.loading
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PublicDownloadPage)