import React from 'react';

const IconSearch = props => {

    const {width, height} = props;

    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width :"10"} height={height ? height :"10"} viewBox="0 0 10 10.002">
        <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M9.864,8.647,7.917,6.7a.468.468,0,0,0-.332-.137H7.266a4.061,4.061,0,1,0-.7.7v.318a.468.468,0,0,0,.137.332L8.647,9.864a.467.467,0,0,0,.662,0l.553-.553A.471.471,0,0,0,9.864,8.647Zm-5.8-2.084a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,4.063,6.563Z" fill="#4f7091"/>
        </svg>


    )
}

export default IconSearch;