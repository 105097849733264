import config from 'config';
import { authHeader } from '../_helpers';

export const backfectiveService = {
    getInvitations,
    getOrganizations,
    magicAgent
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getInvitations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/backfective/invitations`, requestOptions).then(handleResponse);
}


function getOrganizations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/backfective/organizations`, requestOptions).then(handleResponse);
}

function magicAgent(id) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{ 'Content-Type': 'application/json' }},
        body: JSON.stringify({ NewOrganization: id})
    };


    return fetch(`${config.apiNodeUrl}/auth/magic_agent`, requestOptions)
        .then(handleResponse)
        .then(user => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.accessToken){
                localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user)); 
                window.location.reload()
            }
            
            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {                                
     
                logout();
                location.reload(true);
                               
            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }
        
        return data;
    });
}