import React from 'react';

const IconSearchLight = props => {

    const { width, height } = props;

    return (


        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 16 16">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1379" data-name="Retângulo 1379" width="16" height="16" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(1.191 1.308)">
                <path id="Caminho_278" data-name="Caminho 278" d="M12.683,11.983a4.933,4.933,0,0,0,1-3.633,5.067,5.067,0,0,0-4.6-4.4,5.008,5.008,0,0,0-1,9.967,5.1,5.1,0,0,0,3.667-.967l4.167,4.133a.633.633,0,0,0,.933,0h0a.633.633,0,0,0,0-.933Zm-3.367.6a3.667,3.667,0,0,1-4.2-4.267,3.655,3.655,0,1,1,7.2,1.267,3.7,3.7,0,0,1-3,3Z" transform="translate(-3.574 -3.924)" fill="#98acc1" />
            </g>
        </svg>


    )
}

export default IconSearchLight;