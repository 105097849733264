// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oqjsxFF56GdiA5Xgzol98Q\\=\\= {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n  background-color: #FFFFFF;\n  border-radius: 4px;\n  padding: 18px;\n  min-height: 286px;\n}\n\n.TUsEU8JKCjgSEfcrsYMOFg\\=\\= {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.Xnws2emPumwqbPhitsUDsw\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 35px;\n}\n\n.DitmXAVeNKzqqha8KLKyrA\\=\\= {\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 0;\n}\n\n._2kFQHN7E9-1jq6xjsCVN0Q\\=\\= {\n  font-size: 24px;\n  margin: 4px;\n}\n\n._2kFQHN7E9-1jq6xjsCVN0Q\\=\\= span {\n  font-size: 24px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/_components/executive/Widget29/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;AAEF","sourcesContent":[".container {\n  display: flex;\n  gap: 16px;\n  flex-direction: column;\n  background-color: #FFFFFF;\n  border-radius: 4px;\n  padding: 18px;\n  min-height: 286px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.boxTitle {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 35px;\n}\n\n.title {\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 0;\n}\n\n.boxInfoStats{\n  font-size: 24px;\n  margin: 4px;\n}\n.boxInfoStats span{\n  font-size: 24px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "oqjsxFF56GdiA5Xgzol98Q==",
	"header": "TUsEU8JKCjgSEfcrsYMOFg==",
	"boxTitle": "Xnws2emPumwqbPhitsUDsw==",
	"title": "DitmXAVeNKzqqha8KLKyrA==",
	"boxInfoStats": "_2kFQHN7E9-1jq6xjsCVN0Q=="
};
export default ___CSS_LOADER_EXPORT___;
