import React from 'react';

const IconLogoBefective = () => {

  return (


    <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" version="1.1" viewBox="0 0 150 150">
      <g>
        <path style={{ fill: "#FDBE2B" }} className="fil1" d="M146.74 103.53l0 23.97 -45.71 26.48 -25.09 29.91c-0.45,0.54 -1.16,0.73 -1.82,0.49 -0.66,-0.24 -1.08,-0.84 -1.08,-1.55l0 -13.03 -20.5 -11.88 94.21 -54.39zm-73.37 -103.53l21.02 12.18 -94.39 54.5 0 -24.18 73.03 -42.3 0.34 -0.2zm42.05 24.35l21.02 12.18 -136.44 78.77 0 -24.32 115.42 -66.63zm31.32 30.54l0 24.31 -115.23 66.53 -21.02 -12.18 136.26 -78.67z" />
        <path style={{ fill: "#F9AE28" }} className="fil2" d="M73.03 24.51l21.36 -12.33 -21.02 -12.18 -0.34 0.2 0 24.31zm73.71 79.02l-73.71 42.56 0 23.71 0 13.03c0,0.71 0.42,1.31 1.08,1.55 0.66,0.24 1.37,0.05 1.82,-0.49l25.09 -29.91 45.71 -26.48 0 -23.97zm0 -48.63l-73.71 42.56 0 24.31 73.71 -42.56 0 -24.31zm-31.32 -30.54l-42.39 24.47 0 24.31 63.41 -36.61 -21.02 -12.18z" />
      </g>
    </svg>
  )
}

export default IconLogoBefective;