import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './TableRoomReportsAdmin.scss';


export default function TableRoomReportsAdmin({
  data = []
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsPointsAdmin' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>{t('spaces.collaborator')}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}> {t('spaces.most_room')}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t('spaces.total_reservations')}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t('spaces.canceled_reservations')}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t('spaces.most_day')}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t('spaces.average')}</TableCell>
            {/* <TableCell width={'30px'} /> */}
          </TableRow>
        </TableHead>
        <TableBody>

          {
          data.length > 0 ?
          
          data.map((row, index) => (
            <TableRow key={row.mu_id} className={`row-table`}>
              <TableCell>
                {row?.username}
              </TableCell>
              <TableCell>{row?.most_room_reservation}</TableCell>
              <TableCell align="center">{row?.total_reservations}</TableCell>
              <TableCell align="center">{row?.total_reservations_cancelled}</TableCell>
              <TableCell align="center">{t(`settings.teams.${row?.most_day_reservation}`)}</TableCell>
              <TableCell align="center">{row?.avg_hours_reservation}</TableCell>

              {/* <TableCell>
                <IconButton
                  className={`button-options`}
                  aria-label="expand row"
                  size="small"
                >
                  <ArrowRight size={16} />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))
          
          :

          <TableRow><TableCell style={{ cursor: 'auto', fontSize:'12px' }} colSpan={6}>{t('spaces.no_reports')}</TableCell></TableRow>

          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
