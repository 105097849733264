import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { CalendarCheck, XCircle } from 'phosphor-react';
import { toast } from 'react-hot-toast';
import { Form } from '../../_components/rooms_page/reservationForm/Form';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';

function RoomReservation(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, teammates, teammatesActions, alertActions } = props

  const { id } = useParams()


  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  async function getRoomDetail() {
    try {
      const response = await api.get(`rooms/`)
    } catch (error) {
      console.log(error.response)
    }
  }

  const showToast = (date, times, start_time, end_time) => {
    toast(
      (tt) => (
        <Grid container position={'relative'} p={'4px'}>
          <Box position={'absolute'} top={'-3px'} right={'-8px'}>
            <IconButton size='small' onClick={() => toast.dismiss(tt.id)}>
              <XCircle size={16} color="#106276" />
            </IconButton>
          </Box>
          <Grid item xs={12} display={'flex'} gap="10px">
            <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Box className={style.toastCustomIcon}>
                <CalendarCheck color="#22BEAD" />
              </Box>
            </Grid>
            <Grid item xs={9} alignItems="center" justifyContent="center">
              <Typography component={'h6'} sx={{ color: '#22BEAD', fontSize: 14, fontWeight: 500, marginBottom: '2px' }}>
                {t('spaces.reserved')}
              </Typography>
              <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>{moment(date).format('dddd, DD')}</Typography>
              {times.length > 0 ? (
                times.map((item, index) => (
                  <Typography key={index} component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>{item.start_time} às {item.end_time}</Typography>
                ))
              ) : (
                <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>{start_time} às {end_time}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>),
      {
        duration: 4000,
        style: {
          borderRadius: '4px',
          background: '#DBF5F2',
          color: '#fff',
          minWidth: '250px',
          minHeight: '90px',
          padding: '0px'
        }
      }
    );
  }

  useEffect(() => {
    teammatesActions.getTeammates()
  }, [])

  // useEffect(() => { 

  // }, [id])
  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          <Form userDetails={userDetails} teammates={teammates} alertActions={alertActions} showToast={showToast} current_user_id={Number(userDetails.id)} />

        </div>
        {/* PAGE ENDS */}

      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomReservation)