import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";

import IconInfo from '../_icons/IconInfo';
import IconTeam from '../_icons/IconTeam';
import IconTeammate from '../_icons/IconTeammate';

function WorkingAwesomely(props) {
    const {t, i18n} = useTranslation('common');

    const today = props.today || {};
    
    let ProductiveTeamsPercent = 0;
    let ProductiveMachinesPercent = 0;

    if (today.TotalProductiveTeams && today.TotalProductiveTeams != 0) {
        ProductiveTeamsPercent = Math.round((today.TotalProductiveTeams / today.TotalTeams) * 100);
    }

    if (today.TotalProductiveMachines && today.TotalProductiveMachines != 0) {
        ProductiveMachinesPercent = Math.round((today.TotalProductiveMachines / today.TotalMachines) * 100);
    }


    return (
        <div className="box medium">
            {props.productivity &&
                <h3>{t('home.working_awesomely')}<span><IconInfo/><div
                    className="tooltip">{/*<IconInfo/>*/}{t('home.working_awesomely_tooltip')}</div></span></h3>
            }
            {!props.productivity &&
                <h3><Link to="/productive/reports/teammates/">{t('home.working_awesomely')}<span><IconInfo/><div
                    className="tooltip">{/*<IconInfo/>*/}{t('home.working_awesomely_tooltip')}</div></span></Link></h3>
            }
            <div className="statsBlock">
                <div className="secondaryChart">
                    {props.productivity &&
                        <React.Fragment>
                            <div className="block">
                                <div className="icon">
                                    <IconTeam/>
                                </div>
                                <div className="stats">
                                    <span>{t('home.teams_working_awesomely')}</span>

                                    <div className="barchart">
                                        <div className="bar" style={{width: ProductiveTeamsPercent + '%'}}></div>
                                    </div>
                                </div>
                                <div className="number">
                                    {today.TotalProductiveTeams}/{today.TotalTeams || 0}
                                </div>
                            </div>


                            <div className="block work">
                                <div className="icon">
                                    <IconTeammate/>
                                </div>
                                <div className="stats">
                                    <span>{t('home.teammates_working_awesomely')}</span>

                                    <div className="barchart">
                                        <div className="bar" style={{width: ProductiveMachinesPercent + '%'}}></div>
                                    </div>
                                </div>
                                <div className="number">
                                    {today.TotalProductiveMachines}/{today.TotalMachines || 0}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {!props.productivity &&
                        <React.Fragment>
                            <Link to="/productive/reports/teams">
                                <div className="block">
                                    <div className="icon">
                                        <IconTeam/>
                                    </div>
                                    <div className="stats">
                                        <span>{t('home.teams_working_awesomely')}</span>

                                        <div className="barchart">
                                            <div className="bar" style={{width: ProductiveTeamsPercent + '%'}}></div>
                                        </div>
                                    </div>
                                    <div className="number">
                                        {today.TotalProductiveTeams}/{today.TotalTeams || 0}
                                    </div>


                                </div>
                            </Link>
                            <Link to="/productive/reports/teammates">
                                <div className="block work">
                                    <div className="icon">
                                        <IconTeammate/>
                                    </div>
                                    <div className="stats">
                                        <span>{t('home.teammates_working_awesomely')}</span>

                                        <div className="barchart">
                                            <div className="bar" style={{width: ProductiveMachinesPercent + '%'}}></div>
                                        </div>
                                    </div>
                                    <div className="number">
                                        {today.TotalProductiveMachines}/{today.TotalMachines || 0}
                                    </div>
                                </div>
                            </Link>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
};


function mapStateToProps(state) {

    return {
        today: state.stats.payload
    }

}

export default connect(mapStateToProps)(WorkingAwesomely)
