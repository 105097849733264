export const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 13,
  },
  highlighter: {
    overflow: "hidden",
  },
  '&multiLine': {
    control: {
      fontFamily: 'Roboto',
      minHeight: 51,
    },
    highlighter: {
      padding: 14,
      paddingTop: 17,
      fontSize: 13,
      border: '1px solid transparent',
    },
    input: {
      padding: 14,
      paddingTop: 17,
      fontSize: 13,
      border: '1px solid #D6DEEB',
      borderRadius: '2px',
      fontFamily: 'Roboto',
      color: '#1E6B7F',
      outline: 0,
    },
  },
  suggestions: {
    backgroundColor: '#195265',
    borderRadius: '4px',
    list: {
      backgroundColor: '#195265',
      fontSize: 16,
      color: '#fff',
      marginTop: '3px',
      height: "130px",
      overflowY: "scroll",
      borderRadius: '4px'
    },
    item: {
      padding: '5px 15px',
      backgroundColor: '#195265',
      '&focused': {
        backgroundColor: '#205B6F',
      },
    },
  },
}