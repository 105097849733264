import { Backdrop, Box, Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Slide, TextField, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { alertActions } from "../../_actions";
import { api } from "../../_helpers/api";
import { authHeader } from "../../_helpers/auth-header";
import IconClose from "../_icons/IconClose";
import "./MembersCardModal.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));


function MembersCardModal({
  openModalMembers,
  setOpenModalMembers,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo,
  currentCard,
  members
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [member, setMember] = useState(null)
  const [memberError, setMemberError] = useState(false)

  function closeModalMembersCard() {
    setOpenModalMembers(false)
    setMember(null)
    updateInfo(false)
  }

  // CARDS
  const addMemberCard = async (e) => {
    try {
      e.preventDefault()
      if (!member) {
        setMemberError(true)
        return
      }
      setIsSubmitting(true)
      await api.put(`/project-management/boards/lists/cards/${currentCard.id}/members`,
        {
          mu_id: member.value
        },
        {
          headers: authHeader()
        })
      setIsSubmitting(false)
      await updateInfo()
      setMember(null)
      alertActions.success(t('project_management.success.member_add'))
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('project_management.errors.member_add'))
    }
  }

  const removeMemberCard = async (id) => {
    try {


      await api.put(`/project-management/boards/lists/cards/${currentCard.id}/members`,
        {
          mu_id: id
        },
        {
          headers: authHeader()
        })
      await updateInfo()
      alertActions.success(t('project_management.success.member_delete'))
    } catch (error) {
      //alertActions.error(t('project_management.errors.member_delete'))
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalMembersCard ${classes.modal}`}
        open={openModalMembers}
        onClose={closeModalMembersCard}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalMembers} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalMembersCard"}>

            <button onClick={() => { closeModalMembersCard() }} className="close"><IconClose /></button>

            <form onSubmit={addMemberCard}>
              <main>
                <header>
                  <Typography variant="h5">{t('project_management.modal_members.title')}</Typography>
                </header>
                <Box padding={'10px 0px 0px'} display={'flex'} style={{ gap: '10px' }}>
                  <Autocomplete
                    id="country-select-demo"
                    options={colabsOptions.length > 0 ? colabsOptions.filter(co => !members?.map(m => m.id).includes(co.value)) : []}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <Box>
                        <Box>{option.label}</Box>
                        <Box>
                          <Typography variant="caption">{option.email}</Typography>
                        </Box>
                      </Box>
                    )}
                    style={{ width: '80%' }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={t('project_management.modal_members.select')}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        error={memberError}
                      />
                    )}
                    value={member}
                    onChange={(event, newValue) => {
                      setMember(newValue);
                      setMemberError(false)
                    }}
                  />
                  <Button type="submit" disabled={isSubmitting || !member} variant="contained" color="primary" style={{ textTransform: 'initial', width: '20%' }} disableElevation>
                    <span style={{ fontSize: '12px' }}>{t('project_management.modal_members.add')}</span>
                  </Button>
                </Box>

                <Box>
                  <List>
                    {
                      (members !== null && members?.length > 0) 
                      ?
                      members?.map(member => (
                        <>
                          <ListItem
                            style={{ paddingLeft: '6px' }}
                            key={member.email}
                          >
                            <ListItemText style={{ marginTop: '2px', marginBottom: '2px' }} primary={member.username} secondary={member.email} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="delete-member" onClick={() => removeMemberCard(member.id)}>
                                <Delete />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider component="li" />
                        </>
                      ))
                      :
                      <ListItem
                        style={{ paddingLeft: '6px' }}
                      >
                        <ListItemText style={{ marginTop: '2px', marginBottom: '2px' }} primary={<Typography variant="caption" component={'span'}>{t("project_management.modal_members.none")}</Typography>}/>
                      </ListItem>
                    }
                  </List>
                </Box>

              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersCardModal));
