import React from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import IconEdit2 from '../../_components/_icons/IconEdit2';
import IconRemove from '../../_components/_icons/IconRemove';
import { authHeader, befectiveFormatDate, getDayWeek, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import WorkspaceTypeCreateModal from '../../_components/project_management/WorkspaceTypeCreateModal';
import WorkspaceTypeEditModal from '../../_components/project_management/WorkspaceTypeEditModal';


function ProjectManagementWorkspaceTypesPage(props) {

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props

  const [workspaceTypes, setWorkspaceTypes] = useState([])
  const [workspaceTypeIdToDelete, setWorkspaceTypeIdToDelete] = useState(null)
  const [workspaceTypeToEdit, setWorkspaceTypeToEdit] = useState(null)
  const [openModalCreateWorkspaceType, setOpenModalCreateWorkspaceType] = useState(false);
  const [openModalEditWorkspaceType, setOpenModalEditWorkspaceType] = useState(false);


  const [anchorElConfirmDeleteWorkspaceType, setAnchorElConfirmDeleteWorkspaceType] = useState(null);

  const handleClickConfirmDeleteWorkspaceType = (event, attId) => {
    setWorkspaceTypeIdToDelete(attId)
    setAnchorElConfirmDeleteWorkspaceType(event.currentTarget);
  };

  const handleClickToEditWorkspaceType = (event, att) => {
    event.preventDefault()
    setWorkspaceTypeToEdit(att)
    setOpenModalEditWorkspaceType(true)
  }

  const handleCloseConfirmDeleteWorkspaceType = () => {
    setWorkspaceTypeIdToDelete(null)
    setAnchorElConfirmDeleteWorkspaceType(null);
  };

  const openConfirmDeleteWorkspaceType = Boolean(anchorElConfirmDeleteWorkspaceType);
  const idConfirmDeleteWorkspaceType = openConfirmDeleteWorkspaceType ? 'simple-popover-2' : undefined;

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getWorkspaceTypes = async () => {
    try {

      const response = await api.get(`/project-management/workspace-types`, {
        headers: authHeader()
      })
      setWorkspaceTypes(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  const onDeleteWorkspaceType = async () => {
    try {

      const res = await api.delete(`/project-management/workspace-types/${workspaceTypeIdToDelete}`, { headers: authHeader() })
      handleCloseConfirmDeleteWorkspaceType()
      getWorkspaceTypes()
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.delete'))
    }
  }

  useEffect(() => {

    if (openModalEditWorkspaceType === false) {
      setWorkspaceTypeToEdit(null)
    }
  }, [openModalEditWorkspaceType])



  useEffect(() => {
    document.title = 'Befective | ' + t('spaces.settings');
    getWorkspaceTypes()
  }, [])


  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          tabSettingsWorkspaceTypes={true}
          createWorkspaceType={true}
          setOpenModalCreateWorkspaceType={setOpenModalCreateWorkspaceType}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          <div className={style.containerTable}>
            <table>
              <thead>
                <tr>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.title')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.updated_at')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.created_by')}
                  </th>
                  <th
                    style={{ width: '4%', textTransform: 'uppercase' }}
                  >
                    {t('spaces.columns.actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  workspaceTypes?.length > 0 ?
                    workspaceTypes.map((wt, index) => {
                      return (
                        <tr key={index} style={{cursor:'pointer'}}>
                          <td onClick={(e) => handleClickToEditWorkspaceType(e, wt)}>{wt?.title}</td>
                          <td onClick={(e) => handleClickToEditWorkspaceType(e, wt)}>{befectiveFormatDate(wt?.updated_at, i18n.language, 'T')}</td>
                          <td onClick={(e) => handleClickToEditWorkspaceType(e, wt)}>{wt?.created_by_name}</td>
                          <td>
                            <div className={style.boxActions}>
                              <button className={`${style.buttonAction} ${style.permissions}`} onClick={(e) => handleClickToEditWorkspaceType(e, wt)}>
                                <IconEdit2 />
                              </button>
                              <button className={`${style.buttonAction} ${style.remove}`} onClick={(e) => handleClickConfirmDeleteWorkspaceType(e, wt.id)}>
                                <IconRemove />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })

                    :
                    <tr><td colSpan={5}>{t('project_management.workspace_types.no_data')}</td></tr>
                }
              </tbody>
            </table>

          </div>

        </div>
        {/* PAGE ENDS */}

        <Popover
          id={idConfirmDeleteWorkspaceType}
          open={openConfirmDeleteWorkspaceType}
          anchorEl={anchorElConfirmDeleteWorkspaceType}
          onClose={handleCloseConfirmDeleteWorkspaceType}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              backgroundColor: "#FFE3E5",
              boxShadow: "none",
              padding: '18px 14px',
              borderRadius: '6px',
            }
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

            <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmDeleteWorkspaceType}>
                <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
              </Button>
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteWorkspaceType}>
                <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
              </Button>
            </Box>
          </Box>
        </Popover>

      </section>

      <WorkspaceTypeCreateModal
        openModalCreateWorkspaceType={openModalCreateWorkspaceType}
        setOpenModalCreateWorkspaceType={setOpenModalCreateWorkspaceType}
        userDetails={userDetails}
        alertActions={alertActions}
        updateInfo={getWorkspaceTypes}
      />

      {
        workspaceTypeToEdit &&
        <WorkspaceTypeEditModal
          openModalEditWorkspaceType={openModalEditWorkspaceType}
          setOpenModalEditWorkspaceType={setOpenModalEditWorkspaceType}
          userDetails={userDetails}
          alertActions={alertActions}
          updateInfo={getWorkspaceTypes}
          workspaceTypeToEdit={workspaceTypeToEdit}
        />
      }
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementWorkspaceTypesPage)