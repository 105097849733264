import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useTranslation, initReactI18next } from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function HistorySkeleton(props) {
    const { t, i18n } = useTranslation('common');

    return (


        <div className="box loading noBg">
            <div className="dataTable">
                <Skeleton variant="rect" width="100%" height={447} />
            </div>
            <div className="dataTable">
                <Skeleton variant="rect" width="100%" height={447} />
            </div>
        </div>
    )
};

export default HistorySkeleton;