import React from 'react';

const IconThreePoints = props => {

    const { width, height } = props;

    return (


        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "11"} height={height ? height : "20"}viewBox="0 0 11 20">
            <g id="_..." data-name="..." transform="translate(865 -823)">
                <rect id="Retângulo_1221" data-name="Retângulo 1221" width="11" height="20" rx="4" transform="translate(-865 823)" fill="#4F7091" opacity="0" />
                <path id="_...-2" data-name="..." d="M8.3,14.679a1.742,1.742,0,1,1-1.742-1.742A1.741,1.741,0,0,1,8.3,14.679Zm2.516-1.742a1.742,1.742,0,1,0,1.742,1.742A1.741,1.741,0,0,0,10.821,12.938Zm-8.516,0a1.742,1.742,0,1,0,1.742,1.742A1.741,1.741,0,0,0,2.3,12.938Z" transform="translate(-844.579 826.438) rotate(90)" fill="#4F7091aa" />
            </g>
        </svg>

    )
}

export default IconThreePoints;