import { backfectiveConstants } from '../_constants';

export function backfective(state = {}, action) {

    switch (action.type) {
        case backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_SUCCESS:
            return {
                ...state,
                invitations: action.payload,
                loading: false
            };

        case backfectiveConstants.GET_BACKFECTIVE_INVITATIONS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };
        case backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: action.payload,
                loading: false
            };

        case backfectiveConstants.GET_BACKFECTIVE_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };
        case backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_REQUEST:
            return {
                ...state,
                changed: '',
                loading: true
            };

        case backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_SUCCESS:
            return {
                ...state,
                changed: 'success',
                loading: false
            };

        case backfectiveConstants.POST_BACKFECTIVE_MAGIC_AGENT_FAILURE:
            return {
                ...state,
                error: action.error,
                changed: 'error',
                loading: true
            };
        default:
            return state
    }
}