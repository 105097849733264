import { Envelope } from 'phosphor-react';
import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd'
import { NavLink } from 'react-router-dom';
import IconConfig from '../../_icons/IconConfig';
import IconExpand from '../../_icons/IconExpand';
import IconTeammateDetail from '../../_icons/IconTeammateDetail';
import { ListColabItem } from './ListColabItem';

export function ListTeamItem({
  groups,
  expand,
  add,
  openDialogConfirm,
  currentGroup,
  prevGroupId,
  currentIndex,
  innerRef,
  provided,
  colabs,
  generateToken,
  snapshot
}) {
 
  return (
    <>
      <li
        ref={innerRef}
        id={"team" + currentGroup.id}
        className={
          currentIndex === '0'
            ? `t${currentIndex} team${currentGroup.id} teamList`
            : `t${currentIndex} team${prevGroupId} team${currentGroup.id} teamList`
        }
        style={{ 
          marginTop: currentIndex === '0' ? '4px' : '0', 
          display: currentIndex === '0' ? '' : 'none',
          paddingBottom: '15px',
          backgroundColor: snapshot?.isDraggingOver
            ? "#DEE3E9CC"
            : "", 
        }}
        
      >
        <div className="team">{currentGroup.name}</div>
        <div className="subteams">{currentGroup.subTeamsCount || 0}</div>
        <div className="teammates">{currentGroup.teammatesCount || 0}</div>
        <div className="email"></div>
        <div className="token"></div>
        <div className="actions">
          {(groups.find(x => x.ancestry == currentGroup.id) || colabs.find(colab => String(colab.team_id) === String(currentGroup.id))) && (
            <button
              className="expand"
              onClick={() => {
                expand(currentGroup.id, Number(currentIndex))
              }}
            >
              <IconExpand />
            </button>
          )}

          <button
            className="add"
            onClick={() => {
              add(currentGroup.id, 'team', currentGroup.name)
            }}
          >
            <IconTeammateDetail />
          </button>

          {currentGroup.teammatesCount > 0 && (
            <button
              className="invite"
              onClick={(e) => openDialogConfirm(e, currentGroup.id, 'group')}
            >
              <Envelope color='#187DFF' /></button>
          )}

          <NavLink
            to={"/settings/teams/" + currentGroup.id + "/configuration"}
          >
            <button className="config">
              <IconConfig />
            </button>
          </NavLink>
        </div>
        {
          provided.placeholder
        }
      </li>

      {colabs.map((teammate, index) => (
        teammate.team_id == currentGroup.id && (
          <Draggable key={teammate.id} draggableId={String(teammate.id)} index={index}>
            {(provided, snapshot) => (
              <ListColabItem
                key={teammate.id}
                add={add}
                openDialogConfirm={openDialogConfirm}
                teammate={teammate}
                currentIndex={currentIndex}
                generateToken={generateToken}
                unique_code={currentGroup.unique_code}
                currentGroupId={currentGroup.id}
                prevGroupId={prevGroupId}
                innerRef={provided.innerRef}
                provided={provided}
              />
            )}
          </Draggable>
        )
      ))}
    </>

  )
};
