import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../../_actions';

import IconShowPassword from '../../_icons/IconShowPassword'

function SettingsPasswordSkeleton(props) {
  const { t, i18n } = useTranslation('common');

  const { usersActions } = props

  let currentUser = props.userDetails || {}
  let updated = props.updated || false

  const [isVisibleCurrent, setVisibleCurrent] = useState(false);
  const [isVisibleNew, setVisibleNew] = useState(false);
  const [isVisibleConfirm, setVisibleConfirm] = useState(false);

  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({
    current: '',
    new: '',
    confirm: ''
  })

  const [formError, setFormError] = useState({
    current: false,
    new: false,
    confirm: false,
    notEqual: false
  })

  const handleChange = e => {
    setSubmitted(false)

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    let errorCurrent = false
    let errorNew = false
    let errorConfirm = false
    let errorNotEqual = false

    let formIsValid = true

    if (form.current.length <= 3) {
      formIsValid = false
      errorCurrent = t('validation.required')
    }

    if (form.new.length <= 7) {
      formIsValid = false
      errorNew = t('validation.required_min8')
    }

    if (form.confirm.length <= 7) {
      formIsValid = false
      errorConfirm = t('validation.required_min8')
    }

    if (form.new != form.confirm) {
      formIsValid = false
      errorNotEqual = t('validation.not_equal')
    }

    setFormError({
      current: errorCurrent,
      new: errorNew,
      confirm: errorConfirm,
      notEqual: errorNotEqual
    })


    if (formIsValid) {

      setFormError({
        current: false,
        new: false,
        confirm: false,
        notEqual: false
      })

      usersActions.updatePassword(form, currentUser.id)
    }
    else {
      setSubmitted(false)
    }

  }

  function cancel() {

    setFormError({
      current: false,
      new: false,
      confirm: false,
      notEqual: false
    })

    setForm({
      current: '',
      new: '',
      confirm: ''
    })
  }

  function showPassword(type = null) {

    if (type === "current") {
      setVisibleCurrent(!isVisibleCurrent);
    }
    if (type === "new") {
      setVisibleNew(!isVisibleNew);
    }
    if (type === "confirm") {
      setVisibleConfirm(!isVisibleConfirm);
    }

  };


  useEffect(() => {

    if (updated == 'error') {
      setSubmitted(false)
    }

    if (updated == 'success') {
      setSubmitted(false)
      setFormError({
        current: false,
        new: false,
        confirm: false,
        notEqual: false
      })

      setForm({
        current: '',
        new: '',
        confirm: ''
      })

    }

  }, [updated])



  return (
    <form onSubmit={handleSubmit} className="form">
      <h4>{t('profile.change_password')}</h4>
      <div className="rules">{t('profile.password_rules')}</div>
      <div className="field">
        <label htmlFor="passwordCurrent">{t('profile.current_password')}</label>
        <input id="passwordCurrent" name="current" value={form.current} onChange={handleChange} type={isVisibleCurrent ? 'text' : 'password'} />
        <button type="button" className="showPassword"  tabIndex="-1" onClick={() => { showPassword("current") }}>
          <IconShowPassword />
        </button>

      </div>
      {(formError.current) &&
        <div className="error">{formError.current}</div>
      }
      <div className="field">
        <label htmlFor="passwordNew">{t('profile.new_password')}</label>
        <input id="passwordNew" name="new" value={form.new} onChange={handleChange} type={isVisibleNew ? 'text' : 'password'} />
        <button type="button" className="showPassword"  tabIndex="-1" onClick={() => { showPassword("new") }}>
          <IconShowPassword />
        </button>

      </div>
      {(formError.new) &&
        <div className="error">{formError.new}</div>
      }
      <div className="field">
        <label htmlFor="passwordConfirm">{t('profile.confirm_password')}</label>
        <input id="passwordConfirm" name="confirm" value={form.confirm} onChange={handleChange} type={isVisibleConfirm ? 'text' : 'password'} />
        <button type="button" className="showPassword"  tabIndex="-1" onClick={() => { showPassword("confirm") }}>
          <IconShowPassword />
        </button>

      </div>
      {(formError.confirm) &&
        <div className="error">{formError.confirm}</div>
      }

      {(formError.notEqual) &&
        <div className="error">{formError.notEqual}</div>
      }
      <div className="field">
        {(submitted && (!updated)) &&
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
        <button>{t('profile.cancel')}</button>
        <button type="submit">{t('profile.update')}</button>
      </div>
    </form>
  )
};



function mapStateToProps(state) {

  return {
    auth: state.authentication.user,
    updated: state.users.updated,
    userDetails: state.authentication.userDetails,

  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsPasswordSkeleton));