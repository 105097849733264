import React from 'react';

const IconRoomAvailable = props => {
    return (

        <svg id="salas_disp" data-name="salas disp" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1589" data-name="Retângulo 1589" width="16" height="16" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2" transform="translate(1.333 1.333)">
                <path id="Caminho_1454" data-name="Caminho 1454" d="M14.2,20.933l-2-1.967a.7.7,0,0,1-.067-.9.633.633,0,0,1,1-.067l1.533,1.533,4.2-4.2a.667.667,0,0,1,.933.933l-4.667,4.667a.633.633,0,0,1-.933,0Z" transform="translate(-9.334 -11.467)" fill="#22bead" />
                <path id="Caminho_1455" data-name="Caminho 1455" d="M16,5.333V16H5.333V5.333H16M16.667,4h-12A.667.667,0,0,0,4,4.667v12a.667.667,0,0,0,.667.667h12a.667.667,0,0,0,.667-.667v-12A.667.667,0,0,0,16.667,4Z" transform="translate(-4 -4)" fill="#22bead" />
            </g>
        </svg>


    )
}

export default IconRoomAvailable;