import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';
import { CaretCircleDoubleDown, CaretCircleDoubleUp, Percent, Sun, Timer } from 'phosphor-react';
import { secondsToHm } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';

export function CardsHeaderAbsenteeism({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >

      <Box width={'100%'}>
        <h3 className='reportTitleGeneric'>
          {t('productive_reports.card_reports.absenteeism')}<span><IconInfo/><div className="tooltip">{t('productive_reports.card_reports.subtitle_absenteeism')}</div></span>
        </h3>
      </Box>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <Sun size={18} color="#4F7091" weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} my={1}>
            {stats.total_working_days || 0}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('reports_absenteeism.header_cards.total_working_days')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Sun size={18} color="#22BEAD" weight="bold" />
          <Typography color="#22BEAD" component="span" fontSize={20} my={1}>
            {stats.total_days_worked || 0}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('reports_absenteeism.header_cards.total_days_worked')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <Sun size={18} color="#FC6662" weight="bold" />
          <Typography color="#FC6662" component="span" fontSize={20} my={1}>
            {stats.total_not_worked_days || 0}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('reports_absenteeism.header_cards.total_not_worked_days')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: Number(stats.global_absenteeism_day_rate) <= 0 ? '2px solid #22BEAD' : '2px solid #FC6662' }}>
          {/* <Percent size={18} color={Number(stats.global_absenteeism_day_rate) <= 0 ? '#22BEAD' : '#FC6662'} weight="bold" /> */}
          {
            Number(stats.global_absenteeism_day_rate) <= 0
              ?
              <CaretCircleDoubleDown size={18} color='#22BEAD' weight="bold" />
              :
              <CaretCircleDoubleUp size={18} color='#FC6662' weight="bold" />
          }
          <Typography color={Number(stats.global_absenteeism_day_rate) <= 0 ? '#22BEAD' : '#FC6662'} component="span" fontSize={20} my={1}>
            {`${stats.global_absenteeism_day_rate}%`}
          </Typography>
          <Typography color={Number(stats.global_absenteeism_day_rate) <= 0 ? '#22BEAD' : '#FC6662'} component="span" fontSize={12}>
            {t('reports_absenteeism.header_cards.avg_absenteeism_rate')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
