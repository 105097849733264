import React from 'react'
import style from './styles.module.scss';
import { Box, Grid, Skeleton } from '@mui/material';

export default function Widget12Skeleton() {

  return (
    <div className={style.container}>
      <Box display={'flex'} marginBottom={'16px'}>
        <Skeleton variant='h6' width={'25%'} />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box display={'flex'} gap={'30px'}>
              <Skeleton variant='circular' width={180} height={180} />
              <Box display={'flex'} flexDirection={'column'} gap={'16px'} justifyContent={'center'} width={'60%'}>
                <Skeleton variant="rectangular" width={'100%'} height={40} />
                <Skeleton variant="rectangular" width={'100%'} height={40} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display={'flex'} gap={'30px'}>
              <Skeleton variant='circular' width={180} height={180} />
              <Box display={'flex'} flexDirection={'column'} gap={'16px'} justifyContent={'center'} width={'60%'}>
                <Skeleton variant="rectangular" width={'100%'} height={40} />
                <Skeleton variant="rectangular" width={'100%'} height={40} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

    </div>
  )
}
