import React from 'react'
import { befectiveFormatDate } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import style from './Note.module.scss'
import { TagsInput } from 'react-tag-input-component'
import { Checkbox, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, withStyles } from '@material-ui/core'
import { Delete, MoreVert } from '@material-ui/icons'
import EasyEdit from 'react-easy-edit';
import { CalendarCheck, Globe, Lock, Moon, Sun, Timer, UserCircle, Users } from 'phosphor-react'
import InputNewChecklistItem from './InputNewChecklistItem';

const GreenCheckbox = withStyles({
  root: {
    color: '#E4EAEF',
    "&$checked": {
      color: '#22BEAD'
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const iconsSharing = {
  private: <Lock size={16} weight='bold' color='#1E6B7F' />,
  manager: <UserCircle size={16} weight='bold' color='#1E6B7F' />,
  peers: <Users size={16} weight='bold' color='#1E6B7F' />,
  all: <Globe size={16} weight='bold' color='#1E6B7F' />
}

export default function Note({ isOwner, note, handleClickMore, handleClickSharing, handleUpdateItem, handleUpdateNote, handleDeleteItem, getData}) {

  const { t, i18n } = useTranslation('common');

  return (

    isOwner
      ?
      <div className={style.cardNote}>
        <header className={style.headerNote} style={{ backgroundColor: note.card_color || "#ffffff" }}>
          <div style={{ width: '100%' }}>
            <EasyEdit
              type={'text'}
              onSave={(value) => handleUpdateNote({ id: note.id, title: value, card_color: note.card_color, tags: note.tags, sharing: note.access_level, is_fetching: true })}
              onCancel={(value) => { }}
              attributes={{ title: "title", id: note.id }}
              value={note.title}
              placeholder={t('project_management.kanban.placeholder_title')}
              hideSaveButton
              hideCancelButton
              cancelOnBlur
              displayComponent={<h6>{note.title}</h6>}
            />
            <span style={{ lineHeight: '20px', fontSize: '12px' }}>
              {`${t("colab_page.checklist.updated_at")} ${befectiveFormatDate(note.updated_at, i18n.language, 'T')}`}<br />{`${t(`colab_page.checklist.created_by`)} ${t(`colab_page.checklist.me`)}`}<br />{`${t(`colab_page.checklist.completed`)}: ${note.total_items_done} / ${note.total_items}`}
            </span>
          </div>

          <div className={style.groupOptions}>
            {
              note.access_level &&
              <IconButton aria-label="options1" size='small' onClick={(event) => handleClickSharing(event, note)} title={t(`colab_page.checklist.modal_note.field_sharing`)+": "+t(`colab_page.checklist.modal_note.option_${note.access_level}`)}>
                {iconsSharing[note.access_level]}
              </IconButton>
            }
            <IconButton aria-label="options2" size='small' onClick={(event) => handleClickMore(event, note)}>
              <MoreVert fontSize='small' style={{ fill: '#1E6B7F' }} />
            </IconButton>
          </div>
        </header>
        <main className={style.mainNote}>
          <List style={{ padding: 0, overflow: 'auto', maxHeight: '300px' }}>
            {note.items.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;
              return (
                <div key={labelId}>
                  <ListItem key={`checkbox-list-label-${item.id}`} role={undefined} dense>
                    <ListItemIcon style={{ minWidth: 'auto' }}>
                      <GreenCheckbox
                        edge="start"
                        checked={item.done}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={(e) => handleUpdateItem({ id: item.id, title: item.title, done: e.target.checked, is_fetching: true })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        <EasyEdit
                          type={'text'}
                          onSave={(value) => handleUpdateItem({ id: item.id, title: value, done: item.done, is_fetching: true })}
                          onCancel={(value) => { }}
                          attributes={{ title: "title", id: item.id }}
                          value={item.title}
                          placeholder={t('project_management.kanban.placeholder_title')}
                          hideSaveButton
                          hideCancelButton
                          cancelOnBlur
                          displayComponent={<span style={{ textDecoration: item.done ? 'line-through' : 'none', fontWeight: 'normal' }}>{item.title}</span>}
                        />
                      }
                      style={{ color: '#1E6B7F', fontSize: '14px' }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" size="small" onClick={() => handleDeleteItem({ id: item.id })}>
                        <Delete fontSize="inherit" color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </main>
        
        <div style={{ padding: '4px 12px 8px', backgroundColor: '#ffffff' }}>
          <InputNewChecklistItem noteId={note.id} getData={getData} />
        </div>

        <Divider />

        <footer className={`${style.footerNote} tagBoxFooterNote`}>
          <TagsInput
            value={note.tags}
            onChange={e => {
              if (note.tags.join(',') !== e.join(','))
                handleUpdateNote({ id: note.id, title: note.title, card_color: note.card_color, tags: e, sharing: note.access_level })
            }}
            name="tags"
            id="tags"
            placeHolder={t('colab_page.checklist.modal_note.placeholder_tags')}
            classNames={{ tag: 'tag-cls', input: 'input-cls' }}
          />
        </footer>

      </div>
      :
      <div className={style.cardNote}>
        <header className={style.headerNote} style={{ backgroundColor: note.card_color || "#ffffff" }}>
          <div style={{ width: '100%' }}>
                <h6>{note.title}</h6>
            <span style={{ lineHeight: '20px', fontSize: '12px' }}>
              {`${t("colab_page.checklist.updated_at")} ${befectiveFormatDate(note.updated_at, i18n.language, 'T')}`}<br />{`${t(`colab_page.checklist.created_by`)} ${note.owner_username}`}<br />{`${t(`colab_page.checklist.completed`)}: ${note.total_items_done} / ${note.total_items}`}
            </span>
          </div>
        </header>
        <main className={style.mainNote}>
          <List style={{ padding: 0, overflow: 'auto', maxHeight: '300px' }}>
            {note.items.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;
              return (
                  <div key={labelId}>
                    <ListItem key={`checkbox-list-label-${item.id}`} role={undefined} dense>
                      <ListItemIcon style={{ minWidth: 'auto' }}>
                        <GreenCheckbox
                          edge="start"
                          checked={item.done}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={<span style={{ textDecoration: item.done ? 'line-through' : 'none', color: '#1E6B7F', fontWeight: 'normal' }}>{item.title}</span>} />
                    </ListItem>
                    <Divider />
                  </div>
              );
            })}
          </List>
        </main>

        <Divider />

        {
            note.tags.length > 0 &&
            <footer className={`${style.footerNote} tagBoxFooterNote`}>
              {
                note.tags.map((tag, index) => (
                  <div className={style.tagItem} key={`tag-${index}`} size='small'>{tag}</div>
                ))
              }
            </footer>
        }
      </div>
  )
}
