import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, Clock, ClockClockwise, ClockCounterClockwise, HourglassLow, Scales, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authHeader } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import style from './CardsHeader.module.scss';
import IconTeammate from '../../_icons/IconTeammate';
import IconSun from '../../_icons/IconSun';
import IconMoon from '../../_icons/IconMoon';
import IconLate from '../../_icons/IconLate';
import IconAnticipated from '../../_icons/IconAnticipated';

export function CardsHeaderReportsPoints({ alertActions, userDetails, resume }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
          <IconTeammate />
          <Typography color={"#22BEAD"} component="span" fontSize={20} mt={1}>
            {resume?.active_colabs_stats}
          </Typography>
          <Typography color={"#22BEAD"} component="span" fontSize={12}>
            {t('checkin.control_points.header_cards.active_colabs_stats')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <IconTeammate fill={"#4F7091"} />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {resume?.licence_colabs_stats}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
          {t('checkin.control_points.header_cards.licence_colabs_stats')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <IconSun />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.average_start_time}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
          {t('checkin.control_points.header_cards.average_start_time')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <IconMoon />
          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {resume?.average_end_time}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
          {t('checkin.control_points.header_cards.average_end_time')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <IconLate />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.input_late_stats}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
          {t('checkin.control_points.header_cards.input_late_stats')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <IconAnticipated />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.input_anticipated_stats}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
          {t('checkin.control_points.header_cards.input_anticipated_stats')}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
