import config from 'config';
import { authHeader } from '../_helpers';
const moment = require("moment");
export const teammatesService = {
    getTeammates,
    getTeammate,
    updateTeammate,
    deleteTeammate,
    inviteTeammate
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}


function getTeammates(teams) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const queryTeams = teams ? `teams=${teams}` : ''

    return fetch(`${config.apiNodeUrl}/teammates/?${queryTeams}`, requestOptions).then(handleResponse);
}

function getTeammate(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teammates/${id}?today=${moment().format("YYYY-MM-DD")}`, requestOptions).then(handleResponse);
}

function deleteTeammate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/teammates/` + id, requestOptions).then(handleResponse);
}

function updateTeammate(form, id, isInactive) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ Alias: form.alias, Team: form.team, IsActive: form?.is_active, Username: form?.username, SendInvite: form?.send_invite })
    }

    return fetch(`${config.apiNodeUrl}/teammates/` + id + `?isInactivePage=${isInactive}`, requestOptions).then(handleResponse)
}

function inviteTeammate(form, team) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ Emails: form.emails, send_invite: form?.sendInvite, Team: team })
    }

    return fetch(`${config.apiNodeUrl}/teammates/invite`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                window.location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}