import React from 'react';

const IconRoomLessReservation = props => {
    return (


        <svg id="menos_locada" data-name="menos locada" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="invisible_box" data-name="invisible box">
                <rect id="Retângulo_1588" data-name="Retângulo 1588" width="16" height="16" fill="none" />
            </g>
            <g id="Q3_icons" data-name="Q3 icons" transform="translate(1.333 1.333)">
                <g id="Grupo_641" data-name="Grupo 641">
                    <path id="Caminho_1452" data-name="Caminho 1452" d="M16.332,4H5A.967.967,0,0,0,4,5V16.332a.967.967,0,0,0,1,1H16.332a.967.967,0,0,0,1-1V5a.967.967,0,0,0-1-1ZM16,16H5.332V5.332H16Z" transform="translate(-3.998 -3.998)" fill="#fda02b" />
                    <path id="Caminho_1453" data-name="Caminho 1453" d="M22.667,17.333a.667.667,0,0,0,.667-.667v-4a.667.667,0,1,0-1.333,0v4A.667.667,0,0,0,22.667,17.333Z" transform="translate(-15.999 -9.333)" fill="#fda02b" />
                    <circle id="Elipse_142" data-name="Elipse 142" cx="0.667" cy="0.667" r="0.667" transform="translate(6.001 9.334)" fill="#fda02b" />
                </g>
            </g>
        </svg>



    )
}

export default IconRoomLessReservation;