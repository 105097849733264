import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation, initReactI18next} from "react-i18next";

import IconInfo from '../_icons/IconInfo';

function Activities(props) {
    const {t, i18n} = useTranslation('common');

    let today = props.today || {};


    let TotalActivitiesDuration = 0;

    let efficiencyRelated = 0;
    let efficiencyNeutral = 0;
    let efficiencyNotRelated = 0;

    let OutdoorActivitiesDuration = 0;
    let SiteActivitiesDuration = 0;
    let SoftwareActivitiesDuration = 0;

    let OutdoorActivities = [];
    let SiteActivities = [];
    let SoftwareActivities = [];

    if (today.MostUsedActivities) {
        for (let Activitie of today.MostUsedActivities) {
            TotalActivitiesDuration += Activitie.duration;
            if (Activitie.trackedType == "Outdoor") {
                OutdoorActivitiesDuration += Activitie.duration;
                OutdoorActivities.push({
                    "trackedId": Activitie.trackedId,
                    "name": Activitie.exhibitionName,
                    "duration": Activitie.duration,
                    "efficiencyName": Activitie.efficiencyName
                });
            }
            if (Activitie.trackedType == "Site") {
                SiteActivitiesDuration += Activitie.duration;
                SiteActivities.push({
                    "trackedId": Activitie.trackedId,
                    "name": Activitie.exhibitionName,
                    "duration": Activitie.duration,
                    "efficiencyName": Activitie.efficiencyName
                });
            }
            if (Activitie.trackedType == "Software") {
                SoftwareActivitiesDuration += Activitie.duration;
                SoftwareActivities.push({
                    "trackedId": Activitie.trackedId,
                    "name": Activitie.exhibitionName,
                    "duration": Activitie.duration,
                    "efficiencyName": Activitie.efficiencyName
                });
            }
            if (Activitie.efficiencyName == "productive") {
                efficiencyRelated += Activitie.duration
            }
            if (Activitie.efficiencyName == "neutral") {
                efficiencyNeutral += Activitie.duration
            }
            if (Activitie.efficiencyName == "unproductive") {
                efficiencyNotRelated += Activitie.duration
            }
        }
    }

    let OutdoorActivitiesHTML = [];
    let SiteActivitiesHTML = [];
    let SoftwareActivitiesHTML = [];

    let totalOutdoor = 0;
    let outdoorFiltered = [] 
    for(const track of OutdoorActivities){
        //evita duplicidade de processo de ddados
        const wasProcessed = outdoorFiltered.filter(item => item.name === track.name).length
        if(wasProcessed >= 1){
            continue;
        }

        const trackeds_same = OutdoorActivities.filter(item => item.name === track.name)
        const total_track_in_list = trackeds_same.length

        if(total_track_in_list > 1){            
            const track_unified = trackeds_same.reduce(function(acum,item){
                return {duration:acum.duration+item.duration,efficiencyName:item.efficiencyName,name:item.name,trackedId:item.trackedId}
            },{duration:0,efficiencyName:"",name:"",trackedId:0})
            outdoorFiltered = [...outdoorFiltered,track_unified]
            continue;
        }
        outdoorFiltered = [...outdoorFiltered,track]
    }

    for (let Activitie of outdoorFiltered) {
        totalOutdoor++;
        if (totalOutdoor <= 10) {
            OutdoorActivitiesHTML.push(<li key={"activitie-" + Activitie.trackedId}
                                           className={Activitie.efficiencyName}>
                <div className="itemName">{Activitie.name}</div>
                <div
                    className="number">{Math.round((Activitie.duration / (OutdoorActivitiesDuration + SiteActivitiesDuration + SoftwareActivitiesDuration)) * 100)}%
                </div>
            </li>)
        }

    }

    let totalSite = 0;
    for (let Activitie of SiteActivities) {
        totalSite++;
        if (totalSite <= 10) {
            SiteActivitiesHTML.push(<li key={"activitie-" + Activitie.trackedId} className={Activitie.efficiencyName}>
                <div className="itemName">{Activitie.name}</div>
                <div
                    className="number">{Math.round((Activitie.duration / (OutdoorActivitiesDuration + SiteActivitiesDuration + SoftwareActivitiesDuration)) * 100)}%
                </div>
            </li>)
        }
    }

    let totalSoftware = 0;
    for (let Activitie of SoftwareActivities) {
        totalSoftware++;
        if (totalSoftware <= 10) {
            SoftwareActivitiesHTML.push(<li key={"activitie-" + Activitie.trackedId}
                                            className={Activitie.efficiencyName}>
                <div className="itemName">{Activitie.name}</div>
                <div
                    className="number">{Math.round((Activitie.duration / (OutdoorActivitiesDuration + SiteActivitiesDuration + SoftwareActivitiesDuration)) * 100)}%
                </div>
            </li>)
        }
    }

    let percentEfficiencyRelated = 0;
    let percentEfficiencyNeutral = 0;
    let percentEfficiencyNotRelated = 0;

    if (today.TotalProductiveTime > 0 && today.TotalWorkedTime > 0) {
        percentEfficiencyRelated = ((today.TotalProductiveTime / today.TotalWorkedTime) * 100).toFixed(1)
    }

    if (today.TotalNeutralTime > 0 && today.TotalWorkedTime > 0) {
        percentEfficiencyNeutral = ((today.TotalNeutralTime / today.TotalWorkedTime) * 100).toFixed(1)
    }

    if (today.TotalUnproductiveTime > 0 && today.TotalWorkedTime > 0) {
        percentEfficiencyNotRelated = ((today.TotalUnproductiveTime / today.TotalWorkedTime) * 100).toFixed(1)
    }

    return (
        <div className="box large activities">

            {props.productivity &&
                <h3 style={{marginBottom: '14px'}}>

                    {t('home.activities')}
                    <span>
                        <IconInfo/>
                        <div className="tooltip">
                        <IconInfo/>{t('home.activities_tooltip')}
                        </div>
                    </span>
                </h3>
            }
            {!props.productivity &&
                <h3 style={{marginBottom: '14px'}}>
                    <Link to="/productive/activities">
                        {t('home.activities')}
                        <span>
                        <IconInfo/>
                        <div className="tooltip">
                        {/*<IconInfo/>*/}{t('home.activities_tooltip')}
                        </div>
                    </span>

                    </Link>
                </h3>
            }


            <div className="statsBlock">
                <div className="secondaryChart">
                    <div className="block" style={{height: '50px'}}>
                        <div className="stats">
                            <div className="labels">
                                <div className="label related">
                                    {t('home.related_to_work')}: <b> {percentEfficiencyRelated}%</b>
                                </div>
                                <div className="label neutral">
                                    {t('home.neutral')}: <b> {percentEfficiencyNeutral}%</b>
                                </div>
                                <div className="label">
                                    {t('home.not_related_to_work')}: <b> {percentEfficiencyNotRelated}%</b>
                                </div>
                            </div>

                            <div className="barchart">
                                <div className="bar" style={{width: percentEfficiencyRelated + '%'}}></div>
                                <div className="bar neutral" style={{width: percentEfficiencyNeutral + '%'}}></div>
                                <div className="bar unrelated" style={{width: percentEfficiencyNotRelated + '%'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="displayList">
                    <div className="list">
                        <h3>Top 10 Site</h3>
                        <ul>
                            {SiteActivitiesHTML}
                            {SiteActivities.length == 0 &&
                                <p className={'noData'}>{t('no_data')}</p>
                            }
                        </ul>
                    </div>
                    <div className="list">
                        <h3>Top 10 Software</h3>
                        <ul>
                            {SoftwareActivitiesHTML}
                            {SoftwareActivities.length == 0 &&
                                <p className={'noData'}>{t('no_data')}</p>
                            }
                        </ul>
                    </div>
                    <div className="list">
                        <h3>Top 10 Offline</h3>
                        <ul>
                            {OutdoorActivitiesHTML}
                            {OutdoorActivities.length == 0 &&
                                <p className={'noData'}>{t('no_data')}</p>
                            }
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
};

function mapStateToProps(state) {

    return {
        today: state.stats.payload
    }

}

export default connect(mapStateToProps)(Activities)
