import React, { useState, useEffect } from "react";
import "./RulerTrackeds.scss";

const RulerTrackeds = ({ data }) => {
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    const formattedData = formatData(data);
    setTimelineData(formattedData);
  }, [data]);

  const formatData = (data) => {
    // Calcular a duração total da linha do tempo
    const totalDuration =
      new Date(data.end_time).getTime() - new Date(data.start_time).getTime();

    // Formatar cada elemento rastreado
    const formattedTracks = data.trackeds.map((tracked) => {
      const startTime = new Date(tracked.start_time).getTime();
      const endTime = new Date(tracked.end_time).getTime();

      // Calcular a largura do elemento rastreado
      const trackWidth = ((endTime - startTime) / totalDuration) * 100;

      return {
        ...tracked,
        width: trackWidth,
      };
    });

    return formattedTracks;
  };

  return (
    <div className="timeline-container">
      <div className="timeline-ruler">
        {timelineData.map((tracked) => (
          <span
            key={tracked.id}
            className="timeline-tick"
            data-time={tracked.start_time}
          />
        ))}
      </div>
      <div className="timeline-tracks">
        {timelineData.map((tracked) => (
          <div key={tracked.id} className="timeline-track">
            <div
              className="timeline-track-bar"
              style={{
                width: `${tracked.width}%`,
                backgroundColor: getTrackColor(tracked.efficiency),
              }}
            />
            <span className="timeline-track-label">{tracked.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const getTrackColor = (efficiency) => {
  switch (efficiency) {
    case "related":
      return "#00ff00";
    case "unrelated":
      return "#ff0000";
    default:
      return "#ffff00";
  }
};

export default RulerTrackeds;