import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { bindActionCreators } from 'redux'
import Moment from 'moment'
import { isMobile } from 'react-device-detect';

import { api } from '../../_helpers/api'
import { alertActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { authHeader, getColorStatusKr, numberToPercentString } from '../../_helpers';

import './styles.scss'
import IconClock from '../../_components/_icons/IconClock';
import MenuMU from '../../_components/_shared/MenuMU';

function GoalsCompletedPage(props) {
	const { t } = useTranslation('common');

	let path = window.location.pathname.split("/") || [];

	const { alertActions, userDetails, filter } = props;
	const [goalsArchived, setGoalsArchived] = useState([])
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const token = authHeader()

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const maxDay = new Date(date.getFullYear(), date.getMonth() + 12, 0);

	const firstDayDate = firstDay.toISOString().split('T')[0]
	const maxDate = maxDay.toISOString()
	const lastDay = maxDate.split('T')[0]

	function toggleMenu(type) {
		if (type == 'main') {
			setIsMenuOpen(!isMenuOpen);
		} else {
			if (isMobile) {
				setIsMenuOpen(false)
			}
		}
	};

	function toggleFromMenu(value) {

		if (value == 'closed') {
			setIsMenuOpen(true);
		} else {
			setIsMenuOpen(false)
		}
	}

	async function getGoalsArchived() {
		let url = `goals/filed?organization_id=${userDetails?.organizationId}`

		if (filter.date) {
			url += `&date_start=${filter.date.startDate}&date_end=${filter.date.endDate}`
		}

		try {
			const response = await api.get(url, {
				headers: {
					'Authorization': token.Authorization
				}
			})


			setGoalsArchived(response.data)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals_archived.error'))
		}

	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.goals');
	}, []);

	useEffect(() => {
		getGoalsArchived()
	}, [filter])

	return (
		<React.Fragment>

			{path.includes('productivity') && (
				<>
					<div className={isMenuOpen ? "menuToggle open" : "menuToggle"}
						onClick={() => {
							toggleMenu('main')
						}}>
						<i className="arrow"></i>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<MenuMU menuStatus={isMenuOpen} toggle={toggleFromMenu} />
				</>
			)}

			<section className="mainContent">

				<Toolbar objectiveName={t('breadcrub.archived')} />

				<Filterbar
					filterWho={'disabled'}
					filterWhat={'disabled'}
					filterWhen={'multi'}
					startDate={firstDayDate}
					endDate={lastDay}
					maxDate={new Date(maxDate)}
					resetOneYear={true}
				/>

				<main className='container-objectives-archived'>
					{goalsArchived.length > 0 && goalsArchived.map(goal => (
						<>
							<header key={goal.id} className='header'>
								<div className='circularProgressGeneral'>
									<CircularProgressbarWithChildren
										value={Number(goal.progress)}
										strokeWidth={10}
										styles={buildStyles({
											strokeLinecap: "butt",
											pathColor: '#FDA02B',
											trailColor: "#eee",
										})}>
										<span style={{ fontSize: 14, fontWeight: 500, color: '#4F7091' }}>
											{`${numberToPercentString(goal.progress)}`}
										</span>
									</CircularProgressbarWithChildren>
								</div>

								<div className='goals-info'>
									<h1 style={{ textDecoration: goal.is_active ? '' : 'line-through' }}>
										{goal.title}
									</h1>
									<span>{t('goals_archived.archived')}
										<strong className='date'>
											{Moment(goal.filed_at.split('T')[0]).format('DD/MM/YYYY')}
										</strong>
									</span>
								</div>
							</header>

							<table>
								<thead>
									<tr>
										<th style={{ width: '35%' }}></th>
										<th style={{ width: '10%', paddingRight: '40px' }}>{t('goals_archived.progress')}</th>
										<th style={{ width: '10%', textAlign: 'center' }}>{t('goals_archived.status')}</th>
										<th style={{ width: '20%', textAlign: 'center' }}>{t('goals_archived.responsible')}</th>
										<th style={{ width: '15%' }}>{t('goals_archived.archived_kr')}</th>
										<th style={{ width: '10%' }}>{t('goals_archived.time')}</th>
									</tr>
								</thead>
								<tbody>
									{goal.krs.map((kr, index) => (
										<>
											<tr key={kr.id} className='kr-info'>
												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF', textDecoration: !kr.is_active && 'line-through' }}>
													{`KR ${index + 1} - ${kr.title}`}
												</td>

												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF' }}>
													<div className='percent-krs'>
														<div className='circle'>
															<CircularProgressbar
																value={Number(kr.progress)}
																strokeWidth={50}
																styles={buildStyles({
																	strokeLinecap: "butt",
																	trailColor: '#E4EAEF',
																	pathColor: getColorStatusKr(kr.status_kr)
																})}
															/>
														</div>
														<span style={{ color: '#1E6B7F' }}>
															{Math.trunc(Number(kr.progress))}%
														</span>
													</div>
												</td>
												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF' }}></td>
												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF' }}></td>
												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF' }}></td>
												<td style={{ backgroundColor: !kr.is_active && '#E4EAEF', padding: '0' }}>
													<div className='clock' >
														<IconClock />
														<span>00:00</span>
													</div>
												</td>
											</tr>

											{kr.tasks.map(task => (
												<tr key={task.id}>
													<td style={{ textDecoration: !task.is_active && 'line-through' }} >{task.title}</td>
													<td>
														<div className='progress-background'>
															<div className='progress-bar' style={{ width: Number(task.progress).toFixed(2) + '%', backgroundColor: getColorStatusKr(task.status_id) }}></div>
														</div>
													</td>
													<td style={{ backgroundColor: '#E4EAEF', textAlign: 'center', }}>{t('goals_archived.archive')}</td>
													<td>
														<div style={{ textAlign: 'center' }}>{task.responsible}</div>
													</td>
													<td>{Moment(task.filed_at.split('T')[0]).format('DD/MM/YYYY')}</td>
													<td style={{ paddingLeft: '24px' }}>00:00</td>
												</tr>
											))}
										</>
									))}
								</tbody>
							</table>
						</>

					))}
					{goalsArchived.length === 0 && (
						<div className='no-data'>
							<h2>{t('goals_archived.empty')}</h2>
							<p>{t('goals_archived.empty_info')}</p>
						</div>
					)}
				</main>
			</section>

		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsCompletedPage)
