import React from 'react';

const IconTree = props => {
  return (
    <svg id="arvore" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1435" data-name="Retângulo 1435" width="19" height="19" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(0.792 1.188)">
        <g id="Grupo_483" data-name="Grupo 483">
          <path id="Caminho_355" data-name="Caminho 355" d="M18.625,14.875H15.458a.792.792,0,0,0-.792.792v.792a3.132,3.132,0,0,1-3.127-3.127V9.769A5.146,5.146,0,0,0,6.75,4.623V3.792A.792.792,0,0,0,5.958,3H2.792A.792.792,0,0,0,2,3.792V6.958a.792.792,0,0,0,.792.792H5.958a.792.792,0,0,0,.792-.792V6.206A3.562,3.562,0,0,1,9.956,9.769v3.562a4.746,4.746,0,0,0,4.71,4.71v.792a.792.792,0,0,0,.792.792h3.167a.792.792,0,0,0,.792-.792V15.667A.792.792,0,0,0,18.625,14.875ZM5.167,6.167H3.583V4.583H5.167ZM17.833,18.042H16.25V16.458h1.583Z" transform="translate(-2 -3)" fill="#98acc1" />
          <path id="Caminho_356" data-name="Caminho 356" d="M33.608,3H30.442a.792.792,0,0,0-.792.792v.792A5.542,5.542,0,0,0,26.8,5.731a4.75,4.75,0,0,1,.831,1.346,3.6,3.6,0,0,1,2.019-.871v.752a.792.792,0,0,0,.792.792h3.167a.792.792,0,0,0,.792-.792V3.792A.792.792,0,0,0,33.608,3Zm-.792,3.167H31.233V4.583h1.583Z" transform="translate(-16.983 -3)" fill="#98acc1" />
          <path id="Caminho_357" data-name="Caminho 357" d="M8.769,33.633a3.4,3.4,0,0,1-2.019.91v-.752A.792.792,0,0,0,5.958,33H2.792A.792.792,0,0,0,2,33.792v3.167a.792.792,0,0,0,.792.792H5.958a.792.792,0,0,0,.792-.792v-.792A5.3,5.3,0,0,0,9.64,34.94a5.779,5.779,0,0,1-.871-1.306Zm-3.6,2.533H3.583V34.583H5.167Z" transform="translate(-2 -21.125)" fill="#98acc1" />
        </g>
      </g>
    </svg>
  )
}

export default IconTree;