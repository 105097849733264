import config from 'config';
import { authHeader } from '../_helpers';

export const statsService = {
    getStats,
    getProductivity,
    getRealtimeMachineUsers,
    getInactivity,
    getRealtimeTooltip
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getStats(startDate, endDate, teams, teammates, statsIn) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryStats = ''
    let queryTeams = ''
    let queryTeammates = ''

    if (statsIn) {
        queryStats = "&stats=" + statsIn;
    }

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt === 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    return fetch(`${config.apiNodeUrl}/stats?startDate=${startDate}&endDate=${endDate}` + queryTeams + queryTeammates + queryStats, requestOptions).then(handleResponse);
}

function getProductivity(date) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let newDate = new Date();

    let dateHour = date + 'T' + String(newDate.getHours()).padStart(2, "0") + ':' + String(newDate.getMinutes()).padStart(2, "0") + ':' + String(newDate.getSeconds()).padStart(2, "0");


    return fetch(`${config.apiNodeUrl}/stats/my-productivity?current_date=${dateHour}`, requestOptions).then(handleResponse);
}

function getRealtimeMachineUsers(startDate, endDate, teams) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = ''

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt === 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    return fetch(`${config.apiNodeUrl}/stats/realtime/users/?startDate=${startDate}&endDate=${endDate}` + queryTeams, requestOptions).then(handleResponse);
}

function getRealtimeTooltip(tracked_id) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/stats/tracked/${tracked_id}`, requestOptions).then(handleResponse);
}

function getInactivity(teams, teammates) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = '';
    let queryTeammates = '';

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt === 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    return fetch(`${config.apiNodeUrl}/stats/inactivity/?1=1` + queryTeams + queryTeammates, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload();

            }

            const error = (data && data.Message) || response.status;
            return Promise.reject(error);
        }

        return data.rows || data;
    });
}
