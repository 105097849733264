import React from 'react';

const IconSettings = props => {
    return (
     
        <svg  className="icon" id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Caminho_114" data-name="Caminho 114" d="M22.9,6H12.85A3.1,3.1,0,0,0,10,4,3,3,0,0,0,7.2,6H5.1A1.05,1.05,0,0,0,4,7,1.05,1.05,0,0,0,5.1,8H7.2A3,3,0,0,0,10,10a3.1,3.1,0,0,0,2.85-2H22.9A1.05,1.05,0,0,0,24,7a1.05,1.05,0,0,0-1.1-1ZM10,8a1,1,0,1,1,1-1A1,1,0,0,1,10,8Z" transform="translate(-4 -4)" fill="#97b3bf"/>
        <path id="Caminho_115" data-name="Caminho 115" d="M23,20H20.95a3.1,3.1,0,0,0-2.85-2,3,3,0,0,0-2.8,2H5.2a1,1,0,0,0,0,2H15.3a3,3,0,0,0,2.8,2,3.1,3.1,0,0,0,2.85-2H23a1,1,0,0,0,0-2Zm-4.9,2a1,1,0,1,1,1-1A1,1,0,0,1,18.1,22Z" transform="translate(-4.1 -11)" fill="#97b3bf"/>
        <path id="Caminho_116" data-name="Caminho 116" d="M23,34H14.45a3.1,3.1,0,0,0-2.85-2,3,3,0,0,0-2.8,2H5.2a1,1,0,0,0,0,2H8.8a3,3,0,0,0,2.8,2,3.1,3.1,0,0,0,2.85-2H23a1,1,0,0,0,0-2ZM11.6,36a1,1,0,1,1,1-1A1,1,0,0,1,11.6,36Z" transform="translate(-4.1 -18)" fill="#97b3bf"/>
        </svg>

     
    )
}

export default IconSettings;