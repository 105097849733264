import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { Box, Button, Popover, Tooltip, Typography } from '@mui/material';
import { ArrowRight, TrashSimple, X } from 'phosphor-react';
import { useState } from 'react';
import DrawerReservationDetails from '../../_components/rooms_page/my_reservations/DrawerReservationDetails';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';

const defaultUrl = "https://iseb3.com.br/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png"

function RoomMyReservations(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, teammates, teammatesActions, alertActions } = props
  const [myReservations, setMyReservations] = useState({})
  const [fetching, setFetching] = useState(true)
  const [currentReservation, setCurrentReservation] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [canceling, setCanceling] = useState(false);
  const [anchorElConfirmCancel, setAnchorElConfirmCancel] = useState(null);
  const [reservationToCancel, setReservationToCancel] = useState(null);

  const handleClickConfirmCancel = (event, reservationId) => {
    setReservationToCancel(reservationId)
    setAnchorElConfirmCancel(event.currentTarget);
  };

  const handleCloseConfirmCancel = () => {
    setReservationToCancel(null)
    setAnchorElConfirmCancel(null);
  };

  const openConfirmCancel = Boolean(anchorElConfirmCancel);
  const idConfirmCancel = openConfirmCancel ? 'cancel-reservation' : undefined;

  const onCancelReservation = async () => {
    try {
      setCanceling(true)
      const response = await api.put(`/rooms/reservations/${reservationToCancel}`, {}, {
        headers: authHeader()
      })
      await getMyReservations()
      alertActions.success(t('spaces.success.reservation_delete'))
      handleCloseConfirmCancel()
      setCanceling(false)
    } catch (error) {
      console.log(error.response)
      alertActions.success(t('spaces.errors.reservation_delete'))
      setCanceling(false)
    }
  }

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  const handleOpenDrawer = (reserve) => {
    setCurrentReservation(reserve)
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setCurrentReservation(null)
  }

  const getMyReservations = async () => {
    try {
      setFetching(true)
      const response = await api.get(`/rooms/my-reservations`, {
        headers: authHeader()
      })

      if (response.status === 200) {
        setMyReservations(response.data)
        setFetching(false)
      }
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getMyReservations()
    teammatesActions.getTeammates()
  }, [])


  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />

        {/* PAGE STARTS */}
        {
          !fetching
            ?
            <>
              <div className={style.containerRoomsPage}>
                <div className={style.containerTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {t('spaces.columns.room')}
                        </th>
                        <th
                        >
                          {t('spaces.columns.description')}
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          {t('spaces.columns.capacity')}
                        </th>
                        <th style={{ width: '8%', textAlign: 'center' }}>
                          STATUS
                        </th>
                        <th>
                          {t('spaces.columns.reservation')}
                        </th>
                        <th
                          style={{ width: '4%', textAlign: 'center' }}
                        >
                          {t('spaces.columns.actions')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (myReservations && myReservations?.nextReservations?.length > 0) ?
                          myReservations.nextReservations.map((reserve, index) => {
                            return (
                              <tr key={index}>
                                <td onClick={() => handleOpenDrawer(reserve)}>
                                  <div className={style.boxRoom}>
                                    <img src={reserve?.images[0]?.image_url || defaultUrl} alt="Foto sala" />
                                    {reserve?.name}
                                  </div>
                                </td>
                                <td onClick={() => handleOpenDrawer(reserve)}>{reserve?.description}</td>
                                <td style={{ textAlign: 'center' }} onClick={() => handleOpenDrawer(reserve)}>{reserve?.capacity}</td>
                                <td onClick={() => handleOpenDrawer(reserve)}>
                                  <Box display={'flex'} justifyContent={'center'}>
                                    {
                                      reserve?.status === "reserved" &&
                                      <div className={`${style.boxStatus} ${style.reserved}`}>
                                        <div></div>
                                        <div>{t(`spaces.status.${reserve?.status}`)}</div>
                                      </div>
                                    }
                                    {
                                      reserve?.status === "completed" &&
                                      <div className={`${style.boxStatus} ${style.completed}`}>
                                        <div></div>
                                        <div>{t(`spaces.status.${reserve?.status}`)}</div>
                                      </div>
                                    }
                                    {
                                      reserve?.status === "cancelled" &&
                                      <div className={`${style.boxStatus} ${style.cancelled}`}>
                                        <div></div>
                                        <div>{t(`spaces.status.${reserve?.status}`)}</div>
                                      </div>
                                    }
                                  </Box>
                                </td>
                                <td onClick={() => handleOpenDrawer(reserve)}>
                                  {
                                    reserve.type === "T"
                                      ?
                                      (
                                        reserve?.reservation_times?.length > 1
                                          ?
                                          <Tooltip arrow title={
                                            <Box>
                                              {reserve?.reservation_times.map(t => <Box fontSize={'12px'} fontWeight={'500'}>{t.start_time + ' - ' + t.end_time}</Box>)}
                                            </Box>
                                          } placement="bottom">
                                            <Box fontSize={'12px'}>{`${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_times.map(t => t.start_time + ' - ' + t.end_time)[0]},[...]`}</Box>
                                          </Tooltip>
                                          :
                                          <Box fontSize={'12px'}>{`${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_times[0]?.start_time} - ${reserve?.reservation_times[0]?.end_time}`}</Box>
                                      )
                                      :
                                      `${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_start_time} - ${reserve?.reservation_end_time}`
                                  }
                                </td>
                                <td>
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {
                                      !reserve.cancelled &&
                                      <div title={t('spaces.cancel_reservation')} className={style.delete} onClick={(e) => handleClickConfirmCancel(e, reserve.reservation_id)}>
                                        <X size={16} />
                                      </div>
                                    }
                                    <div className={style.arrow} onClick={() => handleOpenDrawer(reserve)}>
                                      <ArrowRight size={16} />
                                    </div>
                                  </Box>
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr><td style={{ cursor: 'auto' }} colSpan={6}>{t('spaces.no_reservations')}</td></tr>

                      }
                    </tbody>
                  </table>

                </div>
                {myReservations &&

                  <div className={style.containerTable}>
                    <header>
                      <h6> {t('spaces.historical')}</h6>
                      <div className={style.indicators}>
                        <div>
                          <div className={`${style.circle} ${style.success}`}></div>
                          <div>{t('spaces.reservations_completed')} : <strong>{myReservations?.reservationsHistory?.stats?.totalCompleted}</strong></div>
                        </div>
                        <div>
                          <div className={`${style.circle} ${style.error}`}></div>
                          <div>{t('spaces.reservations_cancelled')} : <strong>{myReservations?.reservationsHistory?.stats?.totalCancelled}</strong></div>
                        </div>
                      </div>
                    </header>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {t('spaces.columns.room')}
                          </th>
                          <th
                          >
                            {t('spaces.columns.description')}
                          </th>
                          <th style={{ textAlign: 'center' }}>
                            {t('spaces.columns.capacity')}
                          </th>
                          <th style={{ width: '8%', textAlign: 'center' }}>
                            STATUS
                          </th>
                          <th>
                            {t('spaces.columns.reservation')}
                          </th>
                          <th
                            style={{ width: '4%', textAlign: 'center' }}
                          >
                            {t('spaces.columns.actions')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          myReservations?.reservationsHistory?.data?.length > 0 ?
                            myReservations.reservationsHistory.data.map((reserve, index) => {
                              return (
                                <tr key={index}>
                                  <td onClick={() => handleOpenDrawer(reserve)}>
                                    <div className={style.boxRoom}>
                                      <img src={reserve?.images[0]?.image_url || defaultUrl} alt="Foto sala" />
                                      {reserve?.name}
                                    </div>
                                  </td>
                                  <td onClick={() => handleOpenDrawer(reserve)}>{reserve?.description}</td>
                                  <td style={{ textAlign: 'center' }} onClick={() => handleOpenDrawer(reserve)}>{reserve?.capacity}</td>
                                  <td style={{ textAlign: 'center' }} onClick={() => handleOpenDrawer(reserve)}>
                                    <div className={`${style.boxStatus} ${reserve?.status === "cancelled" ? style.cancelled : style.completed}`}>
                                      <div></div>
                                      <div>{t(`spaces.status.${reserve?.status}`)}</div>
                                    </div>
                                  </td>
                                  <td onClick={() => handleOpenDrawer(reserve)}>
                                    {
                                      reserve.type === "T"
                                        ?
                                        (
                                          reserve?.reservation_times?.length > 1
                                            ?
                                            <Tooltip arrow title={
                                              <Box>
                                                {reserve?.reservation_times.map(t => <Box fontSize={'12px'} fontWeight={'500'}>{t.start_time + ' - ' + t.end_time}</Box>)}
                                              </Box>
                                            } placement="bottom">
                                              <Box fontSize={'12px'}>{`${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_times.map(t => t.start_time + ' - ' + t.end_time)[0]}, [...]`}</Box>
                                            </Tooltip>
                                            :
                                            <Box fontSize={'12px'}>{`${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_times[0]?.start_time} - ${reserve?.reservation_times[0]?.end_time}`}</Box>
                                        )
                                        :
                                        `${befectiveFormatDate(reserve?.reservation_date, i18n.language)}, ${reserve?.reservation_start_time} - ${reserve?.reservation_end_time}`
                                    }
                                  </td>
                                  <td>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                      {
                                        !reserve.cancelled &&
                                        <div title={t('spaces.cancel_reservation')} className={style.delete} onClick={(e) => handleClickConfirmCancel(e, reserve.reservation_id)}>
                                          <X size={16} />
                                        </div>
                                      }
                                      <div className={style.arrow} onClick={() => handleOpenDrawer(reserve)}>
                                        <ArrowRight size={16} />
                                      </div>
                                    </Box>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr><td style={{ cursor: 'auto' }} colSpan={6}>{t('spaces.no_reservations')}</td></tr>
                        }
                      </tbody>
                    </table>
                  </div>

                }

              </div>
              {
                currentReservation && <DrawerReservationDetails currentReservation={currentReservation} handleCloseDrawer={handleCloseDrawer} openDrawer={openDrawer} />
              }

              <Popover
                id={idConfirmCancel}
                open={openConfirmCancel}
                anchorEl={anchorElConfirmCancel}
                onClose={handleCloseConfirmCancel}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "#FFE3E5",
                    boxShadow: "none",
                    padding: '18px 14px',
                    borderRadius: '6px',
                  }
                }}
              >
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.cancel_reservation_msg')}</Typography>

                  <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
                    <Button disabled={canceling} style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmCancel}>
                      <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
                    </Button>
                    <Button disabled={canceling} style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onCancelReservation}>
                      <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </>
            :
            <div className={style.containerRoomsPage}>
              <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
            </div>
        }


      </section>

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomMyReservations)