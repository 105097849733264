import React, { useEffect } from "react";
import style from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Box, Container } from "@mui/material";
import IconLogo from "../../_components/_icons/IconLogo";
import { Download } from "phosphor-react";

export default function DownloadStefaniniPage() {
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    document.title = "Befective | " + t("menu.user.download");
  }, []);

  return (
    <Box className={style.containerPage}>
      <Box component={"header"} className={style.header}>
        <Container maxWidth="md" className={style.containerHeader}>
          <IconLogo />
        </Container>
      </Box>
      <Box component={"main"} className={style.main}>
          <h4 className={style.title}>{t("download_stefanini.title")}</h4>
          <p className={style.subtitle}>{t("download_stefanini.subtitle1")}</p>
          <p className={style.subtitle}>{t("download_stefanini.subtitle2")}</p>
          <a className={style.buttonDownload} href="https://befective-agent.s3.amazonaws.com/current/windows/752/befective_setup_stefanini.exe" download="befective_setup_stefanini.exe">
            <Download size={18} color="#205B6F"/> {t("download_stefanini.button")}
          </a>
          <p className={style.subtitle}>{t("download_stefanini.subtitle3")}</p>
      </Box>
    </Box>
  );
}
