import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { api } from '../../_helpers/api';
import IconMainLogo from '../../_components/_icons/IconMainLogo';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export function RequestReservationsConfirmation(props) {
  const { t, i18n } = useTranslation('common');
  const [confirme,setConfirme] = useState(null)
  const { userDetails} = props

  const { id } = useParams()
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const o = queryParams.get('o');
    confirmRequestService(Number(o))
    
  }, [location.search]);

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  

  async function confirmRequestService(org) {
    try {
      const response = await api.put(`settings/company/requests_confirmation/${id}?o=${org}`)
      
      if(response.status === 200){
        setConfirme(true)
      }
    } catch (e) {
      setConfirme(false)
      console.log("/ERROR ->",e)
    }
  }

  




  return (
    
    <div className="loginMainOld">

      <div className="loginContainer" style={{justifyContent:"center",alignItems:"center"}}>
          <div className="mainLogo" >
              <IconMainLogo />
              
          </div>
          <div>
            <p style={{color:"#FFFFFF",fontWeight:"500",fontSize:"16px"}}>{confirme === null ? '' : confirme ? t('requests_confirmation.success') :t('requests_confirmation.error') }</p>
          </div>
      </div>
    </div>
      

       

      
    
  )
};



