import React, {useRef, useState, compose, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {useTranslation, initReactI18next} from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux'
import {usersActions} from '../../../_actions';
import {settingsActions} from '../../../_actions';


function Nav(props) {

    const {t, i18n} = useTranslation('common');

    let path = window.location.pathname.split("/")
    let currentId = path[3]

    return (
        <div className="mainFilterbar">
            <NavLink to={"/settings/teams/" + currentId + "/configuration"} activeClassName="active">
                <button className={'settingsNav'}>{t('settings.teams.nav.edit')}</button>
            </NavLink>

            <NavLink to={"/settings/teams/" + currentId + "/focus"} activeClassName="active">
                <button className={'settingsNav'}>{t('settings.teams.nav.focus')}</button>
            </NavLink>
        </div>

    )
};

function mapStateToProps(state) {

    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
