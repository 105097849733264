import React, { useState } from "react";
import Select from 'react-select'
import "./styles.scss";
import { useHistory, withRouter } from "react-router-dom";
import { ArrowSquareIn, DotsThreeCircleVertical } from "phosphor-react";
import { CircularProgress, Menu, MenuItem, Slide } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import IconClose from "../../_icons/IconClose";
import IconInfo from "../../_icons/IconInfo";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { alertActions, teammatesActions } from "../../../_actions";
import { useEffect } from "react";
import { authHeader } from "../../../_helpers";
import ConfirmDialog from "../../_shared/ConfirmDialog";
import moment from "moment";
import useOpenController from "../../../_helpers/hooks";
import { ExpendableButton } from "./ExpendableButton";
import { ColabDetailsDrawer } from "../GoalsPage/ColabDetailsDrawer";
import { Tooltip } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootTab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '37ch',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '940px',
    height: '560px',
    outline: 'none',
    borderRadius: '8px'
  }
}));

function ObjectivesTable({
  data = null,
  columns = null,
  hover = true,
  striped = true,
  tab = '',
  userDetails = {},
  alertActions = null,
  teammates = [],
  teammatesActions = null,
  updateDashboardInfo
}) {
  const getCaps = (head, field) => {
    if (head) return head.toUpperCase();
    return field.toUpperCase();
  };

  const { t } = useTranslation('common');

  const classes = useStyles();

  const history = useHistory();

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

  const [openModalEditGoal, setOpenModalEditGoal] = useState(false);
  const [isCheckType, setIsCheckType] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teammatesOptions, setTeammatesOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [anchorElOptionsGoals, setAnchorElOptionsGoals] = useState(null);
  const [currentGoal, setCurrentGoal] = useState({});
  const [categories, setCategories] = useState([])
  const [openColabDetails, setOpenColabDetails] = useState(false)
  const [employeeId, setEmployeeId] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [dataEmployee, setDataEmployee] = useState({});
  const [form, setForm] = useState({
    id: null,
    title: '',
    description: '',
    type: '2',
    responsable: '',
    period: '',
    date_start: '',
    date_end: ''
  });
  const [formError, setFormError] = useState({
    id: null,
    title: '',
    description: '',
    type: '',
    responsable: '',
    date_start: '',
    date_end: ''
  });

  const { isOpen, toggle } = useOpenController({
    isOpen: false,
    goalId: -1
  });

  const token = authHeader()

  const today = new Date(String(new Date().toISOString()).split('T')[0])

  const objColorsStatus = {
    completed: { color: '#22BEAD', bgColor: '#D6F4F1' },
    progress: { color: '#FDA02B', bgColor: '#FFF0DD' },
  }

  const listColorsStatus = {
    completed: '#22BEAD',
    progress: '#FDA02B',
    planning: '#98ACC1',
    overdue: '#FF5151'
  }

  const getEmployeeData = async () => {
    try {
      setIsLoading(true)

      const response = await api.get(`/goals/employee/${employeeId}?organization_id=${userDetails?.organizationId}`, {
        headers: authHeader()
      })

      setDataEmployee(response.data)

      setIsLoading(false)

    } catch (error) {
      console.log(error)
    }
  }


  function closeDrawerColabDetails() {
    setOpenColabDetails(false)
    setEmployeeId(null)
  }

  function handleDrawerColab(row) {
    setOpenColabDetails(true)
    setEmployeeId(row?.id)
  }

  function toggleRemoveDialog(id) {

    let elems = document.querySelectorAll(".removeDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById("remove-" + id)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    if (e.target.name === 'type' && e.target.checked) {
      setIsCheckType(true)
    }

    if (e.target.name === 'period') {
      const dateNowSplit = moment().format('YYYY-MM-DD').split('-');
      const day = dateNowSplit[2];
      const month = dateNowSplit[1];
      const year = dateNowSplit[0];

      switch (e.target.value) {
        case 'monthly':
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            date_start: moment(year + '-' + month + '-01').format('YYYY-MM-DD'),
            date_end: moment(year + '-' + month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
          })
          return;
        case 'bimonthly':
          const months_b = getFirstAndLastMonth(month, "bimonthly")
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            date_start: moment(year + '-' + months_b.first_month + '-01').format('YYYY-MM-DD'),
            date_end: moment(year + '-' + months_b.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
          })
          return;
        case 'quarterly':
          const months_q = getFirstAndLastMonth(month, "quarterly")
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            date_start: moment(year + '-' + months_q.first_month + '-01').format('YYYY-MM-DD'),
            date_end: moment(year + '-' + months_q.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
          })
          return;
        case 'semester':
          const months_s = getFirstAndLastMonth(month, "semester")
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            date_start: moment(year + '-' + months_s.first_month + '-01').format('YYYY-MM-DD'),
            date_end: moment(year + '-' + months_s.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
          })
          return;
        case 'yearly':
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            date_start: moment(year + '-' + '01-01').format('YYYY-MM-DD'),
            date_end: moment(year + '-' + '12-31').format('YYYY-MM-DD')
          })
          return;

        default:
          return;
      }
    }
  }

  const getFirstAndLastMonth = (current_month, type) => {
    const cur_month = Number(current_month)
    switch (type) {
      case "bimonthly":
        if (cur_month >= 1 && cur_month <= 2) {
          return {
            first_month: '01',
            last_month: '02'
          }
        }
        if (cur_month >= 3 && cur_month <= 4) {
          return {
            first_month: '03',
            last_month: '04'
          }
        }
        if (cur_month >= 5 && cur_month <= 6) {
          return {
            first_month: '05',
            last_month: '06'
          }
        }
        if (cur_month >= 7 && cur_month <= 8) {
          return {
            first_month: '07',
            last_month: '08'
          }
        }
        if (cur_month >= 9 && cur_month <= 10) {
          return {
            first_month: '09',
            last_month: '10'
          }
        }
        if (cur_month >= 11 && cur_month <= 12) {
          return {
            first_month: '11',
            last_month: '12'
          }
        }

        break;
      case "quarterly":
        if (cur_month >= 1 && cur_month <= 3) {
          return {
            first_month: '01',
            last_month: '03'
          }
        }
        if (cur_month >= 4 && cur_month <= 6) {
          return {
            first_month: '04',
            last_month: '06'
          }
        }
        if (cur_month >= 7 && cur_month <= 9) {
          return {
            first_month: '07',
            last_month: '09'
          }
        }
        if (cur_month >= 10 && cur_month <= 12) {
          return {
            first_month: '10',
            last_month: '12'
          }
        }

        break;
      case "semester":
        if (cur_month >= 1 && cur_month <= 6) {
          return {
            first_month: '01',
            last_month: '06'
          }
        }
        if (cur_month >= 7 && cur_month <= 12) {
          return {
            first_month: '07',
            last_month: '12'
          }
        }

        break;
    }
  }

  const closeModalEditGoal = () => {
    setOpenModalEditGoal(false);
  };

  const getDetailsObjective = async (goal_id) => {
    try {
      const result = await api.get(`goals/${goal_id}/details/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      let goal = result?.data

      setForm({
        id: goal?.id,
        title: goal?.name,
        description: goal?.description,
        type: String(goal?.type_id),
        responsable: goal?.responsable,
        period: goal?.measurement,
        date_start: moment(goal?.date_start).format('YYYY-MM-DD'),
        date_end: moment(goal?.date_end).format('YYYY-MM-DD')
      })

      const resp1 = await api.get(`goals/get-responsibles?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      if (resp1.status === 200) {
        setTeammatesOptions(resp1.data)
      }


    } catch (error) {
      //alertActions.error(error)
    }

  }

  const handleClickMenuGoals = (event, goal) => {
    getDetailsObjective(goal?.id)
    setAnchorElOptionsGoals(event.currentTarget);
    setCurrentGoal(goal);
  };
  const handleCloseMenuOptionsGoals = () => {
    setAnchorElOptionsGoals(null);
  };

  const handleEditGoal = () => {
    setOpenModalEditGoal(true);
    setAnchorElOptionsGoals(null)
  }

  const handleArchiveGoal = async () => {

    try {

      const result = await api.put(`/goals/${currentGoal?.id}/deactive/?organization_id=${userDetails?.organizationId}`,
        {
          action_id: 9,
          from: {},
          to: {},
          datetime: new Date()
        },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      setAnchorElOptionsGoals(null);

      // setConfirmDialog({
      //   ...confirmDialog,
      //   isOpen: false
      // })

      toggleRemoveDialog()

      updateDashboardInfo()

      alertActions.success(t('goals.success.deactive'))
    } catch (error) {
      //alertActions.error(error)
    }
  }

  // const getCategories = async () => {
  //   const token = authHeader()
  //   const response = await api.get('/activities/categories', {
  //     headers: {
  //       'Authorization': token.Authorization
  //     }
  //   })

  //   const categoriesFiltered = response.data?.filter(item => item.is_parent === true)

  //   setCategories(categoriesFiltered)
  // }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('goals.errors.required')
    }
    // else if (form.title.length < 10) {
    //   errors.title = t('goals.errors.title_goal')
    // }

    if (!form.description) {
      errors.description = t('goals.errors.required')
    }
    // else if (form.description.length < 10) {
    //   errors.description = t('goals.errors.description_goal')
    // }

    if (!form.type) {
      errors.type = t('goals.errors.required')
    }

    if (!form.responsable) {
      errors.responsable = t('goals.errors.required')
    }

    if (!form.period) {
      errors.period = t('goals.errors.required')
    }

    if (!form.date_start) {
      errors.date_start = t('goals.errors.required')
    } else if (form.date_start >= form.date_end) {
      errors.date_start = t('goals.errors.invalid_field')
    }

    if (!form.date_end) {
      errors.date_end = t('goals.errors.required')
    } else if (form.date_end <= form.date_start) {
      errors.date_end = t('goals.errors.invalid_field')
    }

    return errors
  }

  const handleSubmitGoal = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const editGoal = async () => {
    try {
      setIsSubmitting(true)
      await api.put(`/goals/${form?.id}/?organization_id=${userDetails.organizationId}`, {
        action_id: 2,
        datetime: new Date(),
        title: form?.title,
        description: form?.description,
        type_id: Number(form?.type),
        accountable_id: form?.responsable,
        created_by: userDetails?.id,
        updated_by: userDetails?.id,
        measurement: form?.period,
        date_start: form?.date_start,
        date_end: form?.date_end
      }, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      updateDashboardInfo()
      alertActions.success(t('goals.success.edit_goal'))
      setIsSubmitting(false)
      closeModalEditGoal()
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t('goals.errors.edit_goal'))
    }
  }

  function getProgressColor(row, col) {
    let colorStatusColab = listColorsStatus.planning
    let goalProgressColab = Number(row[col.field])

    if (goalProgressColab > 0 && goalProgressColab < 100) {
      colorStatusColab = listColorsStatus.progress
    } else if (goalProgressColab >= 100.00) {
      colorStatusColab = listColorsStatus.completed
    }

    return colorStatusColab
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      editGoal()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    if (employeeId !== null)
      getEmployeeData()
  }, [employeeId])

  return (
    <div className="table-overlay">
      <table>
        <thead>
          <tr>
            {columns &&
              columns.map((head, index) => {
                switch (head.field) {
                  case 'start':
                  case 'end':
                    return <th key={head.header} style={{ width: '13%' }}>{getCaps(head.header, head.field)}</th>
                  case 'expand':
                    return <th style={{ width: '10px' }}></th>
                  default:
                    return (
                      <th key={head.header + index} className={tab === 'teammates' && 'teammatesHeader'}>
                        {getCaps(head.header, head.field)}
                      </th>
                    )
                }
              })}
            {
              tab && tab === 'goals' && (
                <>
                  <th style={{ width: '16px' }}></th>
                </>
              )
            }
          </tr>
        </thead>
        {data &&
          data.map((row, indexF) => (
            <tbody>
              <tr
                key={indexF}
                className={`${hover && "hover"} ${striped && "striped"} ${parseInt(indexF) % 2 === 0 ? 'striped' : '#FFFFFF'}`}
                style={{ cursor: (tab === 'goals' || tab === 'teammates') ? 'pointer' : 'normal' }}
                onClick={() => tab === 'teammates' && handleDrawerColab(row)}
              >
                {columns.map((col, index) => {
                  let status = {}
                  if (row.status === 'completed') {
                    status.color = objColorsStatus.completed.color
                    status.bgColor = objColorsStatus.completed.bgColor
                  } else {
                    status.color = objColorsStatus.progress.color
                    status.bgColor = objColorsStatus.progress.bgColor
                  }

                  switch (col.field) {
                    case 'expand':
                      return (
                        <td className="button-td">
                          <ExpendableButton isOpen={isOpen} toggle={() => toggle(row?.id)} goalId={row?.id} />
                        </td>
                      )
                    case 'progress':

                      let colorStatus = listColorsStatus.planning
                      let goalProgress = Number(row[col.field])
                      let dateEndSplit = row.end.split('/')
                      let newDateEnd = new Date(dateEndSplit[2] + '-' + dateEndSplit[1] + '-' + dateEndSplit[0])

                      if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEnd)) {
                        colorStatus = listColorsStatus.progress
                      } else if (goalProgress >= 100.00) {
                        colorStatus = listColorsStatus.completed
                      } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEnd) {
                        colorStatus = listColorsStatus.overdue
                      }

                      return (

                        <td
                          key={col.field + index + row.id}
                          onClick={() => {
                            if (tab && tab === 'goals') {
                              return history.push(`/goals/details/${row?.id}`)
                            } else {
                              return
                            }
                          }}
                        >
                          <div className="progress-column-container">
                            <div
                              className="bar-progress"
                              style={{ width: row[col.field] + '%', backgroundColor: colorStatus }}
                            />
                          </div>
                        </td>
                      )
                    case ('objective_progress'):
                      return (

                        <td
                          key={col.field + index + row.id}
                          className="teammatesColumn"
                        >
                          <Tooltip arrow title={row[col.field] + '%'} >
                            <div className="progress-column-container">
                              <div
                                className="bar-progress"
                                style={{ width: row[col.field] + '%', backgroundColor: getProgressColor(row, col) }}
                              />
                            </div>
                          </Tooltip>
                        </td>
                      )

                    case ('kr_progress'):
                      return (

                        <td
                          key={col.field + index + row.id}
                          className="teammatesColumn"
                        >
                          <Tooltip arrow title={row[col.field] + '%'} >
                            <div className="progress-column-container">
                              <div
                                className="bar-progress"
                                style={{ width: row[col.field] + '%', backgroundColor: getProgressColor(row, col) }}
                              />
                            </div>
                          </Tooltip>
                        </td>
                      )

                    case ('activity_progress'):
                      return (

                        <td
                          key={col.field + index + row.id}
                          className="teammatesColumn"
                        >
                          <Tooltip arrow title={row[col.field] + '%'} >
                            <div className="progress-column-container">
                              <div
                                className="bar-progress"
                                style={{ width: row[col.field] + '%', backgroundColor: getProgressColor(row, col) }}
                              />
                            </div>
                          </Tooltip>
                        </td>
                      )
                    case 'activity':
                      return (
                        <td
                          key={col.field + index + row.id}
                          className={tab === 'teammates' && 'teammatesColumn'}
                        >
                          {row[col.field]}
                        </td>
                      )
                    case 'percent':
                      return (
                        <td key={col.field + index + row.id} className="progress">
                          <div className="percent-background" style={{ color: status.color, backgroundColor: status.bgColor }}>
                            {row[col.field]}
                          </div>
                        </td>
                      )
                    default:
                      return (
                        <td
                          key={col.field + index + row.id}
                          className={
                            tab === 'goals'
                              ? `${col.field === 'krs' ? 'krs-column' : ''}`
                              : tab === 'teammates' ? 'teammatesColumn' : ''
                          }
                          onClick={() => {
                            if (tab && tab === 'goals') {
                              return history.push(`/goals/details/${row?.id}`)
                            } else {
                              return
                            }
                          }}
                        >
                          {col.field === 'id' ? Number(indexF) + 1 : row[col.field]}
                        </td>
                      )
                  }
                })}
                {
                  tab && tab === 'goals' && (
                    <td
                      key={'options'}
                      style={{ paddingRight: 4, paddingLeft: 4 }}

                    >
                      <div
                        style={{ display: 'flex', alignItems: 'center', marginRight: 2, gap: 6 }}
                        className={`actions`}

                      >
                        <div
                          onClick={() => history.push(`/goals/details/${row?.id}`)}
                          style={{ cursor: 'pointer', marginTop: '2px' }}
                        >
                          <ArrowSquareIn size={20} color="#4F7091" />
                        </div>
                        <div

                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleClickMenuGoals(e, row)}
                        >
                          <DotsThreeCircleVertical size={20} color="#4F7091" />

                        </div>
                        <div className="removeDialog" id={"remove-" + row?.id}>
                          <div className="content">
                            <div className="message">
                              {/* <IconInfo /> */}
                              {t(t('confirmDialog.title'))}
                            </div>
                            <div className="options">
                              <button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
                              <button onClick={() => { handleArchiveGoal() }}>{t('confirmDialog.yes')}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  )
                }

              </tr>
              {
                isOpen?.isOpen && isOpen?.goalId === row?.id && row?.key_results?.length > 0 && row?.key_results.map((kr, index) => {
                  return <tr key={'kr-' + kr?.id}>
                    <td></td>
                    <td>{kr?.title}</td>
                  </tr>
                })
              }
            </tbody>
          ))}
      </table>

      {data && <Menu
        className="menu-options"
        id={`menu-options-goals`}
        anchorEl={anchorElOptionsGoals}
        keepMounted
        open={Boolean(anchorElOptionsGoals)}
        onClose={handleCloseMenuOptionsGoals}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <MenuItem onClick={() => handleEditGoal()}>{t('modalDetailTask.edit')}</MenuItem>
        <MenuItem style={{ backgroundColor: '#fff' }} onClick={() => {
          toggleRemoveDialog(currentGoal?.id)
          setAnchorElOptionsGoals(null)
        }}>{t('modalDetailTask.archive_goal')}</MenuItem>
      </Menu>
      }


      {data &&
        <Modal
          disableEnforceFocus={true}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`modal-edit-objective ${classes.modal}`}
          open={openModalEditGoal}
          onClose={closeModalEditGoal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backgroundColor: '#205B6F', opacity: 0.8 }
          }}
          sx={{ border: 'none' }}
          style={{ outline: 0 }}
        >
          {/* <Fade in={openModalEditGoal} style={{outline: 0}}> */}
          <Slide direction="down" in={openModalEditGoal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
            <div className={"containerModalFormEditObjective"}>

              <button onClick={closeModalEditGoal} className="close"><IconClose /></button>


              <form onSubmit={handleSubmitGoal}>
                <main>
                  <header>
                    <h3>
                      {t('goals.modal_goal.new_obj')}
                      <span>
                        <IconInfo />
                        <div className="tooltip">
                          <h6>{t('goals.modal_goal.h6')}</h6>
                          <p><strong>{t('goals.modal_goal.item1')}</strong> {t('goals.modal_goal.content1')}</p>
                          <p><strong>{t('goals.modal_goal.item2')}</strong> {t('goals.modal_goal.content2')}</p>
                          <p><strong>{t('goals.modal_goal.item3')}</strong> {t('goals.modal_goal.content3')}</p>
                          <p><strong>{t('goals.modal_goal.item4')}</strong> {t('goals.modal_goal.content4')}</p>
                        </div>
                      </span>
                    </h3>
                  </header>
                  <div className="field">
                    <label htmlFor="title">{t('goals.modal_goal.title')}*</label>
                    <input
                      id="title"
                      type="text"
                      name="title"
                      placeholder={t('goals.modal_goal.title_placeholder')}
                      value={form?.title || ''}
                      onChange={handleChange}
                    />
                    {formError.title &&
                      <div className="error">{formError.title}</div>
                    }
                  </div>

                  <div className="field">
                    <label htmlFor="description">{t('goals.modal_goal.description')}*</label>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="3"
                      placeholder={t('goals.modal_goal.description_placeholder')}
                      value={form.description || ''}
                      onChange={handleChange}
                    />
                    {formError.description &&
                      <div className="error">{formError.description}</div>
                    }
                  </div>

                  <div className="field">
                    <label htmlFor="responsable">{t('goals.modal_goal.responsible')}*</label>
                    <Select
                      id='responsable'
                      defaultValue={() => teammatesOptions?.find(item => item.value == form?.responsable)}
                      name="responsable"
                      options={teammatesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={t('goals.modal_goal.select')}
                      onChange={(e) => setForm({ ...form, responsable: e.value })}
                    />
                    {formError.responsable &&
                      <div className="error">{formError.responsable}</div>
                    }
                  </div>

                  <div className="fieldset">

                    <div className="col">
                      <label htmlFor="period">{t('goals.modal_goal.period')}</label>
                      <select name="period" id="period" value={form.period || ''} onChange={handleChange}>
                        <option value="" disabled selected hidden>{t('goals.modal_goal.select')}</option>
                        <option value="monthly">{t('goals.modal_goal.monthly')}</option>
                        <option value="bimonthly">{t('goals.modal_goal.bimonthly')}</option>
                        <option value="quarterly">{t('goals.modal_goal.quarterly')}</option>
                        <option value="semester">{t('goals.modal_goal.semester')}</option>
                        <option value="yearly">{t('goals.modal_goal.yearly')}</option>
                      </select>

                      {formError.period &&
                        <div className="error">{formError.period}</div>
                      }
                    </div>

                    <div className="col w-50">
                      <label htmlFor="date_start">{t('goals.modal_goal.start')}</label>
                      <input className="w-auto" type="date" id="date_start" name="date_start" value={form.date_start || ''} onChange={handleChange} />
                      {(formError.date_start) &&
                        <div className="error">{formError.date_start}</div>
                      }
                    </div>

                    <div className="col w-50">
                      <label htmlFor="date_end">{t('goals.modal_goal.end')}</label>
                      <input className="w-auto" type="date" id="date_end" name="date_end" value={form.date_end || ''} onChange={handleChange} />
                      {(formError.date_end) &&
                        <div className="error">{formError.date_end}</div>
                      }
                    </div>
                  </div>

                  <footer>
                    <div className="buttonActionsGroup">
                      <button
                        className="btnCancel"
                        type="button"
                        onClick={closeModalEditGoal}
                      >
                        {t('goals.modal_goal.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <CircularProgress size={15} /> : t('goals.modal_goal.save')}
                      </button>
                    </div>
                  </footer>
                </main>
              </form>


            </div>
          </Slide>
          {/* </Fade> */}
        </Modal>
      }

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        handleCloseMenuOptionsGoals={handleCloseMenuOptionsGoals}
      />

      {openColabDetails && (

        <ColabDetailsDrawer
          openColabDetails={openColabDetails}
          closeDrawerColabDetails={closeDrawerColabDetails}
          data={dataEmployee}
          isLoading={isLoading}
          employeeId={employeeId}
          userDetails={userDetails}
        />
      )}

      {/* {data ? null : (<p>No data to show!:</p>)} */}
    </div>
  );
};

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    teammates: state.teammates.payload
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectivesTable));
