import React from 'react'

export default function IconModuleProductivityManagement({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="produtividade" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1731" data-name="Retângulo 1731" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(1.5 1.5)">
            <path id="Caminho_7216" data-name="Caminho 7216" d="M18.25,17.5H5.5V15.138l.15-.113,3.6-3.6,2.288,2.288L16.75,8.538v1.8a.788.788,0,0,0,.638.788.75.75,0,0,0,.862-.75V6.625a.75.75,0,0,0-.75-.75H13.75a.75.75,0,0,0-.75.863.787.787,0,0,0,.788.637h1.95L11.462,11.5,9.25,9.325,5.5,13.075V4.75a.75.75,0,0,0-1.5,0V19H18.25a.75.75,0,1,0,0-1.5Z" transform="translate(-4 -4)" fill="#24677e" />
          </g>
        </g>
      </g>
    </svg>

  )
}
