import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import Moment from 'moment'
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import IconTeam from '../../_icons/IconTeam'
import IconDetail from '../../_icons/IconDetail'
import { alertActions, settingsActions } from '../../../_actions';
import IconInfo from '../../_icons/IconInfo';
import { Menu } from '@material-ui/core';
import { api } from '../../../_helpers/api';
import { authHeader, calcLicensesAvailable } from '../../../_helpers';
import { ListTeamItem } from './ListTeamItem';
import { Box, Typography } from '@mui/material';




function Teams(props) {

	const { t } = useTranslation('common');
	const [isTeamsSelected, setIsTeamsSelected] = useState(true)

	let permissions = props.permissions || {};
	let filter = props.filter || {};
	let userDetails = props.userDetails || {};
	const org_unique_code = props.companyInformation?.verificationCode || ""
	const { inactiveTeammates, alertActions, teammatesActions, teamsActions, setValue } = props

	const [anchorEl, setAnchorEl] = useState(null);
	const [currentInvite, setCurrentInvite] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [groups, setGroups] = useState([]);
	const [colabs, setColabs] = useState([]);
	const [openDialogUpdate, setOpenDialogUpdate] = useState(false)
	const [machineUserId, setMachineUserId] = useState('')
	const [oldGroup, setOldGroup] = useState(null)
	const [newGroup, setNewGroup] = useState(null)

	const open = Boolean(anchorEl);

	const openDialogConfirm = (event, id, type) => {
		setAnchorEl(event.currentTarget);
		setCurrentInvite({
			id: id,
			type: type
		})
	};

	const handleClose = () => {
		setAnchorEl(null);
		setCurrentInvite(null);
	};

	const handleCloseDialogUpdate = () => {
		setOpenDialogUpdate(false)
		setMachineUserId('')
		setOldGroup('')
		setNewGroup('')
	}

	const handleConfirmInvite = () => {
		if (currentInvite?.type === 'group') {
			inviteGroup()
		}

		if (currentInvite?.type === 'teammate') {
			inviteTeammate()
		}

	}

	const inviteGroup = async () => {
		try {

			setSubmitted(true)

			const res = await api.post(`/teams/invite/${currentInvite?.id}`, {}, {
				headers: authHeader()
			});

			if (res?.status === 200) {
				alertActions.success(t('settings.teams.success_invite_group'))
			} else {
				alertActions.error({ Message: t('settings.teams.error_invite_group') })
			}
			handleClose()

			setSubmitted(false)

		} catch (error) {
			alertActions.error({ Message: t('settings.teams.error_invite_group') })
			setSubmitted(false)
		}
	}

	const inviteTeammate = async () => {
		try {

			setSubmitted(true)

			const res = await api.post(`/teammates/invite/${currentInvite?.id}`, {}, {
				headers: authHeader()
			});

			if (res?.status === 200) {
				alertActions.success(t('settings.teams.success_invite_teammate'))
			} else {
				alertActions.error({ Message: t('settings.teams.error_invite_teammate') })
			}

			handleClose()
			setSubmitted(false)

		} catch (error) {
			alertActions.error({ Message: t('settings.teams.error_invite_teammate') })
			setSubmitted(false)
		}
	}


	Moment.locale(t('date.locale'))

	function generateToken(teamUnique, userId) {

		// let orgUnique = userDetails.unique_code;
		let machineUnique = "";


		for (let char of userId.toString()) {
			switch (char) {
				case "0":
					machineUnique += 'a';
					break;
				case "1":
					machineUnique += 'b';
					break;
				case "2":
					machineUnique += 'c';
					break;
				case "3":
					machineUnique += 'd';
					break;
				case "4":
					machineUnique += 'e';
					break;
				case "5":
					machineUnique += 'f';
					break;
				case "6":
					machineUnique += 'g';
					break;
				case "7":
					machineUnique += 'h';
					break;
				case "8":
					machineUnique += 'i';
					break;
				case "9":
					machineUnique += 'j';
					break;
				default:
					break;
			}
		}

		if (machineUnique.length == 1) {
			machineUnique = "aaaa" + machineUnique
		}
		if (machineUnique.length == 2) {
			machineUnique = "aaa" + machineUnique
		}
		if (machineUnique.length == 3) {
			machineUnique = "aa" + machineUnique
		}
		if (machineUnique.length == 4) {
			machineUnique = "a" + machineUnique
		}

		return org_unique_code + "-" + teamUnique + "-" + machineUnique;
	}

	function add(id, type, name, isInactive = false) {

				if (type == "team") {
			props.openDrawer({ type: 'invite', id: id, name: name })
		}

		if (type == "teammate") {
			props.openDrawer({ type: 'edit', id: id, name: name, isInactive: isInactive })
		}

	}

	function openDrawer(type) {

		props.openDrawer({ type: type })


	}

	function findGroupTeammates(array, idGroup) {
		const groupTeammates = [];
		for (let i = 0; i < array.length; i++) {
			const objetoAtual = array[i];
			if (Number(objetoAtual.team_id) === idGroup) {
				groupTeammates.push(objetoAtual);
			}
		}
		return groupTeammates;
	}


	function findGroupChildren(array, idGroupFather) {
		const filhos = [];
		for (let i = 0; i < array.length; i++) {
			const objetoAtual = array[i];
			if (objetoAtual.ancestry && Number(objetoAtual.ancestry) === idGroupFather) {
				filhos.push(objetoAtual);
				const subFilhos = findGroupChildren(array, objetoAtual.id);
				filhos.push(...subFilhos);
			}
		}
		return filhos;
	}

	function expand(id, levelIndex) {
		const groupChildren = findGroupChildren(groups, id)

		const level = `t${levelIndex + 1}`

		let mainEl = document.getElementById("team" + id)

		let cEl = mainEl.querySelector('.expand')

		if (cEl.classList.contains("active")) {
			cEl.classList.remove("active")
			cEl.parentNode.parentNode.classList.remove("active")

			// percorre todos os grupos filhos
			groupChildren.forEach(group => {
				const childGroup = document.getElementById("team" + group.id)

				// remove a classe active e expanded-team da li do grupo
				if (childGroup.classList.contains('expanded-team')) {
					let button = childGroup.querySelector('.expand')

					if (button) {
						button.classList.remove('active')
					}

					childGroup.classList.remove('active')
					childGroup.classList.remove('expanded-team')
					childGroup.classList.remove('last')
				}

				const groupChildTeammates = findGroupTeammates(colabs, group.id)

				// remove a classe active e expanded-team da li do teammate
				groupChildTeammates.forEach(colab => {
					const colabElement = document.getElementById("teammate" + colab.id)

					colabElement.classList.remove('expanded-team')
					colabElement.classList.remove('last')
				})
			});

			//remove a classe expanded-team dos teammates
			const levelColab = `t${levelIndex}`
			let elemsColab = document.querySelectorAll(".team" + id + ".expanded-team." + levelColab + '.hide-display')

			if (elemsColab.length > 0) {
				[].forEach.call(elemsColab, function (el) {
					el.classList.remove("expanded-team");
					el.classList.remove("last");
				});
			}

			let elems2 = document.querySelectorAll(".team" + id + ".tm.expanded.t0")

			if (elems2.length > 0) {
				[].forEach.call(elems2, function (el) {
					el.classList.remove("expanded");
				});
			}
		} else {

			cEl.classList.add("active")
			cEl.parentNode.parentNode.classList.add("active")

			let elems = document.querySelectorAll(".team" + id + "." + level + ".teamList")

			if (elems.length > 0) {
				let n = 0;
				[].forEach.call(elems, function (el) {
					n++;
					el.classList.add("expanded-team");
					if (elems.length == n) {
						el.classList.add("last");
					}
				});
			}

			const levelColab = `t${levelIndex}`
			let elemsColab = document.querySelectorAll(".team" + id + "." + levelColab + ".hide-display")

			if (elemsColab.length > 0) {
				let n = 0;
				[].forEach.call(elemsColab, function (el) {
					n++;
					el.classList.add("expanded-team");
					if (elemsColab.length == n) {
						el.classList.add("last");
					}
				});
			}

			let elems2 = document.querySelectorAll(".team" + id + ".tm.t0")

			if (elems2.length > 0) {

				[].forEach.call(elems2, function (el) {

					el.classList.add("expanded");

				});
			}
		}

	}

	async function updateMachineUserGroup() {
		try {
			await api.put(`/teammates/${machineUserId}/update-group`, {
				old_group_id: oldGroup?.id,
				new_group_id: newGroup?.id
			}, {
				headers: authHeader()
			})

			// const response = await api.get('/teammates', {
			// 	headers: authHeader()
			// })

			// const responseTeams = await api.get('/teams', {
			// 	headers: authHeader()
			// })


			// setColabs(response.data)
			// setGroups(responseTeams.data)
			setOpenDialogUpdate(false)
			teamsActions.getTeams()
			teammatesActions.getTeammates()
			setMachineUserId('')
			setOldGroup(null)
			setNewGroup(null)
			alertActions.success(t('teammates.success.update_group'))
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('teammates.error.update_group'))
		}
	}

	function onDragEnd({ source, destination, draggableId }) {
		if (!destination) {
			return;
		}
		const oldGroup = groups.find(group => group.id === Number(source.droppableId))
		const newGroup = groups.find(group => group.id === Number(destination.droppableId))

		setMachineUserId(draggableId)
		setOldGroup(oldGroup)
		setNewGroup(newGroup)

		if (source.droppableId !== destination.droppableId) {
			setOpenDialogUpdate(true)
		}
	}

	useEffect(() => {
		
		if (props.teams.payload && props.teammates.payload) {
			if (filter.teammates?.length > 0) {
				const teammate = filter.teammates[0]

				const teammateFiltered = props?.teammates?.payload.find(item => {
					return Number(item.id) === Number(teammate.id)
				})

				let teamsAncestryFiltered = []

				if (teammateFiltered && teammateFiltered.ancestry) {
					const ancestryList = teammateFiltered.ancestry.split('/')

					teamsAncestryFiltered = props.teams.payload.filter(item => ancestryList.includes(String(item.id)))
				}

				const teamTeammateFiltered = props.teams.payload.find(item => item.id === teammateFiltered?.team_id)

				const teamsFiltered = [...teamsAncestryFiltered, teamTeammateFiltered]

				setGroups(teamsFiltered)
				setColabs([teammateFiltered])
				// setTeams(teamsFiltered, [teammateFiltered])
			} else {
				setGroups(props.teams.payload)
				setColabs(props.teammates.payload)
				// setTeams(props.teams.payload, props.teammates.payload)
			}
			// getInactiveTeammates()
		}

	}, [props.teams.loading, props.teammates.loading, filter.teammates])


	return (
		<React.Fragment>

			<header className="pageHeader teams h100" style={{ backgroundColor: '#fff' }}>
				<div className='team-container-row'>
					<div className="team-page-buttons-container">
						<button
							className={isTeamsSelected ? 'selected' : 'not-selected'}
							onClick={() => {
								if (!isTeamsSelected) {
									setIsTeamsSelected(!isTeamsSelected)
								}
							}}
						>
							{t('settings.teams.teams')}
						</button>
						<button
							className={isTeamsSelected ? 'not-selected' : 'selected'}
							onClick={() => {
								if (isTeamsSelected) {
									setIsTeamsSelected(!isTeamsSelected)
									setValue("1")
								}
							}}
						>
							{t('teams.inactive_users')}
						</button>
					</div>
					{permissions.CreateTeams && isTeamsSelected &&
						<button onClick={() => {
							openDrawer("create", null)
						}}>
							<IconTeam />
							<span>{t('settings.teams.create')}</span>
						</button>
					}
				</div>
				{
					props.companyInformation &&
					<div className='team-container-row'>
						<div className='licence-info'>{t('settings.licenses_available')}: {calcLicensesAvailable(props.companyInformation)} / {props.companyInformation?.totalLicenses}</div>
					</div>
				}
			</header>
			<div className="teamsListTable" style={{ backgroundColor: '#F5F6F9' }}>
				{isTeamsSelected ? (
					<DragDropContext onDragEnd={onDragEnd}>
						<ul>
							<li className="header" style={{ backgroundColor: '#FFFFFF' }}>
								<div className="team">{t('settings.teams.team')}</div>
								<div className="subteams">{t('settings.teams.subteams')}</div>
								<div className="teammates">{t('settings.teams.teammates')}</div>
								<div className="email">Email</div>
								<div className="token">Token</div>
								<div className="actions"></div>
							</li>

							{groups.map(t0 => (
								!t0.ancestry && (
									<>
										<Droppable droppableId={String(t0.id)} key={t0.id}>
											{(provided, snapshot) => (
												<ListTeamItem
													add={add}
													groups={groups}
													openDialogConfirm={openDialogConfirm}
													expand={expand}
													currentGroup={t0}
													currentIndex='0'
													prevGroupId={t0.id}
													innerRef={provided.innerRef}
													provided={provided}
													colabs={colabs}
													generateToken={generateToken}
													snapshot={snapshot}
												/>
											)}
										</Droppable>

										{groups.map(t1 => (
											t1.ancestry == t0.id && (
												<>
													<Droppable droppableId={String(t1.id)} key={t1.id}>
														{(provided, snapshot) => (
															<ListTeamItem
																add={add}
																groups={groups}
																openDialogConfirm={openDialogConfirm}
																expand={expand}
																currentGroup={t1}
																currentIndex='1'
																prevGroupId={t0.id}
																innerRef={provided.innerRef}
																provided={provided}
																colabs={colabs}
																generateToken={generateToken}
															/>
														)}
													</Droppable>

													{groups.map(t2 => (
														t2.ancestry == t1.id && (
															<>
																<Droppable droppableId={String(t2.id)} key={t2.id}>
																	{(provided, snapshot) => (
																		<ListTeamItem
																			add={add}
																			groups={groups}
																			openDialogConfirm={openDialogConfirm}
																			expand={expand}
																			currentGroup={t2}
																			currentIndex='2'
																			prevGroupId={t1.id}
																			innerRef={provided.innerRef}
																			provided={provided}
																			colabs={colabs}
																			generateToken={generateToken}
																		/>
																	)}
																</Droppable>

																{groups.map(t3 => (
																	t3.ancestry == t2.id && (
																		<>
																			<Droppable droppableId={String(t3.id)} key={t3.id}>
																				{(provided, snapshot) => (
																					<ListTeamItem
																						add={add}
																						groups={groups}
																						openDialogConfirm={openDialogConfirm}
																						expand={expand}
																						currentGroup={t3}
																						currentIndex='3'
																						prevGroupId={t2.id}
																						innerRef={provided.innerRef}
																						provided={provided}
																						colabs={colabs}
																						generateToken={generateToken}
																					/>
																				)}
																			</Droppable>

																			{groups.map(t4 => (
																				t4.ancestry == t3.id && (
																					<>
																						<Droppable droppableId={String(t4.id)} key={t4.id}>
																							{(provided, snapshot) => (
																								<ListTeamItem
																									add={add}
																									groups={groups}
																									openDialogConfirm={openDialogConfirm}
																									expand={expand}
																									currentGroup={t4}
																									currentIndex='4'
																									prevGroupId={t3.id}
																									innerRef={provided.innerRef}
																									provided={provided}
																									colabs={colabs}
																									generateToken={generateToken}
																								/>
																							)}
																						</Droppable>

																						{groups.map(t5 => (
																							t5.ancestry == t4.id && (
																								<>
																									<Droppable droppableId={String(t5.id)} key={t5.id}>
																										{(provided, snapshot) => (
																											<ListTeamItem
																												add={add}
																												groups={groups}
																												openDialogConfirm={openDialogConfirm}
																												expand={expand}
																												currentGroup={t5}
																												currentIndex='5'
																												prevGroupId={t4.id}
																												innerRef={provided.innerRef}
																												provided={provided}
																												colabs={colabs}
																												generateToken={generateToken}
																											/>
																										)}
																									</Droppable>

																									{groups.map(t6 => (
																										t6.ancestry == t5.id && (
																											<>
																												<Droppable droppableId={String(t6.id)} key={t6.id}>
																													{(provided, snapshot) => (
																														<ListTeamItem
																															add={add}
																															groups={groups}
																															openDialogConfirm={openDialogConfirm}
																															expand={expand}
																															currentGroup={t6}
																															currentIndex='6'
																															prevGroupId={t5.id}
																															innerRef={provided.innerRef}
																															provided={provided}
																															colabs={colabs}
																															generateToken={generateToken}
																														/>
																													)}
																												</Droppable>

																												{groups.map(t7 => (
																													t7.ancestry == t6.id && (
																														<>
																															<Droppable droppableId={String(t7.id)} key={t7.id}>
																																{(provided, snapshot) => (
																																	<ListTeamItem
																																		add={add}
																																		groups={groups}
																																		openDialogConfirm={openDialogConfirm}
																																		expand={expand}
																																		currentGroup={t7}
																																		currentIndex='7'
																																		prevGroupId={t6.id}
																																		innerRef={provided.innerRef}
																																		provided={provided}
																																		colabs={colabs}
																																		generateToken={generateToken}
																																	/>
																																)}
																															</Droppable>
																														</>
																													)
																												))}
																											</>
																										)
																									))}
																								</>
																							)
																						))}
																					</>
																				)
																			))}
																		</>
																	)
																))}
															</>
														)
													))}
												</>
											)
										))}
									</>
								)
							))}
						</ul>
					</DragDropContext>


				) : (
					<table className="table-inactive-teammates" style={{ backgroundColor: '#fff' }}>
						<thead>
							<tr>
								<th style={{ textAlign: 'left', width: '50%' }}>
									{t('teams.teammates')} ({inactiveTeammates.length})
								</th>
								<th style={{ textAlign: 'left' }}>{t('teams.last_team')}</th>
								<th style={{ textAlign: 'left' }}>Email</th>
								<th ></th>
							</tr>
						</thead>
						<tbody>
							{inactiveTeammates?.map(item => (
								<tr key={item.id}>
									<td>{item.name}</td>
									<td>{item.group_name ? item.group_name : '---'}</td>
									<td>{item.email}</td>
									<td style={{ textAlign: 'right' }}>
										<button
											className="inactive-details-button"
											onClick={() => {
												add(item.id, 'teammate', item.name, true)
											}}
										>
											<IconDetail />
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
				{
					currentInvite && (
						<Menu
							className="dialogConfirmInviteGroup"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}

							keepMounted
							getContentAnchorEl={null}
							anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
							transformOrigin={{ vertical: 'top', horizontal: 'right', }}
						>
							<div className="bodyInviteDialog">
								<div className="content">
									<div className="message"><IconInfo />{currentInvite?.type === 'group' ? t('settings.teams.info_invite_team') : t('settings.teams.info_invite_teammate')}</div>
									<div className="options">
										<button disabled={submitted} onClick={handleClose}>{t('no')}</button>
										<button disabled={submitted} onClick={() => { handleConfirmInvite() }}>{t('yes')}</button>
									</div>
								</div>
							</div>
						</Menu>
					)
				}

				{openDialogUpdate && (
					<Menu
						className="dialogUpdateGroup"
						open={openDialogUpdate}
						onClose={handleCloseDialogUpdate}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}

						keepMounted
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: "center", horizontal: "center" }}
						transformOrigin={{ vertical: 'center', horizontal: 'center', }}
					>
						<div className="bodyInviteDialog" style={{display:"flex",flexDirection:"column"}}>
							<div className="content">
								<div className="message" >
									
									<IconInfo />
									{t('teammates.alert_update_group')}									
									<br />
									<strong style={{ display: 'contents' }}>
										{`\n${oldGroup.name} -> ${newGroup.name}`}
									</strong>
								</div>

								<Box display="flex" flexDirection="column" gap="4px"  maxWidth={'320px'} paddingTop={"15px"}>
										<Typography fontSize="12px" fontWeight={500} color="#FF737D" componente="span">{t('teams.alerts.warning_01')}</Typography>
										<Typography fontSize="11px" variant="caption" color="#FF737D" componente="span">{t('teams.alerts.warning_02')}</Typography>
										<Typography fontSize="11px" variant="caption" color="#FF737D" componente="span">{t('teams.alerts.warning_03')}</Typography>
								</Box>
								<div className="options">
									<button disabled={submitted} onClick={handleCloseDialogUpdate}>{t('no')}</button>
									<button disabled={submitted} onClick={() => updateMachineUserGroup()}>{t('yes')}</button>
								</div>
							</div>
						</div>
					</Menu>
				)}
			</div>
		</React.Fragment>

	)
};

function mapStateToProps(state) {

	return {
		token: state.authentication.user.accessToken,
		permissions: state.authentication.permissions,
		userDetails: state.authentication.userDetails,
		settings: state.settings,
		teams: state.teams,
		teammates: state.teammates,
		filter: state.filter,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		settingsActions: bindActionCreators(settingsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teams));
