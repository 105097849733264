import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Box, IconButton, OutlinedInput, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HouseSimple, Users } from 'phosphor-react';
import './CustomSelect.scss'
import { Delete, Highlight, HighlightOff } from '@material-ui/icons';


export default function CustomSelect({
  initialValues = [],
  value = "",
  options = [],
  widthControl = '100px',
  isMulti = true,
  showIconRoom = false,
  onChange = () => {},
  setSelectedOptions = () => {}
}) {


  const { t, i18n } = useTranslation('common');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: widthControl,
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        backgroundColor: "#1E6B7F",
        marginTop: '6px'
      },
    },

  };

  return (
    <FormControl style={{ width: widthControl, position: 'relative' }}>
      {
        isMulti
          ?
          <Select
            className={'customSelectMulti'}
            multiple
            displayEmpty
            value={initialValues}
            onChange={onChange}
            input={
              <OutlinedInput
                style={{ width: '100%', height: '45px', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
              />
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{t('placeholders.select')}</em>;
              }

              return (
                <Box display={'flex'}>
                  <Box
                    display="flex"
                    width="fit-content"
                    alignItems="center"
                    justifyContent="center"
                    px="8px"
                    gap="4px"
                    bgcolor="#E4EAEF"
                    borderRadius="2px"
                    height={'28px'}
                  >
                    {
                      showIconRoom ? <HouseSimple size={18} color="#4F7091" /> : <Users size={18} color="#4F7091" />
                    }

                    {selected.length}
                  </Box>
                </Box>

              );
            }}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>{t('placeholders.select')}</em>
            </MenuItem>
            <MenuItem key={0} value={0} style={{ backgroundColor: "#1E6B7F", color: "#fefefe" }}>                
                <Checkbox checked={initialValues.indexOf(0) > -1} style={{ color: "#fff", '&.Mui-checked': { color: "#fff" } }} />
                <ListItemText primary={t('placeholders.select_all')} />
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} style={{ backgroundColor: "#1E6B7F", color: "#fefefe" }}>
                
                <Checkbox checked={initialValues.indexOf(option.value) > -1} style={{ color: "#fff", '&.Mui-checked': { color: "#fff" } }} />
                <ListItemText primary={option.name.length > 35 ? option.name.slice(0,34)+"...": option.name} />
                
              </MenuItem>
            ))}
          </Select>
          :
          <Box>Teste</Box>
      }
      {
        initialValues.length > 0 &&
        <Box position={'absolute'} top={'10px'} left={'56px'}>
          <IconButton aria-label="delete" size="small" onClick={() => setSelectedOptions([])}>
            <HighlightOff fontSize="small" />
          </IconButton>
        </Box>
      }
    </FormControl>
  );
}
