// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._84MvypTQ4cl8lQ5wWtf\\+PQ\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 180px;\n}\n\n.LNu5HrrT6fPmcEBWJ5NaqQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/_components/_shared/inventory/software/CardsSoftware.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF","sourcesContent":[".cardContainer {\n  display: flex;\n  flex: 1;\n  min-width: 180px;\n}\n\n.cardHeader {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "_84MvypTQ4cl8lQ5wWtf+PQ==",
	"cardHeader": "LNu5HrrT6fPmcEBWJ5NaqQ=="
};
export default ___CSS_LOADER_EXPORT___;
