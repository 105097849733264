import { Backdrop, Modal, Slide, Table, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Avatar, Box, Button, Drawer, IconButton, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { ArrowRight, PencilSimple, PencilSimpleLine, Warning } from 'phosphor-react';
import React, { Fragment, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';



import { authHeader, befectiveFormatDate, calcDurationBTWArrPeriodsInMinutes, delay, genPeriodSimpleArray, getAvatarUrl, getCorrectPeriod, getDayWeek, getMonths, getRemainingPoints, hoursToMinutes, numberToPercentString } from '../../../_helpers';

import { api } from '../../../_helpers/api';
import IconIn from '../../_icons/IconIn';
import IconOut from '../../_icons/IconOut';

import { useDatePickerFilter } from '../../../contexts/DatePickerFilterContext';
import { DrawerSolicitations } from '../dashboard/DrawerSolicitations';
import style from './MonthResume.module.scss';
import { ModalJustify } from '../dashboard/ModalJustify';
import IconShare from '../../_icons/IconShare';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 'max-content !important',
    height: '32px !important',
    backgroundColor: '#FDBE2B !important',
    borderRadius: '0px !important',
    cursor: 'pointer',
    fontWeight: '600 !important',
    border: 'none !important',
    color: '#106276 !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    transition: '0.2s',
    lineHeight: 'initial !important',
    letterSpacing: 'initial !important',

    '&:hover': {
      backgroundColor: '#FDBE2B !important',
      boxShadow: '0px 0px 4px 0px #2B2B2B55 !important',
    },
    '& > span': {
      fontSize: '13px !important',
      display: 'flex !important',
      flexWrap: 'nowrap !important'
    }
  },
  buttonSigned: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 'max-content !important',
    height: '32px !important',
    backgroundColor: '#22BEAD !important',
    borderRadius: '0px !important',
    cursor: 'pointer',
    fontWeight: '600 !important',
    border: 'none !important',
    color: '#106276 !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    transition: '0.2s',
    lineHeight: 'initial !important',
    letterSpacing: 'initial !important',

    '&:hover': {
      backgroundColor: '#22BEAD !important',
      boxShadow: '0px 0px 4px 0px #2B2B2B55 !important',
    },
    '& > span': {
      fontSize: '13px !important',
      display: 'flex !important',
      flexWrap: 'nowrap !important'
    }
  }

}));

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

// const days_of_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export function MonthResume({
  alertActions,
  userDetails,
  colabHistory,
  isCurrentMonth,
  lang,
  getColabHistoryPoints, 
  reasons,
  handleCloseModalExport,
  handleOpenModalExport
}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const { startDate, endDate } = useDatePickerFilter()

  const [pointDetails, setPointDetails] = useState(null)
  const [openDrawerDetailsDay, setOpenDrawerDetailsDay] = useState(false)
  const [openModalSlider, setOpenModalSlider] = useState(false)
  const [checked, setChecked] = useState(false)
  const [solicitations, setSolicitations] = useState([]);
  const [openDrawerSolicitations, setOpenDrawerSolicitations] = useState(false)

  const [openModalJustify, setOpenModalJustify] = useState(false)
  const [pointToJustify, setPointToJustify] = useState(null)
  const [periodToJustify, setPeriodToJustify] = useState(null)
  const [dayToJustify, setDayToJustify] = useState(null)
  const [summariesDateToJustify, setSummariesDateToJustify] = useState(null)
  const [typePointToJustify, setTypePointToJustify] = useState(null)

  function checkIfIsAfterTime(period, day, checkStart = true) {
    const now = moment();
    const dayFormatted = day.split('T')[0]

    if (checkStart) {
      const start = moment(`${dayFormatted}T${period.start_time}`);

      if (now.isAfter(start)) {
        return true
      } else {
        return false
      }
    } else {
      const end = moment(`${dayFormatted}T${period.end_time}`);

      if (now.isAfter(end)) {
        return true
      } else {
        return false
      }
    }
  }

  function isWithinDateRange(start_date, add_days = 0) {
    const start = moment(start_date);
    let end = moment(start_date);
    const now = moment();

    end = end.add(add_days, 'days');

    return now.isSameOrAfter(start, 'day') && now.isSameOrBefore(end, 'day');
  }


  function calcDurationInMinutes(start, end) {
    const startHour = moment(start, "HH:mm:ss");
    const endHour = moment(end, "HH:mm:ss");
    const diffInMiliSeconds = endHour.diff(startHour);
    const durationInMinutes = moment.duration(diffInMiliSeconds).asMinutes();
    return durationInMinutes;
  }
  
  function handleOpenModalJustify(periods_by_day, point, day, summaries_date_id, isCheckin) {

    if (isCheckin) {
      setTypePointToJustify("IN")
    } else {
      setTypePointToJustify("OUT")
    }
    if (point !== null) {
      setPointToJustify(point)
    }
    const p1 = getCorrectPeriod(point, periods_by_day)

    setPeriodToJustify({
      value: p1
    })
    setDayToJustify(day)
    setSummariesDateToJustify(summaries_date_id)

    setOpenModalJustify(true)
  }

  function handleOpenDrawerDetailsDay(day) {
    getPointDetails(day)
    setOpenDrawerDetailsDay(true)
  }

  async function getPointDetails(day) {
    try {
      const response = await api.get(`/checkin/point/details?owner_id=${userDetails.mu_id}&group_id=${userDetails.group_id}&day=${day}`, {
        headers: authHeader()
      })

      setPointDetails(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error('Erro ao carregar detalhes do ponto!')
    }
  }

  async function handleOpenDrawerSolicitations() {
    try {
      const response = await api.get(`/checkin/colab/solicitations/${userDetails.mu_id}`, {
        headers: authHeader()
      })

      setSolicitations(response.data)
      setOpenDrawerSolicitations(true)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_solicitations_points_error"))
    }
  }

  const handleCheck = async () => {
    try {
      await api.post('/checkin/colab-historic/sign', {
        month: months[colabHistory.mes - 1],
        month_number: colabHistory.mes,
        year: colabHistory.ano,
        mu_id: userDetails.mu_id,
        moment: moment().format()
      }, {
        headers: authHeader()
      })

      setChecked(!checked)
      await delay(600)
      setOpenModalSlider(false)

      getColabHistoryPoints(startDate, endDate)

      alertActions.success("Histórico assinado com sucesso!")
    } catch (error) {
      console.log(error)
      //alertActions.error(t("Erro ao assinar histórico!"))
      setOpenModalSlider(false)
    }
  }

  return (
    <>
      <Box marginBottom={'10px'}>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#fff"
          px={3}
          py={2}
          mb="2px"
          position="relative"
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" borderRight="1px solid #E4EAEF" paddingRight="30px">
              <Typography color="#1E6B7F" component="span" fontSize={18} fontWeight={500} mb="12px">
                {getMonths(colabHistory.mes - 1, t)} {colabHistory.ano}
              </Typography>

              <Box display="flex" flexDirection="column" gap="3px">
                <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                  {/* {colabHistory.periods.length > 0 && (
                    `
                    ${t('checkin.historical.header.working')}
                    ${getDayWeek(colabHistory?.periods[0]?.work_days[0])} 
                    ${t('checkin.historical.header.to')}
                    ${getDayWeek(colabHistory?.periods[0]?.work_days[colabHistory?.periods[0]?.work_days?.length - 1])}: 
                    ${colabHistory?.periods?.reduce((acc, period) => {
                      return acc + period.start_time + ' - ' + period.end_time + ' / '
                    }, '').slice(0, -2)}
                  `
                  )} */}
                </Typography>
                <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                  {t('checkin.historical.header.weekends')}: 0
                </Typography>
                <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                  {t('checkin.historical.header.working_days')}: {colabHistory.working_days} ({!colabHistory.is_sign ? colabHistory.total_hours_should_work :colabHistory.sign_obj.total_hours} horas)
                </Typography>
                <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                  {t('checkin.historical.header.closure')}: {befectiveFormatDate(colabHistory.closing_month, lang, "S")}
                </Typography>
                {/* <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
              {t('checkin.historical.header.responsible')}: John Rambo
              </Typography> */}
              </Box>
            </Box>

            <Box display="flex" gap="24px" marginLeft="30px" alignItems="center" marginRight="40px">
              <div className={style.circularProgressGeneral}>
                <CircularProgressbarWithChildren
                  value={ !colabHistory.is_sign ? Number(hoursToMinutes(colabHistory.worked_hours) * 100 / hoursToMinutes(colabHistory.total_hours_should_work)) : Number(hoursToMinutes(colabHistory.sign_obj.worked_hours) * 100 / hoursToMinutes(colabHistory.sign_obj.total_hours))}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: '#22BEAD',
                    trailColor: "#eee",
                  })}>
                  <span style={{ fontSize: 16, fontWeight: 700, color: '#22BEAD' }}>
                    {`${!colabHistory.is_sign ? numberToPercentString(Number(hoursToMinutes(colabHistory.worked_hours) * 100 / hoursToMinutes(colabHistory.total_hours_should_work))) : numberToPercentString(Number(hoursToMinutes(colabHistory.sign_obj.worked_hours) * 100 / hoursToMinutes(colabHistory.sign_obj.total_hours))) }`}
                  </span>
                </CircularProgressbarWithChildren>
              </div>

              <Box display="flex" flexDirection="column">
                <Typography
                  color="#22BEAD"
                  fontSize={14}
                  fontWeight="700"
                >
                  {!colabHistory.is_sign ? `${colabHistory?.worked_hours} / ${colabHistory?.total_hours_should_work} hs` : `${colabHistory?.sign_obj.worked_hours} / ${colabHistory?.sign_obj.total_hours} hs`}
                  {/* {!colabHistory.is_sign ? colabHistory.worked_hours :colabHistory.sign_obj.worked_hours} / {!colabHistory.is_sign ? colabHistory.total_hours_should_work :colabHistory.sign_obj.total_hours} hs */}
                </Typography>
                <Typography
                  color="#22BEAD"
                  fontSize={12}
                  fontWeight="400"
                >
                  {t('checkin.historical.header.worked_time')}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" gap="14px" flexDirection="column">
              <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #22BEAD">
                <Typography fontSize={14} color="#22BEAD" fontWeight={700}>{!colabHistory.is_sign ? colabHistory.overtime :colabHistory.sign_obj.overtime}</Typography>
                <Typography fontSize={10} color="#22BEAD" fontWeight={500}>{t('checkin.historical.header.overtime')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #FF7A90">
                <Typography fontSize={14} color="#FF7A90" fontWeight={700}>{!colabHistory.is_sign ? colabHistory.missing_hours :colabHistory.sign_obj.missing_hours }</Typography>
                <Typography fontSize={10} color="#FF7A90" fontWeight={500}>{t('checkin.historical.header.missing')}</Typography>
              </Box>

              {

                String(colabHistory.balance).includes('-') ?

                  <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #FF7A90">
                    <Typography fontSize={14} color="#FF7A90" fontWeight={700}>{colabHistory.balance}</Typography>
                    <Typography fontSize={10} color="#FF7A90" fontWeight={500}>{t('checkin.historical.header.balance_hours')}</Typography>
                  </Box> :

                  <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #22BEAD">
                    <Typography fontSize={14} color="#22BEAD" fontWeight={700}>{colabHistory.balance}</Typography>
                    <Typography fontSize={10} color="#22BEAD" fontWeight={500}>{t('checkin.historical.header.balance_hours')}</Typography>
                  </Box>
                }
            </Box>

            {isWithinDateRange(colabHistory.closing_month, colabHistory.signature_date) && !colabHistory.is_sign && (
              <Box display="flex" gap="3px" alignItems="center" position="absolute" top="24px" right="24px">
                <Box
                  sx={{ border: '1px solid #CDDAE5' }}
                  width="30px"
                  height="30px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Warning size={16} weight="fill" fill='#98ACC1' />
                </Box>

                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<PencilSimple size={12} weight='fill' />}
                  style={{
                    width: "100px",
                    minWidth: '100px',
                    padding: '0',
                    minHeight: '0',
                    borderRadius: '0',
                    backgroundColor: "#FDBE2B",
                    boxShadow: 'none'
                  }}
                  onClick={() => setOpenModalSlider(true)}
                >
                  {t('checkin.historical.header.btn_sign_point')}
                </Button>
              </Box>
            )}

            {colabHistory.is_sign && (
              <Box display="flex" gap="16px" alignItems="center" position="absolute" top="24px" right="24px">
                <button 
                  className={style.exportButton} 
                  type='button' 
                  onClick={(e) => handleOpenModalExport(e, colabHistory)}>
                  <IconShare />
                </button>
                <Button
                  variant="contained"
                  className={classes.buttonSigned}
                  startIcon={<PencilSimpleLine size={12} weight='fill' />}
                  style={{
                    width: "100px",
                    minWidth: '100px',
                    padding: '0',
                    minHeight: '0',
                    borderRadius: '0',
                    backgroundColor: "#22BEAD",
                    boxShadow: 'none'
                  }}
                >
                  {t('checkin.historical.signed')}
                </Button>
              </Box>
            )}

          </Stack>

          {/* {colabHistory.is_sign && (
          <Stack direction="column" gap="8px" borderTop="1px solid #E4EAEF" mt="25px" paddingTop="25px" paddingBottom="5px">
            <Box display="flex" alignItems="center" gap="6px">
              <PencilSimpleLine size={12} weight="fill" fill="#22BEAD" />
              <Typography fontSize={12} color="#22BEAD" fontWeight={500}>
                Assinado dia 31/05/2023 às 14:10 - IP: 192.168.0.0
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="6px">
              <CheckCircle size={12} color="#187DFF" />
              <Typography fontSize={12} color="#187DFF" fontWeight={500}>
                Ajuste realizado
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="6px">
              <Warning size={12} color="#187DFF" />
              <Typography fontSize={12} color="#187DFF" fontWeight={500}>
                Ajuste realizado
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="6px">
              <Circle size={12} color="#4F7091" />
              <Typography fontSize={12} color="#4F7091" fontWeight={500}>
                Fechamento de ponto recebido
              </Typography>
            </Box>
          </Stack>
        )} */}
        </Box>

        <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead>
              <TableRow sx={{ textTransform: 'uppercase' }}>
                <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px' }} width='8%'>{t("checkin.dashboard.checkin_table.col_date")}</TableCell>
                {/* {colabHistory.array_to_map?.length > 0 && colabHistory.array_to_map.map((_, index) => (
                  <Fragment key={index}>
                    <TableCell className={style.tableHeaderTd} align="center" width="5%">{t("checkin.dashboard.checkin_table.col_in")}</TableCell>
                    <TableCell className={style.tableHeaderTd} align="center" width="7%">{t("checkin.dashboard.checkin_table.col_out")}</TableCell>
                    <TableCell className={style.tableHeaderTd} align="center" width="3%"></TableCell>
                  </Fragment>
                ))} */}
                <TableCell className={style.tableHeaderTd} align="left">{t("checkin.control_points.table.col_points")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="7%">{t("checkin.dashboard.checkin_table.col_time")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="5%">{t("checkin.dashboard.checkin_table.col_balance")}</TableCell>
                <TableCell className={style.tableHeaderTd} style={{ paddingRight: '20px', width: '5%' }} align="right">
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {colabHistory.points_by_day?.map(dayInfos => (
                <TableRow key={dayInfos.day_ref}>
                  <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                    {befectiveFormatDate(dayInfos.day_ref, lang, "S")}
                  </TableCell>

                  <TableCell style={{ borderBottom: '2px solid #F5F6F9' }}>
                    <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                      {
                        dayInfos.points.length > 0
                        &&
                        <>
                          {dayInfos.points.map((point, index) => (

                            <Box
                              key={`${point.moment} ${index}`}
                              style={{ padding: 0 }}
                              align="center"
                            >
                              <Tooltip
                                PopperProps={{
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      backgroundColor: "#FDBE2B",
                                      color: "black",
                                      fontWeight: 500,
                                      width: '90px'
                                    }
                                  }
                                }}
                                title={
                                  [6, 7].includes(point.status_id)
                                    ? point.status_id === 6
                                      ? t("checkin.tooltip.adjust_manual_success")
                                      : t("checkin.tooltip.waiting_return")
                                    : ""
                                }
                                placement="right"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap="4px"
                                  height={35}
                                  minWidth={'54px'}
                                  maxWidth="76px"
                                  margin="0 auto"
                                  paddingX={'2px'}
                                  className={[
                                    style.icon,
                                    ([3, 4, 5, 6, 7].includes(point.status_id) && !colabHistory.is_sign) && style.iconHover
                                  ].join(' ')}
                                  onClick={() => {
                                    ([3, 4].includes(point.status_id) && !colabHistory.is_sign) && handleOpenModalJustify(dayInfos.periods, point, dayInfos.day_ref, dayInfos.id, index === 0)
                                  }}
                                >
                                  {point.type === 'IN' ? (
                                    <IconIn fill={statusColors[point.status_id - 1]} />
                                  ) : (
                                    <IconOut fill={statusColors[point.status_id - 1]} />
                                  )}
                                  <Typography
                                    color={
                                      [6, 7].includes(point.status_id)
                                        ? "#187DFF"
                                        : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                                    }
                                    component="span"
                                    fontSize={12}
                                    lineHeight="initial"
                                  >
                                    {[5, 6, 7].includes(point.status_id) ? (
                                      point.status_id === 7 ? (
                                        t("checkin.status.send")
                                      ) : point.status_id === 6 ? (
                                        point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                      ) : (
                                        t("checkin.status.refused")
                                      )
                                    ) : (
                                      point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                    )}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            </Box>

                          ))}

                          {
                            getRemainingPoints(dayInfos).map((item, index) => (

                              <Box
                                key={index}
                                style={{ padding: 0 }}
                                align="center"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap="4px"
                                  minWidth={'54px'}
                                  maxWidth="76px"
                                  paddingX={'2px'}
                                  margin="0 auto"
                                  className={[
                                    style.icon,
                                    !colabHistory.is_sign && style.iconHover
                                  ].join(' ')}
                                  height={35}
                                  onClick={() => !colabHistory.is_sign && handleOpenModalJustify(dayInfos.periods, null, dayInfos.day_ref, dayInfos.id, index === 0)}
                                >
                                  {item?.type === 'IN' ? (
                                    <IconIn fill={"#C1CDDA"} />
                                  ) : (
                                    <IconOut fill={"#C1CDDA"} />
                                  )}
                                  <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                                    --:--
                                  </Typography>
                                </Box>
                              </Box>


                            ))
                          }
                        </>
                      }
                    </Box>
                  </TableCell>

                  <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="right">
                    {dayInfos.hours_amount}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '2px solid #F5F6F9',
                      color: hoursToMinutes(dayInfos.balance) < 0 ? '#FF5151' : '#22BEAD',
                      fontSize: '12px',
                    }}
                    align="right"
                  >
                    {dayInfos.balance}
                  </TableCell>
                  <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9' }} align="right">
                    <Box className={style.arrow} onClick={() => handleOpenDrawerDetailsDay(dayInfos.day_ref)}>
                      <ArrowRight size={18} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* 
        <DrawerSolicitations
          userDetails={userDetails}
          openDrawerSolicitations={openDrawerSolicitations}
          setOpenDrawerSolicitations={setOpenDrawerSolicitations}
        /> */}

          {openModalJustify && (
            <ModalJustify
              alertActions={alertActions}
              lang={lang}
              dayToJustify={dayToJustify}
              openModalJustify={openModalJustify}
              periodToJustify={periodToJustify}
              pointToJustify={pointToJustify}
              reasons={reasons}
              setDayToJustify={setDayToJustify}
              setOpenModalJustify={setOpenModalJustify}
              setPointToJustify={setPointToJustify}
              setSummariesDateToJustify={setSummariesDateToJustify}
              setTypePointToJustify={setTypePointToJustify}
              summariesDateToJustify={summariesDateToJustify}
              typePointToJustify={typePointToJustify}
              getColabPoints={() => getColabHistoryPoints(startDate, endDate)}
            />
          )}

          {pointDetails && openDrawerDetailsDay && (
            <Drawer
              anchor="right"
              open={openDrawerDetailsDay}
              onClose={() => setOpenDrawerDetailsDay(false)}
              PaperProps={{
                style: {
                  padding: '0px 12px',
                  width: '350px'
                }
              }}
            >
              <div style={{ position: 'absolute', right: 4, top: 4 }}>
                <IconButton aria-label="close" onClick={() => setOpenDrawerDetailsDay(false)}>
                  <Close fontSize="medium" />
                </IconButton>
              </div>

              <Box paddingTop="40px" paddingBottom="20px" display="flex" flexDirection="column" flex="1" justifyContent="space-between">

                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap="4px" flexDirection="column">
                      <Typography fontSize={16} color="#1E6B7F" fontWeight={600}>
                        {t("checkin.drawer_details_day.register_day") + " " + befectiveFormatDate(pointDetails.day, lang, "S")}
                      </Typography>
                      <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                        {t("checkin.drawer_details_day.regular_business_hours") + " " + pointDetails.hours_should_work}
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" gap={4} mb={2} px="16px">
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={pointDetails.hours_amount < pointDetails.hours_should_work ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.hours_amount}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>{t("checkin.drawer_details_day.worked_hours")}</Typography>
                      </Box>
                      <Box display="flex" gap="4px" flexDirection="column">
                        <Typography
                          fontSize={24}
                          color={hoursToMinutes(pointDetails.balance) < 0 ? "#FF7A90" : "#22BEAD"}
                          fontWeight={500}
                        >
                          {pointDetails.balance}
                        </Typography>
                        <Typography fontSize={12} color="#4F7091" fontWeight={400}>
                          {hoursToMinutes(pointDetails.balance) < 0 ? (
                            t("checkin.drawer_details_day.missing_hours")
                          ) : (
                            t("checkin.drawer_details_day.overtime_hours")
                          )}

                        </Typography>
                      </Box>

                    </Box>

                  </Box>

                  
                {pointDetails && (
                  <Box key={pointDetails.id} display="flex" flexDirection="column" className={style.periodBox}>
                    <Box
                      display="flex"
                      bgcolor={
                        (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                          ? "#FFE6E6"
                          : "#DBF5F2"
                      }
                      paddingX={2}
                      paddingY={1}
                      borderRadius="4px"
                    >
                      <Typography
                        color={
                          (calcDurationBTWArrPeriodsInMinutes(pointDetails.periods) / 60) < pointDetails.hours_should_work
                            ? "#FF7A90"
                            : "#22BEAD"
                        }
                        fontSize={12}
                      >
                        {t("checkin.drawer_details_day.period_of")}{' '}
                        {getCorrectPeriod(null,pointDetails.periods)}
                        
                      </Typography>
                    </Box>

                    {pointDetails.points.map(point => (
                      <Box key={point.id} display="flex" flexDirection="column" paddingX={2} mt={2}>
                        <Box display="flex" gap="8px">
                          <Box>
                            {point.type === 'IN' ? (
                              <IconIn fill={statusColors[point.status_id - 1]} />
                            ) : (
                              <IconOut fill={statusColors[point.status_id - 1]} />
                            )}
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography component="span" lineHeight={1} fontSize={14} color="#1E6B7F">
                              <Typography component="span" color="#1E6B7F" lineHeight={1} fontSize={14} fontWeight={600}>
                                {point.time.split(':')[0] + ":" + point.time.split(':')[1]}{' '}
                              </Typography>
                              {point.type === 'IN' ? (
                                t("checkin.drawer_details_day.entry")
                              ) : (
                                t("checkin.drawer_details_day.exit")
                              )}
                            </Typography>
                            <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                            <Typography fontSize={12} color="#4F7091">{t("checkin.drawer_details_day.receipt") + ": " + point?.receipt_token}</Typography>
                          </Box>
                        </ Box>

                        {point.solicitations.map(solicitation => (
                          solicitation.comment !== "" && (
                            <Box key={solicitation.id} display="flex" gap="6px" mt={2} mb={2} alignItems="center">
                              <Avatar
                                alt={userDetails.name}
                                src={
                                  getAvatarUrl({
                                    name: userDetails.name
                                  },
                                    "FDA02B"
                                  )
                                }
                                style={{ width: 18, height: 18 }}
                              />
                              <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                            </Box>
                          )
                        ))}
                      </Box>
                    ))}
                  </Box>
                )}

                  {/* {pointDetails.periods_by_day.map(period => (
                    <Box key={period.start_time} display="flex" flexDirection="column" className={style.periodBox}>
                      <Box
                        display="flex"
                        bgcolor={
                          (calcDurationInMinutes(period.start_time, period.end_time) / 60) < period.hours_should_work
                            ? "#FFE6E6"
                            : "#DBF5F2"
                        }
                        paddingX={2}
                        paddingY={1}
                        borderRadius="4px"
                      >
                        <Typography
                          color={
                            (calcDurationInMinutes(period.start_time, period.end_time) / 60) < period.hours_should_work
                              ? "#FF7A90"
                              : "#22BEAD"
                          }
                          fontSize={12}
                        >
                          {t("checkin.drawer_details_day.period_of")}{' '}
                          {period.start_time.split(':')[0] + ":" + period.start_time.split(':')[1]} -{' '}
                          {period.end_time.split(':')[0] + ":" + period.end_time.split(':')[1]}
                        </Typography>
                      </Box>

                      {period.points_by_periods.map(point => (
                        <Box key={point.id} display="flex" flexDirection="column" paddingX={2} mt={2}>
                          <Box display="flex" gap="8px">
                            <Box>
                              {point.type === 'IN' ? (
                                <IconIn fill={statusColors[point.status_id - 1]} />
                              ) : (
                                <IconOut fill={statusColors[point.status_id - 1]} />
                              )}
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Typography component="span" lineHeight={1} fontSize={14} color="#1E6B7F">
                                <Typography component="span" color="#1E6B7F" lineHeight={1} fontSize={14} fontWeight={600}>
                                  {point.time.split(':')[0] + ":" + point.time.split(':')[1]}{' '}
                                </Typography>
                                {point.type === 'IN' ? (
                                  t("checkin.drawer_details_day.entry")
                                ) : (
                                  t("checkin.drawer_details_day.exit")
                                )}
                              </Typography>
                              <Typography mt={1} fontSize={12} color="#4F7091">{point?.address}</Typography>
                              <Typography fontSize={12} color="#4F7091">{t("checkin.drawer_details_day.receipt") + ": " + point?.receipt_token}</Typography>
                            </Box>
                          </ Box>

                          {point.solicitations.map(solicitation => (
                            solicitation.comment !== "" && (
                              <Box key={solicitation.id} display="flex" gap="6px" mt={2} mb={2} alignItems="center">
                                <Avatar
                                  alt={userDetails.name}
                                  src={
                                    getAvatarUrl({
                                      name: userDetails.name
                                    },
                                      "FDA02B"
                                    )
                                  }
                                  style={{ width: 18, height: 18 }}
                                />
                                <Typography fontSize={12} color="#4F7091" fontStyle="italic">{`"${solicitation.comment}"`}</Typography>
                              </Box>
                            )
                          ))}
                        </Box>
                      ))}
                    </Box>
                  ))} */}
                </Box>


                <Button
                  variant="contained"
                  className={style.solicitationsBtn}
                  style={{ padding: '0', backgroundColor: '#E3EFFF', color: '#187DFF', height: '40px', boxShadow: 'none' }}
                  onClick={() => handleOpenDrawerSolicitations()}
                >
                  {t('checkin.see_solicitations')}
                </Button>
              </Box>

            </Drawer>
          )}

        </TableContainer >
      </Box>
      {openDrawerSolicitations && solicitations && (
        <DrawerSolicitations
          userDetails={userDetails}
          solicitations={solicitations}
          openDrawerSolicitations={openDrawerSolicitations}
          setOpenDrawerSolicitations={setOpenDrawerSolicitations}
        />
      )}

      <Modal
        id='modalActionCheckIn'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalSlider}
        onClose={() => setOpenModalSlider(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
      >
        <Slide direction="down" in={openModalSlider} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box className="containerModalActionCheckIn">
            <Box
              display="flex"
              bgcolor="#fff"
              flexDirection="column"
              zIndex={2}
              py="32px"
              px="26px"
              borderRadius="8px"
              gap="28px"
              width={"320px"}
              component="form"
              noValidate
              autoComplete='off'
              position="relative"
            >
              <Box className="form-content">

                <Box className="header-info">
                  <Box>
                    <PencilSimpleLine color="#22BEAD" size={36} />
                  </Box>
                  <Typography variant='h6' textAlign="center">
                    {t('checkin.historical.msg')} <br />{getMonths(colabHistory.mes - 1, t)} {t('checkin.historical.of')} {colabHistory.ano}
                  </Typography>
                </Box>

                <Box className="checked-box">
                  <input type="checkbox" id="check1" className="toggle" onChange={handleCheck} />
                  <label htmlFor="check1"></label>
                </Box>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal >

    </>
  );
}
