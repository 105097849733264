// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3gwR1VauQW6Farn5oJP13Q\\=\\= {\n  display: flex;\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  border-radius: 100px;\n  width: 28px;\n  height: 28px;\n  color: #98ACC1;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border: none;\n  outline: 0;\n  background-color: transparent;\n  transition: all 0.3s ease;\n  z-index: 7;\n}\n\n._3gwR1VauQW6Farn5oJP13Q\\=\\=:hover {\n  background-color: #E4EAEF;\n}", "",{"version":3,"sources":["webpack://./src/_components/checkin_page/history/ModalRemindSign.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EACA,UAAA;EACA,6BAAA;EACA,yBAAA;EACA,UAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".close {\n  display: flex;\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  border-radius: 100px;\n  width: 28px;\n  height: 28px;\n  color: #98ACC1;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border: none;\n  outline: 0;\n  background-color: transparent;\n  transition: all 0.3s ease;\n  z-index: 7;\n}\n\n.close:hover {\n  background-color: #E4EAEF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "_3gwR1VauQW6Farn5oJP13Q=="
};
export default ___CSS_LOADER_EXPORT___;
