import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../../../_components/_shared/Toolbar'
import TeamsSkeleton from '../../../_components/settings/teams/TeamsSkeleton'
import { useTranslation } from "react-i18next";
import { alertActions, settingsActions, teamsActions } from '../../../_actions';
import { teammatesActions } from '../../../_actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../../_helpers/api';
import { authHeader, befectiveFormatDate, secondsToHm } from '../../../_helpers';
import Filterbar from '../../../_components/_shared/Filterbar';
import moment from 'moment';
import HeaderColabDetails from '../../../_components/_shared/inventory/software/colab_details/HeaderColabDetails';
import ColabDetailTable from '../../../_components/_shared/inventory/software/colab_details/ColabDetailTable';


function InventorySoftwareColabDetailsPage(props) {
  const { t, i18n } = useTranslation('common');
  const { id, mu_id} = useParams()
  
  const { userDetails, alertActions, filter } = props;


  const [data, setData] = useState({
    stats:{
      user: null,
      software_info: null
    },
    data:[],
    countRows:0
  })

  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  
  const columns = useMemo(() => {
    return [
      {
        Header:t("software_inventory.colab_details.col_date"),
        accessor: 'day_ref',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value,userDetails.language,"S"): "-----" ;
        }
      },
      {
        Header: t("software_inventory.colab_details.col_goal"),
        accessor: 'total_expected_worked_time',
        Cell: ({ value }) => {
          return secondsToHm(value);
        },
      },
      {
        Header: t("software_inventory.colab_details.col_total_hours"),
        accessor: 'total_used_time',
        Cell: ({ value }) => {
          return secondsToHm(value);
        },
      },
      {
        Header: t("software_inventory.colab_details.col_percent_used_time"),
        accessor: 'percent_used_time',
        Cell: ({ value }) => {
          return `${value}%`;
        },
      },
    ];
  }, []);

  const getData = async ({ pageIndex, pageSize, isLoading, date_start, date_end}) => {
    try {
      
      if (isLoading) {
        setFetching(true)
      }

      const filterStartDate = date_start ? `&date_start=${date_start}` : ''
      const filterEndDate = date_end ? `&date_end=${date_end}` : ''

      const res = await api.get(`/inventory/software/details/${mu_id}?page=${pageIndex}&per_page=${pageSize}&software_analysis_id=${id}${filterStartDate}${filterEndDate}`, { headers: authHeader() })
      
      if (res.status !== 200) {
        throw new Error('Erro get details inventory software colab')
      }

      setData(res.data)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))

    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {

    let start_date = dateStart;
    let end_date = dateEnd;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
      setDateStart(start_date)
      setDateEnd(end_date)
    }

    getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: true, date_start:start_date, date_end:end_date})

  }, [filter.date])


  return (
    <React.Fragment>

      <section className="mainContent">
        <Toolbar />
      
        <Filterbar
          filterWho={'disabled'}
          filterWhen={'multi'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}          
          showShareBtn={false}
          routeBackToPage={`/inventory/software/${id}`}
          startDate={dateStart}
          endDate={dateEnd}
          maxDate={new Date()}
          withoutMax={true}
          cleanWhenNull={true}
          
        />
        
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                fetching
                  ? (
                    <TeamsSkeleton />
                  ) : (
                    <>
                      <HeaderColabDetails
                        stats={data.stats}
                      />
                      <ColabDetailTable
                        columns={columns}
                        data={data.data}
                        count={pageCount}
                        getData={getData}                  
                        pageCountRows={pageCountRows}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                      />
                    </>
                  )
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>


    </React.Fragment>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySoftwareColabDetailsPage)
