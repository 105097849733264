import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styles from './CollectionMonitoringFull.module.scss'
import CardInfoUser from './CardInfoUser'
import { useTranslation } from 'react-i18next'
import { history } from '../../../_helpers'

export default function CollectionMonitoringFull({ data, activeFilter, setActiveFilter, fetchData, filter }) {
  const { t } = useTranslation('common');
  const [fetching, setFetching] = useState(false);
  const path = window.location.pathname.split("/")
  
  const handleActiveFilter = async (filter, activeFilter) => {
    setFetching(true);
    setActiveFilter(activeFilter);
    await fetchData(false, filter, activeFilter);
    setFetching(false);
  };

  const renderFilterButton = (label, filterType, count) => (
    <div
      className={`${styles.filter} ${activeFilter === filterType && styles.activeFilter}`}
      onClick={() => handleActiveFilter(filter, filterType)}
    >
      <div className={`${styles.circle} ${styles[`circle${label.charAt(0).toUpperCase() + label.slice(1)}`]}`}></div>
      <div className={styles.label}>
        {t(`productive_reports.collection_monitoring.filter_${label}`)} ({count})
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.filtes}>
        {renderFilterButton('all', 'all', data.stats.total)}
        {renderFilterButton('active', 'active', data.stats.total_active)}
        {renderFilterButton('inactive', 'inactive', data.stats.total_inactive)}
      </div>

      <Grid container spacing={2} style={{ marginTop: '6px' }}>
        <Grid item xs={9}>
          <Grid container spacing={1} className={styles.containerCards}>
            {!fetching ? (
              data.data.length > 0 ? (
                data.data.map((card) => <CardInfoUser key={card.id} cardInfo={card} />)
              ) : (
                <Grid item xs={12}>
                  <Typography className="noData" style={{ padding: '10px' }}>
                    {t('generic_messages.none_data')}
                  </Typography>
                </Grid>
              )
            ) : (
              <Grid item xs={12}>
                <Box style={{display:'flex', padding:'20px', justifyContent:'center'}}>
									<CircularProgress size={32} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={1} className={styles.containerCards}>
            <div className={styles.info}>
              <h5 className={styles.infoTitle}>{t('productive_reports.collection_monitoring.list_info.title')}</h5>
              <p className={styles.infoSubtitle}>{t('productive_reports.collection_monitoring.list_info.subtitle')}</p>
              <ol className={styles.infoList}>
                <li className={styles.infoListItem}>{t('productive_reports.collection_monitoring.list_info.item1')}</li>
                <li className={styles.infoListItem}>{t('productive_reports.collection_monitoring.list_info.item2')}</li>
                <li className={styles.infoListItem}>{t('productive_reports.collection_monitoring.list_info.item3')}</li>
                <li className={styles.infoListItem}>{t('productive_reports.collection_monitoring.list_info.item4')}</li>
              </ol>
              <button
                className={styles.infoButtonSupport}
                onClick={() => history.push(`/calls-page/?last_url=${path.slice(1, path.length)}`)}
              >
                {t('productive_reports.collection_monitoring.list_info.support_call')}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}