import React from 'react';

const IconHomeGoals = props => {
  return (
    <svg id="IconHome" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1445" data-name="Retângulo 1445" width="19" height="19" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.583 0.792)">
        <path id="Caminho_370" data-name="Caminho 370" d="M11.916,3.979l6.333,5.779v7.283H15.478V11.46a.792.792,0,0,0-.792-.792H9.145a.792.792,0,0,0-.792.792v5.581H5.583V9.758l6.333-5.779m0-1.979a.435.435,0,0,0-.277.119l-7.4,6.69A.792.792,0,0,0,4,9.4v9.223H9.937V12.252H13.9v6.373h5.938V9.4a.792.792,0,0,0-.238-.594l-7.4-6.69A.435.435,0,0,0,11.916,2Z" transform="translate(-3.999 -2)" fill="#98acc1" />
      </g>
    </svg>

  )
}

export default IconHomeGoals;