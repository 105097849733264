import { Backdrop, CircularProgress, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import IconClose from '../../_components/_icons/IconClose';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeader } from '../../_components/checkin_page/dashboard/CardsHeader';
import { CheckinProgress } from '../../_components/checkin_page/dashboard/CheckinProgress';
import { CheckinTable } from '../../_components/checkin_page/dashboard/CheckinTable';

import moment from 'moment';
import { XCircle } from 'phosphor-react';
import { Toaster, toast } from 'react-hot-toast';
import IconIn from '../../_components/_icons/IconIn';
import IconOut from '../../_components/_icons/IconOut';
import ModalActionCheckIn from '../../_components/checkin_page/ModalActionCheckIn';
import { ModalRemindSign } from '../../_components/checkin_page/history/ModalRemindSign';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinDashboard(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, teammates } = props

  const [colabPoints, setColabPoints] = useState([])
  const [periodsExemple, setPeriodsExemple] = useState({})
  const [settings, setSettings] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reasons, setReasons] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [openModalJustifyWork, setOpenModalJustifyWork] = useState(false)
  const [openModalHelp, setOpenModalHelp] = useState(false)
  const [openModalRemindSign, setOpenRemindSign] = useState(false)
  const [form, setForm] = useState({
    dateStart: moment().toISOString(),
    dateEnd: moment().toISOString(),
    period: "0",
    reason: "0",
    comment: "",
    files: []
  })

  const [formErrors, setFormErrors] = useState({
    dateStart: null,
    dateEnd: null,
    period: null,
    reason: null,
    files: null
  })

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }
  const [openModalActionCheckIn, setOpenModalActionCheckIn] = useState(false)

  function handleCloseModalJustifyWork(e) {
    e.preventDefault()
    setOpenModalJustifyWork(false)
    resetForm()
  }

  function handleCloseModalHelp(e) {
    e.preventDefault()
    setOpenModalHelp(false)
  }

  function handleCloseRemindSign(e) {
    e.preventDefault()
    setOpenRemindSign(false)
  }

  const showToast = (type, receipt_token, created_at) => {
    toast(
      (tt) =>
      (<Grid container position={'relative'} p={'4px'}>
        <Box position={'absolute'} top={'-3px'} right={'-8px'}>
          <IconButton size='small' onClick={() => toast.dismiss(tt.id)}>
            <XCircle size={16} color="#106276" />
          </IconButton>
        </Box>
        <Grid item xs={12} display={'flex'}>
          <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box className={style.toastCustomIcon}>
              {
                type === 'IN' && <IconIn fill={'#22BEAD'} />
              }
              {
                type === 'OUT' && <IconOut fill={'#22BEAD'} />
              }

            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography component={'h6'} sx={{ color: '#22BEAD', fontSize: 14, fontWeight: 500, marginBottom: '2px' }}>
              {
                type === 'IN' && t('checkin.dashboard.toast.check_in_registered')
              }
              {
                type === 'OUT' && t('checkin.dashboard.toast.check_out_registered')
              }
            </Typography>
            <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>{`${t('checkin.dashboard.toast.receipt')}: ${receipt_token}`}</Typography>
            <Typography component={'div'} variant='caption' sx={{ color: '#4F7091', fontSize: 12 }}>{befectiveFormatDate(created_at, i18n.language, 'T')}</Typography>
          </Grid>
        </Grid>
      </Grid>),
      {
        duration: 4000,
        style: {
          borderRadius: '4px',
          background: '#DBF5F2',
          color: '#fff',
          minWidth: '280px',
          minHeight: '90px',
          padding: '0px'
        }
      }
    );
  }

  const handleChange = (e, date = null) => {
    if (date) {
      if (date === "dateStart") {
        const start = moment(new Date(e), 'YYYY-MM-DD');
        const end = moment(new Date(form.dateEnd), 'YYYY-MM-DD');

        if (start.isAfter(end)) {
          setForm({
            ...form,
            [date]: moment(e.$d).format().split("T")[0],
            'dateEnd': moment(e.$d).format().split("T")[0],
          });
        } else {
          setForm({
            ...form,
            [date]: moment(e.$d).format().split("T")[0],
          });
        }
      } else {
        setForm({
          ...form,
          [date]: moment(e.$d).format().split("T")[0],
        });
      }
      return
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);

    setForm({ ...form, files: [...form.files, ...filesArr] });
    setFormErrors({ ...formErrors, files: null })
  }

  const removeFile = (file) => {
    setForm({ ...form, files: form.files.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};

    if (form.dateStart === null) {
      errors.dateStart = t('validation.required')
    }

    if (form.dateEnd === null) {
      errors.dateEnd = t('validation.required')
    }

    if (!form.period || form.period === "0") {
      errors.period = t('validation.required')
    }

    if (!form.reason || form.reason === "0") {
      errors.reason = t('validation.required')
    }

    if (form.files.length > 0) {
      form.files.forEach(file => {
        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP']

        if ((file.size / 1000000) > 2) {
          errors.files = t('calls_page.error.file')
        }

        const fileNameSplitted = file.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          errors.files = t('calls_page.error.file_type')
        }
      })
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      dateStart: moment().toISOString(),
      dateEnd: moment().toISOString(),
      period: "0",
      reason: "0",
      comment: "",
      files: []
    })

    setFormErrors({
      dateStart: null,
      dateEnd: null,
      period: null,
      reason: null,
      files: null
    })
  }

  async function createJustificationNotWork() {
    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('from_day', form.dateStart);
      formData.append('to_day', form.dateEnd);
      formData.append('period_day', form.period);
      formData.append('reason', form.reason);
      formData.append('comment', form.comment);
      formData.append('moment', moment().format());

      for (let item of form.files) {
        formData.append('files', item)
      }

      await api.post(
        `/checkin/not_work_reason`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        }
      )
      setOpenModalJustifyWork(false);
      resetForm()
      alertActions.success(t('checkin.dashboard.alerts.justify_work_success'))
      await getColabPoints()
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      // console.log("/error: ",error.response.data.message)
      alertActions.error(error.response.data.message || t('checkin.dashboard.alerts.justify_work_error'))

    }
  }
  const getCheckinSettings = async () => {
    try {
      
      const resSettings = await api.get('/checkin/settings', { headers: authHeader() });
      console.log("/resSettings",resSettings)
      if(resSettings.status === 200){
        setSettings(resSettings.data)
      }
    } catch (error) {
      console.log(error)
      
    }

  }
  async function getReasons() {
    try {
      const response = await api.get('/checkin/adjustments_reasons', {
        headers: authHeader()
      })

      setReasons(response.data)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t("checkin.dashboard.alerts.get_reasons_error"))
    }
  }

  async function getColabPoints() {
    try {
      const response = await api.get(`/checkin/colab-points?mu_id${userDetails.mu_id}&moment=${moment().format()}`, {
        headers: authHeader()
      })
      setPeriodsExemple({
        number_max_columns: response.data.number_max_columns,
        array_to_map: response.data.array_to_map
      })
      setColabPoints(response.data.points_data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_points_error"))
    }
  }

  async function getOpenSignatures() {
    try {
      const response = await api.get(`/checkin/open-signatures?mu_id=${userDetails.mu_id}&today=${moment().format()}`, {
        headers: authHeader()
      })

      if (response.data.hasOpenSignature) {
        setOpenRemindSign(true)
      }
    } catch (error) {
      console.log(error)
      alertActions.error("Erro ao buscar assinaturas!")
    }
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      createJustificationNotWork()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])


  useEffect(() => {
    document.title = `Befective | ${t('checkin.dashboard.title_page')}`;

    getColabPoints()

    getReasons()
    getOpenSignatures()
    getCheckinSettings()
    teammatesActions.getTeammate(userDetails.mu_id)
  }, []);

  return (
    <>

      <Toaster
        position="top-right"
        reverseOrder={false}
      />

      <section className="mainContent productivity">

        <Toolbar setOpenModalHelp={setOpenModalHelp} />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          actionCheckin={true}
          checkin={true}
          setOpenModalJustifyWork={setOpenModalJustifyWork}
          setOpenModalActionCheckIn={setOpenModalActionCheckIn}
        />

        {/* PAGE STARTS */}
        <div className={style.containerCheckinPage}>
          <CardsHeader alertActions={alertActions} userDetails={userDetails} />
          {!teammates.loading && (
            <>
              <CheckinProgress
                alertActions={alertActions}
                userDetails={userDetails}
                reasons={reasons}
                todayPoints={colabPoints.find(item => item.day === moment().format('YYYY-MM-DD'))}
                periods={periodsExemple.array_to_map}
                getColabPoints={getColabPoints}
              />

              <CheckinTable
                alertActions={alertActions}
                userDetails={userDetails}
                reasons={reasons}
                colabPoints={colabPoints.filter(item => item.day !== moment().format('YYYY-MM-DD'))}
                periods={periodsExemple.array_to_map}
                getColabPoints={getColabPoints}
                pExemple={periodsExemple}
              />
            </>
          )}

        </div>
        {/* PAGE ENDS */}

      </section>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalJustifyWork}
        onClose={handleCloseModalJustifyWork}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalJustifyWork} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="42px"
            px="26px"
            borderRadius="8px"
            gap="28px"
            width={500}
            component="form"
            noValidate
            autoComplete='off'
            position="relative"
          >
            <button
              onClick={handleCloseModalJustifyWork}
              className={style.close}
            >
              <IconClose />
            </button>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>{t("checkin.dashboard.modal_justify_work.title")}</Typography>
              { settings.days_in_advance ? 
                <Typography component="p" fontSize={12} color="#205B6F" >{t("checkin.dashboard.modal_justify_work.days_in_advance_pt1")+settings.days_in_advance+t("checkin.dashboard.modal_justify_work.days_in_advance_pt2")}</Typography> 
                : <></>}
              
            </Box>           

            <Box display="flex" flexDirection="column" gap={2}>

              <Box display="flex" alignItems="center" gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("checkin.dashboard.modal_justify_work.label_date_start")}
                    value={form.dateStart}
                    inputFormat={getDataFormat(lang)}
                    onChange={dateStart => handleChange(dateStart, "dateStart")}
                    minDate={settings.days_in_advance ? moment().add(settings.days_in_advance, 'days').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        {...params}
                        sx={{ maxWidth: '137px', fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: '#4F7091' } }}
                        error={formErrors.dateStart && true}
                        helperText={formErrors.dateStart}
                      />
                    )}
                  />

                  <DatePicker
                    label={t("checkin.dashboard.modal_justify_work.label_date_end")}
                    value={form.dateEnd}
                    inputFormat={getDataFormat(lang)}
                    onChange={dateEnd => handleChange(dateEnd, "dateEnd")}
                    minDate={settings.days_in_advance ? moment().add(settings.days_in_advance, 'days').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        {...params}
                        sx={{ maxWidth: '137px', fieldset: { border: '2px solid #DEE3E9CC' }, input: { color: '#4F7091' } }}
                        error={formErrors.dateEnd && true}
                        helperText={formErrors.dateEnd}
                      />
                    )}
                  />
                </LocalizationProvider>

                <TextField
                  select
                  name="period"
                  label={t("checkin.dashboard.modal_justify_work.label_period")}
                  error={formErrors.period && true}
                  helperText={formErrors.period}
                  value={form.period}
                  onChange={e => handleChange(e, null)}
                  sx={{ flex: 1, fieldset: { border: '2px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
                >
                  <MenuItem value="0" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                    {t("checkin.dashboard.modal_justify_work.options.select")}
                  </MenuItem>
                  <MenuItem value="F" style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                    {t("checkin.dashboard.modal_justify_work.options.period_f")}
                  </MenuItem>
                  {teammates?.detail && teammates.detail?.periods.map(period => (
                    <MenuItem key={period.id} value={`${period.start}-${period.end}`} style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }}>
                      {period.start} - {period.end}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <TextField
                select
                name="reason"
                label={t("checkin.dashboard.modal_justify_work.label_reason")}
                error={formErrors.reason && true}
                helperText={formErrors.reason}
                value={form.reason}
                onChange={e => handleChange(e, null)}
                style={{ fieldset: { border: '2px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
                MenuProps={{
                  style: { display: 'flex', flexDirection: 'column' }
                }}
              >
                <MenuItem style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }} value="0">
                  {t("checkin.dashboard.modal_justify_work.options.select")}
                </MenuItem>
                {reasons.map(reason => (
                  <MenuItem style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '100%' }} key={reason.id} value={reason.id}>
                    {reason.label_pt || reason.label_custom}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-multiline-flexible"
                label={t("checkin.dashboard.modal_justify_work.label_comment")}
                placeholder={t("checkin.dashboard.modal_justify_work.placeholder_comment")}
                multiline
                name="comment"
                rows={4}
                maxRows={4}
                value={form.comment}
                onChange={(e) => handleChange(e, null)}
                sx={{ fieldset: { border: '2px solid #DEE3E9CC' }, textarea: { color: "#4F7091" } }}
              />

              <Box display="flex" flexDirection="column" mt={1}>
                <Typography color="#205B6F" mb={1}>{t("checkin.dashboard.modal_justify_work.label_attach")}</Typography>

                <label className={style.inputFileBox}>
                  <input
                    type="file"
                    onChange={onChangeInputFile}
                    disabled={form.files.length > 0}
                  />
                  <AttachFile fontSize='small' />
                  <div style={{ color: '#205B6F' }}>
                    {t("checkin.dashboard.modal_justify_work.placeholder_select_file")}
                  </div>
                </label>
                <span style={{ color: '#1E6B7F', fontSize: '10px', marginTop: '4px' }}>
                  {t('tasks.file_message')}
                </span>

                {formErrors.files &&
                  <div
                    className={style.error}
                    style={{ marginTop: "2px", marginBottom: 0 }}
                  >
                    {formErrors.files}
                  </div>
                }

                <div className={style.filesContainer}>
                  {form.files.length > 0 && form.files.map((file, index) => (
                    <div key={index} className={style.filePreview}>
                      <div>{file.name}</div>
                      <div>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => removeFile(file)}
                        >
                          <Close fontSize='small' />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
              <Button
                style={{ backgroundColor: '#E3EFFF', paddingY: '12px', paddingX: '28px', borderRadius: '2px', width: '105px', height: '42px' }}
                className={style.cancelBtn}
                onClick={handleCloseModalJustifyWork}
              >
                <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>{t("checkin.dashboard.modal_justify_work.label_btn_cancel")}</Typography>
              </Button>
              <Button
                style={{ backgroundColor: '#187DFF', paddingY: '12px', paddingX: '28px', borderRadius: '2px', width: '105px', height: '42px' }}
                className={style.save}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={14} />
                ) : (
                  <Typography component="span" fontSize={12} color='#fff' textTransform="initial" fontWeight={500}>{t("checkin.dashboard.modal_justify_work.label_btn_request")}</Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalHelp}
        onClose={handleCloseModalHelp}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalHelp} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="42px"
            px="26px"
            borderRadius="8px"
            gap="28px"
            width={450}
            component="form"
            noValidate
            autoComplete='off'
            position="relative"
          >
            <button
              onClick={handleCloseModalHelp}
              className={style.close}
            >
              <IconClose />
            </button>

            <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
              {t("checkin.dashboard.modal_help_points.title")}
            </Typography>

            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #B1C0D0" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_point_open")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_point_open")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #B1C0D0" bgcolor="#B1C0D0" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_normal")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_normal")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #22BEAD" bgcolor="#22BEAD" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_before")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_before")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #FF5151" bgcolor="#FF5151" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_after")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_after")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #FF5151" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_refused")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_refused")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #187DFF" bgcolor="#187DFF" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_approved")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_approved")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box width={9} height={9} border="1px solid #187DFF" borderRadius="100%" />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography
                    component="span"
                    lineHeight="1"
                    fontSize={16}
                    color="#205B6F"
                    fontWeight={500}
                  >
                    {t("checkin.dashboard.modal_help_points.title_checkin_awaiting_adjustment")}
                  </Typography>
                  <Typography
                    lineHeight="1"
                    color="#4f7091"
                    fontSize={12}
                  >
                    {t("checkin.dashboard.modal_help_points.description_checkin_awaiting_adjustment")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>

      {
        !teammates.loading &&
        <ModalActionCheckIn
          openModalActionCheckIn={openModalActionCheckIn}
          setOpenModalActionCheckIn={setOpenModalActionCheckIn}
          userDetails={userDetails}
          teammateDetails={teammates.detail}
          teammatesActions={teammatesActions}
          showToast={showToast}
          getColabPoints={getColabPoints}
          settings={settings}
          alertActions={alertActions}
        />
      }

      <ModalRemindSign openModal={openModalRemindSign} handleCloseModal={handleCloseRemindSign} />

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinDashboard)