import React from 'react'

export default function IconModuleProjectManagement({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="projetos" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1782" data-name="Retângulo 1782" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(1.5 0.749)">
            <g id="Grupo_933" data-name="Grupo 933">
              <path id="Caminho_7527" data-name="Caminho 7527" d="M18.362,2.373a.75.75,0,0,0-.862.75V4.585a8.25,8.25,0,0,0-12.037.038H4.75a.75.75,0,0,0-.75.75v3.75a.75.75,0,0,0,.75.75H8.5a.75.75,0,0,0,.75-.75V5.373a.75.75,0,0,0-.75-.75H7.787A6.675,6.675,0,0,1,11.5,3.5a6.75,6.75,0,0,1,5.025,2.25H14.912a.788.788,0,0,0-.787.637.75.75,0,0,0,.75.862H18.25A.75.75,0,0,0,19,6.5V3.16a.787.787,0,0,0-.637-.788Zm-10.612,6H5.5V6.123H7.75Z" transform="translate(-4 -1.996)" fill="#24677e" />
              <path id="Caminho_7528" data-name="Caminho 7528" d="M18.25,27H14.5a.75.75,0,0,0-.75.75V31.5a.675.675,0,0,0,.3.6,6.45,6.45,0,0,1-2.55.525,6.75,6.75,0,0,1-5.025-2.25H8.087a.788.788,0,0,0,.788-.638.75.75,0,0,0-.75-.862H4.75a.75.75,0,0,0-.75.75v3.337a.788.788,0,0,0,.637.788A.75.75,0,0,0,5.5,33V31.537a8.212,8.212,0,0,0,11.213.712H18.25A.75.75,0,0,0,19,31.5V27.75A.75.75,0,0,0,18.25,27Zm-3,1.5H17.5v2.25H15.25Z" transform="translate(-4 -17.624)" fill="#24677e" />
            </g>
          </g>
        </g>
      </g>
    </svg>


  )
}
