import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useGoogleLogin} from '@react-oauth/google';
import moment from 'moment';

import { alertActions } from '../../_actions';
import HeaderInfoCalendar from '../../_components/integrations/HeaderInfoCalendar';
import ListEventsCalendar from '../../_components/integrations/ListEventsCalendar';
import Filterbar from '../../_components/_shared/Filterbar';
import MenuAccount from '../../_components/_shared/MenuAccount';
import { api } from '../../_helpers/api';

import { ListEventsCalendarSkeleton } from '../../_components/integrations/ListEventsCalendarSkeleton';

import './styles.scss'
import ToolbarMu from '../../_components/_shared/ToolbarMu';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';

function IntegrationGoogleCalendarPage({ filter, alertActions, userDetails }) {

  const { t, i18n } = useTranslation('common');
  
  const locale = navigator.language;
  
  const langOpt = {
    'pt': 'pt',
    'en': 'en',
    'es': 'es',
    'pt-BR': 'pt',
    'en-US': 'en',
    'es-MX': 'es' 
  } 

  const lang = langOpt[`${locale}`] 

  const [anchorEl, setAnchorEl] = useState(null);
  const [accountMenu, setAccountMenu] = useState(false);
  const [events, setEvents] = useState([])
  const [accountsLogged, setAccountsLogged] = useState([])
  const [isLoadingEvents, setIsLoadingEvents] = useState(true)

  const date = filter.singleDate ?? String(moment().format()).split('T')[0]

  const handleClickMenuAccounts = (event) => {
    setAnchorEl(event.currentTarget);
    setAccountMenu(!accountMenu)
  };

  async function handleDeactivateAllGoogleAccounts() {
    try {
      setAccountMenu(false)
      setIsLoadingEvents(true)
     
      await api.patch(`integrations/google-calendar/deactivate-all`, {}, {headers: authHeader()})  
     
      setEvents([])
      setAccountsLogged([])
      setIsLoadingEvents(false)
      alertActions.success(t('integrations.success.deleted'))
    } catch (error) {
      console.log(error.response)
      setIsLoadingEvents(false)
      //alertActions.error(t('integrations.errors.delete'))
    }
    
  }
  
  const handleGoogleLogin = useGoogleLogin({
    // ux_mode: 'redirect',
    // redirect_uri:'http://localhost:8080/productivity/671/ff07f6d3-25c3-380f-8b17-51042fa845d9/google-calendar',
    scope: 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar',
    onSuccess: async codeResponse => {
      try {
        if (!codeResponse.scope.includes('https://www.googleapis.com/auth/calendar')) {
          //alertActions.error(t('integrations.errors.login'))
          return
        }

        setIsLoadingEvents(true)

        const response = await api.post(`integrations/google-calendar/create-token?lang=${lang}`, {
          code: codeResponse.code,
          date
        },
        {headers: authHeader()}        
        )

        setEvents(response.data.events)
        setAccountsLogged(response.data.accounts)
        setAccountMenu(false)
        setIsLoadingEvents(false)
      } catch (error) {
        setIsLoadingEvents(false)
        console.log(error.response)
        //alertActions.error(t('integrations.errors.login'))
      }
    },
    onError: error => {
      setIsLoadingEvents(false)
      console.log(error.response)
      //alertActions.error(t('integrations.errors.login'))
    },
    flow: 'auth-code'
  });

  async function handleGetAccountEvents(account_id) {
    try {
      setAccountMenu(false)
      setIsLoadingEvents(true)
      const response = await api.get(
        `integrations/google-calendar/${account_id}/get-events?date=${date}&lang=${lang}`,
        {headers: authHeader()}
      )
      
      setEvents(response.data.events)
      setAccountsLogged(response.data.accounts)
      setIsLoadingEvents(false)
    } catch (error) {
      console.log(error.response)
      setIsLoadingEvents(false)
      //alertActions.error(t('integrations.errors.get_data'))
    }
  }

  async function getActiveAccountEvents() {
    try {
      setIsLoadingEvents(true)
      const response = await api.get(
        `integrations/google-calendar/get-integrated-accounts?date=${date}&lang=${lang}`,
        {headers: authHeader()}
      )

      setEvents(response.data.events)
      setAccountsLogged(response.data.accounts)
      setIsLoadingEvents(false)
    } catch (error) {
      setIsLoadingEvents(false)
      console.log(error.response)
      //alertActions.error(t('integrations.errors.get_data'))
    }
  }
  
  useEffect(() => {
    getActiveAccountEvents()
  }, [filter?.singleDate])
  
  useEffect(() => {
    document.title = 'Befective | ' + t('integrations.title');
  });

  return (
    <React.Fragment>

      <section className="mainContent">
        <Toolbar
          breadcrumb={t('integrations.title')}
        />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'single'}
          filterWhat={'disabled'}
          actionAddAccountGoogleCalendar={true}
          setAccountMenu={setAccountMenu}
          accountMenu={accountMenu}
          accountsLogged={accountsLogged}
          handleGoogleLogin={handleGoogleLogin}
          isLoadingEvents={isLoadingEvents}
          handleClickMenuAccounts={handleClickMenuAccounts}
        />

        <MenuAccount 
          accountMenu={accountMenu} 
          setAccountMenu={setAccountMenu} 
          accountsLogged={accountsLogged}
          handleLogin={handleGoogleLogin}
          handleGetAccountEvents={handleGetAccountEvents}
          handleDeactivateAllAccounts={handleDeactivateAllGoogleAccounts}
          anchorEl={anchorEl}
        />

        <main className='container-list-calendar'>
          <HeaderInfoCalendar 
            info={{
              service: 'Google Calendar',
              accounts_connected: accountsLogged.length,
              accountActive: accountsLogged.find(acc => acc.is_active)
            }}
            isLoadingEvents={isLoadingEvents}
          />

          {!isLoadingEvents && events.length > 0 ? (
            <ListEventsCalendar 
              eventsList={events} 
              date={date} 
              infoUser={{organization_id: userDetails.origin_organization, mu_token: userDetails.mu_token}} 
              accountIsActive={accountsLogged.find(acc => acc.is_active)}
              getActiveAccountEvents={getActiveAccountEvents}
            />
          ) : (
            !isLoadingEvents && (
              <p className='no-events'>
                {accountsLogged.length > 0
                  ? t('integrations.no_data')
                  : t('integrations.connect')
                }
              </p>
            )
          )}

          {isLoadingEvents && (
            <ListEventsCalendarSkeleton />
          )}
        </main>
      </section>

    </React.Fragment>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    filter: state.filter,
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCalendarPage)