import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, orderData } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { CardsHeaderReportsSignedPoints } from '../../_components/checkin_page/reports_signed_points/CardsHeader';
import TableReportsSignedPoints from '../../_components/checkin_page/reports_signed_points/TableReportsSignedPoints';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinReportsSignedPointsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataReportsSignedPoints, setDataReportsSignedPoints] = useState({});

  const [orderedColumn, setOrderedColumn] = useState('username')
  const [order, setOrder] = useState('asc');


  const orderColumn = (column, type) => {

    const orderedData = orderData(dataReportsSignedPoints.colabs, column, type, orderedColumn, setOrderedColumn, order, setOrder)

    setDataReportsSignedPoints({
      ...dataReportsSignedPoints,
      colabs: orderedData
    })
  };

  function closeShareModal() {
    shareActions.closeModal()
  }

  async function getDataReportsSignedPoints(dateFilter = moment(new Date()).format('YYYY-MM-DD'), teams = null, teammates = null) {
    try {
      setFetching(true)
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/checkin/report/signed?date_ref=${dateFilter}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      setDataReportsSignedPoints({
        ...response.data,
        colabs: [...response.data.colabs].sort((a, b) => a.username.localeCompare(b.username))
      })
      setFetching(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    let date = moment(new Date()).format('YYYY-MM-DD');

    if (filter.singleDate) {
      date = moment(filter.singleDate).format('YYYY-MM-DD')
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataReportsSignedPoints(date, teams, teammates)
  }, [filter])

  useEffect(() => {

    document.title = `Befective | ${t('menu.check_in_remote')}`;
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'disabled'}
          filterWhenMonths={'single'}
          filterWhat={'disabled'}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderReportsSignedPoints alertActions={alertActions} userDetails={userDetails} resume={dataReportsSignedPoints?.stats} />
                <TableReportsSignedPoints data={dataReportsSignedPoints?.colabs} orderColumn={orderColumn} orderedColumn={orderedColumn} order={order} />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1}/>
          }
        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'checkinReportSignedPoints'} showOptionsTypeExportView={true} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinReportsSignedPointsPage)