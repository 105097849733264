import { teammatesConstants } from '../_constants';

export function teammates(state = {}, action) {
    switch (action.type) {
        case teammatesConstants.GET_TEAMMATES_REQUEST:
            return {
                loading: true
            };
        case teammatesConstants.GET_TEAMMATES_SUCCESS:
            return {
                payload: action.payload,
                loading: false
            };
        case teammatesConstants.GET_TEAMMATES_FAILURE:
            return {
                error: action.error,
                loading: true
            };
        case teammatesConstants.GET_TEAMMATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case teammatesConstants.GET_TEAMMATE_SUCCESS:
            return {
                ...state,
                detail: action.payload,
                loading: false
            };
        case teammatesConstants.GET_TEAMMATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };
        case teammatesConstants.POST_TEAMMATE_REQUEST:
            return {
                ...state,
                invited: '',
                loading: false
            };
        case teammatesConstants.POST_TEAMMATE_SUCCESS:
            return {
                ...state,
                invited: 'success',
                loading: false
            };
        case teammatesConstants.POST_TEAMMATE_FAILURE:
            return {
                ...state,
                error: action.error,
                invited: 'error',
                loading: false
            };
        case teammatesConstants.PATCH_TEAMMATE_REQUEST:
            return {
                ...state,
                updated: '',
                loading: false
            };
        case teammatesConstants.PATCH_TEAMMATE_SUCCESS:
            return {
                ...state,
                updated: 'success',
                loading: false
            };
        case teammatesConstants.PATCH_TEAMMATE_FAILURE:
            return {
                ...state,
                error: action.error,
                updated: 'error',
                loading: false
            };
        case teammatesConstants.DELETE_TEAMMATE_REQUEST:
            return {
                ...state,
                deleted: '',
                loading: false
            };
        case teammatesConstants.DELETE_TEAMMATE_SUCCESS:
            return {
                ...state,
                deleted: 'success',
                loading: false
            };
        case teammatesConstants.DELETE_TEAMMATE_FAILURE:
            return {
                ...state,
                error: action.error,
                deleted: 'error',
                loading: false
            };
        default:
            return state
    }
}