import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, filterActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { CardsHeaderReportsPoints } from '../../_components/checkin_page/reports_points/CardsHeader';
import TableReportsPoints from '../../_components/checkin_page/reports_points/TableReportsPoints';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import { CardsHeaderReportsTeammates } from '../../_components/remote_checkin/CardsHeaderReportsTeammates';
import TableReportsTeammates from '../../_components/remote_checkin/TableReportsTeammates';
import GenericSkeleton from '../../_components/remote_checkin/GenericSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function RemoteCheckinReportsTeammatesPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter, filterActions } = props

  const [submitted, setSubmitted] = useState(false);
  const [dataReportsColab, setDataReportsColab] = useState([]);
  const [fetching, setFetching] = useState(true)
  let startDate = new Date(moment().startOf("month")).toISOString().split('T')[0];
  let endDate = new Date(moment()).toISOString().split('T')[0];



  async function getDataReportsPoints(date_start = new Date(moment()).toISOString().split('T')[0], date_end = new Date(moment()).toISOString().split('T')[0], teams = null, teammates = null) {
    try {
      setFetching(true)
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teams=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&users=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      const response = await api.get(`/remote_track/reports/colab?org_id=${userDetails.organizationId}&start=${date_start}&end=${date_end}${queryTeams}${queryTeammates}`)
      setDataReportsColab(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("remote_checkin.errors.load"))
      setFetching(false)
    }
  }

  useEffect(() => {
    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    } else {
      start_date = startDate;
      end_date = endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataReportsPoints(start_date, end_date, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

  useEffect(() => {
    document.title = `Befective | ${t('remote_checkin.title_page_teammates')}`;
    // filterActions.clear('when')
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'multi'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date(moment())}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderReportsTeammates alertActions={alertActions} userDetails={userDetails} resume={dataReportsColab.stats} />
                <TableReportsTeammates data={dataReportsColab.data} />
              </>
              :
              <GenericSkeleton />
          }
        </div>
      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'remoteCheckinReportsTeammates'} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteCheckinReportsTeammatesPage)