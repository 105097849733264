import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleDown, ArrowCircleRight, ArrowCircleUp, ArrowsClockwise, CheckSquareOffset, Clock, ClockClockwise, CloudMoon, HourglassLow, MoonStars, Mountains, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { authHeader, befectiveFormatDate, hoursToMinutes, secondsToFormatHours, secondsToHm } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './DashboardCardsHeader.module.scss';

export function DashboardCardsHeader({ alertActions, userDetails, stats }) {

  const { t, i18n } = useTranslation('common');

  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187DFF` }}>
          <Timer size={20} color={"#187DFF"} weight="bold" />

          <Typography color={"#187DFF"} component="span" fontSize={20} mt={1}>
            {secondsToHm(stats?.total_time) || '00:00'}
          </Typography>
          <Typography color={"#187DFF"} component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_time')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <Clock size={20} color="#187DFF" weight="bold" />
          <Typography color="#187DFF" component="span" fontSize={20} mt={1}>
            {secondsToHm(stats?.average_external_time) || '00:00'}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.average_external_time')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none', position: 'relative' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <CheckSquareOffset size={20} color="#187DFF" weight="bold" />

          <Typography color="#187DFF" component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4f7091' }}>
          <ArrowsClockwise size={20} color="#4f7091" weight="bold" />

          <Typography color="#4f7091" component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin_integrated}

          </Typography>
          <Typography color="#4f7091" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_integrated')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none', position: 'relative' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <ArrowCircleUp size={20} color="#22BEAD" weight="bold" />

          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin_finished}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_finished')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #FC6662` }}>
          <ArrowCircleDown size={20} color={"#FC6662"} weight="bold" />

          <Typography color={"#FC6662"} component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin_unfinished}
          </Typography>
          <Typography color={"#FC6662"} component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_unfinished')}
          </Typography>

        </CardContent>
      </Card>



      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FDA02B' }}>
          <CloudMoon size={20} color="#FDA02B" weight="bold" />

          <Typography color="#FDA02B" component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin_morning}
          </Typography>
          <Typography color="#FDA02B" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_morning')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FDA02B' }}>
          <MoonStars size={20} color="#FDA02B" weight="bold" />

          <Typography color="#FDA02B" component="span" fontSize={20} mt={1}>
            {stats.total_remote_checkin_afternoon}
          </Typography>
          <Typography color="#FDA02B" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_afternoon')}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
