import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { Box, Button, CircularProgress, Grid, IconButton, InputLabel, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authHeader, befectiveFormatDate, secondsToHm } from '../../_helpers';
import { api } from '../../_helpers/api';

import MaskedInput from 'react-text-mask';
import style from './ModalAvgStartEndDetails.module.scss';
import IconClose from '../_icons/IconClose';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalAvgStartEndDetails({
  openModal,
  closeModal,
  filterMonth
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const [dataAvgStartEndDetails, setDataAvgStartEndDetails] = useState({ data: null })
  const [fetchingDataAvgStartEndDetails, setFetchingDataAvgStartEndDetails] = useState(false)

  const getAvgStartDetails = async (filterMonth = moment(new Date()).format('YYYY-MM')) => {


    try {

      setFetchingDataAvgStartEndDetails(true)

      const res = await api.get(`/users/new-productivity/avg-start-details?year_month=${filterMonth}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error("Falha ao carregar os dados")
      }

      const { data } = res

      setDataAvgStartEndDetails(data)

    } catch (error) {
      console.log(error)
    } finally {
      setFetchingDataAvgStartEndDetails(false)
    }
  }

  useEffect(() => {
    if (openModal === true) {
      getAvgStartDetails(filterMonth)
    }
  }, [openModal])


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${style.modalForm} ${classes.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={style.modalFormContainer}>
          <button onClick={closeModal} className={style.close}>
            <div><IconClose /></div>
          </button>
          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            py="32px"
            px="26px"
            borderRadius="8px"
            gap="8px"
            width="520px"
            component="div"
            noValidate
            autoComplete='off'
          >

            <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
              {t('colab_page.simple_cards.title_modal_details')}
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {
                    !fetchingDataAvgStartEndDetails
                      ?

                      <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        <table className={style.table}>
                          <thead>
                            <th>{t('colab_page.simple_cards.col_date_ref')}</th>
                            <th style={{width:'80px'}}>{t(`colab_page.simple_cards.col_start_time`)}</th>
                            <th style={{width:'80px'}}>{t(`colab_page.simple_cards.col_end_time`)}</th>
                          </thead>
                          <tbody>
                            {
                              dataAvgStartEndDetails.data && dataAvgStartEndDetails.data.length > 0
                                ?
                                dataAvgStartEndDetails.data.map(item => (
                                  <tr key={item.day_ref}>
                                    <td>{befectiveFormatDate(item.day_ref, i18n.language)}</td>
                                    <td>{item.start_time}</td>
                                    <td>{item.end_time}</td>
                                  </tr>
                                ))
                                :
                                <tr>
                                  <td colSpan={3}>
                                    {t('colab_page.simple_cards.modal_none_data')}
                                  </td>
                                </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      :
                      <Skeleton width={'100%'} height={80} variant='rounded' />
                  }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Slide>
    </Modal>
  )
}
