import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import PeriodicReports from '../_components/periodicreports/PeriodicReports'
import PeriodicReportsSkeleton from '../_components/periodicreports/PeriodicReportsSkeleton'
import Moment from 'moment';
import IconAdd from '../_components/_icons/IconAdd'

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { periodicActions } from '../_actions';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';


function PeriodicReportsPage(props) {
    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    const { periodicActions } = props;

    let loading = props.loading;


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);


    useEffect(() => {

        periodicActions.getPeriodics();


    }, [])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.periodic-reports');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar />
                <div className="mainFilterbar">
                    <div className="filters">
                        <NavLink to="periodic" activeClassName="active">
                            <button className={'settingsNav'}>{t('periodic.tab_periodic')}</button>
                        </NavLink>
                        <NavLink to="scheduled-reports">
                            <button className={'settingsNav'}>{t('periodic.tab_schedules')}</button>
                        </NavLink>
                    </div>
                    <button type={'button'} className={'add'} style={{width:'140px', justifyContent:'center'}} onClick={() => {
                        history.push("/productive/reports/periodic/add")
                    }}>
                        <IconAdd />
                        {t('periodic.add')}
                    </button>
                </div>
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading === true
                                ? <PeriodicReportsSkeleton />
                                : <PeriodicReports />
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        periodicActions: bindActionCreators(periodicActions, dispatch),

    }
}


function mapStateToProps(state) {

    return {
        loading: state.periodic.loading
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicReportsPage)
