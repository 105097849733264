import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableCollapsibleSettingsCheckin.scss';
import IconExpand from '../_icons/IconExpand';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { getDayWeekV2, history } from '../../_helpers';

function Row(props) {
  const { row, index, openAll } = props;
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation('common');

  function MakeStringWeekDays(arr) {
    if (arr.length === 0) {
      return "Not setting"
    }

    const str_ = arr.reduce((acc, curr) => {
      return acc + getDayWeekV2(curr.day_week, true, t) + ", "
    }, "").slice(0, -1)

    return str_
  }

  React.useEffect(() => {
    setOpen(openAll)
  }, [openAll])

  return (
    <React.Fragment>
      <TableRow
        className={`
        hover
        ${open ? 'table-row-goal-focus' : 'row-objective'}
      `}
        style={{ cursor: 'pointer' }}
      >
        <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} onClick={() => {
          history.push(`/settings/virtual-point/teams/${row.id}`)
        }}>
          {row.name}
        </TableCell>

        <TableCell tag="admin_name" style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} onClick={() => {
          history.push(`/settings/virtual-point/teams/${row.id}`)
        }}>
          {row.admin_name || "------"}
        </TableCell>
        <TableCell
          tag="total_colabs"
          style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}
          align="center"
          onClick={() => {
            history.push(`/settings/virtual-point/teams/${row.id}`)
          }}
        >
          {row.total_colabs}
        </TableCell>
        <TableCell tag="working_hours" style={{ fontSize: '12px', borderBottom: '2px solid #F5F6F9' }} align="center" onClick={() => {
          history.push(`/settings/virtual-point/teams/${row.id}`)
        }}>

          {MakeStringWeekDays(row.working_hours || [])}

        </TableCell>
        <TableCell>
          <IconButton
            className={`button-expand ${open ? 'rotate' : ''}`}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <IconExpand /> : <IconExpand />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow className={`${open ? 'table-body-focus ' : ''}`}>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size="small" aria-label="purchases" >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#98ACC1', fontSize: 11 }} width='32%' align='left'>{t('checkin.control_points.table.col_colab')}</TableCell>
                    <TableCell style={{ color: '#98ACC1', fontSize: 11 }} align='left'>E-mail</TableCell>
                    <TableCell width={'3%'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.teammates.length > 0 && row.teammates.map((teammate) => (
                    <TableRow key={teammate.id}>
                      <TableCell align='left'>{teammate.username}</TableCell>
                      <TableCell align='left'>{teammate.email}</TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {
        open && <TableRow> <TableCell style={{ padding: 0, height: 3 }} colSpan={5}></TableCell></TableRow>
      }
    </React.Fragment>
  );
}

export default function TableCollapsibleSettingsCheckin({
  data = []
}) {
  const [openAll, setOpenAll] = React.useState(false)
  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableCollapsibleSettingsCheckin' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow sx={{ textTransform: 'uppercase', fontSize: '12px' }}>
            <TableCell style={{ fontSize: '10px', color: '#98ACC1' }} width='20%'>{t("settings.teams.team")}</TableCell>
            <TableCell style={{ fontSize: '10px', color: '#98ACC1' }} width="15%">{t("settings.teams.admin")}</TableCell>
            <TableCell style={{ fontSize: '10px', color: '#98ACC1' }} align="center" width="10%">{t("settings.teams.colabs")}</TableCell>
            <TableCell style={{ fontSize: '10px', color: '#98ACC1' }} width={'15%'} align="center">{t("settings.teams.work_week")}</TableCell>
            <TableCell style={{ width: '0%', minWidth: '0%', textAlign: 'end' }}>
              <div>
                <IconButton
                  className={`button-expand ${openAll ? 'rotate' : ''}`}
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenAll(!openAll)}
                >
                  {openAll ? <IconExpand /> : <IconExpand />}
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ?
            data.map((row, index) => (
              <Row index={index} key={row.name} row={row} openAll={openAll} />
            ))
            :
            <TableRow>
              <TableCell colSpan={5}>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
