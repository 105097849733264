import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import { CardsHeaderColabs } from '../../_components/_shared/inventory/colabs/CardsHeader';
import TableColabs from '../../_components/_shared/inventory/colabs/TableColabs';
import InventoryColabDetailsSkeleton from '../../_components/_shared/inventory/colab_details/InventoryColabDetailsSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function InventoryColabsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});

  let startDate = new Date(moment()).toISOString().split('T')[0];
  let endDate = new Date(moment()).toISOString().split('T')[0];



  async function getDataReportsPoints(date_start = new Date(moment()).toISOString().split('T')[0], date_end = new Date(moment()).toISOString().split('T')[0], teams = null, teammates = null) {
    try {
      setFetching(true)
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teams=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&users=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }
      // console.log("/DATA QUERY PARAMS",queryTeammates,queryTeams)
      const response = await api.get(`/inventory?local_datetime=${moment().format()}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })
      if (response.status === 200) {

        setData(response.data)

        setFetching(false)
      }


    } catch (error) {
      console.log(error)
      // alertActions.error('Erro ao carregar os dados')
    }
  }

  useEffect(() => {
    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataReportsPoints(start_date, end_date, teams, teammates)
  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

  useEffect(() => {

  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
        // showShareBtn={false}
        />

        <div className={style.containerCheckinPage}>
          {
            !fetching ?
              <>
                <CardsHeaderColabs alertActions={alertActions} userDetails={userDetails} resume={data?.stats} />
                <TableColabs data={data?.data} />
              </>
              :
              <InventoryColabDetailsSkeleton />
          }
        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal filters={filter} reports={'inventoryColabsReports'} />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryColabsPage)