import React, { useState } from "react";
import { bindActionCreators } from "redux";
import IconShare from "../_icons/IconShare";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { shareActions } from "../../_actions";

function ShareButton(props) {
    const { t, i18n } = useTranslation('common');
    const [validPaths, setValidPaths] = useState([
        'productive',
        'workgoal',
        'timegoal',
        'mood',
        'teams',
        'teammates',
        'realtime',
        'overtime',
        'reports-colabs',
        'inventory-teammates',
        'inactivity',
        'points',
        'signed-points',
        'inventory'
    ]);

    const { shareActions, path, userDetails } = props;

    function openShareModal() {
        shareActions.openModal()
    }

    if (path[1] === 'productive' && (path[2] === 'widgets' || path[2] === 'historical')) {
        return ''
    }

    if(path[1] === 'productive'){

        if(userDetails.allowed_export === true && path[3] === 'realtime' && path[4] === 'user' && path[5]){
            return  <button className="share" onClick={() => { openShareModal() }}>
                        <IconShare />
                    </button>
        }
        return  (userDetails.allowed_export === true && ((validPaths.includes(path[1]) || validPaths.includes(path[2]) || validPaths.includes(path[3])) && path[2] !== 'productivity' && !path[4])) && (

            <button className="share" onClick={() => { openShareModal() }}>
                <IconShare />
            </button>
    
        )
    }

    return  ((validPaths.includes(path[1]) || validPaths.includes(path[2]) || validPaths.includes(path[3])) && path[2] !== 'productivity' && !path[4]) && (

        <button className="share" onClick={() => { openShareModal() }}>
            <IconShare />
        </button>

    )

};

function mapStateToProps(state) {

    return {
		userDetails: state.authentication.userDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        shareActions: bindActionCreators(shareActions, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareButton));
