import React from 'react';
import { Avatar, Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { authHeader, getAvatarUrl, getColorStatusKr, getDynamicRedirectUrl } from '../../../../_helpers';
import styles from './styles.module.scss'
import { useState } from 'react';
import { api } from '../../../../_helpers/api';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Divider, List, ListItem, ListItemText, Skeleton, Tab, Typography } from '@mui/material';

const style = {
  width: '100%',
  bgcolor: 'background.paper',
  color:'#4f7091'
};

export function ColabDetailsDrawer(
  {
    openColabDetails,
    closeDrawerColabDetails,
    data,
    isLoading,
    employeeId,
    userDetails
  }
) {

  const { t } = useTranslation('common');
  const today = new Date(String(new Date().toISOString()).split('T')[0])

  const history = useHistory();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const objColorsStatus = {
    completed: { color: '#22BEAD', bgColor: '#D6F4F1' },
    progress: { color: '#FDA02B', bgColor: '#FFF0DD' },
    planning: { color: '#98ACC1', bgColor: '#E4EAEF' },
    overdue: { color: '#FF5151', bgColor: '#FFEAED' }
  }

  const getColorStatus = (item) => {
    let colorStatus = objColorsStatus.planning
    let goalProgress = Number(item.progress)
    let newDateEnd = new Date(item?.date_end)

    if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEnd)) {
      colorStatus = objColorsStatus.progress
    } else if (goalProgress >= 100.00) {
      colorStatus = objColorsStatus.completed
    } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEnd) {
      colorStatus = objColorsStatus.overdue
    }
    return colorStatus
  }

  return (
    <Drawer anchor='right' open={openColabDetails} onClose={closeDrawerColabDetails}>

      <div style={{ position: 'absolute', right: 4, top: 4 }}>
        <IconButton aria-label="close" onClick={closeDrawerColabDetails}>
          <Close fontSize="medium" />
        </IconButton>
      </div>
      {
        isLoading
          ?

          <div className={styles.container}>
            <div className={styles.headerSkeleton}>
              <Skeleton animation="wave" variant="circular" width={60} height={60} />
              <div className={styles.teammateInfo}>
                <Skeleton width={300} variant="text"/>
                <Skeleton width={300} variant="text"/>
              </div>
            </div>

            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: '#E4EAEF' }}>
                  <TabList 
                    aria-label="lab API tabs example" 
                    >
                    
                    <Skeleton variant="rectangular" width={'80px'} height={30} sx={{marginX:'10px'}}/>
                    <Skeleton variant="rectangular" width={'80px'} height={30} sx={{marginX:'10px'}}/>
                    <Skeleton variant="rectangular" width={'80px'} height={30} sx={{marginX:'10px'}}/>
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: '4px 10px' }}>
                  {
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        {[1,2,3,4,5].map((item, index) => (
                          <>
                            <Skeleton variant="rectangular" width={'100%'} height={60} sx={{marginY:'10px'}}/>
                            <Divider />
                          </>
                        ))}
                      </List>
                  }
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          :
          <div className={styles.container}>
            <header>
              <Avatar
                alt={data?.employee?.name}
                src={getAvatarUrl({ name: data?.employee?.name })}
                style={{ width: 54, height: 54 }}
              />

              <div className={styles.teammateInfo}>
                <h2>{data?.employee?.name}</h2>
                {data?.employee?.group && <span>{data?.employee?.group?.name}</span>}
              </div>
            </header>

            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: '#E4EAEF' }}>
                  <TabList 
                    onChange={handleChange} 
                    aria-label="lab API tabs example" 
                    >
                    <Tab label={t('tabs.goals')} value="1" />
                    <Tab label={t('tabs.krs')} value="2" />
                    <Tab label={t('tabs.tasks')} value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: '4px 10px' }}>
                  {
                    data?.goals?.length > 0
                      ?
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        {data?.goals.map((goal, index) => (
                          <>
                            <ListItem sx={{ paddingY:'16px', paddingX: '6px', display: 'flex', justifyContent: 'space-between'}} 
                                      onClick={()=>history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${goal?.id}`))}
                                      button>
                              <Box maxWidth={'90%'} color={getColorStatus(goal)?.color}>{goal?.name}</Box>
                              <Box color={getColorStatus(goal)?.color} bgcolor={getColorStatus(goal)?.bgColor} padding={'6px'} borderRadius={'6px'} width={'32px'} textAlign='center' fontSize={'11px'} fontWeight={500}>{goal?.progress+'%'}</Box>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </List>
                      :
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        <Typography variant='caption' component={'p'} textAlign={'left'}>
                          {t('tabs.no_goal')}
                        </Typography>
                      </List>
                  }
                </TabPanel>
                <TabPanel value="2" sx={{ padding: '4px 10px' }} >
                  {
                    data?.key_results?.length > 0
                      ?
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        {data?.key_results.map((kr, index) => (
                          <>
                            <ListItem sx={{ paddingY:'16px', paddingX: '6px', display: 'flex', justifyContent: 'space-between' }} 
                                      onClick={()=>history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${kr?.goal_id}?kr_ids=${kr?.id},`))}
                                      button>
                              <Box color={getColorStatus(kr)?.color} maxWidth={'90%'}>{kr?.name}</Box>
                              <Box color={getColorStatus(kr)?.color} bgcolor={getColorStatus(kr)?.bgColor} padding={'6px'} borderRadius={'6px'} width={'32px'} textAlign='center' fontSize={'11px'} fontWeight={500}>{kr?.progress+'%'}</Box>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </List>
                      :                      
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        <Typography variant='caption' component={'p'} textAlign={'left'}>
                          {t('tabs.no_kr')}
                        </Typography>
                      </List>
                  }
                </TabPanel>
                <TabPanel value="3" sx={{ padding: '4px 10px' }}>
                  {
                    data?.tasks?.length > 0
                      ?
                      <List sx={style} 
                            component="nav" 
                            aria-label="mailbox folders"
                            >
                        {data?.tasks.map((task, index) => (
                          <>
                            <ListItem 
                              sx={{ paddingY:'16px', paddingX: '6px', display: 'flex', justifyContent: 'space-between' }}
                              onClick={()=>history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${task?.goal_id}?kr_ids=${task?.key_result_id},`))}
                              button>
                              <Box color={getColorStatus(task)?.color} maxWidth={'90%'}>{task?.name}</Box>
                              <Box color={getColorStatus(task)?.color} bgcolor={getColorStatus(task)?.bgColor} padding={'6px'} borderRadius={'6px'} width={'32px'} textAlign='center' fontSize={'11px'} fontWeight={500}>{task?.progress+'%'}</Box>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </List>
                      :
                      <List sx={style} component="nav" aria-label="mailbox folders">
                        <Typography variant='caption' component={'p'} textAlign={'left'}>
                          {t('tabs.no_task')}
                        </Typography>
                      </List>
                  }
                </TabPanel>
              </TabContext>
            </Box>
            {/* <main className={styles.content}>
              {
                data?.goals && data?.goals?.length > 0
                  &&
                  data?.goals.map((goal, index) => {
                    let colorStatus = objColorsStatus.planning
                    let goalProgress = Number(goal.progress)
                    let newDateEnd = new Date(goal?.date_end)

                    if (goalProgress > 0 && goalProgress < 100 && !(today > newDateEnd)) {
                      colorStatus = objColorsStatus.progress
                    } else if (goalProgress >= 100.00) {
                      colorStatus = objColorsStatus.completed
                    } else if (goalProgress > 0 && goalProgress < 100 && today > newDateEnd) {
                      colorStatus = objColorsStatus.overdue
                    }
                    return <>
                            <div  className={styles.headerGoal} 
                                  style={{backgroundColor:colorStatus.bgColor, paddingBottom: employeeId === goal.owner_id && '10px', cursor:'pointer'}}
                                  onClick={()=>history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${goal?.id}`))}
                                  >
                              <div  className={styles.objectiveInfo} 
                                    style={{backgroundColor:colorStatus.bgColor}}                                
                                    >
                                <h2 style={{color: colorStatus.color}}>{goal.name}</h2>
                                <span style={{color: colorStatus.color}}>{goal.progress}</span>
                              </div>               
                              {employeeId === goal.owner_id && <span className={styles.responsibleGoal}>{t('goals.responsible')}</span>}
                              
                            </div>
                                  
                            {
                              goal?.key_results.length > 0 &&
                              goal?.key_results.map((kr, index) => (
                                <div className={styles.krContainer} 
                                     style={{cursor:'pointer'}}
                                     onClick={() => history.push(getDynamicRedirectUrl(userDetails, `/goals/details/${goal?.id}?kr_ids=${kr?.id},`))
                              }>
                                  <div className={styles.krInfo}>
                                    <h3>{kr.name}</h3>
                                    <span>{kr.progress}</span>
                                  </div>
                                  {
                                    employeeId === kr.owner_id && <span>{t('goals.responsible')}</span>
                                  }

                                </div>
                              ))
                            }
                          </>
                  })
              }

            </main> */}
          </div>
      }

    </Drawer>
  );
}
