import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import TableReportsChecklists from '../../_components/reports/checklists/TableReportsChecklists';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsChecklistsPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, shareActions, filter } = props

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);

  async function getData(isLoading, teams = null, teammates = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t => t.id).join(',')}` : ''

      const response = await api.get(`/report/shared-todo/?current_date=${moment().format('YYYY-MM-DD')}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)

      setFetching(false)

    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: t('reports_checklists.table.col_colab'),
        accessor: 'username'
      },
      {
        Header: t('reports_checklists.table.col_team'),
        accessor: 'name'
      },
      {
        Header: t('reports_checklists.table.col_items_completed'),
        accessor: 'items_stats.total_items_done'
      },
      {
        Header: t('reports_checklists.table.col_items_not_completed'),
        accessor: 'items_stats.total_items_not_done'
      },
      {
        Header: t('reports_checklists.table.col_total_sharing'),
        accessor: 'total_shared_todo'
      },
      {
        Header: t('reports_checklists.table.col_last_update'),
        accessor: 'last_updated_at',
        Cell: (d) => {
          return befectiveFormatDate(d.value, i18n.language, 'T')
        }
      }
    ]
    , [])


  useEffect(() => {

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData(true, teams, teammates)
  }, [filter])

  return (

    <section className="mainContent">

      <Toolbar />

      <Filterbar
        filterWho={'multi'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        showShareBtn={false}
        routeBackToPage={'/productive/reports'}
      />

      <div className={style.containerCheckinPage}>
        {
          !fetching
            ?
            <TableReportsChecklists
              columns={columns}
              data={data}
              getData={getData}
            />
            :
            <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
        }

      </div>

    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsChecklistsPage)