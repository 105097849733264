import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import MapEditor from '../../_components/rooms_page/MapEditor';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function RoomsMapEditor(props) {

  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const params = useParams()

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props
  const [mapData, setMapData] = useState(null)
  const [rooms, setRooms] = useState(null)

  const getMapShapesData = async () => {
    try {
      const response = await api.get(`/rooms/maps/${params.id}/shapes`, {
        headers: authHeader()
      })
      setMapData(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  const getRooms = async () => {
    try {
      const response = await api.get('/rooms/not_linked', {
        headers: authHeader()
      })

      const roomsData = response.data?.reduce((acc, room) => {
        return [...acc, { name: room.name, value: room.id }]
      }, [])

      setRooms(roomsData)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  useEffect(() => {
    document.title = 'Befective | Gestão de Salas | Editor de Mapa';
    getRooms()
    getMapShapesData()
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          routeBackToPage={'/spaces/maps'}
        />

        <div className={style.containerRoomsPage}>
          {
            mapData && <MapEditor mapId={params.id} data={mapData} getData={getMapShapesData} rooms={rooms}/>
          }
        </div>
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsMapEditor)



