import React from 'react';

const IconPeriodicReports2 = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.644" viewBox="0 0 14 14.644">
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0)">
                <path id="Caminho_336" data-name="Caminho 336"
                      d="M18,9.318A7.333,7.333,0,0,1,4.1,12.552a.664.664,0,1,1,1.167-.633,6,6,0,1,0,.933-6.6H7.966a.7.7,0,0,1,.7.567A.667.667,0,0,1,8,6.652H4.666A.667.667,0,0,1,4,5.985v-3.3a.7.7,0,0,1,.567-.7.667.667,0,0,1,.767.667V4.285A7.333,7.333,0,0,1,18,9.318Z"
                      transform="translate(-4 -1.977)" fill="#24677e"/>
                <path id="Caminho_337" data-name="Caminho 337"
                      d="M26,18.667a.567.567,0,0,1-.333-.1L22,16.367v-3.7a.667.667,0,1,1,1.333,0v2.967l3,1.8A.664.664,0,0,1,26,18.667Z"
                      transform="translate(-16 -8.659)" fill="#24677e"/>
            </g>
        </svg>

    );
};

export default IconPeriodicReports2
