import {downloadsConstants} from '../_constants';
import {downloadsService} from '../_services';
import {alertActions} from '.';

export const downloadsActions = {
    getDownloads
};


function getDownloads() {
    return dispatch => {
        dispatch(request());

        downloadsService.getDownloads()
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: downloadsConstants.GET_DOWNLOADS_REQUEST}
    }

    function success(payload) {
        return {type: downloadsConstants.GET_DOWNLOADS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: downloadsConstants.GET_DOWNLOADS_FAILURE, error}
    }
}
