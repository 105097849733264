import React from 'react';

const IconOnline = ({color = "#187dff", width = 22, height = 19}) => {
    return (
        
     
        <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 19">
        <g id="Grupo_23" data-name="Grupo 23">
            <path id="Caminho_18" data-name="Caminho 18" d="M36,21v9H32V21h4m1-2H31a1,1,0,0,0-1,1V31a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V20A1,1,0,0,0,37,19Z" transform="translate(-16 -13)" fill={color}/>
            <path id="Caminho_19" data-name="Caminho 19" d="M21.5,7H6A1,1,0,0,0,5,8V19a1,1,0,0,0,1,1h9.5V18H7V9H20.5v2h2V8A1,1,0,0,0,21.5,7Z" transform="translate(-3.5 -7)" fill={color}/>
            <path id="Caminho_20" data-name="Caminho 20" d="M14,37v2H3a1,1,0,1,1,0-2Z" transform="translate(-2 -22)" fill={color}/>
        </g>
        </svg>

    )
}

export default IconOnline;