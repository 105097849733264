import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Avatar, Grid, Box, Backdrop, IconButton, Menu, MenuItem, Slide, Typography } from '@material-ui/core';
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { alertActions, shareActions, usersActions } from "../../_actions";
import { authHeader, getAvatarUrl } from "../../_helpers";

import "./ModalExportTeamStructure.scss"
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootTab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '37ch',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    width: '400px',
    height: '180px',
    outline: 'none',
    borderRadius: '16px',
    position: 'relative',
    padding: "40px"
  },
  title: {
    fontSize: 16,
    color: '#1E6B7F',
    fontWeight: '500',
    marginBottom: 24
  }
}));

function ModalExportTeamStructure({
  openModalExport,
  handleCloseModalExport,
  viewExport = '',
  filter = {},
  shareActions,
  ...props
}) {

  const classes = useStyles();
  const { t } = useTranslation('common');

  let token = authHeader()

  const [ loadingDoDownload, setLoadingDoDownload ] = useState(false);

  
  function doDownload(format, type = 'pdf') {
    setLoadingDoDownload(true);

    shareActions.getFile({
        format,
        report: viewExport,
        filters: filter,
        type,
        setLoadingDoDownload
    });
}

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModalExport}
        className={`${classes.modal} modal-export-team-structure`}
        onClose={handleCloseModalExport}
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.4 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalExport} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={classes.paper}>
            <div className="box-button-close">
              <button onClick={handleCloseModalExport} className="close" type="button"><IconClose /></button>
            </div>

            <Grid className="container-modal-export" container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={"title"}>{t('settings.teams.modal_export_structure_teams.title')}</Typography>
              </Grid>
              <Grid item xs={12} className="content">
                <ul className={"download"}>
                  <li><b>Download</b></li>
                  {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                  {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                  {!loadingDoDownload && <li><button onClick={() => { doDownload('xlsx', 'excel') }}>XLSX</button></li>}
                  {/* {!loadingDoDownload && <li><button onClick={() => { doDownload("pdf") }}>PDF</button></li>} */}
                </ul>
              </Grid>
            </Grid>
          </div>
        </Slide>
      </Modal>

    </div>

  )

};

function mapStateToProps(state) {

  return {
    users: state.users.items
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalExportTeamStructure));
