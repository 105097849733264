import { teammatesConstants } from '../_constants';
import { teammatesService } from '../_services';
import { alertActions } from '.';

export const teammatesActions = {
    getTeammates,
    getTeammate,
    updateTeammate,
    deleteTeammate,
    inviteTeammate
};


function getTeammates(teams=null) {
    return dispatch => {
        dispatch(request());

        teammatesService.getTeammates(teams)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: teammatesConstants.GET_TEAMMATES_REQUEST } }
    function success(payload) { return { type: teammatesConstants.GET_TEAMMATES_SUCCESS, payload } }
    function failure(error) { return { type: teammatesConstants.GET_TEAMMATES_FAILURE, error } }
}

function getTeammate(id) {
    return dispatch => {
        dispatch(request());

        teammatesService.getTeammate(id)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: teammatesConstants.GET_TEAMMATE_REQUEST } }
    function success(payload) { return { type: teammatesConstants.GET_TEAMMATE_SUCCESS, payload } }
    function failure(error) { return { type: teammatesConstants.GET_TEAMMATE_FAILURE, error } }
}

function updateTeammate(form, id, isInactive) {
    return dispatch => {
        dispatch(request());

        teammatesService.updateTeammate(form, id, isInactive)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: teammatesConstants.PATCH_TEAMMATE_REQUEST } }
    function success(payload) { return { type: teammatesConstants.PATCH_TEAMMATE_SUCCESS, payload } }
    function failure(error) { return { type: teammatesConstants.PATCH_TEAMMATE_FAILURE, error } }
}

function deleteTeammate(id) {
    return dispatch => {
        dispatch(request());

        teammatesService.deleteTeammate(id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: teammatesConstants.DELETE_TEAMMATE_REQUEST } }
    function success(payload) { return { type: teammatesConstants.DELETE_TEAMMATE_SUCCESS, payload } }
    function failure(error) { return { type: teammatesConstants.DELETE_TEAMMATE_FAILURE, error } }
}

function inviteTeammate(form, team) {
    return dispatch => {
        dispatch(request());

        teammatesService.inviteTeammate(form, team)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: teammatesConstants.POST_TEAMMATE_REQUEST } }
    function success(payload) { return { type: teammatesConstants.POST_TEAMMATE_SUCCESS, payload } }
    function failure(error) { return { type: teammatesConstants.POST_TEAMMATE_FAILURE, error } }
}