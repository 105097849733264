import { menuConstants } from '../_constants';

export const menuActions = {
    set,
};

function set(type, state) {

    if (type === "reports") {

        return { type: menuConstants.SET_MENU_REPORTS, state };

    }

    if (type === "settings") {

        return { type: menuConstants.SET_MENU_SETTINGS, state };

    }

    if (type === "goals") {

        return { type: menuConstants.SET_MENU_GOALS, state };

    }

    if (type === "integrations") {

        return { type: menuConstants.SET_MENU_INTEGRATIONS, state };

    }

    if (type === "checkin") {

        return { type: menuConstants.SET_MENU_CHECKIN, state };

    }

    if (type === "control") {

        return { type: menuConstants.SET_MENU_CHECKIN_CONTROL, state };

    }

    if (type === "project_management") {

        return { type: menuConstants.SET_MENU_PROJECT_MANAGEMENT_CONTROL, state };

    }

}

