import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'
import moment from 'moment'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import { Tooltip } from '@material-ui/core'
import { useState } from 'react'
import { Skeleton } from '@material-ui/lab'

import './styles.scss'
import { alertActions, teamsActions } from '../../../_actions'
import TagsList from '../../goals/TagsList'
import {
	authHeader,
	befectiveFormatDate,
	getAvatarUrl, getPeriod, getStatusClass, numberToPercentString,
} from '../../../_helpers'
import { api } from '../../../_helpers/api'

const status_label = ['planned', 'progress', 'completed', 'late']

function DrawerDetailsObjective(props) {
	const { t, i18n } = useTranslation('common')

	const { alertActions } = props;
	const { title } = props
	const { userDetails } = props || {};

	const [detailsObjective, setDetailsObjective] = useState({})
	const [isLoading, setIsLoading] = useState(true)


	const params = useParams()
	const token = authHeader()

	const getDetailsObjective = async () => {
		try {
			setIsLoading(true)
			const result = await api.get(`goals/${params?.id}/details/?organization_id=${userDetails?.organizationId}`, {
				headers: {
					'Authorization': token.Authorization
				}
			})
			setDetailsObjective(result?.data)
			setIsLoading(false)
		} catch (error) {
			//alertActions.error(error)
		}

	}

	useEffect(() => {
		getDetailsObjective()
	}, [])

	return (

		isLoading
			?

			<div className="containerDrawer">

				<header>
					<Skeleton variant="h4" width="100%" height={20} />
				</header>


				<main>

					<div className='headerDetail'>
						<h3>{t('keyresults.sidebar_details.label')}</h3>
					</div>

					<div className='contentDetail'>
						<div>
							<div className="row">
								<div className="col" style={{ width: 'auto' }}>
									<h6><Skeleton width="80px" /></h6>
									<div><Skeleton width="150px" /></div>
								</div>
								<div className="col" style={{ width: 'auto' }}>
									<h6><Skeleton width="80px" /></h6>
									<div><Skeleton width="150px" /></div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div className="avatarTeam">
										<Skeleton width="150px" />
									</div>
								</div>

								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div className="avatarTeam">
										<Skeleton width="150px" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">

									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="150px" />
									</div>
								</div>
								<div className="col">

									<h6><Skeleton width="80px" /></h6>
									<div><Skeleton width="150px" /></div>
								</div>
							</div>
						</div>

						<div>
							<div className="row">

								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="300px" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="300px" />
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="row">
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="300px" />
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<span>
										<Skeleton width="150px" />
									</span>
								</div>
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<span>
										<Skeleton width="150px" />
									</span>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="150px" />
									</div>
								</div>
								<div className="col">
									<h6><Skeleton width="80px" /></h6>
									<div>
										<Skeleton width="150px" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			:
			<div className="containerDrawer">

				<header>
					<h3>{title}</h3>
				</header>


				<main>

					<div className='headerDetail'>
						<h3>{t('keyresults.sidebar_details.label')}</h3>
					</div>

					<div className='contentDetail'>
						<div>
							<div className="row">
								<div className="col" style={{ width: 'auto' }}>
									<h6>{t('keyresults.sidebar_details.period')}</h6>
									<div>{getPeriod(detailsObjective?.date_start, detailsObjective?.date_end,i18n.language)}</div>
								</div>
								<div className="col" style={{ width: 'auto' }}>
									<h6>{t('keyresults.sidebar_details.missing')}</h6>
									<div>{`${detailsObjective?.timeMissing} dias`}</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<h6>{t('keyresults.sidebar_details.created_by')}</h6>
									<div className="avatarTeam">

										<Tooltip title={detailsObjective?.created_by}>
											<Avatar alt={detailsObjective?.created_by} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(detailsObjective?.created_by)} style={{ width: 28, height: 28 }} />
										</Tooltip>
									</div>
								</div>

								<div className="col">
									<h6>{t('keyresults.sidebar_details.responsible')}</h6>
									<div className="avatarTeam">

										<Tooltip title={detailsObjective?.responsable_name}>
											<Avatar alt={detailsObjective?.responsable_name} src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&name=' + encodeURI(detailsObjective?.responsable_name)} style={{ width: 28, height: 28 }} />
										</Tooltip>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">

									<h6>{t('keyresults.sidebar_details.created_at')}</h6>
									<div>{befectiveFormatDate(detailsObjective?.created_at,i18n.language)}</div>
								</div>
								<div className="col">

									<h6>{t('keyresults.sidebar_details.recorded_hours')}</h6>
									<div>{detailsObjective?.totalHoursRecorded}</div>
								</div>
							</div>
						</div>

						<div>
							<div className="row">

								<div className="col">
									<h6>{t('keyresults.sidebar_details.team')}</h6>

									{detailsObjective?.team.length > 0 ? (
										<AvatarGroup max={4}>
											{detailsObjective?.team.map((teammate, index) => (
												<Tooltip key={index} title={teammate?.username}>
													<Avatar alt={teammate?.username} src={getAvatarUrl(teammate)} style={{ width: 28, height: 28 }} />
												</Tooltip>
											))
											}
										</AvatarGroup>
									) : (
										'---'
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<h6>{t('keyresults.sidebar_details.tags')}</h6>
									{detailsObjective?.tags.length > 0 ? (
										<TagsList tags={detailsObjective?.tags} />
									) : (
										'---'
									)}
								</div>
							</div>
						</div>

						<div>

							<div className="row">
								<div className="col">
									<h6>{t('keyresults.sidebar_details.status')}</h6>
									<span className={`color-${getStatusClass(detailsObjective?.status_id)}`}>
										{t(`tasks.${status_label[detailsObjective?.status_id - 1]}`)}
									</span>
								</div>
								<div className="col">
									<h6>{t('keyresults.sidebar_details.progress')}</h6>
									<span className={`color-${getStatusClass(detailsObjective?.status_id)}`}>
										{numberToPercentString(detailsObjective?.progress)}
									</span>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<h6>{t('keyresults.sidebar_details.keyresults')}</h6>
									<div>
										{detailsObjective?.key_results}
									</div>
								</div>
								<div className="col">
									<h6>{t('keyresults.sidebar_details.activities')}</h6>
									<div>
										{detailsObjective?.tasks}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>

	)
}

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		created: state.teams.created
	}

}


function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerDetailsObjective)



