import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Fade, makeStyles, Modal, Slide, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactAvatar from 'react-avatar';
import { TagsInput } from 'react-tag-input-component';

import MuiSelect from "@material-ui/core/Select";
import IconInfo from '../../_icons/IconInfo';
import IconClose from '../../_icons/IconClose';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import { ModalAddTeamTask } from '../ModalAddTeamTask';

import "./styles.scss"
import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { formatCurrencyByEnd } from '../../../_helpers/formattValue';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalEditKeyResult({
  openModalEditKR,
  setOpenModalEditKR,
  teammatesOptions,
  closeModalEditKeyResult,
  alertActions,
  userDetails,
  groups,
  kr,
  updateGoalsDetails,
  currentObjective
}) {
  const { t } = useTranslation('common');

  const classes = useStyles();

  const [formEditKeyResult, setFormEditKeyResult] = useState({});
  const [tagsEditKeyResult, setTagsEditKeyResult] = useState([]);
  const [responsibleEditKeyResult, setResponsibleEditKeyResult] = useState('');
  const [teamKeyResult, setTeamKeyResult] = useState([]);
  const [openModalAddTeamKR, setOpenModalAddTeamKR] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formEditKeyResultInitial, setFormEditKeyResultInitial] = useState({});
  const [isIntChecked, setIsIntChecked] = useState()
  const [valueStart, setValueStart] = useState(null)
  const [valueEnd, setValueEnd] = useState(null)
  const [formErrorEditKeyResult, setFormErrorEditKeyResult] = useState({
    title: '',
    responsible: '',
    type: '',
    start_value: '',
    end_value: '',
    date_start: '',
    date_end: '',
    teams: '',
    tags: '',
    weight: ''
  });

  const token = authHeader()

  const handleClose = (e) => {
    e.preventDefault()
    setOpenModalEditKR(false);
  };

  const handleChangeEditKeyResult = e => {
    if (e.target.name === 'start_value' && e.target.value === "") {
      setFormEditKeyResult({
        ...formEditKeyResult,
        [e.target.name]: 0,
      })

      return
    }

    if (e.target.name === 'end_value' && e.target.value === "") {
      setFormEditKeyResult({
        ...formEditKeyResult,
        [e.target.name]: 0,
      })

      return
    }

    setFormEditKeyResult({
      ...formEditKeyResult,
      [e.target.name]: e.target.value,
    })
  }

  const showModalEditKeyResult = () => {

    setFormEditKeyResultInitial(
      {
        ...kr,
        type: kr?.type === true ? 'result' : 'value'
      }
    );

    setFormEditKeyResult(
      {
        ...kr,
        type: kr?.type === true ? 'result' : 'value'
      }
    );
    setResponsibleEditKeyResult(kr?.responsible_id)
    const teamToKrs = [];

    teammatesOptions?.forEach(teammate => {
      kr?.team_ids?.forEach(id => {
        if (teammate?.value === id)
          teamToKrs.push({
            id: teammate.value,
            username: teammate.label
          })
      })
    })
    setTeamKeyResult(teamToKrs);

  }

  const handleOpenModalAddTeamKR = (e) => {
    e.preventDefault()
    setOpenModalAddTeamKR(true)
  }

  const validateFormEditKeyResult = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('goals.errors.required')
    }
    // else if (form.title.length < 10) {
    //   errors.title = t('goals.errors.title_goal')
    // }

    if (!form.type) {
      errors.type = t('goals.errors.required')
    }
    if (form.type === "false" && !form.start_value) {
      errors.start_value = t('goals.errors.required')
    }

    if (form.type === "false" && !form.end_value) {
      errors.end_value = t('goals.errors.required')
    }

    if (form.type === 'value' && !Number(form.end_value)) {
      errors.valueEnd = t('goals.errors.required')
    }

    if (form.type === 'value' && Number(form.end_value) <= 0) {
      errors.end_value = t('goals.errors.non_zero')
    }

    if (form.type === 'value' && Number(form.end_value) < Number(form.start_value)) {
      errors.end_value = t('goals.errors.greater_than')
    }
    // if (!form.date_start) {
    //   errors.date_start = t('goals.errors.required')
    // }

    // if (!form.date_end) {
    //   errors.date_end = t('goals.errors.required')
    // }

    return errors
  }

  const handleSubmitEditKeyResult = (event) => {
    event.preventDefault();
    setFormErrorEditKeyResult(validateFormEditKeyResult(formEditKeyResult));
    setSubmitted(true);
  }


  const editKeyResult = async () => {
    try {
      setIsSubmitting(true)
      const team = teamKeyResult.reduce((acc, item) => {
        return [...acc, item.id]
      }, [])

      const payload = {
        action_id: 4,
        datetime: new Date(),
        from: JSON.stringify({
          title: formEditKeyResultInitial?.title,
          type_user: formEditKeyResultInitial?.type_user,
          accountable_id: formEditKeyResultInitial?.accountable_id,
          type: formEditKeyResultInitial.type === 'value' ? false : true,
          start_value: formEditKeyResultInitial.type === 'value' ? formEditKeyResultInitial.start_value : 0,
          end_value: formEditKeyResultInitial.type === 'value' ? formEditKeyResultInitial.end_value : 0,
          date_start: currentObjective?.date_start,
          date_end: currentObjective?.date_end,
          is_int_value: formEditKeyResultInitial?.is_int_value,
          team_ids: formEditKeyResultInitial?.team_ids,
          tags: formEditKeyResultInitial?.tags,
          weight: formEditKeyResultInitial?.weight

        }),
        to: JSON.stringify({
          title: formEditKeyResult?.title,
          accountable_id: responsibleEditKeyResult,
          type: formEditKeyResult.type === 'value' ? false : true,
          start_value: formEditKeyResult.type === 'value' ? formEditKeyResult.start_value : 0,
          end_value: formEditKeyResult.type === 'value' ? formEditKeyResult.end_value : 0,
          date_start: currentObjective?.date_start,
          date_end: currentObjective?.date_end,
          is_int_value: isIntChecked,
          team_ids: team,
          tags: tagsEditKeyResult,
          weight: formEditKeyResult?.weight

        }),
        changeLog: [],
        title: formEditKeyResult.title,
        accountable_id: responsibleEditKeyResult,
        type: formEditKeyResult.type === 'value' ? false : true,
        start_value: formEditKeyResult.type === 'value' ? formEditKeyResult.start_value : 0,
        end_value: formEditKeyResult.type === 'value' ? formEditKeyResult.end_value : 0,
        date_start: currentObjective.date_start,
        date_end: currentObjective.date_end,
        is_int_value: isIntChecked,
        team_ids: team,
        tags: tagsEditKeyResult,
        weight: formEditKeyResult?.weight
      }

      await api.put(`/goals/key-results/${formEditKeyResult?.id}?organization_id=${userDetails?.organizationId}`, payload, {
        headers: {
          'Authorization': token.Authorization
        }
      })

      updateGoalsDetails(true)

      setOpenModalEditKR(false)
      alertActions.success(t('modalFormKeyResult.success.update'))
      // setTimeout(() => {
      //   window.location.reload(true)
      // }, 1000)
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t('modalFormKeyResult.error.update'))
    }
  }

  useEffect(() => {
    if (valueStart) {
      handleChangeEditKeyResult({ target: { name: 'start_value', value: valueStart } })
    }
  }, [valueStart])

  useEffect(() => {
    if (valueEnd) {
      handleChangeEditKeyResult({ target: { name: 'end_value', value: valueEnd } })
    }
  }, [valueEnd])

  useEffect(() => {
    if (Object.keys(formErrorEditKeyResult).length === 0 && submitted) {
      editKeyResult()
    } else {
      setSubmitted(false)
    }
  }, [formErrorEditKeyResult, submitted])

  useEffect(() => {
    if (openModalEditKR === true) {
      showModalEditKeyResult()
    }
  }, [openModalEditKR])

  useEffect(() => {
    if (!!formEditKeyResult) {
      setIsIntChecked(formEditKeyResult.is_int_value)
      setValueStart(formEditKeyResult.start_value)
      setValueEnd(formEditKeyResult.end_value)
    }
  }, [formEditKeyResult])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalEditKR}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalEditKR} style={{ outline: 0 }} mountOnEnter unmountOnExit>

          <div className={"containerModalFormKeyResult"}>

            <button onClick={handleClose} className="close"><IconClose /></button>

            <form>
              <div className="leftCol">
                <header>
                  <h3>
                    {t('modalFormKeyResult.header_modal')}
                    <span>
                      <IconInfo />
                      <div className="tooltip">
                        <h6>{t('modalFormKeyResult.tooltip_header.title')}</h6>
                        <p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_one')}</strong> {t('modalFormKeyResult.tooltip_header.info_one')}</p>
                        <p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_two')}</strong> {t('modalFormKeyResult.tooltip_header.info_two')}</p>
                        <p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_three')}</strong> {t('modalFormKeyResult.tooltip_header.info_three')}</p>
                        <p><strong>{t('modalFormKeyResult.tooltip_header.bold_info_four')}</strong> {t('modalFormKeyResult.tooltip_header.info_four')}</p>
                      </div>
                    </span>
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="title-kr">{t('modalFormKeyResult.title')}</label>
                  <input
                    id="title-kr"
                    type="text"
                    name="title"
                    placeholder={t('modalFormKeyResult.placeholder_title')}
                    value={formEditKeyResult?.title}
                    onChange={handleChangeEditKeyResult}
                    maxLength={150}/>
                  <span className="text-limit">{formEditKeyResult?.title?.length}/150</span>
                  {formErrorEditKeyResult.title &&
                    <div className="error">{formErrorEditKeyResult.title}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="selectResponsible">{t('modalFormKeyResult.responsible')}*</label>
                  <Select
                    id='responsible-kr'
                    defaultValue={() => teammatesOptions.find(item => item.value === formEditKeyResult?.responsible_id)}
                    name="responsible"
                    options={teammatesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('goals.modal_goal.select')}
                    onChange={(e) => setResponsibleEditKeyResult(e.value)}
                  />
                  {formErrorEditKeyResult.responsible &&
                    <div className="error">{formErrorEditKeyResult.responsible}</div>
                  }
                </div>

                <div className="fieldset">
                  <div className="col">
                    <label htmlFor="type">{t('modalFormKeyResult.type')}</label>

                    <div className="groupInputRadio">
                      <div>
                        <input
                          type="radio"
                          name="type"
                          id="value"
                          value={"value"}
                          disabled
                          onChange={handleChangeEditKeyResult}
                          checked={formEditKeyResult?.type === "value" ? true : false}
                        />
                        <label htmlFor="value">{t('modalFormKeyResult.type_value')}</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="type"
                          id="result"
                          value={"result"}
                          disabled
                          onChange={handleChangeEditKeyResult}
                          checked={formEditKeyResult?.type === "result" ? true : false}
                        />
                        <label htmlFor="result">{t('modalFormKeyResult.type_result')}</label>
                      </div>
                    </div>

                    {(formErrorEditKeyResult.type) &&
                      <div className="error">{formErrorEditKeyResult.type}</div>
                    }

                  </div>

                  {
                    formEditKeyResult?.type === 'result'
                      ?
                      <>
                        <div className="col label-result" style={{ width: '260px' }}>
                          <span><IconInfo width={20} height={20} /></span>
                          <span style={{ fontSize: '12px' }}>{t('modalFormKeyResult.label_result')}</span>
                        </div>
                      </>
                      :
                      <div className="value-type-container">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            id="valueTypeInt"
                            type="checkbox"
                            name="valueTypeInt"
                            checked={isIntChecked}
                            onChange={() => setIsIntChecked(!isIntChecked)}
                          />
                          <label htmlFor="valueTypeInt">{t('modalFormKeyResult.int')}</label>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                          <input
                            id="valueTypeFloat"
                            type="checkbox"
                            name="valueTypeFloat"
                            checked={!isIntChecked}
                            onChange={() => setIsIntChecked(!isIntChecked)}
                          />
                          <label htmlFor="valueTypeFloat">{t('modalFormKeyResult.float')}</label>
                        </div>
                      </div>
                  }
                </div>

                {formEditKeyResult?.type === 'value' && (
                  isIntChecked ? (
                    <div className="value-chosen-container">

                      <div className="col">
                        <label htmlFor="start_value">{t('modalFormKeyResult.start_in')}</label>
                        <input
                          type="number"
                          id="start_value"
                          name="start_value"
                          value={formEditKeyResult.start_value || 0}
                          onChange={handleChangeEditKeyResult}
                          min={0}
                        />
                        {formErrorEditKeyResult.end_value &&
                          <div className="error" style={{ opacity: 0 }}>#</div>
                        }

                        {formErrorEditKeyResult.start_value &&
                          <div className="error">{formErrorEditKeyResult.start_value}</div>
                        }
                      </div>

                      <div className="col">
                        <div className="arrowBox">
                          <span>{'>'}</span>
                        </div>
                        {formErrorEditKeyResult.end_value &&
                          <div className="error" style={{ opacity: 0 }}>#</div>
                        }
                      </div>

                      <div className="col">
                        <label htmlFor="end_value">{t('modalFormKeyResult.to')}</label>
                        <input
                          type="number"
                          id="end_value"
                          name="end_value"
                          value={formEditKeyResult.end_value || 0}
                          onChange={handleChangeEditKeyResult} min={0}
                        />
                        {formErrorEditKeyResult.end_value &&
                          <div className="error">{formErrorEditKeyResult.end_value}</div>
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="value-chosen-container">

                      <div className="col">
                        <label htmlFor="start_value">{t('modalFormKeyResult.start_in')}</label>

                        <NumberFormatBase
                          format={formatCurrencyByEnd}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          placeholder="0.00"
                          inputMode="numeric"
                          displayType="input"
                          value={
                            !valueStart || !Number(valueStart) ? "" : Number(valueStart) * 100
                          }
                          isAllowed={values => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === "" || floatValue / 100 <= 10000000000;
                          }}
                          onValueChange={values => {
                            if (values.value) {
                              setValueStart((parseFloat(values.value) / 100).toFixed(2));
                            } else {
                              setValueStart((0 / 100).toFixed(2))
                            }
                          }}
                        />

                        {formErrorEditKeyResult.end_value &&
                          <div className="error" style={{ opacity: 0 }}>#</div>
                        }
                        {formErrorEditKeyResult.start_value &&
                          <div className="error">{formErrorEditKeyResult.start_value}</div>
                        }
                      </div>

                      <div className="col">
                        <div className="arrowBox">
                          <span>{'>'}</span>
                        </div>
                        {formErrorEditKeyResult.end_value &&
                          <div className="error" style={{ opacity: 0 }}>#</div>
                        }
                      </div>

                      <div className="col">
                        <label htmlFor="end_value">{t('modalFormKeyResult.to')}</label>

                        <NumberFormatBase
                          format={formatCurrencyByEnd}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          placeholder="0.00"
                          inputMode="numeric"
                          displayType="input"
                          value={
                            !valueEnd || !Number(valueEnd) ? "" : Number(valueEnd) * 100
                          }
                          isAllowed={values => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === "" || floatValue / 100 <= 10000000000;
                          }}
                          onValueChange={values => {
                            if (values.value) {
                              setValueEnd((parseFloat(values.value) / 100).toFixed(2));
                            } else {
                              setValueEnd((0 / 100).toFixed(2))
                            }
                          }}
                        />

                        {formErrorEditKeyResult.end_value &&
                          <div className="error">{formErrorEditKeyResult.end_value}</div>
                        }
                      </div>
                    </div>
                  )
                )}

              </div>

              <div className="rightCol">

                <div className="boxContentInputsAndDetails">

                  <div className="boxInputsRight" style={{ marginTop: 20 }}>
                    <div className="teamBox">
                      <header>
                        <h4>{t('modalFormKeyResult.team')}</h4>
                      </header>
                      <div className="row">
                        <div className='assistant-container'>

                          {teamKeyResult?.length >= 3 ? (
                            <>

                              <ReactAvatar
                                name={teamKeyResult[0].username}
                                size={20}
                                round
                                color='#4F7091'
                                style={{ border: '2px solid #fff', marginRight: '-5px' }}
                              />

                              <ReactAvatar
                                name={teamKeyResult[1].username}
                                size={20}
                                round
                                color='#4F7091'
                                style={{ border: '2px solid #fff', marginRight: '-5px' }}
                              />

                              <ReactAvatar
                                name={teamKeyResult[2].username}
                                size={20}
                                round
                                color='#4F7091'
                                style={{ border: '2px solid #fff' }}
                              />
                              <span
                                style={{
                                  color: '#4F7091',
                                  fontSize: '16px',
                                }}
                              >
                                ...
                              </span>
                            </>

                          ) : (
                            teamKeyResult?.map(user => (
                              <ReactAvatar
                                key={user.id}
                                name={user.username}
                                size={20}
                                round
                                color='#4F7091'
                                style={{ border: '2px solid #fff', marginRight: '-5px' }}
                              />
                            ))
                          )}

                          <button
                            className='add-team-button'
                            onClick={handleOpenModalAddTeamKR}
                          >
                            +
                          </button>

                        </div>

                      </div>
                    </div>

                    <div className="tagsBox">
                      <header>
                        <h4>{t('modalFormKeyResult.tags')}</h4>
                      </header>
                      <div className="row">
                        <TagsInput
                          value={formEditKeyResult?.tags}
                          onChange={e => setTagsEditKeyResult(e)}
                          name="tags"
                          placeHolder="+ Tag"
                        />

                        {(formErrorEditKeyResult.tags) &&
                          <div className="error">{formErrorEditKeyResult.tags}</div>
                        }
                      </div>
                    </div>

                    <div className="weightBox">
                      <header>
                        <h4>{t('modalFormKeyResult.weight')}</h4>
                      </header>
                      <div className="row">
                        <div className="weight-container">

                          <MuiSelect
                            style={{ color: '#98ACC1', textAlign: 'right' }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="weight"
                            id="weight"
                            value={formEditKeyResult.weight || 1}
                            onChange={handleChangeEditKeyResult}
                          >
                            <MenuItem selected value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </MuiSelect>
                          {formErrorEditKeyResult.weight &&
                            <div className="error">{formErrorEditKeyResult.weight}</div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="buttonActionsGroupModalAddKeyResult">
                  <button
                    className="btnCancel"
                    type="button"
                    onClick={() => { closeModalEditKeyResult() }}>
                    {t('button.cancel')}
                  </button>
                  <button
                    type='button'
                    onClick={handleSubmitEditKeyResult}
                    disabled={isSubmitting}>
                    {isSubmitting ? (
                      <CircularProgress size={8} />
                    ) : t('button.save')}
                  </button>
                </div>
              </div>

            </form>

          </div>
        </Slide>

      </Modal>

      <ModalAddTeamTask
        key={2}
        classes={classes}
        openModalAddTeamTask={openModalAddTeamKR}
        setOpenModalAddTeamTask={setOpenModalAddTeamKR}
        assistants={teamKeyResult}
        setAssistants={setTeamKeyResult}
        groups={groups}
      />
    </>
  );
}
