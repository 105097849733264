import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import "./ModalCreateExtraCollection.scss"
import moment from "moment";
import { BefectiveDatePicker } from "../goals/BefectiveDatePicker";
import { Grid, TextField, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalCreateExtraCollection({
  openModalCreateExtraCollection,
  setOpenModalCreateExtraCollection,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo
}) {

  const { t } = useTranslation('common');
  const classes = useStyles();

  const [colabs, setColabs] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    users: [],
    date: moment().format('YYYY-MM-DD'),
    start_time: "08:00",
    end_time: "18:00"
  });

  const [formError, setFormError] = useState({
    users: '',
    date: '',
    start_time: '',
    end_time: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const convertToTime = (valueTime) => {
    const hour = dayjs(valueTime).hour()
    const minute = dayjs(valueTime).minute()
    let hourString = ''
    let minuteString = ''
    if (hour < 10) {
      hourString = '0' + String(hour)
    } else {
      hourString = String(hour)
    }
    if (minute < 10) {
      minuteString = '0' + String(minute)
    } else {
      minuteString = String(minute)
    }
    return hourString + ':' + minuteString
  }

  function closeModalCreateExtraCollection() {
    setOpenModalCreateExtraCollection(false)
    setForm({
      users: [],
      date: moment().format('YYYY-MM-DD'),
      start_time: "08:00",
      end_time: "18:00"
    })
    setFormError({
      users: '',
      date: '',
      start_time: '',
      end_time: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (form.start_time === null || form.start_time === 'NaN:NaN') {
      errors.start_time = t("validation.required")
    }

    if (form.end_time === null || form.end_time === 'NaN:NaN') {
      errors.end_time = t("validation.required")
    }


    if ((form.start_time != 'NaN:NaN' && form.end_time != 'NaN:NaN') && (form.start_time >= form.end_time || form.end_time <= form.start_time)) {
      errors.start_time = t("validation.time_invalid")
    }

    if (!form.date) {
      errors.date = t("validation.required")
    }

    if (form.users.length === 0) {
      errors.users = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createExtraCollection = async () => {
    try {
      setIsSubmitting(true)

      const res = await api.post('/settings/extra-collection', {
        ...form
      }, {
        headers: authHeader()
      })
      
      if(res.data && res.data.statusCode !== 200){
        alertActions.error(res.data)
        return
      }
      updateInfo()
      alertActions.success(t('settings.extra_collections.success.created_extra_collection'))
      closeModalCreateExtraCollection()
    } catch (error) {
      console.log(error)
      alertActions.error(t('settings.extra_collections.error.created_extra_collection'))
    }finally{
      setIsSubmitting(false)
    }
  }


  useEffect(() => {
    handleChange({ target: { name: 'users', value: colabs.map(dest => dest.value) } })
    setFormError({ ...formError, users: '' })
  }, [colabs])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createExtraCollection()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  return (
    <>
      <Modal
        key={'modal-create-board'}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalCreateExtraCollection ${classes.modal}`}
        open={openModalCreateExtraCollection}
        onClose={closeModalCreateExtraCollection}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalCreateExtraCollection} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalCreateExtraCollection"}>

            <button onClick={() => { closeModalCreateExtraCollection() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmit}>
              <main>
                <header>
                  <h3>
                    {t('settings.extra_collections.modal.title_create')}
                  </h3>
                </header>

                <div className="field">
                  <label htmlFor="users">{t('settings.extra_collections.modal.field_colabs')} {`(${form.users.length}/10)`}</label>
                  <Select
                    id='users'
                    isMulti
                    defaultValue={form.users || ''}
                    name="users"
                    options={colabsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('settings.extra_collections.modal.placeholder_colabs')}
                    onChange={setColabs}
                    isOptionDisabled={() => colabs.length >= 10}
                  />

                  {formError.users &&
                    <div className="error">{formError.users}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="date">{t('settings.extra_collections.modal.field_date')}</label>
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.date}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="date"
                    minDate={moment().format('YYYY-MM-DD')}
                    className={'deadlineBoard'}
                  />

                  {formError.date &&
                    <div className="error">{formError.date}</div>
                  }
                </div>

                <Box sx={{ flexGrow: 1, marginBottom: '24px' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} >
                      <Box
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography component="label" color="#24677E" fontSize={11} fontWeight={500} paddingLeft={'2px'}>{t('settings.extra_collections.modal.field_start_time')}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            ampm={false}
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__"
                            value={dayjs('2022-01-01 ' + form.start_time)}
                            onChange={(newValue) => {
                              setForm({ ...form, start_time: convertToTime(newValue) })
                              setFormError({
                                ...formError,
                                start_time: ''
                              })
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                variant='outlined'
                                {...params}
                                style={{ marginRight: 0, color: "#98ACC1" }}
                                sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {
                        formError.start_time &&
                          <div className="error">{formError.start_time}</div>
                        }

                      </Box>
                    </Grid>
                    <Grid item xs={6} >
                      <Box
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography component="label" color="#24677E" fontSize={11} fontWeight={500} paddingLeft={'2px'}>{t('settings.extra_collections.modal.field_end_time')}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            ampm={false}
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__"
                            value={dayjs('2022-01-01 ' + form.end_time)}
                            onChange={(newValue) => {
                              setForm({ ...form, end_time: convertToTime(newValue) })
                              setFormError({
                                ...formError,
                                end_time: ''
                              })
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                variant='outlined'
                                {...params}
                                style={{ marginRight: 0, color: "#98ACC1" }}
                                sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {
                          formError.end_time &&
                          <div className="error">{formError.end_time}</div>
                        }
                      </Box>
                    </Grid>
                  </Grid>

                </Box>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalCreateExtraCollection() }}
                    >
                      {t('settings.extra_collections.modal.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('settings.extra_collections.modal.create')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalCreateExtraCollection));
