import React from 'react'
import Moment from 'moment';
import { Badge, makeStyles } from '@material-ui/core'
import { Chat, CheckCircle, Info } from 'phosphor-react'

import './styles.scss'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: '10px',
    minWidth: '15px',
    width: '15px',
    height: '15px'
  },
}));

const objColorsStatus = {
  high: { color: '#FF5151', backgroundColor: '#FFEAED' },
  average: { color: '#FDA02B', backgroundColor: '#FFF0DD' },
  low: { color: '#187DFF', backgroundColor: '#E3EFFF' },
}

export function TableCallsPage({ data, toggleDrawer }) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  return (
    <>
      <table className="table-calls-page">
        <thead>
          <tr>
            <th 
              style={{ width: '6%', textAlign: 'left', padding: '1rem' }}
            >
              {t('calls_page.number')}
            </th>
            <th 
              style={{ width: '8%', textAlign: 'left', padding: '1rem' }}
            >
              {t('calls_page.opened')}
            </th>
            <th 
              style={{ textAlign: 'left', padding: '1rem' }}
            >
              {t('calls_page.title')}
            </th>
            <th 
              style={{ width: '15%', textAlign: 'left', padding: '1rem' }}
            >
              {t('calls_page.responsible')}
            </th>
            <th
              style={{ width: '10%', padding: '1rem'}}
            >
              {t('calls_page.priority.priority')}
            </th>
            <th 
              style={{ width: '10%' ,textAlign: 'center', padding: '1rem' }}
            >
              {t('calls_page.status.status')}
            </th>
            <th style={{textAlign:'center', width:'120px', padding: '1rem'}}>{t('calls_page.comments.comments')}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr
              key={item.id}
              className={item.call_status === 'S' ? 'column-solved' : ''}
              onClick={toggleDrawer(true, item)}
            >
              <td
                style={{ textAlign: 'left', padding: '1rem' }}
              >
                {item.id}
              </td>
              <td
                style={{ textAlign: 'left', padding: '1rem' }}
              >
                {Moment(item.open_date).format('DD/MM/YYYY')}
              </td>
              <td
                style={{ textAlign: 'left', padding: '1rem'}}
              >
                {item.title}
              </td>
              <td
                style={{ textAlign: 'left', padding: '1rem' }}
              >
                {item.responsible ? item.responsible : '---'}
              </td>
              <td style={objColorsStatus[item.urgency_label[0].us_label.toLowerCase()]}>
                <span>{t(`calls_page.priority.${item.urgency_label[0].us_label.toLowerCase() }`)}</span>
              </td>
              <td style={{ textAlign: 'center', padding: '1rem'}}>
                <div
                  className={item.call_status === 'S' ? "status-table-data solved" : "status-table-data"}
                >
                  {item.call_status === 'S' ? (
                    <>
                      <CheckCircle size={12} color="#22BEAD" weight="bold" />
                      <span>{t('calls_page.status.solved')}</span>
                    </>
                  ) : (
                    <>
                      <Info size={12} color="#187dff" weight="bold" />
                        <span>{t('calls_page.status.open')}</span>
                    </>
                  )}
                </div>
              </td>
              <td>
                <Badge
                  badgeContent={item.total_comments_not_read}
                  color="secondary"
                  overlap="rectangular"
                  classes={{ badge: classes.badge }}
                >
                  <Chat size={26} color="#98ACC1" weight="regular" />
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="table-calls-not-content">Nenhum chamado no momento!</div>
      )}
    </>
  )
}
