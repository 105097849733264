import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Skeleton } from '@mui/material';

import IconGoogleCalendar from '../../_icons/IconGoogleCalendar';
import IconOutlookCalendar from '../../_icons/IconOutlookCalendar';

import './styles.scss';

const HeaderInfoCalendar = ({ info, isLoadingEvents }) =>{
  const { t } = useTranslation('common');
  
  return (
    <div className='card-header-calendar'>
      
      {/* <img src={imageSrc} alt="Icone google calendar" /> */}
      {info.service === "Outlook Calendar" ? (
        <IconOutlookCalendar />
      ) : (
          <IconGoogleCalendar />
      )}
      
      <div className='box-info'>
        <div className='title-info'>
          <h4>{info?.service}</h4>
          {!isLoadingEvents && info.accountActive && (
            <span>({info.accountActive.email})</span>
          )}
          {isLoadingEvents && (
            <Skeleton variant="text" width="250px"/>
          )}
        </div>
        <span>{t('integrations.accounts_connected')}</span>
        <span>
          {isLoadingEvents ? (
              <Skeleton variant="text" width="13px" style={{display: 'inline-block'}}/>
          ) : (
            <strong>
              {info?.accounts_connected}
            </strong>
          )}
         
        </span>
      </div>

    </div>
  );
}

function mapStateToProps(state) {

  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderInfoCalendar));