import { alertActions } from '.';
import { statsConstants } from '../_constants';
import { statsService } from '../_services';

export const statsActions = {
    getStats,
    getProductivity,
    getRealtimeMachineUsers,
    getInactivity,
    getRealtimeTooltip
};


function getStats(startDate, endDate, teams = null, teammates = null, statsIn = null) {
    return dispatch => {
        dispatch(request());

        statsService.getStats(startDate, endDate, teams, teammates, statsIn)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return { type: statsConstants.GET_STATS_REQUEST }
    }

    function success(payload) {
        return { type: statsConstants.GET_STATS_SUCCESS, payload }
    }

    function failure(error) {
        return { type: statsConstants.GET_STATS_FAILURE, error }
    }
}

function getProductivity(date) {
    return dispatch => {
        dispatch(request());

        statsService.getProductivity(date)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return { type: statsConstants.GET_PRODUCTIVITY_REQUEST }
    }

    function success(payload) {
        return { type: statsConstants.GET_PRODUCTIVITY_SUCCESS, payload }
    }

    function failure(error) {
        return { type: statsConstants.GET_PRODUCTIVITY_FAILURE, error }
    }
}

function getRealtimeMachineUsers(startDate, endDate, teams) {
    return dispatch => {
        dispatch(request());

        statsService.getRealtimeMachineUsers(startDate, endDate, teams)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return { type: statsConstants.GET_STATS_REALTIME_REQUEST }
    }

    function success(payload) {
        return { type: statsConstants.GET_STATS_REALTIME_SUCCESS, payload }
    }

    function failure(error) {
        return { type: statsConstants.GET_STATS_REALTIME_FAILURE, error }
    }
}

function getRealtimeTooltip(tracked_id) {
    return dispatch => {
        dispatch(request());

        statsService.getRealtimeTooltip(tracked_id)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return { type: statsConstants.GET_STATS_REALTIME_TOOLTIP_REQUEST }
    }

    function success(payload) {
        return { type: statsConstants.GET_STATS_REALTIME_TOOLTIP_SUCCESS, payload }
    }

    function failure(error) {
        return { type: statsConstants.GET_STATS_REALTIME_TOOLTIP_FAILURE, error }
    }
}

function getInactivity(teams, teammates) {
    return dispatch => {
        dispatch(request());

        statsService.getInactivity(teams, teammates)
            .then(
                payload => {

                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return { type: statsConstants.GET_STATS_INACTIVITY_REQUEST }
    }

    function success(payload) {
        return { type: statsConstants.GET_STATS_INACTIVITY_SUCCESS, payload }
    }

    function failure(error) {
        return { type: statsConstants.GET_STATS_INACTIVITY_FAILURE, error }
    }
}


