import "react-circular-progressbar/dist/styles.css";
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

import {
  statsActions,
  shareActions,
  teamsActions,
  alertActions,
  usersActions,
  settingsActions
} from '../../_actions';
import IconClose from '../../_components/_icons/IconClose';
import DrawerMovimentations from '../../_components/_shared/DrawerMovimentations';
import KeyResultModalForm from '../../_components/goals/KeyResultModalForm';
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import DrawerDetailsObjective from '../../_components/_shared/DrawerDetailsObjective';
import DrawerSearchFilters from '../../_components/_shared/DrawerSearchFilters';
import { api } from '../../_helpers/api';
import ModalDetailTask from '../../_components/goals/ModalDetailTask';
import { authHeader } from '../../_helpers';
import { ModalNewTaskEdit } from '../../_components/goals/ModalNewTaskEdit';
import { KanbanGoalsDetails } from '../../_components/goals/KanbanGoalsDetails';
import { Header } from '../../_components/goals/GoalsPageDetails/Header';
import { Table } from '../../_components/goals/GoalsPageDetails/Table';
import { ModalAddNewTask } from '../../_components/goals/ModalAddNewTask';
import { ModalEditKeyResult } from '../../_components/goals/ModalEditKeyResult';

import './styles.scss'
import MenuMU from "../../_components/_shared/MenuMU";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootTab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '37ch',
  },
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '940px',
    height: '560px',
    outline: 'none',
    borderRadius: '8px'
  }
}));

function GoalsPageDetails(props) {
  const { t } = useTranslation('common');

  let path = window.location.pathname.split("/") || [];

  const { alertActions, shareActions, userDetails, settingsActions } = props;

  let filter = props.filter || {};
  const { teamsActions, companyInformation, usersActions, users } = props;

  const params = useParams()
  const search = useLocation().search;
  const kr_ids = new URLSearchParams(search).get("kr_ids");

  const classes = useStyles();

  const [drawerMovimentations, setDrawerMovimentations] = useState(false)
  const [drawerDetailsObjective, setDrawerDetailsObjective] = useState(false)
  const [drawerSearchFilters, setDrawerSearchFilters] = useState(false)
  const [currentObjective, setCurrentObjective] = useState({})
  const [groups, setGroups] = useState([])
  const [changeViewer, setChangeViewer] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false);
  const [openModalNewTask, setOpenModalNewTask] = useState(false);
  const [openModalNewKr, setOpenModalNewKr] = useState(false);
  const [openModalEditKR, setOpenModalEditKR] = useState(false);
  const [openModalNewTaskEdit, setOpenModalNewTaskEdit] = useState(false);
  const [currentTaskToModal, setCurrentTaskToModal] = useState({})
  const [categories, setCategories] = useState([])
  const [kr, setKr] = useState('')
  const [keyResults, setKeyResults] = useState([])
  const [isLoadingDetailModal, setIsLoadingDetailModal] = useState(true)
  const [teammates, setTeammates] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const token = authHeader()

  function toggleMenu(type) {
    if (type == 'main') {
      setIsMenuOpen(!isMenuOpen);
    } else {
      if (isMobile) {
        setIsMenuOpen(false)
      }
    }
  };

  function toggleFromMenu(value) {

    if (value == 'closed') {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false)
    }
  }

  function closeModalFormGoals() {
    shareActions.closeModalFormGoals()
  }

  function closeModalEditKeyResult() {
    setOpenModalEditKR(false);
  }

  function closeDrawerMovimentations() {
    setDrawerMovimentations(false)
  }

  function openDrawerMovimentations(data) {
    getAllKeyResults()
    setDrawerMovimentations(true)
  }

  function closeDrawerDetailsObjective() {
    setDrawerDetailsObjective(false)
  }

  function openDrawerDetailsObjective(data) {
    setDrawerDetailsObjective(true)
  }


  function openDrawerSearchFilters(data) {
    getAllKeyResults()
    setDrawerSearchFilters(true)
  }


  const getGoalsResponsibleData = async () => {
    try {
      const response = await api.get(`/goals/get-responsibles?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })

      if (response.status === 200) {
        setTeammates(response.data)
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  const getGoalsDetails = async (closedModal = false) => {
    try {
      if (!closedModal)
        setIsLoading(true)

      let url = `goals/${params?.id}/?organization_id=${userDetails?.organizationId}`

      if (kr_ids !== null) {
        url += `&kr_ids=${kr_ids}`
      }

      const result = await api.get(url, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      setCurrentObjective(result?.data)
      usersActions.refresh(userDetails);
      setIsLoading(false)
    } catch (error) {
      //alertActions.error(error)
    }
  }

  const attGoalsDetails = async () => {
    try {
      const result = await api.get(`goals/${params?.id}/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      setCurrentObjective(result?.data)
    } catch (error) {
      //alertActions.error(error)
    }
  }

  const markAsDone = async (task_id) => {
    try {
      const formData = new FormData();
      const result = await api.post(`/goals/key-results/tasks/${task_id}/mark_as_done?organization_id=${userDetails?.organizationId}`,
        formData,
        {
          headers: {
            'Authorization': token.Authorization
          }
        })
      if (result?.status === 200) {
        attGoalsDetails()
      }
    } catch (error) {
      console.log(error)
      //alertActions.error(error)
    }
  }

  const toFileTask = async (task_id, toggleRemoveDialog = () => { }) => {
    try {
      const result = await api.put(`/goals/key-results/tasks/${task_id}/deactivate/?organization_id=${userDetails?.organizationId}`,
        {
          action_id: 7,
          from: {},
          to: {},
          datetime: new Date()
        },
        {
          headers: {
            'Authorization': token.Authorization
          }
        })
      if (result?.status === 200) {
        toggleRemoveDialog()
        attGoalsDetails()
        alertActions.success(t('goals.success.deactive'))
      }
    } catch (error) {
      console.log("/error", error)
      //alertActions.error(error)
    }
  }

  const getAllKeyResults = async () => {
    try {
      const result = await api.get(`goals/key-results/all/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })

      setKeyResults(result?.data?.keyResults)

    } catch (error) {
      //alertActions.error(error)
    }

  }

  const getTeams = async () => {
    try {
      const response = await api.get(`/goals/teams?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })

      setGroups(response.data)

    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('goals.errors.get_goals'))
    }
  }

  const getTaskDetail = async (taskId) => {
    try {
      setIsLoadingDetailModal(true)
      const result = await api.get(`goals/key-results/tasks/${taskId}/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      setCurrentTaskToModal(result?.data)

      setIsLoadingDetailModal(false)
    } catch (error) {
      //alertActions.error(error)
    }

  }

  const getTaskDetailTwo = async (taskId) => {
    try {
      const result = await api.get(`goals/key-results/tasks/${taskId}/?organization_id=${userDetails?.organizationId}`, {
        headers: {
          'Authorization': token.Authorization
        }
      })
      setCurrentTaskToModal(result?.data)
    } catch (error) {
      //alertActions.error(error)
    }

  }

  const showModalTask = (task, kr) => {
    getTaskDetail(task?.id)
    setKr(kr)
    setOpen(true)
  }

  const showModalNewTask = (kr) => {
    setKr(kr);
    setOpenModalNewTask(true)
  }

  const showModalEditKeyResult = (kr) => {
    setKr(kr);
    setOpenModalEditKR(true);
  }

  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false);
    setOpenModalNewTaskEdit(false);
    setIsLoadingDetailModal(false)
    getGoalsDetails(true)
  };

  const handleCloseTaskDetails = () => {
    setOpen(false);
    setOpenModalNewTask(false);
    setOpenModalEditKR(false);
    setOpenModalNewTaskEdit(false);
  };

  const handleCloseTaskEdit = (e) => {
    e.preventDefault()
    setOpenModalNewTaskEdit(false);
  };

  const getCategories = async () => {
    const token = authHeader()
    const response = await api.get('/activities/categories', {
      headers: {
        'Authorization': token.Authorization
      }
    })

    const categoriesFiltered = response.data?.filter(item => item.is_parent === true)

    setCategories(categoriesFiltered)
  }

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.goals') + ' | ' + currentObjective?.name;
    teamsActions.getTeams()
  }, [currentObjective])

  useEffect(() => {
    getGoalsDetails()
    getTeams()
    getGoalsResponsibleData()
    getCategories()
    usersActions.getAll()
    settingsActions.getCompanyInformation()

  }, [kr_ids])


  return (
    <React.Fragment>

      {path.includes('productivity') && (
        <>
          <div className={isMenuOpen ? "menuToggle open" : "menuToggle"}
            onClick={() => {
              toggleMenu('main')
            }}>
            <i className="arrow"></i>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <MenuMU menuStatus={isMenuOpen} toggle={toggleFromMenu} />
        </>
      )}

      <section className="mainContent">

        <Toolbar
          objectiveName={currentObjective?.name}
          companyPurpose={companyInformation?.purpose}
        />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          openDrawerMovimentations={(data) => {
            openDrawerMovimentations(data)
          }}
          actionsGoalsDetails={true}
          actionsGoals={false}
          companyPurpose={companyInformation?.purpose}
          changeViewerKrs={setChangeViewer}
          isLoading={isLoading}
          setOpenModalNewKr={setOpenModalNewKr}
        />

        {
          !changeViewer
            ?
            <main className='containerObjectiveDetails'>
              {
                currentObjective && (
                  <>
                    <Header
                      currentObjective={currentObjective}
                      isLoading={isLoading}
                      openDrawerDetailsObjective={openDrawerDetailsObjective}
                      openDrawerSearchFilters={openDrawerSearchFilters}
                    />

                    <Table
                      isLoading={isLoading}
                      currentObjective={currentObjective}
                      showModalEditKeyResult={showModalEditKeyResult}
                      showModalNewTask={showModalNewTask}
                      showModalTask={showModalTask}
                      markAsDone={markAsDone}
                      toFileTask={toFileTask}
                      userDetails={userDetails}
                      alertActions={alertActions}
                      updateGoalsDetails={getGoalsDetails}
                      teammates={teammates}
                    />
                  </>

                )
              }

            </main>
            :
            <KanbanGoalsDetails
              showModalNewTask={showModalNewTask}
              goalsData={currentObjective}
              showModalTask={showModalTask}
              openDrawerDetailsObjective={openDrawerDetailsObjective}
              userDetails={userDetails}
            />
        }

      </section>



      {/* Start Modal Detail Task */}
      {
        currentTaskToModal.length > 0 && open && !isLoadingDetailModal && (
          <ModalDetailTask
            open={open}
            handleClose={handleClose}
            currentTask={currentTaskToModal}
            setCurrentTaskToModal={setCurrentTaskToModal}
            isLoading={isLoadingDetailModal}
            setOpenModalNewTaskEdit={setOpenModalNewTaskEdit}
            updateGoalsDetails={getGoalsDetails}
            handleCloseTaskDetails={handleCloseTaskDetails}
            userDetails={userDetails}
            teammates={teammates}
            krType={kr?.type}
            date_end={kr?.date_end}
            date_start={kr?.date_start}
            end_value={kr?.end_value}
            typeInput={kr?.is_int_value ? 'int' : 'float'}
            groups={groups}
            teammatesResponsibleOp={teammates}
          />
        )
      }
      {/* End Modal Detail Task */}

      {/* Start Modal Add New Task */}

      <ModalAddNewTask
        alertActions={alertActions}
        categories={categories}
        getGoalsDetails={getGoalsDetails}
        groups={groups}
        kr={kr}
        openModalNewTask={openModalNewTask}
        setOpenModalNewTask={setOpenModalNewTask}
        teammatesOptions={teammates}
        userDetails={userDetails}
      />
      {/* End Modal Add New Task */}


      {/* Start Modal Edit Key Result */}
      <div>
        <ModalEditKeyResult
          alertActions={alertActions}
          closeModalEditKeyResult={closeModalEditKeyResult}
          groups={groups}
          kr={kr}
          openModalEditKR={openModalEditKR}
          setOpenModalEditKR={setOpenModalEditKR}
          teammatesOptions={teammates}
          userDetails={userDetails}
          updateGoalsDetails={getGoalsDetails}
          currentObjective={currentObjective}
        />
      </div>
      {/* End Modal Edit Key Result */}

      {currentTaskToModal.length > 0 && openModalNewTaskEdit && (
        <ModalNewTaskEdit
          categories={categories}
          classes={classes}
          groups={groups}
          userDetails={userDetails}
          teammates={teammates}
          openModalNewTaskEdit={openModalNewTaskEdit}
          handleClose={handleCloseTaskEdit}
          currentTaskToModal={currentTaskToModal}
          setOpenModalNewTaskEdit={setOpenModalNewTaskEdit}
          alertActions={alertActions}
          kr={kr}
          updateGoalsDetails={getGoalsDetails}
          updateTaskDetails={getTaskDetailTwo}
        />
      )}

      {/* Start Modal Add New  Key Result */}
      <div
        onClick={() => closeModalFormGoals()}
        className={'overlay overlayOpacity8' + (props.share.modalAddKeyResult ? ' open' : '')}
      >
      </div>
      <section
        className={'modalForm' + (props.share.modalAddKeyResult ? ' open' : '')}
      >
        <KeyResultModalForm
          openModalNewKr={openModalNewKr}
          setOpenModalNewKr={setOpenModalNewKr}
          teammates={teammates}
          currentObjective={currentObjective}
          groups={groups}
          updateGoalsDetails={getGoalsDetails}
        />
      </section>
      {/* End Modal Add New  Key Result */}

      {/* Start Drawer Movimentations */}
      <div
        className={'overlay' + (drawerMovimentations ? ' open' : '')}
        onClick={() => { setDrawerMovimentations(false) }}
      >
      </div>
      <section
        className={'drawer drawer400' + (drawerMovimentations ? ' open' : '')}
      >
        <button
          onClick={() => { setDrawerMovimentations(false) }}
          className="close"
        >
          <IconClose />
        </button>

        {drawerMovimentations &&
          <DrawerMovimentations closeDrawer={closeDrawerMovimentations} keyResults={keyResults} users={users} teammates={teammates} />
        }
      </section>
      {/* End Drawer Movimentations */}

      {/* Start Drawer Details Objective */}
      <div
        className={'overlay' + (drawerDetailsObjective ? ' open' : '')}
        onClick={() => { setDrawerDetailsObjective(false) }}>
      </div>
      <section
        className={'drawer drawer400' + (drawerDetailsObjective ? ' open' : '')}
      >
        <button
          onClick={() => { setDrawerDetailsObjective(false) }}
          className="close"
        >
          <IconClose />
        </button>

        {drawerDetailsObjective &&
          <DrawerDetailsObjective
            closeDrawer={closeDrawerDetailsObjective}
            title={currentObjective?.name}
            categories={categories}
          />
        }
      </section>
      {/* End  Drawer Details Objective */}

      {/* Start Drawer Search Filters */}
      <div
        className={'overlay' + (drawerSearchFilters ? ' open' : '')}
        onClick={() => { setDrawerSearchFilters(false) }}>

      </div>
      <section
        className={'drawer drawer400' + (drawerSearchFilters ? ' open' : '')}
      >
        <button
          className="close"
          onClick={() => { setDrawerSearchFilters(false) }}
        >
          <IconClose />
        </button>

        {drawerSearchFilters &&
          <DrawerSearchFilters
            currentObjective={currentObjective}
            keyResults={currentObjective.keyresults}
            setCurrentObjective={setCurrentObjective}
            userDetails={userDetails}
          />
        }
      </section>
      {/* End Drawer Search Filters */}


    </React.Fragment>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    teams: state.teams.payload,
    loadingStat: state.stats.loading,
    filter: state.filter,
    share: state.share,
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    companyInformation: state.settings.companyInformation
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsPageDetails)