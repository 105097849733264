import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function HistoricalByHourSkeleton(props) {
    const {t, i18n} = useTranslation('common');


    return (


        <div className="box loading noBg">
            <div className="pageHeader">
                <h3><Skeleton variant="text" width="40%" height={22}/></h3>
                <div className="internalFilters">
                    <div className="filter">
                        <div className="icon">
                            <Skeleton variant="circle" width={16} height={16}/>
                        </div>
                        <div className="info">
                            <div className="name"><Skeleton variant="text" width="100%" height={17}/></div>
                            <div className="total"><Skeleton variant="text" width="100%" height={17}/></div>
                        </div>
                        <div className="number">

                        </div>
                    </div>
                    <div className="filter">
                        <div className="icon">
                            <Skeleton variant="circle" width={16} height={16}/>
                        </div>
                        <div className="info">
                            <div className="name"><Skeleton variant="text" width="100%" height={17}/></div>
                            <div className="total"><Skeleton variant="text" width="100%" height={17}/></div>
                        </div>
                        <div className="number">

                        </div>
                    </div>

                    <div className="filter">
                        <div className="icon">
                            <Skeleton variant="circle" width={16} height={16}/>
                        </div>
                        <div className="info">
                            <div className="name"><Skeleton variant="text" width="100%" height={17}/></div>
                            <div className="total"><Skeleton variant="text" width="100%" height={17}/></div>
                        </div>
                        <div className="number">

                        </div>
                    </div>
                    <div className="filter">
                        <div className="icon">
                            <Skeleton variant="circle" width={16} height={16}/>
                        </div>
                        <div className="info">
                            <div className="name"><Skeleton variant="text" width="100%" height={17}/></div>
                            <div className="total"><Skeleton variant="text" width="100%" height={17}/></div>
                        </div>
                        <div className="number">

                        </div>
                    </div>
                    <div className="filter">
                        <div className="icon">
                            <Skeleton variant="circle" width={16} height={16}/>
                        </div>
                        <div className="info">
                            <div className="name"><Skeleton variant="text" width="100%" height={17}/></div>
                            <div className="total"><Skeleton variant="text" width="100%" height={17}/></div>
                        </div>
                        <div className="number">

                        </div>
                    </div>


                </div>
            </div>


            <div className="dataTable">
                <Skeleton variant="rect" width="100%" height={447}/>

            </div>


        </div>
    )
};

export default HistoricalByHourSkeleton;
