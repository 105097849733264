import { Box, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material'
import { CheckCircle, DotsNine } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IconMainLogoV2 from '../../_components/_icons/IconMainLogoV2'
import { CardModule } from '../../_components/switch_page/CardModule'
import Header from '../../_components/switch_page/Header'

import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import { useCurrentModule } from '../../contexts/CurrentModuleContext'
import style from './ModuleDetailsPage.module.scss'
import { authHeader, befectiveFormatDate, doCountAccessModule, history } from '../../_helpers'
// import { Carousel } from 'react-carousel-minimal';
import IconModuleProductivityManagement from '../../_components/_icons/IconModuleProductivityManagement'
import IconModuleGoalManagement from '../../_components/_icons/IconModuleGoalManagement'
import IconModuleVirtualPoint from '../../_components/_icons/IconModuleVirtualPoint'
import IconModuleCheckInRemote from '../../_components/_icons/IconModuleCheckInRemote'
import IconModuleSpaceManagement from '../../_components/_icons/IconModuleSpaceManagement'
import IconModuleProjectManagement from '../../_components/_icons/IconModuleProjectManagement'
import IconModuleInventory from '../../_components/_icons/IconModuleInventory'
import IconThreePoints from '../../_components/_icons/IconThreePoints'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { api } from '../../_helpers/api'
import SkeletonModuleDetailsPage from '../../_components/switch_page/SkeletonModuleDetailsPage'
import { ModalCreateLead } from '../../_components/switch_page/ModalCreateLead'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AlertBox from '../../_components/_shared/AlertBox'

function ModuleDetailsPage({ userDetails, usersActions, alertActions }) {
  const { t, i18n } = useTranslation('common');

  const lang = userDetails.language || i18n.language

  const [fetching, setFetching] = useState(true)
  const [openModalLead, setOpenModalLead] = useState(false)
  const { id } = useParams()
  const [module, setModule] = useState(null)

  const getDetailsModule = async () => {
    try {
      setFetching(true)
      const response = await api.get(`/switch/module/${id}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Error to load data details module')
      }
      if(response.data){
        setModule(response.data)
        setFetching(false)
      }

    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }

  useEffect(() => {

    getDetailsModule()

    document.title = 'Befective | Home ';

  }, [])


  const getIconModule = (module_key, active) => {
    return {
      productivity_management: <IconModuleProductivityManagement active={active} />,
      goals_management: <IconModuleGoalManagement active={active} />,
      check_in_remote: <IconModuleVirtualPoint active={active} />,
      check_in_remote_mobile: <IconModuleCheckInRemote active={active} />,
      rooms_management: <IconModuleSpaceManagement active={active} />,
      project_management: <IconModuleProjectManagement active={active} />,
      inventory: <IconModuleInventory active={active} />
    }[module_key]
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Define a posição do scroll para o topo quando a rota mudar
  }, [history.location.pathname]); // Dispara o efeito quando a rota mudar

  return (
    <Box
      bgcolor="#F5F6F9"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />
      <Header toBackUrl={'/'} />
      {
        (!fetching && module !== null)
          ?

          <Container component={'main'} maxWidth="lg" className={style.containerMain} sx={{ flexGrow: 1 }}>

            <Grid container spacing={2} component={'section'} mt={'32px'}>
              <Grid item xs={3}>
                <div className={style.cardModule}>
                  <div className={style.cardHeader}>
                    {getIconModule(module.name, false)}
                    <h6 className={style.titleModule}>{module[`short_name_${lang}`]}</h6>
                    {/* <h6 className={style.titleModule}>{t(`switch.${module.name}`)}</h6> */}
                  </div>
                  <p className={style.abstract}>
                    {module[`abstract_${lang}`]}
                  </p>
                  <hr className={style.divider} />
                  {
                    module[`attributes_${lang}`].length > 0 &&
                    module[`attributes_${lang}`].map((att, index) => (
                      <div key={`att-${index}`} className={style.attributeItem}>
                        <div><CheckCircle size={18} color="#22BEAD" /></div>
                        <Typography fontSize={12} color="#1E6B7F" component="span">
                          {att}
                        </Typography>
                      </div>
                    ))
                  }
                  <button className={style.buttonSale} onClick={() => setOpenModalLead(true)}>
                    {t('module_details_page.btn_interested')}
                  </button>
                </div>
              </Grid>
              <Grid item xs={9}>
                {
                  module[`banner_url_${lang}`] &&
                  <div className={style.boxBanner}>
                    <img className={style.imgBanner} src={module[`banner_url_${lang}`]} alt="Banner" />
                  </div>
                }

                <div className={style.cardDescription}>
                  <h6 className={style.titleCard}>{module[`name_${lang}`]}</h6>
                  <div
                    className={style.bodyDescription}
                    dangerouslySetInnerHTML={{ __html: module[`body_description_${lang}`] }}
                  />
                  {/* <p className={style.bodyDescription}>{module[`body_description_${lang}`]}</p> */}
                </div>

                <div className={style.cardBenefits}>
                  <h6 className={style.titleCard}>
                    {t('module_details_page.title_benefits')}
                  </h6>
                  {
                    module[`benefit_${lang}`] &&
                    <ul className={style.listBenefits}>
                      {
                        module[`benefit_${lang}`].map((benefit, index) => (
                          <li key={`benefit-${index}`} className={style.itemBenefits}>
                            <div><CheckCircle size={20} color="#22BEAD" weight="fill" /></div>
                            <Typography fontSize={14} color="#1E6B7F" component="span">
                              <strong>{benefit[`benefit_title_${lang}`]}: </strong>{benefit[`benefit_description_${lang}`]}
                            </Typography>
                          </li>
                        ))
                      }
                    </ul>
                  }
                </div>

              </Grid>
            </Grid>

            <ModalCreateLead
              openModal={openModalLead}
              closeModal={() => setOpenModalLead(false)}
              userDetails={userDetails}
              moduleKey={module.module_name}
              alertActions={alertActions}
            />

          </Container>

          :

          <SkeletonModuleDetailsPage />
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleDetailsPage));