import { Container, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonNewsDetailsPage(props) {

  return (

    <Container component={'main'} maxWidth="lg" sx={{ flexGrow: 1, padding: '16px 0px' }}>

      <Grid container spacing={2} mt={'16px'} mb={'28px'}>
        <Grid item xs={12} >
          <Skeleton width={'100%'} height={200} variant='rounded' />
        </Grid>
      </Grid>

      <Grid container spacing={2} component={'section'} marginTop={'4px'} marginBottom={'32px'}>
        <Grid item xs={12} >
          <Typography component="div" variant='h1'>
            <Skeleton width={150} />
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Skeleton width={'100%'} height={240} variant='rounded' />
        </Grid>
      </Grid>

      <Grid container spacing={2} component={'section'} marginBottom={'32px'}>
        <Grid item xs={12} >
          <Typography component="div" variant='h1'>
            <Skeleton width={150} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="stretch">
            {
              [1, 2, 3].map(module_inactive => (
                <Grid key={`module-inactive-${module_inactive}`} item xs={12} sm={4} display={'flex'} alignItems="stretch">
                  <Skeleton width={'100%'} height={180} variant='rounded' />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>

    </Container>
  )
}