export const historicalConstants = {
    GET_HISTORICAL_SUMMARY_REQUEST: '[HISTORICAL] GET_HISTORICAL_SUMMARY_REQUEST',
    GET_HISTORICAL_SUMMARY_SUCCESS: '[HISTORICAL] GET_HISTORICAL_SUMMARY_SUCCESS',
    GET_HISTORICAL_SUMMARY_FAILURE: '[HISTORICAL] GET_HISTORICAL_SUMMARY_FAILURE',
    GET_HISTORICAL_TEAMMATEDAY_REQUEST: '[HISTORICAL] GET_HISTORICAL_TEAMMATEDAY_REQUEST',
    GET_HISTORICAL_TEAMMATEDAY_SUCCESS: '[HISTORICAL] GET_HISTORICAL_TEAMMATEDAY_SUCCESS',
    GET_HISTORICAL_TEAMMATEDAY_FAILURE: '[HISTORICAL] GET_HISTORICAL_TEAMMATEDAY_FAILURE',
    GET_HISTORICAL_HOUR_ACTIVITIES_REQUEST: '[HISTORICAL] GET_HISTORICAL_HOUR_ACTIVITIES_REQUEST',
    GET_HISTORICAL_HOUR_ACTIVITIES_SUCCESS: '[HISTORICAL] GET_HISTORICAL_HOUR_ACTIVITIES_SUCCESS',
    GET_HISTORICAL_HOUR_ACTIVITIES_FAILURE: '[HISTORICAL] GET_HISTORICAL_HOUR_ACTIVITIES_FAILURE'
};
