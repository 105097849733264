import React from 'react';

const IconAdd = props => {
    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7">
        <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M6.5,5H4.25V2.75a.5.5,0,0,0-.5-.5h-.5a.5.5,0,0,0-.5.5V5H.5a.5.5,0,0,0-.5.5V6a.5.5,0,0,0,.5.5H2.75V8.75a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V6.5H6.5A.5.5,0,0,0,7,6V5.5A.5.5,0,0,0,6.5,5Z" transform="translate(0 -2.25)" fill="#22bead"/>
      </svg>
      
    )
}

export default IconAdd;