import React, { useEffect, useState } from 'react';
import { Avatar, Menu, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import {
  authHeader,
  getAvatarUrl,
  getColorStatusKr,
  getLabelByStatusId,
  getPriority,
  getStatusClass,
  getStatusClassColor,
  numberToPercentString
} from '../../../../_helpers';
import IconClock from '../../../_icons/IconClock';
import IconThreePoints from '../../../_icons/IconThreePoints';
import TagsList from '../../TagsList';
import IconBalon from '../../../_icons/IconBalon';
import ConfirmDialog from '../../../_shared/ConfirmDialog';
import { api } from '../../../../_helpers/api';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import InputTime from '../../../_shared/InputTime';
import moment from 'moment';
import { InputValue } from '../../../_shared/InputValue/InputValue';
import ModalCommentsKR from '../../ModalCommentsKR';
import MuiSelect from "@material-ui/core/Select";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import './styles.scss'

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
});

const PrettoSlider = withStyles({
  root: {
    height: 10,
    width: 100
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    marginTop: -1,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#E4EAEF'
  },
})(Slider);

export function Table({
  isLoading,
  currentObjective,
  showModalEditKeyResult,
  showModalTask,
  showModalNewTask,
  markAsDone,
  toFileTask,
  userDetails,
  alertActions,
  updateGoalsDetails,
  teammates
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const token = authHeader()

  const [dataSorted, setDataSorted] = useState(currentObjective)
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentKr, setCurrentKr] = useState([]);
  const [anchorElTaskOnTable, setAnchorElTaskOnTable] = useState(null);
  const [anchorElTaskOnEditValue, setAnchorElTaskOnEditValue] = useState(null);
  const [anchorElTaskOnTableToTime, setAnchorElTaskOnTableToTime] = useState(null);
  const [currentTaskOnTable, setCurrentTaskOnTable] = useState(null);
  const [currentTaskOnTableToTime, setCurrentTaskOnTableToTime] = useState(null);
  const [valueProgress, setValueProgress] = useState(null)
  const [isUpdatingProgress, setIsUpdatingProgress] = useState(null)
  const [anchorElOptionsKeyResult, setAnchorElOptionsKeyResult] = useState(null);
  const [currentKeyResult, setCurrentKeyResult] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [krValue, setKrValue] = useState(null)
  const [openCommentsKr, setOpenCommentsKr] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSliderChange = (event, newValue) => {
    setValueProgress(newValue);
  };

  async function handleUpdateKrProgress() {
    try {
      if (krValue !== null && Number(krValue) === Number(currentKeyResult?.value)) {
        setIsSubmitted(false)
        return
      }

      setIsUpdatingProgress(true)

      const payload = {
        value: Number(krValue),
        datetime: moment().format()
      }
      await api.patch(`goals/key-results/${currentKeyResult?.id}?organization_id=${userDetails?.organizationId}`, payload,
        {
          headers: {
            'Authorization': token.Authorization
          }
        }
      )
      setCurrentKeyResult(null)
      setKrValue(null)
      updateGoalsDetails(true);
    } catch (error) {
      console.log('Response Error -> ', error);
    } finally {
      setIsUpdatingProgress(false)
      setIsSubmitted(false)
    }
  }

  const updateProgressTask = async () => {
    try {
      let payload

      if (valueProgress !== null && Number(valueProgress) === Number(currentTaskOnTable.progress)) {
        return
      }

      if (valueProgress === null) {
        return
      }

      setIsUpdatingProgress(true)

      payload = {
        progress: valueProgress,
        datetime: moment().format()
      };


      const result = await api.put(`goals/key-results/tasks/${currentTaskOnTable?.id}/small/?organization_id=${userDetails?.organizationId}`, payload, {
        headers: {
          'Authorization': token.Authorization
        }
      });

      // setValue(null)
      setIsUpdatingProgress(false)

      setCurrentTaskOnTable(result.data?.task)

      handleCloseMenuTaskOnTable()
      // handleCloseMenuEditValue()
      updateGoalsDetails(true);
    } catch (error) {
      // setValue(null)
      setIsUpdatingProgress(false)
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const handleClick = (event, task_id) => {
    setCurrentTask(task_id)
    setAnchorEl(event.currentTarget);
  };

  const handleClickKrOnTableEditValue = (event, kr) => {
    setCurrentKeyResult(kr);
    setKrValue(Number(kr?.value))
    setAnchorElTaskOnEditValue(event.currentTarget);
  };

  const handleClickTaskOnTable = (event, task, kr) => {
    setCurrentKr(kr)
    setCurrentTaskOnTable(task)
    setValueProgress(parseInt(task?.progress))
    setAnchorElTaskOnTable(event.currentTarget);
  };

  const handleClickTaskOnTableToTime = (event, task) => {
    setCurrentTaskOnTableToTime(task)
    setAnchorElTaskOnTableToTime(event.currentTarget);
  };

  const handleCloseMenuOptionsKeyResult = () => {
    setAnchorElOptionsKeyResult(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenuEditValue = () => {
    setAnchorElTaskOnEditValue(null);
  };

  const handleCloseMenuTaskOnTable = () => {
    setAnchorElTaskOnTable(null);
    setValueProgress(null)
  };

  const handleCloseMenuTaskOnTableToTime = () => {
    setCurrentTaskOnTableToTime(null);
    setAnchorElTaskOnTableToTime(null);
  };

  const handleClickMenuKeyResult = (e, kr) => {
    setAnchorElOptionsKeyResult(e.currentTarget);
    setCurrentKeyResult(kr);
  }

  function handleOpenModalEditKeyResult(currentKeyResult) {
    showModalEditKeyResult(currentKeyResult)
    handleCloseMenuOptionsKeyResult()
  }

  const handleArchiveKeyResult = async () => {

    try {

      await api.put(`/goals/key-results/${currentKeyResult?.id}/deactivate/?organization_id=${userDetails?.organizationId}`, {
        action_id: 8,
        from: {},
        to: {},
        datetime: new Date()
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      setAnchorElOptionsKeyResult(null);
      toggleRemoveDialog()
      updateGoalsDetails(true)

      alertActions.success(t('goals.success.deactive'))
    } catch (error) {
      //alertActions.error(error)
    }
  }

  function toggleRemoveDialog(id, type) {

    let elems = document.querySelectorAll(".removeDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById(`remove${type}-${id}`)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  const showCommentsKrModal = (kr) => {
    // getTaskDetail(task?.id)
    setCurrentKr(kr)
    setOpenCommentsKr(true)
  }

  const handleCloseCommentsKrModal = (e) => {
    e.preventDefault()
    setOpenCommentsKr(false);
  };

  const handleDragEndKr = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const kr_id = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const keyResultFiltered = currentObjective.keyresults.find(kr => Number(kr.id) === Number(kr_id))

    const newItems = Array.from(currentObjective.keyresults);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setDataSorted({
      ...currentObjective,
      keyresults: newItems
    });

    try {
      await api.patch(`/goals/key-result/${kr_id}/update-index?organization_id=${userDetails?.organizationId}`, {
        source: source,
        destination: destination,
        goal_id: keyResultFiltered.goal_id,
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      updateGoalsDetails(true)
    } catch (error) {
      console.log(error.response)
    }
  };

  const handleDragEndTask = async (result, kr_id) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const source = result.source.index
    const destination = result.destination.index
    const task_id = result.draggableId

    if (destination === source) return; // Se moveu para a mesma posição

    const keyResultFiltered = currentObjective.keyresults.find(kr => Number(kr.id) === Number(kr_id))
    const keyResultFilteredIndex = currentObjective.keyresults.findIndex(kr => Number(kr.id) === Number(kr_id))

    const keyResultFilteredWithoutCurrentKr = currentObjective.keyresults.filter(kr => Number(kr.id) !== Number(kr_id))

    const newItems = Array.from(keyResultFiltered.tasks);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    const newKr = {
      ...keyResultFiltered,
      tasks: newItems
    }

    const newKrsList = [
      ...keyResultFilteredWithoutCurrentKr,
      newKr
    ]

    const newKrItems = Array.from(newKrsList)
    const [reorderedKrItems] = newKrItems.splice(newKrItems.length - 1, 1)
    newKrItems.splice(keyResultFilteredIndex, 0, reorderedKrItems)

    setDataSorted({
      ...currentObjective,
      keyresults: newKrItems
    });

    try {
      await api.patch(`/goals/key-result/tasks/${task_id}/update-index?organization_id=${userDetails?.organizationId}`, {
        source: source,
        destination: destination,
        kr_id: kr_id
      },
        {
          headers: {
            'Authorization': token?.Authorization
          }
        })

      updateGoalsDetails(true)
    } catch (error) {
      console.log(error.response)
    }
  };

  useEffect(() => {
    if (currentTaskOnTable) {
      const delayDebounceFn = setTimeout(() => {
        updateProgressTask();
      }, 1000)

      return () => clearTimeout(delayDebounceFn)

    }
  }, [valueProgress])

  useEffect(() => {
    if (currentKeyResult && isSubmitted) {

      const delayDebounceFn = setTimeout(() => {
        handleUpdateKrProgress();
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [krValue, isSubmitted])

  useEffect(() => {
    if (currentObjective) {
      setDataSorted(currentObjective)
    }
  }, [currentObjective])

  return (
    <>
      <table style={{ marginBottom: 4 }}>
        <thead >
          {
            isLoading ? (
              <tr className='boxColumnsIndicators'>
                <th className='widthLarge'><div><Skeleton variant="text" /></div></th>
                <th width={'10%'} className="textCenter"><div><Skeleton variant="text" /></div></th>
                <th width={'10%'}><div className="textCenter"><Skeleton variant="text" /></div></th>
                <th width={'14%'}><div className="textCenter"><Skeleton variant="text" /></div></th>
                <th width={'8%'}><div className="textCenter"><Skeleton variant="text" /></div></th>
                <th width={'14%'}><div><Skeleton variant="text" /></div></th>
                <th width={'6%'}><div className='textCenter'><Skeleton variant="text" /></div></th>
                <th className='widthSmall' style={{ padding: '0 15px' }}><div></div></th>
              </tr>
            ) : (
              <tr className='boxColumnsIndicators'>
                <th className='widthLarge'><div>{t('keyresults.table_label.keyresult')}</div></th>
                <th width={'10%'} className="textCenter"><div>{t('keyresults.table_label.progress')}</div></th>
                <th width={'10%'}><div className="textCenter">{t('keyresults.table_label.status')}</div></th>
                <th width={'8%'}><div className="textCenter">{t('keyresults.table_label.responsible')}</div></th>
                <th width={'14%'}><div className="textCenter">{t('keyresults.table_label.co_responsibles')}</div></th>
                <th width={'8%'}><div>{t('keyresults.table_label.priority')}</div></th>
                <th width={'14%'}><div>{t('keyresults.table_label.tags')}</div></th>
                <th><div className='textCenter'>{t('keyresults.table_label.time')}</div></th>
                <th className='widthSmall' style={{ padding: '0 15px' }}><div></div></th>
              </tr>
            )
          }
        </thead>
      </table>

      {
        <DragDropContext onDragEnd={handleDragEndKr}>
          <Droppable droppableId="krs">
            {(provided) => (
              <div
                className='krs-drag-drop-container'
                ref={provided.innerRef}
              >
                {
                  dataSorted?.keyresults?.map((kr, index) => (
                    <Draggable
                      key={kr.id}
                      draggableId={String(kr.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className='cardKeyResultList'
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}

                        >
                          <table className='table-details-goals'>

                            <thead>
                              <tr
                                className={`headerKeyResult bg-${getStatusClass(kr?.krStatus)}`}
                                {...provided.dragHandleProps}
                              >
                                <th className='widthLarge'
                                  onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}
                                  // style={{padding: '22px 12px', position: 'relative'}}
                                >
                                  <div>{kr?.title}</div>
                                  {/* <span className='krType'>
                                    {kr.type ? t('modalFormKeyResult.type_result') : t('modalFormKeyResult.type_value')}
                                  </span> */}
                                </th>
                                {kr?.type === false ? (
                                  <th width={'10%'} onClick={(e) => userDetails?.krsPermissions.includes(Number(kr.id)) && handleClickKrOnTableEditValue(e, kr)}>
                                    <div className='boxProgress'>
                                      <div className={`${userDetails?.krsPermissions.includes(Number(kr.id)) && 'krHover'} progressWrapper`}>
                                        <div className={`circle border-${getStatusClass(kr?.krStatus)}`} >
                                          <CircularProgressbar
                                            value={Number(kr?.progress)}
                                            strokeWidth={50}
                                            styles={buildStyles({
                                              strokeLinecap: "butt",
                                              pathColor: getColorStatusKr(Number(kr?.krStatus)),
                                              trailColor: getStatusClassColor(kr?.krStatus),
                                            })}
                                          />
                                        </div>
                                        <div>{numberToPercentString(Number(kr?.progress))}</div>
                                      </div>
                                    </div>
                                  </th>
                                ) : (
                                  <th width={'10%'} onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}>
                                    <div className='boxProgress'>
                                      <div className={`${userDetails?.krsPermissions.includes(Number(kr.id)) && 'krHover'} progressWrapper`}>
                                        <div className={`circle border-${getStatusClass(kr?.krStatus)}`} >
                                          <CircularProgressbar
                                            value={Number(kr?.progress)}
                                            strokeWidth={50}
                                            styles={buildStyles({
                                              strokeLinecap: "butt",
                                              pathColor: getColorStatusKr(Number(kr?.krStatus)),
                                              trailColor: getStatusClassColor(kr?.krStatus),
                                            })}
                                          />
                                        </div>
                                        <div>{numberToPercentString(Number(kr?.progress))}</div>
                                      </div>
                                    </div>
                                  </th>
                                )}

                                <th width={'8%'} onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}></th>
                                <th width={'8%'} onClick={() => showCommentsKrModal(kr)}>
                                  <div className='textCenter'>
                                    {/* {task?.username} */}
                                    <AvatarGroup max={4}>
                                      <Tooltip key={index} title={kr?.responsible_name}>
                                        <Avatar
                                          alt={kr?.responsible_name}
                                          src={getAvatarUrl({ name: kr?.responsible_name })}
                                          style={{ width: 24, height: 24 }}
                                        />
                                      </Tooltip>
                                    </AvatarGroup>

                                  </div>
                                </th>
                                <th width={'14%'} onClick={() => showCommentsKrModal(kr)}>
                                  {
                                    <AvatarGroup max={3}>
                                      {
                                        kr?.team.length
                                          ?
                                          kr?.team?.map((co_responsible, index) => (
                                            <Tooltip key={index} title={co_responsible?.username}>
                                              <Avatar alt={co_responsible?.username} src={getAvatarUrl({
                                                name: co_responsible?.username
                                              })} style={{ width: 22, height: 22 }} />
                                            </Tooltip>
                                          ))
                                          :
                                          <div style={{ border: 'none' }}>---</div>
                                      }

                                    </AvatarGroup>
                                  }

                                </th>
                                <th width={'8%'} onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}></th>
                                <th width={'10%'} onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}></th>
                                <th width={'6%'} onClick={() => userDetails?.krsPermissions.includes(Number(kr.id)) && handleOpenModalEditKeyResult(kr)}>
                                  <div className='content-time'>
                                    <div className={`clock-box-icon fill-${getStatusClass(kr?.krStatus)}`}>
                                      <IconClock />
                                    </div>
                                    <div className='textCenter'>
                                      {kr?.time === '0:0' ? ('00:00') : (kr?.time)}
                                    </div>
                                  </div>
                                </th>
                                {showModalEditKeyResult && (
                                  <th
                                    className='actions widthSmall'
                                    style={{ margin: 0 }}
                                  >
                                    <div
                                      onClick={(e) => userDetails?.krsPermissions.includes(Number(kr.id)) && handleClickMenuKeyResult(e, kr)}
                                      className={`three-points fill-${getStatusClass(kr?.krStatus)}`}
                                      style={{ opacity: userDetails?.krsPermissions.includes(Number(kr.id)) ? '' : '0' }}
                                    >
                                      <IconThreePoints />
                                    </div>
                                    <div className="removeDialog" id={"removeKr-" + kr?.id}>
                                      <div className="content">
                                        <div className="message">
                                          {/* <IconInfo /> */}
                                          {t('confirmDialog.title')}
                                        </div>
                                        <div className="options">
                                          <button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
                                          <button onClick={() => { handleArchiveKeyResult() }}>{t('confirmDialog.yes')}</button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                )}
                              </tr>
                            </thead>

                            <DragDropContext onDragEnd={(result) => handleDragEndTask(result, kr.id)}>
                              <Droppable droppableId="tasks">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                  >
                                    {
                                      kr?.tasks?.length > 0 && (
                                        kr?.tasks.map((task, index) => (
                                          <Draggable
                                            key={task.id}
                                            draggableId={String(task.id)}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <tr
                                                className={`rowActivity ${snapshot.isDragging ? 'dragging' : ''}`}
                                                key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <td className='widthLarge' onClick={(e) => showModalTask(task, kr)}>
                                                  <div className='box-title-task'>
                                                    {
                                                      task?.title.length > 35 ?
                                                        <Tooltip title={task?.title}>
                                                          <span>{task?.title.slice(0, 35) + '...'}</span>
                                                        </Tooltip>
                                                        :
                                                        <span>{task?.title}</span>
                                                    }
                                                    <button>
                                                      <span>{task?.total_comments}</span>
                                                      <IconBalon />
                                                    </button>
                                                  </div>
                                                </td>
                                                <td width={'10%'}>

                                                  <Tooltip key={index} title={task?.progress + '%'}>
                                                    {task.total_items > 0 ? (
                                                      <div className="progress-column-container">
                                                        <div
                                                          className={`bar-progress ${getStatusClass(task?.status_id)}`}
                                                          style={{ width: task?.progress + '%' }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="progress-column-container"
                                                        onClick={(e) => userDetails?.tasksPermissions.includes(Number(task.id)) && handleClickTaskOnTable(e, task, kr)}
                                                      >
                                                        <div
                                                          className={`bar-progress ${getStatusClass(task?.status_id)}`}
                                                          style={{ width: task?.progress + '%' }}
                                                        />
                                                      </div>
                                                    )}
                                                  </Tooltip>
                                                </td>
                                                <td
                                                  className={`tdStatusBox ${getStatusClass(task?.status_id)}`}
                                                  width={'8%'}
                                                >
                                                  <div className={`statusBox textCenter`}>
                                                    {t(getLabelByStatusId(task?.status_id))}
                                                  </div>
                                                </td>
                                                <td width={'8%'} onClick={(e) => showModalTask(task, kr)}>
                                                  <div className='textCenter'>
                                                    {/* {task?.username} */}
                                                    <AvatarGroup max={4}>
                                                      <Tooltip key={index} title={task?.username}>
                                                        <Avatar
                                                          alt={task?.username}
                                                          src={getAvatarUrl({ name: task?.username })}
                                                          style={{ width: 24, height: 24 }}
                                                        />
                                                      </Tooltip>
                                                    </AvatarGroup>

                                                  </div>
                                                </td>
                                                <td
                                                  width={'14%'}
                                                  className='textCenter'
                                                  onClick={(e) => showModalTask(task, kr)}
                                                >
                                                  {task?.team?.length ? (
                                                    <div>
                                                      <AvatarGroup max={3}>
                                                        {
                                                          task?.team?.map((co_responsible, index) => (
                                                            <Tooltip key={index} title={co_responsible?.username}>
                                                              <Avatar alt={co_responsible?.username} src={getAvatarUrl({
                                                                name: co_responsible?.username
                                                              })} style={{ width: 22, height: 22 }} />
                                                            </Tooltip>
                                                          ))
                                                        }
                                                      </AvatarGroup>
                                                    </div>
                                                  ) : (
                                                    <div style={{ textAlign: "center" }}>
                                                      ---
                                                    </div>
                                                  )}

                                                </td>

                                                <td
                                                  width={'8%'}
                                                  className='textCenter'
                                                >
                                                  {task?.priority_id !== null ? (
                                                    <div>
                                                      {t(`tasks.${getPriority(task?.priority_id)}`)}
                                                    </div>
                                                  ) : (
                                                    <div style={{ textAlign: "left", marginLeft: 10 }}>
                                                      ---
                                                    </div>
                                                  )}

                                                </td>

                                                <td width={'10%'}>
                                                  {task?.tags?.length > 0 ? (
                                                    <div>
                                                      <TagsList tags={task?.tags} />
                                                    </div>
                                                  ) : (
                                                    <div style={{ textAlign: 'left' }}>---</div>
                                                  )}
                                                </td>

                                                <td
                                                  width={'6%'}
                                                  className='actionTime'
                                                >
                                                  <div
                                                    className='textCenter'
                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                    onClick={(e) => userDetails?.tasksPermissions.includes(Number(task.id)) && handleClickTaskOnTableToTime(e, task)}
                                                  >
                                                    <div className={`${userDetails?.tasksPermissions.includes(Number(task.id)) && 'timeContentHover'} timeContent`}>
                                                      {task?.time}
                                                    </div>
                                                  </div>

                                                  <div className="removeDialog" id={"removeTime-" + task?.id}>
                                                    <div className="content">
                                                      <div className="message">
                                                        {/* <IconInfo /> */}
                                                        {t('confirmDialog.title')}
                                                      </div>
                                                      <div className="options">
                                                        <button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
                                                        <button onClick={() => { toFileTask(currentTask, toggleRemoveDialog) }}>{t('confirmDialog.yes')}</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>

                                                <td
                                                  className='actions widthSmall'
                                                // onClick={(e) => }
                                                >
                                                  <div
                                                    onClick={(e) => userDetails?.tasksPermissions.includes(Number(task.id)) && handleClick(e, task?.id)}
                                                    className={'three-points'}
                                                    style={{ opacity: userDetails?.tasksPermissions.includes(Number(task.id)) ? '' : '0' }}
                                                  >
                                                    <IconThreePoints />
                                                  </div>

                                                  <div className="removeDialog" id={"removeTask-" + task?.id}>
                                                    <div className="content">
                                                      <div className="message">
                                                        {/* <IconInfo /> */}
                                                        {t('confirmDialog.title')}
                                                      </div>
                                                      <div className="options">
                                                        <button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
                                                        <button onClick={() => { toFileTask(currentTask, toggleRemoveDialog) }}>{t('confirmDialog.yes')}</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>

                                              </tr>
                                            )}

                                          </Draggable>

                                        ))
                                      )
                                    }
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>

                            </DragDropContext>

                          </table>

                          {showModalNewTask && userDetails?.krsPermissions.includes(Number(kr.id)) && (
                            <div className='footerCardKeyResult'>
                              <div>
                                <span
                                  id={`btn-new-task-${kr?.id}`}
                                  onClick={(e) => showModalNewTask(kr)}
                                >
                                  + {t('tasks.new_task')}
                                </span>
                              </div>
                            </div>
                          )}

                          <ConfirmDialog
                            confirmDialog={confirmDialog}
                            setConfirmDialog={setConfirmDialog}
                            handleCloseMenuOptionsGoals={handleCloseMenuOptionsKeyResult}
                          />
                        </div>
                      )}
                    </Draggable>

                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      }

      {currentTaskOnTableToTime &&
        (
          <InputTime
            anchorEl={anchorElTaskOnTableToTime}
            handleCloseMenu={handleCloseMenuTaskOnTableToTime}
            currentTask={currentTaskOnTableToTime}
            updateGoalsDetails={updateGoalsDetails}
          />
        )
      }

      {currentKeyResult && (
        <InputValue
          anchorEl={anchorElTaskOnEditValue}
          handleCloseMenu={handleCloseMenuEditValue}
          value={krValue}
          setValue={setKrValue}
          isUpdatingProgress={isUpdatingProgress}
          end_value={currentKeyResult?.end_value}
          start_value={currentKeyResult?.start_value}
          typeInput={currentKeyResult?.is_int_value ? 'int' : 'float'}
          close={true}
          setIsSubmitted={setIsSubmitted}
        />
      )}

      {currentTaskOnTable &&
        (

          <Menu
            className="menu-task-on-table"
            id={`menu-options-goals`}
            anchorEl={anchorElTaskOnTable}
            keepMounted
            open={Boolean(anchorElTaskOnTable)}
            onClose={handleCloseMenuTaskOnTable}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ horizontal: "center" }}
            style={{ width: 100, padding: 10, }}
          >
            <div className={`box-progress ${isUpdatingProgress && 'disabled-div'}`}>
              <label
                style={{
                  color: getColorStatusKr(currentTaskOnTable?.status_id)
                }}
              >
                {valueProgress !== null ? String(valueProgress) + '%' : (currentTaskOnTable ? currentTaskOnTable.progress : 0) + '%'}
              </label>
              <PrettoSlider
                style={{ color: getColorStatusKr(currentTaskOnTable?.status_id) }}
                width={16}
                height={16}
                aria-label="pretto slider"
                value={valueProgress !== null ? valueProgress : (currentTaskOnTable ? currentTaskOnTable.progress : 0)}
                onChange={handleSliderChange}
              />
            </div>
          </Menu>
        )
      }

      {
        openCommentsKr && (
          <ModalCommentsKR
            openCommentsKr={openCommentsKr}
            handleCloseCommentsKr={handleCloseCommentsKrModal}
            userDetails={userDetails}
            currentKr={currentKr}
            teammates={teammates}
          />
        )
      }

      {currentTask &&
        (<Menu
          className="menu-options"
          id={`menu-options-goals`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ horizontal: "center" }}
        >
          <MenuItem onClick={() => {
            markAsDone(currentTask)
            handleCloseMenu()
          }}>Concluir</MenuItem>
          <MenuItem onClick={() => {
            toggleRemoveDialog(currentTask, 'Task')
            setAnchorEl(null)
          }}>{t('modalDetailTask.archive_goal')}</MenuItem>
        </Menu>)
      }

      {currentKeyResult && <Menu
        className="menu-options"
        id={`menu-options-key-result`}
        anchorEl={anchorElOptionsKeyResult}
        keepMounted
        open={Boolean(anchorElOptionsKeyResult)}
        onClose={handleCloseMenuOptionsKeyResult}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <MenuItem onClick={() => handleOpenModalEditKeyResult(currentKeyResult)}>{t('modalDetailTask.edit')}</MenuItem>
        <MenuItem style={{ backgroundColor: '#fff' }} onClick={() => {
          toggleRemoveDialog(currentKeyResult?.id, 'Kr')
          setAnchorElOptionsKeyResult(null)
        }}>{t('modalDetailTask.archive_goal')}</MenuItem>
      </Menu>
      }
    </>
  );
}
