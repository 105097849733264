import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../../../_components/_shared/Toolbar'
import TeamsSkeleton from '../../../_components/settings/teams/TeamsSkeleton'
import { useTranslation } from "react-i18next";
import { alertActions, settingsActions, teamsActions } from '../../../_actions';
import { teammatesActions } from '../../../_actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FilterbarUsersSettings from '../../../_components/_shared/FilterbarUsersSettings';
import { api } from '../../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../../_helpers';
import StatsDetailsSoftwarePage from '../../../_components/_shared/inventory/software/details/StatsDetailsSoftwarePage';
import DetailTable from '../../../_components/_shared/inventory/software/details/DetailTable';
import Filterbar from '../../../_components/_shared/Filterbar';
import moment from 'moment';


function InventorySoftwareDetailsPage(props) {
  const { t, i18n } = useTranslation('common');
  const { id } = useParams()
  
  const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions, loadingTeams, loadingTeammates, filter } = props;


  const [inactiveTeammates, setInactiveTeammates] = useState([])
  const [data, setData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [activeFilter, setActiveFilter] = useState(1);
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  
  async function getInactiveTeammates() {
    try {
      const response = await api.get(
        `/teammates/inactive_teammates?organization_id=${userDetails.organizationId}`,
        {
          headers: authHeader()
        }
      )

      setInactiveTeammates(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error(t('teams.get_inactive_erro'))
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header:t("software_inventory.details.col_colab"),
        accessor: 'username'
      },
      {
        Header: t("software_inventory.details.col_first_use"),
        accessor: 'first_usage',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value,userDetails.language,"S"): "---" ;
        },
      },
      {
        Header: t("software_inventory.details.col_last_use"),
        accessor: 'last_usage',
        Cell: ({ value }) => {
          
          return value ? befectiveFormatDate(value,userDetails.language,"S") : "---";
        },
      },
      // {
      //   Header:t("software_inventory.details.col_days_available"),
      //   accessor: 'available_days'
      // },
      {
        Header: t("software_inventory.details.col_days_used"),
        accessor: 'total_used_days',
      },
      // {
      //   Header: t("software_inventory.details.col_percent_geral_use"),
      //   accessor: 'geral_usage',
      //   Cell: ({ value }) => {
      //     return String(value+"%") 
      //   },
      // },
      {
        Header: t("software_inventory.details.col_percent_real_use"),
        accessor: 'real_usage_percent',
        Cell: ({ value }) => {
          return String(value+"%") 
        },
      },
    ];
  }, []);

  const getData = async ({ pageIndex, pageSize, isLoading, date_start, date_end, teammates = '', search_text = '', activeFilter='' }) => {
    try {
      if (isLoading) {
        setFetching(true)
      }

      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''
      const querySearch = search_text != '' ? `&search_text=${search_text}` : ''

      let queryActive = '';

      if(activeFilter == 1){
        queryActive = `&filter_active=${true}`
      }
      if(activeFilter == 2){
        queryActive = `&filter_active=${false}`
      }

      const filterStartDate = date_start ? `&date_start=${date_start}` : ''
      const filterEndDate = date_end ? `&date_end=${date_end}` : ''

      // /users/v2/?page=${pageIndex}&per_page=${pageSize}${queryTeammates}${querySearch}${queryActive}
      const res = await api.get(`/inventory/software/${id}?page=${pageIndex}&per_page=${pageSize}${filterStartDate}${filterEndDate}${queryTeammates}${querySearch}${queryActive}&current_day=${moment().format("YYYY-MM-DD")}`, { headers: authHeader() })
      
      if (res.status !== 200) {
        throw new Error('Erro get details inventory software')
      }
      
      // const {purchase_date, due_date} = res.data.stats
      
      // if(purchase_date && due_date && date_start==null && date_end==null){
      //   setDateStart(new Date(purchase_date).toISOString().split('T')[0])
      //   setDateEnd(new Date(due_date).toISOString().split('T')[0])
      // }

      setData(res.data)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }


  // useEffect(() => {
  //   getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, activeFilter: activeFilter})
  // }, [activeFilter])

  useEffect(() => {
    let start_date = dateStart;
    let end_date = dateEnd;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: true, date_start:null, date_end:null, teams:teams, teammates:teammates})

  }, [filter])

  return (
    <React.Fragment>

      <section className="mainContent">
        <Toolbar />
      
        <Filterbar
          filterWho={'multi'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}          
          showShareBtn={false}
          routeBackToPage={"/inventory/software"}
        />
        
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                fetching
                  ? (
                    <TeamsSkeleton />
                  ) : (
                    <>
                      <StatsDetailsSoftwarePage
                        stats={data.stats || null}
                      />
                      <DetailTable
                        columns={columns}
                        data={data.data || []}
                        count={pageCount}
                        pageCountRows={pageCountRows}
                        alertActions={alertActions}
                        activeFilter={activeFilter}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        getData={getData}                  
                        showsIcons={true}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        licenseId={id}
                      />
                    </>
                  )
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>


    </React.Fragment>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySoftwareDetailsPage)
