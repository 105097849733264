import React from 'react';

const IconAllAgents = props => {
    return (
        
        <svg id="Grupo_110" data-name="Grupo 110" xmlns="http://www.w3.org/2000/svg" width="12" height="15.815" viewBox="0 0 12 15.815">
        <path id="Caminho_69" data-name="Caminho 69" d="M28.285,57.905l1.8,1.04V56.865Z" transform="translate(-24.053 -45.073)" fill="#187dff"/>
        <path id="Caminho_70" data-name="Caminho 70" d="M12.369,41.658l1.873,1.11,3.329-1.942v-2.15Z" transform="translate(-11.536 -30.767)" fill="#187dff"/>
        <path id="Caminho_71" data-name="Caminho 71" d="M8.471,23.629v2.15l6.035-3.468v-2.15Z" transform="translate(-8.471 -16.207)" fill="#187dff"/>
        <path id="Caminho_72" data-name="Caminho 72" d="M8.471,5.114v2.15L14.506,3.8V1.646h0Z" transform="translate(-8.471 -1.646)" fill="#187dff"/>
        <path id="Caminho_73" data-name="Caminho 73" d="M42.7,42.7V40.624L36.73,44.092h0v4.023l2.913-3.607Z" transform="translate(-30.695 -32.3)" fill="#187dff"/>
        <path id="Caminho_74" data-name="Caminho 74" d="M36.73,25.9v2.15L42.7,24.585v-2.15Z" transform="translate(-30.695 -17.995)" fill="#187dff"/>
        <path id="Caminho_75" data-name="Caminho 75" d="M36.73,12.683V14.9L42,11.851l-1.873-1.11Z" transform="translate(-30.695 -8.799)" fill="#187dff"/>
        <path id="Caminho_76" data-name="Caminho 76" d="M36.73,3.727l1.8-1.04-1.8-1.04Z" transform="translate(-30.695 -1.646)" fill="#187dff"/>
      </svg>
      

    )
}

export default IconAllAgents;