import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { usersActions } from '../_actions';
import { alertActions } from '../_actions';
import { withTranslation } from "react-i18next";

import IconMainLogo from '../_components/_icons/IconMainLogo'
import IconEmail from '../_components/_icons/IconEmail';
import IconPassword from '../_components/_icons/IconPassword';
import IconShowPassword from '../_components/_icons/IconShowPassword';
import IconTeammate from '../_components/_icons/IconTeammate';

import queryString from 'query-string'
import { useLocation } from 'react-router-dom'


class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(usersActions.logout());



        this.state = {
            name: '',
            email: '',
            passwordConfirmation: '',
            password: '',
            submitted: false,
            showPassword: false,
            resetPassword: false,
            loading: false,
            loaded: false,
            passwordMatch: true,
            passwordShort: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.toggleShow = this.toggleShow.bind(this);
        this.toggleResetPassword = this.toggleResetPassword.bind(this);

    }

    componentDidMount() {

        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        this.props.dispatch(usersActions.getByResetToken(urlParams.get('t'), urlParams.get('o')));

    }

    componentDidUpdate(prevProps, prevState) {

    


        if (this.props.users.activation && prevProps.users != this.props.users) {

            this.setState({ loaded: true, name: this.props.users.activation.full_name, email: this.props.users.activation.email });

        }

   

    }


    toggleShow() {
        this.setState({ showPassword: !this.state.showPassword });
        this.setState({ submitted: false });
    }

    toggleResetPassword() {
        this.setState({ resetPassword: !this.state.resetPassword });
        this.setState({ submitted: false });
    }

    handleChange(e) {

        const { name, value } = e.target;
        this.setState({ [name]: value });

    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.dispatch(alertActions.clear());
        this.setState({ submitted: true });
        const { passwordConfirmation, password } = this.state;
        const { dispatch } = this.props;
        if ((passwordConfirmation && password) && (passwordConfirmation === password)) {

            if (password.length >= 8) {
                dispatch(usersActions.resetPassword(passwordConfirmation, password, this.props.users.activation.id, this.props.users.activation.reset_password_token, this.props.users.activation.organization_id));
                this.setState({ loading: true });
                this.state.passwordShort = false
                this.state.passwordMatch = true
            }
            else {
                this.state.passwordShort = true
                this.state.passwordMatch = true
            }


        }
        else {
            this.state.passwordShort = false
            this.state.passwordMatch = false
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { passwordConfirmation, password, submitted } = this.state;
        const { t } = this.props;
        const { users } = this.props;
        const { alert } = this.props;



        return (

            <div className="loginMainOld">

                <div className="loginContainer">
                    <div className="mainLogo">
                        <IconMainLogo />
                    </div>

                    {(!this.state.loaded && !alert.message) &&
                        <div className="invitationLoading">
                            <div className="lds-ring white"><div></div><div></div><div></div><div></div></div>
                        </div>
                    }

                    {(!this.state.loaded && alert.message) &&
                        <div className="alert">{alert.message}</div>
                    }

                    {(this.state.loaded) &&

                        <div className={this.state.resetPassword ? 'loginForm hide' : 'loginForm'}>
                            <h2>{t("invitation.new_password")}</h2>

                            {alert.message &&
                                <div className="alert">{alert.message}</div>
                            }
                            
                            {submitted && !this.state.passwordMatch &&
                                <div className="alert">{t('login.password_didnt_match')}</div>
                            }

                            {submitted && this.state.passwordShort &&
                                <div className="alert">{t('login.password_short')}</div>
                            }

                            <form name="form" onSubmit={this.handleSubmit}>



                                <div className={'formGroup' + (submitted && (!password || alert.message) ? ' has-error' : '')}>
                                    <div className="icon">
                                        <IconPassword />
                                    </div>
                                    <button type="button" className="showPassword" onClick={this.toggleShow} tabIndex="-1">
                                        <IconShowPassword />
                                    </button>
                                    <input tabIndex="0" id="password" type={this.state.showPassword ? 'text' : 'password'} name="password" placeholder={t('login.password')} defaultValue={password} onChange={this.handleChange} />
                                    {submitted && !password &&
                                        <div className="error">{t('login.password_is_required')}</div>
                                    }
                                </div>

                                <div className={'formGroup' + (submitted && (!passwordConfirmation || alert.message) ? ' has-error' : '')}>
                                    <div className="icon">
                                        <IconPassword />
                                    </div>
                                    <button type="button" className="showPassword" onClick={this.toggleShow} tabIndex="-1">
                                        <IconShowPassword />
                                    </button>
                                    <input tabIndex="0" id="passwordConfirmation" type={this.state.showPassword ? 'text' : 'password'} name="passwordConfirmation" placeholder={t('login.password_confirm')} defaultValue={passwordConfirmation} onChange={this.handleChange} />
                                    {submitted && !passwordConfirmation &&
                                        <div className="error">{t('login.password_is_required')}</div>
                                    }


                                </div>

                                <div className="helperText">{t('roles_password.title')} <br/>&#8226; {t('roles_password.max_string')}<br/>&#8226; {t('roles_password.min_char_lower')}<br/>&#8226; {t('roles_password.min_char_upper')}<br/>&#8226; {t('roles_password.min_number')}<br/>&#8226; {t('roles_password.min_symbol')}</div>


                                <div className="submitContainer">
                                    <button tabIndex="0" disabled={'' + (this.state.loading && !alert.message ? 'disabled' : '')}>{t('invitation.activate')}</button>

                                </div>

                            </form>
                        </div>
                    }


                </div>
                <div className="copyright">Befective ® {t('login.terms')}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { users } = state;
    const { alert } = state;
    return {
        loggingIn, alert, users
    };
}

const connectedInvitationPage = withTranslation('common')(connect(mapStateToProps)(ResetPasswordPage));
export { connectedInvitationPage as ResetPasswordPage };