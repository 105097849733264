import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { Close } from '@material-ui/icons';
import { api } from '../../../../_helpers/api';
import { authHeader } from '../../../../_helpers';

export function KanbanAddCard({ list, showModalNewCard, getBoardKanban }) {

  const { t } = useTranslation('common');

  const [showFormAddCard, setShowFormAddCard] = useState(false)
  const [formCard, setFormCard] = useState({
    title: ''
  });
  const [formCardError, setFormCardError] = useState({
    title: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateFormCard = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('project_management.validations.required_field')
    } else if (form.title.length < 4) {
      errors.title = t('project_management.validations.len_field_error_4')
    }

    return errors
  }

  const handleSubmitAddCard = (e) => {
    e.preventDefault()
    setFormCardError(validateFormCard(formCard))
    setSubmitted(true)
  }

  const handleChange = e => {
    setFormCard({
      ...formCard,
      [e.target.name]: e.target.value,
    })
  }

  const createCard = async () => {
    try {
      setIsSubmitting(true)

      await api.post(`/project-management/boards/lists/${list.id}/cards`, formCard, {
        headers: authHeader()
      })
      getBoardKanban()
      // alertActions.success(t('project_management.success.created_board'))
      setIsSubmitting(false)
      setShowFormAddCard(false)
      setFormCard({
        title: ''
      })
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      // //alertActions.error(t('project_management.errors.created_board'))
    }
  }

  useEffect(() => {

    if (Object.keys(formCardError).length === 0 && submitted) {
      createCard()
    } else {
      setSubmitted(false)
    }

  }, [formCardError, submitted])


  return (
    !showFormAddCard
      ?
      <button
        className='kanban-add-card'
        onClick={() => setShowFormAddCard(true)}
      >
        {t('project_management.kanban.new_card')} +
      </button>
      :
      <Box
        className='formNewCard'
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmitAddCard}
      >
        <Box>
          <TextField id="title" label="Titulo" autoFocus variant="outlined" size='small' name='title' onChange={handleChange} value={formCard.title || ''} fullWidth />
          {formCardError.title &&
            <div className="error">{formCardError.title}</div>
          }
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} gap={1}>
          <IconButton aria-label="close" size="small" onClick={() => setShowFormAddCard(false)} type='button'>
            <Close fontSize='inherit' />
          </IconButton>
          <Button variant="contained" disableElevation size='small' type='submit' disabled={isSubmitting}>
            {t('project_management.kanban.add_btn')}
          </Button>
        </Box>
      </Box>
  );
}
