import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { ClockCounterClockwise, MapTrifold } from 'phosphor-react';
import React from 'react';
import { NavLink } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import style from './Card.module.scss';
import { Map } from '@material-ui/icons';
import { history } from '../../../_helpers';


const defaultUrl = "https://cdn11.bigcommerce.com/s-m1jiibmpmc/stencil/080443d0-e8e7-0139-b5bb-5eca3f67671f/e/f51774b0-60cc-0138-751f-0242ac11000b/icons/icon-no-image.svg"
export function Card({
  room,
  userDetails,
  handleOpenDrawer,
  handleOpenModalViewSubspaces,
  showIcon = true,
  showBorder = false,
  width = "49.5%",
  dateRef = null
}) {
  const { t, i18n } = useTranslation('common');
  
  const toRedirect = (room) => {
    const filterDate = dateRef ? `?date=${dateRef}` : ''
    // if (!room?.is_avaliable_today) {
    //   return ''
    // }
    if (room.sub_space_type === null) {
      return history.push(`/spaces/reservation/${room.id}${filterDate}`)
    }
    if (room.sub_space_type === "shape") {
      if (room.map_representation.shapes.length === 0) {
        return history.push(`/spaces/reservation/${room.id}${filterDate}`)
      } else if (room.map_representation.shapes.length === 1) {
        return history.push(`/spaces/reservation/${room.map_representation.shapes[0].room_id}${filterDate}`)
      } else {
        return handleOpenModalViewSubspaces(room)
      }
    }

    if (room.sub_space_type === "simple") {
      if (room.sub_spaces.length === 0) {
        return history.push(`/spaces/reservation/${room.id}${filterDate}`)
      } else if (room.sub_spaces.length === 1) {
        return history.push(`/spaces/reservation/${room.sub_spaces[0].id}${filterDate}`)
      } else {
        return handleOpenModalViewSubspaces(room)
      }
    }
  }
  return (
    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
      <Box
        display="flex"
        borderRadius="8px"
        // maxHeight="180px"
        minHeight="180px"
        overflow="hidden"
        bgcolor="#fff"
        width="100%"
        gap="14px"
        border={showBorder ? '1px solid #E4EAEF' : 'none'}
      >
        <Box
          display="flex"
          position="relative"
          minWidth="175px"
          minHeight="180px"
          style={{
            backgroundImage: `url(${room.url || defaultUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            cursor: 'pointer'
          }}
          borderRadius="12px 0 0 12px"
          cursor="pointer"
          // component={NavLink}
          // to={''}
          onClick={() => toRedirect(room)}
        >
          {room.status !== 'free' && (
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              gap="6px"
              padding="6px 12px"
              borderRadius="15px"
              bgcolor={room.occupiedBy === userDetails.id ? "#22BEAD" : "#FFEAED"}
              bottom="6px"
              left="6px"
            >
              <Box bgcolor={room.occupiedBy === userDetails.id ? "#fff" : "#FF5151"} width="6px" height="6px" borderRadius="100%"></Box>
              <Typography fontSize="12px" fontWeight={600} color={room.occupiedBy === userDetails.id ? "#fff" : "#FF5151"}>
                {room.occupiedBy === userDetails.id ? t('spaces.reserved_to_me') : t('spaces.occuped_now')}
              </Typography>
            </Box>
          )}

          {
            (room.status === 'free' && room?.is_avaliable_today === false) &&
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              gap="6px"
              padding="6px 12px"
              borderRadius="15px"
              bgcolor={"#FFEAED"}
              bottom="6px"
              left="6px"
            >
              <Box bgcolor={"#FF5151"} width="6px" height="6px" borderRadius="100%"></Box>
              <Typography fontSize="12px" fontWeight={600} color={"#FF5151"}>
                {t('spaces.unavailable_today')}
              </Typography>
            </Box>
          }

        </Box>

        <Box display="flex" flexDirection="column" flex="1" paddingY="12px" position="relative" >
          {showIcon && (
            <Box
              position="absolute"
              top="8px"
              right="8px"
              cursor="pointer"
              className={style.iconDrawer}
              onClick={() => handleOpenDrawer(room)}
            >
              <ClockCounterClockwise size={18} style={{ cursor: 'pointer' }} />

            </Box>
          )}

          {
            room.sub_space_type !== null &&
            <Box
              position="absolute"
              top="28px"
              right="8px"
              sx={{ cursor: 'pointer' }}
              className={style.iconDrawer}
              onClick={() => toRedirect(room)}
            >
              <MapTrifold size={18} />
            </Box>
          }

          <Typography
            // component={NavLink}
            // to={room.sub_space_type === null && `/spaces/reservation/${room.id}`}
            style={{ marginBottom: '6px', textDecoration: 'none', fontSize: '16px', cursor: 'pointer', color: room.status === 'free' ? "#4F7091" : room.occupiedBy === userDetails.id ? "#22BEAD" : "#FF5151", fontWeight: 600 }}
            onClick={() => toRedirect(room)}
          >
            {room.title.length > 35 ? room.title.slice(0, 34) + "..." : room.title}
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap="2px"
            // component={NavLink}
            // to={room.sub_space_type === null && `/spaces/reservation/${room.id}`}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={() => toRedirect(room)}
          >
            <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
              {t('spaces.days')}:
              {' '}
              <span style={{ fontSize: '12px', color: '#4F7091', fontWeight: 700 }}>
                {room.availableDays.reduce((acc, day) => {
                  return acc += t(`settings.teams.${day}`) + ', '
                }, "").slice(0, -2)}
              </span>
            </Typography>

            <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
              {t('spaces.period')}:{' '} <span style={{ fontSize: '12px', color: '#4F7091', fontWeight: 700 }}>{room.availableHour}</span>
            </Typography>

            <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
              {t('spaces.columns.description')}:{' '} <span style={{ fontSize: '12px', color: '#4F7091', fontWeight: 700 }}>
                {room.description.slice(0, 30)}
              </span>
              {room.description.length >= 30 && (
                <Tooltip
                  title={
                    <Box fontSize={'12px'} fontWeight={'500'}>{room.description}</Box>
                  }
                >
                  <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
                    [...]
                  </Typography>
                </Tooltip>
              )}
            </Typography>

            <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
              {t('spaces.max_persons')}:{' '} <span style={{ fontSize: '12px', color: '#4F7091', fontWeight: 700 }}>{`${room.maxCapacity}`}</span>
            </Typography>

            <Typography component="span" style={{ fontSize: '12px', color: "#4F7091" }}>
              {t('spaces.responsible')}:{' '} <span style={{ fontSize: '12px', color: '#4F7091', fontWeight: 700 }}>{room.responsible}</span>
            </Typography>


            <Box display="flex" alignItems="center" gap="4px" flexWrap={'wrap'}>
              {room.itemsRoom.map((item, index) => (
                index < 3 ? (
                  <Typography
                    component="span"
                    padding="3px 6px 2px"
                    borderRadius="2px"
                    bgcolor="#E3EFFF"
                    color="#187DFF"
                    key={item}
                    style={{ fontSize: '12px', fontWeight: 500 }}
                  >
                    {item}
                  </Typography>
                ) : (
                  index === 3 && (
                    <Tooltip
                      title={
                        <Box>
                          {room.itemsRoom.map((item, index) => {
                            return index >= 3 ? (
                              <Box fontSize={'12px'} fontWeight={'500'}>{item}</Box>
                            ) : <> </>
                          }
                          )}
                        </Box>
                      }
                    >
                      <Typography
                        component="span"
                        padding="3px 6px 2px"
                        borderRadius="2px"
                        bgcolor="#E3EFFF"
                        color="#187DFF"
                        key={item}
                        style={{ fontSize: '12px', fontWeight: 500 }}
                      >
                        ...
                      </Typography>
                    </Tooltip>
                  )
                )

              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
