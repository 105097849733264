import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './ActivitiesTable.module.scss';
import { Pagination } from '@material-ui/lab';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp, User } from 'phosphor-react';
import { authHeader, history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, CircularProgress, IconButton, Tooltip, makeStyles, Backdrop, Modal, Slide, TextField } from '@material-ui/core';
import IconEdit2 from '../_icons/IconEdit2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterActions } from '../../_actions';
import { AssignmentReturn, Info } from '@material-ui/icons';
import ModalGroupActivitiesEdit from './ModalGroupActivitiesEdit';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import IconThreePoints from '../_icons/IconThreePoints';

import { Autocomplete } from '@material-ui/lab';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoIcon: {
    '& svg': {
      fontSize: '1.25rem'
    }
  }
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F',
    marginRight: 0,
    maxWidth: 500
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

const ActivitiesTable = ({ columns, data, selectedRows, setSelectedRows, count, getActivitiesData, pageCountRows, alertActions, categories, userDetails, filter }) => {

  const classes = useStyles();
  const { t } = useTranslation('common');
  const [anchorElOptions, setAnchorElOptions] = useState(null);
  const [currentTracked, setCurrentTracked] = useState(null);
  const [openModalEditActivity, setOpenModalEditActivity] = useState(null);
  const [openLicense, setOpenLicense] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true)
  const [listOfLicenses, setListOfLicenses] = useState([]);
  const [fetching, setFetching] = useState(false)
  const [selectedLS, setSelectedLS] = useState(null);
  const [selectedLSError, setSelectedLSError] = useState(false);
  const [submittingAttachLicense, setSubmittingAttachLicense] = useState(false);
	const { currentModule, saveCurrentModule } = useCurrentModule()

  const handleCloseMenuOptions = () => {
    setAnchorElOptions(null);
  };

  const handleClickMenuOptions = (e, row) => {
    setAnchorElOptions(e.currentTarget);
    setCurrentTracked(row);
  }

  const handleOpenModalLicense = () => {
    setAnchorElOptions(null)
    setOpenLicense(true);
  }

  const handleCloseLicenseModal = () => {
    setOpenLicense(false)
    setCurrentTracked(null)
    setAnchorElOptions(null)
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, sortOrder, globalFilter }
  } = tableInstance;

  const toggleAllRowsSelection = (e) => {
    const { checked } = e.target
    const allRows = page.filter(row => row.original.type === 'Software').map((row) => row.original.trackedId);
    if (checked) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([])
    }

  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevState) =>
      prevState.includes(id) ? prevState.filter((rowId) => rowId !== id) : [...prevState, id]
    );
  };

  const handleOpenModalActivityEdit = () => {
    setAnchorElOptions(null)
    setOpenModalEditActivity(true)
  }

  const handleOpenModalActivityEdit2 = (tracked) => {
    setCurrentTracked(tracked)
    setOpenModalEditActivity(true)
  }

  const handleCloseModalActivityEdit = () => {
    setOpenModalEditActivity(false)
    setCurrentTracked(null)
  }

  const fetchData = async ({ pageIndex, pageSize, sortBy, sortOrder, globalFilter, filterSearch }) => {
    try {
      await getActivitiesData({ pageIndex, pageSize, isLoading: false, search_text: filterSearch })
      setFetching(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetching(false)
    }
  };

  async function getListOfSoftwareLicense() {
    try {
      const response = await api.get(`/inventory/software/list-licenses`, {
        headers: authHeader()
      })

      setListOfLicenses(response.data)

    } catch (e) {
      console.log("/error: ", e)
    }
  }

  const handleChangeSelectLicense = (event, value) => {

    setSelectedLS(value)
    setSelectedLSError(false)

  }
  async function handleSubmitAttachment() {
    try {
      setSubmittingAttachLicense(true)
      if (selectedLS === null || !currentTracked) {
        // console.log("/nada foi selecionado")
        //msg para ele selecionar algo
        setSelectedLSError(true)
        return
      }
      const payload = {
        tracked_id: Number(currentTracked.trackedId),
        users: currentTracked.machineids,
        softwareAnalysis_id: selectedLS.id,
      }

      const response = await api.put(`/inventory/merge/software`, payload, {
        headers: authHeader(),

      })
      if (response.status === 200) {
        alertActions.success(t('activities.success_msg'))
        handleCloseLicenseModal()

      }

    } catch (e) {
      console.log("/error: ", e.response.data)
      alertActions.error(e.response.data.Message)
    } finally {
      setSubmittingAttachLicense(false)
    }
  }


  useEffect(() => {
    if (!firstLoading) {
      const filterSearch = filter.search || ''
      setFetching(true)
      fetchData({ pageIndex, pageSize, sortBy, sortOrder, globalFilter, filterSearch });
    }
    setFirstLoading(false)
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getListOfSoftwareLicense()
  }, [])


  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()}>
        <thead>

          <tr>
            {
              userDetails.allowed_update && (
                <th style={{ width: '10px' }} className={styles.headColTable}>
                  <label className={styles.customCheckbox}>
                    <input type="checkbox" onChange={toggleAllRowsSelection} disabled={!page.find(row => row.original.type === 'Software' && row.original.trackeds_grouped === null)} />
                    <span className={`${styles.checkmark} ${styles.checkmarkGreen}`}></span>
                  </label>
                </th>
              )
            }
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {

                  switch (column.id) {
                    case 'exhibitionName':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'left', maxWidth: '350px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    case 'origin':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'left', maxWidth: '180px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    case 'percent_time_usage':
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'center', width: '180px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                    default:
                      return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: 'left', width: '180px' }}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                      </th>
                  }
                })}
              </React.Fragment>
            ))}
            {userDetails.allowed_update && <th style={{ width: '0px' }} className={styles.headColTable} />}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={`${userDetails.allowed_update ? styles.rowHover : ''}`}>
                  {
                    userDetails.allowed_update && (
                      <td>
                        <label className={styles.customCheckbox}>
                          <input type="checkbox"
                            onChange={(e) => { toggleRowSelection(row.original.trackedId) }}
                            checked={selectedRows.includes(row.original.trackedId)}
                            disabled={!(row.original.type === 'Software' && row.original.trackeds_grouped === null)}
                          />
                          <span className={styles.checkmark}></span>
                        </label>
                      </td>
                    )
                  }
                  {row.cells.map((cell) => {
                    switch (cell.column.id) {
                      case 'exhibitionName':
                        return (
                          <td {...cell.getCellProps()} style={{ textAlign: 'left', fontSize: '12px', maxWidth: '350px', wordWrap: 'break-word' }} onClick={() => userDetails.allowed_update && handleOpenModalActivityEdit2(row.original)}>
                            {cell.render("Cell")} {cell.row.original.trackeds_grouped &&
                              (
                                <CustomTooltip title={
                                  <Box padding={'2px'}>
                                    <Typography py={'2px'} fontSize={'12px'} variant='subtitle1'>{t('settings.activities.group_activities')}</Typography>
                                    <ul className='listActionsRestart'>
                                      {
                                        cell.row.original.trackeds_grouped.map(t => (
                                          <li className='listItemActionRestart' key={t.trackedId}>&#8226; {t.exhibitionName}</li>
                                        ))
                                      }
                                    </ul>
                                  </Box>
                                } arrow>
                                  <IconButton aria-label="detail-grouped" size="small">
                                    <Info fontSize="small" color='primary' />
                                  </IconButton>
                                </CustomTooltip>
                              )}
                          </td>
                        );
                      case 'origin':
                        return (
                          <td {...cell.getCellProps()} style={{ textAlign: 'left', fontSize: '12px', maxWidth: '200px', wordWrap: 'break-word' }} onClick={() => userDetails.allowed_update && handleOpenModalActivityEdit2(row.original)}>{cell.render("Cell")}</td>
                        );
                      default:
                        return (
                          <td {...cell.getCellProps()} style={{ textAlign: ['percent_time_usage'].includes(cell.column.id) ? 'center' : 'left', fontSize: '12px', maxWidth: '180px' }} onClick={() => userDetails.allowed_update && handleOpenModalActivityEdit2(row.original)}>{cell.render("Cell")}</td>
                        );
                    }
                  })}
                  {
                    userDetails.allowed_update && (
                      <td>
                        {/* <div
                          onClick={(e) => { handleClickMenuOptions(e, row.original) }} //handleClick()}
                          className={'three-points'}
                          style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}
                        >
                          <IconThreePoints />
                        </div> */}
                        <div className={styles.boxActions}>
                          <button
                            className={`${styles.buttonAction} ${styles.permissions}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleOpenModalActivityEdit2(row.original)
                            }}>
                            <IconEdit2 />
                          </button>
                        </div>
                      </td>
                    )
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>

      <Menu
        className="menu-options"
        id={`menu-options`}
        anchorEl={anchorElOptions}
        keepMounted
        open={Boolean(anchorElOptions)}
        onClose={handleCloseMenuOptions}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => { handleOpenModalActivityEdit() }}>{t("activities.menu_opt_3")}</MenuItem>

        {
          (userDetails.modules.find(item => item.id === 9) && ["Site", "Software"].includes(currentTracked?.type)) &&
          <MenuItem onClick={() => { handleOpenModalLicense() }}>{t("activities.menu_opt_1")}</MenuItem>
        }

        {
          (userDetails.modules.find(item => item.id === 9) && ["Site", "Software"].includes(currentTracked?.type)) &&
          <MenuItem onClick={() => {
            history.push(`/inventory/software?tracked_id=${Number(currentTracked.trackedId)}&type=${currentTracked.type}&open_modal=true`)
            saveCurrentModule('inventory')
          }
          }>{t("activities.menu_opt_2")}</MenuItem>
        }

      </Menu>

      <div className={styles.containerPagination}>
        {fetching && <div style={{ display: "flex", alignItems: "center", marginRight: '12px' }}>
          <CircularProgress size={20} color='primary' />
        </div>}
        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, page) => {
            gotoPage(page - 1)
          }}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem('pageSizeStorage', Number(e.target.value));
          }}
          className={styles.selectOptions}
        >
          {myPageOptions.map((pageSize) => (
            <option key={pageSize.value} value={pageSize.value}>
              {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
            </option>
          ))}
        </select>
      </div>

      <Modal
        open={openLicense}
        onClose={handleCloseLicenseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ outline: 0, border: 'none' }}
        className={`${classes.modal}`}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
      >

        <Slide direction="down" in={openLicense} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              width: "25%",
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
            justifyContent={"center"}>

            {listOfLicenses.length === 0
              ?
              (<div className='no-data'>
                {t("modal.no_data")}

              </div>)
              :
              <>
                <Typography id="modal-modal-title" component="h3" style={{ color: "#1E6B7F", fontSize: "18px", fontWeight: 500 }}>
                  {t('activities.modal_attachment_label')}
                </Typography>

                <div className='container'>
                  <div style={{ marginTop: "20px" }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={listOfLicenses}
                      onChange={(event, newValue) => { handleChangeSelectLicense(event, newValue) }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label={t('activities.modal_select')} variant="outlined" error={selectedLSError} />}
                      onFocus={() => setSelectedLSError(false)}
                      fullWidth
                    />
                  </div>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "18px" }}>
                    <button
                      className="btnAttchment"
                      type="button"
                      onClick={() => { handleSubmitAttachment() }}
                      disabled={submittingAttachLicense}
                    >

                      {
                        !submittingAttachLicense
                          ?
                          t("activities.modal_button")
                          :
                          <CircularProgress size={15} />
                      }
                    </button>
                  </Box>
                </div>
              </>}


          </Box>

        </Slide>

      </Modal>

      {
        (categories && currentTracked) &&
        <ModalGroupActivitiesEdit
          currentTracked={currentTracked}
          openModalEditActivity={openModalEditActivity}
          handleClose={handleCloseModalActivityEdit}
          alertActions={alertActions}
          userDetails={userDetails}
          categories={categories}
          getActivitiesData={() => {
            const filterSearch = filter.search || ''
            return fetchData({ pageIndex, pageSize, sortBy, sortOrder, globalFilter, filterSearch })
          }}
        />
      }

    </div>
  );
};

function mapDispatchToProps(dispatch) {

  return {
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesTable)
