// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AyW3Z9I8VeSQo8X8a06QvA\\=\\= {\n  border: 2px solid rgba(222, 227, 233, 0.8);\n  border-style: dashed;\n  display: flex;\n  align-items: center;\n  height: 41px;\n  cursor: pointer;\n  padding: 0px 0px;\n  border-radius: 4px;\n  width: 100%;\n  background-color: #F5F6F9;\n  font-size: 10px;\n}\n.AyW3Z9I8VeSQo8X8a06QvA\\=\\=:hover {\n  border: 2px solid #187DFF;\n}\n.AyW3Z9I8VeSQo8X8a06QvA\\=\\= input {\n  display: none;\n}\n.AyW3Z9I8VeSQo8X8a06QvA\\=\\= svg {\n  color: #205B6F;\n}\n\n.xumRgJtUck3SMo6fsPDVzw\\=\\= {\n  margin-top: 2px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 4px 0px 4px 6px;\n  background-color: #E3EFFF;\n  color: #187DFF;\n}", "",{"version":3,"sources":["webpack://./src/_components/rooms_page/create/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EACE,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wBAAA;EACA,yBAAA;EACA,cAAA;AAFF","sourcesContent":[".inputFileBox {\n  border: 2px solid #DEE3E9CC;\n  border-style: dashed;\n  display: flex;\n  align-items: center;\n  height: 41px;\n  cursor: pointer;\n  padding: 0px 0px;\n  border-radius: 4px;\n  width: 100%;\n  background-color: #F5F6F9;\n  font-size: 10px;\n\n  &:hover {\n    border: 2px solid #187DFF;\n  }\n\n  input {\n    display: none;\n  }\n\n  svg {\n    color: #205B6F;\n  }\n}\n\n.filePreview {\n  margin-top: 2px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 4px 0px 4px 6px;\n  background-color: #E3EFFF;\n  color: #187DFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFileBox": "AyW3Z9I8VeSQo8X8a06QvA==",
	"filePreview": "xumRgJtUck3SMo6fsPDVzw=="
};
export default ___CSS_LOADER_EXPORT___;
