import React, { Fragment, useEffect } from 'react';

import { history } from '../../_helpers';



export function Redirect({to="/"}) {
  useEffect(()=>{
    history.push(to)
  },[])
  return (
    <Fragment>      
    </Fragment>
  );
}