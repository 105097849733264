import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useLocation } from "react-router-dom";

import { usersActions } from '../../_actions';
import { menuActions } from '../../_actions';

import IconToday from '../_icons/IconToday';
import IconActivities from '../_icons/IconActivities';
import IconLogo from '../_icons/IconLogo'
import IconPeriodicReports from '../_icons/IconPeriodicReports'
import UpdateIcon from '@material-ui/icons/Update';
import ReactGA from 'react-ga4';

import {
	isMobile
} from "react-device-detect";

import IconIntegrations from '../_icons/IconIntegrations';

import { Message } from '@material-ui/icons';
import { useMessagesCount } from '../../contexts/MessagesCountContext';
import IconMyActivities from '../_icons/IconMyActivities';
import IconGoals from '../_icons/IconGoals';
import { api } from '../../_helpers/api';

ReactGA.initialize("G-9FF1246SC3")

function MenuMU(props) {
	const { t } = useTranslation('common');
	let path = window.location.pathname.split("/") || [];

	const location = useLocation();

	const { messagesNotRead, getMessagesUnRead } = useMessagesCount()

	const [isActiveReports, setActiveReports] = useState();
	const [isActiveSettings, setActiveSettings] = useState();
	const [isActiveIntegrations, setIsActiveIntegrations,] = useState();
	const [isActiveGoals, setIsActiveGoals,] = useState();
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [integrationOptionSelected, setIntegrationOptionSelected] = useState('')
	const [goalsOptionSelected, setGoalsOptionSelected] = useState('')
	const [organizationId, setOrganizationId] = useState('');
	const [hash, setHash] = useState('');
	const [machineUser, setMachineUser] = useState(null)

	const { menuActions, usersActions, userDetails } = props;
	
	let menu = props.menu || [];

	function toggleClass(type = null) {

		if (!isMenuOpen) {

			toggleMenu('main');
			if (type === "reports") {
				menuActions.set('reports', true);
			}
			if (type === "settings") {
				menuActions.set('settings', true);
			}
			if (type === "integrations") {
				menuActions.set('integrations', true);
			}
			if (type === "goals") {
				menuActions.set('goals', true);
			}
		} else {

			if (type === "reports") {
				menuActions.set('reports', !isActiveReports);
			} else if (type === "settings") {
				menuActions.set('settings', !isActiveSettings);
			} else if (type === 'integrations') {
				menuActions.set("integrations", !isActiveIntegrations);
			} else if (type === 'goals') {
				menuActions.set("goals", !isActiveGoals);
			} else {
				menuActions.set('reports', false);
			}

		}

	};

	function toggleMenu(type) {

		if (type == 'main') {
			if (isMenuOpen) {
				props.toggle('open')
			} else {
				props.toggle('closed')
			}


		} else {
			if (isMobile) {
				props.toggle('open')
			}
		}

	};

	function verifyIfIsIntegrationPath() {
		if (path.includes('google-calendar') || path.includes('outlook-calendar')) {
			return true
		}

		return false
	}

	function verifyIfIsGoalsPath() {

		if (path.includes('goals')) {
			return true
		}

		return false
	}

	async function getMachineUserDetails(org_id, bef_token) {
		try {
			const response = await api.get(`/messages/machine-user-details?organization_id=${org_id}&bef_token=${bef_token}`)

			setMachineUser(response.data.machineUserDetails)
		} catch (error) {
			console.log(error.response)
		}

	}
	useEffect(() => {
    // Evitar que o token expire por tempo
    setInterval(() => {
        usersActions.refresh(userDetails);
      }, (280*1000));
    // Evitar que o token expire em caso de refresh na página
    setTimeout(() => {
        usersActions.refresh(userDetails);
      }, (15000));
  }, [])

	useEffect(() => {
		if (menu) {
			setActiveReports(menu.reports);
			setActiveSettings(menu.settings);
			setIsActiveIntegrations(menu.integrations)
			setIsActiveGoals(menu.goals)
		}
	}, [props.menu]);

	useEffect(() => {
		const lastPathPosition = path[path.length - 1]

		if (path.includes('google-calendar') || path.includes('outlook-calendar')) {
			menuActions.set('integrations', true);
			setIntegrationOptionSelected(lastPathPosition)
		}

		if (path.includes('goals')) {
			menuActions.set('goals', true);
			if (path.length === 5) {
				setGoalsOptionSelected(lastPathPosition)
			} else {
				const goalPath = path[5]
				setGoalsOptionSelected(goalPath)
			}
		}

		if (userDetails) {
			getMessagesUnRead(path[2], userDetails.id)
		}
		
	}, [userDetails]);


	useEffect(() => {
		setMenuOpen(props.menuStatus)
	}, [props.menuStatus]);

	useEffect(() => {
		//ReactGA.initialize("G-9FF1246SC3");
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path });
	}, [location]);

	useEffect(() => {
		if(organizationId !== path[2] && hash !== path[3]) {
			getMachineUserDetails(path[2], path[3])
		
			setOrganizationId(path[2])
			setHash(path[3])
		}

	}, [hash, organizationId])

	return (
		<nav className={isMenuOpen ? 'mainMenu open' : 'mainMenu'}>


			<div className="item nopointer">

				<div className="mainLogo">
					<IconLogo />
				</div>

			</div>

			<div className="item" onClick={() => {
				toggleClass();
				toggleMenu()
			}}>
				<NavLink exact={true} to={'/productivity/' + organizationId + '/' + hash} activeClassName="active">
					<IconToday />
					<span>{t('home.my_productivity')}</span>
				</NavLink>
			</div>

			<div className="item" onClick={() => {
				toggleClass();
				toggleMenu()
			}}>
				<NavLink to={'/productivity/' + organizationId + '/' + hash + '/activities'}>
					<IconActivities />
					<span>{t('menu.activities')}</span>
				</NavLink>
			</div>

			{userDetails && userDetails.id === machineUser?.id && userDetails.organizationId === machineUser?.organization_id && (
				<>
					{
						(parseInt(organizationId) === 671 || parseInt(organizationId) === 690) && <div className="item" onClick={() => {
							toggleClass();
							toggleMenu()
						}}>
							<NavLink to={'/productivity/' + organizationId + '/' + hash + '/inactivity'}>
								<UpdateIcon />
								<span>{t('menu.inactivity')}</span>
							</NavLink>
						</div>
					}

					<div className={isActiveIntegrations ? 'item sub open' : 'item sub'}>
						<a
							className={verifyIfIsIntegrationPath() ? 'active' : ''}
							onClick={() => toggleClass("integrations")}
						>
							<IconIntegrations />

							<span>{t('integrations.title')}</span>
							<div>
								<i className="arrow"></i>
							</div>
						</a>

						<div className="item" onClick={() => {
							toggleMenu()
							setIntegrationOptionSelected('google-calendar')
						}}>
							<NavLink
								to={'/productivity/' + organizationId + '/' + hash + '/google-calendar'}
								activeClassName={integrationOptionSelected === 'google-calendar' ? 'active' : ''}
							>
								<span>Google Calendar</span>
							</NavLink>
						</div>

						<div className="item" onClick={() => {
							toggleMenu()
							setIntegrationOptionSelected('outlook-calendar')
						}}>
							<NavLink
								to={'/productivity/' + organizationId + '/' + hash + '/outlook-calendar'}
								activeClassName={integrationOptionSelected === 'outlook-calendar' ? 'active' : ''}
							>
								<span>Outlook Calendar</span>
							</NavLink>
						</div>


					</div>

					<div className="item" onClick={() => {
						toggleClass();
						toggleMenu()
					}}>
						<NavLink to={'/productivity/' + organizationId + '/' + hash + '/messages'}>
							{/* <IconBellMenu/> */}
							{
								props.menuStatus
									?
									<Message className='icon' style={{ width: 24, height: 24 }} />
									:
									<div className='box-badge-mobile'>
										<Message className='icon' style={{ width: 24, height: 24 }} />
										{messagesNotRead > 0 && <div className='badge-mobile'>
											{messagesNotRead}
										</div>}
									</div>
							}
							<span>{t('menu.messages')}</span>
							{
								messagesNotRead > 0 && props.menuStatus && <div className='badge'>
									{messagesNotRead}
								</div>
							}

						</NavLink>
					</div>

					{userDetails.show_goals && (
						<div className={isActiveGoals ? 'item sub open' : 'item sub'}>
							<a
								className={verifyIfIsGoalsPath() ? 'active' : ''}
								onClick={() => toggleClass("goals")}
							>
								<IconGoals />
								<span>{t('menu.goals')}</span>
								<div>
									<i className="arrow"></i>
								</div>
							</a>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('goals')
							}}>
								<NavLink
									to={'/productivity/' + organizationId + '/' + hash + '/goals'}
									activeClassName={(goalsOptionSelected === 'goals' || goalsOptionSelected === 'details') ? 'active' : ''}
								>
									<span>{t('goals.running')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('completed')
							}}>
								<NavLink
									to={'/productivity/' + organizationId + '/' + hash + '/goals/completed'}
									activeClassName={goalsOptionSelected === 'completed' ? 'active' : ''}
								>
									<span>{t('goals.completed')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('archived')
							}}>
								<NavLink
									to={'/productivity/' + organizationId + '/' + hash + '/goals/archived'}
									activeClassName={goalsOptionSelected === 'archived' ? 'active' : ''}
								>
									<span>{t('goals.archived')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('notifications')
							}}>
								<NavLink
									to={'/productivity/' + organizationId + '/' + hash + '/goals/notifications'}
									activeClassName={goalsOptionSelected === 'notifications' ? 'active' : ''}
								>
									<span>{t('goals_notifications.notification')}</span>
								</NavLink>
							</div>

						</div>
					)}
				
				</>
			)}


			{/* 
			<div className="item" onClick={() => {
				toggleClass();
				toggleMenu()
			}}>
				<NavLink to={'/productivity/' + organizationId + '/' + hash + '/myactivities'}>
					<IconMyActivities />
					<span>{t('menu.myactivities')}</span>
				</NavLink>
			</div> */}
		</nav>
	)
};

function mapDispatchToProps(dispatch) {

	return {
		menuActions: bindActionCreators(menuActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch)
	}
}

function mapStateToProps(state) {

	return {
		menu: state.menu,
		permissions: state.authentication.permissions,
		userDetails: state.authentication.userDetails
	}

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMU));
