import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import IconTree from "../_icons/IconTree";
import IconMovimentation from "../_icons/IconMovimentation";
import IconHomeGoals from "../_icons/IconHomeGoals";
import IconList from "../_icons/IconList";
import IconGrid from "../_icons/IconGrid";
import { useEffect } from "react";
import IconSearchLight from "../_icons/IconSearchLight";

function ButtonActionsGroup(props) {
	const { t, i18n } = useTranslation('common');

	const { path, setOpenModalNewGoal, setOpenModalNewKr, setDrawerFiltersGoals, userDetails } = props;

	const [validPaths, setValidPaths] = useState([
		'goals'
	]);


	const goal_id = path[path.length - 1]


	const changeViewerKrs = props.changeViewerKrs || null;
	const changeViewerObjectivies = props.changeViewerObjectivies || null;



	const [changeIconHomeObjective, setChangeIconHomeObjective] = useState(false)

	const handleChangeIconHomeObjective = () => {
		setChangeIconHomeObjective(!changeIconHomeObjective)
	}


	const [changeIconKanbanInKR, setChangeIconKanbanInKR] = useState(false)

	const handleChangeIconKanbanInKR = () => {
		setChangeIconKanbanInKR(!changeIconKanbanInKR)
	}

	useEffect(() => {

		if (changeViewerKrs) {
			changeViewerKrs(changeIconKanbanInKR)
		}

		if (changeViewerObjectivies) {
			changeViewerObjectivies(changeIconHomeObjective)
		}
	}, [changeIconKanbanInKR, changeIconHomeObjective])

	return (
		path.includes('productivity') && path[4] === 'goals' && !path[5] ? (
			<div className="ButtonActionsGroup">

				<button className="btnFlowGoals" onClick={() => setDrawerFiltersGoals(true)}>
					<IconSearchLight />
				</button>
				<button className="btnFlowGoals" onClick={handleChangeIconHomeObjective}>
					{
						changeIconHomeObjective
							? <IconHomeGoals />
							: <IconTree />
					}
				</button>
				<button className="btnMovimentations" onClick={() => { props.openDrawerMovimentations() }}>
					<IconMovimentation />
				</button>
				{
					userDetails?.roleName !== 'machine_user' && (
						<button className="btnAddGoals" onClick={() => setOpenModalNewGoal(true)}>
							{t('goals.new-goal')}
						</button>
					)
				}
			</div>
		) : (validPaths.includes(path[1]) && (!path[3] || isNaN(path[3]))) ? (
			<div className="ButtonActionsGroup">
				<button className="btnFlowGoals" onClick={() => setDrawerFiltersGoals(true)}>
					<IconSearchLight />
				</button>
				<button className="btnFlowGoals" onClick={handleChangeIconHomeObjective}>
					{
						changeIconHomeObjective
							? <IconHomeGoals />
							: <IconTree />
					}
				</button>
				<button className="btnMovimentations" onClick={() => { props.openDrawerMovimentations() }}>
					<IconMovimentation />
				</button>
				<button className="btnAddGoals" onClick={() => setOpenModalNewGoal(true)}>
					{t('goals.new-goal')}
				</button>
			</div>

		) : (
			<div className="ButtonActionsGroup">
				<button className="btnFlowGoals" onClick={handleChangeIconKanbanInKR}>
					{
						changeIconKanbanInKR
							? <IconGrid />
							: <IconList />
					}
				</button>
				<button className="btnMovimentations" onClick={() => { props.openDrawerMovimentations() }}>
					<IconMovimentation />
				</button>

				{userDetails?.goalsPermissions.includes(Number(goal_id)) && (
					<button className="btnAddGoals" style={{ width: '100px' }} onClick={() => setOpenModalNewKr(true)}>
						+ Key Result
					</button>
				)}

			</div>
		)
	)
};

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ButtonActionsGroup));
