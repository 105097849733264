import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { bindActionCreators } from 'redux'
import Moment from 'moment'
import { isMobile } from 'react-device-detect';

import { api } from '../../_helpers/api'
import { alertActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { authHeader, befectiveFormatDate, getColorStatusKr } from '../../_helpers';
import IconShare from '../../_components/_icons/IconShare';

import './styles.scss'
import IconInfo from '../../_components/_icons/IconInfo';
import { Checkbox, FormControlLabel, Tooltip, Typography, withStyles } from '@material-ui/core';
import ModalExportReports from '../../_components/goals/ModalExportReports';
import MenuMU from '../../_components/_shared/MenuMU';

const statusColors = ['#98ACC1', '#FDA02B', '#22BEAD', '#FF5151']

const GreenCheckbox = withStyles({
	root: {
		color: '#E4EAEF',
		"&$checked": {
			color: '#22BEAD'
		},
		'&:hover': {
			backgroundColor: 'transparent !important'
		}
	},
	checked: {}
})((props) => <Checkbox color="default" {...props} />);

function GoalsCompletedPage(props) {
	const { t, i18n } = useTranslation('common');

	let path = window.location.pathname.split("/") || [];

	const { alertActions, userDetails, filter } = props;
	const token = authHeader()

	const [openModalExportReports, setOpenModalExportReports] = useState(false);
	const [goalsCompleted, setGoalsCompleted] = useState([])
	const [goalsCompletedFiltered, setGoalsCompletedFiltered] = useState([])
	const [generalProgress, setGeneralProgress] = useState(0)
	const [totalKrs, setTotalKrs] = useState(0)
	const [generalProgressFiltered, setGeneralProgressFiltered] = useState(0)
	const [seeArchived, setSeeArchived] = useState(false)
	const [useArchived, setUseArchived] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const maxDay = new Date(date.getFullYear(), date.getMonth() + 12, 0);

	const firstDayDate = firstDay.toISOString().split('T')[0]
	const maxDate = maxDay.toISOString()
	const lastDay = maxDate.split('T')[0]

	function toggleMenu(type) {
		if (type == 'main') {
			setIsMenuOpen(!isMenuOpen);
		} else {
			if (isMobile) {
				setIsMenuOpen(false)
			}
		}
	};

	function toggleFromMenu(value) {

		if (value == 'closed') {
			setIsMenuOpen(true);
		} else {
			setIsMenuOpen(false)
		}
	}

	const [rangeDate, setRangeDate] = useState({
		startDate: firstDayDate,
		endDate: lastDay
	})
	const handleCloseModalExport = (e) => {
		e.preventDefault()
		setOpenModalExportReports(false);
	};

	function handleToggleSeeArchived() {
		setSeeArchived(!seeArchived)
		setUseArchived(false)
	}

	function getGeneralStatusColor() {
		const progressNumber = Math.trunc(Number(generalProgressFiltered))
		let colorStatus = statusColors[0]

		if (progressNumber > 0 && progressNumber < 100) {
			colorStatus = statusColors[1]
		} else if (progressNumber >= 100) {
			colorStatus = statusColors[2]
		}

		return colorStatus
	}

	async function getGoalsCompleted() {
		let url = `goals/concluded?organization_id=${userDetails?.organizationId}`

		if (filter.date) {
			url += `&startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`

		}

		let queryTeams = ''
		
		if (filter.teams && filter.teams.length > 0) {
        let nt = 0;
        queryTeams = "&teams=";

        for (let team of filter.teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
						queryTeams += "," + team.id;
          }
          nt++;
        }

				url += `${queryTeams}`
				
      }
			
			let queryTeammates = ''
      if (filter.teammates && filter.teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&users=";

        for (let teammate of filter.teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

				url += `${queryTeammates}`

      }

		try {
			const response = await api.get(url, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			const goalsActiveFiltered = response.data?.goals?.filter(goal => goal.is_active === true)

			updateTotalKrs(response.data.goals)

			setGoalsCompleted(response.data.goals)
			setGoalsCompletedFiltered(goalsActiveFiltered)
			setGeneralProgress(response.data.general_progress)
			setGeneralProgressFiltered(response.data.general_progress)
		} catch (error) {
			console.log(error.response)
			//alertActions.error(t('goals_completed.error'))
		}

	}
	function updateTotalKrs(goalsList) {
		let countKrs = 0

		goalsList?.forEach(element => {
			if (element?.krs.length === 0) {
				countKrs += 1
			} else {
				countKrs += element?.krs.length
			}
		});

		setTotalKrs(countKrs)
	}

	function handleFilterGoals() {
		if (seeArchived) {
			const goalsNotActiveFiltered = goalsCompleted.filter(goal => goal.is_active === false)
			setGoalsCompletedFiltered([...goalsCompletedFiltered, ...goalsNotActiveFiltered])
		} else {
			const goalsActiveFiltered = goalsCompleted.filter(goal => goal.is_active === true)
			setGoalsCompletedFiltered(goalsActiveFiltered)
		}
	}

	async function getGeneralProgressFiltered() {
		let url = `goals/concluded?organization_id=${userDetails?.organizationId}&use_not_active=true`

		if (filter.date && filter.date.startDate !== filter.date.endDate) {
			url += `&startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`
			setRangeDate({
				startDate: filter.date.startDate,
				endDate: filter.date.endDate
			})
		}
		try {
			const response = await api.get(url, {
				headers: {
					'Authorization': token.Authorization
				}
			})

			setGeneralProgressFiltered(response.data.general_progress)
			setGoalsCompletedFiltered(response.data.goals)

		} catch (error) {
			//alertActions.error(t('goals_completed.error'))
		}
	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.goals');
	}, []);

	useEffect(() => {
		getGoalsCompleted()
	}, [filter.date, filter.date, filter.teammates, filter.teams])

	useEffect(() => {
		handleFilterGoals()
	}, [seeArchived])

	useEffect(() => {
		if (useArchived) {
			getGeneralProgressFiltered()
		} else {
			setGeneralProgressFiltered(generalProgress)
			if (seeArchived) {
				setGoalsCompletedFiltered(goalsCompleted)
			} else {
				setGoalsCompletedFiltered(goalsCompleted?.filter(goal => goal.is_active === true))
			}
		}
	}, [useArchived])

	return (
		<React.Fragment>

			{path.includes('productivity') && (
				<>
					<div className={isMenuOpen ? "menuToggle open" : "menuToggle"}
						onClick={() => {
							toggleMenu('main')
						}}>
						<i className="arrow"></i>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<MenuMU menuStatus={isMenuOpen} toggle={toggleFromMenu} />
				</>
			)}

			<section className="mainContent">

				<Toolbar objectiveName={t('breadcrub.general_progress')} />

				<Filterbar
					filterWho={'multi'}
					filterWhat={'disabled'}
					filterWhen={'multi'}
					startDate={firstDayDate}
					endDate={lastDay}
					maxDate={new Date(maxDate)}
					resetOneYear={true}
				/>

				<main className='container-objectives-completed'>
					<header className='header'>
						<h1>{t('goals_completed.title')}:{' '}
							{
								filter.date
									? befectiveFormatDate(Moment(new Date(filter.date.startDate)).toISOString().split("T")[0], i18n.language) + ' - ' + befectiveFormatDate(Moment(new Date(filter.date.endDate)).toISOString().split("T")[0], i18n.language)
									: t('goals_completed.all')}
						</h1>

						<div className='end-buttons-container'>
							<div className='checks-container'>
								<FormControlLabel
									control={
										<GreenCheckbox
											checked={seeArchived}
											onClick={() => handleToggleSeeArchived()}
											name={`see-null`}
											color="primary"
										/>
									}
									label={
										<Typography
											style={{ color: '#4F7091', fontSize: '14px' }}
										>
											{t('goals_completed.items_archived')}
										</Typography>
									}
								/>

								{seeArchived && (
									<FormControlLabel
										control={
											<GreenCheckbox
												checked={useArchived}
												onClick={() => setUseArchived(!useArchived)}
												name={`see-null`}
												color="primary"
											/>
										}
										label={
											<Typography
												style={{ color: '#4F7091', fontSize: '14px' }}
											>

												{t('goals_completed.items_archived_with_calculation')}

											</Typography>
										}
									/>
								)}
							</div>
							<button className='export-button' type='button' onClick={() => setOpenModalExportReports(true)}>
								<IconShare />
							</button>
						</div>
					</header>

					<table>
						<thead>
							<tr>
								<th>{t('goals_completed.objectives')}</th>
								<th>{t('goals_completed.krs')}</th>
								<th style={{ textAlign: 'center' }}>{t('goals_completed.progress_kr')}</th>
								<th style={{ textAlign: 'center' }}>{t('goals_completed.progress_obj')}</th>
								<th style={{ textAlign: 'center' }}>{t('goals_completed.completed')}</th>
							</tr>
						</thead>
						<tbody>
							{goalsCompletedFiltered.length > 0 && goalsCompletedFiltered?.map((goal, index) => {

								if (goal?.krs?.length > 0) {
									return goal?.krs?.map((kr, index2) => (
										<tr key={kr?.id}>
											{
												index2 === 0 && (
													<td rowSpan={goal.krs.length}
														style={{
															width: '15%',
															fontSize: '16px',
															color: goal.is_active ? statusColors[goal.status_goal - 1] : '#4F7091',
															fontWeight: '500',
															padding: '15px 22px',
															textDecoration: goal.is_active ? '' : 'line-through'
														}}
													>
														{goal.title}
													</td>
												)
											}

											<td style={{ width: '40%' }}>
												<div key={kr.id} className='info-krs' style={{ textDecoration: goal.is_active && kr.is_active ? '' : 'line-through' }}>{kr.title}</div>
											</td>

											<td style={{ width: '12%' }}>
												<div key={kr.id} className='percent-krs'>
													{goal.is_active && kr.is_active ? (
														<>
															<div className='circle' style={{ border: `1px solid ${getColorStatusKr(Number(kr.status_kr))}` }}>
																<CircularProgressbar
																	value={Number(kr.progress)}
																	strokeWidth={50}
																	styles={buildStyles({
																		strokeLinecap: "butt",
																		trailColor: "#fff",
																		pathColor: getColorStatusKr(Number(kr.status_kr)),
																	})}
																/>
															</div>
															<span style={{ color: statusColors[kr.status_kr - 1] }}>
																{Math.trunc(Number(kr.progress))}%
															</span>
														</>
													) : (
														<>
															{useArchived && (
																<>
																	<div className='circle' style={{ border: `1px solid #4F7091` }}>
																		<CircularProgressbar
																			value={Number(kr.progress)}
																			strokeWidth={50}
																			styles={buildStyles({
																				strokeLinecap: "butt",
																				trailColor: "#fff",
																				pathColor: '#4F7091'
																			})}
																		/>
																	</div>
																	<span style={{ color: '#4F7091' }}>
																		{Math.trunc(Number(kr.progress))}%
																	</span>
																</>
															)}

															{!useArchived && (
																<>
																	<span>{t('goals_completed.null')}</span>

																	<Tooltip title={t("goals_completed.help_kr_archived")} arrow>
																		<span>
																			<IconInfo />
																		</span>
																	</Tooltip>
																</>
															)}

														</>
													)}

												</div>

											</td>

											{(index2 === 0) && (
												<td
													style={{
														width: '13%',
														fontSize: '18px',
														fontWeight: '500',
														color: goal.is_active ? statusColors[goal.status_goal - 1] : '#D6DEE6',
														textAlign: 'center'
													}}
													rowSpan={goal.krs.length}
												>
													{goal.is_active || useArchived ? Math.trunc(Number(goal.progress)) + '%' : t('goals_completed.null')}
												</td>
											)}

											{(index === 0 && index2 === 0) && (
												<td rowSpan={totalKrs} style={{ width: '20%', fontSize: '38px', fontWeight: '500', color: getGeneralStatusColor(), textAlign: 'center' }}>
													{Math.trunc(Number(generalProgressFiltered))}%
												</td>
											)}
										</tr>
									))
								} else {
									return <tr key={goal.id}>
										<td
											style={{
												width: '15%',
												fontSize: '16px',
												color: goal.is_active ? statusColors[goal.status_goal - 1] : '#4F7091',
												fontWeight: '500',
												padding: '15px 22px',
												textDecoration: goal.is_active ? '' : 'line-through'
											}}
										>
											{goal.title}
										</td>
										<td style={{ width: '40%' }}>
											{goal.krs.map(kr => (
												<div key={kr.id} className='info-krs' style={{ textDecoration: goal.is_active && kr.is_active ? '' : 'line-through' }}>{kr.title}</div>
											))}
											{goal.krs.length === 0 && (
												<div className='info-krs' style={{ textDecoration: goal.is_active ? '' : 'line-through' }}>{t('goals_completed.no_kr')}</div>
											)}
										</td>
										<td style={{ width: '12%' }}>
											{goal.krs.map(kr => (
												<div key={kr.id} className='percent-krs'>
													{goal.is_active && kr.is_active ? (
														<>
															<div className='circle' style={{ border: `1px solid ${getColorStatusKr(Number(kr.status_kr))}` }}>
																<CircularProgressbar
																	value={Number(kr.progress)}
																	strokeWidth={50}
																	styles={buildStyles({
																		strokeLinecap: "butt",
																		trailColor: "#fff",
																		pathColor: getColorStatusKr(Number(kr.status_kr)),
																	})}
																/>
															</div>
															<span style={{ color: statusColors[kr.status_kr - 1] }}>
																{Math.trunc(Number(kr.progress))}%
															</span>
														</>
													) : (
														<>
															{useArchived && (
																<>
																	<div className='circle' style={{ border: `1px solid #4F7091` }}>
																		<CircularProgressbar
																			value={Number(kr.progress)}
																			strokeWidth={50}
																			styles={buildStyles({
																				strokeLinecap: "butt",
																				trailColor: "#fff",
																				pathColor: '#4F7091'
																			})}
																		/>
																	</div>
																	<span style={{ color: '#4F7091' }}>
																		{Math.trunc(Number(kr.progress))}%
																	</span>
																</>
															)}

															{!useArchived && (
																<>
																	<span>{t('goals_completed.null')}</span>

																	<Tooltip title={t("goals_completed.help_kr_archived")} arrow>
																		<span>
																			<IconInfo />
																		</span>
																	</Tooltip>
																</>
															)}

														</>
													)}

												</div>
											))}
											{goal.krs.length === 0 && (
												<div className='percent-krs'>
													{goal.is_active ? (
														<>
															<div className='circle' style={{ border: `1px solid #4F7091` }}>
																<CircularProgressbar
																	value={Number(0)}
																	strokeWidth={50}
																	styles={buildStyles({
																		strokeLinecap: "butt",
																		trailColor: "#fff",
																		pathColor: '#4F7091',
																	})}
																/>
															</div>
															<span style={{ color: '#4F7091' }}>
																0%
															</span>
														</>
													) : (
														<>
															<span>{t('goals_completed.null')}</span>

															<Tooltip title={t("goals_completed.help_goal_archived")} arrow>
																<span>
																	<IconInfo />
																</span>
															</Tooltip>
														</>
													)}
												</div>
											)}
										</td>
										<td
											style={{
												width: '13%',
												fontSize: '18px',
												fontWeight: '500',
												color: goal.is_active ? statusColors[goal.status_goal - 1] : '#D6DEE6',
												textAlign: 'center'
											}}
										>
											{goal.is_active || useArchived ? Math.trunc(Number(goal.progress)) + '%' : t('goals_completed.null')}
										</td>
										{index === 0 && (
											<td rowSpan={goalsCompletedFiltered.length} style={{ width: '20%', fontSize: '38px', fontWeight: '500', color: getGeneralStatusColor(), textAlign: 'center' }}>
												{Math.trunc(Number(generalProgressFiltered))}%
											</td>
										)}
									</tr>
								}
							})}

						</tbody>
					</table>

					{goalsCompletedFiltered.length === 0 && (
						<div className='no-data'>
							<h2>{t('goals_completed.empty')}</h2>
							<p>{t('goals_completed.empty_info')}</p>
						</div>
					)}
				</main>
			</section>

			<ModalExportReports openModalExport={openModalExportReports} handleCloseModalExport={handleCloseModalExport} viewExport='generalProgressGoal' filter={{ ...filter, seeArchived: seeArchived, useArchived: useArchived }} />

		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsCompletedPage)
