import { Client, PageIterator } from '@microsoft/microsoft-graph-client';

let graphClient = undefined

export function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }

  return graphClient;
}

export async function getUser(authProvider) {
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient.api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

export async function getUserWeekCalendar(authProvider, date) {
  ensureClient(authProvider);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  // Generate startDateTime and endDateTime query params
  // to display a 7-day window
  // const startDateTime = zonedTimeToUtc(startOfWeek(now), timeZone).toISOString();
  const startDateTime = `${date}T00:00:00.000Z`
  // const endDateTime = zonedTimeToUtc(endOfWeek(now), timeZone).toISOString();
  const endDateTime = `${date}T23:59:59.999Z`

  // GET /me/calendarview?startDateTime=''&endDateTime=''
  // &$select=subject,organizer,start,end
  // &$orderby=start/dateTime
  // &$top=50
  var response = await graphClient
    .api('/me/calendarview')
    .header('Prefer', `outlook.timezone="${timeZone}"`)
    .query({ startDateTime: startDateTime, endDateTime: endDateTime })
    .select('subject,organizer,start,end')
    .orderby('start/dateTime')
    .top(25)
    .get();

  if (response["@odata.nextLink"]) {
    // Presence of the nextLink property indicates more results are available
    // Use a page iterator to get all results
    var events = [];

    // Must include the time zone header in page
    // requests too
    var options = {
      headers: { 'Prefer': `outlook.timezone="${timeZone}"` }
    };

    var pageIterator = new PageIterator(graphClient, response, (event) => {
      events.push(event);
      return true;
    }, options);

    await pageIterator.iterate();

    return events;
  } else {

    return response.value;
  }
}
