import React from 'react';

const IconEdit2 = props => {
    return (

        <svg xwidth="12" height="12" viewBox="0 0 13.338 13.338">
            <g id="icons_Q2" data-name="icons Q2" transform="translate(0)">
                <path id="Caminho_332" data-name="Caminho 332"
                      d="M17.133,8.923l-4.7-4.733a.633.633,0,0,0-.933,0l-7.3,7.3a.667.667,0,0,0-.2.467v4.7a.667.667,0,0,0,.667.667h4.7a.667.667,0,0,0,.467-.2l7.3-7.267a.633.633,0,0,0,0-.933ZM9.1,15.99H5.333V12.223L9.5,8.09l3.733,3.767Zm5.1-5.067L10.433,7.156,11.967,5.59l3.767,3.8Z"
                      transform="translate(-4 -3.985)" fill="#187DFF"/>
            </g>
        </svg>


    )
}

export default IconEdit2;
