import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {NavLink, Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'
import Nav from '../_components/settings/teams/Nav'
import Edit from '../_components/settings/teams/Edit'
import EditSkeleton from '../_components/settings/teams/EditSkeleton'
import {useTranslation, initReactI18next} from "react-i18next";

import {teamsActions} from '../_actions';
import {teammatesActions} from '../_actions';

import IconClose from '../_components/_icons/IconClose';

function CompanyTeamsPageEdit(props) {
    const {t, i18n} = useTranslation('common');

    const {teamsActions} = props;
    const {teammatesActions} = props;

    let permissions = props.permissions || [];

    let loading = props.loading;


    const [teamId, setTeamId] = useState(null)
    const [drawer, setDrawer] = useState(false)
    const [isShowingDrawerCreate, setDrawerCreate] = useState(false)

    const [drawerManageId, setDrawerManageId] = useState()

    const [minimumTime, setMinimumTime] = useState(500)
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)


    useEffect(() => {

        let path = window.location.pathname.split("/")
        let currentId = path[3]

        setTeamId(currentId)

        if (currentId) {

            teamsActions.getTeams()
            teamsActions.getTeamDetail(currentId)
            teamsActions.getTeamActivities(currentId)

        }


    }, []);


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.teams') + ' | ' + t('settings.teams.nav.edit');
    });

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Nav/>
                {/* PAGE STARTS */}
                <div className="mainPage profile">
                    <div className="nocolumn">
                        <div className={'tab full active'}>
                            {
                                !minimumTimeElapsed || loading
                                    ? <EditSkeleton/>
                                    : <Edit/>
                            }
                        </div>
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
    }
}

function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        loading: state.teams.loadingDetail,
        filter: state.filter,
        permissions: state.authentication.permissions
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyTeamsPageEdit));
