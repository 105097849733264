import React, { useEffect, useRef, useState } from 'react';
import style from './styles.module.scss'
import { Box, Button, Divider, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, history } from '../../../_helpers';
import { Delete, Edit, Link } from '@material-ui/icons';
import { Backdrop, CircularProgress, makeStyles, Modal, Popover, Slide, Typography } from "@material-ui/core";
import { ArrowUDownLeft, ArrowUDownRight, ArrowUUpRight } from 'phosphor-react';

const MapEditor = ({
  mapId,
  data,
  getData,
  rooms = []
}) => {

  const { t, i18n } = useTranslation('common');

  const minhaRef = useRef(null);
  const [shapes, setShapes] = useState(data);
  const [shapesOp, setShapesOp] = useState([]);
  const [newShape, setNewShape] = useState({ name: '', x: 0, y: 0, width: 50, height: 50, type: '', color: '#444cbb' });
  const [submittedShape, setSubmittedShape] = useState(false);
  const [formError, setFormError] = useState({
    name: '',
    type: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [dragging, setDragging] = useState(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const [selectedShape, setSelectedShape] = useState(null);
  const [shapeToEdit, setShapeToEdit] = useState(null);

  const [submittedLinked, setSubmittedLinked] = useState(false);
  const [isSubmittingLinked, setIsSubmittingLinked] = useState(false);
  const [formLinked, setFormLinked] = useState({
    room_id: null,
    name: ''
  })
  const [formErrorLinked, setFormErrorLinked] = useState({
    room_id: '',
    name: ''
  });
  const [anchorElLinked, setAnchorElLinked] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);


  const openPopoverLinked = Boolean(anchorElLinked);
  const idPopoverLinked = openPopoverLinked ? 'linked-popover' : undefined;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const leftRotateSVG = () => {
    console.log("/foi chamado left")
    setRotationAngle((rot) => {
      const value = ((rot + 90) >= 360) ? 0 : rot + 90;
      setNewShape({ ...newShape, angle: parseInt(value) })
      return value// Altere o ângulo conforme necessário
    })
  };
  const rightRotateSVG = () => {
    
    setRotationAngle((rot)=> {
      const value = ((rot - 90) <= -360) ? 0 : rot - 90
      setNewShape({ ...newShape, angle: parseInt(value) })
      return value
    }); // Altere o ângulo conforme necessário
  };

  const handleClickLinked = (event, shape) => {
    setSelectedShape(shape)
    setFormLinked(shape)
    setAnchorElLinked(event.currentTarget);
  };

  const handleCloseLinked = () => {
    setAnchorElLinked(null);
    setFormLinked({
      room_id: null,
      name: ''
    })
    setFormErrorLinked({
      room_id: '',
      name: ''
    })
    setSelectedShape(null)
  };

  const handleMouseUp = async () => {
    try {
      if (!dragging) return
      const shape = shapes.find((shape) => shape.id === dragging);
      const res = await api.put(`/rooms/maps/shapes/${dragging}`, shape, { headers: authHeader() })
      await getData()
      setDragging(null);
      setSelectedShape(null)
    } catch (error) {
      console.log(error)
    }

  };

  const handleClean = () => {
    setNewShape({ name: '', x: 0, y: 0, width: 50, height: 50, type: '', color: '#444cbb' })
  }

  const handleDeleteShape = async (shape) => {
    try {
      const res = await api.delete(`/rooms/maps/shapes/${shape.id}`, { headers: authHeader() })
      await getData()
      setShapeToEdit(null)
    } catch (error) {
      console.log(error)
    }
  };
  const getShapes = async () => {
    try {
      const res = await api.get(`/rooms/maps/shapes`, { headers: authHeader() })     
      
      if(res.status === 200 ){
        setShapesOp(res.data)
      }
      
    } catch (error) {
      console.log(error)
    }
  };

  const handleAddShape = async () => {
    try {
      setSubmittedShape(true)
      const res = await api.post(`/rooms/maps/${mapId}/shapes`, newShape, { headers: authHeader() })
      await getData()
      handleClean()
      setSubmittedShape(false)
    } catch (error) {
      console.log(error)
      setSubmittedShape(false)
    }
  };

  const handleMouseDown = (event, shapeId) => {
    event.preventDefault()
    setDragging(shapeId);
    const shape = shapes.find((shape) => shape.id === shapeId);
    const offsetX = event.clientX - shape.x;
    const offsetY = event.clientY - shape.y;
    setOffset({ x: offsetX, y: offsetY });
  };

  const handleMouseMove = (event) => {
    if (dragging !== null) {
      const updatedShapes = [...shapes];
      const shape = updatedShapes.find((shape) => shape.id === dragging);
      const newX = event.clientX - offset.x;
      const newY = event.clientY - offset.y;
      shape.x = newX;
      shape.y = newY;
      setShapes(updatedShapes);
    }
  };


  const validateForm = (form) => {
    const errors = {};
    if (!form.name) {
      errors.name = t('project_management.validations.required_field')
    }
    if (!form.type) {
      errors.type = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(newShape))
    setSubmitted(true)
  }

  useEffect(() => {
    getShapes()
    if (Object.keys(formError).length === 0 && submitted) {
      handleAddShape()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  const validateFormLinked = (form) => {
    const errors = {};

    if (!form.room_id) {
      errors.room_id = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmitLinked = (e) => {
    e.preventDefault()
    setFormErrorLinked(validateFormLinked(formLinked))
    setSubmittedLinked(true)
  }

  const addLinkedPosition = async () => {
    try {
      setIsSubmittingLinked(true)
      const res = await api.put(`/rooms/maps/shapes/${selectedShape.id}`, {
        ...selectedShape,
        room_id: formLinked.room_id,
        name: formLinked.name
      },
        {
          headers: authHeader()
        }
      )
      getData()
      handleCloseLinked()
      setIsSubmittingLinked(false)
    } catch (error) {
      console.log(error)
      handleCloseLinked()
      setIsSubmittingLinked(false)
    }
  }

  useEffect(() => {

    if (Object.keys(formErrorLinked).length === 0 && submittedLinked) {
      addLinkedPosition()
    } else {
      setSubmittedLinked(false)
    }

  }, [formErrorLinked, submittedLinked])

  useEffect(() => {
    setShapes(data)
  }, [data])

  useEffect(() => {
    console.log("shapeToEdit => ", shapeToEdit)
  }, [shapeToEdit])
  

  return (
    <Box>

      <Box sx={{ flexGrow: 1, backgroundColor: '#fff', paddingX: 1.5, paddingY: 2.5, marginBottom: 2, borderRadius: '4px' }}>
        <Grid container spacing={2} component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontSize: '14px', color: '#1E6B7F', fontWeight:500 }}>{t('spaces.map_editor.create_element')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="type"
              select
              label={t('spaces.map_editor.field_shape')}
              value={newShape.type}
              onChange={(e) => setNewShape({ ...newShape, type: e.target.value })}
              placeholder={t('spaces.map_editor.select')}
              error={formError.type}
              onFocus={() => setFormError({ ...formError, type: '' })}
            >
              <MenuItem value={''}>
                {t('spaces.map_editor.select')}
              </MenuItem>
              {shapesOp.length > 0 && shapesOp.map(item => 
                (
                  <MenuItem key={item.id} value={item.key}>
                    {item[`locale_${i18n.language}`]}
                  </MenuItem>
                ))}
              
              {/* <MenuItem value={'rectangle'}>
                {t('spaces.map_editor.rectangle')}
              </MenuItem>
              <MenuItem value={'circle'}>
                {t('spaces.map_editor.circle')}
              </MenuItem>
              <MenuItem value={'chair'}>
                {'Chair'}
              </MenuItem> */}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="name"
              label={t('spaces.map_editor.field_title')}
              variant="outlined"
              value={newShape.name}
              onChange={(e) => setNewShape({ ...newShape, name: e.target.value })}
              error={formError.name}
              onFocus={() => setFormError({ ...formError, name: '' })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="width"
              label={t('spaces.map_editor.field_width')}
              variant="outlined"
              type='number'
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={newShape.width}
              onChange={(e) => setNewShape({ ...newShape, width: parseInt(e.target.value) })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="height"
              label={t('spaces.map_editor.field_height')}
              variant="outlined"
              type='number'
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={newShape.height}
              onChange={(e) => setNewShape({ ...newShape, height: parseInt(e.target.value) })}
            />
          </Grid>
          <Grid item xs={4} display={'flex'} flexDirection={'column'}>
            <label className={style.labelColor} htmlFor="color">{t('spaces.map_editor.field_color')}</label>
            <input className={style.inputColor} id='color' type="color" value={newShape.color} onChange={(e) => setNewShape({ ...newShape, color: e.target.value })} />
          </Grid>
          <Grid item xs={12} justifyContent={'space-between'} display={'flex'} gap={'6px'}>
            <Grid item xs={6}  display={'flex'} gap={'10px'}>
              <ArrowUDownRight size={30} onClick={() => rightRotateSVG()}  />  

              <ArrowUDownLeft size={30}  onClick={() => leftRotateSVG()}/>
            </Grid>
            <Grid item xs={6} justifyContent={'flex-end'} display={'flex'} gap={'6px'}>
              {
                newShape.type &&
                <Button disabled={submittedShape} type='button' variant="contained" color='inherit' disableElevation onClick={handleClean}>
                  {t('spaces.map_editor.clean')}
                </Button>
              }

              {
                shapeToEdit &&
                <Button disabled={submittedShape} type='button' variant="contained" color='error' disableElevation onClick={()=>handleDeleteShape(shapeToEdit)}>
                  {t('spaces.map_editor.btn_delete_shape')}
                </Button>
              }

                <Button disabled={submittedShape} type='submit' variant="contained" disableElevation>
                  {t('spaces.map_editor.btn_add_shape')}
                </Button>
            </Grid>
                        
          </Grid>
        </Grid>
        {
          newShape.type &&
          <Grid container borderTop={'1px solid #B2B8BF75'} marginTop={'12px'}>
            <Grid item xs={12} marginTop={'6px'} marginBottom={'8px'}>
              <Typography variant='h6' style={{ fontSize: '14px', color: '#1E6B7F', fontWeight:500 }}>{t('spaces.map_editor.preview')}</Typography>
            </Grid>
            <Grid item xs={12} justifyContent={'center'} display={'flex'} style={{backgroundColor:'#F5F6F9', padding: '10px'}}>
              {
                newShape.type === 'rectangle' &&
                <svg width={newShape.width} height={newShape.height} style={{transform: `rotate(${rotationAngle}deg)`}}>
                  <g className={style.rectangleGroup}>
                    <rect
                      x={newShape.x}
                      y={newShape.y}
                      width={newShape.width}
                      height={newShape.height}
                      fill={newShape.color}
                      className={style.rectangle}
                    />
                    <text x={newShape.x + newShape.width / 2} y={newShape.y + newShape.height / 2} width={newShape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                      {newShape.name}
                    </text>
                  </g>
                </svg>
              }
              {
                newShape.type === 'circle' &&
                <svg width={newShape.width} height={newShape.height}>
                  <g className={style.rectangleGroup}>
                    <circle
                      cx={newShape.x + newShape.width / 2}
                      cy={newShape.y + newShape.height / 2}
                      r={Math.min(newShape.width, newShape.height) / 2}
                      fill={newShape.color}
                      className={style.rectangle}
                    />
                    <text x={newShape.x + newShape.width / 2} y={newShape.y + newShape.height / 2} width={newShape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                      {newShape.name}
                    </text>
                  </g>
                </svg>
              }

              {
                newShape.type === 'chair' &&
                <svg version="1.1"  x={newShape.x} y={newShape.y} style={{transform: `rotate(${rotationAngle}deg)`}}
                  viewBox="0 0 600 600" width={30} height={30}>        
                  <rect x="239.48" y="66.06" style={{fill:"#5D5360"}} width="33.03" height="74.32"/>
                  <rect x="239.48" y="115.61" style={{fill:"#5D5360"}} width="33.03" height="33.03"/>
                  <rect x="24.774" y="231.23" style={{fill:"#5D5360"}}width="462.45" height="33.03"/>
                  <rect x="66.06" y="231.23" style={{fill:"#5D5360"}} width="379.87" height="33.03"/>
                  <g>
                    <path style={{fill:"#B67F5F"}} d="M82.581,181.678v256.454c0,32.779,24.022,60.476,56.431,65.388C175.042,508.981,214.549,512,256,512
                      s80.958-3.019,116.989-8.48c32.409-4.912,56.431-32.609,56.431-65.388V181.678c0-27.365-22.184-49.548-49.548-49.548H132.129
                      C104.764,132.129,82.581,154.313,82.581,181.678z"/>
                    <path style={{fill:"#B67F5F"}} d="M421.219,26.852C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                      c-12.887,4.535-19.608,18.514-15.012,31.232c3.609,10.001,13.149,16.231,23.333,16.233l313.795,0.006
                      c10.177,0,19.721-6.242,23.334-16.239C440.828,45.366,434.106,31.387,421.219,26.852z"/>
                  </g>
                  <path style={{fill:"#5D5360"}}d="M20.645,404.645L20.645,404.645C9.243,404.645,0,395.402,0,384V194.064
                    c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C41.29,395.402,32.047,404.645,20.645,404.645z"
                    />
                  <path style={{fill:"#6F6571"}} d="M24.774,173.836v230.393C34.198,402.316,41.29,393.987,41.29,384V194.065
                    C41.29,184.077,34.198,175.749,24.774,173.836z"/>
                  <path style={{fill:"#5D5360"}}d="M491.355,404.645L491.355,404.645c-11.402,0-20.645-9.243-20.645-20.645V194.064
                    c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C512,395.402,502.757,404.645,491.355,404.645z"
                    />
                  <path style={{fill:"#6F6571"}} d="M495.484,173.836v230.393C504.907,402.316,512,393.987,512,384V194.065
                    C512,184.077,504.907,175.749,495.484,173.836z"/>
                  <path style={{fill:"#A06E50"}} d="M115.613,297.29c0-77.534,62.853-140.387,140.387-140.387h166.788
                    c-8.571-14.795-24.588-24.774-42.917-24.774H132.129c-27.365,0-49.548,22.184-49.548,49.548v256.454
                    c0,24.209,13.125,45.657,33.032,57.19C115.613,495.321,115.613,297.29,115.613,297.29z"/>
                  <path style={{fill:"#CD916E"}} d="M77.837,62.326c2.841-4.78,7.239-8.693,12.944-10.7C140.718,34.059,197.849,24.774,256,24.774
                    s115.282,9.285,165.219,26.852c5.703,2.007,10.101,5.919,12.943,10.698c0.8-1.333,1.522-2.727,2.069-4.24
                    c4.597-12.718-2.125-26.697-15.012-31.232C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                    c-12.887,4.535-19.608,18.514-15.012,31.232C76.316,59.598,77.037,60.993,77.837,62.326z"/>     
            
                </svg>
              }

              {newShape.type === 'desk' &&
                <svg  width="240.745" height="152.738" viewBox="0 0 240.745 152.738" x={newShape.x} y={newShape.y} style={{transform: `rotate(${rotationAngle}deg)`}}>
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Retângulo_1678" data-name="Retângulo 1678" width="240.738" height="152.255" fill="none"/>
                    </clipPath>
                  </defs>
                  <g id="mesa" transform="translate(-449.383 -943.788)">
                    <rect id="Retângulo_1669" data-name="Retângulo 1669" width="240.738" height="23.783" transform="translate(449.389 1110.274)" fill="#a89d90"/>
                    <rect id="Retângulo_1670" data-name="Retângulo 1670" width="240.738" height="23.783" transform="translate(449.389 1086.491)" fill="#999085"/>
                    <rect id="Retângulo_1671" data-name="Retângulo 1671" width="240.738" height="23.783" transform="translate(449.389 1062.709)" fill="#978c7f"/>
                    <rect id="Retângulo_1672" data-name="Retângulo 1672" width="240.738" height="23.783" transform="translate(449.389 1038.926)" fill="#a49989"/>
                    <rect id="Retângulo_1673" data-name="Retângulo 1673" width="240.738" height="23.783" transform="translate(449.389 1015.144)" fill="#999085"/>
                    <rect id="Retângulo_1674" data-name="Retângulo 1674" width="240.738" height="23.783" transform="translate(449.389 991.361)" fill="#a89d90"/>
                    <rect id="Retângulo_1675" data-name="Retângulo 1675" width="240.738" height="23.783" transform="translate(449.389 967.579)" fill="#a49989"/>
                    <rect id="Retângulo_1676" data-name="Retângulo 1676" width="240.738" height="23.783" transform="translate(449.389 943.796)" fill="#978c7f"/>
                    <path id="Caminho_2614" data-name="Caminho 2614" d="M305.692,17.255C205.4,23.071,105.2,13.225,5.01,10.129,105.2,13.164,205.4,23.009,305.692,17.255" transform="translate(444.436 962.493)" fill="#ceb9b2"/>
                    <path id="Caminho_2615" data-name="Caminho 2615" d="M5.01,10.723C105.2,13.763,205.4,23.6,305.692,17.849,205.4,23.665,105.2,13.825,5.01,10.723" transform="translate(444.436 965.237)" fill="#ceb9b2"/>
                    <path id="Caminho_2616" data-name="Caminho 2616" d="M5.01,11.14C105.2,14.18,205.4,24.02,305.692,18.266,205.4,24.082,105.2,14.242,5.01,11.14" transform="translate(444.436 967.164)" fill="#ceb9b2"/>
                    <path id="Caminho_2617" data-name="Caminho 2617" d="M5.01,11.428C105.2,14.468,205.4,24.308,305.692,18.554,205.4,24.37,105.2,14.53,5.01,11.428" transform="translate(444.436 968.494)" fill="#ceb9b2"/>
                    <path id="Caminho_2618" data-name="Caminho 2618" d="M5.01,11.565C105.2,14.605,205.4,24.445,305.692,18.691,205.4,24.507,105.2,14.667,5.01,11.565" transform="translate(444.436 969.127)" fill="#ceb9b2"/>
                    <path id="Caminho_2619" data-name="Caminho 2619" d="M5.01,11.877C105.2,14.917,205.4,24.757,305.692,19,205.4,24.819,105.2,14.979,5.01,11.877" transform="translate(444.436 970.568)" fill="#ceb9b2"/>
                    <path id="Caminho_2620" data-name="Caminho 2620" d="M5.01,9.527C105.2,12.567,205.4,22.407,305.692,16.653,205.4,22.469,105.2,12.629,5.01,9.527" transform="translate(444.436 959.712)" fill="#ceb9b2"/>
                    <path id="Caminho_2621" data-name="Caminho 2621" d="M5.01,13.448c13.01.427,26.025.152,39.029-.214,6.508-.2,13.01-.461,19.506-.815l9.739-.624c3.186-.354,6.6-.315,9.565-1.366C80,9.407,76.481,9.6,73.317,9.323L63.562,8.98,44.05,8.632,5.01,8.452,44.05,8.6l19.517.332,9.75.337,4.872.292c1.568.23,3.372.039,4.715.877-3.057,1.141-6.412,1.028-9.621,1.4l-9.739.618q-9.745.514-19.5.8c-13.01.36-26.025.618-39.034.185" transform="translate(444.436 954.746)" fill="#ceb9b2"/>
                    <path id="Caminho_2622" data-name="Caminho 2622" d="M5.01,11.195l22.636-.483c7.519-.292,15.156-.27,22.563-1.09-7.379-.714-15.044-.551-22.557-.731L5.01,8.694l22.642.169c7.536.191,15.123-.051,22.614.753-7.514.933-15.078.815-22.614,1.124Z" transform="translate(444.436 955.864)" fill="#ceb9b2"/>
                    <path id="Caminho_2623" data-name="Caminho 2623" d="M5.01,15.055Q34.7,15.831,64.4,15.3c9.9-.169,19.793-.438,29.683-.865,4.945-.219,9.885-.483,14.825-.826l7.4-.629c2.394-.365,5.063-.326,7.171-1.433-2.006-1.146-4.76-1-7.137-1.326l-7.407-.489L94.093,9.154,64.4,8.592c-19.8-.23-39.6-.264-59.395-.23,19.8-.039,39.6-.017,59.395.2l29.689.545,14.842.568,7.407.483,3.692.377c1.169.264,2.591.129,3.5,1.017-2.192,1.191-4.81,1.1-7.221,1.472l-7.4.629c-4.934.343-9.879.6-14.825.815-9.891.427-19.787.691-29.683.854q-29.689.48-59.389-.27" transform="translate(444.436 954.257)" fill="#ceb9b2"/>
                    <path id="Caminho_2624" data-name="Caminho 2624" d="M5.01,18.135c35.05.191,70.1.27,105.15.034,17.522-.124,35.044-.32,52.567-.708,8.761-.2,17.517-.438,26.272-.809,4.378-.185,8.755-.4,13.122-.7l6.547-.562,3.259-.421c.989-.247,2.36-.236,2.945-1.023-1.742-1.146-4.164-1.124-6.26-1.506l-6.536-.68c-4.361-.382-8.733-.674-13.105-.938-8.75-.517-17.505-.9-26.261-1.219-17.511-.635-35.033-1.023-52.555-1.281Q57.588,7.567,5.01,8.379q52.567-.784,105.145-.09c17.522.253,35.044.635,52.561,1.27,8.755.32,17.511.7,26.255,1.208,4.372.264,8.744.556,13.111.938l6.536.674c2.113.4,4.49.32,6.311,1.551-.652.916-1.995.815-3,1.085l-3.259.427L202.127,16c-4.372.3-8.75.517-13.122.7-8.755.365-17.517.607-26.278.8-17.522.382-35.044.573-52.567.691-35.05.225-70.1.14-105.15-.062" transform="translate(444.436 952.441)" fill="#ceb9b2"/>
                    <path id="Caminho_2625" data-name="Caminho 2625" d="M5.01,15.781c27.458.208,54.916.292,82.374.1,13.729-.1,27.458-.27,41.187-.6l20.579-.68,10.284-.579,5.125-.455c1.63-.275,3.58-.208,4.884-1.113-3.007-1.332-6.682-1.208-10.026-1.624l-10.273-.658c-6.856-.354-13.718-.6-20.579-.815-13.723-.41-27.452-.646-41.181-.8-27.458-.3-54.916-.27-82.374-.051,27.458-.23,54.916-.27,82.374.022,13.729.152,27.458.382,41.181.792,6.862.208,13.723.455,20.579.809l10.278.652,5.125.489c1.652.3,3.524.2,4.951,1.18-1.388,1.023-3.288.871-4.934,1.175l-5.131.45-10.278.579-20.585.674c-13.723.326-27.452.483-41.187.584C59.926,16.1,32.468,16,5.01,15.781" transform="translate(444.436 954.132)" fill="#ceb9b2"/>
                    <path id="Caminho_2626" data-name="Caminho 2626" d="M305.692,15.677C205.5,12.636,105.3,2.8,5.01,8.551,105.3,2.735,205.5,12.575,305.692,15.677" transform="translate(444.436 946.869)" fill="#ceb9b2"/>
                    <path id="Caminho_2627" data-name="Caminho 2627" d="M305.692,15.082C205.5,12.041,105.3,2.2,5.01,7.956,105.3,2.14,205.5,11.98,305.692,15.082" transform="translate(444.436 944.121)" fill="#ceb9b2"/>
                    <path id="Caminho_2628" data-name="Caminho 2628" d="M305.692,14.66C205.5,11.625,105.3,1.779,5.01,7.54,105.3,1.723,205.5,11.558,305.692,14.66" transform="translate(444.436 942.194)" fill="#ceb9b2"/>
                    <path id="Caminho_2629" data-name="Caminho 2629" d="M305.692,14.377C205.5,11.336,105.3,1.5,5.01,7.251,105.3,1.435,205.5,11.275,305.692,14.377" transform="translate(444.436 940.864)" fill="#ceb9b2"/>
                    <path id="Caminho_2630" data-name="Caminho 2630" d="M305.692,14.24C205.5,11.2,105.3,1.359,5.01,7.114,105.3,1.3,205.5,11.138,305.692,14.24" transform="translate(444.436 940.231)" fill="#ceb9b2"/>
                    <path id="Caminho_2631" data-name="Caminho 2631" d="M305.692,13.928C205.5,10.887,105.3,1.047,5.01,6.8,105.3.986,205.5,10.826,305.692,13.928" transform="translate(444.436 938.79)" fill="#ceb9b2"/>
                    <path id="Caminho_2632" data-name="Caminho 2632" d="M305.692,16.278C205.5,13.237,105.3,3.4,5.01,9.152,105.3,3.336,205.5,13.176,305.692,16.278" transform="translate(444.436 949.646)" fill="#ceb9b2"/>
                    <path id="Caminho_2633" data-name="Caminho 2633" d="M178.327,10.294c-24.266-2.062-48.667-1.124-72.932.427C81.112,12.294,56.885,14.7,32.58,16.009l.006-.056c24.243,1.725,48.565,2.248,72.871,2.315,24.305-.022,48.627-.495,72.871-2.282-24.243,1.8-48.565,2.282-72.871,2.31-24.305-.062-48.627-.573-72.876-2.287v-.056c24.3-1.3,48.526-3.7,72.814-5.26,24.266-1.545,48.667-2.467,72.932-.4" transform="translate(571.801 957.715)" fill="#ceb9b2"/>
                    <path id="Caminho_2634" data-name="Caminho 2634" d="M154.683,10.179c-19.489-1.158-39.029-.607-58.518.253C76.671,11.309,57.2,12.646,37.7,13.4v-.056c19.483.972,38.989,1.259,58.49,1.3,19.506-.011,39.018-.27,58.5-1.27-19.478,1.012-38.989,1.276-58.5,1.3-19.5-.034-39.012-.315-58.49-1.276v-.056C57.2,12.6,76.671,11.27,96.165,10.4c19.489-.849,39.029-1.388,58.518-.225" transform="translate(595.444 959.639)" fill="#ceb9b2"/>
                    <path id="Caminho_2635" data-name="Caminho 2635" d="M129.173,10.052c-28.66-.882-57.3.725-85.948,1.337v-.056l85.948.028-85.953.028v-.056c28.649-.6,57.3-2.18,85.953-1.281" transform="translate(620.954 960.89)" fill="#ceb9b2"/>
                    <path id="Caminho_2636" data-name="Caminho 2636" d="M33.719,5c11.593.888,23.215,1.349,34.82,1.978L103.381,8.67l69.679,3.31L103.375,8.726,68.539,7.006C56.934,6.371,45.312,5.9,33.719,5" transform="translate(577.063 938.799)" fill="#ceb9b2"/>
                    <path id="Caminho_2637" data-name="Caminho 2637" d="M41.151,5l97.575,5.176Z" transform="translate(611.396 938.799)" fill="#ceb9b2"/>
                    <path id="Caminho_2638" data-name="Caminho 2638" d="M101.081,8.49,49.3,5Z" transform="translate(649.047 938.799)" fill="#ceb9b2"/>
                    <path id="Caminho_2639" data-name="Caminho 2639" d="M68.743,6.748,56.3,5Z" transform="translate(681.385 938.799)" fill="#ceb9b2"/>
                    <path id="Caminho_2640" data-name="Caminho 2640" d="M155.348,45.4c-12.509-.967-25.047-1.512-37.573-2.214L80.19,41.243l-75.18-3.8,75.18,3.748,37.59,1.973c12.521.714,25.058,1.264,37.568,2.242" transform="translate(444.436 1088.653)" fill="#ceb9b2"/>
                    <path id="Caminho_2641" data-name="Caminho 2641" d="M5.01,37.76l100.447,6.154Z" transform="translate(444.436 1090.14)" fill="#ceb9b2"/>
                    <path id="Caminho_2642" data-name="Caminho 2642" d="M5.01,38.06l54.106,4.473Z" transform="translate(444.436 1091.526)" fill="#ceb9b2"/>
                    <path id="Caminho_2643" data-name="Caminho 2643" d="M5.01,38.37,21.667,41.1Z" transform="translate(444.436 1092.958)" fill="#ceb9b2"/>
                    <path id="Caminho_2644" data-name="Caminho 2644" d="M305.692,32.386C205.5,29.345,105.3,19.505,5.01,25.26,105.3,19.444,205.5,29.284,305.692,32.386" transform="translate(444.436 1024.06)" fill="#ceb9b2"/>
                    <path id="Caminho_2645" data-name="Caminho 2645" d="M305.692,31.791C205.5,28.75,105.3,18.91,5.01,24.665,105.3,18.849,205.5,28.689,305.692,31.791" transform="translate(444.436 1021.311)" fill="#ceb9b2"/>
                    <path id="Caminho_2646" data-name="Caminho 2646" d="M305.692,31.374C205.5,28.333,105.3,18.493,5.01,24.248,105.3,18.432,205.5,28.272,305.692,31.374" transform="translate(444.436 1019.385)" fill="#ceb9b2"/>
                    <path id="Caminho_2647" data-name="Caminho 2647" d="M305.692,31.085C205.5,28.051,105.3,18.2,5.01,23.959,105.3,18.143,205.5,27.989,305.692,31.085" transform="translate(444.436 1018.055)" fill="#ceb9b2"/>
                    <path id="Caminho_2648" data-name="Caminho 2648" d="M305.692,30.946C205.5,27.912,105.3,18.066,5.01,23.821,105.3,18.01,205.5,27.85,305.692,30.946" transform="translate(444.436 1017.424)" fill="#ceb9b2"/>
                    <path id="Caminho_2649" data-name="Caminho 2649" d="M305.692,30.638C205.5,27.6,105.3,17.757,5.01,23.512,105.3,17.7,205.5,27.536,305.692,30.638" transform="translate(444.436 1015.985)" fill="#ceb9b2"/>
                    <path id="Caminho_2650" data-name="Caminho 2650" d="M305.692,32.984C205.5,29.95,105.3,20.1,5.01,25.859,105.3,20.048,205.5,29.888,305.692,32.984" transform="translate(444.436 1026.839)" fill="#ceb9b2"/>
                    <path id="Caminho_2651" data-name="Caminho 2651" d="M122.549,26.019c-13.01-.427-26.025-.152-39.029.214-6.508.2-13.01.461-19.506.815l-9.739.624c-3.186.354-6.6.315-9.565,1.366,2.849,1.023,6.367.832,9.531,1.107L64,30.487l19.512.348,39.04.18-39.04-.152-19.517-.332-9.75-.337L49.369,29.9c-1.568-.23-3.372-.039-4.715-.877,3.057-1.141,6.412-1.028,9.621-1.4L64.014,27q9.745-.514,19.5-.8c13.01-.36,26.025-.618,39.034-.185" transform="translate(627.579 1034.855)" fill="#ceb9b2"/>
                    <path id="Caminho_2652" data-name="Caminho 2652" d="M133.391,31.4l-91.079-.258V31.1a529.748,529.748,0,0,0,91.079,2.81,529.812,529.812,0,0,1-91.084-2.759l.006-.051Z" transform="translate(616.736 1059.35)" fill="#ceb9b2"/>
                    <path id="Caminho_2653" data-name="Caminho 2653" d="M95.717,26.035l-22.636.483c-7.519.292-15.156.27-22.563,1.09,7.379.714,15.044.551,22.557.731l22.642.2-22.642-.169c-7.536-.191-15.123.051-22.614-.753,7.514-.933,15.078-.815,22.614-1.124Z" transform="translate(654.41 1035.974)" fill="#ceb9b2"/>
                    <path id="Caminho_2654" data-name="Caminho 2654" d="M155.945,26.016q-29.689-.776-59.389-.242c-9.9.169-19.793.438-29.683.865-4.945.219-9.885.483-14.825.826l-7.4.629c-2.394.365-5.063.326-7.171,1.433,2.006,1.146,4.76,1,7.137,1.326l7.407.489,14.836.573,29.689.562c19.8.23,39.6.258,59.395.23-19.8.039-39.6.017-59.395-.2l-29.689-.545-14.842-.568-7.407-.483-3.692-.377c-1.169-.264-2.591-.129-3.5-1.017,2.192-1.191,4.8-1.1,7.221-1.472l7.4-.629c4.94-.343,9.885-.6,14.83-.815,9.891-.427,19.787-.691,29.683-.854q29.689-.48,59.389.27" transform="translate(594.184 1033.735)" fill="#ceb9b2"/>
                    <path id="Caminho_2655" data-name="Caminho 2655" d="M231.079,25.57c-35.05-.191-70.1-.27-105.15-.034-17.522.124-35.044.32-52.567.708-8.761.2-17.517.438-26.272.809-4.378.185-8.755.4-13.122.7l-6.547.562-3.259.421c-.989.247-2.36.23-2.945,1.023,1.742,1.146,4.164,1.124,6.26,1.506l6.536.68c4.361.382,8.733.674,13.105.938,8.75.517,17.505.9,26.261,1.219,17.511.635,35.033,1.023,52.555,1.281q52.567.75,105.145-.062-52.567.784-105.145.09c-17.522-.253-35.044-.635-52.561-1.27-8.756-.32-17.511-.7-26.255-1.208-4.372-.264-8.744-.556-13.111-.938l-6.536-.68c-2.113-.4-4.49-.315-6.311-1.545.652-.916,2-.815,3-1.085l3.259-.427,6.547-.556c4.372-.3,8.75-.517,13.122-.7,8.756-.365,17.517-.607,26.278-.8,17.522-.382,35.044-.573,52.567-.691,35.05-.225,70.1-.141,105.15.062" transform="translate(519.049 1032.922)" fill="#ceb9b2"/>
                    <path id="Caminho_2656" data-name="Caminho 2656" d="M193.734,25.714c-27.458-.208-54.916-.292-82.374-.1-13.729.1-27.458.27-41.187.6l-20.579.68-10.284.579-5.125.455c-1.63.275-3.58.208-4.884,1.113,3.007,1.332,6.682,1.208,10.026,1.624l10.273.658c6.856.354,13.718.6,20.579.815,13.723.41,27.452.646,41.181.8,27.458.3,54.916.27,82.374.051-27.458.23-54.916.27-82.374-.022-13.729-.152-27.458-.382-41.181-.792-6.862-.208-13.723-.455-20.579-.809l-10.278-.652L34.2,30.227c-1.652-.3-3.524-.2-4.951-1.18,1.388-1.023,3.288-.871,4.934-1.175l5.131-.45,10.278-.579,20.585-.674c13.723-.326,27.452-.483,41.181-.584,27.464-.185,54.921-.09,82.379.129" transform="translate(556.395 1033.44)" fill="#ceb9b2"/>
                    <path id="Caminho_2657" data-name="Caminho 2657" d="M5.01,26.838C105.2,29.878,205.4,39.718,305.692,33.964,205.4,39.78,105.2,29.94,5.01,26.838" transform="translate(444.436 1039.684)" fill="#ceb9b2"/>
                    <path id="Caminho_2658" data-name="Caminho 2658" d="M5.01,27.433C105.2,30.473,205.4,40.313,305.692,34.559,205.4,40.375,105.2,30.535,5.01,27.433" transform="translate(444.436 1042.433)" fill="#ceb9b2"/>
                    <path id="Caminho_2659" data-name="Caminho 2659" d="M5.01,27.85C105.2,30.89,205.4,40.73,305.692,34.976,205.4,40.792,105.2,30.952,5.01,27.85" transform="translate(444.436 1044.359)" fill="#ceb9b2"/>
                    <path id="Caminho_2660" data-name="Caminho 2660" d="M5.01,28.138C105.2,31.178,205.4,41.018,305.692,35.264,205.4,41.08,105.2,31.24,5.01,28.138" transform="translate(444.436 1045.689)" fill="#ceb9b2"/>
                    <path id="Caminho_2661" data-name="Caminho 2661" d="M5.01,28.275C105.2,31.315,205.4,41.155,305.692,35.4,205.4,41.217,105.2,31.377,5.01,28.275" transform="translate(444.436 1046.322)" fill="#ceb9b2"/>
                    <path id="Caminho_2662" data-name="Caminho 2662" d="M5.01,28.587C105.2,31.627,205.4,41.467,305.692,35.713,205.4,41.529,105.2,31.689,5.01,28.587" transform="translate(444.436 1047.764)" fill="#ceb9b2"/>
                    <path id="Caminho_2663" data-name="Caminho 2663" d="M5.01,26.237C105.2,29.277,205.4,39.117,305.692,33.363,205.4,39.179,105.2,29.339,5.01,26.237" transform="translate(444.436 1036.907)" fill="#ceb9b2"/>
                    <path id="Caminho_2664" data-name="Caminho 2664" d="M5.01,32.444c24.266,2.062,48.667,1.124,72.932-.427,24.283-1.574,48.509-3.979,72.809-5.288v.056c-24.243-1.725-48.565-2.248-72.871-2.315-24.305.022-48.627.495-72.871,2.282,24.243-1.8,48.565-2.282,72.871-2.31,24.305.062,48.627.573,72.876,2.287v.056c-24.3,1.3-48.526,3.7-72.814,5.26-24.266,1.545-48.667,2.467-72.932.4" transform="translate(444.436 1028.615)" fill="#ceb9b2"/>
                    <path id="Caminho_2665" data-name="Caminho 2665" d="M5.01,29.24c19.489,1.158,39.029.607,58.518-.253C83.023,28.11,102.495,26.773,122,26.02v.056c-19.484-.972-38.989-1.259-58.5-1.3-19.5.011-39.012.27-58.49,1.27C24.488,25.031,44,24.766,63.5,24.744c19.506.034,39.018.315,58.5,1.276v.056c-19.5.742-38.973,2.074-58.467,2.939C44.039,29.864,24.5,30.4,5.01,29.24" transform="translate(444.436 1030.01)" fill="#ceb9b2"/>
                    <path id="Caminho_2666" data-name="Caminho 2666" d="M5.01,26.443c28.655.882,57.3-.725,85.948-1.337v.056L5.01,25.134l85.953-.028v.056c-28.649.6-57.3,2.18-85.953,1.281" transform="translate(444.436 1031.682)" fill="#ceb9b2"/>
                    <path id="Caminho_2667" data-name="Caminho 2667" d="M102.59,35.912l21.439.23,10.717-.022,2.675-.067,1.337-.084c.191-.034.517-.022.584-.146a2.672,2.672,0,0,0-.641-.157c-1.77-.242-3.552-.36-5.333-.495-14.263-.967-28.542-1.59-42.817-2.276L47.72,30.961C33.5,30.292,19.048,29.809,5.01,28.932c14.038.865,28.486,1.337,42.715,2l42.833,1.905c14.274.7,28.554,1.332,42.811,2.3,1.781.135,3.563.253,5.339.5a2.412,2.412,0,0,1,.652.163c-.084.2-.4.135-.6.185l-1.337.084-2.681.067-10.717.017Z" transform="translate(444.436 1049.358)" fill="#ceb9b2"/>
                    <path id="Caminho_2668" data-name="Caminho 2668" d="M56.79,34.428l45.8-.017v.034L5.01,29.253l97.58,5.159v.034Z" transform="translate(444.434 1050.839)" fill="#ceb9b2"/>
                    <path id="Caminho_2669" data-name="Caminho 2669" d="M5.01,35.138l51.78-2.124-.006.056L5.01,29.552l51.78,3.462v.056Z" transform="translate(444.435 1052.223)" fill="#ceb9b2"/>
                    <path id="Caminho_2670" data-name="Caminho 2670" d="M5.01,31.61l12.442-.028-.006.056L5.01,29.863l12.448,1.72-.006.056Z" transform="translate(444.435 1053.657)" fill="#ceb9b2"/>
                    <path id="Caminho_2671" data-name="Caminho 2671" d="M305.692,27.145c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,15.636,55.076,14.293,5.01,18.007c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 988.243)" fill="#ceb9b2"/>
                    <path id="Caminho_2672" data-name="Caminho 2672" d="M305.692,26.383c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.874,55.076,13.531,5.01,17.245c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 984.723)" fill="#ceb9b2"/>
                    <path id="Caminho_2673" data-name="Caminho 2673" d="M305.692,25.848c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.339,55.076,13,5.01,16.71,55.076,12.979,105.355,14.3,155.433,17c50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 982.251)" fill="#ceb9b2"/>
                    <path id="Caminho_2674" data-name="Caminho 2674" d="M305.692,25.479c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.97,55.076,12.627,5.01,16.341c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 980.547)" fill="#ceb9b2"/>
                    <path id="Caminho_2675" data-name="Caminho 2675" d="M305.692,25.3c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.795,55.076,12.452,5.01,16.166c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 979.738)" fill="#ceb9b2"/>
                    <path id="Caminho_2676" data-name="Caminho 2676" d="M305.692,24.9c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.395,55.076,12.052,5.01,15.766c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 977.891)" fill="#ceb9b2"/>
                    <path id="Caminho_2677" data-name="Caminho 2677" d="M305.692,27.916c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,16.407,55.076,15.064,5.01,18.778c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 991.805)" fill="#ceb9b2"/>
                    <path id="Caminho_2678" data-name="Caminho 2678" d="M5.01,17.652c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,30.521,205.347,29.2,155.27,26.5c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 997.247)" fill="#ceb9b2"/>
                    <path id="Caminho_2679" data-name="Caminho 2679" d="M5.01,18.415c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1000.772)" fill="#ceb9b2"/>
                    <path id="Caminho_2680" data-name="Caminho 2680" d="M5.01,18.949c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,31.818,205.347,30.5,155.27,27.8c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1003.239)" fill="#ceb9b2"/>
                    <path id="Caminho_2681" data-name="Caminho 2681" d="M5.01,19.318c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1004.944)" fill="#ceb9b2"/>
                    <path id="Caminho_2682" data-name="Caminho 2682" d="M5.01,19.494c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1005.757)" fill="#ceb9b2"/>
                    <path id="Caminho_2683" data-name="Caminho 2683" d="M5.01,19.894c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1007.605)" fill="#ceb9b2"/>
                    <path id="Caminho_2684" data-name="Caminho 2684" d="M5.01,16.881c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 993.686)" fill="#ceb9b2"/>
                    <path id="Caminho_2685" data-name="Caminho 2685" d="M5.01,34.58c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1075.45)" fill="#ceb9b2"/>
                    <path id="Caminho_2686" data-name="Caminho 2686" d="M5.01,35.342c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1078.97)" fill="#ceb9b2"/>
                    <path id="Caminho_2687" data-name="Caminho 2687" d="M5.01,35.877c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1081.441)" fill="#ceb9b2"/>
                    <path id="Caminho_2688" data-name="Caminho 2688" d="M5.01,36.246c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.146)" fill="#ceb9b2"/>
                    <path id="Caminho_2689" data-name="Caminho 2689" d="M5.01,36.421c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.954)" fill="#ceb9b2"/>
                    <path id="Caminho_2690" data-name="Caminho 2690" d="M5.01,36.821c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1085.802)" fill="#ceb9b2"/>
                    <path id="Caminho_2691" data-name="Caminho 2691" d="M5.01,33.809c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1071.888)" fill="#ceb9b2"/>
                    <path id="Caminho_2692" data-name="Caminho 2692" d="M305.692,44.073c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,32.564,55.076,31.221,5.01,34.935c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1066.446)" fill="#ceb9b2"/>
                    <path id="Caminho_2693" data-name="Caminho 2693" d="M305.692,43.31c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.8,55.076,30.458,5.01,34.172c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1062.921)" fill="#ceb9b2"/>
                    <path id="Caminho_2694" data-name="Caminho 2694" d="M305.692,42.776c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.267,55.076,29.924,5.01,33.638c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1060.454)" fill="#ceb9b2"/>
                    <path id="Caminho_2695" data-name="Caminho 2695" d="M305.692,42.407c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.9,55.076,29.555,5.01,33.269c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1058.749)" fill="#ceb9b2"/>
                    <path id="Caminho_2696" data-name="Caminho 2696" d="M305.692,42.231c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.722,55.076,29.379,5.01,33.093c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1057.936)" fill="#ceb9b2"/>
                    <path id="Caminho_2697" data-name="Caminho 2697" d="M305.692,41.832c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.323,55.076,28.98,5.01,32.694c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1056.093)" fill="#ceb9b2"/>
                    <path id="Caminho_2698" data-name="Caminho 2698" d="M305.692,44.844c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,33.335,55.076,31.992,5.01,35.706c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1070.007)" fill="#ceb9b2"/>
                    <path id="Caminho_2699" data-name="Caminho 2699" d="M5.01,12.359C55.171,13,105.243,16.186,155.331,18.6c50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.22,55.171,13.017,5.01,12.359" transform="translate(444.436 972.795)" fill="#ceb9b2"/>
                    <path id="Caminho_2700" data-name="Caminho 2700" d="M5.01,12.875c50.161.641,100.233,3.827,150.321,6.238,50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.736,55.171,13.533,5.01,12.875" transform="translate(444.436 975.179)" fill="#ceb9b2"/>
                    <path id="Caminho_2701" data-name="Caminho 2701" d="M5.01,15.379l46.256-2.107v.056L5.01,13.3l46.256-.028.006.056Z" transform="translate(444.435 977.013)" fill="#ceb9b2"/>
                    <path id="Caminho_2702" data-name="Caminho 2702" d="M5.01,23.062l46.256-.028v.056L5.01,20.983l46.261,2.051-.006.056Z" transform="translate(444.435 1012.636)" fill="#ceb9b2"/>
                    <path id="Caminho_2703" data-name="Caminho 2703" d="M96.544,14.481,50.288,16.588v-.056l46.256.028-46.256.028-.006-.056Z" transform="translate(653.582 982.598)" fill="#ceb9b2"/>
                    <path id="Caminho_2704" data-name="Caminho 2704" d="M5.01,20.449c100.121,5.963,200.354,10.992,300.682,11.228C205.364,31.5,105.131,26.479,5.01,20.449" transform="translate(444.436 1010.169)" fill="#ceb9b2"/>
                    <path id="Caminho_2705" data-name="Caminho 2705" d="M29.805,30.174c26.885,1.2,53.747,3.085,80.66,3.54,26.913.539,53.831-.455,80.676-2.315-26.845,1.883-53.764,2.894-80.676,2.372-26.918-.472-53.775-2.383-80.66-3.6" transform="translate(558.981 1055.095)" fill="#ceb9b2"/>
                    <g id="Grupo_14" data-name="Grupo 14" transform="translate(449.383 943.802)">
                      <g id="Grupo_13" data-name="Grupo 13" clip-path="url(#clip-path)">
                        <image id="Retângulo_1677" data-name="Retângulo 1677" width="303.464" height="191.07" transform="translate(0.006 -0.815)" />
                      </g>
                    </g>
                  </g>
              </svg>}
              {newShape.type === 'laptop' && 
                (<svg  x={newShape.x} y={newShape.y} width={30} height={30}  style={{ cursor: 'pointer',transform: `rotate(${rotationAngle}deg)` }}
                          viewBox="0 0 512 512">
                    <path style={{fill:"#C9C6CA"}} d="M437.697,462.474H74.303c-13.684,0-24.777-11.093-24.777-24.777V181.669h412.948v256.028
                      C462.474,451.381,451.381,462.474,437.697,462.474z"/>
                    <path style={{fill:"#6F6571"}} d="M496.285,49.526H15.715c-11.32,0-18.919,11.618-14.382,21.988l48.193,110.155h412.948l48.193-110.155
                      C515.204,61.144,507.605,49.526,496.285,49.526z"/>
                    <path style={{fill:"#5D5360"}} d="M388.143,346.849H123.857c-4.561,0-8.259-3.698-8.259-8.259V222.964c0-4.561,3.698-8.259,8.259-8.259
                      h264.287c4.561,0,8.259,3.698,8.259,8.259V338.59C396.402,343.151,392.705,346.849,388.143,346.849z"/>
                    <path style={{fill:"#DBD9DC"}} d="M189.928,346.849v66.072c0,9.122,7.395,16.518,16.518,16.518h99.107
                      c9.122,0,16.518-7.396,16.518-16.518v-66.072H189.928z"/>
                    <polygon style={{fill:"#5D5360"}} points="41.267,82.562 82.562,181.669 429.438,181.669 470.733,82.562 "/>
                    <g>
                      <path style={{fill:"#6F6571"}} d="M421.179,198.187H90.821c-4.561,0-8.259-3.698-8.259-8.259v-8.259h346.876v8.259
                        C429.438,194.49,425.74,198.187,421.179,198.187z"/>
                      <rect x="115.6" y="239.48" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                      <rect x="115.6" y="272.52" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                      <rect x="115.6" y="305.55" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                    </g>
                    <g>
                      <path style={{fill:"#B7B2B8"}} d="M66.044,363.366V181.669H49.526v256.028c0,13.684,11.093,24.777,24.777,24.777h363.394
                        c10.759,0,19.951-6.932,23.366-16.518H148.634C103.021,445.956,66.044,408.979,66.044,363.366z"/>
                      <path style={{fill:"#B7B2B8"}} d="M461.267,444.925c0.013-0.043,0.036-0.083,0.049-0.126
                        C461.173,444.841,461.165,444.883,461.267,444.925z"/>
                    </g>

                  </svg>)}

              {newShape.type === 'tv' && 
                (<svg   x={newShape.x} y={newShape.y} width={50} height={50} style={{ cursor: 'pointer',transform: `rotate(${rotationAngle}deg)` }}
                  viewBox="0 0 512 512" >
                    <path style={{fill:"#6F6571"}} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-99.097
                      c0-13.682,11.092-24.774,24.774-24.774h132.129c13.682,0,24.774,11.092,24.774,24.774v99.097
                      C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                    <path style={{fill:"#867E88"}} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-57.806h181.677v57.806
                      C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                    <path style={{fill:"#5D5360"}} d="M433.848,226.676c-13.725-7.843-29.26-11.968-45.069-11.968H123.22
                      c-15.808,0-31.343,4.125-45.069,11.968L41.29,247.742h429.42L433.848,226.676z"/>
                    <path style={{fill:"#6F6571"}} d="M503.742,280.773H8.258c-4.561,0-8.258-3.697-8.258-8.258v-16.516c0-4.561,3.697-8.258,8.258-8.258
                      h495.484c4.561,0,8.258,3.697,8.258,8.258v16.516C512,277.076,508.303,280.773,503.742,280.773z"/>
                    <path style={{fill:"#5D5360"}} d="M512,264.258v-8.258c0-4.56-3.697-8.258-8.258-8.258H8.258c-4.561,0-8.258,3.698-8.258,8.258v8.258
                      H512z"/>
                    <path style={{fill:"#4B3F4E"}} d="M161.031,214.71L161.031,214.71h-37.816c-15.807,0-31.34,4.125-45.064,11.968L41.29,247.742h429.42
                      l-28.902-16.516H169.29C160.783,231.225,155.928,221.514,161.031,214.71z"/>
                
                  </svg>)}
            </Grid>
          </Grid>
        }
        
      </Box>

      <svg ref={minhaRef} width="100%" height="600" style={{ border: "1px solid #01010155" }} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
        {Array.from({ length: 40 }, (_, i) => (
          <line
            key={`row-${i}`}
            x1="0%"
            y1={`${(i / 40) * 100}%`}
            x2="100%"
            y2={`${(i / 40) * 100}%`}
            stroke="#ccc"
          />
        ))}
        {Array.from({ length: 40 }, (_, i) => (
          <line
            key={`col-${i}`}
            x1={`${(i / 40) * 100}%`}
            y1="0%"
            x2={`${(i / 40) * 100}%`}
            y2="100%"
            stroke="#ccc"
          />
        ))}
      
        {shapes.map((shape, index) => {
          switch (shape.type) {
            case 'rectangle':
              return  (
                <g className={style.rectangleGroup} key={shape.id} onMouseDown={(event) => handleMouseDown(event, shape.id)}  style={{ cursor: 'pointer'}} >
                  <rect
                    x={shape.x}
                    y={shape.y}
                    stroke={selectedShape?.id === index ? 'red' : shape.color}
                    strokeWidth="2"
                    cursor={selectedShape?.id === index ? 'move' : 'pointer'}
                    width={shape.width}
                    height={shape.height}
                    fill={shape.color}
                    style={{ cursor: 'pointer' }}
                    
                    className={style.rectangle}
                  // onClick={() => shape.room_id && history.push(`/rooms/reservation/${shape.room_id}`)}
                  />
                  <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                    {shape.name}
                  </text>
                  <foreignObject onClick={() => handleDeleteShape(shape)} x={shape.x - 5} y={shape.y - 25} width="24" height="24" className={style.deleteIcon}>
                    <Delete color='error' />
                  </foreignObject>
                  <foreignObject onClick={(e) => { handleClickLinked(e, shape) }}
                    x={shape.x + 20} y={shape.y - 25} width="24" height="24" className={style.deleteIcon}>
                    <Edit color='primary' />
                  </foreignObject>
                </g>
              )
            case 'circle':
              return (
                <g className={style.rectangleGroup} key={shape.id} onMouseDown={(event) => handleMouseDown(event, shape.id)}>
                  <circle
                    cx={shape.x + shape.width / 2}
                    cy={shape.y + shape.height / 2}
                    r={Math.min(shape.width, shape.height) / 2}
                    stroke={selectedShape?.id === index ? 'red' : shape.color}
                    strokeWidth="2"
                    cursor={selectedShape?.id === index ? 'move' : 'pointer'}
                    fill={shape.color}
                    style={{ cursor: 'pointer' }}
                    
                    // onClick={() => setSelectedShape(shape)}
                    className={style.rectangle}
                  />
                  <text x={shape.x + shape.width / 2} y={shape.y + shape.height / 2} width={shape.width} dominantBaseline="middle" textAnchor="middle" fill="white" lengthAdjust="spacingAndGlyphs">
                    {shape.name}
                  </text>
                  <foreignObject onClick={() => handleDeleteShape(shape)} x={shape.x - 5} y={shape.y - 25} width="24" height="24" className={style.deleteIcon}>
                    <Delete color='error' />
                  </foreignObject>
                  <foreignObject onClick={(e) => { handleClickLinked(e, shape) }}
                    x={shape.x + 25} y={shape.y - 25} width="24" height="24" className={style.deleteIcon}>
                    <Edit color='primary' />
                  </foreignObject>
                </g>
              )

            case 'chair':
              return (
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x={shape.x} y={shape.y} onMouseDown={(event) => handleMouseDown(event, shape.id)} style={{ cursor: 'pointer', transform: `rotate(180deg)`}} 
              viewBox="0 0 600 600" width={50} height={50} >   
              
              <g transform={`rotate(${shape.angle} 250 250)`}  onDoubleClick={() => setShapeToEdit(shape)}>
              
              <rect x="239.48" y="66.06" style={{fill:"#5D5360"}} width="33.03" height="74.32"/>
              <rect x="239.48" y="115.61" style={{fill:"#5D5360"}} width="33.03" height="33.03"/>
              <rect x="24.774" y="231.23" style={{fill:"#5D5360"}}width="462.45" height="33.03"/>
              <rect x="66.06" y="231.23" style={{fill:"#5D5360"}} width="379.87" height="33.03"/>
              <g>
                <path style={{fill:"#B67F5F"}} d="M82.581,181.678v256.454c0,32.779,24.022,60.476,56.431,65.388C175.042,508.981,214.549,512,256,512
                  s80.958-3.019,116.989-8.48c32.409-4.912,56.431-32.609,56.431-65.388V181.678c0-27.365-22.184-49.548-49.548-49.548H132.129
                  C104.764,132.129,82.581,154.313,82.581,181.678z"/>
                <path style={{fill:"#B67F5F"}} d="M421.219,26.852C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                  c-12.887,4.535-19.608,18.514-15.012,31.232c3.609,10.001,13.149,16.231,23.333,16.233l313.795,0.006
                  c10.177,0,19.721-6.242,23.334-16.239C440.828,45.366,434.106,31.387,421.219,26.852z"/>
              </g>
              <path style={{fill:"#5D5360"}}d="M20.645,404.645L20.645,404.645C9.243,404.645,0,395.402,0,384V194.064
                c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C41.29,395.402,32.047,404.645,20.645,404.645z"
                />
              <path style={{fill:"#6F6571"}} d="M24.774,173.836v230.393C34.198,402.316,41.29,393.987,41.29,384V194.065
                C41.29,184.077,34.198,175.749,24.774,173.836z"/>
              <path style={{fill:"#5D5360"}}d="M491.355,404.645L491.355,404.645c-11.402,0-20.645-9.243-20.645-20.645V194.064
                c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C512,395.402,502.757,404.645,491.355,404.645z"
                />
              <path style={{fill:"#6F6571"}} d="M495.484,173.836v230.393C504.907,402.316,512,393.987,512,384V194.065
                C512,184.077,504.907,175.749,495.484,173.836z"/>
              <path style={{fill:"#A06E50"}} d="M115.613,297.29c0-77.534,62.853-140.387,140.387-140.387h166.788
                c-8.571-14.795-24.588-24.774-42.917-24.774H132.129c-27.365,0-49.548,22.184-49.548,49.548v256.454
                c0,24.209,13.125,45.657,33.032,57.19C115.613,495.321,115.613,297.29,115.613,297.29z"/>
              <path style={{fill:"#CD916E"}} d="M77.837,62.326c2.841-4.78,7.239-8.693,12.944-10.7C140.718,34.059,197.849,24.774,256,24.774
                s115.282,9.285,165.219,26.852c5.703,2.007,10.101,5.919,12.943,10.698c0.8-1.333,1.522-2.727,2.069-4.24
                c4.597-12.718-2.125-26.697-15.012-31.232C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                c-12.887,4.535-19.608,18.514-15.012,31.232C76.316,59.598,77.037,60.993,77.837,62.326z"/> 
                {/* <foreignObject onClick={() => handleDeleteShape(shape)} x={20} y={20} width="240" height="240" className={style.deleteIcon}>
                <Delete color='error' />
              </foreignObject>
              <foreignObject onClick={(e) => { handleClickLinked(e, shape) }}
                x={10} y={10} width="100" height="100" className={style.deleteIcon}>
                <Edit color='primary' />
              </foreignObject>    */}  
              </g>
              
            </svg>

              )
            case 'desk':
              return (
                <svg  width={240.745} height={152.278} viewBox="0 0 300.745 190.278" x={shape.x} y={shape.y} onMouseDown={(event) => handleMouseDown(event, shape.id)} style={{ cursor: 'pointer'}} >
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Retângulo_1678" data-name="Retângulo 1678" width="300.738" height="152.255" fill="none"/>
                  </clipPath>
                </defs>
                <g id="mesa" transform="translate(-449.383 -943.788)">
                  <rect id="Retângulo_1669" data-name="Retângulo 1669" width="300.738" height="23.783" transform="translate(449.389 1110.274)" fill="#a89d90"/>
                  <rect id="Retângulo_1670" data-name="Retângulo 1670" width="300.738" height="23.783" transform="translate(449.389 1086.491)" fill="#999085"/>
                  <rect id="Retângulo_1671" data-name="Retângulo 1671" width="300.738" height="23.783" transform="translate(449.389 1062.709)" fill="#978c7f"/>
                  <rect id="Retângulo_1672" data-name="Retângulo 1672" width="300.738" height="23.783" transform="translate(449.389 1038.926)" fill="#a49989"/>
                  <rect id="Retângulo_1673" data-name="Retângulo 1673" width="300.738" height="23.783" transform="translate(449.389 1015.144)" fill="#999085"/>
                  <rect id="Retângulo_1674" data-name="Retângulo 1674" width="300.738" height="23.783" transform="translate(449.389 991.361)" fill="#a89d90"/>
                  <rect id="Retângulo_1675" data-name="Retângulo 1675" width="300.738" height="23.783" transform="translate(449.389 967.579)" fill="#a49989"/>
                  <rect id="Retângulo_1676" data-name="Retângulo 1676" width="300.738" height="23.783" transform="translate(449.389 943.796)" fill="#978c7f"/>
                  <path id="Caminho_2614" data-name="Caminho 2614" d="M305.692,17.255C205.4,23.071,105.2,13.225,5.01,10.129,105.2,13.164,205.4,23.009,305.692,17.255" transform="translate(444.436 962.493)" fill="#ceb9b2"/>
                  <path id="Caminho_2615" data-name="Caminho 2615" d="M5.01,10.723C105.2,13.763,205.4,23.6,305.692,17.849,205.4,23.665,105.2,13.825,5.01,10.723" transform="translate(444.436 965.237)" fill="#ceb9b2"/>
                  <path id="Caminho_2616" data-name="Caminho 2616" d="M5.01,11.14C105.2,14.18,205.4,24.02,305.692,18.266,205.4,24.082,105.2,14.242,5.01,11.14" transform="translate(444.436 967.164)" fill="#ceb9b2"/>
                  <path id="Caminho_2617" data-name="Caminho 2617" d="M5.01,11.428C105.2,14.468,205.4,24.308,305.692,18.554,205.4,24.37,105.2,14.53,5.01,11.428" transform="translate(444.436 968.494)" fill="#ceb9b2"/>
                  <path id="Caminho_2618" data-name="Caminho 2618" d="M5.01,11.565C105.2,14.605,205.4,24.445,305.692,18.691,205.4,24.507,105.2,14.667,5.01,11.565" transform="translate(444.436 969.127)" fill="#ceb9b2"/>
                  <path id="Caminho_2619" data-name="Caminho 2619" d="M5.01,11.877C105.2,14.917,205.4,24.757,305.692,19,205.4,24.819,105.2,14.979,5.01,11.877" transform="translate(444.436 970.568)" fill="#ceb9b2"/>
                  <path id="Caminho_2620" data-name="Caminho 2620" d="M5.01,9.527C105.2,12.567,205.4,22.407,305.692,16.653,205.4,22.469,105.2,12.629,5.01,9.527" transform="translate(444.436 959.712)" fill="#ceb9b2"/>
                  <path id="Caminho_2621" data-name="Caminho 2621" d="M5.01,13.448c13.01.427,26.025.152,39.029-.214,6.508-.2,13.01-.461,19.506-.815l9.739-.624c3.186-.354,6.6-.315,9.565-1.366C80,9.407,76.481,9.6,73.317,9.323L63.562,8.98,44.05,8.632,5.01,8.452,44.05,8.6l19.517.332,9.75.337,4.872.292c1.568.23,3.372.039,4.715.877-3.057,1.141-6.412,1.028-9.621,1.4l-9.739.618q-9.745.514-19.5.8c-13.01.36-26.025.618-39.034.185" transform="translate(444.436 954.746)" fill="#ceb9b2"/>
                  <path id="Caminho_2622" data-name="Caminho 2622" d="M5.01,11.195l22.636-.483c7.519-.292,15.156-.27,22.563-1.09-7.379-.714-15.044-.551-22.557-.731L5.01,8.694l22.642.169c7.536.191,15.123-.051,22.614.753-7.514.933-15.078.815-22.614,1.124Z" transform="translate(444.436 955.864)" fill="#ceb9b2"/>
                  <path id="Caminho_2623" data-name="Caminho 2623" d="M5.01,15.055Q34.7,15.831,64.4,15.3c9.9-.169,19.793-.438,29.683-.865,4.945-.219,9.885-.483,14.825-.826l7.4-.629c2.394-.365,5.063-.326,7.171-1.433-2.006-1.146-4.76-1-7.137-1.326l-7.407-.489L94.093,9.154,64.4,8.592c-19.8-.23-39.6-.264-59.395-.23,19.8-.039,39.6-.017,59.395.2l29.689.545,14.842.568,7.407.483,3.692.377c1.169.264,2.591.129,3.5,1.017-2.192,1.191-4.81,1.1-7.221,1.472l-7.4.629c-4.934.343-9.879.6-14.825.815-9.891.427-19.787.691-29.683.854q-29.689.48-59.389-.27" transform="translate(444.436 954.257)" fill="#ceb9b2"/>
                  <path id="Caminho_2624" data-name="Caminho 2624" d="M5.01,18.135c35.05.191,70.1.27,105.15.034,17.522-.124,35.044-.32,52.567-.708,8.761-.2,17.517-.438,26.272-.809,4.378-.185,8.755-.4,13.122-.7l6.547-.562,3.259-.421c.989-.247,2.36-.236,2.945-1.023-1.742-1.146-4.164-1.124-6.26-1.506l-6.536-.68c-4.361-.382-8.733-.674-13.105-.938-8.75-.517-17.505-.9-26.261-1.219-17.511-.635-35.033-1.023-52.555-1.281Q57.588,7.567,5.01,8.379q52.567-.784,105.145-.09c17.522.253,35.044.635,52.561,1.27,8.755.32,17.511.7,26.255,1.208,4.372.264,8.744.556,13.111.938l6.536.674c2.113.4,4.49.32,6.311,1.551-.652.916-1.995.815-3,1.085l-3.259.427L202.127,16c-4.372.3-8.75.517-13.122.7-8.755.365-17.517.607-26.278.8-17.522.382-35.044.573-52.567.691-35.05.225-70.1.14-105.15-.062" transform="translate(444.436 952.441)" fill="#ceb9b2"/>
                  <path id="Caminho_2625" data-name="Caminho 2625" d="M5.01,15.781c27.458.208,54.916.292,82.374.1,13.729-.1,27.458-.27,41.187-.6l20.579-.68,10.284-.579,5.125-.455c1.63-.275,3.58-.208,4.884-1.113-3.007-1.332-6.682-1.208-10.026-1.624l-10.273-.658c-6.856-.354-13.718-.6-20.579-.815-13.723-.41-27.452-.646-41.181-.8-27.458-.3-54.916-.27-82.374-.051,27.458-.23,54.916-.27,82.374.022,13.729.152,27.458.382,41.181.792,6.862.208,13.723.455,20.579.809l10.278.652,5.125.489c1.652.3,3.524.2,4.951,1.18-1.388,1.023-3.288.871-4.934,1.175l-5.131.45-10.278.579-20.585.674c-13.723.326-27.452.483-41.187.584C59.926,16.1,32.468,16,5.01,15.781" transform="translate(444.436 954.132)" fill="#ceb9b2"/>
                  <path id="Caminho_2626" data-name="Caminho 2626" d="M305.692,15.677C205.5,12.636,105.3,2.8,5.01,8.551,105.3,2.735,205.5,12.575,305.692,15.677" transform="translate(444.436 946.869)" fill="#ceb9b2"/>
                  <path id="Caminho_2627" data-name="Caminho 2627" d="M305.692,15.082C205.5,12.041,105.3,2.2,5.01,7.956,105.3,2.14,205.5,11.98,305.692,15.082" transform="translate(444.436 944.121)" fill="#ceb9b2"/>
                  <path id="Caminho_2628" data-name="Caminho 2628" d="M305.692,14.66C205.5,11.625,105.3,1.779,5.01,7.54,105.3,1.723,205.5,11.558,305.692,14.66" transform="translate(444.436 942.194)" fill="#ceb9b2"/>
                  <path id="Caminho_2629" data-name="Caminho 2629" d="M305.692,14.377C205.5,11.336,105.3,1.5,5.01,7.251,105.3,1.435,205.5,11.275,305.692,14.377" transform="translate(444.436 940.864)" fill="#ceb9b2"/>
                  <path id="Caminho_2630" data-name="Caminho 2630" d="M305.692,14.24C205.5,11.2,105.3,1.359,5.01,7.114,105.3,1.3,205.5,11.138,305.692,14.24" transform="translate(444.436 940.231)" fill="#ceb9b2"/>
                  <path id="Caminho_2631" data-name="Caminho 2631" d="M305.692,13.928C205.5,10.887,105.3,1.047,5.01,6.8,105.3.986,205.5,10.826,305.692,13.928" transform="translate(444.436 938.79)" fill="#ceb9b2"/>
                  <path id="Caminho_2632" data-name="Caminho 2632" d="M305.692,16.278C205.5,13.237,105.3,3.4,5.01,9.152,105.3,3.336,205.5,13.176,305.692,16.278" transform="translate(444.436 949.646)" fill="#ceb9b2"/>
                  <path id="Caminho_2633" data-name="Caminho 2633" d="M178.327,10.294c-24.266-2.062-48.667-1.124-72.932.427C81.112,12.294,56.885,14.7,32.58,16.009l.006-.056c24.243,1.725,48.565,2.248,72.871,2.315,24.305-.022,48.627-.495,72.871-2.282-24.243,1.8-48.565,2.282-72.871,2.31-24.305-.062-48.627-.573-72.876-2.287v-.056c24.3-1.3,48.526-3.7,72.814-5.26,24.266-1.545,48.667-2.467,72.932-.4" transform="translate(571.801 957.715)" fill="#ceb9b2"/>
                  <path id="Caminho_2634" data-name="Caminho 2634" d="M154.683,10.179c-19.489-1.158-39.029-.607-58.518.253C76.671,11.309,57.2,12.646,37.7,13.4v-.056c19.483.972,38.989,1.259,58.49,1.3,19.506-.011,39.018-.27,58.5-1.27-19.478,1.012-38.989,1.276-58.5,1.3-19.5-.034-39.012-.315-58.49-1.276v-.056C57.2,12.6,76.671,11.27,96.165,10.4c19.489-.849,39.029-1.388,58.518-.225" transform="translate(595.444 959.639)" fill="#ceb9b2"/>
                  <path id="Caminho_2635" data-name="Caminho 2635" d="M129.173,10.052c-28.66-.882-57.3.725-85.948,1.337v-.056l85.948.028-85.953.028v-.056c28.649-.6,57.3-2.18,85.953-1.281" transform="translate(620.954 960.89)" fill="#ceb9b2"/>
                  <path id="Caminho_2636" data-name="Caminho 2636" d="M33.719,5c11.593.888,23.215,1.349,34.82,1.978L103.381,8.67l69.679,3.31L103.375,8.726,68.539,7.006C56.934,6.371,45.312,5.9,33.719,5" transform="translate(577.063 938.799)" fill="#ceb9b2"/>
                  <path id="Caminho_2637" data-name="Caminho 2637" d="M41.151,5l97.575,5.176Z" transform="translate(611.396 938.799)" fill="#ceb9b2"/>
                  <path id="Caminho_2638" data-name="Caminho 2638" d="M101.081,8.49,49.3,5Z" transform="translate(649.047 938.799)" fill="#ceb9b2"/>
                  <path id="Caminho_2639" data-name="Caminho 2639" d="M68.743,6.748,56.3,5Z" transform="translate(681.385 938.799)" fill="#ceb9b2"/>
                  <path id="Caminho_2640" data-name="Caminho 2640" d="M155.348,45.4c-12.509-.967-25.047-1.512-37.573-2.214L80.19,41.243l-75.18-3.8,75.18,3.748,37.59,1.973c12.521.714,25.058,1.264,37.568,2.242" transform="translate(444.436 1088.653)" fill="#ceb9b2"/>
                  <path id="Caminho_2641" data-name="Caminho 2641" d="M5.01,37.76l100.447,6.154Z" transform="translate(444.436 1090.14)" fill="#ceb9b2"/>
                  <path id="Caminho_2642" data-name="Caminho 2642" d="M5.01,38.06l54.106,4.473Z" transform="translate(444.436 1091.526)" fill="#ceb9b2"/>
                  <path id="Caminho_2643" data-name="Caminho 2643" d="M5.01,38.37,21.667,41.1Z" transform="translate(444.436 1092.958)" fill="#ceb9b2"/>
                  <path id="Caminho_2644" data-name="Caminho 2644" d="M305.692,32.386C205.5,29.345,105.3,19.505,5.01,25.26,105.3,19.444,205.5,29.284,305.692,32.386" transform="translate(444.436 1024.06)" fill="#ceb9b2"/>
                  <path id="Caminho_2645" data-name="Caminho 2645" d="M305.692,31.791C205.5,28.75,105.3,18.91,5.01,24.665,105.3,18.849,205.5,28.689,305.692,31.791" transform="translate(444.436 1021.311)" fill="#ceb9b2"/>
                  <path id="Caminho_2646" data-name="Caminho 2646" d="M305.692,31.374C205.5,28.333,105.3,18.493,5.01,24.248,105.3,18.432,205.5,28.272,305.692,31.374" transform="translate(444.436 1019.385)" fill="#ceb9b2"/>
                  <path id="Caminho_2647" data-name="Caminho 2647" d="M305.692,31.085C205.5,28.051,105.3,18.2,5.01,23.959,105.3,18.143,205.5,27.989,305.692,31.085" transform="translate(444.436 1018.055)" fill="#ceb9b2"/>
                  <path id="Caminho_2648" data-name="Caminho 2648" d="M305.692,30.946C205.5,27.912,105.3,18.066,5.01,23.821,105.3,18.01,205.5,27.85,305.692,30.946" transform="translate(444.436 1017.424)" fill="#ceb9b2"/>
                  <path id="Caminho_2649" data-name="Caminho 2649" d="M305.692,30.638C205.5,27.6,105.3,17.757,5.01,23.512,105.3,17.7,205.5,27.536,305.692,30.638" transform="translate(444.436 1015.985)" fill="#ceb9b2"/>
                  <path id="Caminho_2650" data-name="Caminho 2650" d="M305.692,32.984C205.5,29.95,105.3,20.1,5.01,25.859,105.3,20.048,205.5,29.888,305.692,32.984" transform="translate(444.436 1026.839)" fill="#ceb9b2"/>
                  <path id="Caminho_2651" data-name="Caminho 2651" d="M122.549,26.019c-13.01-.427-26.025-.152-39.029.214-6.508.2-13.01.461-19.506.815l-9.739.624c-3.186.354-6.6.315-9.565,1.366,2.849,1.023,6.367.832,9.531,1.107L64,30.487l19.512.348,39.04.18-39.04-.152-19.517-.332-9.75-.337L49.369,29.9c-1.568-.23-3.372-.039-4.715-.877,3.057-1.141,6.412-1.028,9.621-1.4L64.014,27q9.745-.514,19.5-.8c13.01-.36,26.025-.618,39.034-.185" transform="translate(627.579 1034.855)" fill="#ceb9b2"/>
                  <path id="Caminho_2652" data-name="Caminho 2652" d="M133.391,31.4l-91.079-.258V31.1a529.748,529.748,0,0,0,91.079,2.81,529.812,529.812,0,0,1-91.084-2.759l.006-.051Z" transform="translate(616.736 1059.35)" fill="#ceb9b2"/>
                  <path id="Caminho_2653" data-name="Caminho 2653" d="M95.717,26.035l-22.636.483c-7.519.292-15.156.27-22.563,1.09,7.379.714,15.044.551,22.557.731l22.642.2-22.642-.169c-7.536-.191-15.123.051-22.614-.753,7.514-.933,15.078-.815,22.614-1.124Z" transform="translate(654.41 1035.974)" fill="#ceb9b2"/>
                  <path id="Caminho_2654" data-name="Caminho 2654" d="M155.945,26.016q-29.689-.776-59.389-.242c-9.9.169-19.793.438-29.683.865-4.945.219-9.885.483-14.825.826l-7.4.629c-2.394.365-5.063.326-7.171,1.433,2.006,1.146,4.76,1,7.137,1.326l7.407.489,14.836.573,29.689.562c19.8.23,39.6.258,59.395.23-19.8.039-39.6.017-59.395-.2l-29.689-.545-14.842-.568-7.407-.483-3.692-.377c-1.169-.264-2.591-.129-3.5-1.017,2.192-1.191,4.8-1.1,7.221-1.472l7.4-.629c4.94-.343,9.885-.6,14.83-.815,9.891-.427,19.787-.691,29.683-.854q29.689-.48,59.389.27" transform="translate(594.184 1033.735)" fill="#ceb9b2"/>
                  <path id="Caminho_2655" data-name="Caminho 2655" d="M231.079,25.57c-35.05-.191-70.1-.27-105.15-.034-17.522.124-35.044.32-52.567.708-8.761.2-17.517.438-26.272.809-4.378.185-8.755.4-13.122.7l-6.547.562-3.259.421c-.989.247-2.36.23-2.945,1.023,1.742,1.146,4.164,1.124,6.26,1.506l6.536.68c4.361.382,8.733.674,13.105.938,8.75.517,17.505.9,26.261,1.219,17.511.635,35.033,1.023,52.555,1.281q52.567.75,105.145-.062-52.567.784-105.145.09c-17.522-.253-35.044-.635-52.561-1.27-8.756-.32-17.511-.7-26.255-1.208-4.372-.264-8.744-.556-13.111-.938l-6.536-.68c-2.113-.4-4.49-.315-6.311-1.545.652-.916,2-.815,3-1.085l3.259-.427,6.547-.556c4.372-.3,8.75-.517,13.122-.7,8.756-.365,17.517-.607,26.278-.8,17.522-.382,35.044-.573,52.567-.691,35.05-.225,70.1-.141,105.15.062" transform="translate(519.049 1032.922)" fill="#ceb9b2"/>
                  <path id="Caminho_2656" data-name="Caminho 2656" d="M193.734,25.714c-27.458-.208-54.916-.292-82.374-.1-13.729.1-27.458.27-41.187.6l-20.579.68-10.284.579-5.125.455c-1.63.275-3.58.208-4.884,1.113,3.007,1.332,6.682,1.208,10.026,1.624l10.273.658c6.856.354,13.718.6,20.579.815,13.723.41,27.452.646,41.181.8,27.458.3,54.916.27,82.374.051-27.458.23-54.916.27-82.374-.022-13.729-.152-27.458-.382-41.181-.792-6.862-.208-13.723-.455-20.579-.809l-10.278-.652L34.2,30.227c-1.652-.3-3.524-.2-4.951-1.18,1.388-1.023,3.288-.871,4.934-1.175l5.131-.45,10.278-.579,20.585-.674c13.723-.326,27.452-.483,41.181-.584,27.464-.185,54.921-.09,82.379.129" transform="translate(556.395 1033.44)" fill="#ceb9b2"/>
                  <path id="Caminho_2657" data-name="Caminho 2657" d="M5.01,26.838C105.2,29.878,205.4,39.718,305.692,33.964,205.4,39.78,105.2,29.94,5.01,26.838" transform="translate(444.436 1039.684)" fill="#ceb9b2"/>
                  <path id="Caminho_2658" data-name="Caminho 2658" d="M5.01,27.433C105.2,30.473,205.4,40.313,305.692,34.559,205.4,40.375,105.2,30.535,5.01,27.433" transform="translate(444.436 1042.433)" fill="#ceb9b2"/>
                  <path id="Caminho_2659" data-name="Caminho 2659" d="M5.01,27.85C105.2,30.89,205.4,40.73,305.692,34.976,205.4,40.792,105.2,30.952,5.01,27.85" transform="translate(444.436 1044.359)" fill="#ceb9b2"/>
                  <path id="Caminho_2660" data-name="Caminho 2660" d="M5.01,28.138C105.2,31.178,205.4,41.018,305.692,35.264,205.4,41.08,105.2,31.24,5.01,28.138" transform="translate(444.436 1045.689)" fill="#ceb9b2"/>
                  <path id="Caminho_2661" data-name="Caminho 2661" d="M5.01,28.275C105.2,31.315,205.4,41.155,305.692,35.4,205.4,41.217,105.2,31.377,5.01,28.275" transform="translate(444.436 1046.322)" fill="#ceb9b2"/>
                  <path id="Caminho_2662" data-name="Caminho 2662" d="M5.01,28.587C105.2,31.627,205.4,41.467,305.692,35.713,205.4,41.529,105.2,31.689,5.01,28.587" transform="translate(444.436 1047.764)" fill="#ceb9b2"/>
                  <path id="Caminho_2663" data-name="Caminho 2663" d="M5.01,26.237C105.2,29.277,205.4,39.117,305.692,33.363,205.4,39.179,105.2,29.339,5.01,26.237" transform="translate(444.436 1036.907)" fill="#ceb9b2"/>
                  <path id="Caminho_2664" data-name="Caminho 2664" d="M5.01,32.444c24.266,2.062,48.667,1.124,72.932-.427,24.283-1.574,48.509-3.979,72.809-5.288v.056c-24.243-1.725-48.565-2.248-72.871-2.315-24.305.022-48.627.495-72.871,2.282,24.243-1.8,48.565-2.282,72.871-2.31,24.305.062,48.627.573,72.876,2.287v.056c-24.3,1.3-48.526,3.7-72.814,5.26-24.266,1.545-48.667,2.467-72.932.4" transform="translate(444.436 1028.615)" fill="#ceb9b2"/>
                  <path id="Caminho_2665" data-name="Caminho 2665" d="M5.01,29.24c19.489,1.158,39.029.607,58.518-.253C83.023,28.11,102.495,26.773,122,26.02v.056c-19.484-.972-38.989-1.259-58.5-1.3-19.5.011-39.012.27-58.49,1.27C24.488,25.031,44,24.766,63.5,24.744c19.506.034,39.018.315,58.5,1.276v.056c-19.5.742-38.973,2.074-58.467,2.939C44.039,29.864,24.5,30.4,5.01,29.24" transform="translate(444.436 1030.01)" fill="#ceb9b2"/>
                  <path id="Caminho_2666" data-name="Caminho 2666" d="M5.01,26.443c28.655.882,57.3-.725,85.948-1.337v.056L5.01,25.134l85.953-.028v.056c-28.649.6-57.3,2.18-85.953,1.281" transform="translate(444.436 1031.682)" fill="#ceb9b2"/>
                  <path id="Caminho_2667" data-name="Caminho 2667" d="M102.59,35.912l21.439.23,10.717-.022,2.675-.067,1.337-.084c.191-.034.517-.022.584-.146a2.672,2.672,0,0,0-.641-.157c-1.77-.242-3.552-.36-5.333-.495-14.263-.967-28.542-1.59-42.817-2.276L47.72,30.961C33.5,30.292,19.048,29.809,5.01,28.932c14.038.865,28.486,1.337,42.715,2l42.833,1.905c14.274.7,28.554,1.332,42.811,2.3,1.781.135,3.563.253,5.339.5a2.412,2.412,0,0,1,.652.163c-.084.2-.4.135-.6.185l-1.337.084-2.681.067-10.717.017Z" transform="translate(444.436 1049.358)" fill="#ceb9b2"/>
                  <path id="Caminho_2668" data-name="Caminho 2668" d="M56.79,34.428l45.8-.017v.034L5.01,29.253l97.58,5.159v.034Z" transform="translate(444.434 1050.839)" fill="#ceb9b2"/>
                  <path id="Caminho_2669" data-name="Caminho 2669" d="M5.01,35.138l51.78-2.124-.006.056L5.01,29.552l51.78,3.462v.056Z" transform="translate(444.435 1052.223)" fill="#ceb9b2"/>
                  <path id="Caminho_2670" data-name="Caminho 2670" d="M5.01,31.61l12.442-.028-.006.056L5.01,29.863l12.448,1.72-.006.056Z" transform="translate(444.435 1053.657)" fill="#ceb9b2"/>
                  <path id="Caminho_2671" data-name="Caminho 2671" d="M305.692,27.145c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,15.636,55.076,14.293,5.01,18.007c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 988.243)" fill="#ceb9b2"/>
                  <path id="Caminho_2672" data-name="Caminho 2672" d="M305.692,26.383c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.874,55.076,13.531,5.01,17.245c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 984.723)" fill="#ceb9b2"/>
                  <path id="Caminho_2673" data-name="Caminho 2673" d="M305.692,25.848c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.339,55.076,13,5.01,16.71,55.076,12.979,105.355,14.3,155.433,17c50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 982.251)" fill="#ceb9b2"/>
                  <path id="Caminho_2674" data-name="Caminho 2674" d="M305.692,25.479c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.97,55.076,12.627,5.01,16.341c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 980.547)" fill="#ceb9b2"/>
                  <path id="Caminho_2675" data-name="Caminho 2675" d="M305.692,25.3c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.795,55.076,12.452,5.01,16.166c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 979.738)" fill="#ceb9b2"/>
                  <path id="Caminho_2676" data-name="Caminho 2676" d="M305.692,24.9c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.395,55.076,12.052,5.01,15.766c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 977.891)" fill="#ceb9b2"/>
                  <path id="Caminho_2677" data-name="Caminho 2677" d="M305.692,27.916c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,16.407,55.076,15.064,5.01,18.778c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 991.805)" fill="#ceb9b2"/>
                  <path id="Caminho_2678" data-name="Caminho 2678" d="M5.01,17.652c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,30.521,205.347,29.2,155.27,26.5c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 997.247)" fill="#ceb9b2"/>
                  <path id="Caminho_2679" data-name="Caminho 2679" d="M5.01,18.415c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1000.772)" fill="#ceb9b2"/>
                  <path id="Caminho_2680" data-name="Caminho 2680" d="M5.01,18.949c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,31.818,205.347,30.5,155.27,27.8c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1003.239)" fill="#ceb9b2"/>
                  <path id="Caminho_2681" data-name="Caminho 2681" d="M5.01,19.318c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1004.944)" fill="#ceb9b2"/>
                  <path id="Caminho_2682" data-name="Caminho 2682" d="M5.01,19.494c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1005.757)" fill="#ceb9b2"/>
                  <path id="Caminho_2683" data-name="Caminho 2683" d="M5.01,19.894c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1007.605)" fill="#ceb9b2"/>
                  <path id="Caminho_2684" data-name="Caminho 2684" d="M5.01,16.881c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 993.686)" fill="#ceb9b2"/>
                  <path id="Caminho_2685" data-name="Caminho 2685" d="M5.01,34.58c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1075.45)" fill="#ceb9b2"/>
                  <path id="Caminho_2686" data-name="Caminho 2686" d="M5.01,35.342c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1078.97)" fill="#ceb9b2"/>
                  <path id="Caminho_2687" data-name="Caminho 2687" d="M5.01,35.877c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1081.441)" fill="#ceb9b2"/>
                  <path id="Caminho_2688" data-name="Caminho 2688" d="M5.01,36.246c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.146)" fill="#ceb9b2"/>
                  <path id="Caminho_2689" data-name="Caminho 2689" d="M5.01,36.421c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.954)" fill="#ceb9b2"/>
                  <path id="Caminho_2690" data-name="Caminho 2690" d="M5.01,36.821c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1085.802)" fill="#ceb9b2"/>
                  <path id="Caminho_2691" data-name="Caminho 2691" d="M5.01,33.809c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1071.888)" fill="#ceb9b2"/>
                  <path id="Caminho_2692" data-name="Caminho 2692" d="M305.692,44.073c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,32.564,55.076,31.221,5.01,34.935c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1066.446)" fill="#ceb9b2"/>
                  <path id="Caminho_2693" data-name="Caminho 2693" d="M305.692,43.31c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.8,55.076,30.458,5.01,34.172c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1062.921)" fill="#ceb9b2"/>
                  <path id="Caminho_2694" data-name="Caminho 2694" d="M305.692,42.776c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.267,55.076,29.924,5.01,33.638c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1060.454)" fill="#ceb9b2"/>
                  <path id="Caminho_2695" data-name="Caminho 2695" d="M305.692,42.407c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.9,55.076,29.555,5.01,33.269c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1058.749)" fill="#ceb9b2"/>
                  <path id="Caminho_2696" data-name="Caminho 2696" d="M305.692,42.231c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.722,55.076,29.379,5.01,33.093c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1057.936)" fill="#ceb9b2"/>
                  <path id="Caminho_2697" data-name="Caminho 2697" d="M305.692,41.832c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.323,55.076,28.98,5.01,32.694c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1056.093)" fill="#ceb9b2"/>
                  <path id="Caminho_2698" data-name="Caminho 2698" d="M305.692,44.844c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,33.335,55.076,31.992,5.01,35.706c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1070.007)" fill="#ceb9b2"/>
                  <path id="Caminho_2699" data-name="Caminho 2699" d="M5.01,12.359C55.171,13,105.243,16.186,155.331,18.6c50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.22,55.171,13.017,5.01,12.359" transform="translate(444.436 972.795)" fill="#ceb9b2"/>
                  <path id="Caminho_2700" data-name="Caminho 2700" d="M5.01,12.875c50.161.641,100.233,3.827,150.321,6.238,50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.736,55.171,13.533,5.01,12.875" transform="translate(444.436 975.179)" fill="#ceb9b2"/>
                  <path id="Caminho_2701" data-name="Caminho 2701" d="M5.01,15.379l46.256-2.107v.056L5.01,13.3l46.256-.028.006.056Z" transform="translate(444.435 977.013)" fill="#ceb9b2"/>
                  <path id="Caminho_2702" data-name="Caminho 2702" d="M5.01,23.062l46.256-.028v.056L5.01,20.983l46.261,2.051-.006.056Z" transform="translate(444.435 1012.636)" fill="#ceb9b2"/>
                  <path id="Caminho_2703" data-name="Caminho 2703" d="M96.544,14.481,50.288,16.588v-.056l46.256.028-46.256.028-.006-.056Z" transform="translate(653.582 982.598)" fill="#ceb9b2"/>
                  <path id="Caminho_2704" data-name="Caminho 2704" d="M5.01,20.449c100.121,5.963,200.354,10.992,300.682,11.228C205.364,31.5,105.131,26.479,5.01,20.449" transform="translate(444.436 1010.169)" fill="#ceb9b2"/>
                  <path id="Caminho_2705" data-name="Caminho 2705" d="M29.805,30.174c26.885,1.2,53.747,3.085,80.66,3.54,26.913.539,53.831-.455,80.676-2.315-26.845,1.883-53.764,2.894-80.676,2.372-26.918-.472-53.775-2.383-80.66-3.6" transform="translate(558.981 1055.095)" fill="#ceb9b2"/>
                  <g id="Grupo_14" data-name="Grupo 14" transform="translate(449.383 943.802)">
                    <g id="Grupo_13" data-name="Grupo 13" clip-path="url(#clip-path)">
                      <image id="Retângulo_1677" data-name="Retângulo 1677" width="303.464" height="191.07" transform="translate(0.006 -0.815)" />
                    </g>
                  </g>
                </g>
              </svg>
              )
            case 'laptop':
            return (
              <svg x={shape.x} y={shape.y} width={30} height={30} onMouseDown={(event) => handleMouseDown(event, shape.id)} style={{ cursor: 'pointer'}}
                  viewBox="0 0 512 512">
                    <g transform={`rotate(${shape.angle} 250 250)`}  onDoubleClick={() => setShapeToEdit(shape)}>
                <path style={{fill:"#C9C6CA"}} d="M437.697,462.474H74.303c-13.684,0-24.777-11.093-24.777-24.777V181.669h412.948v256.028
                  C462.474,451.381,451.381,462.474,437.697,462.474z"/>
                <path style={{fill:"#6F6571"}} d="M496.285,49.526H15.715c-11.32,0-18.919,11.618-14.382,21.988l48.193,110.155h412.948l48.193-110.155
                  C515.204,61.144,507.605,49.526,496.285,49.526z"/>
                <path style={{fill:"#5D5360"}} d="M388.143,346.849H123.857c-4.561,0-8.259-3.698-8.259-8.259V222.964c0-4.561,3.698-8.259,8.259-8.259
                  h264.287c4.561,0,8.259,3.698,8.259,8.259V338.59C396.402,343.151,392.705,346.849,388.143,346.849z"/>
                <path style={{fill:"#DBD9DC"}} d="M189.928,346.849v66.072c0,9.122,7.395,16.518,16.518,16.518h99.107
                  c9.122,0,16.518-7.396,16.518-16.518v-66.072H189.928z"/>
                <polygon style={{fill:"#5D5360"}} points="41.267,82.562 82.562,181.669 429.438,181.669 470.733,82.562 "/>
                <g>
                  <path style={{fill:"#6F6571"}} d="M421.179,198.187H90.821c-4.561,0-8.259-3.698-8.259-8.259v-8.259h346.876v8.259
                    C429.438,194.49,425.74,198.187,421.179,198.187z"/>
                  <rect x="115.6" y="239.48" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                  <rect x="115.6" y="272.52" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                  <rect x="115.6" y="305.55" style={{fill:"#6F6571"}} width="280.81" height="16.518"/>
                </g>
                <g>
                  <path style={{fill:"#B7B2B8"}} d="M66.044,363.366V181.669H49.526v256.028c0,13.684,11.093,24.777,24.777,24.777h363.394
                    c10.759,0,19.951-6.932,23.366-16.518H148.634C103.021,445.956,66.044,408.979,66.044,363.366z"/>
                  <path style={{fill:"#B7B2B8"}} d="M461.267,444.925c0.013-0.043,0.036-0.083,0.049-0.126
                    C461.173,444.841,461.165,444.883,461.267,444.925z"/>
                </g>
                </g>

              </svg>
            )
            case 'tv':
              return (<svg xmlns="http://www.w3.org/2000/svg" x={shape.x} y={shape.y} width={50} height={50} onMouseDown={(event) => handleMouseDown(event, shape.id)} style={{ cursor: 'pointer'}}
                viewBox="0 0 512 512" >
                  <g transform={`rotate(${shape.angle} 250 250)`}  onDoubleClick={() => setShapeToEdit(shape)}>
                  <path style={{fill:"#6F6571"}} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-99.097
                    c0-13.682,11.092-24.774,24.774-24.774h132.129c13.682,0,24.774,11.092,24.774,24.774v99.097
                    C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                  <path style={{fill:"#867E88"}} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-57.806h181.677v57.806
                    C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                  <path style={{fill:"#5D5360"}} d="M433.848,226.676c-13.725-7.843-29.26-11.968-45.069-11.968H123.22
                    c-15.808,0-31.343,4.125-45.069,11.968L41.29,247.742h429.42L433.848,226.676z"/>
                  <path style={{fill:"#6F6571"}} d="M503.742,280.773H8.258c-4.561,0-8.258-3.697-8.258-8.258v-16.516c0-4.561,3.697-8.258,8.258-8.258
                    h495.484c4.561,0,8.258,3.697,8.258,8.258v16.516C512,277.076,508.303,280.773,503.742,280.773z"/>
                  <path style={{fill:"#5D5360"}} d="M512,264.258v-8.258c0-4.56-3.697-8.258-8.258-8.258H8.258c-4.561,0-8.258,3.698-8.258,8.258v8.258
                    H512z"/>
                  <path style={{fill:"#4B3F4E"}} d="M161.031,214.71L161.031,214.71h-37.816c-15.807,0-31.34,4.125-45.064,11.968L41.29,247.742h429.42
                    l-28.902-16.516H169.29C160.783,231.225,155.928,221.514,161.031,214.71z"/>
                  </g>
                </svg>)
            default:
              break;
          }
        })}

      </svg>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        elevation={1}
        disableEnforceFocus
      >
        {/* Conteúdo do Popover de opções */}
        <div>
          <p>Opção 1</p>
          <p>Opção 2</p>
          <p>Opção 3</p>
        </div>
      </Popover>

      {/* Start Popover edit shape */}
      <Popover
        id={idPopoverLinked}
        open={openPopoverLinked}
        anchorEl={anchorElLinked}
        onClose={handleCloseLinked}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        elevation={1}
        disableEnforceFocus
      >
        <Box
          width={'300px'}
          padding={'8px'}
        >
          <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 10px', color: '#4F7091', fontSize: '14px' }}>{t('spaces.maps.update_shape')}</Typography>
          <Box
            display="flex"
            flexDirection="column"
            zIndex={2}
          >
            <Grid container spacing={2} component={'form'} noValidate autoComplete='off' onSubmit={handleSubmitLinked}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  type='text'
                  label={t('spaces.maps.title_field')}
                  name='name'
                  defaultValue={selectedShape?.name || ''}
                  onChange={(e) => setFormLinked({ ...formLinked, name: e.target.value })}
                  error={formErrorLinked.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="type"
                  select
                  label={t('spaces.maps.linked_field')}
                  defaultValue={selectedShape?.room_id || ''}
                  onChange={(e) => setFormLinked({ ...formLinked, room_id: e.target.value })}
                  error={formErrorLinked.room_id}
                >

                  <MenuItem value={''}>
                    {t('spaces.maps.option_select')}
                  </MenuItem>
                  {
                    rooms?.length > 0 &&
                    rooms.map((room, index) => (
                      <MenuItem key={room.value} value={room.value}>
                        {room.name}
                      </MenuItem>
                    ))
                  }

                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmittingLinked} fullWidth type="submit" size="small" variant="contained" color="primary" disableElevation>
                  {t('spaces.maps.btn_save')}
                </Button>
              </Grid>
            </Grid>

          </Box>
        </Box>
      </Popover >
      {/* End Popover edit shape */}
    </Box>
  );
};

export default MapEditor;