import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Moment from 'moment';
import Filterbar from '../_components/_shared/Filterbar';
import Toolbar from '../_components/_shared/Toolbar';
import RealTimeFull from '../_components/reports/realtime/RealTimeFull';
import RealTimeFullSkeleton from '../_components/reports/realtime/RealTimeFullSkeleton';

import { useTranslation } from "react-i18next";


import { shareActions, statsActions } from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';


function ReportsRealTimePage(props) {
	const { t, i18n } = useTranslation('common');

	const { statsActions, shareActions } = props;

	let loadingUsers = props.loadingUsers;
	let loading = props.loading;

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);


	useEffect(() => {
		// console.log("chamou")
		let startDate = Moment(new Date()).format('YYYY-MM-DD');
		let endDate = Moment(new Date()).format('YYYY-MM-DD');

		if (props.filter.singleDate) {
			startDate = props.filter.singleDate;
			endDate = props.filter.singleDate;
		}

		let teams = null;
		let teammates = null;

		if (props.filter.teams && props.filter.teams.length > 0) {
			teams = props.filter.teams;
		}

		statsActions.getStats(startDate, endDate, teams, teammates);
		statsActions.getRealtimeMachineUsers(startDate, endDate, teams);

	}, [props.filter.singleDate, props.filter.teams])


	function closeShareModal() {
		shareActions.closeModal()
	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.realtime');
	});

	return (
		<React.Fragment>
			<section className="mainContent">
				<Toolbar />

				<Filterbar
					filterWho="singleTeamsTESTE"
					filterWhen={'single'}
					filterWhat={'disabled'}
				/>

				{/* PAGE STARTS */}
				<div className="mainPage">
					<div className="column single">
						{
							!minimumTimeElapsed || (loadingUsers == true || loading === true)
								? (
									<RealTimeFullSkeleton />
								) : (
									<RealTimeFull />
								)
						}
					</div>

				</div>
				{/* PAGE ENDS */}
			</section>

			<div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
				closeShareModal()
			}}></div>
			<section className={'modal' + (props.share.modal ? ' open' : '')}>
				<ShareModal reports='realTime' filters={props.filter.singleDate} />
			</section>


		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		shareActions: bindActionCreators(shareActions, dispatch),


	}
}


function mapStateToProps(state) {

	return {
		loading: state.stats.loading,
		loadingUsers: state.stats.loadingUsers,
		filter: state.filter,
		share: state.share

	}

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsRealTimePage)
