import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Fade, IconButton, makeStyles, MenuItem, Modal, Slide } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import MuiSelect from "@material-ui/core/Select";
import { TrashSimple } from 'phosphor-react';
import { AttachFile, Close } from '@material-ui/icons';
import ReactAvatar from 'react-avatar';
import Moment from 'moment';
import { TagsInput } from 'react-tag-input-component';

import IconClose from '../../_icons/IconClose';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import { ModalAddTeamTask } from '../ModalAddTeamTask';

import "./styles.scss"
import { BefectiveDatePicker } from '../BefectiveDatePicker';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalAddNewTask({
  openModalNewTask,
  teammatesOptions,
  kr,
  categories,
  userDetails,
  alertActions,
  setOpenModalNewTask,
  getGoalsDetails,
  groups
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [responsible, setResponsible] = useState('');
  const [isCheckSelected, setIsCheckSelect] = useState(false)
  const [checkListTitle, setCheckListTitle] = useState('')
  const [checkListItems, setCheckListItems] = useState([])
  const [assistants, setAssistants] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [openModalAddTeamTask, setOpenModalAddTeamTask] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tags, setTags] = useState([]);
  const [form, setForm] = useState({
    title: '',
    description: '',
    responsible: '',
    date_start: new Date().toISOString().split('T')[0],
    date_end: new Date().toISOString().split('T')[0],
    priority: '',
    complexity: '',
    category: '39473',
    files: [],
    weight: 1
  });

  const [formError, setFormError] = useState({
    title: '',
    description: '',
    responsible: '',
    date_start: '',
    date_end: '',
    priority: '',
    complexity: '',
    category: '',
    files: '',
    weight: ''
  });

  const token = authHeader()

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function handleSetIsCheckSelect(e, value) {
    e.preventDefault()
    setIsCheckSelect(value)
  }

  const handleAddCheckList = (e) => {
    if (e.key === 'Enter' && checkListTitle !== '') {
      const checkIfExists = checkListItems.filter(
        item => item.title.toLowerCase() === checkListTitle.toLowerCase()
      )

      if (checkIfExists.length === 0) {
        setCheckListItems([...checkListItems, {
          title: checkListTitle,
          is_checked: false
        }])

        setCheckListTitle('')
      }
    }

  }

  const handleRemoveCheckList = (title) => {
    setCheckListItems(checkListItems.filter(item => item.title !== title));
  }

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);

    setForm({ ...form, files: [...form.files, ...filesArr] });
    setFormError({ ...formError, files: '' })
  }

  const removeFile = (file) => {
    setForm({ ...form, files: form.files.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('goals.errors.required')
    }

    if (!form.responsible) {
      errors.responsible = t('goals.errors.required')
    }

    if (form.files.length > 0) {
      form.files.forEach(file => {
        if ((file.size / 1000000) > 2) {
          errors.files = t('tasks.error.file_length')
        }
      })
    }
    return errors
  }

  const resetTaskForm = () => {
    setForm({
      title: '',
      description: '',
      responsible: '',
      date_start: '',
      date_end: '',
      priority: '',
      complexity: '',
      category: '',
      checkListItems: [],
      files: [],
      weight: 1
    })

    setFormError({
      title: '',
      description: '',
      responsible: '',
      date_start: '',
      date_end: '',
      priority: '',
      complexity: '',
      category: '',
      files: '',
      weight: ''
    })

    setResponsible('')
    setAssistants([])
    setTags([])
    setCheckListItems([])

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createNewTask = async () => {
    try {
      setIsSubmitting(true)
      const co_responsibles = assistants.reduce((acc, item) => {
        return [...acc, item.id]
      }, [])

      const formData = new FormData();

      formData.append('action_id', 5);
      formData.append('datetime', Moment().format());
      formData.append('title', form.title);
      formData.append('description', form.description);
      formData.append('date_start', form.date_start);
      formData.append('date_end', form.date_end);
      formData.append('priority_id', form.priority);
      formData.append('complexity_id', form.complexity);
      formData.append('mu_id', form.responsible);
      formData.append('category_id', form.category);
      formData.append('weight', form?.weight);

      for (let item of form.files) {
        formData.append('files', item)
      }

      for (let item of tags) {
        formData.append('tags', item)
      }

      for (let item of co_responsibles) {
        formData.append('co_responsibles', item);
      }

      for (let item of checkListItems) {
        formData.append('itens', JSON.stringify(item));
      }

      await api.post(
        `/goals/key-results/${kr.id}/tasks?organization_id=${userDetails?.organizationId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token.Authorization
          },
        }
      )
      getGoalsDetails()
      setOpenModalNewTask(false);
      resetTaskForm()
      alertActions.success(t('tasks.success.create_task'))

      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error.response)
      //alertActions.error(t('tasks.error.create_task'))

    }
  }

  const handleOpenModalAddTeamTask = (e) => {
    e.preventDefault()
    setOpenModalAddTeamTask(true)
  }

  const handleClose = (e) => {
    e.preventDefault()
    setOpenModalNewTask(false);
    resetTaskForm()
  };

  useEffect(() => {
    if (responsible) {
      handleChange({ target: { name: 'responsible', value: responsible } })
    }
  }, [responsible])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createNewTask()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    setForm({
      title: '',
      description: '',
      responsible: '',
      date_start: new Date().toISOString().split('T')[0],
      date_end: new Date().toISOString().split('T')[0],
      priority: '',
      complexity: '',
      category: '39473',
      files: [],
      weight: 1
    })
  }, [kr])

  return (
    <div className='container-modal-new-task'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalNewTask}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalNewTask} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <form className='modal-form-task-container' >
            <div className="box-button-close">
              <button onClick={handleClose} className="close" type='button'><IconClose /></button>
            </div>
            <div className='left-container-modal'>
              <header>
                <h2>{t('tasks.new_task')}</h2>
              </header>

              <div className='boxFieldEditTask'>
                <label htmlFor="input-task-title">{t('tasks.title')}*</label>
                <input
                  type="text"
                  id="input-task-title"
                  name="title"
                  className='input-task-title'
                  placeholder={t('tasks.input_placeholder')}
                  value={form.title}
                  onChange={handleChange}
                  autoFocus
                  maxLength={150}/>
                <span className="text-limit">{form?.title?.length}/150</span>
                {formError.title &&
                  <div className="error">{formError.title}</div>
                }
              </div>

              <div className='boxFieldEditTask'>
                <label htmlFor="input-task-description">{t('tasks.description')}</label>
                <textarea
                  id="input-task-description"
                  name="description"
                  className='input-task-description'
                  placeholder={t('tasks.input_placeholder')}
                  value={form.description}
                  onChange={handleChange}
                />
                {formError.description &&
                  <div className="error">{formError.description}</div>
                }
              </div>

              <div className='dates-task-container'>
                <div className='middle-infos' style={{ width: '230px' }}>
                  <label htmlFor="responsible">{t('tasks.responsible')}*</label>
                  <Select
                    id='responsible'
                    defaultValue={form.responsible}
                    name="responsible"
                    options={teammatesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('goals.modal_goal.select')}
                    onChange={(e) => setResponsible(e.value)}
                  />
                  {formError.responsible &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.responsible}
                    </div>
                  }
                </div>

                <div className='middle-infos'>
                  <label htmlFor="input-task-date-ini">{t('tasks.start')}</label>
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.date_start}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="date_start"
                    minDate={kr.date_start}
                    maxDate={kr.date_end}
                  />
                  {/* <input
                    type="date"
                    id='input-task-date-ini'
                    name='date_start'
                    className='input-task-date'
                    value={form.date_start}
                    onChange={handleChange}
                    min={kr.date_start}
                    max={kr.date_end}
                  /> */}
                  {formError.date_start &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.date_start}
                    </div>
                  }
                </div>

                <div className='middle-infos'>
                  <label htmlFor="input-task-date-end">{t('tasks.end')}</label>
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.date_end}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="date_end"
                    minDate={kr.date_start}
                    maxDate={kr.date_end}
                  />
                  {/* <input
                    type="date"
                    id='input-task-date-end'
                    name='date_end'
                    className='input-task-date'
                    value={form.date_end}
                    onChange={handleChange}
                    min={kr.date_start}
                    max={kr.date_end}
                  /> */}
                  {formError.date_end &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.date_end}
                    </div>
                  }
                </div>
              </div>

              <footer className='footer-task-container'>
                <div className='tabs-task-container'>
                  {/* <button
                    className={isCheckSelected ? 'selected' : ''}
                    onClick={(e) => handleSetIsCheckSelect(e, true)}
                  >
                    CheckList
                  </button> */}

                  <button
                    className={isCheckSelected ? '' : 'selected'}
                    onClick={(e) => handleSetIsCheckSelect(e, false)}
                  >
                    {t('tasks.attach')}
                  </button>
                </div>

                {isCheckSelected ? (
                  <>
                    <div className='new-check-container'>
                      <label htmlFor="input-check-title">{t('tasks.check_title')}</label>
                      <input
                        value={checkListTitle}
                        type="text"
                        id="input-check-title"
                        className='input-check-title'
                        placeholder={t('tasks.input_itens')}
                        onKeyDown={(e) => handleAddCheckList(e)}
                        autoComplete="off"
                        onChange={e => setCheckListTitle(e.target.value)}
                      />
                    </div>

                    <div className='checklist-task-container'>
                      {checkListItems.length > 0 && checkListItems.map(check => (
                        <div key={check.title} className='checklist-task'>
                          <ul>
                            <li>
                              <div className='check'>
                                <div />
                                {check.title}
                              </div>

                              <TrashSimple size={18}
                                weight="fill"
                                onClick={() => handleRemoveCheckList(check.title)}
                              />
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <label className='input-file-box'>
                      <input
                        type="file"
                        multiple
                        onChange={onChangeInputFile}
                      />
                      <AttachFile fontSize='small' />
                      <div>
                        {t('tasks.attach_file')}
                      </div>

                    </label>
                    <span style={{ color: '#1E6B7F', fontSize: '10px' }}>
                      {t('tasks.file_message')}
                    </span>

                    {formError.files &&
                      <div
                        className="error"
                        style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        {formError.files}
                      </div>
                    }

                    <div className='files-container'>
                      {form.files.length > 0 && form.files.map((file, index) => (
                        <div key={index} className="file-preview">
                          <div>{file.name}</div>
                          <div>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => { removeFile(file) }}
                            >
                              <Close fontSize='small' />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

              </footer>

            </div>

            <div className='right-container-modal'>
              <div className='selects-container'>
                <label htmlFor="priority">{t('tasks.priority')}</label>
                <MuiSelect
                  style={{ color: '#98ACC1' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="priority"
                  id="priority"
                  value={form.priority}
                  onChange={handleChange}
                >
                  <MenuItem
                    value=""
                  >
                    {t('goals.modal_goal.select')}
                  </MenuItem>
                  <MenuItem value={1}>{t('tasks.low')}</MenuItem>
                  <MenuItem value={2}>{t('tasks.medium')}</MenuItem>
                  <MenuItem value={3}>{t('tasks.high')}</MenuItem>
                </MuiSelect>

                {formError.priority &&
                  <div className="error">{formError.priority}</div>
                }

                <label htmlFor="complexity">{t('tasks.complexity')}</label>
                <MuiSelect
                  style={{ color: '#98ACC1' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="complexity"
                  id="complexity"
                  value={form.complexity}
                  onChange={handleChange}
                >
                  <MenuItem
                    value=""
                  >
                    {t('goals.modal_goal.select')}
                  </MenuItem>
                  <MenuItem value={1}>{t('tasks.low')}</MenuItem>
                  <MenuItem value={2}>{t('tasks.medium')}</MenuItem>
                  <MenuItem value={3}>{t('tasks.high')}</MenuItem>
                </MuiSelect>

                {formError.complexity &&
                  <div className="error">{formError.complexity}</div>
                }

                <label htmlFor="weight" style={{textTransform: 'uppercase'}}>{t('modalFormKeyResult.weight')}</label>

                <MuiSelect
                  style={{ color: '#98ACC1', textAlign: 'right' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="weight"
                  id="weight"
                  value={form.weight || 1}
                  onChange={handleChange}
                >
                  <MenuItem selected value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </MuiSelect>
                {formError.weight &&
                  <div className="error">{formError.weight}</div>
                }


                <label htmlFor="assistant" style={{ marginBottom: '8px', marginTop: '1.2rem' }}>
                  {t('tasks.assistant')}
                </label>

                <div className='assistant-container'>
                  {assistants?.length >= 3 ? (
                    <>

                      <ReactAvatar
                        name={assistants[0].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />

                      <ReactAvatar
                        name={assistants[1].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />

                      <ReactAvatar
                        name={assistants[2].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff' }}
                      />
                      <span
                        style={{
                          color: '#4F7091',
                          fontSize: '16px',
                        }}
                      >
                        ...
                      </span>
                    </>

                  ) : (
                    assistants?.map(user => (
                      <ReactAvatar
                        key={user.id}
                        name={user.username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />
                    ))
                  )}

                  <button
                    className='add-assistant-button'
                    onClick={handleOpenModalAddTeamTask}
                  >
                    +
                  </button>

                </div>

                <label htmlFor="tags" style={{ marginTop: '1.2rem' }}>
                  TAGS
                </label>
                <div className='tagBox'>
                  <TagsInput
                    value={tags}
                    onChange={e => setTags(e)}
                    name="tags"
                    id="tags"
                    placeHolder="+ Tag"
                  />
                </div>

              </div>

              <div className='buttons-container-add-task'>
                <button className='cancel' onClick={handleClose}>{t('button.cancel')}</button>
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={8} />
                  ) : t('button.save')}

                </button>
              </div>

            </div>
          </form>
        </Slide>
      </Modal>

      <ModalAddTeamTask
        key={1}
        classes={classes}
        openModalAddTeamTask={openModalAddTeamTask}
        setOpenModalAddTeamTask={setOpenModalAddTeamTask}
        assistants={assistants}
        setAssistants={setAssistants}
        groups={groups}
      />
    </div>
  );
}
