import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { CardsHeaderDashboard } from '../../_components/_shared/inventory/dashboard/CardsHeader';
import InventoryColabDetailsSkeleton from '../../_components/_shared/inventory/colab_details/InventoryColabDetailsSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function InventoryDashboardPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [dashboardData, setDashboardData] = useState(null);
  const [fetching, setFetching] = useState(true);

  const [chartSoCount, setChartSoCount] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: []
  })

  const [chartMachinesByRam, setChartMachinesByRam] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: []
  })

  const [chartMachinesByRamUsagePercent, setChartMachinesByRamUsagePercent] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: []
  })

  const [chartMachinesByDisk, setChartMachinesByDisk] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: []
  })

  const [chartMachinesByDiskUsagePercent, setChartMachinesByDiskUsagePercent] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: []
  })

  const [chartMachinesByModel, setChartMachinesByModel] = useState({
    options: {
      labels: [],
      colors: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 220
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ]
    },
    series: []
  })

  const [chartDiskColabs, setChartDiskColabs] = useState({
    options: {
      colors: [
        "#FF4560",
        "#26E7A6",
        // "#6C757D"
      ],
      chart: {
        id: 'horizontal-bar-chart',
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      // plotOptions: {
      //   bar: {
      //     horizontal: true
      //   },
      // },
      xaxis: {
        categories: [],
        // labels: {
        //   formatter: (value) => {
        //     return value + 'GB';
        //   },
        // },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return value + 'GB';
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: false, // Habilita os rótulos de dados
        formatter: (value) => {
          return String(value) + 'GB'; // Define o rótulo personalizado aqui
        },
      },
    },
    series: [
      {
        name: t(`inventory.dashboard.charts.titles.computersBySO`),
        data: [],
      },
      {
        name: t(`inventory.dashboard.charts.free`),
        data: [],
      },
    ]
  })

  const limitLabelsLength = (labels, maxLength) => {
    if (labels.length === 0) return []

    return labels.map((label) => {
      if (label.length > maxLength) {
        return label.substring(0, maxLength) + '...';
      }
      return label;
    })
  };

  async function getDataDashboard(teams = null, teammates = null) {
    try {

      setFetching(true)
      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teams=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&users=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }

      const response = await api.get(`/inventory?local_datetime=${moment().format()}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status === 200) {
        const { data } = response
        setDashboardData(data)
        if(data.data.length > 0){

          setChartSoCount({
            options: {
              ...chartSoCount.options,
              labels: limitLabelsLength(data.charts.chartSoCount.labels, 30),
              colors: data.charts.chartSoCount.colors
            },
            series: data.charts.chartSoCount.series
          })
  
          setChartMachinesByModel({
            options: {
              ...chartMachinesByModel.options,
              labels: limitLabelsLength(data.charts.chartMachinesByModel.labels, 30),
              colors: data.charts.chartMachinesByModel.colors
            },
            series: data.charts.chartMachinesByModel.series
          })
  
          setChartMachinesByRam({
            options: {
              ...chartMachinesByRam.options,
              labels: limitLabelsLength(data.charts.chartMachinesByRam.labels.map(item => t(`inventory.dashboard.charts.${item}`)), 30),
              colors: data.charts.chartMachinesByRam.colors
            },
            series: data.charts.chartMachinesByRam.series
          })
  
          setChartMachinesByRamUsagePercent({
            options: {
              ...chartMachinesByRamUsagePercent.options,
              labels: limitLabelsLength(data.charts.chartMachinesByRamUsagePercent.labels.map(item => t(`inventory.dashboard.charts.${item}`)), 30),
              colors: data.charts.chartMachinesByRamUsagePercent.colors
            },
            series: data.charts.chartMachinesByRamUsagePercent.series
          })
  
          setChartMachinesByDisk({
            options: {
              ...chartMachinesByDisk.options,
              labels: limitLabelsLength(data.charts.chartMachinesByDisk.labels.map(item => t(`inventory.dashboard.charts.${item}`)), 30),
              colors: data.charts.chartMachinesByDisk.colors
            },
            series: data.charts.chartMachinesByDisk.series
          })
  
          setChartMachinesByDiskUsagePercent({
            options: {
              ...chartMachinesByDiskUsagePercent.options,
              labels: limitLabelsLength(data.charts.chartMachinesByDiskUsagePercent.labels.map(item => t(`inventory.dashboard.charts.${item}`)), 30),
              colors: data.charts.chartMachinesByDiskUsagePercent.colors
            },
            series: data.charts.chartMachinesByDiskUsagePercent.series
          })
  
          setChartDiskColabs({
            options: {
              ...chartDiskColabs.options,
              xaxis: {
                ...chartDiskColabs.options.xaxis,
                categories: data.datasetDiskColabs.map((item) => item.username)
              }
            },
            series: [
              {
                name: t(`inventory.dashboard.charts.in_use`),
                data: data.datasetDiskColabs.map((item) => item.disk_used_in_gb),
              },
              {
                name: t(`inventory.dashboard.charts.free`),
                data: data.datasetDiskColabs.map((item) => item.disk_free_in_gb),
              },
              // {
              //   name: 'Total',
              //   data: data.datasetDiskColabs.map((item) => item.disk_total_in_gb),
              // },
            ]
          })
        }
      }

      setFetching(false)

    } catch (error) {
      console.log(error)
      // alertActions.error('Erro ao carregar os dados')
      setFetching(false)

    }
  }


  useEffect(() => {
    getDataDashboard()
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          showShareBtn={false}
        />

        <div className={style.containerCheckinPage}>
          {!fetching ?
            <>
              <CardsHeaderDashboard alertActions={alertActions} userDetails={userDetails} resume={dashboardData?.stats} />

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersBySO`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartSoCount.options} series={chartSoCount.series} type="pie" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersByModel`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartMachinesByModel.options} series={chartMachinesByModel.series} type="pie" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersByRAM`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartMachinesByRam.options} series={chartMachinesByRam.series} type="donut" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersByUsageRAM`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartMachinesByRamUsagePercent.options} series={chartMachinesByRamUsagePercent.series} type="donut" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersByDisk`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartMachinesByDisk.options} series={chartMachinesByDisk.series} type="donut" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.computersByUsageDisk`)}</Typography>
                      <CardContent>
                        <Box component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartMachinesByDiskUsagePercent.options} series={chartMachinesByDiskUsagePercent.series} type="donut" height="220" />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card sx={{ padding: '6px' }} elevation={0}>
                      <Typography variant='h6' sx={{ fontSize: '14px', color: '#1E6B7F', margin: '4px 10px' }}>{t(`inventory.dashboard.charts.titles.diskColabs`)}</Typography>
                      <CardContent>
                        <Box id='horizontal-bar-chart' component={'div'} sx={{ minHeight: '220px' }}>
                          <ReactApexChart options={chartDiskColabs.options} series={chartDiskColabs.series} type="bar" height={600} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </>
            :
            <InventoryColabDetailsSkeleton />
          }

        </div>


      </section>

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDashboardPage)