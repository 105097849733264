import React, { useRef, useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from 'redux'
import { activitiesActions, filterActions } from '../../_actions';
import { useTable, useSortBy, useRowSelect } from 'react-table'
import { secondsToHm } from '../../_helpers';

import IconArrow2 from '../_icons/IconArrow2';

import Moment from 'moment';
import useOutsideClick from '../../_helpers/useOutsideClick';
import { Divider } from '@material-ui/core';

function DrawerActivityTableTeams(props) {
    const { t, i18n } = useTranslation('common');

    const { activitiesActions, userDetails } = props;
    let detailsTeams = props.detailsTeams
    let current = props.current || {};
    let stats = props.stats || {};
    let loadingDetailsTeams = props.loadingDetailsTeams || true;
    let permissions = props.permissions || {};
    let filter = props.filter || {};
    let updated = props.updated || false
    const ref = React.useRef(null);

    const dataTeams = useMemo(() => detailsTeams, []);

    const columnsTeams = React.useMemo(
        () => [
            {
                Header: t('activities.team'),
                accessor: 'teamName' // accessor is the "key" in the data

            },
            {
                Header: t('activities.total_time'),
                accessor: 'duration',
                Cell: (d) => {

                    let totalTime = secondsToHm(d.value);
                    let percentTime = 0;
                    let duration = '';

                    if (current.productiveDuration > 0) {
                        percentTime = Math.round((d.value / current.productiveDuration) * 100)
                    }

                    if (d.row.original.efficiencyName == 'productive') {
                        duration = <div className="duration related">{totalTime}<span>{percentTime}%</span></div>
                    }

                    if (d.row.original.efficiencyName == 'neutral') {
                        duration = <div className="duration neutral">{totalTime}<span>{percentTime}%</span></div>
                    }

                    if (d.row.original.efficiencyName == 'unproductive') {
                        duration = <div className="duration unrelated">{totalTime}<span>{percentTime}%</span></div>
                    }

                    return duration
                }
            },
            {
                Header: t('activities.classification'),
                accessor: 'efficiencyName',
                Cell: (d) => {

                    let cText = '';
                    let cClass = '';

                    if (d.value == 'productive') {
                        cText = t('activities.work_related')
                        cClass = 'related'
                    }
                    if (d.value == 'neutral') {
                        cText = t('activities.work_neutral')
                        cClass = 'neutral'
                    }

                    if (d.value == 'unproductive') {
                        cText = t('activities.work_unrelated')
                        cClass = 'unrelated'
                    }


                    let textTransactional = t('activities.not_transactional')
                    let classTransactional = 'not_transactional'

                    if (d.row.original.its_transactional === true) {
                        textTransactional = t('activities.transactional')
                        classTransactional = 'transactional'
                    }

                    return <div className='boxClassification'>
                        {
                            (userDetails.allowed_activity_classification === true)
                                ?
                                <div className={"relation " + cClass}>
                                    <button onClick={() => {
                                        toggleSetRelation(d.row.original.teamId)
                                    }} className={cClass}>{cText} <IconArrow2 /></button>
                                    <div className={"setRelation"} id={"team-" + d.row.original.teamId}>
                                        <ul>
                                            {
                                                (userDetails.roleName === 'admin' || userDetails.roleName === "magic") &&
                                                <>
                                                    <h4>{t('activities.all_company')}</h4>
                                                    <li onClick={() => {
                                                        setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'productive', false, true)
                                                    }} className="related">{t('activities.work_related')}</li>
                                                    <li onClick={() => {
                                                        setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'neutral', false, true)
                                                    }} className="neutral">{t('activities.work_neutral')}</li>
                                                    <li onClick={() => {
                                                        setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'unproductive', false, true)
                                                    }} className="unrelated">{t('activities.work_unrelated')}</li>
                                                    <Divider style={{width:'100%'}}/>
                                                </>
                                            }

                                            <h4>{t('activities.only_team')}</h4>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'productive', false, false)
                                            }} className="related">{t('activities.work_related')}</li>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'neutral', false, false)
                                            }} className="neutral">{t('activities.work_neutral')}</li>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'unproductive', false, false)
                                            }} className="unrelated">{t('activities.work_unrelated')}</li>

                                            <Divider style={{width:'100%'}}/>
                                            
                                            <h4>{t('activities.all_teams')}</h4>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'productive', true, false)
                                            }} className="related">{t('activities.work_related')}</li>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'neutral', true, false)
                                            }} className="neutral">{t('activities.work_neutral')}</li>
                                            <li onClick={() => {
                                                setRelation(d.row.original.teamId, d.row.original.efficiencyId, 'unproductive', true, false)
                                            }} className="unrelated">{t('activities.work_unrelated')}</li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                <span className={`labelEfficiency ${cClass}`}>{cText}</span>
                        }
                        {
                            (d.value === 'productive' && userDetails.allowed_transactional_classification === true) && (
                                <div className={`relation ${classTransactional}`}>
                                    <button
                                        onClick={() => toggleSetTransactional(d.row.original.teamId)}
                                        className={classTransactional}
                                    >
                                        {textTransactional} <IconArrow2 />
                                    </button>
                                    <div className={"setRelation"} id={"team-transactional-" + d.row.original.teamId} ref={ref}>
                                        <ul>
                                            {
                                                (userDetails.roleName === 'admin' || userDetails.roleName === "magic") &&
                                                <>
                                                    <h4>{t('activities.all_company')}</h4>
                                                    <li onClick={() => {
                                                        setTransactional("organization", d.row.original.teamId, "transactional")
                                                    }} className="transactional">{t('activities.transactional')}</li>
                                                    <li onClick={() => {
                                                        setTransactional("organization", d.row.original.teamId, "not_transactional")
                                                    }} className="not_transactional">{t('activities.not_transactional')}</li>
                                                    <Divider style={{width:'100%'}}/>
                                                </>
                                            }
                                            <h4>{t('activities.only_team')}</h4>
                                            <li onClick={() => {
                                                setTransactional("team", d.row.original.teamId, "transactional")
                                            }} className="transactional">{t('activities.transactional')}</li>
                                            <li onClick={() => {
                                                setTransactional("team", d.row.original.teamId, "not_transactional")
                                            }} className="not_transactional">{t('activities.not_transactional')}</li>
                                            <Divider style={{width:'100%'}}/>
                                            <h4>{t('activities.all_teams')}</h4>
                                            <li onClick={() => {
                                                setTransactional("sub_team", d.row.original.teamId, "transactional")
                                            }} className="transactional">{t('activities.transactional')}</li>
                                            <li onClick={() => {
                                                setTransactional("sub_team", d.row.original.teamId, "not_transactional")
                                            }} className="not_transactional">{t('activities.not_transactional')}</li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                }
            },

        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns: columnsTeams,
            data: dataTeams,
        },
        useSortBy,
        useRowSelect
    )

    function toggleSetRelation(teamId) {

        let thisRelation = document.getElementById("team-" + teamId)

        var elems = document.querySelectorAll(".relation.active")

        if (thisRelation.parentNode.classList.contains('active')) {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });

        } else {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });


            thisRelation.parentNode.classList.add('active')
        }

    }

    function setRelation(teamId, efficiencyId, efficiencyName, subteams, company) {

        toggleSetRelation(teamId)

        activitiesActions.setRelation(teamId, efficiencyId, efficiencyName, subteams, company)
    }

    function toggleSetTransactional(teamId) {

        let thisRelation = document.getElementById("team-transactional-" + teamId)

        var elems = document.querySelectorAll(".relation.active")

        if (thisRelation.parentNode.classList.contains('active')) {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });

        } else {

            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });


            thisRelation.parentNode.classList.add('active')
        }

    }

    const removeElementsActives = () => {
        
        const elems = document.querySelectorAll(".relation.active");

        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
    }

    function setTransactional(update_type, group_id, transactional) {

        if (!current) return

        toggleSetTransactional(group_id)

        activitiesActions.setTransactional(update_type, group_id, current.trackedId, transactional)
    }

    
	useOutsideClick(ref, target => {
        if(!['not_transactional','transactional', 'related', 'unrelated', 'neutral'].includes(target)){
            removeElementsActives()
        }
	})

    useEffect(() => {

        if (updated == 'error') {
            //setSubmitted(false)
        }

        if (updated == 'success') {


            let date = new Date();
            let startDate = Moment(new Date()).format('YYYY-MM-DD');
            let endDate = Moment(new Date()).format('YYYY-MM-DD');

            let teams = null;
            let teammates = null;

            if (filter.date) {
                startDate = filter.date.startDate;
                endDate = filter.date.endDate;
            }

            if (filter.teams) {
                teams = filter.teams;
            }
            if (filter.teammates) {
                teammates = filter.teammates;
            }

            activitiesActions.getActivityDetailsTeams(current.trackedId, startDate, endDate, teams, teammates);
            activitiesActions.getActivityDetailsTeammates(current.trackedId, startDate, endDate, teams, teammates);
        }

    }, [updated])


    return (


        <div className="dataTable teams relations">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <div className="arrowDown"></div>
                                                : <div className="arrowUp"></div>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>


        </div>


    )
};

function mapStateToProps(state) {

    return {
        detailsTeams: state.activities.detailsTeams,
        loadingDetailsTeams: state.activities.loadingDetailsTeams,
        stats: state.stats.payload,
        permissions: state.authentication.permissions,
        updated: state.activities.updated,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

function mapDispatchToProps(dispatch) {

    return {
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerActivityTableTeams)
