import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../_actions';
import utf8 from 'utf8'


import IconWindows from "../_icons/IconWindows";
import IconMac from "../_icons/IconMac";
import Moment from "moment-timezone";
import { AndroidLogo, CopySimple } from 'phosphor-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@material-ui/core';
import { Android } from '@material-ui/icons';

function DownloadApps(props) {
    const { t, i18n } = useTranslation('common');

    const [apps, setApps] = useState({
        android: {
            version: '1.0.0',
            url: "https://befective-agent.s3.amazonaws.com/current/mobile/android/befective.apk",
            available: true
        },
        ios: {
            version: '1.0.0',
            url: null,
            available: false
        }
    });

    const [copied, setCopied] = useState({
        text: t('download.info_copy'),
        value: false
    });

    function download(type, version) {
        if (type === "android") {

            const link = document.createElement('a');
            link.href = apps.android.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        if (type === "ios") {

            const link = document.createElement('a');
            link.href = apps.ios.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const onCopyLink = () => {
        setCopied({ text: t('download.copied'), value: true })
        setTimeout(function () {
            setCopied({ text: t('download.info_copy'), value: false })
        }, 3000);
    }

    return (

        <div className={'tab full active'}>
            <div className="download">
                <section style={{marginBottom:'0px'}}>

                    <h3>{t("download.title_download_mobile")}</h3>
                    {/* <p>{t("download.basic_info")}</p> */}

                    <div className="options">
                        <div className="option">
                            {
                                apps.android.available ?
                                    <div>
                                        <button style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                                            download('android', apps.android.version)
                                        }}>
                                            <div>
                                                <Android fontSize='inherit' />
                                            </div>
                                            {t("download.for_android")}
                                        </button>
                                        <Tooltip className={`tooltip-copy ${copied?.value && 'copied'}`} title={copied?.text} arrow>
                                            <CopyToClipboard text={apps.android.url} onCopy={onCopyLink}>
                                                <button className='button-clipboard'>
                                                    <CopySimple size={22} />
                                                </button>
                                            </CopyToClipboard>
                                        </Tooltip>
                                    </div>
                                    :
                                    <div>
                                        <button style={{ display: 'flex', alignItems: 'center' }} disabled>
                                            <div>
                                                <Android fontSize='inherit' />
                                            </div>
                                            {t("download.for_android")}
                                        </button>
                                        <button className='button-clipboard tooltip-copy' disabled>
                                            <CopySimple size={22} />
                                        </button>
                                    </div>
                            }
                        </div>

                        <div className="option">
                            {
                                apps.ios.available ?
                                    <div>
                                        <button onClick={() => {
                                            download('ios', apps.ios.version)
                                        }}>
                                            <IconMac />
                                            {t("download.for_ios")}
                                        </button>
                                        <Tooltip className={`tooltip-copy ${copied?.value && 'copied'}`} title={copied?.text} arrow>
                                            <CopyToClipboard text={apps.ios.url} onCopy={onCopyLink}>
                                                <button className='button-clipboard'>
                                                    <CopySimple size={22} />
                                                </button>
                                            </CopyToClipboard>
                                        </Tooltip>
                                    </div>
                                    :
                                    <div>
                                        <button disabled>
                                            <IconMac />
                                            {t("download.for_ios")}
                                        </button>
                                        <button className='button-clipboard tooltip-copy' disabled>
                                            <CopySimple size={22} />
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
};


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        userDetails: state.authentication.userDetails,
        updated: state.users.updated,
        downloads: state.downloads,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadApps));
