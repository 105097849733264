import {downloadsConstants} from '../_constants';


export function downloads(state = {}, action) {
    switch (action.type) {
        case downloadsConstants.GET_DOWNLOADS_REQUEST:
            return {

                loading: true
            };
        case downloadsConstants.GET_DOWNLOADS_SUCCESS:
            return {

                list: action.payload,
                loading: false
            };
        case downloadsConstants.GET_DOWNLOADS_FAILURE:
            return {

                error: action.error,
                loading: true
            };


        default:
            return state
    }
}
