import { Container, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonProductivityPage(props) {

  return (

    <Container component={'main'} maxWidth="xl" sx={{ flexGrow: 1, padding: '16px 0px', marginTop:'-80px' }} >

      <Grid container spacing={2} mt={'0px'} mb={'0px'}>
        <Grid item xs={12} >
          <Skeleton width={'100%'} height={600} variant='rounded' />
        </Grid>
      </Grid>
    </Container>
  )
}