import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation, initReactI18next } from "react-i18next";
import { makeStyles } from '@material-ui/styles';

import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import './styles.scss';
import { TableMessages } from '../../_components/messages_page/TableMessages';
import { HeaderMessages } from '../../_components/messages_page/HeaderMessages';
import ModalNewMessage from '../../_components/messages_page/ModalNewMessage';
import { api } from '../../_helpers/api';
import { useMessagesCount } from '../../contexts/MessagesCountContext';

const useStyles = makeStyles((theme) => ({

  root: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      marginRight: '0px'
    },
    '& .MuiTextField-root': {
      width: '100%',
      borderColor: '#e3e9f1',
      marginBottom: '24px',
      '& .MuiOutlinedInput-root': {
        borderColor: '#e3e9f1',
        borderRadius: '0px',
        '& input': {
          width: '100%',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3f51b5',
        borderWidth: '1px'
      }
    },
  },
}));



function MessagesPage(props) {
  const classes = useStyles();
  const { messagesNotRead } = useMessagesCount()
  const { t, i18n } = useTranslation('common');
  const [messagesData, setMessagesData] = useState({});
  const [messagesDataFiltered, setMessagesDataFiltered] = useState([]);
  const [openModalNewMessage, setOpenModalNewMessage] = useState(false);
  const [teammates, setTeammates] = useState([]);
  const { userDetails, alertActions } = props

  function handleFilterMessages(searchContent) {

    if (searchContent === '') {
      setMessagesDataFiltered(messagesData?.messages)
    } else {
      const messagesFilteredForTile = messagesData.messages.filter(item => item.subject.toLowerCase().includes(searchContent.toLowerCase()));

      const messagesFilteredForId = messagesData.messages.filter(item => {
        if (
          String(item.id) === searchContent
          && !item.subject.toLowerCase().includes(searchContent.toLowerCase())
          && !item.message_body.toLowerCase().includes(searchContent.toLowerCase())
        ) {
          return item
        }

        return false
      })

      const messagesFilteredForMessage = messagesData.messages.filter(item => {
        if (
          item.message_body.toLowerCase().includes(searchContent.toLowerCase())
          && String(item.id) !== searchContent
          && !item.subject.toLowerCase().includes(searchContent.toLowerCase())
        ) {
          return item
        }

        return false
      })

      setMessagesDataFiltered([...messagesFilteredForTile, ...messagesFilteredForId, ...messagesFilteredForMessage])
    }
  }

  const getMessagesData = async () => {
    try {
      const response = await api.get(`/messages/my-messages?organization_id=${userDetails?.organizationId}&owner_id=${userDetails?.mu_id}&archived=false`)

      setMessagesData({
        total_received: 0,
        total_sent: 1,
        total_archived: 0,
        messages: response?.data
      })

      setMessagesDataFiltered(response?.data)

    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('calls_page.error.get_calls'))
    }
  }

  const getUnifiedTeammates = async () => {
		try {
			const response = await api.get(`/messages/get-teammates?organization_id=${userDetails?.organizationId}`)

			if(response.status === 200){
				setTeammates(response.data)
			}
		} catch (error) {
			console.log(error.response)
		}
	}

  useEffect(() => {
    document.title = 'Befective | ' + t('messages.messages');
    getMessagesData()
    getUnifiedTeammates()

  }, []);

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          actionNewMessages={true}
          setOpenModalNewMessage={setOpenModalNewMessage}
          total_msg_not_read={messagesNotRead}
        />

        {/* PAGE STARTS */}
        <div className="container-calls-page">
          <HeaderMessages
            total_received={messagesData?.total_received}
            total_sent={messagesData?.total_sent}
            total_archived={messagesData?.total_archived}
            handleFilterCalls={handleFilterMessages}
            tab={'sent'}
          />
          <TableMessages
            data={messagesDataFiltered}
            teammates={teammates}
            alertActions={alertActions}
            updateInfo={getMessagesData}
            userDetails={userDetails}
          />
        </div>
        {/* PAGE ENDS */}

        <ModalNewMessage
          openModalNewMessage={openModalNewMessage}
          setOpenModalNewMessage={setOpenModalNewMessage}
          userDetails={userDetails}
          teammates={teammates}
          alertActions={alertActions}
          updateInfo={getMessagesData}
        />
        
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage)