import React from 'react';

const IconMovimentation = props => {
  return (
    <svg id="Reg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1217" data-name="Retângulo 1217" width="19" height="19" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(0.792 0.783)">
        <path id="Caminho_140" data-name="Caminho 140" d="M2,10.695a8.708,8.708,0,0,0,16.506,3.84.789.789,0,1,0-1.385-.752,7.046,7.046,0,0,1-6.413,4.037,7.125,7.125,0,1,1,5.3-11.875h-2.1a.831.831,0,0,0-.831.673.792.792,0,0,0,.792.91h3.958a.792.792,0,0,0,.792-.792V2.818a.831.831,0,0,0-.673-.831.792.792,0,0,0-.91.792v1.94A8.708,8.708,0,0,0,2,10.695Z" transform="translate(-2 -1.977)" fill="#98acc1" />
        <path id="Caminho_141" data-name="Caminho 141" d="M26.75,19.917a.673.673,0,0,1-.4-.119L22,17.185V12.792a.792.792,0,1,1,1.583,0v3.523l3.563,2.138a.789.789,0,0,1-.4,1.465Z" transform="translate(-14.083 -8.033)" fill="#98acc1" />
      </g>
    </svg>
  )
}

export default IconMovimentation;