import {settingsConstants} from '../_constants';
import {settingsService} from '../_services';
import {alertActions} from '.';

export const settingsActions = {
    getCompanyInformation,
    putCompanyInformation,
    getCompanyAdministrators,
    getCompanyAdministrator,
    deleteCompanyAdministrator,
    postCompanyInviteAdministrator,
    putCompanyInviteAdministrator,
    getCompanyDaysoff,
    getCompanyDaysoffSpecific,
    getCompanyInventory,
    deleteCompanyInventory,
    postCompanyDaysoff,
    deleteCompanyDaysoff
};


function getCompanyInformation() {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyInformation()
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.GET_COMPANY_INFORMATION_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_INFORMATION_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_INFORMATION_FAILURE, error}
    }
}

function putCompanyInformation(form) {
    return dispatch => {
        dispatch(request());

        settingsService.putCompanyInformation(form)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.PUT_COMPANY_INFORMATION_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.PUT_COMPANY_INFORMATION_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.PUT_COMPANY_INFORMATION_FAILURE, error}
    }
}

function getCompanyAdministrators() {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyAdministrators()
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATORS_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATORS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATORS_FAILURE, error}
    }
}

function getCompanyAdministrator(user_id) {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyAdministrator(user_id)
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATOR_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATOR_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_ADMINISTRATOR_FAILURE, error}
    }
}

function postCompanyDaysoff(form, id, type) {
    return dispatch => {
        dispatch(request());

        settingsService.postCompanyDaysoff(form, id, type)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));
                },
                error => {
                    dispatch(alertActions.error(error[0]))
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: settingsConstants.POST_COMPANY_DAYSOFF_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.POST_COMPANY_DAYSOFF_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.POST_COMPANY_DAYSOFF_FAILURE, error}
    }
}

function postCompanyInviteAdministrator(form, teams) {
    return dispatch => {
        dispatch(request());

        settingsService.postCompanyInviteAdministrator(form, teams)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.POST_COMPANY_ADMINISTRATORS_INVITE_FAILURE, error}
    }
}

function putCompanyInviteAdministrator(form, teams, user_id) {
    return dispatch => {
        dispatch(request());

        settingsService.putCompanyInviteAdministrator(form, teams, user_id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.PUT_COMPANY_ADMINISTRATORS_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.PUT_COMPANY_ADMINISTRATORS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.PUT_COMPANY_ADMINISTRATORS_FAILURE, error}
    }
}

function deleteCompanyAdministrator(user_id) {
    return dispatch => {
        dispatch(request());

        settingsService.deleteCompanyAdministrator(user_id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.DELETE_COMPANY_ADMINISTRATORS_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.DELETE_COMPANY_ADMINISTRATORS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.DELETE_COMPANY_ADMINISTRATORS_FAILURE, error}
    }
}

function getCompanyDaysoff() {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyDaysoff()
            .then(
                payload => {
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_FAILURE, error}
    }
}

function getCompanyDaysoffSpecific(id, type) {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyDaysoffSpecific(id, type)
            .then(
                payload => {
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_DAYSOFF_SPECIFIC_FAILURE, error}
    }
}


function getCompanyInventory() {
    return dispatch => {
        dispatch(request());

        settingsService.getCompanyInventory()
            .then(
                payload => {
                    dispatch(success(payload))

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };


    function request() {
        return {type: settingsConstants.GET_COMPANY_INVENTORY_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.GET_COMPANY_INVENTORY_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.GET_COMPANY_INVENTORY_FAILURE, error}
    }
}

function deleteCompanyDaysoff(id) {
    return dispatch => {
        dispatch(request());

        settingsService.deleteCompanyDaysoff(id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.DELETE_COMPANY_DAYSOFF_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.DELETE_COMPANY_DAYSOFF_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.DELETE_COMPANY_DAYSOFF_FAILURE, error}
    }
}

function deleteCompanyInventory(user) {
    return dispatch => {
        dispatch(request());

        settingsService.deleteCompanyInventory(user)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.DELETE_COMPANY_INVENTORY_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.DELETE_COMPANY_INVENTORY_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.DELETE_COMPANY_INVENTORY_FAILURE, error}
    }
}

function deleteCompanyPeriodicReports(id) {
    return dispatch => {
        dispatch(request());

        settingsService.deleteCompanyPeriodicReport(id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload));

                },
                error => {
                    dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_REQUEST}
    }

    function success(payload) {
        return {type: settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_SUCCESS, payload}
    }

    function failure(error) {
        return {type: settingsConstants.DELETE_COMPANY_PERIODIC_REPORT_FAILURE, error}
    }
}
