import React, { useEffect, useState } from 'react'
import styles from './HeaderColabDetails.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../../../../_helpers';
// import { api } from '../../_helpers/api';
// import { authHeader } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  customLabel: {
    fontSize: '10px'
  }

}));

export default function HeaderColabDetails({
  stats
}) {
  const { t, i18n } = useTranslation('common');

  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{`${t("software_inventory.details.title_header")} ${stats.software_info.label || ""} - ${stats.user.username || ""}`}</h6>
        </div>
      </div>
    
    </header>
  )
}
