import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowRight, Timer } from 'phosphor-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconIn from '../_icons/IconIn';
import IconOut from '../_icons/IconOut';

import moment from 'moment';
import { authHeader, getCorrectPeriod, getRemainingPoints } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './DashboardProgress.module.scss';
import { DashboardTimeRuler } from './DashboardTimeRuler';
import { DashboardProgressRuler } from './DashboardProgressRuler';

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

export function DashboardProgress({
  alertActions,
  userDetails,
  todayInfo
}) {
  const { t, i18n } = useTranslation('common');

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="#fff"
      px={3}
      py={2}
      mt={3}
      mb={3}
      boxShadow="0px 2px 3px -2px rgba(0, 0, 0, 0.2)"
      borderRadius="2px"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="row" gap={8} alignItems="center">
          <Typography color="#22BEAD" component="span" fontSize={18} mb="3px" fontWeight={500}>
            {t('checkin.drawer_details_day.today')}
          </Typography>

          {/* <Stack direction="row" gap={'2px'} flexWrap={'wrap'}>
            {todayInfo && (
              <>
                {todayInfo.points.map((point, idx) => (
                  <Fragment key={`${point.id} ${idx}`}>

                    <Box
                      key={idx}
                      display="flex"
                      alignItems="center"
                      gap="4px"
                      padding={'4px'}
                      className={[
                        style.icon,
                        [3, 4, 5, 6, 7].includes(point.status_id) && style.iconHover
                      ].join(' ')}
                      onClick={() => {
                        [5, 6, 7].includes(point.status_id)
                          ? handleOpenDrawerSolicitations()
                          : [3, 4].includes(point.status_id)
                          && handleOpenModalJustify(todayInfo.periods, point, todayInfo.day, todayInfo.summaries_date_id, idx === 0)
                      }}
                    >
                      {point.type === 'IN' ? (
                        <IconIn fill={statusColors[point.status_id - 1]} />
                      ) : (
                        <IconOut fill={statusColors[point.status_id - 1]} />
                      )}
                      <Typography
                        color={
                          [6, 7].includes(point.status_id)
                            ? "#187DFF"
                            : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                        }
                        component="span"
                        fontSize={14}
                        lineHeight="initial"
                      >
                        {[5, 6, 7].includes(point.status_id) ? (
                          point.status_id === 7 ? (
                            "Enviado"
                          ) : point.status_id === 6 ? (
                            point.time.split(':')[0] + ':' + point.time.split(':')[1]
                          ) : (
                            "Recusado"
                          )
                        ) : (
                          point.time.split(':')[0] + ':' + point.time.split(':')[1]
                        )}
                      </Typography>
                    </Box>
                  </Fragment>
                ))
                }
                {
                  getRemainingPoints(todayInfo)?.map((item, index) => (

                    <Box
                      key={index}
                      style={{padding: 0 }}
                      align="center"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        maxWidth="76px"
                        margin="0 auto"
                        className={[
                          style.icon
                        ].join(' ')}
                        padding={'4px'}
                      >
                        {item?.type === 'IN' ? (
                          <IconIn fill={"#C1CDDA"} />
                        ) : (
                          <IconOut fill={"#C1CDDA"} />
                        )}
                        <Typography color="#4F7091" component="span" fontSize={14} lineHeight="initial">
                          --:--
                        </Typography>
                      </Box>
                    </Box>


                  ))
                }
              </>
            )
            }
          </Stack> */}
        </Box>

        {/* <Tooltip
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#FDBE2B",
                color: "black",
                fontWeight: 500,
                width: '75px'
              }
            }
          }}
          title="Soma do tempo em checkin"
          placement="right"
        >

          <Box display="flex" alignItems="center" gap="4px" className={style.time}>
            <Timer size={17} />
            <Typography
              color="#9da5af"
              component="span"
              fontSize={16}
              lineHeight="initial"
            >
              {todayInfo?.hours_amount}
            </Typography>
          </Box>
        </Tooltip> */}

        {/* <Box className={style.arrow}>
          <ArrowRight size={20} />
        </Box> */}
      </Stack>

      <Box display="flex" mt="20px">
        <DashboardTimeRuler data={todayInfo} />
      </Box>

      <Box display="flex" >
        <DashboardProgressRuler data={todayInfo} />
      </Box>
    </Box>
  );
}
