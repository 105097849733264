/* eslint-disable no-restricted-globals */
import config from 'config';
import {authHeader} from '../_helpers';

export const activitiesService = {
    getActivities,
    getActivitiesCategories,
    getActivityDetailsTeam,
    getActivityDetailsTeammates,
    getActivityDetailsSpecific,
    setRelation,
    setCategoryRelation,
    getProductivityActivities,
    getProductivityInactivity,
    setTransactional
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getActivities(startDate, endDate, teams, teammates, offset, software, site, offline, productive, neutral, unproductive, search) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryOffset = '';
    let trackedTypes = '';
    let efficiencies = '';
    let queryTeams = ''
    let queryTeammates = ''
    let textFieldSearch = ''

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    if (offset) {
        queryOffset = '&offset=' + offset;
    }

    if (productive || neutral || unproductive) {
        efficiencies += "&efficiencies="
        if (productive) {
            efficiencies += "productive,";
        }
        if (neutral) {
            efficiencies += "neutral,";
        }
        if (unproductive) {
            efficiencies += "unproductive";
        }

    }

    if (software || site || offline) {
        trackedTypes += "&trackedTypes="
        if (software) {
            trackedTypes += "Software,";
        }
        if (site) {
            trackedTypes += "Site,";
        }
        if (offline) {
            trackedTypes += "Outdoor";
        }

    }

    if(search) {
        textFieldSearch = `&textFieldSearch=${search}`
    }


    return fetch(`${config.apiNodeUrl}/activities?limit=50&startDate=${startDate}&endDate=${endDate}` + queryTeams + queryTeammates + queryOffset + trackedTypes + efficiencies + textFieldSearch, requestOptions).then(handleResponse);
}

function getActivityDetailsTeam(trackedId, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = ''
    let queryTeammates = ''
    let trackedTypes = '';
    let efficiencies = '';

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }
    }
    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }
    }

    if (productive || neutral || unproductive) {
        efficiencies += "&efficiencies="
        if (productive) {
            efficiencies += "productive,";
        }
        if (neutral) {
            efficiencies += "neutral,";
        }
        if (unproductive) {
            efficiencies += "unproductive";
        }

    }

    if (software || site || offline) {
        trackedTypes += "&trackedTypes="
        if (software) {
            trackedTypes += "Software,";
        }
        if (site) {
            trackedTypes += "Site,";
        }
        if (offline) {
            trackedTypes += "Outdoor";
        }

    }

    return fetch(`${config.apiNodeUrl}/activities/${trackedId}?startDate=${startDate}&endDate=${endDate}&type=teams` + queryTeammates + queryTeams + trackedTypes + efficiencies, requestOptions).then(handleResponse);
}

function getActivityDetailsTeammates(trackedId, startDate, endDate, teams, teammates, software, site, offline, productive, neutral, unproductive) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let queryTeams = ''
    let queryTeammates = ''
    let trackedTypes = '';
    let efficiencies = '';

    if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
            if (nt == 0) {
                queryTeams += team.id;
            } else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }


    if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
            if (nt == 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    if (productive || neutral || unproductive) {
        efficiencies += "&efficiencies="
        if (productive) {
            efficiencies += "productive,";
        }
        if (neutral) {
            efficiencies += "neutral,";
        }
        if (unproductive) {
            efficiencies += "unproductive";
        }

    }

    if (software || site || offline) {
        trackedTypes += "&trackedTypes="
        if (software) {
            trackedTypes += "Software,";
        }
        if (site) {
            trackedTypes += "Site,";
        }
        if (offline) {
            trackedTypes += "Outdoor";
        }

    }

    return fetch(`${config.apiNodeUrl}/activities/${trackedId}?startDate=${startDate}&endDate=${endDate}&type=teammates` + queryTeammates + queryTeams + trackedTypes + efficiencies, requestOptions).then(handleResponse);
}

function getActivityDetailsSpecific(startDate, endDate, machineUserId, trackedId, teams, teammates) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/activities/${trackedId}/teammates/${machineUserId}?startDate=${startDate}&endDate=${endDate}`, requestOptions).then(handleResponse);
}

function getActivitiesCategories() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/activities/categories`, requestOptions).then(handleResponse);
}

function setRelation(teamId, efficiencyId, efficiencyName, subteams, company) {
    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            teamId: teamId,
            efficiencyId: efficiencyId,
            efficiencyName: efficiencyName,
            subteams: subteams,
            company: company
        })
    }

    return fetch(`${config.apiNodeUrl}/activities/efficiencies/${efficiencyId}`, requestOptions).then(handleResponse)
}

function setTransactional(update_type, group_id, tracked_id, transactional) {

    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            update_type, 
            group_id, 
            tracked_id, 
            transactional
        })
    }

    return fetch(`${config.apiNodeUrl}/activities/transactional`, requestOptions).then(handleResponse)
}

function setCategoryRelation(teamId, categoryId, efficiencyName, subCategory) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            teamId: teamId,
            categoryId: categoryId,
            efficiencyName: efficiencyName,
            subCategory: subCategory
        })
    }

    return fetch(`${config.apiNodeUrl}/activities/categories/${categoryId}`, requestOptions).then(handleResponse)
}


function getProductivityActivities(date) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(`${config.apiNodeUrl}/stats/my-productivity/activities?current_date=${date}`, requestOptions).then(handleResponse);
}

function getProductivityInactivity(start_date, end_date) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(`${config.apiNodeUrl}/stats/my-productivity/inactivity?start_date=${start_date}&end_date=${end_date}`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
