import React from 'react';

const IconBefective = props => {
    return (

        <svg id="Grupo_98" data-name="Grupo 98" xmlns="http://www.w3.org/2000/svg" width="12.14" height="16" viewBox="0 0 12.14 16">
            <path id="Caminho_69" data-name="Caminho 69" d="M28.285,57.917l1.825,1.053V56.865Z" transform="translate(-24.004 -44.935)" fill="#98acc1" />
            <path id="Caminho_70" data-name="Caminho 70" d="M12.369,41.693l1.895,1.123,3.368-1.965V38.675Z" transform="translate(-11.527 -30.675)" fill="#98acc1" />
            <path id="Caminho_71" data-name="Caminho 71" d="M8.471,23.669v2.175l6.105-3.509V20.161Z" transform="translate(-8.471 -16.161)" fill="#98acc1" />
            <path id="Caminho_72" data-name="Caminho 72" d="M8.471,5.155V7.33l6.105-3.509V1.646h0Z" transform="translate(-8.471 -1.646)" fill="#98acc1" />
            <path id="Caminho_73" data-name="Caminho 73" d="M42.765,42.729V40.624L36.73,44.133h0V48.2l2.947-3.649Z" transform="translate(-30.624 -32.203)" fill="#98acc1" />
            <path id="Caminho_74" data-name="Caminho 74" d="M36.73,25.943v2.175l6.035-3.509V22.434Z" transform="translate(-30.624 -17.943)" fill="#98acc1" />
            <path id="Caminho_75" data-name="Caminho 75" d="M36.73,12.706v2.246l5.333-3.088-1.895-1.123Z" transform="translate(-30.624 -8.776)" fill="#98acc1" />
            <path id="Caminho_76" data-name="Caminho 76" d="M36.73,3.751,38.554,2.7,36.73,1.646Z" transform="translate(-30.624 -1.646)" fill="#98acc1" />
        </svg>


    )
}

export default IconBefective;