import React from 'react';

const IconGrid = props => {
  return (
    <svg id="kanban" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1301" data-name="Retângulo 1301" width="20" height="20" fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons" transform="translate(1.667 1.667)">
        <g id="Grupo_276" data-name="Grupo 276">
          <path id="Caminho_253" data-name="Caminho 253" d="M10.667,4H4.833A.833.833,0,0,0,4,4.833v5.833a.833.833,0,0,0,.833.833h5.833a.833.833,0,0,0,.833-.833V4.833A.833.833,0,0,0,10.667,4ZM9.833,9.833H5.667V5.667H9.833Z" transform="translate(-4 -4)" fill="#98acc1" />
          <path id="Caminho_254" data-name="Caminho 254" d="M32.667,4H26.833A.833.833,0,0,0,26,4.833v5.833a.833.833,0,0,0,.833.833h5.833a.833.833,0,0,0,.833-.833V4.833A.833.833,0,0,0,32.667,4Zm-.833,5.833H27.667V5.667h4.167Z" transform="translate(-16.833 -4)" fill="#98acc1" />
          <path id="Caminho_255" data-name="Caminho 255" d="M10.667,26H4.833A.833.833,0,0,0,4,26.833v5.833a.833.833,0,0,0,.833.833h5.833a.833.833,0,0,0,.833-.833V26.833A.833.833,0,0,0,10.667,26Zm-.833,5.833H5.667V27.667H9.833Z" transform="translate(-4 -16.833)" fill="#98acc1" />
          <path id="Caminho_256" data-name="Caminho 256" d="M32.667,26H26.833a.833.833,0,0,0-.833.833v5.833a.833.833,0,0,0,.833.833h5.833a.833.833,0,0,0,.833-.833V26.833A.833.833,0,0,0,32.667,26Zm-.833,5.833H27.667V27.667h4.167Z" transform="translate(-16.833 -16.833)" fill="#98acc1" />
        </g>
      </g>
    </svg>

  )
}

export default IconGrid;