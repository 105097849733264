import React from 'react';

const IconNotification = props => {
    return (
        
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">

        <g id="icons_Q2" data-name="icons Q2" transform="translate(1 0.667)">
          <g id="Grupo_100" data-name="Grupo 100">
            <path id="Caminho_89" data-name="Caminho 89" d="M16.067,11.4a1.5,1.5,0,0,1-.4-.967V9a4.773,4.773,0,0,0-5-5,4.739,4.739,0,0,0-5,5v1.667a.867.867,0,0,1-.233.567L4.2,12.467a.667.667,0,0,0-.2.467v2.4A.667.667,0,0,0,4.667,16h3.7a2.333,2.333,0,0,0,4.6,0h3.7a.667.667,0,0,0,.667-.667v-2.4a.667.667,0,0,0-.2-.467ZM16,14.667H5.333V13.233l1.033-1.067A2.133,2.133,0,0,0,7,10.667V9a3.413,3.413,0,0,1,3.667-3.667A3.444,3.444,0,0,1,14.333,9v1.433a2.867,2.867,0,0,0,.767,1.9l.9.9Z" transform="translate(-3.667 -3.333)" fill="#98acc1"/>
            <path id="Caminho_90" data-name="Caminho 90" d="M4.333,7.708A5.7,5.7,0,0,1,6.5,3.241a.654.654,0,1,0-.8-1.033A6.867,6.867,0,0,0,3,7.708a.667.667,0,0,0,1.333,0Z" transform="translate(-3 -2.041)" fill="#98acc1"/>
            <path id="Caminho_91" data-name="Caminho 91" d="M34.767,2.133a.667.667,0,1,0-.8,1.067,5.633,5.633,0,0,1,2.167,4.467.667.667,0,0,0,1.333,0,6.967,6.967,0,0,0-2.7-5.533Z" transform="translate(-23.466 -2)" fill="#98acc1"/>
          </g>
        </g>
      </svg>
      
    )
}

export default IconNotification;