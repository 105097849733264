import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export function KanbanAddCard({ kr, showModalNewTask }) {


  const { t } = useTranslation('common');

  function handleOpenModalNewTask() {
    showModalNewTask(kr)
  }

  return (
    <button 
      className='kanban-add-card' 
      onClick={() => handleOpenModalNewTask()}
    >
      {t('tasks.new_task')} +
    </button>
  );
}
