import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {bindActionCreators} from 'redux'
import {useTable, useSortBy, useFilters} from 'react-table'

import {activitiesActions, filterActions} from '../../_actions';
import {statsActions} from '../../_actions';
import IconInfo from '../_icons/IconInfo';
import IconSoftware from '../_icons/IconSoftware';
import IconSite from '../_icons/IconSite';
import IconOutdoor from '../_icons/IconOutdoor';
import {secondsToHm} from '../../_helpers';

function ActivitiesFull(props) {
    const {t} = useTranslation('common');

    let activities = props.activities || [];
    let stats = props.stats || [];

    const data = useMemo(() => activities.rows ? activities.rows : []);

    let TotalWorkedTime = stats.TotalWorkedTime || 0;
    let TotalProductiveTime = stats.TotalProductiveTime || 0;

    const columns = React.useMemo(
        () => [
            {
                id: 'exhibitionName',
                Header: t('activities.activity'),
                accessor: c => c.exhibitionName.toLowerCase(),
                Cell: (d) => {

                    let iconType = '';

                    if (d.row.original.trackedType == "Software") {
                        iconType = <IconSoftware/>
                    }
                    if (d.row.original.trackedType == "Site") {
                        iconType = <IconSite/>
                    }
                    if (d.row.original.trackedType == "Outdoor") {
                        iconType = <IconOutdoor/>
                    }

                    let current = d.row.original;

                    return <div className="activity noLink" title={d.row.original.exhibitionName}>
                        <div className="icon">{iconType}</div>

                        {d.row.original.exhibitionName.substring(0, 50)}</div>
                }
            },
            {
                Header: t('activities.category'),
                accessor: 'categoryName',
            },
            {
                Header: t('activities.total_time'),
                accessor: 'duration',
                Cell: (d) => {

                    let percentTotalTime = "";
                    if (TotalWorkedTime > 0) {
                        percentTotalTime = Math.round((d.value / TotalWorkedTime) * 100) + "%";
                    }

                    return <div className="totalTime">
                        <div className="time">{secondsToHm(d.value)}</div>
                        <span>{percentTotalTime}</span></div>
                }
            },
            {
                Header: t('activities.work_related'),
                accessor: 'productiveDuration',
                Cell: (d) => {

                    let percentRelated = "0%";


                    if (d.value > 0 && TotalProductiveTime > 0) {
                        percentRelated = Math.round((d.value / TotalProductiveTime) * 100) + "%";
                    }


                    return <div className="totalWork">

                        <span>{percentRelated}</span></div>
                }
            },

        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow

    } = useTable({
        columns, data, disableMultiSort: true,
        initialState: {
            sortBy: [
                {
                    id: 'duration',
                    desc: true
                }
            ]
        }
    }, useFilters, useSortBy);


    return (
        <div className="box noBg">

            <div className="pageHeader activities">
                <div className="multi">
                    <h3>{t('activities.total_worked_hours')}: <b> {secondsToHm(TotalWorkedTime)}</b><span><IconInfo/><div
                        className="tooltip">{/*<IconInfo/>*/}{t('activities.tooltip1')}</div></span></h3>
                    <h3 className="work">{t('activities.total_worked_related_hours')}: <b> {secondsToHm(TotalProductiveTime)}</b><span><IconInfo/><div
                        className="tooltip">{/*<IconInfo/>*/}{t('activities.tooltip2')}</div></span></h3>
                </div>


            </div>
            <div className="dataTable activities">
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <div className="arrowDown"></div>
                                                        : <div className="arrowUp"></div>
                                                    : ''}
                                            </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, i) => {

                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                }

                {data.length == 0 &&
                    <p className={'noData'}>{t('no_data')}</p>
                }
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
        statsActions: bindActionCreators(statsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),

    }
}

function mapStateToProps(state) {

    return {
        activities: state.activities,
        stats: state.stats.payload,
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesFull)
