






import React, { useEffect, useState } from 'react';

export function ListDivider({width = 100,heigth = 3,color="rgb(79, 112, 145)",position="flex-start",pt=5}) {

  return (   
    <div style={{display:"flex",backgroundColor:`${color}`,height:`${heigth}px`, width:`${width}%`,borderRadius:"8px",marginTop:`${pt}px`,marginBottom:"10px",alignSelf:position}}></div>
  );
}