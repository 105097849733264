import React from 'react';

const IconEdit = props => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back"
                  d="M18,11.25H8.872l4.193-4.193L12,6,6,12l6,6,1.057-1.057L8.872,12.75H18Z" transform="translate(-6 -6)"
                  fill="#98acc1"/>
        </svg>


    )
}

export default IconEdit;
