// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vW4PiN91gv4QZYMxZWibbg\\=\\= {\n  padding: 24px 32px;\n}\n\n.dgPIjEoQ7Yxma1s5jsPIOQ\\=\\= {\n  display: flex;\n  justify-content: center;\n  margin-right: 8px;\n  align-items: center;\n  background-color: #fff;\n  border-radius: 50%;\n  height: 50px;\n  width: 50px;\n}\n.dgPIjEoQ7Yxma1s5jsPIOQ\\=\\= svg {\n  width: 24px;\n  height: 24px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Spaces/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EAEE,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".containerRoomsPage {\n  padding: 24px 32px;\n}\n\n.toastCustomIcon {\n\n  display: flex;\n  justify-content: center;\n  margin-right: 8px;\n  align-items: center;\n  background-color: #fff;\n  border-radius: 50%;\n  height: 50px;\n  width: 50px;\n\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRoomsPage": "vW4PiN91gv4QZYMxZWibbg==",
	"toastCustomIcon": "dgPIjEoQ7Yxma1s5jsPIOQ=="
};
export default ___CSS_LOADER_EXPORT___;
