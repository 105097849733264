import {combineReducers} from 'redux';

import {authentication} from './authentication.reducer';
import {authentication_mu} from './authentication_mu.reducer';
import {backfective} from './backfective.reducer';
import {activities} from './activities.reducer';
import {alert} from './alert.reducer';
import {downloads} from './downloads.reducer';
import {filter} from './filter.reducer';
import {goals} from './goals.reducer';
import {teams} from './teams.reducer';
import {menu} from './menu.reducer';
import {historical} from './historical.reducer';
import {teammates} from './teammates.reducer';
import {settings} from './settings.reducer';
import {share} from './share.reducer';
import {stats} from './stats.reducer';
import {periodic} from './periodic.reducer';
import {users} from './users.reducer';

const rootReducer = combineReducers({
    authentication,
    authentication_mu,
    activities: activities,
    alert,
    backfective,
    downloads,
    filter,
    goals,
    teams,
    menu,
    historical,
    teammates,
    settings,
    share,
    stats,
    periodic,
    users
});

export default rootReducer;
