import React, { useRef, useState, compose, useEffect  } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../../_actions';


import Skeleton from '@material-ui/lab/Skeleton';

function SettingsProfileSkeleton(props){
    const {t, i18n} = useTranslation('common');

    return (
      <div className="form">
      <div className="field">
        <Skeleton variant="text" width="100%" height={72} />
      </div>
      <div className="field">
      <Skeleton variant="text" width="100%" height={72} />
      </div>
      <div className="field">
      <Skeleton variant="text" width="100%" height={72} />
      </div>
      <div className="field">
      <Skeleton variant="text" width="100%" height={72} />
      </div>
      <div className="field">
          <Skeleton variant="text" width="100px" height={64} />
          <Skeleton variant="text" width="100px" height={64} />
      </div>
    </div>
    )
    };
     


function mapStateToProps(state) {

  return {auth: state.authentication.user}
}

function mapDispatchToProps(dispatch){
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsProfileSkeleton));