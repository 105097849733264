import React, { useEffect, useState } from 'react'
import styles from './TeamInfoHeader.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import PopoverConfirm from './PopoverConfirmDelete';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import ModalColabMoveTo from './ModalColabMoveTo';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  }

}));

export default function TeamInfoHeader({
  organization,
  selectedRows,
  alertActions,
  getColabsData,
  teams,
  setSelectedRows,
  teammates,
  activeFilter,
  setActiveFilter,
  userDetails
}) {
  const { t } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles()

  const [openModalMoveTo, setOpenModalMoveTo] = useState(null);
  const [colabsSelected, setColabsSelected] = useState([]);

  const [anchorElConfirmDeactiveColab, setAnchorElConfirmDeactiveColab] = useState(null);
  const openConfirmDeactiveColab = Boolean(anchorElConfirmDeactiveColab);
  const idConfirmDeactiveColab = openConfirmDeactiveColab ? 'simple-popover-confirm-deactive-colab' : undefined;

  const handleClickConfirmDeactiveColab = (event) => {
    setAnchorElConfirmDeactiveColab(event.currentTarget);
  };

  const [anchorElConfirmActiveColab, setAnchorElConfirmActiveColab] = useState(null);
  const openConfirmActiveColab = Boolean(anchorElConfirmActiveColab);
  const idConfirmActiveColab = openConfirmActiveColab ? 'simple-popover-confirm-active-colab' : undefined;

  const handleClickConfirmActiveColab = (event) => {
    setAnchorElConfirmActiveColab(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElConfirmDeactiveColab(null)
    setAnchorElConfirmActiveColab(null)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  const handleConfirmDeactiveColab = async () => {
    try {
      if (selectedRows.length === 0) {
        throw new Error('Sem colaboradores selecionados')
      }
      const payload = {
        type: 'USER',
        action: 'DEACTIVE',
        ids: selectedRows.join(',')
      }
      const res = await api.post(`/settings/`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao desativar os colaboradores')
      }

      getColabsData({ pageIndex: 0, pageSize: 20, isLoading: false })
      handleClose()
      alertActions.success(t('settings.teams.success.deactivate_colabs'))
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirmActiveColab = async () => {
    try {
      if (selectedRows.length === 0) {
        throw new Error('Sem colaboradores selecionados')
      }
      const payload = {
        type: 'USER',
        action: 'ACTIVE',
        ids: selectedRows.join(',')
      }
      const res = await api.post(`/settings/`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao ativar os colaboradores')
      }

      getColabsData({ pageIndex: 0, pageSize: 20, isLoading: false })
      handleClose()
      alertActions.success(t('settings.teams.success.activate_colabs'))
    } catch (error) {
      console.error(error)
    }
  }


  const removeColab = (colabId) => {
    setColabsSelected(colabsSelected.filter((t) => t.id !== colabId))
    setSelectedRows(selectedRows.filter(r => r !== colabId))
  }

  useEffect(() => {
    setColabsSelected(teammates.filter(t => selectedRows.includes(t.id)))
  }, [selectedRows])


  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{organization.name}</h6>
          <div className={styles.boxStatsColabs}>
          <div className={`${styles.stats} ${activeFilter === 0 && styles.activeFilter}`} onClick={() => setActiveFilter(0)}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{`${t('settings.teams.collabs_total')}:`}</div>
              <div className={styles.numberValue}>{organization.total_colabs}</div>
            </div>

            <div className={`${styles.stats} ${activeFilter === 1 && styles.activeFilter}`} onClick={() => setActiveFilter(1)}>
              <div className={`${styles.circle} ${styles.circleGreen}`}></div>
              <div className={styles.labelStats}>{`${t('settings.teams.colabs_active')}:`}</div>
              <div className={styles.numberValue}>{organization.total_active_colab}</div>
            </div>
            <div className={`${styles.stats} ${activeFilter === 2 && styles.activeFilter}`} onClick={() => setActiveFilter(2)}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{`${t('settings.teams.colabs_inactive')}:`}</div>
              <div className={styles.numberValue}>{organization.total_inactive_colab}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boxActions}>
        {
          userDetails.allowed_update && (
            <Button disableElevation aria-describedby={id} variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMore />} className={classes.button} disabled={!(selectedRows.length > 0)}>
              {`${t('settings.teams.actions')}`}
            </Button>
          )
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#1E6B7F',
              marginTop: '10px'
            }
          }}
        >
          <Box className={styles.menuOptionsActions}>
            <button className={styles.menuOptionsActionButton} onClick={handleClickConfirmActiveColab} aria-describedby={idConfirmActiveColab}>{t('settings.teams.action_active_colabs')}</button>
            <button className={styles.menuOptionsActionButton} onClick={handleClickConfirmDeactiveColab} aria-describedby={idConfirmDeactiveColab}>{t('settings.teams.action_inactive_colabs')}</button>
            <button className={styles.menuOptionsActionButton} onClick={() => setOpenModalMoveTo(true)}>{t('settings.teams.action_move_to')}</button>
            {/* <button className={styles.menuOptionsActionButton} onClick={() => console.log('Reenviar convite')}>{t('settings.teams.action_resend_invite')}</button>
            <button className={styles.menuOptionsActionButton} onClick={() => console.log('Alterar perfil')}>{t('settings.teams.action_change_profile')}</button> */}
          </Box>
        </Popover>

        <PopoverConfirm
          idConfirm={idConfirmDeactiveColab}
          openConfirm={openConfirmDeactiveColab}
          anchorEl={anchorElConfirmDeactiveColab}
          handleClose={handleClose}
          handleConfirm={handleConfirmDeactiveColab}
          title={t('settings.teams.inactive_colab_message')}
        />

        <PopoverConfirm
          idConfirm={idConfirmActiveColab}
          openConfirm={openConfirmActiveColab}
          anchorEl={anchorElConfirmActiveColab}
          handleClose={handleClose}
          handleConfirm={handleConfirmActiveColab}
          title={t('settings.teams.active_colab_message')}
        />
      </div>

      <ModalColabMoveTo
        openModalMoveTo={openModalMoveTo}
        setOpenModalMoveTo={setOpenModalMoveTo}
        colabsSelected={colabsSelected}
        removeColab={removeColab}
        teams={teams}
        getColabsData={getColabsData}
        handleCloseAncor={handleClose}
      />

    </header>
  )
}
