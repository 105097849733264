import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import Moment from 'moment';
import { useTable, useSortBy, useFilters } from 'react-table'

import { activitiesActions, alertActions, filterActions } from '../../_actions';
import { statsActions } from '../../_actions';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import IconInfo from '../_icons/IconInfo';
import IconSoftware from '../_icons/IconSoftware';
import IconSite from '../_icons/IconSite';
import IconOutdoor from '../_icons/IconOutdoor';
import IconTeam from '../_icons/IconTeam';
import IconTeammate from '../_icons/IconTeammate';
import { makeStyles } from '@material-ui/core/styles';
import { authHeader, history, secondsToHm } from '../../_helpers';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Typography, Modal, Slide, Backdrop, CircularProgress } from '@material-ui/core';
import { ModalDetailCategory } from '../_shared/ModalDetailCategory';
import { api } from '../../_helpers/api';

import { AppWindow, FileDoc, FileText, User } from 'phosphor-react';
import { Box } from '@mui/material';
import '../_modal/styles.scss'
import IconThreePoints from '../_icons/IconThreePoints';

import { Autocomplete } from '@material-ui/lab';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';
import EditActivityModal from './EditActivityModal';

const style2 = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "40%",
	bgcolor: 'background.paper',
	borderRadius: 4,
	boxShadow: 24,
	p: 4,
	maxHeight: '90%',
	height: 'auto'
};
const style3 = {
	position: 'absolute',
	// top: '50%',
	// left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "25%",
	bgcolor: 'background.paper',
	borderRadius: 4,
	boxShadow: 24,
	p: 4,
};

const useStyles = makeStyles((theme) => ({
	modal: {
		"&:focus": {
			outline: 'none'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	infoIcon: {
		'& svg': {
			fontSize: '1.25rem'
		}
	}
}));


import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const BefectiveTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F', // Cor de fundo personalizada
    marginRight: 0, // Espaçamento à direita
    maxWidth: '380px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

function ActivitiesFull(props) {
	const { t, i18n } = useTranslation('common');
	const { currentModule, saveCurrentModule } = useCurrentModule()
	const classes = useStyles();
	const trackeds_extra = ["notepad.exe", "wordpad.exe"]
	let activities = props.activities || [];
	let stats = props.stats || [];
	const filter = props.filter || {};
	let filterSoftware = props.filter.software;
	let filterSite = props.filter.site;
	let filterOffline = props.filter.offline;
	let filterProductive = props.filter.productive;
	let filterNeutral = props.filter.neutral;
	let filterUnproductive = props.filter.unproductive;

	const { activitiesActions, userDetails, filterActions, alertActions, fetchData } = props;

	const [openModalDetailCategory, setOpenModalDetailCategory] = useState(false);
	const [categoryId, setCategoryId] = useState(null);
	const [offset, setOffset] = useState(0);
	const [remoteTracks, setRemoteTracks] = useState([]);
	const [files, setFiles] = useState([]);
	const [dataTrackedOffice, setDataTrackedOffice] = useState({ "track_name": "", users: [] });
	const [listOfLicenses, setListOfLicenses] = useState([]);


	const [selectedLS, setSelectedLS] = useState(null);
	const [selectedLSError, setSelectedLSError] = useState(false);

	const [anchorElOptions, setAnchorElOptions] = useState(null);
	const [currentTracked, setCurrentTracked] = useState(null);
	const [openModalEditActivity, setOpenModalEditActivity] = useState(null);
	const [submittingAttachLicense, setSubmittingAttachLicense] = useState(false);


	const [trackedInfo, setTrackedInfo] = useState({
		trackedOrigin: '',
		trackedId: ''
	})
	const [open, setOpen] = useState(false);
	const [openOffice, setOpenOffice] = useState(false);
	const [openLicense, setOpenLicense] = useState(false);

	async function getAllowedFiles() {
		try {

			const response = await api.get(`/settings/company/files_allowed`, {
				headers: authHeader()
			})
			if (response.data.origin_trackeds_files !== null) {
				setFiles(response.data.origin_trackeds_files.reduce((acc, curr) => {
					return [String(curr.value).toLowerCase(), ...acc]
				}, []))
			}
		} catch (e) {
			console.log("/Error ## ", e)
		}

	}

	async function getInfoRemoteTrack() {
		try {

			const dt_start = filter?.date?.startDate || Moment().format('YYYY-MM-DD')
			const dt_end = filter?.date?.endDate || Moment().format('YYYY-MM-DD')

			const response = await api.get(`/activities/get-remote-track-info?date_start=${dt_start}&date_end=${dt_end}&teammates=${filter?.teammates?.reduce((acc, cur) => { return cur.id + "," + acc }, "") || ""}&teams=${filter?.teams?.reduce((acc, cur) => { return cur.id + "," + acc }, "") || ""}`, {
				headers: authHeader()
			})

			setRemoteTracks(response.data)

		} catch (error) {
			console.log("/error: ", error)
		}
	}
	const handleOpen = () => {
		setOpen(true);
		getInfoRemoteTrack()
	}

	async function getInfoTrackOffice(tracked_origin) {
		try {
			const dt_start = filter?.date?.startDate || Moment().format('YYYY-MM-DD')
			const dt_end = filter?.date?.endDate || Moment().format('YYYY-MM-DD')

			const response = await api.get(`/activities/get-track-info?tracked_origin=${tracked_origin}&date_start=${dt_start}&date_end=${dt_end}&teammates=${filter?.teammates?.reduce((acc, cur) => { return cur.id + "," + acc }, "") || ""}&teams=${filter?.teams?.reduce((acc, cur) => { return cur.id + "," + acc }, "") || ""}`, {
				headers: authHeader()
			})

			setDataTrackedOffice({ track_name: tracked_origin, users: response.data })

		} catch (e) {
			console.log("/error: ", e)
		}
	}
	const handleChangeSelectLicense = (event, value) => {

		setSelectedLS(value)
		setSelectedLSError(false)

	}
	async function handleSubmitAttachment() {
		try {
			setSubmittingAttachLicense(true)
			if (selectedLS === null || !currentTracked) {
				// console.log("/nada foi selecionado")
				//msg para ele selecionar algo
				setSelectedLSError(true)
				return
			}
			const payload = {
				tracked_id: Number(currentTracked.trackedId),
				users: currentTracked.machineids,
				softwareAnalysis_id: selectedLS.id,
			}

			const response = await api.put(`/inventory/merge/software`, payload, {
				headers: authHeader(),

			})
			if (response.status === 200) {
				alertActions.success(t('activities.success_msg'))
				handleCloseLicenseModal()

			}

		} catch (e) {
			console.log("/error: ", e.response.data)
			alertActions.error(e.response.data.Message)
		} finally {
			setSubmittingAttachLicense(false)
		}
	}


	async function getListOfSoftwareLicense() {
		try {
			const response = await api.get(`/inventory/software/list-licenses`, {
				headers: authHeader()
			})

			setListOfLicenses(response.data)

		} catch (e) {
			console.log("/error: ", e)
		}
	}
	const handleModalTracked = (tracked_origin) => {

		setOpenOffice(true);
		getInfoTrackOffice(tracked_origin)
	}
	const handleOpenModalLicense = () => {
		setAnchorElOptions(null)
		setOpenLicense(true);
	}

	const handleCloseMenuOptions = () => {
		setAnchorElOptions(null);
	};
	const handleClickMenuOptions = (e, row) => {

		setAnchorElOptions(e.currentTarget);
		setCurrentTracked(row);
	}

	const handleClose = () => {
		setOpen(false);
		setOpenOffice(false)
		setDataTrackedOffice({ "track_name": "", users: [] })
	}

	const handleCloseLicenseModal = () => {
		setOpenLicense(false)
		setSelectedLS(null)
		setCurrentTracked(null)
		setAnchorElOptions(null)
	}

	const handleOpenModalActivityEdit = () => {
		setAnchorElOptions(null)
		setOpenModalEditActivity(true)
	}

	const handleCloseModalActivityEdit = () => {
		setOpenModalEditActivity(false)
		setAnchorElOptions(null)
		setCurrentTracked(null)
	}



	const data = useMemo(() => activities.rows ? activities.rows : []);

	async function getTrackedOrigin(trackedId) {
		try {
			const response = await api.get(`/activities/${trackedId}/trackedOrigin`, {
				headers: authHeader()
			})

			setTrackedInfo({
				trackedId: trackedId,
				trackedOrigin: response.data?.trackedOrigin
			})
		} catch (error) {
			console.log(error.response)
		}
	}

	let TotalWorkedTimeSoftware = stats.TotalWorkedTimeSoftware || 0;
	let TotalWorkedTimeSite = stats.TotalWorkedTimeSite || 0;
	let TotalWorkedTimeOutdoor = stats.TotalWorkedTimeOutdoor || 0;


	let TotalRelatedTime = stats.TotalProductiveTime || 0;
	let TotalNeutralTime = stats.TotalNeutralTime || 0;
	let TotalUnrelatedTime = stats.TotalUnproductiveTime || 0;

	let TotalWorkedTime = stats.TotalWorkedTime || 0;
	let TotalProductiveTime = stats.TotalProductiveTime || 0;

	let PercentWorkedTimeSoftware = 0;
	let PercentWorkedTimeSite = 0;
	let PercentWorkedTimeOutdoor = 0;

	let PercentRelatedTime = 0;
	let PercentNeutralTime = 0;
	let PercentUnrelatedTime = 0;

	if (TotalWorkedTime > 0 && TotalWorkedTimeSoftware > 0) {
		PercentWorkedTimeSoftware = ((TotalWorkedTimeSoftware / TotalWorkedTime) * 100).toFixed(1);
	}
	if (TotalWorkedTime > 0 && TotalWorkedTimeSite > 0) {
		PercentWorkedTimeSite = ((TotalWorkedTimeSite / TotalWorkedTime) * 100).toFixed(1);
	}
	if (TotalWorkedTime > 0 && TotalWorkedTimeOutdoor > 0) {
		PercentWorkedTimeOutdoor = ((TotalWorkedTimeOutdoor / TotalWorkedTime) * 100).toFixed(1);
	}
	if (TotalWorkedTime > 0 && TotalRelatedTime > 0) {
		PercentRelatedTime = ((TotalRelatedTime / TotalWorkedTime) * 100).toFixed(1);
	}
	if (TotalWorkedTime > 0 && TotalNeutralTime > 0) {
		PercentNeutralTime = ((TotalNeutralTime / TotalWorkedTime) * 100).toFixed(1)
	}
	if (TotalWorkedTime > 0 && TotalUnrelatedTime > 0) {
		PercentUnrelatedTime = ((TotalUnrelatedTime / TotalWorkedTime) * 100).toFixed(1);
	}

	const columns = React.useMemo(
		() => [
			{
				id: 'exhibitionName',
				Header: t('activities.activity'),
				accessor: c => c.exhibitionName.toLowerCase(),
				show: true,
				Cell: (d) => {

					let iconType = '';

					if (d.row.original.trackedType === "Software") {
						iconType = <IconSoftware />
					}
					if (d.row.original.trackedType === "Site") {
						iconType = <IconSite />
					}
					if (d.row.original.trackedType === "Outdoor") {
						iconType = <IconOutdoor />
					}

					let current = d.row.original;

					return <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<div className="icon">{iconType}</div>
						<div className="activity" title={d.row.original.exhibitionName} onClick={() => {
							openDrawer("teams", d.row.original.trackedId, current, d.row.id)
						}}>
							{d.row.original.exhibitionName.substring(0, 50)}
						</div>

						{d.row.original.trackedType === 'Software' && files.filter(item => item === String(d.row.original.origin).toLowerCase()).length > 0 &&
							<IconButton
								className={classes.infoIcon}
								aria-label="info-tracked"
								style={{ margin: 0, padding: 2 }}
								onClick={() => handleModalTracked(d.row.original.origin)}
							>
								<FileText size={20} />
							</IconButton>
						}
						{d.row.original.trackedType === 'Outdoor' && Number(d.row.original.trackedId) === 18899140 &&
							<IconButton
								className={classes.infoIcon}
								aria-label="info-tracked"
								style={{ margin: 0, padding: 2 }}
								onClick={() => handleOpen()}
							>
								<PhoneAndroidIcon />
							</IconButton>
						}

						{d.row.original.trackedType !== 'Outdoor' && (
							<div>

								{String(trackedInfo.trackedId) === String(d.row.original.trackedId) ? (
									trackedInfo.trackedOrigin !== '' && (
										<IconButton
											className={classes.infoIcon}
											aria-label="info-tracked"
											style={{ margin: 0, padding: 2 }}
										>
											<Tooltip
												placement="right"
												title={
													trackedInfo.trackedOrigin
												}
												arrow
											>
												<HelpOutlineIcon />
											</Tooltip>
										</IconButton>
									)
								) : (
									<IconButton
										className={classes.infoIcon}
										aria-label="info-tracked"
										style={{ margin: 0, padding: 2 }}
										onClick={() => getTrackedOrigin(d.row.original.trackedId)}
									>
										<HelpOutlineIcon />
									</IconButton>
								)}

							</div>
						)}
					</div>
				}
			},
			{
				Header: t('activities.category'),
				accessor: 'categoryName',
				show: true,
				Cell: (d) => (
					<div>
						<span>{d.row.original.categoryName}</span>
						{
							d.row.original.trackedType !== "Outdoor" && (
								<IconButton className={classes.infoIcon} aria-label="info-tracked" style={{ margin: 0, padding: 2 }} onClick={(e) => { handleOpenModalDetailCategory(e, d.row.original?.categoryId) }}>
									<HelpOutlineIcon />
								</IconButton>
							)
						}
					</div>

				)
			},
			{
				Header: t('activities.total_time'),
				accessor: 'duration',
				show: userDetails.allowed_total_time,
				Cell: (d) => {
					
					let percentTotalTime = "0%";
					const totalWorkedTime = Number(TotalWorkedTime)

					if (d.value > 0 && totalWorkedTime > 0) {
						const percentValue = (d.value / totalWorkedTime) * 100;
						percentTotalTime = convertValuePercent(percentValue)
					}

					return <div className="totalTime">
										<div className="time">{secondsToHm(d.value)}</div>
										<span>{percentTotalTime}</span>
								  </div>
				}
			},
			{
				Header: t('activities.work_related'),
				accessor: 'productiveDuration',
				show: userDetails.allowed_productive.includes('related'),
				Cell: (d) => {

					let current = d.row.original;

					let classClassification = ''
					let titleClassification = t('activities.work_related')

					if(current.groupsEfficiency && current.groupsEfficiency.length > 0){
						
						if(current.groupsEfficiency[0]==="neutral"){
							classClassification = 'neutral'
							titleClassification = t('activities.work_neutral')
						}

						if(current.groupsEfficiency[0]==="unproductive"){
							classClassification = 'unproductive'
							titleClassification = t('activities.work_unrelated')
						}
					}

					if(userDetails.calculate_based_productivity_target===true){
						const productive_percent_from_exepected_produtive = current.productive_percent_from_exepected_produtive ? Number(current.productive_percent_from_exepected_produtive) : 0
						return <div className={`totalWork ${classClassification}`} title={titleClassification}><span>{convertValuePercent(productive_percent_from_exepected_produtive)}</span></div>						
					}

					const productive_percent_from_total_time = current.productive_percent_from_total_time ? Number(current.productive_percent_from_total_time) : 0

					return <div className={`totalWork ${classClassification}`}  title={titleClassification}><span>{convertValuePercent(productive_percent_from_total_time)}</span></div> 
				}
			},
			{
				Header: t('activities.used_by'),
				accessor: 'totalMachineUsers',
				show: true,
				Cell: (d) => {
					let current = d.row.original;

					return <div className="usedBy">
						<div className="team" onClick={() => {
							openDrawer("teams", d.row.original.trackedId, current, d.row.id)
						}}>
							<div className="icon"><IconTeam /></div>
							<span>{d.row.original.totalTeams}</span></div>
						<div className="group" onClick={() => {
							openDrawer("teammates", d.row.original.trackedId, current, d.row.id)
						}}>
							<div className="icon"><IconTeammate /></div>
							<span>{d.row.original.totalMachineUsers}</span></div>
					</div>
				}
			},

		],
		[trackedInfo, files]
	);

	const convertValuePercent = (value) => {
			
		if(value === null || value === undefined || value <= 0){
			return "0%"
		}
			
		if (value > 0 && value < 1) {
			return "<1%"
		} 
			
		return Math.round(value) + "%";
	}

	// 	<td
	// 	className='actions widthSmall'
	// // onClick={(e) => }
	// >
	// <div
	// 	onClick={(e) => userDetails?.tasksPermissions.includes(Number(task.id)) && handleClick(e, task?.id)}
	// 	className={'three-points'}
	// 	style={{ opacity: userDetails?.tasksPermissions.includes(Number(task.id)) ? '' : '0' }}
	// >
	// 	<IconThreePoints />
	// </div>

	// 	<div className="removeDialog" id={"removeTask-" + task?.id}>
	// 		<div className="content">
	// 			<div className="message">
	// 				{/* <IconInfo /> */}
	// 				{t('confirmDialog.title')}
	// 			</div>
	// 			<div className="options">
	// 				<button onClick={() => { toggleRemoveDialog() }}>{t('confirmDialog.no')}</button>
	// 				<button onClick={() => { toFileTask(currentTask, toggleRemoveDialog) }}>{t('confirmDialog.yes')}</button>
	// 			</div>
	// 		</div>
	// 	</div>
	// </td>


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		setFilter
	} = useTable({
		columns, data, disableMultiSort: true,
		initialState: {
			sortBy: [
				{
					id: 'duration',
					desc: true
				}
			]
		}
	}, useFilters, useSortBy);

	const handleOpenModalDetailCategory = (e, categoryId) => {
		e.preventDefault()
		setOpenModalDetailCategory(true)
		setCategoryId(categoryId)
	}


	function openDrawer(type, id, current, index) {
		props.openDrawer({ type: type, id: id, data: current, index: index });
	}

	function showMore() {

		let newOffset = offset + 50;


		setOffset(newOffset);

		let startDate = Moment(new Date()).format('YYYY-MM-DD');
		let endDate = Moment(new Date()).format('YYYY-MM-DD');

		if (filter.date) {
			startDate = filter.date.startDate;
			endDate = filter.date.endDate;
		}


		activitiesActions.getActivities(startDate, endDate, null, null, newOffset, filterSoftware, filterSite, filterOffline, filterProductive, filterNeutral, filterUnproductive);
	}

	function setFilter2(type) {
		if (type == "software") {
			filterActions.set(!filterSoftware, "software");
		}
		if (type == "site") {
			filterActions.set(!filterSite, "site");
		}
		if (type == "offline") {
			filterActions.set(!filterOffline, "offline");
		}
		if (type == "productive") {
			filterActions.set(!filterProductive, "productive");
		}
		if (type == "neutral") {
			filterActions.set(!filterNeutral, "neutral");
		}
		if (type == "unproductive") {
			filterActions.set(!filterUnproductive, "unproductive");
		}
	}

	useEffect(() => {
		if (filter.what) {
			if (filter.what.length > 2) {
				setFilter("exhibitionName", filter.what);
			} else {
				setFilter("exhibitionName", undefined);
			}

		} else {
			setFilter("exhibitionName", undefined);
		}

	}, [filter.what])


	useEffect(() => {
		activitiesActions.getActivitiesCategories()
		getAllowedFiles()
		getListOfSoftwareLicense()
	}, [])

	return (
		<div className="box noBg">

			<div className="pageHeader activities">
				<div className="multi">
					{
						userDetails.allowed_total_time === true && (
							<h3>
								{t('activities.total_worked_hours')}: <b>{secondsToHm(stats.total_expected_worked_time)} / {secondsToHm(TotalWorkedTime)}</b>&nbsp;
									<BefectiveTooltip title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t('activities.tooltip1')}</Typography>} placement="bottom-center" arrow interactive>
										<div><IconInfo /></div>
									</BefectiveTooltip>	
							</h3>
						)
					}
					{
						userDetails.allowed_total_time_related === true && (
								<h3 className="work">
									{t('activities.total_worked_related_hours')}: <b> {secondsToHm(stats.total_expected_productive_time)} / {secondsToHm(stats.total_productive_time)}</b>&nbsp;
									<BefectiveTooltip title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t('activities.tooltip2')}</Typography>} placement="bottom-center" arrow interactive>
										<div><IconInfo /></div>
									</BefectiveTooltip>	
								</h3>
						)
					}
				</div>

				<div className="internalFilters">
					{
						userDetails.allowed_productive.includes('related') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
							<div className={filterProductive ? 'filter active canFilter related' : 'filter canFilter work'}
								onClick={() => {
									setFilter2("productive")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot related"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_productive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.productive_percent_from_total_productive}%`}</div>
								<div className="name">{t('activities.work_related')}</div>
							</div>
							:
							<div className={filterProductive ? 'filter active canFilter related' : 'filter canFilter work'}
								onClick={() => {
									setFilter2("productive")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot related"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_productive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.productive_percent_from_total_time}%`}</div>
								<div className="name">{t('activities.work_related')}</div>
							</div>
						)
					}

					{
						userDetails.allowed_productive.includes('neutral') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
							<div className={filterNeutral ? 'filter active canFilter neutral' : 'filter canFilter neutral'}
								onClick={() => {
									setFilter2("neutral")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot neutral"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_neutral_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_neutral_from_total_productive}%`}</div>
								<div className="name">{t('activities.work_neutral')}</div>
							</div>
							:
							<div className={filterNeutral ? 'filter active canFilter neutral' : 'filter canFilter neutral'}
								onClick={() => {
									setFilter2("neutral")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot neutral"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_neutral_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_neutral_from_total_time}%`}</div>
								<div className="name">{t('activities.work_neutral')}</div>
							</div>
						)
					}

					{
						userDetails.allowed_productive.includes('not_related') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
							<div className={filterUnproductive ? 'filter canFilter unrelated active' : 'filter canFilter sad'}
								onClick={() => {
									setFilter2("unproductive")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot unrelated"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_unproductive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_unproductive_from_total_productive}%`}</div>
								<div className="name">{t('activities.work_unrelated')}</div>
							</div>
							:
							<div className={filterUnproductive ? 'filter canFilter unrelated active' : 'filter canFilter sad'}
								onClick={() => {
									setFilter2("unproductive")
								}}>
								<div className={"head"}>
									<div className="icon">
										<div className="dot unrelated"></div>
									</div>
									{userDetails.allowed_total_time_related === true && <div className="number">{secondsToHm(stats.total_unproductive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_unproductive_from_total_time}%`}</div>
								<div className="name">{t('activities.work_unrelated')}</div>
							</div>

						)
					}

					{
						(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
						?
						<>
							<div className={filterSoftware ? 'filter  canFilter time active' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("software")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconSoftware />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_software_productive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_software_from_total_productive}%`}</div>
								<div className="name">{t('activities.software')}</div>
							</div>

							<div className={filterSite ? 'filter active canFilter time' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("site")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconSite />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_site_productive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_site_from_total_productive}%`}</div>
								<div className="name">{t('activities.site')}</div>
							</div>

							<div className={filterOffline ? 'filter active canFilter time' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("offline")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconOutdoor />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_offline_productive_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_ofline_from_total_productive}%`}</div>
								<div className="name">{t('activities.outdoor')}</div>
							</div>
						</>

						:
						
						<>
							<div className={filterSoftware ? 'filter  canFilter time active' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("software")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconSoftware />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_software_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_software_from_total_time}%`}</div>
								<div className="name">{t('activities.software')}</div>
							</div>

							<div className={filterSite ? 'filter active canFilter time' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("site")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconSite />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_site_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_site_from_total_time}%`}</div>
								<div className="name">{t('activities.site')}</div>
							</div>

							<div className={filterOffline ? 'filter active canFilter time' : 'filter canFilter time'}
								onClick={() => {
									setFilter2("offline")
								}}>
								<div className={"head"}>
									<div className="icon">
										<IconOutdoor />
									</div>
									{ userDetails.allowed_total_time === true && <div className="number">{secondsToHm(stats.total_offline_time)}</div>}
								</div>
								<div className="highlight">{`${stats.percent_ofline_from_total_time}%`}</div>
								<div className="name">{t('activities.outdoor')}</div>
							</div>

						</>
					}
				</div>
			</div>
			<div className="dataTable activities">
				{data.length > 0 &&
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => {
										
										if (column.show) {
											return <th {...column.getHeaderProps(column.getSortByToggleProps())}>
												{column.render('Header')}
												{/* Add a sort direction indicator */}
												<span>
													{column.isSorted
														? column.isSortedDesc
															? <div className="arrowDown"></div>
															: <div className="arrowUp"></div>
														: ''}
												</span>
											</th>
										}
										return null;										
									})}
									<th className='colActions' />


								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(
								(row, i) => {

									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												if (cell.column.show) {
													return (
														<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
													)
												}
												return null
											})}
											<td className='cellActions'>
												<div
													onClick={(e) => { handleClickMenuOptions(e, row.original) }} //handleClick()}
													className={'three-points'}
													style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}
												>
													<IconThreePoints />
												</div>
											</td>
										</tr>
									)
								}
							)}
						</tbody>
					</table>
				}
				{(activities.rows && activities.rows.length >= (50 + offset)) &&
					<button onClick={() => {
						showMore()
					}}>{t('activities.show_more')}</button>
				}
				{data.length == 0 &&
					<p className={'noData'}>{t('no_data')}</p>
				}
			</div>


			<ModalDetailCategory
				key={1}
				openModalDetailCategory={openModalDetailCategory}
				setOpenModalDetailCategory={setOpenModalDetailCategory}
				categoryId={categoryId}
				categories={activities?.categories}
				category={activities?.categories?.find((c) => c.id == categoryId)}
				userDetails={userDetails}
			/>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style2}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{t("modal.title1")}
					</Typography>
					<List
						sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
						aria-label="contacts"
					>
						<ListItem disablePadding>
							{
								remoteTracks.map((item, idx) =>
									<Fragment key={idx}>
										<ListItemIcon>
											<User size={32} />
										</ListItemIcon>
										<ListItemText primary={item.username} />
										<ListItemText primary={item.extra} />
										<ListItemText primary={secondsToHm(Number(item.total_duration))} />
									</Fragment>)
							}

						</ListItem>

					</List>

				</Box>
			</Modal>

			{/* <ModalStandard
				flag={openOffice}
				data={dataTrackedOffice}
			/> */}
			<Modal
				open={openOffice}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>


				<Box sx={style2} justifyContent={"center"}>

					{dataTrackedOffice.users.length === 0
						?
						(<div className='no-data'>
							{t("modal.no_data")}

						</div>)
						:
						<>
							<Typography id="modal-modal-title" variant="h6" component="h2" style={{color: '#1E6B7F'}}>
								{dataTrackedOffice.track_name !== "" &&
									<>
										{t("modal.title2")}{dataTrackedOffice.track_name}
									</>}

							</Typography>

							<div className='container'>
								{
									dataTrackedOffice.users.map((item, idx) =>
										<Fragment key={idx}>
											<div className='card'>
												<div className='card-head'>
													<User size={25} color='#1E6B7F'/>
													<username className='username'>
														{item.username}
													</username>

												</div>
												<div className='list'>

													{item.data.length > 0 && item.data.filter(track => track.file_name).map(track => (
														<>
															<div className='list-item'>

																<file className="item-file">
																	<FileDoc size={15} />
																	<div className='list-item-title'>
																		{track.file_name}
																	</div>
																</file>
																<time className='time'>
																	{secondsToHm(Number(track.total_seconds))}
																</time>
															</div>
														</>
													))}

												</div>

											</div>
										</Fragment>)
								}

							</div>
						</>}


				</Box>

			</Modal>

			{/* {MODAL DE LICENSAS} */}
			<Modal
				open={openLicense}
				onClose={handleCloseLicenseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ outline: 0, border: 'none' }}
				className={`${classes.modal}`}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 400,
					style: { backgroundColor: '#205B6F', opacity: 0.8 }
				}}
			>

				<Slide direction="down" in={openLicense} style={{ outline: 0 }} mountOnEnter unmountOnExit>
					<Box sx={style3} justifyContent={"center"}>

						{listOfLicenses.length === 0
							?
							(<div className='no-data'>
								{t("modal.no_data")}

							</div>)
							:
							<>
								<Typography id="modal-modal-title" component="h3" style={{ color: "#1E6B7F", fontSize: "18px", fontWeight: 500 }}>
									{t('activities.modal_attachment_label')}
								</Typography>

								<div className='container'>
									<div style={{ marginTop: "20px" }}>
										<Autocomplete
											id="combo-box-demo"
											options={listOfLicenses}
											onChange={(event, newValue) => { handleChangeSelectLicense(event, newValue) }}
											getOptionLabel={(option) => option.label}
											renderInput={(params) => <TextField {...params} label={t('activities.modal_select')} variant="outlined" error={selectedLSError} />}
											onFocus={() => setSelectedLSError(false)}
											fullWidth
										/>
									</div>
									<Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "18px" }}>
										<button
											className="btnAttchment"
											type="button"
											onClick={() => { handleSubmitAttachment() }}
											disabled={submittingAttachLicense}
										>

											{
												!submittingAttachLicense
													?
													t("activities.modal_button")
													:
													<CircularProgress size={15} />
											}
										</button>
									</Box>
								</div>
							</>}


					</Box>

				</Slide>

			</Modal>

			<Menu
				className="menu-options"
				id={`menu-options`}
				anchorEl={anchorElOptions}
				keepMounted
				open={Boolean(anchorElOptions)}
				onClose={handleCloseMenuOptions}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				disableAutoFocusItem
			>
				<MenuItem onClick={() => { handleOpenModalActivityEdit() }}>{t("activities.menu_opt_3")}</MenuItem>

				{
					(userDetails.modules.find(item => item.id === 9) && ["Site", "Software"].includes(currentTracked?.trackedType)) &&
					<MenuItem onClick={() => { handleOpenModalLicense() }}>{t("activities.menu_opt_1")}</MenuItem>
				}

				{
					(userDetails.modules.find(item => item.id === 9) && ["Site", "Software"].includes(currentTracked?.trackedType)) &&
					<MenuItem onClick={() => {
						history.push(`/inventory/software?users=${currentTracked.machineids.join(",")}&tracked_id=${Number(currentTracked.trackedId)}&type=${currentTracked.trackedType}&open_modal=true`)
						saveCurrentModule('inventory')
					}
					}>{t("activities.menu_opt_2")}</MenuItem>
				}

			</Menu>

			{
				(activities?.categories && currentTracked) &&
				<EditActivityModal
					currentTracked={currentTracked}
					openModalEditActivity={openModalEditActivity}
					handleClose={handleCloseModalActivityEdit}
					alertActions={alertActions}
					userDetails={userDetails}
					categories={activities?.categories}
					getActivitiesData={fetchData}
				/>
			}

		</div>

	)
};

function mapDispatchToProps(dispatch) {

	return {
		activitiesActions: bindActionCreators(activitiesActions, dispatch),
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch),


	}
}

function mapStateToProps(state) {

	return {
		activities: state.activities,
		stats: state.stats.payload,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesFull)
