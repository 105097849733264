import React from 'react';

const IconConfig = props => {
  
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10.083 10.083">
  <g id="icons_Q2" data-name="icons Q2" transform="translate(0 0)">
    <g id="Grupo_87" data-name="Grupo 87">
      <path id="Caminho_86" data-name="Caminho 86" d="M10.755,8.234l1.26-.344a5.271,5.271,0,0,0,0-1.7l-1.26-.344a.412.412,0,0,1-.252-.6l.642-1.146A4.721,4.721,0,0,0,9.975,2.94L8.83,3.582l-.183.046a.412.412,0,0,1-.412-.3L7.89,2.069a5.271,5.271,0,0,0-1.7,0L5.85,3.33a.413.413,0,0,1-.413.3l-.183-.046L4.109,2.94A4.721,4.721,0,0,0,2.94,4.109l.642,1.146a.413.413,0,0,1-.252.6l-1.26.344a5.271,5.271,0,0,0,0,1.7l1.26.344a.413.413,0,0,1,.252.6L2.94,9.975a4.721,4.721,0,0,0,1.169,1.169L5.255,10.5l.183-.046a.413.413,0,0,1,.413.3l.344,1.26a5.271,5.271,0,0,0,1.7,0l.344-1.26a.412.412,0,0,1,.413-.3l.183.046,1.146.642a4.721,4.721,0,0,0,1.169-1.169L10.5,8.83a.412.412,0,0,1,.252-.6ZM10.48,7.34h-.046a1.4,1.4,0,0,0-.779.756,1.306,1.306,0,0,0,.023,1.1H9.7v.046l.344.573A.687.687,0,0,1,9.861,10l-.573-.344H9.219a1.237,1.237,0,0,0-.573-.137,1.375,1.375,0,0,0-1.283.894v.069h0l-.206.688H6.927L6.744,10.5h0v-.046A1.375,1.375,0,0,0,5.438,9.54a1.237,1.237,0,0,0-.573.137H4.8l-.573.344a.688.688,0,0,1-.183-.183l.344-.573V9.219h.023a1.306,1.306,0,0,0,.023-1.1,1.4,1.4,0,0,0-.779-.756H3.582l-.665-.206V6.927l.665-.183H3.65a1.4,1.4,0,0,0,.779-.756,1.306,1.306,0,0,0-.023-1.1H4.384V4.842L4.04,4.269l.183-.183L4.8,4.43h.069a1.238,1.238,0,0,0,.573.138,1.375,1.375,0,0,0,1.283-.894V3.6h0l.206-.687h.229l.183.665h0v.046a1.375,1.375,0,0,0,1.306.917,1.238,1.238,0,0,0,.573-.137h.069l.573-.344a.687.687,0,0,1,.183.183L9.7,4.819v.046H9.677a1.306,1.306,0,0,0-.023,1.1,1.4,1.4,0,0,0,.779.756H10.5l.665.183v.229l-.665.183Z" transform="translate(-2 -2)" fill={props.color || "#98acc1"}/>
      <path id="Caminho_87" data-name="Caminho 87" d="M17.063,15a2.063,2.063,0,1,0,2.063,2.063A2.062,2.062,0,0,0,17.063,15Zm0,3.208a1.146,1.146,0,1,1,1.146-1.146A1.146,1.146,0,0,1,17.063,18.208Z" transform="translate(-12.021 -12.021)" fill={props.color || "#98acc1"}/>
    </g>
  </g>
</svg>


      
    )
}

export default IconConfig;