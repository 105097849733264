import React from 'react'
import styles from './StatsDetailsSoftwarePage.module.scss'
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from '../../../../../_helpers';

export default function StatsDetailsSoftwarePage({
  stats
}) {

  const { t, i18n } = useTranslation('common');
  
  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{`${t("software_inventory.details.title_header")} ${stats.label || ""}`}</h6>
          <div className={styles.boxStatsColabs}>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.license_used")}:</div>
              <div className={styles.numberValue}>{`${stats.total_used || 0}`}</div>
            </div>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.license_available")}:</div>
              <div className={styles.numberValue}>{`${stats.total_not_used || 0}`}</div>
            </div>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.purchase_date")}:</div>
              <div className={styles.numberValue}>{`${stats.purchase_date ? befectiveFormatDate(stats.purchase_date, i18n.language) : '--/--/----'}`}</div>
            </div>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.due_date")}:</div>
              <div className={styles.numberValue}>{`${stats.due_date ? befectiveFormatDate(stats.due_date, i18n.language) : '--/--/----'}`}</div>
            </div>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.total_past_days")}:</div>
              <div className={styles.numberValue}>{`${stats.total_past_days || 0}`}</div>
            </div>
            <div className={`${styles.stats}`}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{t("software_inventory.details.total_remaining_days")}:</div>
              <div className={styles.numberValue}>{`${stats.total_remaining_days || 0}`}</div>
            </div>
          </div>
        </div>
      </div>
    
    </header>
  )
}
