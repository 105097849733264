import React, { useRef, useState, useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation, initReactI18next } from "react-i18next"
import { bindActionCreators } from 'redux'

import { settingsActions } from '../../../_actions'

function DrawerManage(props) {
    const { t, i18n } = useTranslation('common')

    const { settingsActions } = props

    let invited = props.invited || false
    let teams = props.teams || []
    let teamsList = []
    let specificAdministrator = props.specificAdministrator || []

    const [submitted, setSubmitted] = useState(false)
    const [form, setForm] = useState({
        role: 'manager',
        enable_activities: false,
        enable_freedays: false
    })

    const [formError, setFormError] = useState({
        role: ''
    })

    function toggleTeam(id) {

        let mainOn = document.getElementById("team" + id).classList.contains('on');

        var elems = document.querySelectorAll(".team" + id);

        if (mainOn) {
            [].forEach.call(elems, function (el) {
                el.classList.remove("on");
            });
        }
        else {
            [].forEach.call(elems, function (el) {
                el.classList.add("on");
            });
        }

    }

    for (let team of teams) {
        if (!team.ancestry) {
            teamsList.push(
                <li 
                    key={team.id} 
                    id={"team" + team.id} 
                    className={"t0 team" + team.id}
                >
                        <b>{team.name}</b>
                        <div 
                            className="toggle" 
                            onClick={() => { toggleTeam(team.id) }}
                        >
                            <span></span>
                        </div>
                </li>
            )

            let p0 = team.id

            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(
                        <li 
                            key={t1.id} 
                            id={"team" + t1.id} 
                            className={"t1 team" + p0 + " team" + t1.id}
                        >
                            {t1.name}
                        <div 
                            className="toggle" 
                            onClick={() => { toggleTeam(t1.id) }}
                        >
                            <span></span>
                        </div>
                        </li>
                    )

                    let p1 = t1.id

                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<li key={t2.id} id={"team" + t2.id} className={"t2 team" + p0 + " team" + p1 + " team" + t2.id}>{t2.name}<div className="toggle" onClick={() => { toggleTeam(t2.id) }}><span></span></div></li>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<li key={t3.id} id={"team" + t3.id} className={"t3 team" + p0 + " team" + p1 + " team" + p2 + " team" + t3.id}>{t3.name}<div className="toggle" onClick={() => { toggleTeam(t3.id) }}><span></span></div></li>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<li key={t4.id} id={"team" + t4.id} className={"t4 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + t4.id}>{t4.name}<div className="toggle" onClick={() => { toggleTeam(t4.id) }}><span></span></div></li>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<li key={t5.id} id={"team" + t5.id} className={"t5 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + t5.id}>{t5.name}<div className="toggle" onClick={() => { toggleTeam(t5.id) }}><span></span></div></li>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<li key={t6.id} id={"team" + t6.id} className={"t6 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + t6.id}>{t6.name}<div className="toggle" onClick={() => { toggleTeam(t6.id) }}><span></span></div></li>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<li key={t7.id} id={"team" + t7.id} className={"t7 team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + p6 + " team" + t7.id}>{t7.name}<div className="toggle" onClick={() => { toggleTeam(t7.id) }}><span></span></div></li>)
                                                                }
                                                            }

                                                        }
                                                    }

                                                }
                                            }

                                        }
                                    }

                                }
                            }
                        }
                    }

                }


            }

        }

    }

    const handleChange = e => {
        setSubmitted(false)
        setForm({
            ...form,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitted(true)

        let formIsValid = true


        if (!form["role"]) {
            formIsValid = false
            setFormError({
                ...formError,
                email: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            let teams = []

            var elems = document.querySelectorAll(".on");
            [].forEach.call(elems, function (el) {
                teams.push(parseInt(el.id.replace("team", '')))
            })

            setFormError({
                name: '',
                email: '',
                role: ''
            })

            await settingsActions.putCompanyInviteAdministrator(form, teams, props.userId)
            await settingsActions.getCompanyAdministrators()
            closeDrawer()
            setSubmitted(false)
        }
        else {
            setSubmitted(false)
        }

    }

    function closeDrawer() {

        setForm({
            role: '',
            enable_activities: false,
            enable_freedays: false
        })

        setFormError({
            role: ''
        })

        props.closeDrawer()
    }

    useEffect(() => {

        if (invited == 'error') {
            setSubmitted(false)
        }

    }, [invited])

    useEffect(() => {
        
        setForm({
            role: specificAdministrator.role,
            enable_activities: specificAdministrator.enable_activities,
            enable_freedays: specificAdministrator.enable_freedays
        })

        if (specificAdministrator.teams) {
            for (let team of specificAdministrator.teams) {
                let elem = document.querySelector(".team" + team.group_id);

                elem?.classList.add("on");
                // for (let item of elems) {
                //     console.log('item ->', item)
                //     item.classList.add("on");
                // }
                // [].forEach.call(elems, function (el) {
                //     console.log('el', el)
                //     el.classList.add("on");
                // });

            }
        }



    }, [props.specificAdministrator])


    useEffect(() => {

        if (specificAdministrator.teams) {
            for (let team of specificAdministrator.teams) {
                var elems = document.querySelectorAll(".team" + team.group_id);

                [].forEach.call(elems, function (el) {
                    el.classList.add("on");
                });

            }
        }

    }, [props.teams])

    return (
        <React.Fragment>
            <div className="internal invite">

                <header>
                    <h3>{specificAdministrator.name}</h3>
                </header>


                <form onSubmit={handleSubmit} className="form">
                    <div className="fieldset radio">
                        <div className="field">
                            <input type="radio" id="role1" name="role" checked={form.role === "admin" || form.role === "magic"} value="admin" onChange={handleChange} /> <label htmlFor="role1">{t('admin1')}</label>
                        </div>
                        <div className="field">
                            <input type="radio" id="role2" name="role" checked={form.role === "manager"} value="manager" onChange={handleChange} /> <label htmlFor="role2">{t('admin2')}</label>
                        </div>
                        <div className="field">
                            <input type="radio" id="role3" name="role" checked={form.role === "analyst"} value="analyst" onChange={handleChange} /> <label htmlFor="role3">{t('admin3')}</label>
                        </div>
                    </div>
                    {(form.role == 'analyst') &&
                        <div className="fieldset radio extra">
                            <div className="field">
                                <input type="checkbox" id="enable_activities" name="enable_activities" checked={form.enable_activities} value={form.enable_activities} onChange={handleChange} /> <label htmlFor="enable_activities">{t('settings.administrator.enable_activities')}</label>
                            </div>
                            <div className="field">
                                <input type="checkbox" id="enable_freedays" name="enable_freedays" checked={form.enable_freedays} value={form.enable_freedays} onChange={handleChange} /> <label htmlFor="enable_freedays">{t('settings.administrator.enable_freedays')}</label>
                            </div>
                        </div>

                    }

                    <div className="formReturn">
                        {(invited == 'error') &&
                            <div className="error">{t('settings.administrator.invite_error')}</div>
                        }

                        {(formError.name || formError.email || formError.role) &&
                            <div className="error">{t('settings.administrator.required')}</div>
                        }
                    </div>

                    <div className="teams">
                        <h3>Teams to lead</h3>
                        <ul>
                            {teamsList}
                        </ul>
                    </div>

                    <div className="controls">
                        {(submitted && !invited) &&
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        }
                        <button type="button" disabled={submitted ? true : false} onClick={() => { closeDrawer() }}>{t('settings.company.cancel')}</button>
                        <button type="submit" disabled={submitted ? true : false}>{t('settings.company.update')}</button>
                    </div>
                </form>


                <div className="permission">

                </div>

            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        specificAdministrator: state.settings.specificAdministrator,
        invited: state.settings.invited
    }

}


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerManage)



