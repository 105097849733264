import React from 'react';

const IconMood = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Icon_awesome-smile" data-name="Icon awesome-smile" d="M8,.563a8,8,0,1,0,8,8A8,8,0,0,0,8,.563Zm2.581,5.419A1.032,1.032,0,1,1,9.548,7.014,1.031,1.031,0,0,1,10.581,5.982Zm-5.161,0A1.032,1.032,0,1,1,4.387,7.014,1.031,1.031,0,0,1,5.419,5.982Zm6.284,5.49a4.819,4.819,0,0,1-7.406,0,.516.516,0,0,1,.794-.661,3.79,3.79,0,0,0,5.819,0A.517.517,0,0,1,11.7,11.472Z" transform="translate(0 -0.563)" fill="#98acc1" />
    </svg>

  )
}

export default IconMood;