import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';


import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function PeriodProductivitySkeleton(props) {
    const {t, i18n} = useTranslation('common');


    return (
        <React.Fragment>
            <div className="box productivity">
                <div className={'number'}><Skeleton variant="text" width={160} height={32}/></div>
                <div className={'label'}><Skeleton variant="text" width={80} height={32}/></div>
            </div>
            <div className="box productivity">
                <div className={'number'}><Skeleton variant="text" width={160} height={32}/></div>
                <div className={'label'}><Skeleton variant="text" width={80} height={32}/></div>
            </div>
            <div className="box ">
                <div className={'number'}><Skeleton variant="text" width={160} height={32}/></div>
                <div className={'label'}><Skeleton variant="text" width={80} height={32}/></div>
            </div>
            <div className="box ">
                <div className={'number'}><Skeleton variant="text" width={160} height={32}/></div>
                <div className={'label'}><Skeleton variant="text" width={80} height={32}/></div>
            </div>
        </React.Fragment>
    )
};

export default PeriodProductivitySkeleton;
