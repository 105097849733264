export function secondsToHm(d, seconds = false) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h.toFixed() + (h == 1 ? "h " : "h ") : "";
    let mDisplay = m > 0 ? m.toFixed() + (m == 1 ? "min " : "min ") : "";
    let sDisplay = s > 0 ? s.toFixed() + (s == 1 ? "s" : "s") : "";


    if (seconds) {
        if (!sDisplay && !mDisplay && !hDisplay) {
            return "∅"
        } else {
            return hDisplay + mDisplay + sDisplay;
        }

    } else {
        if (!mDisplay) {

            if (s > 0 && !hDisplay) {
                mDisplay = s + "s"
            } else if (hDisplay) {
                mDisplay = "00min"
            } else if (!hDisplay) {
                mDisplay = "∅"
            }

        }
        return hDisplay + mDisplay;
    }

}
