import React from 'react';

const IconShare = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
        <path id="icon" d="M11,10a2.987,2.987,0,0,0-1.868.653l-3.2-2a3.017,3.017,0,0,0,0-1.3l3.2-2a2.995,2.995,0,1,0-1.06-1.7l-3.2,2a3,3,0,1,0,0,4.694l3.2,2A3,3,0,1,0,11,10Z" />
        </svg>


 

    )
}

export default IconShare;