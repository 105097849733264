export const machineUsersConstants = {
    LOGIN_REQUEST: '[MACHINE_USERS] LOGIN_REQUEST',
    LOGIN_SUCCESS: '[MACHINE_USERS] LOGIN_SUCCESS',
    GENERATE_PASSWORD_SUCCESS: '[MACHINE_USERS] GENERATE_PASSWORD_SUCCESS',
    LOGIN_FAILURE: '[MACHINE_USERS] LOGIN_FAILURE',
    LOGIN_REFRESH_REQUEST: '[MACHINE_USERS] LOGIN_REFRESH_REQUEST',
    LOGIN_REFRESH_SUCCESS: '[MACHINE_USERS] LOGIN_REFRESH_SUCCESS',
    LOGIN_REFRESH_FAILURE: '[MACHINE_USERS] LOGIN_REFRESH_FAILURE',
    LOGOUT: '[MACHINE_USERS] LOGOUT',
    GET_BY_TOKEN_REQUEST: '[MACHINE_USERS] GET_BY_TOKEN_REQUEST',
    GET_BY_TOKEN_SUCCESS: '[MACHINE_USERS] GET_BY_TOKEN_SUCCESS',
    GET_BY_TOKEN_FAILURE: '[MACHINE_USERS] GET_BY_TOKEN_FAILURE',
    GETALL_REQUEST: '[MACHINE_USERS] GETALL_REQUEST',
    GETALL_SUCCESS: '[MACHINE_USERS] GETALL_SUCCESS',
    GETALL_FAILURE: '[MACHINE_USERS] GETALL_FAILURE',
    PATCH_USER_REQUEST: '[MACHINE_USERS] PATCH_USER_REQUEST',
    PATCH_USER_SUCCESS: '[MACHINE_USERS] PATCH_USER_SUCCESS',
    PATCH_USER_FAILURE: '[MACHINE_USERS] PATCH_USER_FAILURE',
    PATCH_USER_PASSWORD_REQUEST: '[MACHINE_USERS] PATCH_USER_PASSWORD_REQUEST',
    PATCH_USER_PASSWORD_SUCCESS: '[MACHINE_USERS] PATCH_USER_PASSWORD_SUCCESS',
    PATCH_USER_PASSWORD_FAILURE: '[MACHINE_USERS] PATCH_USER_PASSWORD_FAILURE',
    POST_USER_RECOVER_REQUEST: '[MACHINE_USERS] POST_USER_RECOVER_REQUEST',
    POST_USER_RECOVER_SUCCESS: '[MACHINE_USERS] POST_USER_RECOVER_SUCCESS',
    POST_USER_RECOVER_FAILURE: '[MACHINE_USERS] POST_USER_RECOVER_FAILURE'
};
   