import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowRight, Timer } from 'phosphor-react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconIn from '../../_icons/IconIn';
import IconOut from '../../_icons/IconOut';

import moment from 'moment';
import { authHeader, getCorrectPeriod, getRemainingPoints } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import style from './CheckinProgress.module.scss';
import { DrawerDetailsToday } from './DrawerDetailsToday';
import { DrawerSolicitations } from './DrawerSolicitations';
import { ModalJustify } from './ModalJustify';
import { ProgressRuler } from './ProgressRuler';
import { TimeRuler } from './TimeRuler';

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

export function CheckinProgress({
  alertActions,
  userDetails,
  todayPoints,
  reasons,
  periods,
  getColabPoints
}) {
  const { t, i18n } = useTranslation('common');

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const [solicitations, setSolicitations] = useState([]);
  const [pointDetails, setPointDetails] = useState(null)
  const [openDrawerSolicitations, setOpenDrawerSolicitations] = useState(false)
  const [openModalJustify, setOpenModalJustify] = useState(false)
  const [pointToJustify, setPointToJustify] = useState(null)
  const [periodToJustify, setPeriodToJustify] = useState(null)
  const [dayToJustify, setDayToJustify] = useState(null)
  const [summariesDateToJustify, setSummariesDateToJustify] = useState(null)
  const [typePointToJustify, setTypePointToJustify] = useState(null)
  const [openDrawerDetailsToday, setOpenDrawerDetailsToday] = useState(false)
  
  function checkIfIsAfterTime(period, checkStart = true) {
    const now = moment();

    if (checkStart) {
      const start = moment(period.start_time, 'HH:mm:ss');

      if (now.isAfter(start)) {
        return true
      } else {
        return false
      }
    } else {
      const end = moment(period.end_time, 'HH:mm:ss');

      if (now.isAfter(end)) {
        return true
      } else {
        return false
      }
    }
  }

  async function handleOpenDrawerSolicitations() {
    try {
      const response = await api.get(`/checkin/colab/solicitations/${userDetails.mu_id}`, {
        headers: authHeader()
      })

      setSolicitations(response.data)
      setOpenDrawerSolicitations(true)
    } catch (error) {
      console.log(error)
      //alertActions.error(t("checkin.dashboard.alerts.get_solicitations_points_error"))
    }
  }

  function handleOpenModalJustify(periods_by_day, point, day, summaries_date_id, isCheckin) {

    if (isCheckin) {
      setTypePointToJustify("IN")
    } else {
      setTypePointToJustify("OUT")
    }
    if (point !== null) {
      setPointToJustify(point)
    }
    const p1 = getCorrectPeriod(point,periods_by_day)

    setPeriodToJustify({
      value: p1
    })
    setDayToJustify(day)
    setSummariesDateToJustify(summaries_date_id)

    setOpenModalJustify(true)
  }

  function handleOpenDrawerDetailsDay(day) {
    getPointDetails(day)
    setOpenDrawerDetailsToday(true)
  }

  async function getPointDetails(day) {
    try {
      const response = await api.get(`/checkin/point/details?owner_id=${userDetails.mu_id}&group_id=${userDetails.group_id}&day=${day}`, {
        headers: authHeader()

      })

      setPointDetails(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error('Erro ao carregar detalhes do ponto!')
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="#fff"
      px={3}
      py={2}
      mt={3}
      mb={3}
      boxShadow="0px 2px 3px -2px rgba(0, 0, 0, 0.2)"
      borderRadius="2px"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="row" gap={8} alignItems="center">
          <Typography color="#22BEAD" component="span" fontSize={18} mb="3px" fontWeight={500}>
            {t('checkin.drawer_details_day.today')}
          </Typography>

          <Stack direction="row" gap={'2px'} flexWrap={'wrap'}>
            {todayPoints && (
              <>
                {todayPoints.points.map((point, idx) => (
                  <Fragment key={`${point.id} ${idx}`}>

                    <Box
                      key={idx}
                      display="flex"
                      alignItems="center"
                      gap="4px"
                      padding={'4px'}
                      className={[
                        style.icon,
                        [3, 4, 5, 6, 7].includes(point.status_id) && style.iconHover
                      ].join(' ')}
                      onClick={() => {
                        [5, 6, 7].includes(point.status_id)
                          ? handleOpenDrawerSolicitations()
                          : [3, 4].includes(point.status_id)
                          && handleOpenModalJustify(todayPoints.periods, point, todayPoints.day, todayPoints.summaries_date_id, idx === 0)
                      }}
                    >
                      {point.type === 'IN' ? (
                        <IconIn fill={statusColors[point.status_id - 1]} />
                      ) : (
                        <IconOut fill={statusColors[point.status_id - 1]} />
                      )}
                      <Typography
                        color={
                          [6, 7].includes(point.status_id)
                            ? "#187DFF"
                            : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                        }
                        component="span"
                        fontSize={14}
                        lineHeight="initial"
                      >
                        {[5, 6, 7].includes(point.status_id) ? (
                          point.status_id === 7 ? (
                            "Enviado"
                          ) : point.status_id === 6 ? (
                            point.time.split(':')[0] + ':' + point.time.split(':')[1]
                          ) : (
                            "Recusado"
                          )
                        ) : (
                          point.time.split(':')[0] + ':' + point.time.split(':')[1]
                        )}
                      </Typography>
                    </Box>

                    {/* {idx < todayPoints?.points.length - 1 && (
                    <Divider orientation="vertical" flexItem sx={{ height: '32px', borderRightWidth: '2px', borderColor: '#E4EAEF' }} />
                  )} */}
                  </Fragment>
                ))
                }
                {
                  getRemainingPoints(todayPoints)?.map((item, index) => (

                    <Box
                      key={index}
                      style={{padding: 0 }}
                      align="center"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                        maxWidth="76px"
                        margin="0 auto"
                        className={[
                          style.icon
                        ].join(' ')}
                        padding={'4px'}
                      >
                        {item?.type === 'IN' ? (
                          <IconIn fill={"#C1CDDA"} />
                        ) : (
                          <IconOut fill={"#C1CDDA"} />
                        )}
                        <Typography color="#4F7091" component="span" fontSize={14} lineHeight="initial">
                          --:--
                        </Typography>
                      </Box>
                    </Box>


                  ))
                }
              </>
            )
            }
          </Stack>
        </Box>

        <Tooltip
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#FDBE2B",
                color: "black",
                fontWeight: 500,
                width: '75px'
              }
            }
          }}
          title="Soma do tempo em checkin"
          placement="right"
        >

          <Box display="flex" alignItems="center" gap="4px" className={style.time}>
            <Timer size={17} />
            <Typography
              color="#9da5af"
              component="span"
              fontSize={16}
              lineHeight="initial"
            >
              {todayPoints?.hours_amount}
            </Typography>
          </Box>
        </Tooltip>

        <Box className={style.arrow} onClick={() => handleOpenDrawerDetailsDay(todayPoints.day)}>
          <ArrowRight size={20} />
        </Box>
      </Stack>

      <Box display="flex" mt="20px">
        <TimeRuler data={todayPoints} />
      </Box>

      <Box display="flex" >
        <ProgressRuler data={todayPoints} />
      </Box>

      {openDrawerSolicitations && solicitations && (
        <DrawerSolicitations
          userDetails={userDetails}
          solicitations={solicitations}
          openDrawerSolicitations={openDrawerSolicitations}
          setOpenDrawerSolicitations={setOpenDrawerSolicitations}
        />
      )}

      {openModalJustify && (
        <ModalJustify
          alertActions={alertActions}
          lang={lang}
          dayToJustify={dayToJustify}
          openModalJustify={openModalJustify}
          periodToJustify={periodToJustify}
          pointToJustify={pointToJustify}
          reasons={reasons}
          setDayToJustify={setDayToJustify}
          setOpenModalJustify={setOpenModalJustify}
          setPointToJustify={setPointToJustify}
          setSummariesDateToJustify={setSummariesDateToJustify}
          setTypePointToJustify={setTypePointToJustify}
          summariesDateToJustify={summariesDateToJustify}
          typePointToJustify={typePointToJustify}
          getColabPoints={getColabPoints}
        />
      )}

      {openDrawerDetailsToday && pointDetails && (
        <DrawerDetailsToday
          userDetails={userDetails}
          pointDetails={pointDetails}
          openDrawerDetailsToday={openDrawerDetailsToday}
          setOpenDrawerDetailsToday={setOpenDrawerDetailsToday}
        />
      )}
    </Box>
  );
}
