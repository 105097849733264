import React, { useRef, useState, compose, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from '../../../_helpers/useOutsideClick';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../../_actions';


import Skeleton from '@material-ui/lab/Skeleton';

function CompanyInventorySkeleton(props) {
  const { t, i18n } = useTranslation('common');

  return (
    <React.Fragment>

      <header className="pageHeader daysoff">
        <h3><Skeleton variant="text" width="100px" height={64} /></h3>
        <ul>
          <li>
            <Skeleton variant="text" width="200px" height={64} />
          </li>
          <li>
            <Skeleton variant="text" width="200px" height={64} />
          </li>
          <li>
            <Skeleton variant="text" width="200px" height={64} />
          </li>
        </ul>
        <div className="calendar">

        </div>
      </header>

      <div className="dataTable administrators" style={{ padding: '1%', width: '98%' }}>
        <Skeleton variant="rect" width="100%" height={547} />
      </div>

    </React.Fragment>
  )
};



function mapStateToProps(state) {

  return { auth: state.authentication.user }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyInventorySkeleton));