import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { alertActions, periodicActions, teamsActions } from '../../_actions';
import useOutsideClick from "../../_helpers/useOutsideClick";

import { authHeader, befectiveFormatDate, validateEmail } from "../../_helpers";
import { periodicService } from '../../_services';
import IconAdd from "../_icons/IconAdd";
import IconArrow3 from "../_icons/IconArrow3";
import IconClose from "../_icons/IconClose";
import IconEdit2 from '../_icons/IconEdit2';
import IconEmail2 from '../_icons/IconEmail2';
import IconPeriodicReports2 from "../_icons/IconPeriodicReports2";
import IconRemove from '../_icons/IconRemove';
import IconReport from '../_icons/IconReport';
import IconTo from "../_icons/IconTo";
import { Box } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment/moment';
import { Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import DatePicker from "react-datepicker";
import style from './ScheduledReportsCreate.module.scss'
import { BefectiveDatePicker } from '../goals/BefectiveDatePicker';
import IconCalendar2 from '../_icons/IconCalendar2';
import { api } from '../../_helpers/api';


const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={style.customInput} type='button' onClick={onClick} ref={ref}>
        {value}
    </button>
));

function ScheduledReportsEdit(props) {

    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    let teams = props.teams || []
    let teammates = props.teammates || []
    let teamsList = []
    let teammatesList = []
    const { scheduled, alertActions, updateInfo } = props
    const userDetails = props.userDetails
    const refFilter = useRef();
    const [teamsListFiltered, setTeamsListFiltered] = useState([])
    const [teammatesListFiltered, setTeammatesListFiltered] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [selectReport, setSelectReport] = useState(false)
    const [selectTeams, setSelectTeams] = useState(false)
    const [selectTeammates, setSelectTeammates] = useState(false)

    const [searchTeams, setSearchTeams] = useState('')
    const [searchTeammates, setSearchTeammates] = useState('')

    const [TargetTeamsFilterHTML, setTargetTeamsFilterHTML] = useState([])
    const [TargetTeammatesFilterHTML, setTargetTeammatesFilterHTML] = useState([])
    const [EmailsHTML, setEmailsHTML] = useState([])
    const [emailToAdd, setEmailToAdd] = useState('')
    const [emailError, setEmailError] = useState(true)

    const [groupsObj, setGroupsObj] = useState(scheduled.filter.for_type === 'teams' ? scheduled.filter.group_ids : [])
    const [teammateObj, setTeammateObj] = useState(scheduled.filter.for_type === 'teammates' ? scheduled.filter.machine_users_ids : [])
    const [emailsObj, setEmailsObj] = useState(scheduled?.emails || [])


    const [form, setForm] = useState({
        report_name: scheduled.report_name || 'executive',
        filter_target: scheduled.filter.for_type || 'organization',
        emails: scheduled?.emails || [],
        groups: scheduled.filter.for_type === 'teams' ? scheduled.filter.group_ids : [],
        machine_users: scheduled.filter.for_type === 'teammates' ? scheduled.filter.machine_users_ids : [],
        start_date: scheduled.start_date ? moment(scheduled.start_date).format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'),
        end_date: scheduled.end_date ? moment(scheduled.end_date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        scheduled_date: scheduled.scheduled_date ? moment(scheduled.scheduled_date).format('YYYY-MM-DD') : moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
    });

    const [formError, setFormError] = useState({
        name: '',
        emails: ''
    });

    teammates.sort((a, b) => a.name.localeCompare(b.name))

    for (let t of teammates) {
        teammatesList.push(<li id={'teammate-' + t.id} onClick={() => {
            setTeammateFilter(t.id, t.name)
        }}>{t.name}</li>)
    }

    for (let t0 of teams) {
        if (!t0.ancestry) {

            teamsList.push(<li id={'team-' + t0.id} onClick={() => {
                setTeamFilter(t0.id, t0.name)
            }}>{t0.name}</li>)
            let p0 = t0.id
            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(<li id={'team-' + t1.id} onClick={() => {
                        setTeamFilter(t1.id, t1.name)
                    }}>&nbsp;&nbsp;{t1.name}</li>)
                    let p1 = t1.id
                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<li id={'team-' + t2.id} onClick={() => {
                                setTeamFilter(t2.id, t2.name)
                            }}>&nbsp;&nbsp;&nbsp;&nbsp;{t2.name}</li>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<li id={'team-' + t3.id} onClick={() => {
                                        setTeamFilter(t3.id, t3.name)
                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t3.name}</li>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<li id={'team-' + t4.id} onClick={() => {
                                                setTeamFilter(t4.id, t4.name)
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t4.name}</li>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<li id={'team-' + t5.id} onClick={() => {
                                                        setTeamFilter(t5.id, t5.name)
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t5.name}</li>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<li id={'team-' + t6.id} onClick={() => {
                                                                setTeamFilter(t6.id, t6.name)
                                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t6.name}</li>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<li id={'team-' + t7.id}
                                                                        onClick={() => {
                                                                            setTeamFilter(t7.id, t7.name)
                                                                        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t7.name}</li>)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    function toggleSelectTeams(status, e) {


        if (!e.target.classList.contains('search')) {
            setSelectTeams(status)
            setTeamsListFiltered([])
            setSearchTeams('')
        }


    }

    function toggleSelectTeammates(status, e) {


        if (!e.target.classList.contains('search')) {
            setSelectTeammates(status)
            setTeammatesListFiltered([])
            setSearchTeammates('')
        }


    }

    useOutsideClick(refFilter, target => {


        if (refFilter && typeof target == "string" && !target.includes("input")) {

            if (selectTeams) {
                setSelectTeams(false)
            }

            if (selectTeammates) {
                setSelectTeammates(false)
            }

            if (selectReport) {
                setSelectReport(false)
            }

        }

    });

    function setTeamFilter(id, name) {

        setFormError({
            ...formError,
            groups: '',
        })
        if (form?.groups.indexOf(id) !== -1) {
            return;
        }
        setGroupsObj([...groupsObj, { id: id, name: name }])
        let groups = form?.groups
        groups.push(id)
        setForm({
            ...form,
            groups: groups,
        })

    }

    function setTeammateFilter(id, name) {

        setFormError({
            ...formError,
            machine_users: '',
        })

        if (form?.machine_users.indexOf(id) !== -1) {
            return;
        }
        let mu = form?.machine_users

        mu.push(id)

        setForm({
            ...form,
            machine_users: mu,
        })
        setTeammateObj([...teammateObj, { id: id, name: name }])

    }

    function addEmail() {

        if (validateEmail(emailToAdd)) {

            setFormError({
                ...formError,
                emails: '',
            })

            setEmailError(false)
            setEmailToAdd('')

            let emails = form?.emails

            emails.push(emailToAdd)
            setEmailsObj([...emailsObj, emailToAdd])
            setForm({
                ...form,
                emails: emails,
            })

        }


    }

    function removeEmailFilter(email) {
        // document.getElementById("email-" + email).remove();

        const emails = form?.emails

        const index = emails.indexOf(email);
        if (index > -1) { // only splice array when item is found
            emails.splice(index, 1); // 2nd parameter means remove one item only
        }
        setEmailsObj(emailsObj.filter(item => item !== email))
        setForm({
            ...form,
            emails: emails,
        })

    }
    function removeTeamFilter(id) {
        // document.getElementById("filter-team-" + id).remove();

        let groups = form?.groups

        const index = groups.indexOf(id);
        if (index > -1) { // only splice array when item is found
            groups.splice(index, 1); // 2nd parameter means remove one item only
        }
        setGroupsObj(groupsObj.filter(item => item.id != id))
        setForm({
            ...form,
            groups: groups,
        })

    }

    function removeTeammateFilter(id) {
        // document.getElementById("filter-teammate-" + id).remove();

        let mu = form?.machine_users

        const index = mu.indexOf(id);
        if (index > -1) { // only splice array when item is found
            mu.splice(index, 1); // 2nd parameter means remove one item only
        }

        setForm({
            ...form,
            machine_users: mu,
        })
        setTeammateObj(teammateObj.filter(item => item.id != id))
    }


    const handleChange = e => {
        //e.preventDefault();
        setSubmitted(false)

        if (e.target.name == 'filter_target') {
            setTargetTeamsFilterHTML([])
            setTargetTeammatesFilterHTML([])
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        } else {

            setForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        }


    }


    function handleChangeSearchFilter(e) {

        e.preventDefault()
        setSearchTeams(e.target.value)

        let listTeams = []


        if (e.target.value.length > 2) {

            for (let team of teams) {
                let testName = team.name.toLowerCase();

                if (testName.includes(e.target.value.toLowerCase())) {
                    listTeams.push(<li id={'team-' + team.id} onClick={() => {
                        setTeamFilter(team.id, team.name)
                    }}>&nbsp;&nbsp;{team.name}</li>)
                }
            }
            setTeamsListFiltered(listTeams)

        } else {
            setTeamsListFiltered([])
        }

    }

    function handleEmailToAdd(e) {

        e.preventDefault()


        if (validateEmail(e.target.value)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }

        setEmailToAdd(e.target.value)

    }

    function handleChangeSearchFilter2(e) {

        e.preventDefault()
        setSearchTeammates(e.target.value)

        let listTeammates = []


        if (e.target.value.length > 2) {

            for (let teammate of teammates) {
                let testName = teammate.name.toLowerCase();

                if (testName.includes(e.target.value.toLowerCase())) {
                    listTeammates.push(<li id={'team-' + teammate.id} onClick={() => {
                        setTeamFilter(teammate.id, teammate.name)
                    }}>&nbsp;&nbsp;{teammate.name}</li>)
                }
            }
            setTeammatesListFiltered(listTeammates)

        } else {
            setTeammatesListFiltered([])
        }

    }

    const handleChangeReport = (report) => {
        setSubmitted(false)

        setForm({
            ...form,
            report_name: report,
        })

    }

    async function handleSubmit(event) {

        event.preventDefault()

        // console.log("SUBMIT", form)

        setSubmitted(true)

        let formIsValid = true;

        let tmpErrors = {}

        if (form?.emails.length < 1) {
            formIsValid = false

            tmpErrors.emails = t('settings.company.error.required')

        }

        if (form?.filter_target == 'teams' && form?.groups.length < 1) {
            formIsValid = false

            tmpErrors.groups = t('settings.company.error.required')

        }

        if (form?.filter_target == 'teammates' && form?.machine_users.length < 1) {
            formIsValid = false

            tmpErrors.machine_users = t('settings.company.error.required')

        }

        if (formIsValid) {


            setFormError({
                name: '',
                emails: ''
            })

            let filter = {}

            if (form?.filter_target === 'teammates') {
                filter = {
                    for_type: form?.filter_target,
                    machine_users_ids: form?.machine_users
                }
            } else if (form?.filter_target === 'teams') {
                filter = {
                    for_type: form?.filter_target,
                    group_ids: form?.groups
                }
            } else {
                filter = {
                    for_type: form?.filter_target
                }
            }

            const payload = {
                ...form,
                filter: filter
            }

            const res = await api.put(`/scheduled_reports/${scheduled.id}`, payload, { headers: authHeader() })
            setSubmitted(false)
            alertActions.success('Relatório atualizado com sucesso!')
            await updateInfo()
            // location.reload(true)
        } else {

            alertActions.error('Erro ao atualizar o relatório.')

            setFormError({
                ...tmpErrors
            })

            setSubmitted(false)
        }


    }

    function configGroup(filter) {

        switch (filter?.for_type) {
            case 'teammates':
                // pegar o teamates by ids
                const teammates_f = teammates.filter(item => filter.machine_users_ids.indexOf(item.id) !== -1)
                const teammates_ids = teammates_f.reduce(function (ac, teammate) {
                    return [...ac, teammate.id]
                }, [])
                const teammates_ids_f = teammates_ids.filter(item => form?.machine_users.indexOf(item) === -1)
                const teammate_new = [...form?.machine_users, ...teammates_ids_f]

                setForm({
                    ...form,
                    machine_users: teammate_new,
                })
                setTeammateObj(teammates_f)
                break;

            case 'teams':
                const team_f = teams.filter(item => filter.group_ids.indexOf(item.id) !== -1)

                const teams_f_ids = team_f.reduce(function (ac, team) {
                    return [...ac, team.id]
                }, [])
                const teams__ids_f = teams_f_ids.filter(item => form?.groups.indexOf(item) === -1)
                const teams_new = [...form?.groups, ...teams__ids_f]

                setForm({
                    ...form,
                    groups: teams_new,
                })
                setGroupsObj(team_f)
                break;
            case 'organization':
                break;
        }
    }

    useEffect(() => {
        console.log('form -> ', form)
    }, [form])

    useEffect(() => {
        configGroup(scheduled?.filter)
    }, [])

    return (
        <React.Fragment>

            <div className="editPage visible">
                <form onSubmit={handleSubmit} className="form edit">

                    <section className={'hasSub'}>

                        <div className={'sub'}>
                            <div className="icon">
                                <IconReport />

                            </div>
                            <span className={'filterLabel'}>{t('periodic.report')}</span>

                            <div className="field fixed">
                                <div className={selectReport ? 'befSelect opened' : 'befSelect'} onClick={() => {
                                    setSelectReport(!selectReport)
                                }}>
                                    <ul ref={refFilter}>
                                        <li className={'empty'}>Selecione uma opção</li>
                                        <li className={form?.report_name == 'executive' ? 'selected' : ''} onClick={() => {
                                            handleChangeReport('executive')
                                        }}>{t('periodic.executive')}
                                        </li>
                                        <li className={form?.report_name == 'active_mood_goal' ? 'selected' : ''}
                                            onClick={() => {
                                                handleChangeReport('active_mood_goal')
                                            }}>{t('periodic.active_mood_goal')}
                                        </li>
                                        <li className={form?.report_name == 'active_time_goal' ? 'selected' : ''}
                                            onClick={() => {
                                                handleChangeReport('active_time_goal')
                                            }}>{t('periodic.active_time_goal')}
                                        </li>
                                        <li className={form?.report_name == 'active_work_goal' ? 'selected' : ''}
                                            onClick={() => {
                                                handleChangeReport('active_work_goal')
                                            }}>{t('periodic.active_work_goal')}
                                        </li>
                                        <li className={form?.report_name == 'teams' ? 'selected' : ''}
                                            onClick={() => {
                                                handleChangeReport('teams')
                                            }}>{t('periodic.teams')}
                                        </li>
                                        <li className={form?.report_name == 'teammates' ? 'selected' : ''}
                                            onClick={() => {
                                                handleChangeReport('teammates')
                                            }}>{t('periodic.teammates')}
                                        </li>
                                    </ul>
                                    <IconArrow3 />
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className={'hasSub'}>
                        <div className={'sub'}>
                            <div className="icon">
                                <IconTo />

                            </div>
                            <span className={'filterLabel'}>{t('periodic.target_filter')}</span>
                            <div className="field">
                                <input type="radio" id="organization" name="filter_target"
                                    checked={form?.filter_target == 'organization'}
                                    value="organization"
                                    onChange={handleChange} /> <label
                                        htmlFor="organization">{t('periodic.organization')}</label>
                            </div>
                            <div className="field">
                                <input type="radio" id="teams" name="filter_target"
                                    checked={form?.filter_target == 'teams'}
                                    value="teams"
                                    onChange={handleChange} /> <label
                                        htmlFor="teams">{t('periodic.teams')}</label>
                            </div>
                            <div className="field">
                                <input type="radio" id="teammates" name="filter_target"
                                    checked={form?.filter_target == 'teammates'}
                                    value="teammates"
                                    onChange={handleChange} /> <label
                                        htmlFor="teammates">{t('periodic.teammates')}</label>
                            </div>
                        </div>
                        {form?.filter_target == 'teams' &&
                            <React.Fragment>
                                <div className={'sub noIcon'}>
                                    <span className={'filterLabel'}>{t('periodic.teams')}</span>
                                    <div className="field fixed">
                                        <div className={selectTeams ? 'befSelect opened' : 'befSelect'}
                                            onClick={(e) => {
                                                toggleSelectTeams(!selectTeams, e)
                                            }}>
                                            <ul ref={refFilter}>
                                                <li className={'empty'}><input className={'search'} type={'text'}
                                                    placeholder={'Selecione uma equipe'}
                                                    value={searchTeams}
                                                    onChange={(e) => handleChangeSearchFilter(e)} />
                                                </li>
                                                {teamsListFiltered.length > 0 &&
                                                    teamsListFiltered
                                                }
                                                {teamsListFiltered.length == 0 &&
                                                    teamsList
                                                }
                                            </ul>
                                            <IconArrow3 />
                                        </div>
                                    </div>
                                    {formError.groups &&
                                        <div className="error periodic sub">{formError.groups}</div>
                                    }
                                </div>
                                {groupsObj?.length > 0 &&
                                    <div className="periods">
                                        <div className={'periodRow target'}>

                                            {groupsObj.map((item) =>
                                            (<div id={'filter-team-' + item.id} onClick={() => {
                                                removeTeamFilter(item.id)
                                            }} className={'team targetFilter'}>{item.name}
                                                <button type={'button'}><IconClose /></button>
                                            </div>)
                                            )}
                                        </div>
                                    </div>
                                }

                            </React.Fragment>
                        }

                        {form?.filter_target == 'teammates' &&
                            <React.Fragment>
                                <div className={'sub noIcon'}>
                                    <span className={'filterLabel'}>{t('periodic.teammates')}</span>
                                    <div className="field fixed">
                                        <div className={selectTeammates ? 'befSelect opened' : 'befSelect'}
                                            onClick={(e) => {
                                                toggleSelectTeammates(!selectTeammates, e)
                                            }}>
                                            <ul ref={refFilter}>
                                                <li className={'empty'}><input className={'search'} type={'text'}
                                                    placeholder={'Selecione um colaborador'}
                                                    value={searchTeammates}
                                                    onChange={(e) => handleChangeSearchFilter2(e)} />
                                                </li>
                                                {teammatesListFiltered.length > 0 &&
                                                    teammatesListFiltered
                                                }
                                                {teammatesListFiltered.length == 0 &&
                                                    teammatesList
                                                }
                                            </ul>
                                            <IconArrow3 />
                                        </div>
                                    </div>
                                    {formError.machine_users &&
                                        <div className="error periodic sub">{formError.machine_users}</div>
                                    }
                                </div>
                                {teammateObj.length > 0 &&
                                    <div className="periods">
                                        <div className={'periodRow target'}>
                                            {teammateObj.map(item => <div id={'filter-teammate-' + item.id} onClick={() => {
                                                removeTeammateFilter(item.id)
                                            }} className={'teammate targetFilter'}>{item.name}
                                                <button type={'button'}><IconClose /></button>
                                            </div>)}
                                        </div>
                                    </div>
                                }


                            </React.Fragment>
                        }

                    </section>

                    <section className={'hasSub'}>
                        <div className="icon">
                            <IconCalendar2 />
                        </div>

                        <span className={'filterLabel'}>Período</span>

                        <Box display={'flex'} style={{ gap: '6px' }}>
                            <Box className={style.boxDatepicker}>
                                <Typography component={'label'} variant='caption' fontSize={'12px'} style={{ color: '#4F7091' }}>Data de início</Typography>
                                <BefectiveDatePicker
                                    userDetails={userDetails}
                                    date_value={form.start_date}
                                    formEdit={form}
                                    setFormEdit={setForm}
                                    setSubmitted={setSubmitted}
                                    field="start_date"
                                    minDate={moment(new Date()).subtract(6, 'months').format('YYYY-MM-DD')}
                                    maxDate={moment(new Date()).format('YYYY-MM-DD')}
                                    className="scheduledInput"
                                    customInput={<CustomInput />}
                                />

                            </Box>
                            <Box className={style.boxDatepicker}>
                                <Typography component={'label'} variant='caption' fontSize={'12px'} style={{ color: '#4F7091' }}>Data de término</Typography>
                                <BefectiveDatePicker
                                    userDetails={userDetails}
                                    date_value={form.end_date}
                                    formEdit={form}
                                    setFormEdit={setForm}
                                    setSubmitted={setSubmitted}
                                    field="end_date"
                                    minDate={form.start_date}
                                    maxDate={moment(new Date()).format('YYYY-MM-DD')}
                                    className="scheduledInput"
                                    customInput={<CustomInput />}
                                />
                            </Box>
                        </Box>
                    </section>

                    <section className={'hasSub'}>
                        <div className="icon">
                            <IconCalendar2 />
                        </div>

                        <span className={'filterLabel'}>Data de envio</span>

                        <Box display={'flex'} style={{ gap: '6px' }}>
                            <Box className={style.boxDatepicker}>
                                {/* <Typography component={'label'} variant='caption' fontSize={'12px'}>Data de envio</Typography> */}
                                <BefectiveDatePicker
                                    userDetails={userDetails}
                                    date_value={form.scheduled_date}
                                    formEdit={form}
                                    setFormEdit={setForm}
                                    setSubmitted={setSubmitted}
                                    field="scheduled_date"
                                    minDate={moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}
                                    maxDate={""}
                                    className="scheduledInput"
                                    customInput={<CustomInput />}
                                />
                            </Box>
                        </Box>
                    </section>

                    <section className={'hasSub'}>
                        <div className="icon">
                            <IconEmail2 />
                        </div>
                        <span className={'filterLabel'}>{t('periodic.emails')}</span>
                        <div className="field fixed" style={{ flexWrap: 'nowrap' }}>
                            <input type="text" id="emailToAdd" name="emailToAdd"
                                className={(emailError && emailToAdd.length > 2) ? 'validationError' : ''}
                                placeholder={t('periodic.email_place_holder')}
                                autoComplete="off"
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && !emailError) {
                                        addEmail()
                                    }
                                }}
                                value={emailToAdd} onChange={(e) => handleEmailToAdd(e)} />
                            <button className={emailError ? 'addEmail validationError' : 'addEmail'} type={'button'}
                                onClick={() => addEmail()}><IconAdd />
                            </button>

                        </div>
                        {emailsObj?.length > 0 &&
                            <div className="periods">
                                <div className={'periodRow target'}>
                                    {emailsObj.map(item => <div id={'email-' + item} onClick={() => {
                                        removeEmailFilter(item)
                                    }} className={'team targetFilter'}>{item}
                                        <button type={'button'}><IconClose /></button>
                                    </div>)}
                                </div>
                            </div>
                        }
                        {formError.emails &&
                            <div className="error periodic">{formError.emails}</div>
                        }
                    </section>

                    <section className={'hasSub'}>
                        <button className={'cancel'} type={'button'} onClick={() => {
                            history.push('/productive/reports/scheduled-reports')
                        }}
                        >{t('periodic.cancel')}</button>
                        <button className={'submit'} type={'submit'}>{t('periodic.submit')}</button>
                    </section>

                    <section>
                        <Box style={{ backgroundColor: '#FFECD4', width:'100%', padding: '10px', borderRadius: '4px' }}>
                            <Typography style={{ color: '#FDA02B' }} variant='subtitle2' component={'p'}>{t('scheduled.obs')}</Typography>
                            <Typography style={{ color: '#FDA02B' }} variant='body2' component={'p'}>{t('scheduled.msg_01')} <strong style={{fontSize:'12px'}}>{befectiveFormatDate(form?.scheduled_date, i18n.language)}</strong>.</Typography>
                            <Typography style={{ color: '#FDA02B' }} variant='body2' component={'p'}>{t('scheduled.msg_02')}</Typography>
                        </Box>
                    </section>


                </form>

            </div>
        </React.Fragment>

    )
};

function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        permissions: state.authentication.permissions,
        current: state.teams.detail,
        teams: state.teams.payload,
        teammates: state.teammates.payload,
        created: state.periodic.created,
        updatedActivities: state.teams.updatedActivities,
        userDetails: state.authentication.userDetails,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        teamsActions: bindActionCreators(teamsActions, dispatch),
        periodicActions: bindActionCreators(periodicActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsEdit));
