import { makeStyles } from '@material-ui/core';
import { useLoadScript } from "@react-google-maps/api";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, filterActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import Map from '../../_components/checkin_page/Map';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinReportsMapPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, teammates, filter, filterActions } = props
  const [markers, setMarkers] = useState([]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDzdRVu96X67ri9_8M83Vag9IdAQ7suhaM" // Add your API key
  });

  const getMarkers = async (dateFilter = moment(new Date()).format('YYYY-MM-DD'), teams = null, teammates = null) => {
    try {

      let queryTeams = ''
      let queryTeammates = ''

      if (teams && teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of teams) {
          if (nt == 0) {
            queryTeams += team.id;
          } else {
            queryTeams += "," + team.id;
          }
          nt++;
        }

      }

      if (teammates && teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of teammates) {
          if (nt === 0) {
            queryTeammates += teammate.id;
          } else {
            queryTeammates += "," + teammate.id;
          }
          nt++;
        }

      }

      const res = await api.get(`/checkin/map-locations?date=${dateFilter}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })
      
      setMarkers(res.data.filter(item => item.position !== null))
      
    } catch (error) {
      alertActions.error('Erro ao carregar os pontos')
    }
  }

  useEffect(() => {
    let date = moment(new Date()).format('YYYY-MM-DD');

    if (filter.singleDate) {
      date = filter.singleDate
    }

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getMarkers(date, teams, teammates)
  }, [filter])

  useEffect(() => {
    filterActions.replace(moment(new Date()).format('YYYY-MM-DD'), 'singleWhen')
    filterActions.clear('who')
  }, [])

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'single'}
          filterWhat={'disabled'}
        />
        {
          <div className={style.containerCheckinPage}>
            {isLoaded && <Map markers={markers} />}
          </div>
        }
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinReportsMapPage)