import { Box, Divider } from '@material-ui/core';
import { Menu, MenuItem } from '@mui/material';
import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import utf8 from 'utf8';

import './toolbar.scss';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';

export function MenuMagic({
  anchorEl,
  open,
  handleClose,
  userDetails,
  handleClick2,
  open2,
  anchorEl2,
  handleClose2,
  organizations,
  backfectiveActions,
  isMuUser = false,
}) {
  const { t } = useTranslation('common');

  const history = useHistory();
  const  { saveFirstAccess, saveCurrentModule } = useCurrentModule()
  const path = window.location.pathname.split("/")

  function handleRedirect(url) {
    saveCurrentModule('productivity_management')
    // saveFirstAccess(true)
    handleClose()
    history.push(url)
  }

  function handleChangeOrganization(orgId) {
    handleClose()
    backfectiveActions.magicAgent(orgId)
  }

  function zeroPad(num, places) {
    var zero = places - num.toString()?.length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="menu-befective"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#24677E",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => userDetails?.roleName !== "machine_user" && handleRedirect('/settings/my-account')}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <span style={{ fontSize: "16px", fontWeight: "500", color: '#fff' }}>
            {utf8.decode(userDetails?.name)}
          </span>
          <span style={{ fontSize: "12px", color: '#fff' }}>
            {utf8.decode(userDetails?.organizationName)}
          </span>
          <span style={{ fontSize: "11px", color: '#97B3BF' }}>
            {userDetails?.profile_name}
          </span>
        </MenuItem>

        <Divider />

        {!isMuUser && (
          <Box display={'flex'} flexDirection={'column'}>

            {/* {userDetails?.isMagicAgent && (
              <MenuItem onClick={() => handleRedirect('/backfective/invitations')}>
                <span className='point'>
                  Backfective
                </span>
              </MenuItem>
            )} */}

            {userDetails?.isMagicAgent && (
              <MenuItem onClick={handleClick2} className={open2 ? 'selected' : ''}>
                <span className='point'>
                  {t('menu.user.magic_agent')}
                </span>
              </MenuItem>
            )}

            <MenuItem onClick={() => handleRedirect('/download')}>
              <span className='point'>
                {t('menu.user.download')}
              </span>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect('/terms')}>
              <span className='point'>
                {t('menu.user.terms')}
              </span>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect(`/calls-page/?last_url=${path.slice(1, path.length)}`)}>
              <span className='point'>
                {t('menu.user.call_suport')}
              </span>
            </MenuItem>
            {/* <MenuItem onClick={() => handleRedirect('/help-center')}>
              <span className='point'>
                {t('menu.user.help_center')}
              </span>
            </MenuItem> */}

          </Box>
        )}

        <MenuItem onClick={() => handleRedirect('/login')}>
          <span className='point'>
            {t('menu.user.sign_out')}
          </span>
        </MenuItem >

      </Menu>

      {!isMuUser && (
        <Menu
          anchorEl={anchorEl2}
          id="menu-befective"
          open={open2}
          onClose={handleClose2}
          onClick={handleClose2}
          PaperProps={{
            style: {
              transform: 'translateX(-82%) translateY(-168px)',
              maxHeight: '450px',
              overflowY: 'scroll',
              marginTop: '30px'
            },
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 180,
                right: -5,
                width: 10,
                height: 10,
                bgcolor: "#24677E",
                transform: "translateY(-30%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {organizations?.map(org => (
            Moment(org.validation_date).isAfter(Moment(), "day") && (
              <MenuItem
                key={org.id}
                onClick={() => handleChangeOrganization(org.id)}
                style={{ paddingTop: '10px', paddingBottom: '10px' }}
              >
                <span style={{ marginRight: '12px', fontSize: '12px', fontWeight: '500', color: org.client_type === 'poc' ? '#FC6662' : '#ffffff'}}>
                  {zeroPad(org.id, 4)}
                </span>
                <span style={{color: org.client_type === 'poc' ? '#FC6662' : '#ffffff'}}>
                  {org.name}
                </span>
              </MenuItem>
            )

          ))}
        </Menu>
      )}

    </>
  );
}
