import { menuConstants } from '../_constants';

export function menu(state = {}, action) {

    switch (action.type) {

        case menuConstants.SET_MENU_REPORTS:
            return {

                reports: action.state
            };

        case menuConstants.SET_MENU_SETTINGS:
            return {

                settings: action.state
            };

        case menuConstants.SET_MENU_GOALS:
            return {

                goals: action.state
            };

        case menuConstants.SET_MENU_INTEGRATIONS:
            return {

                integrations: action.state
            };

        case menuConstants.SET_MENU_CHECKIN:
            return {

                checkin: action.state
            };
        case menuConstants.SET_MENU_CHECKIN_CONTROL:
            return {

                control: action.state
            };
        case menuConstants.SET_MENU_PROJECT_MANAGEMENT_CONTROL:
            return {

                project_management: action.state
            };
        default:
            return state
    }
}