import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../../_icons/IconClose";
import { api } from "../../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../../_actions";
import { authHeader } from "../../../_helpers";

import "./styles.scss"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalEditAttribute({
  openModalEditAttribute,
  setOpenModalEditAttribute,
  userDetails,
  alertActions,
  updateInfo,
  attributeToEdit
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    title: attributeToEdit?.title || '',
    pre_selected: attributeToEdit?.pre_selected === true ? '1' : '0'
  });

  const [formError, setFormError] = useState({
    title: '',
    pre_selected: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function closeModalEditAttribute() {
    setOpenModalEditAttribute(false)
    setForm({
      title: '',
      pre_selected: '1'
    })
    setFormError({
      title: '',
      pre_selected: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('messages.modal_new_message.required_field')
    }

    return errors
  }


  const handleSubmitAttribute = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const editAttribute = async () => {
    try {
      setIsSubmitting(true)

      const res = await api.put(`/rooms/attributes/${attributeToEdit?.id}`, form,
        {
          headers: authHeader()
        }
      )
      if (res.data) {
        updateInfo()
        alertActions.success(t('spaces.success.update'))
        closeModalEditAttribute()
        setIsSubmitting(false)
      }

    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('spaces.error.update'))
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      editAttribute()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormAttributesEdit ${classes.modal}`}
        open={openModalEditAttribute}
        onClose={closeModalEditAttribute}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalEditAttribute} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormAttributesEdit"}>

            <button onClick={() => { closeModalEditAttribute() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmitAttribute}>
              <main>
                <header>
                  <h3>
                    {t('spaces.attributes.edit')}
                  </h3>
                </header>
                <div className="field">
                  <label htmlFor="title">{t('spaces.attributes.title')}*</label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    minLength={2}
                    maxLength={60}
                    placeholder={t('spaces.attributes.title_placeholder')}
                    value={form.title || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.title.length}/60</span>
                  {formError.title &&
                    <div className="error">{formError.title}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="pre_selected">{t('spaces.attributes.pre_selected')}*</label>
                  <RadioGroup
                    row
                    aria-labelledby="pre_selected"
                    name="pre_selected"
                    id="pre_selected"
                    value={form?.pre_selected}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Sim" />
                    <FormControlLabel value="0" control={<Radio />} label="Não" />
                  </RadioGroup>
                  {formError.pre_selected &&
                    <div className="error">{formError.pre_selected}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalEditAttribute() }}
                    >
                      {t('spaces.attributes.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('spaces.attributes.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalEditAttribute));
