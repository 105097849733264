import React from 'react'
import style from './styles.module.scss';
import { Box, Skeleton } from '@mui/material';

export default function Widget13Skeleton() {

  return (
    <div className={style.container}>
      <Box display={'flex'}>
        <Skeleton variant='h6' width={'30%'} />
      </Box>

      <Box display={'flex'} gap={'6px'} justifyContent={'space-around'} alignItems={'center'} minHeight={'200px'}>
        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="circular" width={80} height={80} />
      </Box>
      <Box display={'flex'} >
        <Skeleton variant='p' width={'40%'} />
      </Box>
    </div>
  )
}
