import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers";

import "./ModalMoveTo.scss"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Delete } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const options = ['Option 1', 'Option 2'];

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '100%',
    marginTop: '4px'
  },
  list: {
    border: '1px solid #D6DEEB',
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: 200
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#187dff', // Cor da borda quando está em foco
      borderWidth: '1px'
    }
  }
}));

function ModalMoveTo({
  openModalMoveTo,
  setOpenModalMoveTo,
  userDetails,
  alertActions,
  getTeamsData,
  teamsSelected,
  removeTeam,
  teams,
  handleCloseAncor
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const teamOptions = [
    {
      label: t('settings.teams.option_select'),
      value: '',
      disabled: true
    },
    ...teams?.map(t => {
      return {
        label: t.herac,
        value: t.id
      }
    })
  ];

  const [form, setForm] = useState({
    destination_group_id: teamOptions[0]
  });

  const [formError, setFormError] = useState({
    destination_group_id: ''
  });

  function closeModalMoveTo() {
    setOpenModalMoveTo(false)
    setForm({
      destination_group_id: teamOptions[0]
    })
    setFormError({
      destination_group_id: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};
    if (!form.destination_group_id || form.destination_group_id.value === '') {
      errors.destination_group_id = t('settings.teams.required_field')
    }
    
    return errors
  }


  const handleMoveToTeam = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const moveToTeam = async () => {
    try {
      setIsSubmitting(true)

      const payload = {
        type: 'TEAM',
        action: 'MOVE',
        ids: teamsSelected.map(t => t.id).join(','),
        destination_group_id: form.destination_group_id.value
      }
      const res = await api.post(`/settings/`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao mover as equipes')
      }

      getTeamsData({ pageIndex: 0, pageSize: 20, isLoading: false })
      closeModalMoveTo()
      handleCloseAncor()
      alertActions.success(t('settings.teams.success.move_teams'))

    } catch (error) {
      console.error(error)
      // alertActions.error(error)
    }finally{
      setIsSubmitting(false)
    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      moveToTeam()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


 

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalFormMoveTo ${classes.modal}`}
        open={openModalMoveTo}
        onClose={closeModalMoveTo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalMoveTo} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalFormMoveTo"}>

            <button onClick={closeModalMoveTo} className="close"><IconClose /></button>

            <form onSubmit={handleMoveToTeam}>
              <main>
                <header>
                  <h3>
                    {t('settings.teams.title_form_move')}
                  </h3>
                </header>
                <div className="field">
                  <label>{`${t('settings.teams.field_selected_teams')} (${teamsSelected.length})`}</label>
                  <div className={`${classes.listContainer}`}>
                    <List dense={true} className={classes.list}>
                      {
                        teamsSelected.map((team) => (
                          <ListItem key={team.id}>
                            <ListItemText
                              style={{ color: '#1E6B7F' }}
                              primary={team.name}
                            />
                            {
                              teamsSelected.length > 1 &&
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="remove" size={'small'} onClick={() => removeTeam(team.id)}>
                                  <Delete size={'small'} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            }
                          </ListItem>
                        ))
                      }
                    </List>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="title">{t('settings.teams.field_move_to')}</label>
                  <Autocomplete
                    value={form.destination_group_id}
                    onChange={(event, newValue) => {
                      setForm({...form, destination_group_id:newValue})
                      setFormError({...formError, destination_group_id: ''})
                    }}
                    renderOption={(option) => {                      
                      if(option.disabled){
                        return <option disabled hidden selected style={{display:'none'}}>{option.label}</option>
                      }
                      return <option>{option.label}</option>
                    }}
                    getOptionDisabled={(option) => console.log(option)}
                    getOptionLabel={(option) => option.label}
                    id="teams-to-move"
                    options={(teamOptions && teamOptions.length > 0) ? teamOptions.filter(t => !teamsSelected.map(ts => ts.id).includes(t.value)) : []}
                    fullWidth
                    renderInput={(params) => <TextField {...params} className={classes.focused} variant="outlined" fullWidth />}
                  />
                  {formError.destination_group_id &&
                    <div className="error">{formError.destination_group_id}</div>
                  }
                </div>

                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={closeModalMoveTo}
                    >
                      {t('settings.teams.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('settings.teams.move_btn')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalMoveTo));
