import React from 'react';
import { history } from '../../_helpers';

const SubspaceIconChair = ({
    shape,
    tooltipId,
    x,
    y,
    rotationAngle,
    onMouseDown = () => { },
    onDoubleClick = () => { },
    urlRedirect = null
}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x={x} y={y} style={{ cursor: 'pointer' }}
            viewBox="0 0 800 800" width={50} height={50} onMouseDown={onMouseDown} onClick={() => urlRedirect && history.push(urlRedirect)}
            data-for={tooltipId}
            data-tip
        >

            <g transform={`rotate(${rotationAngle} 300 300)`} onDoubleClick={onDoubleClick} >

                <rect x="239.48" y="66.06" style={{ fill: "#5D5360" }} width="33.03" height="74.32" />
                <rect x="239.48" y="115.61" style={{ fill: "#5D5360" }} width="33.03" height="33.03" />
                <rect x="24.774" y="231.23" style={{ fill: "#5D5360" }} width="462.45" height="33.03" />
                <rect x="66.06" y="231.23" style={{ fill: "#5D5360" }} width="379.87" height="33.03" />
                <g>
                    <path style={{ fill: "#B67F5F" }} d="M82.581,181.678v256.454c0,32.779,24.022,60.476,56.431,65.388C175.042,508.981,214.549,512,256,512
                  s80.958-3.019,116.989-8.48c32.409-4.912,56.431-32.609,56.431-65.388V181.678c0-27.365-22.184-49.548-49.548-49.548H132.129
                  C104.764,132.129,82.581,154.313,82.581,181.678z"/>
                    <path style={{ fill: "#B67F5F" }} d="M421.219,26.852C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                  c-12.887,4.535-19.608,18.514-15.012,31.232c3.609,10.001,13.149,16.231,23.333,16.233l313.795,0.006
                  c10.177,0,19.721-6.242,23.334-16.239C440.828,45.366,434.106,31.387,421.219,26.852z"/>
                </g>
                <path style={{ fill: "#5D5360" }} d="M20.645,404.645L20.645,404.645C9.243,404.645,0,395.402,0,384V194.064
                c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C41.29,395.402,32.047,404.645,20.645,404.645z"
                />
                <path style={{ fill: "#6F6571" }} d="M24.774,173.836v230.393C34.198,402.316,41.29,393.987,41.29,384V194.065
                C41.29,184.077,34.198,175.749,24.774,173.836z"/>
                <path style={{ fill: "#5D5360" }} d="M491.355,404.645L491.355,404.645c-11.402,0-20.645-9.243-20.645-20.645V194.064
                c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C512,395.402,502.757,404.645,491.355,404.645z"
                />
                <path style={{ fill: "#6F6571" }} d="M495.484,173.836v230.393C504.907,402.316,512,393.987,512,384V194.065
                C512,184.077,504.907,175.749,495.484,173.836z"/>
                <path style={{ fill: "#A06E50" }} d="M115.613,297.29c0-77.534,62.853-140.387,140.387-140.387h166.788
                c-8.571-14.795-24.588-24.774-42.917-24.774H132.129c-27.365,0-49.548,22.184-49.548,49.548v256.454
                c0,24.209,13.125,45.657,33.032,57.19C115.613,495.321,115.613,297.29,115.613,297.29z"/>
                <path style={{ fill: "#CD916E" }} d="M77.837,62.326c2.841-4.78,7.239-8.693,12.944-10.7C140.718,34.059,197.849,24.774,256,24.774
                s115.282,9.285,165.219,26.852c5.703,2.007,10.101,5.919,12.943,10.698c0.8-1.333,1.522-2.727,2.069-4.24
                c4.597-12.718-2.125-26.697-15.012-31.232C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
                c-12.887,4.535-19.608,18.514-15.012,31.232C76.316,59.598,77.037,60.993,77.837,62.326z"/>
            </g>

        </svg>
        // <svg version="1.1" x={x} y={y} style={{ transform: `rotate(${rotationAngle}deg)` }}
        //     viewBox="0 0 600 600" width={30} height={30}>
        //     <rect x="239.48" y="66.06" style={{ fill: "#5D5360" }} width="33.03" height="74.32" />
        //     <rect x="239.48" y="115.61" style={{ fill: "#5D5360" }} width="33.03" height="33.03" />
        //     <rect x="24.774" y="231.23" style={{ fill: "#5D5360" }} width="462.45" height="33.03" />
        //     <rect x="66.06" y="231.23" style={{ fill: "#5D5360" }} width="379.87" height="33.03" />
        //     <g>
        //         <path style={{ fill: "#B67F5F" }} d="M82.581,181.678v256.454c0,32.779,24.022,60.476,56.431,65.388C175.042,508.981,214.549,512,256,512
        //               s80.958-3.019,116.989-8.48c32.409-4.912,56.431-32.609,56.431-65.388V181.678c0-27.365-22.184-49.548-49.548-49.548H132.129
        //               C104.764,132.129,82.581,154.313,82.581,181.678z"/>
        //         <path style={{ fill: "#B67F5F" }} d="M421.219,26.852C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
        //               c-12.887,4.535-19.608,18.514-15.012,31.232c3.609,10.001,13.149,16.231,23.333,16.233l313.795,0.006
        //               c10.177,0,19.721-6.242,23.334-16.239C440.828,45.366,434.106,31.387,421.219,26.852z"/>
        //     </g>
        //     <path style={{ fill: "#5D5360" }} d="M20.645,404.645L20.645,404.645C9.243,404.645,0,395.402,0,384V194.064
        //             c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C41.29,395.402,32.047,404.645,20.645,404.645z"
        //     />
        //     <path style={{ fill: "#6F6571" }} d="M24.774,173.836v230.393C34.198,402.316,41.29,393.987,41.29,384V194.065
        //             C41.29,184.077,34.198,175.749,24.774,173.836z"/>
        //     <path style={{ fill: "#5D5360" }} d="M491.355,404.645L491.355,404.645c-11.402,0-20.645-9.243-20.645-20.645V194.064
        //             c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645V384C512,395.402,502.757,404.645,491.355,404.645z"
        //     />
        //     <path style={{ fill: "#6F6571" }} d="M495.484,173.836v230.393C504.907,402.316,512,393.987,512,384V194.065
        //             C512,184.077,504.907,175.749,495.484,173.836z"/>
        //     <path style={{ fill: "#A06E50" }} d="M115.613,297.29c0-77.534,62.853-140.387,140.387-140.387h166.788
        //             c-8.571-14.795-24.588-24.774-42.917-24.774H132.129c-27.365,0-49.548,22.184-49.548,49.548v256.454
        //             c0,24.209,13.125,45.657,33.032,57.19C115.613,495.321,115.613,297.29,115.613,297.29z"/>
        //     <path style={{ fill: "#CD916E" }} d="M77.837,62.326c2.841-4.78,7.239-8.693,12.944-10.7C140.718,34.059,197.849,24.774,256,24.774
        //             s115.282,9.285,165.219,26.852c5.703,2.007,10.101,5.919,12.943,10.698c0.8-1.333,1.522-2.727,2.069-4.24
        //             c4.597-12.718-2.125-26.697-15.012-31.232C371.282,9.285,314.151,0,256,0S140.718,9.285,90.781,26.852
        //             c-12.887,4.535-19.608,18.514-15.012,31.232C76.316,59.598,77.037,60.993,77.837,62.326z"/>

        // </svg>


    )
}

export default SubspaceIconChair;