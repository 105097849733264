import { Box, Chip, Divider, FormControlLabel, Grid, List, ListItem, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { X } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { alertActions, teammatesActions, usersActions } from '../../_actions'
// import IconAdd from '../../_components/_icons/IconAdd'
import Filterbar from '../../_components/_shared/Filterbar'
import Toolbar from '../../_components/_shared/Toolbar'
import { authHeader } from '../../_helpers'
import { api } from '../../_helpers/api'
// import SelectResponsibles from './SelectResponsibles'
import './styles.scss'
// import { Button, Drawer, IconButton } from '@material-ui/core'
import { WeekDays } from '../../_components/checkin_page/WeekDays'
import { TableGroups } from './TableGroups'
import FilterbarTeams from '../../_components/_shared/FilterbarTeams'
import moment from 'moment'
import TableCollapsibleSettingsCheckin from '../../_components/checkin_page/TableCollapsibleSettingsCheckin'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
// import { Close } from '@material-ui/icons'
// import { TableGroups } from './TableGroups'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22BEAD' : '#22BEAD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function CheckinSettingsTeams(props) {
  const [groups, setGroups] = useState([])
  const { userDetails, alertActions, filter } = props

  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);

  const getGroupsToConfig = async (queryTeams = "", queryTeammates = "") => {
    try {
      setFetching(true)
      const response = await api.get(`/checkin/settings/groups?date=${moment().format('YYYY-MM-DD')}${queryTeams}${queryTeammates}`,
        { headers: authHeader() });

      if (response.status === 200) {
        setGroups(response.data)
      }
      setFetching(false)

    } catch (e) {
      console.log(e)
      setFetching(false)


    }
  }

  useEffect(() => {
    // getGroupsToConfig()
    document.title = 'Befective | ' + t('menu.settings') + ' | Check-in';
  }, [])

  useEffect(() => {
    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams.reduce((acc, item) => {
        return acc + String(item.id) + ','
      }, '').slice(0, -1);
    }

    if (filter.teammates) {
      teammates = filter.teammates.reduce((acc, item) => {
        return acc + String(item.id) + ','
      }, '').slice(0, -1);
    }

    const queryTeams = teams !== null ? `&teams_ids=${teams}` : ''
    const queryTeammates = teammates !== null ? `&teammates_ids=${teammates}` : ''

    getGroupsToConfig(queryTeams, queryTeammates)
  }, [filter])

  return (
    <section className="mainContent">
      <Toolbar />
      {/* <FilterbarTeams /> */}
      <Filterbar
        filterWho={'multi'}
        filterWhen={'disabled'}
        filterWhenMonths={'disabled'}
        filterWhat={'disabled'}
        showShareBtn={false}
      />
      <Paper elevation={0} sx={{ marginX: 4, marginY: 3, padding: 3 }}>
        {

          <Grid
            container
            spacing={2}
            component={'form'}
            id='formSettingsCheckinTeams'
          // onSubmit={handleSubmit}
          >
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography component={'h6'} variant='h6'>{t("settings.teams.label_setting_team")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>



            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              {/* alertActions, userDetails, groups = [], getDashboardInfo, filter */}
              {
                !fetching
                  ?
                  <TableCollapsibleSettingsCheckin data={groups}/>

                  :
                  <GlobalSkeleton totalRowsStats={0} totalTables={1} />
              }
              {/* <TableGroups
              alertActions={alertActions}
              userDetails={userDetails}
              groups={groups}
              getDashboardInfo={[]}
              filter={[]}
              /> */}
            </Grid>

          </Grid>
        }
      </Paper>
    </section>

  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinSettingsTeams)