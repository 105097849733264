import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { authHeader, getDayWeek, history } from '../../_helpers';
import style from './FormCreate.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import SelectResponsibles from '../../pages/CheckinSettings/SelectResponsibles';
import { Box, Grid, Button, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Typography, Autocomplete, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import Switch from "react-switch";
import { CheckCircle, TrashSimple } from 'phosphor-react';
import { api } from '../../_helpers/api';


export function FormCreate({ userDetails, teammates, optionsGroups, alertActions, userToEdit, optionsProfile }) {

  const { t, i18n } = useTranslation('common');

  const [isSubmitting, setIsSubmitting] = useState(false)

  let formSchema = yup.object({
    name: yup.string().required(t('spaces.required_field')),
    // username: yup.string().required(t('spaces.required_field')),
    email: yup.string().email().required(t('spaces.required_field')),
    team: yup.object().nonNullable(t('spaces.required_field')),
    access_profile: yup.object().nonNullable(t('spaces.required_field')),
    // role_name: yup.string().required(t('spaces.required_field')),
    // teams_allowed: yup.array(),
    // admission_date: yup.string().required(t('spaces.required_field'))
    // active: yup.string().required(t('spaces.required_field'))
  });

  const { register, handleSubmit, watch, setValue, formState: { errors }, setError, getValues } = useForm({
    defaultValues: {
      name: userToEdit?.name || '',
      username: userToEdit?.username || '',
      email: userToEdit?.email || '',
      team: userToEdit?.group_id ? { value: userToEdit?.group_id, label: userToEdit?.group_name } : null,
      access_profile: userToEdit?.access_profile ? (userToEdit?.access_profile?.is_default_profile === true ? { ...userToEdit?.access_profile, label: t(`settings.teams.profiles.${userToEdit?.access_profile.key}`) } : userToEdit?.access_profile) : null,
      // role_name: userToEdit?.role_name || '',
      // teams_allowed: userToEdit?.teams_allowed || [],
      send_invite: false,
      active: userToEdit?.active,
      admission_date: userToEdit?.admission_date ? moment(userToEdit.admission_date).format('YYYY-MM-DD') : null

    },
    resolver: yupResolver(formSchema)
  });

  const { name, username, email, team, active, send_invite, admission_date, access_profile } = watch()

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const onSubmit = async (data) => {

    try {

      setIsSubmitting(true)

      const payload = {
        ...data,
        group_id: team.value,
        access_profile_id: access_profile.value,
        access_profile_key: access_profile.key
      }

      if (!userToEdit) {
        const resCreate = await api.post('/users/v2/', payload,
          {
            headers: authHeader()
          })

        if (resCreate.status !== 200) {
          throw new Error("Error create user")
        }
        alertActions.success(t('settings.users.success.create_user'))
        history.push('/settings/users')
        setIsSubmitting(false)
        return
      }

      const resEdit = await api.put(`/users/v2/${userToEdit?.id}`, payload,
        {
          headers: authHeader()
        })

      if (resEdit.status !== 200) {
        throw new Error("Error update user")
      }

      alertActions.success(t('settings.users.success.update_user'))
      history.push('/settings/users')
      setIsSubmitting(false)

    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
      setIsSubmitting(false)
    }
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  function handleChangeDate(value) {
    if (!value) {
      setValue('admission_date', null)
      return
    }
    const newDate = moment(value.$d).format().split("T")[0];
    setValue('admission_date', newDate)
  }
  return (

    <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            {
              !userToEdit && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                {t('settings.users.form_create.title')}
              </Typography>
            }
            {
              userToEdit && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                {t('settings.users.form_create.title_update')}
              </Typography>
            }

          </Box>

          <Box display="flex" alignItems="center" gap="8px">

            <Button
              type="button"
              style={{ minWidth: '100px', backgroundColor: "#E3EFFF", color: "#187DFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              onClick={() => history.push('/settings/users')}
            >
              {t('settings.users.form_create.btn_cancel')}
            </Button>

            <Button
              type="submit"
              style={{ minWidth: '100px', backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              disabled={isSubmitting}
            >
              {t('settings.users.form_create.btn_save')}
            </Button>
          </Box>

        </Box>

        <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
              {t('settings.users.form_create.field_active')}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
            <Switch
              checked={active}
              onChange={(checked) => setValue('active', checked)}
              onColor="#22BEAD"
              onHandleColor="#FFFFFF"
              offColor='#E3E7EC'
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={30}
              width={55}
              className="react-switch"
              id="active"
            />
            <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
          </Stack>
        </Grid>

        <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>

          <Box display={'flex'} alignItems={'center'}>
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
              {t('settings.users.form_create.field_invite')}
            </Typography>
          </Box>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
            <Switch
              checked={send_invite}
              onChange={(checked) => setValue('send_invite', checked)}
              onColor="#22BEAD"
              onHandleColor="#FFFFFF"
              offColor='#E3E7EC'
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={30}
              width={55}
              className="react-switch"
              id="material-switch"
            />
            <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
          </Stack>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_name')}*</Typography>
          </Box>

          <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
            <Box display={'flex'} width={'100%'} gap={'8px'}>
              <Box display="flex" flexDirection="column" gap="4px" width="100%">
                <TextField
                  name="name"
                  label={`${t('settings.users.form_create.field_name')}*`}
                  placeholder=''
                  error={false}
                  helperText={""}
                  {...register('name')}
                  sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                >
                </TextField>

                {errors.name && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.name.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_username')}</Typography>
          </Box>

          <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
            <Box display={'flex'} width={'100%'} gap={'8px'}>
              <Box display="flex" flexDirection="column" gap="4px" width="100%">
                <TextField
                  name="username"
                  label={t('settings.users.form_create.field_username')}
                  placeholder=''
                  error={false}
                  helperText={""}
                  {...register('username')}
                  sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                >
                </TextField>

                {errors.username && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.username.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_email')}*</Typography>
          </Box>

          <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
            <Box display={'flex'} width={'100%'} gap={'8px'}>
              <Box display="flex" flexDirection="column" gap="4px" width="100%">
                <TextField
                  name="email"
                  label={`${t('settings.users.form_create.field_email')}*`}
                  placeholder=''
                  error={false}
                  helperText={""}
                  {...register('email')}
                  sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' } }}
                >
                </TextField>

                {errors.email && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.email.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_admission_date')}*</Typography>
          </Box>

          <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
            <Box display={'flex'} width={'100%'} gap={'8px'}>
              <Box display="flex" flexDirection="column" gap="4px" width="100%">
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                  <DatePicker
                    label={`${t('settings.users.form_create.field_admission_date')}*`}
                    value={admission_date}
                    inputFormat={getDataFormat(lang)}
                    onChange={value => handleChangeDate(value)}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        fullWidth
                        {...params}
                        sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, input: { color: '#4F7091', paddingY: '11.5px' }, label: { top: '-4px' } }}
                        error={false}
                        helperText={""}
                      />
                    )}
                  />
                </LocalizationProvider>

                {errors.admission_date && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.admission_date.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {optionsGroups.length > 0 &&
          (<Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_team')}*</Typography>
            </Box>
            <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
              <Box display={'flex'} width={'100%'} gap={'8px'}>
                <Box display="flex" flexDirection="column" gap="4px" width="100%">
                  <Autocomplete
                    id="select-team"
                    options={optionsGroups}
                    value={team}
                    onChange={(event, newValue) => {
                      setValue('team', newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' }, input: { padding: '2px 4px' } }}
                        label={`${t('settings.users.form_create.field_team')}*`}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '2px 4px'
                          }
                        }}
                      />
                    )}
                  />
                  {errors.team && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.team.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>)
        }

        {optionsProfile.length > 0 &&
          (<Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="20px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.users.form_create.field_profile')}*</Typography>
            </Box>
            <Box display="flex" alignItems="center" width="50%" flexDirection={'column'}>
              <Box display={'flex'} width={'100%'} gap={'8px'}>
                <Box display="flex" flexDirection="column" gap="4px" width="100%">
                  <Autocomplete
                    id="select-profile"
                    options={optionsProfile}
                    value={access_profile}
                    onChange={(event, newValue) => {
                      setValue('access_profile', newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: '100%', height: '45px', fieldset: { border: '2px solid #DEE3E9CC', height: '45px' }, div: { color: '#4F7091', height: '45px' }, label: { top: '-4px' }, input: { padding: '2px 4px' } }}
                        label={`${t('settings.users.form_create.field_profile')}*`}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '2px 4px'
                          }
                        }}
                      />
                    )}
                  />
                  {errors.access_profile && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.access_profile.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>)
        }

      </Box>
    </Box>
  )
}
