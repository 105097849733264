import React from 'react'

export default function IconModuleInventory({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (


    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="invent" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1736" data-name="Retângulo 1736" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(2.25 0.75)">
            <g id="Grupo_871" data-name="Grupo 871">
              <path id="Caminho_7226" data-name="Caminho 7226" d="M14.75,16a.75.75,0,1,0,0,1.5h6a.75.75,0,1,0,0-1.5Z" transform="translate(-11 -10.75)" fill="#24677e" />
              <path id="Caminho_7227" data-name="Caminho 7227" d="M20.75,24h-6a.75.75,0,1,0,0,1.5h6a.75.75,0,0,0,0-1.5Z" transform="translate(-11 -15.75)" fill="#24677e" />
              <path id="Caminho_7228" data-name="Caminho 7228" d="M17.75,32h-3a.75.75,0,1,0,0,1.5h3a.75.75,0,1,0,0-1.5Z" transform="translate(-11 -20.75)" fill="#24677e" />
              <path id="Caminho_7229" data-name="Caminho 7229" d="M18.75,3.5h-1.5V2.75A.75.75,0,0,0,16.5,2H9a.75.75,0,0,0-.75.75V3.5H6.75A.75.75,0,0,0,6,4.25v13.5a.75.75,0,0,0,.75.75h12a.75.75,0,0,0,.75-.75V4.25A.75.75,0,0,0,18.75,3.5ZM18,17H7.5V5H9.75V3.5h6V5H18Z" transform="translate(-6 -2)" fill="#24677e" />
            </g>
          </g>
        </g>
      </g>
    </svg>



  )
}
