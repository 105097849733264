import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { authHeader, getDayWeek, history } from '../../_helpers';
import style from './FormProfiles.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Typography, Autocomplete, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, CircularProgress } from '@mui/material';
import Switch from "react-switch";
import { CheckCircle, TrashSimple } from 'phosphor-react';
import { api } from '../../_helpers/api';
import CustomReactSelect from '../_shared/CustomReactSelect';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import GlobalSkeleton from '../_shared/GlobalSkeleton';


export function FormProfiles({ userDetails, optionsGroups, optionsPermissions, alertActions }) {

  const { t, i18n } = useTranslation('common');
  const { id } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [teamSelected2, setTeamSelected2] = useState(null)
  const [disableTeams, setDisableTeams] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('create')

  const optionsPermissionsSettings = [
    {
      value: 'all_settings',
      label: t('settings.profiles.form.option_all')
    },
    {
      value: 'allowed_read',
      label: t('settings.profiles.form.option_see_details')
    },
    {
      value: 'allowed_create',
      label: t('settings.profiles.form.option_create')
    },
    {
      value: 'allowed_update',
      label: t('settings.profiles.form.option_update')
    },
    {
      value: 'allowed_delete',
      label: t('settings.profiles.form.option_delete')
    }
  ]
  const optionsPermissionsActivitiesProductive = optionsPermissions.find(o => o.group_name == 'activities') ? [
    {
      value: 'all_activities',
      label: t('settings.profiles.form.option_all')
    },
    ...optionsPermissions.find(o => o.group_name == 'activities').options.map(option => ({ value: option.id, label: option[`title_${i18n.language}`], key: option.key }))
  ] : []

  const optionsPermissionsWidgets = optionsPermissions.find(o => o.group_name == 'widgets') ? [
    {
      value: 'all_widgets',
      label: t('settings.profiles.form.option_all')
    },
    ...optionsPermissions.find(o => o.group_name == 'widgets').options.map(option => ({ value: option.id, label: option[`title_${i18n.language}`], key: option.key }))
  ] : []

  const optionsPermissionsReports = optionsPermissions.find(o => o.group_name == 'reports') ? [
    {
      value: 'all_reports',
      label: t('settings.profiles.form.option_all')
    },
    ...optionsPermissions.find(o => o.group_name == 'reports').options.map(option => ({ value: option.id, label: option[`title_${i18n.language}`], key: option.key }))
  ] : []

  const optionsPermissionsMenu = optionsPermissions.find(o => o.group_name == 'menu') ? [
    {
      value: 'all_menu',
      label: t('settings.profiles.form.option_all')
    },
    ...optionsPermissions.find(o => o.group_name == 'menu').options.map(option => ({ value: option.id, label: option[`title_${i18n.language}`], key: option.key }))
  ] : []

  const [form, setForm] = useState({
    name: '',
    allowed_menu: [],
    allowed_reports: [],
    allowed_widgets: [],
    allowed_productive: [],
    allowed_teams: [],
    allowed_settings: [],
    allowed_total_time: true,
    allowed_total_time_related: true,
    allowed_export: true,
    allowed_activity_classification: true,
    allowed_transactional_classification: false,
    calculate_based_productivity_target: false
  })

  const [formError, setFormError] = useState({
    name: '',
    allowed_menu: '',
    allowed_reports: '',
    allowed_widgets: '',
    allowed_productive: '',
    allowed_teams: '',
    allowed_settings: '',
    allowed_total_time: '',
    allowed_total_time_related: '',
    allowed_export: '',
    allowed_activity_classification: '',
    allowed_transactional_classification: '',
    calculate_based_productivity_target: ''
  })

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const createProfile = async () => {

    try {

      setIsSubmitting(true)

      const payload = {
        ...form,
        allowed_menu: form.allowed_menu.find(t => t.value === 'all_menu') ? optionsPermissions.find(o => o.group_name == 'menu').options.map(option => option.id) : form.allowed_menu.map(item => item.value),
        allowed_reports: form.allowed_reports.find(t => t.value === 'all_reports') ? optionsPermissions.find(o => o.group_name == 'reports').options.map(option => option.id) : form.allowed_reports.map(item => item.value),
        allowed_widgets: form.allowed_widgets.find(t => t.value === 'all_widgets') ? optionsPermissions.find(o => o.group_name == 'widgets').options.map(option => option.id) : form.allowed_widgets.map(item => item.value),
        allowed_productive: form.allowed_productive.find(t => t.value === 'all_activities') ? optionsPermissions.find(o => o.group_name == 'activities').options.map(option => option.id) : form.allowed_productive.map(item => item.value),
        allowed_teams: form.allowed_teams.find(t => t.value === 'all_organization') ? optionsGroups.map(g => g.value) : form.allowed_teams.map(g => g.value),
        allowed_create: form.allowed_settings.find(item => (item.value === 'allowed_create' || item.value === 'all_settings')) ? true : false,
        allowed_read: form.allowed_settings.find(item => (item.value === 'allowed_read' || item.value === 'all_settings')) ? true : false,
        allowed_update: form.allowed_settings.find(item => (item.value === 'allowed_update' || item.value === 'all_settings')) ? true : false,
        allowed_delete: form.allowed_settings.find(item => (item.value === 'allowed_delete' || item.value === 'all_settings')) ? true : false
      }

      if (action === 'create') {
        const resCreate = await api.post('/settings/access-profile', payload,
          {
            headers: authHeader()
          })

        if (resCreate.status !== 200) {
          throw new Error("Error create profile")
        }
        alertActions.success(t('settings.profiles.success.created_profile'))
        history.push('/settings/profiles')
        setIsSubmitting(false)
        return
      }

      const resEdit = await api.put(`/settings/access-profile/${form.id}`, payload,
        {
          headers: authHeader()
        })

      if (resEdit.status !== 200) {
        throw new Error("Error update profile")
      }

      alertActions.success(t('settings.profiles.success.updated_profile'))
      // history.push('/settings/profiles')
      setIsSubmitting(false)

    } catch (error) {
      console.error(error)
      setIsSubmitting(false)
    }
  }

  function handleAddTeam() {

    if(!teamSelected2) return 

    if (teamSelected2?.value === 'all_organization') {
      setForm({
        ...form,
        allowed_teams: [teamSelected2]
      })
      setTeamSelected2(null)
      setDisableTeams(true)
      return
    }
    const checkIfExists = form.allowed_teams.find(item => item.value === teamSelected2?.value)

    if (!checkIfExists && teamSelected2 !== '') {
      setForm({
        ...form,
        allowed_teams: [...form.allowed_teams, teamSelected2]
      })
      setTeamSelected2(null)
    }
  }

  function handleRemoveTeam(team_id) {
    if (team_id === 'all_organization') {
      setForm({
        ...form,
        allowed_teams: []
      })
      setDisableTeams(false)
    }
    const teamFiltered = form.allowed_teams.filter(item => item.value !== team_id)

    setForm({
      ...form,
      allowed_teams: teamFiltered
    })
  }

  const handleChangeSelectValues = (values, field_name, all_name) => {
    const isContainsAll = values.find(v => v.value == all_name)

    if (isContainsAll) {
      setForm({
        ...form,
        [field_name]: [isContainsAll]
      })
      setFormError({
        ...formError,
        [field_name]: ''
      })
      return
    }
    setForm({
      ...form,
      [field_name]: values
    })
    setFormError({
      ...formError,
      [field_name]: ''
    })
  }

  const handleChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
    setFormError({
      ...formError,
      [name]: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.name) {
      errors.name = t("validation.required")
    }

    if (form.allowed_menu.length === 0) {
      errors.allowed_menu = t("validation.required")
    }

    if (form.allowed_reports.length === 0) {
      errors.allowed_reports = t("validation.required")
    }

    if (form.allowed_widgets.length === 0) {
      errors.allowed_widgets = t("validation.required")
    }

    if (form.allowed_productive.length === 0) {
      errors.allowed_productive = t("validation.required")
    }

    if (form.allowed_settings.length === 0) {
      errors.allowed_settings = t("validation.required")
    }

    if (form.allowed_teams.length === 0) {
      errors.allowed_teams = t("validation.required")
    }
    

    return errors
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createProfile()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  const getProfileById = async (withLoading = true, id = null) => {
    try {
      if (withLoading) setLoading(true)
      const filterId = id ? `?id=${id}` : ''
      const { data } = await api.get(`/settings/access-profile${filterId}`, { headers: authHeader() })
      setForm({
        ...form,
        id: data[0].id,
        name: data[0].name,
        allowed_menu: getDefaultOptions(data[0].allowed_menu, optionsPermissions.find(o => o.group_name == 'menu')?.options, 'all_menu'),
        allowed_reports: getDefaultOptions(data[0].allowed_reports, optionsPermissions.find(o => o.group_name == 'reports')?.options, 'all_reports'),
        allowed_widgets: getDefaultOptions(data[0].allowed_widgets, optionsPermissions.find(o => o.group_name == 'widgets')?.options, 'all_widgets'),
        allowed_productive: getDefaultOptions(data[0].allowed_productive, optionsPermissions.find(o => o.group_name == 'activities')?.options, 'all_activities'),
        allowed_settings: getDefaultOptionsSettings(data[0], 'all_settings'),
        allowed_export: data[0].allowed_export,
        allowed_activity_classification: data[0].allowed_activity_classification,
        allowed_transactional_classification: data[0].allowed_transactional_classification,
        allowed_teams: data[0].allowed_teams,
        allowed_total_time: data[0].allowed_total_time,
        allowed_total_time_related: data[0].allowed_total_time_related,
        calculate_based_productivity_target: data[0].calculate_based_productivity_target
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getDefaultOptions = (data, options, all_value) => {

    if (data.length === 0) return []

    const data_ids = data.map(item => item.id)
    const options_ids = options.map(option => option.id)

    if (data_ids.length === options_ids.length) {
      return [
        {
          value: all_value,
          label: t('settings.profiles.form.option_all')
        }
      ]
    }

    return data.map(item => ({ value: item.id, label: item[`label_${i18n.language}`], key: item.key }))
  }

  const getDefaultOptionsSettings = (data, all_value) => {

    if (!data) return []

    if(data.allowed_create === true && data.allowed_update === true && data.allowed_read === true && data.allowed_delete === true){
      return [
        {
          value: all_value,
          label: t('settings.profiles.form.option_all')
        }
      ]
    }
    
    const arrayData = [
      {
        value: 'allowed_read',
        label: t('settings.profiles.form.option_see_details'),
        selected: data.allowed_read
      },
      {
        value: 'allowed_create',
        label: t('settings.profiles.form.option_create'),
        selected: data.allowed_create
      },
      {
        value: 'allowed_update',
        label: t('settings.profiles.form.option_update'),
        selected: data.allowed_update
      },
      {
        value: 'allowed_delete',
        label: t('settings.profiles.form.option_delete'),
        selected: data.allowed_delete
      }
    ]
    return arrayData.filter(item => item.selected === true)
  }

  useEffect(() => {
    if (id) {
      setAction('edit')
      getProfileById(true, id)
    } else {
      setLoading(false)
    }
  }, [id])

  return (

    <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit}>
      {
        !loading
          ?
          <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                {
                  action === 'create' && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                    {t('settings.profiles.form.title_create')}
                  </Typography>
                }
                {
                  action === 'edit' && <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
                    {t('settings.profiles.form.title_update')}
                  </Typography>
                }
              </Box>

              <Box display="flex" alignItems="center" gap="8px">

                <Button
                  type="button"
                  style={{ minWidth: '100px', backgroundColor: "#E3EFFF", color: "#187DFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
                  onClick={() => history.push('/settings/profiles')}
                >
                  {t('settings.profiles.form.btn_cancel')}
                </Button>

                <Button
                  type="submit"
                  style={{ minWidth: '100px', backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
                  disabled={isSubmitting}
                >
                  {
                    !isSubmitting
                    ?
                    t('settings.profiles.form.btn_save')
                    :
                    <CircularProgress size={'21px'} color='inherit'/>
                  }
                </Button>
              </Box>

            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_name')}*</Typography>
              </Box>

              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} gap={'8px'}>
                  <Box display="flex" flexDirection="column" gap="4px" width="100%">
                    <TextField
                      name="name"
                      placeholder={t('settings.profiles.form.field_name')}
                      error={false}
                      helperText={""}
                      sx={{ width: '100%', height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' } }}
                      value={form.name}
                      onChange={handleChange}
                    >
                    </TextField>

                    {formError.name && (
                      <Typography fontSize={12} color="#FF7A90" component="span">
                        {formError.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_access_menu')}*</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} flexDirection="column" gap={'8px'}>
                  <CustomReactSelect value={form.allowed_menu} options={optionsPermissionsMenu} onChange={(values) => handleChangeSelectValues(values, 'allowed_menu', 'all_menu')} isOptionDisabled={() => form.allowed_menu.find(u => u.value == 'all_menu')} />
                  {formError.allowed_menu && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {formError.allowed_menu}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_access_reports')}*</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} flexDirection="column" gap={'8px'}>
                  <CustomReactSelect value={form.allowed_reports} options={optionsPermissionsReports} onChange={(values) => handleChangeSelectValues(values, 'allowed_reports', 'all_reports')} isOptionDisabled={() => form.allowed_reports.find(u => u.value == 'all_reports')} />
                  {formError.allowed_reports && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {formError.allowed_reports}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_access_widgets')}*</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} flexDirection="column" gap={'8px'}>
                  <CustomReactSelect value={form.allowed_widgets} options={optionsPermissionsWidgets} onChange={(values) => handleChangeSelectValues(values, 'allowed_widgets', 'all_widgets')} isOptionDisabled={() => form.allowed_widgets.find(u => u.value == 'all_widgets')} />
                  {formError.allowed_widgets && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {formError.allowed_widgets}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_access_data_productive')}*</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} flexDirection="column" gap={'8px'}>
                  <CustomReactSelect value={form.allowed_productive} options={optionsPermissionsActivitiesProductive} onChange={(values) => handleChangeSelectValues(values, 'allowed_productive', 'all_activities')} isOptionDisabled={() => form.allowed_productive.find(u => u.value == 'all_activities')} />
                  {formError.allowed_productive && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {formError.allowed_productive}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>{t('settings.profiles.form.field_control_permissions_settings')}*</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="380px" flexDirection={'column'}>
                <Box display={'flex'} width={'100%'} flexDirection="column" gap={'8px'}>
                  <CustomReactSelect value={form.allowed_settings} options={optionsPermissionsSettings} onChange={(values) => handleChangeSelectValues(values, 'allowed_settings', 'all_settings')} isOptionDisabled={() => form.allowed_settings.find(u => u.value == 'all_settings')} />
                  {formError.allowed_settings && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {formError.allowed_settings}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} maxWidth={'70%'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_calculate_stats')}
                </Typography>
                <Typography component="span" style={{ fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}>
                  {t('settings.profiles.form.field_help_calculate_stats')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.calculate_based_productivity_target}
                  onChange={(checked) => setForm({ ...form, calculate_based_productivity_target: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="calculate_based_productivity_target"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_allowed_see_total_time')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.allowed_total_time}
                  onChange={(checked) => setForm({ ...form, allowed_total_time: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="allowed_total_time"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_allowed_see_total_time_related')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.allowed_total_time_related}
                  onChange={(checked) => setForm({ ...form, allowed_total_time_related: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="allowed_total_time_related"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_allowed_export')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.allowed_export}
                  onChange={(checked) => setForm({ ...form, allowed_export: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="allowed_export"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_allowed_update_efficiency')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.allowed_activity_classification}
                  onChange={(checked) => setForm({ ...form, allowed_activity_classification: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="allowed_activity_classification"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography component="span" style={{ fontSize: "14px", color: "#1E6B7F", fontWeight: 500 }}>
                  {t('settings.profiles.form.field_allowed_update_transactional')}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.no')}</Typography>
                <Switch
                  checked={form.allowed_transactional_classification}
                  onChange={(checked) => setForm({ ...form, allowed_transactional_classification: checked })}
                  onColor="#22BEAD"
                  onHandleColor="#FFFFFF"
                  offColor='#E3E7EC'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={30}
                  width={55}
                  className="react-switch"
                  id="allowed_transactional_classification"
                />
                <Typography component="span" fontSize={14} color="#1E6B7F">{t('spaces.yes')}</Typography>
              </Stack>
            </Grid>

            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
              <Box display="flex" alignItems="center" gap="8px">
                <Typography component="span" fontSize={14} color="#1E6B7F" fontWeight={500}>{t('settings.profiles.form.field_manage_teams')}*</Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="4px">
                <Box display="flex" alignItems="center" gap="8px">
                  <Autocomplete
                    id="select-team-2"
                    options={[
                      {
                        value: 'all_organization',
                        label: t('settings.users.form_create.all_organization')
                      },
                      ...optionsGroups
                    ].filter(op => !form.allowed_teams.find(t => t.value == op.value))}
                    disabled={disableTeams}
                    value={teamSelected2}
                    onChange={(event, newValue) => {
                      setTeamSelected2(newValue)
                      setFormError({
                        ...formError,
                        allowed_teams: ''
                      })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: '100%', height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' }, input: { padding: '2px 4px' } }}
                        placeholder={t('settings.profiles.form.select_team')}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            minWidth: 300,
                            padding: '2px 4px'
                          }
                        }}
                      />
                    )}
                  />

                  <Button
                    onClick={handleAddTeam}
                    style={{
                      backgroundColor: (teamSelected2 === '' || disableTeams) ? '#F5F6F9' : '#FDBE2B',
                      color: '#106276',
                      height: '42px',
                      marginBottom: '1px',
                      borderRadius: '2px',
                      fontSize: '12px',
                      textTransform: 'initial'
                    }}
                    disabled={teamSelected2 === '' || disableTeams}
                  >
                    + {t('settings.profiles.form.add')}
                  </Button>

                </Box>
                {formError.allowed_teams && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {formError.allowed_teams}
                  </Typography>
                )}
              </Box>

            </Box>
            <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow sx={{ textTransform: "uppercase" }}>
                    <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '16px', textTransform: "uppercase" }} width="auto">
                      {t('settings.users.form_create.field_team')}
                    </TableCell>
                    <TableCell className={style.tableHeaderTd} style={{ width: '20px' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {form.allowed_teams.length > 0
                    ? form.allowed_teams.map(team => (
                      <TableRow key={team.value} >
                        <TableCell style={{ paddingLeft: '16px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                          {[
                            {
                              value: 'all_organization',
                              label: t('settings.users.form_create.all_organization')
                            },
                            ...optionsGroups
                          ].find(g => g.value === team.value)?.label}
                        </TableCell>
                        <TableCell style={{ paddingRight: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px', textAlign: 'right' }}>
                          <TrashSimple size={18}
                            weight="fill"
                            cursor="pointer"
                            className={style.trash}
                            onClick={() => handleRemoveTeam(team.value)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                    :
                    <TableRow>
                      <TableCell colSpan={2} style={{ paddingLeft: '16px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                        {t('settings.profiles.form.none_team_add')}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>

            </TableContainer >

          </Box>
          :
          <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
            <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          </Box>
      }

    </Box>
  )
}
