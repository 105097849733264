import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';


import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

function TodayStatsSkeleton(props) {
    const {t, i18n} = useTranslation('common');

    const [metricTime, setMetric1] = useState(80);
    const [metricTimeOnline, setMetric2] = useState(50);
    const [metricTimeOffline, setMetric3] = useState(30);
    const [metricWork, setMetric4] = useState(70);
    const [metricWorkOnline, setMetric5] = useState(40);
    const [metricWorkOffline, setMetric6] = useState(30);


    return (
        <div className="box stats">
            <h3><Skeleton variant="text" width="100%" height={22}/></h3>

            <div className="statsBlock loading">
                <BrowserView>
                    <div className="mainChart">
                        <Skeleton variant="circle" width={188} height={188}/>
                    </div>
                </BrowserView>
                <div className="secondaryChart">
                    <div className="block">
                        <div className="icon">
                            <Skeleton variant="circle" width={32} height={32}/>
                        </div>
                        <div className="stats">
                            <Skeleton variant="text" width="100%" height={17}/>
                            <BrowserView>
                                <Skeleton variant="text" width="100%" height={17}/>

                            </BrowserView>
                        </div>

                    </div>
                    <div className="block">
                        <div className="icon">
                            <Skeleton variant="circle" width={32} height={32}/>
                        </div>
                        <div className="stats">
                            <Skeleton variant="text" width="100%" height={17}/>
                            <BrowserView>
                                <Skeleton variant="text" width="100%" height={17}/>

                            </BrowserView>
                        </div>

                    </div>
                </div>
            </div>

            <div className="statsBlock loading">
                <BrowserView>
                    <div className="mainChart">
                        <Skeleton variant="circle" width={188} height={188}/>
                    </div>
                </BrowserView>
                <div className="secondaryChart">
                    <div className="block">
                        <div className="icon">
                            <Skeleton variant="circle" width={32} height={32}/>
                        </div>
                        <div className="stats">
                            <Skeleton variant="text" width="100%" height={17}/>
                            <BrowserView>
                                <Skeleton variant="text" width="100%" height={17}/>
                            </BrowserView>
                        </div>

                    </div>
                    <div className="block">
                        <div className="icon">
                            <Skeleton variant="circle" width={32} height={32}/>
                        </div>
                        <div className="stats">
                            <Skeleton variant="text" width="100%" height={17}/>
                            <BrowserView>
                                <Skeleton variant="text" width="100%" height={17}/>
                            </BrowserView>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default TodayStatsSkeleton;
