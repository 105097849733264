import React from 'react';
import { history } from '../../_helpers';

const SubspaceIconTV = ({
        x,
        y,
        rotationAngle,
        onMouseDown = () => { },
        onDoubleClick = () => { },
        urlRedirect=null
}) => {
        return (

                <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width={50} height={50} onMouseDown={onMouseDown} style={{ cursor: 'pointer' }}
                        viewBox="0 0 512 512" onClick={() => urlRedirect && history.push(urlRedirect)}>
                        <g transform={`rotate(${rotationAngle} 250 250)`} onDoubleClick={onDoubleClick}>
                                <path style={{ fill: "#6F6571" }} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-99.097
    c0-13.682,11.092-24.774,24.774-24.774h132.129c13.682,0,24.774,11.092,24.774,24.774v99.097
    C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                                <path style={{ fill: "#867E88" }} d="M322.065,330.323h-132.13c-13.682,0-24.774-11.092-24.774-24.774v-57.806h181.677v57.806
    C346.839,319.23,335.747,330.323,322.065,330.323z"/>
                                <path style={{ fill: "#5D5360" }} d="M433.848,226.676c-13.725-7.843-29.26-11.968-45.069-11.968H123.22
    c-15.808,0-31.343,4.125-45.069,11.968L41.29,247.742h429.42L433.848,226.676z"/>
                                <path style={{ fill: "#6F6571" }} d="M503.742,280.773H8.258c-4.561,0-8.258-3.697-8.258-8.258v-16.516c0-4.561,3.697-8.258,8.258-8.258
    h495.484c4.561,0,8.258,3.697,8.258,8.258v16.516C512,277.076,508.303,280.773,503.742,280.773z"/>
                                <path style={{ fill: "#5D5360" }} d="M512,264.258v-8.258c0-4.56-3.697-8.258-8.258-8.258H8.258c-4.561,0-8.258,3.698-8.258,8.258v8.258
    H512z"/>
                                <path style={{ fill: "#4B3F4E" }} d="M161.031,214.71L161.031,214.71h-37.816c-15.807,0-31.34,4.125-45.064,11.968L41.29,247.742h429.42
    l-28.902-16.516H169.29C160.783,231.225,155.928,221.514,161.031,214.71z"/>
                        </g>
                </svg>
        )
}

export default SubspaceIconTV;