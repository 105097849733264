import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, filterActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';


import { Box, Typography } from '@mui/material';
import moment from 'moment';
import IconClose from '../../_components/_icons/IconClose';
import { MonthResume } from '../../_components/checkin_page/history/MonthResume';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import { useDatePickerFilter } from '../../contexts/DatePickerFilterContext';
import style from './styles.module.scss';
import ModalExportReports from '../../_components/goals/ModalExportReports';
import HistorySkeleton from '../../_components/checkin_page/history/HistorySkeleton';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function CheckinHistory(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');
  const { startDate, endDate } = useDatePickerFilter()
  const [openModalExportReports, setOpenModalExportReports] = useState(false);

  const { userDetails, alertActions, teammatesActions, teammates, filter, filterActions } = props

  const [colabHistory, setColabHistory] = useState([])
  const [fetching, setFetching] = useState(true)

  const [openModalHelp, setOpenModalHelp] = useState(false)

  const [reasons, setReasons] = useState([])

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  const handleCloseModalExport = (e) => {
    e.preventDefault()
    setOpenModalExportReports(false);
  };

  const handleOpenModalExport = (e, colabHistory) => {
    e.preventDefault()
    setOpenModalExportReports(true);
    filterActions.replace(colabHistory.closing_month, 'singleWhen')
  };

  var currentDate = new Date();

  // Obtenha o mês atual e o ano atual
  var currentMonth = currentDate.getMonth() + 1;
  var currentYear = currentDate.getFullYear();


  async function getColabHistoryPoints(date_start, date_end) {
    try {
      setFetching(true)
      const response = await api.get(`/checkin/colab-historic?moment=${moment().format()}&date_start=${date_start}&date_end=${date_end}`, {
        headers: authHeader()
      })
      setColabHistory(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  async function getReasons() {
    try {
      const response = await api.get('/checkin/adjustments_reasons', {
        headers: authHeader()
      })

      setReasons(response.data)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t("checkin.dashboard.alerts.get_reasons_error"))
    }
  }

  function handleCloseModalHelp(e) {
    e.preventDefault()
    setOpenModalHelp(false)
  }

  useEffect(() => {

    document.title = `Befective | ${t('menu.check_in_remote')}`;
    teammatesActions.getTeammate(userDetails.id)
    getReasons()
  }, []);

  useEffect(() => {

    if (moment(endDate).isBefore(startDate)) return

    getColabHistoryPoints(startDate, endDate)

  }, [startDate, endDate])

  return (
    <>

      <section className="mainContent productivity">

        <Toolbar setOpenModalHelp={setOpenModalHelp} />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenYear={'single'}
          checkinHistory={false}
        />

        {/* PAGE STARTS */}
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              !teammates.loading && (
                <>
                  {colabHistory.map((history, index) => (
                    <MonthResume
                      key={index}
                      alertActions={alertActions}
                      userDetails={userDetails}
                      colabHistory={history}
                      isCurrentMonth={history.mes === currentMonth && history.ano === String(currentYear)}
                      lang={lang}
                      getColabHistoryPoints={getColabHistoryPoints}
                      reasons={reasons}
                      handleCloseModalExport={handleCloseModalExport}
                      handleOpenModalExport={handleOpenModalExport}
                    />
                  ))}

                  {colabHistory.length === 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      minHeight={300}
                      bgcolor="#fff"
                      px={3}
                      py={2}
                      position="relative"
                    >
                      <Typography
                        color="#1E6B7F"
                        component="span"
                        fontSize={16}
                        fontWeight={500}
                      >
                        {t('checkin.no_data.historical')}
                      </Typography>
                    </Box>
                  )}
                </>
              )
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={2} />
          }
        </div>
        {/* PAGE ENDS */}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModalHelp}
          onClose={handleCloseModalHelp}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backgroundColor: '#205B6F', opacity: 0.8 }
          }}
          sx={{ border: 'none' }}
          style={{ outline: 0 }}
        >
          <Slide direction="down" in={openModalHelp} style={{ outline: 0 }} mountOnEnter unmountOnExit>
            <Box
              display="flex"
              bgcolor="#fff"
              flexDirection="column"
              zIndex={2}
              py="42px"
              px="26px"
              borderRadius="8px"
              gap="28px"
              width={450}
              component="form"
              noValidate
              autoComplete='off'
              position="relative"
            >
              <button
                onClick={handleCloseModalHelp}
                className={style.close}
              >
                <IconClose />
              </button>

              <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
                Pontos de check-in
              </Typography>

              <Box display="flex" flexDirection="column" gap={4}>
                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #B1C0D0" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Ponto aberto
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ainda não foi feito  o check-in.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #B1C0D0" bgcolor="#B1C0D0" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Check-in no horário normal
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o expediente começa 08:00 e o ponto foi batido nesse horário.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #22BEAD" bgcolor="#22BEAD" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Check-in fora do horário
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o expediente começa as 08:00 e o ponto foi batido às 07:45.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #FF5151" bgcolor="#FF5151" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Check-in fora do horário
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o expediente começa as 08:00 e o ponto foi batido às 08:15.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #FF5151" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Check-in manual recusado
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o colaborador solicitou ajuste de ponto e este foi recusado.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #187DFF" bgcolor="#187DFF" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Check-in manual aprovado
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o colaborador solicitou ajuste de ponto e este foi aceito.
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" gap={2}>
                  <Box width={9} height={9} border="1px solid #187DFF" borderRadius="100%" />
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      component="span"
                      lineHeight="1"
                      fontSize={16}
                      color="#205B6F"
                      fontWeight={500}
                    >
                      Aguardando ajuste manual
                    </Typography>
                    <Typography
                      lineHeight="1"
                      color="#4f7091"
                      fontSize={12}
                    >
                      Ex: o colaborador solicitou ajuste de ponto e está aguardando resposta.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Slide>
        </Modal>

        <ModalExportReports
          openModalExport={openModalExportReports}
          handleCloseModalExport={handleCloseModalExport}
          viewExport='historyPointsColab'
          filter={{ ...filter, mu_id: userDetails.mu_id }}
        />

      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinHistory)