import { CheckSquare, CheckSquareOffset, X } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import EasyEdit from 'react-easy-edit';
import { Checkbox, IconButton } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    'padding': 0,
    'color': '#22BEAD !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));

export function Item({ title = "", checked = false, id, saveItem, updateItem, innerRef, provided, deleteItem }) {
  // id, title, due_date, checked
  const [itemEdit, setItemEdit] = useState(false)
  const { t } = useTranslation('common');
  const [checkedItem, setCheckedItem] = useState(checked);
  const [titleItem, setTitleItem] = useState(title);

  const classes = useStyles()

  const handleChangeCheck = (event) => {
    setCheckedItem(event.target.checked);
    updateItem({ id: id, title: titleItem, checked: event.target.checked })
  };
  const handleChangeTextarea = (event) => {
    setTitleItem(event.target.value);
  };
  function handleEditItem() {
    setItemEdit((value) => {
      return !value
    })
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  function handleSaveItem(body) {
    saveItem(body)
    handleEditItem()
  }
  function handleDeleteItem(id) {
    deleteItem(id)
  }
  function handleUpdateItem(body) {
    updateItem(body)
    // handleEditItem()
  }

  const save = async (value) => {
    if(!value) return
    handleUpdateItem({ id: id, title: value, checked: checkedItem })
  }

  const cancel = (e) => {
    console.log(e)
  }


  return (
    <div
      className='item'
      ref={innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >
      <div className='checkBox'>
        <Checkbox {...label}
          checked={checkedItem}
          onChange={handleChangeCheck}
          color="default"
          disableRipple
          classes={{ root: classes.root }}   
        />
      </div>
      <div className='item-title'>
        {!itemEdit ?
          <div className='boxItem'>
            {/* <p onClick={handleEditItem}>
              {title}
            </p> */}
             <EasyEdit
                    type={'text'}
                    onSave={(value) => save(value, id)}
                    onCancel={cancel}
                    attributes={{ name: "title", id: id }}
                    value={title}
                    hideSaveButton
                    hideCancelButton
                    cancelOnBlur                    
                    displayComponent={<span style={{ textDecoration: checkedItem ? 'line-through' : 'none', fontWeight: 'normal' }}>{title}</span>}
                  />
            <div className='boxItemActions'>
              {/* <IconButton size="small" >
                <Edit fontSize="small" color='primary'/>
              </IconButton> */}
              <IconButton size="small" onClick={() => { handleDeleteItem(id) }}>
                <Delete fontSize="small" color='error'/>
              </IconButton>
            </div>

          </div>
          :
          <div className='boxTask'>
            <textarea className='item-title-input' onChange={handleChangeTextarea} value={titleItem} rows={1}></textarea>
            <div className='botton-action'>
              <div className='button-row-items'>
                <div className='button' onClick={() => { handleUpdateItem({ id: id, title: titleItem, checked: checkedItem }) }}>
                  {t('modalDetailTask.save')}
                </div>
                <X style={{ cursor: "pointer" }} size={24} color='#4F7091' onClick={() => {
                  handleEditItem()
                }} />
              </div>
              {/* <div className='button-delete' onClick={() => { handleDeleteItem(id) }}>
                {t('modalDetailTask.delete')}
              </div> */}
            </div>
          </div>
        }

      </div>
    </div>

  );
}