import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Container, Grid, Typography } from '@mui/material';
import Note from '../../_components/checklist/Note';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsChecklistsSharingPage(props) {

  const { t, i18n } = useTranslation('common');
  const { id } = useParams()
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);

  async function getData() {
    try {

      setFetching(true)

      const response = await api.get(`/report/shared-todo/details?mu_id=${id}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)

      setFetching(false)

    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }


  useEffect(() => {
    getData()
  }, [])

  return (

    <section className="mainContent">

      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        showShareBtn={false}
        routeBackToPage={'/productive/reports/checklists'}
      />

      <div className={style.containerCheckinPage}>
        {
          !fetching
            ?
            <Grid container spacing={2}>
              {
                (data && data.length > 0) &&
                data.map(note => (
                  <Grid item xs={4} key={`note-${note.id}`}>
                    <Note
                      isOwner={false}
                      note={note}
                    />
                  </Grid>
                ))
              }
            </Grid>
            :
            <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
        }

      </div>

    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsChecklistsSharingPage)