import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import ShareModal from '../../_components/_shared/ShareModal';

import { useTranslation, initReactI18next } from "react-i18next";

import { statsActions, shareActions, usersActions, alertActions } from '../../_actions';
import { api } from '../../_helpers/api';
import { authHeader, calcWidthWidget, getRangeFilterWhen } from '../../_helpers';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import moment from 'moment';
import Widget8 from '../../_components/executive/Widget8';
import Widget11 from '../../_components/executive/Widget11';
import Widget9 from '../../_components/executive/Widget9';
import Widget13 from '../../_components/executive/Widget13';
import Widget7 from '../../_components/executive/Widget7';
import Widget14 from '../../_components/executive/Widget14';
import Widget15 from '../../_components/executive/Widget15';
import Widget16 from '../../_components/executive/Widget16';
import Widget12 from '../../_components/executive/Widget12';
import Widget10 from '../../_components/executive/Widget10';
import Widget28 from '../../_components/executive/Widget28';
import Widget25 from '../../_components/executive/Widget25';
import Widget26 from '../../_components/executive/Widget26';
import Widget29 from '../../_components/executive/Widget29';

function ExecutivePage(props) {
    const { t, i18n } = useTranslation('common');

    const { statsActions, shareActions, userDetails, usersActions, alertActions } = props;

    const currentDate = moment()

    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);
    const [widgetsUser, setWidgetsUser] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const filter = props.filter || {};

    function closeShareModal() {
        shareActions.closeModal()
    }

    const updateFilterRangeDate = (dateStart, dateEnd) => {
        setFilterDateStart(dateStart)
        setFilterDateEnd(dateEnd)
    }

    const getWidgetsUser = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/stats/executive/widgets`, { headers: authHeader() })
            if (response.status !== 200) {
                throw new Error('Erro get widgets!!!')
            }

            const { data } = response

            if (data.length > 0) {

                const range = getRangeFilterWhen(userDetails?.initialPreview || '')

                let startDate = range.startDate;
                let endDate = range.endDate;

                updateFilterRangeDate(startDate, endDate)

                setWidgetsUser(data)
            }
            setIsLoading(false)
        } catch (error) {
            alertActions.error(t('generic_messages.errors.load'))
            setIsLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.today');
    });


    const renderWidgets = (widgets) => {

        if (widgets.length === 0) {
            return <Box padding={'16px'} display={'flex'} component={Paper} elevation={0} width={'100%'}>
                <Link href="/productive/widgets"><Typography width={'100%'} variant='subtitle1'>{t('widgets.click_settings_widgets')}</Typography></Link> 
            </Box>
        }

        const range = getRangeFilterWhen(userDetails?.initialPreview || '')

        let startDate = range.startDate;
        let endDate = range.endDate;

        // updateFilterRangeDate(startDate, endDate)

        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }

        let queryTeams = ''
        let queryTeammates = ''

        if (teams && teams.length > 0) {
            let nt = 0;
            queryTeams = "&teams=";

            for (let team of teams) {
                if (nt == 0) {
                    queryTeams += team.id;
                } else {
                    queryTeams += "," + team.id;
                }
                nt++;
            }

        }

        if (teammates && teammates.length > 0) {
            let nt = 0;
            queryTeammates = "&users=";

            for (let teammate of teammates) {
                if (nt === 0) {
                    queryTeammates += teammate.id;
                } else {
                    queryTeammates += "," + teammate.id;
                }
                nt++;
            }

        }

        const filters = {
            filterStartDate: `&start_date=${startDate}`,
            filterEndDate: `&end_date=${endDate}`,
            filterTeams: queryTeams,
            filterTeammates: queryTeammates
        }

        const widgetsAllowed = widgets.filter(w => userDetails.allowed_widgets.includes(w.key))
        
        const widgetsComponents = widgetsAllowed.map(widget => {
            switch (widget.Widget_key) {
                case 'Widget7':
                    return <Widget7 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} />
                case 'Widget8':
                    return <Widget8 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} />
                case 'Widget9':
                    return <Widget9 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} userDetails={userDetails} />
                case 'Widget10':
                    return <Widget10 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} />
                case 'Widget11':
                    return <Widget11 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)}  filters={filters}/>
                case 'Widget12':
                    return <Widget12 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} />
                case 'Widget13':
                    return <Widget13 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} />
                case 'Widget14':
                    return <Widget14 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} />
                case 'Widget15':
                    return <Widget15 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} />
                case 'Widget16':
                    return <Widget16 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} userDetails={userDetails}/>
                case 'Widget28':
                    return <Widget28 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} userDetails={userDetails}/>
                case 'Widget25':
                    return <Widget25 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(50)} filters={filters} />
                case 'Widget26':
                    return <Widget26 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} />
                case 'Widget29':
                    return <Widget29 alertActions={alertActions} currentDate={currentDate} width={calcWidthWidget(100)} filters={filters} />
                default:
                    return
            }
        })

        return widgetsComponents

    }

    useEffect(() => {
        getWidgetsUser()
    }, [filter])

    return (
        <React.Fragment>

            <section className="mainContent hiddenOverflowX">
                <Toolbar />
                <Filterbar
                    startDate={filterDateStart}
                    endDate={filterDateEnd}
                    widgetsSettings={true}
                    showShareBtn={false}
                    
                />
                {/* PAGE STARTS */}
                {
                    !isLoading
                    &&
                    <Box sx={{ flexGrow: 1}}>
                        <Grid container spacing={1} padding={'16px'} display={'flex'}>
                            {
                                renderWidgets(widgetsUser)
                            }
                        </Grid>
                    </Box>
                }
            </section>

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>

            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal filters={filter} />
            </section>


        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch),

    }
}


function mapStateToProps(state) {

    return {
        loadingStat: state.stats.loading,
        filter: state.filter,
        share: state.share,
        userDetails: state.authentication.userDetails,
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ExecutivePage)
