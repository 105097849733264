import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { alertActions } from '../../_actions';

import IconClose from '../_icons/IconClose';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function AlertBox(props) {
  const { t, i18n } = useTranslation('common');

  const { alertActions } = props;

  let alert = props.alert || [];

  const [alertClass, setAlertClass] = useState("alertBox");
  const [alertMessage, setAlertMessage] = useState();
  const [alertCode, setAlertCode] = useState();

  useEffect(() => {
    // Fecha alerta atual e limpa os dados caso tenha alerta aberto
    //setAlertMessage();
    //setAlertCode();
    showAlertBox();

    // Aguarda um pouco para dar tempo da animação de esconder caso tenha um alerta aberto.
    showAlertBox();
    
    setTimeout(() => {
      hideAlertBox()
    }, (3000));

  }, [alert]);

  function showAlertBox() {
    
    if (alert.message) {

      let message

      if (typeof alert.message === 'string' || alert.message instanceof String){
        message = alert.message
      }
      else if (typeof alert.message === 'number' || alert.message instanceof Number) {
        message = alert.message
      }
      else{
        setAlertClass("alertBox active alert-danger");
        setAlertMessage(t('alert.503'));
        setAlertCode("[BEF-503]");
        return
      }

      setAlertClass("alertBox active " + alert.type);

      if (alert.type == "alert-success") {
        if(alert.message != ""){        
          setAlertMessage(message);
        }
        else{
          setAlertMessage(t('alert.success'));
        }
        
      }
      else {
        if (typeof message === "number") {
          switch (message) {
            case 401:
              setAlertMessage(t('alert.401'));
              setAlertCode("[BEF-401]");
              break;
            case 404:
              setAlertMessage(t('alert.404'));
              setAlertCode("[BEF-404]");
              break;
            case 408:
              setAlertMessage(t('alert.408'));
              setAlertCode("[BEF-408]");
              break;
            case 500:
              setAlertMessage(t('alert.500'));
              setAlertCode("[BEF-500]");
              break;
            case 504:
              setAlertMessage(t('alert.504'));
              setAlertCode("[BEF-504]");
              break;
            default:
              setAlertMessage(t('alert.001'));
              setAlertCode("[BEF-001]");
          }
        }
        else if (message != "") {
          setAlertMessage(message);
        }
        else {
          setAlertMessage(t('alert.001'));
          setAlertCode("[BEF-001]");
        }
      }

    } 
  }

  function hideAlertBox() {
    setAlertClass("alertBox");
    setAlertMessage();
    setAlertCode();

  }


  return (
    <div className={alertClass}>
      <div className="status"></div>
      <div className="message">{alertMessage}<i>{alertCode}</i></div>
      <div className="close" onClick={() => { hideAlertBox() }}><IconClose /></div>
    </div>

  )
};



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  }
}


function mapStateToProps(state) {

  return {
    alert: state.alert,
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlertBox));
