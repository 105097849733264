import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { teamsActions } from '../../../_actions'

function DrawerCreate(props) {
    const { t, i18n } = useTranslation('common')

    const { teamsActions } = props
    const { drawerData, getTeamsData} = props

    let created = props.created || false
    let teams = props.teams || []

    let teamsList = []

    const [submitted, setSubmitted] = useState(false)
    const [form, setForm] = useState({
        name: '',
        parent: null,
        clone_from: null,
        is_subteam: false,
        is_a_clone: false
    })

    const [formError, setFormError] = useState({
        name: ''
    })

    const handleChange = e => {
        setSubmitted(false)

        if (e.target.name == 'is_a_clone') {
            setForm({
                ...form,
                [e.target.name]: !form.is_a_clone,
            })
        }
        else if (e.target.name == 'is_subteam') {
            setForm({
                ...form,
                [e.target.name]: !form.is_subteam,
            })
        }
        else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        }


    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)

        let formIsValid = true

        if (!form["name"]) {
            formIsValid = false
            setFormError({
                ...formError,
                name: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            setFormError({
                name: ''
            })

            teamsActions.postTeams(form)
        }
        else {
            setSubmitted(false)
        }

    }

    function closeDrawer() {

        setForm({
            name: '',
            parent: null,
            clone_from: null,
            is_subteam: false,
            is_a_clone: false
        })
        setSubmitted(false)
        setFormError({
            name: ''
        })

        props.closeDrawer()
    }

    useEffect(() => {

        if (created == 'error') {
            setSubmitted(false)
        }

        if (created == 'success' && submitted) {
            setSubmitted(false)
            teamsActions.getTeams()
            getTeamsData({ pageIndex: 0, pageSize: 10, isLoading: false })
            closeDrawer()
        }

    }, [created])

    for (let t0 of teams) {
        if (!t0.ancestry) {
            teamsList.push(<option className='t0' value={t0.id}>{t0.name}</option>)
            let p0 = t0.id
            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(<option className='t1' value={p0 + '/' + t1.id}>&nbsp;&nbsp;{t1.name}</option>)
                    let p1 = t1.id
                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<option className='t2' value={p0 + '/' + p1 + '/' + t2.id}>&nbsp;&nbsp;&nbsp;&nbsp;{t2.name}</option>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<option className='t3' value={p0 + '/' + p1 + '/' + p2 + '/' + t3.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t3.name}</option>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<option className='t4' value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + t4.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t4.name}</option>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<option className='t5' value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + t5.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t5.name}</option>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<option className='t6' value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + p5 + '/' + t6.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t6.name}</option>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<option className='t7' value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + p5 + '/' + p6 + '/' + t7.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t7.name}</option>)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (form.is_a_clone && form.clone_from === null) {
            setForm({
                ...form,
                clone_from: String(teams[0].id)
            })
        }
    }, [form.is_a_clone])
    return (
        <React.Fragment>
            <div className="internal invite team create">

                <header>
                    <h3>{t('settings.teams.create')}</h3>
                </header>

                <form onSubmit={handleSubmit} className="form">

                    <div className="fieldset radio extra">
                        <div className="field">
                            <input type="checkbox" id="is_a_clone" name="is_a_clone" checked={form.is_a_clone} value={form.is_a_clone} onChange={handleChange} /> <label htmlFor="is_a_clone">{t('settings.teams.is_a_clone')}</label>
                        </div>

                        <div className="field">
                            <input type="checkbox" id="is_subteam" name="is_subteam" checked={form.is_subteam} value={form.is_subteam} onChange={handleChange} /> <label htmlFor="is_subteam">{t('settings.teams.is_subteam')}</label>
                        </div>
                    </div>

                    <div className="fieldset text">
                        <div className="field">
                            <label htmlFor="name">{t('settings.teams.name')}*</label>
                            <input type="text" id="name" name="name" value={form.name} onChange={handleChange} />
                        </div>
                    </div>

                    {form.is_a_clone &&
                        <div className="fieldset text">
                            <div className="field">
                                <label htmlFor="clone_from">{t('settings.teams.clone_from')}</label>
                                <select id="clone_from" name="clone_from" onChange={handleChange}>
                                    {teamsList}
                                </select>
                            </div>
                        </div>
                    }

                    {form.is_subteam &&
                        <div className="fieldset text">
                            <div className="field">
                                <label htmlFor="parent">{t('settings.teams.parent_team')}</label>
                                <select id="parent" name="parent" onChange={handleChange}>
                                    {teamsList}
                                </select>
                            </div>
                        </div>
                    }

                    <div className="formReturn">
                        {(created == 'error') &&
                            <div className="error">{t('settings.teams.create_error')}</div>
                        }

                        {(formError.name || formError.email || formError.role) &&
                            <div className="error">{t('settings.administrator.required')}</div>
                        }
                    </div>

                    <div className="controls">
                        {(submitted && !created) &&
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        }
                        <button type="button" disabled={submitted ? true : false} onClick={() => { closeDrawer() }}>{t('settings.teams.cancel')}</button>
                        <button type="submit" disabled={submitted ? true : false}>{t('settings.teams.create')}</button>
                    </div>
                </form>

            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        created: state.teams.created
    }

}


function mapDispatchToProps(dispatch) {

    return {
        teamsActions: bindActionCreators(teamsActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerCreate)



