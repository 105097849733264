import React from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import IconEdit2 from '../../_components/_icons/IconEdit2';
import IconRemove from '../../_components/_icons/IconRemove';
import { authHeader, befectiveFormatDate, getDayWeek, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ModalNewAttribute from '../../_components/rooms_page/ModalNewAttribute';
import ModalEditAttribute from '../../_components/rooms_page/ModalEditAttribute';


function RoomAttributesPage(props) {

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props

  const [attributesRoom, setAttributesRoom] = useState([])
  const [attributeIdToDelete, setAttributeIdToDelete] = useState(null)
  const [attributeToEdit, setAttributeToEdit] = useState(null)
  const [openModalNewAttribute, setOpenModalNewAttribute] = useState(false);
  const [openModalEditAttribute, setOpenModalEditAttribute] = useState(false);


  const [anchorElConfirmDeleteAttribute, setAnchorElConfirmDeleteAttribute] = useState(null);

  const handleClickConfirmDeleteAttribute = (event, attId) => {
    setAttributeIdToDelete(attId)
    setAnchorElConfirmDeleteAttribute(event.currentTarget);
  };

  const handleClickToEditAttribute = (event, att) => {
    event.preventDefault()
    setAttributeToEdit(att)
    setOpenModalEditAttribute(true)
  }

  const handleCloseConfirmDeleteAttribute = () => {
    setAttributeIdToDelete(null)
    setAnchorElConfirmDeleteAttribute(null);
  };

  const openConfirmDeleteAttribute = Boolean(anchorElConfirmDeleteAttribute);
  const idConfirmDeleteAttribute = openConfirmDeleteAttribute ? 'simple-popover-2' : undefined;

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getAttributesRoom = async () => {
    try {

      const response = await api.get(`/rooms/attributes`, {
        headers: authHeader()
      })
      setAttributesRoom(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  const onDeleteAttribute = async () => {
    try {

      const res = await api.delete(`/rooms/attributes/${attributeIdToDelete}`, { headers: authHeader() })
      handleCloseConfirmDeleteAttribute()
      getAttributesRoom()
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.delete'))
    }
  }

  useEffect(() => {

    if (openModalEditAttribute === false) {
      setAttributeToEdit(null)
    }
  }, [openModalEditAttribute])



  useEffect(() => {
    document.title = 'Befective | ' + t('spaces.settings');
    getAttributesRoom()
  }, [])


  return (
    <>
      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          tabSettingsRoom={true}
          createAttribute={true}
          setOpenModalNewAttribute={setOpenModalNewAttribute}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          <div className={style.containerTable}>
            <table>
              <thead>
                <tr>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.title')}
                  </th>
                  <th style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                    {t('spaces.columns.pre_selected')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.updated_at')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.columns.created_by')}
                  </th>
                  <th
                    style={{ width: '4%', textTransform: 'uppercase' }}
                  >
                    {t('spaces.columns.actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  attributesRoom?.length > 0 ?
                    attributesRoom.map((att, index) => {
                      return (
                        <tr key={index}>
                          <td>{att?.title}</td>
                          <td style={{textAlign: 'center'}}>{att?.pre_selected ? t('spaces.attributes.yes') : t('spaces.attributes.no')}</td>
                          <td>{befectiveFormatDate(att?.updated_at, i18n.language, 'T')}</td>
                          <td>{att?.created_by_name}</td>
                          <td>
                            <div className={style.boxActions}>
                              <button className={`${style.buttonAction} ${style.permissions}`} onClick={(e) => handleClickToEditAttribute(e, att)}>
                                <IconEdit2 />
                              </button>
                              <button className={`${style.buttonAction} ${style.remove}`} onClick={(e) => handleClickConfirmDeleteAttribute(e, att.id)}>
                                <IconRemove />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })

                    :
                    <tr><td colSpan={5}>{t('spaces.attributes.no_data')}</td></tr>
                }
              </tbody>
            </table>

          </div>

        </div>
        {/* PAGE ENDS */}

        <Popover
          id={idConfirmDeleteAttribute}
          open={openConfirmDeleteAttribute}
          anchorEl={anchorElConfirmDeleteAttribute}
          onClose={handleCloseConfirmDeleteAttribute}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              backgroundColor: "#FFE3E5",
              boxShadow: "none",
              padding: '18px 14px',
              borderRadius: '6px',
            }
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

            <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmDeleteAttribute}>
                <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
              </Button>
              <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteAttribute}>
                <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
              </Button>
            </Box>
          </Box>
        </Popover>

      </section>

      <ModalNewAttribute
        openModalNewAttribute={openModalNewAttribute}
        setOpenModalNewAttribute={setOpenModalNewAttribute}
        userDetails={userDetails}
        alertActions={alertActions}
        updateInfo={getAttributesRoom}
      />

      {
        attributeToEdit &&
        <ModalEditAttribute
          openModalEditAttribute={openModalEditAttribute}
          setOpenModalEditAttribute={setOpenModalEditAttribute}
          userDetails={userDetails}
          alertActions={alertActions}
          updateInfo={getAttributesRoom}
          attributeToEdit={attributeToEdit}
        />
      }
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomAttributesPage)