import React from 'react';

const IconDetail = props => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="4" viewBox="0 0 11 3.194">
      <path id="_..." data-name="..." d="M7.659,14.534a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,7.659,14.534Zm2.306-1.6a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,9.966,12.938Zm-7.806,0a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,2.159,12.938Z" transform="translate(-0.563 -12.938)" fill="#98acc1"/>
    </svg>
    
    )
}

export default IconDetail;