import React from 'react';

const IconCopy = props => {
    return (

<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
  <g id="Icon_feather-copy" data-name="Icon feather-copy" transform="translate(-2.5 -2.5)">
    <path id="Caminho_112" data-name="Caminho 112" d="M14.7,13.5h5.4a1.2,1.2,0,0,1,1.2,1.2v5.4a1.2,1.2,0,0,1-1.2,1.2H14.7a1.2,1.2,0,0,1-1.2-1.2V14.7A1.2,1.2,0,0,1,14.7,13.5Z" transform="translate(-6.3 -6.3)" fill="none" stroke="#98acc1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    <path id="Caminho_113" data-name="Caminho 113" d="M4.8,10.8H4.2A1.2,1.2,0,0,1,3,9.6V4.2A1.2,1.2,0,0,1,4.2,3H9.6a1.2,1.2,0,0,1,1.2,1.2v.6" fill="none" stroke="#98acc1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
  </g>
</svg>

      
    )
}

export default IconCopy;