import React, {useRef, useState, useMemo, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation, initReactI18next} from "react-i18next"
import {bindActionCreators} from 'redux'

import {settingsActions} from '../../../_actions'

function DrawerInvite(props) {
    const {t, i18n} = useTranslation('common')

    const {settingsActions} = props

    let invited = props.invited || false
    let teams = props.teams || []
    let teamsList = []

    const [submitted, setSubmitted] = useState(false)
    const [form, setForm] = useState({
        name: '',
        email: '',
        role: 'manager',
        enable_activities: false,
        enable_freedays: false
    })

    const [formError, setFormError] = useState({
        name: '',
        email: '',
        role: ''
    })

    function toggleTeam(id) {

        let mainOn = document.getElementById("team" + id).classList.contains('on');

        var elems = document.querySelectorAll(".team" + id);

        if (mainOn) {
            [].forEach.call(elems, function (el) {
                el.classList.remove("on");
            });
        } else {
            [].forEach.call(elems, function (el) {
                el.classList.add("on");
            });
        }

    }

    for (let team of teams) {
        if (!team.ancestry) {
            teamsList.push(<li key={team.id} id={"team" + team.id} className={"t0 on team" + team.id}><b>{team.name}</b>
                <div className="toggle" onClick={() => {
                    toggleTeam(team.id)
                }}><span></span></div>
            </li>)
            let p0 = team.id
            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(<li key={t1.id} id={"team" + t1.id}
                                       className={"t1 on team" + p0 + " team" + t1.id}>{t1.name}
                        <div className="toggle" onClick={() => {
                            toggleTeam(t1.id)
                        }}><span></span></div>
                    </li>)
                    let p1 = t1.id
                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<li key={t2.id} id={"team" + t2.id}
                                               className={"t2 on team" + p0 + " team" + p1 + " team" + t2.id}>{t2.name}
                                <div className="toggle" onClick={() => {
                                    toggleTeam(t2.id)
                                }}><span></span></div>
                            </li>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<li key={t3.id} id={"team" + t3.id}
                                                       className={"t3 on team" + p0 + " team" + p1 + " team" + p2 + " team" + t3.id}>{t3.name}
                                        <div className="toggle" onClick={() => {
                                            toggleTeam(t3.id)
                                        }}><span></span></div>
                                    </li>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<li key={t4.id} id={"team" + t4.id}
                                                               className={"t4 on team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + t4.id}>{t4.name}
                                                <div className="toggle" onClick={() => {
                                                    toggleTeam(t4.id)
                                                }}><span></span></div>
                                            </li>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<li key={t5.id} id={"team" + t5.id}
                                                                       className={"t5 on team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + t5.id}>{t5.name}
                                                        <div className="toggle" onClick={() => {
                                                            toggleTeam(t5.id)
                                                        }}><span></span></div>
                                                    </li>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<li key={t6.id} id={"team" + t6.id}
                                                                               className={"t6 on team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + t6.id}>{t6.name}
                                                                <div className="toggle" onClick={() => {
                                                                    toggleTeam(t6.id)
                                                                }}><span></span></div>
                                                            </li>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<li key={t7.id} id={"team" + t7.id}
                                                                                       className={"t7 on team" + p0 + " team" + p1 + " team" + p2 + " team" + p3 + " team" + p4 + " team" + p5 + " team" + p6 + " team" + t7.id}>{t7.name}
                                                                        <div className="toggle" onClick={() => {
                                                                            toggleTeam(t7.id)
                                                                        }}><span></span></div>
                                                                    </li>)
                                                                }
                                                            }

                                                        }
                                                    }

                                                }
                                            }

                                        }
                                    }

                                }
                            }
                        }
                    }

                }


            }

        }

    }

    const handleChange = e => {
        setSubmitted(false)
        setForm({
            ...form,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)

        let formIsValid = true

        if (!form["name"]) {
            formIsValid = false
            setFormError({
                ...formError,
                name: t('settings.company.error.required'),
            })
        }

        if (!form["email"]) {
            formIsValid = false
            setFormError({
                ...formError,
                email: t('settings.company.error.required'),
            })
        }

        if (!form["role"]) {
            formIsValid = false
            setFormError({
                ...formError,
                email: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            let teams = []

            var elems = document.querySelectorAll(".on");
            [].forEach.call(elems, function (el) {
                teams.push(parseInt(el.id.replace("team", '')))
            })

            setFormError({
                name: '',
                email: '',
                role: ''
            })

            settingsActions.postCompanyInviteAdministrator(form, teams)
        } else {
            setSubmitted(false)
        }

    }

    function closeDrawer() {

        setForm({
            name: '',
            email: '',
            role: '',
            enable_activities: false,
            enable_freedays: false
        })

        setSubmitted(false)

        setFormError({
            name: '',
            email: '',
            role: ''
        })

        props.closeDrawer()
    }

    useEffect(() => {

        if (invited == 'error') {
            setSubmitted(false)
        }

        if (invited == 'success') {
            setSubmitted(false)
            closeDrawer()
            settingsActions.getCompanyAdministrators()
        }

    }, [invited])


    return (
        <React.Fragment>
            <div className="internal invite">

                <header>
                    <h3>{t('settings.administrator.invite_someone')}</h3>
                </header>


                <form onSubmit={handleSubmit} className="form">
                    <div className="fieldset radio">
                        <div className="field" style={{display: 'none'}}>
                            <input type="radio" id="role1" name="role" checked={form.role === "admin"} value="admin"
                                   onChange={handleChange}/> <label htmlFor="role1">{t('admin1')}</label>
                        </div>
                        <div className="field">
                            <input type="radio" id="role2" name="role" checked={form.role === "manager"} value="manager"
                                   onChange={handleChange}/> <label htmlFor="role2">{t('admin2')}</label>
                        </div>
                        <div className="field">
                            <input type="radio" id="role3" name="role" checked={form.role === "analyst"} value="analyst"
                                   onChange={handleChange}/> <label htmlFor="role3">{t('admin3')}</label>
                        </div>
                    </div>
                    {(form.role == 'analyst') &&
                        <div className="fieldset radio extra">
                            <div className="field">
                                <input type="checkbox" id="enable_activities" name="enable_activities"
                                       checked={form.enable_activities} value={form.enable_activities}
                                       onChange={handleChange}/> <label
                                htmlFor="enable_activities">{t('settings.administrator.enable_activities')}</label>
                            </div>
                            <div className="field">
                                <input type="checkbox" id="enable_freedays" name="enable_freedays"
                                       checked={form.enable_freedays} value={form.enable_freedays}
                                       onChange={handleChange}/> <label
                                htmlFor="enable_freedays">{t('settings.administrator.enable_freedays')}</label>
                            </div>
                        </div>
                    }

                    <div className="fieldset text">
                        <div className="field name">
                            <label htmlFor="name">{t('settings.administrator.name')}*</label>
                            <input type="text" id="name" name="name" value={form.name} onChange={handleChange}/>
                        </div>
                        <div className="field">
                            <label htmlFor="email">{t('settings.administrator.email')}*</label>
                            <input type="text" id="email" name="email" value={form.email} onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="formReturn">
                        {(invited == 'error') &&
                            <div className="error">{t('settings.administrator.invite_error')}</div>
                        }

                        {(formError.name || formError.email || formError.role) &&
                            <div className="error">{t('settings.administrator.required')}</div>
                        }
                    </div>

                    <div className="teams">
                        <h3>Teams to lead</h3>
                        <ul>
                            {teamsList}
                        </ul>
                    </div>

                    <div className="controls">
                        {(submitted && !invited) &&
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        }
                        <button type="button" disabled={submitted ? true : false} onClick={() => {
                            closeDrawer()
                        }}>{t('settings.company.cancel')}</button>
                        <button type="submit"
                                disabled={submitted ? true : false}>{t('settings.company.invite')}</button>
                    </div>
                </form>


                <div className="permission">

                </div>

            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        invited: state.settings.invited
    }

}


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DrawerInvite)



