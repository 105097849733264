import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Avatar, Backdrop, Box, Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Slide, TextField, Typography } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";
import "./MembersWorkspaceModal.scss"
import { Autocomplete } from "@material-ui/lab";
import { BeachAccess, Delete, Image, Work } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));


function MembersWorkspaceModal({
  type = "W",
  openModalMembers,
  setOpenModalMembers,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo,
  currentWorkspace,
  currentBoard = []
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [colabs, setColabs] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [member, setMember] = useState(null)
  const [memberError, setMemberError] = useState(false)
  const [members, setMembers] = useState([])

  function closeModalMembersWorkspace() {
    setOpenModalMembers(false)
    setMember(null)
    updateInfo(false)
  }

  const addMemberWorkspace = async () => {
    try {
      if (!member) {
        setMemberError(true)
        return
      }
      setIsSubmitting(true)
      await api.post(`/project-management/workspaces/${currentWorkspace.id}/members`,
        {
          member_id: member.value
        },
        {
          headers: authHeader()
        })
      setIsSubmitting(false)
      getMemberWorkspace()
      setMember(null)
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('project_management.errors.updated_workspace'))
    }
  }

  const removeMemberWorkspace = async (id) => {
    try {

      await api.delete(`/project-management/workspaces/members/${id}`,
        {
          headers: authHeader()
        })
      getMemberWorkspace()
    } catch (error) {
      //alertActions.error(t('project_management.errors.updated_workspace'))
    }
  }


  const getMemberWorkspace = async () => {
    try {
      const { data } = await api.get(`/project-management/workspaces/${currentWorkspace.id}/members`, {
        headers: authHeader()
      })
      setMembers(data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  // BOARDS
  const getMembersBoard = async () => {
    try {
      const { data } = await api.get(`/project-management/boards/${currentBoard.id}/members`, {
        headers: authHeader()
      })
      setMembers(data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  const addMemberBoard = async () => {
    try {
      if (!member) {
        setMemberError(true)
        return
      }
      setIsSubmitting(true)
      await api.post(`/project-management/boards/${currentBoard.id}/members`,
        {
          mu_id: member.value
        },
        {
          headers: authHeader()
        })
      setIsSubmitting(false)
      getMembersBoard()
      setMember(null)
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('project_management.errors.updated_workspace'))
    }
  }

  const removeMemberBoard = async (id) => {
    try {

      await api.delete(`/project-management/boards/members/${id}`,
        {
          headers: authHeader()
        })
      getMembersBoard()
    } catch (error) {
      //alertActions.error(t('project_management.errors.updated_workspace'))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    switch (type) {
      case "W":
        return addMemberWorkspace()
      case "B":
        return addMemberBoard()
      default:
        console.log("Type not exists!!!")
        return   
    }
  }

  useEffect(() => {
    if (openModalMembers && type === "W") {
      getMemberWorkspace()
    }
    if (openModalMembers && type === "B") {
      getMembersBoard()
    }
  }, [openModalMembers])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalMembersWorkspace ${classes.modal}`}
        open={openModalMembers}
        onClose={closeModalMembersWorkspace}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalMembers} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalMembersWorkspace"}>

            <button onClick={() => { closeModalMembersWorkspace() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmit}>
              <main>
                <header>
                  <Typography variant="h5">{t('project_management.modal_members.title')}</Typography>
                </header>
                <Box padding={'10px 0px 0px'} display={'flex'} style={{ gap: '10px' }}>
                  <Autocomplete
                    id="country-select-demo"
                    options={colabsOptions.length > 0 ? colabsOptions.filter(co => !members.map(m => m.mu_id).includes(co.value)) : []}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight={false}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <Box>
                        <Box>{option.label}</Box>
                        <Box>
                          <Typography variant="caption">{option.email}</Typography>
                        </Box>
                      </Box>
                    )}
                    style={{ width: '80%' }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={t('project_management.modal_members.select')}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        error={memberError}
                      />
                    )}
                    value={member}
                    onChange={(event, newValue) => {
                      setMember(newValue);
                      setMemberError(false)
                    }}
                  />
                  <Button type="submit" disabled={isSubmitting || !member} variant="contained" color="primary" style={{ textTransform: 'initial', width: '20%' }} disableElevation>
                    <span style={{ fontSize: '12px' }}>{t('project_management.modal_members.add')}</span>
                  </Button>
                </Box>

                <Box>
                  <List>
                    {
                      members.length > 0 ?
                      members.map(member => (
                        <>
                          <ListItem
                            style={{ paddingLeft: '6px' }}
                            key={member.email}
                          >
                            <ListItemText style={{ marginTop: '2px', marginBottom: '2px' }} primary={member.username} secondary={member.email} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="delete-member" onClick={() => type === "W" ? removeMemberWorkspace(member.id) : removeMemberBoard(member.id)}>
                                <Delete />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider component="li" />
                        </>
                      ))
                      :
                      <ListItem
                        style={{ paddingLeft: '6px' }}
                      >
                        <ListItemText style={{ marginTop: '2px', marginBottom: '2px' }} primary={<Typography variant="caption" component={'span'}>{t("project_management.modal_members.none")}</Typography>}/>
                      </ListItem>

                    }
                  </List>
                </Box>

              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersWorkspaceModal));
