import React from 'react';

const IconWindows = props => {
    return (
        
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <path id="Icon_awesome-windows" data-name="Icon awesome-windows" d="M0,3.9l4.918-.678V7.977H0ZM0,12.6l4.918.678V8.582H0Zm5.459.75,6.541.9V8.582H5.459Zm0-10.195V7.977H12V2.25l-6.541.9Z" transform="translate(0 -2.25)" fill="#187dff"/>
</svg>

    

    )
}

export default IconWindows;