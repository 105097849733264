import React from 'react'
import Moment from 'moment';
import { Badge, makeStyles } from '@material-ui/core'
import { Chat, CheckCircle, Info } from 'phosphor-react'

import './styles.scss'
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup, IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, OpenInNew, SettingsBackupRestore, Visibility } from '@material-ui/icons';
import { authHeader, getAvatarUrl, limitedText } from '../../../_helpers';
import { useState } from 'react';
import { useEffect } from 'react';
import { DialogConfirm } from '../DialogConfirm';
import { api } from '../../../_helpers/api';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  badge: {
    fontSize: '10px',
    minWidth: '15px',
    width: '15px',
    height: '15px'
  },
}));

export function TableMessagesArchived({ data, teammates, alertActions, updateInfo, userDetails }) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [currentMessageId, setCurrentMessageId] = useState(null)
  const [submmited, setSubmitted] = useState(false)

  const openDialogConfirm = (event, messageId) => {
    setCurrentMessageId(messageId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentMessageId(null);
    setAnchorEl(null);
  };

  const handleActionUnarchiveMessage = async () => {

    try {
      setSubmitted(true)

      const res = await api.post(`/messages/${currentMessageId}/deactivate?organization_id=${userDetails?.organizationId}`,
        {
          deactivate: true
        }
      )
      if (res.data) {
        updateInfo()
        alertActions.success(t('messages.alerts.success_archived_message'))
        handleClose()
        setSubmitted(false)
      }

    } catch (error) {
      setSubmitted(false)
      //alertActions.error(t('messages.alerts.error_archived_message'))
    }
  }

  return (
    <>
      <table className="table-calls-page">
        <thead>
          <tr>
            <th
              style={{ width: '30%', textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.subject')}
            </th>
            <th
              style={{ width: 'auto', textAlign: 'left' }}
            >
              {t('messages.list.message')}
            </th>
            <th
              style={{ width: '20%', textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.destinations')}
            </th>
            <th
              style={{ width: '8%', textAlign: 'center' }}
            >
              {t('messages.list.actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map(message => (
            <tr
              key={message.id}
            >
              <td
                style={{ textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                {limitedText(message.subject, 30)}
              </td>
              <td
                style={{ textAlign: 'left' }}
              >
                {limitedText(message.message_body, 80)}
              </td>
              <td
                style={{ textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <AvatarGroup max={3} style={{ justifyContent: 'start' }}>
                  {
                    message.destination_id.length && message.destination_id?.map((item, key) => {
                      
                        const nameTT = teammates?.find((tt) => tt.value == item)?.label
                        return (
                          <Tooltip key={key} title={nameTT}>
                            <Avatar
                              alt={nameTT}
                              src={getAvatarUrl({ name: nameTT })}
                              style={{ width: 28, height: 28 }}
                            />
                          </Tooltip>
                        )
                    })
                  }
                </AvatarGroup>
              </td>
              <td
                style={{ textAlign: 'center' }}
              >
                <div>
                  <Tooltip title={t('messages.list.tooltip_unarchive')}>
                    <IconButton aria-label="delete" className={classes.margin} size="small" onClick={(e) => openDialogConfirm(e, message?.id)}>
                      <SettingsBackupRestore fontSize="small" style={{ color: '#fc6662' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
        {
          currentMessageId && <DialogConfirm type='unarchive' isOpen={open} anchorEl={anchorEl} submitted={submmited} handleActionConfirm={handleActionUnarchiveMessage} handleClose={handleClose} />
        }
      </table>
      {data.length === 0 && (
        <div className="table-calls-not-content">{t('messages.no_message_archive')}</div>
      )}
    </>
  )
}
