import { goalsConstants } from '../_constants';

export function goals(state = {}, action) {

    switch (action.type) {
        case goalsConstants.GET_GOALS_REQUEST:
            return {
                loading: true
            };
        case goalsConstants.GET_GOALS_TEAMMATES_REQUEST:
            return {
                loadingTeammates: true
            };
        case goalsConstants.GET_GOALS_SUCCESS:
         
            return {
                payload: action.payload,
                loading: false
            };
        case goalsConstants.GET_GOALS_TEAMMATES_SUCCESS:
            return {
                payloadTeammates: action.payload,
                loadingTeammates: false
            };
        case goalsConstants.GET_GOALS_FAILURE:
            return {
                error: action.error,
                loading: true
            };
        default:
            return state
    }
}