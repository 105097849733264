import React, { useEffect, useState } from 'react'
import Moment from 'moment';
import {  CircularProgress, Drawer, IconButton } from '@material-ui/core'
import {  Download, Info, } from 'phosphor-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { Close } from '@material-ui/icons';


import { api } from '../../../_helpers/api'
import { alertActions } from '../../../_actions'
import { CommentSkeleton } from '../CommentSkeleton';
import Comment from '../Comment';

import './styles.scss'

function DrawerComments(
  { 
    callData,
    state_drawer, 
    toggleDrawer, 
    userDetails,
    callsDataFiltered,
    setCallsDataFiltered,
    alertActions
  }) {
  const { t, i18n } = useTranslation('common');
  const [newComment, setNewComment] = useState('')
  const [commentsData, setCommentsData] = useState([])
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false)
  const [isLoadingComments, setIsLoadingComments] = useState(true)

  async function changeAllCommentsState() {
    try {
      await api.put(`/support_calls/${callData?.id}/set_read`) 

      const newCallsData = [...callsDataFiltered]
      newCallsData.forEach(item => {
        if(item.id === callData.id) {
          item.total_comments_not_read = 0
        }
      })

      setCallsDataFiltered(newCallsData)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('calls_page.error.update_comments_read'))
    }
  }

  const getCommentsData = async () => {
    
    try {
      const response = await api.get(
        `/support_calls/${callData?.id}/comments?organization_id=${userDetails?.origin_organization}&owner_id=${userDetails?.id}`
      )

      setCommentsData(response.data.comments)
      setIsLoadingComments(false)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('calls_page.error.get_comments'))
    }
  }

  async function createComment(e) {
    try {
      e.preventDefault()
      if(newComment !== '') {
        setIsCommentSubmitting(true)
        const response = await api.post(
          `/support_calls/${callData?.id}/comments?organization_id=${userDetails?.origin_organization}&owner_id=${userDetails?.id}`,
          {
            made_in: Moment(new Date()).format('YYYY-MM-DD'),
            value: newComment
          }
        )
        
        setCommentsData([{ ...response.data.comment[0], full_name: userDetails.name }, ...commentsData])
        setNewComment('')
        setIsCommentSubmitting(false)
        alertActions.success(t('calls_page.success.create_comment'))

        setTimeout(() => {
          alertActions.clear()
        }, 2000)
      }
    } catch (error) {
      console.log(error.response)
      setIsCommentSubmitting(false)
      //alertActions.error(t('calls_page.error.create_comment'))
    }
  }

  useEffect(() => {
    if (userDetails && callData){
      getCommentsData()
      changeAllCommentsState()
    }
  }, [userDetails, callData])
  
  
  return (
    <>
      <Drawer anchor='right' open={state_drawer} onClose={toggleDrawer(false)}>
        <div style={{ position: 'absolute', right: 4, top: 4 }}>
          <IconButton aria-label="close" onClick={toggleDrawer(false)}>
            <Close fontSize="medium" />
          </IconButton>
        </div>

        <div className="drawer-container-calls-page">
          <header>
            <h2>{callData?.title}</h2>
            <div>
              <span className={`drawer-priority-info ${callData?.urgency_label[0]?.us_label.toLowerCase() }`}>
                {t('calls_page.priority.priority_normal')}:
                <strong>{t(`calls_page.priority.${callData?.urgency_label[0]?.us_label.toLowerCase()}`)}</strong>
              </span>
              <div 
                className={callData?.call_status === "S" ? "drawer-status-call solved" : "drawer-status-call"}
              >
                <Info 
                  size={12} 
                  color={callData?.call_status === "S" ? "#22BEAD": "#187dff"} 
                  weight="bold" 
                />
                <span>{callData?.call_status === "S" ? t('calls_page.status.solved') : t('calls_page.status.open')}</span>
              </div>
            </div>
          </header>

          <main className="main-content-drawer">
            <div className="details-box-drawer">
              <p dangerouslySetInnerHTML={{ __html: callData?.description }}>
              </p>
            </div>

            {callData?.attachments?.map((item, index) => (
              <a key={item.attch_id} href={item.attch_url} download="proposed_file_name" >
                <div>
                  <span>{`Anexo ${index+1}`}</span>
                  <Download size={14} color="#187dff" weight="bold" />
                </div>
              </a>
            ))}
          </main>

          <footer className="footer-content-drawer">
            <h2>{t('calls_page.comments.comments_normal')} ({callData?.total_comments})</h2>

            {isLoadingComments ? (
              <CommentSkeleton />
            ) : (
              <div className="comments-box-scroll">
                  {commentsData?.map(item => (
                    <Comment
                      key={item.id}
                      user_id={userDetails.id}
                      full_name={item.full_name}
                      org_id={userDetails.origin_organization}
                      commentData={item}
                      showOptions={callData?.call_status === "O"}
                    />
                  ))}
              </div>
            )}

            {callData?.call_status === "O" && (
              <form onSubmit={createComment}>
                <input
                  type="text"
                  placeholder={t('calls_page.comments.placeholder')}
                  value={newComment}
                  onChange={e => setNewComment(e.target.value)}
                  disabled={isCommentSubmitting}
                />

                <button 
                  type="submit"
                  className="create-comment-button"
                  disabled={isCommentSubmitting}
                >
                  {isCommentSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('button.send')
                  )}
                </button>
              </form>
            )}
          </footer>
        </div>
      </Drawer>
    </>
  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComments)