import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleDown, ArrowCircleRight, ArrowCircleUp, ArrowsClockwise, CheckSquareOffset, Clock, ClockClockwise, ClockCounterClockwise, HourglassLow, Scales, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './CardsHeaderReportsTeammates.module.scss';
import IconTeammate from '../_icons/IconTeammate';
import IconSun from '../_icons/IconSun';
import IconMoon from '../_icons/IconMoon';
import IconLate from '../_icons/IconLate';

import { authHeader, befectiveFormatDate, hoursToMinutes, secondsToFormatHours, secondsToHm } from '../../_helpers';

export function CardsHeaderReportsTeammates({ alertActions, userDetails, resume }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #187DFF` }}>
          <Timer size={18} color={"#187DFF"} weight="bold" />
          <Typography color={"#187DFF"} component="span" fontSize={18} mt={1}>
            {secondsToHm(resume.total_time_in_seconds)}
          </Typography>
          <Typography color={"#187DFF"} component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_time')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <Clock size={18} color={"#187DFF"} weight="bold" />
          <Typography color="#187DFF" component="span" fontSize={18} mt={1}>
            {secondsToHm(resume.average_external_time)}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.average_external_time')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <CheckSquareOffset size={18} color={"#187DFF"} weight="bold" />
          <Typography color="#187DFF" component="span" fontSize={18} mt={1}>
            {resume?.total_remote_checkin}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <ArrowCircleUp size={18} color={"#22BEAD"} weight="bold" />
          <Typography color="#22BEAD" component="span" fontSize={18} mt={1}>
            {resume?.total_remote_checkin_finished}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_finished')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <ArrowCircleDown size={18} color={"#FC6662"} weight="bold" />

          <Typography color="#FC6662" component="span" fontSize={18} mt={1}>
            {resume?.total_remote_checkin_unfinished}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_unfinished')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4f7091' }}>
          <ArrowsClockwise size={18} color={"#4f7091"} weight="bold" />
          <Typography color="#4f7091" component="span" fontSize={18} mt={1}>
            {resume?.total_remote_checkin_integrated}
          </Typography>
          <Typography color="#4f7091" component="span" fontSize={12}>
            {t('remote_checkin.dashboard.header_cards.total_remote_checkin_integrated')}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
