import React from 'react';

const IconPassword = props => {
    return (    
     
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1028" data-name="Retângulo 1028" width="24" height="24" fill="none"/>
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(1.011 5.5)">
            <path id="Caminho_42" data-name="Caminho 42" d="M23.661,16.55h0c-1.05-1.3-4.95-5.55-10.65-5.55s-9.6,4.25-10.65,5.55a1.5,1.5,0,0,0,0,1.9c1.05,1.3,4.95,5.55,10.65,5.55s9.6-4.25,10.65-5.55A1.5,1.5,0,0,0,23.661,16.55ZM13.011,22c-4.4,0-7.65-3.1-8.85-4.5,1.2-1.4,4.45-4.5,8.85-4.5s7.65,3.1,8.85,4.5C20.661,18.9,17.411,22,13.011,22Z" transform="translate(-2.022 -11)" fill="#98acc1"/>
            <circle id="Elipse_13" data-name="Elipse 13" cx="3" cy="3" r="3" transform="translate(7.989 3.5)" fill="#98acc1"/>
          </g>
        </svg>

    )
}

export default IconPassword;