import React, { useEffect, useRef, useState } from 'react'
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography, Box, Divider, Popover } from '@material-ui/core';
import { ArrowDropDown, PersonAdd, Settings } from '@material-ui/icons';
import style from './WorkspacesFilter.module.scss';
import { history, limitedText } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

export default function RecentBoardsFilter({
  recentBoards = []
}) {

  const { t, i18n } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    
    if(recentBoards.length <= 0 ) return

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectBoard = (idBoard) => {
    history.push(`/project_management/home/boards/${idBoard}`)
    handleClose()
  }


  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        className={style.btnFilterWorkspace}
      >
        <Box display={'flex'} width={'100%'} justifyContent={'space-evenly'}>
          <Box component={'span'} style={{ textTransform: 'initial' }} color={'#106276'} fontWeight={400}>
            {t("project_management.workspaces.recent_boards")}
          </Box>
          <ArrowDropDown style={{ fill: '#106276' }} />
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper style={{backgroundColor: '#24677e', minWidth:'200px', maxWidth:'280px'}}>
          <Box>
            <MenuList id="menu-list-boards">

              {
                recentBoards?.filter(r => r.is_favorite === false)?.map((board, index) =>

                  board.title.length > 40
                    ?
                    <Tooltip
                      disableInteractive
                      title={board.title}
                      placement="bottom"
                      style={{ maxWidth: '300px' }}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      }}
                    >
                      <MenuItem key={board.title} onClick={() => redirectBoard(board.id)} >
                        <Typography variant='subtitle2' className={style.itemText}>{limitedText(board.title, 40)}</Typography>
                      </MenuItem>
                    </Tooltip>
                    :
                    <MenuItem key={board.title} onClick={() => redirectBoard(board.id)}>
                      <Typography variant='subtitle2'className={style.itemText}>{board.title}</Typography>
                    </MenuItem>
                )
              }
            </MenuList>
          </Box>
        </Paper>
          
      </Popover>
    </div>
  )
}
