import { Modal, Slide, makeStyles } from '@material-ui/core';
import { Backdrop, Box, Button, Stack, Typography } from '@mui/material';
import { Warning } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { authHeader, history } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import IconClose from '../../_icons/IconClose';
import style from './ModalConfirm.module.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const linkSX = {
  cursor: 'pointer',
  textDecoration: 'none'
};

export function ModalConfirm({
  openModal,
  handleCloseModal,
  reservation_id,
  alertActions
}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  async function handleCancelReservation() {
    try {
      const response = await api.put(`/rooms/reservations/${reservation_id}`, {},{
        headers: authHeader()
      })

      alertActions.success(t('spaces.success.reservation_delete'))
      handleCloseModal()
      history.push('/spaces')
    } catch (error) {
      console.log(error.response)
      alertActions.success(t('spaces.errors.reservation_delete'))
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="30px"
          px="26px"
          borderRadius="8px"
          gap="4px"
          width={350}
          component="form"
          noValidate
          autoComplete='off'
          position="relative"
        >
          <button
            onClick={handleCloseModal}
            className={style.close}
          >
            <IconClose />
          </button>

          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="8px">
            <Warning size={44} weight="fill" color="#FDBE2B" />

            <Stack direction="column" alignItems="center" justifyContent="center" gap="16px">
              <Typography fontSize={14} color="#4F7091" fontWeight={500} textAlign="center">
                Deseja cancelar sua reserva?
              </Typography>

              <Box display="flex" alignItems="center" gap="4px">
                <Button
                  style={{
                    backgroundColor: '#E3EFFF',
                    paddingY: '12px',
                    paddingX: '28px',
                    borderRadius: '2px',
                    minWidth: '100px',
                  }}
                  onClick={handleCloseModal}
                >
                  Sair
                </Button>
                <Button
                  style={{
                    backgroundColor: '#FFEAED',
                    paddingY: '12px',
                    paddingX: '28px',
                    borderRadius: '2px',
                    color: '#FF5151',
                    minWidth: '100px',
                  }}
                  onClick={handleCancelReservation}
                >
                  Confirmar
                </Button>
              </Box>
            </Stack>

          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
