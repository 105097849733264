import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
export const ExpendableButton = ({ isOpen, toggle, goalId }) => {
  return (

    <IconButton aria-label="expand" size="small" className="expand-button" onClick={toggle}>
      {
        isOpen?.isOpen && isOpen?.goalId === goalId
          ?
          <ExpandLessIcon />
          :
          <ExpandMoreIcon />
      }

    </IconButton>
  );
};