import React from 'react';

const IconMac = props => {
    return (
        
      <svg xmlns="http://www.w3.org/2000/svg" width="10.084" height="12" viewBox="0 0 10.084 12">
      <path id="Icon_awesome-apple" data-name="Icon awesome-apple" d="M8.712,8.591a2.5,2.5,0,0,1,1.34-2.272A2.878,2.878,0,0,0,7.783,5.125a9.508,9.508,0,0,0-2.371.555,18.343,18.343,0,0,0-2.047-.528A3.247,3.247,0,0,0,.281,8.72,6.677,6.677,0,0,0,.667,10.9c.343.983,1.581,3.394,2.872,3.354.675-.016,1.152-.48,2.031-.48.852,0,1.294.48,2.047.48,1.3-.019,2.422-2.21,2.749-3.2A2.656,2.656,0,0,1,8.712,8.591ZM7.2,4.192A2.523,2.523,0,0,0,7.839,2.25a2.841,2.841,0,0,0-1.819.935,2.563,2.563,0,0,0-.686,1.926A2.25,2.25,0,0,0,7.2,4.192Z" transform="translate(-0.281 -2.25)" fill="#187dff"/>
    </svg>
    

    )
}

export default IconMac;