import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';
import { ArrowFatUp, ArrowUp, CaretCircleDoubleDown, CaretCircleDoubleUp, Percent, Sun, Timer } from 'phosphor-react';
import { secondsToHm } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';

export function CardsHeaderPotentialProductive({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Box width={'100%'}>
        <h3 className='reportTitleGeneric'>
          {t('productive_reports.card_reports.potential_productive')}<span><IconInfo/><div className="tooltip">{t('productive_reports.card_reports.subtitle_potential_productive')}</div></span>
        </h3>
      </Box>
      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <Timer size={18} color="#4F7091" weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} my={1}>
            {secondsToHm(Number(stats.total_expected_productive_time_avg))}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('reports_potential_productive.header_cards.total_expected_productive_time_avg')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Timer size={18} color="#22BEAD" weight="bold" />
          <Typography color="#22BEAD" component="span" fontSize={20} my={1}>
            {secondsToHm(Number(stats.total_productive_time_avg))}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('reports_potential_productive.header_cards.total_productive_time_avg')}
          </Typography>
        </CardContent>
      </Card>


      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        {
          Number(stats.total_productive_balance_percent) >= 0
            ?

            <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
              <Percent size={18} color={'#22BEAD'} weight="bold" />
              <Typography color={'#22BEAD'} component="span" fontSize={20} my={1}>
                {`${stats.total_productive_balance_percent}%`}
              </Typography>
              <Typography color={'#22BEAD'} component="span" fontSize={12}>
                {t('reports_potential_productive.header_cards.total_productive_balance_percent')}
              </Typography>
            </CardContent>

            :

            <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
              <Percent size={18} color={'#FC6662'} weight="bold" />
              <Typography color={'#FC6662'} component="span" fontSize={20} my={1}>
              <ArrowFatUp size={16} weight='fill' color='#FC6662'/> {`${String(stats.total_productive_balance_percent).replace('-','')}%`}
              </Typography>
              <Typography color={'#FC6662'} component="span" fontSize={12}>
                {t('reports_potential_productive.header_cards.total_productive_balance_percent')}
              </Typography>
            </CardContent>
        }

      </Card>

    </Stack>
  );
}
