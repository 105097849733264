import chroma from "chroma-js";
import React from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    zIndex: 1,
    minWidth: 380,
    minHeight: 48,
    borderRadius: 4,
    border: 'solid 1px #D6DEEB',
    display: 'flex',
    cursor: 'pointer',
    webkitAppearance: 'none',
    transition: 'all 0.1s ease',
    ':focus': {
      outline: 0,
      border: 'solid 1px #187DFF',
    },
    ':active': {
      outline: 0,
      border: 'solid 1px #187DFF',
    }
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '2147483647',
    position: 'absolute'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color ?? 'black');
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      height: 37,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E8F1FE',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#187DFF',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#187DFF',
    ':hover': {
      backgroundColor: data.color,
      // color: '#ffffff',
    },
  }),
};




export default function CustomReactSelect({
  value=[],
  defaultValue = [],
  options = [],
  isMulti = true,
  closeMenuOnSelect = false,
  onChange = () => false,
  isOptionDisabled = () => false,
  placeholderKey='select',
  isDisabled=false
}) {
  const { t, i18n } = useTranslation('common');
  
  return (
    <Select
      value={value}
      placeholder={t(`placeholders.${placeholderKey}`)}
      noOptionsMessage={() => t('placeholders.no_options')}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={defaultValue}
      isMulti={isMulti}
      options={options}
      styles={colourStyles}
      onChange={onChange}      
      isOptionDisabled={isOptionDisabled}
      isDisabled={isDisabled}
    />
  )
}
