export const settingsConstants = {
    GET_COMPANY_INFORMATION_REQUEST: '[SETTINGS] GET_COMPANY_INFORMATION_REQUEST',
    GET_COMPANY_INFORMATION_SUCCESS: '[SETTINGS] GET_COMPANY_INFORMATION_SUCCESS',
    GET_COMPANY_INFORMATION_FAILURE: '[SETTINGS] GET_COMPANY_INFORMATION_FAILURE',
    PUT_COMPANY_INFORMATION_REQUEST: '[SETTINGS] PUT_COMPANY_INFORMATION_REQUEST',
    PUT_COMPANY_INFORMATION_SUCCESS: '[SETTINGS] PUT_COMPANY_INFORMATION_SUCCESS',
    PUT_COMPANY_INFORMATION_FAILURE: '[SETTINGS] PUT_COMPANY_INFORMATION_FAILURE',
    GET_COMPANY_ADMINISTRATORS_REQUEST: '[SETTINGS] GET_COMPANY_ADMINISTRATORS_REQUEST',
    GET_COMPANY_ADMINISTRATORS_SUCCESS: '[SETTINGS] GET_COMPANY_ADMINISTRATORS_SUCCESS',
    GET_COMPANY_ADMINISTRATORS_FAILURE: '[SETTINGS] GET_COMPANY_ADMINISTRATORS_FAILURE',
    GET_COMPANY_ADMINISTRATOR_REQUEST: '[SETTINGS] GET_COMPANY_ADMINISTRATOR_REQUEST',
    GET_COMPANY_ADMINISTRATOR_SUCCESS: '[SETTINGS] GET_COMPANY_ADMINISTRATOR_SUCCESS',
    GET_COMPANY_ADMINISTRATOR_FAILURE: '[SETTINGS] GET_COMPANY_ADMINISTRATOR_FAILURE',
    DELETE_COMPANY_ADMINISTRATORS_REQUEST: '[SETTINGS] DELETE_COMPANY_ADMINISTRATORS_REQUEST',
    DELETE_COMPANY_ADMINISTRATORS_SUCCESS: '[SETTINGS] DELETE_COMPANY_ADMINISTRATORS_SUCCESS',
    DELETE_COMPANY_ADMINISTRATORS_FAILURE: '[SETTINGS] DELETE_COMPANY_ADMINISTRATORS_FAILURE',
    POST_COMPANY_ADMINISTRATORS_INVITE_REQUEST: '[SETTINGS] POST_COMPANY_ADMINISTRATORS_INVITE_REQUEST',
    POST_COMPANY_ADMINISTRATORS_INVITE_SUCCESS: '[SETTINGS] POST_COMPANY_ADMINISTRATORS_INVITE_SUCCESS',
    POST_COMPANY_ADMINISTRATORS_INVITE_FAILURE: '[SETTINGS] POST_COMPANY_ADMINISTRATORS_INVITE_FAILURE',
    PUT_COMPANY_ADMINISTRATORS_REQUEST: '[SETTINGS] PUT_COMPANY_ADMINISTRATORS_REQUEST',
    PUT_COMPANY_ADMINISTRATORS_SUCCESS: '[SETTINGS] PUT_COMPANY_ADMINISTRATORS_SUCCESS',
    PUT_COMPANY_ADMINISTRATORS_FAILURE: '[SETTINGS] PUT_COMPANY_ADMINISTRATORS_FAILURE',
    GET_COMPANY_DAYSOFF_REQUEST: '[SETTINGS] GET_COMPANY_DAYSOFF_REQUEST',
    GET_COMPANY_DAYSOFF_SUCCESS: '[SETTINGS] GET_COMPANY_DAYSOFF_SUCCESS',
    GET_COMPANY_DAYSOFF_FAILURE: '[SETTINGS] GET_COMPANY_DAYSOFF_FAILURE',
    GET_COMPANY_DAYSOFF_SPECIFIC_REQUEST: '[SETTINGS] GET_COMPANY_DAYSOFF_SPECIFIC_REQUEST',
    GET_COMPANY_DAYSOFF_SPECIFIC_SUCCESS: '[SETTINGS] GET_COMPANY_DAYSOFF_SPECIFIC_SUCCESS',
    GET_COMPANY_DAYSOFF_SPECIFIC_FAILURE: '[SETTINGS] GET_COMPANY_DAYSOFF_SPECIFIC_FAILURE',
    POST_COMPANY_DAYSOFF_REQUEST: '[SETTINGS] POST_COMPANY_DAYSOFF_REQUEST',
    POST_COMPANY_DAYSOFF_SUCCESS: '[SETTINGS] POST_COMPANY_DAYSOFF_SPECIFIC_SUCCESS',
    POST_COMPANY_DAYSOFF_FAILURE: '[SETTINGS] POST_COMPANY_DAYSOFF_SPECIFIC_FAILURE',
    DELETE_COMPANY_DAYSOFF_REQUEST: '[SETTINGS] DELETE_COMPANY_DAYSOFF_REQUEST',
    DELETE_COMPANY_DAYSOFF_SUCCESS: '[SETTINGS] DELETE_COMPANY_DAYSOFF_SPECIFIC_SUCCESS',
    DELETE_COMPANY_DAYSOFF_FAILURE: '[SETTINGS] DELETE_COMPANY_DAYSOFF_SPECIFIC_FAILURE',

    GET_COMPANY_INVENTORY_REQUEST: '[SETTINGS] GET_COMPANY_INVENTORY_REQUEST',
    GET_COMPANY_INVENTORY_SUCCESS: '[SETTINGS] GET_COMPANY_INVENTORY_SUCCESS',
    GET_COMPANY_INVENTORY_FAILURE: '[SETTINGS] GET_COMPANY_INVENTORY_FAILURE',
    DELETE_COMPANY_INVENTORY_REQUEST: '[SETTINGS] DELETE_COMPANY_INVENTORY_REQUEST',
    DELETE_COMPANY_INVENTORY_SUCCESS: '[SETTINGS] DELETE_COMPANY_INVENTORY_SUCCESS',
    DELETE_COMPANY_INVENTORY_FAILURE: '[SETTINGS] DELETE_COMPANY_INVENTORY_FAILURE',

    GET_COMPANY_PERIODIC_REPORT_REQUEST: '[SETTINGS] GET_COMPANY_PERIODIC_REPORT_REQUEST',
    GET_COMPANY_PERIODIC_REPORT_SUCCESS: '[SETTINGS] GET_COMPANY_PERIODIC_REPORT_SUCCESS',
    GET_COMPANY_PERIODIC_REPORT_FAILURE: '[SETTINGS] GET_COMPANY_PERIODIC_REPORT_FAILURE',

    DELETE_COMPANY_PERIODIC_REPORT_REQUEST: '[SETTINGS] DELETE_COMPANY_PERIODIC_REPORT_REQUEST',
    DELETE_COMPANY_PERIODIC_REPORT_SUCCESS: '[SETTINGS] DELETE_COMPANY_PERIODIC_REPORT_SUCCESS',
    DELETE_COMPANY_PERIODIC_REPORT_FAILURE: '[SETTINGS] DELETE_COMPANY_PERIODIC_REPORT_FAILURE',

    PUT_COMPANY_PERIODIC_REPORT_REQUEST: '[SETTINGS] PUT_COMPANY_PERIODIC_REPORT_REQUEST',
    PUT_COMPANY_PERIODIC_REPORT_SUCCESS: '[SETTINGS] PUT_COMPANY_PERIODIC_REPORT_SUCCESS',
    PUT_COMPANY_PERIODIC_REPORT_FAILURE: '[SETTINGS] PUT_COMPANY_PERIODIC_REPORT_FAILURE',
};

