import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

import { befectiveFormatDate, checkIfIsAfterTime, formatPeriods, genPeriodSimpleArray, getMonths, getRemainingPoints, hoursToMinutes, numberToPercentString, secondsToFormatHours } from '../../../_helpers';


import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import IconIn from '../../_icons/IconIn';
import IconOut from '../../_icons/IconOut';
import style from './ReportsPointsDetails.module.scss';
import IconShare from '../../_icons/IconShare';
import ModalExportReports from '../../goals/ModalExportReports';
import { PencilSimpleLine, Warning } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({

  button: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 'max-content !important',
    height: '32px !important',
    backgroundColor: '#FDBE2B !important',
    borderRadius: '0px !important',
    cursor: 'pointer',
    fontWeight: '600 !important',
    border: 'none !important',
    color: '#106276 !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    transition: '0.2s',
    lineHeight: 'initial !important',
    letterSpacing: 'initial !important',

    '&:hover': {
      backgroundColor: '#FDBE2B !important',
      boxShadow: '0px 0px 4px 0px #2B2B2B55 !important',
    },
    '& > span': {
      fontSize: '13px !important',
      display: 'flex !important',
      flexWrap: 'nowrap !important'
    }
  },
  buttonSigned: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 'max-content !important',
    height: '32px !important',
    backgroundColor: '#22BEAD !important',
    borderRadius: '0px !important',
    cursor: 'auto !important',
    fontWeight: '600 !important',
    border: 'none !important',
    color: '#106276 !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    transition: '0.2s',
    lineHeight: 'initial !important',
    letterSpacing: 'initial !important',
    '& > span': {
      fontSize: '13px !important',
      display: 'flex !important',
      flexWrap: 'nowrap !important'
    }
  },
  buttonNoSigned: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 'max-content !important',
    height: '32px !important',
    backgroundColor: '#F5F6F9 !important',
    borderRadius: '0px !important',
    cursor: 'auto !important',
    fontWeight: '600 !important',
    border: 'none !important',
    color: '#98ACC1 !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    transition: '0.2s',
    lineHeight: 'initial !important',
    letterSpacing: 'initial !important',
    '& > span': {
      fontSize: '13px !important',
      display: 'flex !important',
      flexWrap: 'nowrap !important'
    }
  }

}));

const statusColors = [
  "#C1CDDA",
  "#C1CDDA",
  "#22BEAD",
  "#FF7A90",
  "#FF7A90",
  "#187DFF",
  "#187DFF"
]

const pointsCircle = [
  <Box width={6} height={6} border="1px solid #C1CDDA" borderRadius="100%" />,
  <Box width={6} height={6} bgcolor="#C1CDDA" border="1px solid #C1CDDA" borderRadius="100%" />,
  <Box width={6} height={6} bgcolor="#22BEAD" border="1px solid #22BEAD" borderRadius="100%" />,
  <Box width={6} height={6} bgcolor="#FF7A90" border="1px solid #FF7A90" borderRadius="100%" />,
  <Box width={6} height={6} border="1px solid #FF7A90" borderRadius="100%" />,
  <Box width={6} height={6} bgcolor="#187DFF" border="1px solid #187DFF" borderRadius="100%" />,
  <Box width={6} height={6} border="1px solid #187DFF" borderRadius="100%" />,
]

export function ReportsPointsDetails({
  alertActions,
  userDetails,
  dataHistorical,
  isCurrentMonth,
  periods,
  filter
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');
  const [openModalExportReports, setOpenModalExportReports] = useState(false);

  const handleCloseModalExport = (e) => {
    e.preventDefault()
    setOpenModalExportReports(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-between'}
        bgcolor="#fff"
        px={3}
        py={2}
        mb="2px"
        position="relative"
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" borderRight="1px solid #E4EAEF" paddingRight="30px">
            <Typography color="#1E6B7F" component="span" fontSize={18} fontWeight={500} mb="12px">
              {getMonths(filter.singleDate ? moment(filter.singleDate).month() : moment().month(), t)} {filter.singleDate ? moment(filter.singleDate).year() : moment().year()}
            </Typography>

            <Box display="flex" flexDirection="column" gap="3px">
              <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                {/* {`${t('checkin.historical.header.periods')}: ${formatPeriods(dataHistorical?.periods)}`} */}
              </Typography>
              <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                {`${t('checkin.historical.header.weekends')}: 0`}
              </Typography>
              <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                {`${t('checkin.historical.header.working_days')}: ${dataHistorical?.working_days} (${!dataHistorical.is_sign ? dataHistorical?.total_hours_should_work : dataHistorical?.sign_obj.total_hours})`}
              </Typography>
              <Typography color="#4F7091" component="span" fontSize={12} fontWeight={400}>
                {`${t('checkin.historical.header.closure')}: ${befectiveFormatDate(dataHistorical?.closing_month, i18n.language)}`}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap="24px" marginLeft="30px" alignItems="center" marginRight="40px">
            <div className={style.circularProgressGeneral}>
              <CircularProgressbarWithChildren
                value={!dataHistorical.is_sign ? Number(dataHistorical?.worked_hours_percent) || 0 : Number(hoursToMinutes(dataHistorical.sign_obj.worked_hours) * 100 / hoursToMinutes(dataHistorical.sign_obj.total_hours))}
                strokeWidth={10}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: '#22BEAD',
                  trailColor: "#eee",
                })}>
                <span style={{ fontSize: 16, fontWeight: 700, color: '#22BEAD' }}>
                  {`${!dataHistorical.is_sign ? numberToPercentString(dataHistorical?.worked_hours_percent || 0) : numberToPercentString(Number(hoursToMinutes(dataHistorical.sign_obj.worked_hours) * 100 / hoursToMinutes(dataHistorical.sign_obj.total_hours)))}`}
                </span>
              </CircularProgressbarWithChildren>
            </div>

            <Box display="flex" flexDirection="column">
              <Typography
                color="#22BEAD"
                fontSize={14}
                fontWeight="700"
              >
                {!dataHistorical.is_sign ? `${dataHistorical?.worked_hours} / ${dataHistorical?.total_hours_should_work}` : `${dataHistorical?.sign_obj.worked_hours} / ${dataHistorical?.sign_obj.total_hours}`}

              </Typography>
              <Typography
                color="#22BEAD"
                fontSize={12}
                fontWeight="400"
              >
                {t('checkin.historical.header.worked_time')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap="14px" flexDirection="column">
            <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #22BEAD">
              <Typography fontSize={14} color="#22BEAD" fontWeight={700}>{!dataHistorical.is_sign ? dataHistorical?.overtime : dataHistorical?.sign_obj.overtime}</Typography>
              <Typography fontSize={10} color="#22BEAD" fontWeight={500}>{t('checkin.historical.header.overtime')}</Typography>
            </Box>

            <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #FF7A90">
              <Typography fontSize={14} color="#FF7A90" fontWeight={700}>{!dataHistorical.is_sign ? dataHistorical?.missing_hours : dataHistorical?.sign_obj.missing_hours}</Typography>
              <Typography fontSize={10} color="#FF7A90" fontWeight={500}>{t('checkin.historical.header.missing')}</Typography>
            </Box>

            {

              String(dataHistorical.balance).includes('-') ?

                <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #FF7A90">
                  <Typography fontSize={14} color="#FF7A90" fontWeight={700}>{dataHistorical.balance}</Typography>
                  <Typography fontSize={10} color="#FF7A90" fontWeight={500}>{t('checkin.historical.header.balance_hours')}</Typography>
                </Box> :

                <Box display="flex" flexDirection="column" paddingLeft="4px" borderLeft="2px solid #22BEAD">
                  <Typography fontSize={14} color="#22BEAD" fontWeight={700}>{dataHistorical.balance}</Typography>
                  <Typography fontSize={10} color="#22BEAD" fontWeight={500}>{t('checkin.historical.header.balance_hours')}</Typography>
                </Box>
            }

          </Box>
        </Stack>

        <Box display="flex" gap="14px" alignItems={'center'} justifyContent={'center'}>
          {
            dataHistorical.is_sign
              ?
              <Button
                disableTouchRipple
                variant="contained"
                className={classes.buttonSigned}
                startIcon={<PencilSimpleLine size={12} weight='fill' />}
                style={{
                  width: "100px",
                  minWidth: '100px',
                  padding: '0',
                  minHeight: '0',
                  borderRadius: '0',
                  backgroundColor: "#22BEAD",
                  boxShadow: 'none'
                }}
              >
                {t('checkin.historical.signed')}
              </Button>
              :
              <Button
                disableTouchRipple
                variant="contained"
                className={classes.buttonNoSigned}
                startIcon={<Warning size={12} weight='fill' />}
                style={{
                  width: "100px",
                  minWidth: '100px',
                  padding: '0',
                  minHeight: '0',
                  borderRadius: '0',
                  backgroundColor: "#F5F6F9 ",
                  boxShadow: 'none'
                }}
              >
                {t('checkin.historical.not_signed')}
              </Button>
          }
          <button className={style.exportButton} type='button' onClick={() => setOpenModalExportReports(true)}>
            <IconShare />
          </button>
        </Box>
      </Box>
      {
        !dataHistorical.no_data &&
        <TableContainer component={Paper} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead>
              <TableRow sx={{ textTransform: 'uppercase' }}>
                <TableCell className={style.tableHeaderTd} style={{ paddingLeft: '20px' }} width='8%'>{t("checkin.dashboard.checkin_table.col_date")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="left">{t("checkin.control_points.table.col_points")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="7%">{t("checkin.dashboard.checkin_table.col_time")}</TableCell>
                <TableCell className={style.tableHeaderTd} align="right" width="5%">{t("checkin.dashboard.checkin_table.col_balance")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataHistorical?.points_by_day?.map(dayInfos => (
                <TableRow key={dayInfos.id}>
                  <TableCell style={{ paddingLeft: '20px', borderBottom: '2px solid #F5F6F9', fontSize: '12px' }}>
                    {befectiveFormatDate(dayInfos.day_ref, i18n.language, "S")}
                  </TableCell>

                  <TableCell style={{ borderBottom: '2px solid #F5F6F9' }}>
                    <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                      {
                        dayInfos.points.length > 0
                        &&
                        <>
                          {dayInfos.points.map((point, index) => (

                            <Box
                              key={`${point.moment} ${index}`}
                              style={{ padding: 0 }}
                              align="center"
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap="4px"
                                padding={'6px 2px'}
                                margin="0 auto"
                                minWidth={'54px'}
                                className={[
                                  style.icon
                                ].join(' ')}
                              >
                                {point.type === 'IN' ? (
                                  <IconIn fill={statusColors[point.status_id - 1]} />
                                ) : (
                                  <IconOut fill={statusColors[point.status_id - 1]} />
                                )}
                                <Typography
                                  color={
                                    [6, 7].includes(point.status_id)
                                      ? "#187DFF"
                                      : point.status_id === 5 ? "#FF7A90" : "#4F7091"
                                  }
                                  component="span"
                                  fontSize={12}
                                  lineHeight="initial"
                                >
                                  {[5, 6, 7].includes(point.status_id) ? (
                                    point.status_id === 7 ? (
                                      t("checkin.status.send")
                                    ) : point.status_id === 6 ? (
                                      point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                    ) : (
                                      t("checkin.status.refused")
                                    )
                                  ) : (
                                    point.time.split(':')[0] + ':' + point.time.split(':')[1]
                                  )}
                                </Typography>
                              </Box>
                            </Box>

                          ))}

                          {
                            getRemainingPoints(dayInfos).map((item, index) => (

                              <Box
                                key={index}
                                style={{ padding: 0 }}
                                align="center"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  minWidth={'54px'}
                                  gap="4px"
                                  margin="0 auto"
                                  className={[
                                    style.icon
                                  ].join(' ')}
                                  padding={'6px 2px'}
                                >
                                  {item?.type === 'IN' ? (
                                    <IconIn fill={"#C1CDDA"} />
                                  ) : (
                                    <IconOut fill={"#C1CDDA"} />
                                  )}
                                  <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                                    --:--
                                  </Typography>
                                </Box>
                              </Box>


                            ))
                          }
                        </>
                      }
                    </Box>
                  </TableCell>

                  <TableCell style={{ borderBottom: '2px solid #F5F6F9', fontSize: '12px' }} align="right">
                    {dayInfos.hours_amount}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '2px solid #F5F6F9',
                      color: hoursToMinutes(dayInfos.balance) < 0 ? '#FF5151' : '#22BEAD',
                      fontSize: '12px',
                    }}
                    align="right"
                  >
                    {dayInfos.balance}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer >
      }

      <ModalExportReports openModalExport={openModalExportReports} handleCloseModalExport={handleCloseModalExport} viewExport='historyPointsColab' filter={{ ...filter, mu_id: dataHistorical.owner_id }} />

    </>

  );
}
