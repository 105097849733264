import React from 'react'

export default function IconModuleSpaceManagement({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="espaços" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1783" data-name="Retângulo 1783" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(1.5 1.5)">
            <path id="Caminho_7529" data-name="Caminho 7529" d="M19,18.25V4.75A.75.75,0,0,0,18.25,4H4.75A.75.75,0,0,0,4,4.75V6.625a.75.75,0,0,0,.862.75A.787.787,0,0,0,5.5,6.588V5.5h12v12H5.5V16.375a.75.75,0,0,0-.862-.75A.788.788,0,0,0,4,16.412V18.25a.75.75,0,0,0,.75.75h13.5A.75.75,0,0,0,19,18.25Z" transform="translate(-4 -4)" fill="#24677e" />
            <path id="Caminho_7530" data-name="Caminho 7530" d="M10.937,21.026l3.338-3.375a.713.713,0,0,0,0-1.05l-3.338-3.375a.787.787,0,0,0-1.012-.075.713.713,0,0,0-.075,1.125l2.1,2.1H4.75a.75.75,0,0,0,0,1.5h7.2l-2.1,2.1A.713.713,0,0,0,9.925,21.1a.787.787,0,0,0,1.013-.075Z" transform="translate(-4 -9.626)" fill="#24677e" />
          </g>
        </g>
      </g>
    </svg>

  )
}
