import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableHistoryColab.scss';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from 'phosphor-react';
import { Typography } from '@mui/material';
import { befectiveFormatDate, history } from '../../../../_helpers';

export default function TableHistoryColab({
  data = []
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0} sx={{marginTop:'10px'}}>
      <Box sx={{padding: '10px'}}>
        <Typography variant='subtitle2' fontSize={'14px'} style={{color: '#1E6B7F'}}>Top 10</Typography>
      </Box>
      <Table className='tableColabDetailsInventory' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid #F5F6F9' }}>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='left'>{t("inventory.colabs.table.register_at")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='left'>{t("inventory.colabs.table.cpu_usage")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.usage_ram")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.free_ram")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.usage_disk")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.free_disk")}</TableCell>
            {/* <TableCell width={'30px'} /> */}
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.id} className={`row-table`}>
                <TableCell align="left">{befectiveFormatDate(row.created_at, i18n.language, 'T')}</TableCell>
                <TableCell align="left">{String(Number(row.cpu_current_percent_usage).toFixed(2)) + "%"}</TableCell>
                <TableCell align="center">{String(Number(row.ram_current_usage_in_mb / 1024).toFixed(2))+" GB" + " - " + String(Number(row.ram_current_usage_percent).toFixed(2)) + "%"}</TableCell>
                <TableCell align="center">{String(Number((row.ram_total_in_mb - row.ram_current_usage_in_mb) / 1024).toFixed(2))+" GB"}</TableCell>
                <TableCell align="center">{String(Number(row.disk_used_in_mb / 1024).toFixed(2))+" GB" + " - " + String(Number(row.disk_percent).toFixed(2)) + "%"}</TableCell>
                <TableCell align="center">{String(Number((row.disk_free_in_mb) / 1024).toFixed(2))+" GB"}</TableCell>
              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
