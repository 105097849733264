import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Avatar, Backdrop, Box, Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Slide, TextField, Typography, Tooltip } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";
import style from "./BoardsArchivedModal.module.scss"
import { Autocomplete } from "@material-ui/lab";
import { BeachAccess, Delete, Image, SettingsBackupRestore, Work } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));


function BoardsArchivedModal({
  openModalBoardsArchived,
  setOpenModalBoardsArchived,
  alertActions,
  updateInfo,
  currentWorkspace
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [boardsArchived, setBoardsArchived] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUnarchiving, setIsUnarchiving] = useState(false)

  function closeModalBoardsArchived() {
    setOpenModalBoardsArchived(false)
    updateInfo(false)
  }

  // BOARDS ARCHIVED
  const getBoardsArchived = async () => {
    try {
      setIsLoading(true)
      const { data } = await api.get(`/project-management/workspaces/${currentWorkspace.id}/archived-boards`, {
        headers: authHeader()
      })
      setBoardsArchived(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('project_management.errors.load'))
      setIsLoading(false)
    }
  }

  const handleUnarchiveBoard = async (board) => {
    try {

      if(!board) return

      setIsUnarchiving(true)
      
      const result = await api.put(`/project-management/boards/${board?.id}`,
        { 
          ...board,
          archive: false
        },
        {
          headers: authHeader()
        }
      )
      await getBoardsArchived()
      await updateInfo()
      alertActions.success(t('project_management.success.unarchived_board'))
      setIsUnarchiving(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.unarchived_board'))
      setIsUnarchiving(false)
    }
  }

  useEffect(() => {
    if (openModalBoardsArchived)
      getBoardsArchived()
  }, [openModalBoardsArchived])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${style.modalBoardsArchived} ${classes.modal}`}
        open={openModalBoardsArchived}
        onClose={closeModalBoardsArchived}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalBoardsArchived} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={style.containerModalBoardsArchived}>

            <button onClick={() => { closeModalBoardsArchived() }} className={style.close}><IconClose /></button>

            <main>
              <header>
                <Typography variant="h5">{t('project_management.modal_archived.title')}</Typography>
              </header>
              <Box maxHeight={'400px'} style={{ overflowY: 'scroll', paddingRight:'8px' }}>
                <List>
                  {
                    boardsArchived.length > 0 &&
                    boardsArchived.map(board => (
                      <>
                        <ListItem
                          style={{ paddingLeft: '6px' }}
                        >
                          <ListItemText style={{ marginTop: '2px', marginBottom: '2px', color: '#1E6B7F' }} primary={board.title} secondary={board.description} />
                          <ListItemSecondaryAction>
                            <Tooltip title={t('project_management.btn_unarchive')}>
                              <IconButton disabled={isUnarchiving} size="small" edge="end" aria-label="archived-board" onClick={() => handleUnarchiveBoard(board)}>
                                <SettingsBackupRestore />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" />
                      </>
                    ))
                  }
                </List>
              </Box>

            </main>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardsArchivedModal));
