import { Box, Card, CardContent, Popover, Stack, Typography } from '@mui/material';
import { ArrowCircleRight, ArrowCircleUp, Clock, ClockClockwise, HourglassLow, Timer, WarningCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { authHeader, befectiveFormatDate, hoursToMinutes } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import style from './CardsHeader.module.scss';

export function CardsHeader({ alertActions, userDetails }) {
  const { t, i18n } = useTranslation('common');

  const [popoverTitle, setPopoverTitle] = useState('')
  const [popoverContent, setPopoverConent] = useState([])
  const [cardsInfos, setCardsInfos] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event, title,content = []) => {
    setPopoverTitle(title)
    setPopoverConent(content)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getColabCardsStats() {
    try {
      const response = await api.get(`/checkin/colab-stats?owner_id=${userDetails.mu_id}&group_id=${userDetails.group_id}&moment=${moment().format()}`, {
        headers: authHeader()
      })

      setCardsInfos(response.data)
    } catch (error) {
      console.log(error)
      //alertActions.error(t('checkin.dashboard.alerts.get_colabs_card_error'))
    }
  }



  function calcPercent(workedTime, shouldWorkTime) {
    if (!workedTime || !shouldWorkTime || Number(cardsInfos?.total_hours_shound_work_float) === 0) return 0
    return Number((hoursToMinutes(cardsInfos?.workedHours) * 100) / hoursToMinutes(cardsInfos?.total_hours_shound_work)).toFixed(2)
  }

  useEffect(() => {
    getColabCardsStats()
  }, [])

  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid ${hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"}` }}>
          <Timer size={20} color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} weight="bold" />

          <Typography color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} component="span" fontSize={20} mt={1}>
            {cardsInfos?.hoursBank || '00:00'}
          </Typography>
          <Typography color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_annual_leave')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #187DFF' }}>
          <Clock size={20} color="#187DFF" weight="bold" />

          <Typography color="#187DFF" component="span" fontSize={20} mt={1}>
            {cardsInfos?.workedHours || '00:00'} / {cardsInfos?.total_hours_shound_work || '00:00'}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_hours_worked')}
          </Typography>

          <Typography
            position="absolute"
            color="#187DFF"
            component="span"
            fontSize={12}
            fontWeight="500"
            paddingX="8px"
            paddingY="1px"
            bgcolor="#E3EFFF"
            right={12}
            top={10}
            borderRadius="4px"
          >
            {calcPercent(cardsInfos?.workedHours, cardsInfos?.total_hours_shound_work)}%
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none', cursor: 'pointer' }}
        className={style.cardContainer}
        onClick={(e) => handleClick(e, t('checkin.dashboard.header_cards.info_hours_extra_month'),cardsInfos?.extra_hours)}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <ArrowCircleUp size={20} color="#22BEAD" weight="bold" />

          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {cardsInfos?.overtime || '00:00'}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_hours_extra_month')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none', cursor: 'pointer' }}
        className={style.cardContainer}
        onClick={(e) => handleClick(e, t('checkin.dashboard.header_cards.info_hours_missing_month'),cardsInfos?.missing_hours)}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FDA02B' }}>
          <ClockClockwise size={20} color="#FDA02B" weight="bold" />

          <Typography color="#FDA02B" component="span" fontSize={20} mt={1}>
            {cardsInfos?.missingHours || '00:00'}
          </Typography>
          <Typography color="#FDA02B" component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_hours_missing_month')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid ${hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"}` }}>
          <HourglassLow size={20} color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} weight="bold" />

          <Typography color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} component="span" fontSize={20} mt={1}>
            {cardsInfos?.hoursBalance || '00:00'}
          </Typography>
          <Typography color={hoursToMinutes(cardsInfos?.hoursBank) < 0 ? '#FF5151' : "#22BEAD"} component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_balance_month')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4f7091' }}>
          <WarningCircle size={20} color="#4f7091" weight="bold" />

          <Typography color="#4f7091" component="span" fontSize={20} mt={1}>
            {cardsInfos?.pointError || '0'}
          </Typography>
          <Typography color="#4f7091" component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_errors_point')}
          </Typography>

        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #BB8EFE' }}>
          <ArrowCircleRight size={20} color="#BB8EFE" weight="bold" />

          <Typography color="#BB8EFE" component="span" fontSize={20} mt={1}>
            {cardsInfos?.annualLeave || '0'}
          </Typography>
          <Typography color="#BB8EFE" component="span" fontSize={12}>
            {t('checkin.dashboard.header_cards.info_missing_days')}
          </Typography>

        </CardContent>
      </Card>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FDBE2B",
            boxShadow: "none",
            padding: '12px',
            borderRadius: '12px',
            marginLeft: '-30px'
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography fontSize="14px" color="black" fontWeight={700}>{popoverTitle}</Typography>

          <Box display="flex" flexDirection="column" gap="2px">
            
            {popoverContent.length > 0 && popoverContent.map((item) => 
            <Box key={item.id} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography fontSize="12px" color="black">{befectiveFormatDate(item.day,i18n.language)}</Typography>
              <Typography fontSize="12px" color="black" fontWeight={700}>{item.balance}</Typography>
            </Box>
            )}
            
          </Box>

        </Box>
      </Popover>
    </Stack>
  );
}
