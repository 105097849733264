import {usersConstants} from '../_constants';
import {usersService} from '../_services';
import {alertActions} from '.';
import {history} from '../_helpers';

export const usersActions = {
    login,
    logout,
    refresh,
    getAll,
    activate,
    getByToken,
    patch,
    updatePassword,
    passwordRecover,
    getByResetToken,
    resetPassword,
    getResponsibles
};

function login(org_id, email, password) {
    return dispatch => {
        dispatch(request({email}));

        usersService.login(org_id, email, password)
            .then(
                user => {
                    dispatch(success(user));         
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    usersService.logout();
    return {type: usersConstants.LOGOUT};
}

function passwordRecover(org_id, email) {
    return dispatch => {
        dispatch(request());

        usersService.passwordRecover(org_id, email)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.POST_USER_RECOVER_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.POST_USER_RECOVER_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.POST_USER_RECOVER_FAILURE, error}
    }
}

function refresh(user) {
    return dispatch => {
        dispatch(request());

        usersService.refresh(user)
            .then(
                user => dispatch(success(user)),
                error => {
                    //dispatch(failure(error));
                    ////dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.LOGIN_REFRESH_REQUEST}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_REFRESH_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_REFRESH_FAILURE, error}
    }
}

function getByToken(token,org_id) {
    return dispatch => {
        dispatch(request());

        usersService.getByToken(token,org_id)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    history.push('/');
                }
            );
    };

    function request(user) {
        return {type: usersConstants.GET_BY_TOKEN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.GET_BY_TOKEN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.GET_BY_TOKEN_FAILURE, error}
    }
}

function getByResetToken(token, org_id) {
    return dispatch => {
        dispatch(request());

        usersService.getByResetToken(token, org_id)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    history.push('/');
                }
            );
    };

    function request(user) {
        return {type: usersConstants.GET_BY_TOKEN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.GET_BY_TOKEN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.GET_BY_TOKEN_FAILURE, error}
    }
}

function activate(password, passwordConfirm, user_id, token,org_id) {
    return dispatch => {
        dispatch(request());

        usersService.activate(password, passwordConfirm, user_id, token,org_id)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}

function resetPassword(password, passwordConfirm, user_id, token, org_id) {
    return dispatch => {
        dispatch(request());

        usersService.resetPassword(password, passwordConfirm, user_id, token, org_id)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: usersConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: usersConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error}
    }
}


function getAll() {
    return dispatch => {
        dispatch(request());

        usersService.getAll()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            )
    }

    function request() {
        return {type: usersConstants.GETALL_REQUEST}
    }

    function success(users) {
        return {type: usersConstants.GETALL_SUCCESS, users}
    }

    function failure(error) {
        return {type: usersConstants.GETALL_FAILURE, error}
    }
}

function patch(form, id) {
    return dispatch => {
        dispatch(request());

        usersService.patch(form, id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => {

                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() {
        return {type: usersConstants.PATCH_USER_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.PATCH_USER_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.PATCH_USER_FAILURE, error}
    }
}

function updatePassword(form, id) {
    return dispatch => {
        dispatch(request());

        usersService.updatePassword(form, id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: usersConstants.PATCH_USER_PASSWORD_REQUEST}
    }

    function success(payload) {
        return {type: usersConstants.PATCH_USER_PASSWORD_SUCCESS, payload}
    }

    function failure(error) {
        return {type: usersConstants.PATCH_USER_PASSWORD_FAILURE, error}
    }
}


function getResponsibles(organizationId) {
    return dispatch => {
        dispatch(request());

        usersService.getResponsibles(organizationId)
            .then(
                payload => {
                    
                    dispatch(success(payload))
                },
                error => { 
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: usersConstants.GET_RESPONSIBLES_REQUEST } }
    function success(payload) { return { type: usersConstants.GET_RESPONSIBLES_SUCCESS, payload } }
    function failure(error) { return { type: usersConstants.GET_RESPONSIBLES_FAILURE, error } }
}
