import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import './TableReportsTeammates.scss';
import { befectiveFormatDate, history, secondsToHm } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { ArrowRight, House } from 'phosphor-react';
import { Apartment, Business, Home } from '@material-ui/icons';
import { Tooltip, Typography } from '@mui/material';
import style from './DashboardTable.module.scss';
import moment from 'moment';


export default function TableReportsTeammatesDetails({
  data = null
}) {

  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsPoints' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow sx={{ textTransform: "uppercase" }}>
            <TableCell className={style.tableHeaderTd}>{t("remote_checkin.dashboard.table.col_date")}</TableCell>
            <TableCell className={style.tableHeaderTd}>{t("remote_checkin.dashboard.table.col_in")}</TableCell>
            <TableCell className={style.tableHeaderTd}>{t("remote_checkin.dashboard.table.col_out")}</TableCell>
            <TableCell className={style.tableHeaderTd} align={'center'}>{t("remote_checkin.dashboard.table.col_status")}</TableCell>
            <TableCell className={style.tableHeaderTd}>{t("remote_checkin.dashboard.table.col_time")}</TableCell>
            <TableCell className={style.tableHeaderTd} style={{ width: '30%' }}>{t("remote_checkin.dashboard.table.col_obs")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (data && data?.remote_trackeds?.length > 0)
              ?
              data?.remote_trackeds?.map(row => (
                <TableRow className={style.tableRow} key={row.id} style={{ cursor: "auto" }}>
                  <TableCell className={style.tableCell}>
                    {befectiveFormatDate(row.date_ref, i18n.language)}
                  </TableCell>

                  <TableCell className={style.tableCell}>
                    {moment(row.records[0]?.datetime).format('HH:mm')}
                  </TableCell>
                  <TableCell className={style.tableCell}>
                    {row.status === 'COMPLETED' ? moment(row.records[1]?.datetime).format('HH:mm') : '--:--'}
                  </TableCell>
                  <TableCell align='center'>
                    {
                      row.status === 'COMPLETED'
                        ?
                        <Box className={style.boxStatus}>
                          <Box component='span' className={style.statusFinished}>
                            {t('remote_checkin.dashboard.table.finished')}
                          </Box>
                        </Box>
                        :
                        <Box className={style.boxStatus}>
                          <Box component='span' className={style.statusUnfinished}>
                            {t('remote_checkin.dashboard.table.unfinished')}
                          </Box>
                        </Box>
                    }
                  </TableCell>
                  <TableCell className={style.tableCell}>
                    {row.status === 'COMPLETED' ? secondsToHm((moment(row.records[1]?.datetime).diff(moment(row.records[0]?.datetime), 'seconds'))) : "0"}
                  </TableCell>
                  <TableCell className={style.tableCell}>
                    {
                     row.observation ?
                        row.observation.length > 50
                        ?
                          <Tooltip title={row.observation} arrow>
                            <Typography variant='caption' fontSize={'12px'}>{String(row.observation).slice(0, 50) + '...'}</Typography>
                          </Tooltip>
                        :
                          <Typography variant='caption' fontSize={'12px'}>{row.observation}</Typography>

                        :
                        "---"
                    }
                  </TableCell>
                </TableRow>
              ))
              :
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                    {t('remote_checkin.dashboard.table.no_data')}
                  </Typography>
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
