import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box } from '@mui/material';
import './TeamsCollapsibleTable.scss';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import IconExpand from '../../_icons/IconExpand';
import { secondsToHm } from '../../../_helpers';
import { UserCircle } from 'phosphor-react';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '300px',
  },
}))(Tooltip);

const CustomColoredTooltip = ({ backgroundColor, children, ...props }) => {
  const ColoredTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: backgroundColor,
      maxWidth: '300px',
    },
  }))(CustomTooltip);

  return (
    <ColoredTooltip {...props}>
      {children}
    </ColoredTooltip>
  );
};

function TreeNode({ index, node, hover = true }) {
  const [open, setOpen] = useState(false);

  const hasChildren = node.children && node.children.length > 0;

  const toggleNode = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        className={`
      ${hover && "hover"} 
      ${open ? 'table-row focus ' : ''}
      ${parseInt(index) % 2 === 0 ? 'bgColorGray' : '#FFFFFF'}
      row-main
    `}
      >
        <TableCell>{node.name}</TableCell>
        <TableCell style={{ width: '20%' }}>
          <div className='boxProductiveTimeCell'>
            <div className={`total-productive`}>
              {secondsToHm(node.totalWorkedTime, false)}
            </div>
            <CustomColoredTooltip
              key={index}
              title={
                <div style={{ padding: '6px' }}>
                  <span style={{ fontSize: '16px', fontWeight: '500' }}>{node.work_percent + '%'}</span>
                  <span style={{ fontSize: '14px', fontWeight: '400' }}>&nbsp;{node.reach_work_target ? 'Meta atingida' : 'Meta não atingida'}</span>
                </div>
              }
              backgroundColor={node.reach_work_target ? '#22BEAD' : '#FF7A90'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              }}
              interactive>
              <div className={`progress-column-container `}>
                <div className={`bar-progress ${node.reach_work_target ? 'completed' : 'late'}`} style={{ width: `${node.work_percent}%` }}></div>
              </div>
            </CustomColoredTooltip>
          </div>
        </TableCell>
        <TableCell style={{ width: '20%' }}>
          <div className='boxProductiveTimeCell'>
            <div className={`total-productive`}>
              {secondsToHm(node.totalProductiveTime, false)}
            </div>
            <CustomColoredTooltip
              key={index}
              title={
                <div style={{ padding: '6px' }}>
                  <span style={{ fontSize: '16px', fontWeight: '500' }}>{node.productive_percent + '%'}</span>
                  <span style={{ fontSize: '14px', fontWeight: '400' }}>&nbsp;{node.reach_productive_target ? 'Meta atingida' : 'Meta não atingida'}</span>
                </div>
              }
              backgroundColor={node.reach_productive_target ? '#22BEAD' : '#FF7A90'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              }}
              interactive>
              <div className={`progress-column-container `}>
                <div className={`bar-progress ${node.reach_productive_target ? 'completed' : 'late'}`} style={{ width: `${node.productive_percent}%` }}></div>
              </div>
            </CustomColoredTooltip>
          </div>
        </TableCell>
        <TableCell style={{ width: '14%', textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <UserCircle size={16} color='#4F7091' />
            </div>
            <div style={{ fontSize: '12px', color: '#4F7091' }}>
              &nbsp;{node.total_colabs}
            </div>
          </div>
        </TableCell>
        <TableCell
          key={'options'}
          style={{ width: '4%' }}

        >
          {hasChildren &&
            <div
              style={{ display: 'flex', alignItems: 'center', marginRight: 1, gap: 3 }}
              className={`actions`}
            >
              <div style={{ marginTop: '-5px' }}>
                <IconButton
                  className={`button-expand ${open ? 'rotate focus' : ''}`}
                  aria-label="expand row"
                  size="small"
                  onClick={toggleNode}
                >
                  {open ? <IconExpand /> : <IconExpand />}
                </IconButton>
              </div>
            </div>
          }
        </TableCell>
      </TableRow>
      {hasChildren && (
        <TableRow
          className={`
            ${open ? 'table-row focusBorder' : ''}
          `}
        >
          <TableCell colSpan={5} style={{ padding: '0px' }}>
            <Collapse in={open}>
              <Table size="small">
                <TableBody>
                  {node.children.map((child, index) => (
                    <TreeNode index={index} key={child.id} node={{ ...child, depth: (node.depth || 0) + 1 }} />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function TeamsCollapsibleTable({ data }) {
  const { t, i18n } = useTranslation('common');

  const [openAll, setOpenAll] = useState(false)


  return (
    <TableContainer style={{ maxHeight: '420px' }}>
      <Table size="small" className='teamsCollapsibleTable'>
        <TableHead>
          <TableRow style={{ textTransform: 'uppercase' }}>
            <TableCell style={{ color: '#98ACC1', fontSize: 10 }}>{t('widgets.widget10.col_team')}</TableCell>
            <TableCell align='left' style={{ width: '20%', color: '#98ACC1', fontSize: 10 }}>{t('widgets.widget10.col_worked_time')}</TableCell>
            <TableCell align='left' style={{ width: '20%', color: '#98ACC1', fontSize: 10 }}>{t('widgets.widget10.col_productive_time')}</TableCell>
            <TableCell align='center' style={{ width: '14%', color: '#98ACC1', fontSize: 10 }}>{t('widgets.widget10.col_colabs')}</TableCell>
            <TableCell style={{ width: '0%', minWidth: '0%', textAlign: 'end' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((node, index) => (
            <TreeNode index={index} key={node.group_id} node={node} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TeamsCollapsibleTable;
