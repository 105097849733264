import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import RealTimeFullSkeleton from '../_components/reports/realtime/RealTimeFullSkeleton'
import Moment from 'moment';

import { useTranslation } from "react-i18next";


import RealTimeUserDetails from '../_components/reports/realtime/RealTimeUserDetails';
import { useParams } from 'react-router-dom';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';
import { bindActionCreators } from 'redux';
import { shareActions } from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';


function ReportsRealTimePage({filter, share, shareActions}) {
	const { t, i18n } = useTranslation('common');

	let { machine_user_id } = useParams();

	const [userRealtimeInfos, setUserRealtimeInfos] = useState(null)
	const [isLoading, setIsLoading] = useState(true);

	let todayDate = Moment();
	let startDate = Moment(todayDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
	let endDate = Moment(todayDate).format('YYYY-MM-DD');

	async function getUserRealTimeDetails() {
		try {
			setIsLoading(true)
			let dateStart = startDate;
			let dateEnd = endDate;

			if (filter.date) {
				dateStart = filter.date.startDate;
				dateEnd = filter.date.endDate;
			}

			const response = await api.get(`/stats/realtime/users/${machine_user_id}?dateStart=${dateStart}&dateEnd=${dateEnd}`, {
				headers: authHeader()
			})

			setUserRealtimeInfos(response.data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error.response)
		}
	}

	
	function closeShareModal() {
		shareActions.closeModal()
	}

	useEffect(() => {
		getUserRealTimeDetails()
	}, [filter?.date])

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.realtime');
	});

	return (
		<React.Fragment>
			<section className="mainContent">
				<Toolbar />
				<Filterbar
					filterWho={'disabled'}
					filterWhen={'multi'}
					filterWhat={'disabled'}
					startDate={startDate}
					endDate={endDate}
					routeBackToPage={'/productive/reports/realtime'}
					showShareButton={true}
				/>
				
				{/* PAGE STARTS */}
				<div className="mainPage">
					<div className="column single">
						{isLoading 
							? (
								<RealTimeFullSkeleton />
							) : (
								<RealTimeUserDetails data={userRealtimeInfos}/>
							)
						}
					</div>

				</div>
				{/* PAGE ENDS */}
			</section>

			<div className={'overlay' + (share.modal ? ' open' : '')} onClick={() => {
				closeShareModal()
			}}></div>
			<section className={'modal' + (share.modal ? ' open' : '')}>
				<ShareModal reports='realtimeColabDetails' filters={{
					date:{
						startDate: filter.date ? filter.date.startDate : startDate,
						endDate:  filter.date ? filter.date.endDate : endDate
					},
					machine_user_id: machine_user_id
				}} />
			</section>

		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {		
		shareActions: bindActionCreators(shareActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		filter: state.filter,
		share: state.share
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsRealTimePage)
