import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useTranslation, initReactI18next } from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function InvitationsSkeleton(props) {
    const { t, i18n } = useTranslation('common');


    return (


        <div className="box loading mood">


            <div className="pageHeader">
                <h3><Skeleton variant="text" width="200px" height={17} /></h3>
                <div className="internalFilters quickView">
                    <div className="filter">
                        <div className="icon happy">
                            <Skeleton variant="circle" width={32} height={32} />
                        </div>
                        <div className="info">
                            <Skeleton variant="text" width="80px" height={17} />
                        </div>

                    </div>
                    <div className="filter">
                        <div className="icon ok">
                            <Skeleton variant="circle" width={32} height={32} />
                        </div>
                        <div className="info">
                            <Skeleton variant="text" width="80px" height={17} />
                        </div>

                    </div>
                    <div className="filter">
                        <div className="icon sad">
                            <Skeleton variant="circle" width={32} height={32} />
                        </div>
                        <div className="info">
                            <Skeleton variant="text" width="80px" height={17} />
                        </div>

                    </div>
                </div>
            </div>
            <div className="dataTable">
                <Skeleton variant="rect" width="100%" height={447} />

            </div>


        </div>
    )
};

export default InvitationsSkeleton;