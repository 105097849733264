import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'


import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import Invitations from '../_components/backfective/Invitations'
import InvitationsSkeleton from '../_components/backfective/InvitationSkeleton'

import {useTranslation, initReactI18next} from "react-i18next";


import {backfectiveActions} from '../_actions';

import IconClose from '../_components/_icons/IconClose';

function BackfectivePageInvitations(props) {
    const {t, i18n} = useTranslation('common');

    const {backfectiveActions} = props;

    let loading = props.loading;
    let filter = props.filter || {};


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);


    useEffect(() => {

        backfectiveActions.getInvitations();

    }, []);


    useEffect(() => {
        document.title = 'Backfective | ' + t('menu.backfective.invitations');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>

                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading || !props.invitations
                                ? <InvitationsSkeleton/>
                                : <Invitations/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        backfectiveActions: bindActionCreators(backfectiveActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        loading: state.backfective.loading,
        invitations: state.backfective.invitations,
        filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(BackfectivePageInvitations)
