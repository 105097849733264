import { Skeleton } from '@mui/material';
import React from 'react';


import './styles.scss';

export function ListEventsCalendarSkeleton(){
  
  return (
    <div className='container-card-events-skeleton'>
      <Skeleton variant="rounded" width="100%" height={90} style={{borderRadius: '4px'}}/>
      <Skeleton variant="rounded" width="100%" height={90} style={{borderRadius: '4px'}}/>
      <Skeleton variant="rounded" width="100%" height={90} style={{borderRadius: '4px'}}/>
    </div>
  );
}
