import React from 'react';

const IconActivities = props => {
    return (
        
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_11" data-name="Retângulo 11" width="24" height="24" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(1 4)">
            <g id="Grupo_3" data-name="Grupo 3">
            <path id="Caminho_11" data-name="Caminho 11" d="M23,8H3A1,1,0,0,0,2,9v7a1,1,0,0,0,1.15,1A1.05,1.05,0,0,0,4,15.95V10H22V22H17a1,1,0,0,0-1,1.15,1.05,1.05,0,0,0,1.05.85H23a1,1,0,0,0,1-1V9A1,1,0,0,0,23,8Z" transform="translate(-2 -8)" fill="#97b3bf"/>
            <path id="Caminho_12" data-name="Caminho 12" d="M2,30v3a1.05,1.05,0,0,0,1.1,1h9.8A1.05,1.05,0,0,0,14,33V30a10.25,10.25,0,0,0-6-2,10.25,10.25,0,0,0-6,2Zm10,2H4v-.9a7.8,7.8,0,0,1,8,0Z" transform="translate(-2 -18)" fill="#97b3bf"/>
            <path id="Caminho_13" data-name="Caminho 13" d="M27,18h5a1,1,0,0,0,0-2H27a1,1,0,0,0,0,2Z" transform="translate(-14 -12)" fill="#97b3bf"/>
            <path id="Caminho_14" data-name="Caminho 14" d="M33,25a1,1,0,0,0-1-1H27a1,1,0,0,0,0,2h5A1,1,0,0,0,33,25Z" transform="translate(-14 -16)" fill="#97b3bf"/>
            <path id="Caminho_15" data-name="Caminho 15" d="M14,17a3,3,0,1,0-3,3A3,3,0,0,0,14,17Zm-4,0a1,1,0,1,1,1,1A1,1,0,0,1,10,17Z" transform="translate(-5 -11)" fill="#97b3bf"/>
            </g>
        </g>
        </svg>
 

    )
}

export default IconActivities;