import React, { useState } from "react";
import Select from 'react-select'
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, CircularProgress, makeStyles, Modal, Slide } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader } from "../../_helpers/auth-header";
import "./ModalCreateWorkspace.scss"

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ModalEditWorkspace({
  openModalEditWorkspace,
  setOpenModalEditWorkspace,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo,
  currentWorkspace
}) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [colabs, setColabs] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workspaceTypes, setWorkspaceTypes] = useState([])

  const [form, setForm] = useState({
    name: currentWorkspace?.title || '',
    description: currentWorkspace?.description || '',
    colabs: currentWorkspace?.members,
    type_id: currentWorkspace?.type_id || ''
  });

  const [formError, setFormError] = useState({
    name: '',
    description: '',
    colabs: '',
    type_id: ''
  });

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function closeModalEditWorkspace() {
    setOpenModalEditWorkspace(false)
    setForm({
      name: currentWorkspace?.title || '',
      description: currentWorkspace?.description || '',
      colabs: currentWorkspace?.members,
      type_id: currentWorkspace?.type_id || ''
    })
    setFormError({
      name: '',
      description: '',
      colabs: '',
      type_id: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.name) {
      errors.name = t('project_management.validations.required_field')
    } else if (form.name.length < 4) {
      errors.name = t('project_management.validations.len_field_error_4')
    }

    if (!form.type_id) {
      errors.type_id = t('project_management.validations.required_field')
    }

    return errors
  }

  const handleSubmitWorkspace = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const createWorkspace = async () => {
    try {
      setIsSubmitting(true)
      console.log(form)
      await api.put(`/project-management/workspaces/${currentWorkspace.id}`, {
        ...form,
        title: form.name,
        members: form?.colabs.join(',')
      }, {
        headers: authHeader()
      })
      updateInfo()
      alertActions.success(t('project_management.success.updated_workspace'))
      closeModalEditWorkspace()
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      //alertActions.error(t('project_management.errors.updated_workspace'))
    }
  }


  useEffect(() => {
    handleChange({ target: { name: 'colabs', value: colabs.map(dest => dest.value) } })
  }, [colabs])

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      createWorkspace()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  const getWorkspaceTypes = async () => {
    try {

      const response = await api.get(`/project-management/workspace-types`, {
        headers: authHeader()
      })
      setWorkspaceTypes(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(error)
    }
  }

  useEffect(() => {
    getWorkspaceTypes()
    setForm({
      ...form,
      colabs: currentWorkspace?.members?.map(item => item.value)
    })
  }, [])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`modalWorkspace ${classes.modal}`}
        open={openModalEditWorkspace}
        onClose={closeModalEditWorkspace}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalEditWorkspace} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <div className={"containerModalWorkspace"}>

            <button onClick={() => { closeModalEditWorkspace() }} className="close"><IconClose /></button>

            <form onSubmit={handleSubmitWorkspace}>
              <main>
                <header>
                  <h3>
                    {t('project_management.workspaces.title_header_edit')}
                  </h3>
                  <p>{t('project_management.workspaces.label_header_helper')}</p>
                </header>
                <div className="field">
                  <label htmlFor="name">{t('project_management.workspaces.field_name')}*</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    minLength={4}
                    maxLength={100}
                    placeholder={t('project_management.workspaces.placeholder_name')}
                    value={form.name || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.name.length}/100</span>
                  {formError.name &&
                    <div className="error">{formError.name}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="type_id">{t('project_management.workspaces.field_type')}*</label>
                  <select name="type_id" id="type_id" value={form.type_id || ''} onChange={handleChange} style={{ fontSize: form.type_id === '' ? '12px' : '14px', color: form.type_id === '' && '#A4B3CD' }}>
                    <option value="" selected hidden disabled >{t('project_management.workspaces.select')}</option>
                    {
                      workspaceTypes?.length > 0 &&
                      workspaceTypes.map((wt) => <option key={wt.id} style={{ color: '#1E6B7F' }} value={wt.id}>{wt.title}</option>)
                    }
                    {/* <option style={{color: '#1E6B7F'}} value="marketing">{t('project_management.workspaces.types.marketing')}</option>
                      <option style={{color: '#1E6B7F'}} value="education">{t('project_management.workspaces.types.education')}</option>
                      <option style={{color: '#1E6B7F'}} value="crm_salles">{t('project_management.workspaces.types.crm_salles')}</option>
                      <option style={{color: '#1E6B7F'}} value="tecnology">{t('project_management.workspaces.types.tecnology')}</option>
                      <option style={{color: '#1E6B7F'}} value="rh">{t('project_management.workspaces.types.rh')}</option>
                      <option style={{color: '#1E6B7F'}} value="operations">{t('project_management.workspaces.types.operations')}</option>
                      <option style={{color: '#1E6B7F'}} value="others">{t('project_management.workspaces.types.others')}</option> */}
                  </select>

                  {formError.type_id &&
                    <div className="error">{formError.type_id}</div>
                  }
                </div>


                <div className="field">
                  <label htmlFor="colabs">{t('project_management.workspaces.field_members')}</label>
                  <Select
                    id='colabs'
                    isMulti
                    defaultValue={currentWorkspace?.members}
                    name="colabs"
                    options={colabsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('project_management.workspaces.select')}
                    onChange={setColabs}
                  />

                  {formError.colabs &&
                    <div className="error">{formError.colabs}</div>
                  }
                </div>

                <div className="field">
                  <label htmlFor="description">{t('project_management.workspaces.field_description')}</label>
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="5"
                    minLength={8}
                    maxLength={600}
                    placeholder={t('project_management.workspaces.placeholder_description')}
                    value={form.description || ''}
                    onChange={handleChange}
                  />
                  <span className="text-limit">{form.description.length}/600</span>
                  {formError.description &&
                    <div className="error">{formError.description}</div>
                  }
                </div>


                <footer>
                  <div className="buttonActionsGroup">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={() => { closeModalEditWorkspace() }}
                    >
                      {t('project_management.boards.modal.cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={15} /> : t('project_management.boards.modal.save')}
                    </button>
                  </div>
                </footer>
              </main>
            </form>

          </div>
        </Slide>

      </Modal>

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalEditWorkspace));
