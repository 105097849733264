import React from 'react'

export default function IconModuleGoalManagement({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="objetivos" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1362" data-name="Retângulo 1362" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(1.875 0.744)">
            <path id="Caminho_54" data-name="Caminho 54" d="M12.126,5.74h0a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,.75.75h0a.75.75,0,0,1-.75.75h-6A.75.75,0,0,1,12.126,5.74Zm.75,5.25h6a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75h-6a.75.75,0,0,0-.75.75h0A.75.75,0,0,0,12.876,10.99Zm0,4.5h6a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75h-6a.75.75,0,0,0-.75.75h0A.75.75,0,0,0,12.876,15.49Zm-6-4.5h.75a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75h-.75a.75.75,0,0,0-.75.75h0A.75.75,0,0,0,6.876,10.99Zm0,4.5h.75a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75h-.75a.75.75,0,0,0-.75.75h0A.75.75,0,0,0,6.876,15.49Zm.9-8.475,3.75-3.75a.713.713,0,0,0,0-1.05.712.712,0,0,0-1.05,0L7.251,5.44l-.975-.975a.713.713,0,0,0-1.125.075.788.788,0,0,0,.075,1.013l1.5,1.462a.712.712,0,0,0,1.05,0Z" transform="translate(-5.001 -1.985)" fill="#106276" />
          </g>
        </g>
      </g>
    </svg>
  )
}
