// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tPRJZpxdU-6GYh4Y0-ChpQ\\=\\= {\n  background-color: #fff;\n  width: 100%;\n  padding: 8px;\n}\n\n.MpqQr4MFKd4YcxdEBwtsTA\\=\\= {\n  border-collapse: collapse;\n  width: 100%;\n}\n.MpqQr4MFKd4YcxdEBwtsTA\\=\\= thead tr th {\n  text-align: left;\n  color: #24677E;\n  font-size: 12px;\n  font-weight: 400;\n  padding: 18px 0;\n}\n.MpqQr4MFKd4YcxdEBwtsTA\\=\\= tbody tr:nth-child(odd) {\n  background-color: #F5F6F9;\n}\n.MpqQr4MFKd4YcxdEBwtsTA\\=\\= tbody tr td {\n  font-size: 14px;\n  color: #24677E;\n  padding: 10px 0;\n}\n.MpqQr4MFKd4YcxdEBwtsTA\\=\\= tbody tr td svg {\n  width: 14px;\n  height: 14px;\n  margin-top: 2px;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/realtime/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;AAGM;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AADR;AAQM;EACE,yBAAA;AANR;AAQM;EACE,eAAA;EACA,cAAA;EACA,eAAA;AANR;AAQQ;EACE,WAAA;EACA,YAAA;EACA,eAAA;AANV","sourcesContent":[".tableContainer {\n  background-color: #fff;\n  width: 100%;\n  padding: 8px;\n}\n\n.table {\n  border-collapse: collapse;\n  width: 100%;\n\n  thead {\n    tr {\n      th {\n        text-align: left;\n        color: #24677E;\n        font-size: 12px;\n        font-weight: 400;\n        padding: 18px 0;\n      }\n    }\n  }\n\n  tbody {\n    tr {\n      &:nth-child(odd) {\n        background-color: #F5F6F9;\n      }\n      td {\n        font-size: 14px;\n        color: #24677E;\n        padding: 10px 0;\n\n        svg {\n          width: 14px;\n          height: 14px;\n          margin-top: 2px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "tPRJZpxdU-6GYh4Y0-ChpQ==",
	"table": "MpqQr4MFKd4YcxdEBwtsTA=="
};
export default ___CSS_LOADER_EXPORT___;
