import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import BoardCard from "../../_components/project_management/BoardCard";
import { Grid, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import ModalCreateBoard from "../../_components/project_management/ModalCreateBoard";
import ModalCreateWorkspace from "../../_components/project_management/ModalCreateWorkspace";

function ProjectManagementBoardsPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, usersActions, responsibles } = props
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalCreateBoard, setOpenModalCreateBoard] = useState(false)
  const [openModalCreateWorkspace, setOpenModalCreateWorkspace] = useState(false)
  const [workspaces, setWorkspaces] = useState([])

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getWorkspaces = async () => {
    try {
      const { data } = await api.get(`/project-management/workspaces`, {
        headers: authHeader()
      })
      setWorkspaces(data)
    } catch (error) {
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  const getBoards = async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/project-management/boards', {
        headers: authHeader()
      })
      setBoards(data)
      setLoading(false)
    } catch (error) {
      console.error(error.response)
      //alertActions.error(t('project_management.errors.load'))
      setLoading(false)
    }
  }

  useEffect(() => {
    getBoards()
    usersActions.getResponsibles(userDetails?.organizationId)
    getWorkspaces()
  }, [])

  return (
    <section className="mainContent productivity">

      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        buttonCreateBoard={true}
        setOpenModalCreateBoard={setOpenModalCreateBoard}
        setOpenModalCreateWorkspace={setOpenModalCreateWorkspace}
        filterWorkspace={true}
        filterRecentBoards={true}
      />

      <div className={style.containerBoardsPage}>
        {
          !loading
            ?
            (
              boards.length > 0
                ?
                <BoardCard
                  boards={boards}
                  userDetails={userDetails}
                  colabs={responsibles}
                  alertActions={alertActions}
                  getBoards={getBoards}
                  workspaces={workspaces}
                />
                :
                <Typography variant="caption">Nenhum quadro no momento</Typography>

            )
            :
            <Grid container spacing={2}>
              {
                Array.from(new Array(3)).map((_, index) => (
                  <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={3} item>
                    <Skeleton variant="rectangular" width={'100%'} height={70} />
                  </Grid>
                ))
              }
            </Grid>
        }
      </div>

      <ModalCreateBoard
        openModalCreateBoard={openModalCreateBoard}
        setOpenModalCreateBoard={setOpenModalCreateBoard}
        userDetails={userDetails}
        colabsOptions={responsibles}
        alertActions={alertActions}
        updateInfo={getBoards}
        workspaces={workspaces}
      />

      <ModalCreateWorkspace
        openModalCreateWorkspace={openModalCreateWorkspace}
        setOpenModalCreateWorkspace={setOpenModalCreateWorkspace}
        userDetails={userDetails}
        colabsOptions={responsibles}
        alertActions={alertActions}
        updateInfo={getBoards}
      />

    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementBoardsPage)