import React from 'react';

const IconHelp = props => {
    return (
        
     
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path id="question" d="M16.563,8.563a8,8,0,1,1-8-8A8,8,0,0,1,16.563,8.563ZM8.777,3.208a4.178,4.178,0,0,0-3.76,2.057.388.388,0,0,0,.088.524l1.119.849a.387.387,0,0,0,.538-.068c.576-.731.971-1.155,1.848-1.155.659,0,1.474.424,1.474,1.063,0,.483-.4.731-1.049,1.1C8.276,8,7.272,8.528,7.272,9.853v.129a.387.387,0,0,0,.387.387H9.466a.387.387,0,0,0,.387-.387V9.939c0-.918,2.683-.956,2.683-3.441C12.536,4.627,10.6,3.208,8.777,3.208Zm-.215,8a1.484,1.484,0,1,0,1.484,1.484A1.486,1.486,0,0,0,8.563,11.208Z" transform="translate(-0.563 -0.563)" fill=""/>
        </svg>



 

    )
}

export default IconHelp;