import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";

import IconStart from "../_icons/IconStart";
import {secondsToHm} from "../../_helpers";


function WorkGoalProductivity(props) {
    const {t, i18n} = useTranslation('common');

    let productivity = props.productivity || {};

    let Target = 100;

    if (productivity.user && productivity.user.target_percent) {
        Target = productivity.user.target_percent;
    }

    let PercentWorkGoal = 0;
    let WorkGoalHelper = 'Ω';

    if (productivity.TotalExpectedProductiveTime && productivity.TotalExpectedProductiveTime > 0 && productivity.TotalProductiveTime && productivity.TotalProductiveTime > 0) {
        PercentWorkGoal = ((productivity.TotalProductiveTime / productivity.TotalExpectedProductiveTime) * 100).toFixed(2);
    } else if (productivity.TotalExpectedProductiveTime == 0) {

        PercentWorkGoal = 0;
    }

    if (productivity.TotalExpectedProductiveTime > 0) {
        WorkGoalHelper = '( '+secondsToHm(productivity.TotalProductiveTime) + ' / ' + secondsToHm(productivity.TotalExpectedProductiveTime)+` ) `;
    }

    return (
        <div className={'box goal'}>
            <h3>{t('productivity.work_goal')}</h3>
            <div
                className={'helper'}>{t('productivity.helper_work_goal1')} {Target}% {t('productivity.helper_work_goal2')}</div>

            <div className={'barchart'}>
                <div className={'bar work'} style={{width: PercentWorkGoal + '%'}}></div>
            </div>
            <div className={'helperChart'}> 
                {WorkGoalHelper}
                {
                    productivity?.TotalExpectedProductiveTime > 0 && <span style={{marginLeft:4, color: '#22BEAD', fontWeight: '500'}}> {PercentWorkGoal + '%'} </span>
                }
                
            </div>
        </div>

    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(WorkGoalProductivity)
