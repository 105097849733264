// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mGN5EaIXkZ1c6AW8pjXYeA\\=\\= {\n  cursor: pointer;\n  transition: all 0.2s;\n  position: relative;\n}\n.mGN5EaIXkZ1c6AW8pjXYeA\\=\\= > * {\n  transition: all 0.2s;\n}\n.mGN5EaIXkZ1c6AW8pjXYeA\\=\\=:hover {\n  transform: scale(1.025);\n  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.5);\n}\n.mGN5EaIXkZ1c6AW8pjXYeA\\=\\=:hover span {\n  color: #22BEAD;\n}\n.mGN5EaIXkZ1c6AW8pjXYeA\\=\\=:hover > * {\n  transform: scale(1);\n}\n.mGN5EaIXkZ1c6AW8pjXYeA\\=\\= svg {\n  width: inherit;\n  height: inherit;\n}\n\n.BkY1Jw6D-CdaWvFJPY7GXg\\=\\= {\n  position: absolute !important;\n  left: 4px;\n  top: 4px;\n}\n.BkY1Jw6D-CdaWvFJPY7GXg\\=\\= svg {\n  width: 30px;\n  height: 30px;\n  font-size: 16px;\n}", "",{"version":3,"sources":["webpack://./src/_components/switch_page/CardModule.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;EACA,kBAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,uBAAA;EACA,+CAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,cAAA;EACA,eAAA;AAHJ;;AAOA;EACE,6BAAA;EACA,SAAA;EACA,QAAA;AAJF;AAKE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAHJ","sourcesContent":[".modulesCard {\n  cursor: pointer;\n  transition: all 0.2s;\n  position: relative;\n\n  &>* {\n    transition: all 0.2s;\n  }\n\n  &:hover {\n    transform: scale(1.025);\n    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.5);\n\n    span {\n      color: #22BEAD;\n    }\n  }\n\n  &:hover>* {\n    transform: scale(1);\n  }\n\n  svg {\n    width: inherit;\n    height: inherit;\n  }\n}\n\n.buttonFavorite{\n  position: absolute !important;\n  left: 4px;\n  top: 4px;\n  svg{\n    width: 30px;\n    height: 30px;\n    font-size: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modulesCard": "mGN5EaIXkZ1c6AW8pjXYeA==",
	"buttonFavorite": "BkY1Jw6D-CdaWvFJPY7GXg=="
};
export default ___CSS_LOADER_EXPORT___;
