import { Box, Stack, Typography } from '@mui/material'
import { DotsNine } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IconMainLogoV2 from '../../_components/_icons/IconMainLogoV2'
import { CardModule } from '../../_components/switch_page/CardModule'
import Header from '../../_components/switch_page/Header'

import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { usersActions } from '../../_actions'
import { redirectToModule } from '../../_helpers/redirectToModule'
import { useCurrentModule } from '../../contexts/CurrentModuleContext'
import style from './SwitchPageOld.module.scss'
import { history } from '../../_helpers'

function SwitchPageOld({ userDetails, usersActions }) {
  const { t } = useTranslation('common');

  const { removeCurrentModule, saveFirstAccess, getFirstAccess } = useCurrentModule()


  const modules = userDetails.modules || []
  const initialScreen = userDetails.initialScreen
  const defaultModule = userDetails.defaultModule

  useEffect(() => {
    const firstAccess = localStorage.getItem('befective:firstAccess');
    if (defaultModule &&  firstAccess == 'true') {
      saveFirstAccess(false)
      if (userDetails.roleName !== 'collaborator') {
        redirectToModule(defaultModule, initialScreen)
      } else{
        if(defaultModule === 'productivity_management'){
          history.push('/productive/productivity/')
        }else{
          redirectToModule(defaultModule, initialScreen)
        }
      }
    }
    
    document.title = 'Befective | Home ';

  }, [])


  return (
    <Box
      bgcolor="#F5F6F9"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Header />

      <Box
        mt={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box className={style.logo}>
          <IconMainLogoV2 />
        </Box>
        {!modules && 
            (<Typography component="h1" fontSize={17} fontWeight={600} color="#1E6B7F">
              {t('switch.no_modules')}
            </Typography>)
          }
        {modules && 
        <>
          <Typography mt="8px" component="h2" fontSize={22} fontWeight={700} color="#1E6B7F">
            {t('switch.select_options')}
          </Typography>

          <Stack
            maxWidth={1000}
            flexWrap="wrap"
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt="35px"
            sx={{
              gap: { sm: '10px', xl: '14px' },
              mt: { sm: '35px', xl: '45px' },
              mb: { sm: '60px', xl: '70px' }
            }}
          >
            {(modules && modules.length > 0) && modules.map((module) => (
              <CardModule key={module.id} module={module} initialScreen={initialScreen} userDetails={userDetails} usersActions={usersActions} />
            ))}
          </Stack>
          <Box display="flex" alignItems="center" mb={10}>
            <Typography component="h2" fontSize={14} fontWeight={400} color="#1E6B7F">
              {t('switch.switch_module')}
            </Typography>
            <span>
              <DotsNine color="#1E6B7F" size={20} weight='bold' style={{ marginTop: '4px' }} />
            </span>
            <Typography component="h2" fontSize={14} fontWeight={400} color="#1E6B7F">
              {t('switch.switch_module_complement')}
            </Typography>
          </Box>
        </>

        }
      </Box >

    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SwitchPageOld));