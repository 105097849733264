import React from 'react';

const IconMyActivities = props => {
  return (

    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1441" data-name="Retângulo 1441" width="24" height="24" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(0.833 0.833)">
        <path id="Caminho_365" data-name="Caminho 365" d="M11.167,3.667a7.5,7.5,0,1,1-7.5,7.5,7.5,7.5,0,0,1,7.5-7.5m0-1.667a9.125,9.125,0,1,0,6.494,2.673A9.167,9.167,0,0,0,11.167,2Z" transform="translate(-2 -2)" fill="#cedee3" />
        <path id="Caminho_366" data-name="Caminho 366" d="M14.751,22.367l-2.5-2.458a.875.875,0,0,1-.083-1.125.792.792,0,0,1,1.25-.083l1.917,1.917,5.25-5.25a.833.833,0,0,1,1.167,1.167l-5.833,5.833a.792.792,0,0,1-1.167,0Z" transform="translate(-7.834 -9.7)" fill="#cedee3" />
      </g>
    </svg>
  )
}

export default IconMyActivities;