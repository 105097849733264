import { Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDayWeek } from '../../../_helpers';
import IconMoon from '../../_icons/IconMoon';
import IconSun from '../../_icons/IconSun';
import IconTeammate from '../../_icons/IconTeammate';
import style from './CardsHeader.module.scss';
import IconRoomAvailable from '../../_icons/IconRoomAvailable';
import { Clock, XCircle } from 'phosphor-react';
import IconRoomMostReservation from '../../_icons/IconRoomMostReservation';
import IconRoomDay from '../../_icons/IconRoomDay';

export function CardsHeaderRoomReportsAdmin({ alertActions, userDetails, resume }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer}>
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: `3px solid #22BEAD` }}>
          <IconRoomAvailable />
          <Typography color={"#22BEAD"} component="span" fontSize={20} mt={1}>
            {resume?.total_reservations}
          </Typography>
          <Typography color={"#22BEAD"} component="span" fontSize={12}>
            {t('spaces.total_reservations')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #FC6662' }}>
          <XCircle size={18} color="#FC6662" />
          <Typography color="#FC6662" component="span" fontSize={20} mt={1}>
            {resume?.total_reservations_cancelled}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('spaces.total_reservations_cancel')}
          </Typography>

        </CardContent>
      </Card>

      
      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
          <Clock size={18} color="#4F7091" />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {resume?.avg_hours_reservation}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('spaces.average_time_reservation')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #22BEAD' }}>
          <IconRoomMostReservation />
          <Typography color="#22BEAD" component="span" fontSize={20} mt={1}>
            {resume?.most_room_reservation}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('spaces.most_room')}
          </Typography>

        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '3px solid #4F7091' }}>
        <IconRoomDay />
          <Typography color="#4F7091" component="span" fontSize={20} mt={1}>
            {getDayWeek(resume?.most_day_reservation, true)}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('spaces.most_day')}
          </Typography>

        </CardContent>
      </Card>
    </Stack>
  );
}
