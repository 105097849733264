import React from 'react'

export default function IconModuleCheckInRemote({
  active
}) {

  const bgColor = active ? "#fdbe2b" : "#dbf5f2"

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="check" transform="translate(-274 -379)">
        <rect id="Retângulo_1572" data-name="Retângulo 1572" width="38" height="38" rx="4" transform="translate(274 379)" fill={bgColor} />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect id="Retângulo_1784" data-name="Retângulo 1784" width="18" height="18" fill="none" />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(2.625 0.75)">
            <path id="Caminho_7531" data-name="Caminho 7531" d="M19.425,11.5A1.125,1.125,0,1,1,18.3,12.625,1.125,1.125,0,0,1,19.425,11.5m0-1.5a2.625,2.625,0,1,0,2.625,2.625A2.625,2.625,0,0,0,19.425,10Z" transform="translate(-13.125 -7)" fill="#24677e" />
            <path id="Caminho_7532" data-name="Caminho 7532" d="M13.375,2A6.459,6.459,0,0,0,7,8.75c0,3.712,4.313,7.988,5.85,9.525a.713.713,0,0,0,1.05,0c1.538-1.538,5.85-5.738,5.85-9.525A6.415,6.415,0,0,0,13.375,2ZM8.5,8.75A4.96,4.96,0,0,1,13.375,3.5,4.96,4.96,0,0,1,18.25,8.75a7.615,7.615,0,0,1-1.462,3.862,7.182,7.182,0,0,0-6.825,0A7.615,7.615,0,0,1,8.5,8.75Zm4.875,7.95a36.188,36.188,0,0,1-2.512-2.85,5.55,5.55,0,0,1,5.025,0,36.188,36.188,0,0,1-2.512,2.85Z" transform="translate(-7 -2)" fill="#24677e" />
          </g>
        </g>
      </g>
    </svg>
  )
}
