import React from 'react';

const IconIn = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.714" height="12" viewBox="0 0 13.714 12">
      <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M5.143,0V1.714H12v8.571H5.143V12h8.571V0ZM6.857,3.429V5.143H0V6.857H6.857V8.571L10.286,6Z" fill={props.fill} />
    </svg>
  )
}

export default IconIn;