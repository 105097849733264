import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import "react-datepicker/dist/react-datepicker.css";
import {addDays, subYears} from 'date-fns';
import Moment from 'moment';
import useOutsideClick from "../../../_helpers/useOutsideClick";
import {filterActions} from '../../../_actions';
import {statsActions} from '../../../_actions';


import {useTranslation, initReactI18next} from "react-i18next";

import IconClear from '../../_icons/IconClear'


function WhenSingle(props) {
    const {t, i18n} = useTranslation('common');

    registerLocale('es', es)
    registerLocale('pt', pt)

    const {filterActions} = props;
    const {userDetails} = props

    let filter = props.filter || {};


    let limit = props.limit || null;


    let calendarLanguage = 'pt'

    if (userDetails && userDetails.language) {
        calendarLanguage = userDetails.language;
    } else {
        calendarLanguage = window.navigator.userLanguage;
    }


    const [minDate, setMinDate] = useState(props.minDate || null);
    const [maxDate, setMaxDate] = useState(props.maxDate || new Date());

    const [whenFilter, setShowWhenFilter] = useState(true);

    if (limit) {

        if (filter.date) {
            if (new Date(Moment(filter.date.startDate).add(+31, 'd')) < new Date()) {
                setMaxDate(new Date(Moment(filter.date.startDate).add(+31, 'd')));
            } else {
                setMaxDate(new Date());
            }

            setMinDate(new Date(Moment(filter.date.endDate).add(-31, 'd')));


        } else {
            setMinDate(new Date(Moment().add(-31, 'd')));
        }

    }


    const [ManualWhenValue, setManualWhenValue] = useState('');

    const refFilter = useRef();

    const [filterWhen, setWhen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useOutsideClick(refFilter, target => {


        if (filterWhen) {

            if (refFilter && !target.includes("react-datepicker") && !target.includes("column") && !target.includes("who") && !target.includes("input")) {

                if (filterWhen) {
                    setWhen(false);
                }

            }

        }
    });


    const renderDayContents = (day, date) => {
        return <span>{day}</span>;
    };

    function applyWhenFilter(date) {

        setStartDate(date)

        let path = window.location.pathname.split("/");


        let date1 = Moment(date).format('YYYY-MM-DD');


        filterActions.replace(date1, 'singleWhen')


        setWhen(false);

    }

    function quickWhenFilter(date1) {

        applyWhenFilter(date1);


    }

    function clearWhen() {

        let date1 = new Date();
        applyWhenFilter(date1);

    }

    function manualWhenFilter(e) {

        if (e.target.value.length >= 10) {

            if (e.keyCode == 13) {


                let toDateStart = ManualWhenValue.split("/");


                let NewStartDate = '';


                let date1 = '';


                let valid = true;

                if (toDateStart[2] > 2040) {
                    setManualWhenValue('');
                    return
                }

                if (i18n.language == 'en') {
                    date1 = toDateStart[2] + '-' + toDateStart[0] + '-' + toDateStart[1];

                } else {
                    date1 = toDateStart[2] + '-' + toDateStart[1] + '-' + toDateStart[0];

                }

                NewStartDate = new Date(date1 + "T12:00:00");


                if (new Date(date1) > new Date()) {
                    valid = false;
                }


                if (valid) {
                    applyWhenFilter(NewStartDate)
                } else {
                    setManualWhenValue('');
                }


            }
            if (e.keyCode == 8 || e.keyCode == 46) {
                setManualWhenValue(e.target.value.slice(0, -1));
            }

            return
        }

        if (e.keyCode != 8 && e.keyCode != 46) {

            if (e.keyCode >= 48 && e.keyCode <= 57) {

                let newValue = '';

                if (e.target.value.length == 2 || e.target.value.length == 5 || e.target.value.length == 15 || e.target.value.length == 18) {
                    newValue = e.target.value + "/" + e.key;
                } else if (e.target.value.length == 10) {
                    newValue = e.target.value + " > " + e.key;
                } else {
                    newValue = e.target.value + e.key;
                }

                setManualWhenValue(newValue);

            } else {

            }

        } else {
            setManualWhenValue(e.target.value.slice(0, -1));
        }


    }

    useEffect(() => {
        
        filterActions.clear('when')
        
        if (props.filter.singleDate) {
            let newDate = new Date(props.filter.singleDate + "T12:00:00");
            setStartDate(newDate)
        }


    }, [props.filter.singleDate]);


    return (

        <div className={filterWhen ? 'filter when open' : 'when filter'} ref={refFilter}>
            <div className="holder">
                <span className="label">{t('filter.when')}:</span>
                <span className="value" onClick={() => {
                    setWhen(!filterWhen);
                    setManualWhenValue('')
                }}>{Moment(startDate).format(t('date.mm/dd/yyyy'))}</span>
                <input className="input" value={ManualWhenValue} onKeyUp={(data) => {
                    manualWhenFilter(data);
                }}
                       placeholder={Moment(startDate).format(t('date.mm/dd/yyyy'))}/>
                <div className="clear" onClick={() => {
                    clearWhen()
                }}><IconClear/></div>
            </div>
            <div className="comp date">
                <div className="column date">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => applyWhenFilter(date)}
                        startDate={startDate}
                        //selectsRange
                        maxDate={maxDate}
                        minDate={minDate}
                        dateFormat="YYYY-MM-DD"
                        disabledKeyboardNavigation // <-- active-day bug is still open
                        inline
                        renderDayContents={renderDayContents}
                        locale={calendarLanguage}
                    />
                </div>


            </div>
        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),

    }
}


function mapStateToProps(state) {

    return {
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(WhenSingle)
