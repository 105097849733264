import React from 'react';

const IconPeriod = props => {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">

      <g id="icons_Q2" data-name="icons Q2" transform="translate(0.667 0.659)">
        <path id="Caminho_92" data-name="Caminho 92" d="M20.138,23.19a.633.633,0,0,0-.933,0l-2.867,2.867-1.2-1.2a.667.667,0,0,0-1.033.067.733.733,0,0,0,.133.9l1.633,1.633a.633.633,0,0,0,.933,0l3.333-3.333a.633.633,0,0,0,0-.933Zm0,0a.633.633,0,0,0-.933,0l-2.867,2.867-1.2-1.2a.667.667,0,0,0-1.033.067.733.733,0,0,0,.133.9l1.633,1.633a.633.633,0,0,0,.933,0l3.333-3.333a.633.633,0,0,0,0-.933Z" transform="translate(-10.005 -15.982)" fill="#98acc1" />
        <path id="Caminho_93" data-name="Caminho 93" d="M16,3.985H13v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985H7v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985h-3A.667.667,0,0,0,2,4.652V15.318a.667.667,0,0,0,.667.667H16a.667.667,0,0,0,.667-.667V4.652A.667.667,0,0,0,16,3.985Zm-.667,10.667h-12V7.985h12Zm0-8h-12V5.318h12Z" transform="translate(-2 -1.977)" fill="#98acc1" />
      </g>
    </svg>

  )
}

export default IconPeriod;