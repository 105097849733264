import {teamsConstants} from '../_constants';
import {teamsService} from '../_services';
import {alertActions} from '.';

export const teamsActions = {
    getTeams,
    postTeams,
    patchTeams,
    getTeamDetail,
    getTeamActivities,
    postActivitie,
    getTeamFocus,
    disableTeam,
    deleteActivitie
};

function getTeams(teams = null) {
    return dispatch => {
        dispatch(request());

        teamsService.getTeams(teams)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.GET_TEAMS_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.GET_TEAMS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.GET_TEAMS_FAILURE, error}
    }
}

function postTeams(form) {
    return dispatch => {
        dispatch(request());

        teamsService.postTeams(form)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.POST_TEAMS_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.POST_TEAMS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.POST_TEAMS_FAILURE, error}
    }
}

function patchTeams(form, id) {
    return dispatch => {
        dispatch(request());

        teamsService.patchTeams(form, id)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.PATCH_TEAMS_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.PATCH_TEAMS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.PATCH_TEAMS_FAILURE, error}
    }
}

function getTeamDetail(id) {
    return dispatch => {
        dispatch(request());

        teamsService.getTeamDetail(id)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.GET_TEAM_DETAIL_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.GET_TEAM_DETAIL_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.GET_TEAM_DETAIL_FAILURE, error}
    }
}

function getTeamActivities(id) {
    return dispatch => {
        dispatch(request());

        teamsService.getTeamActivities(id)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.GET_TEAM_ACTIVITIES_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.GET_TEAM_ACTIVITIES_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.GET_TEAM_ACTIVITIES_FAILURE, error}
    }
}

function getTeamFocus(id) {
    return dispatch => {
        dispatch(request());

        teamsService.getTeamFocus(id)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.GET_TEAM_FOCUS_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.GET_TEAM_FOCUS_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.GET_TEAM_FOCUS_FAILURE, error}
    }
}

function disableTeam(id) {
    return dispatch => {
        dispatch(request());

        teamsService.disableTeam(id)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.DELETE_TEAM_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.DELETE_TEAM_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.DELETE_TEAM_FAILURE, error}
    }
}

function deleteActivitie(teamId, trackedId) {
    return dispatch => {
        dispatch(request());

        teamsService.deleteActivitie(teamId, trackedId)
            .then(
                payload => {

                    dispatch(success(payload))
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.DELETE_TEAM_ACTIVITIE_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.DELETE_TEAM_ACTIVITIE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.DELETE_TEAM_ACTIVITIE_FAILURE, error}
    }
}


function postActivitie(form, teamId) {
    return dispatch => {
        dispatch(request());

        teamsService.postActivitie(form, teamId)
            .then(
                payload => {
                    dispatch(alertActions.success(payload.Message))
                    dispatch(success(payload))

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error))
                }
            );
    };

    function request() {
        return {type: teamsConstants.POST_TEAMS_ACTIVITIE_REQUEST}
    }

    function success(payload) {
        return {type: teamsConstants.POST_TEAMS_ACTIVITIE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: teamsConstants.POST_TEAMS_ACTIVITIE_FAILURE, error}
    }
}
