import React, { useState } from 'react'
import { Menu } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './styles.scss'
import IconInfo from '../../_icons/IconInfo';

export function DialogConfirm({type='archive', isOpen, anchorEl, handleClose, submitted = false, handleActionConfirm}) {
  const { t, i18n } = useTranslation('common');
  
  return (
    <Menu
      className="dialogConfirm"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}

      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: 'top', horizontal: 'right', }}
    >
      <div className="bodyDialogConfirm">
        <div className="content">
          <div className="message"><IconInfo />{type === 'archive' ? t('messages.info_archived') : t('messages.info_unarchived')}</div>
          <div className="options">
            <button disabled={submitted} onClick={handleClose}>{t('no')}</button>
            <button disabled={submitted} onClick={() => { handleActionConfirm() }}>{t('yes')}</button>
          </div>
        </div>
      </div>
    </Menu>
  )
}
