import React from 'react';

const IconEdit = props => {
    return (

        <svg className={'edit'} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
            <path className={'edit'} id="Icon_awesome-clone" data-name="Icon awesome-clone"
                  d="M9.969,0A1.031,1.031,0,0,1,11,1.031V7.219A1.031,1.031,0,0,1,9.969,8.25H3.781A1.031,1.031,0,0,1,2.75,7.219V1.031A1.031,1.031,0,0,1,3.781,0H9.969M3.781,8.938A1.721,1.721,0,0,1,2.063,7.219V2.75H1.031A1.031,1.031,0,0,0,0,3.781V9.969A1.031,1.031,0,0,0,1.031,11H7.219A1.031,1.031,0,0,0,8.25,9.969V8.938Z"
                  fill="#98acc1"/>
        </svg>


    )
}

export default IconEdit;
