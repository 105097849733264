import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useTranslation, initReactI18next } from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function RealTimeFullSkeleton(props) {
    const { t, i18n } = useTranslation('common');


    return (
        <div className="box loading noBg"><div className="pageHeader compact realtime">
            <h3><Skeleton variant="text" width="40%" height={22} /></h3>
            <div className="internalFilters">
                <div className="filter active ">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>
                <div className="filter active ">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>

                <div className="filter active ">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>

                <div className="filter active size2">
                    <div className="icon happy">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>

                </div>
                <div className="filter active size2">
                    <div className="icon ok">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>
                <div className="filter active size2">
                    <div className="icon sad">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>
    
                <div className="filter work active  size3">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>
                <div className="filter active  size3">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>

                </div>
                <div className="filter work active  size3">
                    <div className="icon">
                        <Skeleton variant="circle" width={16} height={16} />
                    </div>
                    <div className="info">
                        <div className="name"><Skeleton variant="text" width="80%" height={17} /></div>
                        <div className="total"><Skeleton variant="text" width="80%" height={17} /></div>
                    </div>
                </div>


            </div>


        </div>

            <div className="dataTable">
                <Skeleton variant="rect" width="99%" height={447} />

            </div>




        </div>
    )
};

export default RealTimeFullSkeleton;