import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';
import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { authHeader, secondsToHm } from '../../../_helpers';
import { VictoryPie, VictoryTheme } from 'victory';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import IconOnline from '../../_icons/IconOnline';
import IconOffline from '../../_icons/IconOffline';
import IconChart from '../../_icons/IconChart';
import { api } from '../../../_helpers/api';
import Widget12Skeleton from './Widget12Skeleton';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';


export default function Widget12({ width, currentDate, alertActions, filters }) {
  const { t, i18n } = useTranslation('common');

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/time_goal_work_goal?year_month=${currentDate.format('YYYY-MM')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget7!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])


  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div className={style.header}>
            <div className={style.boxTitle}>
              <h6 className={style.title}>{t('home.your_company_stats')}</h6>
              <BefectiveTooltip
                title={
                  <>
                    <Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}><strong style={{ fontSize: '11px' }}>{t('home.time_goal2_avg') + ': '}</strong>{t('home.performance_label_1')}</Typography>
                    <Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}><strong style={{ fontSize: '11px' }}>{t('home.work_goal2_avg') + ': '}</strong>{t('home.performance_label_2')}</Typography>
                  </>
                }
                placement="right-start"
                arrow
                interactive>
                <div><IconInfo /></div>
              </BefectiveTooltip>
            </div>
          </div>

          <div className={style.boxStats}>
            <a className={style.statsBlock} style={{ cursor: 'default' }}>
              <div className={style.mainChart} style={{ cursor: 'default' }}>
                <div className={style.stats}>
                  <MobileView>
                    <div className={style.icon}><IconChart></IconChart></div>
                  </MobileView>
                  <span className={style.primary}>
                    {data.percent_work + '%'}
                  </span>
                  <span className={style.secondary}>{t('home.time_goal2_avg')}</span>
                  <span style={{marginTop:'4px'}}>{secondsToHm(data.total_work_average)}</span>
                </div>
                <BrowserView>
                  <svg width={188} height={188}>
                    <VictoryPie
                      padAngle={0}
                      standalone={false}
                      // used to hide labels
                      labelComponent={<span />}
                      innerRadius={86}
                      width={188} height={188}
                      data={[{ 'key': "", 'y': data.percent_work }, {
                        'key': "",
                        'y': (100 - data.percent_work)
                      }]}
                      colorScale={["", "#D2D7E1"]}
                    />
                    <circle cx={94} cy={94} r={79} />
                  </svg>
                </BrowserView>

              </div>

              <div className={style.secondaryChart}>

                <div className={style.block}>
                  <div className={style.icon}>
                    <IconOnline />
                  </div>
                  <div className={style.statsSecondary}>
                    <span>{t('home.online_activities')}</span>
                    <span>{secondsToHm(data.total_work_online_avg)}</span>
                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_work_online + '%' }}></div>
                    </div>
                  </div>
                  <div className={style.number}>
                    {data.percent_work_online}%
                  </div>
                </div>
                <div className={style.block}>
                  <div className={style.icon}>
                    <IconOffline />
                  </div>
                  <div className={style.statsSecondary}>
                    <span>{t('home.offline_activities')}</span>
                    <span>{secondsToHm(data.total_work_offline_avg)}</span>
                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_work_offline + '%' }}></div>
                    </div>
                  </div>
                  <div className={style.number}>
                    {data.percent_work_offline}%
                  </div>
                </div>
              </div>

            </a>


            <a className={`${style.statsBlock} ${style.work}`} style={{ cursor: 'default' }}>
              <div className={style.mainChart} style={{ cursor: 'default' }}>

                <div className={style.stats}>
                  <MobileView>
                    <div className={style.icon}><IconChart></IconChart></div>
                  </MobileView>
                  <span className={style.primary}>
                    {
                      data.percent_productive + '%'
                    }
                  </span>
                  <span className={style.secondary}>{t('home.work_goal2_avg')}</span>
                  <span style={{marginTop:'4px'}}>{secondsToHm(data.total_productive_avg)}</span>
                </div>
                <BrowserView>
                  <svg width={188} height={188}>
                    <VictoryPie
                      padAngle={0}
                      standalone={false}
                      // used to hide labels
                      labelComponent={<span />}
                      innerRadius={86}
                      width={188} height={188}
                      data={[{ 'key': "", 'y': data.percent_productive }, {
                        'key': "",
                        'y': (100 - data.percent_productive)
                      }]}
                      colorScale={["", "#D2D7E1"]}
                    />
                    <circle cx={94} cy={94} r={79} />
                  </svg>
                </BrowserView>

              </div>
              <div className={style.secondaryChart}>
                <div className={`${style.block} ${style.work}`}>
                  <div className={style.icon}>
                    <IconOnline />
                  </div>
                  <div className={style.statsSecondary}>
                    <span>{t('home.online_activities')}</span>
                    <span>{secondsToHm(data.total_productive_online_avg)}</span>
                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_productive_online + '%' }}></div>
                    </div>
                  </div>
                  <div className={style.number}>
                    {data.percent_productive_online}%
                  </div>
                </div>
                <div className={`${style.block} ${style.work}`}>
                  <div className={style.icon}>
                    <IconOffline />
                  </div>
                  <div className={style.statsSecondary}>
                    <span>{t('home.offline_activities')}</span>
                    <span>{secondsToHm(data.total_productive_offline_avg)}</span>
                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_productive_offline + '%' }}></div>
                    </div>
                  </div>
                  <div className={style.number}>
                    {data.percent_productive_offline}%
                  </div>
                </div>
              </div>
            </a>

          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget12Skeleton />
      </Grid>
  )
};
