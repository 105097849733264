import React from 'react';

const IconBalon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
      <path id="balão" d="M12,3H4A1,1,0,0,0,3,4v9l2-2h7a1,1,0,0,0,1-1V4A1,1,0,0,0,12,3Zm0,7H5L4,11V4h8Z" transform="translate(-3 -3)" fill="#98acc1" />
    </svg>
  )
}

export default IconBalon;