import React, { useRef, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from "redux";
import { VictoryStack, VictoryBar, VictoryTheme } from "victory";

import Moment from "moment";

import { activitiesActions } from "../../_actions";
import { statsActions } from "../../_actions";
import { historicalActions } from "../../_actions";

import { parseValue, secondsToHm } from "../../_helpers";

import Skeleton from "@material-ui/lab/Skeleton";

import IconInfo from "../_icons/IconInfo";
import IconOnline from "../_icons/IconOnline";
import IconOutdoor from "../_icons/IconOutdoor";
import IconHappy from "../_icons/IconHappy";
import IconOk from "../_icons/IconOk";
import IconSad from "../_icons/IconSad";
import IconDate from "../_icons/IconDate";
import IconArrow from "../_icons/IconArrow";
import { Typography } from "@material-ui/core";


import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const BefectiveTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F', // Cor de fundo personalizada
    marginRight: 0, // Espaçamento à direita
    maxWidth: '380px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

function HistoricalByHour(props) {
  const { t, i18n } = useTranslation("common");

  const { historicalActions, userDetails } = props;

  const history = useHistory();
  Moment.locale(t("date.locale"));

  const [DisplayHoursHTML, setDisplayHoursHTML] = useState([]);

  const [DisplayStartTime, setDisplayStartTime] = useState("");
  const [DisplayEndedTime, setDisplayEndedTime] = useState("");
  const [DisplayTotalWorkedTime, setDisplayTotalWorkedTime] = useState(0);
  const [DisplayTotalWorkedTimeOnline, setDisplayTotalWorkedTimeOnline] = useState(0);
  const [DisplayTotalWorkedTimeOutdoor, setDisplayTotalWorkedTimeOutdoor] = useState(0);
  const [DisplayTotalProductiveTime, setDisplayTotalProductiveTime] = useState(0);
  const [DisplayTotalNeutralTime, setDisplayTotalNeutralTime] = useState(0);
  const [DisplayTotalUnproductiveTime, setDisplayTotalUnproductiveTime] = useState(0);
  const [DisplayPercentWorkedTimeOnline, setDisplayPercentWorkedTimeOnline] = useState(0);
  const [DisplayPercentWorkedTimeOutdoor, setDisplayPercentWorkedTimeOutdoor] = useState(0);
  const [DisplayPercentProductiveTime, setDisplayPercentProductiveTime] = useState(0);
  const [DisplayPercentNeutralTime, setDisplayPercentNeutralTime] = useState(0);
  const [DisplayPercentUnproductiveTime, setDisplayPercentUnproductiveTime] = useState(0);
  const [DisplayTotalBarsDays, setDisplayTotalBarsDays] = useState(0);
  const [data, setData] = useState(null);

  const [DisplayMachineUserName, setDisplayMachineUserName] = useState(0);

  function toggleShowing(hour, MachineUserName) {
    props.openDrawer({ hour, MachineUserName });
  }

  function setHistorical(data) {
    setData(data);

    let SelectedDateHTML = [];
    let OutlineHTML = [];
    let HoursHTML = [];

    setDisplayTotalBarsDays(data.length || 0);
    setDisplayMachineUserName(data.MachineUserName);
    let MaxHours = 60;

    for (let item of data.hours) {
      let RelativeRelated = 0;
      let RelativeNeutral = 0;
      let RelativeUnrelated = 0;
      //let TotalTime = item.TotalProductiveTime + item.TotalNeutralTime + item.TotalUnproductiveTime;
      let TotalTime = 3600;
      let noBorder = "";
      let nothing = "";
      let hourFormated = Moment.utc(item.hour).format("HH:mm");

      if (MaxHours > 0 && item.TotalProductiveTime > 0) {
        RelativeRelated = Math.round(
          (item.TotalProductiveTime / TotalTime) * 100
        );
      }
      if (MaxHours > 0 && item.TotalNeutralTime > 0) {
        RelativeNeutral = Math.round((item.TotalNeutralTime / TotalTime) * 100);
      }
      if (MaxHours > 0 && item.TotalUnproductiveTime > 0) {
        RelativeUnrelated = Math.round(
          (item.TotalUnproductiveTime / TotalTime) * 100
        );
      }

      if (RelativeRelated + RelativeNeutral + RelativeUnrelated < 5) {
        noBorder = "no-border";
      }

      if (TotalTime == 0) {
        nothing = "nothing";
      }

      HoursHTML.push(
        <div className={"day " + nothing}>
          <div className="labelBox">
            <div className="header">
              <div className="icon">
                <IconDate />
              </div>
              {hourFormated}
            </div>
            {userDetails.allowed_productive.includes("related") && (
              <div className="related">
                {t("historical.related")}:{" "}
                {secondsToHm(item.TotalProductiveTime)}
              </div>
            )}
            {userDetails.allowed_productive.includes("neutral") && (
              <div className="neutral">
                {t("historical.neutral")}: {secondsToHm(item.TotalNeutralTime)}
              </div>
            )}
            {userDetails.allowed_productive.includes("not_related") && (
              <div className="unrelated">
                {t("historical.unrelated")}:{" "}
                {secondsToHm(item.TotalUnproductiveTime)}
              </div>
            )}
          </div>
          <div
            className="bar"
            onClick={() => {
              toggleShowing(
                Moment.utc(item.hour).format("YYYY-MM-DD HH:mm:ss"),
                data.MachineUserName
              );
            }}
          >
            {userDetails.allowed_productive.includes("not_related") && (
              <div
                className={"unrelated " + noBorder}
                style={{ height: RelativeUnrelated + "%" }}
              ></div>
            )}
            {userDetails.allowed_productive.includes("neutral") && (
              <div
                className={"neutral " + noBorder}
                style={{ height: RelativeNeutral + "%" }}
              ></div>
            )}
            {userDetails.allowed_productive.includes("related") && (
              <div
                className={"related " + noBorder}
                style={{ height: RelativeRelated + "%" }}
              ></div>
            )}
          </div>
          <div className="labelDay">{hourFormated}</div>
        </div>
      );
    }

    setDisplayHoursHTML(HoursHTML);

    let TotalWorkedTime = data.TotalWorkedTime || 0;
    let TotalWorkedTimeOnline = data.TotalWorkedTime - data.TotalWorkedTimeOutdoor || 0;
    let TotalWorkedTimeOutdoor = data.TotalWorkedTimeOutdoor || 0;
    let TotalProductiveTime = data.TotalProductiveTime || 0;
    let TotalNeutralTime = data.TotalNeutralTime || 0;
    let TotalUnproductiveTime = data.TotalUnproductiveTime || 0;

    setDisplayTotalWorkedTime(TotalWorkedTime);
    setDisplayTotalWorkedTimeOnline(TotalWorkedTimeOnline);
    setDisplayTotalWorkedTimeOutdoor(TotalWorkedTimeOutdoor);
    setDisplayTotalProductiveTime(TotalProductiveTime);
    setDisplayTotalNeutralTime(TotalNeutralTime);
    setDisplayTotalUnproductiveTime(TotalUnproductiveTime);
    setDisplayStartTime(data.StartTime);
    setDisplayEndedTime(data.EndTime);

    if (TotalWorkedTime > 0) {
      setDisplayPercentWorkedTimeOnline(
        Math.round(
          ((TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalWorkedTime) * 100
        )
      );
    }
    if (TotalWorkedTime > 0) {
      setDisplayPercentWorkedTimeOutdoor(
        Math.round((TotalWorkedTimeOutdoor / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalProductiveTime > 0) {
      setDisplayPercentProductiveTime(
        Math.round((TotalProductiveTime / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalNeutralTime > 0) {
      setDisplayPercentNeutralTime(
        Math.round((TotalNeutralTime / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalUnproductiveTime > 0) {
      setDisplayPercentUnproductiveTime(
        Math.round((TotalUnproductiveTime / TotalWorkedTime) * 100)
      );
    }

    let Fraction = Math.round(MaxHours / 9);

    for (let n = 0; n < 10; n++) {
      let hour = Fraction * n;

      if (n == 9) {
        OutlineHTML.push(
          <div className="hour">
            <span>{MaxHours}</span>
          </div>
        );
      } else {
        OutlineHTML.push(
          <div className="hour">
            <span>{hour}</span>
          </div>
        );
      }
    }
  }

  useEffect(() => {
    if (props.historical?.teammateDay) {
      setHistorical(props.historical.teammateDay);
    }
  }, [props.historical.loading]);

  return (
    <div className="box historical noBg">
      <div className="pageHeader historical">
        <div className="multi">
          {userDetails.allowed_total_time === true && (
            <h3>
              {t("historical.total_time")}:{" "}
              <b> {secondsToHm(data?.TotalExpectedWorkedTime)} / {secondsToHm(data?.TotalWorkedTime)}</b>&nbsp;
              <BefectiveTooltip
                title={
                  <Typography
                    component={"div"}
                    variant="caption"
                    marginY={"4px"}
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                  >
                    {t("historical.tooltip1")}
                  </Typography>
                }
                placement="bottom-center"
                arrow
                interactive
              >
                <div>
                  <IconInfo />
                </div>
              </BefectiveTooltip>
            </h3>
          )}
          {userDetails.allowed_total_time_related === true && (
              <h3 className="work">
                {t("historical.total_time_related")}:{" "}
                <b> {secondsToHm(data?.total_expected_productive_time)} / {secondsToHm(data?.total_productive_time)}</b>&nbsp;
                <BefectiveTooltip
                  title={
                    <Typography
                      component={"div"}
                      variant="caption"
                      marginY={"4px"}
                      style={{ fontSize: "12px", lineHeight: "16px" }}
                    >
                      {t("historical.tooltip2")}
                    </Typography>
                  }
                  placement="bottom-center"
                  arrow
                  interactive
                >
                  <div>
                    <IconInfo />
                  </div>
                </BefectiveTooltip>
              </h3>
          )}
        </div>
        <div className="internalFilters">
          {userDetails.allowed_productive.includes("related") &&
            (userDetails.calculate_based_productivity_target !== undefined &&
            userDetails.calculate_based_productivity_target === true ? (
              <div className="filter work active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot related"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                      <div className="number">
                        {secondsToHm(data?.total_productive_time)}
                      </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_related")}</div>
              </div>
            ) : (
              <div className="filter work active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot related"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_productive_time)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_related")}</div>
              </div>
            ))}

          {userDetails.allowed_productive.includes("neutral") &&
            (userDetails.calculate_based_productivity_target !== undefined &&
            userDetails.calculate_based_productivity_target === true ? (
              <div className="filter neutral active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot neutral"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                      <div className="number">
                        {secondsToHm(data?.total_neutral_time)}
                      </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_neutral_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_neutral")}</div>
              </div>
            ) : (
              <div className="filter neutral active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot neutral"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_neutral_time)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_neutral_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_neutral")}</div>
              </div>
            ))}

          {userDetails.allowed_productive.includes("not_related") &&
            (userDetails.calculate_based_productivity_target !== undefined &&
            userDetails.calculate_based_productivity_target === true ? (
              <div className="filter sad active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot unrelated"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_unproductive_time)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_unproductive_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_unrelated")}</div>
              </div>
            ) : (
              <div className="filter sad active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot unrelated"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_unproductive_time)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_unproductive_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_unrelated")}</div>
              </div>
            ))}

          {userDetails.calculate_based_productivity_target !== undefined &&
          userDetails.calculate_based_productivity_target === true ? (
            <>
              <div className="filter time active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOnline />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_productive_online_time)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_online_from_total_productive)}%`}</div>
                <div className="name">{t("historical.online")}</div>
              </div>

              <div className="filter active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOutdoor />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_productive_offline_time)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_offline_from_total_productive)}%`}</div>
                <div className="name">{t("historical.outdoor")}</div>
              </div>
            </>
          ) : (
            <>
              <div className="filter time active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOnline />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_online_time)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_online_from_total_time)}%`}</div>
                <div className="name">{t("historical.online")}</div>
              </div>

              <div className="filter active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOutdoor />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_offline_time)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_offline_from_total_time)}%`}</div>
                <div className="name">{t("historical.outdoor")}</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="chartWrapper">
        <div className="internalBreadcrub">
          <button
            className="back"
            onClick={() => {
              history.push("/productive/historical");
            }}
          >
            <IconArrow />
          </button>
          <h3>
            {Moment.utc(props.day).format("DD/MM/YYYY")} -{" "}
            {DisplayMachineUserName}{" "}
            {DisplayStartTime
              ? " - " +
                t("historical.started") +
                ": " +
                Moment.utc(DisplayStartTime).format("HH:mm:ss") +
                " | " +
                t("historical.ended") +
                ": " +
                Moment.utc(DisplayEndedTime).format("HH:mm:ss")
              : " - " + t("no_data")}
          </h3>
        </div>
        <div className={"historicalChart days"}>
          <div className="outline">
            <div className="hour">
              <span>0</span>
            </div>
            <div className="hour">
              <span>10</span>
            </div>
            <div className="hour">
              <span>20</span>
            </div>
            <div className="hour">
              <span>30</span>
            </div>
            <div className="hour">
              <span>40</span>
            </div>
            <div className="hour">
              <span>50</span>
            </div>
            <div className="hour">
              <span>60</span>
            </div>
            <div className="timeLabel">min</div>
          </div>
          <div className={"days hours"}>{DisplayHoursHTML}</div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    historicalActions: bindActionCreators(historicalActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    historical: state.historical,
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalByHour);
