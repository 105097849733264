import React from 'react';

const IconInfo = props => {
    const width = props.width || 10;
    const height = props.width || 10;
    const color = props.color || '#c2cdda'

    return (
        
     
        <svg id="info" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 10">
        <path id="info-2" data-name="info" d="M8,3a5,5,0,1,0,5,5A5,5,0,0,0,8,3Zm.5,7.5h-1v-3h1Zm0-4h-1v-1h1Z" transform="translate(-3 -3)" fill={color}/>
        </svg>

    )
}

export default IconInfo;