import React from "react";

const IconGoogleCalendar = (props) => {
  return (
    <svg id="Google_Calendar_icon__2020_" data-name="Google_Calendar_icon_(2020)" xmlns="http://www.w3.org/2000/svg" width="47.933" height="47.933" viewBox="0 0 47.933 47.933">
      <g id="Grupo_629" data-name="Grupo 629">
        <path id="Caminho_3742" data-name="Caminho 3742" d="M64.845,39.616,53.492,38.355,39.617,39.616,38.355,52.23l1.261,12.614L52.23,66.421l12.614-1.577,1.261-12.929Z" transform="translate(-28.264 -28.264)" fill="#fff" />
        <path id="Caminho_3743" data-name="Caminho 3743" d="M59.018,79.6a4.877,4.877,0,0,1-1.952-2.8l2.189-.9a3.323,3.323,0,0,0,1.041,1.76,2.682,2.682,0,0,0,1.791.621,2.761,2.761,0,0,0,1.845-.653,2.086,2.086,0,0,0,.773-1.662,2.061,2.061,0,0,0-.814-1.684,3.157,3.157,0,0,0-2.037-.653H60.589V71.461h1.135a2.767,2.767,0,0,0,1.769-.568,1.872,1.872,0,0,0,.719-1.555,1.718,1.718,0,0,0-.643-1.4,2.494,2.494,0,0,0-1.63-.527,2.193,2.193,0,0,0-1.533.514,3.015,3.015,0,0,0-.826,1.264l-2.166-.9A4.81,4.81,0,0,1,59,66.132a4.574,4.574,0,0,1,2.958-.934,5.4,5.4,0,0,1,2.39.514,4.09,4.09,0,0,1,1.662,1.425,3.638,3.638,0,0,1,.6,2.047,3.352,3.352,0,0,1-.558,1.961,3.849,3.849,0,0,1-1.372,1.233v.129a4.165,4.165,0,0,1,1.76,1.372,3.6,3.6,0,0,1,.687,2.208,4.131,4.131,0,0,1-.653,2.3,4.521,4.521,0,0,1-1.8,1.586,5.677,5.677,0,0,1-2.583.58A5.346,5.346,0,0,1,59.018,79.6Z" transform="translate(-42.491 -48.674)" fill="#1a73e8" />
        <path id="Caminho_3744" data-name="Caminho 3744" d="M109.855,69.826l-2.39,1.738-1.2-1.823,4.311-3.109h1.652V81.3h-2.371Z" transform="translate(-79.897 -49.764)" fill="#1a73e8" />
        <path id="Caminho_3745" data-name="Caminho 3745" d="M140.879,152.231l11.352-11.352-5.676-2.523-5.676,2.523-2.523,5.676Z" transform="translate(-104.298 -104.298)" fill="#ea4335" />
        <path id="Caminho_3746" data-name="Caminho 3746" d="M33.092,154.558l2.523,5.676H60.843V148.882H35.615Z" transform="translate(-24.262 -112.301)" fill="#34a853" />
        <path id="Caminho_3747" data-name="Caminho 3747" d="M.034-3.75A3.783,3.783,0,0,0-3.75.034v32.8l5.676,2.523L7.6,32.83V7.6H32.83l2.523-5.676L32.831-3.75Z" transform="translate(3.75 3.75)" fill="#4285f4" />
        <path id="Caminho_3748" data-name="Caminho 3748" d="M-3.75,148.882v7.568a3.783,3.783,0,0,0,3.784,3.784H7.6V148.882Z" transform="translate(3.75 -112.301)" fill="#188038" />
        <path id="Caminho_3749" data-name="Caminho 3749" d="M148.882,35.615V60.843h11.352V35.615l-5.676-2.523Z" transform="translate(-112.301 -24.262)" fill="#fbbc04" />
        <path id="Caminho_3750" data-name="Caminho 3750" d="M160.234,7.6V.034A3.783,3.783,0,0,0,156.45-3.75h-7.568V7.6Z" transform="translate(-112.301 3.75)" fill="#1967d2" />
      </g>
    </svg>

  )
}

export default IconGoogleCalendar;