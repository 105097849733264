import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import "react-datepicker/dist/react-datepicker.css";

import {filterActions} from '../../../_actions';
import {statsActions} from '../../../_actions';
import {teamsActions} from '../../../_actions';
import {teammatesActions} from '../../../_actions';

import {useTranslation, initReactI18next} from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";

import IconClear from '../../_icons/IconClear'
import IconCheck from '../../_icons/IconCheck'
import IconTeammate from '../../_icons/IconTeammate'

function PeriodSingleGoal(props) {
    const {t, i18n} = useTranslation('common');


    const {filterActions} = props;
    const {userDetails} = props


    let teammates = props.teammates || [];
    let filter = props.filter || {};

    const {teammatesActions} = props;

    let FilteredTeammatesHTML = [];
    const searchInput = useRef(null);
    const [helperPeriodsGoal, setHelperPeriodsGoal] = useState(0);
    const refFilter = useRef();
    const [filterPeriod, setPeriod] = useState(false);
    const [selectedTeammates, setSelectedTeammates] = useState({});
    const [filterWhoValue, setFilterWhoValue] = useState('');
    const [InitialTeammatesHTML, setInitialTeammatesHTML] = useState('');
    const [teammatesHTML, setTeammatesHTML] = useState('');


    const changeFilterWho = (event) => {

        setFilterWhoValue(event.target.value);

        let value = event.target.value.toLowerCase();

        if (value.length > 2) {

            for (let Teammate of teammates) {


                let testName = Teammate.name.toLowerCase();

                if (testName.includes(value)) {

                    let TeammateClass = 'fTeammate';

                    if (selectedTeammates && selectedTeammates[Teammate.id]) {
                        TeammateClass = 'fTeammate active';
                    }

                    FilteredTeammatesHTML.push(<li id={"fTeammate" + Teammate.id} className={TeammateClass}
                                                   onClick={() => {
                                                       setWhoFilter(Teammate.id, Teammate.name, "teammates")
                                                   }} key={"Teammate-" + Teammate.id}>
                        <div className="check"><IconCheck/></div>
                        {Teammate.name}</li>)


                }
            }

            setTeammatesHTML(FilteredTeammatesHTML);

        } else {
            setTeammatesHTML(InitialTeammatesHTML);
        }
    };


    useOutsideClick(refFilter, target => {

        if (FilteredTeammatesHTML.length == 0) {
            setTeammatesHTML(InitialTeammatesHTML);
        }

        if (filterPeriod) {

            if (refFilter && !target.includes("react-datepicker") && !target.includes("column") && !target.includes("who") && !target.includes("input")) {
                if (filterPeriod) {
                    setPeriod(false);
                }
            }

        }
    });

    teammates = teammates.sort((a, b) => a.name.localeCompare(b.name))


    function setTeammatesList() {


        if (props.teammates) {
            let newSelected = {}

            if (props.filter.teammates) {
                for (let item of props.filter.teammates) {
                    newSelected[item.id] = true;
                }
            }


            let tempTeammates = []

            for (let Teammate of teammates) {

                let aClass = '';

                if (Teammate.ancestry) {
                    let ancestry = Teammate.ancestry.split("/");

                    for (let a of ancestry) {
                        aClass += ' fTeamTeammate-' + a;
                    }
                }


                let TeammateClass = 'fTeammate fTeamTeammate-' + Teammate.team_id + " " + aClass;

                if ((newSelected || selectedTeammates) && (selectedTeammates[Teammate.id] || newSelected[Teammate.id])) {
                    TeammateClass = 'fTeammate active';
                }

                tempTeammates.push(<li id={"fTeammate" + Teammate.id} className={TeammateClass} onClick={() => {
                    setWhoFilter(Teammate.id, Teammate.name, "teammates")
                }} key={"Teammate-" + Teammate.id}>
                    <div className="check"><IconCheck/></div>
                    {Teammate.name}</li>)
            }

            setInitialTeammatesHTML(tempTeammates)
            setTeammatesHTML(tempTeammates)

        }
    }

    function clearWho() {

        filterActions.clear("who");
        setHelperPeriodsGoal(0);
        setPeriod(false);


        let elemsTeammates = document.querySelectorAll(".fTeammate");

        [].forEach.call(elemsTeammates, function (el) {
            el.classList.remove("active");
        });


    }

    function setWhoFilter(id, name, type) {
        setPeriod(false);

        if (type == "teammates") {


            let element = document.getElementById("fTeammate" + id)

            if (element.classList.contains('active')) {
                filterActions.clear("who");
                document.getElementById("fTeammate" + id).classList.remove('active');
            } else {
                filterActions.replace({id}, "teammates");
                let elemsTeammates = document.querySelectorAll(".fTeammate");

                [].forEach.call(elemsTeammates, function (el) {
                    el.classList.remove("active");
                });
                document.getElementById("fTeammate" + id).classList.add('active');
            }

        }

    }


    useEffect(() => {

        let nf = 0;

        if (props.filter.teammates) {
            let newSelected = {}

            for (let item of props.filter.teammates) {
                newSelected[item.id] = true;
                nf++;
            }

            setSelectedTeammates(newSelected);

        }


        setHelperPeriodsGoal(nf);

        setTeammatesList()

    }, [props.teammates])


    useEffect(() => {

        let nf = 0;

        if (filter.teammates) {
            let newSelected = {}

            for (let item of filter.teammates) {
                newSelected[item.id] = true;
                nf++;
            }

            setSelectedTeammates(newSelected);

        }

        setHelperPeriodsGoal(nf);

    }, [filter.teammates])


    useEffect(() => {

        if (props.openWho && props.openWho != '') {


            if (props.filter.teammates && props.filter.teammates.length == 0) {
                setPeriod(true);

            } else if (props.filter.teammates && props.filter.teammates.length == 1) {
                clearWho();
                filterActions.set(props.filter.teammates[0], "teammates");
            } else {
                clearWho();
                setPeriod(true);

            }


        }


    }, [props.openWho])

    useEffect(() => {

        if (props.clearWho && props.clearWho != '') {

            clearWho();
        }


    }, [props.clearWho])

    useEffect(() => {

        searchInput.current.focus();

    }, [filterPeriod])

    return (

        <div className={filterPeriod ? 'filter who open' : 'who filter'} ref={refFilter}>
            <div className="holder">
                <span className="label">{t('filter.period')}:</span>
                <span className="value" onClick={() => {
                    setPeriod(!filterPeriod);
                }}>
                            {helperPeriodsGoal > 0 && (
                                <div className={"helper teammates"}>
                                    <div className="icon"><IconTeammate/></div>
                                    {helperPeriodsGoal}
                                </div>
                            )}
                    {(helperPeriodsGoal === 0) && t('filter.all_period')}
                    <i className="arrow"></i></span>
                <input className="input" ref={searchInput} placeholder={t('filter.search')} value={filterWhoValue}
                       onChange={changeFilterWho}/>
                <div className="clear" onClick={() => {
                    clearWho()
                }}><IconClear/></div>
            </div>
            <div className={"comp single"}>
                <div className="column">
                    <div className="title">{t('filter.periods')}</div>
                    <ul>
                        {teammatesHTML}
                    </ul>
                </div>

            </div>
        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        teammates: state.teammates.payload,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSingleGoal)
