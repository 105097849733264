import {teamsConstants} from '../_constants';

export function teams(state = {}, action) {
    switch (action.type) {
        case teamsConstants.GET_TEAMS_REQUEST:
            return {

                loading: true
            };
        case teamsConstants.GET_TEAMS_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                loading: false
            };
        case teamsConstants.GET_TEAMS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };

        case teamsConstants.POST_TEAMS_REQUEST:
            return {
                ...state,
                created: '',
                loading: false
            };
        case teamsConstants.POST_TEAMS_SUCCESS:
            return {
                ...state,
                created: 'success',
                loading: false
            };
        case teamsConstants.POST_TEAMS_FAILURE:
            return {
                ...state,
                error: action.error,
                created: 'error',
                loading: false
            };

        case teamsConstants.PATCH_TEAMS_REQUEST:
            return {
                ...state,
                updated: '',
                loading: false
            };
        case teamsConstants.PATCH_TEAMS_SUCCESS:
            return {
                ...state,
                updated: 'success',
                loading: false
            };
        case teamsConstants.PATCH_TEAMS_FAILURE:
            return {
                ...state,
                error: action.error,
                updated: 'error',
                loading: false
            };

        case teamsConstants.GET_TEAM_DETAIL_REQUEST:
            return {
                ...state,
                loadingDetail: true
            };
        case teamsConstants.GET_TEAM_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.payload,
                loadingDetail: false
            };
        case teamsConstants.GET_TEAM_DETAIL_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingDetail: true
            };

        case teamsConstants.GET_TEAM_ACTIVITIES_REQUEST:
            return {
                ...state,
                loadingDetail: true
            };
        case teamsConstants.GET_TEAM_ACTIVITIES_SUCCESS:
            return {
                ...state,
                activities: action.payload,
                loadingDetail: false
            };
        case teamsConstants.GET_TEAM_ACTIVITIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingDetail: true
            };

        case teamsConstants.GET_TEAM_FOCUS_REQUEST:
            return {
                ...state,
                loadingFocus: true
            };
        case teamsConstants.GET_TEAM_FOCUS_SUCCESS:
            return {
                ...state,
                focus: action.payload,
                loadingFocus: false
            };
        case teamsConstants.GET_TEAM_FOCUS_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingFocus: true
            };


        case teamsConstants.DELETE_TEAM_REQUEST:
            return {
                ...state,
                deleted: '',
                loading: false
            };
        case teamsConstants.DELETE_TEAM_SUCCESS:
            return {
                ...state,
                deleted: 'success',
                loading: false
            };
        case teamsConstants.DELETE_TEAM_FAILURE:
            return {
                ...state,
                error: action.error,
                deleted: 'error',
                loading: false
            };

        case teamsConstants.POST_TEAMS_ACTIVITIE_REQUEST:
            return {
                ...state,
                created: '',
                updatedActivities: false
            };
        case teamsConstants.POST_TEAMS_ACTIVITIE_SUCCESS:
            return {
                ...state,
                created: 'success',
                updatedActivities: action.payload.id
            };
        case teamsConstants.POST_TEAMS_ACTIVITIE_FAILURE:
            return {
                ...state,
                error: action.error,
                created: 'error',
                updatedActivities: false
            };
        case teamsConstants.DELETE_TEAM_ACTIVITIE_REQUEST:
            return {
                ...state,
                deleted: '',
                loading: false
            };
        case teamsConstants.DELETE_TEAM_ACTIVITIE_SUCCESS:
            return {
                ...state,
                deleted: 'success',
                loading: false
            };
        case teamsConstants.DELETE_TEAM_ACTIVITIE_FAILURE:
            return {
                ...state,
                error: action.error,
                deleted: 'error',
                loading: false
            };

        default:
            return state
    }
}
