import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import MuiSelect from "@material-ui/core/Select";
import ReactAvatar from 'react-avatar';
import { Backdrop, CircularProgress, Fade, MenuItem, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { TagsInput } from 'react-tag-input-component';

import IconClose from '../../_icons/IconClose'
import { ModalAddTeamTask } from '../ModalAddTeamTask';
import { api } from '../../../_helpers/api';

import './styles.scss'
import { authHeader } from '../../../_helpers';
import { BefectiveDatePicker } from '../BefectiveDatePicker';

export function ModalNewTaskEdit({
  classes,
  openModalNewTaskEdit,
  setOpenModalNewTaskEdit,
  handleClose,
  teammates,
  kr,
  userDetails,
  groups,
  categories,
  currentTaskToModal,
  alertActions,
  updateGoalsDetails,
  updateTaskDetails
}) {

  const { t } = useTranslation('common');

  const [responsible, setResponsible] = useState(currentTaskToModal[0]?.responsible_id)
  const [assistants, setAssistants] = useState(currentTaskToModal[0]?.co_responsibles)
  const [tags, setTags] = useState(currentTaskToModal[0]?.tags || []);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openModalAddTeamTask, setOpenModalAddTeamTask] = useState(false);
  const [submitted, setSubmitted] = useState(false)

  const [form, setForm] = useState({
    title: currentTaskToModal[0]?.title,
    description: currentTaskToModal[0]?.description || '',
    responsible: currentTaskToModal[0]?.responsible_id,
    date_start: currentTaskToModal[0]?.date_start ? currentTaskToModal[0]?.date_start.split('T')[0] : '',
    date_end: currentTaskToModal[0]?.date_end ? currentTaskToModal[0]?.date_end.split('T')[0] : '',
    priority: currentTaskToModal[0]?.priority?.length > 0 ? currentTaskToModal[0]?.priority[0].id : '',
    complexity: currentTaskToModal[0]?.complexity?.length > 0 ? currentTaskToModal[0]?.complexity[0].id : '',
    category: '39473',
    weight: currentTaskToModal[0]?.weight

  });

  const [formError, setFormError] = useState({
    title: '',
    description: '',
    responsible: '',
    date_start: '',
    date_end: '',
    priority: '',
    complexity: '',
    category: '',
    weight: ''
  });

  const token = authHeader()

  useEffect(() => {
    if (responsible) {
      handleChange({ target: { name: 'responsible', value: responsible } })
    }
  }, [responsible])

  const handleOpenModalAddTeamTask = (e) => {
    e.preventDefault()
    setOpenModalAddTeamTask(true)
  }

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('goals.errors.required')
    }
    // else if (form.title.length < 10) {
    //   errors.title = t('goals.errors.title_goal')
    // }

    // if (!form.description) {
    //   errors.description = t('goals.errors.required')
    // } 
    // else if (form.description.length < 10) {
    //   errors.description = t('goals.errors.description_goal')
    // }

    if (!form.responsible) {
      errors.responsible = t('goals.errors.required')
    }

    // if (!form.date_start) {
    //   errors.date_start = t('goals.errors.required')
    // }

    // if (!form.date_end) {
    //   errors.date_end = t('goals.errors.required')
    // }

    // if (!form.priority) {
    //   errors.priority = t('goals.errors.required')
    // }

    // if (!form.complexity) {
    //   errors.complexity = t('goals.errors.required')
    // }

    // if (!form.category) {
    //   errors.category = t('goals.errors.required')
    // }

    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const updateTask = async () => {

    try {
      setIsSubmitting(true)
      const co_responsibles = assistants.reduce((acc, item) => {
        return [...acc, item.id]
      }, [])
      await api.put(
        `/goals/key-results/tasks/${currentTaskToModal[0]?.id}?organization_id=${userDetails?.organizationId}`,
        {
          action_id: 6,
          datetime: new Date(),
          from: JSON.stringify({
            title: currentTaskToModal[0]?.title,
            description: currentTaskToModal[0]?.description || '',
            responsible: currentTaskToModal[0]?.responsible_id,
            date_start: currentTaskToModal[0]?.date_start ? currentTaskToModal[0]?.date_start.split('T')[0] : '',
            date_end: currentTaskToModal[0]?.date_end ? currentTaskToModal[0]?.date_end.split('T')[0] : '',
            priority: currentTaskToModal[0]?.priority?.length > 0 ? currentTaskToModal[0]?.priority[0].id : '',
            complexity: currentTaskToModal[0]?.complexity?.length > 0 ? currentTaskToModal[0]?.complexity[0].id : '',
            category: '39473',
            tags: currentTaskToModal[0]?.tags || [],
            co_responsibles: currentTaskToModal[0]?.co_responsibles || [],
            weight: currentTaskToModal[0]?.weight
          }),
          to: JSON.stringify({
            title: form.title,
            description: form.description,
            date_start: form.date_start,
            date_end: form.date_end,
            priority_id: form.priority,
            complexity_id: form.complexity,
            mu_id: form.responsible,
            category_id: form.category,
            tags,
            co_responsibles,
            weight: form?.weight

          }),
          title: form.title,
          description: form.description,
          date_start: form.date_start,
          date_end: form.date_end,
          priority_id: form.priority,
          complexity_id: form.complexity,
          mu_id: form.responsible,
          category_id: form.category,
          tags,
          co_responsibles,
          weight: form?.weight
        }, {
        headers: {
          'Authorization': token.Authorization
        }
      }
      )


      updateGoalsDetails()
      updateTaskDetails(currentTaskToModal[0].id)

      setOpenModalNewTaskEdit(false)
      alertActions.success(t('tasks.success.update_task'))

      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      console.log(error)
      //alertActions.error(t('tasks.error.update_task'))

    }
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      updateTask()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalNewTaskEdit}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ border: 'none' }}
      >
        <Fade in={openModalNewTaskEdit} >
          <form className='modal-edit-task-container' >
            <div className="box-button-close">
              <button onClick={handleClose} className="close" type='button'><IconClose /></button>
            </div>
            <div className='left-container-modal'>
              <header>
                <h2>{t('modalNewTaskEdit.label')}</h2>
              </header>

              <div className='boxFieldEditTask'>
                <label htmlFor="input-task-title">{t('tasks.title')}*</label>
                <input
                  type="text"
                  id="input-task-title"
                  name="title"
                  className='input-task-title'
                  placeholder={t('tasks.input_placeholder')}
                  value={form.title}
                  onChange={handleChange}
                  maxLength={150}/>
                <span className="text-limit">{form?.title?.length}/150</span>
                {formError.title &&
                  <div className="error">{formError.title}</div>
                }
              </div>              
              
              <div className='boxFieldEditTask'>
                <label htmlFor="input-task-description">{t('tasks.description')}</label>
                <textarea
                  id="input-task-description"
                  name="description"
                  className='input-task-description'
                  placeholder={t('tasks.input_placeholder')}
                  value={form.description}
                  onChange={handleChange}
                />
                {formError.description &&
                  <div className="error">{formError.description}</div>
                }
              </div>
              

              <div className='dates-task-container'>
                <div className='middle-infos' style={{ width: '230px' }}>
                  <label htmlFor="responsible">{t('tasks.responsible')}*</label>
                  <Select
                    id='responsible'
                    defaultValue={() => teammates.find(item => item.value === form?.responsible)}
                    name="responsible"
                    options={teammates}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('goals.modal_goal.select')}
                    onChange={(e) => setResponsible(e.value)}
                  />
                  {formError.responsible &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.responsible}
                    </div>
                  }
                </div>

                <div className='middle-infos'>
                  <label htmlFor="input-task-date-ini">{t('tasks.start')}</label>
                  {/* <input
                    type="date"
                    id='input-task-date-ini'
                    name='date_start'
                    className='input-task-date'
                    value={form.date_start}
                    onChange={handleChange}
                    min={kr.date_start}
                    max={kr.date_end}
                  /> */}
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.date_start}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="date_start"
                    minDate={kr.date_start}
                    maxDate={kr.date_end}
                  />
                  {formError.date_start &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.date_start}
                    </div>
                  }
                </div>

                <div className='middle-infos'>
                  <label htmlFor="input-task-date-end">{t('tasks.end')}</label>
                  <BefectiveDatePicker
                    userDetails={userDetails}
                    date_value={form.date_end}
                    formEdit={form}
                    setFormEdit={setForm}
                    setSubmitted={setSubmitted}
                    field="date_end"
                    minDate={kr.date_start}
                    maxDate={kr.date_end}
                  />
                  {/* <input
                    type="date"
                    id='input-task-date-end'
                    name='date_end'
                    className='input-task-date'
                    value={form.date_end}
                    onChange={handleChange}
                    min={kr.date_start}
                    max={kr.date_end}
                  /> */}
                  {formError.date_end &&
                    <div className="error" style={{ marginTop: 0, marginBottom: 0 }}>
                      {formError.date_end}
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className='right-container-modal'>
              <div className='selects-container'>
                <label htmlFor="priority">{t('tasks.priority')}</label>
                <MuiSelect
                  style={{ color: '#98ACC1' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="priority"
                  id="priority"
                  value={form.priority}
                  onChange={handleChange}
                >
                  <MenuItem
                    value=""
                  >
                    {t('goals.modal_goal.select')}
                  </MenuItem>
                  <MenuItem value={1}>{t('tasks.low')}</MenuItem>
                  <MenuItem value={2}>{t('tasks.medium')}</MenuItem>
                  <MenuItem value={3}>{t('tasks.high')}</MenuItem>
                </MuiSelect>

                {formError.priority &&
                  <div className="error">{formError.priority}</div>
                }

                <label htmlFor="complexity">{t('tasks.complexity')}</label>
                <MuiSelect
                  style={{ color: '#98ACC1' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="complexity"
                  id="complexity"
                  value={form.complexity}
                  onChange={handleChange}
                >
                  <MenuItem
                    value=""
                  >
                    {t('goals.modal_goal.select')}
                  </MenuItem>
                  <MenuItem value={1}>{t('tasks.low')}</MenuItem>
                  <MenuItem value={2}>{t('tasks.medium')}</MenuItem>
                  <MenuItem value={3}>{t('tasks.high')}</MenuItem>
                </MuiSelect>

                {formError.complexity &&
                  <div className="error">{formError.complexity}</div>
                }

                <label htmlFor="weight" style={{textTransform:'uppercase'}}>{t('modalDetailTask.weight')}</label>

                <MuiSelect
                  style={{ color: '#98ACC1', textAlign:'right'}}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="weight"
                  id="weight"
                  value={form?.weight || 1}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </MuiSelect>

                {/* <label htmlFor="category">{t('tasks.category')}*</label>
                <MuiSelect
                  style={{ color: '#98ACC1' }}
                  displayEmpty
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                  name="category"
                  id="category"
                  value={form.category}
                  onChange={handleChange}
                >
                  <MenuItem
                    value=""
                  >
                    {t('goals.modal_goal.select')}
                  </MenuItem>
                  {categories?.map(item => {
                    if (userDetails?.language === 'en') {
                      return (
                        <MenuItem key={item.id} value={item.id}>{item.name_en}</MenuItem>
                      )
                    }

                    if (userDetails?.language === 'es') {
                      return (
                        <MenuItem key={item.id} value={item.id}>{item.name_es}</MenuItem>
                      )
                    }

                    return (
                      <MenuItem key={item.id} value={item.id}>{item.name_pt}</MenuItem>
                    )
                  })}
                </MuiSelect>

                {formError.category &&
                  <div className="error">{formError.category}</div>
                } */}

                <label htmlFor="assistant" style={{ marginBottom: '8px', marginTop: '1.2rem' }}>
                  {t('tasks.assistant')}
                </label>

                <div className='assistant-container'>
                  {assistants?.length >= 3 ? (
                    <>

                      <ReactAvatar
                        name={assistants[0].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />

                      <ReactAvatar
                        name={assistants[1].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />

                      <ReactAvatar
                        name={assistants[2].username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff' }}
                      />
                      <span
                        style={{
                          color: '#4F7091',
                          fontSize: '16px',
                        }}
                      >
                        ...
                      </span>
                    </>

                  ) : (
                    assistants?.map(user => (
                      <ReactAvatar
                        key={user.id}
                        name={user.username}
                        size={20}
                        round
                        color='#4F7091'
                        style={{ border: '2px solid #fff', marginRight: '-5px' }}
                      />
                    ))
                  )}

                  <button
                    className='add-assistant-button'
                    onClick={handleOpenModalAddTeamTask}
                  >
                    +
                  </button>

                </div>


                <label htmlFor="tags" style={{ marginTop: '1.5rem' }}>
                  TAGS
                </label>
                <div className='tagBox'>
                  <TagsInput
                    value={tags}
                    onChange={e => setTags(e)}
                    name="tags"
                    id="tags"
                    placeHolder="+ Tag"
                  />
                </div>

              </div>

              <div className='buttons-container'>
                <button className='cancel' onClick={handleClose}>{t('button.cancel')}</button>
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={8} />
                  ) : t('button.save')}

                </button>
              </div>

            </div>
          </form>
        </Fade>
      </Modal>

      <ModalAddTeamTask
        classes={classes}
        openModalAddTeamTask={openModalAddTeamTask}
        setOpenModalAddTeamTask={setOpenModalAddTeamTask}
        assistants={assistants}
        setAssistants={setAssistants}
        groups={groups}
      />
    </>
  )
}
