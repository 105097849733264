import React from 'react'
import { Badge, makeStyles } from '@material-ui/core'

import './styles.scss'
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup, IconButton, Tooltip } from '@mui/material';
import { AttachFile, ChatBubbleOutline, Filter } from '@material-ui/icons';
import { getAvatarUrl, limitedText } from '../../../_helpers';
import { useState } from 'react';
import { api } from '../../../_helpers/api';
import ModalReplyMessage from '../ModalReplyMessage';
import { useMessagesCount } from '../../../contexts/MessagesCountContext';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  badge: {
    fontSize: '10px',
    minWidth: '15px',
    width: '15px',
    height: '15px'
  },
  margin2: {
    margin: theme.spacing(1),
  },
  badge2: {
    fontSize: '10px',
    minWidth: '15px',
    width: '15px',
    height: '15px',
    backgroundColor: '#4F7091',
    color: '#FFFFFF'
  }
}));

export function TableMessagesReceived({ data, teammates, users, alertActions, updateInfo, userDetails }) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  const { getMessagesUnRead } = useMessagesCount()

  const [currentMessageId, setCurrentMessageId] = useState(null)
  const [openModalReplyMessage, setOpenModalReplyMessage] = useState(false);

  const handleCloseModalReplyMessage = () => {
    setCurrentMessageId(null);
    setOpenModalReplyMessage(false);
  };

  const setReadMessage = async (messageId, is_read) => {
    try {

      const payload = {
        owner_id: userDetails.mu_id
      };

      if (is_read) {
        return
      }
      const result = await api.post(`messages/${messageId}/read?organization_id=${userDetails?.organizationId}`, payload);
      if (result.status === 200) {
        updateInfo()
      }
      getMessagesUnRead(userDetails?.organizationId, userDetails?.mu_id)
    } catch (error) {
      // console.log('Response Error -> ', error);
      // alertActions.error(error)
    }
  }

  const handleClickReplyMessage = (event, messageId, is_read) => {
    event.preventDefault()
    setCurrentMessageId(messageId)
    setOpenModalReplyMessage(true)
    setReadMessage(messageId, is_read)
  }


  return (
    <>
      <table className="table-messages-received">
        <thead>
          <tr>
            <th
              style={{ width: '15%', textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.sent_at')}
            </th>
            <th
              style={{ width: '15%', textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.sent_by')}
            </th>
            <th
              style={{ width: 'auto', textAlign: 'left' }}
            >
              {t('messages.list.message')}
            </th>
            <th
              style={{ width: '15%', textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.destinations')}
            </th>
            <th
              style={{ width: '8%', textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              {t('messages.list.attach')}
            </th>
            <th
              style={{ width: '8%', textAlign: 'center' }}
            >
              {t('messages.list.actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map(message => (
            <tr
              className={`${message?.read === false ? '' : 'bgColorRead'}`}
              key={message.id}
              onClick={(e) => handleClickReplyMessage(e, message.id, message.read)}
            >
              <td
                style={{ textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}

              >
                {new Date(message?.datetime).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
              </td>

              <td
                style={{ textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}

              >
                {limitedText(message?.owner_name, 15)}
              </td>
              <td
                style={{ textAlign: 'left' }}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}

              >
                {limitedText(message.message_body, 50)}
              </td>
              <td
                style={{ textAlign: 'left', paddingLeft: '1rem', paddingRight: '1rem' }}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}

              >
                <AvatarGroup max={3} style={{ justifyContent: 'start' }}>
                  {
                    message.destination_id.length && message.destination_id?.map((item, key) => {
                      const nameTT = teammates?.find((tt) => tt.value == item)?.label
                      return (
                        <Tooltip key={key} title={nameTT}>
                          <Avatar
                            alt={nameTT}
                            src={getAvatarUrl({ name: nameTT })}
                            style={{ width: 28, height: 28 }}
                          />
                        </Tooltip>
                      )
                    })
                  }
                </AvatarGroup>
              </td>
              <td
                style={{ textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem'}}
                onClick={(e) => handleOpenReplyMessage(e, message.id, message.read)}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}
              >              
                <Badge
                  badgeContent={message?.attachments.length}
                  classes={{ badge: classes.badge2 }}
                >
                  <AttachFile
                   />
                </Badge>
              </td>
              <td
                style={{ textAlign: 'center' }}
                className={`${message?.read === false ? '' : 'bgColorRead'}`}

              >
                <div>
                  <Tooltip title={t('messages.list.tooltip_to_see')}>
                    <IconButton aria-label="details" className={classes.margin} size="small" onClick={(e) => handleClickReplyMessage(e, message.id, message.read)}>
                      {Number(message.total_unread_replies) === 0 ? (
                        <ChatBubbleOutline fontSize="small" style={{ color: '#187dff' }} />
                      ) : (
                        <Badge badgeContent={message.total_unread_replies} color="secondary" style={{ padding: '2px', minWidth: 16, height: 16 }}>
                          <ChatBubbleOutline fontSize="small" style={{ color: '#187dff' }} />
                        </Badge>
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      {
        currentMessageId && <ModalReplyMessage
          open={openModalReplyMessage}
          currentMessageId={currentMessageId}
          handleCloseModalReplyMessage={handleCloseModalReplyMessage}
          userDetails={userDetails}
          teammates={teammates}
          users={users}
          updateInfo={updateInfo}
        />
      }

      {data.length === 0 && (
        <div className="table-calls-not-content">{t('messages.no_message_received')}</div>
      )}
    </>
  )
}
