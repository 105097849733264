import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useState } from 'react';
import IconInfo from '../../../_icons/IconInfo';
import moment from 'moment'

import './styles.scss';
import { useRef } from 'react';
import { api } from '../../../../_helpers/api';
import { bindActionCreators } from 'redux';
import { alertActions } from '../../../../_actions';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { authHeader } from '../../../../_helpers';

const colorsStatus = [
  '#039be5',
  '#7986cb',
  '#33b679',
  '#8e24aa',
  '#e67c73',
  '#f6c026',
  '#f5511d',
  '#039be5',
  '#616161',
  '#3f51b5',
  '#0b8043',
  '#d60000'
]

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 250,
  }
}));

const CardEvent = ({ event, date, infoUser, accountIsActive, alertActions, getActiveAccountEvents, platform }) => {
  const { t, i18n } = useTranslation('common');
  const ref = useRef();

  const classes = useStyles();

  const initial_date = moment(new Date())
  const end_time = moment(new Date(event.end_time))
  const minutes_diff = moment.duration(end_time.diff(initial_date)).asMinutes()

  const [eventToConciliation, setEventToConciliation] = useState({
    isConciliation: null
  })

  const [disabledButton, setDisabledButton] = useState(true);

  const handleClickCheck = (isChecked, event) => {
    setEventToConciliation({
      ...event,
      event_id: event?.event_id,
      isConciliation: isChecked
    });
    toggleDialogConfirmation(event?.event_id)
    actionConfirmConciliation(event)
  }

  const handleClickRollbackEventConciliation = (isChecked, event) => {
    setEventToConciliation({
      ...event,
      event_id: event?.event_id,
      isConciliation: isChecked
    });
    setDisabledButton(false);
    toggleDialogRollbackConciliation(event?.event_id);
  }

  function toggleDialogConfirmation(id) {

    let elems = document.querySelectorAll(".confirmDialog.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById("confirm-" + id)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  function toggleDialogRollbackConciliation(id) {

    let elems = document.querySelectorAll(".confirmDialogRollbackReconcile.open")

    if (elems.length > 0) {
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
    }

    let elem = document.getElementById("confirmRollback-" + id)

    if (elem) {
      elem.classList.toggle("open")
    }

  }

  const actionConfirmConciliation = async (event) => {
    try {
      const response = await api.post(`integrations/google-calendar/to_integrate/confirm/?organization_id=${infoUser?.organization_id}`, {
        bef_token: infoUser?.mu_token,
        event_id: event?.event_id,
        email: accountIsActive?.email,
        start: event?.start_time,
        end: event?.end_time,
      },
        { headers: authHeader() }

      )
      if (response.data?.status) {
        setDisabledButton(false)
      } else {
        setDisabledButton(true)
        //alertActions.error(t(''))
      }
    } catch (error) {
      setDisabledButton(true)
      //alertActions.error(t(''))
      toggleDialogConfirmation()
    }
  }

  const actionConciliation = async () => {

    try {
      if (eventToConciliation) {
        setDisabledButton(true)
        const response = await api.post(`integrations/google-calendar/to_integrate/?organization_id=${infoUser?.organization_id}`, {
          bef_token: infoUser?.mu_token,
          event_id: eventToConciliation?.event_id,
          email: accountIsActive?.email,
          start: eventToConciliation?.start_time,
          end: eventToConciliation?.end_time,
          platform: platform
        },

          { headers: authHeader() }
        )
        if (response.status === 200) {
          setDisabledButton(false)
          getActiveAccountEvents()
        } else {
          setDisabledButton(true)
          //alertActions.error(t(''))
        }

      } else {
        console.log('no event to reconcile!')
      }

    } catch (error) {
      setDisabledButton(true)
      //alertActions.error(t(''))
      toggleDialogConfirmation()
    }
  }

  const actionRollbackConciliation = async () => {

    try {
      if (eventToConciliation) {
        setDisabledButton(true)
        const response = await api.post(`integrations/google-calendar/rollback_integration/?organization_id=${infoUser?.organization_id}`, {
          bef_token: infoUser?.mu_token,
          event_id: eventToConciliation?.event_id,
          email: accountIsActive?.email
        },

          { headers: authHeader() }

        )
        if (response.status === 200) {
          setDisabledButton(false)
          getActiveAccountEvents()
          toggleDialogRollbackConciliation()
        } else {
          setDisabledButton(true)
          //alertActions.error(t(''))
        }

      } else {
        console.log('no event to reconcile!')
      }

    } catch (error) {
      setDisabledButton(true)
      //alertActions.error(t(''))
      toggleDialogConfirmation()
    }
  }



  return (
    <div className='card-container-calendar actions'>
      <div className='card-event' style={{ borderLeft: `8px solid ${colorsStatus[event.color_id]}` }}>

        <div className='header'>
          <div>
            <h4>{event?.title}</h4>
            <span>{event?.organizer}</span>
          </div>

          <h3>{moment(date).format('DD/MM')}</h3>
        </div>

        <div className='content-date'>
          <span>{moment(date).format("dddd, DD MMMM")}</span>
          <h5>
            {moment(event.start_time).format('HH:mm') + ' até ' + moment(event.end_time).format('HH:mm')}
          </h5>
        </div>

      </div>

      <div className='box-checkbox'>
        {
          event?.can_reconcile && !event.is_checked
            ?
            (minutes_diff <= 0
              ? (
                <label className={`containerCheck`} htmlFor={`event-${event?.event_id}`} >
                  {t('integrations.reconcile')}
                  <input type="checkbox" name="conciliation" id={`event-${event?.event_id}`} onClick={(e) => handleClickCheck(e.target.checked, event)} />
                  <span className={`checkmark`}></span>
                </label>
              ) : (
                <Tooltip classes={{ tooltip: classes.customWidth }} title={<Typography variant='subtitle2' >{t('integrations.info_conciliation_unavailable')}</Typography>} arrow>
                  <div className='conciliation-unavailable'>
                    <span>{t('integrations.no_future_trackeds')}</span>
                    <IconInfo />
                  </div>
                </Tooltip>
              ))
            :
            (event.is_checked ? (
              <label className={`containerCheck checked`} htmlFor={`event-${event?.event_id}`} >
                {t('integrations.reconciled')}
                <input type="checkbox" name="conciliation" id={`event-${event?.event_id}`} onClick={(e) => handleClickRollbackEventConciliation(e.target.checked, event)} />
                <span className={`checkmark checked`}></span>
              </label>
            ) : (
              <Tooltip classes={{ tooltip: classes.customWidth }} title={<Typography variant='subtitle2' >{t('integrations.info_conciliation_unavailable')}</Typography>} arrow>
                <div className='conciliation-unavailable'>
                  <span>{t('integrations.conciliation_unavailable')}</span>
                  <IconInfo />
                </div>
              </Tooltip>
            ))

        }

      </div>

      <div className="confirmDialog" id={"confirm-" + event?.event_id} ref={ref}>
        <div className="content">
          <div className="message">
            <IconInfo />
            {t('integrations.confirmDialogMessage')}
          </div>
          <div className='subtitle'>
            {t('integrations.subtitle_confirm')}
          </div>
          <div className="options">
            <button onClick={() => { toggleDialogConfirmation() }}>{t('no')}</button>
            <button disabled={disabledButton} onClick={() => { actionConciliation() }}>{t('yes')}</button>
          </div>
        </div>
      </div>

      <div className="confirmDialogRollbackReconcile" id={"confirmRollback-" + event?.event_id} ref={ref}>
        <div className="content">
          <div className="message">
            <IconInfo />
            {t(t('integrations.confirmDialogMessageRollback'))}
          </div>
          <div className='subtitle'>
            {t('integrations.subtitle_confirm_rollback')}
          </div>
          <div className="options" style={{ marginTop: 12 }}>
            <button onClick={() => { toggleDialogRollbackConciliation() }}>{t('no')}</button>
            <button disabled={disabledButton} onClick={() => { actionRollbackConciliation() }}>{t('yes')}</button>
          </div>
        </div>
      </div>

    </div>
  );
}

function mapStateToProps(state) {

  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardEvent));