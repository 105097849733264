import React from 'react';

const IconIntegrations = (props) => {
  return (
    <svg className="icon" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Retângulo_1584" data-name="Retângulo 1584" width="20" height="20" fill="none" />
        <rect id="Retângulo_1585" data-name="Retângulo 1585" width="20" height="20" fill="none" />
        <rect id="Retângulo_1586" data-name="Retângulo 1586" width="20" height="20" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(1.675 0.833)">
        <path id="Caminho_3741" data-name="Caminho 3741" d="M19.012,8.666a2.583,2.583,0,0,0-2.375,1.667H13.1a4.125,4.125,0,0,0-.625-1.375L13.928,7.25a3.25,3.25,0,0,0,.917.167,2.5,2.5,0,1,0-2.5-2.5,2.542,2.542,0,0,0,.333,1.25l-1.5,1.708a3.542,3.542,0,0,0-1.75-.458L8.72,7.5l-.5-1.167A2.5,2.5,0,1,0,6.512,7h.167L7.22,8.166a3.708,3.708,0,0,0,0,6l-.542,1.167H6.512A2.5,2.5,0,1,0,8.22,16l.5-1.167.708.083a3.542,3.542,0,0,0,1.75-.458l1.5,1.708a2.542,2.542,0,0,0-.333,1.25,2.5,2.5,0,1,0,2.5-2.5,3.25,3.25,0,0,0-.917.167L12.47,13.375A4.125,4.125,0,0,0,13.1,12h3.542a2.583,2.583,0,0,0,2.375,1.667,2.5,2.5,0,0,0,0-5ZM5.678,4.5a.833.833,0,1,1,.833.833A.833.833,0,0,1,5.678,4.5Zm.833,14.167a.833.833,0,1,1,.833-.833A.833.833,0,0,1,6.512,18.666ZM14.845,4.083a.833.833,0,1,1-.833.833A.833.833,0,0,1,14.845,4.083Zm-7.5,7.083A2.083,2.083,0,1,1,9.428,13.25,2.083,2.083,0,0,1,7.345,11.166Zm8.333,6.25a.833.833,0,1,1-.833-.833A.833.833,0,0,1,15.678,17.416ZM19.012,12a.833.833,0,1,1,.833-.833A.833.833,0,0,1,19.012,12Z" transform="translate(-4.02 -1.999)" fill="#fdbe2b" />
      </g>
    </svg>
  )
}

export default IconIntegrations;