import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import {useTranslation, initReactI18next} from "react-i18next";

import IconStart from "../_icons/IconStart";
import {RadialChart} from "react-vis";


function PeriodProductivity(props) {
    const {t, i18n} = useTranslation('common');

    let productivity = props.productivity || {};

    const myData = [{
        angle: productivity.TotalProductiveTime,
        color: '#22bead'
    },
        {
            angle: productivity.TotalNeutralTime,
            color: '#ffc997'
        },
        {
            angle: productivity.TotalUnproductiveTime,
            color: '#fc6662'
        }]

    let PerProductive = 0;
    let PerNeutral = 0;
    let PerUnproductive = 0;

    if (productivity.TotalWorkedTime > 0) {
        if (productivity.TotalProductiveTime > 0) {
            PerProductive = Math.round((parseInt(productivity.TotalProductiveTime) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
        if (productivity.TotalNeutralTime > 0) {
            PerNeutral = Math.round((parseInt(productivity.TotalNeutralTime) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
        if (productivity.TotalUnproductiveTime > 0) {
            PerUnproductive = Math.round((parseInt(productivity.TotalUnproductiveTime) / parseInt(productivity.TotalWorkedTime)) * 100);
        }
    }

    return (
        <div className={'box graph'}>
            <h3>{t('productivity.productivity')}</h3>
            <div className={'helper'}>
                <ul>
                    <li className={'related'}>{t('productivity.productive')} ({PerProductive}%)</li>
                    <li>{t('productivity.neutral')} ({PerNeutral}%)</li>
                    <li className={'unrelated'}>{t('productivity.unproductive')} ({PerUnproductive}%)</li>
                </ul>
            </div>
            <div className={'piechart'}>
                {productivity.TotalWorkedTime > 0 &&
                    <RadialChart
                        data={myData}
                        colorType="literal"
                        stroke={'#fff'}
                        width={400}
                        height={400}/>
                }
                {productivity.TotalWorkedTime == 0 &&
                    t('productivity.not_set')
                }
            </div>

        </div>
    )
};


function mapStateToProps(state) {

    return {
        productivity: state.stats.payload
    }

}

export default connect(mapStateToProps)(PeriodProductivity)
