import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { useTranslation, initReactI18next } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{fontSize:16}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function ModalDetailCategory({ openModalDetailCategory, setOpenModalDetailCategory, categoryId, categories, category, userDetails }) {

  const { t, i18n } = useTranslation('common');

  const handleClose = () => {
    setOpenModalDetailCategory(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openModalDetailCategory} PaperProps={{style: { borderRadius: 16 } }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {userDetails?.language === 'en' && category?.name_en}
          {userDetails?.language === 'pt' && category?.name_pt}
          {userDetails?.language === 'es' && category?.name_es}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {userDetails?.language === 'en' && t('category.description')+': '+category?.description_en}
            {userDetails?.language === 'pt' && t('category.description')+': '+category?.description_pt}
            {userDetails?.language === 'es' && t('category.description')+': '+category?.description_es}
          </Typography>
          <Typography gutterBottom>
            {t('category.examples')+': '+category?.examples}
          </Typography>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
