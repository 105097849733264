import React from 'react';

const IconPeriodicReports = props => {
    return (
        <svg height="48px" id="Layer_4" version="1.1" viewBox="0 0 48 48" width="48px" className="icon">
            <g>
                <polygon
                    points="25.916,8 25.916,23.359 32.992,30.434 30.164,33.262 21.9,25 21.916,24.983 21.916,8  "/>
                <path
                    d="M2.216,34.138H0v4.747h2.216c0.271,1.058,0.711,2.051,1.306,2.934l-1.521,1.522l2.693,2.691l1.544-1.543   c0.852,0.554,1.797,0.972,2.808,1.231v2.216h4.761v-2.216c1.033-0.267,2.002-0.695,2.867-1.269l1.486,1.484l2.692-2.691   l-1.485-1.484c0.572-0.864,1-1.831,1.268-2.862h2.218v-4.776h-2.218c-0.262-1.007-0.677-1.951-1.23-2.8l1.448-1.449l-2.692-2.692   l-1.426,1.425c-0.879-0.591-1.865-1.029-2.918-1.302v-2.22h-4.78v2.22c-1.03,0.268-1.994,0.694-2.858,1.267l-1.483-1.486   l-2.693,2.694l1.484,1.484C2.909,32.131,2.481,33.103,2.216,34.138z M7.51,36.511c0-2.163,1.754-3.917,3.916-3.917   c2.164,0,3.918,1.754,3.918,3.917s-1.754,3.917-3.918,3.917C9.264,40.428,7.51,38.674,7.51,36.511z"
                />
                <path d="M0.025,22.997v2.007C0.012,24.67,0,24.336,0,24S0.012,23.33,0.025,22.997z"/>
                <path
                    d="M30.083,9.958L31.041,9H31l2.555-2.555C30.715,4.893,27.465,4,24,4C13.322,4,4.624,12.375,4.055,22.911   H0.027C0.598,10.162,11.11,0,24,0c4.576,0,8.845,1.293,12.483,3.517L39,1v0.042L40.041,0v9.715l-0.243,0.243H30.083z"
                />
                <path
                    d="M44,24c0-2.868-0.618-5.585-1.709-8.048l2.998-2.998C47.014,16.261,48,20.012,48,24   c0,13.255-10.745,24-24,24v-4C35.046,44,44,35.046,44,24z"
                />
            </g>
        </svg>
    );
};

export default IconPeriodicReports
