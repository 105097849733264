export const statsConstants = {
    GET_STATS_REQUEST: '[STATS] GET_STATS_REQUEST',
    GET_STATS_SUCCESS: '[STATS] GET_STATS_SUCCESS',
    GET_STATS_FAILURE: '[STATS] GET_STATS_FAILURE',
    GET_STATS_REALTIME_REQUEST: '[STATS] GET_STATS_REALTIME_REQUEST',
    GET_STATS_REALTIME_SUCCESS: '[STATS] GET_STATS_REALTIME_SUCCESS',
    GET_STATS_REALTIME_FAILURE: '[STATS] GET_STATS_REALTIME_FAILURE',
    GET_STATS_REALTIME_TOOLTIP_REQUEST: '[STATS] GET_STATS_REALTIME_TOOLTIP_REQUEST',
    GET_STATS_REALTIME_TOOLTIP_SUCCESS: '[STATS] GET_STATS_REALTIME_TOOLTIP_SUCCESS',
    GET_STATS_REALTIME_TOOLTIP_FAILURE: '[STATS] GET_STATS_REALTIME_TOOLTIP_FAILURE',
    GET_PRODUCTIVITY_REQUEST: '[STATS] GET_PRODUCTIVITY_PRODUCTIVITY_REQUEST',
    GET_PRODUCTIVITY_SUCCESS: '[STATS] GET_PRODUCTIVITY_PRODUCTIVITY_SUCCESS',
    GET_PRODUCTIVITY_FAILURE: '[STATS] GET_PRODUCTIVITY_PRODUCTIVITY_FAILURE',
    GET_STATS_INACTIVITY_REQUEST: '[STATS] GET_STATS_INACTIVITY_REQUEST',
    GET_STATS_INACTIVITY_SUCCESS: '[STATS] GET_STATS_INACTIVITY_SUCCESS',
    GET_STATS_INACTIVITY_FAILURE: '[STATS] GET_STATS_INACTIVITY_FAILURE',
};

