import React, { createContext, useContext, useEffect, useState } from "react";

const CurrentModuleContext = createContext({})

export function CurrentModuleProvider({children}) {
  const [currentModule, setCurrentModule] = useState(null)

  async function saveCurrentModule(module) {
    setCurrentModule(module)
    localStorage.setItem('befective:currentModule', JSON.stringify(module));
  }

  async function removeCurrentModule() {
    setCurrentModule(null)
    localStorage.removeItem('befective:currentModule');
  }

  async function saveFirstAccess(value) {
    localStorage.setItem('befective:firstAccess', JSON.stringify(value));
  }

  useEffect(() => {
    const storedModule = localStorage.getItem('befective:currentModule');

    if (storedModule) {
      setCurrentModule(JSON.parse(storedModule));
    } else {
      setCurrentModule(null)
    }
  }, []);
  
  return (
    <CurrentModuleContext.Provider value={{ currentModule, setCurrentModule, saveCurrentModule, removeCurrentModule, saveFirstAccess }}>
      {children}
    </CurrentModuleContext.Provider>
  )
}

export const useCurrentModule = () => useContext(CurrentModuleContext)