import { DotsSixVertical, Envelope } from 'phosphor-react';
import React from 'react';
import IconDetail from '../../_icons/IconDetail';


export function ListColabItem({
  openDialogConfirm,
  add,
  teammate,
  generateToken,
  currentIndex,
  prevGroupId,
  currentGroupId,
  unique_code,
  innerRef,
  provided
}) {
  return (
    <li
      ref={innerRef} 
      {...provided.draggableProps} 
      id={"teammate" + teammate.id}
      className={
        currentIndex === '0'
          ? `t${currentIndex} tm team${currentGroupId} hide-display`
          : `t${currentIndex} tm team${prevGroupId} team${currentGroupId} hide-display`
      }
    >
      <div  {...provided.dragHandleProps}>
        <DotsSixVertical size={28} color="#98ACC1" weight="fill" />
      </div>
      <div className="team"> &nbsp;&nbsp;{teammate.name}</div>
      <div className="subteams"></div>
      <div className="teammates"></div>
      <div className="email">{teammate.email}</div>
      <div className="token">
        {generateToken(unique_code, teammate.id)}
      </div>
      <div className="actions">
        <button
          className="add"
          onClick={() => {
            add(teammate.id, 'teammate', teammate.name)
          }}
        >
          <IconDetail />
        </button>
        <button
          className="invite"
          onClick={(e) => {
            openDialogConfirm(e, teammate.id, 'teammate')
          }}
        >
          <Envelope color='#187DFF' />
        </button>
      </div>
    </li>
  )
};
