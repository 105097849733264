import React, { useState } from 'react'
import Select from 'react-select';
import { Backdrop, Checkbox, Fade, FormControlLabel, Modal, Typography, withStyles } from '@material-ui/core'

import IconSearch from '../../_icons/IconSearch';

import './styles.scss'
import Avatar from 'react-avatar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from '../../_icons/IconClose';


const GreenCheckbox = withStyles({
  root: {
    color: '#E4EAEF',
    "&$checked": {
      color: '#22BEAD'
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export function ModalAddTeamTask({ 
  assistants, 
  setAssistants, 
  openModalAddTeamTask, 
  classes, 
  setOpenModalAddTeamTask, 
  groups,
  setSubmitted = null,
  assistantsBackup = null
}) {
  const { t } = useTranslation('common');
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [groupsFormatted, setGroupsFormatted] = useState([]);
  const [groupsFiltered, setGroupsFiltered] = useState(groupsFormatted);

  const groupsOptions = [
    {
      label: 'Todos',
      value: ''
    },
    ...groups?.map(item => {
      return {
        label: item.label,
        value: item.id
      }
    })
  ]

  const formatGroups = () => {
    const groupsFormattedTemp = groups?.reduce((acumulador, item) => {
      return [...acumulador, {
        id: item.id,
        label: item.label,
        users: item.children
      }]
    }, [])

    setGroupsFormatted(groupsFormattedTemp)
    setGroupsFiltered(groupsFormattedTemp)
  }

  const handleChangeCheck = (group_id) => {
    const checkIfIsChecked = checkedGroups?.filter(item => item === group_id)

    if (checkIfIsChecked.length > 0) {
      const groupsFilteredTemp = checkedGroups?.filter(item => item !== group_id)
      setCheckedGroups(groupsFilteredTemp);

      const groupFiltered = groupsFiltered?.find(group => group.id === group_id)
      const usersIds = groupFiltered?.users?.reduce((acc, item) => {
        return [
          ...acc, 
          item.id
        ]
      }, [])

      const assistantsFiltered = assistants?.filter(user => !usersIds.includes(user.id))

      setAssistants(assistantsFiltered)
    }else {
      setCheckedGroups([...checkedGroups, group_id]);
      
      const groupFiltered = groupsFiltered?.find(group => group.id === group_id)
      const assistantsTemp = []

      groupFiltered?.users?.forEach(userByGroup => {
        const userAlreadyChecked = assistants?.filter(user => user.id === userByGroup.id)

        if (userAlreadyChecked.length <= 0) {
          assistantsTemp.push({ 
            id: userByGroup.id, 
            username: userByGroup.label 
          })
        }
      })

      setAssistants([...assistants, ...assistantsTemp])
    }
  };

  const handleAddAssistant = (user_id, user_label) => {
    const assistantExists = assistants?.filter(item => item.id === user_id);
    if (assistantExists.length > 0) {
      const assistantsFiltered = assistants?.filter(item => item.id !== user_id);
      setAssistants(assistantsFiltered)
    }else {
      setAssistants([...assistants, { id: user_id, username: user_label }])
    }
  }

  const filterGroups = (group) => {

    if(group.value === ''){
      setGroupsFiltered(groupsFormatted)
    } else {
      const groupsFilteredTemp = groupsFormatted.filter(item => item.id === group.value)
      
      setGroupsFiltered(groupsFilteredTemp)
    }
  }

  const onFilterChange = (e) => {
    let groupsFilteredTemp = []

    groupsFormatted.forEach((group) => {
      const filter = group.users.filter(user => user.label.includes(e.target.value))

      if (filter.length > 0) {
        groupsFilteredTemp.push({...group, users: filter})
      }
    });
    setGroupsFiltered(groupsFilteredTemp)
  }

  const handleCloseModalAddTeamTask = () => {
    setOpenModalAddTeamTask(false)
    setGroupsFiltered(groupsFormatted)
    if (setSubmitted) {
      setSubmitted(true)
    }
    // setCheckedGroups([])
  }

  function handleCancel() {
    setOpenModalAddTeamTask(false)
    setGroupsFiltered(groupsFormatted)
    setCheckedGroups([])
    if(!setSubmitted) {
      setAssistants([])
    }

    if (setSubmitted) {
      setAssistants(assistantsBackup)
      setSubmitted(true)
    }
  }


  useEffect(() => {
    formatGroups()
  }, [groups]) 
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalAddTeamTask}
      onClose={handleCloseModalAddTeamTask}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ border: 'none' }}
    >
      <Fade in={openModalAddTeamTask} >
        <div className='modal-add-team-task-container'>
          <div className="box-button-close">
            <button onClick={handleCloseModalAddTeamTask} className="close" type='button'><IconClose /></button>
          </div>
          <header>
            <h2>{t('modalAddTeam.label')}</h2>

            <div className='filters'>
              <Select
                defaultValue={groupsOptions[0]}
                name="teamsFilter"
                options={groupsOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => filterGroups(e) }
                placeholder={t('modalAddTeam.filter1')}
              />


              <div className='inputSearch'>
                <label htmlFor='inputSearchAddTime'>
                  <IconSearch width="13" height="13" />
                </label>
                <input
                  id='inputSearchAddTime'
                  type="text"
                  name='searchTeamAndTeammate'
                  placeholder={t('modalAddTeam.filter2')}
                  onChange={onFilterChange}
                />
              </div>
            </div>
          </header>

          <main className='check-box-container'>
            {groupsFiltered?.map(group => (
              <div key={group.id} className='check-content'>
                <div className='group-check'>
                  <FormControlLabel
                    control={
                      <GreenCheckbox 
                        checked={
                          checkedGroups.filter(item => item === group.id).length > 0 
                        } 
                        onChange={() => handleChangeCheck(group.id)} 
                        classes={{root:classes.checkbox}}
                      />
                    }
                    label={<Typography style={{ color: '#4F7091', fontSize: '16px', fontWeight: '500' }}>{group.label}</Typography>}
                  />
                </div>

                <ul className='users-check-content'>
                  {group.users?.map(user => (
                    <li key={user.id}>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={
                              assistants?.filter(item => item.id === user.id).length > 0
                            }
                            onClick={() => handleAddAssistant(user.id, user.label)}
                            classes={{ root: classes.checkbox }}
                          />
                        }
                        label={
                          <Typography style={{ color: assistants?.filter(item => item.id === user.id).length > 0 ? '#22BEAD' : '#4F7091',  }}>
                            {user.label}
                          </Typography>
                        }
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </main>
          <footer className='footer-container'>
            <button onClick={() => handleCancel()}>{t('button.cancel')}</button>
            <button className='save-button' onClick={() => handleCloseModalAddTeamTask()}>{t('button.save')}</button>
          </footer>
        </div>
      </Fade>
    </Modal>
  )
}

