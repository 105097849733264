import React from 'react';
import { history } from '../../_helpers';

const SubspaceIconLaptop = ({
        x,
        y,
        rotationAngle,
        onMouseDown = () => { },
        onDoubleClick = () => { },
        urlRedirect = null
}) => {
        return (
                // <svg x={x} y={y} width={30} height={30} style={{ cursor: 'pointer', transform: `rotate(${rotationAngle}deg)` }}
                //         viewBox="0 0 512 512">
                //         <path style={{ fill: "#C9C6CA" }} d="M437.697,462.474H74.303c-13.684,0-24.777-11.093-24.777-24.777V181.669h412.948v256.028
                //     C462.474,451.381,451.381,462.474,437.697,462.474z"/>
                //         <path style={{ fill: "#6F6571" }} d="M496.285,49.526H15.715c-11.32,0-18.919,11.618-14.382,21.988l48.193,110.155h412.948l48.193-110.155
                //     C515.204,61.144,507.605,49.526,496.285,49.526z"/>
                //         <path style={{ fill: "#5D5360" }} d="M388.143,346.849H123.857c-4.561,0-8.259-3.698-8.259-8.259V222.964c0-4.561,3.698-8.259,8.259-8.259
                //     h264.287c4.561,0,8.259,3.698,8.259,8.259V338.59C396.402,343.151,392.705,346.849,388.143,346.849z"/>
                //         <path style={{ fill: "#DBD9DC" }} d="M189.928,346.849v66.072c0,9.122,7.395,16.518,16.518,16.518h99.107
                //     c9.122,0,16.518-7.396,16.518-16.518v-66.072H189.928z"/>
                //         <polygon style={{ fill: "#5D5360" }} points="41.267,82.562 82.562,181.669 429.438,181.669 470.733,82.562 " />
                //         <g>
                //                 <path style={{ fill: "#6F6571" }} d="M421.179,198.187H90.821c-4.561,0-8.259-3.698-8.259-8.259v-8.259h346.876v8.259
                //       C429.438,194.49,425.74,198.187,421.179,198.187z"/>
                //                 <rect x="115.6" y="239.48" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                //                 <rect x="115.6" y="272.52" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                //                 <rect x="115.6" y="305.55" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                //         </g>
                //         <g>
                //                 <path style={{ fill: "#B7B2B8" }} d="M66.044,363.366V181.669H49.526v256.028c0,13.684,11.093,24.777,24.777,24.777h363.394
                //       c10.759,0,19.951-6.932,23.366-16.518H148.634C103.021,445.956,66.044,408.979,66.044,363.366z"/>
                //                 <path style={{ fill: "#B7B2B8" }} d="M461.267,444.925c0.013-0.043,0.036-0.083,0.049-0.126
                //       C461.173,444.841,461.165,444.883,461.267,444.925z"/>
                //         </g>

                // </svg>

                <svg x={x} y={y} width={30} height={30} onMouseDown={onMouseDown} style={{ cursor: 'pointer' }}
                        viewBox="0 0 512 512" onClick={() => urlRedirect && history.push(urlRedirect)}>
                        <g transform={`rotate(${rotationAngle} 250 250)`} onDoubleClick={onDoubleClick}>
                                <path style={{ fill: "#C9C6CA" }} d="M437.697,462.474H74.303c-13.684,0-24.777-11.093-24.777-24.777V181.669h412.948v256.028
                  C462.474,451.381,451.381,462.474,437.697,462.474z"/>
                                <path style={{ fill: "#6F6571" }} d="M496.285,49.526H15.715c-11.32,0-18.919,11.618-14.382,21.988l48.193,110.155h412.948l48.193-110.155
                  C515.204,61.144,507.605,49.526,496.285,49.526z"/>
                                <path style={{ fill: "#5D5360" }} d="M388.143,346.849H123.857c-4.561,0-8.259-3.698-8.259-8.259V222.964c0-4.561,3.698-8.259,8.259-8.259
                  h264.287c4.561,0,8.259,3.698,8.259,8.259V338.59C396.402,343.151,392.705,346.849,388.143,346.849z"/>
                                <path style={{ fill: "#DBD9DC" }} d="M189.928,346.849v66.072c0,9.122,7.395,16.518,16.518,16.518h99.107
                  c9.122,0,16.518-7.396,16.518-16.518v-66.072H189.928z"/>
                                <polygon style={{ fill: "#5D5360" }} points="41.267,82.562 82.562,181.669 429.438,181.669 470.733,82.562 " />
                                <g>
                                        <path style={{ fill: "#6F6571" }} d="M421.179,198.187H90.821c-4.561,0-8.259-3.698-8.259-8.259v-8.259h346.876v8.259
                    C429.438,194.49,425.74,198.187,421.179,198.187z"/>
                                        <rect x="115.6" y="239.48" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                                        <rect x="115.6" y="272.52" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                                        <rect x="115.6" y="305.55" style={{ fill: "#6F6571" }} width="280.81" height="16.518" />
                                </g>
                                <g>
                                        <path style={{ fill: "#B7B2B8" }} d="M66.044,363.366V181.669H49.526v256.028c0,13.684,11.093,24.777,24.777,24.777h363.394
                    c10.759,0,19.951-6.932,23.366-16.518H148.634C103.021,445.956,66.044,408.979,66.044,363.366z"/>
                                        <path style={{ fill: "#B7B2B8" }} d="M461.267,444.925c0.013-0.043,0.036-0.083,0.049-0.126
                    C461.173,444.841,461.165,444.883,461.267,444.925z"/>
                                </g>
                        </g>

                </svg>

        )
}

export default SubspaceIconLaptop;