import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';


import {useTranslation, initReactI18next} from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function TimeGoalProductivitySkeleton(props) {
    const {t, i18n} = useTranslation('common');


    return (
        <div className={'box goal'}>
            <h3><Skeleton variant="text" width={180} height={32}/></h3>
            <div className={'helper'}><Skeleton variant="text" width={160} height={22}/></div>
            <Skeleton style={{marginTop: '10px'}} variant="text" width="100%" height={22}/>

        </div>
    )
};

export default TimeGoalProductivitySkeleton;
