import React, {useState, useCallback, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'


import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import HistoricalByHour from '../_components/historical/HistoricalByHour.jsx'
import HistoricalByHourSkeleton from '../_components/historical/HistoricalByHourSkeleton'

import {useTranslation, initReactI18next} from "react-i18next";
import Moment from 'moment';

import {filterActions, historicalActions} from '../_actions';
import {statsActions} from '../_actions';
import IconClose from "../_components/_icons/IconClose";
import DrawerHistoricalSkeleton from "../_components/historical/DrawerHistoricalSkeleton";
import DrawerHistorical from "../_components/historical/DrawerHistorical";
import {useHistory} from "react-router-dom";


function HistoricalPageByHour(props) {
    const {t, i18n} = useTranslation('common');

    const {historicalActions} = props;
    const {filterActions} = props;

    let loading = props.loading;
    let filter = props.filter || {};

    const history = useHistory();

    const isMountedTeammates = useRef(false);
    const isMountedDate = useRef(false);


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const [loadMore, setLoadMore] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const maxDate = new Date(Moment().add(-1, 'd'));

    const [drawer, setDrawer] = useState(false);
    const [drawerData, setDrawerData] = useState({});
    const [drawerCurrentIndex, setDrawerCurrentIndex] = useState();

    const [view, setView] = useState('summary');
    const [openWho, setOpenWho] = useState('');
    const [clearWho, setClearWho] = useState('');
    const [dayToShow, setDayToShow] = useState(null);
    const [whenFilter, setWhenFilter] = useState(true);

    const isLoadingDrawer = useRef(false);

    let date = new Date();
    let startDate = new Date(Moment().add(-7, 'd')).toISOString().split('T')[0];
    let endDate = date.toISOString().split('T')[0];
    //coment
    function openDrawer(data) {

        if (filter.teammates && filter.teammates[0]) {
            isLoadingDrawer.current = true;

            setDrawerData({
                "hour": data.hour,
                "MachineUserName": data.MachineUserName
            });

            let teammates = filter.teammates;

            historicalActions.getHistoricalHourActivities(data.hour, teammates[0].id);

        }

    }

    function changeDrawer(data) {

        if (filter.teammates && filter.teammates[0]) {
            isLoadingDrawer.current = true;
            setDrawer(false);
            setDrawerData({
                "hour": data.hour,
                "MachineUserName": data.MachineUserName
            });

            let teammates = filter.teammates;
            historicalActions.getHistoricalHourActivities(data.hour, teammates[0].id);

        }

    }

    useEffect(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        setDayToShow(params.day)

        if (params.mu && params.day) {

            historicalActions.getHistoricalTeammate(params.day, params.mu);

            let id = params.mu;
            filterActions.replace(params.day, "singleWhen");
            filterActions.replace({id}, "teammates");
        }


    }, [])

    useEffect(() => {

        if (isMountedTeammates.current) {
            if (!props.historical.loading) {
                let teammates = null;
                if (props.filter.teammates && props.filter.teammates[0] && dayToShow) {

                    teammates = props.filter.teammates;
                    history.push("/productive/historical/hour?mu=" + teammates[0].id + "&day=" + dayToShow)
                    historicalActions.getHistoricalTeammate(dayToShow, teammates[0].id);
                }
            }
        } else {
            isMountedTeammates.current = true;
        }


    }, [props.filter.teammates]);

    useEffect(() => {
        if (isMountedDate.current) {
            if (!props.historical.loading) {
                let teammates = null;

                if (props.filter.singleDate) {
                    setDayToShow(props.filter.singleDate)


                    if (props.filter.teammates && props.filter.teammates[0]) {

                        teammates = props.filter.teammates;
                        history.push("/productive/historical/hour?mu=" + teammates[0].id + "&day=" + props.filter.singleDate)
                        historicalActions.getHistoricalTeammate(props.filter.singleDate, teammates[0].id);
                    }
                }
            }
        } else {
            isMountedDate.current = true;
        }

    }, [props.filter.singleDate]);

    useEffect(() => {

        if (isLoadingDrawer.current === true && props.historical.loading === false) {

            setDrawer(true);
            isLoadingDrawer.current = false;
        }


    }, [props.historical.hourActivities]);


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.historical');

    });

    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar/>


                <Filterbar defaultStartDate={startDate}
                           defaultEndDate={endDate}
                           openWho={openWho}
                           filterWho={'singleTeammates'}
                           filterWhen={'single'}
                           filterWhat={'disabled'}
                    //maxDate={maxDate}
                />


                {/* PAGE STARTS */}
                <div className="mainPage">


                    <div className="column single">
                        {!minimumTimeElapsed || loading === true
                            ? <HistoricalByHourSkeleton/>
                            : <HistoricalByHour day={dayToShow} openDrawer={(data) => {
                                openDrawer(data)
                            }} back={(data) => {
                                setOpenWho('');
                                setDayToShow(null);
                                setView('summary');
                                setWhenFilter('show');
                                filterActions.clear('who')
                            }}/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}

                <div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
                    setDrawer(false)
                }}></div>
                <section className={'drawer' + (drawer ? ' open' : '')}>
                    <button onClick={() => {
                        setDrawer(false)
                    }} className="close"><IconClose/></button>
                    {
                        !drawer || !props.historical.hourActivities
                            ? <DrawerHistoricalSkeleton/>
                            : <DrawerHistorical current={drawerData} changeDrawer={(data) => {
                                changeDrawer(data)
                            }}/>
                    }
                </section>

            </section>

        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        historicalActions: bindActionCreators(historicalActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.historical.loading,
        loadingType: state.historical.loadingType,
        filter: state.filter,
        teammates: state.teammates,
        historical: state.historical
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalPageByHour)
