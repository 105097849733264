import React from 'react';

const IconOut = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.714" height="12" viewBox="0 0 13.714 12">
      <path id="Icon_open-account-logout" data-name="Icon open-account-logout" d="M5.143,0V1.714H12v8.571H5.143V12h8.571V0ZM3.429,3.429,0,6,3.429,8.571V6.857h6.857V5.143H3.429Z" fill={props.fill} />
    </svg>

  )
}

export default IconOut;