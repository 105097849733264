import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { befectiveFormatDate } from '../../../_helpers';

import ObjectivesTable from '../TableObjective';
import CollapsibleTableNew from './NewCollapsibleTable';

import './styles.scss'

export function ObjectivesFooter({ goalsData, krsData, colabsData, teamsData,  userDetails, updateDashboardInfo, teammates }) {
  const { t, i18n } = useTranslation('common');
  const [selectedMenu, setSelectedMenu] = useState('goals')

  const columnsObjectivesTable = [
    { field: "objective", header: t('goals.title_table') },
    { field: "krs", header: "Krs" },
    { field: "progress", header: t('goals.progress') },
    { field: "start", header: t('goals.start') },
    { field: "end", header: t('goals.end') },
    // { field: "published", header: t('goals.published') },
    { field: "responsible", header: t('goals.responsible') }
  ];

  const columnsTeams = [
    { field: "team", header: t('goals.right_table.team') },
    { field: "objective", header: t('goals.g_short') },
    { field: "kr", header: "kr" },
    { field: "activity", header: t('goals.right_table.activity') },
    { field: "percent", header: t('goals.right_table.progress') },
  ];

  const columnsTeammates = [
    { field: "teammate", header: t('goals.right_table.teammate') },
    { field: "objective", header: t('goals.right_table.objectives') },
    { field: "objective_progress", header: t('goals.right_table.progress_objectives') },
    { field: "kr", header: "krs" },
    { field: "kr_progress", header: t('goals.right_table.progress_krs') },
    { field: "activity", header: t('goals.right_table.activity') },
    { field: "activity_progress", header: t('goals.right_table.progress_activities') },
    // { field: "percent", header: "Prog." },
  ];

 

  const tableDataGoals = goalsData?.map(item => {
    return {
      id: item.id,
      objective: item.title,
      krs: item.kr,
      progress: item.progress,
      start: item.date_start ? befectiveFormatDate(item.date_start, i18n.language) : '---',
      end: item.date_end ? befectiveFormatDate(item.date_end, i18n.language) : '---',
      key_results: krsData?.filter((kr) => kr?.goal_id === item?.id),
      published: item?.published,
      responsable_id: item?.responsable_id,
      responsable_name: item?.responsable_name,
      weight: item?.weight,
      created_by_id: item?.created_by_id,
    }
  })

  const tableDataTeammates = colabsData?.map(item => {
    return {
      id: item.id,
      teammate: item.username,
      objective: item.number_of_goals,
      objective_progress: +item.progress_of_goals,
      kr: item.number_of_krs,
      kr_progress: item.progress_of_krs,
      activity: item.number_of_tasks,
      activity_progress: item.progress_of_tasks
      // percent: item.progres + '%',
      // status: item.progres === '100.00' ? 'completed' : 'progress'
    }
  })

  const tableDataTeams = teamsData?.map(item => {
    return {
      team: item.name,
      objective: item.goals,
      kr: item.kr,
      activity: item.task,
      percent: item.progres + '%',
      status: item.progres === '100.00' ? 'completed' : 'progress'
    }
  })



  function handleChangeMenu(menu) {
    setSelectedMenu(menu)
  }


  return (
    <div className="container-objectives-footer">
      <header>
        <button
          onClick={() => handleChangeMenu('goals')}
          className={selectedMenu === 'goals' ? "selected" : ''}
          type="button"

        >
          {t('goals.plural')}
        </button>
        <button
          onClick={() => handleChangeMenu('teams')}
          className={selectedMenu === 'teams' ? "selected" : ''}
          type="button"
        >
          {t('goals.teams')}
        </button>
        <button
          onClick={() => handleChangeMenu('teammates')}
          className={selectedMenu === 'teammates' ? "selected" : ''}
          type="button"
        >
          {t('goals.teammates')}
        </button>
      </header>

      <main className="content-objectives-footer">
        {selectedMenu === 'goals' && (
          <CollapsibleTableNew
            data={tableDataGoals}
            columns={columnsObjectivesTable}
            hover={true}
            striped={true}
            tab={'goals'}
            userDetails={userDetails}
            updateDashboardInfo={updateDashboardInfo}
            expand={true}
            teammates={teammates}
          />
        )}
        {selectedMenu === 'teams' && (
          <ObjectivesTable data={tableDataTeams} columns={columnsTeams} hover={true} striped={false} />
        )}
        {selectedMenu === 'teammates' && (
          <ObjectivesTable tab='teammates' data={tableDataTeammates} columns={columnsTeammates} hover={true} striped={false} />
        )}
      </main>
    </div>
  )
}

