import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import IconClose from "../_icons/IconClose";
import { api } from "../../_helpers/api";
import { Backdrop, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Paper, Slide, Tooltip } from "@material-ui/core";
import { alertActions } from "../../_actions";
import { authHeader, createHierarchy } from "../../_helpers";
import "./ModalGroupActivitiesEdit.scss"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: '100%',
    marginTop: '0px'
  },
  list: {
    border: '1px solid #D6DEEB',
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: 200
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#187dff', // Cor da borda quando está em foco
      borderWidth: '1px'
    }
  },
  paper: {
    marginTop: '10px',
  }
}));

function ModalGroupActivitiesEdit({
  currentTracked,
  openModalEditActivity,
  userDetails,
  handleClose,
  alertActions,
  getActivitiesData,
  categories
}) {

  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trackedsGrouped, setTrackedsGroupeds] = useState(currentTracked?.trackeds_grouped || null);
  const [trackedAdd, setTrackedAdd] = useState(null)
  const categoriesOptions = createHierarchy(categories, userDetails.language || i18n.language);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: currentTracked?.exhibitionName || '',
    category: currentTracked.categoryId ? categoriesOptions.find(c => c.value == currentTracked.categoryId) : ''
  });

  const [formError, setFormError] = useState({
    name: '',
    category: ''
  });

  function closeModalEditActivity() {
    handleClose()
    setForm({
      name: '',
      category: ''
    })
    setFormError({
      name: '',
      category: ''
    })
    setTrackedsGroupeds(null)
  }

  const handleInputChange = async (event) => {

    if (!event || !event.target || !event.target.value) {
      return;
    }

    const inputValue = event.target.value;

    if (inputValue.length < 3) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await api.get(`/settings/trackeds-software/?search_text=${inputValue}&trackeds_existing=${trackedsGrouped.map(t => t.trackedId).join(',')}&current_tracked=${currentTracked.trackedId}`, {
        headers: authHeader()
      });
      setOptions(data);
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
    }

    setLoading(false);
  };

  const validateForm = (form) => {

    const errors = {};

    if (!form.name) {
      errors.name = t('settings.teams.required_field')
    }

    if (!form.category || form.category.value === '') {
      errors.category = t('settings.teams.required_field')
    }

    return errors
  }


  const handleEditActivity = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const updateActivity = async () => {
    try {
      setIsSubmitting(true)

      const payload = {
        category_id: form.category.value,
        label: form.name,
        trackeds_grouped: trackedsGrouped
      }

      const res = await api.put(`/settings/group-activities/${currentTracked.trackedId}`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao editar a atividade')
      }

      getActivitiesData()
      closeModalEditActivity()
      alertActions.success(t('activities.edit_activity_success'))
      setIsSubmitting(false)
    } catch (error) {
      console.error(error)
      alertActions.error(error.response.data)
      setIsSubmitting(false)
    }
  }


  const handleAddActivity = () => {

    const checkIfExists = trackedsGrouped.find(item => item.trackedId === trackedAdd.value)

    if (!checkIfExists && trackedAdd !== null) {
      setTrackedsGroupeds([
        ...trackedsGrouped,
        {
          trackedId: trackedAdd.value,
          origin: trackedAdd.origin,
          exhibitionName: trackedAdd.label
        }
      ])
      setTrackedAdd(null)
    }
  }

  const removeActivity = (trackedId) => {
    setTrackedsGroupeds(trackedsGrouped.filter((t) => t.trackedId !== trackedId))
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      updateActivity()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  const handleChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
    
    setFormError({
      ...formError,
      [name]: ''
    })
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`modalFormEditGroupActivities ${classes.modal}`}
      open={openModalEditActivity}
      onClose={closeModalEditActivity}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalEditActivity} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={"containerModalFormEditGroupActivities"}>

          <button onClick={closeModalEditActivity} className="close"><IconClose /></button>

          <form onSubmit={handleEditActivity}>
            <main>
              <header>
                <h3>
                  {t('activities.edit_activity')}
                </h3>
              </header>
              {
                trackedsGrouped &&
                <>
                  <div className="field">
                    <label htmlFor="trackeds_software">{t('settings.activities.modal_group_activities.field_new_activity_grouped')}</label>
                    <div className="boxAddActivity">
                      <Autocomplete
                        id="trackeds_software"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        fullWidth
                        options={options}
                        loading={loading}
                        onInputChange={handleInputChange}
                        onChange={(event, value) => {
                          setTrackedAdd(value);
                        }}
                        noOptionsText={t("software_inventory.form_license.no_options")}
                        value={trackedAdd}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            placeholder={t("settings.activities.modal_group_activities.placeholder_activity_grouped")}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                      <Button variant="contained" color="primary" style={{ minWidth: '50px' }} disabled={!trackedAdd} onClick={handleAddActivity}>
                        <span style={{ fontSize: '20px' }}>+</span>
                      </Button>
                    </div>
                  </div>

                  <div className="field">
                    <label>{`${t('settings.activities.modal_group_activities.field_activities_selected')} (${trackedsGrouped.length})`}</label>
                    <div className={`${classes.listContainer}`}>
                      <List dense={true} className={classes.list}>
                        {
                          trackedsGrouped.length > 0 ?
                            trackedsGrouped.map((act) => (
                              <ListItem key={act.trackedId}>
                                <ListItemText
                                  style={{ color: '#24677E' }}
                                  primary={
                                    act.exhibitionName.length >= 50
                                      ?
                                      <Tooltip title={act.exhibitionName} placement="top" arrow>
                                        <span style={{ fontSize: "12px" }}>{act.exhibitionName.slice(0, 50) + "[...]"}</span>
                                      </Tooltip>
                                      :
                                      act.exhibitionName
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="remove" size={'small'} onClick={() => removeActivity(act.trackedId)}>
                                    <Delete size={'small'} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))
                            :
                            <ListItem>
                              <ListItemText
                                style={{ color: '#24677E' }}
                                primary={t('settings.activities.modal_group_activities.none_activity')}
                              />
                            </ListItem>

                        }
                      </List>
                    </div>
                  </div>
                </>
              }


              <div className="field">
                <label htmlFor="label_tracked">{t('activities.field_label')}</label>
                <TextField
                  id="label_tracked"
                  value={form.name}
                  variant="outlined"
                  name="name"
                  onChange={handleChange}
                  style={{color:'#24677E'}}
                />
                {formError.name &&
                  <div className="error">{formError.name}</div>
                }
              </div>

              <div className="field">
                <label htmlFor="category">{t('activities.field_category')}</label>
                <Autocomplete
                  value={form.category}
                  onChange={(event, newValue) => {
                    setForm({ ...form, category: newValue })
                    setFormError({ ...formError, category: '' })
                  }}
                  PopperComponent={(props) => (
                    <Paper elevation={0} {...props} className={classes.paper} />
                  )}
                  renderOption={(option) => {
                    if (option.is_children === true) {
                      return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{option.label}</span>
                    }

                    return <span>{option.label}</span>
                  }}
                  getOptionLabel={(option) => option.label}
                  id="category"
                  options={(categoriesOptions && categoriesOptions.length > 0) ? categoriesOptions : []}
                  fullWidth
                  renderInput={(params) => <TextField {...params} className={classes.focused} variant="outlined" fullWidth />}
                />
                {formError.category &&
                  <div className="error">{formError.category}</div>
                }
              </div>

              <footer>
                <div className="buttonActionsGroup">
                  <button
                    className="btnCancel"
                    type="button"
                    onClick={closeModalEditActivity}
                  >
                    {t('activities.cancel')}
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={15} /> : t('activities.save')}
                  </button>
                </div>
              </footer>
            </main>
          </form>

        </div>
      </Slide>

    </Modal>
  )
};

function mapStateToProps(state) {

  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalGroupActivitiesEdit));
