import {statsConstants} from '../_constants';

export function stats(state = {}, action) {
    switch (action.type) {
        case statsConstants.GET_STATS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case statsConstants.GET_STATS_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                loading: false,
            };
        case statsConstants.GET_STATS_FAILURE:
            return {
                error: action.error,
                loading: true

            };
        case statsConstants.GET_STATS_REALTIME_REQUEST:
            return {
                ...state,
                loadingUsers: true
            };
        case statsConstants.GET_STATS_REALTIME_SUCCESS:
            return {
                ...state,
                realtime: action.payload,
                loadingUsers: false
            };
        case statsConstants.GET_STATS_REALTIME_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingUsers: true
            };
        case statsConstants.GET_STATS_REALTIME_TOOLTIP_REQUEST:
            return {
                ...state,
                realtimeTooltip: null
            };
        case statsConstants.GET_STATS_REALTIME_TOOLTIP_SUCCESS:
            return {
                ...state,
                realtimeTooltip: action.payload,

            };
        case statsConstants.GET_STATS_REALTIME_TOOLTIP_FAILURE:
            return {
                ...state,
                error: action.error
            };


        case statsConstants.GET_PRODUCTIVITY_REQUEST:
            return {
                loading: true,
            };
        case statsConstants.GET_PRODUCTIVITY_SUCCESS:
            return {
                payload: action.payload,
                loading: false,
            };
        case statsConstants.GET_PRODUCTIVITY_FAILURE:
            return {
                error: action.error,
                loading: true

            };
        case statsConstants.GET_STATS_INACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case statsConstants.GET_STATS_INACTIVITY_SUCCESS:
            return {
                ...state,
                inactivity: action.payload,
                inactivityMu: action.payload.machineUsers,
                loading: false
            };
        case statsConstants.GET_STATS_INACTIVITY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: true
            };
        default:
            return state
    }
}
