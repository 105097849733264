import config from 'config';
import { authHeader } from '../_helpers';

export const myProductivityService = {
    login,
    logout,
    refresh,
    getByToken,
    activate,
    getAll,
    patch,
    updatePassword,
    passwordRecover,
    getByResetToken,
    resetPassword,
    generatePasswordFirst
};

function login(organizationId, tokenMachineUser, email, password) {

    const payload = {
        current_date: new Date().toISOString().slice(0, 10),
        organization_id: organizationId,
        mu_token: tokenMachineUser,
        email: email,
        password: password
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${config.apiNodeUrl}/stats/my-productivity/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));

            return user;
        });
}

function passwordRecover(organizationId, tokenMachineUser, email) {
    
    const payload = {
        organization_id: organizationId,
        mu_token: tokenMachineUser,
        email: email,
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${config.apiNodeUrl}/stats/my-productivity/reset-password`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));

            return user;
        });
}

function refresh(){

    const requestOptions = {
        method: 'POST',
        headers: authHeader(true)
    };

    return fetch(`${config.apiNodeUrl}/auth/refresh`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));

            return user;
        });

}

function activate(password, passwordConfirm, user_id, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, passwordConfirm, user_id, token })
    };


    return fetch(`${config.apiNodeUrl}/auth/activate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.accessToken){
                localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));
            }
            
            return user;
        });
}

function resetPassword(password, passwordConfirm, organization_id, token) {

    const payload = {
        organization_id,
        reset_token:token,
        password,
        confirm_password: passwordConfirm
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };



    return fetch(`${config.apiNodeUrl}/stats/my-productivity/redefine-password`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

        

            if(user.accessToken){
                localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));
            }
        


            return user;
        });
}


function generatePasswordFirst(organizationId, tokenMachineUser, email, password, passwordConfirm) {

    const payload = {
        current_date: new Date().toISOString().slice(0, 10),
        organization_id: organizationId,
        mu_token: tokenMachineUser,
        password: password,
        confirm_password: passwordConfirm,
        email: email
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };



    return fetch(`${config.apiNodeUrl}/stats/my-productivity/login/new-password`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


function getByToken(organizationId, token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/auth/activate_colab/${organizationId}/${token}`, requestOptions).then(handleResponse)
}

function getByResetToken(organizationId, token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/auth/activate_colab/${organizationId}/${token}`, requestOptions).then(handleResponse)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
    
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function patch(form, id) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ Name: form.name, Language: form.language, Timezone: form.timezone })
    };

    return fetch(`${config.apiNodeUrl}/users/`+id, requestOptions).then(handleResponse);
}

function updatePassword(form, id) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ CurrentPassword: form.current, NewPassword: form.new })
    };

    return fetch(`${config.apiNodeUrl}/users/password/`+id, requestOptions).then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {                                
     
                logout();
                // location.reload(true);
                               
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        
        return data;
    });
}