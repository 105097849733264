import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import IconEdit2 from '../../_components/_icons/IconEdit2';
import IconRemove from '../../_components/_icons/IconRemove';
import ModalNewMap from '../../_components/rooms_page/ModalNewMap';
import ModalEditMap from '../../_components/rooms_page/ModalEditMap';
import { Box, Button, Popover, Typography } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function RoomsMap(props) {

  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props
  const [mapsData, setMapsData] = useState([])
  const [submitted, setSubmitted] = useState(false);

  const [openModalNewMap, setOpenModalNewMap] = useState(false)
  const [openModalEditMap, setOpenModalEditMap] = useState(false)
  const [mapToEdit, setMapToEdit] = useState(null)

  const [anchorElConfirmDeleteMap, setAnchorElConfirmDeleteMap] = useState(null);
  const openConfirmDeleteMap = Boolean(anchorElConfirmDeleteMap);
  const idConfirmDeleteMap = openConfirmDeleteMap ? 'popover-map' : undefined;
  const [mapIdToDelete, setMapIdToDelete] = useState(null)

  const handleCloseConfirmDeleteMap = () => {
    setMapIdToDelete(null)
    setAnchorElConfirmDeleteMap(null);
  };

  const handleClickConfirmDeleteMap = (event, mapId) => {
    setMapIdToDelete(mapId)
    setAnchorElConfirmDeleteMap(event.currentTarget);
  };

  const onDeleteMap = async () => {
    try {
      const res = await api.delete(`/rooms/maps/${mapIdToDelete}`, { headers: authHeader() })
      handleCloseConfirmDeleteMap()
      getMapsData()
      alertActions.success(t('spaces.success.delete'))
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.delete'))
    }
  }

  const getMapsData = async () => {
    try {
      const response = await api.get(`/rooms/maps`, {
        headers: authHeader()
      })
      setMapsData(response.data);
    } catch (error) {
      console.log(error)
      //alertActions.error(t('spaces.errors.load'))
    }
  }

  const handleClickToEditMap = (event, map) => {
    event.preventDefault()
    setMapToEdit(map)
    setOpenModalEditMap(true)
  }

  useEffect(() => {
    document.title = 'Befective | Gestão de Salas | Mapas';
    getMapsData()
  }, []);

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhenMonths={'disabled'}
          filterWhat={'disabled'}
          createMap={true}
          setOpenModalNewMap={setOpenModalNewMap}
        />

        <div className={style.containerRoomsPage}>
          <div className={style.containerTable}>
            <table>
              <thead>
                <tr>
                  <th style={{ textTransform: 'uppercase', width: '4%'}}>
                    {t('spaces.maps.columns.id')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.maps.columns.name')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('spaces.maps.columns.description')}
                  </th>
                  <th style={{ textTransform: 'uppercase', width: '14%' }}>
                    {t('spaces.maps.columns.updated_at')}
                  </th>
                  <th style={{ textTransform: 'uppercase', width: '14%' }}>
                    {t('spaces.maps.columns.created_at')}
                  </th>
                  <th
                    style={{ width: '4%' }}
                  >
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  mapsData?.length > 0 ?
                    mapsData.map((map, _) => {
                      return (
                        <tr key={map.id}>
                          <td onClick={() => history.push(`/spaces/maps/${map.id}`)}>{map?.id}</td>
                          <td onClick={() => history.push(`/spaces/maps/${map.id}`)}>{map?.name}</td>
                          <td onClick={() => history.push(`/spaces/maps/${map.id}`)}>{map?.description}</td>
                          <td onClick={() => history.push(`/spaces/maps/${map.id}`)}>{befectiveFormatDate(map?.created_at, i18n.language, 'T')}</td>
                          <td onClick={() => history.push(`/spaces/maps/${map.id}`)}>{befectiveFormatDate(map?.updated_at, i18n.language, 'T')}</td>
                          <td>
                            <div className={style.boxActions}>
                              <button className={`${style.buttonAction} ${style.permissions}`} onClick={(e) => handleClickToEditMap(e, map)}>
                                <IconEdit2 />
                              </button>
                              <button className={`${style.buttonAction} ${style.remove}`} onClick={(e) => handleClickConfirmDeleteMap(e, map.id)}>
                                <IconRemove />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })

                    :
                    <tr><td colSpan={6}>{t('spaces.maps.no_data')}</td></tr>
                }
              </tbody>
            </table>

          </div>

        </div>
      </section>

      <Popover
        id={idConfirmDeleteMap}
        open={openConfirmDeleteMap}
        anchorEl={anchorElConfirmDeleteMap}
        onClose={handleCloseConfirmDeleteMap}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: "#FFE3E5",
            boxShadow: "none",
            padding: '18px 14px',
            borderRadius: '6px',
          }
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography fontSize="12px" color="#FF737D" componente="span">{t('spaces.exclude_confirm')}</Typography>

          <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#fff', borderRadius: '2px' }} onClick={handleCloseConfirmDeleteMap}>
              <Typography component="span" fontSize={10} color="#FF737D">{t('spaces.no')}</Typography>
            </Button>
            <Button style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={onDeleteMap}>
              <Typography component="span" fontSize={10} color="#fff">{t('spaces.yes')}</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>

      <ModalNewMap
        openModalNewMap={openModalNewMap}
        setOpenModalNewMap={setOpenModalNewMap}
        alertActions={alertActions}
        updateInfo={getMapsData}
      />

      {
        mapToEdit &&
        <ModalEditMap
          openModalEditMap={openModalEditMap}
          setOpenModalEditMap={setOpenModalEditMap}
          alertActions={alertActions}
          updateInfo={getMapsData}
          mapToEdit={mapToEdit}
          setMapToEdit={setMapToEdit}
        />
      }

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsMap)



