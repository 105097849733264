import config from 'config'
import {authHeader} from '../_helpers'
import {useState} from "react";

export const periodicService = {
    postPeriodic,
    putPeriodic,
    patchPeriodic,
    deletePeriodic,
    getPeriodics,
    getPeriodic,
    activatePeriodic,
    deactivatePeriodic

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE)
}

function postPeriodic(form) {


    let filters = []

    filters = {...filters, for_type: form.filter_target}

    if (form.groups.length > 0) {
        filters = {...filters, group_ids: form.groups}
    }

    if (form.machine_users.length > 0) {
        filters = {...filters, machine_users_ids: form.machine_users}
    }

    if (form.efficiencies) {
        filters = {...filters, efficiencies: form.efficiencies}
    }

    if (form.mood) {
        filters = {...filters, mood: form.mood}
    }

    if (form.type) {
        filters = {...filters, type: form.type}
    }

    if (form.team_reached) {
        filters = {...filters, team_reached: form.team_reached}
    }

    if (form.team_not_reached) {
        filters = {...filters, team_not_reached: form.team_not_reached}
    }

    if (form.teammates_reached) {
        filters = {...filters, teammates_reached: form.teammates_reached}
    }

    if (form.teammates_not_reached) {
        filters = {...filters, teammates_not_reached: form.teammates_not_reached}
    }

    if (form.trackeds) {
        filters = {...filters, tracked_name: form.trackeds}
    }

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            periodic_report: {
                name: form.name,
                report_name: form.report,
                day: form.frequency_day,
                frequency: form.frequency,
                emails: form.emails,
                filter: filters,
                created_by:"v2"
            }
        })
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports`, requestOptions).then(handleResponse)
}
function putPeriodic(form,pr_id) {


    let filters = []

    filters = {...filters, for_type: form.filter_target}

    if (form.groups.length > 0) {
        filters = {...filters, group_ids: form.groups}
    }

    if (form.machine_users.length > 0) {
        filters = {...filters, machine_users_ids: form.machine_users}
    }

    if (form.efficiencies) {
        filters = {...filters, efficiencies: form.efficiencies}
    }

    if (form.mood) {
        filters = {...filters, mood: form.mood}
    }

    if (form.type) {
        filters = {...filters, type: form.type}
    }

    if (form.team_reached) {
        filters = {...filters, team_reached: form.team_reached}
    }

    if (form.team_not_reached) {
        filters = {...filters, team_not_reached: form.team_not_reached}
    }

    if (form.teammates_reached) {
        filters = {...filters, teammates_reached: form.teammates_reached}
    }

    if (form.teammates_not_reached) {
        filters = {...filters, teammates_not_reached: form.teammates_not_reached}
    }

    if (form.trackeds) {
        filters = {...filters, tracked_name: form.trackeds}
    }

    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({
            periodic_report: {
                name: form.name,
                report_name: form.report,
                day: form.frequency_day,
                frequency: form.frequency,
                emails: form.emails,
                filter: filters,
                created_by:"v2"
            }
        })
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports/${pr_id}`, requestOptions).then(handleResponse)
}

function activatePeriodic(id) {

    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports/${id}/activate`, requestOptions).then(handleResponse)
}

function deactivatePeriodic(id) {

    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports/${id}/deactivate`, requestOptions).then(handleResponse)
}

function patchPeriodic(id, form) {

    const requestOptions = {
        method: 'PATCH',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
        body: JSON.stringify({active: form.active})
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports/${id}/update-status`, requestOptions).then(handleResponse)
}

function deletePeriodic(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), ...{'Content-Type': 'application/json'}}
    }
    return fetch(`${config.apiNodeUrl}/periodic-reports/${id}`, requestOptions).then(handleResponse)
}


function getPeriodics() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports`, requestOptions).then(handleResponse)
}

function getPeriodic(pr_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/periodic-reports/${pr_id}`, requestOptions).then((response) =>{return response.json()})
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text)

        if (!response.ok) {
            if (response.status === 401) {
                logout()
                location.reload(true)
            }

            const error = (data && data.Message) || response.statusText
            return Promise.reject([error, response.status])
        }
        return data
    })
}

