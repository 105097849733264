import React from 'react';
import { history } from '../../_helpers';

const SubspaceIconDesk = ({
        x,
        y,
        rotationAngle,
        onMouseDown = () => { },
        onDoubleClick = () => { },
        urlRedirect = null
}) => {
        return (
                <svg width={240.745} height={152.278} viewBox="0 0 300.745 190.278" style={{ transform: `rotate(${rotationAngle}deg)`, cursor: 'pointer' }} x={x} y={y} onMouseDown={onMouseDown} onClick={() => urlRedirect && history.push(urlRedirect)}>
                        <defs>
                                <clipPath id="clip-path">
                                        <rect id="Retângulo_1678" data-name="Retângulo 1678" width="300.738" height="152.255" fill="none" />
                                </clipPath>
                        </defs>
                        <g id="mesa" transform="translate(-449.383 -943.788)" onDoubleClick={onDoubleClick}>
                                <rect id="Retângulo_1669" data-name="Retângulo 1669" width="300.738" height="23.783" transform="translate(449.389 1110.274)" fill="#a89d90" />
                                <rect id="Retângulo_1670" data-name="Retângulo 1670" width="300.738" height="23.783" transform="translate(449.389 1086.491)" fill="#999085" />
                                <rect id="Retângulo_1671" data-name="Retângulo 1671" width="300.738" height="23.783" transform="translate(449.389 1062.709)" fill="#978c7f" />
                                <rect id="Retângulo_1672" data-name="Retângulo 1672" width="300.738" height="23.783" transform="translate(449.389 1038.926)" fill="#a49989" />
                                <rect id="Retângulo_1673" data-name="Retângulo 1673" width="300.738" height="23.783" transform="translate(449.389 1015.144)" fill="#999085" />
                                <rect id="Retângulo_1674" data-name="Retângulo 1674" width="300.738" height="23.783" transform="translate(449.389 991.361)" fill="#a89d90" />
                                <rect id="Retângulo_1675" data-name="Retângulo 1675" width="300.738" height="23.783" transform="translate(449.389 967.579)" fill="#a49989" />
                                <rect id="Retângulo_1676" data-name="Retângulo 1676" width="300.738" height="23.783" transform="translate(449.389 943.796)" fill="#978c7f" />
                                <path id="Caminho_2614" data-name="Caminho 2614" d="M305.692,17.255C205.4,23.071,105.2,13.225,5.01,10.129,105.2,13.164,205.4,23.009,305.692,17.255" transform="translate(444.436 962.493)" fill="#ceb9b2" />
                                <path id="Caminho_2615" data-name="Caminho 2615" d="M5.01,10.723C105.2,13.763,205.4,23.6,305.692,17.849,205.4,23.665,105.2,13.825,5.01,10.723" transform="translate(444.436 965.237)" fill="#ceb9b2" />
                                <path id="Caminho_2616" data-name="Caminho 2616" d="M5.01,11.14C105.2,14.18,205.4,24.02,305.692,18.266,205.4,24.082,105.2,14.242,5.01,11.14" transform="translate(444.436 967.164)" fill="#ceb9b2" />
                                <path id="Caminho_2617" data-name="Caminho 2617" d="M5.01,11.428C105.2,14.468,205.4,24.308,305.692,18.554,205.4,24.37,105.2,14.53,5.01,11.428" transform="translate(444.436 968.494)" fill="#ceb9b2" />
                                <path id="Caminho_2618" data-name="Caminho 2618" d="M5.01,11.565C105.2,14.605,205.4,24.445,305.692,18.691,205.4,24.507,105.2,14.667,5.01,11.565" transform="translate(444.436 969.127)" fill="#ceb9b2" />
                                <path id="Caminho_2619" data-name="Caminho 2619" d="M5.01,11.877C105.2,14.917,205.4,24.757,305.692,19,205.4,24.819,105.2,14.979,5.01,11.877" transform="translate(444.436 970.568)" fill="#ceb9b2" />
                                <path id="Caminho_2620" data-name="Caminho 2620" d="M5.01,9.527C105.2,12.567,205.4,22.407,305.692,16.653,205.4,22.469,105.2,12.629,5.01,9.527" transform="translate(444.436 959.712)" fill="#ceb9b2" />
                                <path id="Caminho_2621" data-name="Caminho 2621" d="M5.01,13.448c13.01.427,26.025.152,39.029-.214,6.508-.2,13.01-.461,19.506-.815l9.739-.624c3.186-.354,6.6-.315,9.565-1.366C80,9.407,76.481,9.6,73.317,9.323L63.562,8.98,44.05,8.632,5.01,8.452,44.05,8.6l19.517.332,9.75.337,4.872.292c1.568.23,3.372.039,4.715.877-3.057,1.141-6.412,1.028-9.621,1.4l-9.739.618q-9.745.514-19.5.8c-13.01.36-26.025.618-39.034.185" transform="translate(444.436 954.746)" fill="#ceb9b2" />
                                <path id="Caminho_2622" data-name="Caminho 2622" d="M5.01,11.195l22.636-.483c7.519-.292,15.156-.27,22.563-1.09-7.379-.714-15.044-.551-22.557-.731L5.01,8.694l22.642.169c7.536.191,15.123-.051,22.614.753-7.514.933-15.078.815-22.614,1.124Z" transform="translate(444.436 955.864)" fill="#ceb9b2" />
                                <path id="Caminho_2623" data-name="Caminho 2623" d="M5.01,15.055Q34.7,15.831,64.4,15.3c9.9-.169,19.793-.438,29.683-.865,4.945-.219,9.885-.483,14.825-.826l7.4-.629c2.394-.365,5.063-.326,7.171-1.433-2.006-1.146-4.76-1-7.137-1.326l-7.407-.489L94.093,9.154,64.4,8.592c-19.8-.23-39.6-.264-59.395-.23,19.8-.039,39.6-.017,59.395.2l29.689.545,14.842.568,7.407.483,3.692.377c1.169.264,2.591.129,3.5,1.017-2.192,1.191-4.81,1.1-7.221,1.472l-7.4.629c-4.934.343-9.879.6-14.825.815-9.891.427-19.787.691-29.683.854q-29.689.48-59.389-.27" transform="translate(444.436 954.257)" fill="#ceb9b2" />
                                <path id="Caminho_2624" data-name="Caminho 2624" d="M5.01,18.135c35.05.191,70.1.27,105.15.034,17.522-.124,35.044-.32,52.567-.708,8.761-.2,17.517-.438,26.272-.809,4.378-.185,8.755-.4,13.122-.7l6.547-.562,3.259-.421c.989-.247,2.36-.236,2.945-1.023-1.742-1.146-4.164-1.124-6.26-1.506l-6.536-.68c-4.361-.382-8.733-.674-13.105-.938-8.75-.517-17.505-.9-26.261-1.219-17.511-.635-35.033-1.023-52.555-1.281Q57.588,7.567,5.01,8.379q52.567-.784,105.145-.09c17.522.253,35.044.635,52.561,1.27,8.755.32,17.511.7,26.255,1.208,4.372.264,8.744.556,13.111.938l6.536.674c2.113.4,4.49.32,6.311,1.551-.652.916-1.995.815-3,1.085l-3.259.427L202.127,16c-4.372.3-8.75.517-13.122.7-8.755.365-17.517.607-26.278.8-17.522.382-35.044.573-52.567.691-35.05.225-70.1.14-105.15-.062" transform="translate(444.436 952.441)" fill="#ceb9b2" />
                                <path id="Caminho_2625" data-name="Caminho 2625" d="M5.01,15.781c27.458.208,54.916.292,82.374.1,13.729-.1,27.458-.27,41.187-.6l20.579-.68,10.284-.579,5.125-.455c1.63-.275,3.58-.208,4.884-1.113-3.007-1.332-6.682-1.208-10.026-1.624l-10.273-.658c-6.856-.354-13.718-.6-20.579-.815-13.723-.41-27.452-.646-41.181-.8-27.458-.3-54.916-.27-82.374-.051,27.458-.23,54.916-.27,82.374.022,13.729.152,27.458.382,41.181.792,6.862.208,13.723.455,20.579.809l10.278.652,5.125.489c1.652.3,3.524.2,4.951,1.18-1.388,1.023-3.288.871-4.934,1.175l-5.131.45-10.278.579-20.585.674c-13.723.326-27.452.483-41.187.584C59.926,16.1,32.468,16,5.01,15.781" transform="translate(444.436 954.132)" fill="#ceb9b2" />
                                <path id="Caminho_2626" data-name="Caminho 2626" d="M305.692,15.677C205.5,12.636,105.3,2.8,5.01,8.551,105.3,2.735,205.5,12.575,305.692,15.677" transform="translate(444.436 946.869)" fill="#ceb9b2" />
                                <path id="Caminho_2627" data-name="Caminho 2627" d="M305.692,15.082C205.5,12.041,105.3,2.2,5.01,7.956,105.3,2.14,205.5,11.98,305.692,15.082" transform="translate(444.436 944.121)" fill="#ceb9b2" />
                                <path id="Caminho_2628" data-name="Caminho 2628" d="M305.692,14.66C205.5,11.625,105.3,1.779,5.01,7.54,105.3,1.723,205.5,11.558,305.692,14.66" transform="translate(444.436 942.194)" fill="#ceb9b2" />
                                <path id="Caminho_2629" data-name="Caminho 2629" d="M305.692,14.377C205.5,11.336,105.3,1.5,5.01,7.251,105.3,1.435,205.5,11.275,305.692,14.377" transform="translate(444.436 940.864)" fill="#ceb9b2" />
                                <path id="Caminho_2630" data-name="Caminho 2630" d="M305.692,14.24C205.5,11.2,105.3,1.359,5.01,7.114,105.3,1.3,205.5,11.138,305.692,14.24" transform="translate(444.436 940.231)" fill="#ceb9b2" />
                                <path id="Caminho_2631" data-name="Caminho 2631" d="M305.692,13.928C205.5,10.887,105.3,1.047,5.01,6.8,105.3.986,205.5,10.826,305.692,13.928" transform="translate(444.436 938.79)" fill="#ceb9b2" />
                                <path id="Caminho_2632" data-name="Caminho 2632" d="M305.692,16.278C205.5,13.237,105.3,3.4,5.01,9.152,105.3,3.336,205.5,13.176,305.692,16.278" transform="translate(444.436 949.646)" fill="#ceb9b2" />
                                <path id="Caminho_2633" data-name="Caminho 2633" d="M178.327,10.294c-24.266-2.062-48.667-1.124-72.932.427C81.112,12.294,56.885,14.7,32.58,16.009l.006-.056c24.243,1.725,48.565,2.248,72.871,2.315,24.305-.022,48.627-.495,72.871-2.282-24.243,1.8-48.565,2.282-72.871,2.31-24.305-.062-48.627-.573-72.876-2.287v-.056c24.3-1.3,48.526-3.7,72.814-5.26,24.266-1.545,48.667-2.467,72.932-.4" transform="translate(571.801 957.715)" fill="#ceb9b2" />
                                <path id="Caminho_2634" data-name="Caminho 2634" d="M154.683,10.179c-19.489-1.158-39.029-.607-58.518.253C76.671,11.309,57.2,12.646,37.7,13.4v-.056c19.483.972,38.989,1.259,58.49,1.3,19.506-.011,39.018-.27,58.5-1.27-19.478,1.012-38.989,1.276-58.5,1.3-19.5-.034-39.012-.315-58.49-1.276v-.056C57.2,12.6,76.671,11.27,96.165,10.4c19.489-.849,39.029-1.388,58.518-.225" transform="translate(595.444 959.639)" fill="#ceb9b2" />
                                <path id="Caminho_2635" data-name="Caminho 2635" d="M129.173,10.052c-28.66-.882-57.3.725-85.948,1.337v-.056l85.948.028-85.953.028v-.056c28.649-.6,57.3-2.18,85.953-1.281" transform="translate(620.954 960.89)" fill="#ceb9b2" />
                                <path id="Caminho_2636" data-name="Caminho 2636" d="M33.719,5c11.593.888,23.215,1.349,34.82,1.978L103.381,8.67l69.679,3.31L103.375,8.726,68.539,7.006C56.934,6.371,45.312,5.9,33.719,5" transform="translate(577.063 938.799)" fill="#ceb9b2" />
                                <path id="Caminho_2637" data-name="Caminho 2637" d="M41.151,5l97.575,5.176Z" transform="translate(611.396 938.799)" fill="#ceb9b2" />
                                <path id="Caminho_2638" data-name="Caminho 2638" d="M101.081,8.49,49.3,5Z" transform="translate(649.047 938.799)" fill="#ceb9b2" />
                                <path id="Caminho_2639" data-name="Caminho 2639" d="M68.743,6.748,56.3,5Z" transform="translate(681.385 938.799)" fill="#ceb9b2" />
                                <path id="Caminho_2640" data-name="Caminho 2640" d="M155.348,45.4c-12.509-.967-25.047-1.512-37.573-2.214L80.19,41.243l-75.18-3.8,75.18,3.748,37.59,1.973c12.521.714,25.058,1.264,37.568,2.242" transform="translate(444.436 1088.653)" fill="#ceb9b2" />
                                <path id="Caminho_2641" data-name="Caminho 2641" d="M5.01,37.76l100.447,6.154Z" transform="translate(444.436 1090.14)" fill="#ceb9b2" />
                                <path id="Caminho_2642" data-name="Caminho 2642" d="M5.01,38.06l54.106,4.473Z" transform="translate(444.436 1091.526)" fill="#ceb9b2" />
                                <path id="Caminho_2643" data-name="Caminho 2643" d="M5.01,38.37,21.667,41.1Z" transform="translate(444.436 1092.958)" fill="#ceb9b2" />
                                <path id="Caminho_2644" data-name="Caminho 2644" d="M305.692,32.386C205.5,29.345,105.3,19.505,5.01,25.26,105.3,19.444,205.5,29.284,305.692,32.386" transform="translate(444.436 1024.06)" fill="#ceb9b2" />
                                <path id="Caminho_2645" data-name="Caminho 2645" d="M305.692,31.791C205.5,28.75,105.3,18.91,5.01,24.665,105.3,18.849,205.5,28.689,305.692,31.791" transform="translate(444.436 1021.311)" fill="#ceb9b2" />
                                <path id="Caminho_2646" data-name="Caminho 2646" d="M305.692,31.374C205.5,28.333,105.3,18.493,5.01,24.248,105.3,18.432,205.5,28.272,305.692,31.374" transform="translate(444.436 1019.385)" fill="#ceb9b2" />
                                <path id="Caminho_2647" data-name="Caminho 2647" d="M305.692,31.085C205.5,28.051,105.3,18.2,5.01,23.959,105.3,18.143,205.5,27.989,305.692,31.085" transform="translate(444.436 1018.055)" fill="#ceb9b2" />
                                <path id="Caminho_2648" data-name="Caminho 2648" d="M305.692,30.946C205.5,27.912,105.3,18.066,5.01,23.821,105.3,18.01,205.5,27.85,305.692,30.946" transform="translate(444.436 1017.424)" fill="#ceb9b2" />
                                <path id="Caminho_2649" data-name="Caminho 2649" d="M305.692,30.638C205.5,27.6,105.3,17.757,5.01,23.512,105.3,17.7,205.5,27.536,305.692,30.638" transform="translate(444.436 1015.985)" fill="#ceb9b2" />
                                <path id="Caminho_2650" data-name="Caminho 2650" d="M305.692,32.984C205.5,29.95,105.3,20.1,5.01,25.859,105.3,20.048,205.5,29.888,305.692,32.984" transform="translate(444.436 1026.839)" fill="#ceb9b2" />
                                <path id="Caminho_2651" data-name="Caminho 2651" d="M122.549,26.019c-13.01-.427-26.025-.152-39.029.214-6.508.2-13.01.461-19.506.815l-9.739.624c-3.186.354-6.6.315-9.565,1.366,2.849,1.023,6.367.832,9.531,1.107L64,30.487l19.512.348,39.04.18-39.04-.152-19.517-.332-9.75-.337L49.369,29.9c-1.568-.23-3.372-.039-4.715-.877,3.057-1.141,6.412-1.028,9.621-1.4L64.014,27q9.745-.514,19.5-.8c13.01-.36,26.025-.618,39.034-.185" transform="translate(627.579 1034.855)" fill="#ceb9b2" />
                                <path id="Caminho_2652" data-name="Caminho 2652" d="M133.391,31.4l-91.079-.258V31.1a529.748,529.748,0,0,0,91.079,2.81,529.812,529.812,0,0,1-91.084-2.759l.006-.051Z" transform="translate(616.736 1059.35)" fill="#ceb9b2" />
                                <path id="Caminho_2653" data-name="Caminho 2653" d="M95.717,26.035l-22.636.483c-7.519.292-15.156.27-22.563,1.09,7.379.714,15.044.551,22.557.731l22.642.2-22.642-.169c-7.536-.191-15.123.051-22.614-.753,7.514-.933,15.078-.815,22.614-1.124Z" transform="translate(654.41 1035.974)" fill="#ceb9b2" />
                                <path id="Caminho_2654" data-name="Caminho 2654" d="M155.945,26.016q-29.689-.776-59.389-.242c-9.9.169-19.793.438-29.683.865-4.945.219-9.885.483-14.825.826l-7.4.629c-2.394.365-5.063.326-7.171,1.433,2.006,1.146,4.76,1,7.137,1.326l7.407.489,14.836.573,29.689.562c19.8.23,39.6.258,59.395.23-19.8.039-39.6.017-59.395-.2l-29.689-.545-14.842-.568-7.407-.483-3.692-.377c-1.169-.264-2.591-.129-3.5-1.017,2.192-1.191,4.8-1.1,7.221-1.472l7.4-.629c4.94-.343,9.885-.6,14.83-.815,9.891-.427,19.787-.691,29.683-.854q29.689-.48,59.389.27" transform="translate(594.184 1033.735)" fill="#ceb9b2" />
                                <path id="Caminho_2655" data-name="Caminho 2655" d="M231.079,25.57c-35.05-.191-70.1-.27-105.15-.034-17.522.124-35.044.32-52.567.708-8.761.2-17.517.438-26.272.809-4.378.185-8.755.4-13.122.7l-6.547.562-3.259.421c-.989.247-2.36.23-2.945,1.023,1.742,1.146,4.164,1.124,6.26,1.506l6.536.68c4.361.382,8.733.674,13.105.938,8.75.517,17.505.9,26.261,1.219,17.511.635,35.033,1.023,52.555,1.281q52.567.75,105.145-.062-52.567.784-105.145.09c-17.522-.253-35.044-.635-52.561-1.27-8.756-.32-17.511-.7-26.255-1.208-4.372-.264-8.744-.556-13.111-.938l-6.536-.68c-2.113-.4-4.49-.315-6.311-1.545.652-.916,2-.815,3-1.085l3.259-.427,6.547-.556c4.372-.3,8.75-.517,13.122-.7,8.756-.365,17.517-.607,26.278-.8,17.522-.382,35.044-.573,52.567-.691,35.05-.225,70.1-.141,105.15.062" transform="translate(519.049 1032.922)" fill="#ceb9b2" />
                                <path id="Caminho_2656" data-name="Caminho 2656" d="M193.734,25.714c-27.458-.208-54.916-.292-82.374-.1-13.729.1-27.458.27-41.187.6l-20.579.68-10.284.579-5.125.455c-1.63.275-3.58.208-4.884,1.113,3.007,1.332,6.682,1.208,10.026,1.624l10.273.658c6.856.354,13.718.6,20.579.815,13.723.41,27.452.646,41.181.8,27.458.3,54.916.27,82.374.051-27.458.23-54.916.27-82.374-.022-13.729-.152-27.458-.382-41.181-.792-6.862-.208-13.723-.455-20.579-.809l-10.278-.652L34.2,30.227c-1.652-.3-3.524-.2-4.951-1.18,1.388-1.023,3.288-.871,4.934-1.175l5.131-.45,10.278-.579,20.585-.674c13.723-.326,27.452-.483,41.181-.584,27.464-.185,54.921-.09,82.379.129" transform="translate(556.395 1033.44)" fill="#ceb9b2" />
                                <path id="Caminho_2657" data-name="Caminho 2657" d="M5.01,26.838C105.2,29.878,205.4,39.718,305.692,33.964,205.4,39.78,105.2,29.94,5.01,26.838" transform="translate(444.436 1039.684)" fill="#ceb9b2" />
                                <path id="Caminho_2658" data-name="Caminho 2658" d="M5.01,27.433C105.2,30.473,205.4,40.313,305.692,34.559,205.4,40.375,105.2,30.535,5.01,27.433" transform="translate(444.436 1042.433)" fill="#ceb9b2" />
                                <path id="Caminho_2659" data-name="Caminho 2659" d="M5.01,27.85C105.2,30.89,205.4,40.73,305.692,34.976,205.4,40.792,105.2,30.952,5.01,27.85" transform="translate(444.436 1044.359)" fill="#ceb9b2" />
                                <path id="Caminho_2660" data-name="Caminho 2660" d="M5.01,28.138C105.2,31.178,205.4,41.018,305.692,35.264,205.4,41.08,105.2,31.24,5.01,28.138" transform="translate(444.436 1045.689)" fill="#ceb9b2" />
                                <path id="Caminho_2661" data-name="Caminho 2661" d="M5.01,28.275C105.2,31.315,205.4,41.155,305.692,35.4,205.4,41.217,105.2,31.377,5.01,28.275" transform="translate(444.436 1046.322)" fill="#ceb9b2" />
                                <path id="Caminho_2662" data-name="Caminho 2662" d="M5.01,28.587C105.2,31.627,205.4,41.467,305.692,35.713,205.4,41.529,105.2,31.689,5.01,28.587" transform="translate(444.436 1047.764)" fill="#ceb9b2" />
                                <path id="Caminho_2663" data-name="Caminho 2663" d="M5.01,26.237C105.2,29.277,205.4,39.117,305.692,33.363,205.4,39.179,105.2,29.339,5.01,26.237" transform="translate(444.436 1036.907)" fill="#ceb9b2" />
                                <path id="Caminho_2664" data-name="Caminho 2664" d="M5.01,32.444c24.266,2.062,48.667,1.124,72.932-.427,24.283-1.574,48.509-3.979,72.809-5.288v.056c-24.243-1.725-48.565-2.248-72.871-2.315-24.305.022-48.627.495-72.871,2.282,24.243-1.8,48.565-2.282,72.871-2.31,24.305.062,48.627.573,72.876,2.287v.056c-24.3,1.3-48.526,3.7-72.814,5.26-24.266,1.545-48.667,2.467-72.932.4" transform="translate(444.436 1028.615)" fill="#ceb9b2" />
                                <path id="Caminho_2665" data-name="Caminho 2665" d="M5.01,29.24c19.489,1.158,39.029.607,58.518-.253C83.023,28.11,102.495,26.773,122,26.02v.056c-19.484-.972-38.989-1.259-58.5-1.3-19.5.011-39.012.27-58.49,1.27C24.488,25.031,44,24.766,63.5,24.744c19.506.034,39.018.315,58.5,1.276v.056c-19.5.742-38.973,2.074-58.467,2.939C44.039,29.864,24.5,30.4,5.01,29.24" transform="translate(444.436 1030.01)" fill="#ceb9b2" />
                                <path id="Caminho_2666" data-name="Caminho 2666" d="M5.01,26.443c28.655.882,57.3-.725,85.948-1.337v.056L5.01,25.134l85.953-.028v.056c-28.649.6-57.3,2.18-85.953,1.281" transform="translate(444.436 1031.682)" fill="#ceb9b2" />
                                <path id="Caminho_2667" data-name="Caminho 2667" d="M102.59,35.912l21.439.23,10.717-.022,2.675-.067,1.337-.084c.191-.034.517-.022.584-.146a2.672,2.672,0,0,0-.641-.157c-1.77-.242-3.552-.36-5.333-.495-14.263-.967-28.542-1.59-42.817-2.276L47.72,30.961C33.5,30.292,19.048,29.809,5.01,28.932c14.038.865,28.486,1.337,42.715,2l42.833,1.905c14.274.7,28.554,1.332,42.811,2.3,1.781.135,3.563.253,5.339.5a2.412,2.412,0,0,1,.652.163c-.084.2-.4.135-.6.185l-1.337.084-2.681.067-10.717.017Z" transform="translate(444.436 1049.358)" fill="#ceb9b2" />
                                <path id="Caminho_2668" data-name="Caminho 2668" d="M56.79,34.428l45.8-.017v.034L5.01,29.253l97.58,5.159v.034Z" transform="translate(444.434 1050.839)" fill="#ceb9b2" />
                                <path id="Caminho_2669" data-name="Caminho 2669" d="M5.01,35.138l51.78-2.124-.006.056L5.01,29.552l51.78,3.462v.056Z" transform="translate(444.435 1052.223)" fill="#ceb9b2" />
                                <path id="Caminho_2670" data-name="Caminho 2670" d="M5.01,31.61l12.442-.028-.006.056L5.01,29.863l12.448,1.72-.006.056Z" transform="translate(444.435 1053.657)" fill="#ceb9b2" />
                                <path id="Caminho_2671" data-name="Caminho 2671" d="M305.692,27.145c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,15.636,55.076,14.293,5.01,18.007c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 988.243)" fill="#ceb9b2" />
                                <path id="Caminho_2672" data-name="Caminho 2672" d="M305.692,26.383c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.874,55.076,13.531,5.01,17.245c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 984.723)" fill="#ceb9b2" />
                                <path id="Caminho_2673" data-name="Caminho 2673" d="M305.692,25.848c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.339,55.076,13,5.01,16.71,55.076,12.979,105.355,14.3,155.433,17c50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 982.251)" fill="#ceb9b2" />
                                <path id="Caminho_2674" data-name="Caminho 2674" d="M305.692,25.479c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.97,55.076,12.627,5.01,16.341c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 980.547)" fill="#ceb9b2" />
                                <path id="Caminho_2675" data-name="Caminho 2675" d="M305.692,25.3c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.795,55.076,12.452,5.01,16.166c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 979.738)" fill="#ceb9b2" />
                                <path id="Caminho_2676" data-name="Caminho 2676" d="M305.692,24.9c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.395,55.076,12.052,5.01,15.766c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 977.891)" fill="#ceb9b2" />
                                <path id="Caminho_2677" data-name="Caminho 2677" d="M305.692,27.916c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,16.407,55.076,15.064,5.01,18.778c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 991.805)" fill="#ceb9b2" />
                                <path id="Caminho_2678" data-name="Caminho 2678" d="M5.01,17.652c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,30.521,205.347,29.2,155.27,26.5c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 997.247)" fill="#ceb9b2" />
                                <path id="Caminho_2679" data-name="Caminho 2679" d="M5.01,18.415c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1000.772)" fill="#ceb9b2" />
                                <path id="Caminho_2680" data-name="Caminho 2680" d="M5.01,18.949c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,31.818,205.347,30.5,155.27,27.8c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1003.239)" fill="#ceb9b2" />
                                <path id="Caminho_2681" data-name="Caminho 2681" d="M5.01,19.318c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1004.944)" fill="#ceb9b2" />
                                <path id="Caminho_2682" data-name="Caminho 2682" d="M5.01,19.494c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1005.757)" fill="#ceb9b2" />
                                <path id="Caminho_2683" data-name="Caminho 2683" d="M5.01,19.894c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1007.605)" fill="#ceb9b2" />
                                <path id="Caminho_2684" data-name="Caminho 2684" d="M5.01,16.881c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 993.686)" fill="#ceb9b2" />
                                <path id="Caminho_2685" data-name="Caminho 2685" d="M5.01,34.58c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1075.45)" fill="#ceb9b2" />
                                <path id="Caminho_2686" data-name="Caminho 2686" d="M5.01,35.342c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1078.97)" fill="#ceb9b2" />
                                <path id="Caminho_2687" data-name="Caminho 2687" d="M5.01,35.877c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1081.441)" fill="#ceb9b2" />
                                <path id="Caminho_2688" data-name="Caminho 2688" d="M5.01,36.246c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.146)" fill="#ceb9b2" />
                                <path id="Caminho_2689" data-name="Caminho 2689" d="M5.01,36.421c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.954)" fill="#ceb9b2" />
                                <path id="Caminho_2690" data-name="Caminho 2690" d="M5.01,36.821c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1085.802)" fill="#ceb9b2" />
                                <path id="Caminho_2691" data-name="Caminho 2691" d="M5.01,33.809c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1071.888)" fill="#ceb9b2" />
                                <path id="Caminho_2692" data-name="Caminho 2692" d="M305.692,44.073c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,32.564,55.076,31.221,5.01,34.935c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1066.446)" fill="#ceb9b2" />
                                <path id="Caminho_2693" data-name="Caminho 2693" d="M305.692,43.31c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.8,55.076,30.458,5.01,34.172c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1062.921)" fill="#ceb9b2" />
                                <path id="Caminho_2694" data-name="Caminho 2694" d="M305.692,42.776c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.267,55.076,29.924,5.01,33.638c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1060.454)" fill="#ceb9b2" />
                                <path id="Caminho_2695" data-name="Caminho 2695" d="M305.692,42.407c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.9,55.076,29.555,5.01,33.269c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1058.749)" fill="#ceb9b2" />
                                <path id="Caminho_2696" data-name="Caminho 2696" d="M305.692,42.231c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.722,55.076,29.379,5.01,33.093c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1057.936)" fill="#ceb9b2" />
                                <path id="Caminho_2697" data-name="Caminho 2697" d="M305.692,41.832c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.323,55.076,28.98,5.01,32.694c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1056.093)" fill="#ceb9b2" />
                                <path id="Caminho_2698" data-name="Caminho 2698" d="M305.692,44.844c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,33.335,55.076,31.992,5.01,35.706c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1070.007)" fill="#ceb9b2" />
                                <path id="Caminho_2699" data-name="Caminho 2699" d="M5.01,12.359C55.171,13,105.243,16.186,155.331,18.6c50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.22,55.171,13.017,5.01,12.359" transform="translate(444.436 972.795)" fill="#ceb9b2" />
                                <path id="Caminho_2700" data-name="Caminho 2700" d="M5.01,12.875c50.161.641,100.233,3.827,150.321,6.238,50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.736,55.171,13.533,5.01,12.875" transform="translate(444.436 975.179)" fill="#ceb9b2" />
                                <path id="Caminho_2701" data-name="Caminho 2701" d="M5.01,15.379l46.256-2.107v.056L5.01,13.3l46.256-.028.006.056Z" transform="translate(444.435 977.013)" fill="#ceb9b2" />
                                <path id="Caminho_2702" data-name="Caminho 2702" d="M5.01,23.062l46.256-.028v.056L5.01,20.983l46.261,2.051-.006.056Z" transform="translate(444.435 1012.636)" fill="#ceb9b2" />
                                <path id="Caminho_2703" data-name="Caminho 2703" d="M96.544,14.481,50.288,16.588v-.056l46.256.028-46.256.028-.006-.056Z" transform="translate(653.582 982.598)" fill="#ceb9b2" />
                                <path id="Caminho_2704" data-name="Caminho 2704" d="M5.01,20.449c100.121,5.963,200.354,10.992,300.682,11.228C205.364,31.5,105.131,26.479,5.01,20.449" transform="translate(444.436 1010.169)" fill="#ceb9b2" />
                                <path id="Caminho_2705" data-name="Caminho 2705" d="M29.805,30.174c26.885,1.2,53.747,3.085,80.66,3.54,26.913.539,53.831-.455,80.676-2.315-26.845,1.883-53.764,2.894-80.676,2.372-26.918-.472-53.775-2.383-80.66-3.6" transform="translate(558.981 1055.095)" fill="#ceb9b2" />
                                <g id="Grupo_14" data-name="Grupo 14" transform="translate(449.383 943.802)">
                                        <g id="Grupo_13" data-name="Grupo 13" clip-path="url(#clip-path)">
                                                <image id="Retângulo_1677" data-name="Retângulo 1677" width="303.464" height="191.07" transform="translate(0.006 -0.815)" />
                                        </g>
                                </g>
                        </g>
                </svg>
                // <svg width="240.745" height="152.738" viewBox="0 0 240.745 152.738" x={x} y={y} style={{ transform: `rotate(${rotationAngle}deg)` }}>
                //         <defs>
                //                 <clipPath id="clip-path">
                //                         <rect id="Retângulo_1678" data-name="Retângulo 1678" width="240.738" height="152.255" fill="none" />
                //                 </clipPath>
                //         </defs>
                //         <g id="mesa" transform="translate(-449.383 -943.788)">
                //                 <rect id="Retângulo_1669" data-name="Retângulo 1669" width="240.738" height="23.783" transform="translate(449.389 1110.274)" fill="#a89d90" />
                //                 <rect id="Retângulo_1670" data-name="Retângulo 1670" width="240.738" height="23.783" transform="translate(449.389 1086.491)" fill="#999085" />
                //                 <rect id="Retângulo_1671" data-name="Retângulo 1671" width="240.738" height="23.783" transform="translate(449.389 1062.709)" fill="#978c7f" />
                //                 <rect id="Retângulo_1672" data-name="Retângulo 1672" width="240.738" height="23.783" transform="translate(449.389 1038.926)" fill="#a49989" />
                //                 <rect id="Retângulo_1673" data-name="Retângulo 1673" width="240.738" height="23.783" transform="translate(449.389 1015.144)" fill="#999085" />
                //                 <rect id="Retângulo_1674" data-name="Retângulo 1674" width="240.738" height="23.783" transform="translate(449.389 991.361)" fill="#a89d90" />
                //                 <rect id="Retângulo_1675" data-name="Retângulo 1675" width="240.738" height="23.783" transform="translate(449.389 967.579)" fill="#a49989" />
                //                 <rect id="Retângulo_1676" data-name="Retângulo 1676" width="240.738" height="23.783" transform="translate(449.389 943.796)" fill="#978c7f" />
                //                 <path id="Caminho_2614" data-name="Caminho 2614" d="M305.692,17.255C205.4,23.071,105.2,13.225,5.01,10.129,105.2,13.164,205.4,23.009,305.692,17.255" transform="translate(444.436 962.493)" fill="#ceb9b2" />
                //                 <path id="Caminho_2615" data-name="Caminho 2615" d="M5.01,10.723C105.2,13.763,205.4,23.6,305.692,17.849,205.4,23.665,105.2,13.825,5.01,10.723" transform="translate(444.436 965.237)" fill="#ceb9b2" />
                //                 <path id="Caminho_2616" data-name="Caminho 2616" d="M5.01,11.14C105.2,14.18,205.4,24.02,305.692,18.266,205.4,24.082,105.2,14.242,5.01,11.14" transform="translate(444.436 967.164)" fill="#ceb9b2" />
                //                 <path id="Caminho_2617" data-name="Caminho 2617" d="M5.01,11.428C105.2,14.468,205.4,24.308,305.692,18.554,205.4,24.37,105.2,14.53,5.01,11.428" transform="translate(444.436 968.494)" fill="#ceb9b2" />
                //                 <path id="Caminho_2618" data-name="Caminho 2618" d="M5.01,11.565C105.2,14.605,205.4,24.445,305.692,18.691,205.4,24.507,105.2,14.667,5.01,11.565" transform="translate(444.436 969.127)" fill="#ceb9b2" />
                //                 <path id="Caminho_2619" data-name="Caminho 2619" d="M5.01,11.877C105.2,14.917,205.4,24.757,305.692,19,205.4,24.819,105.2,14.979,5.01,11.877" transform="translate(444.436 970.568)" fill="#ceb9b2" />
                //                 <path id="Caminho_2620" data-name="Caminho 2620" d="M5.01,9.527C105.2,12.567,205.4,22.407,305.692,16.653,205.4,22.469,105.2,12.629,5.01,9.527" transform="translate(444.436 959.712)" fill="#ceb9b2" />
                //                 <path id="Caminho_2621" data-name="Caminho 2621" d="M5.01,13.448c13.01.427,26.025.152,39.029-.214,6.508-.2,13.01-.461,19.506-.815l9.739-.624c3.186-.354,6.6-.315,9.565-1.366C80,9.407,76.481,9.6,73.317,9.323L63.562,8.98,44.05,8.632,5.01,8.452,44.05,8.6l19.517.332,9.75.337,4.872.292c1.568.23,3.372.039,4.715.877-3.057,1.141-6.412,1.028-9.621,1.4l-9.739.618q-9.745.514-19.5.8c-13.01.36-26.025.618-39.034.185" transform="translate(444.436 954.746)" fill="#ceb9b2" />
                //                 <path id="Caminho_2622" data-name="Caminho 2622" d="M5.01,11.195l22.636-.483c7.519-.292,15.156-.27,22.563-1.09-7.379-.714-15.044-.551-22.557-.731L5.01,8.694l22.642.169c7.536.191,15.123-.051,22.614.753-7.514.933-15.078.815-22.614,1.124Z" transform="translate(444.436 955.864)" fill="#ceb9b2" />
                //                 <path id="Caminho_2623" data-name="Caminho 2623" d="M5.01,15.055Q34.7,15.831,64.4,15.3c9.9-.169,19.793-.438,29.683-.865,4.945-.219,9.885-.483,14.825-.826l7.4-.629c2.394-.365,5.063-.326,7.171-1.433-2.006-1.146-4.76-1-7.137-1.326l-7.407-.489L94.093,9.154,64.4,8.592c-19.8-.23-39.6-.264-59.395-.23,19.8-.039,39.6-.017,59.395.2l29.689.545,14.842.568,7.407.483,3.692.377c1.169.264,2.591.129,3.5,1.017-2.192,1.191-4.81,1.1-7.221,1.472l-7.4.629c-4.934.343-9.879.6-14.825.815-9.891.427-19.787.691-29.683.854q-29.689.48-59.389-.27" transform="translate(444.436 954.257)" fill="#ceb9b2" />
                //                 <path id="Caminho_2624" data-name="Caminho 2624" d="M5.01,18.135c35.05.191,70.1.27,105.15.034,17.522-.124,35.044-.32,52.567-.708,8.761-.2,17.517-.438,26.272-.809,4.378-.185,8.755-.4,13.122-.7l6.547-.562,3.259-.421c.989-.247,2.36-.236,2.945-1.023-1.742-1.146-4.164-1.124-6.26-1.506l-6.536-.68c-4.361-.382-8.733-.674-13.105-.938-8.75-.517-17.505-.9-26.261-1.219-17.511-.635-35.033-1.023-52.555-1.281Q57.588,7.567,5.01,8.379q52.567-.784,105.145-.09c17.522.253,35.044.635,52.561,1.27,8.755.32,17.511.7,26.255,1.208,4.372.264,8.744.556,13.111.938l6.536.674c2.113.4,4.49.32,6.311,1.551-.652.916-1.995.815-3,1.085l-3.259.427L202.127,16c-4.372.3-8.75.517-13.122.7-8.755.365-17.517.607-26.278.8-17.522.382-35.044.573-52.567.691-35.05.225-70.1.14-105.15-.062" transform="translate(444.436 952.441)" fill="#ceb9b2" />
                //                 <path id="Caminho_2625" data-name="Caminho 2625" d="M5.01,15.781c27.458.208,54.916.292,82.374.1,13.729-.1,27.458-.27,41.187-.6l20.579-.68,10.284-.579,5.125-.455c1.63-.275,3.58-.208,4.884-1.113-3.007-1.332-6.682-1.208-10.026-1.624l-10.273-.658c-6.856-.354-13.718-.6-20.579-.815-13.723-.41-27.452-.646-41.181-.8-27.458-.3-54.916-.27-82.374-.051,27.458-.23,54.916-.27,82.374.022,13.729.152,27.458.382,41.181.792,6.862.208,13.723.455,20.579.809l10.278.652,5.125.489c1.652.3,3.524.2,4.951,1.18-1.388,1.023-3.288.871-4.934,1.175l-5.131.45-10.278.579-20.585.674c-13.723.326-27.452.483-41.187.584C59.926,16.1,32.468,16,5.01,15.781" transform="translate(444.436 954.132)" fill="#ceb9b2" />
                //                 <path id="Caminho_2626" data-name="Caminho 2626" d="M305.692,15.677C205.5,12.636,105.3,2.8,5.01,8.551,105.3,2.735,205.5,12.575,305.692,15.677" transform="translate(444.436 946.869)" fill="#ceb9b2" />
                //                 <path id="Caminho_2627" data-name="Caminho 2627" d="M305.692,15.082C205.5,12.041,105.3,2.2,5.01,7.956,105.3,2.14,205.5,11.98,305.692,15.082" transform="translate(444.436 944.121)" fill="#ceb9b2" />
                //                 <path id="Caminho_2628" data-name="Caminho 2628" d="M305.692,14.66C205.5,11.625,105.3,1.779,5.01,7.54,105.3,1.723,205.5,11.558,305.692,14.66" transform="translate(444.436 942.194)" fill="#ceb9b2" />
                //                 <path id="Caminho_2629" data-name="Caminho 2629" d="M305.692,14.377C205.5,11.336,105.3,1.5,5.01,7.251,105.3,1.435,205.5,11.275,305.692,14.377" transform="translate(444.436 940.864)" fill="#ceb9b2" />
                //                 <path id="Caminho_2630" data-name="Caminho 2630" d="M305.692,14.24C205.5,11.2,105.3,1.359,5.01,7.114,105.3,1.3,205.5,11.138,305.692,14.24" transform="translate(444.436 940.231)" fill="#ceb9b2" />
                //                 <path id="Caminho_2631" data-name="Caminho 2631" d="M305.692,13.928C205.5,10.887,105.3,1.047,5.01,6.8,105.3.986,205.5,10.826,305.692,13.928" transform="translate(444.436 938.79)" fill="#ceb9b2" />
                //                 <path id="Caminho_2632" data-name="Caminho 2632" d="M305.692,16.278C205.5,13.237,105.3,3.4,5.01,9.152,105.3,3.336,205.5,13.176,305.692,16.278" transform="translate(444.436 949.646)" fill="#ceb9b2" />
                //                 <path id="Caminho_2633" data-name="Caminho 2633" d="M178.327,10.294c-24.266-2.062-48.667-1.124-72.932.427C81.112,12.294,56.885,14.7,32.58,16.009l.006-.056c24.243,1.725,48.565,2.248,72.871,2.315,24.305-.022,48.627-.495,72.871-2.282-24.243,1.8-48.565,2.282-72.871,2.31-24.305-.062-48.627-.573-72.876-2.287v-.056c24.3-1.3,48.526-3.7,72.814-5.26,24.266-1.545,48.667-2.467,72.932-.4" transform="translate(571.801 957.715)" fill="#ceb9b2" />
                //                 <path id="Caminho_2634" data-name="Caminho 2634" d="M154.683,10.179c-19.489-1.158-39.029-.607-58.518.253C76.671,11.309,57.2,12.646,37.7,13.4v-.056c19.483.972,38.989,1.259,58.49,1.3,19.506-.011,39.018-.27,58.5-1.27-19.478,1.012-38.989,1.276-58.5,1.3-19.5-.034-39.012-.315-58.49-1.276v-.056C57.2,12.6,76.671,11.27,96.165,10.4c19.489-.849,39.029-1.388,58.518-.225" transform="translate(595.444 959.639)" fill="#ceb9b2" />
                //                 <path id="Caminho_2635" data-name="Caminho 2635" d="M129.173,10.052c-28.66-.882-57.3.725-85.948,1.337v-.056l85.948.028-85.953.028v-.056c28.649-.6,57.3-2.18,85.953-1.281" transform="translate(620.954 960.89)" fill="#ceb9b2" />
                //                 <path id="Caminho_2636" data-name="Caminho 2636" d="M33.719,5c11.593.888,23.215,1.349,34.82,1.978L103.381,8.67l69.679,3.31L103.375,8.726,68.539,7.006C56.934,6.371,45.312,5.9,33.719,5" transform="translate(577.063 938.799)" fill="#ceb9b2" />
                //                 <path id="Caminho_2637" data-name="Caminho 2637" d="M41.151,5l97.575,5.176Z" transform="translate(611.396 938.799)" fill="#ceb9b2" />
                //                 <path id="Caminho_2638" data-name="Caminho 2638" d="M101.081,8.49,49.3,5Z" transform="translate(649.047 938.799)" fill="#ceb9b2" />
                //                 <path id="Caminho_2639" data-name="Caminho 2639" d="M68.743,6.748,56.3,5Z" transform="translate(681.385 938.799)" fill="#ceb9b2" />
                //                 <path id="Caminho_2640" data-name="Caminho 2640" d="M155.348,45.4c-12.509-.967-25.047-1.512-37.573-2.214L80.19,41.243l-75.18-3.8,75.18,3.748,37.59,1.973c12.521.714,25.058,1.264,37.568,2.242" transform="translate(444.436 1088.653)" fill="#ceb9b2" />
                //                 <path id="Caminho_2641" data-name="Caminho 2641" d="M5.01,37.76l100.447,6.154Z" transform="translate(444.436 1090.14)" fill="#ceb9b2" />
                //                 <path id="Caminho_2642" data-name="Caminho 2642" d="M5.01,38.06l54.106,4.473Z" transform="translate(444.436 1091.526)" fill="#ceb9b2" />
                //                 <path id="Caminho_2643" data-name="Caminho 2643" d="M5.01,38.37,21.667,41.1Z" transform="translate(444.436 1092.958)" fill="#ceb9b2" />
                //                 <path id="Caminho_2644" data-name="Caminho 2644" d="M305.692,32.386C205.5,29.345,105.3,19.505,5.01,25.26,105.3,19.444,205.5,29.284,305.692,32.386" transform="translate(444.436 1024.06)" fill="#ceb9b2" />
                //                 <path id="Caminho_2645" data-name="Caminho 2645" d="M305.692,31.791C205.5,28.75,105.3,18.91,5.01,24.665,105.3,18.849,205.5,28.689,305.692,31.791" transform="translate(444.436 1021.311)" fill="#ceb9b2" />
                //                 <path id="Caminho_2646" data-name="Caminho 2646" d="M305.692,31.374C205.5,28.333,105.3,18.493,5.01,24.248,105.3,18.432,205.5,28.272,305.692,31.374" transform="translate(444.436 1019.385)" fill="#ceb9b2" />
                //                 <path id="Caminho_2647" data-name="Caminho 2647" d="M305.692,31.085C205.5,28.051,105.3,18.2,5.01,23.959,105.3,18.143,205.5,27.989,305.692,31.085" transform="translate(444.436 1018.055)" fill="#ceb9b2" />
                //                 <path id="Caminho_2648" data-name="Caminho 2648" d="M305.692,30.946C205.5,27.912,105.3,18.066,5.01,23.821,105.3,18.01,205.5,27.85,305.692,30.946" transform="translate(444.436 1017.424)" fill="#ceb9b2" />
                //                 <path id="Caminho_2649" data-name="Caminho 2649" d="M305.692,30.638C205.5,27.6,105.3,17.757,5.01,23.512,105.3,17.7,205.5,27.536,305.692,30.638" transform="translate(444.436 1015.985)" fill="#ceb9b2" />
                //                 <path id="Caminho_2650" data-name="Caminho 2650" d="M305.692,32.984C205.5,29.95,105.3,20.1,5.01,25.859,105.3,20.048,205.5,29.888,305.692,32.984" transform="translate(444.436 1026.839)" fill="#ceb9b2" />
                //                 <path id="Caminho_2651" data-name="Caminho 2651" d="M122.549,26.019c-13.01-.427-26.025-.152-39.029.214-6.508.2-13.01.461-19.506.815l-9.739.624c-3.186.354-6.6.315-9.565,1.366,2.849,1.023,6.367.832,9.531,1.107L64,30.487l19.512.348,39.04.18-39.04-.152-19.517-.332-9.75-.337L49.369,29.9c-1.568-.23-3.372-.039-4.715-.877,3.057-1.141,6.412-1.028,9.621-1.4L64.014,27q9.745-.514,19.5-.8c13.01-.36,26.025-.618,39.034-.185" transform="translate(627.579 1034.855)" fill="#ceb9b2" />
                //                 <path id="Caminho_2652" data-name="Caminho 2652" d="M133.391,31.4l-91.079-.258V31.1a529.748,529.748,0,0,0,91.079,2.81,529.812,529.812,0,0,1-91.084-2.759l.006-.051Z" transform="translate(616.736 1059.35)" fill="#ceb9b2" />
                //                 <path id="Caminho_2653" data-name="Caminho 2653" d="M95.717,26.035l-22.636.483c-7.519.292-15.156.27-22.563,1.09,7.379.714,15.044.551,22.557.731l22.642.2-22.642-.169c-7.536-.191-15.123.051-22.614-.753,7.514-.933,15.078-.815,22.614-1.124Z" transform="translate(654.41 1035.974)" fill="#ceb9b2" />
                //                 <path id="Caminho_2654" data-name="Caminho 2654" d="M155.945,26.016q-29.689-.776-59.389-.242c-9.9.169-19.793.438-29.683.865-4.945.219-9.885.483-14.825.826l-7.4.629c-2.394.365-5.063.326-7.171,1.433,2.006,1.146,4.76,1,7.137,1.326l7.407.489,14.836.573,29.689.562c19.8.23,39.6.258,59.395.23-19.8.039-39.6.017-59.395-.2l-29.689-.545-14.842-.568-7.407-.483-3.692-.377c-1.169-.264-2.591-.129-3.5-1.017,2.192-1.191,4.8-1.1,7.221-1.472l7.4-.629c4.94-.343,9.885-.6,14.83-.815,9.891-.427,19.787-.691,29.683-.854q29.689-.48,59.389.27" transform="translate(594.184 1033.735)" fill="#ceb9b2" />
                //                 <path id="Caminho_2655" data-name="Caminho 2655" d="M231.079,25.57c-35.05-.191-70.1-.27-105.15-.034-17.522.124-35.044.32-52.567.708-8.761.2-17.517.438-26.272.809-4.378.185-8.755.4-13.122.7l-6.547.562-3.259.421c-.989.247-2.36.23-2.945,1.023,1.742,1.146,4.164,1.124,6.26,1.506l6.536.68c4.361.382,8.733.674,13.105.938,8.75.517,17.505.9,26.261,1.219,17.511.635,35.033,1.023,52.555,1.281q52.567.75,105.145-.062-52.567.784-105.145.09c-17.522-.253-35.044-.635-52.561-1.27-8.756-.32-17.511-.7-26.255-1.208-4.372-.264-8.744-.556-13.111-.938l-6.536-.68c-2.113-.4-4.49-.315-6.311-1.545.652-.916,2-.815,3-1.085l3.259-.427,6.547-.556c4.372-.3,8.75-.517,13.122-.7,8.756-.365,17.517-.607,26.278-.8,17.522-.382,35.044-.573,52.567-.691,35.05-.225,70.1-.141,105.15.062" transform="translate(519.049 1032.922)" fill="#ceb9b2" />
                //                 <path id="Caminho_2656" data-name="Caminho 2656" d="M193.734,25.714c-27.458-.208-54.916-.292-82.374-.1-13.729.1-27.458.27-41.187.6l-20.579.68-10.284.579-5.125.455c-1.63.275-3.58.208-4.884,1.113,3.007,1.332,6.682,1.208,10.026,1.624l10.273.658c6.856.354,13.718.6,20.579.815,13.723.41,27.452.646,41.181.8,27.458.3,54.916.27,82.374.051-27.458.23-54.916.27-82.374-.022-13.729-.152-27.458-.382-41.181-.792-6.862-.208-13.723-.455-20.579-.809l-10.278-.652L34.2,30.227c-1.652-.3-3.524-.2-4.951-1.18,1.388-1.023,3.288-.871,4.934-1.175l5.131-.45,10.278-.579,20.585-.674c13.723-.326,27.452-.483,41.181-.584,27.464-.185,54.921-.09,82.379.129" transform="translate(556.395 1033.44)" fill="#ceb9b2" />
                //                 <path id="Caminho_2657" data-name="Caminho 2657" d="M5.01,26.838C105.2,29.878,205.4,39.718,305.692,33.964,205.4,39.78,105.2,29.94,5.01,26.838" transform="translate(444.436 1039.684)" fill="#ceb9b2" />
                //                 <path id="Caminho_2658" data-name="Caminho 2658" d="M5.01,27.433C105.2,30.473,205.4,40.313,305.692,34.559,205.4,40.375,105.2,30.535,5.01,27.433" transform="translate(444.436 1042.433)" fill="#ceb9b2" />
                //                 <path id="Caminho_2659" data-name="Caminho 2659" d="M5.01,27.85C105.2,30.89,205.4,40.73,305.692,34.976,205.4,40.792,105.2,30.952,5.01,27.85" transform="translate(444.436 1044.359)" fill="#ceb9b2" />
                //                 <path id="Caminho_2660" data-name="Caminho 2660" d="M5.01,28.138C105.2,31.178,205.4,41.018,305.692,35.264,205.4,41.08,105.2,31.24,5.01,28.138" transform="translate(444.436 1045.689)" fill="#ceb9b2" />
                //                 <path id="Caminho_2661" data-name="Caminho 2661" d="M5.01,28.275C105.2,31.315,205.4,41.155,305.692,35.4,205.4,41.217,105.2,31.377,5.01,28.275" transform="translate(444.436 1046.322)" fill="#ceb9b2" />
                //                 <path id="Caminho_2662" data-name="Caminho 2662" d="M5.01,28.587C105.2,31.627,205.4,41.467,305.692,35.713,205.4,41.529,105.2,31.689,5.01,28.587" transform="translate(444.436 1047.764)" fill="#ceb9b2" />
                //                 <path id="Caminho_2663" data-name="Caminho 2663" d="M5.01,26.237C105.2,29.277,205.4,39.117,305.692,33.363,205.4,39.179,105.2,29.339,5.01,26.237" transform="translate(444.436 1036.907)" fill="#ceb9b2" />
                //                 <path id="Caminho_2664" data-name="Caminho 2664" d="M5.01,32.444c24.266,2.062,48.667,1.124,72.932-.427,24.283-1.574,48.509-3.979,72.809-5.288v.056c-24.243-1.725-48.565-2.248-72.871-2.315-24.305.022-48.627.495-72.871,2.282,24.243-1.8,48.565-2.282,72.871-2.31,24.305.062,48.627.573,72.876,2.287v.056c-24.3,1.3-48.526,3.7-72.814,5.26-24.266,1.545-48.667,2.467-72.932.4" transform="translate(444.436 1028.615)" fill="#ceb9b2" />
                //                 <path id="Caminho_2665" data-name="Caminho 2665" d="M5.01,29.24c19.489,1.158,39.029.607,58.518-.253C83.023,28.11,102.495,26.773,122,26.02v.056c-19.484-.972-38.989-1.259-58.5-1.3-19.5.011-39.012.27-58.49,1.27C24.488,25.031,44,24.766,63.5,24.744c19.506.034,39.018.315,58.5,1.276v.056c-19.5.742-38.973,2.074-58.467,2.939C44.039,29.864,24.5,30.4,5.01,29.24" transform="translate(444.436 1030.01)" fill="#ceb9b2" />
                //                 <path id="Caminho_2666" data-name="Caminho 2666" d="M5.01,26.443c28.655.882,57.3-.725,85.948-1.337v.056L5.01,25.134l85.953-.028v.056c-28.649.6-57.3,2.18-85.953,1.281" transform="translate(444.436 1031.682)" fill="#ceb9b2" />
                //                 <path id="Caminho_2667" data-name="Caminho 2667" d="M102.59,35.912l21.439.23,10.717-.022,2.675-.067,1.337-.084c.191-.034.517-.022.584-.146a2.672,2.672,0,0,0-.641-.157c-1.77-.242-3.552-.36-5.333-.495-14.263-.967-28.542-1.59-42.817-2.276L47.72,30.961C33.5,30.292,19.048,29.809,5.01,28.932c14.038.865,28.486,1.337,42.715,2l42.833,1.905c14.274.7,28.554,1.332,42.811,2.3,1.781.135,3.563.253,5.339.5a2.412,2.412,0,0,1,.652.163c-.084.2-.4.135-.6.185l-1.337.084-2.681.067-10.717.017Z" transform="translate(444.436 1049.358)" fill="#ceb9b2" />
                //                 <path id="Caminho_2668" data-name="Caminho 2668" d="M56.79,34.428l45.8-.017v.034L5.01,29.253l97.58,5.159v.034Z" transform="translate(444.434 1050.839)" fill="#ceb9b2" />
                //                 <path id="Caminho_2669" data-name="Caminho 2669" d="M5.01,35.138l51.78-2.124-.006.056L5.01,29.552l51.78,3.462v.056Z" transform="translate(444.435 1052.223)" fill="#ceb9b2" />
                //                 <path id="Caminho_2670" data-name="Caminho 2670" d="M5.01,31.61l12.442-.028-.006.056L5.01,29.863l12.448,1.72-.006.056Z" transform="translate(444.435 1053.657)" fill="#ceb9b2" />
                //                 <path id="Caminho_2671" data-name="Caminho 2671" d="M305.692,27.145c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,15.636,55.076,14.293,5.01,18.007c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 988.243)" fill="#ceb9b2" />
                //                 <path id="Caminho_2672" data-name="Caminho 2672" d="M305.692,26.383c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.874,55.076,13.531,5.01,17.245c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 984.723)" fill="#ceb9b2" />
                //                 <path id="Caminho_2673" data-name="Caminho 2673" d="M305.692,25.848c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,14.339,55.076,13,5.01,16.71,55.076,12.979,105.355,14.3,155.433,17c50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 982.251)" fill="#ceb9b2" />
                //                 <path id="Caminho_2674" data-name="Caminho 2674" d="M305.692,25.479c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.97,55.076,12.627,5.01,16.341c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 980.547)" fill="#ceb9b2" />
                //                 <path id="Caminho_2675" data-name="Caminho 2675" d="M305.692,25.3c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.795,55.076,12.452,5.01,16.166c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 979.738)" fill="#ceb9b2" />
                //                 <path id="Caminho_2676" data-name="Caminho 2676" d="M305.692,24.9c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,13.395,55.076,12.052,5.01,15.766c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 977.891)" fill="#ceb9b2" />
                //                 <path id="Caminho_2677" data-name="Caminho 2677" d="M305.692,27.916c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,16.407,55.076,15.064,5.01,18.778c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 991.805)" fill="#ceb9b2" />
                //                 <path id="Caminho_2678" data-name="Caminho 2678" d="M5.01,17.652c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,30.521,205.347,29.2,155.27,26.5c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 997.247)" fill="#ceb9b2" />
                //                 <path id="Caminho_2679" data-name="Caminho 2679" d="M5.01,18.415c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1000.772)" fill="#ceb9b2" />
                //                 <path id="Caminho_2680" data-name="Caminho 2680" d="M5.01,18.949c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343C255.626,31.818,205.347,30.5,155.27,27.8c-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1003.239)" fill="#ceb9b2" />
                //                 <path id="Caminho_2681" data-name="Caminho 2681" d="M5.01,19.318c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1004.944)" fill="#ceb9b2" />
                //                 <path id="Caminho_2682" data-name="Caminho 2682" d="M5.01,19.494c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1005.757)" fill="#ceb9b2" />
                //                 <path id="Caminho_2683" data-name="Caminho 2683" d="M5.01,19.894c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1007.605)" fill="#ceb9b2" />
                //                 <path id="Caminho_2684" data-name="Caminho 2684" d="M5.01,16.881c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 993.686)" fill="#ceb9b2" />
                //                 <path id="Caminho_2685" data-name="Caminho 2685" d="M5.01,34.58c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1075.45)" fill="#ceb9b2" />
                //                 <path id="Caminho_2686" data-name="Caminho 2686" d="M5.01,35.342c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1078.97)" fill="#ceb9b2" />
                //                 <path id="Caminho_2687" data-name="Caminho 2687" d="M5.01,35.877c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1081.441)" fill="#ceb9b2" />
                //                 <path id="Caminho_2688" data-name="Caminho 2688" d="M5.01,36.246c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.146)" fill="#ceb9b2" />
                //                 <path id="Caminho_2689" data-name="Caminho 2689" d="M5.01,36.421c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1083.954)" fill="#ceb9b2" />
                //                 <path id="Caminho_2690" data-name="Caminho 2690" d="M5.01,36.821c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1085.802)" fill="#ceb9b2" />
                //                 <path id="Caminho_2691" data-name="Caminho 2691" d="M5.01,33.809c50.145,1.95,100.16,6.1,150.265,8.795,50.077,2.714,100.351,4.057,150.417.343-50.066,3.731-100.345,2.411-150.423-.287-50.1-2.72-100.121-6.884-150.26-8.851" transform="translate(444.436 1071.888)" fill="#ceb9b2" />
                //                 <path id="Caminho_2692" data-name="Caminho 2692" d="M305.692,44.073c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,32.564,55.076,31.221,5.01,34.935c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1066.446)" fill="#ceb9b2" />
                //                 <path id="Caminho_2693" data-name="Caminho 2693" d="M305.692,43.31c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.8,55.076,30.458,5.01,34.172c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1062.921)" fill="#ceb9b2" />
                //                 <path id="Caminho_2694" data-name="Caminho 2694" d="M305.692,42.776c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,31.267,55.076,29.924,5.01,33.638c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1060.454)" fill="#ceb9b2" />
                //                 <path id="Caminho_2695" data-name="Caminho 2695" d="M305.692,42.407c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.9,55.076,29.555,5.01,33.269c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1058.749)" fill="#ceb9b2" />
                //                 <path id="Caminho_2696" data-name="Caminho 2696" d="M305.692,42.231c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.722,55.076,29.379,5.01,33.093c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1057.936)" fill="#ceb9b2" />
                //                 <path id="Caminho_2697" data-name="Caminho 2697" d="M305.692,41.832c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,30.323,55.076,28.98,5.01,32.694c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1056.093)" fill="#ceb9b2" />
                //                 <path id="Caminho_2698" data-name="Caminho 2698" d="M305.692,44.844c-50.145-1.95-100.16-6.1-150.265-8.795C105.35,33.335,55.076,31.992,5.01,35.706c50.066-3.731,100.345-2.411,150.423.287,50.1,2.72,100.121,6.884,150.26,8.851" transform="translate(444.436 1070.007)" fill="#ceb9b2" />
                //                 <path id="Caminho_2699" data-name="Caminho 2699" d="M5.01,12.359C55.171,13,105.243,16.186,155.331,18.6c50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.22,55.171,13.017,5.01,12.359" transform="translate(444.436 972.795)" fill="#ceb9b2" />
                //                 <path id="Caminho_2700" data-name="Caminho 2700" d="M5.01,12.875c50.161.641,100.233,3.827,150.321,6.238,50.072,2.377,100.323,3.956,150.361-.028-50.032,4.007-100.289,2.445-150.361.084C105.237,16.736,55.171,13.533,5.01,12.875" transform="translate(444.436 975.179)" fill="#ceb9b2" />
                //                 <path id="Caminho_2701" data-name="Caminho 2701" d="M5.01,15.379l46.256-2.107v.056L5.01,13.3l46.256-.028.006.056Z" transform="translate(444.435 977.013)" fill="#ceb9b2" />
                //                 <path id="Caminho_2702" data-name="Caminho 2702" d="M5.01,23.062l46.256-.028v.056L5.01,20.983l46.261,2.051-.006.056Z" transform="translate(444.435 1012.636)" fill="#ceb9b2" />
                //                 <path id="Caminho_2703" data-name="Caminho 2703" d="M96.544,14.481,50.288,16.588v-.056l46.256.028-46.256.028-.006-.056Z" transform="translate(653.582 982.598)" fill="#ceb9b2" />
                //                 <path id="Caminho_2704" data-name="Caminho 2704" d="M5.01,20.449c100.121,5.963,200.354,10.992,300.682,11.228C205.364,31.5,105.131,26.479,5.01,20.449" transform="translate(444.436 1010.169)" fill="#ceb9b2" />
                //                 <path id="Caminho_2705" data-name="Caminho 2705" d="M29.805,30.174c26.885,1.2,53.747,3.085,80.66,3.54,26.913.539,53.831-.455,80.676-2.315-26.845,1.883-53.764,2.894-80.676,2.372-26.918-.472-53.775-2.383-80.66-3.6" transform="translate(558.981 1055.095)" fill="#ceb9b2" />
                //                 <g id="Grupo_14" data-name="Grupo 14" transform="translate(449.383 943.802)">
                //                         <g id="Grupo_13" data-name="Grupo 13" clip-path="url(#clip-path)">
                //                                 <image id="Retângulo_1677" data-name="Retângulo 1677" width="303.464" height="191.07" transform="translate(0.006 -0.815)" />
                //                         </g>
                //                 </g>
                //         </g>
                // </svg>

        )
}

export default SubspaceIconDesk;